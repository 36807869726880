import { CaseLoad, PatientNote, Scheduler, SchedulerPatient } from '@app/interfaces';
import { createAction, props } from '@ngrx/store';

export const scheduleChangedAction = createAction(
    '[schedule action] schedule tabs changed',
    props<{ selectedTab: string }>()
);

export const scheduleResetAction = createAction('[schedule action] schedule tabs reset');
export const schedulerChangedAction = createAction(
    '[scheduler action] schedule patient and therapist changed',
    props<{ scheduler: Scheduler }>()
);
export const createOrUpdateCaseloadAction = createAction(
    '[scheduler action] schedule therapist caseload create or update',
    props<{ therapistId: string; caseload: CaseLoad }>()
);
export const schedulerPatientNotesUpdates = createAction(
    '[scheduler action] patient note updated',
    props<{
        patientData: SchedulerPatient;
        therapyDiscipline: string;
        patientNote: PatientNote;
        updatedCaseload: CaseLoad;
    }>()
);
export const schedulerResetAction = createAction('[scheduler action] scheduler patient and therapist reset');
export const schedulerCalendarChangedAction = createAction(
    '[scheduler action] scheduler calendar changed',
    props<{ selectedDate: Date }>()
);

export const schedulerCalendarResetAction = createAction('[scheduler action] scheduler calendar reset');
