<div
    [ngClass]="{ focused: isInputFocused, disabled: isInputFocused, 'disabled-input': disabled }"
    class="input-border-radius text-secondary w-100 body-medium d-flex {{ wrapperClass }}"
    [style]="style"
>
    <div class="input-group-prepend align-items-center px-2">
        <span [class.disabled]="isInputFocused"><img src="assets/icons/search.svg" /></span>
    </div>
    <input
        [(ngModel)]="searchValue"
        class="pl-0 borderless input-border-radius pr-2 {{ inputClass }}"
        style="width: 100%;"
        type="search"
        #searchInput
        [autocomplete]="autocomplete"
        [placeholder]="placeholder"
        (keyup)="onChange($event)"
        (focus)="onInputFocus()"
        (blur)="onInputBlur()"
        (input)="onAnyChange($event)"
        [disabled]="disabled"
    />
</div>
<!-- //colworx sk add #searchInput to input to clear search input -->