import {
    AfterViewInit,
    Directive,
    ElementRef,
    EventEmitter,
    Host,
    Input,
    OnDestroy,
    Output,
} from '@angular/core';

@Directive({
    selector: '[enterTheViewportNotifier]',
})
export class EnterTheViewportNotifierDirective implements AfterViewInit, OnDestroy {
    @Output() visibilityChange: EventEmitter<any> = new EventEmitter<any>();
    @Input() rootMargin: string;
    @Input() threshold: number;
    @Input() disabled: boolean;
    private _observer: IntersectionObserver;

    constructor(@Host() private _elementRef: ElementRef) {}
    ngAfterViewInit(): void {
        const options = {
            root: null,
            ...(this.rootMargin ? { rootMargin: this.rootMargin } : { rootMargin: '-50% 0%' }), // rootMargin: '50% 0%',
            ...(this.threshold ? { threshold: this.threshold } : { threshold: 0.0 }), //threshold: 0.20,
        };

        this._observer = new IntersectionObserver(this._callback, options);

        this._observer.observe(this._elementRef.nativeElement);
    }

    ngOnDestroy() {
        this._observer?.disconnect();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private _callback = (entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting == true) {
                this.visibilityChange.emit({ visible: this.disabled });
            }
        });
    };
}
