<div [formGroup]="vitalsForm">
    <div class="row">
        <div class="col-lg-6 col-md-12">
            <div class="row">
                <div class="col-6">
                    <app-input
                        label="Respiratory Rate"
                        placeholder="Enter Value"
                        type="number"
                        inputWithContent="true"
                        [contentInfo]="{ type: 'text', text: 'BPM' }"
                        formControlName="respiratoryRate"
                        mask="000"
                        (changeHappened)="onChange()"
                    ></app-input>
                    <div class="row">
                        <div class="form-group col-12 mb-0">
                            <label class="text-secondary fw-bold">Blood Pressure</label>
                        </div>
                        <div class="col-6 mt-n2">
                            <app-input
                                [hideLabel]="true"
                                placeholder="Enter"
                                type="number"
                                inputWithContent="true"
                                [contentInfo]="{ type: 'text', text: 'SBP' }"
                                formControlName="SBP"
                                mask="000"
                                (changeHappened)="onChange()"
                            ></app-input>
                        </div>
                        <div class="col-6 mt-n2">
                            <app-input
                                [hideLabel]="true"
                                placeholder="Enter"
                                type="number"
                                inputWithContent="true"
                                [contentInfo]="{ type: 'text', text: 'DBP' }"
                                formControlName="DBP"
                                mask="000"
                                (changeHappened)="onChange()"
                            ></app-input>
                        </div>
                    </div>
                    <app-input
                        label="Temperature"
                        placeholder="Enter Degree"
                        type="number"
                        allowDecimal="true"
                        [temperatureScale]="temperatureScale"
                        inputWithContent="true"
                        [contentInfo]="{ type: 'dropdown', options: ['C', 'F'] }"
                        formControlName="temperature"
                        (changeHappened)="onChange()"
                    ></app-input>
                </div>
                <div class="col-6">
                    <app-input
                        label="Pulse Rate"
                        placeholder="Enter Value"
                        type="number"
                        inputWithContent="true"
                        [contentInfo]="{ type: 'text', text: 'BPM' }"
                        formControlName="pulseRate"
                        mask="000"
                        (changeHappened)="onChange()"
                    ></app-input>
                    <app-input
                        label="O2 Saturation"
                        placeholder="Enter Percentage"
                        type="number"
                        inputWithContent="true"
                        [contentInfo]="{ type: 'text', text: '%' }"
                        formControlName="o2Saturation"
                        mask="000"
                        (changeHappened)="onChange()"
                    ></app-input>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-12">
            <app-text-box label="Notes/Comments" formControlName="note" (onBlur)="onChange()"></app-text-box>
        </div>
    </div>
</div>
