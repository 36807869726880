import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GridActionsService } from '@app/data-grid/services/grid-actions.service';

@Injectable({
    providedIn: 'root',
})
export class PrintService {
    isPrinting = false;
    selectedDocs = [];
    withEdits = false;

    constructor(private router: Router, private gridActionService: GridActionsService) {}

    printDocument(documentName: string, documentData: string[]) {
        this.isPrinting = true;
        this.router.navigate([
            '/print/' + documentName + '/' + documentData.join(),
            // {
            //   outlets: {
            //     print: ['print', documentName, documentData.join()],
            //   },
            // },
        ]);
    }

    onDataReady() {
        this.gridActionService.print.next(true);
        setTimeout(() => {
            // window.print();
            print();
            this.isPrinting = false;
            this.router.navigate([{ outlets: { print: null } }]);
        }, 500);
    }

    getSelectedDocs(): any[] {
        return this.selectedDocs;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setSelectedDocs(value): any[] {
        return this.selectedDocs;
    }

    getWithEdits(): boolean {
        return this.withEdits;
    }

}
