import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TherapyDisciplineBarCardComponent } from './therapy-discipline-bar-card.component';
import { PccV2HistoryListComponent } from './pcc-v2-history-list/pcc-v2-history-list.component';
import { DataGridModule } from '@app/data-grid/data-grid.module';

@NgModule({
    declarations: [TherapyDisciplineBarCardComponent, PccV2HistoryListComponent],
    imports: [CommonModule, DataGridModule],
    exports: [TherapyDisciplineBarCardComponent, PccV2HistoryListComponent]
})
export class TherapyDisciplineBarCardModule { }