import { Injectable } from '@angular/core';
import { map, toArray } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { concat, Observable, of } from 'rxjs';
const baseUrl = environment.apiUrl + '/facility-admission';

@Injectable({
    providedIn: 'root',
})
export class ReportService {
    constructor(private http: HttpClient) { }

    getTripleCheck(payload: any): Observable<any[]> {
        const url = `${baseUrl}/triple-check-report`;
        return this.http.post<any>(url, payload).pipe(map((v) => v.data));
    }
    getMOP(payload: any): Observable<any> {
        const url = `${environment.apiUrl}/payor/mbp-report`;
        if(payload.facilities.length < 1) return of([])
        const facilityChunks = this.chunkArray(payload.facilities, 10)
        const payloadChunks = facilityChunks.map(facilityChunk => ({...payload, facilities: facilityChunk}))
        return concat(...payloadChunks.map(payload => this.http.post<any>(url, payload))).pipe(toArray(), map((v) => {
            return [...(v.map(respItem => respItem.data))].flat()
        }))
    }
    getEOMReport(payload: any): Observable<any[]> {
        const url = `${baseUrl}/eom-report`;
        return this.http.post<any>(url, payload).pipe(map((v) => v.data));
    }
    getTripleCheckDetails(payload: any): Observable<any[]> {
        const url = `${baseUrl}/triple-check-report-details`;
        return this.http.post<any>(url, payload).pipe(map((v) => v.data));
    }
    getEOMDetails(payload: any): Observable<any[]> {
        const url = `${baseUrl}/eom-report-details`;
        return this.http.post<any>(url, payload).pipe(map((v) => v.data));
    }

    private chunkArray(arr, chunkSize) {
        const result = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
          result.push(arr.slice(i, i + chunkSize));
        }
        return result;
      }
}
