import { OverlayRef } from '@angular/cdk/overlay';
import { BehaviorSubject } from 'rxjs';

export class Ref {
    afterClose: BehaviorSubject<boolean> = new BehaviorSubject(false);
    afterAction: BehaviorSubject<boolean> = new BehaviorSubject(false);
    afterActionData: BehaviorSubject<any> = new BehaviorSubject(null);
    constructor(readonly overlay: OverlayRef) {
        this.overlay.outsidePointerEvents().subscribe(() => {
            this.close();
            this.afterClosed();
        });
        this.overlay.keydownEvents().subscribe((v) => {
            if (v.key === 'Escape') {
                this.close();
                this.afterClosed();
            }
        });
    }

    close() {
        this.overlay.dispose();
    }

    afterClosed() {
        this.afterClose.next(true);
    }
}
