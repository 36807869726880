<app-base-modal-card [heading]="heading" (close)="onClose()">
    <div body class="body-container">
        <div *ngIf="previousJustification">
            <span class="text-secondary font-weight-bold"> Last Visit Summary</span>
            <br />
            <p class="mt-2">
                <span *ngIf="showMore"
                    >{{ previousJustification | slice: 0:350 }}
                    {{ previousJustification.length >= 350 ? '...' : '' }}</span
                >
                <span *ngIf="!showMore">{{ previousJustification }}</span>
                <span
                    *ngIf="previousJustification.length >= 350"
                    class="text-primary body-medium cursor-pointer"
                    (click)="showMore = !showMore"
                >
                    {{ showMore ? 'See More' : 'See Less' }}
                </span>
            </p>
        </div>
        <app-text-box
            [(ngModel)]="justification"
            [isSuggestionBox]="true"
            [suggestionsList]="suggestionsList"
            [highlightTexts]="['_', { from: '[', to: ']' }]"
            (isFilled)="updateValidStatus($event)"
            maxLength="2000"
        ></app-text-box>
    </div>
    <div footer class="w-100 d-flex flex-row align-items-center justify-content-end">
        <button
            [ngClass]="{ disabled: !this.justification || saveInProgress }"
            [disabled]="!valid"
            (click)="saveClicked()"
            class="btn btn-secondary rounded-pill d-flex flex-row align-items-enter"
        >
            Save
        </button>
    </div>
</app-base-modal-card>
