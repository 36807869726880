import { Component, Input, OnInit } from '@angular/core';
import { ROUTER_UTILS } from '@app/helpers';
import { Router } from '@angular/router';

@Component({
    selector: 'app-permanent-alert',
    templateUrl: './permanent-alert.component.html',
    styleUrls: ['./permanent-alert.component.scss'],
})
export class PermanentAlertComponent implements OnInit {
    @Input() type: 'danger' | 'warning' | 'info' | 'success';
    @Input() message = 'Something went wrong!';
    @Input() button = true;
    @Input() gotoButton = true;
    @Input() patient;
    @Input() therapyAdmission;
    docUrl = '/' + ROUTER_UTILS.config.patients.pcc.tabs.documentation.root;
    evalUrl = '/' + ROUTER_UTILS.config.patients.pcc.tabs.documentation.evalDoc.root;
    className = 'text-white';
    icon: string;
    constructor(private router: Router) {}
    ngOnInit(): void {
        switch (this.type) {
            case 'danger': {
                this.icon = 'cancel';
                break;
            }
            case 'warning': {
                this.className = 'text-black';
                this.icon = 'warning';
                break;
            }
            case 'info': {
                this.icon = 'info';
                break;
            }
            case 'success': {
                this.icon = 'check_circle';
                break;
            }

            default:
                break;
        }
    }
}
