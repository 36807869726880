import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

@Injectable()
export class UserDateInterceptor implements HttpInterceptor {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isApiUrl = request.url.startsWith(environment.apiUrl);
        const hours = new Date().getHours();
        const minutes = new Date().getMinutes();
        const seconds = new Date().getSeconds();
        if (isApiUrl) {
            request = request.clone({
                setHeaders: { 'X-user-date': new Date(new Date().setHours(hours, minutes, seconds, 0)).toString() },
            });
        }

        return next.handle(request);
    }
}
