import { createReducer, on } from '@ngrx/store';
import {
    spinnerDocumentStateChangeAction,
    spinnerTherapyStateChangeAction,
    spinnerStateChangeAction,
    spinnerStateResetAction,
} from './../actions/spinner.actions';

import {
    initialDocumentLoadingSpinnerState,
    initialLoadingSpinnerState,
    initialTherapyLoadingSpinnerState,
} from '@app/store/selectors';
export const spinnerStatesReducer = createReducer(
    initialLoadingSpinnerState,
    on(spinnerStateChangeAction, (state, { selectedLoadingSpinnerState }) => selectedLoadingSpinnerState),

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    on(spinnerStateResetAction, (state) => initialLoadingSpinnerState)
);

//initialDocumentLoadingSpinnerState
export const spinnerDocumentStatesReducer = createReducer(
    initialDocumentLoadingSpinnerState,
    on(spinnerDocumentStateChangeAction, (state, { selectedLoadingSpinnerState }) => selectedLoadingSpinnerState)
);

export const spinnerTherapyStatesReducer = createReducer(
    initialTherapyLoadingSpinnerState,
    on(spinnerTherapyStateChangeAction, (state, { selectedLoadingSpinnerState }) => selectedLoadingSpinnerState)
);
