import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DIALOG_DATA } from '@app/helpers/dialog-tokens';
import { DailyNote, DetailedCptCodes, TherapyAdmission } from '@app/interfaces';
import { DialogRef, DailyNotesService, DocumentationService, TherapyAdmissionService, StoreService } from '@app/services';
import { Subscription } from 'rxjs';
import { ToasterService } from '@app/services';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { LibrariesService } from '@app/services/libraries.service';
import { Doc_Notes } from '@app/interfaces/documentation';

@Component({
    selector: 'app-skilled-services-goals',
    templateUrl: './add-skilled-services.component.html',
    styleUrls: ['./add-skilled-services.component.scss'],
})
export class AddSkilledServicesComponent implements OnInit {
    heading = '';
    currentCptCode = '';
    justification = '';
    previousJustification = '';
    valid = false;
    showMore = true;
    suggestionsList: Array<string> = [];
    private subscriptions: Subscription[] = [];
    private currentTA: TherapyAdmission;
    private currentDN: DailyNote;
    private currentAdmissions: TherapyAdmission[];
    private routeData: { doc: string; discipline: string } = {
        doc: '',
        discipline: '',
    };
    saveInProgress = false;

    constructor(
        @Inject(DIALOG_DATA) private data,
        private ref: DialogRef,
        private route: ActivatedRoute,
        private dailyNotesService: DailyNotesService,
        private toaster: ToasterService,
        private changeDetection: ChangeDetectorRef,
        private store: Store<{
            therapyAdmission: TherapyAdmission[];
        }>,
        private libraryService: LibrariesService,
        private therapyService: TherapyAdmissionService,
        private documentationService: DocumentationService,
        private therapyAdmissionService: TherapyAdmissionService,
        private storeService: StoreService
    ) {
        this.route.queryParamMap.subscribe({
            next: (params) => {
                this.routeData.doc = params.get('doc');
                this.routeData.discipline = params.get('discipline');
            },
        });

        this.subscriptions.push(
            this.store.select('therapyAdmission').subscribe((admissions: TherapyAdmission[]) => {
                if (!admissions || !admissions.length) {
                    return;
                }

                this.currentAdmissions = admissions;
                this.currentTA = this.therapyService.initCurrentTherapyAdmissionByIdQueryString(admissions);
                this.currentDN = this.currentTA?.documentation?.dailyNote?.find(
                    (elem) => elem.id === this.routeData.doc
                );
            })
        );
    }

    ngOnInit(): void {
        this.heading = this.data.heading;
        this.currentCptCode = this.data.cptCode;
        this.justification = this.data.justification === 'NA' ? '' : this.data.justification;
        this.previousJustification = this.data.previousJustification === 'NA' ? '' : this.data.previousJustification;
        this.getSkilledSuggestions();
    }

    onClose() {
        this.ref.close();
    }
    onSave() {
        console.log('Saving...');
        this.onClose();
    }
    ngAfterContentChecked() {
        this.changeDetection.detectChanges();
    }
    getSkilledSuggestions() {
        const skilledServices = this.libraryService.getSkilledServices();
        const cptDataExists = skilledServices[this.currentTA.therapyDiscipline].filter(
            (item) => item.key === this.currentCptCode
        );
        if (cptDataExists.length > 0) {
            if (Array.isArray(cptDataExists[0].value)) {
                this.suggestionsList = cptDataExists[0].value;
            } else {
                this.suggestionsList = [cptDataExists[0].value];
            }
        }
    }

    updateValidStatus(event) {
        this.valid = event;
    }

    saveClicked(): Promise<void> {
        this.saveInProgress = true;
        const payload: any = cloneDeep(this.currentDN);
        payload.documentation_id = this.currentTA.documentation.id;
        if (!payload) {
            console.log('ERR: unable to get current Daily Note');
            return;
        }
        const foundIndex = payload.objectiveAssessment.detailedCptCodes.findIndex(
            (record: DetailedCptCodes) => record.code === this.currentCptCode
        );
        const newRecord = {
            code: this.currentCptCode,
            description: this.data.description,
            exercises: payload.objectiveAssessment.detailedCptCodes[foundIndex].exercises,
            goals: payload.objectiveAssessment.detailedCptCodes[foundIndex].goals,
            cptMinutes: payload.objectiveAssessment.detailedCptCodes[foundIndex].cptMinutes,
            justification: this.justification,
            isTeleHealthEligible: payload.objectiveAssessment.detailedCptCodes[foundIndex].isTeleHealthEligible,
            isMds: payload.objectiveAssessment.detailedCptCodes[foundIndex].isMds,
        };
        payload.objectiveAssessment.detailedCptCodes[foundIndex] = newRecord;
        const currentTA = JSON.parse(JSON.stringify(this.currentTA));
            currentTA.documentation.dailyNote[this.data.dailyNoteIndex].objectiveAssessment.detailedCptCodes =
                payload['objectiveAssessment']['detailedCptCodes'];
            this.dailyNotesService.prepareDataForEdit(
                'objectiveAssessment',
                'detailedCptCodes',
                payload.objectiveAssessment.detailedCptCodes,
                'array',
                this.data.allTherapyAdmissions,
                currentTA,
                this.data.dailyNoteIndex
            );
        if (!this.data.isEditMode) {
            this.documentationService.updateDocumentationV2(currentTA, Doc_Notes.Daily_note).then((result) => {
                if (!result) {
                    return;
                }
                this.toaster.show({
                    title: 'Successful',
                    body: 'Justification added to Service Code ' + this.currentCptCode,
                    type: 'success',
                });
                this.onClose();
            }, () => {
                this.therapyAdmissionService
                    .getAdmissionOnFacilities(this.currentTA.facilityAdmission)
                    .subscribe((admissions) => {
                        this.storeService.setTherapyAdmission(admissions);
                    });
            });
        } else {
            this.onClose();
        }
    }
}
