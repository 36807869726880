/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
    selector: 'app-radio-btn-change-value',
    templateUrl: './radio-btn-change-value.component.html',
    styleUrls: ['./radio-btn-change-value.component.scss'],
})
export class RadioBtnChangeValueComponent implements ControlValueAccessor {
    @Input('options') radioOptions: Array<{ name: string; value: any }> = [];
    @Input() label: string;
    @Input() isVertical = false;
    @Output() radioChangeEvent: EventEmitter<any> = new EventEmitter();
    @Input() backgroundClass = 'bg-white';
    @Input() id = '';
    @Input() contentAlign = 'justify-content-center';
    propagateChange = (_: any) => {};
    onTouched = (_: any) => {};

    @Input() value: any;
    @Input() disabled: boolean;

    constructor(public readonly ngControl: NgControl) {
        ngControl.valueAccessor = this;
    }

    get isRequired(): boolean {
        const control: AbstractControl = this.ngControl.control;
        return control?.validator?.(control)?.required ?? false;
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    radioSelect(value: any): void {
        this.value = value;
        this.propagateChange(value);
        this.radioChangeEvent.emit(value);
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
