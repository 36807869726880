import { Pipe, PipeTransform } from '@angular/core';
import { cloneDeep } from 'lodash';

@Pipe({
    name: 'arraySort',
})
export class ArraySortPipe implements PipeTransform {
    transform(value: any[], criteria: SortCriteria): any[] {
        value = cloneDeep(value);
        if (!value || !criteria) return value;

        const p: string = criteria.property;

        const sortFn: (a: any, b: any) => any = (a, b) => {
            let value = 0;
            if (a[p] === undefined) value = -1;
            else if (b[p] === undefined) value = 1;
            else value = a[p] > b[p] ? 1 : b[p] > a[p] ? -1 : 0;
            return criteria.descending ? value * -1 : value;
        };

        value.sort(sortFn);
        return value;
    }
}

export interface SortCriteria {
    property: string;
    descending?: boolean;
}
