<nav
    class="layout-transition app-header-container header-design navbar navbar-expand-lg navbar-light bg-white"
    [ngClass]="layout === 'collapsed' ? 'layout-collapsed' : 'layout-expanded'"
>
    <div class="container-fluid pl-0">
        <!-- <a class="navbar-brand" href="#">
      <img loading="lazy" src="./../../../assets/icons/logo.svg" width="136px" />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
      (click)="toggleHamburger()"
    >
      <span class="navbar-toggler-icon"></span>
    </button> -->
    <div [ngClass]="mobSizeEnabled ? 'd-flex justify-content-between align align-items-center w-100 ' : ''">
            <div *ngIf="mobSizeEnabled">
                <span (click)="leftMenu = true" class="material-symbols-outlined align-middle ml-1 mt-2"> menu </span>
            </div>
            <a href="#" id="site-logo-box" [style.width]="mobSizeEnabled ? '40%' : ''">
                <img
                    loading="lazy"
                    class="logo-text"
                    src="../../../assets/logo/logo.svg"
                    [style.width]="mobSizeEnabled ? '100%' : ''"
                />
            </a>

            <div *ngIf="mobSizeEnabled">
                <span (click)="rightMenu = true" class="material-symbols-outlined align-middle ml-1 mt-2">
                    home_work
                </span>
            </div>
        </div>
        <div
            class="collapse navbar-collapse justify-content-lg-end"
            id="navbarSupportedContent"
            [class.show]="showMobileMenu === true"
            *ngIf="account"
        >
            <ul class="navbar-nav custom-navbar pt-1">
                <ng-container *ngIf="loading; else loaded" [ngTemplateOutlet]="skeleton"></ng-container>
                <ng-template #loaded>
                    <ng-container *ngIf="!loading">
                        <li *ngIf="!invoicePage" class="nav-item d-flex align-items-center justify-content-center">
                            <div
                                ngbDropdown
                                (openChange)="openClose($event)"
                                class="d-inline-block"
                                [ngStyle]="{ color: checkRoutes() || !showOrganizations ? '#888' : '#212529' }"
                            >
                                <span
                                    [ngClass]="{ disabled: checkRoutes() || !showOrganizations }"
                                    ngbDropdownToggle
                                    class="cursor-pointer"
                                    id="organizationDropdown"
                                >
                                    <span
                                        class="material-symbols-outlined align-middle mr-1"
                                        [ngStyle]="{ color: checkRoutes() || !showOrganizations ? '#888' : '#3e568e' }"
                                        ngbTooltip="Organizations"
                                    >
                                        {{ checkRoutes() || !showOrganizations ? 'domain_disabled' : 'domain' }}
                                    </span>
                                    {{ selectedOrganization.name }}
                                    <span *ngIf="showOrganizations" class="material-symbols-outlined align-middle mr-2">
                                        expand_more
                                    </span>
                                    <span
                                        *ngIf="!showOrganizations"
                                        class="material-symbols-outlined align-middle mr-2"
                                    >
                                    </span>
                                </span>
                                <div
                                    *ngIf="showOrganizations"
                                    class="facility-list-holder"
                                    ngbDropdownMenu
                                    aria-labelledby="organizationDropdown"
                                >
                                    <app-search
                                        placeholder="Search Organization"
                                        (search)="organizationSearch($event)"
                                        wrapperClass="mb-2 bg-body-background"
                                        inputClass="bg-body-background"
                                    ></app-search>
                                    <div class="facility-list-dropdown">
                                        <ul>
                                            <li
                                                class="bg-body-background d-flex flex-row justify-content-between"
                                                [ngClass]="{ active: selectedOrganization.name === organization.name }"
                                                *ngFor="let organization of filteredOrganizationList"
                                                (click)="organizationChanged(organization)"
                                                ngbDropdownItem
                                            >
                                                <span
                                                    class="flex flex-wrap"
                                                    [ngbTooltip]="
                                                        organization.name?.length > 17 ? organization.name : ''
                                                    "
                                                    >{{ organization.name | stringLength: 18:18 }}</span
                                                >
                                                <span
                                                    class="top-right-badge bg-parimary-200 border border-light rounded-circle d-flex flex-row align-items-center justify-content-center"
                                                >
                                                    <span ngbTooltip="Facilities">{{
                                                        organization.facilityCount
                                                    }}</span>
                                                </span>
                                            </li>
                                        </ul>
                                        <span
                                            *ngIf="this.filteredOrganizationList.length < 1"
                                            class="text-center d-block pt-3 no-list-item"
                                            >No Organizations Found</span
                                        >
                                        <span
                                            *ngIf="
                                                this.organizationList.length > 4 &&
                                                this.organizationList.length !== this.filteredOrganizationList.length &&
                                                this.organizationSearchString === ''
                                            "
                                            (click)="showAllOrganizations()"
                                            class="text-center text-primary cursor-pointer show-all"
                                            >Show All</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="nav-item d-flex align-items-center justify-content-center">
                            <div
                                ngbDropdown
                                (openChange)="openClose($event)"
                                class="d-inline-block"
                                [ngStyle]="{ color: checkRoutes() || showNavItem ? '#888' : '#212529' }"
                            >
                            <!-- colworx-ak: add showNavItem -->
                                <span
                                [ngClass]="{ disabled: checkRoutes() || invoicePage || showNavItem }"

                                    ngbDropdownToggle
                                    class="cursor-pointer"
                                    id="facilityDropdown"
                                >
                                   <!-- colworx-ak: add showNavItem -->
                                    <span
                                        class="material-symbols-outlined align-middle mr-1"
                                        [ngStyle]="{ color: checkRoutes() || showNavItem ? '#888' : '#3e568e' }"
                                        ngbTooltip="Facilities"
                                    >
                                        {{ checkRoutes() ? 'domain_disabled' : 'home_work' }}
                                    </span>
                                    {{
                                        invoicePage
                                            ? 'All Authorized Facilities'
                                            : showNavItem
                                            ? 'All Facilities'
                                            : selectedFacility.name
                                    }}
                                    <span *ngIf="!invoicePage" class="material-symbols-outlined align-middle">
                                        expand_more
                                    </span>
                                </span>
                                <div class="facility-list-holder" ngbDropdownMenu aria-labelledby="facilityDropdown">
                                    <app-search
                                        placeholder="Search facility"
                                        (search)="facilitySearch($event)"
                                        wrapperClass="mb-2 bg-body-background"
                                        inputClass="bg-body-background"
                                    ></app-search>
                                    <div class="facility-list-dropdown">
                                        <ul>
                                            <li
                                                class="bg-body-background"
                                                [ngClass]="{ active: selectedFacility.name === facility.name }"
                                                *ngFor="let facility of filteredFacilityList"
                                                (click)="facilityChanged(facility)"
                                                ngbDropdownItem
                                            >
                                                {{ facility.name }}
                                            </li>
                                        </ul>
                                        <span
                                            *ngIf="this.filteredFacilityList.length < 1"
                                            class="text-center d-block pt-3 no-list-item"
                                            >No Facilities Found</span
                                        >
                                        <span
                                            *ngIf="
                                                this.titles.length > 4 &&
                                                this.titles.length !== this.filteredFacilityList.length &&
                                                this.facilitySearchString === ''
                                            "
                                            (click)="showAllFacilities()"
                                            class="text-center text-primary cursor-pointer show-all"
                                            >Show All</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <!-- colworx-ak: add *ngIf="!showNavItem" -->
                            <span *ngIf="!showNavItem" class="px-2 py-1 ml-2" style="width: 160px">
                                <app-dropdown-pill
                                    [disabled]="!goLivePermission"
                                    [pillStatus]="goLive ? 'On' : 'Off'"
                                    [selectedPill]="goLive ? 'Go Live: On' : 'Go Live: Off'"
                                    [options]="goLiveOptions"
                                    (selectedPillChange)="handleGoLiveChange($event)"
                                ></app-dropdown-pill>
                            </span>

                        </li>
                    </ng-container>
                </ng-template>

                <ng-template #skeleton>
                    <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
                </ng-template>
                <!-- colworx-ak: add *ngIf="!showNavItem" -->
                <li *ngIf="!showNavItem" class="nav-item d-flex align-items-center ml-2">
                    <img
                        src="../../../assets/images/icon-feedback.png"
                        alt="Icon Feedback"
                        width="40"
                        class="cursor-pointer"
                        (click)="openFeedback()"
                        placement="bottom"
                        ngbTooltip="Give Feedback"
                    />
                </li>
                <!-- colworx-ak: add *ngIf="!showNavItem" -->
                <li *ngIf="!AuthService.isSuperAdmin && !showNavItem && config" class="nav-item d-flex align-items-center justify-content-center">
                    <div
                        ngbDropdown
                        class="d-inline-block clock-dropdown"
                        [ngClass]="{ 'pointer-events-none opacity-50 ': AuthService.isSuperAdmin }"
                    >
                        <button
                            ngbDropdownToggle
                            class="btn text-capitalize shadow-none icon-text-item"
                            id="timeTrackingDropdown"
                        >
                            <div class="clock-icon">
                                <span
                                    class="material-symbols-outlined color-secondary-300"
                                    [class.filled-icon]="timeExist"
                                    >schedule</span
                                >
                            </div>
                        </button>
                        <div class="clock-in-dropdown" ngbDropdownMenu aria-labelledby="timeTrackingDropdown">
                            <p class="text-secondary">
                                <strong>My Time: </strong> {{ currentDate | date: 'EEEE, MMM d' }}
                            </p>
                            <div class="in-out-block">
                                <app-input
                                    *ngIf="config.enableClock"
                                    type="text"
                                    [ngModel]="clock"
                                    [inputWithContent]="true"
                                    [contentInfo]="{
                                        type: 'button',
                                        text: timeEvent,
                                        icon: timeExist ? 'stop' : 'arrow_right',
                                        type: 'button'
                                    }"
                                    [contentClass]="timeExist ? 'bg-danger-200' : 'bg-secondary'"
                                    [readOnly]="true"
                                    [mask]="true"
                                    (changeHappened)="trackTimeToggle(timeEvent)"
                                    inputClass="clock-input color-secondary-300 disabled"
                                ></app-input>
                                <hr />
                                <p
                                    ngbDropdownToggle
                                    [routerLink]="timeTrackingRoute"
                                    class="font-14 text-secondary d-flex align-items-center justify-content-center cursor-pointer m-0"
                                >
                                    Open your timecard
                                    <span class="material-symbols-outlined">arrow_right</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </li>
                <!-- colworx-ak: add *ngIf="!showNavItem" -->
                <li *ngIf="!showNavItem" class="nav-item d-flex align-items-center justify-content-center">
                    <div
                        ngbDropdown
                        (openChange)="openClose($event)"
                        class="d-inline-block"
                        [ngStyle]="{ color: checkRoutes() ? '#888' : '#212529' }"
                    >
                        <button
                            ngbDropdownToggle
                            class="btn text-capitalize shadow-none icon-text-item pl-md-1 pr-md-1"
                            id="notificationDropdown"
                        >
                            <div class="d-flex flex-row align-items-center justify-content-center">
                                <div>
                                    <div class="badge-icon">
                                        <img loading="lazy" src="./../../../assets/icons/bell.svg" />
                                        <div
                                            *ngIf="unReadCount > 0"
                                            class="top-right-badge bg-danger-200 border border-light rounded-circle d-flex flex-row align-items-center justify-content-center"
                                        >
                                            <span class="text-white notification-count">{{
                                                unReadCount > 99 ? '99+' : unReadCount
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                                <h6 class="ml-1 mb-0 h6-semibold"></h6>
                            </div>
                        </button>
                        <div
                            ngbDropdownToggle
                            class="notification-list-dropdown"
                            ngbDropdownMenu
                            aria-labelledby="notificationDropdown"
                        >
                            <div class="feed-header">
                                <span class="notification-header-text-1">Notifications</span>
                                <span
                                    *ngIf="markingReadLoading"
                                    class="spinner-border spinner-border-sm mr-1 mark-read-loading"
                                ></span>
                                <span
                                    (click)="markAllAsRead($event)"
                                    *ngIf="!markingReadLoading && notifications.length > 0"
                                    [disabled]="unReadCount < 1"
                                    class="mark-read-disabled"
                                    [ngClass]="unReadCount > 0 ? 'mark-read' : ''"
                                    >Mark as read</span
                                >
                            </div>
                            <div *ngIf="notifications.length == 0 && archivedCount == 0" class="no-notification">
                                No Notifications yet
                            </div>
                            <div
                                *ngFor="let notification of notifications.slice(0, 5)"
                                class="notification-cell"
                                [ngClass]="!notification.isRead ? 'unread-background' : ''"
                                (click)="notificationHandler(notification)"
                            >
                                <div class="message-container">
                                    <div *ngIf="!notification.isRead" class="header-container">
                                        <div class="is-read-icon"><span class="elipse"></span></div>
                                    </div>
                                    <div class="message-container-inner">
                                        <div class="subject-line">
                                            <span class="message ellipsis">{{ notification.data.title }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="time-container">
                                    <span class="time"
                                        >{{ formatNotificationTime(notification.createdAt) }} |
                                        {{ notification?.facility?.facilityName }}</span
                                    >
                                </div>
                            </div>

                            <div
                                *ngIf="notifications.length > 0 || archivedCount > 0"
                                class="notification-cell"
                                (click)="showAllNotifications($event)"
                            >
                                <div class="message-container">
                                    <div class="header-container"></div>
                                    <div class="message-container-inner">
                                        <div class="subject-line">
                                            <span class="see-all-notifications">See all Notifications</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="notification-list-dropdown">
                                <ul>
                                    <li class="feed-header">Notifications</li>
                                    <li
                                        class="bg-body-background"
                                        *ngFor="let notification of notifications.slice(0, 4)"
                                        (click)="notificationHandler(notification)"
                                        ngbDropdownItem
                                    >
                                        {{ notification.message }}
                                    </li>
                                </ul>
                                <span
                                    *ngIf="this.notifications.length < 1"
                                    class="text-center d-block pt-3 no-list-item"
                                    >No Notification Found</span
                                >
                                <span
                                    *ngIf="
                                        this.notifications.length > 4 &&
                                        this.titles.length !== this.notifications.length
                                    "
                                    (click)="showAllNotifications()"
                                    class="text-center text-primary cursor-pointer show-all"
                                    >Show All</span
                                >
                            </div> -->
                        </div>
                    </div>
                </li>
                <li
                    class="nav-item dropdown facility-dropdown dropdown-menu-end d-flex align-items-center justify-content-center"
                >
                    <div ngbDropdown class="d-inline-block">
                        <button
                            (click)="toggleExpandMore()"
                            class="btn text-capitalize shadow-none icon-text-item pl-md-0 pr-md-0"
                            id=""
                            ngbDropdownToggle
                        >
                            <div class="d-flex flex-row align-items-center justify-content-center">
                                <h7
                                    class="h6-semibold character-circle bg-secondary text-white d-flex flex-row align-items-center rounded-circle mb-0"
                                >
                                    {{ (currentUser$ | async).user.firstName.charAt(0) | uppercase
                                    }}{{ (currentUser$ | async).user.lastName.charAt(0) | uppercase }}
                                </h7>
                                <h7 class="h6-semibold ml-2 mb-0" id="user-name-box">
                                    {{ (currentUser$ | async).user.lastName | titlecase }},
                                    {{ (currentUser$ | async).user.firstName | titlecase }}
                                </h7>
                                <span class="material-icons-outlined arrow-down"> expand_more </span>
                            </div>
                        </button>
                        <!-- colworx-ak: add *ngIf="!showNavItem" -->
                        <div
                            ngbDropdownMenu
                            *ngIf="!showNavItem"
                            aria-labelledby="dropdownBasic1"
                            class="zindex-popover userDropDown"
                        >
                           <button
                                ngbDropdownItem
                                routerLink="/"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                class="nav-item nav-link"
                            >
                                <span class="material-symbols-outlined"> home </span>
                                {{ 'appTranslation.header.user_dropdown.home' | translate | titlecase }}
                            </button>
                            <button
                                ngbDropdownItem
                                class="nav-item nav-link"
                                [routerLink]="['/profile']"
                                [queryParams]="{ id: userId }"
                                routerLinkActive="active"
                            >
                                <span class="material-symbols-outlined"> person </span>
                                {{ 'appTranslation.header.user_dropdown.profile' | translate | titlecase }}
                            </button>
                            <button
                                ngbDropdownItem
                                *ngIf="showAdminPanel"
                                [routerLink]="adminUrl"
                                routerLinkActive="active"
                                class="nav-item nav-link"
                            >
                                <span class="material-symbols-outlined"> manage_accounts </span>
                                {{ 'appTranslation.header.user_dropdown.admin' | translate | titlecase }}
                            </button>
                            <button
                                ngbDropdownItem
                                ngbDropdownItem
                                *ngIf="!showAdminPanel && showManage"
                                [routerLink]="adminUrl"
                                routerLinkActive="active"
                                class="nav-item nav-link"
                            >
                                <span class="material-symbols-outlined"> manage_accounts </span>
                                {{ 'appTranslation.header.user_dropdown.manage' | translate | titlecase }}
                            </button>
                            <button
                                ngbDropdownItem
                                [routerLink]="helpUrl"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                class="nav-item nav-link"
                            >
                                <span class="material-symbols-outlined"> help </span>
                                {{ 'appTranslation.left_nav.help' | translate | titlecase }}
                            </button>
                            <!-- <button
                                ngbDropdownItem
                                *ngIf="showSettings"
                                [routerLink]="settingsUrl"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                class="nav-item nav-link"
                            >
                                {{ 'appTranslation.left_nav.settings' | translate | titlecase }}
                            </button> -->
                            <button ngbDropdownItem (click)="logout()" class="nav-item nav-link">
                                <span class="material-symbols-outlined"> logout </span>
                                {{ 'appTranslation.header.user_dropdown.logout' | translate }}
                            </button>
                        </div>

                        <!-- colworx-ak:-->
                        <div
                            ngbDropdownMenu
                            class="zindex-popover userDropDown drodown-show"
                            *ngIf="showNavItem"
                            aria-labelledby="dropdownBasic1"
                        >

                            <button
                                class="body-small fw-600 text-dropdown-blue"
                                ngbDropdownItem
                                (click)="navigateToProfile()"
                            >
                            View Profile
                            </button>
                            <button class="body-small text-danger fw-600" ngbDropdownItem (click)="logout()">
                                {{ 'appTranslation.header.user_dropdown.logout' | translate }}
                            </button>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>
<!-- colworx-sk -->
<p-sidebar
    [style]="{ background: '#1c3b6d' }"
    [showCloseIcon]="false"
    [(visible)]="leftMenu"
    position="left"
    [baseZIndex]="10000"
    autoZIndex="true"
    baseZIndex="-1"
>
    <div>
        <div class="custom-header" style="display: flex; justify-content: space-between; margin: 0 10px; color: white">
            <h6>Menu</h6>
            <div class="header-buttons">
                <span (click)="closeSidebar()" class="material-symbols-outlined"> close </span>
            </div>
        </div>
        <div class="breakline">
            <hr />
        </div>

        <div class="leftNav" *ngFor="let nav of navValues; index as i">
            <div [ngClass]="{ navlist: TsIndex === i }" (click)="redirectFromSideNav(i, nav.routeTo)">
                <span class="material-symbols-outlined"> {{ nav.icon }} </span>
                {{ nav.name }}
            </div>
        </div>
    </div>

    <div class="logout-section" (click)="logout()">
        <span class="material-symbols-outlined"> logout </span> <span style="margin-left: 5px">Logout</span>
    </div>
</p-sidebar>
<p-sidebar
    [showCloseIcon]="false"
    [style]="{ background: '#E7EBEE' }"
    [(visible)]="rightMenu"
    position="right"
    [baseZIndex]="10000"
    autoZIndex="true"
    baseZIndex="-1"
>
    <div>
        <div class="custom-header" style="display: flex; justify-content: space-between; margin: 0 10px">
            <h6>Change facility</h6>
            <div class="header-buttons">
                <span (click)="closeSidebar()" class="material-symbols-outlined"> close </span>
            </div>
        </div>
        <div class="rightNav">
            <div
                ngbDropdown
                (openChange)="openClose($event)"
                [ngStyle]="{ color: checkRoutes() || !showOrganizations ? '#888' : '#212529' }"
            >
                <span
                    [ngClass]="{ disabled: checkRoutes() || !showOrganizations }"
                    ngbDropdownToggle
                    class="cursor-pointer"
                    id="organizationDropdown"
                >
                    <span
                        style="padding: 12px 15px"
                        class="material-symbols-outlined align-middle mr-1"
                        [ngStyle]="{ color: checkRoutes() || !showOrganizations ? '#888' : '#3e568e' }"
                        ngbTooltip="Organizations"
                    >
                        {{ checkRoutes() || !showOrganizations ? 'domain_disabled' : 'domain' }}
                    </span>
                    {{ selectedOrganization.name }}
                    <span
                        style="position: absolute; right: 0"
                        *ngIf="showOrganizations"
                        class="material-symbols-outlined align-middle mr-2 mt-2"
                    >
                        expand_more
                    </span>
                    <span *ngIf="!showOrganizations" class="material-symbols-outlined align-middle mr-2"> </span>
                </span>
                <div
                    *ngIf="showOrganizations"
                    class="facility-list-holder"
                    ngbDropdownMenu
                    aria-labelledby="organizationDropdown"
                >
                    <app-search
                        placeholder="Search Organization"
                        (search)="organizationSearch($event)"
                        wrapperClass="mb-2 bg-body-background"
                        inputClass="bg-body-background"
                    ></app-search>
                    <div class="facility-list-dropdown">
                        <ul>
                            <li
                                class="bg-body-background d-flex flex-row justify-content-between"
                                [ngClass]="{ active: selectedOrganization.name === organization.name }"
                                *ngFor="let organization of filteredOrganizationList"
                                (click)="organizationChanged(organization)"
                                ngbDropdownItem
                            >
                                <span
                                    class="flex flex-wrap"
                                    [ngbTooltip]="organization.name?.length > 17 ? organization.name : ''"
                                    >{{ organization.name | stringLength: 18:18 }}</span
                                >
                                <span
                                    class="top-right-badge bg-parimary-200 border border-light rounded-circle d-flex flex-row align-items-center justify-content-center"
                                >
                                    <span ngbTooltip="Facilities">{{ organization.facilityCount }}</span>
                                </span>
                            </li>
                        </ul>
                        <span
                            *ngIf="this.filteredOrganizationList.length < 1"
                            class="text-center d-block pt-3 no-list-item"
                            >No Organizations Found</span
                        >
                        <span
                            *ngIf="
                                this.organizationList.length > 4 &&
                                this.organizationList.length !== this.filteredOrganizationList.length &&
                                this.organizationSearchString === ''
                            "
                            (click)="showAllOrganizations()"
                            class="text-center text-primary cursor-pointer show-all"
                            >Show All</span
                        >
                    </div>
                </div>
            </div>

            <div
                ngbDropdown
                (openChange)="openClose($event)"
                style="z-index: 0 !important; left: 0 !important"
                [ngStyle]="{ color: checkRoutes() || showNavItem ? '#888' : '#212529' }"
            >
                <span
                    [ngClass]="{ disabled: checkRoutes() || showNavItem }"
                    ngbDropdownToggle
                    class="cursor-pointer"
                    id="facilityDropdown"
                >
                    <span
                        style="padding: 12px 15px"
                        class="material-symbols-outlined align-middle mr-1"
                        [ngStyle]="{ color: checkRoutes() || showNavItem  ? '#888' : '#3e568e' }"
                        ngbTooltip="Facilities"
                    >
                        {{ checkRoutes() ? 'domain_disabled' : 'home_work' }}
                    </span>
                    {{showNavItem ? 'All Facilities' : selectedFacility.name }}
                    <span style="position: absolute; right: 0" class="material-symbols-outlined align-middle mr-2 mt-2">
                        expand_more
                    </span>
                </span>
                <div class="facility-list-holder" ngbDropdownMenu aria-labelledby="facilityDropdown">
                    <app-search
                        placeholder="Search facility"
                        (search)="facilitySearch($event)"
                        wrapperClass="mb-2 bg-body-background"
                        inputClass="bg-body-background"
                    ></app-search>
                    <div class="facility-list-dropdown">
                        <ul>
                            <li
                                class="bg-body-background"
                                [ngClass]="{ active: selectedFacility.name === facility.name }"
                                *ngFor="let facility of filteredFacilityList"
                                (click)="facilityChanged(facility)"
                                ngbDropdownItem
                            >
                                {{ facility.name }}
                            </li>
                        </ul>
                        <span *ngIf="this.filteredFacilityList.length < 1" class="text-center d-block pt-3 no-list-item"
                            >No Facilities Found</span
                        >
                        <span
                            *ngIf="
                                this.titles.length > 4 &&
                                this.titles.length !== this.filteredFacilityList.length &&
                                this.facilitySearchString === ''
                            "
                            (click)="showAllFacilities()"
                            class="text-center text-primary cursor-pointer show-all"
                            >Show All</span
                        >
                    </div>
                </div>
            </div>
            <!-- <span
                    *ngIf="!goLiveEnabled"
                    class="badge badge-gray-100 font-weight-normal rounded-curve px-2 py-1 ml-2"
                >
                    Go Live is off
                </span> -->
            <!-- </li> -->
            <!-- </ng-container>
    </ng-template> -->
        </div>
    </div>
</p-sidebar>
<!-- colworx-sk -->
