function get(obj, path): any {
    // Code updated: Previous code was returning false in case of empty string comparison
    return path.split('.').reduce((r, e) => {
        if (r === undefined || r === null) return undefined; // Stop if parent is undefined/null
        return Object.prototype.hasOwnProperty.call(r, e) ? r[e] : undefined; // Use safe check for property existence
    }, obj);
}

function isEmpty(o): any {
    // If any ambiguity caused in previous work. Remember the change of !o.
    if (!o || typeof o !== 'object') return true;
    else return !Object.keys(o).length;
}

function build(a, b, o = null, prev = ''): any {
    return Object.keys(a).reduce(
        (r, e) => {
            const path = prev + (prev ? '.' + e : e);
            const bObj = get(b, path);
            const isBothEmptyStrings = a[e] === '' && bObj === '';
            const value = isBothEmptyStrings || a[e] === bObj;

            if (typeof a[e] === 'object' && a[e] !== null) {
                if (isEmpty(a[e]) && isEmpty(bObj)) {
                    if (!r[e]) r[e] = true;
                } else if (!bObj && isEmpty(a[e])) {
                    r[e] = value;
                } else {
                    r[e] = build(a[e], b, r[e], path);
                }
            } else {
                r[e] = value;
            }
            return r;
        },
        o ? o : {}
    );
}

export const _compareArrays = (a, b) => {
    const o = build(a, b);
    return build(b, a, o);
};
