import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { GridRowAction } from '@app/data-grid/interfaces/grid-row-action';
import { GridActionsService } from '@app/data-grid/services/grid-actions.service';
import { TreatmentDiagnosis } from '@app/interfaces';
import { ClinicalCategoriesService } from '@app/services';
import { GridApi, RowClassRules } from 'ag-grid-community';
import * as _ from 'lodash';

@Component({
    selector: 'app-add-item-table',
    templateUrl: './add-item-table.component.html',
    styleUrls: ['./add-item-table.component.scss'],
})
export class AddItemTableComponent implements OnChanges {
    state: 'zero' | 'one' = 'zero';
    @Input() treatmentDiagnosisInput: TreatmentDiagnosis[];
    loading = false;
    isDone = true;
    @Input() isReadOnly = false;
    @Input() disableAdd = false;
    @Input() searchPlaceholder;
    @Input() heading = '';
    @Input() columns;
    @Input() data: any[] = [];
    @Input() selectedData: any[] = [];
    @Input() editColumns;
    @Input() addedItemKey: string;
    @Input() addedItemSubKey: string;
    @Input() identifier: string;
    @Input() addEvent = false;
    @Output() addEventEmitter = new EventEmitter();
    @Output() updateEventEmitter = new EventEmitter();
    @Output() searchEvent: EventEmitter<string> = new EventEmitter();

    // Row class rules to display
    rowClassRules: RowClassRules = {
        'bg-primary-50': function (params) {
            console.log(
                this.selectedData.findIndex((object) => object.id === params.data[this.identifier]),
                params.data[this.identifier]
            );
            return (
                this.selectedData.findIndex((object) => object[this.identifier] === params.data[this.identifier]) !== -1
            );
        }.bind(this),
        'text-secondary': function (params) {
            return (
                this.selectedData.findIndex((object) => object[this.identifier] === params.data[this.identifier]) !== -1
            );
        }.bind(this),
    };
    gridApi: GridApi;
    constructor(
        private clinicalCategoryService: ClinicalCategoriesService,
        private gridActionService: GridActionsService
    ) {
        //NOTE: currently this grid used only in MDS, and we have no specific actions. In future if we need actions we can subscribe.
        // gridActionService.action.subscribe((params) => this.deleteSelectedRowItem(params));
    }
    setState(value: 'zero' | 'one') {
        this.state = value;
    }
    public searchStart(event) {
        this.searchEvent.emit(event);
    }
    // For Ag Grid
    // Working with clinical categories
    addSelectedItem(event: any) {
        const data = event.data;
        if (!this.isDone) {
            if (this.selectedData.findIndex((object) => object[this.identifier] === data[this.identifier]) === -1) {
                this.selectedData = [...this.selectedData, data];
            } else {
                this.removeSelectedItem(data);
            }
        } else {
            if (this.selectedData.findIndex((object) => object[this.identifier] === data[this.identifier]) === -1) {
                this.selectedData = [...this.selectedData, data];
            }
        }
    }
    removeSelectedItem(data: any) {
        this.selectedData = this.selectedData.filter((item) => item[this.identifier] !== data[this.identifier]);
        this.updateEventEmitter.emit(this.selectedData);
    }
    setLoading(value: boolean) {
        this.loading = value;
    }
    toggleIsDone(): void {
        if (!this.addEvent) {
            this.isDone = !this.isDone;
            if (this.isDone === true) {
                this.gridApi.setColumnDefs(this.columns);
                this.updateEventEmitter.emit(this.selectedData);
                if (this.selectedData.length <= 0) {
                    this.state = 'zero';
                } 
            } else {
                this.gridApi.setColumnDefs(this.editColumns);
            }
            this.gridApi.sizeColumnsToFit();
        } else if (this.addEvent) {
            this.addEventEmitter.emit(true);
        }
    }
    // Grid API functions
    initGrid(event: any) {
        this.gridApi = event.api;
    }
    redrawRows() {
        this.gridApi.redrawRows();
    }
    // Grid renderer functions
    setRendererDate(param: any) {
        this.selectedData = this.selectedData.map((item) => {
            const deepItem = _.cloneDeep(item);
            if (deepItem[this.identifier] === param.data[this.identifier]) {
                deepItem.date = param.value;
            }
            return deepItem;
        });
        this.updateEventEmitter.emit(this.selectedData);
    }
    deleteSelectedRowItem(params: GridRowAction) {
        console.log(params);

        this.selectedData = this.selectedData.filter((item) => item[this.identifier] !== params.data[this.identifier]);
        this.updateEventEmitter.emit(this.selectedData);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.selectedData.firstChange) {
            if (changes.selectedData.currentValue.length != changes.selectedData.previousValue.length) {
                this.gridApi.setDomLayout('autoHeight');
            }
        }
        if (changes.selectedData.currentValue.length > 0) {
            this.state = 'one';
        }
    }
}
