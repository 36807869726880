import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, take, switchMap } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Store } from '@ngrx/store';
import { PatientState, SLPComorbidity } from '@app/store/store.interface';
import { SLPChangeAction } from '@app/store';

const baseUrl = `${environment.apiUrl}/slp-comorbidity`;

@Injectable({ providedIn: 'root' })
export class SlpComorbidityService {
    tab$: Observable<any>;
    patient: PatientState;
    isLoading: boolean;
    constructor(private http: HttpClient, private store: Store<{ slpComorbidity: SLPComorbidity[] }>) {}
    //searching and storing all comorbidity in local state to make search a bit faster
    //because if I take it from stateless backend then it would have to query it all the time whenever user searches for it.
    getAllSLPs(): Observable<SLPComorbidity[]> {
        return this.store.select('slpComorbidity').pipe(
            take(1),
            switchMap((comorbidities) => {
                if (comorbidities.length > 0) return of(comorbidities);
                else if (this.isLoading) return;
                else {
                    this.isLoading = true;
                    return this.http.get<{ data: SLPComorbidity[] }>(`${baseUrl}/all`, { withCredentials: true }).pipe(
                        map((slp) => {
                            this.isLoading = false;
                            this.store.dispatch(SLPChangeAction({ SLPComorbidity: slp.data }));
                            return slp.data;
                        })
                    );
                }
            })
        );
    }

    slpSearch(searchText: string) {
        if (searchText.length >= 3) {
            return this.http.get<any>(`${baseUrl}/search/` + searchText, { withCredentials: true }).pipe(
                map((result) => {
                    return result;
                })
            );
        } else {
            return of([]);
        }
    }
}
