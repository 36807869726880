import { createAction, props } from '@ngrx/store';
import { PatientState } from '../store.interface';

export const patientChangedAction = createAction(
    '[patient action] patient changed',
    props<{ patient: PatientState }>()
);
export const patientRemovedAction = createAction('[patient action] patient removed');

export const caseloadCurrentDateAction = createAction(
    '[caseload date] caseload current date action',
    props<{ selectedDate: Date }>()
);