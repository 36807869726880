/* eslint-disable spellcheck/spell-checker */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ROUTER_UTILS } from '@app/helpers';
import { uuidv4 } from '@app/helpers/utils/charts/goals.utils';
import { TabItem } from '@app/interfaces';
import { FacilityManagementService, OrganizationManagementService, ToasterService } from '@app/services';
import { take } from 'rxjs';

interface OrganizationData {
    companyCode: string;
    name: string;
}
@Component({
    selector: 'app-add-organization',
    templateUrl: './add-organization.component.html',
    styleUrls: ['./add-organization.component.scss'],
})
export class AddOrganizationComponent implements OnInit {
    organizationForm: FormGroup;
    EditingData: any;
    loading: boolean;
    title = 'Add New Organization';
    tabs: TabItem[];
    showOrganizationalInfo = true;
    showOrganizationaPreferences = false;
    showBillingInfo = false;
    isNpcComplete = false;
    isConfigComplete = false;
    orgData: OrganizationData = { companyCode: '', name: '' };
    filteredStates = [];
    states = [];
    counties = [];
    mailingCounties: any[];
    organizationUrl = '';
    config :any 
    npcActivities =[ {
        activity:  'Labor Time',
        unit:  'By hour',
        billable:true ,
        rate: '',
        activityId: uuidv4(),
        isDeleted:  false,
        isDeletable: false,
        endDate: ''
    },
    {
        activity:  'Travel Time',
        unit:  'By hour',
        billable:true ,
        rate: '',
        activityId: uuidv4(),
        isDeleted:  false,
        isDeletable: false,
        endDate: ''
    },
    {
        activity:  'Administrative Time',
        unit:  'By hour',
        billable:true ,
        rate: '',
        activityId: uuidv4(),
        isDeleted:  false,
        isDeletable: false,
        endDate: ''
    },
    {
        activity:  'Mileage',
        unit:  'By mile',
        billable:true ,
        rate: '',
        activityId: uuidv4(),
        isDeleted:  false,
        isDeletable: false,
        endDate: ''
    }
]
    enableTherapyInvoice: false;
    enableClock: false;
    constructor(
        private router: Router,
        private toasterService: ToasterService,
        private formBuilder: FormBuilder,
        private _organizationService: OrganizationManagementService,
        private facilityManagementService: FacilityManagementService
    ) {
        this.states = this.facilityManagementService.getUSAStates();
        this.filteredStates = this.states.map((item: any) => item.stateName);
        this.counties = this.facilityManagementService.getUSACounties();
    }

    ngOnInit(): void {
        this.organizationForm = this.formBuilder.group({
            name: ['', [Validators.required]],
            companyCode: ['', [Validators.required]],
            address: this.formBuilder.group({
                address: ['', [Validators.required]],
                city: ['', [Validators.required]],
                county: [''],
                state: ['', [Validators.required]],
                zipCode: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]],
                phone: [''],
                fax: [''],
            }),
        });
        this.initializeTabs();
        this.organizationUrl = `/${ROUTER_UTILS.config.admin.organization.root}/${ROUTER_UTILS.config.admin.organization.organizationDetails}/`;
    }

    updateCounties(state: string) {
        const stateData = this.states?.filter((x) => x?.stateName === state);
        this.mailingCounties = this.counties
            ?.filter((x) => x?.stateCode === stateData[0]?.stateCode)
            ?.map((item) => item.countyName);
    }
    initializeTabs() {
        this.tabs = [
            {
                url: 'organization-information',
                title: 'Organization Information*',
                highlight: false,
                underline: true,
                isDone: false,
            },
            {
                url: 'organization-preferences',
                title: 'Organization Preferences*',
                highlight: false,
                underline: false,
                isDone: false,
            },
            {
                url: 'non-patient-care',
                title: 'Timecard activities*',
                highlight: false,
                underline: false,
                isDone: true,
            }
            
        ];
    }
    handleCheckboxStatus(event: { billingChecked: boolean; clockChecked: boolean, isSaving: boolean }) {
        this.isConfigComplete = true;
        this.config={
            enableTherapyInvoice: event.billingChecked,
            enableClock: event.clockChecked
        }
        if(event.isSaving){
            this.setActivities(event);
        }
    }
    setPreferences() {
        this.selectedTab(1);
    }
    setActivities(event) {
        if (!this.organizationForm.invalid) {
            this.selectedTab(2);
        }
        this.config={
            enableTherapyInvoice: event.billingChecked,
            enableClock: event.clockChecked
        }
    }
    handleNPCList(event) {
        this.npcActivities = event.data;
        if(event.isValid){
            this.isNpcComplete = true;
        }
        else{
            this.isNpcComplete = false;
        }
        if(event.isSaving && event.isValid){
            this.saveUpdateOrganization();
        }
    }
    saveUpdateOrganization() {
        if (this.organizationForm.invalid) {
            this.organizationForm.markAllAsTouched();
            return;
        }
        this.npcActivities.forEach((activity: any)=> {
            delete activity._id
        });
        this.organizationForm.value.npc =     this.npcActivities;
            this.organizationForm.value.config = {
                enableTherapyInvoice: this.config.enableTherapyInvoice,
                enableClock: this.config.enableClock
            }
            this._organizationService
                .saveOrganization(this.organizationForm.value)
                .pipe(take(1))
                .subscribe(
                    (result: any) => {
                        if (result.success) {
                            this.toasterService.show({ title: 'Success', body: result.message, type: 'success' });
                        } else {
                            this.toasterService.show({ title: 'Failure', body: result.message, type: 'error' });
                        }
                        const id = result.data.id;
                        this.orgData.name = result.data.name;
                        this.orgData.companyCode = result.data.companyCode;
                        this.router.navigateByUrl(this.organizationUrl + id);
                        
                    },
                    (error) => {
                        this.toasterService.show({ title: 'Failure', body: error, type: 'error' });
                    }
                );
    }
    onCancel() {
        history.back();
    }

    updateOrganizationData(data) {
        this._organizationService.__Organizations.subscribe((org) => {
            const organization = org.find((item) => item.id == this.EditingData.id);
            organization.name = data.name;
            organization.companyCode = data.companyCode;
        });
    }

    selectedTab(index) {
        if (this.organizationForm.invalid) {
            this.toasterService.show({
                title: 'Failure',
                body: 'Please add Organization details',
                type: 'error',
            });
            return;
        } else if (
            index === 2
        ) {
            this.tabs[0].highlight = false;
            this.tabs[0].underline = true;
            this.tabs[0].isDone = !this.organizationForm.invalid;
            this.tabs[1].highlight = false;
            this.tabs[1].underline = true;
            this.tabs[1].isDone = this.isConfigComplete;
            this.tabs[2].highlight = true;
            this.tabs[2].underline = true;
            this.tabs[2].isDone = this.isNpcComplete;
            this.showOrganizationalInfo = false;
            this.showOrganizationaPreferences = false;
            this.showBillingInfo = true;
        } else if (index === 0) {
            this.showOrganizationalInfo = true;
            this.showBillingInfo = false;
            this.showOrganizationaPreferences = false;
            this.tabs[0].highlight = true;
            this.tabs[0].underline = true;
            this.tabs[0].isDone = !this.organizationForm.invalid;
            this.tabs[2].highlight = false;
            this.tabs[2].underline = false;
            this.tabs[2].isDone = this.isNpcComplete;
            this.tabs[1].highlight = false;
            this.tabs[1].underline = false;
            this.tabs[1].isDone = this.isConfigComplete;
        }
        else if (index === 1) {
            this.showOrganizationaPreferences = true;
            this.showBillingInfo = false;
            this.showOrganizationalInfo = false;
            this.tabs[1].highlight = true;
            this.tabs[1].underline = true;
            this.tabs[1].isDone = this.isConfigComplete;
            this.tabs[0].highlight = false;
            this.tabs[0].underline = true;
            this.tabs[0].isDone = !this.organizationForm.invalid;
            this.tabs[2].highlight = false;
            this.tabs[2].underline = false;
            this.tabs[2].isDone = this.isNpcComplete;
        }
    }
}
