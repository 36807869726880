<div class="form-group body-small">
    <label>{{ label }}</label>
    <div
        ngbDropdown
        #dropdown="ngbDropdown"
        [placement]="placement"
        (openChange)="dropdownOpenChanged($event)"
        class="d-inline-block form-control rounded-curve"
        [ngClass]="disabled ? 'disabled-background-color' : 'bg-body-background'"
    >
        <button style="padding: 15px 40px 15px 15px" id="dropdownForm1" [disabled]="disabled" ngbDropdownToggle>
            <span
                [ngClass]="{ 'body-small placeholder': !value }"
                class="text-secondary float-left w-100 overflow-hidden text-left"
                >{{ value ? (value | titlecase | name) : placeholder }}</span
            >
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownForm1" class="w-100">
            <app-search
                *ngIf="options.length > 3 && showSearch"
                placeholder="Search"
                wrapperClass="mb-2 bg-body-background"
                (search)="search($event)"
                inputClass="bg-body-background"
            ></app-search>
            <div
                *ngIf="filteredOptions.length > 0"
                style="overflow-y: scroll; overflow-x: hidden; max-height: 14rem"
                class="text-secondary body-small-bold bg-white"
            >
                <div
                    *ngFor="let option of filteredOptions"
                    class="pt-1 pr-2 pb-1 pl-2 dropdown-list"
                    (click)="optionSelect(option); $event.stopPropagation(); dropdown.close()"
                >
                    <div class="row">
                        <div class="col-9 body-large-semibold">{{ option | titlecase | name }}</div>
                    </div>
                    <div class="mt-3 dropdown-divider"></div>
                </div>
            </div>
            <div *ngIf="filteredOptions.length <= 0" class="text-secondary body-small-semibold text-center">
                No record found
            </div>
        </div>
    </div>
</div>
