export enum TIME {
    hours = 6,
    minutes = 0,
    seconds = 0,
}

interface Time {
    hours: number;
    minutes: number;
    seconds: number;
}

export function setHours(date: string | Date): Date | null {
    if (date) {
        return new Date(new Date(date).setHours(TIME.hours, TIME.minutes, TIME.seconds));
    } else return null;
}

export function getTimeSlots(): Time[] {
    const timeIntervals = [];

    for (let i = 0; i < 24 * 4; i++) {
        const hour24 = Math.floor(i / 4);
        const hour12 = hour24 % 12 === 0 ? 12 : hour24 % 12;
        const minutes = (i % 4) * 15;
        const period = hour24 < 12 ? 'AM' : 'PM';

        timeIntervals.push({
            hours: hour12.toString().padStart(2, '0'),
            minutes: minutes.toString().padStart(2, '0'),
            seconds: '00',
            period,
        });
    }

    return timeIntervals;
}

export function to24HourFormat(time): Time {
    const { hours, minutes, seconds, period } = time;
    const hours24 = period === 'PM' ? (hours % 12) + 12 : hours % 12;

    return { hours: hours24, minutes, seconds };
}

export function convertTimeTo24HourFormat(timeString: string) {
    const [time, period] = timeString.split(' ');
    const [initialHour, minutes] = time.split(':').map(Number);
    // Adjust hour for 24-hour format
    const hour = period === 'PM' && initialHour !== 12
        ? initialHour + 12
        : period === 'AM' && initialHour === 12
        ? 0
        : initialHour;
    return {
        hour,
        minutes,
        seconds: 0
    };
}
