<div *ngIf="isPrintOpen" class="empty-container"></div>
<div *ngIf="reportsLoaded" id="print-content-cont">
    <div *ngFor="let report of reports; index as i">
        <app-daily-notes-report
            #dailyNote
            *ngIf="report.type === 'Daily Note' && report.key === 'description'"
            [reportId]="report.id"
            [report]="isNotDocExport ? null : selectedDocs[0]?.dailyNote"
        >
        </app-daily-notes-report>
        <app-evaluation-report
            #evaluation
            *ngIf="report.type === 'Evaluation'"
            [reportId]="report.id"
            [physicianDetails]="report.physicianDetails"
            [report]="isNotDocExport ? null : selectedDocs[0]?.evaluation"
        ></app-evaluation-report>
        <app-progress-note-report
            #progressNote
            *ngIf="report.type === 'Progress Note'"
            [reportId]="report.id"
            [report]="isNotDocExport ? null : selectedDocs[0]?.progressNote"
        >
        </app-progress-note-report>
        <app-upoc-report
            *ngIf="report.type === 'Updated Plan of Care'"
            [physicianDetails]="report.physicianDetails"
            [reportId]="report.id"
        >
        </app-upoc-report>
        <app-recertification-report
            *ngIf="report.type === 'Recertification'"
            [reportId]="report.id"
            [physicianDetails]="report.physicianDetails"
            [report]="isNotDocExport ? null : selectedDocs[0]?.recertificationNote"
        >
        </app-recertification-report>
        <app-discharge-report
            *ngIf="report.type === 'Discharge Note'"
            [reportId]="report.id"
            [report]="isNotDocExport ? null : selectedDocs[0]?.dischargeNote"
        >
        </app-discharge-report>
        <app-addendum-report
            *ngIf="report.type === 'Addendum' || (report.type === 'Daily Note' && report.key !== 'description')"
            [reportId]="report.id"
            [key]="report.key"
            [therapistExists]="report.assignedTherapistExist"
            [report]="isNotDocExport ? null : selectedDocs[0]?.addendumNote"
        >
        </app-addendum-report>
    </div>
</div>
