import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-impairments-cell-renderer',
    templateUrl: './impairments-cell-renderer.component.html',
    styleUrls: ['./impairments-cell-renderer.component.scss'],
})
export class ImpairmentsCellRendererComponent implements ICellRendererAngularComp {
    params;
    refresh(): boolean {
        return false;
    }
    agInit(params: ICellRendererParams): void {
        // if (Array.isArray(params.data.fieldValue)) params.data.fieldValue = params.data.fieldValue.map(val => val.text).join(', ')
        this.params = params.data;
        // console.log('params....', this.params)
    }
}
