import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { convertSecsToMins, placeZerosOnLeft } from '@app/helpers/utils/data.utils';

@Component({
    selector: 'app-n-inputs',
    templateUrl: './n-inputs.component.html',
    styleUrls: ['./n-inputs.component.scss'],
})
export class NInputsComponent implements OnInit {
    @Input() value: number; //  will be in secs
    @Input() backgroundClass = 'bg-body-background';
    @Input() inputClass = '';
    @Input() minValue: number;
    @Output() valueChanged: EventEmitter<any> = new EventEmitter();

    // will convert below vars to "Inputs" in future
    fields: string[] = ['min', 'sec'];
    placeholderIndex: { [key: string]: string } = {
        min: 'min',
        sec: 'sec',
    };
    valueIndex: { [field: string]: any } = {};
    showError = true;
    errorMessage = '';

    ngOnInit(): void {
        // populate fields
        if (this.value) {
            const response = convertSecsToMins(+this.value);

            Object.keys(response).forEach((key) => {
                const value = response[key];
                this.valueIndex[key] = value === 0 ? null : value;
            });
        }

        this.errorMessage = 'Minimum required time is ' + this.convertSecondsIntoMinutes();
    }

    /**
     * This function will convert seconds into minutes
     * @returns - Time as MM:SS
     */
    convertSecondsIntoMinutes(): string {
        const { min, sec } = convertSecsToMins(+this.minValue);
        return placeZerosOnLeft(min, 2) + ':' + placeZerosOnLeft(sec, 2);
    }

    onChange(field: string, event: MouseEvent | FocusEvent | any) {
        const k = event.keyCode;

        const isInteger = (k >= 48 && k <= 57) || (k >= 96 && k <= 105);
        const backspace = k === 8;

        if (!isInteger && !backspace) {
            event.stopPropagation();
            event.preventDefault();
            return;
        }

        let value = parseInt(event.target.value, 10);

        if (value < 0 || (field === 'sec' && value > 59)) {
            value = null;
        }

        this.valueIndex[field] = value;
        const total = this.recalculateTotal();
        this.showError = this.minValue > total;
        this.valueChanged.emit(total);
    }

    recalculateTotal() {
        const min = parseInt(this.valueIndex['min'], 10);
        const sec = parseInt(this.valueIndex['sec'], 10);

        if (min && sec) {
            return min * 60 + sec;
        }

        if (min) {
            return min * 60;
        }

        if (sec) {
            return sec;
        }

        return null;
    }
}
