import { ArraySortPipe } from './array-sort.pipe';
import { DifferenceInDaysPipe } from './calendar/differenceInDays.pipe';
import { isPastDatePipe } from './calendar/isPastDate.pipe';
import { IsSameDayPipe } from './calendar/isSameDay.pipe';
import { isTodayPipe } from './calendar/isToday.pipe';
import {
    FindPlannedDayPipe,
    GetDisciplinePlannedDayPipe,
    GetPlansForDayFromPlannedDataPipe,
    isTherapistAvailablePipe,
    SelectDisciplinePipe,
} from './data-extraction-pipes';
import { DisciplineAbbreviationPipe } from './discipline-abbreviation.pipe';
import { GetTherapyDisciplinePipe } from './get-therapy-discipline.pipe';
import { MinutesToHoursMinutesPipe } from './minutes-to-hours-minutes.pipe';
import { SecondsToHoursMinutesSecondsPipe } from './seconds-to-hours-minutes-seconds.pipe';
import { MinutesToHoursPipe } from './minutes-to-hours.pipe';
import { StringLengthPipe } from './string-length.pipe';
import { NamePipe } from './name.pipe';
import { IsEmptyObjectListPipe, IsEmptyObjectPipe } from './validations-pipes';
import { IsBeforePipe } from './calendar/index';
import { eocValidatorPipe } from './calendar/index';
import { SafeHtmlPipe } from './safeHtml.pipe';
import { TruncateTextPipe } from './truncate-text.pipe';
import { GetNonProjectedPipe } from '@app/pipes/charts/goals.pipe';
import { DateToDayPipe } from './date-to-day.pipe';
import { NInputsPipe } from './n-inputs.pipe';
import { DateFormatterPipe } from './date-formatter.pipe';
import { DisciplineShortFormPipe } from './discipline-short-form.pipe';

export const SHARED_PIPES = [
    DisciplineAbbreviationPipe,
    ArraySortPipe,
    isTodayPipe,
    isPastDatePipe,
    FindPlannedDayPipe,
    GetPlansForDayFromPlannedDataPipe,
    IsEmptyObjectPipe,
    SelectDisciplinePipe,
    DifferenceInDaysPipe,
    IsSameDayPipe,
    GetDisciplinePlannedDayPipe,
    IsEmptyObjectListPipe,
    GetTherapyDisciplinePipe,
    StringLengthPipe,
    NamePipe,
    isTherapistAvailablePipe,
    MinutesToHoursMinutesPipe,
    SecondsToHoursMinutesSecondsPipe,
    MinutesToHoursPipe,
    IsBeforePipe,
    eocValidatorPipe,
    SafeHtmlPipe,
    TruncateTextPipe,
    GetNonProjectedPipe,
    DateToDayPipe,
    NInputsPipe,
    DateFormatterPipe,
    DisciplineShortFormPipe
];

export { CheckTherapyAdmissionForDisciplinePipe } from './check-therapy-admission-for-discipline.pipe';
export { FilterTherapyAdmissionOnTPPipe } from './filter-therapy-admission-on-tp.pipe';
export { ArraySortPipe } from './array-sort.pipe';
export { GetTherapyDisciplinePipe } from './get-therapy-discipline.pipe';
export { GetAdmissionDatesPipe } from './get-admission-dates.pipe';
export { DisciplineAbbreviationPipe } from './discipline-abbreviation.pipe';
export { DisciplineShortFormPipe } from './discipline-short-form.pipe';
export * from './calendar/index';
export * from './data-extraction-pipes';
export * from './validations-pipes';
export { StringLengthPipe } from './string-length.pipe';
export { MinutesToHoursMinutesPipe } from './minutes-to-hours-minutes.pipe';
export { SecondsToHoursMinutesSecondsPipe } from './seconds-to-hours-minutes-seconds.pipe';
export { MinutesToHoursPipe } from './minutes-to-hours.pipe';
export { NamePipe } from './name.pipe';
export { SafeHtmlPipe } from './safeHtml.pipe';
export { TruncateTextPipe } from './truncate-text.pipe';
export { DateToDayPipe } from './date-to-day.pipe';
export { NInputsPipe } from './n-inputs.pipe';
export { DateFormatterPipe } from './date-formatter.pipe';
