import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { StoreService } from '@app/services';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-pp-mrn-bar',
  templateUrl: './pp-mrn-bar.component.html',
  styleUrls: ['./pp-mrn-bar.component.scss']
})
export class PpMrnBarComponent implements OnInit {
  @Input() data: string; 
  loadingObs: any;
  @ViewChild('mrnBar', { static: false }) mrnBar: ElementRef;
  widthToTransform = '';
  isSticky = false;
  private readonly onDestroy = new Subject<void>();
  
  constructor(  private storeService: StoreService, private route: ActivatedRoute,private render: Renderer2,private router: Router) { }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scroll, true);
    window.addEventListener('resize', this.resize, true);
    this.loadingObs = this.storeService.getLoadingState();
  }

  scroll = (event): void => {
    this.modifyMRNBar(event, false);
};

resize = (event): void => {
    this.modifyMRNBar(event, true);
};
// shrinking & sticking mrn-bar to top center
modifyMRNBar(event, reSized): void {
    const mrnPlaceholder = document.getElementById('mrn-placeholder');
    const scrollAble = document.getElementById('content');
    const barToShrink = document.getElementById('mrn-bar');

    if (barToShrink && this.router.url !== '/admission') {
        const parentWidth = barToShrink !== null ? this.render.parentNode(barToShrink).offsetWidth : 0;
        if (
            (scrollAble.scrollTop > 55 && !barToShrink.classList.contains('sticky')) ||
            (scrollAble.scrollTop > 55 && reSized)
        ) {
            this.render.addClass(barToShrink, 'sticky');
            // need to get width AFTER it settles down shrinking
            setTimeout(() => {
                const barWidth = barToShrink.offsetWidth;
                this.widthToTransform = 'translateX(' + ((parentWidth - barWidth) / 2).toString() + 'px)';
            }, 35);

            this.isSticky = true;
            this.render.addClass(mrnPlaceholder, 'activated');
        } else if (scrollAble.scrollTop < 55 && barToShrink.classList.contains('sticky')) {
            this.render.removeClass(barToShrink, 'sticky');
            setTimeout(() => {
                this.widthToTransform = '';
            }, 35);
            this.isSticky = false;

            this.render.removeClass(mrnPlaceholder, 'activated');
        }
    }
}

ngOnDestroy(): void {
    this.onDestroy.next();
    window.removeEventListener('scroll', this.scroll, true);
    window.removeEventListener('resize', this.scroll, true);
}

}
