<div [@openClose]="state" class="{{ data.type == 'notification' ? 'hidden' : ' toast-container ' + data.type }}">
    <div class="content ml-3" style="flex-grow: 1"><img /></div>
    <div class="content" style="flex-grow: 1">
        <div class="mt-3 toast-body">
            <h6 class="h6-semibold">{{ data.title }}</h6>
            <div style="width: 260px">
                <p>{{ data.body }}</p>
            </div>
        </div>
    </div>
    <div class="content">
        <div class="pr-3 mb-5 close-button">
            <span class="material-icons-outlined" style="cursor: pointer" (click)="close()"> close </span>
        </div>
    </div>
</div>
<div
    class=" {{ data.type != 'notification' ? 'hidden' : ' notification-popup-layout ' + data.type }}"
    [@openClose]="state"
>
    <div class="bg-secondary d-flex justify-content-between align-items-center w-100 px-2 py-2">
        <div class="d-flex align-items-center">
            <h4 class="body-small-semibold text-white mb-0">
                <span class="mr-2 mb-0"><img src="assets/icons/innova-icon.svg" width="20px" alt="" /></span> Innova
                Health
            </h4>
        </div>
        <img (click)="close()" src="assets/icons/close.svg" class="close" style="filter: brightness(10)" />
    </div>
    <div class="inner-msg-wrapper">
        <div class="inner-message mb-2">
            <span class="message">{{ data.title }}</span>
        </div>
        <div class="time-stamp-layout">
            <span class="text">{{ data.timestamp }}</span>
        </div>
    </div>
</div>
