import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StoreService } from '@app/services';
import {
    RowClassRules,
    ColDef,
    GridApi,
    GridReadyEvent,
    GridSizeChangedEvent,
    RowClickedEvent,
    FirstDataRenderedEvent,
    AgGridEvent,
} from 'ag-grid-community';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
})
export class TableComponent {
    private gridApi: GridApi;
    @Input() columnDefs: ColDef[] = [];
    @Input() rowData = [];
    @Input() pagination = false;
    @Input() paginationPageSize = 10;
    @Input() showCount = true;
    @Input() rowClassRules: RowClassRules;
    @Input() context: any;
    loadingObs: any;
    loadingOverlayMessage = 'No Rows To Show';
    noRowsOverlayMessage = 'No Rows To Show';
    emptyRowsOverlayMessage = '';
    @Output() onGridReady: EventEmitter<GridReadyEvent> = new EventEmitter();
    @Output() onRowClicked: EventEmitter<RowClickedEvent> = new EventEmitter();
    constructor(private storeService: StoreService) {
        this.loadingObs = this.storeService.getLoadingState();
    }
    public overlayLoadingTemplate =
        '<span  class="bg-transparent spinner-border text-primary spinner-border-sm mt-5 mr-1" style="padding: 10px;"></span>';
    public overlayNoRowsTemplate =
        '<span  class="bg-transparent body-regular text-secondary" style="padding: 10px;">' +
        this.noRowsOverlayMessage +
        '</span>';
    public overlayEmptyRowsTemplate =
        '<span  class="bg-transparent body-regular text-secondary" style="padding: 10px;">' +
        this.emptyRowsOverlayMessage +
        '</span>';

    gridReady(event: GridReadyEvent) {
        this.onGridReady.emit(event);
    }
    gridPopulated(event: FirstDataRenderedEvent) {
        event.api.sizeColumnsToFit();
    }
    gridSizeChanged(event: GridSizeChangedEvent) {
        event.api.sizeColumnsToFit();
    }

    rowClicked(event: RowClickedEvent) {
        this.onRowClicked.emit(event);
    }
    onSortChanged(e: AgGridEvent) {
        e.api.refreshCells();
    }
}
