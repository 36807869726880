<div class="input-group d-flex flex-row">
    <input
        readonly
        type="text"
        class="form-control"
        placeholder="MM/DD/YYYY"
        name="dp"
        ngbDatepicker
        [(ngModel)]="model"
        (click)="datepicker.toggle()"
        #datepicker="ngbDatepicker"
        (ngModelChange)="calendarDateChange($event)"
        ngModel
        style="background: transparent"
    />
    <div class="d-flex flex-row form-control calendar-Btn justify-content-center" (click)="datepicker.toggle()">
        <span class="material-icons-outlined"> calendar_today </span>
    </div>
</div>
