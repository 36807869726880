import { createReducer, on } from '@ngrx/store';
import { plannerTherapyAdmissionsChangedAction, plannerTherapyAdmissionsRemovedAction } from '@app/store/actions/index';
import { initialPlannerTherapyAdmissions } from '@app/store/selectors';

export const plannerTherapyAdmissionsReducer = createReducer(
    initialPlannerTherapyAdmissions,
    on(plannerTherapyAdmissionsChangedAction, (state, { therapyadmissions }) => [...therapyadmissions]),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    on(plannerTherapyAdmissionsRemovedAction, (state) => [...initialPlannerTherapyAdmissions])
);
