<div class="custom-control custom-switch text-center mt-2">
    <input
        type="checkbox"
        class="custom-control-input"
        id="{{ radioId }}"
        [disabled]="params.disabled"
        [(ngModel)]="switchValue"
        (click)="onSwitchValue($event)"
        (change)="emitChangedValue()"
    />
    <label
        class="custom-control-label"
        [ngClass]="{
            'custom-control-label-disabled-on': switchValue && params.disabled,
            'custom-control-label-disabled-off': !switchValue && params.disabled
        }"
        for="{{ radioId }}"
    ></label>
</div>
