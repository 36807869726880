import { Component, Input, OnInit } from '@angular/core';
import { CONSTANTS } from '@app/helpers/constants/documentation-report';
import { DocumentationReportService } from '@app/services';

@Component({
    selector: 'app-addendum-report',
    templateUrl: './addendum-report.component.html',
    styleUrls: ['./addendum-report.component.scss'],
})
export class AddendumReportComponent implements OnInit {
    @Input() reportId: string;
    @Input() report: any;
    @Input() key: string;
    @Input() therapistExists: boolean;
    isDataLoaded: Promise<boolean> | boolean;

    constructor(private docReportService: DocumentationReportService) { }

    async ngOnInit(): Promise<void> {
        if (this.key !== CONSTANTS.DESCRIPTION) {
            this.isDataLoaded = await this.docReportService.prepareDailyNoteData(this.reportId, this.key);
            if (this.isDataLoaded) {
                setTimeout(() => {
                    this.docReportService.countLoadedNotes();
                }, 0);
            }
        } else {
            const isExport = !!this.report
            this.isDataLoaded = await this.docReportService.prepareAddendumNoteData(this.reportId, isExport ? this.report : null);
            if (this.isDataLoaded) {
                setTimeout(() => {
                    this.docReportService.countLoadedNotes();
                }, 0);
            }
        }
    }
}
