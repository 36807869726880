import { Pipe, PipeTransform } from '@angular/core';
import { isPastDate } from '@app/helpers/utils/date.utils';

@Pipe({
    name: 'isPastDate',
    pure: true,
})
export class isPastDatePipe implements PipeTransform {
    transform(date: Date): boolean {
        return isPastDate(date);
    }
}
