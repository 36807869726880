<div
    (onafterprint)="afterPrint()"
    class="row mt-5 mb-5"
    *ngIf="patients"
    style="height: 100px; margin: 0 auto; width: 93%"
>
    <!-- <div class="col-9">
        <div><b> Name:</b> {{ doc.printData.therapist }}</div>
        <div><b> Discipline:</b> {{ doc.printData.discipline }}</div>
        <div><b> Note:</b> {{ doc.printData.type }}</div>
        <div><b> Due Date:</b> {{ doc.printData.dueDate | date: 'MM/dd/yy' }}</div>
        <div><b> Completion Date:</b> {{ doc.printData.completionDate }}</div>
    </div> -->
    <div class="col-3">
        <img src="../../../../assets/logo/logo.svg" />
    </div>
</div>
<table>
    <thead style="margin-top: 2vh !important">
        <th>Patient Name</th>
        <th>Appointment Time</th>
        <th>Room No.</th>
        <th>Discipline</th>
        <th>Therapist name</th>
        <th>Planned Minutes</th>
        <th>Doc Type</th>
    </thead>
    <tbody>
        <tr *ngFor="let patient of patients">
            <td class="text-secondary">{{ patient.name }}</td>
            <td class="text-secondary">{{ patient.appointmentStartTime }}</td>
            <td class="text-secondary">{{ patient.roomNumber || 'N/A' }}</td>
            <td class="text-secondary">{{ patient.therapyDiscipline }}</td>
            <td class="text-secondary">
                <span>{{ patient.therapistName }} </span>
                <span *ngIf="patient.therapist?.therapistType" class="text-secondary body-medium-semibold">
                    - {{ patient.therapist?.therapistType }}
                </span>
            </td>
            <td class="text-secondary">{{ patient.plannedTx }}</td>
            <td class="text-secondary">{{ patient.noteType }}</td>
        </tr>
    </tbody>
</table>
