import { Component, Inject } from '@angular/core';
import { DIALOG_DATA } from '@app/helpers/dialog-tokens';
import { DialogRef, ToasterService } from '@app/services';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-appointment-details-modal',
    templateUrl: './appointment-details-modal.component.html',
    styleUrls: ['./appointment-details-modal.component.scss'],
})
export class AppointmentDetailsModalComponent {
    content: any;
    defaultTime: NgbTimeStruct = { hour: 0, minute: 0, second: 0 };
    saveButtonText = 'Save';
    appointmentForm = new FormGroup({
        plannedTx: new FormControl(''),
        startTime: new FormControl(''),
        endTime: new FormControl({ value: '', disabled: true }),
        notes: new FormControl(''),
    });

    constructor(
        @Inject(DIALOG_DATA) private data,
        private dialogRef: DialogRef,
        private toasterService: ToasterService
    ) {
        this.content = data;
        if (data) {
            this.appointmentForm.patchValue({
                plannedTx: data.plannedTx || '',
                startTime: data.startTime ? this.convertDateToTime(data.startTime) : '',
                endTime: data.endTime ? this.convertDateToTime(data.endTime) : '',
                notes: data.notes || '',
            });
            if (this.appointmentForm.get('startTime').value || this.appointmentForm.get('notes').value) {
                this.saveButtonText = 'Update';
            } else {
                this.saveButtonText = 'Save';
            }
        }

        this.appointmentForm.get('startTime').valueChanges.subscribe((startTime) => {
            if (startTime) {
                this.calculateEndTime(startTime);
            } else {
                this.appointmentForm.get('endTime').setValue('');
            }
        });
    }

    calculateEndTime(startTime: NgbTimeStruct) {
        const plannedTx = this.appointmentForm.get('plannedTx').value;

        if (startTime && plannedTx) {
            let hour = startTime.hour;
            let minute = startTime.minute + plannedTx;

            // Handle minute overflow
            if (minute >= 60) {
                hour += Math.floor(minute / 60);
                minute = minute % 60;
            }
            // Set the calculated end time
            const endTime: NgbTimeStruct = { hour, minute, second: 0 };
            // Handle hour overflow (e.g., if endTime goes beyond 24 hours)
            if (endTime.hour >= 24) {
                endTime.hour = endTime.hour % 24;
            }
            // Update endTime form control
            this.appointmentForm.get('endTime').setValue(endTime);
        }
    }

    onClose(data?: any) {
        this.dialogRef.close(data);
    }
    addComment() {
        this.onClose(this.appointmentForm.value);
    }
    onSave() {
        if (this.appointmentForm.value.startTime) {
            const dateTime = this.convertTimeToDate(this.appointmentForm.value.startTime);
            this.appointmentForm.get('startTime').setValue(dateTime.toISOString(), { emitEvent: false });
        }
        if (this.appointmentForm.getRawValue().endTime) {
            const dateTime = this.convertTimeToDate(this.appointmentForm.getRawValue().endTime);
            this.appointmentForm.get('endTime').setValue(dateTime.toISOString());
        }
        this.dialogRef.close(this.appointmentForm.getRawValue());
        let toasterMessage = '';
        if (this.saveButtonText == 'Save') {
            toasterMessage = 'Timecard created successfully';
        } else {
            toasterMessage = 'Timecard updated successfully';
        }
        this.toasterService.show({
            title: 'Success',
            body: toasterMessage,
            type: 'success',
        });
    }

    convertTimeToDate(time: NgbTimeStruct, date?: Date): Date {
        if (!time) {
            throw new Error('Invalid time provided');
        }

        // Create a new Date object using the provided date
        const convertedDate = date ? new Date(date) : new Date(); // Use the provided date as the base

        // Set the hours and minutes from NgbTimeStruct
        convertedDate.setHours(time.hour);
        convertedDate.setMinutes(time.minute);
        convertedDate.setSeconds(0); // Optional: set seconds to 0

        return convertedDate;
    }

    convertDateToTime(date: Date): NgbTimeStruct {
        return { hour: new Date(date).getHours(), minute: new Date(date).getMinutes(), second: 0 };
    }
}
