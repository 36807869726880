<app-base-modal-card
    [showFooter]="false"
    [heading]="content.heading"
    [wrapperClass]="content.heading === 'Approve Hours' ? 'modal-time' : isOpen ? 'modal-mobile modal-mobile-open' : 'modal-mobile modal-mobile-close'"
    (close)="onClose(false)"
>
    <div body class="card-wrapping">
        <div class="d-flex justify-content-start align-items-center mt-2 mb-4">
            <div class="field-radiobutton">
                <p-radioButton name="Notmypatient" value="Not my patient" [(ngModel)]="selectedValue" inputId="city1"></p-radioButton>
                <label class="m-0" for="Notmypatient">Not my patient</label>
            </div>
            <div class="field-radiobutton2">
                <p-radioButton name="Other" value="Other" [(ngModel)]="selectedValue" inputId="city2"></p-radioButton>
                <label class="m-0" for="Other">Other</label>
            </div>
        </div>
        <form [formGroup]="reasonForm">
            <app-text-box *ngIf="selectedValue == 'Other'" placeholder="Add your reason" formControlName="reason"></app-text-box>
            <div *ngIf="reasonForm.get('reason').invalid && reasonForm.get('reason').touched && selectedValue == 'Other'" class="text-danger">
                Please provide a reason.
            </div>
            <div class="d-flex justify-content-end mt-5 mb-2">
                <app-button
                    [buttonClass]="'btn-secondary btn-outline innova-btn standard-btn innova-btn-md body-regular-light-bold-14'"
                    type="secondary"
                    text="{{ content.btn1Text }}"
                    (click)="closeAction()"
                >
                </app-button>
                <app-button
                    [buttonClass]="'btn-secondary innova-btn standard-btn innova-btn-md body-regular-light-bold-14 ml-2'"
                    type="secondary"
                    [disabled]="!selectedValue"
                    text="{{ content.btn2Text }}"
                    (click)="addReason()"
                >
                </app-button>
            </div>
        </form>
    </div>
</app-base-modal-card>
