<div [class]="'row card-padding-x ' + contentClass">
    <ng-container *ngFor="let control of controls">
        <!-- If not a merge -->
        <ng-container *ngIf="control.type !== 'merge'">
            <div
                [class]="'mt-3 mb-3 col-' + control.column || 'auto'"
                *ngIf="control.type === 'search-clinical-categories'"
            >
                <app-search-bar
                    type="multi"
                    placeholder="Search code"
                    (searchEvent)="clinicalCategoriesSearch($event)"
                    (removeEvent)="removeSelectedItem($event); isDone === false ? redrawRows() : null"
                    [selectedData]="selectedData"
                    [loading]="loading"
                    [disabled]="isDone"
                    objectKey="code"
                ></app-search-bar>
            </div>
            <div [class]="'mt-3 mb-3 col-' + control.column || 'auto'" *ngIf="control.type === 'suggestion-box'">
                <app-text-box
                    *ngIf="control.type === 'suggestion-box'"
                    [isSuggestionBox]="control.isSuggestionBox"
                    [suggestionsList]="control.suggestionsList"
                    [isSaveButton]="true"
                    [label]="control.label"
                    (emitData)="control.onChange($event); setData($event, control.key); emitData()"
                    [class]="control.class"
                    [backgroundClass]="control.backgroundClass"
                    [placeholder]="control.placeholder"
                    [highlightTexts]="['_', { from: '[', to: ']' }]"
                    (isFilled)="setTextBoxFilled($event)"
                    [suggestionBoxValue]="dataForEdit?.description"
                ></app-text-box>
            </div>
            <!-- <div
                [class]="'mt-3 mb-3 col-' + control.column || 'auto'"
                *ngIf="control.type !== 'suggestion-box'"
            >
                <app-switch
                    [(ngModel)]="data[control.key]"
                    (ngModelChange)="setData($event, control.key); emitData()"
                    [label]="control.label"
                    *ngIf="control.type === 'switch'"
                ></app-switch>
                <app-select
                    *ngIf="control.type === 'select'"
                    [(ngModel)]="data[control.key]"
                    (ngModelChange)="setData($event, control.key); emitData()"
                    [options]="control.selectOptions"
                    [label]="control.label"
                    [class]="control.class"
                    [backgroundClass]="control.backgroundClass"
                ></app-select>
            </div> -->
        </ng-container>
        <!-- If merge -->
        <ng-container *ngIf="control.type === 'merge'">
            <div
                [class]="control.column ? 'col-' + control.column + ' ' + control.class : control.class"
                [style]="control.style"
            >
                <div class="row">
                    <div *ngIf="control.label !== ''" [class]="'form-group ' + control.labelClass">
                        <label> {{ control.label }} </label>
                    </div>
                </div>
                <div
                    [class]="control.mergeClass ? 'row ' + control.mergeClass : 'row'"
                    [style]="control.mergeStyle || ''"
                >
                    <ng-container *ngFor="let item of control.merge">
                        <div
                            [class]="
                                item.column
                                    ? 'mt-3 mb-3 col-' + item.column + ' ' + item.wrapperClass
                                    : 'mt-3 mb-3 ' + item.wrapperClass
                            "
                            [style]="item.wrapperStyle"
                            *ngIf="item.type !== 'suggestion-box'"
                        >
                            <input
                                type="{{ item.validationType || text }}"
                                *ngIf="item.type === 'text'"
                                [(ngModel)]="data[control.key][item.key]"
                                (ngModelChange)="setData($event, item.key, control.key); emitData()"
                                [options]="item.selectOptions"
                                [placeholder]="item.placeholder"
                                [class]="item.class + ' ' + item.backgroundClass"
                                [imask]="{ mask: item.regex || '' }"
                            />
                            <div
                                *ngIf="item.type === 'label'"
                                [class]="'form-group ' + item.class + ' ' + item.backgroundClass"
                            >
                                <label> {{ item.label }} </label>
                            </div>
                            <!-- app-select: start -->
                            <ng-container *ngIf="item.disableToggle !== undefined">
                                <app-select
                                    *ngIf="item.type === 'select'"
                                    [(ngModel)]="data[control.key][item.key]"
                                    (ngModelChange)="setData($event, item.key, control.key); emitData()"
                                    [options]="item.selectOptions"
                                    [label]="item.label"
                                    [placeholder]="item.placeholder"
                                    [class]="item.class"
                                    [backgroundClass]="item.backgroundClass"
                                    [disabled]="checkDisabled(item.disableToggle, control.key)"
                                ></app-select>
                            </ng-container>
                            <ng-container *ngIf="item.disableToggle === undefined">
                                <app-select
                                    *ngIf="item.type === 'select'"
                                    [(ngModel)]="data[control.key][item.key]"
                                    (ngModelChange)="setData($event, item.key, control.key); emitData()"
                                    [options]="item.selectOptions"
                                    [label]="item.label"
                                    [placeholder]="item.placeholder"
                                    [class]="item.class"
                                    [backgroundClass]="item.backgroundClass"
                                ></app-select>
                            </ng-container>
                            <!-- app-select: end -->
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
