export function isDateInRange(date: Date, minDate: Date, maxDate: Date) {
    if (
        !date || !minDate || !maxDate ||
        new Date(date).toISOString() > new Date(maxDate).toISOString() ||
        new Date(date).toISOString() < new Date(minDate).toISOString()
    ) {
        return false;
    }
    return true;
}

/**
 * function will check if time of date is in range
 * @param {Date} checkTime - time to check in range
 * @param {Date} startTime - start time of the range
 * @param {Date} endTime - end time of range
 */
export function isTimeInRange(checkDate, startDate, endDate) {
    try {
        const checkTime = new Date(checkDate);
        const startTime = new Date(startDate);
        const endTime = new Date(endDate);
    
        const checkHours = checkTime.getHours();
        const checkMinutes = checkTime.getMinutes();
        
        const startHours = startTime.getHours();
        const startMinutes = startTime.getMinutes();
        
        const endHours = endTime.getHours();
        const endMinutes = endTime.getMinutes();
    
        const checkTotalMinutes = checkHours * 60 + checkMinutes;
        const startTotalMinutes = startHours * 60 + startMinutes;
        const endTotalMinutes = endHours * 60 + endMinutes;
    
        return checkTotalMinutes >= startTotalMinutes && checkTotalMinutes <= endTotalMinutes;
    } catch (error) {
        return false;
    }
}