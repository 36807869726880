import { InjectionToken } from '@angular/core';

export class ToastData {
    title: string;
    body: string;
    type: 'success' | 'warning' | 'info' | 'error' | 'notification';
    timestamp?: string;
}

export class SideNavData<T> {
    value: T;
}

export interface ToastConfig {
    position: {
        top: number;
        right: number;
    };
}

export const defaultToastConfig: ToastConfig = {
    position: {
        top: 20,
        right: 20,
    },
};

export const TOAST_CONFIG_TOKEN = new InjectionToken('toast-config');
