import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService, DialogRef, ToasterService, FacilityManagementService } from '@app/services';

@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
    feedbackTypes = [
        { id: 'Suggestion', name: 'I have an idea or suggestion' },
        { id: 'Problem', name: 'I found a problem' },
        { id: 'Help', name: 'I need help' },
        { id: 'Awesomeness', name: 'This is awesome because...' },
    ];
    feedbackHeading = '';
    state = 'open';
    formFeedback: FormGroup;
    account: any;

    constructor(
        private ref: DialogRef,
        private fb: FormBuilder,
        private AuthService: AuthService,
        private toasterService: ToasterService,
        private router: Router,
        private facility: FacilityManagementService
    ) {
        this.AuthService.account.subscribe((x) => (this.account = x));
    }

    ngOnInit(): void {
        this.formFeedback = this.fb.group({
            feedbackType: ['', Validators.required],
            feedback: ['', Validators.required],
        });
    }

    onClose() {
        this.ref.close();
    }
    onSave() {
        this.onClose();
    }

    sendFeedback(formValues) {
        const feedbackObj = {
            attachments: [
                {
                    color: this.assignFeedbackColors(formValues.feedbackType),
                    blocks: [
                        {
                            type: 'header',
                            text: {
                                type: 'plain_text',
                                text: this.account.user.firstName + ', ' + this.account.user.lastName,
                            },
                        },
                        {
                            type: 'section',
                            fields: [
                                {
                                    type: 'mrkdwn',
                                    text: `*User Email:*\n${this.account.user.email}`,
                                },
                                {
                                    type: 'mrkdwn',
                                    text: `*Feedback Type:*\n${formValues.feedbackType}`,
                                },
                                {
                                    type: 'mrkdwn',
                                    text: `*URL:*\n${this.router.routerState.snapshot.url}`,
                                },
                                {
                                    type: 'mrkdwn',
                                    text: `*Role:*\n${this.account.user?.userRoles?.[0]?.facilities?.[0]?.roles?.[0]?.baseRole}`,
                                },
                                {
                                    type: 'mrkdwn',
                                    text: `*Facility:*\n${this.facility._currentFacility.facilityName}`,
                                },
                            ],
                        },
                        {
                            type: 'section',
                            text: {
                                type: 'mrkdwn',
                                text: `*${this.feedbackHeading}:*\n${formValues.feedback}`,
                            },
                        },
                    ],
                },
            ],
        };
        this.AuthService.submitFeedback(feedbackObj).subscribe({
            next: () => {
                this.toasterService.show({
                    title: 'Feedback Submitted!',
                    body: 'Thank you for your valuable feedback',
                    type: 'success',
                });
                if (formValues.feedbackType != 'Awesomeness') {
                    this.createFreshDeskTicket(formValues);
                }

                this.formFeedback.reset();
                this.onClose();
            },
            error: (err) => {
                this.toasterService.show({ title: 'Error', body: err, type: 'error'})
            }
        });
    }
    createFreshDeskTicket(formValues) {
        let type = '';
        let priority = 1;

        if (formValues.feedbackType == 'Suggestion') {
            type = 'Feature Request';
        } else if (formValues.feedbackType == 'Problem') {
            type = 'Problem';
            priority = 2;
        } else if (formValues.feedbackType == 'Help') {
            type = 'Question';
            priority = 2;
        }

        // const roles = this.account.user.roles.map((item) => item.name);

        const body = {
            name: this.account.user.lastName + ', ' + this.account.user.firstName,
            type,
            custom_fields: {
                // cf_user_title: roles.join(', '),  // Commented out because of the Fresh Desk schema does not accept this field.
                cf_facility: this.facility._currentFacility.facilityName,
            },
            description: formValues.feedback + ` <br> <strong>URL:</strong> ${window.location.href}`,
            source: 9,
            subject:
                formValues.feedback.length > 100
                    ? formValues.feedback.toString().substring(0, 100) + '...'
                    : formValues.feedback,
            email: this.account.user.email,
            priority,
            status: 2,
        };

        this.AuthService.createFreshDeskTicket(body).subscribe(() => {
            console.log('Ticket created');
        });
    }
    assignFeedbackColors(feedbackType: string) {
        switch (feedbackType) {
            case 'Suggestion': {
                this.feedbackHeading = 'I have an idea or suggestion';
                return '#fffe00';
            }
            case 'Problem': {
                this.feedbackHeading = 'I found a problem';
                return '#f00';
            }
            case 'Help': {
                this.feedbackHeading = 'I need help';
                return '#797A7D';
            }
            case 'Awesomeness': {
                this.feedbackHeading = 'This is awesome because...';
                return '#0f0';
            }
        }
    }
}
