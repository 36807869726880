import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FacilityManagementService } from '@app/services';

import { environment } from '@environments/environment';

@Injectable()
export class FacilityInterceptor implements HttpInterceptor {
    constructor(private facilityManagementService: FacilityManagementService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isApiUrl = request.url.startsWith(environment.apiUrl);
        if (isApiUrl) {
            const facilityId = this.facilityManagementService?._currentFacility?.id
                ? this.facilityManagementService?._currentFacility?.id : ''
            const facilityIds = request.headers.get('facility-ids')
            request = request.clone({
                setHeaders: {
                    'x-facility-id': facilityId,
                    'facility-ids': facilityIds ? facilityIds : facilityId
                },
            });
        }

        return next.handle(request);
    }
}
