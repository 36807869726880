import { Pipe, PipeTransform } from '@angular/core';
import { isEmptyObjectList } from '@app/helpers/utils/data.utils';
import { isEmpty } from 'lodash';

@Pipe({
    name: 'isEmptyObject',
    pure: true,
})
export class IsEmptyObjectPipe implements PipeTransform {
    transform(obj): boolean {
        return isEmpty(obj);
    }
}

@Pipe({
    name: 'isEmptyObjectList',
    pure: true,
})
export class IsEmptyObjectListPipe implements PipeTransform {
    transform(data: any[]) {
        return isEmptyObjectList(data);
    }
}
