import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DIALOG_DATA } from '@app/helpers/dialog-tokens';
import { DailyNote, DetailedCptCodes, TherapyAdmission } from '@app/interfaces';
import { DialogRef, DailyNotesService, DocumentationService, TherapyAdmissionService, StoreService } from '@app/services';
import { Subscription } from 'rxjs';
import { ToasterService } from '@app/services';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { Doc_Notes } from '@app/interfaces/documentation';

@Component({
    selector: 'app-add-goals',
    templateUrl: './add-goals.component.html',
    styleUrls: ['./add-goals.component.scss'],
})
export class AddGoalsComponent implements OnInit, OnDestroy {
    heading = '';
    goals = [];
    goalsSelected = false;
    saveBtnStatus = false;
    selectedProcedures = [];
    currentCptCode = '';
    masterCheckbox = false;
    savingGoals = false;
    private subscriptions: Subscription[] = [];
    private currentTA: TherapyAdmission;
    private currentDN: DailyNote;
    private currentAdmissions: TherapyAdmission[];
    private routeData: { doc: string; discipline: string } = {
        doc: '',
        discipline: '',
    };

    constructor(
        @Inject(DIALOG_DATA) private data,
        private ref: DialogRef,
        private route: ActivatedRoute,
        private dailyNotesService: DailyNotesService,
        private toaster: ToasterService,
        private store: Store<{
            therapyAdmission: TherapyAdmission[];
        }>,
        private therapyService: TherapyAdmissionService,
        private documentationService: DocumentationService,
        private therapyAdmissionService: TherapyAdmissionService,
        private storeService: StoreService,
    ) {
        this.route.queryParamMap.subscribe({
            next: (params) => {
                this.routeData.doc = params.get('doc');
                this.routeData.discipline = params.get('discipline');
            },
        });

        this.subscriptions.push(
            this.store.select('therapyAdmission').subscribe((admissions: TherapyAdmission[]) => {
                if (!admissions || !admissions.length) {
                    return;
                }

                this.currentAdmissions = admissions;
                this.currentTA = this.therapyService.initCurrentTherapyAdmissionByIdQueryString(admissions);
                this.currentDN = this.currentTA?.documentation.dailyNote?.find((elem) => elem.id === this.routeData.doc);

                this.loadGoals();
            })
        );
    }

    ngOnInit(): void {
        this.heading = this.data.heading;
        this.currentCptCode = this.data.cptCode;
        // this.options = this.data.options ?? ['Progress', 'UPOC', 'Re-Cert', 'Discharge', 'Addendum'];
    }

    loadGoals(): void {
        if (this.currentDN.objectiveAssessment?.goals?.length) {
            const goals = this.currentDN.objectiveAssessment.goals;

            this.goals = goals
                .filter(
                    (goal) => goal?.status?.description !== 'Achieved' && goal?.status?.description !== 'Discontinued'
                )
                .map((item: any) => {
                    let mappedGoal;
                    const goalFound = this.data.goal.find((ltg) => ltg === item.goal);
                    if (goalFound) {
                        mappedGoal = {
                            isSelected: true,
                        };
                    } else {
                        mappedGoal = {
                            isSelected: false,
                        };
                    }
                    mappedGoal.title = item.goal;
                    mappedGoal.clof = item.clof.levelOfFunction;
                    mappedGoal.plof = item.plof.levelOfFunction;
                    mappedGoal.stg = [];

                    // Will always have stg at no more than first level of obj
                    if (item.stgs && item.stgs.length) {
                        mappedGoal.stg = item.stgs
                            .filter(
                                (goal) =>
                                    goal?.status?.description !== 'Achieved' &&
                                    goal?.status?.description !== 'Discontinued'
                            )
                            .map((stage) => {
                                const goalFound = this.data.goal.find((ltg) => ltg === stage.goal);
                                if (goalFound) {
                                    return {
                                        title: stage.goal,
                                        clof: stage.clof.levelOfFunction,
                                        plof: stage.plof.levelOfFunction,

                                        isSelected: true,
                                    };
                                } else {
                                    return {
                                        title: stage.goal,
                                        clof: stage.clof.levelOfFunction,
                                        plof: stage.plof.levelOfFunction,

                                        isSelected: false,
                                    };
                                }
                            });
                    }

                    return mappedGoal;
                });
        }
    }

    onClose() {
        this.ref.close();
    }
    onSave() {
        console.log('Saving...');
        this.onClose();
    }

    goalsChanged(item: any, event: boolean) {
        item.isSelected = event;
        this.evaluateBtnState();
        this.goalsSelected = !!this.getSelectedGoals().length;
    }

    evaluateBtnState() {
        this.saveBtnStatus = !!(this.getSelectedGoals().length + 1) && !this.savingGoals;
    }

    getSelectedGoals = () => {
        const selectedGoals = [];

        this.goals.forEach((elem) => {
            if (elem.isSelected) {
                selectedGoals.push({
                    goal: elem.title,
                    clof: elem.clof,
                    plof: elem.plof,
                    type: 'LTG',
                });
            }

            if (elem.stg.length) {
                elem.stg.forEach((elem) => {
                    if (elem.isSelected) {
                        selectedGoals.push({
                            goal: elem.title,
                            clof: elem.clof,
                            plof: elem.plof,
                            type: 'STG',
                        });
                    }
                });
            }
        });
        return selectedGoals;
    };

    saveClicked(): Promise<void> {
        this.savingGoals = true;
        const payload: any = cloneDeep(this.currentDN);
        payload.documentation_id = this.currentTA.documentation.id;

        if (!payload) {
            console.log('ERR: unable to get current Daily Note');
            return;
        }

        const foundIndex = payload.objectiveAssessment.detailedCptCodes.findIndex(
            (record: DetailedCptCodes) => record.code === this.currentCptCode
        );
        this.evaluateBtnState();

        const newRecord = {
            code: this.currentCptCode,
            description: this.data.description,
            exercises: payload.objectiveAssessment.detailedCptCodes[foundIndex].exercises,
            goals: this.getSelectedGoals(),
            cptMinutes: payload.objectiveAssessment.detailedCptCodes[foundIndex].cptMinutes,
            justification: payload.objectiveAssessment.detailedCptCodes[foundIndex].justification,
            isTeleHealthEligible: payload.objectiveAssessment.detailedCptCodes[foundIndex].isTeleHealthEligible,
            isMds: payload.objectiveAssessment.detailedCptCodes[foundIndex].isMds,
        };

        payload.objectiveAssessment.detailedCptCodes[foundIndex] = newRecord;
        const currentTA = JSON.parse(JSON.stringify(this.currentTA));
        currentTA.documentation.dailyNote[this.data.dailyNoteIndex].objectiveAssessment.detailedCptCodes =
            payload['objectiveAssessment']['detailedCptCodes'];
        this.dailyNotesService.prepareDataForEdit(
            'objectiveAssessment',
            'detailedCptCodes',
            payload.objectiveAssessment.detailedCptCodes,
            'array',
            this.data.allTherapyAdmissions,
            currentTA,
            this.data.dailyNoteIndex
        );
        if (!this.data.isEditMode) {
            this.documentationService.updateDocumentationV2(currentTA, Doc_Notes.Daily_note).then((result) => {
                if (!result) {
                    return;
                }
                this.toaster.show({
                    title: 'Successful',
                    body: 'Goals updated to CPT ' + this.currentCptCode,
                    type: 'success',
                });
                this.onClose();
            }, () => {
                this.therapyAdmissionService
                    .getAdmissionOnFacilities(this.currentTA.facilityAdmission)
                    .subscribe((admissions) => {
                        this.storeService.setTherapyAdmission(admissions);
                    });
            });
        } else {
            this.onClose();
        }
    }
    areAllCheckboxChecked(): boolean {
        return this.goals.every((item) => item.isSelected);
    }
    toggleAllCheckbox() {
        this.masterCheckbox = this.areAllCheckboxChecked() ? true : false;
        this.masterCheckbox = !this.masterCheckbox;
        this.goals.forEach((checkbox) => {
            checkbox.isSelected = this.masterCheckbox;
            checkbox?.stg?.forEach((stg) => {
                stg.isSelected = this.masterCheckbox;
            });
        });
        this.evaluateBtnState();
    }

    ngOnDestroy(): void {
        this.subscriptions.map((sub: Subscription) => sub.unsubscribe());
    }
}
