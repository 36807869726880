/* eslint-disable @typescript-eslint/no-explicit-any */
import { inject, Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { Router } from '@angular/router';
import { ToasterService } from '@app/services';

@Injectable()
export class VersionInterceptor implements HttpInterceptor {
    private router = inject(Router); // injecting service without constructor
    private toaster = inject(ToasterService);

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap((event: HttpResponse<any>) => {
                if (event instanceof HttpResponse && event?.url?.includes('/api/')) {
                    const currentVersion = localStorage.getItem('version');
                    const backendVersion = event.headers.get('version');
                    const firstTimeUser = localStorage.getItem('logoutEvent') === null;
                    if (firstTimeUser) {
                        localStorage.setItem('version', backendVersion);
                    }
                    if (!firstTimeUser && currentVersion !== backendVersion) {
                        localStorage.setItem('version', backendVersion);
                        window.location.href = window.location.href + `?noCache=${new Date().getTime()}`;
                    }
                }
            })
        );
    }
}
