<app-base-modal-card
    [heading]="heading"
    [isHeaderEditable]="true"
    placeholder="Enter Title Here"
    (headingEmitter)="headerUpdate($event)"
    wrapperClass="modal-medium"
    (close)="onClose()"
>
    <div body>
        <div class="row">
            <div class="col-6">
                <app-input
                    [hideLabel]="false"
                    placeholder="Enter Test Value"
                    type="text"
                    label="Score"
                    [required]="true"
                    [(ngModel)]="score"
                >
                </app-input>
            </div>
            <div class="col-6">
                <app-input
                    [hideLabel]="false"
                    placeholder="Type Here"
                    type="text"
                    label="Interpretation"
                    [(ngModel)]="interpretation"
                >
                </app-input>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <app-text-box
                    placeholder="Type here"
                    backgroundClass="bg-body-background"
                    label="Notes/Comments"
                    [(ngModel)]="notes"
                >
                </app-text-box>
            </div>
        </div>
    </div>

    <div footer class="d-flex flex-row justify-content-end w-100">
        <button
            [disabled]="!heading || !score"
            class="btn btn-secondary px-4 py-1 rounded-pill d-flex flex-row"
            (click)="saveCustomTest()"
        >
            {{ toUpdate ? 'Update' : 'Save' }}
            <span *ngIf="loader" class="ml-2 mt-1 spinner-border spinner-border-sm"></span>
        </button>
    </div>
</app-base-modal-card>
