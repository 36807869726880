<ng-template #printTemplate>
    <div class="mb-4">
        <app-view-mode-table
            [tableTitle]="profileInformation.title"
            [tableCol]="profileInformation.cols"
            [tableData]="profileInformation.data"
            [pagination]="false"
            [showCount]="false"
            [headerFull]="true"
        ></app-view-mode-table>
    </div>

    <div class="mb-4">
        <app-view-mode-table
            [tableTitle]="assignedFacilities.title"
            [tableCol]="assignedFacilities.cols"
            [tableData]="assignedFacilities.data"
            [pagination]="false"
            [showCount]="false"
            [headerFull]="true"
        ></app-view-mode-table>
    </div>

    <div class="mb-4" *ngIf="activityRates?.length">
        <div class="custom-card">
            <div class="header">
                <p>Activity Rates</p>
            </div>
            <div class="body card-padding">
                <div *ngFor="let activityRate of activityRates; let activityRateIndex = index;">
                <hr *ngIf="activityRateIndex > 0">
                    <div class="row">
                        <div class="col-12 text-secondary mb-3">
                            <strong class="d-block body-medium-bold">Facilities</strong>
                            <div>
                                <span *ngFor="let facility of activityRate.facilities">
                                    <span *ngIf="facilityExists(facility)" class="badge badge-pill badge-grey p-2 px-3 rounded-pill mr-3 mt-1 font-poppins-400-12
                                    d-inline-flex flex-row align-items-center justify-content-between pill text-secondary">
                                        <span> {{ getFacilityName(facility) }}</span>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4 text-secondary mb-3">
                            <strong class="d-block body-medium-bold">Labor Time</strong>
                            <span>{{'$' + (activityRate?.laborTime ?? 0) + ' per hour'}}</span>
                        </div>
                        <div class="col-4 text-secondary mb-3">
                            <strong class="d-block body-medium-bold">Travel Time</strong>
                            <span>{{'$' + (activityRate?.travelTime ?? 0) + ' per hour'}}</span>
                        </div>
                        <div class="col-4 text-secondary mb-3">
                            <strong class="d-block body-medium-bold">Administrative Time</strong>
                            <span>{{'$' + (activityRate?.administrativeTime ?? 0) + ' per hour'}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="isTherapist">
        <div class="mb-4">
            <div class="custom-card">
                <div class="header">
                    <p>Therapist Details</p>
                </div>
                <div class="body card-padding">
                    <div class="row">
                        <div class="col-4 text-secondary mb-3">
                            <strong class="d-block body-medium-bold">Discipline</strong>
                            <span>{{ therapistDetails?.discipline }}</span>
                        </div>
                        <div class="col-4 text-secondary mb-3">
                            <strong class="d-block body-medium-bold">Therapist Type</strong>
                            <span>{{ therapistDetails?.therapistType }}</span>
                        </div>
                        <div class="col-4 text-secondary mb-3">
                            <strong class="d-block body-medium-bold">Signature Credentials</strong>
                            <span>{{ therapistDetails?.signCredentials }}</span>
                        </div>
                        <div class="col-4 text-secondary">
                            <strong class="d-block body-medium-bold">NPI</strong>
                            <span>{{ therapistDetails?.NPI }}</span>
                        </div>
                        <div class="col-4 text-secondary">
                            <strong class="d-block body-medium-bold">Labor Type</strong>
                            <span>{{ therapistDetails?.laborType }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mb-4">
            <app-view-mode-table
                [tableTitle]="licenseDetails.title"
                [tableCol]="licenseDetails.cols"
                [tableData]="licensures"
                [pagination]="false"
                [showCount]="false"
                [headerFull]="true"
            ></app-view-mode-table>
        </div>
    </div>

    <div *ngIf="isPhysician">
        <app-card class="mb-4">
            <div class="header">
                <p>Physician Details</p>
            </div>
            <div class="body card-padding">
                <div class="row">
                    <div class="col-4 text-secondary mb-3">
                        <strong class="d-block body-medium-bold">NPI</strong>
                        <span>{{ physicianDetails?.NPI }}</span>
                    </div>
                    <div class="col-4 text-secondary mb-3">
                        <strong class="d-block body-medium-bold">Credentials</strong>
                        <span>{{ physicianDetails?.credentials }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 text-secondary mb-3">
                        <strong class="d-block body-medium-bold">License No.</strong>
                        <span>{{ physicianDetails?.licenseNo }}</span>
                    </div>
                    <div class="col-4 text-secondary mb-3">
                        <strong class="d-block body-medium-bold">State</strong>
                        <span>{{ physicianDetails?.state }}</span>
                    </div>
                    <div class="col-4 text-secondary mb-3">
                        <strong class="d-block body-medium-bold">Expiration Date</strong>
                        <span>{{ physicianDetails?.licenseExpirationDate | date }}</span>
                    </div>
                </div>
            </div>
        </app-card>

        <app-card class="mb-4">
            <div class="header d-flex justify-content-between">
                <p>Contact</p>
            </div>
            <div class="body card-padding">
                <div class="row">
                    <div class="col-4 text-secondary mb-3">
                        <strong class="d-block body-medium-bold">Phone Number</strong>
                        <span>{{ physicianDetails?.phoneNumber }}</span>
                    </div>
                    <div class="col-4 text-secondary mb-3">
                        <strong class="d-block body-medium-bold">Mobile Number</strong>
                        <span>{{ physicianDetails?.mobileNumber }}</span>
                    </div>
                </div>
            </div>
        </app-card>

        <app-card class="mb-4">
            <div class="header d-flex justify-content-between">
                <p>Delivery Method</p>
            </div>
            <div class="body card-padding">
                <div class="row">
                    <div class="col-sm-6">
                        <input
                            type="checkbox"
                            [checked]="physicianDetails?.isElectronicSignature"
                            [disabled]="true"
                            id="electronicSignature"
                            class="mr-2 checkbox"
                        />
                        <label class="text-secondary m-0" for="electronicSignature">Electronic Signature</label>
                    </div>
                </div>
            </div>
        </app-card>

        <app-card class="mb-4">
            <div class="header d-flex justify-content-between">
                <p>Notifications</p>
            </div>
            <div class="body card-padding">
                <div class="row d-flex flex-row align-items-center">
                    <div class="col-sm-3">
                        <label class="text-secondary m-0">Notify via Email</label>
                    </div>
                    <div class="col-sm-3">
                        <app-switch
                            class="d-inline-block align-middle"
                            controlContainerClass="h-auto"
                            [(ngModel)]="physicianDetails.notifyViaEmail"
                            [disabled]="true"
                        ></app-switch>
                    </div>
                    <div class="col-sm-4">
                        <div class="text-secondary mb-3">
                            <strong class="d-block body-medium-bold">Email</strong>
                            <span>{{ profileInformation?.data?.[0]?.email }}</span>
                        </div>
                    </div>
                </div>
                
            </div>
        </app-card>
    </div>
</ng-template>

<div *ngIf="showForm" class="no-print">
    <div class="d-flex flex-row justify-content-between align-items-center mb-3 no-print">
        <ng-container *ngIf="!isProfileUserSuperAdmin">
            <app-go-back title="View User" [path]="'/admin/accounts'"></app-go-back>
        </ng-container>
        <ng-container *ngIf="isProfileUserSuperAdmin">
            <div class="d-flex justify-content-between">
                <app-go-back title="View User" [path]="'/admin/accounts'"></app-go-back>
                <span
                    class="ml-3 badge p-2 px-3 rounded-pill text-secondary border d-inline-block align-middle border-secondary"
                    >Super Admin</span
                >
            </div>
        </ng-container>
        <div ngbDropdown class="d-inline-block">
            <button
                class="innova-btn btn-secondary btn-sm d-flex flex-row justify-content-center align-items-center p-2"
                id="dd-toggle"
                placement="left"
                ngbDropdownToggle
            >
                <span class="material-symbols-outlined"> menu </span>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dd-small">
                <button ngbDropdownItem class="nav-item nav-link" *ngIf="isAllowedToEdit" (click)="editUser()">
                    Edit
                </button>
                <button ngbDropdownItem [disabled]="false" class="nav-item nav-link" (click)="print()">Print</button>
                <button ngbDropdownItem class="nav-item nav-link" *ngIf="isAllowedToEdit" (click)="changePassword()">
                    Change Password
                </button>
            </div>
        </div>
    </div>
    <div [ngTemplateOutlet]="printTemplate"></div>
</div>

<div class="print-only print-width" *ngIf="applyPrintWidth">
    <div [ngTemplateOutlet]="printTemplate"></div>
</div>
