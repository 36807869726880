import { cloneDeep, isEmpty } from 'lodash';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { isDropAblePlan } from './validation.utils';
import { IPlannerPlannedResult } from '@app/interfaces';
/**
 * This utility function checks an object if all the values of an object are null or an empty string and then return a boolean value.
 * Example: isObjectNullValued(ObjectToCheck, ArrayOfDefaultValuesToIgnore);
 * Result: Boolean Value (True | False)
 */

export const isObjectNullValued = (
    object: any /* Object that is to check */,
    defaultValues: any[] /* Array of values to ignore if any */
) => {
    return Object.values(object).every((value) => value === null || value === '' || defaultValues.includes(value));
};

export const separatePlannedUnplanned = (data: any[]) => {
    data = cloneDeep(data);
    let unplanned: any[] = [];
    let planned: any[] = [];
    for (const item of data) {
        if (!item.patient) {
            continue;
        }
        for (const _therapyAdmission of item.therapyAdmissions) {
            // If any discipline is not planned => Unplanned
            if (!hasPlannedTherapyOrEvalOnly(_therapyAdmission)) {
                pushValid(unplanned, item);
                continue;
            }
            // If planned but recertification has done => Unplanned
            if (checkRecertificationPatients(_therapyAdmission)) {
                pushValid(unplanned, item);
                continue;
            }
            // Planned state
            pushValid(planned, item);
        }
    }
    unplanned = getSortedBaseOnConflict(unplanned);
    planned = getSortedBaseOnConflict(planned);
    return { unplanned, planned };
};

const hasPlannedTherapyOrEvalOnly = (_therapyAdmission) => {
    return (
        (_therapyAdmission.planTherapy && Object.keys(_therapyAdmission.planTherapy).length > 0) ||
        (_therapyAdmission && _therapyAdmission.documentation.evaluation.evaluationType.evalOnly)
    );
}

/**
 * It will filter out those patients that are planned but recertification
 * has done for them (need to re-plan) so showing it in unplanned section
 */
const checkRecertificationPatients = (admission) => {
    if (admission.hasPlanConflict && admission.documentation?.updatedPOC?.isRecert) {
        admission.isRecert = true;
        return true;
    }
}

export const orderPlannerSearch = (data: any[]) => {
    const _data: any[] = [];
    for (const item of data) {
        pushValid(_data, getSingleUserData(data, item.facilityAdmission?.patient._id));
    }
    return _data;
};

// Push valid data
const pushValid = (data, newItem) => {
    let flag = false;
    for (const item of data) {
        if (item._id === newItem._id) {
            flag = true;
            break;
        }
    }
    if (!flag) {
        data.push(cloneDeep(newItem));
    }
};

// Function to get therapy admission against a specific user _id
export const getSingleUserData = (data: any[], id: string) => {
    // Deep copy
    data = cloneDeep(data);
    // Filter for only a specific patient
    data = data.filter((item) => item.facilityAdmission.patient._id === id);
    // create a single patient object
    const _patient: any = { ...data[0].facilityAdmission?.patient };
    // delete patient object from data array
    for (const item of data) {
        delete item.facilityAdmission.patient;
    }
    // create list for facilityAdmissions
    const _facilityAdmissions: any[] = [];
    // Filter out all facility admissions
    for (const item of data) {
        // if (!_facilityAdmissions.includes(item.facilityAdmission)) _facilityAdmissions.push({ ...item.facilityAdmission });
        pushValid(_facilityAdmissions, cloneDeep(item.facilityAdmission));
    }
    // For each facility admission, remove facility admission from therapy admission and assign therapy admission to  facility admissions
    for (const _facilityAdmission of _facilityAdmissions) {
        // Filter and deep clone all therapy admission with single facility admission
        const _data = cloneDeep(data.filter((item) => item.facilityAdmission._id === _facilityAdmission._id));
        // Delete facility admission for each therapy admission
        for (const _item of _data) {
            delete _item.facilityAdmission;
        }
        _facilityAdmission.therapyAdmissions = cloneDeep(_data);
    }
    _patient.facilityAdmissions = cloneDeep(_facilityAdmissions);
    return _patient;
};

// This utility is used to select discipline from planned patient

export const getDisciplinePlannedDay = (plannedPatient: any, discipline: 'PT' | 'OT' | 'ST') => {
    const data = cloneDeep(plannedPatient);
    let selected: any;
    data.facilityAdmission.therapyAdmissions.forEach((_selected) => {
        if (_selected.therapyDiscipline === discipline) {
            selected = _selected.planTherapy;
            // selected = { plannedDay: [_selected.planTherapy.plannedDay[0]] };
            return;
        }
    });
    return selected;
};

export const selectDayFromPlanTherapy = (planTherapy: any, date: Date) => {
    let data: any;
    planTherapy?.plannedDay?.forEach((item) => {
        if (new Date(item.day).toDateString() === new Date(date).toDateString()) {
            data = item;
            return;
        }
    });
    return data;
};

// Therapy to calendar data mapping
/**
 * Generates data that can then be mapped to planner calendar days
 * Data is generated in format that other utilities also use.
 */
export const mapPlannedToCalendar = (_data): any[] => {
    const plannedPatient = cloneDeep(_data);
    const calendarData = [];
    const dates = [];

    // To store data related to each discipline
    let ot;
    let pt;
    let st;
    /**
     * There are going to be only three types of admission PT, OT, ST.
     * Here, we are separating all three into their own variables.
     * So, loop runs max 3 times to set the variables
     */
    // eslint-disable-next-line no-unsafe-optional-chaining
    for (const admission of plannedPatient?.facilityAdmission?.therapyAdmissions) {
        if (admission.therapyDiscipline === 'PT') {
            pt = admission;
        }
        if (admission.therapyDiscipline === 'OT') {
            ot = admission;
        }
        if (admission.therapyDiscipline === 'ST') {
            st = admission;
        }
    }
    // Finding EOC
    let _eoc;

    if (!isEmpty(pt)) _eoc = pt?.planTherapy?.endOfPlan;
    if (
        !isEmpty(ot) &&
        new Date(_eoc).setHours(0, 0, 0, 0) <= new Date(ot?.planTherapy?.endOfPlan).setHours(0, 0, 0, 0)
    ) {
        _eoc = ot?.planTherapy?.endOfPlan;
    }
    if (
        !isEmpty(st) &&
        new Date(_eoc).setHours(0, 0, 0, 0) <= new Date(st?.planTherapy?.endOfPlan).setHours(0, 0, 0, 0)
    ) {
        _eoc = st?.planTherapy?.endOfPlan;
    }
    if (pt !== undefined) {
        pt.planTherapy?.plannedDay?.forEach((_item) => {
            if (new Date(_item.day).getTime() > new Date(_eoc).getTime()) {
                _eoc = _item.day;
            }
        });
    }
    if (ot !== undefined) {
        ot.planTherapy?.plannedDay?.forEach((_item) => {
            if (new Date(_item.day).getTime() > new Date(_eoc).getTime()) {
                _eoc = _item.day;
            }
        });
    }
    if (st !== undefined) {
        st.planTherapy?.plannedDay?.forEach((_item) => {
            if (new Date(_item.day).getTime() > new Date(_eoc).getTime()) {
                _eoc = _item.day;
            }
        });
    }
    // Finding effective from
    const ptEffectiveFrom = pt?.planTherapy?.effectiveFrom;
    const otEffectiveFrom = ot?.planTherapy?.effectiveFrom;
    const stEffectiveFrom = st?.planTherapy?.effectiveFrom;
    const ptCertification = pt?.documentation?.updatedPOC?.certification;
    const otCertification = ot?.documentation?.updatedPOC?.certification;
    const stCertification = st?.documentation?.updatedPOC?.certification;
    /**
     * Generating all days from all disciplines
     * This function pushes values to an array of dates that are there in disciplines.
     * Dates are unique that mean no duplicate value will be pushed.
     */
    plannedPatient.facilityAdmission.therapyAdmissions.forEach((admission) =>
        admission.planTherapy?.plannedDay?.forEach((day) => {
            if (dates.findIndex((_day) => new Date(_day).toDateString() === new Date(day.day).toDateString()) === -1) {
                dates.push(day.day);
            }
        })
    );
    /**
     * Sorting dates then
     * Generating date object with pt, ot st
     * An object with date and other required properties is build and pushed to calendarData
     */

    let ptDay = 0;
    let otDay = 0;
    let stDay = 0;
    let ptWeek = 0;
    let stWeek = 0;
    let otWeek = 0;
    dates
        .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
        .forEach((date, index) => {
            let tempPt: any;
            let tempOt: any;
            let tempSt: any;
            if (pt !== undefined) {
                pt.planTherapy?.plannedDay?.forEach((_item) => {
                    if (new Date(_item.day).toDateString() === new Date(date).toDateString()) {
                        tempPt = {
                            ptSocDate: pt?.SOCDate,
                            isPt: plannedPatient.isPt,
                            isOt: plannedPatient.isOt,
                            isSt: plannedPatient.isSt,
                            ..._item,
                            eoc: _eoc,
                            therapyAdmission: pt._id,
                            documentation: pt.documentation._id,
                            hasPlanConflict: pt.hasPlanConflict,
                        };
                        tempPt.discipline = 'PT';
                        tempPt.planTherapy = { ...pt.planTherapy };
                    }
                });
            }
            if (ot !== undefined) {
                ot.planTherapy?.plannedDay?.forEach((_item) => {
                    if (new Date(_item.day).toDateString() === new Date(date).toDateString()) {
                        tempOt = {
                            stSocDate: st?.SOCDate,
                            isPt: plannedPatient.isPt,
                            isOt: plannedPatient.isOt,
                            isSt: plannedPatient.isSt,
                            ..._item,
                            eoc: _eoc,
                            therapyAdmission: ot._id,
                            documentation: ot.documentation._id,
                            hasPlanConflict: ot.hasPlanConflict,
                        };
                        tempOt.discipline = 'OT';
                        tempOt.planTherapy = { ...ot.planTherapy };
                    }
                });
            }
            if (st !== undefined) {
                st.planTherapy?.plannedDay?.forEach((_item) => {
                    if (new Date(_item.day).toDateString() === new Date(date).toDateString()) {
                        tempSt = {
                            otSocDate: ot?.SOCDate,
                            isPt: plannedPatient.isPt,
                            isOt: plannedPatient.isOt,
                            isSt: plannedPatient.isSt,
                            ..._item,
                            eoc: _eoc,
                            therapyAdmission: st._id,
                            documentation: st.documentation._id,
                            hasPlanConflict: st.hasPlanConflict,
                        };
                        tempSt.discipline = 'ST';
                        tempSt.planTherapy = { ...st.planTherapy };
                    }
                });
            }
            // Pushing single date object to calendar data

            new Date(date).setHours(0, 0, 0, 0) >= new Date(pt?.SOCDate).setHours(0, 0, 0, 0) && pt?.SOCDate
                ? ++ptDay
                : null;
            new Date(date).setHours(0, 0, 0, 0) >= new Date(ot?.SOCDate).setHours(0, 0, 0, 0) && ot?.SOCDate
                ? ++otDay
                : null;
            new Date(date).setHours(0, 0, 0, 0) >= new Date(st?.SOCDate).setHours(0, 0, 0, 0) && st?.SOCDate
                ? ++stDay
                : null;

            Math.ceil(ptDay / 7) > ptWeek ? ++ptWeek : null;
            Math.ceil(otDay / 7) > otWeek ? ++otWeek : null;
            Math.ceil(stDay / 7) > stWeek ? ++stWeek : null;
            const ptDiffTime = Math.abs(
                new Date(pt?.SOCDate).setHours(0, 0, 0, 0) - new Date(date).setHours(0, 0, 0, 0)
            );
            const ptDiffDays = Math.ceil(ptDiffTime / (1000 * 60 * 60 * 24));
            const stDiffTime = Math.abs(
                new Date(st?.SOCDate).setHours(0, 0, 0, 0) - new Date(date).setHours(0, 0, 0, 0)
            );
            const stDiffDays = Math.ceil(stDiffTime / (1000 * 60 * 60 * 24));
            const otDiffTime = Math.abs(
                new Date(ot?.SOCDate).setHours(0, 0, 0, 0) - new Date(date).setHours(0, 0, 0, 0)
            );
            const otDiffDays = Math.ceil(otDiffTime / (1000 * 60 * 60 * 24));
            calendarData.push({
                day: date,
                week: Math.ceil((index + 1) / 7),
                ptWeek: Math.ceil((ptDiffDays + 1) / 7),
                otWeek: Math.ceil((otDiffDays + 1) / 7),
                stWeek: Math.ceil((stDiffDays + 1) / 7),
                ptDay,
                otDay,
                stDay,
                ptEffectiveFrom,
                otEffectiveFrom,
                stEffectiveFrom,
                ptCertification,
                otCertification,
                stCertification,
                anticipatedDC: {
                    pt: pt?.anticipatedDischargeDate,
                    ot: ot?.anticipatedDischargeDate,
                    st: st?.anticipatedDischargeDate,
                },
                ptSocDate: pt?.SOCDate,
                otSocDate: ot?.SOCDate,
                stSocDate: st?.SOCDate,
                eoc: _eoc,
                // Plan therapy is removed because not needed for now.
                // disciplines: { pt: tempPt, ot: tempOt, st: tempSt }
                disciplines: [{ ...tempPt }, { ...tempOt }, { ...tempSt }],
            });
            // return calendarData;
        });
    return calendarData;
};
// Not to use
// Therapy calendar data mapping for week view
// Prerequisite => mapPlannedToCalendar()
/**
 * You must use mapPlannedToCalendar() utility
 * to generate data format that will be passed to this utility.
 * calendarData: any[] => data format generated by mapPlannedToCalendar()
 */
export const getPlansForDayFromPlannedData = (calendarData: any[], date: Date) => {
    const index = calendarData.findIndex(
        (_item) => new Date(_item.day).setHours(0, 0, 0, 0) === new Date(date).setHours(0, 0, 0, 0)
    );
    if (index !== -1) {
        const data = calendarData[index];
        const gen = [];
        if (data?.disciplines[0] !== undefined) {
            gen.push({
                id: data._id,
                date: data.date,
                discipline: data.disciplines[0].discipline,
                week: data.week,
                dayNo: data.dayNo,
                individual: data.disciplines[0].individual,
                concurrent: data.disciplines[0].concurrent,
                group: data.disciplines[0].group,
                note: data.disciplines[0].note,
                therapists: data.disciplines[0].therapists,
                // Remove after
                // disciplines: data.disciplines,
                viewType: 'WEEK_VIEW',
                day: data.disciplines[0].day,
            });
        } else {
            gen.push({});
        }
        if (data?.disciplines[1] !== undefined) {
            gen.push({
                id: data._id,
                date: data.date,
                discipline: data.disciplines[1].discipline,
                week: data.week,
                dayNo: data.dayNo,
                individual: data.disciplines[1].individual,
                concurrent: data.disciplines[1].concurrent,
                group: data.disciplines[1].group,
                note: data.disciplines[1].note,
                therapists: data.disciplines[1].therapists,
                // Remove after
                // disciplines: data.disciplines,
                viewType: 'WEEK_VIEW',
                day: data.disciplines[1].day,
            });
        } else {
            gen.push({});
        }
        if (data?.disciplines[2] !== undefined) {
            gen.push({
                id: data._id,
                date: data.date,
                discipline: data.disciplines[2].discipline,
                week: data.week,
                dayNo: data.dayNo,
                individual: data.disciplines[2].individual,
                concurrent: data.disciplines[2].concurrent,
                group: data.disciplines[2].group,
                note: data.disciplines[2].note,
                therapists: data.disciplines[2].therapists,
                // Remove after
                // disciplines: data.disciplines,
                viewType: 'WEEK_VIEW',
                day: data.disciplines[2].day,
            });
        } else {
            gen.push({});
        }
        return gen;
    } else {
        return [];
    }
};

/**
 * This utility is used the change plans for a specific date in calendarDate.
 * Once the plans for a specific date are updated, the calendar plans will re-render
 * This will emulate the update of plans in calendar drag and drop events.
 * Takes in three parameters, calendarData, date and new plans array.
 * Returns a new calendarData with updated plan for the selected date
 */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const updatePlans = (calendarData: any, fromDate: Date, toDate: Date, planIndex) => {
    // calendarData.forEach((item) => { });
    return calendarData;
};

/**
 * This utility swaps the plans between two dates lists.
 * Dates for the plans are also swapped in this process
 * depends on isDropAblePlan
 */

export const swapPlan = (event: CdkDragDrop<any[]>, patient: IPlannerPlannedResult) => {
    /**
     * A data attribute [attr.data-date] date is passed to set the dates between previous and current container.
     * event.container => is the current element to which item is currently dropped.
     * nextDate => is the date where element is being dropped
     * event.previousContainer => is the previous element the current droppable item came from.
     * previousDate => is the date the droppable item is coming from
     */
    const therapyAdmissions = patient.facilityAdmission.therapyAdmissions;
    const nextDate = event.container.element.nativeElement.dataset.date;
    const previousDate = event.previousContainer.element.nativeElement.dataset.date;
    const data = event.previousContainer.data[event.previousIndex];

    const therapyAdmission: any = therapyAdmissions.find(
        (therapyAdmission) => therapyAdmission.therapyDiscipline == data.discipline
    );
    const throughDate = therapyAdmission.documentation.updatedPOC.certification.throughDate;
    const isDropAble = isDropAblePlan(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
    );
    const recertExists = data.recertificationNotes;
    // Only allowing Recert Daily note to move out of cert period
    let isRecertDailyNote = false
    if (recertExists &&
        Date.parse(new Date(nextDate).toString().slice(0, 15)) >=
            Date.parse(new Date().toString().slice(0, 15))) {
        isRecertDailyNote = true
    }
    if (!therapyAdmission.hasPlanConflict && isDropAble == true) {
        if (
            (isRecertDailyNote ||
            Date.parse(new Date(nextDate).toString().slice(0, 15)) <=
                Date.parse(new Date(throughDate).toString().slice(0, 15)) &&
            Date.parse(new Date(nextDate).toString().slice(0, 15)) >= Date.parse(new Date().toString().slice(0, 15))) &&
            event.previousContainer !== event.container
        ) {
            const nextItem = event.container.data[event.currentIndex];
            const previousItem = event.previousContainer.data[event.previousIndex];

            /**
             * Swapping dates
             * using !isEmpty to make sure the next object is not {} object.
             * It is to make sure we do not push into an empty object as it will break the logic.
             */
            if (!isEmpty(nextItem)) {
                nextItem.day = previousDate;
            }
            if (!isEmpty(previousItem)) {
                previousItem.day = nextDate;
            }
            // Swapping plans
            event.previousContainer.data[event.previousIndex] = nextItem;
            event.container.data[event.previousIndex] = previousItem;
            previousItem.id = previousItem._id;
            previousItem.planTherapy = previousItem.planTherapy._id;
            return { previousItem, nextItem };
        }
    }
    return undefined;
};

// Utility to check if array is filled with empty objects or not

export const isEmptyObjectList = (data: any[]): boolean => {
    return data?.every((item) => isEmpty(item));
};

export const findPlannedDay = (calendarData: any[], date: Date) => {
    const index = calendarData.findIndex((item) => new Date(item.day).toDateString() === new Date(date).toDateString());
    if (index !== -1) {
        return calendarData[index];
    } else {
        return undefined;
    }
};

// Originally made for filtering impairment lists
export const filterListItems = (originalListItems: any[], searchString: string) => {
    return originalListItems.filter((li) => li.text.toLowerCase().includes(searchString));
};
// For filtering Facility in header dropdown
export const filterFacilityItems = (originalListItems: any[], searchString: string) => {
    return originalListItems.filter((li) => li.name.toLowerCase().includes(searchString));
};
function getSortedBaseOnConflict(facilityAdmissions: any[]) {
    facilityAdmissions = facilityAdmissions.sort((a, b) => {
        const aHasConflict = a.therapyAdmissions.some((admission: any) => admission.hasPlanConflict);
        const bHasConflict = b.therapyAdmissions.some((admission: any) => admission.hasPlanConflict);

        if (aHasConflict && !bHasConflict) {
            return -1;
        } else if (!aHasConflict && bHasConflict) {
            return 1;
        } else {
            const aTitle = (a.patient.lastName + ' ' + a.patient.firstName).toLowerCase();
            const bTitle = (b.patient.lastName + ' ' + b.patient.firstName).toLowerCase();

            if (aTitle < bTitle) {
                return -1;
            } else if (aTitle > bTitle) {
                return 1;
            } else {
                return 0;
            }
        }
    });
    return facilityAdmissions;
}

export const filterNotesByDateRange = (patients, startDate, endDate) => {
    const startTimestamp = new Date(new Date(startDate).setHours(0, 0, 0, 0)).getTime();
    const endTimestamp = new Date(new Date(endDate).setHours(23, 59, 59, 59)).getTime();

    for (let i = 0; i < patients.length; i++) {
        const entry = cloneDeep(patients[i]);
        const therapyAdmissions = entry.therapyAdmissions || [];

        for (let j = 0; j < therapyAdmissions.length; j++) {
            const admission = therapyAdmissions[j];
            const documentation = admission.documentation || {};
            const dailyNotes = documentation.dailyNote || [];
            const evaluation = documentation.evaluation || [];
            const progressNote = documentation.progressNote || [];
            const dischargeNote = documentation.dischargeNote || [];

            const filteredEvaluationNotes = [];
            const filteredDailyNotes = [];
            const filteredProgressNote = [];
            const filteredDischargeNote = [];

            for (let k = 0; k < evaluation.length; k++) {
                const note = evaluation[k];
                const noteDate = new Date(note.day).getTime();

                if (noteDate >= startTimestamp && noteDate <= endTimestamp) {
                    filteredEvaluationNotes.push(note);
                }
            }
            for (let k = 0; k < dailyNotes.length; k++) {
                const note = dailyNotes[k];
                const noteDate = new Date(note.day).getTime();

                if (noteDate >= startTimestamp && noteDate <= endTimestamp) {
                    filteredDailyNotes.push(note);
                }
            }
            for (let k = 0; k < progressNote.length; k++) {
                const note = progressNote[k];
                const noteDate = new Date(note.day).getTime();

                if (noteDate >= startTimestamp && noteDate <= endTimestamp) {
                    filteredProgressNote.push(note);
                }
            }
            for (let k = 0; k < dischargeNote.length; k++) {
                const note = dischargeNote[k];
                const noteDate = new Date(note.day).getTime();

                if (noteDate >= startTimestamp && noteDate <= endTimestamp) {
                    filteredDischargeNote.push(note);
                }
            }
            documentation.evaluation = filteredEvaluationNotes; // Update the evaluation notes in documentation
            documentation.dailyNote = filteredDailyNotes; // Update the daily notes in documentation
            documentation.progressNote = filteredProgressNote; // Update the progressNote notes in documentation
            documentation.dischargeNote = filteredDischargeNote; // Update the dischargeNote notes in documentation
            therapyAdmissions[j].documentation = documentation;
        }
        patients[i].therapyAdmissions = therapyAdmissions;
    }

    return patients;
};

export const convertSecsToMins = (totalSecs: number) => {
    const min = Math.trunc(totalSecs / 60);
    const sec = totalSecs - min * 60;
    return { min, sec };
};

export const placeZerosOnLeft = (number: number | string, requiredLength): string => {
    const numberString = '' + number;

    if (numberString.length >= requiredLength) {
        return numberString;
    }

    const zerosOnLeft = Array(requiredLength - numberString.length).fill('0');

    return zerosOnLeft + numberString;
};
