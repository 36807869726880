import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ViewModeRoutingModule } from './view-mode-routing.module';
import { ViewModeLayoutComponent } from './view-mode-layout/view-mode-layout.component';
import { ViewModProgressNoteComponent } from './view-mod-progress-note/view-mod-progress-note.component';

import { SharedModule } from '@app/shared/shared.module';
import { InfoBarComponent } from './info-bar/info-bar.component';
import { DailyNotesComponent } from './daily-notes/daily-notes.component';
import { UpdatePlanOfCareComponent } from './update-plan-of-care/update-plan-of-care.component';
import { RecertificationComponent } from './recertification/recertification.component';
import { DischargeNoteComponent } from './discharge-note/discharge-note.component';
import { EvalDocComponent } from './eval-doc/eval-doc.component';
import { AddendumComponent } from './addendum/addendum.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        ViewModeLayoutComponent,
        ViewModProgressNoteComponent,
        InfoBarComponent,
        DailyNotesComponent,
        UpdatePlanOfCareComponent,
        RecertificationComponent,
        DischargeNoteComponent,
        EvalDocComponent,
        AddendumComponent,
    ],
    imports: [CommonModule, ViewModeRoutingModule, SharedModule, NgxSkeletonLoaderModule, TranslateModule],
    exports: [
        ViewModProgressNoteComponent,
        InfoBarComponent,
        DailyNotesComponent,
        UpdatePlanOfCareComponent,
        RecertificationComponent,
        DischargeNoteComponent,
        EvalDocComponent,
        AddendumComponent,
    ],
})
export class ViewModeModule {}
