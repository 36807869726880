<app-base-modal-card [heading]="heading" wrapperClass="modal-medium" (close)="onClose()">
    <div body class="d-flex flex-column align-items-start">
        <div *ngIf="evalOnly" class="caution">
            <!-- <img src="assets/icons/caution.svg" height="28" width="32" /> -->
            <span class="material-symbols-outlined text-warning-200">
                warning
                </span>
            <p class="message">
                You selected Evaluation Only. Please agree to attestation and Sign.
            </p>
        </div>
        <span class="ml-1 pl-1 text-secondary" style="display: block">
            I agree, and it is my intent, to sign this record/document and affirmation of electronic signature
            for electronic submission and printed record/document. I understand that my signing and submitting
            this record/document in this fashion is the legal equivalent of having placed my handwritten
            signature on the submitted record/document and this affirmation. I understand and agree that by
            electronically signing and submitting this record/document in this fashion I am affirming to the
            truth of the information contained.
        </span>
        <div class="d-flex mt-4 ml-2">
            <input type="checkbox" [(ngModel)]="isAgree" class="checkbox" />
            <span class="credentials ml-2">{{ loggedInUser }}</span>
        </div>
    </div>

    <div footer class="d-flex flex-row justify-content-end w-100">
        <button
            [disabled]="!isAgree || pendingResponse"
            class="btn btn-secondary px-4 py-1 rounded-pill d-flex flex-row"
            (click)="onSign()"
        >
            Sign
            <span *ngIf="loader" class="ml-2 mt-1 spinner-border spinner-border-sm"></span>
        </button>
    </div>
</app-base-modal-card>
