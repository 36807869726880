import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StoreService } from './store.service';
import { environment } from '@environments/environment';
const baseUrl = `${environment.apiUrl}`;

@Injectable({
    providedIn: 'root',
})
export class ContractService {
     addedPayors = [];
    facilityList: any[];
    constructor(private http: HttpClient, private storeService: StoreService) {}

    getContractSkeleton() {
        return this.http.get(`${baseUrl}/contract/getContractSkeleton`, { withCredentials: true });
    }

    createContractFacilityAssignment(contracts: any) {
        return this.http.post(`${baseUrl}/contract-information/addContractFacilityAssignment`, contracts, {
            withCredentials: true,
        });
    }

    getContractFacilityAssignment(id: string) {
        return this.http.get(`${baseUrl}/contract-information/getContractFacilityAssignment/${id}`, {
            withCredentials: true,
        });
    }

    getFacilityActiveContracts(id: string) {
        return this.http.get(`${baseUrl}/contract-information/getFacilityActiveContracts/${id}`, {
            withCredentials: true,
        });
    }

    getAllContracts(organizationId:string) {
        return this.http.get(`${baseUrl}/contract-information/getAll/${organizationId}`, {
            withCredentials: true,
        });
    }

    updateContract(contract: any, id: any) {
        return this.http.put(`${baseUrl}/contract-information/updateContract/${id}`, contract, {
            withCredentials: true,
        });
    }

    createContractTemplate(contract: any) {
        return this.http.post(`${baseUrl}/contract-template/createContractTemplate`, contract, {
            withCredentials: true,
        });
    }

    getContractTemplate(organizationId:string) {
        return this.http.get(`${baseUrl}/contract-template/getContractTemplate/${organizationId}`, { withCredentials: true });
    }
    getContractInformationByPayorAndFacilityId(params) {
        return this.http.post(`${baseUrl}/contract-information/active-contract`, params, { withCredentials: true });
    }

    updateContractTemplate(id: string, contract: any) {
        return this.http.post(`${baseUrl}/contract-template/updateContractTemplate/${id}`, contract, {
            withCredentials: true,
        });
    }

    deleteContractTemplate(id: string) {
        return this.http.delete(`${baseUrl}/contract-template/deleteContractTemplate/${id}`, {
            withCredentials: true,
        });
    }
}
