import { Component, Input, OnInit } from '@angular/core';
import { DocumentationReportService } from '@app/services';
import { DOC_KEY_VALUE } from '@app/helpers/constants/documentation-report';

@Component({
  selector: 'app-paragraph',
  templateUrl: './paragraph.component.html',
  styleUrls: ['./paragraph.component.scss']
})
export class ParagraphComponent implements OnInit {

  @Input() type: string;
  @Input() key: string;

  title: string;
  data: string;

  constructor(
    private documentationService: DocumentationReportService
  ) { }

  ngOnInit(): void {
    try {
      this.title = DOC_KEY_VALUE[this.key]
      this.data = this.documentationService[this.type][this.key]
    } catch (error) {
      this.data = "No data available."
    }
  }

}
