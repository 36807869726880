/* eslint-disable spellcheck/spell-checker */
import { Injectable, inject } from '@angular/core';
import { productFruits } from 'product-fruits';
import { AuthService } from "./auth.service";
import { User } from '@app/models';
import * as _ from '../helpers/utils/object.utils';
import { environment } from '@environments/environment';
import { FacilityManagementService, OrganizationManagementService } from '.';
import { Observable, filter, switchMap, take } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IHMACUserInfo } from '../interfaces/product-fruits.interface';
import { IApiResponse } from '../interfaces/api-response.interface';

@Injectable({
  providedIn: "root"
})
export class ProductFruitsService {
  // Services
  private accountService: AuthService = inject(AuthService);
  private organizationService: OrganizationManagementService = inject(OrganizationManagementService);
  private facilityManagementService: FacilityManagementService = inject(FacilityManagementService);
  private http: HttpClient = inject(HttpClient);

  init(): void {
    try {
      if(!this.accountService?.accountValue) {
        return;
      }

      const { user }: { user: User } = this.accountService.accountValue;
      if(_.isEmpty(user) || (user && !user.email)) {
        return;
      }
      const roles = this.accountService.rolesValue?.map(role => role.name)?.join(', ') || '';
      const hashEmail = btoa(user.email);
      let orgName;
      let currentFacilityName;
      this.organizationService.currentOrganizationSubject$.pipe(
        filter(data => !!data),
        take(1),
        switchMap((organization: any) => {
          orgName = organization.name;
          return this.facilityManagementService.currentFacilitySubject$;
        }),
        filter(data => !!data),
        take(1),
        switchMap((facility: any) => {
          currentFacilityName = facility.facilityName;
          return this.verifyUserIdentity(hashEmail);
        })).subscribe({
          next: (result: IApiResponse) => {
            try {
              const { data } = result;
              productFruits.init(atob(environment.productFruitsKey), 'en', {
                username: hashEmail,
                firstname: user.firstName,
                lastname: user.lastName,
                role: roles,
                signUpAt: user.createdAt.toString(),
                email: user.email,
                hmac: {
                  hash: (data as IHMACUserInfo).hash, // hmacHash is the code you generated in your backend, not the private key!
                  // * don't need expiry date for now
                  // expiration: (data as IHMACUserInfo).expirationFinalDate
                },
                props: {
                  facility: currentFacilityName,
                  organization: orgName
                }
              });
              productFruits.safeExec(($productFruits) => {
                $productFruits.push(['pageChanged']);
              });
            } catch(err) {
              console.log("ProductFruitsService Error ==> ", err);
            }
          },
          error: (err: Error) => console.log("ProductFruitsService Error ==> ", err)
        });
    } catch(err) {
      console.log("ProductFruitsService Error ==> ", err);
    }
  }

  verifyUserIdentity(username: string): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(environment.apiUrl + '/product-fruits/verify-identity?username=' + username);
  }

  removeProductFruitsScript() {
    (window as any).productFruits?.services?.destroy()
  }
}
