<div class="col-12 mt-3">
    <div class="row">
        <div class="col-12 rounded-curve bg-white pt-3 pb-3 pl-3 pr-3" [class.top-card]="state === 'one'">
            <div *ngIf="state == 'zero'" class="d-flex flex-row justify-content-center align-items-center p-5">
                <div class="d-flex flex-column align-items-center justify-content-center">
                    <p class="body-medium-bold text-secondary">No {{ heading }} yet</p>
                    <app-button
                        *ngIf="isReadOnly === false"
                        (click)="setState('one')"
                        type="secondary"
                        [text]="'Add New'"
                        icon="add"
                        class="ml-1 mt-2"
                    ></app-button>
                </div>
            </div>

            <div *ngIf="state === 'one'" class="d-flex flex-row align-items-center justify-content-between">
                <p class="body-medium-bold text-secondary">{{ heading }}</p>
                <app-button
                    (click)="toggleIsDone()"
                    *ngIf="isDone === false && isReadOnly === false"
                    type="secondary"
                    [circle]="true"
                    icon="save"
                    class="ml-1 mt-2"
                ></app-button>
                <app-button
                    (click)="toggleIsDone()"
                    *ngIf="isDone === true && isReadOnly === false"
                    type="secondary"
                    [circle]="true"
                    icon="add"
                    class="ml-1 mt-2"
                ></app-button>
            </div>
            <div class="mt-2"></div>
            <div *ngIf="state == 'one'">
                <div class="col-12">
                    <app-search-bar
                        *ngIf="isDone === false && isReadOnly === false"
                        type="multi"
                        placeholder="Search code"
                        (searchEvent)="clinicalCategoriesSearch($event)"
                        (removeEvent)="removeSelectedItem($event); isDone === false ? redrawRows() : null"
                        [selectedData]="selectedData"
                        [loading]="loading"
                        [disabled]="isDone"
                        objectKey="code"
                    ></app-search-bar>
                </div>
                <!-- ag grid table -->
                <div class="col-12" *ngIf="!isReadOnly">
                    <app-data-grid
                        domLayout="autoHeight"
                        [context]="{ componentParent: this }"
                        [columnDefs]="isDone === true ? columns : editColumns"
                        [rowData]="isDone === true ? selectedData : data"
                        [showCount]="false"
                        (rowClicked)="isDone === false ? addSelectedItem($event) + redrawRows() : null"
                        [rowClassRules]="isDone === false ? rowClassRules : null"
                        (gridReady)="initGrid($event)"
                        [isSearchable]="true"
                        [loading]="loading"
                    ></app-data-grid>
                </div>
                <div class="col-12" *ngIf="isReadOnly">
                    <app-data-grid
                        domLayout="autoHeight"
                        [columnDefs]="treatmentDiagnosisColumns"
                        [showCount]="false"
                        [rowData]="treatmentDiagnosisData"
                        (gridReady)="initGrid($event)"
                    >
                    </app-data-grid>
                </div>
            </div>
        </div>
    </div>
</div>
