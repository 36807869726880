<div *ngIf="showEdit && value" class="position-relative">
    <input
        class="time-pill text-secondary"
        #time
        [(ngModel)]="params.value"
        (keydown.enter)="onEnterKey()"
        (blur)="onBlur()"
        (focus)="onFocus()"
        (keyup)="validateInput($event)"
        required
        type="number"
        min="0"
        maxlength="3"
    />
    <div class="unit text-secondary">min</div>
</div>
<span *ngIf="!showEdit">{{ params.value }}</span>
<span
    *ngIf="!value"
    class="material-symbols-outlined cursor-pointer align-items-center d-flex"
    (click)="showTimeInput()"
    >add</span
>
