import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Location } from '@angular/common';
import { ModalService } from '@app/services/modal.service';
import { BackConfirmationDialogComponent } from './back-confirmation-dialog/back-confirmation-dialog.component';
import { StoreService } from '@app/services';
import { Router } from '@angular/router';
@Component({
    selector: 'app-go-back',
    templateUrl: './go-back.component.html',
    styleUrls: ['./go-back.component.scss'],
})
export class GoBackComponent {
    @Input() title = '';
    @Input() goBackAction = true;
    @Input() acronym = '';
    @Input() confirmAction = false;
    @Input() path: string;
    @Input() pathParams: any;
    @Input() titleCaseOff: boolean;
    @Input() mainClass = '';
    @Input() responsiveFont = '';
    @Output() confirm = new EventEmitter();
    @Output() backButtonPressed = new EventEmitter();
    constructor(
        private location: Location,
        private modal: ModalService,
        private storeService: StoreService,
        private router: Router
    ) {}

    public backButtonAction() {
        if (this.confirmAction) this.openModal();
        else {
            if (this.path) {
                const queryParams = {};
                if (this.pathParams) {
                    Object.keys(this.pathParams)?.forEach((x) => {
                        queryParams[x] = this.pathParams[x];
                    });
                }
                this.router.navigate([this.path], { queryParams });
                return;
            }
            this.confirm.emit(true);
            history.back();
        }
        this.backButtonPressed.emit(true);
    }

    openModal() {
        const dialogRef = this.modal.open(BackConfirmationDialogComponent, {
            data: { heading: 'Confirmation' },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.confirm.emit(result);

                if (this.path) {
                    this.router.navigate([this.path]);
                    return;
                }

                history.back();
            }
        });
        /*dialogRef.events.subscribe((events) => {
      switch (events?.type) {
        case 'confirm':
          history.back();
          dialogRef.close();
          break;
        case 'close':
          dialogRef.close();
          break;
      }
    });*/
    }
}
