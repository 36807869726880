<ng-container *ngIf="(loadingObs | async)?.isLoading; else loaded" [ngTemplateOutlet]="skeleton"></ng-container>
<ng-template #loaded>
    <div *ngIf="!(loadingObs | async)?.isLoading" [class]="printMode ? 'col-md-11' : ''" class="mb-5">
        <div *ngIf="!printMode && currentDocSigned" class="info-bar-padding">
            <app-info-bar
                [documentType]="DOCUMENTATION_CONSTANTS.UPDATED_PLAN_OF_CARE"
                [disableButtons]="isDifferentDisciplineTherapist"
                [isCoSign]="showCoSign"
            >
            </app-info-bar>
        </div>

        <div class="row">
            <div class="col-md-12">
                <app-view-mode-table
                    [tableTitle]="medicalDiagnosis.title"
                    [tableCol]="medicalDiagnosis.cols"
                    [tableData]="medicalDiagnosis.data"
                    [pagination]="false"
                    [showCount]="false"
                    [headerFull]="true"
                    tableHeight="height:250px"
                >
                </app-view-mode-table>
            </div>
        </div>

        <div class="row pt-5">
            <div class="col-md-6">
                <app-view-mode-table
                    [tableTitle]="treatmentDiagnosis.title"
                    [tableCol]="treatmentDiagnosis.cols"
                    [tableData]="treatmentDiagnosis.data"
                    [pagination]="false"
                    [showCount]="false"
                    [headerFull]="true"
                    tableHeight="height:250px"
                >
                </app-view-mode-table>
            </div>

            <div class="col-md-6">
                <app-view-mode-table
                    [tableTitle]="precautions.title"
                    [tableCol]="precautions.cols"
                    [tableData]="precautions.data"
                    [pagination]="false"
                    [showCount]="false"
                    [headerFull]="true"
                    tableHeight="height:250px"
                >
                </app-view-mode-table>
            </div>
        </div>

        <div class="row pt-5">
            <div class="col-md-6">
                <app-view-mode-table
                    [tableTitle]="contraindications.title"
                    [tableCol]="contraindications.cols"
                    [tableData]="contraindications.data"
                    [pagination]="false"
                    [showCount]="false"
                    [headerFull]="true"
                    tableHeight="height:250px"
                >
                </app-view-mode-table>
            </div>

            <div class="col-md-6"></div>
        </div>

        <div class="row pt-5">
            <div class="col-md-12">
                <app-view-mode-table
                    [tableTitle]="impairments.title"
                    [tableCol]="impairments.cols"
                    [tableData]="impairments.data"
                    [pagination]="false"
                    [showCount]="false"
                    [headerFull]="true"
                    tableHeight="height:200px"
                >
                </app-view-mode-table>
            </div>
        </div>

        <div class="row pt-5">
            <div class="col-md-12">
                <app-view-mode-table
                    [tableTitle]="functionalDeficits.title"
                    [tableCol]="functionalDeficits.cols"
                    [tableData]="functionalDeficits.data"
                    [pagination]="false"
                    [showCount]="false"
                    [headerFull]="true"
                    tableHeight="height:200px"
                >
                </app-view-mode-table>
            </div>
        </div>

        <div class="row pt-5">
            <div class="col-md-12">
                <app-view-mode-table
                    [tableTitle]="standardizedTest.title"
                    [tableCol]="standardizedTest.cols"
                    [tableData]="standardizedTest.data"
                    [pagination]="false"
                    [showCount]="false"
                    [headerFull]="true"
                    tableHeight="height:200px"
                >
                </app-view-mode-table>
            </div>
        </div>



   <!-- colworx-sk -->
   <div
   *ngIf="changeHistoryPoc?.length > 0 && PocDoc"
   class="open-history-btn text-white d-flex align-items-center"
   [ngClass]="evalHistorySidePoc == true ? 'hide' : 'show'"
   (click)="openEvalHistoryPoc()"
>
   <span class="material-icons-round text-white">history</span>
   Open Edit History
</div>

<div class="col-lg-2 col-md-3 col-sm-3 customclass">
    <app-history
        style="background-color: white"
        (closed)="closeHistorySideBarPoc($event)"
        header="Edit History"
        *ngIf="evalHistorySidePoc"
    >
        <div
            id="changeInstance"
            *ngFor="let history of changeHistoryPoc; let i = index; let first = first; let last = last"
            (click)="prepareChangeInstanceData(i)"
        >
            <div
                class="main-body p-4"
                [class.selected-changeInstance]="i == selectedChangeInstance"
            >
                <span class="text-secondary body-medium-bold">{{ (history.changeHistory.editedAt | date: 'MMM d, y, h:mm a')}}</span>
                <ng-container *ngIf="history?.changeHistory?.changedFields?.length > 0">
                    <p
                        class="change-item-text"
                        [ngClass]="{
                            'underline-item': change.fieldKey === selectedLabel && i === selectedChangeInstance
                        }"
                        *ngFor="let change of history.changeHistory.changedFields"
                        (click)="scroll(change.fieldKey, i)"
                    >
                        {{ change.fieldKey }}
                    </p>
                </ng-container>
                <p class="mb-0">
                    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="4" cy="4" r="3.5" fill="#69A981" />
                    </svg>
                    <span class="ml-2 body-small">{{
                        history.changeHistory.signedBy.lastName +
                              ', ' +
                              history.changeHistory.signedBy.firstName
                    }}</span>
                </p>
            </div>
            <div class="bg-secondary-a20" style="height: 1px"></div>
        </div>
    </app-history>
</div>
<!-- colworx-sk -->
        <div class="row pt-5">
            <div class="col-md-12">
                <app-view-mode-paragraph
                    [paragraphTitle]="reasonForUpdate.title"
                    [paragraph]="reasonForUpdate.paragraph"
                >
                </app-view-mode-paragraph>
            </div>
        </div>

        <div class="row pt-5">
            <div class="col-md-12">
                <app-view-mode-goals tableTitle="Goals/ LTG STG Status" [goalData]="goal"></app-view-mode-goals>
            </div>
        </div>

        <div class="row pt-5">
            <div class="col-md-12">
                <app-view-mode-table
                    [tableTitle]="cptCodes.title"
                    [tableCol]="cptCodes.cols"
                    [tableData]="cptCodes.data"
                    [pagination]="false"
                    [showCount]="false"
                    [headerFull]="true"
                    tableHeight="height:200px"
                ></app-view-mode-table>
            </div>
        </div>

        <div class="row pt-5">
            <div class="col-md-12">
                <app-view-mode-table
                    [tableTitle]="frequencyDuration.title"
                    [tableCol]="frequencyDuration.cols"
                    [tableData]="frequencyDuration.data"
                    [pagination]="false"
                    [showCount]="false"
                    [headerFull]="true"
                    tableHeight="height:200px"
                >
                </app-view-mode-table>
            </div>
        </div>

        <div class="row pt-5">
            <div class="col-md-12">
                <app-view-mode-table
                    [tableTitle]="certification.title"
                    [tableCol]="certification.cols"
                    [tableData]="certification.data"
                    [pagination]="false"
                    [showCount]="false"
                    [headerFull]="true"
                    tableHeight="height:250px"
                >
                </app-view-mode-table>
            </div>
        </div>

        <div class="custom-card mt-5">
            <div class="addendumBox">
                <app-view-mode-table
                    [tableTitle]="addendum.title"
                    [tableCol]="addendum.cols"
                    [tableData]="addendum.data"
                    [pagination]="false"
                    [showCount]="false"
                    [headerFull]="true"
                    [insideCard]="false"
                    tableHeight="height:250px"
                ></app-view-mode-table>
            </div>

            <div *ngIf="!printMode" id="addendumNote" class="row pt-4 px-3">
                <div class="col-md-12">
                    <app-text-box
                        backgroundClass="bg-body-background"
                        [isSaveButton]="true"
                        (emitData)="updateAddendum($event)"
                        [disabled]="(!authService.isManager && !authService.isTherapist) || admissionDischarged"
                        [suggestionBoxValue]="currentAddendum"
                        label=""
                        maxLength="5000"
                    ></app-text-box>
                </div>
            </div>

            <div *ngIf="showCoSign" class="co-sign-btn-holder bg-body-background text-right">
                <app-button
                    type="btn btn-secondary"
                    text="Co-sign"
                    icon="chevron_right"
                    iconClass="small ml-1"
                    class="ml-2 mt-2"
                    buttonClass="py-2"
                    [rightIcon]="true"
                    [disabled]="!enableCoSignBtn"
                    (onClick)="onCoSignClicked()"
                ></app-button>
            </div>

            <div *ngIf="!printMode" class="row my-3 pr-3">
                <div class="col-md-12">
                    <app-button
                        type="btn btn-outline-secondary"
                        text="Add"
                        [tooltipText]="tooltipCheck()"
                        tooltipPlacement="left"
                        icon="chevron_right"
                        [rightIcon]="true"
                        class="ml-1 add-btn"
                        [disabled]="isDifferentDisciplineTherapist"
                        (click)="!admissionDischarged && addAddendumNote()"
                        [style]="
                            !admissionDischarged
                                ? authService.isManager || authService.isTherapist
                                    ? ''
                                    : 'opacity:0.5'
                                : 'opacity:0.5'
                        "
                    ></app-button>
                </div>
            </div>
        </div>
        <!-- Comment it out for now, we will do it after pilot -->
        <!-- <div class="col-md-3">
            <app-history header="Change History">
                <div *ngFor="let opt of optionToShow">
                    <div class="main-body pl-5">
                        <span class="change-history-date">{{ opt.date }}</span>
                        <ng-container *ngIf="opt.changes.length > 0">
                            <p class="changes" *ngFor="let change of opt.changes">{{ change }}</p>
                        </ng-container>
                        <p>
                            <svg
                                width="8"
                                height="8"
                                viewBox="0 0 8 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="4" cy="4" r="3.5" fill="#69A981" />
                            </svg>
                            <span class="change-history-Person">{{ opt.text }}</span>
                        </p>
                    </div>
                    <div class="bg-secondary-a20" style="height: 1px"></div>
                </div>
            </app-history>
        </div> -->
    </div>
</ng-template>

<ng-template #skeleton>
    <div>
        <br />
        <ngx-skeleton-loader
            appearance="line"
            [theme]="{ width: '25em', height: '7em', 'margin-left': '2em' }"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
            appearance="line"
            [theme]="{ width: '70em', height: '7em', 'margin-left': '3em' }"
        ></ngx-skeleton-loader>
        <br />
        <ngx-skeleton-loader
            appearance="line"
            [theme]="{ width: '25em', height: '5em', 'margin-left': '2em' }"
        ></ngx-skeleton-loader>
        <br />
        <ngx-skeleton-loader
            appearance="line"
            [theme]="{ width: '80%', height: '5em', 'margin-left': '2em' }"
        ></ngx-skeleton-loader>
        <br />
        <ngx-skeleton-loader
            appearance="line"
            [theme]="{ width: '40%', height: '4em', 'margin-left': '2em' }"
        ></ngx-skeleton-loader>
        <br />
        <ngx-skeleton-loader
            appearance="line"
            [theme]="{ width: '80%', height: '30em', 'margin-left': '2em' }"
        ></ngx-skeleton-loader>
    </div>
</ng-template>
