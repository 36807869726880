<ng-container *ngIf="loading; else loaded" [ngTemplateOutlet]="skeleton"></ng-container>
<ng-template #loaded>
    <div *ngIf="!loading" class="d-flex flex-row">
        <div>
            <div class="d-flex flex-row">
                <div class="align-self-center mb-2">
                    <app-go-back title="{{ pageTitle }}"></app-go-back>
                </div>
            </div>
            <div class="row">
                <div class="col-10">
                    <form [formGroup]="facilityForm">
                        <section
                            #OnBoarding
                            enterTheViewportNotifier
                            (visibilityChange)="inViewPort($event, 'Facility Onboarding')"
                            [disabled]="scrollAutoPointer"
                        >
                            <div class="custom-card mb-3">
                                <div class="header">
                                    <p>Facility Onboarding</p>
                                </div>
                                <div class="body card-padding row justify-content-start">
                                    <div class="col-lg-8 col-md-12">
                                        <div class="row">
                                            <!-- <div class="d-flex flex- row"> -->
                                            <div class="col-md-6">
                                                <app-input
                                                    placeholder="Type here"
                                                    type="text"
                                                    label="Facility Name"
                                                    formControlName="facilityName"
                                                >
                                                </app-input>
                                            </div>
                                            <div class="col-md-6">
                                                <app-input
                                                    placeholder="Type here"
                                                    type="text"
                                                    label="Alternate Facility Name"
                                                    formControlName="alternateName"
                                                >
                                                </app-input>
                                            </div>
                                            <div class="col-md-6">
                                                <app-input
                                                    placeholder="Type here"
                                                    type="text"
                                                    label="Facility Area No"
                                                    formControlName="facilityAreaNumber"
                                                >
                                                </app-input>
                                            </div>
                                            <div class="col-md-6">
                                                <app-select
                                                    label="Facility Status"
                                                    formControlName="status"
                                                    [options]="statusOptions"
                                                >
                                                </app-select>
                                            </div>
                                            <div class="col-md-6">
                                                <app-select
                                                    label="Facility PBJ Type"
                                                    formControlName="PBJType"
                                                    [options]="PBJOptions"
                                                >
                                                </app-select>
                                            </div>
                                            <div class="col-md-6">
                                                <app-input
                                                    placeholder="Type here"
                                                    type="text"
                                                    label="Facility PBJ ID"
                                                    formControlName="PBJID"
                                                >
                                                </app-input>
                                            </div>
                                            <div class="col-md-6">
                                                <app-input
                                                    placeholder="12345"
                                                    type="number"
                                                    label="Facility No"
                                                    formControlName="facilityNumber"
                                                >
                                                </app-input>
                                            </div>
                                            <div class="col-md-6">
                                                <app-input
                                                    placeholder="Type here"
                                                    type="text"
                                                    label="Facility OID"
                                                    formControlName="facilityOID"
                                                >
                                                </app-input>
                                            </div>
                                            <div class="col-md-6">
                                                <app-select
                                                    label="Organization Name"
                                                    formControlName="organization"
                                                    [options]="organizations"
                                                >
                                                </app-select>
                                            </div>
                                            <div class="col-md-6">
                                                <app-select
                                                    label="Time Zone"
                                                    formControlName="timezone"
                                                    [options]="timezone"
                                                >
                                                </app-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 mt-md-4 col-md-12">
                                        <div class="body-medium-semibold text-secondary mb-2">Facility Type</div>
                                        <app-radio-button
                                            class="card text-secondary px-3"
                                            formControlName="facilityType"
                                            [options]="facilityTypeOptions"
                                            isVertical=" true"
                                            id="FT"
                                        >
                                        </app-radio-button>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section
                            class="mt-2"
                            #mailAddress
                            enterTheViewportNotifier
                            (visibilityChange)="inViewPort($event, 'Mailing Address & Contact')"
                            [disabled]="scrollAutoPointer"
                        >
                            <div class="custom-card mb-3">
                                <div class="header">
                                    <p>Facility Address</p>
                                </div>
                                <div formGroupName="mailingAddress" class="body card-padding row justify-content-start">
                                    <!-- <div class="d-flex flex- row"> -->
                                    <div class="col-lg-6 col-md-6">
                                        <app-input
                                            placeholder="Type here"
                                            type="text"
                                            label="Address"
                                            formControlName="address"
                                        >
                                        </app-input>
                                    </div>

                                    <div class="col-md-3">
                                        <app-input
                                            placeholder="City"
                                            type="text"
                                            label="City"
                                            formControlName="city"
                                        >
                                        </app-input>
                                    </div>


                                    <div class="col-lg-3 col-md-4">
                                        <app-input
                                            placeholder="12345"
                                            type="number"
                                            label="Zip Code"
                                            formControlName="zipCode"
                                            mask="00000"
                                        >
                                        </app-input>
                                    </div>
                                 
                                    <div class="col-lg-4 col-md-6">
                                        <app-select
                                            label="State"
                                            formControlName="state"
                                            [options]="filteredStates"
                                            (change)="getStateMac($event.target.value)"
                                            inputClass="form-group"
                                        ></app-select>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <app-select label="County" formControlName="county" [options]="mailingCounties">
                                        </app-select>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label class="text-secondary ml-0 d-block">MAC *</label>
                                            <label class="d-block font-weight-normal ml-0 mt-3">
                                                {{ macInfo?.id ? macInfo.name : 'Select state for its MAC' }}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label class="text-secondary ml-0 d-block">Locality *</label>
                                            <label class="d-block font-weight-normal ml-0 mt-3">
                                                {{
                                                    facilityForm?.controls.mailingAddress?.controls.carrier.value
                                                        ? facilityForm?.controls.mailingAddress?.controls.carrier
                                                              .value +
                                                          '' +
                                                          facilityForm?.controls.mailingAddress?.controls.locality.value
                                                        : 'Please enter valid zip code to view locality'
                                                }}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <app-input
                                            placeholder="Type here"
                                            type="text"
                                            label="Phone no"
                                            formControlName="phoneNo"
                                        >
                                        </app-input>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <app-input
                                            placeholder="Type here"
                                            type="text"
                                            label="Fax"
                                            formControlName="fax"
                                        >
                                        </app-input>
                                    </div>
                                    <!-- <div class="col-md-4">
                                        <app-select label="Timezone" formControlName="timezone" [options]="timezone">
                                        </app-select>
                                    </div> -->
                                </div>
                            </div>
                        </section>
                        <section
                            class="mt-2"
                            #billingAddress
                            enterTheViewportNotifier
                            (visibilityChange)="inViewPort($event, 'Billing Address & Contact')"
                            [disabled]="scrollAutoPointer"
                        >
                            <div class="custom-card mb-3 overflow-hidden">
                                <div class="header d-flex flex-row justify-content-between">
                                    <p>Remittance Address & Contact</p>
                                    <span>
                                        <input
                                            [ngbTooltip]="isMailingAddressFilled() ? '' : 'Please Fill Mailing Address'"
                                            [ngClass]="{ 'disable-field': !isMailingAddressFilled() }"
                                            type="checkbox"
                                            formControlName="isBothAddressSame"
                                            placeholder="Type here"
                                            (click)="preventClick($event)"
                                        />
                                        <label class="body-small-bold text-secondary" for="isBothAddressSame"
                                            >Same as Mailing Address</label
                                        >
                                    </span>
                                </div>
                                <div
                                    *ngIf="facilityForm.value.isBothAddressSame == false"
                                    formGroupName="billingAddress"
                                    class="body card-padding row justify-content-start"
                                >
                                    <!-- <div class="d-flex flex- row"> -->
                                    <div class="col-md-6">
                                        <app-input
                                            placeholder="Type here"
                                            type="text"
                                            label="Address"
                                            formControlName="address"
                                        >
                                        </app-input>
                                    </div>
                                   
                                    <div class="col-md-3">
                                        <app-input
                                            placeholder="City"
                                            type="text"
                                            label="City"
                                            formControlName="city"
                                        >
                                        </app-input>
                                    </div>

                                    <div class="col-md-3">
                                        <app-input
                                            placeholder="10000"
                                            type="number"
                                            label="Zip Code"
                                            formControlName="zipCode"
                                            mask="00000"
                                        >
                                        </app-input>
                                    </div>
                                    
                                    <div class="col-md-4">
                                        <app-select
                                            label="State"
                                            formControlName="state"
                                            [options]="filteredStates"
                                        ></app-select>
                                    </div>
                                   
                                    <div class="col-md-4">
                                        <app-select label="County" formControlName="county" [options]="billingCounties">
                                        </app-select>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label class="text-secondary ml-0 d-block">Locality *</label>
                                            <label class="d-block font-weight-normal ml-0 mt-3">
                                                {{
                                                    facilityForm?.controls.billingAddress?.controls.carrier.value
                                                        ? facilityForm?.controls.billingAddress?.controls.carrier
                                                              .value +
                                                          '' +
                                                          facilityForm?.controls.billingAddress?.controls.locality.value
                                                        : 'Please enter valid zip code to view locality'
                                                }}
                                            </label>
                                        </div>
                                    </div>
                                    
                                    <div class="col-md-4">
                                        <app-input
                                            placeholder="Type here"
                                            type="text"
                                            label="Phone no"
                                            formControlName="phoneNo"
                                        >
                                        </app-input>
                                    </div>
                                    <div class="col-md-4">
                                        <app-input
                                            placeholder="Type here"
                                            type="text"
                                            label="Fax"
                                            formControlName="fax"
                                        >
                                        </app-input>
                                    </div>
                                    <!-- <div class="col-md-4">
                                        <app-select label="Timezone" formControlName="timezone" [options]="timezone">
                                        </app-select>
                                    </div> -->
                                </div>
                            </div>
                        </section>
                        <div class="row mb-3">
                            <div class="col-lg-6 col-md-12">
                                <section
                                    #admission
                                    enterTheViewportNotifier
                                    (visibilityChange)="inViewPort($event, 'Admission')"
                                    [disabled]="scrollAutoPointer"
                                    rootMargin="50% 0%"
                                    threshold="0.75"
                                >
                                    <div class="custom-card">
                                        <div class="header">
                                            <p>Admission Type</p>
                                        </div>
                                        <div class="body card-padding row justify-content-start">
                                            <!-- <div class="col-4"> -->
                                            <app-checkbox
                                                class="card text-secondary px-3"
                                                formControlName="admissionType"
                                                [options]="admissionTypeOptions"
                                            >
                                            </app-checkbox>
                                            <!-- </div> -->
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="custom-card h-100">
                                    <div class="header">
                                        <p>Co-Sign Settings</p>
                                    </div>
                                    <div class="body card-padding row justify-content-start">
                                        <app-checkbox
                                            class="card text-secondary px-3"
                                            formControlName="coSignSettings"
                                            [options]="coSignOptions"
                                        >
                                        </app-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section
                            #customerInfo
                            enterTheViewportNotifier
                            rootMargin="-40% 0%"
                            threshold="0.75"
                            (visibilityChange)="inViewPort($event, 'Customer information')"
                            [disabled]="scrollAutoPointer"
                        >
                            <div class="custom-card mb-3">
                                <div class="header">
                                    <p>Customer information</p>
                                </div>
                                <div
                                    formGroupName="customerInformation"
                                    class="body card-padding row justify-content-start"
                                >
                                    <div class="col-lg-4 col-md-6">
                                        <app-input
                                            placeholder="Type here"
                                            type="text"
                                            label="CMS Facility ID"
                                            formControlName="CMSFacilityID"
                                        >
                                        </app-input>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <app-date-picker
                                            label="Customer Start Date"
                                            formControlName="customerStartDate"
                                            [tooltip]="SSNTooltip"
                                            [maxDate]="currentDate"
                                        >
                                        </app-date-picker>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <app-input
                                            placeholder="Type here"
                                            type="text"
                                            label="Customer no"
                                            formControlName="customerNo"
                                        >
                                        </app-input>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section
                            #npi
                            enterTheViewportNotifier
                            (visibilityChange)="inViewPort($event, 'Facility NPI')"
                            [disabled]="scrollAutoPointer"
                            rootMargin="-20% 0%"
                            threshold="0.5"
                        >
                            <div class="custom-card mb-3">
                                <div class="header">
                                    <p>Facility NPI</p>
                                </div>
                                <div formGroupName="facilityNPI" class="body card-padding row justify-content-start">
                                    <div class="col-lg-4 col-md-6">
                                        <app-input
                                            placeholder="Type here"
                                            type="text"
                                            label="Service Facility NPI"
                                            formControlName="serviceFacilityNPI"
                                        >
                                        </app-input>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <app-input
                                            placeholder="Type here"
                                            type="text"
                                            label="Billing NPI"
                                            formControlName="billingNPI"
                                        >
                                        </app-input>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <app-input
                                            placeholder="Type here"
                                            type="text"
                                            label="Alternate Facility NPI"
                                            formControlName="alternateFacilityNPI"
                                        >
                                        </app-input>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section
                            #medCare
                            enterTheViewportNotifier
                            (visibilityChange)="inViewPort($event, 'Medicare and Medicaid Information')"
                            [disabled]="scrollAutoPointer"
                            rootMargin="-15% 0%"
                            threshold="0.5"
                        >
                            <div class="custom-card mb-3">
                                <div class="header">
                                    <p>Medicare & Medicaid</p>
                                </div>
                                <div class="row body card-padding justify-content-start">
                                    <div class="col-lg-4 col-md-6">
                                        <app-input
                                            placeholder="Type here"
                                            type="text"
                                            label="Medicare Provider No"
                                            formControlName="medicareProviderNo"
                                        >
                                        </app-input>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <app-input
                                            placeholder="Type here"
                                            type="text"
                                            label="Medicaid Provider No"
                                            formControlName="medicaidProviderNo"
                                        >
                                        </app-input>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </form>
                    <div class="row p-3 justify-content-end">
                        <button class="innova-btn btn-danger" (click)="goBack()">Cancel</button>
                        <button
                            *ngIf="facilityOIDLoading"
                            class="innova-btn btn-secondary ml-1"
                            [disabled]="facilityOIDLoading === true"
                        >
                            <div class="spinner-border spinner-border-sm text-secondary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </button>
                        <button
                            *ngIf="!facilityOIDLoading"
                            class="innova-btn btn-secondary ml-1"
                            (click)="isEditing ? facilityOIDExists(currentFacility) : facilityOIDExists()"
                            [disabled]="!facilityForm.valid"
                        >
                            {{ isEditing ? 'Update' : 'Save' }}
                        </button>
                    </div>
                </div>
                <div class="d-flex col-2 pt-4 mt-1">
                    <app-quick-links
                        [links]="facilityQuickLinks"
                        [scrolledDiv]="selectedLink"
                        (linkChanged)="linkChanged($event)"
                        [selectedLink]="selectedLink"
                    >
                    </app-quick-links>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #skeleton>
    <br />
    <ngx-skeleton-loader
        appearance="line"
        [theme]="{ width: '15em', height: '3em', 'margin-left': '2em' }"
    ></ngx-skeleton-loader>
    <br />
    <ngx-skeleton-loader
        appearance="line"
        [theme]="{ width: '15em', height: '3em', 'margin-left': '2em' }"
    ></ngx-skeleton-loader>
    <hr class="table-footer" />
    <br />
    <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CARD"></ngx-skeleton-loader>
    <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CARD"></ngx-skeleton-loader>
    <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.DETAIL"></ngx-skeleton-loader>
    <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.RIGHTDETAIL"></ngx-skeleton-loader>
    <br />
    <br />
    <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CARD"></ngx-skeleton-loader>
    <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CARD"></ngx-skeleton-loader>
    <br />
    <br />
    <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CARD"></ngx-skeleton-loader>
    <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CARD"></ngx-skeleton-loader>
    <br />
    <br />
    <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CARD"></ngx-skeleton-loader>
    <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CARD"></ngx-skeleton-loader>
    <br />
    <br />
    <ngx-skeleton-loader
        appearance="line"
        [theme]="{ width: '30em', height: '3em', 'margin-left': '2em' }"
    ></ngx-skeleton-loader>
    <br />
    <hr class="table-footer" />
</ng-template>
