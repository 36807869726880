import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-tab-small',
    templateUrl: './tab-small.component.html',
    styleUrls: ['./tab-small.component.scss'],
})
export class TabSmallComponent {
    @Input() _buttons: string[];
    @Input() selectedButton: string;
    @Output() buttonChanged = new EventEmitter<string>();

    constructor() {
        if (!this.selectedButton) {
            if (this._buttons?.length > 0) {
                this.selectedButton = this._buttons[0];
                this.buttonChanged.emit(this.selectedButton);
            }
        }
    }

    // Actions
    setSelectedButton(_button) {
        this.selectedButton = _button;
        this.buttonChanged.emit(this.selectedButton);
    }
}
