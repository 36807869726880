import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ROUTER_UTILS } from '@app/helpers';
import { environment } from '@environments/environment';
import { map, Observable, of, Subject } from 'rxjs';
import { StoreService } from '.';
import { Router } from '@angular/router';
import { MODES } from '@app/helpers/constants/PDPM';
import { cloneDeep } from 'lodash';
import { format } from 'date-fns';
const baseUrl = `${environment.apiUrl}/pdpm`;
@Injectable({
    providedIn: 'root',
})
export class PDPMService {
    private _currentPatientId$: Subject<string> = new Subject();
    private _currentTab$: Subject<string> = new Subject();
    private _chevronClicked$: Subject<boolean> = new Subject();
    private _patients: any[];
    private _next: number;
    private _prev: number;

    // PCC: PDPM starts here
    public minutesCache: { [mode: string]: any } = {};

    public pdpmDetailsUrl: string =
        '/' +
        ROUTER_UTILS.config.patients.root +
        '/' +
        ROUTER_UTILS.config.patients.pdpm.root +
        '/' +
        ROUTER_UTILS.config.patients.pdpm.details;

    patientUrl: string = ROUTER_UTILS.config.patients.root;
    pdpmUrl: string = ROUTER_UTILS.config.patients.pdpm.root;
    boardFilter = {
        search: '',
        discipline: 'ALL',
    };
    editMode: boolean;
    therapyDaysGrandTotal = 0; // needed for 'ALL' filter in both modes
    public pdpmBoardUrl: string = this.patientUrl + '/' + this.pdpmUrl + '/' + ROUTER_UTILS.config.patients.pdpm.board;
    private _PCC_Url = '/' + ROUTER_UTILS.config.patients.pcc.root;

    // PT/ST clinical categories mapping
    private _PTClinicalCategory = {
        // TODO: Script to replace 'surger' with surgery in entire app including DB, excel sheets
        // eslint-disable-next-line spellcheck/spell-checker
        'major joint replacement or spinal surger ': 'Major Joint Replacement or Spinal Surgery',
        'major joint replacement or spinal surgery ': 'Major Joint Replacement or Spinal Surgery',
        'orthopedic surgery (except major joint replacement or spinal surgery) ': 'Other Orthopedic',
        'non-orthopedic surgery ': 'Non-Orthopedic Surgery',
        'acute infections ': 'Medical Management',
        'cardiovascular and coagulations ': 'Medical Management',
        'pulmonary ': 'Medical Management',
        'non-surgical orthopedic/musculoskeletal ': 'Other Orthopedic',
        'acute neurologic ': 'Acute Neurologic',
        'cancer ': 'Medical Management',
        'medical management ': 'Medical Management',
    };

    private _SLPClinicalCategory = {
        'major joint replacement or spinal surgery ': 'Non-Neurologic',
        'orthopedic surgery (except major joint replacement or spinal surgery ': 'Non-Neurologic',
        'non-orthopedic surgery ': 'Non-Neurologic',
        'acute infections ': 'Non-Neurologic',
        'cardiovascular and coagulations ': 'Non-Neurologic',
        'pulmonary ': 'Non-Neurologic',
        'non-surgical orthopedic/musculoskeletal ': 'Non-Neurologic',
        'acute neurologic ': 'Acute Neurologic',
        'cancer ': 'Non-Neurologic',
        'medical management ': 'Non-Neurologic',
    };

    // will be populated from backend
    _caseMixGroupIndex = null;

    constructor(private http: HttpClient, private _storeService: StoreService, private _router: Router) {}

    get patients(): any[] {
        return this._patients;
    }

    set patients(_patients: any[]) {
        this._patients = _patients;
    }

    get currentPatientId$(): Subject<string> {
        return this._currentPatientId$;
    }

    set currentPatientId$(patientId: any) {
        this._currentPatientId$.next(patientId);
    }

    get currentTab$(): Subject<string> {
        return this._currentTab$;
    }

    set currentTab$(tab: any) {
        this._currentTab$.next(tab);
    }

    get chevronClicked$(): Subject<boolean> {
        return this._chevronClicked$;
    }

    set chevronClicked$(event: any) {
        this._chevronClicked$.next(event);
    }

    get prev(): number {
        return this._prev;
    }

    set prev(index: number) {
        this._prev = index;
    }

    get next(): number {
        return this._next;
    }

    set next(index: number) {
        this._next = index;
    }

    getPTClinicalCategory(primaryDiagnosisClinicalCategory: string): string {
        return this._PTClinicalCategory[primaryDiagnosisClinicalCategory];
    }

    getSLPClinicalCategory(primaryDiagnosisClinicalCategory: string): string {
        return this._SLPClinicalCategory[primaryDiagnosisClinicalCategory];
    }

    getCaseMixedGroupMapping(caseMixedGroup: string, type: string) {
        const mixedGroup = this._caseMixGroupIndex[caseMixedGroup];
        if (!mixedGroup) {
            return;
        }
        return mixedGroup[type];
    }

    loadPDPMPatients(facilityId: any = ''): Observable<any> {
        return this.http
            .get<any>(
                `${baseUrl}/board/${facilityId ? facilityId : JSON.parse(localStorage.getItem('current-facility')).id}`,
                {
                    withCredentials: true,
                }
            )
            .pipe(
                map(({ success, data }) => {
                    if (!success) {
                        return of([]);
                    }

                    this.patients = [...data];
                    return data;
                })
            );
    }

    getPerDayAndPerDisciplineIndexes(response) {
        const dayHashDisciplineIndex = {};
        const dayIndex = {};

        // calculate per day total (can have more than one same discipline note now)
        response.forEach((elem: any) => {
            const record = cloneDeep(elem);

            if (record && record.day) {
                const day = format(new Date(new Date(record.day).setHours(0, 0, 0, 0)), 'MM/dd/yyyy');

                // needed for 'total' in grand total pinned row
                if (dayIndex[day]) {
                    dayIndex[day] += record.total;
                } else {
                    dayIndex[day] = record.total;
                }

                // needed for each discipline row
                if (dayHashDisciplineIndex[`${day}#${record.therapyDiscipline}`]) {
                    dayHashDisciplineIndex[`${day}#${record.therapyDiscipline}`] += record.total;
                } else {
                    dayHashDisciplineIndex[`${day}#${record.therapyDiscipline}`] = record.total;
                }
            }
        });

        return [dayHashDisciplineIndex, dayIndex];
    }

    calculateTherapyDaysGrandTotal(response) {
        this.therapyDaysGrandTotal = 0;
        if (!response || !response.length) {
            return false;
        }

        const [dayHashDisciplineIndex, dayIndex] = this.getPerDayAndPerDisciplineIndexes(response);

        Object.keys(dayIndex).forEach((day) => {
            if (dayIndex[day] >= 15) {
                this.therapyDaysGrandTotal++;
            }
        });

        return [dayHashDisciplineIndex, dayIndex];
    }

    parseAllDisciplinesView(mode: string, filter: any, data: { response: any }): any[] {
        this.therapyDaysGrandTotal = 0;
        const { response } = data;

        const output = this.calculateTherapyDaysGrandTotal(response);

        if (!output) {
            return [];
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [dayHashDisciplineIndex, dayIndex] = output;

        const result = {
            PT: { therapyDays: 0, coTreat: 0, concurrent: 0, individual: 0, group: 0, total: 0 },
            ST: { therapyDays: 0, coTreat: 0, concurrent: 0, individual: 0, group: 0, total: 0 },
            OT: { therapyDays: 0, coTreat: 0, concurrent: 0, individual: 0, group: 0, total: 0 },
        };

        Object.keys(dayHashDisciplineIndex).forEach((key) => {
            const discipline = key.split('#')[1];

            if (key.includes(discipline) && dayHashDisciplineIndex[key] >= 15) {
                result[discipline].therapyDays++;
            }
        });

        // finding everything else
        response.forEach((record) => {
            record.coTreat = record.coTreat ?? 0;
            record.concurrent = record.concurrent ?? 0;
            record.individual = record.individual ?? 0;
            record.group = record.group ?? 0;
            record.total = record.total ?? 0;

            result[record.therapyDiscipline].coTreat += record.coTreat;
            result[record.therapyDiscipline].concurrent += record.concurrent;
            result[record.therapyDiscipline].individual += record.individual;
            result[record.therapyDiscipline].group += record.group;
            result[record.therapyDiscipline].total += record.total;
        });

        return Object.keys(result).map((discipline: string) => {
            return {
                discipline,
                therapyDays: result[discipline].therapyDays === 0 ? '-' : result[discipline].therapyDays,
                co_treatment: result[discipline].coTreat,
                concurrent: result[discipline].concurrent,
                individual: result[discipline].individual,
                group: result[discipline].group,
                total: result[discipline].total,
            };
        });
    }

    // used for 7 days look back + ARD Minutes Mode
    parseAndFilterTherapyMinutes(mode: any, filter: any, data: any) {
        const minutesIndex = {};
        let therapyMinutesData = [];

        const { response } = data;
        const { selectedDiscipline } = filter;

        response
            .filter((elem) => selectedDiscipline === elem.therapyDiscipline)
            .forEach((elem: any) => {
                const record = cloneDeep(elem);

                if (record && record.day) {
                    // displays date w.r.t to client's timezone
                    const day = format(new Date(new Date(record.day).setHours(0, 0, 0, 0)), 'MM/dd/yyyy');

                    record.coTreat = record.coTreat ?? 0;
                    record.concurrent = record.concurrent ?? 0;
                    record.individual = record.individual ?? 0;
                    record.group = record.group ?? 0;
                    record.total = record.total ?? 0;

                    if (minutesIndex[day]) {
                        minutesIndex[day].coTreat += record.coTreat;
                        minutesIndex[day].concurrent += record.concurrent;
                        minutesIndex[day].individual += record.individual;
                        minutesIndex[day].group += record.group;
                        minutesIndex[day].total += record.total;
                    } else {
                        minutesIndex[day] = record;
                    }
                }
            });

        // sorted by daily note date
        const sorted = Object.keys(minutesIndex).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

        let counter = 1;
        sorted.forEach((key) => {
            const record = minutesIndex[key];

            therapyMinutesData.push({
                date: key,
                dailyNoteOrder: record.total >= 15 ? counter++ : '-',
                individual: record.individual ?? 'N/A',
                concurrent: record.concurrent ?? 'N/A',
                group: record.group ?? 'N/A',
                co_treatment: record.coTreat ?? 'N/A',
                total: record.total,
            });
        });

        if ([MODES.ARD_MINUTES].includes(mode)) {
            therapyMinutesData = therapyMinutesData?.slice(-7);
        }

        return therapyMinutesData ?? [];
    }

    getPDPMPatientDetails(patientId: string) {
        return this.http.get<any>(`${baseUrl}/details/${patientId}`, {
            withCredentials: true,
        });
    }

    getCaseMixedGroupRatesByYear(year: string) {
        return this.http.get<any>(`${baseUrl}/CMGRates/${year}`, {
            withCredentials: true,
        });
    }

    getPDPM(_id: string) {
        return this.http.get<any>(`${baseUrl}/${_id}`, {
            withCredentials: true,
        });
    }

    updateActivePDPMInstance(_id: string, activeInstance: any, queryParams = null) {
        return this.http.put<any>(
            `${baseUrl}/active${queryParams ? '?' + queryParams : ''}`,
            { _id, activeInstance },
            {
                withCredentials: true,
            }
        );
    }

    getTherapyMinutes(patientId: string, facilityId: string, queryParams = null) {
        let url = `${baseUrl}/details/${patientId}/therapy-minutes`;

        url = `${url}?timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;

        if (queryParams) {
            url = `${url}&${queryParams}`;
        }

        url = `${url}&facilityId=${facilityId}`;

        return this.http.get<any>(url, {
            withCredentials: true,
        });
    }

    getPTOTProjectedCMG(category: string, score: number) {
        if (
            ['major joint replacement', 'spinal surgery', 'major joint replacement or spinal surgery'].includes(
                category.toLowerCase()
            )
        ) {
            if (score >= 0 && score <= 5) {
                return 'TA';
            } else if (score >= 6 && score <= 9) {
                return 'TB';
            } else if (score >= 10 && score <= 23) {
                return 'TC';
            } else if (score === 24) {
                return 'TD';
            }
        } else if (['other orthopedic'].includes(category.toLowerCase())) {
            if (score >= 0 && score <= 5) {
                return 'TE';
            } else if (score >= 6 && score <= 9) {
                return 'TF';
            } else if (score >= 10 && score <= 23) {
                return 'TG';
            } else if (score === 24) {
                return 'TH';
            }
        } else if (['medical management'].includes(category.toLowerCase())) {
            if (score >= 0 && score <= 5) {
                return 'TI';
            } else if (score >= 6 && score <= 9) {
                return 'TJ';
            } else if (score >= 10 && score <= 23) {
                return 'TK';
            } else if (score === 24) {
                return 'TL';
            }
        } else if (['non-orthopedic surgery', 'acute neurologic'].includes(category.toLowerCase())) {
            if (score >= 0 && score <= 5) {
                return 'TM';
            } else if (score >= 6 && score <= 9) {
                return 'TN';
            } else if (score >= 10 && score <= 23) {
                return 'TO';
            } else if (score === 24) {
                return 'TP';
            }
        }
    }

    calculateTotal(individual: number, concurrent: number, coTreat: number, group: number): number {
        let sum = 0;

        if (individual) {
            sum += individual;
        }

        // Excluded concurrent and coTreat from row-based sum
        // Will remove comment code after requirements gets stable
        // =======================================================

        if (concurrent) {
            sum += concurrent;
        }

        // if (coTreat) {
        //     sum += coTreat;
        // }

        if (group) {
            sum += group;
        }

        return sum;
    }

    calculateGrandTotal(therapyMinutesData, filter: 'ALL' | 'PT' | 'OT' | 'ST' | null = null, mode: MODES) {
        if (!therapyMinutesData.length) {
            return [];
        }

        const sum = {
            individual: 0,
            concurrent: 0,
            group: 0,
            co_treatment: 0,
        };

        therapyMinutesData.forEach((day) => {
            Object.keys(day).forEach((key) => {
                if (day[key] !== 'N/A' && ['individual', 'concurrent', 'group', 'co_treatment'].includes(key)) {
                    sum[key] += day[key];
                }
            });
        });

        const total = Object.keys(sum).reduce((accumulator, key) => {
            // do not count coTreatment in grandtotal calculations
            if (['co_treatment'].includes(key)) {
                return accumulator;
            }
            return accumulator + sum[key];
        }, 0);

        let therapyDaysGrandTotal;
        if (filter === 'ALL') {
            this.calculateTherapyDaysGrandTotal(this.minutesCache[mode]);
            therapyDaysGrandTotal = this.therapyDaysGrandTotal;
        } else {
            therapyDaysGrandTotal = therapyMinutesData.filter((elem) => elem.dailyNoteOrder !== '-').length; //therapyMinutesData.reduce((acc: number, curr: any) => acc + curr.therapyDays, 0);
        }

        return [
            {
                date: ' ',
                ...Object.keys(sum).reduce((accumulator, key) => {
                    const percentage = total ? (sum[key] / total) * 100 : 0;
                    const rounded = Math.round(percentage * 10) / 10;
                    const singleDecimalPoint = +rounded.toFixed(1);

                    return { ...accumulator, [key]: sum[key], [`${key}Percentage`]: singleDecimalPoint };
                }, {}),
                total: total,
                therapyDaysGrandTotal,
            },
        ];
    }

    updateChevrons(index: number): void {
        // calculate prev: '-1' or 'null' for no previous patient
        this._prev =
            index <= 0
                ? null
                : this.patients.findIndex(
                      (elem) =>
                          elem._id ===
                          this.patients
                              .slice(0, index)
                              .reverse()
                              .find((elem) => elem.column === this.patients[index].column)?._id
                  );

        // calculate next: '-1' or 'null' for no next patient
        this._next =
            index + 1 >= this.patients.length
                ? null
                : this.patients.findIndex(
                      (elem) =>
                          elem._id ===
                          this.patients
                              .slice(index + 1, this.patients.length)
                              .find((elem) => elem.column === this.patients[index].column)?._id
                  );
    }

    /**
     * @function goToPCC
     * @description Redirects to PCC:PDPM
     * @param {void}
     * @returns {void}
     */
    goToPCC(patientId: string, facilityId = ''): void {
        if (!patientId) {
            console.log('ERR: No patientId provided!');
            return;
        }

        this._storeService.updatePatientState({}); // reset store value because navigating to PCC will result in old patient from store
        this._router.navigate([this._PCC_Url], {
            queryParams: {
                patient: patientId,
                tab: 'pdpm',
                facilityAd: facilityId,
                isFromEOM: true,
            },
        });
    }

    therapyMinutesHandler(response: any, discipline: string, mode: MODES) {
        const filter: { selectedDiscipline } = {
            selectedDiscipline: discipline,
        };

        const data = { response: response };

        const minutes =
            filter.selectedDiscipline === 'ALL'
                ? this.parseAllDisciplinesView(mode, filter, data)
                : this.parseAndFilterTherapyMinutes(mode, filter, data);

        let therapyMinutesData = [...minutes];

        if ([MODES.ARD_MINUTES].includes(mode)) {
            therapyMinutesData = therapyMinutesData.slice(-7);
        }

        const grandTotal = this.calculateGrandTotal(therapyMinutesData, filter.selectedDiscipline, mode);

        return { therapyMinutesData, grandTotal };
    }
}
