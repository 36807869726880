import { Component, Inject } from '@angular/core';
import { DIALOG_DATA } from '@app/helpers/dialog-tokens';
import { DialogRef } from '@app/services';

@Component({
    selector: 'app-goal-discontinuation-modal',
    templateUrl: './goal-discontinuation-modal.component.html',
    styleUrls: ['./goal-discontinuation-modal.component.scss'],
})
export class GoalDiscontinuationModalComponent {
    deleteOption = [];
    reasonForDiscontinuing = '';
    constructor(@Inject(DIALOG_DATA) public data, private ref: DialogRef) {}

    onClose(discontinueStgs?) {
        this.ref.close({ reasonForDiscontinuing: this.reasonForDiscontinuing, discontinueStgs });
    }
    onDone() {
        this.onClose(true);
    }
}
