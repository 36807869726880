export async function scrollToElement(element) {
    return new Promise((resolve) => {
        element.nativeElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
        setTimeout(() => {
            resolve('Its Resolve');
        }, 100);
    });
}

export async function scrollToElementWithOffset(element, block = 'center') {
    if (!element?.nativeElement) {
        return;
    }
    return new Promise((resolve) => {
        setTimeout(() => {
            element.nativeElement.scrollIntoView({ block, behavior: 'smooth' });
            resolve('Its Resolve');
        }, 100);
    });
}
