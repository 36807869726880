<app-base-modal-card [heading]="goalData.name" (close)="onClose()">
    <div body>
        <div class="w-100 d-flex flx-row align-items-center justify-content-start">
            <div>
                <span class="text-secondary fw-900">Target date: </span>
                <span class="text-secondary">{{ dateFormatter(goalData.targetDate) }}</span>
            </div>
            <div class="ml-3">
                <span class="tet-secondary fw-900">Goal status: </span>
                <span class="text-primary fw-800">{{ goalData.goalStatus }}</span>
            </div>
        </div>
        <!-- Goal details -->
        <div class="body-large-semibold mb-3">Goal details</div>
        <table class="innova-table-v2 mb-4">
            <thead>
                <tr>
                    <th></th>
                    <th>Prior</th>
                    <th>Current</th>
                    <th>Target</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span class="text-primary fw-600">Level of Function</span></td>
                    <td>
                        {{
                            goalData.goalHistory[goalData.goalHistory.length - 1].clof ||
                                goalData.goalHistory[goalData.goalHistory.length - 1].value
                        }}
                    </td>
                    <td>{{ goalData.clof || goalData.value }}</td>
                    <td>{{ goalData.TLOF || goalData.target }}</td>
                </tr>
                <tr>
                    <td><span class="text-primary fw-600">Assistive Device</span></td>
                    <td>N/A</td>
                    <td>Wheel chair</td>
                    <td>Walking Cane</td>
                </tr>
            </tbody>
        </table>
        <!-- Target History -->
        <div class="body-large-semibold mb-3">Goal details</div>
        <table class="innova-table-v2 mb-4">
            <thead>
                <tr>
                    <th>Changed From</th>
                    <th>Changed to</th>
                    <th>Date</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let history of goalData.goalHistory; let i = index">
                    <td *ngIf="i + 1 < goalData.goalHistory.length" class="ml-2">
                        {{ goalData.goalHistory[i + 1].clof || goalData.goalHistory[i + 1].value }}
                        <span
                            *ngIf="
                                (goalData.goalHistory[i + 1].clof || goalData.goalHistory[i + 1].value) <=
                                (history.clof || history.value)
                            "
                            class="material-symbols-outlined solid text-success"
                        >
                            arrow_upward
                        </span>
                        <span
                            *ngIf="
                                (goalData.goalHistory[i + 1].clof || goalData.goalHistory[i + 1].value) >
                                (history.clof || history.value)
                            "
                            class="material-symbols-outlined solid text-danger"
                        >
                            arrow_downward
                        </span>
                    </td>
                    <td *ngIf="i + 1 >= goalData.goalHistory.length" class="ml-2">
                        {{ goalData.goalHistory[i].clof || goalData.goalHistory[i].value }}
                        <span
                            *ngIf="
                                (goalData.goalHistory[i].clof || goalData.goalHistory[i].value) <=
                                (history.clof || history.value)
                            "
                            class="material-symbols-outlined solid text-success"
                        >
                            arrow_upward
                        </span>
                        <span
                            *ngIf="
                                (goalData.goalHistory[i].clof || goalData.goalHistory[i].value) >
                                (history.clof || history.value)
                            "
                            class="material-symbols-outlined solid text-danger"
                        >
                            arrow_downward
                        </span>
                    </td>
                    <td>
                        <div class="d-flex flex-row align-items-center">
                            <span>{{ history.clof || history.value }}</span>
                        </div>
                    </td>
                    <td>{{ dateFormatter(history.date) }}</td>
                </tr>
            </tbody>
        </table>
        <div class="mt-3">
            <h5 class="h5 text-secondary">Goal Purpose</h5>
            <p class="text-secondary" style="max-width: 50em">
                {{ goalData.goalPurpose }}
            </p>
        </div>
    </div>
    <div footer>
        <button (click)="onClose()" class="btn btn-outline-secondary rounded-pill d-flex flex-row align-items-enter">
            Cancel
        </button>
    </div>
</app-base-modal-card>
