import { Pipe, PipeTransform } from '@angular/core';
import { TherapyAdmission, TherapyDiscipline } from '@app/interfaces';

@Pipe({
    name: 'filterTherapyAdmissionOnTP',
})
export class FilterTherapyAdmissionOnTPPipe implements PipeTransform {
    transform(admission: TherapyAdmission[], therapyDiscipline: TherapyDiscipline) {
        return admission.filter((add) => add.therapyDiscipline === therapyDiscipline)[0];
    }
}
