import { Pipe, PipeTransform, OnDestroy } from '@angular/core';
import { AppService } from '@app/services';

@Pipe({
    name: 'stringLength',
    pure: false,
})
export class StringLengthPipe implements PipeTransform, OnDestroy {
    smallScreenCharLength = 13;
    largeScreenCharLength = 18;
    maxNameLength: any;
    screenWidth$: any;
    screenWidth: any;

    constructor(private appService: AppService) {}
    ngOnDestroy(): void {
        if (this.screenWidth$) {
            this.screenWidth$.unsubscribe();
        }
    }
    transform(value: string, smallLength: number, largeLength: number): string {
        if (!value) return value;
        this.screenWidth$ = this.appService.onScreenResize$.subscribe((value) => {
            this.screenWidth = value;
        });

        smallLength = !smallLength == true ? this.smallScreenCharLength : smallLength;
        largeLength = !largeLength == true ? this.largeScreenCharLength : largeLength;

        if (this.screenWidth.width <= 1536) {
            return value?.length <= smallLength ? value : value.slice(0, smallLength - 3) + '...';
        }
        return value?.length <= largeLength ? value : value.slice(0, largeLength - 3) + '...';
    }
}
