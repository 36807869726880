<div class="custom-card">
    <div *ngIf="paragraphTitle" class="header d-flex flex-row align-items-center justify-content-between">
        <p class="text-secondary">{{ paragraphTitle }}</p>
        <button
            class="btn btn-close d-flex flex-row align-items-center justify-content-center modal-close-button"
            (click)="onClose()"
        >
            <span class="material-icons-outlined text-white-100" style="vertical-align: middle"> close </span>
        </button>
    </div>
    <div class="body p-3">
        <ng-container *ngIf="innerHtml">
            <p class="body-medium text-secondary word-break-all" [innerHtml]="innerHtml"></p>
        </ng-container>
        <ng-container>
            <p *ngIf="!innerHtml" class="body-medium text-secondary word-break-all paragraph">
                {{ paragraph }}
            </p>
        </ng-container>
    </div>
</div>