import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

@Injectable()
export class TimezoneInterceptor implements HttpInterceptor {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isApiUrl = request.url.startsWith(environment.apiUrl);
        if (isApiUrl) {
            request = request.clone({
                setHeaders: {
                    'X-Timezone-Offset': this.getTimezoneOffset(),
                    'X-timezone-IANA-format': Intl.DateTimeFormat().resolvedOptions().timeZone,
                },
            });
        }

        return next.handle(request);
    }

    // I return the timezone offset (in minutes) for the current Browser platform. This
    // is the number of minutes that the current timezone would have to add to a Date in
    // order to calculated the current UTC time.
    private getTimezoneOffset(): string {
        return String(new Date().getTimezoneOffset());
    }
}
