/* eslint-disable @typescript-eslint/no-unused-vars */
import { createReducer, on } from '@ngrx/store';
import {
    schedulerPatientNotesUpdates,
    createOrUpdateCaseloadAction,
    scheduleChangedAction,
    scheduleResetAction,
    schedulerChangedAction,
    schedulerResetAction,
    schedulerCalendarChangedAction,
    schedulerCalendarResetAction,
} from '@app/store/actions/index';
import { initialSchedulerCalendarState, scheduleTabInitialState } from '@app/store/selectors';
import { initialScheduler } from '@app/store/selectors';
import { cloneDeep } from 'lodash';
export const scheduleReducer = createReducer(
    scheduleTabInitialState,
    on(scheduleChangedAction, (state, { selectedTab }) => ({
        selectedTab: selectedTab,
    })),

    on(scheduleResetAction, (state) => ({
        ...scheduleTabInitialState,
    }))
);
export const schedulerCalendarReducer = createReducer(
    initialSchedulerCalendarState,
    on(schedulerCalendarChangedAction, (state, { selectedDate }) => ({
        selectedDate: selectedDate,
    })),

    on(schedulerCalendarResetAction, (state) => ({
        ...initialSchedulerCalendarState,
    }))
);
export const schedulerReducer = createReducer(
    initialScheduler,
    on(schedulerChangedAction, (state, { scheduler }) => ({
        patients: scheduler.patients,
        therapists: scheduler.therapists,
    })),
    on(createOrUpdateCaseloadAction, (state, { therapistId, caseload }) => {
        const therapistIndex = state.therapists.findIndex((therapists) => therapistId == therapists._id);
        const updatedArray = cloneDeep(state.therapists);
        const therapistData = updatedArray[therapistIndex];
        const availability = therapistData.availability.find((data) => data._id === caseload.availability);
        const caseLoadIndex = availability.caseloads.findIndex((item) => item._id == caseload._id);

        if (caseLoadIndex > -1) {
            availability.caseloads[caseLoadIndex] = caseload;
        } else {
            availability.caseloads.push(caseload);
        }
        //updatedArray[index] = newData;

        return {
            ...state,
            therapists: updatedArray,
        };
    }),
    on(schedulerPatientNotesUpdates, (state, { patientData, therapyDiscipline, patientNote, updatedCaseload }) => {
        //const updatedPatient = cloneDeep(patientData);
        const updatedPatientArray = cloneDeep(state.patients);
        const patientIndex = updatedPatientArray.findIndex((patient) => patient._id == patientData._id);
        const updatedPatient = cloneDeep(updatedPatientArray[patientIndex]);
        if (patientNote.noteType == 'eval') {
            updatedPatient.therapyAdmissions.find(
                (admission) => admission.therapyDiscipline == therapyDiscipline
            ).documentation['evaluation'][0].caseload = updatedCaseload;
        } else {
            updatedPatient.therapyAdmissions
                .find((admission) => admission.therapyDiscipline == therapyDiscipline)
                .documentation[patientNote.noteType + 'Note'].find(
                    (docNote) => docNote._id == patientNote._id
                ).caseload = updatedCaseload;
        }

        // const therapyAdmission = updatedPatient.therapyAdmissions.find(
        //     (admission) => admission.therapyDiscipline == therapyDiscipline
        // );
        // const selectedNote = therapyAdmission.documentation[patientNote.noteType + 'Note'].find(
        //     (docNote) => docNote._id == patientNote._id
        // );
        // selectedNote.caseload = updatedCaseload;
        const admissions = updatedPatientArray[patientIndex].therapyAdmissions.filter(
            (admission) => admission.therapyDiscipline != therapyDiscipline
        );
        if (admissions.length > 0) {
            admissions.forEach((admission) => {
                updatedPatient.therapyAdmissions.push(admission);
            });
        }
        updatedPatientArray[patientIndex] = updatedPatient;
        return {
            ...state,
            patients: updatedPatientArray,
        };
    }),
    on(schedulerResetAction, (state) => ({
        ...initialScheduler,
    }))
);
