<div *ngIf="alerts.length">
    <div>
        <div class="{{ cssClasses(alerts[alerts.length - 1]) }}">
            <span
                class="material-icons align-text-top"
                [ngClass]="alerts[alerts.length - 1].type == '4' ? 'text-black' : 'text-white'"
            >
                {{ alertTypes[alerts[alerts.length - 1].type] }}
            </span>
            <span [innerHTML]="alerts[alerts.length - 1].message"></span>

            <button
                type="button"
                (click)="removeAlert(alerts[alerts.length - 1])"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
            >
                <span
                    class="material-icons"
                    [ngClass]="alerts[alerts.length - 1].type == '4' ? 'text-black' : 'text-white'"
                >
                    close
                </span>
            </button>
        </div>
    </div>
</div>
