
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AuthService } from '@app/services';
import { NguCarouselConfig } from '@ngu/carousel';

@Component({
    selector: 'app-document-pending-stack',
    templateUrl: './document-pending-stack.component.html',
    styleUrls: ['./document-pending-stack.component.scss'],
})
export class DocumentPendingStackComponent implements OnInit {
    @Input() pocData: any = [];
    @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent!: ElementRef<any>;
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Output() actionBtn = new EventEmitter<{}>();
    public carouselTileConfig: NguCarouselConfig = {
      grid: { xs: 1, sm: 1, md: 1, lg: 5, all: 0 },
      speed: 250,
      point: {
        visible: true
      },
      touch: true,
      loop: false,
      interval: { timing: 1500 },
      animation: 'lazy'
    };
    showLeftArrow = false; // Controls chevron_left visibility
    showRightArrow = true; // Controls chevron_right visibility
    constructor(private authService: AuthService) {}

    public scrollRight(): void {
        this.widgetsContent.nativeElement.scrollTo({
            left: this.widgetsContent.nativeElement.scrollLeft + 377,
            behavior: 'smooth',
        });
        this.checkScrollPosition();
    }

    public scrollLeft(): void {
        this.widgetsContent.nativeElement.scrollTo({
            left: this.widgetsContent.nativeElement.scrollLeft - 377,
            behavior: 'smooth',
        });
        this.checkScrollPosition();
    }
    // colworx-ak: add outstandingDays
    outstandingDays(params: any): string {
        const currentDate = new Date();
        const docDate = new Date(params?.dueDate);
    
        // Reset the time part of both dates to midnight
        currentDate.setHours(0, 0, 0, 0);
        docDate.setHours(0, 0, 0, 0);
    
        // Calculate the difference in days
        const diffDays = params?.diffDays;
    
        let dayText;
        if (diffDays === 0) {
          dayText = 'Today';
        } else if (diffDays === 1) {
          dayText = '1 day';
        } else {
          dayText = `${diffDays} days`;
        }
    
        // Return the formatted string
        return `${dayText}`;
      }
    // Method to check the scroll position and show/hide arrows accordingly
    checkScrollPosition(): void {
        const scrollLeft = this.widgetsContent.nativeElement.scrollLeft;
        const scrollWidth = this.widgetsContent.nativeElement.scrollWidth;
        const clientWidth = this.widgetsContent.nativeElement.clientWidth;

        // Hide chevron_left if scrolled all the way to the left
        this.showLeftArrow = scrollLeft > 0;

        // Hide chevron_right if scrolled all the way to the right
        this.showRightArrow = scrollLeft < scrollWidth - clientWidth;
    }

    onActionClick(action: string, rowData: any) {
        const obj = {
            action: action,
            rowdata: [rowData],
        };
        this.actionBtn.emit(obj);
    }
    ngOnInit(): void {
        // console.log('child', this.pocData);
    }

}
