<table #progressNoteReport id="progressNoteReport" class="print-table" *ngIf="isDataLoaded">
    <thead #header id="header" class="print-header">
        <!-- <th class="n-header">
            <app-header [isFirst]="false" [type]="'progressNote'"></app-header>
        </th> -->
        <tr class="print-first-header">
            <app-header [isFirst]="true" [type]="'progressNote'" [toExport]="toExport"></app-header>
        </tr>
    </thead>
    <tr style="page-break-before: avoid">
        <app-grid-layout [type]="'progressNote'" [key]="'medicalDiagnosis'"></app-grid-layout>
    </tr>
    <tr>
        <app-grid-layout [type]="'progressNote'" [key]="'treatmentDiagnosis'"></app-grid-layout>
    </tr>
    <tr>
        <app-grid-layout [gridType]="'NonHeaderGrid'" [type]="'progressNote'" [key]="'precautions'"></app-grid-layout>
    </tr>
    <tr>
        <app-grid-layout
            [gridType]="'NonHeaderGrid'"
            [type]="'progressNote'"
            [key]="'contraindications'"
        ></app-grid-layout>
    </tr>
    <tr>
        <app-paragraph [type]="'progressNote'" [key]="'patientAssessmentSinceLastTreatment'"></app-paragraph>
    </tr>
    <tr>
        <app-grid-layout [gridType]="'DynamicGrid'" [type]="'progressNote'" [key]="'goals'"></app-grid-layout>
    </tr>
    <tr>
        <app-paragraph [type]="'progressNote'" [key]="'clinicalImpression'"></app-paragraph>
    </tr>
    <tr>
        <app-paragraph [type]="'progressNote'" [key]="'overallPlanAndUpdates'"></app-paragraph>
    </tr>
    <tr>
        <app-grid-layout [gridType]="'TreeGrid'" [type]="'progressNote'" [key]="'addendum'"></app-grid-layout>
    </tr>
    <tr [ngClass]="{'print-footer': !withEdits}" *ngIf="isDataLoaded">
        <app-signature [type]="'progressNote'" [toShowPhysician]="false"></app-signature>
    </tr>
    <tr class="print-footer" *ngIf="withEdits">
        <app-grid-layout [type]="'progressNote'" [key]="'changeHistoryPrint'"></app-grid-layout>
    </tr>
</table>
