import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { TherapyAdmission, TreatmentDiagnosis } from '@app/interfaces';
import { ClinicalCategoriesService } from '@app/services';
import { RowClassRules, GridApi } from 'ag-grid-community';
import * as _ from 'lodash';

import { ActionsCellRendererComponent } from '@app/data-grid/cell-renderers/actions-cell-renderer/actions-cell-renderer.component';
import { GridActionsService } from '@app/data-grid/services/grid-actions.service';
import { GridRowAction } from '@app/data-grid/interfaces/grid-row-action';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-treatment-diagnosis-card',
    templateUrl: './treatment-diagnosis-card.component.html',
    styleUrls: ['./treatment-diagnosis-card.component.scss'],
})
export class TreatmentDiagnosisCardComponent implements OnChanges {
    public therapyDiscipline;
    private readonly onDestroy = new Subject<void>();

    @Input() treatmentDiagnosisInput: TreatmentDiagnosis[];
    @Input() heading = '';
    @Input() isReadOnly = false;
    @Input() treatmentDiagnosisData = [];
    @Output() treatmentDiagnosisEvent: EventEmitter<TreatmentDiagnosis[]> = new EventEmitter<TreatmentDiagnosis[]>();
    @Output() treatmentDiagnosisSave: EventEmitter<TreatmentDiagnosis[]> = new EventEmitter();

    state: 'zero' | 'one' = 'zero';
    loading = false;
    isDone = false;
    gridApi: GridApi;
    currentAdmission: TherapyAdmission;
    therapyAdmissions$: any;
    data: any[] = [];
    selectedData: any[] = [];

    columns = [
        { headerName: 'Code', field: 'code', resizable: true, width: 10 },
        { headerName: 'Description', field: 'description', resizable: true },
        {
            headerName: 'Actions',
            field: 'actions',
            type: 'rightAligned',
            resizable: true,
            cellRenderer: ActionsCellRendererComponent,
            cellRendererParams: {
                delete: true,
                gridName: 'treatmentDiagnosis',
            },
        },
    ];
    editColumns = [
        { headerName: 'Code', field: 'code', resizable: true },
        { headerName: 'Description', field: 'description', resizable: true },
    ];
    treatmentDiagnosisColumns = [
        { headerName: 'Code', field: 'code', width: 80 },
        { headerName: 'Description', field: 'description', width: 280 },
    ];
    // Row class rules to display
    rowClassRules: RowClassRules = {
        'bg-primary-50': function (params) {
            return this.selectedData.findIndex((object) => object.code === params.data.code) !== -1;
        }.bind(this),
        'text-secondary': function (params) {
            return this.selectedData.findIndex((object) => object.code === params.data.code) !== -1;
        }.bind(this),
    };
    constructor(
        private clinicalCategoryService: ClinicalCategoriesService,
        private gridActionService: GridActionsService,
        private route: ActivatedRoute
    ) {
        gridActionService.action.subscribe((params) => {
            if (params.actionType === 'delete' && params.gridName === 'treatmentDiagnosis') {
                this.deleteSelectedRowItem(params);
            }
        });
        this.route.queryParamMap.subscribe({
            next: (params) => {
                this.therapyDiscipline = params.get('discipline');
            },
        });
    }

    ngOnChanges(): void {
        if (this.treatmentDiagnosisInput?.length > 0) {
            this.selectedData = this.treatmentDiagnosisInput;
            this.data = this.treatmentDiagnosisInput;
            this.isDone = true;
            this.state = 'one';
        }
        if (this.isReadOnly) {
            this.setReadonlyData();
        }
    }
    setState(value: 'zero' | 'one') {
        this.state = value;
    }
    clinicalCategoriesSearch(text: string) {
        this.loading = true;
        this.clinicalCategoryService.categorySearch(text, '', '').subscribe(
            (data) => {
                this.loading = false;
                this.data = data.map((item) => ({ code: item.code, description: item.description }));
            },
            () => {
                this.loading = false;
                this.data = [];
            }
        );
    }
    // For Ag Grid
    // Working with clinical categories
    addSelectedItem(event: any) {
        const data = event.data;
        const index = this.selectedData.findIndex((object) => object.code === data.code);
        if (index == -1) {
            data.date = '';
            this.selectedData = [...this.selectedData, data];
        } else if (index >= 0) {
            this.selectedData = this.selectedData.filter((item) => item.code !== data.code);
        }
        this.treatmentDiagnosisEvent.emit(this.selectedData);
    }
    removeSelectedItem(data: any) {
        this.selectedData = this.selectedData.filter((item) => item.code !== data?.code);
        this.treatmentDiagnosisEvent.emit(this.selectedData);
        this.treatmentDiagnosisSave.emit(this.selectedData);

        // clear list ONLY when search 'x' is clicked
        if (!data) {
            this.data = [];
        }
    }
    setLoading(value: boolean) {
        this.loading = value;
    }
    toggleIsDone(): void {
        this.isDone = !this.isDone;
        if (this.isDone === true) {
            this.gridApi.setColumnDefs(this.columns);
        } else {
            this.gridApi.setColumnDefs(this.editColumns);
        }
        this.gridApi.sizeColumnsToFit();
        this.treatmentDiagnosisEvent.emit(this.selectedData);
        if (this.isDone === true) this.treatmentDiagnosisSave.emit(this.selectedData);
    }
    // Grid API functions
    initGrid(event: any) {
        this.gridApi = event.api;
    }
    redrawRows() {
        this.gridApi.redrawRows();
    }
    // Grid renderer functions
    setRendererDate(param: any) {
        this.selectedData = this.selectedData.map((item) => {
            const deepItem = _.cloneDeep(item);
            if (deepItem.code === param.data.code) {
                deepItem.date = param.value;
            }
            return deepItem;
        });
        this.treatmentDiagnosisEvent.emit(this.selectedData);
    }
    deleteSelectedRowItem(params: GridRowAction) {
        this.selectedData = this.selectedData.filter((item) => item.code != params.data.code);
        this.treatmentDiagnosisEvent.emit(_.cloneDeep(this.selectedData));
        this.treatmentDiagnosisSave.emit(this.selectedData);
    }
    setReadonlyData() {
        //this.medicalDiagnosisInput = this.medicalDiagnosis;
        if (this.treatmentDiagnosisData?.length > 0) {
            this.isDone = true;
            this.state = 'one';
        }
    }
    ngOnDestroy(): void {
        this.onDestroy.next();
    }
}
