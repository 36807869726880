import { Component } from '@angular/core';
import { GridActionsService } from '@app/data-grid/services/grid-actions.service';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-cpt-cell-renderer',
    templateUrl: './cpt-cell-renderer.component.html',
    styleUrls: ['./cpt-cell-renderer.component.scss'],
})
export class CptCellRendererComponent implements ICellRendererAngularComp {
    params: any;

    constructor(private gridActionService: GridActionsService) {}

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    refresh() {
        return false;
    }

    timeClicked() {
        if (this.params.data.time === 'Add Time' || this.params.data.minutes === 'Add Time') {
            const { data, rowIndex, gridName } = this.params;
            this.gridActionService.setSelectedAction('addTime', data, rowIndex, gridName);
        }
    }
}
