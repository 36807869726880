import {
    findClosestIndex,
    getAllHighlightsSorted,
    getHighlightsCounter,
    highlightBlanks,
    isLastSelectedItem,
} from './../../helpers/utils/string.utils';
import { IHighlightItem, ICursorHighlight, ISelectionRange, ISelectedItem } from './../../interfaces/ui';
/* eslint-disable @typescript-eslint/no-empty-function */
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Optional,
    Output,
    ViewChild,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';
import { DynamicContentCardView } from '@app/interfaces';
import { debounceTime, distinctUntilChanged, fromEvent, Subject, takeUntil, tap } from 'rxjs';
import { applyHighlights } from '@app/helpers/utils/string.utils';

interface SelectionBoxOutput {
    selection: string;
}
@Component({
    selector: 'app-text-box',
    templateUrl: './text-box.component.html',
    styleUrls: ['./text-box.component.scss'],
})
// export class TextBoxComponent implements ControlValueAccessor, OnDestroy, OnChanges, AfterViewInit {
export class TextBoxComponent implements ControlValueAccessor, OnDestroy, AfterViewInit, OnChanges {
    @Output() selectionEvent: EventEmitter<SelectionBoxOutput> = new EventEmitter<SelectionBoxOutput>();
    @Output() changesHappened: EventEmitter<string> = new EventEmitter<string>();
    @Output() onBlur: EventEmitter<string> = new EventEmitter<string>();
    @Output() onKeyUp: EventEmitter<string> = new EventEmitter<string>();
    @Output() isFilled: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() disableSignButton: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() emitData: EventEmitter<string> = new EventEmitter<string>(); // Emit data when user will click on save button
    @Input() isSaveButton = false; // It should be true where this text-box is used with Save button as well
    @Input() keyUpDelay = 3000;
    @Input() suggestionsList: string[] = [];
    @Input() placeholder = 'Type here';
    @Input() label: string;
    @Input() isSuggestionBox = false;
    @Input() backgroundClass = 'bg-body-background';
    @Input() suggestionBoxValue = '';
    @Input() class: string;
    @Input() borderClass = false;
    @Input() actions: ('cancel' | 'save')[];
    @Input() rows = 5;
    @Input() labelClass = 'text-secondary';
    @Input() maxLength = 1000;
    @ViewChild('suggestionBox') public suggestionBox;
    @ViewChild('suggestionListElement')
    suggestionListElement: ElementRef<HTMLElement>;
    selectionRange: ISelectionRange = { start: 0, end: 0 };
    suggestionsListFiltered = [];
    // Highlighted suggestion is the complete paragraph that is shown and is highlighted currently in the suggestion list.
    highlightedSuggestion = '';
    isSuggestionEnabled = true;
    selectAction: DynamicContentCardView = 'CONTENT';
    @Input() disabled = false;
    private readonly onDestroy = new Subject<void>();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    propagateChange = (_: any) => {};
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onTouched = (_: any) => {};
    // Highlight and selection features
    counter = 0;
    @Input() highlightTexts: IHighlightItem[] = [];
    @ViewChild('backdrop') $backdrop: ElementRef<HTMLDivElement>;
    @ViewChild('suggestionBox') $textArea: ElementRef<HTMLTextAreaElement>;
    highlights: ICursorHighlight[];

    constructor(@Optional() public readonly ngControl: NgControl) {
        if (ngControl) ngControl.valueAccessor = this;
    }

    ngOnChanges(): void {
        this.isSuggestionEnabled = !this.suggestionBoxValue?.length;
    }

    ngAfterViewInit(): void {
        this.textBoxAdopter();
        this.highlightedSuggestion = this.suggestionsList?.[0];
        if (this.suggestionBox && this.suggestionBox.nativeElement) {
            fromEvent(this.suggestionBox.nativeElement, 'keyup')
                .pipe(
                    debounceTime(1500),
                    distinctUntilChanged(),
                    tap(() => {
                        // If there is save button, then that button will save data as soon as user click it
                        if (!this.isSaveButton && this.counter === 0) {
                            this.disableSignButton.emit(false);
                            this.onBlur.emit(this.suggestionBox?.nativeElement?.value);
                        }
                    })
                )
                .subscribe();
        }
    }
    ngOnDestroy(): void {
        this.onDestroy.next();
    }
    valueChange(value: any): void {
        this.onChangesHappened(value);
    }
    onSearch(event) {
        // fix for an edge case when removing all text after selection disables the suggestionIcon.
        if (event?.target?.value === '') {
            this.isSuggestionEnabled = true;
        }
        if (!this.isSuggestionEnabled || !this.isSuggestionBox) return;
        this.suggestionsListFiltered = this.suggestionsList?.filter((item) =>
            item.toLowerCase().includes(event.target.value.toLowerCase())
        );
    }
    onBlurHandler() {
        // As user will click save button, it will trigger blur and data will be saved
        if (this.isSaveButton) {
            this.emitData.emit(this.suggestionBox?.nativeElement?.value);
        }
        if (this.isSuggestionBox) {
            this.suggestionsListFiltered = [];
        }
        // this.onChangesHappened(value);
    }
    onKeyUpHandler(value: string) {
        this.onKeyUp.emit(value);
    }
    onSuggestionToggle() {
        this.isSuggestionEnabled = !this.isSuggestionEnabled;
        this.suggestionsListFiltered = this.suggestionsList;
        if (this.isSuggestionEnabled) {
            // When SuggestionBox is opened for an input, it should focus for that input
            this.suggestionBox.nativeElement.focus();
        }
    }

    onSuggestionSelect(event: string) {
        this.valueChange(event);
        this.selectionEvent.emit({ selection: this.suggestionBoxValue });
        this.onSuggestionToggle();
        // this.changesHappened.emit(this.suggestionBoxValue);
        // setTimeout(() => this.suggestionBox.nativeElement.focus(), 100);
        this.suggestionsListFiltered = [];
        this.suggestionBox.nativeElement.focus();
        // Counter = 0 means there is no highlighted text
        if (this.counter === 0) {
            // If user is selecting text from suggestion list, it should save it as well
            this.onBlur.emit(event);
        } else {
            // As the selected text has highlighted texts so disabling sign/resign button.
            this.disableSignButton.emit(true);
        }
    }

    onChangesHappened(event: string) {
        this.propagateChange(event);
        this.suggestionBoxValue = event;
        this.counter = getHighlightsCounter(this.suggestionBoxValue, this.highlightTexts);
        // this.changesHappened.emit(event);
    }
    get isRequired(): boolean {
        if (this.ngControl) {
            const control: AbstractControl = this.ngControl.control;
            if (control.validator === null) return false;
            return control.validator(control)?.required ?? false;
        }
    }

    get hasError(): boolean {
        if (this.ngControl) {
            const control: AbstractControl = this.ngControl.control;
            if (control && (control.dirty || control.touched) && control.invalid) return true;
            return false;
        }
    }

    writeValue(obj: any): void {
        this.suggestionBoxValue = obj;
        // For suggestionBox highlight and selection features
        if (obj !== undefined) {
            this.suggestionBoxValue = obj;
        }
    }
    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
    public textBoxAdopter() {
        fromEvent(this.suggestionBox?.nativeElement, 'blur')
            .pipe(takeUntil(this.onDestroy))
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .subscribe((e: any) => {
                this.onBlurHandler();
                this.counter = getHighlightsCounter(this.suggestionBoxValue, this.highlightTexts);
            });
        fromEvent(this.suggestionBox?.nativeElement, 'keyup')
            .pipe(takeUntil(this.onDestroy))
            .subscribe((e: any) => {
                this.onKeyUpHandler(e.target.value);
                this.counter = getHighlightsCounter(this.suggestionBoxValue, this.highlightTexts);
            });
        // Handling suggestions
        fromEvent(this.suggestionBox?.nativeElement, 'keydown')
            .pipe(takeUntil(this.onDestroy))
            .subscribe((e: any) => {
                if (this.suggestionsListFiltered?.length > 0) {
                    // Key arrow up
                    if (e.keyCode === 38) this.highlightPreviousSuggestion();
                    // Key arrow down
                    if (e.keyCode === 40) this.highlightNextSuggestion();
                    // Key enter/return
                    if (e.keyCode === 13) {
                        e.preventDefault();
                        this.onSuggestionSelect(this.highlightedSuggestion);
                        this.highlightedSuggestion = this.suggestionsList[0];
                        this.highlights = getAllHighlightsSorted(this.suggestionBoxValue, this.highlightTexts, [
                            'start',
                        ]);
                        // if (this.highlights.length > 0) this.setSelectionRange({ start: this.highlights[0].start, end: this.highlights[0].end });
                        this.focusHandler();
                    }
                    // If there are suggestions then fix the scroll of suggestions
                    if (this.suggestionListElement?.nativeElement) {
                        this.suggestionListElement.nativeElement.childNodes.forEach((node: any) => {
                            if (
                                node.id ===
                                this.getSelectionId(this.suggestionsList.indexOf(this.highlightedSuggestion))
                            ) {
                                node.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'nearest',
                                    inline: 'nearest',
                                });
                            }
                        });
                    }
                }
                // Handle Tab key
                if (e.keyCode === 9) {
                    // Refresh selections
                    this.highlights = getAllHighlightsSorted(this.suggestionBoxValue, this.highlightTexts, ['start']);
                    if (this.highlights.length > 0) {
                        if (!isLastSelectedItem(this.highlights, e.target.selectionStart)) {
                            e.preventDefault();
                            this.selectNextItem(e.target.selectionStart);
                        }
                    }
                }
            });
    }
    getSelectionId(index: number) {
        return `${index}-selection-item`;
    }
    setHighlightedItem(index: number) {
        this.highlightedSuggestion = this.suggestionsListFiltered[index];
    }
    // Highlight and selection features
    get highlightedText() {
        return applyHighlights(this.suggestionBoxValue, this.highlightTexts, this.counter, this.isFilled);
    }
    handleScroll() {
        const scrollTop = this.$textArea.nativeElement.scrollTop;
        this.$backdrop.nativeElement.scrollTop = scrollTop;

        const scrollLeft = this.$textArea.nativeElement.scrollLeft;
        this.$backdrop.nativeElement.scrollLeft = scrollLeft;
    }
    // To handle click events
    clickHandler(e) {
        setTimeout(() => this.selectBlankValues(e), 0);
    }
    focusHandler(event?) {
        this.highlights = getAllHighlightsSorted(this.suggestionBoxValue, this.highlightTexts, ['start']);
        if (this.highlights.length > 0) {
            this.setSelectionRange({
                start: this.highlights[0].start,
                end: this.highlights[0].end,
            });
        }
        if (event?.target?.value === '' && this.isSuggestionEnabled) {
            this.onSearch(event);
        }
    }
    selectBlankValues(e) {
        // to select blank values
        const { selectionRange, highlights } = highlightBlanks(
            { start: e.selectionStart, end: e.selectionEnd },
            this.suggestionBoxValue,
            this.highlightTexts
        );
        if (selectionRange) {
            this.selectionRange = selectionRange;
            this.setSelectionRange({
                start: selectionRange.start,
                end: selectionRange.end,
            });
            // e.setSelectionRange(this.selectionRange.start, this.selectionRange.end);
            this.highlights = highlights;
        }
    }
    selectNextItem(caretPosition) {
        // Find Next item to highlight by current caret position
        if(!this.highlights.length) return 
        const highLightsStartPositions = this.highlights.map(highlight => highlight.start)
        const closestIndex = findClosestIndex(highLightsStartPositions, caretPosition)

        const next: ISelectedItem = {
            item: this.highlights[closestIndex + 1],
            index: closestIndex + 1,
        };
        // If item is not undefined
        if (next.item !== undefined) {
            this.setSelectionRange({
                start: next.item.start,
                end: next.item.end,
            });
        }
    }
    highlightNextSuggestion() {
        const index = this.suggestionsList.indexOf(this.highlightedSuggestion);
        if (index !== this.suggestionsList.length - 1) this.highlightedSuggestion = this.suggestionsList[index + 1];
    }
    highlightPreviousSuggestion() {
        const index = this.suggestionsList.indexOf(this.highlightedSuggestion);
        if (!(index <= 0)) this.highlightedSuggestion = this.suggestionsList[index - 1];
    }
    setSelectionRange(range: ISelectionRange) {
        this.selectionRange.start = range.start;
        this.selectionRange.end = range.end;
        this.suggestionBox.nativeElement.setSelectionRange(this.selectionRange.start, this.selectionRange.end);
    }
}
