/* eslint-disable spellcheck/spell-checker */
export interface GridRowAction {
    actionType: string;
    rowIndex: number;
    gridName: string;
    data; // dynamic for each table
    headerName?: string;
}

export const GridActionConstants = {
    EDIT: 'edit',
    DELETE: 'delete',
    MAKE_A_GOAL: 'make a goal',
    STATUS_CHANGED: 'status changed',
};

export const DeleteSectionColumn = [
    'medicaldiagnosis',
    'treatmentdiagnosis',
    'precautioncols',
    'contraindicationcols',
    'cptcodes',
    'pastmedicalhistorycols',
    'medicationcols',
    'goalscols',
    'socialcols',
    'environmentalcols',
    'priorhospitalizationstays',
    'notes',
];
