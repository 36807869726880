/**
 * This file contains the pipes for extracting data from planned results only
 */
import { Pipe, PipeTransform } from '@angular/core';
import { getPlansForDayFromPlannedData, selectDayFromPlanTherapy, findPlannedDay } from '@app/helpers/utils/data.utils';
import { ITherapyPlan } from '@app/interfaces';
import { TherapyDiscipline } from '@app/interfaces/therapyAdmission';
// Finds plan from a given date from calendarData and returns that item from list
@Pipe({
    name: 'findPlannedDay',
    pure: true,
})
export class FindPlannedDayPipe implements PipeTransform {
    transform(calendarData: any[], date: Date) {
        return findPlannedDay(calendarData, date);
    }
}

@Pipe({
    name: 'getDisciplinePlannedDay',
    pure: true,
})
export class GetDisciplinePlannedDayPipe implements PipeTransform {
    transform(planTherapy: any, date: Date) {
        return selectDayFromPlanTherapy(planTherapy, date);
    }
}

@Pipe({
    name: 'getPlansForDayFromPlannedData',
    pure: true,
})

// Uses utility get getPlansForDayFromPlannedData
export class GetPlansForDayFromPlannedDataPipe implements PipeTransform {
    transform(calendarData: any[], date: Date) {
        return getPlansForDayFromPlannedData(calendarData, date);
    }
}

@Pipe({
    name: 'selectDiscipline',
    pure: true,
})
export class SelectDisciplinePipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    transform(therapyPlan: ITherapyPlan[], discipline: TherapyDiscipline) {
        // therapyPlan.forEach((plan) => {});
        return [];
    }
}
