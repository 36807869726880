import { Injectable } from '@angular/core';
import { getPlansForDayFromPlannedData } from '@app/helpers/utils/data.utils';
import { toNumber } from 'lodash';
import { BehaviorSubject } from 'rxjs';
@Injectable({
    providedIn: 'root',
})
export class CalculateTotalHoursService {
    totals$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
    calculateTotals(calendarData: any, weekView) {
        const _totals = [];
        weekView.forEach((weekDay) => {
            const plans: any = getPlansForDayFromPlannedData(calendarData, weekDay.date);
            const total = { planned: 0, actual: 0 };
            plans.forEach((plan) => {
                if (plan?.day !== undefined) {
                    if (!isNaN(toNumber(plan?.individual?.planned))) {
                        total.planned = total.planned + toNumber(plan.individual.planned);
                    }
                    if (!isNaN(toNumber(plan?.concurrent?.planned))) {
                        total.planned = total.planned + toNumber(plan.concurrent.planned);
                    }
                    if (!isNaN(toNumber(plan?.group?.planned))) {
                        total.planned = total.planned + toNumber(plan.group.planned);
                    }

                    if (!isNaN(toNumber(plan?.individual?.actual))) {
                        total.actual = total.actual + toNumber(plan.individual.actual);
                    }
                    if (!isNaN(toNumber(plan?.concurrent?.actual))) {
                        total.actual = total.actual + toNumber(plan.concurrent.actual);
                    }
                    if (!isNaN(toNumber(plan?.group?.actual))) {
                        total.actual = total.actual + toNumber(plan.group.actual);
                    }
                }
            });
            _totals.push(total);
        });
        return this.totals$.next(_totals);
    }
}
