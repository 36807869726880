<div>
    <div *ngIf="!loading && !npcList?.length && !activities">
        <div class="custom-card">
            <div class="header">
                <p>{{ title }}</p>
            </div>
            <div class="body card-padding">
                <div class="no-activities-parent">
                    <div class="no-activities">No Timecard Activities Added</div>
                    <div>
                        <button (click)="addActivities()" class="innova-btn btn-secondary ml-1">
                            Add Timecard Activities
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!isRehabCenter" class="no-activities-parent">
            <div class="btns">
                <button (click)="backButton()" class="innova-btn button-skip btn-secondary ml-1 cancel-btn">
                    {{ backTitle }}
                </button>
                <button (click)="skipNow()" class="innova-btn button-skip btn-secondary ml-1 cancel-btn">Skip</button>
            </div>
        </div>
    </div>

    <div *ngIf="(!loading && npcList?.length) || activities">
        <div class="custom-card">
            <div class="header">
                <p>{{ title }}</p>
                <span class="cursor-pointer" [ngClass]="{ 'plus-icon': isEditMode }" (click)="addNPC()">
                    <img loading="lazy" class="edit-icon" [src]="isEditMode ? addIcon : editIcon" />
                </span>
            </div>

            <form [formGroup]="form" (ngSubmit)="openConfirmationModal()">
                <table class="data-table">
                    <thead>
                        <tr>
                            <ng-container *ngFor="let header of headerSizes">
                                <th [style.width]="header.size" [style.paddingRight]="header.padding">
                                    {{ header.title }}
                                </th>
                            </ng-container>
                        </tr>
                    </thead>
                    <tbody formArrayName="rows">
                        <ng-container *ngFor="let item of rowForms.controls; let i = index">
                            <tr [formGroupName]="i" *ngIf="!item.get('isDeleted').value">
                                <td [ngClass]="isEditMode ? 'editable-name-padding' : 'name-padding'">
                                    <input
                                        placeholder="Enter Activity Name"
                                        type="text"
                                        class="form-control border"
                                        [ngClass]="{
                                            'remove-border': !isEditMode || !item.get('isDeletable').value,
                                            'remove-padding': !item.get('isDeletable').value && isEditMode
                                        }"
                                        formControlName="activity"
                                    />
                                </td>
                                <td
                                    [ngClass]="{
                                        'disable-select': !isEditMode || !item.get('isDeletable').value,
                                        'select-padding': !isEditMode || !item.get('isDeletable').value,
                                        'editable-select-padding': isEditMode && item.get('isDeletable').value,
                                        'remove-padding': !item.get('isDeletable').value
                                    }"
                                    class="select-unit"
                                >
                                    <app-select
                                        formControlName="unit"
                                        [options]="units"
                                        [selectedValue]="unit"
                                    ></app-select>
                                </td>

                                <td
                                    [ngClass]="{
                                        'disable-select': !isEditMode,
                                        'select-padding': !isEditMode,
                                        'editable-select-padding': isEditMode,
                                        'labour-select':
                                            isEditMode && item.get('activity')?.value?.toLowerCase() === 'labor time'
                                    }"
                                >
                                    <label [ngClass]="{ 'disable-click': !isEditMode }" class="checkbox-label">
                                        <label class="billOptions">
                                            <app-select
                                                formControlName="billable"
                                                [options]="billOptions"
                                                [selectedValue]="
                                                    item.get('billable').value ? 'Billable' : 'Non-Billable'
                                                "
                                                (optionChanged)="updateOptions($event, item.value)"
                                            ></app-select>
                                        </label>
                                    </label>
                                </td>
                                <td class="rate-cell" [ngClass]="{ 'editable-rate-padding': isEditMode }">
                                    <div
                                        *ngIf="
                                            item.get('billable').value === true ||
                                            item.get('billable').value === 'Billable'
                                        "
                                        [ngClass]="{
                                            'input-error': input.value?.length > 4,
                                            'edit-mode-input': isEditMode,
                                            'input-container': !isEditMode
                                        }"
                                    >
                                        <span
                                            *ngIf="!disableRate(item.value) && isEditMode && item.value.billable"
                                            [ngClass]="{
                                                'editable-currency': !item.get('billable').value,
                                                dollar: item.get('billable').value,
                                                'greyed-out':
                                                    !item.value.isDeletable &&
                                                    item.get('activity').value?.toLowerCase() !== 'mileage'
                                            }"
                                            >$</span
                                        >
                                        <input
                                            #input
                                            type="text"
                                            class="form-control"
                                            [placeholder]="getPlaceholderText(item.value)"
                                            (input)="validateInput($event)"
                                            maxlength="10"
                                            [ngClass]="{
                                                'remove-border':
                                                    !isEditMode ||
                                                    !item.get('billable').value ||
                                                    disableRate(item.value),
                                                'non-billable': !item.get('billable').value,
                                                billable: item.get('billable').value,
                                                'greyed-out': disableRate(item.value)
                                            }"
                                            formControlName="rate"
                                        />
                                        <span
                                            *ngIf="
                                                !disableRate(item.value) &&
                                                isEditMode &&
                                                (item?.value?.billable || item?.value?.billable == 'Billable') &&
                                                item?.value?.unit
                                            "
                                            [ngClass]="{
                                                'disable-rate':
                                                    !item.get('billable').value || item?.value?.billable !== 'Billable',
                                                'adjust-width': !isEditMode
                                            }"
                                            class="rate"
                                            >{{ getUnit(item.get('unit').value) }}</span
                                        >
                                    </div>

                                    <div
                                        *ngIf="
                                            item.get('billable').value === false ||
                                            item.get('billable').value === 'Non-Billable'
                                        "
                                        [ngClass]="isEditMode ? 'edit-mode-input' : 'input-container'"
                                    >
                                        <input
                                            type="text"
                                            class="form-control greyed-out"
                                            [placeholder]="
                                                individualRatesActivity.includes(item.get('activity').value)
                                                    ? 'Individual rates'
                                                    : 'Non Billable'
                                            "
                                        />
                                    </div>
                                </td>

                                <td
                                    class="end-date"
                                    [ngClass]="{
                                        'disable-date-picker':
                                            item.get('activity').value?.toLowerCase() === 'labor time',
                                        'disable-calendar': !isEditMode
                                    }"
                                >
                                    <app-date-picker
                                        (ngModelChange)="endDateSelected($event, item, i)"
                                        [placeholder]="
                                            item.get('activity').value === 'Labor Time' ? 'Open Ended' : 'MM/DD/YYYY'
                                        "
                                        formControlName="endDate"
                                    >
                                    </app-date-picker>
                                </td>
                                <td>
                                    <span *ngIf="isEditMode" class="cursor-pointer delete-icon">
                                        <span
                                            [ngClass]="
                                                item.get('isDeletable').value ? 'trash-icon' : 'disabled-trash-icon'
                                            "
                                            class="material-symbols-outlined"
                                            (click)="deleteNPC(i)"
                                        >
                                            delete
                                        </span>
                                        <!-- <img loading="lazy" src="./../../../assets/icons/delete-icon2.svg" /> -->
                                    </span>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </form>
        </div>
        <div *ngIf="isEditMode" class="no-activities-parent">
            <div class="btns">
                <button
                    (click)="handleCancel()"
                    [ngClass]="{ 'change-border': isRehabCenter }"
                    class="innova-btn button-skip btn-secondary ml-1 cancel-btn"
                >
                    {{ backTitle }}
                </button>
                <button
                    [ngbTooltip]="isRateInvalid()?toolTipTemplate:''"
                    placement="top"
                    container="body"
                    [tooltipClass]="'ngb-tooltip-custom-cont'"
                    [disabled]="isMandatoryFieldsFilled() || (npcList?.length && isDataSame()) || isDataSaving || isRateInvalid()"
                    (click)="openConfirmationModal()"
                    class="btn-secondary innova-btn innova-btn-lg save-btn"
                >
                    <span *ngIf="isDataSaving" class="spinner-border spinner-border-sm mr-1 text-primary"></span>

                    Save
                </button>
            </div>
        </div>
    </div>
</div>
<ng-template #toolTipTemplate>
    <div><span class="text-danger">Maximum value exceeded for activity rate.</span>&nbsp;</div>
</ng-template>
<div *ngIf="loading">
    <ng-container>
        <div>
            <br />
            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
            <hr class="table-footer" />
            <div class="pagination-loader">
                <ngx-skeleton-loader [theme]="{ width: '10em', 'margin-right': '0' }"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{ width: '10em', 'margin-left': '5em' }"></ngx-skeleton-loader>
            </div>
        </div>
    </ng-container>
</div>
