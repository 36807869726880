import { Component, Inject } from '@angular/core';
import { DIALOG_DATA } from '@app/helpers/dialog-tokens';
import { DialogRef } from '@app/services';

@Component({
  selector: 'app-deletion-confirmation-dialog',
  templateUrl: './deletion-confirmation-dialog.component.html',
  styleUrls: ['./deletion-confirmation-dialog.component.scss'],
})
export class DeletionConfirmationDialogComponent {
  heading = '';
  message = '';
  resourceType = '';
  button1Text = 'Delete';
  button2Text = 'Cancel';

  constructor(@Inject(DIALOG_DATA) private data, private dialogRef: DialogRef) {
    this.heading = data?.heading;
    this.resourceType = data?.resourceType ? data.resourceType : 'item';
    this.message = data?.message;
    this.button1Text = data?.button1Text || this.button1Text;
    this.button2Text = data?.button2Text || this.button2Text;
  }

  deleteAction(isDeleted) {
    this.dialogRef.close(isDeleted);
  }
  /*deleteAction() {
  this.dialogRef.events.next({
    type: 'confirm',
    data: null,
  });
}
close() {
  this.dialogRef.events.next({
    type: 'close',
    data: null,
  });
}*/
}
