<div>
    <button
        *ngFor="let _button of _buttons"
        type="button"
        [class.selected-button]="_button?.toLowerCase() === selectedButton?.toLowerCase()"
        [class.unselected-button]="_button?.toLowerCase() !== selectedButton?.toLowerCase()"
        (click)="setSelectedButton(_button)"
        class="tab-small h6 font-weight-bold body-medium-semibold"
    >
        <span [class.opacity-10]="_button?.toLowerCase() !== selectedButton?.toLowerCase()">
            {{ _button }}
        </span>
    </button>
</div>
