/* eslint-disable spellcheck/spell-checker */
export const counties = [
    { stateCode: 'AL', countyName: 'Autauga County' },
    { stateCode: 'AL', countyName: 'Baldwin County' },
    { stateCode: 'AL', countyName: 'Barbour County' },
    { stateCode: 'AL', countyName: 'Bibb County' },
    { stateCode: 'AL', countyName: 'Blount County' },
    { stateCode: 'AL', countyName: 'Bullock County' },
    { stateCode: 'AL', countyName: 'Butler County' },
    { stateCode: 'AL', countyName: 'Calhoun County' },
    { stateCode: 'AL', countyName: 'Chambers County' },
    { stateCode: 'AL', countyName: 'Cherokee County' },
    { stateCode: 'AL', countyName: 'Chilton County' },
    { stateCode: 'AL', countyName: 'Choctaw County' },
    { stateCode: 'AL', countyName: 'Clarke County' },
    { stateCode: 'AL', countyName: 'Clay County' },
    { stateCode: 'AL', countyName: 'Cleburne County' },
    { stateCode: 'AL', countyName: 'Coffee County' },
    { stateCode: 'AL', countyName: 'Colbert County' },
    { stateCode: 'AL', countyName: 'Conecuh County' },
    { stateCode: 'AL', countyName: 'Coosa County' },
    { stateCode: 'AL', countyName: 'Covington County' },
    { stateCode: 'AL', countyName: 'Crenshaw County' },
    { stateCode: 'AL', countyName: 'Cullman County' },
    { stateCode: 'AL', countyName: 'Dale County' },
    { stateCode: 'AL', countyName: 'Dallas County' },
    { stateCode: 'AL', countyName: 'DeKalb County' },
    { stateCode: 'AL', countyName: 'Elmore County' },
    { stateCode: 'AL', countyName: 'Escambia County' },
    { stateCode: 'AL', countyName: 'Etowah County' },
    { stateCode: 'AL', countyName: 'Fayette County' },
    { stateCode: 'AL', countyName: 'Franklin County' },
    { stateCode: 'AL', countyName: 'Geneva County' },
    { stateCode: 'AL', countyName: 'Greene County' },
    { stateCode: 'AL', countyName: 'Hale County' },
    { stateCode: 'AL', countyName: 'Henry County' },
    { stateCode: 'AL', countyName: 'Houston County' },
    { stateCode: 'AL', countyName: 'Jackson County' },
    { stateCode: 'AL', countyName: 'Jefferson County' },
    { stateCode: 'AL', countyName: 'Lamar County' },
    { stateCode: 'AL', countyName: 'Lauderdale County' },
    { stateCode: 'AL', countyName: 'Lawrence County' },
    { stateCode: 'AL', countyName: 'Lee County' },
    { stateCode: 'AL', countyName: 'Limestone County' },
    { stateCode: 'AL', countyName: 'Lowndes County' },
    { stateCode: 'AL', countyName: 'Macon County' },
    { stateCode: 'AL', countyName: 'Madison County' },
    { stateCode: 'AL', countyName: 'Marengo County' },
    { stateCode: 'AL', countyName: 'Marion County' },
    { stateCode: 'AL', countyName: 'Marshall County' },
    { stateCode: 'AL', countyName: 'Mobile County' },
    { stateCode: 'AL', countyName: 'Monroe County' },
    { stateCode: 'AL', countyName: 'Montgomery County' },
    { stateCode: 'AL', countyName: 'Morgan County' },
    { stateCode: 'AL', countyName: 'Perry County' },
    { stateCode: 'AL', countyName: 'Pickens County' },
    { stateCode: 'AL', countyName: 'Pike County' },
    { stateCode: 'AL', countyName: 'Randolph County' },
    { stateCode: 'AL', countyName: 'Russell County' },
    { stateCode: 'AL', countyName: 'St. Clair County' },
    { stateCode: 'AL', countyName: 'Shelby County' },
    { stateCode: 'AL', countyName: 'Sumter County' },
    { stateCode: 'AL', countyName: 'Talladega County' },
    { stateCode: 'AL', countyName: 'Tallapoosa County' },
    { stateCode: 'AL', countyName: 'Tuscaloosa County' },
    { stateCode: 'AL', countyName: 'Walker County' },
    { stateCode: 'AL', countyName: 'Washington County' },
    { stateCode: 'AL', countyName: 'Wilcox County' },
    { stateCode: 'AL', countyName: 'Winston County' },
    { stateCode: 'AK', countyName: 'Aleutians East Borough' },
    { stateCode: 'AK', countyName: 'Aleutians West Census Area' },
    { stateCode: 'AK', countyName: 'Anchorage Municipality' },
    { stateCode: 'AK', countyName: 'Bethel Census Area' },
    { stateCode: 'AK', countyName: 'Bristol Bay Borough' },
    { stateCode: 'AK', countyName: 'Denali Borough' },
    { stateCode: 'AK', countyName: 'Dillingham Census Area' },
    { stateCode: 'AK', countyName: 'Fairbanks North Star Borough' },
    { stateCode: 'AK', countyName: 'Haines Borough' },
    { stateCode: 'AK', countyName: 'Hoonah-Angoon Census Area' },
    { stateCode: 'AK', countyName: 'Juneau City and Borough' },
    { stateCode: 'AK', countyName: 'Kenai Peninsula Borough' },
    { stateCode: 'AK', countyName: 'Ketchikan Gateway Borough' },
    { stateCode: 'AK', countyName: 'Kodiak Island Borough' },
    { stateCode: 'AK', countyName: 'Lake and Peninsula Borough' },
    { stateCode: 'AK', countyName: 'Matanuska-Susitna Borough' },
    { stateCode: 'AK', countyName: 'Nome Census Area' },
    { stateCode: 'AK', countyName: 'North Slope Borough' },
    { stateCode: 'AK', countyName: 'Northwest Arctic Borough' },
    { stateCode: 'AK', countyName: 'Petersburg Census Area' },
    { stateCode: 'AK', countyName: 'Prince of Wales-Hyder Census Area' },
    { stateCode: 'AK', countyName: 'Sitka City and Borough' },
    { stateCode: 'AK', countyName: 'Skagway Municipality' },
    { stateCode: 'AK', countyName: 'Southeast Fairbanks Census Area' },
    { stateCode: 'AK', countyName: 'Valdez-Cordova Census Area' },
    { stateCode: 'AK', countyName: 'Wade Hampton Census Area' },
    { stateCode: 'AK', countyName: 'Wrangell City and Borough' },
    { stateCode: 'AK', countyName: 'Yakutat City and Borough' },
    { stateCode: 'AK', countyName: 'Yukon-Koyukuk Census Area' },
    { stateCode: 'AZ', countyName: 'Apache County' },
    { stateCode: 'AZ', countyName: 'Cochise County' },
    { stateCode: 'AZ', countyName: 'Coconino County' },
    { stateCode: 'AZ', countyName: 'Gila County' },
    { stateCode: 'AZ', countyName: 'Graham County' },
    { stateCode: 'AZ', countyName: 'Greenlee County' },
    { stateCode: 'AZ', countyName: 'La Paz County' },
    { stateCode: 'AZ', countyName: 'Maricopa County' },
    { stateCode: 'AZ', countyName: 'Mohave County' },
    { stateCode: 'AZ', countyName: 'Navajo County' },
    { stateCode: 'AZ', countyName: 'Pima County' },
    { stateCode: 'AZ', countyName: 'Pinal County' },
    { stateCode: 'AZ', countyName: 'Santa Cruz County' },
    { stateCode: 'AZ', countyName: 'Yavapai County' },
    { stateCode: 'AZ', countyName: 'Yuma County' },
    { stateCode: 'AR', countyName: 'Arkansas County' },
    { stateCode: 'AR', countyName: 'Ashley County' },
    { stateCode: 'AR', countyName: 'Baxter County' },
    { stateCode: 'AR', countyName: 'Benton County' },
    { stateCode: 'AR', countyName: 'Boone County' },
    { stateCode: 'AR', countyName: 'Bradley County' },
    { stateCode: 'AR', countyName: 'Calhoun County' },
    { stateCode: 'AR', countyName: 'Carroll County' },
    { stateCode: 'AR', countyName: 'Chicot County' },
    { stateCode: 'AR', countyName: 'Clark County' },
    { stateCode: 'AR', countyName: 'Clay County' },
    { stateCode: 'AR', countyName: 'Cleburne County' },
    { stateCode: 'AR', countyName: 'Cleveland County' },
    { stateCode: 'AR', countyName: 'Columbia County' },
    { stateCode: 'AR', countyName: 'Conway County' },
    { stateCode: 'AR', countyName: 'Craighead County' },
    { stateCode: 'AR', countyName: 'Crawford County' },
    { stateCode: 'AR', countyName: 'Crittenden County' },
    { stateCode: 'AR', countyName: 'Cross County' },
    { stateCode: 'AR', countyName: 'Dallas County' },
    { stateCode: 'AR', countyName: 'Desha County' },
    { stateCode: 'AR', countyName: 'Drew County' },
    { stateCode: 'AR', countyName: 'Faulkner County' },
    { stateCode: 'AR', countyName: 'Franklin County' },
    { stateCode: 'AR', countyName: 'Fulton County' },
    { stateCode: 'AR', countyName: 'Garland County' },
    { stateCode: 'AR', countyName: 'Grant County' },
    { stateCode: 'AR', countyName: 'Greene County' },
    { stateCode: 'AR', countyName: 'Hempstead County' },
    { stateCode: 'AR', countyName: 'Hot Spring County' },
    { stateCode: 'AR', countyName: 'Howard County' },
    { stateCode: 'AR', countyName: 'Independence County' },
    { stateCode: 'AR', countyName: 'Izard County' },
    { stateCode: 'AR', countyName: 'Jackson County' },
    { stateCode: 'AR', countyName: 'Jefferson County' },
    { stateCode: 'AR', countyName: 'Johnson County' },
    { stateCode: 'AR', countyName: 'Lafayette County' },
    { stateCode: 'AR', countyName: 'Lawrence County' },
    { stateCode: 'AR', countyName: 'Lee County' },
    { stateCode: 'AR', countyName: 'Lincoln County' },
    { stateCode: 'AR', countyName: 'Little River County' },
    { stateCode: 'AR', countyName: 'Logan County' },
    { stateCode: 'AR', countyName: 'Lonoke County' },
    { stateCode: 'AR', countyName: 'Madison County' },
    { stateCode: 'AR', countyName: 'Marion County' },
    { stateCode: 'AR', countyName: 'Miller County' },
    { stateCode: 'AR', countyName: 'Mississippi County' },
    { stateCode: 'AR', countyName: 'Monroe County' },
    { stateCode: 'AR', countyName: 'Montgomery County' },
    { stateCode: 'AR', countyName: 'Nevada County' },
    { stateCode: 'AR', countyName: 'Newton County' },
    { stateCode: 'AR', countyName: 'Ouachita County' },
    { stateCode: 'AR', countyName: 'Perry County' },
    { stateCode: 'AR', countyName: 'Phillips County' },
    { stateCode: 'AR', countyName: 'Pike County' },
    { stateCode: 'AR', countyName: 'Poinsett County' },
    { stateCode: 'AR', countyName: 'Polk County' },
    { stateCode: 'AR', countyName: 'Pope County' },
    { stateCode: 'AR', countyName: 'Prairie County' },
    { stateCode: 'AR', countyName: 'Pulaski County' },
    { stateCode: 'AR', countyName: 'Randolph County' },
    { stateCode: 'AR', countyName: 'St. Francis County' },
    { stateCode: 'AR', countyName: 'Saline County' },
    { stateCode: 'AR', countyName: 'Scott County' },
    { stateCode: 'AR', countyName: 'Searcy County' },
    { stateCode: 'AR', countyName: 'Sebastian County' },
    { stateCode: 'AR', countyName: 'Sevier County' },
    { stateCode: 'AR', countyName: 'Sharp County' },
    { stateCode: 'AR', countyName: 'Stone County' },
    { stateCode: 'AR', countyName: 'Union County' },
    { stateCode: 'AR', countyName: 'Van Buren County' },
    { stateCode: 'AR', countyName: 'Washington County' },
    { stateCode: 'AR', countyName: 'White County' },
    { stateCode: 'AR', countyName: 'Woodruff County' },
    { stateCode: 'AR', countyName: 'Yell County' },
    { stateCode: 'CA', countyName: 'Alameda County' },
    { stateCode: 'CA', countyName: 'Alpine County' },
    { stateCode: 'CA', countyName: 'Amador County' },
    { stateCode: 'CA', countyName: 'Butte County' },
    { stateCode: 'CA', countyName: 'Calaveras County' },
    { stateCode: 'CA', countyName: 'Colusa County' },
    { stateCode: 'CA', countyName: 'Contra Costa County' },
    { stateCode: 'CA', countyName: 'Del Norte County' },
    { stateCode: 'CA', countyName: 'El Dorado County' },
    { stateCode: 'CA', countyName: 'Fresno County' },
    { stateCode: 'CA', countyName: 'Glenn County' },
    { stateCode: 'CA', countyName: 'Humboldt County' },
    { stateCode: 'CA', countyName: 'Imperial County' },
    { stateCode: 'CA', countyName: 'Inyo County' },
    { stateCode: 'CA', countyName: 'Kern County' },
    { stateCode: 'CA', countyName: 'Kings County' },
    { stateCode: 'CA', countyName: 'Lake County' },
    { stateCode: 'CA', countyName: 'Lassen County' },
    { stateCode: 'CA', countyName: 'Los Angeles County' },
    { stateCode: 'CA', countyName: 'Madera County' },
    { stateCode: 'CA', countyName: 'Marin County' },
    { stateCode: 'CA', countyName: 'Mariposa County' },
    { stateCode: 'CA', countyName: 'Mendocino County' },
    { stateCode: 'CA', countyName: 'Merced County' },
    { stateCode: 'CA', countyName: 'Modoc County' },
    { stateCode: 'CA', countyName: 'Mono County' },
    { stateCode: 'CA', countyName: 'Monterey County' },
    { stateCode: 'CA', countyName: 'Napa County' },
    { stateCode: 'CA', countyName: 'Nevada County' },
    { stateCode: 'CA', countyName: 'Orange County' },
    { stateCode: 'CA', countyName: 'Placer County' },
    { stateCode: 'CA', countyName: 'Plumas County' },
    { stateCode: 'CA', countyName: 'Riverside County' },
    { stateCode: 'CA', countyName: 'Sacramento County' },
    { stateCode: 'CA', countyName: 'San Benito County' },
    { stateCode: 'CA', countyName: 'San Bernardino County' },
    { stateCode: 'CA', countyName: 'San Diego County' },
    { stateCode: 'CA', countyName: 'San Francisco County' },
    { stateCode: 'CA', countyName: 'San Joaquin County' },
    { stateCode: 'CA', countyName: 'San Luis Obispo County' },
    { stateCode: 'CA', countyName: 'San Mateo County' },
    { stateCode: 'CA', countyName: 'Santa Barbara County' },
    { stateCode: 'CA', countyName: 'Santa Clara County' },
    { stateCode: 'CA', countyName: 'Santa Cruz County' },
    { stateCode: 'CA', countyName: 'Shasta County' },
    { stateCode: 'CA', countyName: 'Sierra County' },
    { stateCode: 'CA', countyName: 'Siskiyou County' },
    { stateCode: 'CA', countyName: 'Solano County' },
    { stateCode: 'CA', countyName: 'Sonoma County' },
    { stateCode: 'CA', countyName: 'Stanislaus County' },
    { stateCode: 'CA', countyName: 'Sutter County' },
    { stateCode: 'CA', countyName: 'Tehama County' },
    { stateCode: 'CA', countyName: 'Trinity County' },
    { stateCode: 'CA', countyName: 'Tulare County' },
    { stateCode: 'CA', countyName: 'Tuolumne County' },
    { stateCode: 'CA', countyName: 'Ventura County' },
    { stateCode: 'CA', countyName: 'Yolo County' },
    { stateCode: 'CA', countyName: 'Yuba County' },
    { stateCode: 'CO', countyName: 'Adams County' },
    { stateCode: 'CO', countyName: 'Alamosa County' },
    { stateCode: 'CO', countyName: 'Arapahoe County' },
    { stateCode: 'CO', countyName: 'Archuleta County' },
    { stateCode: 'CO', countyName: 'Baca County' },
    { stateCode: 'CO', countyName: 'Bent County' },
    { stateCode: 'CO', countyName: 'Boulder County' },
    { stateCode: 'CO', countyName: 'Broomfield County' },
    { stateCode: 'CO', countyName: 'Chaffee County' },
    { stateCode: 'CO', countyName: 'Cheyenne County' },
    { stateCode: 'CO', countyName: 'Clear Creek County' },
    { stateCode: 'CO', countyName: 'Conejos County' },
    { stateCode: 'CO', countyName: 'Costilla County' },
    { stateCode: 'CO', countyName: 'Crowley County' },
    { stateCode: 'CO', countyName: 'Custer County' },
    { stateCode: 'CO', countyName: 'Delta County' },
    { stateCode: 'CO', countyName: 'Denver County' },
    { stateCode: 'CO', countyName: 'Dolores County' },
    { stateCode: 'CO', countyName: 'Douglas County' },
    { stateCode: 'CO', countyName: 'Eagle County' },
    { stateCode: 'CO', countyName: 'Elbert County' },
    { stateCode: 'CO', countyName: 'El Paso County' },
    { stateCode: 'CO', countyName: 'Fremont County' },
    { stateCode: 'CO', countyName: 'Garfield County' },
    { stateCode: 'CO', countyName: 'Gilpin County' },
    { stateCode: 'CO', countyName: 'Grand County' },
    { stateCode: 'CO', countyName: 'Gunnison County' },
    { stateCode: 'CO', countyName: 'Hinsdale County' },
    { stateCode: 'CO', countyName: 'Huerfano County' },
    { stateCode: 'CO', countyName: 'Jackson County' },
    { stateCode: 'CO', countyName: 'Jefferson County' },
    { stateCode: 'CO', countyName: 'Kiowa County' },
    { stateCode: 'CO', countyName: 'Kit Carson County' },
    { stateCode: 'CO', countyName: 'Lake County' },
    { stateCode: 'CO', countyName: 'La Plata County' },
    { stateCode: 'CO', countyName: 'Larimer County' },
    { stateCode: 'CO', countyName: 'Las Animas County' },
    { stateCode: 'CO', countyName: 'Lincoln County' },
    { stateCode: 'CO', countyName: 'Logan County' },
    { stateCode: 'CO', countyName: 'Mesa County' },
    { stateCode: 'CO', countyName: 'Mineral County' },
    { stateCode: 'CO', countyName: 'Moffat County' },
    { stateCode: 'CO', countyName: 'Montezuma County' },
    { stateCode: 'CO', countyName: 'Montrose County' },
    { stateCode: 'CO', countyName: 'Morgan County' },
    { stateCode: 'CO', countyName: 'Otero County' },
    { stateCode: 'CO', countyName: 'Ouray County' },
    { stateCode: 'CO', countyName: 'Park County' },
    { stateCode: 'CO', countyName: 'Phillips County' },
    { stateCode: 'CO', countyName: 'Pitkin County' },
    { stateCode: 'CO', countyName: 'Prowers County' },
    { stateCode: 'CO', countyName: 'Pueblo County' },
    { stateCode: 'CO', countyName: 'Rio Blanco County' },
    { stateCode: 'CO', countyName: 'Rio Grande County' },
    { stateCode: 'CO', countyName: 'Routt County' },
    { stateCode: 'CO', countyName: 'Saguache County' },
    { stateCode: 'CO', countyName: 'San Juan County' },
    { stateCode: 'CO', countyName: 'San Miguel County' },
    { stateCode: 'CO', countyName: 'Sedgwick County' },
    { stateCode: 'CO', countyName: 'Summit County' },
    { stateCode: 'CO', countyName: 'Teller County' },
    { stateCode: 'CO', countyName: 'Washington County' },
    { stateCode: 'CO', countyName: 'Weld County' },
    { stateCode: 'CO', countyName: 'Yuma County' },
    { stateCode: 'CT', countyName: 'Fairfield County' },
    { stateCode: 'CT', countyName: 'Hartford County' },
    { stateCode: 'CT', countyName: 'Litchfield County' },
    { stateCode: 'CT', countyName: 'Middlesex County' },
    { stateCode: 'CT', countyName: 'New Haven County' },
    { stateCode: 'CT', countyName: 'New London County' },
    { stateCode: 'CT', countyName: 'Tolland County' },
    { stateCode: 'CT', countyName: 'Windham County' },
    { stateCode: 'DE', countyName: 'Kent County' },
    { stateCode: 'DE', countyName: 'New Castle County' },
    { stateCode: 'DE', countyName: 'Sussex County' },
    { stateCode: 'DC', countyName: 'District of Columbia' },
    { stateCode: 'FL', countyName: 'Alachua County' },
    { stateCode: 'FL', countyName: 'Baker County' },
    { stateCode: 'FL', countyName: 'Bay County' },
    { stateCode: 'FL', countyName: 'Bradford County' },
    { stateCode: 'FL', countyName: 'Brevard County' },
    { stateCode: 'FL', countyName: 'Broward County' },
    { stateCode: 'FL', countyName: 'Calhoun County' },
    { stateCode: 'FL', countyName: 'Charlotte County' },
    { stateCode: 'FL', countyName: 'Citrus County' },
    { stateCode: 'FL', countyName: 'Clay County' },
    { stateCode: 'FL', countyName: 'Collier County' },
    { stateCode: 'FL', countyName: 'Columbia County' },
    { stateCode: 'FL', countyName: 'DeSoto County' },
    { stateCode: 'FL', countyName: 'Dixie County' },
    { stateCode: 'FL', countyName: 'Duval County' },
    { stateCode: 'FL', countyName: 'Escambia County' },
    { stateCode: 'FL', countyName: 'Flagler County' },
    { stateCode: 'FL', countyName: 'Franklin County' },
    { stateCode: 'FL', countyName: 'Gadsden County' },
    { stateCode: 'FL', countyName: 'Gilchrist County' },
    { stateCode: 'FL', countyName: 'Glades County' },
    { stateCode: 'FL', countyName: 'Gulf County' },
    { stateCode: 'FL', countyName: 'Hamilton County' },
    { stateCode: 'FL', countyName: 'Hardee County' },
    { stateCode: 'FL', countyName: 'Hendry County' },
    { stateCode: 'FL', countyName: 'Hernando County' },
    { stateCode: 'FL', countyName: 'Highlands County' },
    { stateCode: 'FL', countyName: 'Hillsborough County' },
    { stateCode: 'FL', countyName: 'Holmes County' },
    { stateCode: 'FL', countyName: 'Indian River County' },
    { stateCode: 'FL', countyName: 'Jackson County' },
    { stateCode: 'FL', countyName: 'Jefferson County' },
    { stateCode: 'FL', countyName: 'Lafayette County' },
    { stateCode: 'FL', countyName: 'Lake County' },
    { stateCode: 'FL', countyName: 'Lee County' },
    { stateCode: 'FL', countyName: 'Leon County' },
    { stateCode: 'FL', countyName: 'Levy County' },
    { stateCode: 'FL', countyName: 'Liberty County' },
    { stateCode: 'FL', countyName: 'Madison County' },
    { stateCode: 'FL', countyName: 'Manatee County' },
    { stateCode: 'FL', countyName: 'Marion County' },
    { stateCode: 'FL', countyName: 'Martin County' },
    { stateCode: 'FL', countyName: 'Miami-Dade County' },
    { stateCode: 'FL', countyName: 'Monroe County' },
    { stateCode: 'FL', countyName: 'Nassau County' },
    { stateCode: 'FL', countyName: 'Okaloosa County' },
    { stateCode: 'FL', countyName: 'Okeechobee County' },
    { stateCode: 'FL', countyName: 'Orange County' },
    { stateCode: 'FL', countyName: 'Osceola County' },
    { stateCode: 'FL', countyName: 'Palm Beach County' },
    { stateCode: 'FL', countyName: 'Pasco County' },
    { stateCode: 'FL', countyName: 'Pinellas County' },
    { stateCode: 'FL', countyName: 'Polk County' },
    { stateCode: 'FL', countyName: 'Putnam County' },
    { stateCode: 'FL', countyName: 'St. Johns County' },
    { stateCode: 'FL', countyName: 'St. Lucie County' },
    { stateCode: 'FL', countyName: 'Santa Rosa County' },
    { stateCode: 'FL', countyName: 'Sarasota County' },
    { stateCode: 'FL', countyName: 'Seminole County' },
    { stateCode: 'FL', countyName: 'Sumter County' },
    { stateCode: 'FL', countyName: 'Suwannee County' },
    { stateCode: 'FL', countyName: 'Taylor County' },
    { stateCode: 'FL', countyName: 'Union County' },
    { stateCode: 'FL', countyName: 'Volusia County' },
    { stateCode: 'FL', countyName: 'Wakulla County' },
    { stateCode: 'FL', countyName: 'Walton County' },
    { stateCode: 'FL', countyName: 'Washington County' },
    { stateCode: 'GA', countyName: 'Appling County' },
    { stateCode: 'GA', countyName: 'Atkinson County' },
    { stateCode: 'GA', countyName: 'Bacon County' },
    { stateCode: 'GA', countyName: 'Baker County' },
    { stateCode: 'GA', countyName: 'Baldwin County' },
    { stateCode: 'GA', countyName: 'Banks County' },
    { stateCode: 'GA', countyName: 'Barrow County' },
    { stateCode: 'GA', countyName: 'Bartow County' },
    { stateCode: 'GA', countyName: 'Ben Hill County' },
    { stateCode: 'GA', countyName: 'Berrien County' },
    { stateCode: 'GA', countyName: 'Bibb County' },
    { stateCode: 'GA', countyName: 'Bleckley County' },
    { stateCode: 'GA', countyName: 'Brantley County' },
    { stateCode: 'GA', countyName: 'Brooks County' },
    { stateCode: 'GA', countyName: 'Bryan County' },
    { stateCode: 'GA', countyName: 'Bulloch County' },
    { stateCode: 'GA', countyName: 'Burke County' },
    { stateCode: 'GA', countyName: 'Butts County' },
    { stateCode: 'GA', countyName: 'Calhoun County' },
    { stateCode: 'GA', countyName: 'Camden County' },
    { stateCode: 'GA', countyName: 'Candler County' },
    { stateCode: 'GA', countyName: 'Carroll County' },
    { stateCode: 'GA', countyName: 'Catoosa County' },
    { stateCode: 'GA', countyName: 'Charlton County' },
    { stateCode: 'GA', countyName: 'Chatham County' },
    { stateCode: 'GA', countyName: 'Chattahoochee County' },
    { stateCode: 'GA', countyName: 'Chattooga County' },
    { stateCode: 'GA', countyName: 'Cherokee County' },
    { stateCode: 'GA', countyName: 'Clarke County' },
    { stateCode: 'GA', countyName: 'Clay County' },
    { stateCode: 'GA', countyName: 'Clayton County' },
    { stateCode: 'GA', countyName: 'Clinch County' },
    { stateCode: 'GA', countyName: 'Cobb County' },
    { stateCode: 'GA', countyName: 'Coffee County' },
    { stateCode: 'GA', countyName: 'Colquitt County' },
    { stateCode: 'GA', countyName: 'Columbia County' },
    { stateCode: 'GA', countyName: 'Cook County' },
    { stateCode: 'GA', countyName: 'Coweta County' },
    { stateCode: 'GA', countyName: 'Crawford County' },
    { stateCode: 'GA', countyName: 'Crisp County' },
    { stateCode: 'GA', countyName: 'Dade County' },
    { stateCode: 'GA', countyName: 'Dawson County' },
    { stateCode: 'GA', countyName: 'Decatur County' },
    { stateCode: 'GA', countyName: 'DeKalb County' },
    { stateCode: 'GA', countyName: 'Dodge County' },
    { stateCode: 'GA', countyName: 'Dooly County' },
    { stateCode: 'GA', countyName: 'Dougherty County' },
    { stateCode: 'GA', countyName: 'Douglas County' },
    { stateCode: 'GA', countyName: 'Early County' },
    { stateCode: 'GA', countyName: 'Echols County' },
    { stateCode: 'GA', countyName: 'Effingham County' },
    { stateCode: 'GA', countyName: 'Elbert County' },
    { stateCode: 'GA', countyName: 'Emanuel County' },
    { stateCode: 'GA', countyName: 'Evans County' },
    { stateCode: 'GA', countyName: 'Fannin County' },
    { stateCode: 'GA', countyName: 'Fayette County' },
    { stateCode: 'GA', countyName: 'Floyd County' },
    { stateCode: 'GA', countyName: 'Forsyth County' },
    { stateCode: 'GA', countyName: 'Franklin County' },
    { stateCode: 'GA', countyName: 'Fulton County' },
    { stateCode: 'GA', countyName: 'Gilmer County' },
    { stateCode: 'GA', countyName: 'Glascock County' },
    { stateCode: 'GA', countyName: 'Glynn County' },
    { stateCode: 'GA', countyName: 'Gordon County' },
    { stateCode: 'GA', countyName: 'Grady County' },
    { stateCode: 'GA', countyName: 'Greene County' },
    { stateCode: 'GA', countyName: 'Gwinnett County' },
    { stateCode: 'GA', countyName: 'Habersham County' },
    { stateCode: 'GA', countyName: 'Hall County' },
    { stateCode: 'GA', countyName: 'Hancock County' },
    { stateCode: 'GA', countyName: 'Haralson County' },
    { stateCode: 'GA', countyName: 'Harris County' },
    { stateCode: 'GA', countyName: 'Hart County' },
    { stateCode: 'GA', countyName: 'Heard County' },
    { stateCode: 'GA', countyName: 'Henry County' },
    { stateCode: 'GA', countyName: 'Houston County' },
    { stateCode: 'GA', countyName: 'Irwin County' },
    { stateCode: 'GA', countyName: 'Jackson County' },
    { stateCode: 'GA', countyName: 'Jasper County' },
    { stateCode: 'GA', countyName: 'Jeff Davis County' },
    { stateCode: 'GA', countyName: 'Jefferson County' },
    { stateCode: 'GA', countyName: 'Jenkins County' },
    { stateCode: 'GA', countyName: 'Johnson County' },
    { stateCode: 'GA', countyName: 'Jones County' },
    { stateCode: 'GA', countyName: 'Lamar County' },
    { stateCode: 'GA', countyName: 'Lanier County' },
    { stateCode: 'GA', countyName: 'Laurens County' },
    { stateCode: 'GA', countyName: 'Lee County' },
    { stateCode: 'GA', countyName: 'Liberty County' },
    { stateCode: 'GA', countyName: 'Lincoln County' },
    { stateCode: 'GA', countyName: 'Long County' },
    { stateCode: 'GA', countyName: 'Lowndes County' },
    { stateCode: 'GA', countyName: 'Lumpkin County' },
    { stateCode: 'GA', countyName: 'McDuffie County' },
    { stateCode: 'GA', countyName: 'McIntosh County' },
    { stateCode: 'GA', countyName: 'Macon County' },
    { stateCode: 'GA', countyName: 'Madison County' },
    { stateCode: 'GA', countyName: 'Marion County' },
    { stateCode: 'GA', countyName: 'Meriwether County' },
    { stateCode: 'GA', countyName: 'Miller County' },
    { stateCode: 'GA', countyName: 'Mitchell County' },
    { stateCode: 'GA', countyName: 'Monroe County' },
    { stateCode: 'GA', countyName: 'Montgomery County' },
    { stateCode: 'GA', countyName: 'Morgan County' },
    { stateCode: 'GA', countyName: 'Murray County' },
    { stateCode: 'GA', countyName: 'Muscogee County' },
    { stateCode: 'GA', countyName: 'Newton County' },
    { stateCode: 'GA', countyName: 'Oconee County' },
    { stateCode: 'GA', countyName: 'Oglethorpe County' },
    { stateCode: 'GA', countyName: 'Paulding County' },
    { stateCode: 'GA', countyName: 'Peach County' },
    { stateCode: 'GA', countyName: 'Pickens County' },
    { stateCode: 'GA', countyName: 'Pierce County' },
    { stateCode: 'GA', countyName: 'Pike County' },
    { stateCode: 'GA', countyName: 'Polk County' },
    { stateCode: 'GA', countyName: 'Pulaski County' },
    { stateCode: 'GA', countyName: 'Putnam County' },
    { stateCode: 'GA', countyName: 'Quitman County' },
    { stateCode: 'GA', countyName: 'Rabun County' },
    { stateCode: 'GA', countyName: 'Randolph County' },
    { stateCode: 'GA', countyName: 'Richmond County' },
    { stateCode: 'GA', countyName: 'Rockdale County' },
    { stateCode: 'GA', countyName: 'Schley County' },
    { stateCode: 'GA', countyName: 'Screven County' },
    { stateCode: 'GA', countyName: 'Seminole County' },
    { stateCode: 'GA', countyName: 'Spalding County' },
    { stateCode: 'GA', countyName: 'Stephens County' },
    { stateCode: 'GA', countyName: 'Stewart County' },
    { stateCode: 'GA', countyName: 'Sumter County' },
    { stateCode: 'GA', countyName: 'Talbot County' },
    { stateCode: 'GA', countyName: 'Taliaferro County' },
    { stateCode: 'GA', countyName: 'Tattnall County' },
    { stateCode: 'GA', countyName: 'Taylor County' },
    { stateCode: 'GA', countyName: 'Telfair County' },
    { stateCode: 'GA', countyName: 'Terrell County' },
    { stateCode: 'GA', countyName: 'Thomas County' },
    { stateCode: 'GA', countyName: 'Tift County' },
    { stateCode: 'GA', countyName: 'Toombs County' },
    { stateCode: 'GA', countyName: 'Towns County' },
    { stateCode: 'GA', countyName: 'Treutlen County' },
    { stateCode: 'GA', countyName: 'Troup County' },
    { stateCode: 'GA', countyName: 'Turner County' },
    { stateCode: 'GA', countyName: 'Twiggs County' },
    { stateCode: 'GA', countyName: 'Union County' },
    { stateCode: 'GA', countyName: 'Upson County' },
    { stateCode: 'GA', countyName: 'Walker County' },
    { stateCode: 'GA', countyName: 'Walton County' },
    { stateCode: 'GA', countyName: 'Ware County' },
    { stateCode: 'GA', countyName: 'Warren County' },
    { stateCode: 'GA', countyName: 'Washington County' },
    { stateCode: 'GA', countyName: 'Wayne County' },
    { stateCode: 'GA', countyName: 'Webster County' },
    { stateCode: 'GA', countyName: 'Wheeler County' },
    { stateCode: 'GA', countyName: 'White County' },
    { stateCode: 'GA', countyName: 'Whitfield County' },
    { stateCode: 'GA', countyName: 'Wilcox County' },
    { stateCode: 'GA', countyName: 'Wilkes County' },
    { stateCode: 'GA', countyName: 'Wilkinson County' },
    { stateCode: 'GA', countyName: 'Worth County' },
    { stateCode: 'HI', countyName: 'Hawaii County' },
    { stateCode: 'HI', countyName: 'Honolulu County' },
    { stateCode: 'HI', countyName: 'Kalawao County' },
    { stateCode: 'HI', countyName: 'Kauai County' },
    { stateCode: 'HI', countyName: 'Maui County' },
    { stateCode: 'ID', countyName: 'Ada County' },
    { stateCode: 'ID', countyName: 'Adams County' },
    { stateCode: 'ID', countyName: 'Bannock County' },
    { stateCode: 'ID', countyName: 'Bear Lake County' },
    { stateCode: 'ID', countyName: 'Benewah County' },
    { stateCode: 'ID', countyName: 'Bingham County' },
    { stateCode: 'ID', countyName: 'Blaine County' },
    { stateCode: 'ID', countyName: 'Boise County' },
    { stateCode: 'ID', countyName: 'Bonner County' },
    { stateCode: 'ID', countyName: 'Bonneville County' },
    { stateCode: 'ID', countyName: 'Boundary County' },
    { stateCode: 'ID', countyName: 'Butte County' },
    { stateCode: 'ID', countyName: 'Camas County' },
    { stateCode: 'ID', countyName: 'Canyon County' },
    { stateCode: 'ID', countyName: 'Caribou County' },
    { stateCode: 'ID', countyName: 'Cassia County' },
    { stateCode: 'ID', countyName: 'Clark County' },
    { stateCode: 'ID', countyName: 'Clearwater County' },
    { stateCode: 'ID', countyName: 'Custer County' },
    { stateCode: 'ID', countyName: 'Elmore County' },
    { stateCode: 'ID', countyName: 'Franklin County' },
    { stateCode: 'ID', countyName: 'Fremont County' },
    { stateCode: 'ID', countyName: 'Gem County' },
    { stateCode: 'ID', countyName: 'Gooding County' },
    { stateCode: 'ID', countyName: 'Idaho County' },
    { stateCode: 'ID', countyName: 'Jefferson County' },
    { stateCode: 'ID', countyName: 'Jerome County' },
    { stateCode: 'ID', countyName: 'Kootenai County' },
    { stateCode: 'ID', countyName: 'Latah County' },
    { stateCode: 'ID', countyName: 'Lemhi County' },
    { stateCode: 'ID', countyName: 'Lewis County' },
    { stateCode: 'ID', countyName: 'Lincoln County' },
    { stateCode: 'ID', countyName: 'Madison County' },
    { stateCode: 'ID', countyName: 'Minidoka County' },
    { stateCode: 'ID', countyName: 'Nez Perce County' },
    { stateCode: 'ID', countyName: 'Oneida County' },
    { stateCode: 'ID', countyName: 'Owyhee County' },
    { stateCode: 'ID', countyName: 'Payette County' },
    { stateCode: 'ID', countyName: 'Power County' },
    { stateCode: 'ID', countyName: 'Shoshone County' },
    { stateCode: 'ID', countyName: 'Teton County' },
    { stateCode: 'ID', countyName: 'Twin Falls County' },
    { stateCode: 'ID', countyName: 'Valley County' },
    { stateCode: 'ID', countyName: 'Washington County' },
    { stateCode: 'IL', countyName: 'Adams County' },
    { stateCode: 'IL', countyName: 'Alexander County' },
    { stateCode: 'IL', countyName: 'Bond County' },
    { stateCode: 'IL', countyName: 'Boone County' },
    { stateCode: 'IL', countyName: 'Brown County' },
    { stateCode: 'IL', countyName: 'Bureau County' },
    { stateCode: 'IL', countyName: 'Calhoun County' },
    { stateCode: 'IL', countyName: 'Carroll County' },
    { stateCode: 'IL', countyName: 'Cass County' },
    { stateCode: 'IL', countyName: 'Champaign County' },
    { stateCode: 'IL', countyName: 'Christian County' },
    { stateCode: 'IL', countyName: 'Clark County' },
    { stateCode: 'IL', countyName: 'Clay County' },
    { stateCode: 'IL', countyName: 'Clinton County' },
    { stateCode: 'IL', countyName: 'Coles County' },
    { stateCode: 'IL', countyName: 'Cook County' },
    { stateCode: 'IL', countyName: 'Crawford County' },
    { stateCode: 'IL', countyName: 'Cumberland County' },
    { stateCode: 'IL', countyName: 'DeKalb County' },
    { stateCode: 'IL', countyName: 'De Witt County' },
    { stateCode: 'IL', countyName: 'Douglas County' },
    { stateCode: 'IL', countyName: 'DuPage County' },
    { stateCode: 'IL', countyName: 'Edgar County' },
    { stateCode: 'IL', countyName: 'Edwards County' },
    { stateCode: 'IL', countyName: 'Effingham County' },
    { stateCode: 'IL', countyName: 'Fayette County' },
    { stateCode: 'IL', countyName: 'Ford County' },
    { stateCode: 'IL', countyName: 'Franklin County' },
    { stateCode: 'IL', countyName: 'Fulton County' },
    { stateCode: 'IL', countyName: 'Gallatin County' },
    { stateCode: 'IL', countyName: 'Greene County' },
    { stateCode: 'IL', countyName: 'Grundy County' },
    { stateCode: 'IL', countyName: 'Hamilton County' },
    { stateCode: 'IL', countyName: 'Hancock County' },
    { stateCode: 'IL', countyName: 'Hardin County' },
    { stateCode: 'IL', countyName: 'Henderson County' },
    { stateCode: 'IL', countyName: 'Henry County' },
    { stateCode: 'IL', countyName: 'Iroquois County' },
    { stateCode: 'IL', countyName: 'Jackson County' },
    { stateCode: 'IL', countyName: 'Jasper County' },
    { stateCode: 'IL', countyName: 'Jefferson County' },
    { stateCode: 'IL', countyName: 'Jersey County' },
    { stateCode: 'IL', countyName: 'Jo Daviess County' },
    { stateCode: 'IL', countyName: 'Johnson County' },
    { stateCode: 'IL', countyName: 'Kane County' },
    { stateCode: 'IL', countyName: 'Kankakee County' },
    { stateCode: 'IL', countyName: 'Kendall County' },
    { stateCode: 'IL', countyName: 'Knox County' },
    { stateCode: 'IL', countyName: 'Lake County' },
    { stateCode: 'IL', countyName: 'LaSalle County' },
    { stateCode: 'IL', countyName: 'Lawrence County' },
    { stateCode: 'IL', countyName: 'Lee County' },
    { stateCode: 'IL', countyName: 'Livingston County' },
    { stateCode: 'IL', countyName: 'Logan County' },
    { stateCode: 'IL', countyName: 'McDonough County' },
    { stateCode: 'IL', countyName: 'McHenry County' },
    { stateCode: 'IL', countyName: 'McLean County' },
    { stateCode: 'IL', countyName: 'Macon County' },
    { stateCode: 'IL', countyName: 'Macoupin County' },
    { stateCode: 'IL', countyName: 'Madison County' },
    { stateCode: 'IL', countyName: 'Marion County' },
    { stateCode: 'IL', countyName: 'Marshall County' },
    { stateCode: 'IL', countyName: 'Mason County' },
    { stateCode: 'IL', countyName: 'Massac County' },
    { stateCode: 'IL', countyName: 'Menard County' },
    { stateCode: 'IL', countyName: 'Mercer County' },
    { stateCode: 'IL', countyName: 'Monroe County' },
    { stateCode: 'IL', countyName: 'Montgomery County' },
    { stateCode: 'IL', countyName: 'Morgan County' },
    { stateCode: 'IL', countyName: 'Moultrie County' },
    { stateCode: 'IL', countyName: 'Ogle County' },
    { stateCode: 'IL', countyName: 'Peoria County' },
    { stateCode: 'IL', countyName: 'Perry County' },
    { stateCode: 'IL', countyName: 'Piatt County' },
    { stateCode: 'IL', countyName: 'Pike County' },
    { stateCode: 'IL', countyName: 'Pope County' },
    { stateCode: 'IL', countyName: 'Pulaski County' },
    { stateCode: 'IL', countyName: 'Putnam County' },
    { stateCode: 'IL', countyName: 'Randolph County' },
    { stateCode: 'IL', countyName: 'Richland County' },
    { stateCode: 'IL', countyName: 'Rock Island County' },
    { stateCode: 'IL', countyName: 'St. Clair County' },
    { stateCode: 'IL', countyName: 'Saline County' },
    { stateCode: 'IL', countyName: 'Sangamon County' },
    { stateCode: 'IL', countyName: 'Schuyler County' },
    { stateCode: 'IL', countyName: 'Scott County' },
    { stateCode: 'IL', countyName: 'Shelby County' },
    { stateCode: 'IL', countyName: 'Stark County' },
    { stateCode: 'IL', countyName: 'Stephenson County' },
    { stateCode: 'IL', countyName: 'Tazewell County' },
    { stateCode: 'IL', countyName: 'Union County' },
    { stateCode: 'IL', countyName: 'Vermilion County' },
    { stateCode: 'IL', countyName: 'Wabash County' },
    { stateCode: 'IL', countyName: 'Warren County' },
    { stateCode: 'IL', countyName: 'Washington County' },
    { stateCode: 'IL', countyName: 'Wayne County' },
    { stateCode: 'IL', countyName: 'White County' },
    { stateCode: 'IL', countyName: 'Whiteside County' },
    { stateCode: 'IL', countyName: 'Will County' },
    { stateCode: 'IL', countyName: 'Williamson County' },
    { stateCode: 'IL', countyName: 'Winnebago County' },
    { stateCode: 'IL', countyName: 'Woodford County' },
    { stateCode: 'IN', countyName: 'Adams County' },
    { stateCode: 'IN', countyName: 'Allen County' },
    { stateCode: 'IN', countyName: 'Bartholomew County' },
    { stateCode: 'IN', countyName: 'Benton County' },
    { stateCode: 'IN', countyName: 'Blackford County' },
    { stateCode: 'IN', countyName: 'Boone County' },
    { stateCode: 'IN', countyName: 'Brown County' },
    { stateCode: 'IN', countyName: 'Carroll County' },
    { stateCode: 'IN', countyName: 'Cass County' },
    { stateCode: 'IN', countyName: 'Clark County' },
    { stateCode: 'IN', countyName: 'Clay County' },
    { stateCode: 'IN', countyName: 'Clinton County' },
    { stateCode: 'IN', countyName: 'Crawford County' },
    { stateCode: 'IN', countyName: 'Daviess County' },
    { stateCode: 'IN', countyName: 'Dearborn County' },
    { stateCode: 'IN', countyName: 'Decatur County' },
    { stateCode: 'IN', countyName: 'DeKalb County' },
    { stateCode: 'IN', countyName: 'Delaware County' },
    { stateCode: 'IN', countyName: 'Dubois County' },
    { stateCode: 'IN', countyName: 'Elkhart County' },
    { stateCode: 'IN', countyName: 'Fayette County' },
    { stateCode: 'IN', countyName: 'Floyd County' },
    { stateCode: 'IN', countyName: 'Fountain County' },
    { stateCode: 'IN', countyName: 'Franklin County' },
    { stateCode: 'IN', countyName: 'Fulton County' },
    { stateCode: 'IN', countyName: 'Gibson County' },
    { stateCode: 'IN', countyName: 'Grant County' },
    { stateCode: 'IN', countyName: 'Greene County' },
    { stateCode: 'IN', countyName: 'Hamilton County' },
    { stateCode: 'IN', countyName: 'Hancock County' },
    { stateCode: 'IN', countyName: 'Harrison County' },
    { stateCode: 'IN', countyName: 'Hendricks County' },
    { stateCode: 'IN', countyName: 'Henry County' },
    { stateCode: 'IN', countyName: 'Howard County' },
    { stateCode: 'IN', countyName: 'Huntington County' },
    { stateCode: 'IN', countyName: 'Jackson County' },
    { stateCode: 'IN', countyName: 'Jasper County' },
    { stateCode: 'IN', countyName: 'Jay County' },
    { stateCode: 'IN', countyName: 'Jefferson County' },
    { stateCode: 'IN', countyName: 'Jennings County' },
    { stateCode: 'IN', countyName: 'Johnson County' },
    { stateCode: 'IN', countyName: 'Knox County' },
    { stateCode: 'IN', countyName: 'Kosciusko County' },
    { stateCode: 'IN', countyName: 'LaGrange County' },
    { stateCode: 'IN', countyName: 'Lake County' },
    { stateCode: 'IN', countyName: 'LaPorte County' },
    { stateCode: 'IN', countyName: 'Lawrence County' },
    { stateCode: 'IN', countyName: 'Madison County' },
    { stateCode: 'IN', countyName: 'Marion County' },
    { stateCode: 'IN', countyName: 'Marshall County' },
    { stateCode: 'IN', countyName: 'Martin County' },
    { stateCode: 'IN', countyName: 'Miami County' },
    { stateCode: 'IN', countyName: 'Monroe County' },
    { stateCode: 'IN', countyName: 'Montgomery County' },
    { stateCode: 'IN', countyName: 'Morgan County' },
    { stateCode: 'IN', countyName: 'Newton County' },
    { stateCode: 'IN', countyName: 'Noble County' },
    { stateCode: 'IN', countyName: 'Ohio County' },
    { stateCode: 'IN', countyName: 'Orange County' },
    { stateCode: 'IN', countyName: 'Owen County' },
    { stateCode: 'IN', countyName: 'Parke County' },
    { stateCode: 'IN', countyName: 'Perry County' },
    { stateCode: 'IN', countyName: 'Pike County' },
    { stateCode: 'IN', countyName: 'Porter County' },
    { stateCode: 'IN', countyName: 'Posey County' },
    { stateCode: 'IN', countyName: 'Pulaski County' },
    { stateCode: 'IN', countyName: 'Putnam County' },
    { stateCode: 'IN', countyName: 'Randolph County' },
    { stateCode: 'IN', countyName: 'Ripley County' },
    { stateCode: 'IN', countyName: 'Rush County' },
    { stateCode: 'IN', countyName: 'St. Joseph County' },
    { stateCode: 'IN', countyName: 'Scott County' },
    { stateCode: 'IN', countyName: 'Shelby County' },
    { stateCode: 'IN', countyName: 'Spencer County' },
    { stateCode: 'IN', countyName: 'Starke County' },
    { stateCode: 'IN', countyName: 'Steuben County' },
    { stateCode: 'IN', countyName: 'Sullivan County' },
    { stateCode: 'IN', countyName: 'Switzerland County' },
    { stateCode: 'IN', countyName: 'Tippecanoe County' },
    { stateCode: 'IN', countyName: 'Tipton County' },
    { stateCode: 'IN', countyName: 'Union County' },
    { stateCode: 'IN', countyName: 'Vanderburgh County' },
    { stateCode: 'IN', countyName: 'Vermillion County' },
    { stateCode: 'IN', countyName: 'Vigo County' },
    { stateCode: 'IN', countyName: 'Wabash County' },
    { stateCode: 'IN', countyName: 'Warren County' },
    { stateCode: 'IN', countyName: 'Warrick County' },
    { stateCode: 'IN', countyName: 'Washington County' },
    { stateCode: 'IN', countyName: 'Wayne County' },
    { stateCode: 'IN', countyName: 'Wells County' },
    { stateCode: 'IN', countyName: 'White County' },
    { stateCode: 'IN', countyName: 'Whitley County' },
    { stateCode: 'IA', countyName: 'Adair County' },
    { stateCode: 'IA', countyName: 'Adams County' },
    { stateCode: 'IA', countyName: 'Allamakee County' },
    { stateCode: 'IA', countyName: 'Appanoose County' },
    { stateCode: 'IA', countyName: 'Audubon County' },
    { stateCode: 'IA', countyName: 'Benton County' },
    { stateCode: 'IA', countyName: 'Black Hawk County' },
    { stateCode: 'IA', countyName: 'Boone County' },
    { stateCode: 'IA', countyName: 'Bremer County' },
    { stateCode: 'IA', countyName: 'Buchanan County' },
    { stateCode: 'IA', countyName: 'Buena Vista County' },
    { stateCode: 'IA', countyName: 'Butler County' },
    { stateCode: 'IA', countyName: 'Calhoun County' },
    { stateCode: 'IA', countyName: 'Carroll County' },
    { stateCode: 'IA', countyName: 'Cass County' },
    { stateCode: 'IA', countyName: 'Cedar County' },
    { stateCode: 'IA', countyName: 'Cerro Gordo County' },
    { stateCode: 'IA', countyName: 'Cherokee County' },
    { stateCode: 'IA', countyName: 'Chickasaw County' },
    { stateCode: 'IA', countyName: 'Clarke County' },
    { stateCode: 'IA', countyName: 'Clay County' },
    { stateCode: 'IA', countyName: 'Clayton County' },
    { stateCode: 'IA', countyName: 'Clinton County' },
    { stateCode: 'IA', countyName: 'Crawford County' },
    { stateCode: 'IA', countyName: 'Dallas County' },
    { stateCode: 'IA', countyName: 'Davis County' },
    { stateCode: 'IA', countyName: 'Decatur County' },
    { stateCode: 'IA', countyName: 'Delaware County' },
    { stateCode: 'IA', countyName: 'Des Moines County' },
    { stateCode: 'IA', countyName: 'Dickinson County' },
    { stateCode: 'IA', countyName: 'Dubuque County' },
    { stateCode: 'IA', countyName: 'Emmet County' },
    { stateCode: 'IA', countyName: 'Fayette County' },
    { stateCode: 'IA', countyName: 'Floyd County' },
    { stateCode: 'IA', countyName: 'Franklin County' },
    { stateCode: 'IA', countyName: 'Fremont County' },
    { stateCode: 'IA', countyName: 'Greene County' },
    { stateCode: 'IA', countyName: 'Grundy County' },
    { stateCode: 'IA', countyName: 'Guthrie County' },
    { stateCode: 'IA', countyName: 'Hamilton County' },
    { stateCode: 'IA', countyName: 'Hancock County' },
    { stateCode: 'IA', countyName: 'Hardin County' },
    { stateCode: 'IA', countyName: 'Harrison County' },
    { stateCode: 'IA', countyName: 'Henry County' },
    { stateCode: 'IA', countyName: 'Howard County' },
    { stateCode: 'IA', countyName: 'Humboldt County' },
    { stateCode: 'IA', countyName: 'Ida County' },
    { stateCode: 'IA', countyName: 'Iowa County' },
    { stateCode: 'IA', countyName: 'Jackson County' },
    { stateCode: 'IA', countyName: 'Jasper County' },
    { stateCode: 'IA', countyName: 'Jefferson County' },
    { stateCode: 'IA', countyName: 'Johnson County' },
    { stateCode: 'IA', countyName: 'Jones County' },
    { stateCode: 'IA', countyName: 'Keokuk County' },
    { stateCode: 'IA', countyName: 'Kossuth County' },
    { stateCode: 'IA', countyName: 'Lee County' },
    { stateCode: 'IA', countyName: 'Linn County' },
    { stateCode: 'IA', countyName: 'Louisa County' },
    { stateCode: 'IA', countyName: 'Lucas County' },
    { stateCode: 'IA', countyName: 'Lyon County' },
    { stateCode: 'IA', countyName: 'Madison County' },
    { stateCode: 'IA', countyName: 'Mahaska County' },
    { stateCode: 'IA', countyName: 'Marion County' },
    { stateCode: 'IA', countyName: 'Marshall County' },
    { stateCode: 'IA', countyName: 'Mills County' },
    { stateCode: 'IA', countyName: 'Mitchell County' },
    { stateCode: 'IA', countyName: 'Monona County' },
    { stateCode: 'IA', countyName: 'Monroe County' },
    { stateCode: 'IA', countyName: 'Montgomery County' },
    { stateCode: 'IA', countyName: 'Muscatine County' },
    { stateCode: 'IA', countyName: "O'Brien County" },
    { stateCode: 'IA', countyName: 'Osceola County' },
    { stateCode: 'IA', countyName: 'Page County' },
    { stateCode: 'IA', countyName: 'Palo Alto County' },
    { stateCode: 'IA', countyName: 'Plymouth County' },
    { stateCode: 'IA', countyName: 'Pocahontas County' },
    { stateCode: 'IA', countyName: 'Polk County' },
    { stateCode: 'IA', countyName: 'Pottawattamie County' },
    { stateCode: 'IA', countyName: 'Poweshiek County' },
    { stateCode: 'IA', countyName: 'Ringgold County' },
    { stateCode: 'IA', countyName: 'Sac County' },
    { stateCode: 'IA', countyName: 'Scott County' },
    { stateCode: 'IA', countyName: 'Shelby County' },
    { stateCode: 'IA', countyName: 'Sioux County' },
    { stateCode: 'IA', countyName: 'Story County' },
    { stateCode: 'IA', countyName: 'Tama County' },
    { stateCode: 'IA', countyName: 'Taylor County' },
    { stateCode: 'IA', countyName: 'Union County' },
    { stateCode: 'IA', countyName: 'Van Buren County' },
    { stateCode: 'IA', countyName: 'Wapello County' },
    { stateCode: 'IA', countyName: 'Warren County' },
    { stateCode: 'IA', countyName: 'Washington County' },
    { stateCode: 'IA', countyName: 'Wayne County' },
    { stateCode: 'IA', countyName: 'Webster County' },
    { stateCode: 'IA', countyName: 'Winnebago County' },
    { stateCode: 'IA', countyName: 'Winneshiek County' },
    { stateCode: 'IA', countyName: 'Woodbury County' },
    { stateCode: 'IA', countyName: 'Worth County' },
    { stateCode: 'IA', countyName: 'Wright County' },
    { stateCode: 'KS', countyName: 'Allen County' },
    { stateCode: 'KS', countyName: 'Anderson County' },
    { stateCode: 'KS', countyName: 'Atchison County' },
    { stateCode: 'KS', countyName: 'Barber County' },
    { stateCode: 'KS', countyName: 'Barton County' },
    { stateCode: 'KS', countyName: 'Bourbon County' },
    { stateCode: 'KS', countyName: 'Brown County' },
    { stateCode: 'KS', countyName: 'Butler County' },
    { stateCode: 'KS', countyName: 'Chase County' },
    { stateCode: 'KS', countyName: 'Chautauqua County' },
    { stateCode: 'KS', countyName: 'Cherokee County' },
    { stateCode: 'KS', countyName: 'Cheyenne County' },
    { stateCode: 'KS', countyName: 'Clark County' },
    { stateCode: 'KS', countyName: 'Clay County' },
    { stateCode: 'KS', countyName: 'Cloud County' },
    { stateCode: 'KS', countyName: 'Coffey County' },
    { stateCode: 'KS', countyName: 'Comanche County' },
    { stateCode: 'KS', countyName: 'Cowley County' },
    { stateCode: 'KS', countyName: 'Crawford County' },
    { stateCode: 'KS', countyName: 'Decatur County' },
    { stateCode: 'KS', countyName: 'Dickinson County' },
    { stateCode: 'KS', countyName: 'Doniphan County' },
    { stateCode: 'KS', countyName: 'Douglas County' },
    { stateCode: 'KS', countyName: 'Edwards County' },
    { stateCode: 'KS', countyName: 'Elk County' },
    { stateCode: 'KS', countyName: 'Ellis County' },
    { stateCode: 'KS', countyName: 'Ellsworth County' },
    { stateCode: 'KS', countyName: 'Finney County' },
    { stateCode: 'KS', countyName: 'Ford County' },
    { stateCode: 'KS', countyName: 'Franklin County' },
    { stateCode: 'KS', countyName: 'Geary County' },
    { stateCode: 'KS', countyName: 'Gove County' },
    { stateCode: 'KS', countyName: 'Graham County' },
    { stateCode: 'KS', countyName: 'Grant County' },
    { stateCode: 'KS', countyName: 'Gray County' },
    { stateCode: 'KS', countyName: 'Greeley County' },
    { stateCode: 'KS', countyName: 'Greenwood County' },
    { stateCode: 'KS', countyName: 'Hamilton County' },
    { stateCode: 'KS', countyName: 'Harper County' },
    { stateCode: 'KS', countyName: 'Harvey County' },
    { stateCode: 'KS', countyName: 'Haskell County' },
    { stateCode: 'KS', countyName: 'Hodgeman County' },
    { stateCode: 'KS', countyName: 'Jackson County' },
    { stateCode: 'KS', countyName: 'Jefferson County' },
    { stateCode: 'KS', countyName: 'Jewell County' },
    { stateCode: 'KS', countyName: 'Johnson County' },
    { stateCode: 'KS', countyName: 'Kearny County' },
    { stateCode: 'KS', countyName: 'Kingman County' },
    { stateCode: 'KS', countyName: 'Kiowa County' },
    { stateCode: 'KS', countyName: 'Labette County' },
    { stateCode: 'KS', countyName: 'Lane County' },
    { stateCode: 'KS', countyName: 'Leavenworth County' },
    { stateCode: 'KS', countyName: 'Lincoln County' },
    { stateCode: 'KS', countyName: 'Linn County' },
    { stateCode: 'KS', countyName: 'Logan County' },
    { stateCode: 'KS', countyName: 'Lyon County' },
    { stateCode: 'KS', countyName: 'McPherson County' },
    { stateCode: 'KS', countyName: 'Marion County' },
    { stateCode: 'KS', countyName: 'Marshall County' },
    { stateCode: 'KS', countyName: 'Meade County' },
    { stateCode: 'KS', countyName: 'Miami County' },
    { stateCode: 'KS', countyName: 'Mitchell County' },
    { stateCode: 'KS', countyName: 'Montgomery County' },
    { stateCode: 'KS', countyName: 'Morris County' },
    { stateCode: 'KS', countyName: 'Morton County' },
    { stateCode: 'KS', countyName: 'Nemaha County' },
    { stateCode: 'KS', countyName: 'Neosho County' },
    { stateCode: 'KS', countyName: 'Ness County' },
    { stateCode: 'KS', countyName: 'Norton County' },
    { stateCode: 'KS', countyName: 'Osage County' },
    { stateCode: 'KS', countyName: 'Osborne County' },
    { stateCode: 'KS', countyName: 'Ottawa County' },
    { stateCode: 'KS', countyName: 'Pawnee County' },
    { stateCode: 'KS', countyName: 'Phillips County' },
    { stateCode: 'KS', countyName: 'Pottawatomie County' },
    { stateCode: 'KS', countyName: 'Pratt County' },
    { stateCode: 'KS', countyName: 'Rawlins County' },
    { stateCode: 'KS', countyName: 'Reno County' },
    { stateCode: 'KS', countyName: 'Republic County' },
    { stateCode: 'KS', countyName: 'Rice County' },
    { stateCode: 'KS', countyName: 'Riley County' },
    { stateCode: 'KS', countyName: 'Rooks County' },
    { stateCode: 'KS', countyName: 'Rush County' },
    { stateCode: 'KS', countyName: 'Russell County' },
    { stateCode: 'KS', countyName: 'Saline County' },
    { stateCode: 'KS', countyName: 'Scott County' },
    { stateCode: 'KS', countyName: 'Sedgwick County' },
    { stateCode: 'KS', countyName: 'Seward County' },
    { stateCode: 'KS', countyName: 'Shawnee County' },
    { stateCode: 'KS', countyName: 'Sheridan County' },
    { stateCode: 'KS', countyName: 'Sherman County' },
    { stateCode: 'KS', countyName: 'Smith County' },
    { stateCode: 'KS', countyName: 'Stafford County' },
    { stateCode: 'KS', countyName: 'Stanton County' },
    { stateCode: 'KS', countyName: 'Stevens County' },
    { stateCode: 'KS', countyName: 'Sumner County' },
    { stateCode: 'KS', countyName: 'Thomas County' },
    { stateCode: 'KS', countyName: 'Trego County' },
    { stateCode: 'KS', countyName: 'Wabaunsee County' },
    { stateCode: 'KS', countyName: 'Wallace County' },
    { stateCode: 'KS', countyName: 'Washington County' },
    { stateCode: 'KS', countyName: 'Wichita County' },
    { stateCode: 'KS', countyName: 'Wilson County' },
    { stateCode: 'KS', countyName: 'Woodson County' },
    { stateCode: 'KS', countyName: 'Wyandotte County' },
    { stateCode: 'KY', countyName: 'Adair County' },
    { stateCode: 'KY', countyName: 'Allen County' },
    { stateCode: 'KY', countyName: 'Anderson County' },
    { stateCode: 'KY', countyName: 'Ballard County' },
    { stateCode: 'KY', countyName: 'Barren County' },
    { stateCode: 'KY', countyName: 'Bath County' },
    { stateCode: 'KY', countyName: 'Bell County' },
    { stateCode: 'KY', countyName: 'Boone County' },
    { stateCode: 'KY', countyName: 'Bourbon County' },
    { stateCode: 'KY', countyName: 'Boyd County' },
    { stateCode: 'KY', countyName: 'Boyle County' },
    { stateCode: 'KY', countyName: 'Bracken County' },
    { stateCode: 'KY', countyName: 'Breathitt County' },
    { stateCode: 'KY', countyName: 'Breckinridge County' },
    { stateCode: 'KY', countyName: 'Bullitt County' },
    { stateCode: 'KY', countyName: 'Butler County' },
    { stateCode: 'KY', countyName: 'Caldwell County' },
    { stateCode: 'KY', countyName: 'Calloway County' },
    { stateCode: 'KY', countyName: 'Campbell County' },
    { stateCode: 'KY', countyName: 'Carlisle County' },
    { stateCode: 'KY', countyName: 'Carroll County' },
    { stateCode: 'KY', countyName: 'Carter County' },
    { stateCode: 'KY', countyName: 'Casey County' },
    { stateCode: 'KY', countyName: 'Christian County' },
    { stateCode: 'KY', countyName: 'Clark County' },
    { stateCode: 'KY', countyName: 'Clay County' },
    { stateCode: 'KY', countyName: 'Clinton County' },
    { stateCode: 'KY', countyName: 'Crittenden County' },
    { stateCode: 'KY', countyName: 'Cumberland County' },
    { stateCode: 'KY', countyName: 'Daviess County' },
    { stateCode: 'KY', countyName: 'Edmonson County' },
    { stateCode: 'KY', countyName: 'Elliott County' },
    { stateCode: 'KY', countyName: 'Estill County' },
    { stateCode: 'KY', countyName: 'Fayette County' },
    { stateCode: 'KY', countyName: 'Fleming County' },
    { stateCode: 'KY', countyName: 'Floyd County' },
    { stateCode: 'KY', countyName: 'Franklin County' },
    { stateCode: 'KY', countyName: 'Fulton County' },
    { stateCode: 'KY', countyName: 'Gallatin County' },
    { stateCode: 'KY', countyName: 'Garrard County' },
    { stateCode: 'KY', countyName: 'Grant County' },
    { stateCode: 'KY', countyName: 'Graves County' },
    { stateCode: 'KY', countyName: 'Grayson County' },
    { stateCode: 'KY', countyName: 'Green County' },
    { stateCode: 'KY', countyName: 'Greenup County' },
    { stateCode: 'KY', countyName: 'Hancock County' },
    { stateCode: 'KY', countyName: 'Hardin County' },
    { stateCode: 'KY', countyName: 'Harlan County' },
    { stateCode: 'KY', countyName: 'Harrison County' },
    { stateCode: 'KY', countyName: 'Hart County' },
    { stateCode: 'KY', countyName: 'Henderson County' },
    { stateCode: 'KY', countyName: 'Henry County' },
    { stateCode: 'KY', countyName: 'Hickman County' },
    { stateCode: 'KY', countyName: 'Hopkins County' },
    { stateCode: 'KY', countyName: 'Jackson County' },
    { stateCode: 'KY', countyName: 'Jefferson County' },
    { stateCode: 'KY', countyName: 'Jessamine County' },
    { stateCode: 'KY', countyName: 'Johnson County' },
    { stateCode: 'KY', countyName: 'Kenton County' },
    { stateCode: 'KY', countyName: 'Knott County' },
    { stateCode: 'KY', countyName: 'Knox County' },
    { stateCode: 'KY', countyName: 'Larue County' },
    { stateCode: 'KY', countyName: 'Laurel County' },
    { stateCode: 'KY', countyName: 'Lawrence County' },
    { stateCode: 'KY', countyName: 'Lee County' },
    { stateCode: 'KY', countyName: 'Leslie County' },
    { stateCode: 'KY', countyName: 'Letcher County' },
    { stateCode: 'KY', countyName: 'Lewis County' },
    { stateCode: 'KY', countyName: 'Lincoln County' },
    { stateCode: 'KY', countyName: 'Livingston County' },
    { stateCode: 'KY', countyName: 'Logan County' },
    { stateCode: 'KY', countyName: 'Lyon County' },
    { stateCode: 'KY', countyName: 'McCracken County' },
    { stateCode: 'KY', countyName: 'McCreary County' },
    { stateCode: 'KY', countyName: 'McLean County' },
    { stateCode: 'KY', countyName: 'Madison County' },
    { stateCode: 'KY', countyName: 'Magoffin County' },
    { stateCode: 'KY', countyName: 'Marion County' },
    { stateCode: 'KY', countyName: 'Marshall County' },
    { stateCode: 'KY', countyName: 'Martin County' },
    { stateCode: 'KY', countyName: 'Mason County' },
    { stateCode: 'KY', countyName: 'Meade County' },
    { stateCode: 'KY', countyName: 'Menifee County' },
    { stateCode: 'KY', countyName: 'Mercer County' },
    { stateCode: 'KY', countyName: 'Metcalfe County' },
    { stateCode: 'KY', countyName: 'Monroe County' },
    { stateCode: 'KY', countyName: 'Montgomery County' },
    { stateCode: 'KY', countyName: 'Morgan County' },
    { stateCode: 'KY', countyName: 'Muhlenberg County' },
    { stateCode: 'KY', countyName: 'Nelson County' },
    { stateCode: 'KY', countyName: 'Nicholas County' },
    { stateCode: 'KY', countyName: 'Ohio County' },
    { stateCode: 'KY', countyName: 'Oldham County' },
    { stateCode: 'KY', countyName: 'Owen County' },
    { stateCode: 'KY', countyName: 'Owsley County' },
    { stateCode: 'KY', countyName: 'Pendleton County' },
    { stateCode: 'KY', countyName: 'Perry County' },
    { stateCode: 'KY', countyName: 'Pike County' },
    { stateCode: 'KY', countyName: 'Powell County' },
    { stateCode: 'KY', countyName: 'Pulaski County' },
    { stateCode: 'KY', countyName: 'Robertson County' },
    { stateCode: 'KY', countyName: 'Rockcastle County' },
    { stateCode: 'KY', countyName: 'Rowan County' },
    { stateCode: 'KY', countyName: 'Russell County' },
    { stateCode: 'KY', countyName: 'Scott County' },
    { stateCode: 'KY', countyName: 'Shelby County' },
    { stateCode: 'KY', countyName: 'Simpson County' },
    { stateCode: 'KY', countyName: 'Spencer County' },
    { stateCode: 'KY', countyName: 'Taylor County' },
    { stateCode: 'KY', countyName: 'Todd County' },
    { stateCode: 'KY', countyName: 'Trigg County' },
    { stateCode: 'KY', countyName: 'Trimble County' },
    { stateCode: 'KY', countyName: 'Union County' },
    { stateCode: 'KY', countyName: 'Warren County' },
    { stateCode: 'KY', countyName: 'Washington County' },
    { stateCode: 'KY', countyName: 'Wayne County' },
    { stateCode: 'KY', countyName: 'Webster County' },
    { stateCode: 'KY', countyName: 'Whitley County' },
    { stateCode: 'KY', countyName: 'Wolfe County' },
    { stateCode: 'KY', countyName: 'Woodford County' },
    { stateCode: 'LA', countyName: 'Acadia Parish' },
    { stateCode: 'LA', countyName: 'Allen Parish' },
    { stateCode: 'LA', countyName: 'Ascension Parish' },
    { stateCode: 'LA', countyName: 'Assumption Parish' },
    { stateCode: 'LA', countyName: 'Avoyelles Parish' },
    { stateCode: 'LA', countyName: 'Beauregard Parish' },
    { stateCode: 'LA', countyName: 'Bienville Parish' },
    { stateCode: 'LA', countyName: 'Bossier Parish' },
    { stateCode: 'LA', countyName: 'Caddo Parish' },
    { stateCode: 'LA', countyName: 'Calcasieu Parish' },
    { stateCode: 'LA', countyName: 'Caldwell Parish' },
    { stateCode: 'LA', countyName: 'Cameron Parish' },
    { stateCode: 'LA', countyName: 'Catahoula Parish' },
    { stateCode: 'LA', countyName: 'Claiborne Parish' },
    { stateCode: 'LA', countyName: 'Concordia Parish' },
    { stateCode: 'LA', countyName: 'De Soto Parish' },
    { stateCode: 'LA', countyName: 'East Baton Rouge Parish' },
    { stateCode: 'LA', countyName: 'East Carroll Parish' },
    { stateCode: 'LA', countyName: 'East Feliciana Parish' },
    { stateCode: 'LA', countyName: 'Evangeline Parish' },
    { stateCode: 'LA', countyName: 'Franklin Parish' },
    { stateCode: 'LA', countyName: 'Grant Parish' },
    { stateCode: 'LA', countyName: 'Iberia Parish' },
    { stateCode: 'LA', countyName: 'Iberville Parish' },
    { stateCode: 'LA', countyName: 'Jackson Parish' },
    { stateCode: 'LA', countyName: 'Jefferson Parish' },
    { stateCode: 'LA', countyName: 'Jefferson Davis Parish' },
    { stateCode: 'LA', countyName: 'Lafayette Parish' },
    { stateCode: 'LA', countyName: 'Lafourche Parish' },
    { stateCode: 'LA', countyName: 'La Salle Parish' },
    { stateCode: 'LA', countyName: 'Lincoln Parish' },
    { stateCode: 'LA', countyName: 'Livingston Parish' },
    { stateCode: 'LA', countyName: 'Madison Parish' },
    { stateCode: 'LA', countyName: 'Morehouse Parish' },
    { stateCode: 'LA', countyName: 'Natchitoches Parish' },
    { stateCode: 'LA', countyName: 'Orleans Parish' },
    { stateCode: 'LA', countyName: 'Ouachita Parish' },
    { stateCode: 'LA', countyName: 'Plaquemines Parish' },
    { stateCode: 'LA', countyName: 'Pointe Coupee Parish' },
    { stateCode: 'LA', countyName: 'Rapides Parish' },
    { stateCode: 'LA', countyName: 'Red River Parish' },
    { stateCode: 'LA', countyName: 'Richland Parish' },
    { stateCode: 'LA', countyName: 'Sabine Parish' },
    { stateCode: 'LA', countyName: 'St. Bernard Parish' },
    { stateCode: 'LA', countyName: 'St. Charles Parish' },
    { stateCode: 'LA', countyName: 'St. Helena Parish' },
    { stateCode: 'LA', countyName: 'St. James Parish' },
    { stateCode: 'LA', countyName: 'St. John the Baptist Parish' },
    { stateCode: 'LA', countyName: 'St. Landry Parish' },
    { stateCode: 'LA', countyName: 'St. Martin Parish' },
    { stateCode: 'LA', countyName: 'St. Mary Parish' },
    { stateCode: 'LA', countyName: 'St. Tammany Parish' },
    { stateCode: 'LA', countyName: 'Tangipahoa Parish' },
    { stateCode: 'LA', countyName: 'Tensas Parish' },
    { stateCode: 'LA', countyName: 'Terrebonne Parish' },
    { stateCode: 'LA', countyName: 'Union Parish' },
    { stateCode: 'LA', countyName: 'Vermilion Parish' },
    { stateCode: 'LA', countyName: 'Vernon Parish' },
    { stateCode: 'LA', countyName: 'Washington Parish' },
    { stateCode: 'LA', countyName: 'Webster Parish' },
    { stateCode: 'LA', countyName: 'West Baton Rouge Parish' },
    { stateCode: 'LA', countyName: 'West Carroll Parish' },
    { stateCode: 'LA', countyName: 'West Feliciana Parish' },
    { stateCode: 'LA', countyName: 'Winn Parish' },
    { stateCode: 'ME', countyName: 'Androscoggin County' },
    { stateCode: 'ME', countyName: 'Aroostook County' },
    { stateCode: 'ME', countyName: 'Cumberland County' },
    { stateCode: 'ME', countyName: 'Franklin County' },
    { stateCode: 'ME', countyName: 'Hancock County' },
    { stateCode: 'ME', countyName: 'Kennebec County' },
    { stateCode: 'ME', countyName: 'Knox County' },
    { stateCode: 'ME', countyName: 'Lincoln County' },
    { stateCode: 'ME', countyName: 'Oxford County' },
    { stateCode: 'ME', countyName: 'Penobscot County' },
    { stateCode: 'ME', countyName: 'Piscataquis County' },
    { stateCode: 'ME', countyName: 'Sagadahoc County' },
    { stateCode: 'ME', countyName: 'Somerset County' },
    { stateCode: 'ME', countyName: 'Waldo County' },
    { stateCode: 'ME', countyName: 'Washington County' },
    { stateCode: 'ME', countyName: 'York County' },
    { stateCode: 'MD', countyName: 'Allegany County' },
    { stateCode: 'MD', countyName: 'Anne Arundel County' },
    { stateCode: 'MD', countyName: 'Baltimore County' },
    { stateCode: 'MD', countyName: 'Calvert County' },
    { stateCode: 'MD', countyName: 'Caroline County' },
    { stateCode: 'MD', countyName: 'Carroll County' },
    { stateCode: 'MD', countyName: 'Cecil County' },
    { stateCode: 'MD', countyName: 'Charles County' },
    { stateCode: 'MD', countyName: 'Dorchester County' },
    { stateCode: 'MD', countyName: 'Frederick County' },
    { stateCode: 'MD', countyName: 'Garrett County' },
    { stateCode: 'MD', countyName: 'Harford County' },
    { stateCode: 'MD', countyName: 'Howard County' },
    { stateCode: 'MD', countyName: 'Kent County' },
    { stateCode: 'MD', countyName: 'Montgomery County' },
    { stateCode: 'MD', countyName: "Prince George's County" },
    { stateCode: 'MD', countyName: "Queen Anne's County" },
    { stateCode: 'MD', countyName: "St. Mary's County" },
    { stateCode: 'MD', countyName: 'Somerset County' },
    { stateCode: 'MD', countyName: 'Talbot County' },
    { stateCode: 'MD', countyName: 'Washington County' },
    { stateCode: 'MD', countyName: 'Wicomico County' },
    { stateCode: 'MD', countyName: 'Worcester County' },
    { stateCode: 'MD', countyName: 'Baltimore city' },
    { stateCode: 'MA', countyName: 'Barnstable County' },
    { stateCode: 'MA', countyName: 'Berkshire County' },
    { stateCode: 'MA', countyName: 'Bristol County' },
    { stateCode: 'MA', countyName: 'Dukes County' },
    { stateCode: 'MA', countyName: 'Essex County' },
    { stateCode: 'MA', countyName: 'Franklin County' },
    { stateCode: 'MA', countyName: 'Hampden County' },
    { stateCode: 'MA', countyName: 'Hampshire County' },
    { stateCode: 'MA', countyName: 'Middlesex County' },
    { stateCode: 'MA', countyName: 'Nantucket County' },
    { stateCode: 'MA', countyName: 'Norfolk County' },
    { stateCode: 'MA', countyName: 'Plymouth County' },
    { stateCode: 'MA', countyName: 'Suffolk County' },
    { stateCode: 'MA', countyName: 'Worcester County' },
    { stateCode: 'MI', countyName: 'Alcona County' },
    { stateCode: 'MI', countyName: 'Alger County' },
    { stateCode: 'MI', countyName: 'Allegan County' },
    { stateCode: 'MI', countyName: 'Alpena County' },
    { stateCode: 'MI', countyName: 'Antrim County' },
    { stateCode: 'MI', countyName: 'Arenac County' },
    { stateCode: 'MI', countyName: 'Baraga County' },
    { stateCode: 'MI', countyName: 'Barry County' },
    { stateCode: 'MI', countyName: 'Bay County' },
    { stateCode: 'MI', countyName: 'Benzie County' },
    { stateCode: 'MI', countyName: 'Berrien County' },
    { stateCode: 'MI', countyName: 'Branch County' },
    { stateCode: 'MI', countyName: 'Calhoun County' },
    { stateCode: 'MI', countyName: 'Cass County' },
    { stateCode: 'MI', countyName: 'Charlevoix County' },
    { stateCode: 'MI', countyName: 'Cheboygan County' },
    { stateCode: 'MI', countyName: 'Chippewa County' },
    { stateCode: 'MI', countyName: 'Clare County' },
    { stateCode: 'MI', countyName: 'Clinton County' },
    { stateCode: 'MI', countyName: 'Crawford County' },
    { stateCode: 'MI', countyName: 'Delta County' },
    { stateCode: 'MI', countyName: 'Dickinson County' },
    { stateCode: 'MI', countyName: 'Eaton County' },
    { stateCode: 'MI', countyName: 'Emmet County' },
    { stateCode: 'MI', countyName: 'Genesee County' },
    { stateCode: 'MI', countyName: 'Gladwin County' },
    { stateCode: 'MI', countyName: 'Gogebic County' },
    { stateCode: 'MI', countyName: 'Grand Traverse County' },
    { stateCode: 'MI', countyName: 'Gratiot County' },
    { stateCode: 'MI', countyName: 'Hillsdale County' },
    { stateCode: 'MI', countyName: 'Houghton County' },
    { stateCode: 'MI', countyName: 'Huron County' },
    { stateCode: 'MI', countyName: 'Ingham County' },
    { stateCode: 'MI', countyName: 'Ionia County' },
    { stateCode: 'MI', countyName: 'Iosco County' },
    { stateCode: 'MI', countyName: 'Iron County' },
    { stateCode: 'MI', countyName: 'Isabella County' },
    { stateCode: 'MI', countyName: 'Jackson County' },
    { stateCode: 'MI', countyName: 'Kalamazoo County' },
    { stateCode: 'MI', countyName: 'Kalkaska County' },
    { stateCode: 'MI', countyName: 'Kent County' },
    { stateCode: 'MI', countyName: 'Keweenaw County' },
    { stateCode: 'MI', countyName: 'Lake County' },
    { stateCode: 'MI', countyName: 'Lapeer County' },
    { stateCode: 'MI', countyName: 'Leelanau County' },
    { stateCode: 'MI', countyName: 'Lenawee County' },
    { stateCode: 'MI', countyName: 'Livingston County' },
    { stateCode: 'MI', countyName: 'Luce County' },
    { stateCode: 'MI', countyName: 'Mackinac County' },
    { stateCode: 'MI', countyName: 'Macomb County' },
    { stateCode: 'MI', countyName: 'Manistee County' },
    { stateCode: 'MI', countyName: 'Marquette County' },
    { stateCode: 'MI', countyName: 'Mason County' },
    { stateCode: 'MI', countyName: 'Mecosta County' },
    { stateCode: 'MI', countyName: 'Menominee County' },
    { stateCode: 'MI', countyName: 'Midland County' },
    { stateCode: 'MI', countyName: 'Missaukee County' },
    { stateCode: 'MI', countyName: 'Monroe County' },
    { stateCode: 'MI', countyName: 'Montcalm County' },
    { stateCode: 'MI', countyName: 'Montmorency County' },
    { stateCode: 'MI', countyName: 'Muskegon County' },
    { stateCode: 'MI', countyName: 'Newaygo County' },
    { stateCode: 'MI', countyName: 'Oakland County' },
    { stateCode: 'MI', countyName: 'Oceana County' },
    { stateCode: 'MI', countyName: 'Ogemaw County' },
    { stateCode: 'MI', countyName: 'Ontonagon County' },
    { stateCode: 'MI', countyName: 'Osceola County' },
    { stateCode: 'MI', countyName: 'Oscoda County' },
    { stateCode: 'MI', countyName: 'Otsego County' },
    { stateCode: 'MI', countyName: 'Ottawa County' },
    { stateCode: 'MI', countyName: 'Presque Isle County' },
    { stateCode: 'MI', countyName: 'Roscommon County' },
    { stateCode: 'MI', countyName: 'Saginaw County' },
    { stateCode: 'MI', countyName: 'St. Clair County' },
    { stateCode: 'MI', countyName: 'St. Joseph County' },
    { stateCode: 'MI', countyName: 'Sanilac County' },
    { stateCode: 'MI', countyName: 'Schoolcraft County' },
    { stateCode: 'MI', countyName: 'Shiawassee County' },
    { stateCode: 'MI', countyName: 'Tuscola County' },
    { stateCode: 'MI', countyName: 'Van Buren County' },
    { stateCode: 'MI', countyName: 'Washtenaw County' },
    { stateCode: 'MI', countyName: 'Wayne County' },
    { stateCode: 'MI', countyName: 'Wexford County' },
    { stateCode: 'MN', countyName: 'Aitkin County' },
    { stateCode: 'MN', countyName: 'Anoka County' },
    { stateCode: 'MN', countyName: 'Becker County' },
    { stateCode: 'MN', countyName: 'Beltrami County' },
    { stateCode: 'MN', countyName: 'Benton County' },
    { stateCode: 'MN', countyName: 'Big Stone County' },
    { stateCode: 'MN', countyName: 'Blue Earth County' },
    { stateCode: 'MN', countyName: 'Brown County' },
    { stateCode: 'MN', countyName: 'Carlton County' },
    { stateCode: 'MN', countyName: 'Carver County' },
    { stateCode: 'MN', countyName: 'Cass County' },
    { stateCode: 'MN', countyName: 'Chippewa County' },
    { stateCode: 'MN', countyName: 'Chisago County' },
    { stateCode: 'MN', countyName: 'Clay County' },
    { stateCode: 'MN', countyName: 'Clearwater County' },
    { stateCode: 'MN', countyName: 'Cook County' },
    { stateCode: 'MN', countyName: 'Cottonwood County' },
    { stateCode: 'MN', countyName: 'Crow Wing County' },
    { stateCode: 'MN', countyName: 'Dakota County' },
    { stateCode: 'MN', countyName: 'Dodge County' },
    { stateCode: 'MN', countyName: 'Douglas County' },
    { stateCode: 'MN', countyName: 'Faribault County' },
    { stateCode: 'MN', countyName: 'Fillmore County' },
    { stateCode: 'MN', countyName: 'Freeborn County' },
    { stateCode: 'MN', countyName: 'Goodhue County' },
    { stateCode: 'MN', countyName: 'Grant County' },
    { stateCode: 'MN', countyName: 'Hennepin County' },
    { stateCode: 'MN', countyName: 'Houston County' },
    { stateCode: 'MN', countyName: 'Hubbard County' },
    { stateCode: 'MN', countyName: 'Isanti County' },
    { stateCode: 'MN', countyName: 'Itasca County' },
    { stateCode: 'MN', countyName: 'Jackson County' },
    { stateCode: 'MN', countyName: 'Kanabec County' },
    { stateCode: 'MN', countyName: 'Kandiyohi County' },
    { stateCode: 'MN', countyName: 'Kittson County' },
    { stateCode: 'MN', countyName: 'Koochiching County' },
    { stateCode: 'MN', countyName: 'Lac qui Parle County' },
    { stateCode: 'MN', countyName: 'Lake County' },
    { stateCode: 'MN', countyName: 'Lake of the Woods County' },
    { stateCode: 'MN', countyName: 'Le Sueur County' },
    { stateCode: 'MN', countyName: 'Lincoln County' },
    { stateCode: 'MN', countyName: 'Lyon County' },
    { stateCode: 'MN', countyName: 'McLeod County' },
    { stateCode: 'MN', countyName: 'Mahnomen County' },
    { stateCode: 'MN', countyName: 'Marshall County' },
    { stateCode: 'MN', countyName: 'Martin County' },
    { stateCode: 'MN', countyName: 'Meeker County' },
    { stateCode: 'MN', countyName: 'Mille Lacs County' },
    { stateCode: 'MN', countyName: 'Morrison County' },
    { stateCode: 'MN', countyName: 'Mower County' },
    { stateCode: 'MN', countyName: 'Murray County' },
    { stateCode: 'MN', countyName: 'Nicollet County' },
    { stateCode: 'MN', countyName: 'Nobles County' },
    { stateCode: 'MN', countyName: 'Norman County' },
    { stateCode: 'MN', countyName: 'Olmsted County' },
    { stateCode: 'MN', countyName: 'Otter Tail County' },
    { stateCode: 'MN', countyName: 'Pennington County' },
    { stateCode: 'MN', countyName: 'Pine County' },
    { stateCode: 'MN', countyName: 'Pipestone County' },
    { stateCode: 'MN', countyName: 'Polk County' },
    { stateCode: 'MN', countyName: 'Pope County' },
    { stateCode: 'MN', countyName: 'Ramsey County' },
    { stateCode: 'MN', countyName: 'Red Lake County' },
    { stateCode: 'MN', countyName: 'Redwood County' },
    { stateCode: 'MN', countyName: 'Renville County' },
    { stateCode: 'MN', countyName: 'Rice County' },
    { stateCode: 'MN', countyName: 'Rock County' },
    { stateCode: 'MN', countyName: 'Roseau County' },
    { stateCode: 'MN', countyName: 'St. Louis County' },
    { stateCode: 'MN', countyName: 'Scott County' },
    { stateCode: 'MN', countyName: 'Sherburne County' },
    { stateCode: 'MN', countyName: 'Sibley County' },
    { stateCode: 'MN', countyName: 'Stearns County' },
    { stateCode: 'MN', countyName: 'Steele County' },
    { stateCode: 'MN', countyName: 'Stevens County' },
    { stateCode: 'MN', countyName: 'Swift County' },
    { stateCode: 'MN', countyName: 'Todd County' },
    { stateCode: 'MN', countyName: 'Traverse County' },
    { stateCode: 'MN', countyName: 'Wabasha County' },
    { stateCode: 'MN', countyName: 'Wadena County' },
    { stateCode: 'MN', countyName: 'Waseca County' },
    { stateCode: 'MN', countyName: 'Washington County' },
    { stateCode: 'MN', countyName: 'Watonwan County' },
    { stateCode: 'MN', countyName: 'Wilkin County' },
    { stateCode: 'MN', countyName: 'Winona County' },
    { stateCode: 'MN', countyName: 'Wright County' },
    { stateCode: 'MN', countyName: 'Yellow Medicine County' },
    { stateCode: 'MS', countyName: 'Adams County' },
    { stateCode: 'MS', countyName: 'Alcorn County' },
    { stateCode: 'MS', countyName: 'Amite County' },
    { stateCode: 'MS', countyName: 'Attala County' },
    { stateCode: 'MS', countyName: 'Benton County' },
    { stateCode: 'MS', countyName: 'Bolivar County' },
    { stateCode: 'MS', countyName: 'Calhoun County' },
    { stateCode: 'MS', countyName: 'Carroll County' },
    { stateCode: 'MS', countyName: 'Chickasaw County' },
    { stateCode: 'MS', countyName: 'Choctaw County' },
    { stateCode: 'MS', countyName: 'Claiborne County' },
    { stateCode: 'MS', countyName: 'Clarke County' },
    { stateCode: 'MS', countyName: 'Clay County' },
    { stateCode: 'MS', countyName: 'Coahoma County' },
    { stateCode: 'MS', countyName: 'Copiah County' },
    { stateCode: 'MS', countyName: 'Covington County' },
    { stateCode: 'MS', countyName: 'DeSoto County' },
    { stateCode: 'MS', countyName: 'Forrest County' },
    { stateCode: 'MS', countyName: 'Franklin County' },
    { stateCode: 'MS', countyName: 'George County' },
    { stateCode: 'MS', countyName: 'Greene County' },
    { stateCode: 'MS', countyName: 'Grenada County' },
    { stateCode: 'MS', countyName: 'Hancock County' },
    { stateCode: 'MS', countyName: 'Harrison County' },
    { stateCode: 'MS', countyName: 'Hinds County' },
    { stateCode: 'MS', countyName: 'Holmes County' },
    { stateCode: 'MS', countyName: 'Humphreys County' },
    { stateCode: 'MS', countyName: 'Issaquena County' },
    { stateCode: 'MS', countyName: 'Itawamba County' },
    { stateCode: 'MS', countyName: 'Jackson County' },
    { stateCode: 'MS', countyName: 'Jasper County' },
    { stateCode: 'MS', countyName: 'Jefferson County' },
    { stateCode: 'MS', countyName: 'Jefferson Davis County' },
    { stateCode: 'MS', countyName: 'Jones County' },
    { stateCode: 'MS', countyName: 'Kemper County' },
    { stateCode: 'MS', countyName: 'Lafayette County' },
    { stateCode: 'MS', countyName: 'Lamar County' },
    { stateCode: 'MS', countyName: 'Lauderdale County' },
    { stateCode: 'MS', countyName: 'Lawrence County' },
    { stateCode: 'MS', countyName: 'Leake County' },
    { stateCode: 'MS', countyName: 'Lee County' },
    { stateCode: 'MS', countyName: 'Leflore County' },
    { stateCode: 'MS', countyName: 'Lincoln County' },
    { stateCode: 'MS', countyName: 'Lowndes County' },
    { stateCode: 'MS', countyName: 'Madison County' },
    { stateCode: 'MS', countyName: 'Marion County' },
    { stateCode: 'MS', countyName: 'Marshall County' },
    { stateCode: 'MS', countyName: 'Monroe County' },
    { stateCode: 'MS', countyName: 'Montgomery County' },
    { stateCode: 'MS', countyName: 'Neshoba County' },
    { stateCode: 'MS', countyName: 'Newton County' },
    { stateCode: 'MS', countyName: 'Noxubee County' },
    { stateCode: 'MS', countyName: 'Oktibbeha County' },
    { stateCode: 'MS', countyName: 'Panola County' },
    { stateCode: 'MS', countyName: 'Pearl River County' },
    { stateCode: 'MS', countyName: 'Perry County' },
    { stateCode: 'MS', countyName: 'Pike County' },
    { stateCode: 'MS', countyName: 'Pontotoc County' },
    { stateCode: 'MS', countyName: 'Prentiss County' },
    { stateCode: 'MS', countyName: 'Quitman County' },
    { stateCode: 'MS', countyName: 'Rankin County' },
    { stateCode: 'MS', countyName: 'Scott County' },
    { stateCode: 'MS', countyName: 'Sharkey County' },
    { stateCode: 'MS', countyName: 'Simpson County' },
    { stateCode: 'MS', countyName: 'Smith County' },
    { stateCode: 'MS', countyName: 'Stone County' },
    { stateCode: 'MS', countyName: 'Sunflower County' },
    { stateCode: 'MS', countyName: 'Tallahatchie County' },
    { stateCode: 'MS', countyName: 'Tate County' },
    { stateCode: 'MS', countyName: 'Tippah County' },
    { stateCode: 'MS', countyName: 'Tishomingo County' },
    { stateCode: 'MS', countyName: 'Tunica County' },
    { stateCode: 'MS', countyName: 'Union County' },
    { stateCode: 'MS', countyName: 'Walthall County' },
    { stateCode: 'MS', countyName: 'Warren County' },
    { stateCode: 'MS', countyName: 'Washington County' },
    { stateCode: 'MS', countyName: 'Wayne County' },
    { stateCode: 'MS', countyName: 'Webster County' },
    { stateCode: 'MS', countyName: 'Wilkinson County' },
    { stateCode: 'MS', countyName: 'Winston County' },
    { stateCode: 'MS', countyName: 'Yalobusha County' },
    { stateCode: 'MS', countyName: 'Yazoo County' },
    { stateCode: 'MO', countyName: 'Adair County' },
    { stateCode: 'MO', countyName: 'Andrew County' },
    { stateCode: 'MO', countyName: 'Atchison County' },
    { stateCode: 'MO', countyName: 'Audrain County' },
    { stateCode: 'MO', countyName: 'Barry County' },
    { stateCode: 'MO', countyName: 'Barton County' },
    { stateCode: 'MO', countyName: 'Bates County' },
    { stateCode: 'MO', countyName: 'Benton County' },
    { stateCode: 'MO', countyName: 'Bollinger County' },
    { stateCode: 'MO', countyName: 'Boone County' },
    { stateCode: 'MO', countyName: 'Buchanan County' },
    { stateCode: 'MO', countyName: 'Butler County' },
    { stateCode: 'MO', countyName: 'Caldwell County' },
    { stateCode: 'MO', countyName: 'Callaway County' },
    { stateCode: 'MO', countyName: 'Camden County' },
    { stateCode: 'MO', countyName: 'Cape Girardeau County' },
    { stateCode: 'MO', countyName: 'Carroll County' },
    { stateCode: 'MO', countyName: 'Carter County' },
    { stateCode: 'MO', countyName: 'Cass County' },
    { stateCode: 'MO', countyName: 'Cedar County' },
    { stateCode: 'MO', countyName: 'Chariton County' },
    { stateCode: 'MO', countyName: 'Christian County' },
    { stateCode: 'MO', countyName: 'Clark County' },
    { stateCode: 'MO', countyName: 'Clay County' },
    { stateCode: 'MO', countyName: 'Clinton County' },
    { stateCode: 'MO', countyName: 'Cole County' },
    { stateCode: 'MO', countyName: 'Cooper County' },
    { stateCode: 'MO', countyName: 'Crawford County' },
    { stateCode: 'MO', countyName: 'Dade County' },
    { stateCode: 'MO', countyName: 'Dallas County' },
    { stateCode: 'MO', countyName: 'Daviess County' },
    { stateCode: 'MO', countyName: 'DeKalb County' },
    { stateCode: 'MO', countyName: 'Dent County' },
    { stateCode: 'MO', countyName: 'Douglas County' },
    { stateCode: 'MO', countyName: 'Dunklin County' },
    { stateCode: 'MO', countyName: 'Franklin County' },
    { stateCode: 'MO', countyName: 'Gasconade County' },
    { stateCode: 'MO', countyName: 'Gentry County' },
    { stateCode: 'MO', countyName: 'Greene County' },
    { stateCode: 'MO', countyName: 'Grundy County' },
    { stateCode: 'MO', countyName: 'Harrison County' },
    { stateCode: 'MO', countyName: 'Henry County' },
    { stateCode: 'MO', countyName: 'Hickory County' },
    { stateCode: 'MO', countyName: 'Holt County' },
    { stateCode: 'MO', countyName: 'Howard County' },
    { stateCode: 'MO', countyName: 'Howell County' },
    { stateCode: 'MO', countyName: 'Iron County' },
    { stateCode: 'MO', countyName: 'Jackson County' },
    { stateCode: 'MO', countyName: 'Jasper County' },
    { stateCode: 'MO', countyName: 'Jefferson County' },
    { stateCode: 'MO', countyName: 'Johnson County' },
    { stateCode: 'MO', countyName: 'Knox County' },
    { stateCode: 'MO', countyName: 'Laclede County' },
    { stateCode: 'MO', countyName: 'Lafayette County' },
    { stateCode: 'MO', countyName: 'Lawrence County' },
    { stateCode: 'MO', countyName: 'Lewis County' },
    { stateCode: 'MO', countyName: 'Lincoln County' },
    { stateCode: 'MO', countyName: 'Linn County' },
    { stateCode: 'MO', countyName: 'Livingston County' },
    { stateCode: 'MO', countyName: 'McDonald County' },
    { stateCode: 'MO', countyName: 'Macon County' },
    { stateCode: 'MO', countyName: 'Madison County' },
    { stateCode: 'MO', countyName: 'Maries County' },
    { stateCode: 'MO', countyName: 'Marion County' },
    { stateCode: 'MO', countyName: 'Mercer County' },
    { stateCode: 'MO', countyName: 'Miller County' },
    { stateCode: 'MO', countyName: 'Mississippi County' },
    { stateCode: 'MO', countyName: 'Moniteau County' },
    { stateCode: 'MO', countyName: 'Monroe County' },
    { stateCode: 'MO', countyName: 'Montgomery County' },
    { stateCode: 'MO', countyName: 'Morgan County' },
    { stateCode: 'MO', countyName: 'New Madrid County' },
    { stateCode: 'MO', countyName: 'Newton County' },
    { stateCode: 'MO', countyName: 'Nodaway County' },
    { stateCode: 'MO', countyName: 'Oregon County' },
    { stateCode: 'MO', countyName: 'Osage County' },
    { stateCode: 'MO', countyName: 'Ozark County' },
    { stateCode: 'MO', countyName: 'Pemiscot County' },
    { stateCode: 'MO', countyName: 'Perry County' },
    { stateCode: 'MO', countyName: 'Pettis County' },
    { stateCode: 'MO', countyName: 'Phelps County' },
    { stateCode: 'MO', countyName: 'Pike County' },
    { stateCode: 'MO', countyName: 'Platte County' },
    { stateCode: 'MO', countyName: 'Polk County' },
    { stateCode: 'MO', countyName: 'Pulaski County' },
    { stateCode: 'MO', countyName: 'Putnam County' },
    { stateCode: 'MO', countyName: 'Ralls County' },
    { stateCode: 'MO', countyName: 'Randolph County' },
    { stateCode: 'MO', countyName: 'Ray County' },
    { stateCode: 'MO', countyName: 'Reynolds County' },
    { stateCode: 'MO', countyName: 'Ripley County' },
    { stateCode: 'MO', countyName: 'St. Charles County' },
    { stateCode: 'MO', countyName: 'St. Clair County' },
    { stateCode: 'MO', countyName: 'Ste. Genevieve County' },
    { stateCode: 'MO', countyName: 'St. Francois County' },
    { stateCode: 'MO', countyName: 'St. Louis County' },
    { stateCode: 'MO', countyName: 'Saline County' },
    { stateCode: 'MO', countyName: 'Schuyler County' },
    { stateCode: 'MO', countyName: 'Scotland County' },
    { stateCode: 'MO', countyName: 'Scott County' },
    { stateCode: 'MO', countyName: 'Shannon County' },
    { stateCode: 'MO', countyName: 'Shelby County' },
    { stateCode: 'MO', countyName: 'Stoddard County' },
    { stateCode: 'MO', countyName: 'Stone County' },
    { stateCode: 'MO', countyName: 'Sullivan County' },
    { stateCode: 'MO', countyName: 'Taney County' },
    { stateCode: 'MO', countyName: 'Texas County' },
    { stateCode: 'MO', countyName: 'Vernon County' },
    { stateCode: 'MO', countyName: 'Warren County' },
    { stateCode: 'MO', countyName: 'Washington County' },
    { stateCode: 'MO', countyName: 'Wayne County' },
    { stateCode: 'MO', countyName: 'Webster County' },
    { stateCode: 'MO', countyName: 'Worth County' },
    { stateCode: 'MO', countyName: 'Wright County' },
    { stateCode: 'MO', countyName: 'St. Louis city' },
    { stateCode: 'MT', countyName: 'Beaverhead County' },
    { stateCode: 'MT', countyName: 'Big Horn County' },
    { stateCode: 'MT', countyName: 'Blaine County' },
    { stateCode: 'MT', countyName: 'Broadwater County' },
    { stateCode: 'MT', countyName: 'Carbon County' },
    { stateCode: 'MT', countyName: 'Carter County' },
    { stateCode: 'MT', countyName: 'Cascade County' },
    { stateCode: 'MT', countyName: 'Chouteau County' },
    { stateCode: 'MT', countyName: 'Custer County' },
    { stateCode: 'MT', countyName: 'Daniels County' },
    { stateCode: 'MT', countyName: 'Dawson County' },
    { stateCode: 'MT', countyName: 'Deer Lodge County' },
    { stateCode: 'MT', countyName: 'Fallon County' },
    { stateCode: 'MT', countyName: 'Fergus County' },
    { stateCode: 'MT', countyName: 'Flathead County' },
    { stateCode: 'MT', countyName: 'Gallatin County' },
    { stateCode: 'MT', countyName: 'Garfield County' },
    { stateCode: 'MT', countyName: 'Glacier County' },
    { stateCode: 'MT', countyName: 'Golden Valley County' },
    { stateCode: 'MT', countyName: 'Granite County' },
    { stateCode: 'MT', countyName: 'Hill County' },
    { stateCode: 'MT', countyName: 'Jefferson County' },
    { stateCode: 'MT', countyName: 'Judith Basin County' },
    { stateCode: 'MT', countyName: 'Lake County' },
    { stateCode: 'MT', countyName: 'Lewis and Clark County' },
    { stateCode: 'MT', countyName: 'Liberty County' },
    { stateCode: 'MT', countyName: 'Lincoln County' },
    { stateCode: 'MT', countyName: 'McCone County' },
    { stateCode: 'MT', countyName: 'Madison County' },
    { stateCode: 'MT', countyName: 'Meagher County' },
    { stateCode: 'MT', countyName: 'Mineral County' },
    { stateCode: 'MT', countyName: 'Missoula County' },
    { stateCode: 'MT', countyName: 'Musselshell County' },
    { stateCode: 'MT', countyName: 'Park County' },
    { stateCode: 'MT', countyName: 'Petroleum County' },
    { stateCode: 'MT', countyName: 'Phillips County' },
    { stateCode: 'MT', countyName: 'Pondera County' },
    { stateCode: 'MT', countyName: 'Powder River County' },
    { stateCode: 'MT', countyName: 'Powell County' },
    { stateCode: 'MT', countyName: 'Prairie County' },
    { stateCode: 'MT', countyName: 'Ravalli County' },
    { stateCode: 'MT', countyName: 'Richland County' },
    { stateCode: 'MT', countyName: 'Roosevelt County' },
    { stateCode: 'MT', countyName: 'Rosebud County' },
    { stateCode: 'MT', countyName: 'Sanders County' },
    { stateCode: 'MT', countyName: 'Sheridan County' },
    { stateCode: 'MT', countyName: 'Silver Bow County' },
    { stateCode: 'MT', countyName: 'Stillwater County' },
    { stateCode: 'MT', countyName: 'Sweet Grass County' },
    { stateCode: 'MT', countyName: 'Teton County' },
    { stateCode: 'MT', countyName: 'Toole County' },
    { stateCode: 'MT', countyName: 'Treasure County' },
    { stateCode: 'MT', countyName: 'Valley County' },
    { stateCode: 'MT', countyName: 'Wheatland County' },
    { stateCode: 'MT', countyName: 'Wibaux County' },
    { stateCode: 'MT', countyName: 'Yellowstone County' },
    { stateCode: 'NE', countyName: 'Adams County' },
    { stateCode: 'NE', countyName: 'Antelope County' },
    { stateCode: 'NE', countyName: 'Arthur County' },
    { stateCode: 'NE', countyName: 'Banner County' },
    { stateCode: 'NE', countyName: 'Blaine County' },
    { stateCode: 'NE', countyName: 'Boone County' },
    { stateCode: 'NE', countyName: 'Box Butte County' },
    { stateCode: 'NE', countyName: 'Boyd County' },
    { stateCode: 'NE', countyName: 'Brown County' },
    { stateCode: 'NE', countyName: 'Buffalo County' },
    { stateCode: 'NE', countyName: 'Burt County' },
    { stateCode: 'NE', countyName: 'Butler County' },
    { stateCode: 'NE', countyName: 'Cass County' },
    { stateCode: 'NE', countyName: 'Cedar County' },
    { stateCode: 'NE', countyName: 'Chase County' },
    { stateCode: 'NE', countyName: 'Cherry County' },
    { stateCode: 'NE', countyName: 'Cheyenne County' },
    { stateCode: 'NE', countyName: 'Clay County' },
    { stateCode: 'NE', countyName: 'Colfax County' },
    { stateCode: 'NE', countyName: 'Cuming County' },
    { stateCode: 'NE', countyName: 'Custer County' },
    { stateCode: 'NE', countyName: 'Dakota County' },
    { stateCode: 'NE', countyName: 'Dawes County' },
    { stateCode: 'NE', countyName: 'Dawson County' },
    { stateCode: 'NE', countyName: 'Deuel County' },
    { stateCode: 'NE', countyName: 'Dixon County' },
    { stateCode: 'NE', countyName: 'Dodge County' },
    { stateCode: 'NE', countyName: 'Douglas County' },
    { stateCode: 'NE', countyName: 'Dundy County' },
    { stateCode: 'NE', countyName: 'Fillmore County' },
    { stateCode: 'NE', countyName: 'Franklin County' },
    { stateCode: 'NE', countyName: 'Frontier County' },
    { stateCode: 'NE', countyName: 'Furnas County' },
    { stateCode: 'NE', countyName: 'Gage County' },
    { stateCode: 'NE', countyName: 'Garden County' },
    { stateCode: 'NE', countyName: 'Garfield County' },
    { stateCode: 'NE', countyName: 'Gosper County' },
    { stateCode: 'NE', countyName: 'Grant County' },
    { stateCode: 'NE', countyName: 'Greeley County' },
    { stateCode: 'NE', countyName: 'Hall County' },
    { stateCode: 'NE', countyName: 'Hamilton County' },
    { stateCode: 'NE', countyName: 'Harlan County' },
    { stateCode: 'NE', countyName: 'Hayes County' },
    { stateCode: 'NE', countyName: 'Hitchcock County' },
    { stateCode: 'NE', countyName: 'Holt County' },
    { stateCode: 'NE', countyName: 'Hooker County' },
    { stateCode: 'NE', countyName: 'Howard County' },
    { stateCode: 'NE', countyName: 'Jefferson County' },
    { stateCode: 'NE', countyName: 'Johnson County' },
    { stateCode: 'NE', countyName: 'Kearney County' },
    { stateCode: 'NE', countyName: 'Keith County' },
    { stateCode: 'NE', countyName: 'Keya Paha County' },
    { stateCode: 'NE', countyName: 'Kimball County' },
    { stateCode: 'NE', countyName: 'Knox County' },
    { stateCode: 'NE', countyName: 'Lancaster County' },
    { stateCode: 'NE', countyName: 'Lincoln County' },
    { stateCode: 'NE', countyName: 'Logan County' },
    { stateCode: 'NE', countyName: 'Loup County' },
    { stateCode: 'NE', countyName: 'McPherson County' },
    { stateCode: 'NE', countyName: 'Madison County' },
    { stateCode: 'NE', countyName: 'Merrick County' },
    { stateCode: 'NE', countyName: 'Morrill County' },
    { stateCode: 'NE', countyName: 'Nance County' },
    { stateCode: 'NE', countyName: 'Nemaha County' },
    { stateCode: 'NE', countyName: 'Nuckolls County' },
    { stateCode: 'NE', countyName: 'Otoe County' },
    { stateCode: 'NE', countyName: 'Pawnee County' },
    { stateCode: 'NE', countyName: 'Perkins County' },
    { stateCode: 'NE', countyName: 'Phelps County' },
    { stateCode: 'NE', countyName: 'Pierce County' },
    { stateCode: 'NE', countyName: 'Platte County' },
    { stateCode: 'NE', countyName: 'Polk County' },
    { stateCode: 'NE', countyName: 'Red Willow County' },
    { stateCode: 'NE', countyName: 'Richardson County' },
    { stateCode: 'NE', countyName: 'Rock County' },
    { stateCode: 'NE', countyName: 'Saline County' },
    { stateCode: 'NE', countyName: 'Sarpy County' },
    { stateCode: 'NE', countyName: 'Saunders County' },
    { stateCode: 'NE', countyName: 'Scotts Bluff County' },
    { stateCode: 'NE', countyName: 'Seward County' },
    { stateCode: 'NE', countyName: 'Sheridan County' },
    { stateCode: 'NE', countyName: 'Sherman County' },
    { stateCode: 'NE', countyName: 'Sioux County' },
    { stateCode: 'NE', countyName: 'Stanton County' },
    { stateCode: 'NE', countyName: 'Thayer County' },
    { stateCode: 'NE', countyName: 'Thomas County' },
    { stateCode: 'NE', countyName: 'Thurston County' },
    { stateCode: 'NE', countyName: 'Valley County' },
    { stateCode: 'NE', countyName: 'Washington County' },
    { stateCode: 'NE', countyName: 'Wayne County' },
    { stateCode: 'NE', countyName: 'Webster County' },
    { stateCode: 'NE', countyName: 'Wheeler County' },
    { stateCode: 'NE', countyName: 'York County' },
    { stateCode: 'NV', countyName: 'Churchill County' },
    { stateCode: 'NV', countyName: 'Clark County' },
    { stateCode: 'NV', countyName: 'Douglas County' },
    { stateCode: 'NV', countyName: 'Elko County' },
    { stateCode: 'NV', countyName: 'Esmeralda County' },
    { stateCode: 'NV', countyName: 'Eureka County' },
    { stateCode: 'NV', countyName: 'Humboldt County' },
    { stateCode: 'NV', countyName: 'Lander County' },
    { stateCode: 'NV', countyName: 'Lincoln County' },
    { stateCode: 'NV', countyName: 'Lyon County' },
    { stateCode: 'NV', countyName: 'Mineral County' },
    { stateCode: 'NV', countyName: 'Nye County' },
    { stateCode: 'NV', countyName: 'Pershing County' },
    { stateCode: 'NV', countyName: 'Storey County' },
    { stateCode: 'NV', countyName: 'Washoe County' },
    { stateCode: 'NV', countyName: 'White Pine County' },
    { stateCode: 'NV', countyName: 'Carson City' },
    { stateCode: 'NH', countyName: 'Belknap County' },
    { stateCode: 'NH', countyName: 'Carroll County' },
    { stateCode: 'NH', countyName: 'Cheshire County' },
    { stateCode: 'NH', countyName: 'Coos County' },
    { stateCode: 'NH', countyName: 'Grafton County' },
    { stateCode: 'NH', countyName: 'Hillsborough County' },
    { stateCode: 'NH', countyName: 'Merrimack County' },
    { stateCode: 'NH', countyName: 'Rockingham County' },
    { stateCode: 'NH', countyName: 'Strafford County' },
    { stateCode: 'NH', countyName: 'Sullivan County' },
    { stateCode: 'NJ', countyName: 'Atlantic County' },
    { stateCode: 'NJ', countyName: 'Bergen County' },
    { stateCode: 'NJ', countyName: 'Burlington County' },
    { stateCode: 'NJ', countyName: 'Camden County' },
    { stateCode: 'NJ', countyName: 'Cape May County' },
    { stateCode: 'NJ', countyName: 'Cumberland County' },
    { stateCode: 'NJ', countyName: 'Essex County' },
    { stateCode: 'NJ', countyName: 'Gloucester County' },
    { stateCode: 'NJ', countyName: 'Hudson County' },
    { stateCode: 'NJ', countyName: 'Hunterdon County' },
    { stateCode: 'NJ', countyName: 'Mercer County' },
    { stateCode: 'NJ', countyName: 'Middlesex County' },
    { stateCode: 'NJ', countyName: 'Monmouth County' },
    { stateCode: 'NJ', countyName: 'Morris County' },
    { stateCode: 'NJ', countyName: 'Ocean County' },
    { stateCode: 'NJ', countyName: 'Passaic County' },
    { stateCode: 'NJ', countyName: 'Salem County' },
    { stateCode: 'NJ', countyName: 'Somerset County' },
    { stateCode: 'NJ', countyName: 'Sussex County' },
    { stateCode: 'NJ', countyName: 'Union County' },
    { stateCode: 'NJ', countyName: 'Warren County' },
    { stateCode: 'NM', countyName: 'Bernalillo County' },
    { stateCode: 'NM', countyName: 'Catron County' },
    { stateCode: 'NM', countyName: 'Chaves County' },
    { stateCode: 'NM', countyName: 'Cibola County' },
    { stateCode: 'NM', countyName: 'Colfax County' },
    { stateCode: 'NM', countyName: 'Curry County' },
    { stateCode: 'NM', countyName: 'De Baca County' },
    { stateCode: 'NM', countyName: 'Dona Ana County' },
    { stateCode: 'NM', countyName: 'Eddy County' },
    { stateCode: 'NM', countyName: 'Grant County' },
    { stateCode: 'NM', countyName: 'Guadalupe County' },
    { stateCode: 'NM', countyName: 'Harding County' },
    { stateCode: 'NM', countyName: 'Hidalgo County' },
    { stateCode: 'NM', countyName: 'Lea County' },
    { stateCode: 'NM', countyName: 'Lincoln County' },
    { stateCode: 'NM', countyName: 'Los Alamos County' },
    { stateCode: 'NM', countyName: 'Luna County' },
    { stateCode: 'NM', countyName: 'McKinley County' },
    { stateCode: 'NM', countyName: 'Mora County' },
    { stateCode: 'NM', countyName: 'Otero County' },
    { stateCode: 'NM', countyName: 'Quay County' },
    { stateCode: 'NM', countyName: 'Rio Arriba County' },
    { stateCode: 'NM', countyName: 'Roosevelt County' },
    { stateCode: 'NM', countyName: 'Sandoval County' },
    { stateCode: 'NM', countyName: 'San Juan County' },
    { stateCode: 'NM', countyName: 'San Miguel County' },
    { stateCode: 'NM', countyName: 'Santa Fe County' },
    { stateCode: 'NM', countyName: 'Sierra County' },
    { stateCode: 'NM', countyName: 'Socorro County' },
    { stateCode: 'NM', countyName: 'Taos County' },
    { stateCode: 'NM', countyName: 'Torrance County' },
    { stateCode: 'NM', countyName: 'Union County' },
    { stateCode: 'NM', countyName: 'Valencia County' },
    { stateCode: 'NY', countyName: 'Albany County' },
    { stateCode: 'NY', countyName: 'Allegany County' },
    { stateCode: 'NY', countyName: 'Bronx County' },
    { stateCode: 'NY', countyName: 'Broome County' },
    { stateCode: 'NY', countyName: 'Cattaraugus County' },
    { stateCode: 'NY', countyName: 'Cayuga County' },
    { stateCode: 'NY', countyName: 'Chautauqua County' },
    { stateCode: 'NY', countyName: 'Chemung County' },
    { stateCode: 'NY', countyName: 'Chenango County' },
    { stateCode: 'NY', countyName: 'Clinton County' },
    { stateCode: 'NY', countyName: 'Columbia County' },
    { stateCode: 'NY', countyName: 'Cortland County' },
    { stateCode: 'NY', countyName: 'Delaware County' },
    { stateCode: 'NY', countyName: 'Dutchess County' },
    { stateCode: 'NY', countyName: 'Erie County' },
    { stateCode: 'NY', countyName: 'Essex County' },
    { stateCode: 'NY', countyName: 'Franklin County' },
    { stateCode: 'NY', countyName: 'Fulton County' },
    { stateCode: 'NY', countyName: 'Genesee County' },
    { stateCode: 'NY', countyName: 'Greene County' },
    { stateCode: 'NY', countyName: 'Hamilton County' },
    { stateCode: 'NY', countyName: 'Herkimer County' },
    { stateCode: 'NY', countyName: 'Jefferson County' },
    { stateCode: 'NY', countyName: 'Kings County' },
    { stateCode: 'NY', countyName: 'Lewis County' },
    { stateCode: 'NY', countyName: 'Livingston County' },
    { stateCode: 'NY', countyName: 'Madison County' },
    { stateCode: 'NY', countyName: 'Monroe County' },
    { stateCode: 'NY', countyName: 'Montgomery County' },
    { stateCode: 'NY', countyName: 'Nassau County' },
    { stateCode: 'NY', countyName: 'New York County' },
    { stateCode: 'NY', countyName: 'Niagara County' },
    { stateCode: 'NY', countyName: 'Oneida County' },
    { stateCode: 'NY', countyName: 'Onondaga County' },
    { stateCode: 'NY', countyName: 'Ontario County' },
    { stateCode: 'NY', countyName: 'Orange County' },
    { stateCode: 'NY', countyName: 'Orleans County' },
    { stateCode: 'NY', countyName: 'Oswego County' },
    { stateCode: 'NY', countyName: 'Otsego County' },
    { stateCode: 'NY', countyName: 'Putnam County' },
    { stateCode: 'NY', countyName: 'Queens County' },
    { stateCode: 'NY', countyName: 'Rensselaer County' },
    { stateCode: 'NY', countyName: 'Richmond County' },
    { stateCode: 'NY', countyName: 'Rockland County' },
    { stateCode: 'NY', countyName: 'St. Lawrence County' },
    { stateCode: 'NY', countyName: 'Saratoga County' },
    { stateCode: 'NY', countyName: 'Schenectady County' },
    { stateCode: 'NY', countyName: 'Schoharie County' },
    { stateCode: 'NY', countyName: 'Schuyler County' },
    { stateCode: 'NY', countyName: 'Seneca County' },
    { stateCode: 'NY', countyName: 'Steuben County' },
    { stateCode: 'NY', countyName: 'Suffolk County' },
    { stateCode: 'NY', countyName: 'Sullivan County' },
    { stateCode: 'NY', countyName: 'Tioga County' },
    { stateCode: 'NY', countyName: 'Tompkins County' },
    { stateCode: 'NY', countyName: 'Ulster County' },
    { stateCode: 'NY', countyName: 'Warren County' },
    { stateCode: 'NY', countyName: 'Washington County' },
    { stateCode: 'NY', countyName: 'Wayne County' },
    { stateCode: 'NY', countyName: 'Westchester County' },
    { stateCode: 'NY', countyName: 'Wyoming County' },
    { stateCode: 'NY', countyName: 'Yates County' },
    { stateCode: 'NC', countyName: 'Alamance County' },
    { stateCode: 'NC', countyName: 'Alexander County' },
    { stateCode: 'NC', countyName: 'Alleghany County' },
    { stateCode: 'NC', countyName: 'Anson County' },
    { stateCode: 'NC', countyName: 'Ashe County' },
    { stateCode: 'NC', countyName: 'Avery County' },
    { stateCode: 'NC', countyName: 'Beaufort County' },
    { stateCode: 'NC', countyName: 'Bertie County' },
    { stateCode: 'NC', countyName: 'Bladen County' },
    { stateCode: 'NC', countyName: 'Brunswick County' },
    { stateCode: 'NC', countyName: 'Buncombe County' },
    { stateCode: 'NC', countyName: 'Burke County' },
    { stateCode: 'NC', countyName: 'Cabarrus County' },
    { stateCode: 'NC', countyName: 'Caldwell County' },
    { stateCode: 'NC', countyName: 'Camden County' },
    { stateCode: 'NC', countyName: 'Carteret County' },
    { stateCode: 'NC', countyName: 'Caswell County' },
    { stateCode: 'NC', countyName: 'Catawba County' },
    { stateCode: 'NC', countyName: 'Chatham County' },
    { stateCode: 'NC', countyName: 'Cherokee County' },
    { stateCode: 'NC', countyName: 'Chowan County' },
    { stateCode: 'NC', countyName: 'Clay County' },
    { stateCode: 'NC', countyName: 'Cleveland County' },
    { stateCode: 'NC', countyName: 'Columbus County' },
    { stateCode: 'NC', countyName: 'Craven County' },
    { stateCode: 'NC', countyName: 'Cumberland County' },
    { stateCode: 'NC', countyName: 'Currituck County' },
    { stateCode: 'NC', countyName: 'Dare County' },
    { stateCode: 'NC', countyName: 'Davidson County' },
    { stateCode: 'NC', countyName: 'Davie County' },
    { stateCode: 'NC', countyName: 'Duplin County' },
    { stateCode: 'NC', countyName: 'Durham County' },
    { stateCode: 'NC', countyName: 'Edgecombe County' },
    { stateCode: 'NC', countyName: 'Forsyth County' },
    { stateCode: 'NC', countyName: 'Franklin County' },
    { stateCode: 'NC', countyName: 'Gaston County' },
    { stateCode: 'NC', countyName: 'Gates County' },
    { stateCode: 'NC', countyName: 'Graham County' },
    { stateCode: 'NC', countyName: 'Granville County' },
    { stateCode: 'NC', countyName: 'Greene County' },
    { stateCode: 'NC', countyName: 'Guilford County' },
    { stateCode: 'NC', countyName: 'Halifax County' },
    { stateCode: 'NC', countyName: 'Harnett County' },
    { stateCode: 'NC', countyName: 'Haywood County' },
    { stateCode: 'NC', countyName: 'Henderson County' },
    { stateCode: 'NC', countyName: 'Hertford County' },
    { stateCode: 'NC', countyName: 'Hoke County' },
    { stateCode: 'NC', countyName: 'Hyde County' },
    { stateCode: 'NC', countyName: 'Iredell County' },
    { stateCode: 'NC', countyName: 'Jackson County' },
    { stateCode: 'NC', countyName: 'Johnston County' },
    { stateCode: 'NC', countyName: 'Jones County' },
    { stateCode: 'NC', countyName: 'Lee County' },
    { stateCode: 'NC', countyName: 'Lenoir County' },
    { stateCode: 'NC', countyName: 'Lincoln County' },
    { stateCode: 'NC', countyName: 'McDowell County' },
    { stateCode: 'NC', countyName: 'Macon County' },
    { stateCode: 'NC', countyName: 'Madison County' },
    { stateCode: 'NC', countyName: 'Martin County' },
    { stateCode: 'NC', countyName: 'Mecklenburg County' },
    { stateCode: 'NC', countyName: 'Mitchell County' },
    { stateCode: 'NC', countyName: 'Montgomery County' },
    { stateCode: 'NC', countyName: 'Moore County' },
    { stateCode: 'NC', countyName: 'Nash County' },
    { stateCode: 'NC', countyName: 'New Hanover County' },
    { stateCode: 'NC', countyName: 'Northampton County' },
    { stateCode: 'NC', countyName: 'Onslow County' },
    { stateCode: 'NC', countyName: 'Orange County' },
    { stateCode: 'NC', countyName: 'Pamlico County' },
    { stateCode: 'NC', countyName: 'Pasquotank County' },
    { stateCode: 'NC', countyName: 'Pender County' },
    { stateCode: 'NC', countyName: 'Perquimans County' },
    { stateCode: 'NC', countyName: 'Person County' },
    { stateCode: 'NC', countyName: 'Pitt County' },
    { stateCode: 'NC', countyName: 'Polk County' },
    { stateCode: 'NC', countyName: 'Randolph County' },
    { stateCode: 'NC', countyName: 'Richmond County' },
    { stateCode: 'NC', countyName: 'Robeson County' },
    { stateCode: 'NC', countyName: 'Rockingham County' },
    { stateCode: 'NC', countyName: 'Rowan County' },
    { stateCode: 'NC', countyName: 'Rutherford County' },
    { stateCode: 'NC', countyName: 'Sampson County' },
    { stateCode: 'NC', countyName: 'Scotland County' },
    { stateCode: 'NC', countyName: 'Stanly County' },
    { stateCode: 'NC', countyName: 'Stokes County' },
    { stateCode: 'NC', countyName: 'Surry County' },
    { stateCode: 'NC', countyName: 'Swain County' },
    { stateCode: 'NC', countyName: 'Transylvania County' },
    { stateCode: 'NC', countyName: 'Tyrrell County' },
    { stateCode: 'NC', countyName: 'Union County' },
    { stateCode: 'NC', countyName: 'Vance County' },
    { stateCode: 'NC', countyName: 'Wake County' },
    { stateCode: 'NC', countyName: 'Warren County' },
    { stateCode: 'NC', countyName: 'Washington County' },
    { stateCode: 'NC', countyName: 'Watauga County' },
    { stateCode: 'NC', countyName: 'Wayne County' },
    { stateCode: 'NC', countyName: 'Wilkes County' },
    { stateCode: 'NC', countyName: 'Wilson County' },
    { stateCode: 'NC', countyName: 'Yadkin County' },
    { stateCode: 'NC', countyName: 'Yancey County' },
    { stateCode: 'ND', countyName: 'Adams County' },
    { stateCode: 'ND', countyName: 'Barnes County' },
    { stateCode: 'ND', countyName: 'Benson County' },
    { stateCode: 'ND', countyName: 'Billings County' },
    { stateCode: 'ND', countyName: 'Bottineau County' },
    { stateCode: 'ND', countyName: 'Bowman County' },
    { stateCode: 'ND', countyName: 'Burke County' },
    { stateCode: 'ND', countyName: 'Burleigh County' },
    { stateCode: 'ND', countyName: 'Cass County' },
    { stateCode: 'ND', countyName: 'Cavalier County' },
    { stateCode: 'ND', countyName: 'Dickey County' },
    { stateCode: 'ND', countyName: 'Divide County' },
    { stateCode: 'ND', countyName: 'Dunn County' },
    { stateCode: 'ND', countyName: 'Eddy County' },
    { stateCode: 'ND', countyName: 'Emmons County' },
    { stateCode: 'ND', countyName: 'Foster County' },
    { stateCode: 'ND', countyName: 'Golden Valley County' },
    { stateCode: 'ND', countyName: 'Grand Forks County' },
    { stateCode: 'ND', countyName: 'Grant County' },
    { stateCode: 'ND', countyName: 'Griggs County' },
    { stateCode: 'ND', countyName: 'Hettinger County' },
    { stateCode: 'ND', countyName: 'Kidder County' },
    { stateCode: 'ND', countyName: 'LaMoure County' },
    { stateCode: 'ND', countyName: 'Logan County' },
    { stateCode: 'ND', countyName: 'McHenry County' },
    { stateCode: 'ND', countyName: 'McIntosh County' },
    { stateCode: 'ND', countyName: 'McKenzie County' },
    { stateCode: 'ND', countyName: 'McLean County' },
    { stateCode: 'ND', countyName: 'Mercer County' },
    { stateCode: 'ND', countyName: 'Morton County' },
    { stateCode: 'ND', countyName: 'Mountrail County' },
    { stateCode: 'ND', countyName: 'Nelson County' },
    { stateCode: 'ND', countyName: 'Oliver County' },
    { stateCode: 'ND', countyName: 'Pembina County' },
    { stateCode: 'ND', countyName: 'Pierce County' },
    { stateCode: 'ND', countyName: 'Ramsey County' },
    { stateCode: 'ND', countyName: 'Ransom County' },
    { stateCode: 'ND', countyName: 'Renville County' },
    { stateCode: 'ND', countyName: 'Richland County' },
    { stateCode: 'ND', countyName: 'Rolette County' },
    { stateCode: 'ND', countyName: 'Sargent County' },
    { stateCode: 'ND', countyName: 'Sheridan County' },
    { stateCode: 'ND', countyName: 'Sioux County' },
    { stateCode: 'ND', countyName: 'Slope County' },
    { stateCode: 'ND', countyName: 'Stark County' },
    { stateCode: 'ND', countyName: 'Steele County' },
    { stateCode: 'ND', countyName: 'Stutsman County' },
    { stateCode: 'ND', countyName: 'Towner County' },
    { stateCode: 'ND', countyName: 'Traill County' },
    { stateCode: 'ND', countyName: 'Walsh County' },
    { stateCode: 'ND', countyName: 'Ward County' },
    { stateCode: 'ND', countyName: 'Wells County' },
    { stateCode: 'ND', countyName: 'Williams County' },
    { stateCode: 'OH', countyName: 'Adams County' },
    { stateCode: 'OH', countyName: 'Allen County' },
    { stateCode: 'OH', countyName: 'Ashland County' },
    { stateCode: 'OH', countyName: 'Ashtabula County' },
    { stateCode: 'OH', countyName: 'Athens County' },
    { stateCode: 'OH', countyName: 'Auglaize County' },
    { stateCode: 'OH', countyName: 'Belmont County' },
    { stateCode: 'OH', countyName: 'Brown County' },
    { stateCode: 'OH', countyName: 'Butler County' },
    { stateCode: 'OH', countyName: 'Carroll County' },
    { stateCode: 'OH', countyName: 'Champaign County' },
    { stateCode: 'OH', countyName: 'Clark County' },
    { stateCode: 'OH', countyName: 'Clermont County' },
    { stateCode: 'OH', countyName: 'Clinton County' },
    { stateCode: 'OH', countyName: 'Columbiana County' },
    { stateCode: 'OH', countyName: 'Coshocton County' },
    { stateCode: 'OH', countyName: 'Crawford County' },
    { stateCode: 'OH', countyName: 'Cuyahoga County' },
    { stateCode: 'OH', countyName: 'Darke County' },
    { stateCode: 'OH', countyName: 'Defiance County' },
    { stateCode: 'OH', countyName: 'Delaware County' },
    { stateCode: 'OH', countyName: 'Erie County' },
    { stateCode: 'OH', countyName: 'Fairfield County' },
    { stateCode: 'OH', countyName: 'Fayette County' },
    { stateCode: 'OH', countyName: 'Franklin County' },
    { stateCode: 'OH', countyName: 'Fulton County' },
    { stateCode: 'OH', countyName: 'Gallia County' },
    { stateCode: 'OH', countyName: 'Geauga County' },
    { stateCode: 'OH', countyName: 'Greene County' },
    { stateCode: 'OH', countyName: 'Guernsey County' },
    { stateCode: 'OH', countyName: 'Hamilton County' },
    { stateCode: 'OH', countyName: 'Hancock County' },
    { stateCode: 'OH', countyName: 'Hardin County' },
    { stateCode: 'OH', countyName: 'Harrison County' },
    { stateCode: 'OH', countyName: 'Henry County' },
    { stateCode: 'OH', countyName: 'Highland County' },
    { stateCode: 'OH', countyName: 'Hocking County' },
    { stateCode: 'OH', countyName: 'Holmes County' },
    { stateCode: 'OH', countyName: 'Huron County' },
    { stateCode: 'OH', countyName: 'Jackson County' },
    { stateCode: 'OH', countyName: 'Jefferson County' },
    { stateCode: 'OH', countyName: 'Knox County' },
    { stateCode: 'OH', countyName: 'Lake County' },
    { stateCode: 'OH', countyName: 'Lawrence County' },
    { stateCode: 'OH', countyName: 'Licking County' },
    { stateCode: 'OH', countyName: 'Logan County' },
    { stateCode: 'OH', countyName: 'Lorain County' },
    { stateCode: 'OH', countyName: 'Lucas County' },
    { stateCode: 'OH', countyName: 'Madison County' },
    { stateCode: 'OH', countyName: 'Mahoning County' },
    { stateCode: 'OH', countyName: 'Marion County' },
    { stateCode: 'OH', countyName: 'Medina County' },
    { stateCode: 'OH', countyName: 'Meigs County' },
    { stateCode: 'OH', countyName: 'Mercer County' },
    { stateCode: 'OH', countyName: 'Miami County' },
    { stateCode: 'OH', countyName: 'Monroe County' },
    { stateCode: 'OH', countyName: 'Montgomery County' },
    { stateCode: 'OH', countyName: 'Morgan County' },
    { stateCode: 'OH', countyName: 'Morrow County' },
    { stateCode: 'OH', countyName: 'Muskingum County' },
    { stateCode: 'OH', countyName: 'Noble County' },
    { stateCode: 'OH', countyName: 'Ottawa County' },
    { stateCode: 'OH', countyName: 'Paulding County' },
    { stateCode: 'OH', countyName: 'Perry County' },
    { stateCode: 'OH', countyName: 'Pickaway County' },
    { stateCode: 'OH', countyName: 'Pike County' },
    { stateCode: 'OH', countyName: 'Portage County' },
    { stateCode: 'OH', countyName: 'Preble County' },
    { stateCode: 'OH', countyName: 'Putnam County' },
    { stateCode: 'OH', countyName: 'Richland County' },
    { stateCode: 'OH', countyName: 'Ross County' },
    { stateCode: 'OH', countyName: 'Sandusky County' },
    { stateCode: 'OH', countyName: 'Scioto County' },
    { stateCode: 'OH', countyName: 'Seneca County' },
    { stateCode: 'OH', countyName: 'Shelby County' },
    { stateCode: 'OH', countyName: 'Stark County' },
    { stateCode: 'OH', countyName: 'Summit County' },
    { stateCode: 'OH', countyName: 'Trumbull County' },
    { stateCode: 'OH', countyName: 'Tuscarawas County' },
    { stateCode: 'OH', countyName: 'Union County' },
    { stateCode: 'OH', countyName: 'Van Wert County' },
    { stateCode: 'OH', countyName: 'Vinton County' },
    { stateCode: 'OH', countyName: 'Warren County' },
    { stateCode: 'OH', countyName: 'Washington County' },
    { stateCode: 'OH', countyName: 'Wayne County' },
    { stateCode: 'OH', countyName: 'Williams County' },
    { stateCode: 'OH', countyName: 'Wood County' },
    { stateCode: 'OH', countyName: 'Wyandot County' },
    { stateCode: 'OK', countyName: 'Adair County' },
    { stateCode: 'OK', countyName: 'Alfalfa County' },
    { stateCode: 'OK', countyName: 'Atoka County' },
    { stateCode: 'OK', countyName: 'Beaver County' },
    { stateCode: 'OK', countyName: 'Beckham County' },
    { stateCode: 'OK', countyName: 'Blaine County' },
    { stateCode: 'OK', countyName: 'Bryan County' },
    { stateCode: 'OK', countyName: 'Caddo County' },
    { stateCode: 'OK', countyName: 'Canadian County' },
    { stateCode: 'OK', countyName: 'Carter County' },
    { stateCode: 'OK', countyName: 'Cherokee County' },
    { stateCode: 'OK', countyName: 'Choctaw County' },
    { stateCode: 'OK', countyName: 'Cimarron County' },
    { stateCode: 'OK', countyName: 'Cleveland County' },
    { stateCode: 'OK', countyName: 'Coal County' },
    { stateCode: 'OK', countyName: 'Comanche County' },
    { stateCode: 'OK', countyName: 'Cotton County' },
    { stateCode: 'OK', countyName: 'Craig County' },
    { stateCode: 'OK', countyName: 'Creek County' },
    { stateCode: 'OK', countyName: 'Custer County' },
    { stateCode: 'OK', countyName: 'Delaware County' },
    { stateCode: 'OK', countyName: 'Dewey County' },
    { stateCode: 'OK', countyName: 'Ellis County' },
    { stateCode: 'OK', countyName: 'Garfield County' },
    { stateCode: 'OK', countyName: 'Garvin County' },
    { stateCode: 'OK', countyName: 'Grady County' },
    { stateCode: 'OK', countyName: 'Grant County' },
    { stateCode: 'OK', countyName: 'Greer County' },
    { stateCode: 'OK', countyName: 'Harmon County' },
    { stateCode: 'OK', countyName: 'Harper County' },
    { stateCode: 'OK', countyName: 'Haskell County' },
    { stateCode: 'OK', countyName: 'Hughes County' },
    { stateCode: 'OK', countyName: 'Jackson County' },
    { stateCode: 'OK', countyName: 'Jefferson County' },
    { stateCode: 'OK', countyName: 'Johnston County' },
    { stateCode: 'OK', countyName: 'Kay County' },
    { stateCode: 'OK', countyName: 'Kingfisher County' },
    { stateCode: 'OK', countyName: 'Kiowa County' },
    { stateCode: 'OK', countyName: 'Latimer County' },
    { stateCode: 'OK', countyName: 'Le Flore County' },
    { stateCode: 'OK', countyName: 'Lincoln County' },
    { stateCode: 'OK', countyName: 'Logan County' },
    { stateCode: 'OK', countyName: 'Love County' },
    { stateCode: 'OK', countyName: 'McClain County' },
    { stateCode: 'OK', countyName: 'McCurtain County' },
    { stateCode: 'OK', countyName: 'McIntosh County' },
    { stateCode: 'OK', countyName: 'Major County' },
    { stateCode: 'OK', countyName: 'Marshall County' },
    { stateCode: 'OK', countyName: 'Mayes County' },
    { stateCode: 'OK', countyName: 'Murray County' },
    { stateCode: 'OK', countyName: 'Muskogee County' },
    { stateCode: 'OK', countyName: 'Noble County' },
    { stateCode: 'OK', countyName: 'Nowata County' },
    { stateCode: 'OK', countyName: 'Okfuskee County' },
    { stateCode: 'OK', countyName: 'Oklahoma County' },
    { stateCode: 'OK', countyName: 'Okmulgee County' },
    { stateCode: 'OK', countyName: 'Osage County' },
    { stateCode: 'OK', countyName: 'Ottawa County' },
    { stateCode: 'OK', countyName: 'Pawnee County' },
    { stateCode: 'OK', countyName: 'Payne County' },
    { stateCode: 'OK', countyName: 'Pittsburg County' },
    { stateCode: 'OK', countyName: 'Pontotoc County' },
    { stateCode: 'OK', countyName: 'Pottawatomie County' },
    { stateCode: 'OK', countyName: 'Pushmataha County' },
    { stateCode: 'OK', countyName: 'Roger Mills County' },
    { stateCode: 'OK', countyName: 'Rogers County' },
    { stateCode: 'OK', countyName: 'Seminole County' },
    { stateCode: 'OK', countyName: 'Sequoyah County' },
    { stateCode: 'OK', countyName: 'Stephens County' },
    { stateCode: 'OK', countyName: 'Texas County' },
    { stateCode: 'OK', countyName: 'Tillman County' },
    { stateCode: 'OK', countyName: 'Tulsa County' },
    { stateCode: 'OK', countyName: 'Wagoner County' },
    { stateCode: 'OK', countyName: 'Washington County' },
    { stateCode: 'OK', countyName: 'Washita County' },
    { stateCode: 'OK', countyName: 'Woods County' },
    { stateCode: 'OK', countyName: 'Woodward County' },
    { stateCode: 'OR', countyName: 'Baker County' },
    { stateCode: 'OR', countyName: 'Benton County' },
    { stateCode: 'OR', countyName: 'Clackamas County' },
    { stateCode: 'OR', countyName: 'Clatsop County' },
    { stateCode: 'OR', countyName: 'Columbia County' },
    { stateCode: 'OR', countyName: 'Coos County' },
    { stateCode: 'OR', countyName: 'Crook County' },
    { stateCode: 'OR', countyName: 'Curry County' },
    { stateCode: 'OR', countyName: 'Deschutes County' },
    { stateCode: 'OR', countyName: 'Douglas County' },
    { stateCode: 'OR', countyName: 'Gilliam County' },
    { stateCode: 'OR', countyName: 'Grant County' },
    { stateCode: 'OR', countyName: 'Harney County' },
    { stateCode: 'OR', countyName: 'Hood River County' },
    { stateCode: 'OR', countyName: 'Jackson County' },
    { stateCode: 'OR', countyName: 'Jefferson County' },
    { stateCode: 'OR', countyName: 'Josephine County' },
    { stateCode: 'OR', countyName: 'Klamath County' },
    { stateCode: 'OR', countyName: 'Lake County' },
    { stateCode: 'OR', countyName: 'Lane County' },
    { stateCode: 'OR', countyName: 'Lincoln County' },
    { stateCode: 'OR', countyName: 'Linn County' },
    { stateCode: 'OR', countyName: 'Malheur County' },
    { stateCode: 'OR', countyName: 'Marion County' },
    { stateCode: 'OR', countyName: 'Morrow County' },
    { stateCode: 'OR', countyName: 'Multnomah County' },
    { stateCode: 'OR', countyName: 'Polk County' },
    { stateCode: 'OR', countyName: 'Sherman County' },
    { stateCode: 'OR', countyName: 'Tillamook County' },
    { stateCode: 'OR', countyName: 'Umatilla County' },
    { stateCode: 'OR', countyName: 'Union County' },
    { stateCode: 'OR', countyName: 'Wallowa County' },
    { stateCode: 'OR', countyName: 'Wasco County' },
    { stateCode: 'OR', countyName: 'Washington County' },
    { stateCode: 'OR', countyName: 'Wheeler County' },
    { stateCode: 'OR', countyName: 'Yamhill County' },
    { stateCode: 'PA', countyName: 'Adams County' },
    { stateCode: 'PA', countyName: 'Allegheny County' },
    { stateCode: 'PA', countyName: 'Armstrong County' },
    { stateCode: 'PA', countyName: 'Beaver County' },
    { stateCode: 'PA', countyName: 'Bedford County' },
    { stateCode: 'PA', countyName: 'Berks County' },
    { stateCode: 'PA', countyName: 'Blair County' },
    { stateCode: 'PA', countyName: 'Bradford County' },
    { stateCode: 'PA', countyName: 'Bucks County' },
    { stateCode: 'PA', countyName: 'Butler County' },
    { stateCode: 'PA', countyName: 'Cambria County' },
    { stateCode: 'PA', countyName: 'Cameron County' },
    { stateCode: 'PA', countyName: 'Carbon County' },
    { stateCode: 'PA', countyName: 'Centre County' },
    { stateCode: 'PA', countyName: 'Chester County' },
    { stateCode: 'PA', countyName: 'Clarion County' },
    { stateCode: 'PA', countyName: 'Clearfield County' },
    { stateCode: 'PA', countyName: 'Clinton County' },
    { stateCode: 'PA', countyName: 'Columbia County' },
    { stateCode: 'PA', countyName: 'Crawford County' },
    { stateCode: 'PA', countyName: 'Cumberland County' },
    { stateCode: 'PA', countyName: 'Dauphin County' },
    { stateCode: 'PA', countyName: 'Delaware County' },
    { stateCode: 'PA', countyName: 'Elk County' },
    { stateCode: 'PA', countyName: 'Erie County' },
    { stateCode: 'PA', countyName: 'Fayette County' },
    { stateCode: 'PA', countyName: 'Forest County' },
    { stateCode: 'PA', countyName: 'Franklin County' },
    { stateCode: 'PA', countyName: 'Fulton County' },
    { stateCode: 'PA', countyName: 'Greene County' },
    { stateCode: 'PA', countyName: 'Huntingdon County' },
    { stateCode: 'PA', countyName: 'Indiana County' },
    { stateCode: 'PA', countyName: 'Jefferson County' },
    { stateCode: 'PA', countyName: 'Juniata County' },
    { stateCode: 'PA', countyName: 'Lackawanna County' },
    { stateCode: 'PA', countyName: 'Lancaster County' },
    { stateCode: 'PA', countyName: 'Lawrence County' },
    { stateCode: 'PA', countyName: 'Lebanon County' },
    { stateCode: 'PA', countyName: 'Lehigh County' },
    { stateCode: 'PA', countyName: 'Luzerne County' },
    { stateCode: 'PA', countyName: 'Lycoming County' },
    { stateCode: 'PA', countyName: 'McKean County' },
    { stateCode: 'PA', countyName: 'Mercer County' },
    { stateCode: 'PA', countyName: 'Mifflin County' },
    { stateCode: 'PA', countyName: 'Monroe County' },
    { stateCode: 'PA', countyName: 'Montgomery County' },
    { stateCode: 'PA', countyName: 'Montour County' },
    { stateCode: 'PA', countyName: 'Northampton County' },
    { stateCode: 'PA', countyName: 'Northumberland County' },
    { stateCode: 'PA', countyName: 'Perry County' },
    { stateCode: 'PA', countyName: 'Philadelphia County' },
    { stateCode: 'PA', countyName: 'Pike County' },
    { stateCode: 'PA', countyName: 'Potter County' },
    { stateCode: 'PA', countyName: 'Schuylkill County' },
    { stateCode: 'PA', countyName: 'Snyder County' },
    { stateCode: 'PA', countyName: 'Somerset County' },
    { stateCode: 'PA', countyName: 'Sullivan County' },
    { stateCode: 'PA', countyName: 'Susquehanna County' },
    { stateCode: 'PA', countyName: 'Tioga County' },
    { stateCode: 'PA', countyName: 'Union County' },
    { stateCode: 'PA', countyName: 'Venango County' },
    { stateCode: 'PA', countyName: 'Warren County' },
    { stateCode: 'PA', countyName: 'Washington County' },
    { stateCode: 'PA', countyName: 'Wayne County' },
    { stateCode: 'PA', countyName: 'Westmoreland County' },
    { stateCode: 'PA', countyName: 'Wyoming County' },
    { stateCode: 'PA', countyName: 'York County' },
    { stateCode: 'RI', countyName: 'Bristol County' },
    { stateCode: 'RI', countyName: 'Kent County' },
    { stateCode: 'RI', countyName: 'Newport County' },
    { stateCode: 'RI', countyName: 'Providence County' },
    { stateCode: 'RI', countyName: 'Washington County' },
    { stateCode: 'SC', countyName: 'Abbeville County' },
    { stateCode: 'SC', countyName: 'Aiken County' },
    { stateCode: 'SC', countyName: 'Allendale County' },
    { stateCode: 'SC', countyName: 'Anderson County' },
    { stateCode: 'SC', countyName: 'Bamberg County' },
    { stateCode: 'SC', countyName: 'Barnwell County' },
    { stateCode: 'SC', countyName: 'Beaufort County' },
    { stateCode: 'SC', countyName: 'Berkeley County' },
    { stateCode: 'SC', countyName: 'Calhoun County' },
    { stateCode: 'SC', countyName: 'Charleston County' },
    { stateCode: 'SC', countyName: 'Cherokee County' },
    { stateCode: 'SC', countyName: 'Chester County' },
    { stateCode: 'SC', countyName: 'Chesterfield County' },
    { stateCode: 'SC', countyName: 'Clarendon County' },
    { stateCode: 'SC', countyName: 'Colleton County' },
    { stateCode: 'SC', countyName: 'Darlington County' },
    { stateCode: 'SC', countyName: 'Dillon County' },
    { stateCode: 'SC', countyName: 'Dorchester County' },
    { stateCode: 'SC', countyName: 'Edgefield County' },
    { stateCode: 'SC', countyName: 'Fairfield County' },
    { stateCode: 'SC', countyName: 'Florence County' },
    { stateCode: 'SC', countyName: 'Georgetown County' },
    { stateCode: 'SC', countyName: 'Greenville County' },
    { stateCode: 'SC', countyName: 'Greenwood County' },
    { stateCode: 'SC', countyName: 'Hampton County' },
    { stateCode: 'SC', countyName: 'Horry County' },
    { stateCode: 'SC', countyName: 'Jasper County' },
    { stateCode: 'SC', countyName: 'Kershaw County' },
    { stateCode: 'SC', countyName: 'Lancaster County' },
    { stateCode: 'SC', countyName: 'Laurens County' },
    { stateCode: 'SC', countyName: 'Lee County' },
    { stateCode: 'SC', countyName: 'Lexington County' },
    { stateCode: 'SC', countyName: 'McCormick County' },
    { stateCode: 'SC', countyName: 'Marion County' },
    { stateCode: 'SC', countyName: 'Marlboro County' },
    { stateCode: 'SC', countyName: 'Newberry County' },
    { stateCode: 'SC', countyName: 'Oconee County' },
    { stateCode: 'SC', countyName: 'Orangeburg County' },
    { stateCode: 'SC', countyName: 'Pickens County' },
    { stateCode: 'SC', countyName: 'Richland County' },
    { stateCode: 'SC', countyName: 'Saluda County' },
    { stateCode: 'SC', countyName: 'Spartanburg County' },
    { stateCode: 'SC', countyName: 'Sumter County' },
    { stateCode: 'SC', countyName: 'Union County' },
    { stateCode: 'SC', countyName: 'Williamsburg County' },
    { stateCode: 'SC', countyName: 'York County' },
    { stateCode: 'SD', countyName: 'Aurora County' },
    { stateCode: 'SD', countyName: 'Beadle County' },
    { stateCode: 'SD', countyName: 'Bennett County' },
    { stateCode: 'SD', countyName: 'Bon Homme County' },
    { stateCode: 'SD', countyName: 'Brookings County' },
    { stateCode: 'SD', countyName: 'Brown County' },
    { stateCode: 'SD', countyName: 'Brule County' },
    { stateCode: 'SD', countyName: 'Buffalo County' },
    { stateCode: 'SD', countyName: 'Butte County' },
    { stateCode: 'SD', countyName: 'Campbell County' },
    { stateCode: 'SD', countyName: 'Charles Mix County' },
    { stateCode: 'SD', countyName: 'Clark County' },
    { stateCode: 'SD', countyName: 'Clay County' },
    { stateCode: 'SD', countyName: 'Codington County' },
    { stateCode: 'SD', countyName: 'Corson County' },
    { stateCode: 'SD', countyName: 'Custer County' },
    { stateCode: 'SD', countyName: 'Davison County' },
    { stateCode: 'SD', countyName: 'Day County' },
    { stateCode: 'SD', countyName: 'Deuel County' },
    { stateCode: 'SD', countyName: 'Dewey County' },
    { stateCode: 'SD', countyName: 'Douglas County' },
    { stateCode: 'SD', countyName: 'Edmunds County' },
    { stateCode: 'SD', countyName: 'Fall River County' },
    { stateCode: 'SD', countyName: 'Faulk County' },
    { stateCode: 'SD', countyName: 'Grant County' },
    { stateCode: 'SD', countyName: 'Gregory County' },
    { stateCode: 'SD', countyName: 'Haakon County' },
    { stateCode: 'SD', countyName: 'Hamlin County' },
    { stateCode: 'SD', countyName: 'Hand County' },
    { stateCode: 'SD', countyName: 'Hanson County' },
    { stateCode: 'SD', countyName: 'Harding County' },
    { stateCode: 'SD', countyName: 'Hughes County' },
    { stateCode: 'SD', countyName: 'Hutchinson County' },
    { stateCode: 'SD', countyName: 'Hyde County' },
    { stateCode: 'SD', countyName: 'Jackson County' },
    { stateCode: 'SD', countyName: 'Jerauld County' },
    { stateCode: 'SD', countyName: 'Jones County' },
    { stateCode: 'SD', countyName: 'Kingsbury County' },
    { stateCode: 'SD', countyName: 'Lake County' },
    { stateCode: 'SD', countyName: 'Lawrence County' },
    { stateCode: 'SD', countyName: 'Lincoln County' },
    { stateCode: 'SD', countyName: 'Lyman County' },
    { stateCode: 'SD', countyName: 'McCook County' },
    { stateCode: 'SD', countyName: 'McPherson County' },
    { stateCode: 'SD', countyName: 'Marshall County' },
    { stateCode: 'SD', countyName: 'Meade County' },
    { stateCode: 'SD', countyName: 'Mellette County' },
    { stateCode: 'SD', countyName: 'Miner County' },
    { stateCode: 'SD', countyName: 'Minnehaha County' },
    { stateCode: 'SD', countyName: 'Moody County' },
    { stateCode: 'SD', countyName: 'Pennington County' },
    { stateCode: 'SD', countyName: 'Perkins County' },
    { stateCode: 'SD', countyName: 'Potter County' },
    { stateCode: 'SD', countyName: 'Roberts County' },
    { stateCode: 'SD', countyName: 'Sanborn County' },
    { stateCode: 'SD', countyName: 'Shannon County' },
    { stateCode: 'SD', countyName: 'Spink County' },
    { stateCode: 'SD', countyName: 'Stanley County' },
    { stateCode: 'SD', countyName: 'Sully County' },
    { stateCode: 'SD', countyName: 'Todd County' },
    { stateCode: 'SD', countyName: 'Tripp County' },
    { stateCode: 'SD', countyName: 'Turner County' },
    { stateCode: 'SD', countyName: 'Union County' },
    { stateCode: 'SD', countyName: 'Walworth County' },
    { stateCode: 'SD', countyName: 'Yankton County' },
    { stateCode: 'SD', countyName: 'Ziebach County' },
    { stateCode: 'TN', countyName: 'Anderson County' },
    { stateCode: 'TN', countyName: 'Bedford County' },
    { stateCode: 'TN', countyName: 'Benton County' },
    { stateCode: 'TN', countyName: 'Bledsoe County' },
    { stateCode: 'TN', countyName: 'Blount County' },
    { stateCode: 'TN', countyName: 'Bradley County' },
    { stateCode: 'TN', countyName: 'Campbell County' },
    { stateCode: 'TN', countyName: 'Cannon County' },
    { stateCode: 'TN', countyName: 'Carroll County' },
    { stateCode: 'TN', countyName: 'Carter County' },
    { stateCode: 'TN', countyName: 'Cheatham County' },
    { stateCode: 'TN', countyName: 'Chester County' },
    { stateCode: 'TN', countyName: 'Claiborne County' },
    { stateCode: 'TN', countyName: 'Clay County' },
    { stateCode: 'TN', countyName: 'Cocke County' },
    { stateCode: 'TN', countyName: 'Coffee County' },
    { stateCode: 'TN', countyName: 'Crockett County' },
    { stateCode: 'TN', countyName: 'Cumberland County' },
    { stateCode: 'TN', countyName: 'Davidson County' },
    { stateCode: 'TN', countyName: 'Decatur County' },
    { stateCode: 'TN', countyName: 'DeKalb County' },
    { stateCode: 'TN', countyName: 'Dickson County' },
    { stateCode: 'TN', countyName: 'Dyer County' },
    { stateCode: 'TN', countyName: 'Fayette County' },
    { stateCode: 'TN', countyName: 'Fentress County' },
    { stateCode: 'TN', countyName: 'Franklin County' },
    { stateCode: 'TN', countyName: 'Gibson County' },
    { stateCode: 'TN', countyName: 'Giles County' },
    { stateCode: 'TN', countyName: 'Grainger County' },
    { stateCode: 'TN', countyName: 'Greene County' },
    { stateCode: 'TN', countyName: 'Grundy County' },
    { stateCode: 'TN', countyName: 'Hamblen County' },
    { stateCode: 'TN', countyName: 'Hamilton County' },
    { stateCode: 'TN', countyName: 'Hancock County' },
    { stateCode: 'TN', countyName: 'Hardeman County' },
    { stateCode: 'TN', countyName: 'Hardin County' },
    { stateCode: 'TN', countyName: 'Hawkins County' },
    { stateCode: 'TN', countyName: 'Haywood County' },
    { stateCode: 'TN', countyName: 'Henderson County' },
    { stateCode: 'TN', countyName: 'Henry County' },
    { stateCode: 'TN', countyName: 'Hickman County' },
    { stateCode: 'TN', countyName: 'Houston County' },
    { stateCode: 'TN', countyName: 'Humphreys County' },
    { stateCode: 'TN', countyName: 'Jackson County' },
    { stateCode: 'TN', countyName: 'Jefferson County' },
    { stateCode: 'TN', countyName: 'Johnson County' },
    { stateCode: 'TN', countyName: 'Knox County' },
    { stateCode: 'TN', countyName: 'Lake County' },
    { stateCode: 'TN', countyName: 'Lauderdale County' },
    { stateCode: 'TN', countyName: 'Lawrence County' },
    { stateCode: 'TN', countyName: 'Lewis County' },
    { stateCode: 'TN', countyName: 'Lincoln County' },
    { stateCode: 'TN', countyName: 'Loudon County' },
    { stateCode: 'TN', countyName: 'McMinn County' },
    { stateCode: 'TN', countyName: 'McNairy County' },
    { stateCode: 'TN', countyName: 'Macon County' },
    { stateCode: 'TN', countyName: 'Madison County' },
    { stateCode: 'TN', countyName: 'Marion County' },
    { stateCode: 'TN', countyName: 'Marshall County' },
    { stateCode: 'TN', countyName: 'Maury County' },
    { stateCode: 'TN', countyName: 'Meigs County' },
    { stateCode: 'TN', countyName: 'Monroe County' },
    { stateCode: 'TN', countyName: 'Montgomery County' },
    { stateCode: 'TN', countyName: 'Moore County' },
    { stateCode: 'TN', countyName: 'Morgan County' },
    { stateCode: 'TN', countyName: 'Obion County' },
    { stateCode: 'TN', countyName: 'Overton County' },
    { stateCode: 'TN', countyName: 'Perry County' },
    { stateCode: 'TN', countyName: 'Pickett County' },
    { stateCode: 'TN', countyName: 'Polk County' },
    { stateCode: 'TN', countyName: 'Putnam County' },
    { stateCode: 'TN', countyName: 'Rhea County' },
    { stateCode: 'TN', countyName: 'Roane County' },
    { stateCode: 'TN', countyName: 'Robertson County' },
    { stateCode: 'TN', countyName: 'Rutherford County' },
    { stateCode: 'TN', countyName: 'Scott County' },
    { stateCode: 'TN', countyName: 'Sequatchie County' },
    { stateCode: 'TN', countyName: 'Sevier County' },
    { stateCode: 'TN', countyName: 'Shelby County' },
    { stateCode: 'TN', countyName: 'Smith County' },
    { stateCode: 'TN', countyName: 'Stewart County' },
    { stateCode: 'TN', countyName: 'Sullivan County' },
    { stateCode: 'TN', countyName: 'Sumner County' },
    { stateCode: 'TN', countyName: 'Tipton County' },
    { stateCode: 'TN', countyName: 'Trousdale County' },
    { stateCode: 'TN', countyName: 'Unicoi County' },
    { stateCode: 'TN', countyName: 'Union County' },
    { stateCode: 'TN', countyName: 'Van Buren County' },
    { stateCode: 'TN', countyName: 'Warren County' },
    { stateCode: 'TN', countyName: 'Washington County' },
    { stateCode: 'TN', countyName: 'Wayne County' },
    { stateCode: 'TN', countyName: 'Weakley County' },
    { stateCode: 'TN', countyName: 'White County' },
    { stateCode: 'TN', countyName: 'Williamson County' },
    { stateCode: 'TN', countyName: 'Wilson County' },
    { stateCode: 'TX', countyName: 'Anderson County' },
    { stateCode: 'TX', countyName: 'Andrews County' },
    { stateCode: 'TX', countyName: 'Angelina County' },
    { stateCode: 'TX', countyName: 'Aransas County' },
    { stateCode: 'TX', countyName: 'Archer County' },
    { stateCode: 'TX', countyName: 'Armstrong County' },
    { stateCode: 'TX', countyName: 'Atascosa County' },
    { stateCode: 'TX', countyName: 'Austin County' },
    { stateCode: 'TX', countyName: 'Bailey County' },
    { stateCode: 'TX', countyName: 'Bandera County' },
    { stateCode: 'TX', countyName: 'Bastrop County' },
    { stateCode: 'TX', countyName: 'Baylor County' },
    { stateCode: 'TX', countyName: 'Bee County' },
    { stateCode: 'TX', countyName: 'Bell County' },
    { stateCode: 'TX', countyName: 'Bexar County' },
    { stateCode: 'TX', countyName: 'Blanco County' },
    { stateCode: 'TX', countyName: 'Borden County' },
    { stateCode: 'TX', countyName: 'Bosque County' },
    { stateCode: 'TX', countyName: 'Bowie County' },
    { stateCode: 'TX', countyName: 'Brazoria County' },
    { stateCode: 'TX', countyName: 'Brazos County' },
    { stateCode: 'TX', countyName: 'Brewster County' },
    { stateCode: 'TX', countyName: 'Briscoe County' },
    { stateCode: 'TX', countyName: 'Brooks County' },
    { stateCode: 'TX', countyName: 'Brown County' },
    { stateCode: 'TX', countyName: 'Burleson County' },
    { stateCode: 'TX', countyName: 'Burnet County' },
    { stateCode: 'TX', countyName: 'Caldwell County' },
    { stateCode: 'TX', countyName: 'Calhoun County' },
    { stateCode: 'TX', countyName: 'Callahan County' },
    { stateCode: 'TX', countyName: 'Cameron County' },
    { stateCode: 'TX', countyName: 'Camp County' },
    { stateCode: 'TX', countyName: 'Carson County' },
    { stateCode: 'TX', countyName: 'Cass County' },
    { stateCode: 'TX', countyName: 'Castro County' },
    { stateCode: 'TX', countyName: 'Chambers County' },
    { stateCode: 'TX', countyName: 'Cherokee County' },
    { stateCode: 'TX', countyName: 'Childress County' },
    { stateCode: 'TX', countyName: 'Clay County' },
    { stateCode: 'TX', countyName: 'Cochran County' },
    { stateCode: 'TX', countyName: 'Coke County' },
    { stateCode: 'TX', countyName: 'Coleman County' },
    { stateCode: 'TX', countyName: 'Collin County' },
    { stateCode: 'TX', countyName: 'Collingsworth County' },
    { stateCode: 'TX', countyName: 'Colorado County' },
    { stateCode: 'TX', countyName: 'Comal County' },
    { stateCode: 'TX', countyName: 'Comanche County' },
    { stateCode: 'TX', countyName: 'Concho County' },
    { stateCode: 'TX', countyName: 'Cooke County' },
    { stateCode: 'TX', countyName: 'Coryell County' },
    { stateCode: 'TX', countyName: 'Cottle County' },
    { stateCode: 'TX', countyName: 'Crane County' },
    { stateCode: 'TX', countyName: 'Crockett County' },
    { stateCode: 'TX', countyName: 'Crosby County' },
    { stateCode: 'TX', countyName: 'Culberson County' },
    { stateCode: 'TX', countyName: 'Dallam County' },
    { stateCode: 'TX', countyName: 'Dallas County' },
    { stateCode: 'TX', countyName: 'Dawson County' },
    { stateCode: 'TX', countyName: 'Deaf Smith County' },
    { stateCode: 'TX', countyName: 'Delta County' },
    { stateCode: 'TX', countyName: 'Denton County' },
    { stateCode: 'TX', countyName: 'DeWitt County' },
    { stateCode: 'TX', countyName: 'Dickens County' },
    { stateCode: 'TX', countyName: 'Dimmit County' },
    { stateCode: 'TX', countyName: 'Donley County' },
    { stateCode: 'TX', countyName: 'Duval County' },
    { stateCode: 'TX', countyName: 'Eastland County' },
    { stateCode: 'TX', countyName: 'Ector County' },
    { stateCode: 'TX', countyName: 'Edwards County' },
    { stateCode: 'TX', countyName: 'Ellis County' },
    { stateCode: 'TX', countyName: 'El Paso County' },
    { stateCode: 'TX', countyName: 'Erath County' },
    { stateCode: 'TX', countyName: 'Falls County' },
    { stateCode: 'TX', countyName: 'Fannin County' },
    { stateCode: 'TX', countyName: 'Fayette County' },
    { stateCode: 'TX', countyName: 'Fisher County' },
    { stateCode: 'TX', countyName: 'Floyd County' },
    { stateCode: 'TX', countyName: 'Foard County' },
    { stateCode: 'TX', countyName: 'Fort Bend County' },
    { stateCode: 'TX', countyName: 'Franklin County' },
    { stateCode: 'TX', countyName: 'Freestone County' },
    { stateCode: 'TX', countyName: 'Frio County' },
    { stateCode: 'TX', countyName: 'Gaines County' },
    { stateCode: 'TX', countyName: 'Galveston County' },
    { stateCode: 'TX', countyName: 'Garza County' },
    { stateCode: 'TX', countyName: 'Gillespie County' },
    { stateCode: 'TX', countyName: 'Glasscock County' },
    { stateCode: 'TX', countyName: 'Goliad County' },
    { stateCode: 'TX', countyName: 'Gonzales County' },
    { stateCode: 'TX', countyName: 'Gray County' },
    { stateCode: 'TX', countyName: 'Grayson County' },
    { stateCode: 'TX', countyName: 'Gregg County' },
    { stateCode: 'TX', countyName: 'Grimes County' },
    { stateCode: 'TX', countyName: 'Guadalupe County' },
    { stateCode: 'TX', countyName: 'Hale County' },
    { stateCode: 'TX', countyName: 'Hall County' },
    { stateCode: 'TX', countyName: 'Hamilton County' },
    { stateCode: 'TX', countyName: 'Hansford County' },
    { stateCode: 'TX', countyName: 'Hardeman County' },
    { stateCode: 'TX', countyName: 'Hardin County' },
    { stateCode: 'TX', countyName: 'Harris County' },
    { stateCode: 'TX', countyName: 'Harrison County' },
    { stateCode: 'TX', countyName: 'Hartley County' },
    { stateCode: 'TX', countyName: 'Haskell County' },
    { stateCode: 'TX', countyName: 'Hays County' },
    { stateCode: 'TX', countyName: 'Hemphill County' },
    { stateCode: 'TX', countyName: 'Henderson County' },
    { stateCode: 'TX', countyName: 'Hidalgo County' },
    { stateCode: 'TX', countyName: 'Hill County' },
    { stateCode: 'TX', countyName: 'Hockley County' },
    { stateCode: 'TX', countyName: 'Hood County' },
    { stateCode: 'TX', countyName: 'Hopkins County' },
    { stateCode: 'TX', countyName: 'Houston County' },
    { stateCode: 'TX', countyName: 'Howard County' },
    { stateCode: 'TX', countyName: 'Hudspeth County' },
    { stateCode: 'TX', countyName: 'Hunt County' },
    { stateCode: 'TX', countyName: 'Hutchinson County' },
    { stateCode: 'TX', countyName: 'Irion County' },
    { stateCode: 'TX', countyName: 'Jack County' },
    { stateCode: 'TX', countyName: 'Jackson County' },
    { stateCode: 'TX', countyName: 'Jasper County' },
    { stateCode: 'TX', countyName: 'Jeff Davis County' },
    { stateCode: 'TX', countyName: 'Jefferson County' },
    { stateCode: 'TX', countyName: 'Jim Hogg County' },
    { stateCode: 'TX', countyName: 'Jim Wells County' },
    { stateCode: 'TX', countyName: 'Johnson County' },
    { stateCode: 'TX', countyName: 'Jones County' },
    { stateCode: 'TX', countyName: 'Karnes County' },
    { stateCode: 'TX', countyName: 'Kaufman County' },
    { stateCode: 'TX', countyName: 'Kendall County' },
    { stateCode: 'TX', countyName: 'Kenedy County' },
    { stateCode: 'TX', countyName: 'Kent County' },
    { stateCode: 'TX', countyName: 'Kerr County' },
    { stateCode: 'TX', countyName: 'Kimble County' },
    { stateCode: 'TX', countyName: 'King County' },
    { stateCode: 'TX', countyName: 'Kinney County' },
    { stateCode: 'TX', countyName: 'Kleberg County' },
    { stateCode: 'TX', countyName: 'Knox County' },
    { stateCode: 'TX', countyName: 'Lamar County' },
    { stateCode: 'TX', countyName: 'Lamb County' },
    { stateCode: 'TX', countyName: 'Lampasas County' },
    { stateCode: 'TX', countyName: 'La Salle County' },
    { stateCode: 'TX', countyName: 'Lavaca County' },
    { stateCode: 'TX', countyName: 'Lee County' },
    { stateCode: 'TX', countyName: 'Leon County' },
    { stateCode: 'TX', countyName: 'Liberty County' },
    { stateCode: 'TX', countyName: 'Limestone County' },
    { stateCode: 'TX', countyName: 'Lipscomb County' },
    { stateCode: 'TX', countyName: 'Live Oak County' },
    { stateCode: 'TX', countyName: 'Llano County' },
    { stateCode: 'TX', countyName: 'Loving County' },
    { stateCode: 'TX', countyName: 'Lubbock County' },
    { stateCode: 'TX', countyName: 'Lynn County' },
    { stateCode: 'TX', countyName: 'McCulloch County' },
    { stateCode: 'TX', countyName: 'McLennan County' },
    { stateCode: 'TX', countyName: 'McMullen County' },
    { stateCode: 'TX', countyName: 'Madison County' },
    { stateCode: 'TX', countyName: 'Marion County' },
    { stateCode: 'TX', countyName: 'Martin County' },
    { stateCode: 'TX', countyName: 'Mason County' },
    { stateCode: 'TX', countyName: 'Matagorda County' },
    { stateCode: 'TX', countyName: 'Maverick County' },
    { stateCode: 'TX', countyName: 'Medina County' },
    { stateCode: 'TX', countyName: 'Menard County' },
    { stateCode: 'TX', countyName: 'Midland County' },
    { stateCode: 'TX', countyName: 'Milam County' },
    { stateCode: 'TX', countyName: 'Mills County' },
    { stateCode: 'TX', countyName: 'Mitchell County' },
    { stateCode: 'TX', countyName: 'Montague County' },
    { stateCode: 'TX', countyName: 'Montgomery County' },
    { stateCode: 'TX', countyName: 'Moore County' },
    { stateCode: 'TX', countyName: 'Morris County' },
    { stateCode: 'TX', countyName: 'Motley County' },
    { stateCode: 'TX', countyName: 'Nacogdoches County' },
    { stateCode: 'TX', countyName: 'Navarro County' },
    { stateCode: 'TX', countyName: 'Newton County' },
    { stateCode: 'TX', countyName: 'Nolan County' },
    { stateCode: 'TX', countyName: 'Nueces County' },
    { stateCode: 'TX', countyName: 'Ochiltree County' },
    { stateCode: 'TX', countyName: 'Oldham County' },
    { stateCode: 'TX', countyName: 'Orange County' },
    { stateCode: 'TX', countyName: 'Palo Pinto County' },
    { stateCode: 'TX', countyName: 'Panola County' },
    { stateCode: 'TX', countyName: 'Parker County' },
    { stateCode: 'TX', countyName: 'Parmer County' },
    { stateCode: 'TX', countyName: 'Pecos County' },
    { stateCode: 'TX', countyName: 'Polk County' },
    { stateCode: 'TX', countyName: 'Potter County' },
    { stateCode: 'TX', countyName: 'Presidio County' },
    { stateCode: 'TX', countyName: 'Rains County' },
    { stateCode: 'TX', countyName: 'Randall County' },
    { stateCode: 'TX', countyName: 'Reagan County' },
    { stateCode: 'TX', countyName: 'Real County' },
    { stateCode: 'TX', countyName: 'Red River County' },
    { stateCode: 'TX', countyName: 'Reeves County' },
    { stateCode: 'TX', countyName: 'Refugio County' },
    { stateCode: 'TX', countyName: 'Roberts County' },
    { stateCode: 'TX', countyName: 'Robertson County' },
    { stateCode: 'TX', countyName: 'Rockwall County' },
    { stateCode: 'TX', countyName: 'Runnels County' },
    { stateCode: 'TX', countyName: 'Rusk County' },
    { stateCode: 'TX', countyName: 'Sabine County' },
    { stateCode: 'TX', countyName: 'San Augustine County' },
    { stateCode: 'TX', countyName: 'San Jacinto County' },
    { stateCode: 'TX', countyName: 'San Patricio County' },
    { stateCode: 'TX', countyName: 'San Saba County' },
    { stateCode: 'TX', countyName: 'Schleicher County' },
    { stateCode: 'TX', countyName: 'Scurry County' },
    { stateCode: 'TX', countyName: 'Shackelford County' },
    { stateCode: 'TX', countyName: 'Shelby County' },
    { stateCode: 'TX', countyName: 'Sherman County' },
    { stateCode: 'TX', countyName: 'Smith County' },
    { stateCode: 'TX', countyName: 'Somervell County' },
    { stateCode: 'TX', countyName: 'Starr County' },
    { stateCode: 'TX', countyName: 'Stephens County' },
    { stateCode: 'TX', countyName: 'Sterling County' },
    { stateCode: 'TX', countyName: 'Stonewall County' },
    { stateCode: 'TX', countyName: 'Sutton County' },
    { stateCode: 'TX', countyName: 'Swisher County' },
    { stateCode: 'TX', countyName: 'Tarrant County' },
    { stateCode: 'TX', countyName: 'Taylor County' },
    { stateCode: 'TX', countyName: 'Terrell County' },
    { stateCode: 'TX', countyName: 'Terry County' },
    { stateCode: 'TX', countyName: 'Throckmorton County' },
    { stateCode: 'TX', countyName: 'Titus County' },
    { stateCode: 'TX', countyName: 'Tom Green County' },
    { stateCode: 'TX', countyName: 'Travis County' },
    { stateCode: 'TX', countyName: 'Trinity County' },
    { stateCode: 'TX', countyName: 'Tyler County' },
    { stateCode: 'TX', countyName: 'Upshur County' },
    { stateCode: 'TX', countyName: 'Upton County' },
    { stateCode: 'TX', countyName: 'Uvalde County' },
    { stateCode: 'TX', countyName: 'Val Verde County' },
    { stateCode: 'TX', countyName: 'Van Zandt County' },
    { stateCode: 'TX', countyName: 'Victoria County' },
    { stateCode: 'TX', countyName: 'Walker County' },
    { stateCode: 'TX', countyName: 'Waller County' },
    { stateCode: 'TX', countyName: 'Ward County' },
    { stateCode: 'TX', countyName: 'Washington County' },
    { stateCode: 'TX', countyName: 'Webb County' },
    { stateCode: 'TX', countyName: 'Wharton County' },
    { stateCode: 'TX', countyName: 'Wheeler County' },
    { stateCode: 'TX', countyName: 'Wichita County' },
    { stateCode: 'TX', countyName: 'Wilbarger County' },
    { stateCode: 'TX', countyName: 'Willacy County' },
    { stateCode: 'TX', countyName: 'Williamson County' },
    { stateCode: 'TX', countyName: 'Wilson County' },
    { stateCode: 'TX', countyName: 'Winkler County' },
    { stateCode: 'TX', countyName: 'Wise County' },
    { stateCode: 'TX', countyName: 'Wood County' },
    { stateCode: 'TX', countyName: 'Yoakum County' },
    { stateCode: 'TX', countyName: 'Young County' },
    { stateCode: 'TX', countyName: 'Zapata County' },
    { stateCode: 'TX', countyName: 'Zavala County' },
    { stateCode: 'UT', countyName: 'Beaver County' },
    { stateCode: 'UT', countyName: 'Box Elder County' },
    { stateCode: 'UT', countyName: 'Cache County' },
    { stateCode: 'UT', countyName: 'Carbon County' },
    { stateCode: 'UT', countyName: 'Daggett County' },
    { stateCode: 'UT', countyName: 'Davis County' },
    { stateCode: 'UT', countyName: 'Duchesne County' },
    { stateCode: 'UT', countyName: 'Emery County' },
    { stateCode: 'UT', countyName: 'Garfield County' },
    { stateCode: 'UT', countyName: 'Grand County' },
    { stateCode: 'UT', countyName: 'Iron County' },
    { stateCode: 'UT', countyName: 'Juab County' },
    { stateCode: 'UT', countyName: 'Kane County' },
    { stateCode: 'UT', countyName: 'Millard County' },
    { stateCode: 'UT', countyName: 'Morgan County' },
    { stateCode: 'UT', countyName: 'Piute County' },
    { stateCode: 'UT', countyName: 'Rich County' },
    { stateCode: 'UT', countyName: 'Salt Lake County' },
    { stateCode: 'UT', countyName: 'San Juan County' },
    { stateCode: 'UT', countyName: 'Sanpete County' },
    { stateCode: 'UT', countyName: 'Sevier County' },
    { stateCode: 'UT', countyName: 'Summit County' },
    { stateCode: 'UT', countyName: 'Tooele County' },
    { stateCode: 'UT', countyName: 'Uintah County' },
    { stateCode: 'UT', countyName: 'Utah County' },
    { stateCode: 'UT', countyName: 'Wasatch County' },
    { stateCode: 'UT', countyName: 'Washington County' },
    { stateCode: 'UT', countyName: 'Wayne County' },
    { stateCode: 'UT', countyName: 'Weber County' },
    { stateCode: 'VT', countyName: 'Addison County' },
    { stateCode: 'VT', countyName: 'Bennington County' },
    { stateCode: 'VT', countyName: 'Caledonia County' },
    { stateCode: 'VT', countyName: 'Chittenden County' },
    { stateCode: 'VT', countyName: 'Essex County' },
    { stateCode: 'VT', countyName: 'Franklin County' },
    { stateCode: 'VT', countyName: 'Grand Isle County' },
    { stateCode: 'VT', countyName: 'Lamoille County' },
    { stateCode: 'VT', countyName: 'Orange County' },
    { stateCode: 'VT', countyName: 'Orleans County' },
    { stateCode: 'VT', countyName: 'Rutland County' },
    { stateCode: 'VT', countyName: 'Washington County' },
    { stateCode: 'VT', countyName: 'Windham County' },
    { stateCode: 'VT', countyName: 'Windsor County' },
    { stateCode: 'VA', countyName: 'Accomack County' },
    { stateCode: 'VA', countyName: 'Albemarle County' },
    { stateCode: 'VA', countyName: 'Alleghany County' },
    { stateCode: 'VA', countyName: 'Amelia County' },
    { stateCode: 'VA', countyName: 'Amherst County' },
    { stateCode: 'VA', countyName: 'Appomattox County' },
    { stateCode: 'VA', countyName: 'Arlington County' },
    { stateCode: 'VA', countyName: 'Augusta County' },
    { stateCode: 'VA', countyName: 'Bath County' },
    { stateCode: 'VA', countyName: 'Bedford County' },
    { stateCode: 'VA', countyName: 'Bland County' },
    { stateCode: 'VA', countyName: 'Botetourt County' },
    { stateCode: 'VA', countyName: 'Brunswick County' },
    { stateCode: 'VA', countyName: 'Buchanan County' },
    { stateCode: 'VA', countyName: 'Buckingham County' },
    { stateCode: 'VA', countyName: 'Campbell County' },
    { stateCode: 'VA', countyName: 'Caroline County' },
    { stateCode: 'VA', countyName: 'Carroll County' },
    { stateCode: 'VA', countyName: 'Charles City County' },
    { stateCode: 'VA', countyName: 'Charlotte County' },
    { stateCode: 'VA', countyName: 'Chesterfield County' },
    { stateCode: 'VA', countyName: 'Clarke County' },
    { stateCode: 'VA', countyName: 'Craig County' },
    { stateCode: 'VA', countyName: 'Culpeper County' },
    { stateCode: 'VA', countyName: 'Cumberland County' },
    { stateCode: 'VA', countyName: 'Dickenson County' },
    { stateCode: 'VA', countyName: 'Dinwiddie County' },
    { stateCode: 'VA', countyName: 'Essex County' },
    { stateCode: 'VA', countyName: 'Fairfax County' },
    { stateCode: 'VA', countyName: 'Fauquier County' },
    { stateCode: 'VA', countyName: 'Floyd County' },
    { stateCode: 'VA', countyName: 'Fluvanna County' },
    { stateCode: 'VA', countyName: 'Franklin County' },
    { stateCode: 'VA', countyName: 'Frederick County' },
    { stateCode: 'VA', countyName: 'Giles County' },
    { stateCode: 'VA', countyName: 'Gloucester County' },
    { stateCode: 'VA', countyName: 'Goochland County' },
    { stateCode: 'VA', countyName: 'Grayson County' },
    { stateCode: 'VA', countyName: 'Greene County' },
    { stateCode: 'VA', countyName: 'Greensville County' },
    { stateCode: 'VA', countyName: 'Halifax County' },
    { stateCode: 'VA', countyName: 'Hanover County' },
    { stateCode: 'VA', countyName: 'Henrico County' },
    { stateCode: 'VA', countyName: 'Henry County' },
    { stateCode: 'VA', countyName: 'Highland County' },
    { stateCode: 'VA', countyName: 'Isle of Wight County' },
    { stateCode: 'VA', countyName: 'James City County' },
    { stateCode: 'VA', countyName: 'King and Queen County' },
    { stateCode: 'VA', countyName: 'King George County' },
    { stateCode: 'VA', countyName: 'King William County' },
    { stateCode: 'VA', countyName: 'Lancaster County' },
    { stateCode: 'VA', countyName: 'Lee County' },
    { stateCode: 'VA', countyName: 'Loudoun County' },
    { stateCode: 'VA', countyName: 'Louisa County' },
    { stateCode: 'VA', countyName: 'Lunenburg County' },
    { stateCode: 'VA', countyName: 'Madison County' },
    { stateCode: 'VA', countyName: 'Mathews County' },
    { stateCode: 'VA', countyName: 'Mecklenburg County' },
    { stateCode: 'VA', countyName: 'Middlesex County' },
    { stateCode: 'VA', countyName: 'Montgomery County' },
    { stateCode: 'VA', countyName: 'Nelson County' },
    { stateCode: 'VA', countyName: 'New Kent County' },
    { stateCode: 'VA', countyName: 'Northampton County' },
    { stateCode: 'VA', countyName: 'Northumberland County' },
    { stateCode: 'VA', countyName: 'Nottoway County' },
    { stateCode: 'VA', countyName: 'Orange County' },
    { stateCode: 'VA', countyName: 'Page County' },
    { stateCode: 'VA', countyName: 'Patrick County' },
    { stateCode: 'VA', countyName: 'Pittsylvania County' },
    { stateCode: 'VA', countyName: 'Powhatan County' },
    { stateCode: 'VA', countyName: 'Prince Edward County' },
    { stateCode: 'VA', countyName: 'Prince George County' },
    { stateCode: 'VA', countyName: 'Prince William County' },
    { stateCode: 'VA', countyName: 'Pulaski County' },
    { stateCode: 'VA', countyName: 'Rappahannock County' },
    { stateCode: 'VA', countyName: 'Richmond County' },
    { stateCode: 'VA', countyName: 'Roanoke County' },
    { stateCode: 'VA', countyName: 'Rockbridge County' },
    { stateCode: 'VA', countyName: 'Rockingham County' },
    { stateCode: 'VA', countyName: 'Russell County' },
    { stateCode: 'VA', countyName: 'Scott County' },
    { stateCode: 'VA', countyName: 'Shenandoah County' },
    { stateCode: 'VA', countyName: 'Smyth County' },
    { stateCode: 'VA', countyName: 'Southampton County' },
    { stateCode: 'VA', countyName: 'Spotsylvania County' },
    { stateCode: 'VA', countyName: 'Stafford County' },
    { stateCode: 'VA', countyName: 'Surry County' },
    { stateCode: 'VA', countyName: 'Sussex County' },
    { stateCode: 'VA', countyName: 'Tazewell County' },
    { stateCode: 'VA', countyName: 'Warren County' },
    { stateCode: 'VA', countyName: 'Washington County' },
    { stateCode: 'VA', countyName: 'Westmoreland County' },
    { stateCode: 'VA', countyName: 'Wise County' },
    { stateCode: 'VA', countyName: 'Wythe County' },
    { stateCode: 'VA', countyName: 'York County' },
    { stateCode: 'VA', countyName: 'Alexandria city' },
    { stateCode: 'VA', countyName: 'Bedford city' },
    { stateCode: 'VA', countyName: 'Bristol city' },
    { stateCode: 'VA', countyName: 'Buena Vista city' },
    { stateCode: 'VA', countyName: 'Charlottesville city' },
    { stateCode: 'VA', countyName: 'Chesapeake city' },
    { stateCode: 'VA', countyName: 'Colonial Heights city' },
    { stateCode: 'VA', countyName: 'Covington city' },
    { stateCode: 'VA', countyName: 'Danville city' },
    { stateCode: 'VA', countyName: 'Emporia city' },
    { stateCode: 'VA', countyName: 'Fairfax city' },
    { stateCode: 'VA', countyName: 'Falls Church city' },
    { stateCode: 'VA', countyName: 'Franklin city' },
    { stateCode: 'VA', countyName: 'Fredericksburg city' },
    { stateCode: 'VA', countyName: 'Galax city' },
    { stateCode: 'VA', countyName: 'Hampton city' },
    { stateCode: 'VA', countyName: 'Harrisonburg city' },
    { stateCode: 'VA', countyName: 'Hopewell city' },
    { stateCode: 'VA', countyName: 'Lexington city' },
    { stateCode: 'VA', countyName: 'Lynchburg city' },
    { stateCode: 'VA', countyName: 'Manassas city' },
    { stateCode: 'VA', countyName: 'Manassas Park city' },
    { stateCode: 'VA', countyName: 'Martinsville city' },
    { stateCode: 'VA', countyName: 'Newport News city' },
    { stateCode: 'VA', countyName: 'Norfolk city' },
    { stateCode: 'VA', countyName: 'Norton city' },
    { stateCode: 'VA', countyName: 'Petersburg city' },
    { stateCode: 'VA', countyName: 'Poquoson city' },
    { stateCode: 'VA', countyName: 'Portsmouth city' },
    { stateCode: 'VA', countyName: 'Radford city' },
    { stateCode: 'VA', countyName: 'Richmond city' },
    { stateCode: 'VA', countyName: 'Roanoke city' },
    { stateCode: 'VA', countyName: 'Salem city' },
    { stateCode: 'VA', countyName: 'Staunton city' },
    { stateCode: 'VA', countyName: 'Suffolk city' },
    { stateCode: 'VA', countyName: 'Virginia Beach city' },
    { stateCode: 'VA', countyName: 'Waynesboro city' },
    { stateCode: 'VA', countyName: 'Williamsburg city' },
    { stateCode: 'VA', countyName: 'Winchester city' },
    { stateCode: 'WA', countyName: 'Adams County' },
    { stateCode: 'WA', countyName: 'Asotin County' },
    { stateCode: 'WA', countyName: 'Benton County' },
    { stateCode: 'WA', countyName: 'Chelan County' },
    { stateCode: 'WA', countyName: 'Clallam County' },
    { stateCode: 'WA', countyName: 'Clark County' },
    { stateCode: 'WA', countyName: 'Columbia County' },
    { stateCode: 'WA', countyName: 'Cowlitz County' },
    { stateCode: 'WA', countyName: 'Douglas County' },
    { stateCode: 'WA', countyName: 'Ferry County' },
    { stateCode: 'WA', countyName: 'Franklin County' },
    { stateCode: 'WA', countyName: 'Garfield County' },
    { stateCode: 'WA', countyName: 'Grant County' },
    { stateCode: 'WA', countyName: 'Grays Harbor County' },
    { stateCode: 'WA', countyName: 'Island County' },
    { stateCode: 'WA', countyName: 'Jefferson County' },
    { stateCode: 'WA', countyName: 'King County' },
    { stateCode: 'WA', countyName: 'Kitsap County' },
    { stateCode: 'WA', countyName: 'Kittitas County' },
    { stateCode: 'WA', countyName: 'Klickitat County' },
    { stateCode: 'WA', countyName: 'Lewis County' },
    { stateCode: 'WA', countyName: 'Lincoln County' },
    { stateCode: 'WA', countyName: 'Mason County' },
    { stateCode: 'WA', countyName: 'Okanogan County' },
    { stateCode: 'WA', countyName: 'Pacific County' },
    { stateCode: 'WA', countyName: 'Pend Oreille County' },
    { stateCode: 'WA', countyName: 'Pierce County' },
    { stateCode: 'WA', countyName: 'San Juan County' },
    { stateCode: 'WA', countyName: 'Skagit County' },
    { stateCode: 'WA', countyName: 'Skamania County' },
    { stateCode: 'WA', countyName: 'Snohomish County' },
    { stateCode: 'WA', countyName: 'Spokane County' },
    { stateCode: 'WA', countyName: 'Stevens County' },
    { stateCode: 'WA', countyName: 'Thurston County' },
    { stateCode: 'WA', countyName: 'Wahkiakum County' },
    { stateCode: 'WA', countyName: 'Walla Walla County' },
    { stateCode: 'WA', countyName: 'Whatcom County' },
    { stateCode: 'WA', countyName: 'Whitman County' },
    { stateCode: 'WA', countyName: 'Yakima County' },
    { stateCode: 'WV', countyName: 'Barbour County' },
    { stateCode: 'WV', countyName: 'Berkeley County' },
    { stateCode: 'WV', countyName: 'Boone County' },
    { stateCode: 'WV', countyName: 'Braxton County' },
    { stateCode: 'WV', countyName: 'Brooke County' },
    { stateCode: 'WV', countyName: 'Cabell County' },
    { stateCode: 'WV', countyName: 'Calhoun County' },
    { stateCode: 'WV', countyName: 'Clay County' },
    { stateCode: 'WV', countyName: 'Doddridge County' },
    { stateCode: 'WV', countyName: 'Fayette County' },
    { stateCode: 'WV', countyName: 'Gilmer County' },
    { stateCode: 'WV', countyName: 'Grant County' },
    { stateCode: 'WV', countyName: 'Greenbrier County' },
    { stateCode: 'WV', countyName: 'Hampshire County' },
    { stateCode: 'WV', countyName: 'Hancock County' },
    { stateCode: 'WV', countyName: 'Hardy County' },
    { stateCode: 'WV', countyName: 'Harrison County' },
    { stateCode: 'WV', countyName: 'Jackson County' },
    { stateCode: 'WV', countyName: 'Jefferson County' },
    { stateCode: 'WV', countyName: 'Kanawha County' },
    { stateCode: 'WV', countyName: 'Lewis County' },
    { stateCode: 'WV', countyName: 'Lincoln County' },
    { stateCode: 'WV', countyName: 'Logan County' },
    { stateCode: 'WV', countyName: 'McDowell County' },
    { stateCode: 'WV', countyName: 'Marion County' },
    { stateCode: 'WV', countyName: 'Marshall County' },
    { stateCode: 'WV', countyName: 'Mason County' },
    { stateCode: 'WV', countyName: 'Mercer County' },
    { stateCode: 'WV', countyName: 'Mineral County' },
    { stateCode: 'WV', countyName: 'Mingo County' },
    { stateCode: 'WV', countyName: 'Monongalia County' },
    { stateCode: 'WV', countyName: 'Monroe County' },
    { stateCode: 'WV', countyName: 'Morgan County' },
    { stateCode: 'WV', countyName: 'Nicholas County' },
    { stateCode: 'WV', countyName: 'Ohio County' },
    { stateCode: 'WV', countyName: 'Pendleton County' },
    { stateCode: 'WV', countyName: 'Pleasants County' },
    { stateCode: 'WV', countyName: 'Pocahontas County' },
    { stateCode: 'WV', countyName: 'Preston County' },
    { stateCode: 'WV', countyName: 'Putnam County' },
    { stateCode: 'WV', countyName: 'Raleigh County' },
    { stateCode: 'WV', countyName: 'Randolph County' },
    { stateCode: 'WV', countyName: 'Ritchie County' },
    { stateCode: 'WV', countyName: 'Roane County' },
    { stateCode: 'WV', countyName: 'Summers County' },
    { stateCode: 'WV', countyName: 'Taylor County' },
    { stateCode: 'WV', countyName: 'Tucker County' },
    { stateCode: 'WV', countyName: 'Tyler County' },
    { stateCode: 'WV', countyName: 'Upshur County' },
    { stateCode: 'WV', countyName: 'Wayne County' },
    { stateCode: 'WV', countyName: 'Webster County' },
    { stateCode: 'WV', countyName: 'Wetzel County' },
    { stateCode: 'WV', countyName: 'Wirt County' },
    { stateCode: 'WV', countyName: 'Wood County' },
    { stateCode: 'WV', countyName: 'Wyoming County' },
    { stateCode: 'WI', countyName: 'Adams County' },
    { stateCode: 'WI', countyName: 'Ashland County' },
    { stateCode: 'WI', countyName: 'Barron County' },
    { stateCode: 'WI', countyName: 'Bayfield County' },
    { stateCode: 'WI', countyName: 'Brown County' },
    { stateCode: 'WI', countyName: 'Buffalo County' },
    { stateCode: 'WI', countyName: 'Burnett County' },
    { stateCode: 'WI', countyName: 'Calumet County' },
    { stateCode: 'WI', countyName: 'Chippewa County' },
    { stateCode: 'WI', countyName: 'Clark County' },
    { stateCode: 'WI', countyName: 'Columbia County' },
    { stateCode: 'WI', countyName: 'Crawford County' },
    { stateCode: 'WI', countyName: 'Dane County' },
    { stateCode: 'WI', countyName: 'Dodge County' },
    { stateCode: 'WI', countyName: 'Door County' },
    { stateCode: 'WI', countyName: 'Douglas County' },
    { stateCode: 'WI', countyName: 'Dunn County' },
    { stateCode: 'WI', countyName: 'Eau Claire County' },
    { stateCode: 'WI', countyName: 'Florence County' },
    { stateCode: 'WI', countyName: 'Fond du Lac County' },
    { stateCode: 'WI', countyName: 'Forest County' },
    { stateCode: 'WI', countyName: 'Grant County' },
    { stateCode: 'WI', countyName: 'Green County' },
    { stateCode: 'WI', countyName: 'Green Lake County' },
    { stateCode: 'WI', countyName: 'Iowa County' },
    { stateCode: 'WI', countyName: 'Iron County' },
    { stateCode: 'WI', countyName: 'Jackson County' },
    { stateCode: 'WI', countyName: 'Jefferson County' },
    { stateCode: 'WI', countyName: 'Juneau County' },
    { stateCode: 'WI', countyName: 'Kenosha County' },
    { stateCode: 'WI', countyName: 'Kewaunee County' },
    { stateCode: 'WI', countyName: 'La Crosse County' },
    { stateCode: 'WI', countyName: 'Lafayette County' },
    { stateCode: 'WI', countyName: 'Langlade County' },
    { stateCode: 'WI', countyName: 'Lincoln County' },
    { stateCode: 'WI', countyName: 'Manitowoc County' },
    { stateCode: 'WI', countyName: 'Marathon County' },
    { stateCode: 'WI', countyName: 'Marinette County' },
    { stateCode: 'WI', countyName: 'Marquette County' },
    { stateCode: 'WI', countyName: 'Menominee County' },
    { stateCode: 'WI', countyName: 'Milwaukee County' },
    { stateCode: 'WI', countyName: 'Monroe County' },
    { stateCode: 'WI', countyName: 'Oconto County' },
    { stateCode: 'WI', countyName: 'Oneida County' },
    { stateCode: 'WI', countyName: 'Outagamie County' },
    { stateCode: 'WI', countyName: 'Ozaukee County' },
    { stateCode: 'WI', countyName: 'Pepin County' },
    { stateCode: 'WI', countyName: 'Pierce County' },
    { stateCode: 'WI', countyName: 'Polk County' },
    { stateCode: 'WI', countyName: 'Portage County' },
    { stateCode: 'WI', countyName: 'Price County' },
    { stateCode: 'WI', countyName: 'Racine County' },
    { stateCode: 'WI', countyName: 'Richland County' },
    { stateCode: 'WI', countyName: 'Rock County' },
    { stateCode: 'WI', countyName: 'Rusk County' },
    { stateCode: 'WI', countyName: 'St. Croix County' },
    { stateCode: 'WI', countyName: 'Sauk County' },
    { stateCode: 'WI', countyName: 'Sawyer County' },
    { stateCode: 'WI', countyName: 'Shawano County' },
    { stateCode: 'WI', countyName: 'Sheboygan County' },
    { stateCode: 'WI', countyName: 'Taylor County' },
    { stateCode: 'WI', countyName: 'Trempealeau County' },
    { stateCode: 'WI', countyName: 'Vernon County' },
    { stateCode: 'WI', countyName: 'Vilas County' },
    { stateCode: 'WI', countyName: 'Walworth County' },
    { stateCode: 'WI', countyName: 'Washburn County' },
    { stateCode: 'WI', countyName: 'Washington County' },
    { stateCode: 'WI', countyName: 'Waukesha County' },
    { stateCode: 'WI', countyName: 'Waupaca County' },
    { stateCode: 'WI', countyName: 'Waushara County' },
    { stateCode: 'WI', countyName: 'Winnebago County' },
    { stateCode: 'WI', countyName: 'Wood County' },
    { stateCode: 'WY', countyName: 'Albany County' },
    { stateCode: 'WY', countyName: 'Big Horn County' },
    { stateCode: 'WY', countyName: 'Campbell County' },
    { stateCode: 'WY', countyName: 'Carbon County' },
    { stateCode: 'WY', countyName: 'Converse County' },
    { stateCode: 'WY', countyName: 'Crook County' },
    { stateCode: 'WY', countyName: 'Fremont County' },
    { stateCode: 'WY', countyName: 'Goshen County' },
    { stateCode: 'WY', countyName: 'Hot Springs County' },
    { stateCode: 'WY', countyName: 'Johnson County' },
    { stateCode: 'WY', countyName: 'Laramie County' },
    { stateCode: 'WY', countyName: 'Lincoln County' },
    { stateCode: 'WY', countyName: 'Natrona County' },
    { stateCode: 'WY', countyName: 'Niobrara County' },
    { stateCode: 'WY', countyName: 'Park County' },
    { stateCode: 'WY', countyName: 'Platte County' },
    { stateCode: 'WY', countyName: 'Sheridan County' },
    { stateCode: 'WY', countyName: 'Sublette County' },
    { stateCode: 'WY', countyName: 'Sweetwater County' },
    { stateCode: 'WY', countyName: 'Teton County' },
    { stateCode: 'WY', countyName: 'Uinta County' },
    { stateCode: 'WY', countyName: 'Washakie County' },
    { stateCode: 'WY', countyName: 'Weston County' },
];
