<table class="print-table" *ngIf="isDataLoaded">
    <thead class="print-header">
        <!-- <th class="n-header">
            <app-header [isFirst]="false" [type]="'updatedPlanOfCareNote'"></app-header>
        </th> -->
        <tr class="print-first-header">
            <app-header [isFirst]="true" [type]="'updatedPlanOfCareNote'"></app-header>
        </tr>
    </thead>
    <tr style="page-break-before: avoid">
        <app-grid-layout [type]="'updatedPlanOfCareNote'" [key]="'medicalDiagnosis'"></app-grid-layout>
    </tr>
    <tr>
        <app-grid-layout [type]="'updatedPlanOfCareNote'" [key]="'treatmentDiagnosis'"></app-grid-layout>
    </tr>
    <tr>
        <app-grid-layout
            [gridType]="'NonHeaderGrid'"
            [type]="'updatedPlanOfCareNote'"
            [key]="'precautions'"
        ></app-grid-layout>
    </tr>
    <tr>
        <app-grid-layout
            [gridType]="'NonHeaderGrid'"
            [type]="'updatedPlanOfCareNote'"
            [key]="'contraindications'"
        ></app-grid-layout>
    </tr>
    <tr>
        <app-grid-layout
            [gridType]="'TreeGrid'"
            [type]="'updatedPlanOfCareNote'"
            [key]="'impairments'"
        ></app-grid-layout>
    </tr>
    <tr>
        <app-grid-layout
            [gridType]="'TreeGrid'"
            [type]="'updatedPlanOfCareNote'"
            [key]="'functionalDeficits'"
        ></app-grid-layout>
    </tr>
    <tr>
        <app-grid-layout
            [gridType]="'TreeGrid'"
            [type]="'updatedPlanOfCareNote'"
            [key]="'standardizedTests'"
        ></app-grid-layout>
    </tr>
    <tr>
        <app-paragraph [type]="'updatedPlanOfCareNote'" [key]="'reasonForUpdate'"></app-paragraph>
    </tr>
    <tr>
        <app-grid-layout [gridType]="'DynamicGrid'" [type]="'updatedPlanOfCareNote'" [key]="'goals'"></app-grid-layout>
    </tr>
    <tr>
        <app-grid-layout [type]="'updatedPlanOfCareNote'" [key]="'skilledServices'"></app-grid-layout>
    </tr>
    <tr>
        <app-grid-layout
            [gridType]="'DynamicGrid'"
            [type]="'updatedPlanOfCareNote'"
            [key]="'frequencyAndDuration'"
        ></app-grid-layout>
    </tr>
    <tr>
        <app-grid-layout [gridType]="'TreeGrid'" [type]="'updatedPlanOfCareNote'" [key]="'addendum'"></app-grid-layout>
    </tr>
    <tr class="print-footer" *ngIf="isDataLoaded">
        <app-signature [type]="'updatedPlanOfCareNote'"></app-signature>
    </tr>
</table>
