/* eslint-disable spellcheck/spell-checker */
import { Directive, HostListener, Input, ComponentFactoryResolver, ViewContainerRef, ComponentRef } from '@angular/core';
import { CustomTooltipComponent } from '../shared/custom-tooltip/custom-tooltip.component';

@Directive({
  selector: '[tooltipCustom]'
})
export class CustomTooltipDirective {
  @Input('text') text: string;
  @Input('warningText') warningText: string;
  @Input('position') position = 'left';

  private tooltipCustomRef: ComponentRef<CustomTooltipComponent> | null = null;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef
  ) {}

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.tooltipCustomRef) {
      const factory = this.componentFactoryResolver.resolveComponentFactory(CustomTooltipComponent);
      this.tooltipCustomRef = this.viewContainerRef.createComponent(factory);
      this.tooltipCustomRef.instance.text = this.text;
      this.tooltipCustomRef.instance.warningText = this.warningText;
      // const hostPosition = this.elementRef.nativeElement.getBoundingClientRect();
      // const scrollPosition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
      let positionStyle = {
        top:  '0px',
        left:  '',
        bottom: 'auto',
        right: '30px'
      };

      if(this.position?.toLowerCase() === 'right') {
        positionStyle = {
          top:  '0px',
          left:  '40px',
          bottom: 'auto',
          right: 'auto'
        };
  
      } else if(this.position?.toLowerCase() === 'bottom') {
        positionStyle = {
          top:  '40px',
          left:  '',
          bottom: 'auto',
          right: '10px'
        };
      } else if(this.position?.toLowerCase() === 'top') {
        positionStyle = {
          top:  'auto',
          left:  '',
          bottom: '40px',
          right: '10px'
        };
      }
      this.position
      this.tooltipCustomRef.instance.position = positionStyle;
      this.tooltipCustomRef.instance.visible = true;
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.tooltipCustomRef) {
      this.tooltipCustomRef.instance.visible = false;
      setTimeout(() => {
        if (this.tooltipCustomRef) {
          this.tooltipCustomRef.destroy();
          this.tooltipCustomRef = null;
        }
      }, 200);
    }
  }
}
