import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    OnChanges,
    ViewChild,
    ElementRef,
    OnDestroy,
} from '@angular/core';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import {
    HeaderActionItem,
    CardUIAction,
    CardPayload,
    InputConfiguration,
    InputBottomActionItems,
} from '@app/interfaces/ui';
import { isEmpty, isUndefined } from 'lodash';

@Component({
    selector: 'app-grid-card',
    templateUrl: './grid-card.component.html',
    styleUrls: ['./grid-card.component.scss'],
})
export class GridCardComponent implements OnInit, OnChanges, OnDestroy {
    @Input() heading: string;
    @Input() suggestionsList: string[];
    @Input() columnDefs: ColDef[] = [];
    @Input() rowData: any[];
    @Input() defaultAction: (payload: any) => void;
    @Input() numbered: boolean;
    @Input() actionSave: (payload: CardPayload) => void;
    @Input() controls: InputConfiguration[];
    @Input() maxGridHeight = '400px';
    @Input() checkFill = false;
    @Output() onChange: EventEmitter<any> = new EventEmitter();
    @Input() saveButton = 'save';
    canSave = false;
    newItem: any = {};
    @Input() view: string;
    @Input() dataForEdit: object;
    @Output() viewOut: EventEmitter<any> = new EventEmitter();
    @Output() dataForEditOut: EventEmitter<any> = new EventEmitter();
    noContentView: string;

    gridApi: GridReadyEvent;

    views: string[] = ['CONTENT', 'NO_CONTENT', 'ADD_MORE'];
    actions: Map<string, CardUIAction>;
    headerActionItems: HeaderActionItem[];
    inputBottomActionItems: InputBottomActionItems[];

    // eslint-disable-next-line spellcheck/spell-checker
    @Input() isReadonly: boolean;

    @ViewChild('dataGrid', { static: false }) dataGrid: ElementRef;

    ngOnChanges() {
        /**
         * To check view every time changes happen and make sure that
         * after changes all checks are met
         */
        if (this.view === 'CONTENT' && this.rowData.length === 0) {
            this.view = this.noContentView;
        }
        if (this.dataGrid && this.rowData.length < 5) {
            if(this.gridApi && this.gridApi.api && this.gridApi.api.getDisplayedRowCount() > 0) {
                this.gridApi.api.setDomLayout('autoHeight');
            }
            this.dataGrid.nativeElement.style.height = null;
        }
    }
    ngOnInit(): void {
        if (isUndefined(this.rowData)) this.rowData = [];
        this.view = 'CONTENT';
        this.noContentView = 'NO_CONTENT';
        // content
        this.actions = new Map<string, CardUIAction>([
            [
                'ACTION_ADD_MORE',
                (payload: CardPayload) => {
                    console.log(`Add more action invoked!`);
                    this.setView(payload.targetView);
                },
            ],
            [
                'ACTION_CANCEL_SAVE',
                (payload: CardPayload) => {
                    this.dataForEditOut.emit({});
                    this.setView(payload.targetView);
                },
            ],
            [
                'ACTION_SAVE',
                (payload: CardPayload) => {
                    this.pushNewItem();
                    this.setView(payload.targetView);
                },
            ],
            ['ACTION_DEFAULT', (payload: CardPayload) => this.defaultAction(payload)],
        ]);
        this.inputBottomActionItems = [
            {
                label: 'Cancel',
                icon: '',
                rightIcon: true,
                style: 'padding-top: 0.6em !important;padding-bottom: 0.6em !important;',
                class: 'btn btn-outline-secondary border-0 d-flex flex-row align-items-center justify-content-between p-2',
                innovaBtn: false,
                innovaBtnLayout: true,
                autoView: false,
                payload: {
                    views: ['ADD_MORE'],
                    targetView: 'CONTENT',
                },
                action: 'ACTION_CANCEL_SAVE',
            },
            {
                label: 'Save',
                // remove icon from button because it is just saving
                icon: '',
                rightIcon: true,
                class: 'btn-secondary d-flex flex-row align-items-center justify-content-between p-2',
                autoView: false,
                payload: {
                    views: ['ADD_MORE'],
                    targetView: 'CONTENT',
                },
                action: 'ACTION_SAVE',
            },
        ];
        this.headerActionItems = [
            {
                label: 'Add',
                icon: 'add',
                class: 'btn btn-secondary rounded-circle d-flex flex-column align-items-center justify-content-center p-2',
                innovaBtn: false,
                innovaBtnLayout: false,
                autoView: false,
                payload: {
                    views: ['CONTENT'],
                    targetView: 'ADD_MORE',
                },
                action: 'ACTION_ADD_MORE',
            },
            // {
            //   label: 'Cancel',
            //   icon: 'close',
            //   class: 'btn btn-secondary rounded-circle d-flex flex-column align-items-center justify-content-center p-2',
            //   autoView: false,
            //   payload: {
            //     views: ['ADD_MORE'],
            //     targetView: 'CONTENT',
            //   },
            //   action: 'ACTION_CANCEL_SAVE',
            // },
            // {
            //   label: 'Save',
            //   icon: 'save',
            //   class: 'btn btn-secondary rounded-circle d-flex flex-column align-items-center justify-content-center p-2',
            //   autoView: false,
            //   payload: {
            //     views: ['ADD_MORE'],
            //     targetView: 'CONTENT',
            //   },
            //   action: 'ACTION_SAVE',
            // },
        ];
    }
    pushNewItem() {
        if (!isEmpty(this.newItem)) {
            this.onChange.emit(this.newItem);
            this.newItem = {};
        }
    }
    setView(view: string) {
        this.viewOut.emit(view);
        this.view = view;
    }
    controlWidgetHandler(data: any) {
        this.newItem = data;
    }

    gridReady(event: GridReadyEvent) {
        this.gridApi = event;
        this.gridApi.api.setDomLayout('autoHeight');
    }
    setCanSave(value: boolean) {
        this.canSave = value;
    }
    btnClicked(item) {
        // Fix for a case where btn is being clicked despite being disabled
        if (
            item.action === 'ACTION_SAVE' &&
            this.newItem &&
            this.newItem.description &&
            this.newItem.description.includes('_')
        ) {
            return;
        }
        this.actions.get(item.action)(item.payload);
    }

    ngOnDestroy(): void {
        if (this.gridApi) {
            this.gridApi = null;
        }
    }
}
