/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import {
    ControlValueAccessor,
    FormBuilder,
    FormGroup,
    NgControl,
    Validators,
} from '@angular/forms';

export interface Options {
    text: string;
    isChecked: boolean;
    isDisabled: boolean;
}

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements ControlValueAccessor, OnChanges {
    @Input() options;
    @Input() label;
    @Input() labelClass = '';
    @Input() isProjected = false;
    @Input() eachItemClass = '';
    @Input() fontSize = '';
    @Input() selectWithCards;
    @Input() selectWithCardsOptions;
    @Input() currentOption;
    @Input() currentOptionValue;
    @Input() currentSelectCardValues;
    @Input() disciplineRow;
    @Input() disciplineColumn;

    @Output() optionChecked = new EventEmitter();
    @Output() selectWithCardChangedEmitter = new EventEmitter();

    // only needed when selectWithCards = true
    therapistForm: FormGroup = this.fb.group({});

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    propagateChange = (_: any) => {};
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onTouched = (_: any) => {};

    value: any;
    disabled: boolean;

    constructor(public readonly ngControl: NgControl, private fb: FormBuilder) {
        ngControl.valueAccessor = this;
    }

    ngOnChanges(): void {
        if (
            this.selectWithCards &&
            this.selectWithCardsOptions &&
            Object.keys(this.selectWithCardsOptions).length &&
            !Object.keys(this.therapistForm.value).length // initialize only first time and not again & again
        ) {
            // add form controls
            Object.keys(this.selectWithCardsOptions)
                // don't create formControl of current discipline because its not editable and being rendered separately
                .filter((key) => key !== this.currentOption)
                .forEach((key) => {
                    const newControl = this.fb.control(this.currentSelectCardValues[key], [Validators.required]);
                    this.therapistForm.addControl(key, newControl);
                });

            // initialize formControl values
            this.therapistForm.get(this.currentOption)?.patchValue(this.currentOptionValue);
        }
    }

    writeValue(obj: any): void {
        if (obj) {
            obj.forEach((v) => {
                const found = this.options.find((option) => option.text === v.text);
                if (found) found.isChecked = true;
            });
        }
        this.value = obj;
    }
    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onCheckboxChange(itemIndex: number, mode, change: any) {
        this.propagateChange(this.options.filter((v) => v.isChecked));
        this.optionChecked.emit(mode);

        // remove
        if (this.selectWithCards && this.selectWithCardsOptions && Object.keys(this.selectWithCardsOptions).length) {
            if (mode.isChecked) {
                this.therapistForm.addControl(mode.text, this.fb.control('', [Validators.required]));
            } else {
                this.therapistForm.removeControl(mode.text);
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    selectWithCardChanged(event) {
        this.selectWithCardChangedEmitter.emit(this.therapistForm.value);
    }
}
