import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SchedulerRightBarComponent } from '@app/modules/scheduling/scheduler/scheduler-right-bar/scheduler-right-bar.component';
import { ModalService, SchedulerService, SideNavService, ToasterService } from '@app/services';
import { GoalModalComponent } from '@app/shared/charts/modals/goal-modal/goal-modal.component';
@Component({
    selector: 'app-showcase',
    templateUrl: './showcase.component.html',
    styleUrls: ['./showcase.component.scss'],
})
export class ShowcaseComponent {
    form: FormGroup = new FormGroup({
        firstName: new FormControl('mmr', [Validators.minLength(5), Validators.required]),
        title: new FormControl('Mr.'),
        date: new FormControl('03/22/2022'),
        gender: new FormControl('Female'),
    });
    exampleInputValue = '';
    exampleSelectValue = 'Mrs.';
    exampleDateValue;
    exampleRadioValue = '';
    titles = ['Mr.', 'Mrs.'];
    radioOptions = ['Male', 'Female', 'Do not specify'];
    constructor(
        private toastService: ToasterService,
        private sideNavService: SideNavService,
        private schedulerService: SchedulerService,
        private modal: ModalService) {}

    get f() {
        return this.form.controls;
    }

    openModal() {
        this.modal.open(GoalModalComponent);
    }

    openSideNav() {
        const TSN = this.sideNavService.show(SchedulerRightBarComponent, {
            data: {
                "therapist": {
                    "availability": {
                        "days": {
                            "sun": {
                                "available": true,
                                "time": 480
                            },
                            "mon": {
                                "available": true,
                                "time": 480
                            },
                            "tue": {
                                "available": true,
                                "time": 480
                            },
                            "wed": {
                                "available": true,
                                "time": 480
                            },
                            "thu": {
                                "available": true,
                                "time": 480
                            },
                            "fri": {
                                "available": false,
                                "time": null
                            },
                            "sat": {
                                "available": true,
                                "time": 480
                            }
                        },
                    },
                    "name": "y, x",
                    "discipline": "PT",
                    "patients": [],
                    "email": "x@y.com"
                },
                "date": "2023-02-27T19:00:00.000Z"
            },
            position: 'right',
        });
        TSN.afterActionData.subscribe((data) => {
            this.schedulerService.unAssignTriggered.next(data);
        });
    }
    

    showSuccessToast() {
        this.toastService.show({
            title: 'Well done!',
            body: 'You successfully read this important message.',
            type: 'success',
        });
    }

    showWarningToast() {
        this.toastService.show({
            title: 'Warning!',
            body: 'Sorry! There was a problem with your request.',
            type: 'warning',
        });
    }

    showInfoToast() {
        this.toastService.show({
            title: 'Hi there!',
            body: 'Do you have a problem? Just use this contact form.',
            type: 'info',
        });
    }

    showErrorToast() {
        this.toastService.show({
            title: 'Oh Snap!',
            body: 'Change a few things up and try submitting again.',
            type: 'error',
        });
    }
}
