<label class="body-medium-semibold {{ labelClass }}" *ngIf="!isProjected">{{ label }}</label>
<div class="{{ disciplineRow }}">
    <div
        [ngClass]="{ 'align-items-center': !selectWithCards }"
        class="checker {{ disciplineColumn }} d-flex checkbox-holder {{ eachItemClass }}"
        *ngFor="let option of options; let i = index"
    >
        <div [ngClass]="{ 'position-relative overflow-hidden projectedCheck': isProjected }">
            <input
                type="checkbox"
                [(ngModel)]="option.isChecked"
                (ngModelChange)="onCheckboxChange(i, option, $event)"
                id="{{ label + option.text }}"
                [disabled]="option.isDisabled"
            />
            <label
                *ngIf="isProjected"
                for="{{ label + option.text }}"
                class="material-symbols-outlined filled"
                [ngClass]="{ solid: option.isChecked }"
            >
                chart_data
            </label>
        </div>
        &nbsp;&nbsp;<label
            *ngIf="!isProjected"
            for="{{ label + option.text }}"
            class="{{ fontSize }}"
            style="vertical-align: top"
            >{{ option.text }}
        </label>
        <div *ngIf="option.isChecked && selectWithCards && currentOption !== option.text" class="ml-2">
            <form [formGroup]="therapistForm">
                <app-select-with-cards
                    [label]="
                        'Select ' + option.text + ' Therapist ' + (therapistForm?.get(option.text)?.valid ? '' : '*')
                    "
                    [formControlName]="option.text"
                    placeholder="Choose one Option"
                    placement="top-left"
                    [options]="selectWithCardsOptions[option.text]"
                    (optionChanged)="selectWithCardChanged($event)"
                    class="pb-5"
                ></app-select-with-cards>
            </form>
        </div>
        <div class="ml-3" *ngIf="selectWithCards && currentOption === option.text">
            {{ currentOptionValue?.user?.lastName }}, {{ currentOptionValue?.user?.firstName }}
        </div>
    </div>
</div>
