<span
    *ngIf="canEdit()"
    class="material-icons-outlined text-secondary cursor-pointer align-middle px-1"
    (click)="actionClicked($event, 'edit')"
    [ngClass]="!isActionAllowed ? 'disable':''"
    >edit</span
>
<span
    *ngIf="params.disabledEdit"
    class="material-icons-outlined text-gray align-middle px-1"
    >edit</span
>
<span
    *ngIf="canDelete()"
    class="material-icons-outlined text-danger cursor-pointer align-middle px-1 ml-3"
    (click)="actionClicked($event, 'delete')"
    [ngClass]="!isActionAllowed ? 'disable':''"
    >delete</span
>
<ng-container *ngIf="!params.whiteTooltipEnabled; else customTooltipTemplate">
    <span
        *ngIf="params.disabledDelete"
        class="material-icons-outlined text-gray align-middle px-1 ml-3"
        [ngbTooltip]="params.disabledDeleteMessage" placement="right" container="body"
    >delete</span>
</ng-container>
<span
    *ngIf="params.download"
    class="material-icons-round text-secondary cursor-pointer align-middle"
    (click)="actionClicked($event, 'download')"
    >download</span
>
<span
    *ngIf="params.resolve"
    ngbTooltip="{{ params.tooltipText }}"
    placement="left"
    container="body"
    (click)="actionClicked($event, 'resolve')"
    class="mr-2 material-symbols-outlined text-secondary cursor-pointer align-middle"
    >problem</span
>
<span
    *ngIf="params.view"
    class="material-icons-round text-secondary cursor-pointer align-middle"
    (click)="actionClicked($event, 'view')"
    >visibility</span
>
<span
    *ngIf="params.lock"
    ngbTooltip="{{ params.tooltipText }}"
    placement="left"
    container="body"
    class="material-symbols-outlined text-secondary d-inline-block align-middle"
    >lock</span
>

<div
    *ngIf="params.coTreatConflict"
    class="text-secondary body-medium-semibold mt-2 cursor-pointer align-middle"
    (click)="conflictClicked()"
>
    View Details
</div>

<div
    *ngIf="params.notifyToCorrect"
    class="text-secondary body-medium-semibold cursor-pointer align"
    (click)="notifyToCorrect()"
>
    <button
        [disabled]="notifyDisabled"
        type="button"
        class="btn-medium btn-outline text-secondary bg-transparent border-secondary-300 px-3 py-1 border-radius-50"
    >
        Notify To Correct
    </button>
</div>

<span
    *ngIf="params.rollBack"
    class="material-symbols-outlined text-secondary d-inline-block align-middle cursor-pointer mr-1"
    [class.opacity-50]="params.disabled"
    (click)="actionClicked($event, 'rollBack')"
>
    settings_backup_restore</span
>
<span
    *ngIf="params.details"
    class="material-symbols-outlined text-secondary d-inline-block align-middle cursor-pointer"
    [class.opacity-50]="params.disabled"
    [class.pointer-events-none]="params.disabled"
    (click)="actionClicked($event, 'details')"
    >open_in_new</span
>


<!-- Custom html content for ngb Tooltip -->
<ng-template #customTooltipTemplate>
    <span
        *ngIf="params.disabledDelete"
        class="material-icons-outlined text-gray align-middle px-1 ml-3"
        [ngbTooltip]="deleteMessageTemplate" placement="right" container="body"
        [tooltipClass]="'ngb-tooltip-custom-cont'"
    >delete</span>
</ng-template>

<ng-template #deleteMessageTemplate>
    <div>
        <span class="text-danger">{{params.disabledDeleteWarning}}</span>&nbsp;
        <span class="text-secondary">{{params.disabledDeleteMessage}}</span>
    </div>
</ng-template>