<div class="mt-3">
    <div class="custom-card">
        <div class="header">
            <div class="row">
                <div class="col-md-6">
                    <p class="text-secondary">{{ heading }}</p>
                </div>
                <div class="col-md-6">
                    <app-button
                        *ngIf="records.length && !action"
                        type="secondary"
                        [circle]="true"
                        icon="add"
                        (click)="setState('add')"
                        class="float-right"
                    ></app-button>
                </div>
            </div>
        </div>
        <div class="card-padding body">
            <ng-container [ngSwitch]="action">
                <ng-container *ngSwitchCase="'add'" [ngTemplateOutlet]="addForm"></ng-container>
                <ng-container *ngSwitchCase="'edit'" [ngTemplateOutlet]="addForm"></ng-container>
            </ng-container>

            <ng-container *ngIf="!records.length && action !== 'add'" [ngTemplateOutlet]="noRecordsYet"> </ng-container>
            <ng-container *ngIf="records.length" [ngTemplateOutlet]="table"> </ng-container>
        </div>
    </div>
</div>

<ng-template #noRecordsYet
    ><div class="d-flex flex-row justify-content-center align-items-center">
        <div class="text-center text-secondary body-small-semibold my-3">
            <p>No prior visits yet</p>
            <app-button
                type="secondary"
                [text]="'Add Dates'"
                icon="add"
                class="ml-1 mt-2"
                (click)="setState('add')"
            ></app-button>
        </div>
    </div>
</ng-template>

<ng-template #addForm>
    <form #form [formGroup]="hospitalizationStaysForm" #ngForm="ngForm" (submit)="formSubmitted()">
        <div class="d-flex">
            <app-date-picker
                label="Admission"
                formControlName="admission"
                [backgroundClass]="'bg-body-background'"
                (ngModelChange)="updateDischargeDate()"
                [maxDate]="hospitalizationMaxDate"
            ></app-date-picker>
            <app-date-picker
                label="Discharge"
                formControlName="discharge"
                [backgroundClass]="'bg-body-background'"
                class="ml-3"
                [minDate]="dischargeMinDate"
                [maxDate]="hospitalizationMaxDate"
            ></app-date-picker>
        </div>
        <app-text-box
            [label]="'Description'"
            [backgroundClass]="'bg-body-background'"
            formControlName="description"
        ></app-text-box>

        <ng-container [ngTemplateOutlet]="bottomBtns"> </ng-container>
    </form>
</ng-template>

<ng-template #bottomBtns>
    <div class="mt-4 mb-3 d-flex justify-content-end">
        <app-button
            [text]="'Cancel'"
            [style]="'padding-top: 0.6em !important;padding-bottom: 0.6em !important;'"
            (click)="cancel()"
        ></app-button>
        <app-button
            [text]="action === 'add' ? 'save' : action === 'edit' ? 'update' : ''"
            [style]="'padding-top: 0.6em !important;padding-bottom: 0.6em !important;'"
            [icon]="''"
            [class]="'btn btn-secondary ml-2'"
            [rightIcon]="true"
            [type]="'submit'"
            [disabled]="!hospitalizationStaysForm.valid || submitted"
        ></app-button>
    </div>
</ng-template>

<ng-template #table>
    <app-data-grid domLayout="autoHeight" [columnDefs]="colsData" [rowData]="records" domLayout="autoHeight">
    </app-data-grid>
</ng-template>
