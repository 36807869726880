/* eslint-disable spellcheck/spell-checker */
import { FacilityAdmission } from '@app/models';
import { ChartService } from './../../../services/chart.service';
import {
    generateGoalSTGs,
    getGoalSelection,
    generateSeries,
    ISODateToXAxis,
    getLengthOfStay,
    getTodayMarkLine,
    fillInLineGaps,
    getSmallestEvaluationDate,
    uuidv4,
} from './../../../helpers/utils/charts/goals.utils';
import { ChartColors, ChartEvaluationData, ChartGoal, EChartsMarkLine } from './../../../interfaces/charts';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalService, ToasterService } from '@app/services';
import { GoalModalComponent } from '../modals/goal-modal/goal-modal.component';
import { ChartViews, LengthOfStay, TherapyAdmission, TherapyDiscipline } from '@app/interfaces';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription, switchMap, takeUntil } from 'rxjs';
import { isSameDay } from 'date-fns';
import { ActivatedRoute } from '@angular/router';
import { findTherapyCaseByParamId } from '@app/helpers/utils/therapy-case.utils';

@Component({
    selector: 'app-goals-chart',
    templateUrl: './goals-chart.component.html',
    styleUrls: ['./goals-chart.component.scss'],
})
export class GoalsChartComponent implements OnInit, OnDestroy {
    observableDestroyer = new Subject();
    loadingChartData = true;
    charDataObservable: Observable<any>;
    facilityAdmissionData: FacilityAdmission;
    therapyAdmissionData: TherapyAdmission[];
    fullPocChartLength = 0;
    monthViewChartLength = 30;
    weekViewChartLength = 7;
    hideDisciplineFilter = false
    // ltgColor = '#1F78B4';
    // stgColor = '#B2DF8A';
    colors: ChartColors = {
        LTG: '#1F78B4',
        STG: '#FD7014',
    };
    chartViews: ChartViews[] = ['Full POC', 'Month', 'Week'];
    selectedChartView: ChartViews = 'Full POC';
    @ViewChild('hiddenBtnToOpenModal') hiddenBtnToOpenModal: ElementRef;
    chart: any;
    stg = false;
    bluePrintData: any;
    selectedGoal = 'all';
    therapyDiscipline: TherapyDiscipline = 'PT';
    chartEvaluationData: ChartEvaluationData;
    anticipatedDischargeDate = '';
    anticipatedDischargeGraphic = '';
    dashedLineStyle = {
        normal: {
            type: 'dashed',
        },
    };
    lengthOfStay: LengthOfStay;
    lineProps = {
        symbol: 'none',
        type: 'line',
        smooth: true,
        label: {
            show: true,
            formatter: (params) => {
                return `PN ${params.dataIndex + 1}`;
            },
        },
    };
    TLOFLineProps = {
        symbol: 'none',
        type: 'line',
        smooth: false,
        lineStyle: {
            normal: {
                type: 'dashed',
            },
        },
        label: {
            show: false,
            position: 'right',
            formatter: (params) => {
                return params.seriesName;
            },
        },
    };
    dashedLineProps = {
        symbol: 'circle',
        symbolSize: 10,
        type: 'line',
        smooth: true,
    };
    verticalLineToday: EChartsMarkLine = {};
    verticalLineEnd: EChartsMarkLine = {
        silent: true, // ignore mouse events
        symbol: 'none',
        lineStyle: {
            color: '#D1D1D0',
            width: 2.5,
        },
        label: {
            show: false,
        },
        data: [
            {
                type: 'min',
                name: 'Anticipated Discharge',
            },
        ],
    };
    goals: ChartGoal[] = [];
    scatterData: any[] = [];
    TLOF: ChartGoal[];
    data: any;
    initOpts: any = {
        // renderer: 'svg'
    };
    option: any = {
        grid: {
            // right: 140,
            right: 1,
            left: 70,
            top: 10,
            bottom: 50,
        },
        dataZoon: false,
        noData: {
            text: 'No Data Available',
            textStyle: {
                fontSize: 18,
                fontWeight: 'bold',
                color: '#555',
            },
        },
        tooltip: {
            // trigger: 'axis',
            trigger: 'item',
            axisPointer: {
                show: true,
                type: 'shadow',
            },
            formatter: (params) => {
                return `<div class='d-flex flex-row align-items-center justify-content-between'>
          <span class="material-symbols-outlined text-primary">
          visibility
          </span>
          <span class='ml-2'> ${params.seriesName} </span>
        </div>`;
            },
        },
        legend: {
            show: false,
        },
        xAxis: {
            type: 'category',
            name: 'Dates',
            nameLocation: 'start',
            nameTextStyle: {
                color: '#F4633A',
                fontSize: 12,
                padding: 5,
                fontStyle: 'normal',
                fontFamily: 'Roboto',
                fontWeight: 700,
            },
            data: [],
        },
        yAxis: {
            show: true,
            max: 100,
            interval: 10,
            type: 'value',
            name: 'CLOF Scale',
            nameLocation: 'center',
            nameTextStyle: {
                color: '#1C3775',
                fontSize: 12,
                lineHeight: 50,
                fontStyle: 'normal',
                fontFamily: 'Roboto',
                fontWeight: 700,
            },
        },
        // Adding anticipated discharge label
        graphic: [],
        series: [],
    };
    chartDataSubscription: Subscription;
    currentTherapyAdmission: TherapyAdmission;
    constructor(
        private modal: ModalService,
        private toasterService: ToasterService,
        private chartService: ChartService,
        private activatedRoute: ActivatedRoute,
        private store$: Store<{ facilityAdmission: FacilityAdmission; therapyAdmission: TherapyAdmission[] }>
    ) {}
    mapDataToChart(goals) {
        return goals.map((goal) => ({
            uid: goal.uid,
            name: goal.name,
            discipline: goal.discipline,
            data: goal.data,
            isProjected: false,
            scaleInterpretation: goal.scaleInterpretation,
            goalType: 'LTG',
            calculatedEvaluationCLOF: goal.calculatedEvaluationCLOF,
            plot: goal.plot,
            itemStyle: {
                symbolSize: 10,
            },
            target: goal.TLOF,
            stgs: goal.stgs.map((stg) => ({
                uid: goal.uid,
                name: stg.name,
                calculatedClof: stg.calculatedClof,
                clof: stg.clof,
                discipline: stg.discipline,
                data: stg.data,
                isProjected: false,
                goalType: 'stg',
                target: stg.target,
            })),
        }));
    }
    ngOnDestroy(): void {
        this.observableDestroyer.next(undefined);
        this.observableDestroyer.complete();
        
    }
    ngOnInit(): void {
        const queryString = this.activatedRoute.snapshot.queryParams;
        this.hideDisciplineFilter = this.activatedRoute.toString().includes('therapy-case')
        this.store$.pipe(takeUntil(this.observableDestroyer)).subscribe((storeData) => {
            this.facilityAdmissionData = storeData.facilityAdmission;
            this.therapyAdmissionData = storeData.therapyAdmission;
            this.currentTherapyAdmission = findTherapyCaseByParamId(this.therapyAdmissionData, queryString['therapy-case']);
            this.therapyDiscipline = this.currentTherapyAdmission?.therapyDiscipline || this.therapyDiscipline;
            const facilityAdmission = storeData.facilityAdmission.id;
            if (this.facilityAdmissionData.anticipatedDischarge?.anticipatedDischargeDate) {
                this.charDataObservable = this.chartService.getGoalsChartData(facilityAdmission);
            } else {
                this.loadingChartData = false;
            }
        });
        if (this.facilityAdmissionData.anticipatedDischarge.anticipatedDischargeDate) {
            this.chartDataSubscription = this.chartDataGetter.subscribe();
        }
    }
    get chartDataGetter() {
        return this.charDataObservable.pipe(
            switchMap(async (data) => {
                try {
                    this.bluePrintData = data;
                    this.loadingChartData = true;
                    // Adding uids to seriesData
                    for (let i = 0; i < data.y.seriesData.length; i++) {
                        data.y.seriesData[i].uid = await uuidv4();
                        for (let j = 0; j < data.y.seriesData[i].stgs.length; j++) {
                            data.y.seriesData[i].stgs[j].uid = await uuidv4();
                        }
                    }
                    // adding uids to TLOF Data
                    for (let i = 0; i < data.y.tlof.length; i++) {
                        data.y.tlof[i].uid = data.y.seriesData[i].uid;
                        for (let j = 0; j < data.y.tlof[i].stgs.length; j++) {
                            data.y.tlof[i].stgs[j].uid = data.y.seriesData[i].stgs[j].uid;
                        }
                    }
                    // Start: Setting anticipated discharge date
                    const _tempAnticipatedDischargeDate = new Date(
                        this.facilityAdmissionData.anticipatedDischarge.anticipatedDischargeDate
                    );
                    this.anticipatedDischargeGraphic = `${
                        _tempAnticipatedDischargeDate.getMonth() + 1
                    }/${_tempAnticipatedDischargeDate.getDate()}`;

                    // Setting length of stay
                    this.lengthOfStay = getLengthOfStay(this.facilityAdmissionData.admitDate);
                    // Setting today mark line
                    // const marklineIndex = this.option.xAxis.data.slice(0,)
                    this.verticalLineToday = getTodayMarkLine(this.lengthOfStay);
                    // Mapping data to chart data
                    this.goals = this.mapDataToChart(data.y.seriesData);
                    // Settings xAxis data (Dates)
                    data.x = data.x[`${this.therapyDiscipline.toLowerCase()}Axis`];
                    this.option.xAxis.data = [...data.x.map((x) => ISODateToXAxis(x))];

                    for (const [index, item] of data.y.scatterData.entries()) {
                        const _preparedScatter: any[] = [];
                        const [name, value] = item.values[0];
                        for (const xItem of data.x) {
                            // Convert date strings to Date objects
                            const xAxisDate = new Date(xItem);
                            const scatterDate = new Date(name);
                            if (isSameDay(xAxisDate, scatterDate)) {
                                _preparedScatter.push({ name: ISODateToXAxis(name), value });
                            } else {
                                _preparedScatter.push({ name: '-', value: '-' });
                            }
                        }
                        data.y.scatterData[index].data = _preparedScatter;
                    }
                    // Settings discipline for scatter data
                    data.y.scatterData = data.y.scatterData.map((item) => ({
                        ...item,
                        discipline: this.goals.find((goal) => goal.name === item.name)?.discipline,
                    }));
                    this.scatterData = data.y.scatterData;
                    // Updating chart data zoom
                    // Commented out because we are now going to remove the data zoom from our charts
                    // this.updateChartDataZoom();
                    // Setting tlof data
                    this.TLOF = [...data.y.tlof];
                    const filteredTherapyAdmission = this.therapyAdmissionData.filter(
                        (admission) => admission.therapyDiscipline === this.therapyDiscipline && admission._id === this.currentTherapyAdmission?._id
                    );
                    if (filteredTherapyAdmission.length > 0) {
                        const smallestEvaluationDate = getSmallestEvaluationDate(filteredTherapyAdmission);
                        const startDate = ISODateToXAxis(smallestEvaluationDate.toISOString());
                        const todayDate = ISODateToXAxis(new Date().toISOString());
                        const startDateIndex = this.option.xAxis.data.indexOf(startDate);
                        const todayDateIndex =
                            this.option.xAxis.data.indexOf(todayDate) === -1
                                ? this.option.xAxis.data.length - 1
                                : this.option.xAxis.data.indexOf(todayDate);
                        this.goals = this.goals.map((item) => {
                            item.data = fillInLineGaps(item, data.y.scatterData, {
                                startDate: startDateIndex,
                                endDate: todayDateIndex,
                                evaluationCLOF: item.calculatedEvaluationCLOF,
                                xAxis: this.option.xAxis,
                            });
                            if (item.scaleInterpretation !== '' && item.scaleInterpretation !== 'Less is best') {
                                item.data = item.data.reverse();
                            }
                            item.stgs.map((stgItem) => {
                                stgItem.data = fillInLineGaps(stgItem, data.y.scatterData, {
                                    startDate: startDateIndex,
                                    endDate: todayDateIndex,
                                    evaluationCLOF: stgItem.calculatedClof,
                                    xAxis: this.option.xAxis,
                                });
                            });
                            return item;
                        });
                        this.option.xAxis.data = this.option.xAxis.data.slice(
                            this.option.xAxis.data.indexOf(ISODateToXAxis(smallestEvaluationDate.toISOString())),
                            this.option.xAxis.data.length
                        );
                        this.TLOF = data.y.tlof.map((item) => ({
                            ...item,
                            data: [item.TLOF],
                            stgs: item.stgs.map((stg) => ({ ...stg, data: [stg.TLOF] })),
                        }));
                        // Generate chart at the end
                        this.generate();
                        this.loadingChartData = false;
                        return new Observable();
                    }
                } catch (err: any) {
                    console.error(err);
                    this.loadingChartData = false;
                    return undefined;
                } finally {
                    this.loadingChartData = false;
                }
            })
        );
    }

    recallChartDataGetter() {
        // Unsubscribe any previous subscription if it exists
        if (this.chartDataSubscription) {
            this.chartDataSubscription.unsubscribe();
        }
        // Subscribe to the chartDataGetter and store the new subscriptio
        this.chartDataSubscription = this.chartDataGetter.subscribe();
    }
    // When chart is initialized
    onChartInit(event: any) {
        this.data = [];
        this.chart = event;
        this.chart.setOption(this.option);
        this.chart.on('click', (params) => {
            const index = this.bluePrintData.y.seriesData.findIndex((x) => x.name === params.seriesName);
            if (index > -1) {
                this.data = this.bluePrintData.y.seriesData[index];
                this.hiddenBtnToOpenModal.nativeElement.click();
            } else {
                this.bluePrintData.y.seriesData.forEach((x) => {
                    const stgIndex = x.stgs.findIndex(
                        (y) => y.name === params.seriesName && y.discipline === this.therapyDiscipline
                    );
                    if (stgIndex > -1) {
                        this.data = x.stgs[stgIndex];
                        this.data.clof = this.data.clof['levelOfFunction'];
                        this.hiddenBtnToOpenModal.nativeElement.click();
                    }
                });
            }
        });
        // Disable legend selection
        this.chart.on('legendselectchanged', (params) => {
            this.chart.setOption({ animation: false });
            this.chart.dispatchAction({
                type: 'legendSelect',
                name: params.name,
            });
            this.chart.setOption({ animation: true });
        });
    }
    hiddenBtnClicked(): void {
        this.openModal();
    }
    showSTGNotification() {
        if (this.selectedGoal === 'all') {
            this.toasterService.show({
                title: 'Info',
                body: 'Please select a "Long Term Goal (LTG)" to enable its "Short Term Goals (STG)"',
                type: 'info',
            });
        }
    }
    // Set value for stg switch
    setStg(value: boolean) {
        if (this.selectedGoal === 'all') {
            this.stg = false;
            return;
        }
        this.stg = value;
    }
    openModal() {
        const dialogRef = this.modal.open(GoalModalComponent, {
            data: this.data,
        });
        return dialogRef.afterClosed();
    }
    // Generate all in just a single function
    generate() {
        // Filtering out goals
        if (this.selectedGoal === 'all') this.setStg(false);
        this.generateChartContent(
            // Discipline can not be all. That is why a we need to filter the discipline
            getGoalSelection(this.filterDiscipline(this.goals, this.therapyDiscipline), this.selectedGoal)
        );
        if (this.option.series.length > 0) {
            // If there is data then show the chart
            this.option.series = [
                ...this.option.series,
                ...this.generateScatterData(this.scatterData, this.selectedGoal),
            ];
            // Adding trigger line event to true so that user can click on line as well to trigger the click events
            this.option.series = this.option.series.map((item) => {
                return {
                    ...item,
                    triggerLineEvent: true,
                };
            });
            if (this.chart) this.chart.setOption(this.option, true);
            // Set the data for evaluation
            // DevNote: Make sure to handle therapyDiscipline all as well.
            // End of set the data for evaluation
        } else {
            // If there is no data then show no data message
            if (this.chart)
                this.chart.setOption(
                    {
                        title: {
                            show: true,
                            text: 'No Data',
                            left: 'center',
                            top: 'center',
                        },
                        xAxis: {
                            show: false,
                        },
                        yAxis: {
                            show: false,
                        },
                        series: [],
                        x: 'center',
                        y: 'center',
                    },
                    true
                );
        }
    }
    // This method generates the scatter data for the chart
    generateScatterData(scatterData: any[], goal: string) {
        // Filter for a single goal name if single goal is selected not all goals
        if (goal !== 'all') {
            scatterData = scatterData.filter((_) => _.name === goal);
        }
        // Disciplines can not be all. That is why we need to filter the discipline
        scatterData = scatterData.filter((_) => _.discipline === this.therapyDiscipline);
        // Generating scatter data and settings its color related to series goal color
        const ltgs = scatterData.map((item) => ({
            name: this.option.series.find((s) => s.name === item.name).name,
            symbolSize: 10,
            itemStyle: {
                color: this.colors['LTG'],
            },
            data: item.data,
            z: 5,
            type: 'scatter',
        }));
        const stgs = [];
        if (this.stg === true) {
            scatterData.forEach((item) =>
                item.stgs.forEach((stg) => {
                    stgs.push({
                        name: stg.name,
                        symbolSize: 10,
                        itemStyle: {
                            color: this.colors['STG'],
                        },
                        data: stg.data,
                        z: 5,
                        type: 'scatter',
                    });
                })
            );
        }
        return [...ltgs, ...stgs];
    }
    // Generates the content for chart and sets it.
    // Generates series and legend and sets it in the options
    generateChartContent(goals: ChartGoal[]) {
        if (goals.length <= 0) {
            this.option.series = [];
            return;
        }
        this.option.series = generateSeries({
            goals,
            xAxis: this.option.xAxis,
            TLOF: this.TLOF,
            commonProps: this.lineProps,
            TLOFLineProps: this.TLOFLineProps,
            selectedChartView: this.selectedChartView,
            verticalLineEnd: this.verticalLineEnd,
            verticalLineToday: this.verticalLineToday,
            selectedGoal: this.selectedGoal,
            stg: this.stg,
            colors: this.colors,
        });
        if (this.selectedGoal !== 'all') {
            // If all goals is not selected
            this.option.legend.data = goals
                .filter((goal) => goal.isProjected === false)
                .map((goal) => ({ name: goal.name, icon: 'rect' }));
            // Adding stgs
            if (this.stg === true) {
                // Adding to legend
                goals
                    .filter((goal) => goal.isProjected === false)
                    .forEach((goal) =>
                        goal.stgs.forEach((_goal) => this.option.legend.data.push({ name: _goal.name, icon: 'rect' }))
                    );
                // Adding stgs to goals
                goals = [...goals, ...generateGoalSTGs(goals.filter((goal) => goal.isProjected === false))];
            }
        } else {
            // --->If all goals is selected
            this.option.legend.data = goals
                .filter((goal) => goal.isProjected === false)
                .map((goal) => ({ name: goal.name, icon: 'rect' }));
        }
    }
    // Filter goals by discipline
    filterDiscipline(goals: ChartGoal[], discipline: TherapyDiscipline | 'all') {
        return goals.filter((goal) => goal.discipline === discipline);
    }
    // Sets selected goal
    setSelectedGoal(event) {
        this.selectedGoal = event.currentTarget.value;
        this.setStg(true);
    }
    // Set Therapy Discipline
    setTherapyDiscipline(event) {
        this.therapyDiscipline = event.target.value;
        this.selectedGoal = 'all';
    }
    // Returns all goals with value isProjected=false;
    getNonProjected(goals: ChartGoal[]) {
        return goals.filter((goal) => goal.isProjected === false);
    }
    // Handling chart views
    setChartView(event) {
        this.selectedChartView = event.target.value;
    }
    // Updates the zoom based on chart views
    // chartView: 'Full POC' | 'Month' | 'Week'
    renderChart() {
        /* TO handle chart views */
    }
    leftChevronClick() {
        /* To handle left chevron button click on chart */
    }
    rightChevronClick() {
        /* To handle right chevron button click on chart */
    }
    // Legend
    mapGoalsToLegend() {
        this.goals = this.goals?.filter((goal) => goal.discipline === this.therapyDiscipline);
        if (this.selectedGoal === 'all') {
            return this.goals.map((item) => ({
                title: item.name,
                tlof: item.target || item.TLOF,
                type: item.goalType.toUpperCase(),
                stgs: item.stgs.map((stgItem) => ({
                    title: stgItem.name,
                    tlof: stgItem.target,
                    type: stgItem.goalType.toUpperCase(),
                    stgs: [],
                })),
            }));
        } else {
            return this.goals
                .filter((item) => item.name === this.selectedGoal && item.discipline === this.therapyDiscipline)
                .map((item) => ({
                    title: item.name,
                    tlof: item.target,
                    type: item.goalType.toUpperCase(),
                    stgs: item.stgs.map((stgItem) => ({
                        title: stgItem.name,
                        tlof: stgItem.target,
                        type: stgItem.goalType.toUpperCase(),
                        stgs: [],
                    })),
                }));
        }
    }
}
