import { createAction, props } from '@ngrx/store';
import { loadingSpinnerState } from '../store.interface';

export const spinnerStateChangeAction = createAction(
    '[SpinnerState action] Loading Spinner State Changed',
    props<{ selectedLoadingSpinnerState: loadingSpinnerState }>()
);
export const spinnerDocumentStateChangeAction = createAction(
    '[SpinnerDocumentState action] Loading Spinner State Changed',
    props<{ selectedLoadingSpinnerState: loadingSpinnerState }>()
);
export const spinnerTherapyStateChangeAction = createAction(
    '[SpinnerTherapyState action] Loading Spinner State Changed',
    props<{ selectedLoadingSpinnerState: loadingSpinnerState }>()
);

export const spinnerStateResetAction = createAction('[SpinnerState action] Loading Spinner Reset');
