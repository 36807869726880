<app-base-modal-card [heading]="heading" (close)="onClose()" wrapperClass="overflow-y-inherit">
    <div body class="body-container">
        <div class="row bg-white px-2 rounded-pill mt-3">
            <div class="col-6">
                <app-input
                    [(ngModel)]="proceduresQuery"
                    (ngModelChange)="searchProcedures()"
                    placeholder="Type exercises to search..."
                    label="Add procedures"
                ></app-input>
                <app-input
                    [(ngModel)]="customProcedure"
                    placeholder="Type here..."
                    label="Add custom procedures"
                ></app-input>
            </div>
            <div class="col-6">
                <div class="mt-3 bg-white p-2">
                    <span
                        *ngFor="let item of selectedProcedures; let i = index"
                        class="badge rounded-pill bg-primary pl-3 pr-2 py-2 text-white mr-2 mb-2"
                    >
                        {{ item?.description ?? 'NA' }}
                        <span
                            class="material-symbols-outlined font-16 align-middle cursor-pointer"
                            (click)="removeProcedure(item, i)"
                            >close</span
                        >
                    </span>
                    <span
                        *ngFor="let item of data.exercise; let i = index"
                        class="badge rounded-pill bg-primary pl-3 pr-2 py-2 text-white mr-2 mb-2"
                    >
                        {{ item }}
                        <span
                            class="material-symbols-outlined font-16 align-middle cursor-pointer"
                            (click)="removeProcedure(item, i)"
                            >close</span
                        >
                    </span>
                </div>
            </div>
        </div>
        <!-- Search results -->
        <div class="mt-3 p-1">
            <div class="d-flex flex-column">
                <!-- Single Result -->
                <ng-container *ngIf="proceduresQuery?.length && !searchedProcedures?.length">
                    <div class="d-flex flex-row align-items-center justify-content-center mt-1 mb-1">
                        {{ underSearchMsg }}
                    </div>
                </ng-container>
                <div *ngFor="let item of searchedProcedures" class="w-100 d-flex flex-column mt-3">
                    <div class="d-flex flex-row">
                        <input
                            type="checkbox"
                            class="checkbox"
                            [checked]="item.checked"
                            (click)="procedureClicked(item, $event)"
                        />
                        <div class="body-medium-semibold ml-3">{{ item?.description ?? 'NA' }}</div>
                    </div>
                    <div class="d-flex flex-row aling-items-center justify-content-center w-100 px-2 mt-3">
                        <div class="bg-black-a10 w-100" style="height: 1px"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="see-more-container d-flex justify-content-center">
            <div
                #seeMoreBtn
                *ngIf="seeMoreProcedures.length"
                class="see-more-btn d-flex justify-content-center align-items-center"
                (click)="applySeeMoreFilter()"
            >
                <span class="material-symbols-outlined solid"> expand_more </span>
                <button class="btn orange-color">
                    See More ({{ searchedProcedures.length }}/{{
                        searchedProcedures.length + seeMoreProcedures.length
                    }})
                </button>
            </div>
        </div>

        <div class="p-3">
            <button
                class="mt-3 mb-3 btn px-3 btn-secondary rounded-pill"
                [disabled]="!customProcedure?.length"
                (click)="addCustomProcedure()"
            >
                Add Procedure
            </button>
        </div>
    </div>
    <div footer class="w-100 d-flex flex-row align-items-center justify-content-end">
        <button
            [ngClass]="{ disabled: !selectedProcedures }"
            (click)="saveClicked()"
            class="btn btn-secondary rounded-pill d-flex flex-row align-items-enter"
        >
            Save
            <span class="material-symbols-outlined solid ml-1"> chevron_right </span>
        </button>
    </div>
</app-base-modal-card>
