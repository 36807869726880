import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService, ToasterService } from '@app/services';
import { ROUTER_UTILS, Role } from '@app/helpers';

@Injectable({ providedIn: 'root' })
export class AuthorizationGuard implements CanActivate {
    constructor(private router: Router, private AuthService: AuthService, private toasterService: ToasterService) {}

    canActivate(
        route: ActivatedRouteSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const account = this.AuthService.accountValue;

        if (account) {
            const editProfileUrl = ROUTER_UTILS.config.admin.root +'/' +ROUTER_UTILS.config.admin.accounts.root + `/${ROUTER_UTILS.config.admin.accounts.editUser}`
            const changePasswordUrl = ROUTER_UTILS.config.admin.root +'/' +ROUTER_UTILS.config.admin.accounts.root + `/${ROUTER_UTILS.config.admin.accounts.changePassword}`
            const viewProfileUrl = ROUTER_UTILS.config.profile.root;
            if ((route['_routerState'].url.includes(editProfileUrl) || route['_routerState'].url.includes(viewProfileUrl) || route['_routerState'].url.includes(changePasswordUrl))
                && route['_routerState'].url.includes(this.AuthService.accountValue.user.id)) {
                return true;
            } else if (route.data.rights && route.data.rights.some(right => this.AuthService.applyPolicy(right))) {
                return true;
            } else if (route.data.role && route.data.role === Role.SuperAdmin && this.AuthService.isSuperAdmin) {
                return true;
            }
            this.toasterService.show({
                title: 'Error',
                body: `You don't have permission to perform this action.`,
                type: 'error',
            });
            this.router.navigateByUrl(ROUTER_UTILS.config.base.home);
            return false;
        }
        console.error('No user information');
        return false;
    }
}
