<div [class.custom-card]="insideCard">
    <!-- Section 1 -->
    <div class="header">
        <div class="row align-items-center" *ngIf="headerFull; else tableHeader">
            <div class="col-md-6">
                <p class="text-secondary">{{ tableTitle }}</p>
            </div>
            <div class="col-md-6">
                <div class="d-flex flex-row align-items-center justify-content-end" *ngIf="options?.length > 0">
                    <app-select
                        label="GG Type"
                        labelLeft="true"
                        [(ngModel)]="selectedOption"
                        (ngModelChange)="onOptionChange($event)"
                        [options]="options"
                        [inputClass]="inputClass"
                        [small]="true"
                    >
                    </app-select>
                </div>
            </div>
        </div>
        <ng-template #tableHeader>
            <div class="row">
                <div class="col-md-6">
                    <p class="text-secondary">{{ tableTitle }}</p>
                </div>
                <div class="col-md-6" *ngIf="tableTitleSecond">
                    <span
                        class="h6-semibold text-primary float-right mb-0"
                        [innerHTML]="tableTitleSecond | safeHtml"
                    ></span>
                </div>
            </div>
        </ng-template>
    </div>
    <!-- <div class="bg-secondary-a20 mb-2" style="height: 1px"></div> -->
    <div class="row" *ngIf="visibleSectionGG && tableTitle === 'Section GG'">
        <div class="col-md-6">
            <ng-container *ngIf="configData?.component === 'mds'; else generic">
                <span
                    [ngClass]="{
                        'text-success-200': configData.completed === configData.total,
                        'text-danger': configData.completed !== configData.total
                    }"
                    class="h6-semibold float-left mt-2 px-3"
                >
                    <span>
                        <ng-container *ngIf="configData.completed === configData.total">
                            <img src="assets/icons/fsGreen.svg" />
                        </ng-container>
                        <ng-container *ngIf="configData.completed !== configData.total">
                            <img src="assets/icons/fsRed.svg" />
                        </ng-container>
                    </span>
                    <span
                        [ngClass]="{
                            'text-success-200': configData.completed === configData.total,
                            'text-danger': configData.completed !== configData.total
                        }"
                        class="ml-2 h-title"
                    >
                        {{ tableCountTitle }}:<span class="body-large-bold">
                            {{ tableCount == undefined ? 0 : tableCount }}</span
                        ></span
                    >
                    <span class="ml-2 body-medium">
                        ({{ configData.completed }} completed out of {{ configData.total }})
                    </span>
                </span>
            </ng-container>

            <ng-template #generic>
                <span class="h6-semibold text-primary float-left mt-2 px-3">
                    <span
                        ><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="10" cy="10" r="10" fill="#F4633A" />
                            <path
                                d="M9 7.02V8.15H6.09V9.94H8.32V11.05H6.09V14H4.69V7.02H9ZM12.4027 14.07C11.9161 14.07 11.4761 13.9867 11.0827 13.82C10.6961 13.6533 10.3894 13.4133 10.1627 13.1C9.93607 12.7867 9.8194 12.4167 9.81273 11.99H11.3127C11.3327 12.2767 11.4327 12.5033 11.6127 12.67C11.7994 12.8367 12.0527 12.92 12.3727 12.92C12.6994 12.92 12.9561 12.8433 13.1427 12.69C13.3294 12.53 13.4227 12.3233 13.4227 12.07C13.4227 11.8633 13.3594 11.6933 13.2327 11.56C13.1061 11.4267 12.9461 11.3233 12.7527 11.25C12.5661 11.17 12.3061 11.0833 11.9727 10.99C11.5194 10.8567 11.1494 10.7267 10.8627 10.6C10.5827 10.4667 10.3394 10.27 10.1327 10.01C9.93273 9.74333 9.83273 9.39 9.83273 8.95C9.83273 8.53667 9.93607 8.17667 10.1427 7.87C10.3494 7.56333 10.6394 7.33 11.0127 7.17C11.3861 7.00333 11.8127 6.92 12.2927 6.92C13.0127 6.92 13.5961 7.09667 14.0427 7.45C14.4961 7.79667 14.7461 8.28333 14.7927 8.91H13.2527C13.2394 8.67 13.1361 8.47333 12.9427 8.32C12.7561 8.16 12.5061 8.08 12.1927 8.08C11.9194 8.08 11.6994 8.15 11.5327 8.29C11.3727 8.43 11.2927 8.63333 11.2927 8.9C11.2927 9.08667 11.3527 9.24333 11.4727 9.37C11.5994 9.49 11.7527 9.59 11.9327 9.67C12.1194 9.74333 12.3794 9.83 12.7127 9.93C13.1661 10.0633 13.5361 10.1967 13.8227 10.33C14.1094 10.4633 14.3561 10.6633 14.5627 10.93C14.7694 11.1967 14.8727 11.5467 14.8727 11.98C14.8727 12.3533 14.7761 12.7 14.5827 13.02C14.3894 13.34 14.1061 13.5967 13.7327 13.79C13.3594 13.9767 12.9161 14.07 12.4027 14.07Z"
                                fill="white"
                            />
                        </svg>
                    </span>
                    <span class="h-title">
                        {{ tableCountTitle }}:<span class="body-large-bold text-secondary">
                            {{ tableCount == undefined ? 0 : tableCount }}</span
                        ></span
                    >
                </span>
            </ng-template>
        </div>
    </div>
    <div class="body" [ngStyle]="{ height: !showGrid ? '200px' : 'auto' }">
        <span class="align-items-center d-flex h-100 justify-content-center" *ngIf="!showGrid">No rows to show</span>
        <ng-container *ngIf="showGrid">
            <app-data-grid
                [domLayout]="'autoHeight'"
                [columnDefs]="tableCol"
                [rowData]="tableData"
                [pagination]="pagination"
                [showCount]="showCount"
                (gridReady)="initGrid($event)"
            >
            </app-data-grid>
        </ng-container>
    </div>
    <!-- Section 2  -->
</div>
<div *ngIf="showMore" class="text-center mt-3">
    <div *ngIf="showMoreState">
        <span class="see-more text-primary" (click)="showMoreClick(); showMoreState = !showMoreState">
            <span class="material-icons-outlined">expand_more</span>
            See More</span
        >
    </div>
    <div *ngIf="!showMoreState">
        <span class="see-more text-primary" (click)="showLessClick(); showMoreState = !showMoreState">
            <span class="material-icons-outlined">expand_less</span>
            See Less</span
        >
    </div>
</div>
