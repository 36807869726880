<div id="discipline-bar-card-cont">
    <ng-container *ngFor="let discipline of disciplines">
        <ng-template
            *ngTemplateOutlet="
                disciplineCard;
                context: { discipline: discipline, activeAdmission: activeTherapyAdmissions[discipline] }
            "
        ></ng-template>
    </ng-container>
</div>

<!-- ================== -->
<!-- Discipline detail template -->
<!-- ================== -->
<ng-template #disciplineCard let-discipline="discipline" let-activeAdmission="activeAdmission">
    <div class="d-flex flex-column discipline-card-cont">
        <div class="text-secondary w-100 abs-center">
            <div class="h5 abs-center discipline-text no-margin">{{ discipline }}</div>
            <div class="discipline-content ml-4">
                <ng-container *ngIf="activeAdmission; else noTherapy">
                    <div class="d-flex flex-column align-items-center">
                        <div class="body-large-bold">SOC Date</div>
                        <div class="body-regular-14">{{ transformDate(activeAdmission.SOCDate) }}</div>
                    </div>
                    <div class="d-flex flex-column align-items-center">
                        <div class="body-large-bold">Supervising Therapist</div>
                        <div class="body-regular-14">
                            <ng-template
                                *ngTemplateOutlet="templateWithTitle; context: {
                                    longText: getSuperTherapistInfo(activeAdmission.assignedTherapists),
                                    admission: activeAdmission
                                }">
                            </ng-template>
                        </div>
                    </div>
                    <div class="d-flex flex-column align-items-center">
                        <div class="body-large-bold">EOC Date</div>
                        <div class="body-regular-14">
                            {{
                                activeAdmission?.documentation?.dischargeNote?.signed?.signDate
                                    ? transformDate(
                                          activeAdmission?.documentation?.dischargeNote?.assessment?.endOfCareDate
                                      )
                                    : ' -- '
                            }}
                        </div>
                    </div>
                    <div class="abs-center">
                        <span
                            class="admission-status-badge badge bg-success-100-a40 rounded-pill text-success-200
                                rounded-pill-fixed-w body-regular-12-no-lineHeight">
                            {{ activeAdmission?.isDischarged ? statusEnum.Closed : statusEnum.Open }}</span
                        >
                    </div>
                    <div class="abs-center">
                        <div
                            class="icon-lg-cell material-symbols-outlined text-secondary d-inline-block align-middle cursor-pointer"
                            (click)="openTherapyCase(activeAdmission._id, activeAdmission.facilityAdmission)"
                        >
                            open_in_new
                        </div>
                    </div>
                </ng-container>
                <!-- No Therapy Case -->
                <ng-template #noTherapy>
                    <div class="abs-center">
                        <span class="no-therapy-case d-flex justify-content-center">No Open Therapy Case</span>
                        <div class="abs-center add-therapy-case">
                            <!-- <button *ngIf="canAdd" class="btn pt-0 mt-n2 shadow-none btn-sm" [routerLink]="'admissionURL'"> -->
                            <button *ngIf="checkAddPermission && !isFacilityDischarged" class="btn abs-center pt-0 shadow-none btn-sm" (click)="addTherapyCase(discipline)">
                                <span
                                    class="material-icons-outlined text-secondary"
                                    ngbTooltip="Add New Patient"
                                    placement="right"
                                >
                                    add_circle
                                </span>
                            </button>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
        <app-pcc-v2-history-list
            [listExpanded]="expandAdmissionHistory"
            [rowData]="admissionsDataGridRows[discipline]"
            [columnDefs]="admissionsDataGridColumns"
            [toggleTitle]="'Case'"
            [gridOptions]="gridOptions"
            [rowClass]="'bg-white'"
            [toggleTitleClass]="'bg-body-color'"
        ></app-pcc-v2-history-list>
    </div>
</ng-template>

<!-- ================== -->
<!-- template with title -->
<!-- ================== -->
<ng-template #templateWithTitle let-longText="longText" let-admission="admission">
    <span *ngIf="!longText.includes('...')">{{ longText }}</span>

    <span *ngIf="longText.includes('...')" [title]="getSuperTherapistInfo(admission.assignedTherapists, true)">{{ longText }}</span>
</ng-template>