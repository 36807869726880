<div class="form-group" [class.select-error]="hasError">
    <label [class]="labelClass" *ngIf="label">{{ label | translate }} {{ isRequired ? '*' : '' }}</label>
    <select
        class="form-control rounded-curve {{ fontSize }}"
        style="overflow: hidden !important; white-space: nowrap; text-overflow: ellipsis"
        [(ngModel)]="value"
        [disabled]="disabled"
        (change)="optionSelect($event)"
        [ngClass]="[backgroundClass, foregroundClass]"
        [class.placeholder-select]="value == ''"
    >
        <option selected disabled hidden value="">{{ placeholder }}</option>
        <option
            class="body-large options"
            [ngClass]="backgroundClass ? backgroundClass : ''"
            *ngFor="let option of selectOptions"
            value="{{ option.id }}"
            (click)="optionSelect(option)"
        >
            {{ option.label | translate }}
        </option>
    </select>
</div>
