import { Component, Inject } from '@angular/core';
import { DIALOG_DATA } from '@app/helpers/dialog-tokens';
import { DialogRef } from '@app/services';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-generic-deletion-modal',
    templateUrl: './generic-deletion-modal.component.html',
    styleUrls: ['./generic-deletion-modal.component.scss'],
})
export class GenericDeletionModalComponent {
    constructor(@Inject(DIALOG_DATA) public data, private ref: DialogRef, protected sanitizer: DomSanitizer) {}

    onClose(result?) {
        this.ref.close(result);
    }
    onDone() {
        this.onClose(true);
    }
}
