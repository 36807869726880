import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'date-view-renderer',
    template: `
        <ng-container>
            <!-- <span *ngIf="params.data.status == 'Past Due'" class="text-danger">{{ day | date }}</span> -->
            <span>{{ date }}</span>
        </ng-container>
    `,
})
export class DateViewRenderer implements ICellRendererAngularComp {
    private params: any;
    date: any;
    public disabled = false;
    constructor(private datePipe: DatePipe) {}
    agInit(params: any): void {
        this.date = this.datePipe.transform(params.data.date, 'MM/dd/yyyy');
        this.params = params;
    }

    refresh() {
        return false;
    }
}
