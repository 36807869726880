import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { ActionsCellRendererComponent } from '@app/data-grid/cell-renderers';
import { DIALOG_DATA } from '@app/helpers/dialog-tokens';
import { DialogRef } from '@app/services';

@Component({
    selector: 'app-conflict-modal',
    templateUrl: './conflict-modal.component.html',
    styleUrls: ['./conflict-modal.component.scss'],
})
export class ConflictModalComponent {
    heading;
    conflicts;

    matrix = [];
    constructor(@Inject(DIALOG_DATA) public data, private dialogRef: DialogRef, private datePipe: DatePipe) {
        this.heading = `Co-Treatment Conflict: ${data.name}`;
        this.conflicts = data.conflicts;

        if (this.conflicts.length) {
            this.conflicts.forEach((conflict) => {
                const cols = [
                    {
                        headerName: 'Therapist Name',
                        field: 'therapistName',
                        width: 110,
                    },
                    {
                        headerName: 'Note',
                        field: 'note',
                        width: 90,
                    },
                    {
                        headerName: 'Co-Treatment Disciplines',
                        field: 'disciplines',
                        cellRenderer: (v) => {
                            let html = '<div>';

                            v.value?.forEach((elem) => {
                                html += `<span class="badge p-1 pl-3 pr-3 rounded-pill ${
                                    v.data.mappingIssue
                                        ? 'rounded-pill bg-danger-a10 text-danger'
                                        : 'bg-success-a10 text-success ' // 'bg-secondary-a30 bg-secondary-a30'
                                }  body-small-semibold">${elem}</span>`;
                            });

                            html += `</div>`;

                            return html;
                        },
                    },
                    {
                        headerName: 'Co-Treatment Min.',
                        field: 'coTreatMinutes',
                        width: 60,
                        cellClass: (v) => {
                            return v.data.minutesIssue ? 'text-danger-200' : 'text-success';
                        },
                    },
                    {
                        headerName: 'Action',
                        field: 'notify',
                        width: 100,
                        cellStyle: { display: 'flex', 'align-items': 'center' },
                        cellRenderer: ActionsCellRendererComponent,
                        cellRendererParams: () => {
                            return {
                                notifyToCorrect: true,
                            };
                        },
                    },
                ];

                let day = conflict.day;

                const rowData =
                    conflict.dayConflicts.map((dayConflict) => {
                        const { lastName, firstName, therapistType } = dayConflict.therapist;
                        const coTreatmentMinutes = dayConflict.coTreatMinutes;
                        const text = `${lastName}, ${firstName} (${therapistType}) Co-Treatment: `;
                        const minutes = coTreatmentMinutes ? `${coTreatmentMinutes}m` : '';
                        const therapistName = `${dayConflict.therapist.lastName}, ${dayConflict.therapist.firstName} (${dayConflict.therapist.therapistType})`;
                        const disciplines =
                            dayConflict.coTreatDisciplines
                                ?.filter((elem) => elem.discipline != dayConflict.therapyDiscipline)
                                ?.map(
                                    (elem) =>
                                        `${elem.therapistId?.lastName}, ${elem.therapistId?.firstName} - ${elem.therapistId?.therapistType} `
                                ) ?? [];

                        // hot fix: previous day is coming, will implement proper fix from timezone.
                        day = dayConflict.day;

                        return {
                            day: dayConflict.day,
                            patientId: data._id,
                            patientName: data.name,
                            therapistName: therapistName,
                            therapistId: dayConflict.therapist._id,
                            therapistUserId: dayConflict.therapist.userId,
                            note: `Daily Note (${dayConflict.therapyDiscipline})`,
                            dailyNoteId: dayConflict._id,
                            discipline: dayConflict.therapyDiscipline, // note discipline
                            mappingIssue: dayConflict.mappingIssue, // conflict reason
                            minutesIssue: dayConflict.minutesIssue, // conflict reason
                            disciplines: disciplines,
                            coTreatMinutes: dayConflict.coTreatMinutes,
                            notify: true,
                            message: { text, minutes },
                            therapyCase: dayConflict.therapyCase,
                            facilityAd: data.facilityAd,
                        };
                    }) ?? [];

                this.matrix.push({
                    day: this.transformDate(new Date(day)),
                    cols: cols,
                    rowData: rowData,
                });
            });
        }
    }

    close() {
        this.dialogRef.close();
    }
    private transformDate = (date) => {
        if (!Number.isNaN(date)) return this.datePipe.transform(new Date(date), 'MM/dd/YY');
    };
}
