import { setHours } from '@app/helpers/constants';
import { TabItem } from '@app/interfaces';

export const AVAILABILITY_OBJ = {
    duration: {
        effectiveFrom: setHours(new Date('2024-01-01T00:00:00')).toISOString(), // default date when go live is off
        //effectiveFrom: '',
        effectiveThru: '',
    },
    days: {
        //default availability
        sun: {
            available: true,
            time: 480,
        },
        mon: {
            available: true,
            time: 480,
        },
        tue: {
            available: true,
            time: 480,
        },
        wed: {
            available: true,
            time: 480,
        },
        thu: {
            available: true,
            time: 480,
        },
        fri: {
            available: true,
            time: 480,
        },
        sat: {
            available: true,
            time: 480,
        },
    },
    note: '',
    facility: '',
};

export enum TAB_ACTIONS {
    PERSONAL_INFO = 'PERSONAL_INFO',
    FACILITY_ROLES = 'FACILITY_ROLES',
    ACTIVITY_RATES = 'ACTIVITY_RATES',
    ADDITIONAL_DETAILS = 'ADDITIONAL_DETAILS',
    SUBMIT = 'SUBMIT',
}

export const ADD_USER_TABS: TabItem[] = [
    {
        url: TAB_ACTIONS.PERSONAL_INFO,
        title: 'Personal Info',
        highlight: false,
        underline: true,
        isDone: false,
    },
    {
        url: TAB_ACTIONS.FACILITY_ROLES,
        title: 'Facilities & Roles',
        highlight: false,
        underline: false,
        isDone: true,
        unauthorized: true,
    },
    {
        url: TAB_ACTIONS.ACTIVITY_RATES,
        title: 'Activity Rates',
        highlight: false,
        underline: false,
        isDone: true,
        unauthorized: true,
    },
    {
        url: TAB_ACTIONS.ADDITIONAL_DETAILS,
        title: 'Additional Details',
        highlight: false,
        underline: false,
        isDone: true,
        unauthorized: true,
    },
];

export enum BASE_ROLES {
    ADMIN = 'SystemAdmin',
    THERAPIST = 'Therapist',
    PHYSICIAN = 'Physician',
    MANAGER = 'Manager',
    STAFF = 'Staff',
    READ_ONLY = 'Read Only',
    SUPER_ADMIN = 'Super Admin',
    PHYSICIAN_STAFF = 'Physician Staff'
}

export enum USER_TABS_INDEX {
    PERSONAL_INFO = 0,
    FACILITY_ROLES = 1,
    ACTIVITY_RATES = 2,
    ADDITIONAL_DETAILS = 3,
}

export const PHYSICIAN_CREDENTIALS = ['M.D.', 'D.O.', 'PA', 'NP', 'DNP'];

