import { Component, Input, OnChanges } from '@angular/core';
import { ColDef, GridOptions } from 'ag-grid-community';

@Component({
  selector: 'app-pcc-v2-history-list',
  templateUrl: './pcc-v2-history-list.component.html',
  styleUrls: ['./pcc-v2-history-list.component.scss']
})
export class PccV2HistoryListComponent implements OnChanges {
  @Input() columnDefs: ColDef[] = [];
  @Input() rowData: unknown[] = [];
  @Input() listExpanded = false;
  @Input() toggleTitle = '';
  @Input() toggleTitleClass = '';
  @Input() rowClass = '';
  @Input() gridOptions: GridOptions;

  rowClassRules = {
    'disabled': 'data.disabled',
  };

  rowHeight = 60; // default rowHeight is 60 so if we have 3 elements we can set height dynamically like this upon expand 3 * 60 = 180
  gridHeight = '0px';

  ngOnChanges(): void {
    this.rowClass += ' ag-history-row-class'; 
    this.setGridHeightBasedContent();
  }

  toggleList(): void {
    this.listExpanded = !this.listExpanded;
    this.setGridHeightBasedContent();
  }

  private setGridHeightBasedContent(): void {
    if(this.listExpanded && this.rowData?.length) {
      // plus 1 is for header height of grid
      this.gridHeight = this.rowHeight * (this.rowData.length + 1) + 'px';
    } else {
      this.gridHeight = '0px';
    }
  }
}
