import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { AuthService } from '.';
import { map } from 'rxjs/operators';

const baseUrl = `${environment.apiUrl}/charts/goals`;

@Injectable({ providedIn: 'root' })
export class ChartService {
    constructor(private http: HttpClient, private authService: AuthService) {}
    // getGoalsChartData(facilityAdmission: string, gte: Date, lte: Date) {
    //     return this.http
    //         .get<any>(
    //             `${baseUrl}?facilityAdmission=${facilityAdmission}&gte=${gte.toISOString()}&lte=${lte.toISOString()}`
    //         )
    //         .pipe(map((data) => data.data));
    // }
    getGoalsChartData(facilityAdmission: string) {
        return this.http.get<any>(`${baseUrl}?facilityAdmission=${facilityAdmission}`).pipe(map((data) => data.data));
    }
}
