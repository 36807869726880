import { Pipe, PipeTransform } from '@angular/core';
import { convertSecsToMins, placeZerosOnLeft } from '@app/helpers/utils/data.utils';

@Pipe({
    name: 'nInputs',
})
export class NInputsPipe implements PipeTransform {
    transform(value: unknown, ...args: unknown[]): unknown {
        if (!value) {
            return 'N/A';
        }

        if (!args || !args.length) {
            return value;
        }

        const scaleType = args[0];

        if (scaleType !== 'n-inputs') {
            return value;
        }

        // Case where target, CLOF or PLOF are like "2 - Independent" i.e string
        if (isNaN(+value)) {
            return;
        }

        // 'n-inputs' values here...
        const { min, sec } = convertSecsToMins(+value);
        return placeZerosOnLeft(min, 2) + ':' + placeZerosOnLeft(sec, 2);
    }
}
