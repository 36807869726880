export enum Role {
    Manager = 'Manager',
    SystemAdmin = 'System Admin',
    Physician = 'Physician',
    PhysicianStaff = 'Physician Staff', // colworx-ak add role staff role
    Therapist = 'Therapist',
    SuperAdmin = 'Super Admin',
    Staff = 'Staff',
    ReadOnly = 'Read Only',
    Unknown = 'Unknown'
}

export enum AdminPanelRole {
    Manager = 'Manager',
    SystemAdmin = 'System Admin',
    Physician = 'Physician',
    Therapist = 'Therapist',
}
