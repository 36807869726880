import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CheckIconCellRendererComponent } from '@app/data-grid/cell-renderers';
import { isAllowedToCoSign } from '@app/helpers';
import { findTherapyCaseByParamId } from '@app/helpers/utils/therapy-case.utils';
import { FacilityAdmission, RecertificationNote, TherapyAdmission } from '@app/interfaces';
import { DOCUMENTATION_CONSTANTS, Doc_Notes } from '@app/interfaces/documentation';
import { PrintData } from '@app/interfaces/print';
import { Account } from '@app/models';
import { CoSignNoteComponent } from '@app/modules/patients/PCC/pcc-tabs/pcc-documentation/modals/co-sign-note/co-sign-note.component';
import { SignNoteComponent } from '@app/modules/patients/PCC/pcc-tabs/pcc-documentation/modals/sign-note/sign-note.component';
import {
    AuthService,
    GoalService,
    ModalService,
    StandardizedTestsService,
    StoreService,
    ToasterService,
} from '@app/services';
// colworx-sk
import { PPUserManagementService } from '@app/services';
// colworx-sk
import { EvaluationService } from '@app/services/evaluation.service';
import { TimeConfirmationDialogComponent } from '@app/shared/time-confirmation-dialog/time-confirmation-dialog.component';
import { select, Store } from '@ngrx/store';
import { ColDef } from 'ag-grid-community';
import { format, parse } from 'date-fns';
import { cloneDeep } from 'lodash';
import { Subject, takeUntil } from 'rxjs';
import { FunctionalDeficitNoteComponent } from '../../patient-assessment/functional-deficit-note/functional-deficit-note.component';

@Component({
    selector: 'app-recertification',
    templateUrl: './recertification.component.html',
    styleUrls: ['./recertification.component.scss'],
})
export class RecertificationComponent implements OnInit, OnDestroy {
    @Input() printMode = false;
    @Input() printData: PrintData;
    noteTitle = 'Recertification';
    DOCUMENTATION_CONSTANTS = DOCUMENTATION_CONSTANTS;
    showCoSign = false;
    contentEl = document.getElementById('content');
    enableCoSignBtn = false;

    medicalDiagnosis: {
        title: string;
        cols: ColDef[];
        data: Array<object>;
    } = {
        title: 'Medical Diagnosis',
        cols: [
            { field: 'no', headerName: 'No.', width: 60, valueGetter: 'node.rowIndex + 1' },
            { field: 'code', headerName: 'Code', width: 100 },
            { field: 'description', headerName: 'Description', width: 300, wrapText: true, autoHeight: true },
            {
                field: 'pdpmClinicalCategory',
                headerName: 'PDPM Clinical Category',
                width: 300,
                wrapText: true,
                autoHeight: true,
            },
            {
                field: 'onsetDate',
                headerName: 'Onset Date',
                cellRenderer: (params) => {
                    return params.data.onsetDate ? this.transformDate(new Date(params.data.onsetDate)) : '-----';
                },
            },
            { field: 'slp', headerName: 'SLP Comorbidity' },
            { headerName: 'Primary DX', field: 'isPrimary', cellRenderer: CheckIconCellRendererComponent },
        ],
        data: [],
    };
    treatmentDiagnosis: {
        title: string;
        cols: ColDef[];
        data: Array<object>;
    } = {
        title: 'Treatment Diagnosis',
        cols: [
            { field: 'no', headerName: 'No.', width: 100, valueGetter: 'node.rowIndex + 1' },
            { field: 'code', headerName: 'Code', width: 100 },
            {
                field: 'description',
                headerName: 'Description',
                cellClass: ['body-medium', 'line-space'],
                autoHeight: true,
            },
            {
                field: 'onsetDate',
                headerName: 'Onset Date',
                cellRenderer: (params) => {
                    return params.data.onsetDate ? this.transformDate(new Date(params.data.onsetDate)) : '-----';
                },
            },
        ],
        data: [],
    };
    precautions: { title: string; cols: ColDef[]; data: Array<object> } = {
        title: 'Precautions',
        cols: [
            { field: 'no', headerName: 'No.', width: 30, valueGetter: 'node.rowIndex + 1' },
            {
                field: 'description',
                headerName: 'Description',
                cellClass: ['body-medium', 'line-space'],
                autoHeight: true,
            },
        ],
        data: [],
    };
    contraindications: {
        title: string;
        cols: ColDef[];
        data: Array<object>;
    } = {
        title: 'Contraindications',
        cols: [
            { field: 'no', headerName: 'No.', width: 30, valueGetter: 'node.rowIndex + 1' },
            {
                field: 'description',
                headerName: 'Description',
                cellClass: ['body-medium', 'line-space'],
                autoHeight: true,
            },
        ],
        data: [],
    };
    assessmentSinceTreatmentStarted: { title: string; paragraph: string } = {
        title: 'Patient assessment since treatment started',
        paragraph: '',
    };

    impairments: { title: string; cols: ColDef[]; data: any[] } = {
        title: 'Impairments',
        cols: [],
        data: [],
    };

    functionalDeficits: { title: string; cols: ColDef[]; data: any[] } = {
        title: 'Functional Deficits',
        cols: [
            { field: 'no', headerName: 'No.', minWidth: 60, maxWidth: 60, valueGetter: 'node.rowIndex + 1' },
            { field: 'name', headerName: 'Name' },
            {
                field: 'clof',
                headerName: 'Current level of function',
                autoHeight: true,
                wrapText: true,
                cellRenderer: FunctionalDeficitNoteComponent,
                cellRendererParams: (params) => {
                    return {
                        note: params.data.clofNote,
                    };
                },
            },
            {
                field: 'plof',
                headerName: 'Prior level of function',
                autoHeight: true,
                wrapText: true,
                cellRenderer: FunctionalDeficitNoteComponent,
                cellRendererParams: (params) => {
                    return {
                        note: params.data.plofNote,
                    };
                },
            },
        ],
        data: [],
    };

    standardizedTest: { title: string; cols: ColDef[]; data: any[] } = {
        title: 'Standardized test',
        cols: [
            { field: 'no', headerName: 'No.', minWidth: 60, maxWidth: 60, valueGetter: 'node.rowIndex + 1' },
            { field: 'name', headerName: 'Name', width: 200 },
            {
                headerName: 'Score',
                field: 'score',
                width: 40,
                cellRenderer: (params) =>
                    params.data?.totalScore ? `${params.data.score} / ${params.data.totalScore}` : params.data.score,
            },
            {
                field: 'interpretation',
                headerName: 'Interpretation',
                width: 200,
                autoHeight: true,
                cellClass: ['body-medium', 'line-space'],
            },
            {
                field: 'note',
                headerName: 'Note',
                suppressMovable: true,
                cellClass: ['body-medium', 'line-space'],
                autoHeight: true,
            },
        ],
        data: [],
    };

    clinicalImpression: { title: string; paragraph: string } = {
        title: 'Clinical Impression',
        paragraph: '',
    };
    therapyNecessity: { title: string; paragraph: string } = {
        title: 'Therapy Necessity',
        paragraph: '',
    };
    prognosisRehabGoals: { title: string; paragraph: string } = {
        title: 'Prognosis for Achieving Rehab Goals',
        paragraph: '',
    };
    reasonContinuingTreatment: { title: string; paragraph: string } = {
        title: 'Reason for Continuing Treatment',
        paragraph: '',
    };
    updatesTreatment: { title: string; paragraph: string } = {
        title: 'Updates for Treatment',
        paragraph: '',
    };

    goal: any = [];

    cptCodes: { title: string; cols: ColDef[]; data: any[] } = {
        title: 'Skilled Services',
        cols: [
            { headerName: 'No.', width: 30, valueGetter: 'node.rowIndex + 1' },
            {
                field: 'cptCode',
                headerName: 'Code',
                width: 60,
                cellClass: ['body-medium', 'line-space'],
                autoHeight: true,
            },
            { field: 'description', headerName: 'Code Description' },
        ],
        data: [],
    };

    frequencyDuration: { title: string; cols: ColDef[]; data: any[] } = {
        title: 'Frequency and Duration',
        cols: [
            { field: 'frequency', headerName: 'Frequency', width: 30 },
            { field: 'duration', headerName: 'Duration', width: 30 },
            // comment the code fot further notice
            // { field: 'intensity', headerName: 'Intensity', width: 30 },
        ],
        data: [],
    };

    addendum: { title: string; cols: ColDef[]; data: Array<object> } = {
        title: 'Addendum',
        cols: [
            {
                field: 'description',
                width: 900,
                headerName: 'Description',
                autoHeight: true,
                wrapText: true,
            },
            {
                field: 'userNameRole',
                headerName: 'User name & Role',
                cellRenderer: (params) => {
                    const keyData = params.data?.signed?.signedBy[0];
                    return this.userNameFormatter(keyData);
                },
                autoHeight: true,
                width: 250,
            },
            {
                field: 'date',
                headerName: 'Date & Time',
                valueFormatter: (params) => {
                    const completionDate = params.data?.signed?.signDate;
                    const completionTime =
                        ('0' + new Date(completionDate).getHours()).slice(-2) +
                        ':' +
                        ('0' + new Date(completionDate).getMinutes()).slice(-2);
                    return completionDate
                        ? this.transformDate(new Date(completionDate)) + ' ' + completionTime
                        : '-------';
                },
            },
        ],
        data: [],
    };
    certification: { title: string; cols: ColDef[]; data: Array<object> } = {
        title: 'Certification',
        cols: [
            {
                field: 'fromDate',
                headerName: 'From',
                // valueFormatter: (params) => {
                //     return this.transformDate(new Date(params.data.fromDate));
                // },
            },
            {
                field: 'throughDate',
                headerName: 'Through',
                // valueFormatter: (params) => {
                //     debugger;
                //     return this.transformDate(new Date(params.data.throughDate));
                // },
            },
        ],
        data: [{ fromDate: '', throughDate: '' }],
    };

    optionToShow = [
        {
            date: this.dateFormat('2021-03-25'),
            text: 'Green Field dade county',
            changes: ['Caregiver education', 'Vitals Updated', 'Plan of treatment updated'],
        },
        {
            date: this.dateFormat('2021-03-26'),
            text: 'Green Field dade county',
            changes: ['CPT Codes Updated'],
        },
    ];

    dateFormat(date): string {
        const d = parse(date, 'yyyy-MM-dd', new Date());
        return format(d, 'MMMM, dd h:mm a');
    }

    private transformDate = (date) => {
        if (!date) {
            return '';
        }
        return this.datePipe.transform(new Date(date), 'MM/dd/yyyy');
    };
    userNameFormatter(user: any) {
        return user?.roles
            ? `<p class="name mb-0" >${user.lastName + ', ' + user.firstName}</p><span>${user.roles.map(
                  (role) => role.name
              )}</span>`
            : `<p class="name mb-0" >${
                  this.user.user.lastName + ', ' + this.user.user.firstName
              }</p><span>${this.user.user.roles.map((role) => role.name)}</span>`;
    }
    private readonly onDestroy = new Subject<void>();
    ngOnDestroy(): void {
        this.onDestroy.next();
        this.contentEl.removeEventListener('scroll', this.divScroll, true);
    }
    admissionDischarged = false;
    currentAdmission: TherapyAdmission;
    therapyAdmissions: TherapyAdmission[];
    therapyDiscipline: string;
    medicalDiagnosisData: any[] = [];
    recertificationNoteId: string;
    recertificationNote: RecertificationNote;
    user: any;
    currentAddendum: string;
    loadingObs: any;
    isDifferentDisciplineTherapist = false;
    therapistDiscipline: any;
    currentDocSigned = false;
    PocDoc = false;
    constructor(
        private userManagementService:PPUserManagementService,
        private route: ActivatedRoute,
        private storeService: StoreService,
        private authService: AuthService,
        private datePipe: DatePipe,
        private modalService: ModalService,
        private toasterService: ToasterService,
        private standardizedTestsService: StandardizedTestsService,
        private store: Store<{
            therapyAdmission: TherapyAdmission[];
            facilityAdmission: FacilityAdmission;
            user: Account;
        }>,
        private goalService: GoalService,
        private evaluationService: EvaluationService
    ) {
        this.loadingObs = this.storeService.getLoadingState();
        this.store
            .select('facilityAdmission')
            .pipe(takeUntil(this.onDestroy))
            .subscribe((data) => {
                if (data.patientDischarge) {
                    this.admissionDischarged = true;
                }
            });
        this.route.queryParamMap.subscribe({
            next: (params) => {
                this.therapyDiscipline = params.get('discipline');
                this.recertificationNoteId = params.get('doc');
                  // colworx-sk
                  const pocDocParam = params.get('pocDocuments');
                  this.PocDoc = pocDocParam === 'true';
                  if(this.PocDoc){
                      this.getEditHistory(this.recertificationNoteId)
                  }
                 // colworx-sk
            },
        });
        this.store.pipe(select('user')).subscribe((user) => {
            if (user) {
                this.user = user;
            }
        });
        this.impairments.cols = [
            ...this.evaluationService
                .getImpairmentCols()
                .filter(
                    (elem) =>
                        elem.headerName.toLowerCase() !== 'actions' && elem.headerName.toLowerCase() !== 'make a goal'
                ),
        ];
    }

    ngOnInit(): void {
        this.contentEl.addEventListener('scroll', this.divScroll, true);
        if (this.printMode) {
            const { discipline, id } = this.printData;
            this.therapyDiscipline = discipline;
            this.recertificationNoteId = id;
        }
        this.store
            .select('therapyAdmission')
            .pipe(takeUntil(this.onDestroy))
            .subscribe((data) => {
                this.currentAddendum = '';
                this.therapyAdmissions = data;

                const queryString = this.route.snapshot.queryParams;
                this.currentAdmission = findTherapyCaseByParamId(data, queryString['therapy-case']);
                this.therapyDiscipline ||= this.currentAdmission?.therapyDiscipline;
                this.therapyDiscipline ||= 'PT';
                this.currentAdmission = cloneDeep(this.currentAdmission);

                if (this.currentAdmission) {
                    this.recertificationNote = this.currentAdmission.documentation.recertificationNote.find(
                        (recertificationNote) => recertificationNote.id == this.recertificationNoteId
                    );
                    this.currentDocSigned = !!this.recertificationNote?.signed?.signDate;
                    this.showCoSign = isAllowedToCoSign(
                        this.currentAdmission,
                        this.user,
                        this.recertificationNote.coSigned
                    );
                    this.treatmentDiagnosis.data = this.recertificationNote?.patientHistory?.treatmentDiagnosis ?? [];
                    if (this.recertificationNote) {
                        this.medicalDiagnosis.data = this.recertificationNote?.patientHistory.medicalDiagnosis;
                    }
                    this.precautions.data = this.recertificationNote?.patientHistory?.precautions;
                    this.contraindications.data = this.recertificationNote?.patientHistory?.contraindications;

                    this.impairments.data = this.recertificationNote?.objectiveAssessment.impairments;

                    this.functionalDeficits.data = this.buildFunctionalDeficits(
                        this.recertificationNote?.objectiveAssessment.functionalDeficits
                    );

                    this.standardizedTest.data = this.recertificationNote?.objectiveAssessment.standardizedTests;
                    this.standardizedTest.data = this.standardizedTestsService.addTotalsInStandardizedTestsData(
                        this.standardizedTest.data
                    );
                    this.clinicalImpression.paragraph = this.recertificationNote?.assessment.clinicalImpression;

                    this.therapyNecessity.paragraph = this.recertificationNote?.assessment.therapyNecessity;
                    this.prognosisRehabGoals.paragraph =
                        this.recertificationNote.assessment.prognosisForAchievingRehabGoals;
                    this.reasonContinuingTreatment.paragraph =
                        this.recertificationNote.assessment.reasonForContinuingTreatment;

                    this.updatesTreatment.paragraph = this.recertificationNote.assessment.updateToTreatment;
                    this.cptCodes.data = this.recertificationNote?.plan.skilledServices;
                    this.frequencyDuration.data = this.viewFrequencyAndDuration(
                        this.recertificationNote.plan.frequencyAndDuration
                    );
                    this.certification.data = [this.recertificationNote.plan.certification];
                    this.addendum.data = this.recertificationNote?.addendum ? this.recertificationNote.addendum : [];
                    this.assessmentSinceTreatmentStarted.paragraph =
                        this.recertificationNote?.subjectiveAssessment.patientAssessmentSinceTreatmentStarted;
                    this.goal = this.goalService.buildGoal(this.recertificationNote.plan.goals);
                    // this.currentUpdatesTreatement.paragraph = this.progressNote.plan;
                }
            });
        if (this.authService.isTherapist) {
            this.therapistDiscipline = this.authService.accountValue.user.therapist.discipline;
            if (this.therapistDiscipline.replace('A', '') !== this.therapyDiscipline) {
                this.isDifferentDisciplineTherapist = true;
            }
        } else if (this.authService.isManager || this.authService.isSuperAdmin) this.isDifferentDisciplineTherapist = true;
    }

    onCoSignClicked() {
        if (this.admissionDischarged) {
            const data = {
                heading: 'Confirmation',
                icon: '',
                btn1Text: 'Cancel',
                btn2Text: 'Continue',
                lowerBodyValue: '',
                iconFilled: true,
                bodyText: `This patient has a facility discharge, would you like to continue?`,
            };
            const dialogRef = this.modalService.open(TimeConfirmationDialogComponent, {
                data,
            });
            dialogRef.afterClosed().subscribe((res) => {
                if (res) {
                    this.modalService.open(CoSignNoteComponent, {
                        data: {
                            documentType: Doc_Notes.Re_Cert,
                            heading: 'Recertification Note',
                            document: this.recertificationNote,
                        },
                    });
                }
            });
        } else {
            this.modalService.open(CoSignNoteComponent, {
                data: {
                    documentType: Doc_Notes.Re_Cert,
                    heading: 'Recertification Note',
                    document: this.recertificationNote,
                },
            });
        }
    }
    divScroll = (event): void => {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 500)
            this.enableCoSignBtn = true;
    };

    updateAddendum(description) {
        this.currentAddendum = description;
    }

    padTo2Digits(digit: number) {
        return digit.toString().padStart(2, '0');
    }

    buildFunctionalDeficits(deficits) {
        const functionalDeficits = [];
        for (let index = 0; index < deficits?.length; index++) {
            const deficit = deficits[index];
            functionalDeficits.push({
                no: index + 1,
                name: deficit.name,
                clof: deficit.clof.levelOfFunction,
                clofNote: deficit.clof.note,
                plof: deficit.plof.levelOfFunction,
                plofNote: deficit.plof.note,
            });
        }

        return functionalDeficits;
    }

    viewFrequencyAndDuration(frequencyAndDuration) {
        const frequencyAndDurationList = [];
        let index = 0;
        if (Array.isArray(frequencyAndDuration) && frequencyAndDuration.length) {
            for (const data of frequencyAndDuration) {
                index++;
                frequencyAndDurationList.push({
                    no: index,
                    range: data.range ? 'Yes' : 'No',
                    frequency: data.frequencyFrom, //data.range ? `${data.frequencyFrom} to ${data.frequencyTo}` : `${data.frequencyFrom} `,
                    duration: `${data.duration + ' ' + data.durationType}`,
                    intensity: data.intensity,
                });
            }
        } else {
            frequencyAndDurationList.push({ range: '', frequency: '', duration: '', intensity: '' });
        }
        return frequencyAndDurationList;
    }

    addAddendumNote() {
        if (this.authService.isManager || this.authService.isTherapist) {
            if (this.currentAddendum) {
                const addendumNote = {
                    description: this.currentAddendum,
                };
                this.modalService.open(SignNoteComponent, {
                    data: {
                        documentType: 'recertificationNote',
                        addendumNote,
                        heading: 'Addendum Note',
                        recertNote: this.recertificationNoteId,
                    },
                });
            } else {
                this.toasterService.show({ title: 'Failure', body: 'Add description', type: 'error' });
            }
        }
    }

    public tooltipCheck = () => {
        if (this.admissionDischarged) {
            return 'Patient Discharged';
        } else if (!this.authService.isManager && !this.authService.isTherapist) {
            return 'You are not authorized to add addendum';
        } else if (this.isDifferentDisciplineTherapist) {
            return '';
        } else {
            return 'Add Addendum Note';
        }
    };

       //colworx-sk
       @Input() evalHistorySidePoc: boolean;
       changeHistoryPoc: any = [];
       openEvalHistoryPoc() {
           this.evalHistorySidePoc = true;
       }
   
       closeHistorySideBarPoc(event: boolean) {
           this.evalHistorySidePoc = event;
       }
       getEditHistory(docId: any) {
           this.userManagementService.getEvaluationHistory(docId).subscribe((res: any) => {
               this.changeHistoryPoc = res.data;
           });
       }
}
