import { DatePipe } from '@angular/common';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

//This function convert NgbDateStruct to string
export const toDateString = (date: NgbDateStruct, format: string) => {
    const tempDate = date
        ? new DatePipe('en-US').transform(new Date(Date.UTC(date.year, date.month - 1, date.day, 0, 0, 0)), format)
        : null;
    return tempDate;
};
