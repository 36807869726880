export enum MODES {
    SEVEN_DAYS_LOOK_BACK = '7 Days Look Back',
    TOTAL_THERAPY_MINUTES = 'Total Therapy Minutes',
    ARD_MINUTES = 'ARD Minutes',
}

export enum COLUMN_NAME {
    ADMISSION_ARD = 'ADMISSION_ARD',
    PENDING_CMG_SCORES = 'PENDING_CMG_SCORES',
    PLACEHOLDER_CMGS = 'PLACEHOLDER_CMGS',
    INTERIM_PAYMENT_ASSESSMENT = 'INTERIM_PAYMENT_ASSESSMENT',
}

export enum PPS_EVENT {
    ADMISSION = 'admission',
    IPA = 'IPA',
}
