import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-base-modal-card',
    templateUrl: './base-modal-card.component.html',
    styleUrls: ['./base-modal-card.component.scss'],
})
export class BaseModalCardComponent {
    @Input() heading: string;
    @Input() subHeading: string;
    @Input() headingClasses = '';
    @Input() crossIconClasses = '';
    @Input() placeholder = 'Type Here';
    @Input() showTooltip = false;
    @Input() minHeight: string;
    @Input() showFooterDivider = true;
    @Input() wrapperClass = '';
    @Input() paddingZero = '';
    @Input() inputStyle: string;
    @Input() showFooter = true;
    @Input() showCross = true;
    @Input() isHeaderEditable = false;
    @Output() close: EventEmitter<void> = new EventEmitter();
    @Output() headingEmitter: EventEmitter<string> = new EventEmitter();

    updateTitle(event: KeyboardEvent) {
        const inputElement = event.target as HTMLInputElement;
        this.heading = inputElement.value;
        this.headingEmitter.emit(this.heading);
    }

    onClose() {
        this.close.emit();
    }
}
