import { TableDataBlock } from '@app/interfaces';
import { format } from 'date-fns';
import { GOAL_CONSTANTS } from '@app/interfaces/documentation';

export const DOC_PRINT_ROUTE = 'print/document';

export const DOC_KEY_VALUE = {
    patientAssessmentSinceLastTreatment: "Patient's self-report of their current status",
    vitals: 'Vitals',
    coTreatment: 'Other Treatment Minutes',
    responseToTreatment: 'Response to treatment',
    planForNextTreatment: 'Plan for next treatment',
    anticipatedDischargeDate: 'Anticipated Discharge Date',
    overallPlanAndUpdates: 'Overall plan and updates to treatment',
    addendum: 'Addendum',
    changeHistoryPrint: 'Edit History',
    signDate: 'Completion Date',
    contraindications: 'Contraindications',
    medicalDiagnosis: 'Medical Diagnosis',
    treatmentDiagnosis: 'Treatment Diagnosis',
    pastMedicalHistory: 'Past Medical History',
    priorHospitalizationDates: 'Prior Hospitalization Dates',
    reasonForNotCreatingPoc: 'Reason for not creating POC',
    precautions: 'Precautions',
    clinicalImpression: 'Clinical Impression',
    impairments: 'Impairments',
    functionalDeficits: 'Functional Deficits',
    standardizedTests: 'Standardized Tests',
    skilledServices: 'Skilled Services',
    frequencyAndDuration: 'Frequency and Duration',
    therapyNecessity: 'Therapy Necessity',
    prognosisForAchievingRehabGoals: 'Prognosis for achieving rehab goals',
    updateToTreatment: 'Updates to treatment',
    dischargePlan: 'Anticipated Discharge Plan',
    dischargeDestination: 'Anticipated Discharge Destination',
    reasonForContinuingTreatment: 'Overall plan and updates to treatment',
    reasonForUpdate: 'Reason for update',
    reasonForReferral: 'Reason for referral',
    chiefComplaint: 'Chief Complaint',
    patientGoals: 'Patient goals',
    priorLevelOfFunction: 'Prior level of function',
    socialDeterminantsOfHealth: 'Social determinants of health',
    environmentalFactors: 'Environmental factors',
    previousTherapy: 'Previous therapy',
    caregiverEducation: 'Caregiver Education',
    interventionApproach: 'Intervention Approach',
    evaluationType: 'Eval Type',
    medications: 'Medications',
    endOfCareReason: 'End of Care Reason',
    sectionGg: 'Section GG',
    description: 'Note Addendum',
    refused: 'Reason for Refused',
    withheld: 'Reason for Withheld',
};

export enum REPORT_GRIDS {
    HEADER = 'HeaderGrid',
    NON_HEADER = 'NonHeaderGrid',
    TREE = 'TreeGrid',
    DYNAMIC = 'DynamicGrid',
}

export enum STATUS_MAPPER {
    ['On Going'] = 'by',
    ['Achieved'] = 'achieved on',
    ['Not Achieved'] = 'not achieved by',
    ['Discontinued'] = 'discontinued on',
}

export enum SECTION_WIDTH {
    impairment = '30%',
    functionalDeficits = '90%',
    standardizedTests = '90%',
}

export enum CONSTANTS {
    PATIENT = 'patient',
    FACILITY_ADMISSION = 'facilityAdmission',
    THERAPY_ADMISSION = 'therapyAdmission',
    VALUE = 'value',
    ADDENDUM = 'Addendum',
    CHANGE_HISTORY_PRINT = 'changeHistoryPrint',
    DAILY = 'Daily',
    PROGRESS = 'Progress',
    RECERTIFICATION = 'Recertification',
    UPOC = 'UPOC',
    EVALUATION = 'Evaluation',
    DISCHARGE = 'Discharge',
    VITALS = 'vitals',
    PLOF = 'plof',
    CLOF = 'clof',
    LTG = 'ltg',
    STG = 'stg',
    EVAL_TYPE = 'evalType',
    STANDARDIZED_TESTS = 'standardizedTests',
    SECTION_GG = 'sectionGg',
    FUNCTIONAL_DEFICITS = 'functionalDeficits',
    IMPAIRMENT = 'impairment',
    REASON_FOR_DISCONTINUE = 'reasonForDiscontinue',
    DISCONTINUED = 'Discontinued',
    NA = 'N/A',
    ICD_10_CODES = 'icd10Codes',
    DATE_FORMATE = 'MM/dd/yyyy',
    TIME_FORMATE = 'HH:mm',
    DESCRIPTION = 'description',
    REFUSED = 'refused',
    WITHHELD = 'withheld',
    OTHER_TREATMENT_MINUTES = 'otherTreatmentMinutes',
}

export const HEADERS = {
    DIAGNOSIS: [
        {
            key: 'code',
            value: 'Code.',
            width: '13%',
        },
        {
            key: 'description',
            value: 'Description',
            width: '70%',
        },
        {
            key: 'onsetDate',
            value: 'Onset',
            width: '17%',
        },
    ],
    MEDICAL_HISTORY: [
        {
            key: 'no',
            value: 'No.',
            width: '13%',
        },
        {
            key: 'description',
            value: 'Description',
            width: '87%',
        },
    ],
    HOSPITALIZATION_DATES: [
        {
            key: 'no',
            value: 'No.',
            width: '13%',
        },
        {
            key: 'description',
            value: 'Description',
            width: '67%',
        },
        {
            key: 'admissionDate',
            value: 'Admission Date',
            width: '15%',
        },
        {
            key: 'dischargeDate',
            value: 'Discharge Date',
            width: '15%',
        },
    ],
    SKILLED_SERVICES: [
        {
            key: 'no',
            value: 'No.',
            width: '10%',
        },
        {
            key: 'code',
            value: 'Code',
            width: '15%',
        },
        {
            key: 'description',
            value: 'Description',
            width: '75%',
        },
    ],
    CHANGE_HISTORY_PRINT: [
        {
            key: 'username',
            value: 'User',
            width: '30%',
        },
        {
            key: 'sectionsEdited',
            value: 'Sections Edited',
            width: '40%',
        },
        {
            key: 'timeDate',
            value: 'Time & Date',
            width: '30%',
        },
    ],
};

export const NOTES_SEQUENCE = [
    'Evaluation',
    'Daily Note',
    'Progress Note',
    'Updated Plan of Care',
    'Recertification',
    'Discharge Note',
    'Addendum',
];

export const GRID_CONSTANTS = {
    LTG: [
        {
            key: 'ltg',
            value: 'LTG',
            width: '10%',
            isBold: true,
            isRightBordered: false,
            paddingLeft: '1vw',
        },
        {
            key: 'plof',
            value: 'PLOF',
            width: '10%',
            isBold: true,
            isRightBordered: false,
            paddingLeft: '1vw',
        },
        {
            key: 'clof',
            value: 'CLOF',
            width: '10%',
            isBold: true,
            isRightBordered: false,
            paddingLeft: '1vw',
        },
        {
            key: 'purpose',
            value: 'Purpose',
            width: '10%',
            isBold: true,
            isRightBordered: false,
            paddingLeft: '1vw',
        },
        {
            key: 'reasonForDiscontinue',
            value: 'Reason For Discontinue',
            width: '20%',
            isBold: true,
            isRightBordered: false,
            paddingLeft: '1vw',
        },
    ],
    STG: [
        {
            key: 'stg',
            value: 'STG',
            width: '10%',
            isBold: true,
            isRightBordered: false,
            paddingLeft: '1vw',
        },
        {
            key: 'plof',
            value: 'PLOF',
            width: '10%',
            isBold: true,
            isRightBordered: false,
            paddingLeft: '1vw',
        },
        {
            key: 'clof',
            value: 'CLOF',
            width: '10%',
            isBold: true,
            isRightBordered: false,
            paddingLeft: '1vw',
        },
        {
            key: 'purpose',
            value: 'Purpose',
            width: '10%',
            isBold: true,
            isRightBordered: false,
            paddingLeft: '1vw',
        },
        {
            key: 'reasonForDiscontinue',
            value: 'Reason For Discontinue',
            width: '20%',
            isBold: true,
            isRightBordered: false,
            paddingLeft: '1vw',
        },
    ],
    VITALS: [
        {
            key: 'respiratoryRate',
            value: 'Respiratory Rate',
            width: '20%',
            isBold: true,
            isRightBordered: true,
            paddingLeft: '1vw',
        },
        {
            key: 'pulseRate',
            value: 'Pulse Rate',
            width: '12%',
            isBold: true,
            isRightBordered: true,
            paddingLeft: '1vw',
        },
        {
            key: 'bloodPressure',
            value: 'Blood Pressure',
            width: '20%',
            isBold: true,
            isRightBordered: true,
            paddingLeft: '1vw',
        },
        {
            key: 'o2Saturation',
            value: 'O2 Saturation',
            width: '15%',
            isBold: true,
            isRightBordered: true,
            paddingLeft: '1vw',
        },
        {
            key: 'temperature',
            value: 'Temperature',
            width: '20%',
            isBold: true,
            isRightBordered: true,
            paddingLeft: '1vw',
        },
        {
            key: 'note',
            value: 'Notes',
            width: '20%',
            isBold: true,
            isRightBordered: true,
            paddingLeft: '1vw',
        },
    ],
    IMPAIRMENT: [
        {
            key: 'name',
            value: 'Name',
            width: '10%',
            isBold: true,
            isRightBordered: true,
            paddingLeft: '1vw',
        },
        {
            key: 'value',
            value: 'Value',
            width: '10%',
            isBold: true,
            isRightBordered: false,
            paddingLeft: '1vw',
        },
        {
            key: 'note',
            value: 'Note',
            width: '10%',
            isBold: true,
            isRightBordered: true,
            paddingLeft: '1vw',
        },
    ],
    FUNCTIONAL_DEFICITS: [
        {
            key: 'name',
            value: 'Name',
            width: '10%',
            isBold: true,
            isRightBordered: true,
            paddingLeft: '1vw',
        },
        {
            key: 'clof',
            value: 'CLOF',
            width: '10%',
            isBold: true,
            isRightBordered: true,
            paddingLeft: '1vw',
        },
        {
            key: 'clofAssistiveDevice',
            value: 'Assistive/Adaptive Devices',
            width: '25%',
            isBold: true,
            isRightBordered: true,
            paddingLeft: '1vw',
        },
        {
            key: 'clofNote',
            value: 'Note',
            width: '10%',
            isBold: true,
            isRightBordered: true,
            paddingLeft: '1vw',
        },
        {
            key: 'plof',
            value: 'PLOF',
            width: '10%',
            isBold: true,
            isRightBordered: true,
            paddingLeft: '1vw',
        },
        {
            key: 'plofAssistiveDevice',
            value: 'Assistive/Adaptive Devices',
            width: '25%',
            isBold: true,
            isRightBordered: true,
            paddingLeft: '1vw',
        },
        {
            key: 'plofNote',
            value: 'Note',
            width: '10%',
            isBold: true,
            isRightBordered: true,
            paddingLeft: '1vw',
        },
    ],
    SECTION_GG: [
        {
            key: 'sectionGg',
            value: '',
            width: '0%',
            isBold: false,
            isRightBordered: false,
            paddingLeft: '0',
        },
        {
            key: 'score',
            value: 'Score',
            width: '10%',
            isBold: true,
            isRightBordered: true,
            paddingLeft: '1vw',
        },
        {
            key: 'assistiveDevices',
            value: 'Assistive/Adaptive Devices',
            width: '25%',
            isBold: true,
            isRightBordered: true,
            paddingLeft: '1vw',
        },
    ],
    STANDARDIZED_TESTS: [
        {
            key: 'name',
            value: 'Name',
            width: '10%',
            isBold: true,
            isRightBordered: true,
            paddingLeft: '1vw',
        },
        {
            key: 'score',
            value: 'Score',
            width: '10%',
            isBold: true,
            isRightBordered: true,
            paddingLeft: '1vw',
        },
        {
            key: 'interpretation',
            value: 'Interpretation',
            width: '20%',
            isBold: true,
            isRightBordered: true,
            paddingLeft: '1vw',
        },
        {
            key: 'note',
            value: 'Note',
            width: '10%',
            isBold: true,
            isRightBordered: true,
            paddingLeft: '1vw',
        },
    ],
    FREQUENCY_AND_DURATION: [
        {
            key: 'frequency',
            value: 'Frequency',
            width: '13%',
            isBold: true,
            isRightBordered: true,
            paddingLeft: '1vw',
        },
        {
            key: 'duration',
            value: 'Duration',
            width: '13%',
            isBold: true,
            isRightBordered: true,
            paddingLeft: '1vw',
        },
        {
            key: 'intensity',
            value: 'Intensity',
            width: '10%',
            isBold: true,
            isRightBordered: true,
            paddingLeft: '1vw',
        },
        {
            key: 'certificationFrom',
            value: 'Certification From',
            width: '20%',
            isBold: true,
            isRightBordered: true,
            paddingLeft: '1vw',
        },
        {
            key: 'certificationThrough',
            value: 'Certification Through',
            width: '22%',
            isBold: true,
            isRightBordered: true,
            paddingLeft: '1vw',
        },
    ],
    EVALUATION_TYPE: [
        {
            key: 'code',
            value: 'Code:',
            width: '5%',
            isBold: true,
            isRightBordered: false,
            paddingLeft: '1vw',
        },
        {
            key: 'eval',
            value: '',
            width: '0%',
            isBold: false,
            isRightBordered: false,
            paddingLeft: '0',
        },
        {
            key: 'evaluationTime',
            value: 'Time:',
            width: '5%',
            isBold: true,
            isRightBordered: false,
            paddingLeft: '1vw',
        },
        {
            key: 'description',
            value: 'Description',
            width: '20%',
            isBold: true,
            isRightBordered: false,
            paddingLeft: '1vw',
        },
    ],
    OTHER_TREATMENT_MINUTES: [
        {
            key: 'coTreat',
            value: 'Co-treat:',
            width: '15%',
            isBold: true,
            isRightBordered: true,
            paddingLeft: '1vw',
        },
        {
            key: 'coTreatTime',
            value: 'Time',
            width: '5%',
            isBold: true,
            isRightBordered: false,
            paddingLeft: '1vw',
        },
        {
            key: 'coTreatingTherapist',
            value: 'Co-Treating Therapists:',
            width: '20%',
            isBold: true,
            isRightBordered: false,
            paddingLeft: '1vw',
        },
        {
            key: 'empty',
            value: '',
            width: '15%',
            isBold: true,
            isRightBordered: true,
            paddingLeft: '1vw',
        },
        {
            key: 'justification',
            value: 'Justification',
            width: '20%',
            isBold: true,
            isRightBordered: false,
            paddingLeft: '1vw',
        },
        {
            key: 'concurrent',
            value: 'Concurrent',
            width: '15%',
            isBold: true,
            isRightBordered: true,
            paddingLeft: '1vw',
        },
        {
            key: 'concurrentTime',
            value: 'Time',
            width: '5%',
            isBold: true,
            isRightBordered: false,
            paddingLeft: '1vw',
        },
    ],
    ADDENDUM: [
        {
            key: 'usernameWithRole',
            value: 'Username & Role',
            width: '17%',
            isBold: true,
            isRightBordered: false,
            paddingLeft: '1vw',
        },
        {
            key: 'date',
            value: 'Date',
            width: '5%',
            isBold: true,
            isRightBordered: false,
            paddingLeft: '1vw',
        },
        {
            key: 'time',
            value: 'Time',
            width: '5%',
            isBold: true,
            isRightBordered: false,
            paddingLeft: '1vw',
        },
        {
            key: 'description',
            value: 'Description',
            width: '10%',
            isBold: true,
            isRightBordered: false,
            paddingLeft: '1vw',
        },
    ],
    CHANGE_HISTORY_PRINT: [
        {
            key: 'username',
            value: 'User',
            width: '30%',
            isBold: true,
            isRightBordered: false,
            paddingLeft: '1vw',
        },
        {
            key: 'sectionsEdited',
            value: 'Sections Edited',
            width: '40%',
            isBold: true,
            isRightBordered: false,
            paddingLeft: '1vw',
        },
        {
            key: 'timeDate',
            value: 'Time & Date',
            width: '30%',
            isBold: true,
            isRightBordered: false,
            paddingLeft: '1vw',
        },
    ],
};
// Function to map target date or completion date in the print view of notes
export const dateMapper = (data) => {
    if (
        [GOAL_CONSTANTS.ACHIEVED, GOAL_CONSTANTS.DISCONTINUED, GOAL_CONSTANTS.NOT_ACHIEVED].includes(
            data?.status?.description
        )
    ) {
        return data.status?.dateOfCompletion
            ? format(new Date(data.status.dateOfCompletion), CONSTANTS.DATE_FORMATE)
            : '';
    } else {
        const targetDate = data?.updatedTargetDate || data?.targetDate;
        return targetDate ? format(new Date(targetDate), CONSTANTS.DATE_FORMATE) : '';
    }
};

export const gridValueMapper = (key: string, data, section?: string): TableDataBlock => {
    const mapper: TableDataBlock = {
        value: '',
        width: '',
    };
    switch (key) {
        case 'ltg':
        case 'stg':
            mapper.value = data.goal
                ? `<span>
                    <b>${data.goal}</b> 
                    (${data?.target || ''} ${
                      data?.assistiveDevice && data.assistiveDevice.toLowerCase() !== 'none'
                          ? 'using <b>' + data.assistiveDevice + '</b>'
                          : ''
                  }) 
                    ${data?.status?.description ? STATUS_MAPPER[data.status.description] : ''} 
                    ${'<b>' + dateMapper(data) + '</b>'}
                </span>`
                : '';
            mapper.width = '90%';
            mapper.isBold = false;
            mapper.isRightBordered = false;
            mapper.paddingLeft = '1vw';
            break;
        case 'plof':
            mapper.value = `<span>${data?.plof?.levelOfFunction || CONSTANTS.NA}</span>`;
            mapper.width = !section || section === CONSTANTS.FUNCTIONAL_DEFICITS ? '40%' : '85%';
            mapper.isBold = false;
            mapper.isRightBordered = false;
            mapper.paddingLeft = '1vw';
            break;
        case 'clof':
            mapper.value = `<span>${data?.clof?.levelOfFunction || CONSTANTS.NA}</span>`;
            mapper.width = !section || section === CONSTANTS.FUNCTIONAL_DEFICITS ? '40%' : '85%';
            mapper.isBold = false;
            mapper.isRightBordered = false;
            mapper.paddingLeft = '1vw';
            break;
        case 'clofNote':
            mapper.value = `<span>${data?.clof?.note || CONSTANTS.NA}</span>`;
            mapper.width = !section || section === CONSTANTS.FUNCTIONAL_DEFICITS ? '85%' : '85%';
            mapper.isBold = false;
            mapper.isRightBordered = false;
            mapper.paddingLeft = '1vw';
            break;
        case 'plofNote':
            mapper.value = `<span>${data?.plof?.note || CONSTANTS.NA}</span>`;
            mapper.width = !section || section === CONSTANTS.FUNCTIONAL_DEFICITS ? '85%' : '85%';
            mapper.isBold = false;
            mapper.isRightBordered = false;
            mapper.paddingLeft = '1vw';
            break;
        case 'purpose':
            mapper.value = `<span>${data?.goalPurpose || CONSTANTS.NA}</span>`;
            mapper.width = '80%';
            mapper.isBold = false;
            mapper.isRightBordered = false;
            mapper.paddingLeft = '1vw';
            break;
        case 'reasonForDiscontinue':
            mapper.value = `<span>${data?.status?.reason || CONSTANTS.NA}</span>`;
            mapper.width = '80%';
            mapper.isBold = false;
            mapper.isRightBordered = false;
            mapper.paddingLeft = '1vw';
            break;
        case 'score':
            mapper.value = `<span>${
                section === CONSTANTS.SECTION_GG ? data?.clof?.ggScore ?? CONSTANTS.NA : data?.score || CONSTANTS.NA
            }</span>`;
            if (section === CONSTANTS.STANDARDIZED_TESTS)
                mapper.value = `<span>${data.totalScore ? data?.score + ' / ' + data?.totalScore : data?.score}</span>`;
            mapper.width = !section ? '10%' : section === CONSTANTS.SECTION_GG ? '40%' : '80%';
            mapper.isBold = false;
            mapper.isRightBordered = true;
            mapper.paddingLeft = '1vw';
            break;
        case 'interpretation':
            mapper.value = `<span>${data?.interpretation || CONSTANTS.NA}</span>`;
            mapper.width = section ? '80%' : '60%';
            mapper.isBold = false;
            mapper.isRightBordered = false;
            mapper.paddingLeft = '1vw';
            break;
        case 'note':
        case 'description':
            mapper.value = `<span>${data ? data[key] : CONSTANTS.NA}</span>`;
            mapper.width = section === CONSTANTS.EVAL_TYPE || section === CONSTANTS.VITALS ? '80%' : '90%';
            mapper.isBold = false;
            mapper.isRightBordered = false;
            mapper.paddingLeft = '1vw';
            break;
        case 'frequency':
            mapper.value = `<span>${data?.frequencyFrom || CONSTANTS.NA} per week</span>`;
            mapper.width = '20%';
            mapper.isBold = false;
            mapper.isRightBordered = true;
            mapper.paddingLeft = '1vw';
            break;
        case 'intensity':
            mapper.value = `<span>${data?.intensity || CONSTANTS.NA}</span>`;
            mapper.width = '24%';
            mapper.isBold = false;
            mapper.isRightBordered = true;
            mapper.paddingLeft = '1vw';
            break;
        case 'duration':
            mapper.value = `<span>${data?.duration || ''} ${data?.durationType || ''}</span>`;
            mapper.width = '20%';
            mapper.isBold = false;
            mapper.isRightBordered = true;
            mapper.paddingLeft = '1vw';
            break;
        case 'certificationFrom':
            mapper.value = `<span>${data?.fromDate || CONSTANTS.NA}</span>`;
            mapper.width = '15%';
            mapper.isBold = false;
            mapper.isRightBordered = false;
            mapper.paddingLeft = '1vw';
            break;
        case 'certificationThrough':
            mapper.value = `<span>${data?.throughDate || CONSTANTS.NA}</span>`;
            mapper.width = '43%';
            mapper.isBold = false;
            mapper.isRightBordered = true;
            mapper.paddingLeft = '1vw';
            break;
        case 'name':
            mapper.value = `<span>${
                data?.impairmentName
                    ? data.impairmentName + (data?.subSection ? ', ' + data.subSection : '')
                    : data.name || CONSTANTS.NA
            }</span>`;
            mapper.width = SECTION_WIDTH[section];
            mapper.isBold = false;
            mapper.isRightBordered = false;
            mapper.paddingLeft = '1vw';
            break;
        case 'value':
            mapper.value = `<span>${
                data?.fieldValue && data?.fieldValue?.length && data?.fieldValue[0]?.text
                    ? data.fieldValue.map((v) => '- ' + v.text).join('<br />')
                    : data?.fieldValue || CONSTANTS.NA
            }</span>`;
            mapper.width = '50%';
            mapper.isBold = false;
            mapper.isRightBordered = false;
            mapper.paddingLeft = '1vw';
            break;
        case 'code':
            mapper.value = `<span>${
                data?.evalType && data?.evalType?.split('-').length ? data.evalType.split('-')[0] : CONSTANTS.NA
            }</span>`;
            mapper.width = '10%';
            mapper.isBold = false;
            mapper.isRightBordered = true;
            mapper.paddingLeft = '1vw';
            break;
        case 'eval':
            mapper.value = `<span>${
                data?.evalType && data?.evalType?.split(' - ').length > 1 ? data.evalType.split(' - ')[1] : CONSTANTS.NA
            }</span>`;
            mapper.width = '70%';
            mapper.isBold = false;
            mapper.isRightBordered = true;
            mapper.paddingLeft = '1vw';
            break;
        case 'evaluationTime':
            mapper.value = `<span>${data?.minutes && data.evalOnly ? `${data.minutes} mins` : CONSTANTS.NA}</span>`;
            mapper.width = '10%';
            mapper.isBold = false;
            mapper.isRightBordered = true;
            mapper.paddingLeft = '1vw';
            break;
        case 'sectionGg':
            mapper.value = `<span>${data?.name ?? CONSTANTS.NA}</span>`;
            mapper.width = '100%';
            mapper.isBold = false;
            mapper.isRightBordered = true;
            mapper.paddingLeft = '1vw';
            break;
        case 'assistiveDevices':
            mapper.value = `<span>
                    ${data?.clof?.assistiveDevice || CONSTANTS.NA}
                </span>`;
            mapper.width = '25%';
            mapper.isBold = false;
            mapper.isRightBordered = false;
            mapper.paddingLeft = '1vw';
            break;
        case 'clofAssistiveDevice':
            mapper.value = `<span>${data?.clof?.assistiveDevice || CONSTANTS.NA}</span>`;
            mapper.width = '25%';
            mapper.isBold = false;
            mapper.isRightBordered = false;
            mapper.paddingLeft = '1vw';
            break;
        case 'plofAssistiveDevice':
            mapper.value = `<span>${data?.plof?.assistiveDevice || CONSTANTS.NA}</span>`;
            mapper.width = '25%';
            mapper.isBold = false;
            mapper.isRightBordered = false;
            mapper.paddingLeft = '1vw';
            break;
        case 'respiratoryRate':
            mapper.value = `<span>${data?.respiratoryRate ? data.respiratoryRate + ' bpm' : CONSTANTS.NA}</span>`;
            mapper.width = '43%';
            mapper.isBold = false;
            mapper.isRightBordered = false;
            mapper.paddingLeft = '1vw';
            break;
        case 'pulseRate':
            mapper.value = `<span>${data?.pulseRate ? data.pulseRate + ' bpm' : CONSTANTS.NA}</span>`;
            mapper.width = '25%';
            mapper.isBold = false;
            mapper.isRightBordered = false;
            mapper.paddingLeft = '1vw';
            break;
        case 'bloodPressure':
            mapper.value = `<span>${data?.bloodPressure ? data.bloodPressure + ' mg' : CONSTANTS.NA}</span>`;
            mapper.width = '40%';
            mapper.isBold = false;
            mapper.isRightBordered = false;
            mapper.paddingLeft = '1vw';
            break;
        case 'o2Saturation':
            mapper.value = `<span>${data?.o2Saturation ? data.o2Saturation + '%' : CONSTANTS.NA}</span>`;
            mapper.width = '25%';
            mapper.isBold = false;
            mapper.isRightBordered = false;
            mapper.paddingLeft = '1vw';
            break;
        case 'temperature':
            mapper.value = `<span>${data?.temperature ? data.temperature : CONSTANTS.NA}</span>`;
            mapper.width = '80%';
            mapper.isBold = false;
            mapper.isRightBordered = false;
            mapper.paddingLeft = '1vw';
            break;
        case 'coTreatTime':
            mapper.value = `<span>${data?.coTreatTime ? `${data.coTreatTime} mins` : CONSTANTS.NA}</span>`;
            mapper.width = '10%';
            mapper.isBold = false;
            mapper.isRightBordered = true;
            mapper.paddingLeft = '1vw';
            break;
        case 'concurrentTime':
            mapper.value = `<span>${data?.concurrentTime ? `${data.concurrentTime} mins` : CONSTANTS.NA}</span>`;
            mapper.width = '80%';
            mapper.isBold = false;
            mapper.isRightBordered = true;
            mapper.paddingLeft = '1vw';
            break;
        case 'coTreatingTherapist':
            mapper.value = `<span>${data?.coTreatingTherapist ? data.coTreatingTherapist : CONSTANTS.NA}</span>`;
            mapper.width = '50%';
            mapper.isBold = false;
            mapper.isRightBordered = false;
            mapper.paddingLeft = '1vw';
            break;
        case 'justification':
            mapper.value = `<span>${data?.justification ? data.justification : CONSTANTS.NA}</span>`;
            mapper.width = '65%';
            mapper.isBold = false;
            mapper.isRightBordered = false;
            mapper.paddingLeft = '1vw';
            break;
        case 'usernameWithRole':
            mapper.value = `<span>${data?.usernameWithRole ? data.usernameWithRole : CONSTANTS.NA}</span>`;
            mapper.width = '53%';
            mapper.isBold = false;
            mapper.isRightBordered = false;
            mapper.paddingLeft = '1vw';
            break;
        case 'username':
            mapper.value = `<span>${data?.username ? data.username : CONSTANTS.NA}</span>`;
            mapper.width = '30%';
            mapper.isBold = false;
            mapper.isRightBordered = false;
            mapper.paddingLeft = '1vw';
            break;
        case 'sectionsEdited':
            mapper.value = `<span>${data?.sectionsEdited ? data.sectionsEdited : CONSTANTS.NA}</span>`;
            mapper.width = '35%';
            mapper.isBold = false;
            mapper.isRightBordered = false;
            mapper.paddingLeft = '1vw';
            break;
        case 'timeDate':
            mapper.value = `<span>${data?.timeDate ? data.timeDate : CONSTANTS.NA}</span>`;
            mapper.width = '35%';
            mapper.isBold = false;
            mapper.isRightBordered = false;
            mapper.paddingLeft = '1vw';
            break;
        case 'date':
            mapper.value = `<span>${data?.date ? data.date : CONSTANTS.NA}</span>`;
            mapper.width = '10%';
            mapper.isBold = false;
            mapper.isRightBordered = false;
            mapper.paddingLeft = '1vw';
            break;
        case 'time':
            mapper.value = `<span>${data?.time ? data.time : CONSTANTS.NA}</span>`;
            mapper.width = '10%';
            mapper.isBold = false;
            mapper.isRightBordered = false;
            mapper.paddingLeft = '1vw';
            break;
    }
    return mapper;
};

export const ADD_CPT_STYLES = {
    BLOCK: `display: flex !important;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                height: 100%;`,
    BADGE: `display: flex !important;
                width: 130px;
                height: 20px;
                justify-content: space-evenly;
                border-radius: 35px;
                background: #E8EDF9;
                color: #6076A7;
                margin-right: 2vw;`,
    IMG: `display: flex;
                width: 12px;
                height: 12px;
                align-self: center;`,
    LABEL: `display: flex;
                margin-bottom: 0;
                justify-content: center;
                align-items: center;
                font-size: 12px`,
};

export const DISCHARGE_DESTINATIONS = [
    'Home',
    'Home Health Care',
    'SNF',
    'Assisted Living',
    'Independent Living',
    'Hospital',
    'Long term acute care hospital (LTACH)',
    'Inpatient Rehab Facility (IRF)',
    'Hospice',
    'Expired',
    'LTC',
    'Other SNF',
];

export const getCurrentUser = () => JSON.parse(localStorage.getItem('innova-frontend'))?.user;
