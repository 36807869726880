<table #dailyNoteReport id="dailyNoteReport" class="print-table" *ngIf="isDataLoaded">
    <thead #header id="header" class="print-header">
        <!-- <th class="n-header">
            <app-header [isFirst]="false" [type]="'dailyNote'"></app-header>
        </th> -->
        <tr class="print-first-header">
            <app-header [isFirst]="true" [type]="'dailyNote'" [toExport]="toExport"></app-header>
        </tr>
    </thead>
    <tr style="page-break-before: avoid">
        <app-paragraph [type]="'dailyNote'" [key]="'patientAssessmentSinceLastTreatment'"></app-paragraph>
    </tr>
    <tr>
        <app-grid-layout [gridType]="'DynamicGrid'" [type]="'dailyNote'" [key]="'vitals'"></app-grid-layout>
    </tr>
    <tr>
        <app-treatment></app-treatment>
    </tr>
    <tr>
        <app-grid-layout [gridType]="'DynamicGrid'" [type]="'dailyNote'" [key]="'coTreatment'"></app-grid-layout>
    </tr>
    <tr>
        <app-paragraph [type]="'dailyNote'" [key]="'responseToTreatment'"></app-paragraph>
    </tr>
    <tr>
        <app-paragraph [type]="'dailyNote'" [key]="'planForNextTreatment'"></app-paragraph>
    </tr>
    <tr>
        <app-paragraph [type]="'dailyNote'" [key]="'anticipatedDischargeDate'"></app-paragraph>
    </tr>
    <tr>
        <app-grid-layout [gridType]="'TreeGrid'" [type]="'dailyNote'" [key]="'addendum'"></app-grid-layout>
    </tr>
    <tr [ngClass]="{'print-footer': !withEdits}" *ngIf="isDataLoaded">
        <app-signature [type]="'dailyNote'" [toShowPhysician]="false"></app-signature>
    </tr>
    <tr class="print-footer" *ngIf="withEdits">
        <app-grid-layout [type]="'dailyNote'" [key]="'changeHistoryPrint'"></app-grid-layout>
    </tr>
</table>
