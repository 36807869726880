import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'app-custom-cell-renderer',
    templateUrl: './custom-cell-renderer.component.html',
    styleUrls: ['./custom-cell-renderer.component.scss'],
})
export class CustomCellRendererComponent implements ICellRendererAngularComp {
    private params: any;
    value: any;
    cellParams: any;
    comment: string;
    gridName:string
    agInit(params: any): void {
        this.params = params;
        this.cellParams = params;
        this.value = this.params.value;
        this.gridName=params.gridName
        if (this.params.gridName === 'therapist') {
            this.comment = this.cellParams.data.caseloadComment;
        } else {
            this.comment = this.cellParams.data.comment;
        }
    }

    refresh(params: any): boolean {
        this.value = params.value;
        return true;
    }
}
