<ng-container *ngIf="goal">
    <!-- Single LTG Goal -->
    <div
        (click)="emitClickEvent()"
        *ngIf="goal?.type === 'LTG'"
        [class]="'d-flex align-items-start cursor-pointer py-2 ' + class"
    >
        <!-- Color pillbox -->
        <div class="p-2 bg-secondary rounded"></div>
        <!-- Goal Information -->
        <div class="d-flex flex-column ml-2">
            <!-- Goal Title -->
            <div class="body-small text-secondary">{{ goal?.title }}</div>
            <!-- Goal TLOF -->
            <div class="text-blue">
                <span class="body-small-bold"> Target: </span>
                <span class="body-small"> {{ goal?.tlof }}</span>
            </div>
        </div>
    </div>
    <!-- Single STG Goal -->
    <div *ngIf="goal?.type === 'STG'" [class]="'d-flex align-items-start cursor-pointer pl-3 py-2 ' + class">
        <!-- Color pillbox -->
        <div class="p-2 bg-primary rounded"></div>
        <!-- Goal Information -->
        <div class="d-flex flex-column ml-2">
            <!-- Goal Title -->
            <div class="body-small text-secondary">{{ goal?.title }}</div>
            <!-- Goal TLOF -->
            <div class="text-blue">
                <span class="body-small-bold"> Target: </span>
                <span class="body-small"> {{ goal?.tlof }}</span>
            </div>
        </div>
    </div>
</ng-container>
