import { FunctionPassedActions } from '@app/interfaces';
// To check the end date of the care
export const checkEndDate = (planner: any, date: string) => {
    const effectiveFromDate = new Date(planner.effectiveFrom);
    const dateOnDurationEnding = new Date(
        effectiveFromDate.setDate(effectiveFromDate.getDate() + planner.duration * 7)
    );

    return new Date(date) < dateOnDurationEnding;
};

// To get the day of week in 'mon', 'sun' format
export const getDayOfWeek = (date: string) => {
    const dayOfWeek = new Date(date).getDay();

    return isNaN(dayOfWeek) ? null : ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'][dayOfWeek];
};
// To check the frequency
export const checkFrequency = (planner: any) => {
    return planner.frequency[getDayOfWeek(planner.effectiveFrom)];
};

export const showPill = async (
    planner: any,
    date: string,
    payload: { successActions: FunctionPassedActions; errorActions: FunctionPassedActions }
) => {
    const effectiveFromDate = new Date(planner.effectiveFrom);
    const check = checkFrequency(planner) && checkEndDate(planner, date) && effectiveFromDate < new Date(date);
    if (check) {
        payload?.successActions?.actions?.forEach(async (action) => await action.callback(...action.params));
    } else {
        payload?.errorActions?.actions?.forEach(async (action) => await action.callback(...action.params));
    }

    return check;
};

export const getFullDayName = (abbr: string): string => {
    const dayMap = {
        sun: 'Sunday',
        mon: 'Monday',
        tue: 'Tuesday',
        wed: 'Wednesday',
        thu: 'Thursday',
        fri: 'Friday',
        sat: 'Saturday',
    };

    return dayMap[abbr];
};
// showPill(planner) {
//   const effectiveFromDate = new Date(planner.effectiveFrom);

//   const check = this.checkFrequency(planner) && this.checkEndDate(planner) && effectiveFromDate < new Date(this.date);
//   if (check) {
//     this.setShowHeader(true);
//     this.setCanShowPopup(true);
//   } else {
//     this.setCanShowPopup(false);
//   }
//   return check;
// }
// checkFrequency(planner) {
//   return planner.frequency[this.getDayOfWeek(this.date)];
// }

// checkEndDate(planner) {
//   const effectiveFromDate = new Date(planner.effectiveFrom);
//   const dateOnDurationEnding = new Date(
//     effectiveFromDate.setDate(effectiveFromDate.getDate() + planner.duration * 7)
//   );

//   return new Date(this.date) < dateOnDurationEnding;
// }

// getDayOfWeek(date) {
//   const dayOfWeek = new Date(date).getDay();
//   return isNaN(dayOfWeek) ? null : ['sun', 'mon', 'tues', 'wed', 'thu', 'fri', 'sat'][dayOfWeek];
// }
