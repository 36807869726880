/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
    selector: 'app-radio-button',
    templateUrl: './radio-button.component.html',
    styleUrls: ['./radio-button.component.scss'],
})
export class RadioButtonComponent implements ControlValueAccessor {
    @Input('options') radioOptions = [];
    @Input() label: string;
    @Input() isVertical = false;
    @Output() radioChangeEvent: EventEmitter<any> = new EventEmitter();
    @Input() backgroundClass = 'bg-white';
    @Input() id = '';
    @Input() contentAlign = 'justify-content-center';
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    propagateChange = (_: any) => {};
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onTouched = (_: any) => {};

    @Input() value: any;
    @Input() disabled: boolean;
    constructor(public readonly ngControl: NgControl) {
        ngControl.valueAccessor = this;
    }

    get isRequired(): boolean {
        const control: AbstractControl = this.ngControl.control;
        // console.log(control.errors)
        if (control.validator === null) return false;
        return control.validator(control)?.required ?? false;
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    radioSelect(event): void {
        this.propagateChange(event.value);
        this.radioChangeEvent.emit(event);
    }
    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
