import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TabItem } from '@app/interfaces';
import { ToasterService } from '@app/services';

@Component({
    selector: 'app-organization-nav-bar',
    templateUrl: './organization-nav-bar.component.html',
    styleUrls: ['./organization-nav-bar.component.scss'],
})
export class OrganizationNavBarComponent {
    @Input() tabs: TabItem[];
    @Output() tabIndex = new EventEmitter<number>();

    constructor(private toasterService: ToasterService) {}

    selectedTab(index: number) {
        this.tabIndex.emit(index);
    }
}
