<app-base-modal-card [heading]="heading" (close)="onClose()">
    <!-- <div class="modal-container"> -->
    <div body>
        <app-toggle
            [fullWidth]="false"
            [class]="'w-auto d-inline-flex'"
            [buttons]="buttons"
            (buttonChanged)="appToggleChanged()"
        >
        </app-toggle>

        <ng-container *ngIf="loader">
            <div class="d-flex mt-3 mb-4">
                <div class="col-12">
                    <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.HEADER_BAR"></ngx-skeleton-loader>

                    <div class="mt-3">
                        <div>
                            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.LINE"></ngx-skeleton-loader>
                        </div>
                        <div>
                            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.LINE2"></ngx-skeleton-loader>
                        </div>
                    </div>
                    <div class="mt-3">
                        <div>
                            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.LINE"></ngx-skeleton-loader>
                        </div>
                        <div>
                            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.BLOCK"></ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <form *ngIf="!loader" class="mt-4" [formGroup]="mainForm">
            <!-- co-treat form starts here -->

            <div *ngIf="currToggle === 'coTreat' && allowCoTreatment" formGroupName="coTreatForm">
                <h6 class="h6-bold mt-2 mb-4">Co-Treatment Minutes</h6>
                <div class="input">
                    <app-input
                        placeholder="Type here..."
                        [label]="'No of Minutes'"
                        [backgroundClass]="'bg-body-background'"
                        formControlName="minutes"
                        tooltipPlacement="right"
                        [customToolTip]="{
                            tooltip: !underGrandTotal,
                            msg:
                                'Sum of Co-Treat & Concurrent cannot exceed ' +
                                data?.mdsMinutesGrandTotal +
                                ' minutes since this is the grand total of MDS CPT Code minutes.'
                        }"
                    ></app-input>
                </div>
                <div class="d-flex flex-row">
                    <app-checkbox
                        [label]="checkList.title"
                        formControlName="disciplines"
                        [options]="checkList.options"
                        (optionChecked)="onCheckboxChange($event)"
                        [selectWithCards]="selectWithCards"
                        [selectWithCardsOptions]="selectWithCardsOptions"
                        [currentOption]="currentTA?.therapyDiscipline"
                        [currentOptionValue]="currentDisciplineTherapist"
                        [currentSelectCardValues]="currentSelectCardValues"
                        (selectWithCardChangedEmitter)="selectWithCardChangedCalled($event)"
                        class="w-100"
                        disciplineRow="row"
                        disciplineColumn="col-4 min-height100"
                    ></app-checkbox>
                </div>
                <app-text-box
                    [rows]="2"
                    [label]="'Justification'"
                    [backgroundClass]="'bg-body-background'"
                    formControlName="justification"
                    placeholder="Type here..."
                ></app-text-box>
            </div>

            <!-- concurrent form starts here -->

            <ng-container *ngIf="currToggle === 'concurrent' && allowConcurrent" formGroupName="concurrentForm">
                <h6 class="h6-bold mb-4">Concurrent Minutes</h6>
                <div class="d-flex">
                    <app-input
                        placeholder="Type here..."
                        [label]="'No of Minutes'"
                        [backgroundClass]="'bg-body-background'"
                        formControlName="minutes"
                        tooltipPlacement="right"
                        [customToolTip]="{
                            tooltip: !underGrandTotal,
                            msg:
                                'Sum of Co-Treat & Concurrent cannot exceed ' +
                                data?.mdsMinutesGrandTotal +
                                ' minutes since this is the grand total of MDS CPT Code minutes.'
                        }"
                    ></app-input>
                </div>
            </ng-container>

            <!-- bottom buttons starts here-->
        </form>
    </div>
    <div footer class="bottom-btns mt-4 d-flex justify-content-end">
        <app-button [text]="'Cancel'" [class]="'btn-outline'" (click)="onClose()"></app-button>
        <app-button
            [text]="btnText"
            [icon]=""
            [class]="'btn btn-secondary ml-2'"
            [rightIcon]="true"
            [type]="'button'"
            (click)="formSubmitted()"
            [disabled]="!saveBtnStatus"
        ></app-button>
    </div>
    <!-- </div> -->
</app-base-modal-card>
