<app-base-modal-card [heading]="data.facility ? data.facility.facilityName : data.name" (close)="onClose()">
    <div body class="p-4">
        <div class="w-100 d-flex flx-row align-items-center justify-content-start" *ngIf="data.facility">
            <div>
                <span class="text-secondary fw-600">Contract From: </span>
                <span class="text-secondary">{{ data.dateFrom }}</span>
            </div>
            <div class="ml-2">
                <span class="text-secondary fw-600">Contract Through: </span>
                <span class="text-secondary">{{ data.dateThrough }}</span>
            </div>
        </div>
        <br />
        <div class="body-large-semibold mb-3">Contract details</div>
        <ng-container select="[body]">
            <div body class="table-container">
                <div class="d-flex align-items-center w-50 mb-4 justify-content-between">
                    <span *ngIf="data.contractType == 'CPT Code Rates'" class="items-gap d-flex"
                        ><span class="body-medium-semibold">MPPR</span>
                        <span class="font-pop-400-12">{{ mppr }}</span></span
                    >
                    <span *ngIf="data.contractType == 'CPT Code Rates'" class="items-gap d-flex"
                        ><span class="body-medium-semibold">Asst.Reduction</span
                        ><span class="font-pop-400-12">{{ asstReduction }}</span></span
                    >

                    <span
                        *ngIf="data.contractType === 'Per Diem Rate' || data.contractType === 'PDPM'"
                        class="items-gap d-flex"
                        ><span class="body-medium-semibold">Billable DC</span
                        ><span class="font-pop-400-12">{{ billableDischarge }}</span></span
                    >
                </div>
                <!-- <table class="innova-table-v2 mb-4">
                    <thead>
                        <tr>
                            <th>MPPR</th>
                            <th>Asst.Reduction</th>
                            <th>Billable DC</th>
                            <th>Contract Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{ mppr }}</td>
                            <td>{{ asstReduction }}</td>
                            <td>{{ data.billableDischarge }}</td>
                            <td>{{ data.contractType }}</td>
                        </tr>
                    </tbody>
                </table> -->
                <!-- <table class="innova-table-v2 mb-4">
                    <thead>
                        <tr>
                            <th *ngFor="let obj of controlsArray">
                                {{ getObjectKeys(obj).split('-')[0] | uppercase }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td *ngFor="let obj of controlsArray">
                                {{ obj[getObjectKeys(obj)].split('-')[0] | uppercase }}
                            </td>
                        </tr>
                    </tbody>
                </table> -->
                <ng-container>
                    <table class="innova-table-v2 mb-4">
                        <!-- <thead>
                            <tr>
                                <th *ngFor="let key of Row.keys">{{ key[0].split('-')[0] | uppercase }}</th>
                            </tr>
                        </thead> -->

                        <tbody>
                            <tr *ngFor="let obj of keyValues; let j = index">
                                <td class="w-50 font-pop-600-14">{{ mapKey(obj?.key?.split('-')[0]) | uppercase }}</td>
                                <td>
                                    {{ obj?.value ? (obj?.value | uppercase) : 'N/A' }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div
                        class="row w-100"
                        *ngIf="data && data.contractVariant == 'PDPM - Therapy Component Individual'"
                    >
                        <div class="col-4 border-right border-light pr-0 pl-0">
                            <div class="d-flex flex-row justify-content-around bg-body-background p-2">
                                <span class="body-medium-semibold">PT CMG</span>
                                <span class="body-medium-semibold">% age</span>
                            </div>
                            <div
                                *ngFor="let pt of data.controlsArray[1].nestedControlsArray; let i = index"
                                class="d-flex flex-row justify-content-around p-2"
                                [ngClass]="{ 'bg-body-background': i % 2 != 0 }"
                            >
                                <span class="body-medium-semibold">
                                    {{ getKey(pt) | uppercase }}
                                </span>
                                <span>
                                    {{ pt[getOriginalKey(pt)] || 'N/A' }}
                                </span>
                            </div>
                        </div>
                        <div class="col-4 border-right border-light pr-0 pl-0">
                            <div class="d-flex flex-row justify-content-around bg-body-background p-2">
                                <span class="body-medium-semibold">OT CMG</span>
                                <span class="body-medium-semibold">% age</span>
                            </div>
                            <div
                                *ngFor="let pt of data.controlsArray[2].nestedControlsArray; let i = index"
                                class="d-flex flex-row justify-content-around p-2"
                                [ngClass]="{ 'bg-body-background': i % 2 != 0 }"
                            >
                                <span class="body-medium-semibold">
                                    {{ getKey(pt) | uppercase }}
                                </span>
                                <span>
                                    {{ pt[getOriginalKey(pt)] || 'N/A' }}
                                </span>
                            </div>
                        </div>
                        <div class="col-4 pr-0 pl-0">
                            <div class="d-flex flex-row justify-content-around bg-body-background p-2">
                                <span class="body-medium-semibold">SLP CMG</span>
                                <span class="body-medium-semibold">% age</span>
                            </div>
                            <div
                                *ngFor="let pt of data.controlsArray[0].nestedControlsArray; let i = index"
                                class="d-flex flex-row justify-content-around p-2"
                                [ngClass]="{ 'bg-body-background': i % 2 != 0 }"
                            >
                                <span class="body-medium-semibold">
                                    {{ getKey(pt) | uppercase }}
                                </span>
                                <span>
                                    {{ pt[getOriginalKey(pt)] || 'N/A' }}
                                </span>
                            </div>
                        </div>
                        <!-- <thead>
                        <tr>
                            <th >PT CMG</th>
                            <th >OT CMG</th>
                            <th >SLP CMG</th>

                        </tr>
                    </thead> -->
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>
</app-base-modal-card>
