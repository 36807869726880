import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { CONSTANTS } from '@app/helpers/constants/documentation-report';
import { DOCUMENTATION_CONSTANTS } from '@app/interfaces/documentation';
import { PrintService, DocumentationReportService, DocumentationService, ToasterService } from '@app/services';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-documentation',
    templateUrl: './documentation.component.html',
    styleUrls: ['./documentation.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DocumentationComponent implements OnInit, OnDestroy {
    @ViewChild('evaluation') evaluation: ElementRef;
    subscription = new Subscription();
    reports: { id: string; type: string }[];
    selectedDocs;
    isNotDocExport: boolean;
    isPrintOpen: boolean;
    reportsLoaded = false;

    constructor(
        private printService: PrintService,
        private documentService: DocumentationReportService,
        private documentationService: DocumentationService,
        private router: Router,
        private toaster: ToasterService
    ) {
        this.reports = [];
        this.isNotDocExport = false;
    }

    ngOnInit(): void {
        try {
            this.isPrintOpen = true;
            this.isNotDocExport =
                !this.router.url.includes('evaluation-document') &&
                !this.router.url.includes('daily-note-document') &&
                !this.router.url.includes('progress-note-document') &&
                !this.router.url.includes('re-certification-document') &&
                !this.router.url.includes('discharge-note-document') &&
                !this.router.url.includes('addendum-note-document');

            this.selectedDocs = this.printService.getSelectedDocs();

            const idsAndTypes = this.selectedDocs?.map((selectedDoc) => ({
                id: selectedDoc.id,
                type: selectedDoc.type,
                dueDate: selectedDoc.dueDate,
                key: selectedDoc.status.reduce((acc, cur) => {
                    if (cur === DOCUMENTATION_CONSTANTS.COMPLETED) {
                        acc = CONSTANTS.DESCRIPTION;
                    } else if (cur === DOCUMENTATION_CONSTANTS.REFUSED) {
                        acc = CONSTANTS.REFUSED;
                    } else if (cur === DOCUMENTATION_CONSTANTS.WITHHELD) {
                        acc = CONSTANTS.WITHHELD;
                    }
                    if (acc.length) {
                        return acc;
                    }
                }, ''),
                assignedTherapistExist: !!selectedDoc?.assignedTherapists?.length,
            }));
            this.reports = this.documentService.notesSequence(idsAndTypes);
            const pocReports = this.reports
                .filter(
                    (x) => x.type === 'Recertification' || x.type === 'Updated Plan of Care' || x.type === 'Evaluation'
                )
                .map((x) => x.id);
            if (pocReports && pocReports.length) {
                this.documentationService.fetchPOCDocPhysicianDetails(pocReports).subscribe({
                    next: (data) => {
                        this.reports.map((x) => {
                            const found = data?.find((y) => y.documentId === x.id);
                            if (found) {
                                x['physicianDetails'] = Object.keys(found.physicianDetails).length
                                    ? found.physicianDetails
                                    : null;
                            } else {
                                x['physicianDetails'] = null;
                            }
                            return x;
                        });
                        this.reportsLoaded = true;
                    },
                    error: () => {
                        this.reportsLoaded = true;
                    },
                });
            } else {
                this.reportsLoaded = true;
            }
        } catch (error) {
            this.isPrintOpen = false;
            this.toaster.show({
                title: 'Print',
                body: 'Layout load unsuccessful due to some error.',
                type: 'error',
            });
        }
    }

    ngAfterViewInit() {
        window.onafterprint = this.afterPrint;
        this.subscription.add(
            this.documentService.loadedNotes.subscribe((count: number) => {
                if (count === this.reports.length) {
                    this.documentService.resetLoadedNotes();
                    if (this.isNotDocExport) {
                        this.isPrintOpen = false;
                        this.documentationService.setDocPrintLayoutStatus(true);
                        setTimeout(() => {
                            print();
                        }, 0);
                    }
                }
            })
        );
    }

    afterPrint(): void {
        window.history.back();

        if (this.subscription) this.subscription.unsubscribe();
    }

    ngOnDestroy(): void {
        this.documentationService.setDocPrintLayoutStatus(false);
        if (this.subscription) this.subscription.unsubscribe();
    }
}
