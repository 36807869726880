import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import {
    JwtInterceptor,
    ErrorInterceptor,
    TimezoneInterceptor,
    UserDateInterceptor,
    UserIdInterceptor,
    FacilityInterceptor,
    VersionInterceptor,
} from '@app/interceptors';
import { appInitializer } from '@app/interceptors';
import { AuthService, OrganizationManagementService } from '@app/services';
import { AppComponent } from './app.component';
import { AlertComponent } from '@app/shared';
import { HeaderComponent } from './core/header/header.component';
import { LeftNavBarComponent } from './core/left-nav-bar/left-nav-bar.component';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { RootReducer } from '@app/store/index';
import { NgbDateCustomParserFormatter } from '@app/helpers';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ShowcaseComponent } from './core/showcase/showcase.component';
import { SharedModule } from './shared/shared.module';
import { NgxEchartsModule } from 'ngx-echarts';
import { FeedbackComponent } from './modules/feedback/feedback.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import {SidebarModule} from 'primeng/sidebar';
import { environment } from '@environments/environment';
const config: SocketIoConfig = {
    url: environment.socketUrl,
    options: { transports: ['websocket'], upgrade: false },
};

@NgModule({
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        SocketIoModule.forRoot(config),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        StoreModule.forRoot(RootReducer),
        StoreDevtoolsModule.instrument(),
        BrowserAnimationsModule,
        SharedModule,
        FormsModule,
        SidebarModule,
        NgxEchartsModule.forRoot({
            /**
             * This will import all modules from echarts.
             * If you only need custom modules,
             * please refer to [Custom Build] section.
             */
            echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
        }),
        NgxSkeletonLoaderModule,
    ],
    declarations: [
        AppComponent,
        AlertComponent,
        HeaderComponent,
        LeftNavBarComponent,
        ShowcaseComponent,
        FeedbackComponent,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializer,
            multi: true,
            deps: [AuthService, OrganizationManagementService],
        },
        { provide: HTTP_INTERCEPTORS, useClass: FacilityInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TimezoneInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: UserDateInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: UserIdInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: VersionInterceptor, multi: true },
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
        { provide: HTTP_INTERCEPTORS, useClass: VersionInterceptor, multi: true },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}
