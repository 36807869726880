import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { DIALOG_DATA } from '@app/helpers/dialog-tokens';
import { DialogRef } from '@app/services';

@Component({
    selector: 'app-goal-modal',
    templateUrl: './goal-modal.component.html',
    styleUrls: ['./goal-modal.component.scss'],
})
export class GoalModalComponent {
    heading: string;
    goalData: any;
    s;
    constructor(@Inject(DIALOG_DATA) private data, private ref: DialogRef, private datePipe: DatePipe) {
        this.goalData = data;
        this.goalData.goalHistory = this.data.goalHistory.slice().reverse();
    }

    onClose() {
        this.ref.close();
    }
    dateFormatter(date: any) {
        if (!Number.isNaN(date)) return this.datePipe.transform(new Date(date), 'MM/dd/YY');
    }
    onSave() {
        console.log('Saving...');
        this.onClose();
    }
}
