import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LibrariesService {
    getReasonForReferral() {
        return {
            PT: [
                'CVA: This _____ year old [GENDER] was admitted with a history of a CVA on[DATE] with resulting ________ impacting the patients ability to perform _________.',
                'Fall: This _____ year old [GENDER] presents to therapy due to a fall on [DATE] as a result of _______ resulting in _______.  Patient has complaints of _________ with resulting impairments in _________.',
                "Maintenance Program: This ____ year old [GENDER] would benefit from the development of a maintenance program.  This program is needed to maintain the patient's ability to ________ in order to decrease risk of ____________.  The specialized skill, knowledge, and judgment of a Physical ",
                "Fall at Home: This _____ year old [GENDER] fell at home, resulting  in ________.  This has impacted this patient's _______.  As a result of these noted limitations in functional activities, this patient is unable to safely return to home.  This patient requires skilled therapy in order to improve safety and function.",
                'General Functional Decline: This _____ year old [GENDER] presents to therapy with a significant decline in functional mobility including ______ due to _______.  As a result of these noted limitations this patient is unable to safely return to home.  This patient requires skilled therapy in order to improve safety and function.',
                'Joint replacement: This ____ year old [GENDER] underwent a _________ replacement on [DATE].  The patient reports complaints of ______ and decreased functional mobilities of _____________.  As a result of these complaints and limited functional activities, this patient is unable to safely return home.  Without therapy, this patient is at risk of _________.',
                'Medically Complex/Multiple Comorbidities: This ______ year old [GENDER] presents to therapy with multiple conditions, including ________.  The patient has shown a significant decline in functional mobility including _________ over [TIME] due to medically complex conditions resulting from _______.  This patient requires skilled therapy interventions to improve self-care in order to return home safely.',
                'Wound Care: This ____ year old [GENDER] presents to therapy with [TYPE OF WOUND] wound of the [BODY PART] as a result of _______.  Estimated time/presence of wound is ________ and previous wound care treatments include _________.  The patient requires skilled therapy for skilled wound care treatment to improve wound healing.',
                'Pain: This _____ year old [GENDER] presents to therapy with [X/10] pain levels of the _________ for [TIME PERIOD] due to _________.  The pain impairs ability to perform __________.  Other treatments that have (or have not) been successful at controlling pain and impacting function include __________.',
                'Post Surgical: This ____ year old ____ [GENDER] underwent [SURGERY] on [DATE].The patient reports complaints of ________ and impaired ability to perform ___________.',
            ],
            OT: [
                'Cognitive Linguistics: This ___ year old [GENDER] presents to occupational therapy with impairments in ______ for _____ due to ______.  The patient has shown a decline from _____ to _____ in the following activities ________.',
                'CVA: This _____ year old [GENDER] was admitted with a history of a CVA on[DATE] with resulting ________ impacting the patients ability to perform _________.',
                'Fall: This _____ year old [GENDER] presents to therapy due to a fall on [DATE] as a result of _______ resulting in _______.  Patient has complaints of _________ with resulting impairments in _________.',
                "Maintenance Program: This ____ year old [GENDER] would benefit from the development of a maintenance program.  This program is needed to maintain the patient's ability to ________ in order to decrease risk of ____________.  The specialized skill, knowledge, and judgment of a Occupational Therapist are required to design and establish the program, assure patient safety, as well as train the patient and/or caregivers.",
                "Fall at Home: This _____ year old [GENDER] fell at home, resulting  in ________.  This has impacted this patient's _______.  As a result of these noted limitations in functional activities, this patient is unable to safely return to home.  This patient requires skilled therapy in order to improve safety and function.",
                'General Functional Decline: This _____ year old [GENDER] presents to therapy with a significant decline in ADLs including ______ due to _______.  As a result of these noted limitations this patient is unable to safely return to home.  This patient requires skilled therapy in order to improve safety and function.',
                'Joint replacement: This ____ year old [GENDER] underwent a _________ replacement on [DATE].  The patient reports complaints of ______ and decreased functional abilities and ADLs of _____________.  As a result of these complaints and limited functional activities, this patient is unable to safely return home.  Without therapy, this patient is at risk of _________.',
                "Medically Complex & Multiple Comorbidities: This ______ year old [GENDER] presents to therapy with multiple conditions, including ________.  The patient has shown a significant decline in ADL's including _________ over [TIME] due to medically complex conditions resulting from _______.  This patient requires skilled therapy interventions to improve self-care in order to return home safely.",
                'Wound Care: This ____ year old [GENDER] presents to therapy with [TYPE OF WOUND] wound of the [BODY PART] as a result of _______.  Estimated time/presence of wound is ________ and previous wound care treatments include _________.  The patient requires skilled therapy for skilled wound care treatment to improve wound healing.',
                'Pain: This _____ year old [GENDER] presents to therapy with [X/10] pain levels of the _________ for [TIME PERIOD] due to _________.  The pain impairs ability to perform __________.  Other treatments that have (or have not) been successful at controlling pain and impacting function include __________.',
                'Post Surgical: This ____ year old ____ [GENDER] underwent [SURGERY] on [DATE].The patient reports complaints of ________ and impaired ability to perform ___________.',
            ],
            ST: [
                'Cognitive Linguistics: This ___ year old [GENDER] presents to speech therapy with impairments in ______ for _____ due to ______.  The patient has shown a decline from _____ to _____ in the following activities ________.',
                'Cognitive Training: This ____ year old [Gender] was admitted with significant cognitive impairments impacting an ability to safely return to home.  Skilled Speech Therapy is required to address these impairments to ensure a safe discharge to home.',
                'Dysphagia: This _____ year old [GENDER] was admitted with significant swallowing difficulty. Skilled Speech Therapy is necessary for ______ in order for patient to tolerate safest diet and/or liquid consistency in order to return home safely.',
                'Communication Impairment: This ____ old [Gender] present to speech therapy with a recent decline in communication abilities of ________ due to _______. Skilled speech therapy is necessary to improve _______ and ability to make wants and needs known to allow for improved functional communication and safe return to home.',
                'General Functional Decline: This _____ year old [GENDER] presents to therapy with a significant decline in ADLs including ______ due to _______.  A decrease in self care/ADL ability for [TIME PERIOD] has been noticed, resulting in decreased safety and an increase need for assistance.',
                'Post Surgical: This ____ year old ____ [GENDER] underwent [SURGERY] on [DATE].The patient reports complaints of ________ and impaired ability to perform ___________.',
                'Speech Deficits: This _____ year old [GENDER] presents to speech therapy with a recent decline in communication abilities of _________ due to _________.',
                'This ______year-old [GENDER] was admitted with significant swallowing difficulty. Skilled Speech Therapy is necessary to reduce risk of aspiration and/or choking, tolerate safest diet and/or liquid consistency and facilitate safe and optimal PO intake.',
            ],
        };
    }
    getChiefComplaint() {
        return {
            PT: [
                'Pain of [BODY PART]',
                'Weakness of [BODY PART]',
                'Instability of ________',
                'Functional limitations including ____________',
                "ADL's including ___________.",
                'Inability to perform work related duties such as _________',
                'Inability to perform house hold duties such as __________.',
                'Loss of balance.',
                'Problems with gait including _________.',
            ],
            OT: [
                'Pain of [BODY PART]',
                'Weakness of ________',
                'Instability of ________',
                'Functional limitations including ____________',
                "ADL's including ___________.",
                'Inability to perform work related duties such as _________',
                'Inability to perform house hold duties such as __________.',
                'Loss of balance.',
                'Problems with gait including _________.',
            ],
            ST: [
                'Swallowing food',
                'Verbal understanding',
                'Recollection / memory',
                'Word retrieval',
                'Spoken communication',
                'Written communication',
                'Understanding',
                'Pocketing food',
                'Holding food in mouth',
                'Coughing at meals',
                'Expectorating food or medications',
                'Weight loss related to dysphagia',
                'Recurrent aspiration PNA',
            ],
        };
    }
    getPriorLevelOfFunction() {
        return {
            PT: [
                'Lived in assisted living facility and able to _______.',
                'Lived in home with ______ stairs and able to navigate with _________ assistance and _______ device.',
                'Lived in single story home and did not have to navigate stairs or steps.',
                'Lived in SNF and began to develop increase in risk for ______.',
                'Lived in SNF and skin was intact without pressure sores or wounds.',
                'Lived independently at home with _________ and able to __________.',
                'Wheelchair bound, non ambulatory and able to ______.',
                'Worked as a __________.',
            ],
            OT: [
                'Independent at home with all ADLs and self-care.',
                'Independent in all ADLs including bathing and toileting.',
                'Independent in homemaking.',
                'Lived at home with part-time caregiver and was able to perform _________.',
                'Lived at home with full-time caregiver and was able to perform _________.',
                'Lived at home with home health assistant for ________.',
                'Lived at SNF and able to _______.',
                'Lived at SNF and required ______ assist for ________.',
                'Lived in assisted living facility and able to _______.',
                'Lived in home with ______ stairs and able to navigate with_________ assistance and _______ device.',
                'Lived in single story home and did not have to navigate stairs or steps.',
                'Lived in SNF and began to develop increase in risk for ______.',
                'Lived in SNF and skin was intact without pressure sores or wounds',
                "Lived in SNF and was independent with the following ADL's ________.",
                'Lived independently at home with _________ and able to __________.',
                'Wheelchair bound, non ambulatory and able to _______.',
                'Worked as a __________.',
            ],
            ST: [
                'Able to communicate daily wants/needs effectively.',
                'Lived at home with part-time caregiver and was able to perform _________.',
                'Lived at home with full-time caregiver and was able to perform _________.',
                'Lived at home with home health assistant for ________.',
                'Lived at SNF and able to _______.',
                'Lived at SNF and required ______ assist for ________.',
                'Lived in assisted living facility and able to _______.',
                'Lived in SNF and began to develop increase in risk for ______.',
                'Lived in SNF and required modified diet of _______ for safety.',
                'Lived in SNF with no difficulties expressing wants/medical needs to familiar listeners.',
                'Lived in SNF with no difficulties expressing wants/medical needs to unfamiliar listeners.',
                'Lived in SNF without any diet restrictions/diet modifications.',
                'Mental awareness and functional communication WFLs.',
                'Mild dementia not affecting daily routine or safety.',
                'No history of receptive or expressive deficits.',
                'No history of swallowing problems.',
                'No reported problems swallowing.',
                'Past effective diet modification required for swallow safety.',
                'Past neurologic involvement with expressive deficit but not impacting functional communication.',
                'Past swallowing treatment- resolved & stable.',
            ],
        };
    }
    getTherapyNecessity() {
        return {
            PT: [
                'Patient requires skilled PT to address impairments/deficits which include ___________.',
                "Patient requires skilled PT to address impairments/deficits which include ___________ related to the patient's recent inpatient hospital stay and diagnosis of _____________.",
            ],
            OT: [
                'Patient requires skilled OT to address impairments/deficits which include ___________.',
                "Patient requires skilled OT to address impairments/deficits which include ___________ related to the patient's recent inpatient hospital stay and diagnosis of _____________.",
            ],
            ST: [
                "Patient requires skilled ST to address impairments/deficits which include _________ related to the patient's recent inpatient hospital stay and diagnosis of ______________.",
                'Therapy necessary for_______.  Without therapy patient is at risk for_________.',
            ],
        };
    }
    getInterventionApproaches() {
        return {
            PT: [
                'In addition to individual treatment sessions, patient will also benefit from the group and/or concurrent modes of therapy. Patient was educated and is agreeable to utilization of different modes of therapy delivery.',
                'Patient will benefit from group therapy because it allows for modeling (a form of learning in which individuals learn by imitating the actions of others and it reduces social isolation and enhances coping mechanisms.',
                'Patient will benefit from group therapy to: Create a network that promotes growth and learning by enabling patients to receive and give support and to share experiences from different points of view.',
                'Patient will benefit from group/concurrent therapy because it is supported by evidence to promote increased patient engagement and sustainable outcomes.',
                'Patient will benefit from group/concurrent therapy to: Promote independence and minimize dependence.',
            ],
            OT: [
                'In addition to individual treatment sessions, patient will also benefit from the group and/or concurrent modes of therapy. Patient was educated and is agreeable to utilization of different modes of therapy delivery.',
                'Patient will benefit from group therapy because it allows for modeling (a form of learning in which individuals learn by imitating the actions of others and it reduces social isolation and enhances coping mechanisms.',
                'Patient will benefit from group therapy to: Create a network that promotes growth and learning by enabling patients to receive and give support and to share experiences from different points of view.',
                'Patient will benefit from group/concurrent therapy because it is supported by evidence to promote increased patient engagement and sustainable outcomes.',
                'Patient will benefit from group/concurrent therapy to: Promote independence and minimize dependence.',
            ],
            ST: [
                'In addition to individual treatment sessions, patient will also benefit from the group and/or concurrent modes of therapy. Patient was educated and is agreeable to utilization of different modes of therapy delivery.',
                'Patient will benefit from group therapy to: Create a network that promotes growth and learning by enabling patients to receive and give support and to share experiences from different points of view.',
                'Patient will benefit from group therapy because it allows for modeling (a form of learning in which individuals learn by imitating the actions of others and it reduces social isolation and enhances coping mechanisms.',
                'Patient will benefit from group/concurrent therapy because it is supported by evidence to promote increased patient engagement and sustainable outcomes.',
                'Patient will benefit from group/concurrent therapy to: Promote independence and minimize dependence.',
            ],
        };
    }
    getPastMedicalHistory() {
        return {
            PT: [
                'Amputation [LOCATION]',
                'Compression fracture [LOCATION]',
                'Wounds [LOCATION]',
                'Tendinosis [LOCATION]',
                'Venous stasis ulcer [LOCATION]',
                'Achilles tendon tear [LOCATION]',
                'ACL tear surgically repaired [LOCATION]',
                'Above knee amputation (AKA) [LOCATION]',
                'Below knee amputation (BKE) [LOCATION]',
                'Cancer (SITE/TYPE)',
                'Surgery (TYPE)',
                'AAA (Abdominal Aortic Aneurysm)',
                'Achilles tendon tear [LOCATION]',
                'Acute Renal Failure',
                'AIDS (Acquired Immune Deficiency Syndrome)',
                "Alzheimer's Disease",
                'Anemia',
                'Angina',
                'Anxiety',
                'ASHD (Arteriosclerotic Heart Disease)',
                'Atrial Fibrillation',
                'Bipolar Disorder',
                'Bowel Obstruction',
                'C-Diff',
                'Coronary Artery Bypass Graft (CABG)',
                'CAD (Coronary Artery Disease)',
                'Carpal tunnel syndrome',
                'Chronic bronchitis',
                'Chronic renal failure',
                'Colostomy',
                'COPD (Chronic Obstructive Pulmonary Disease)',
                'CRPS (Complex Regional Pain Syndrome)',
                'CVA (Cerebrovascular Accident)',
                'CVI (Chronic Venous Insufficiency)',
                'DDD (Degenerative Disc Disease)',
                'Depression',
                'Diabetes -- Type 1',
                'Diabetes -- Type2',
                'Dialysis (TYPE & FREQUENCY)',
                'Diverticulitis',
                'DJD (Degenerative Joint Disease) [JOINT/LOCATION]',
                'Emphysema',
                'Esophageal dysphagia',
                'ESRD (End Stage Renal Disease)',
                'ETOH abuse',
                'Fibromyalgia',
                'Fracture of [LOCATION]',
                'GERD (Gastroesophageal Reflux Disease)',
                'GI Bleed',
                'Gout',
                'Head Injury [TYPE]',
                'Heart Failure',
                'Hip Fracture [LOCATION]',
                'Hip Replacement [LOCATION]',
                'History of aspiration',
                'History of falls',
                'HIV (Human Immunodeficiency Virus)',
                'HTN (hypertension)',
                "Huntington's Disease",
                'Hypothyroidism',
                'Hysterectomy',
                'Inguinal hernia repair',
                'Kyphoscoliosis',
                'Kyphosis',
                'Hemiplegia [RIGHT/LEFT]',
                'Low vision',
                'Lower lobe infiltrate [LOCATION]',
                'Lymphedema [LOCATION]',
                'Meniscus teat [LOCATION]',
                'MI (Myocardial Infarction)',
                'Mild cognitive impairment',
                'Multiple sclerosis',
                'NG (Nasogastric) tube',
                'Oral dysphagia',
                'Oropharyngeal dysphagia',
                'Osteoarthritis of [LOCATION]',
                'Osteoporosis',
                "Parkinson's Disease",
                'PCL (Posterior Cruciate Ligament) tear surgically repaired [LOCATION]',
                'PCL (Posterior Cruciate Ligament) tear',
                'Pharyngeal dysphagia',
                'Pneumonia',
                'Pulmonary fibrosis',
                'PVD (Peripheral Vascular Disease)',
                'Rheumatoid arthritis in [LOCATION]',
                'Rotator cuff surgery [RIGHT OR LEFT]',
                'Rotator cuff tear [RIGHT OR LEFT]',
                'Scoliosis',
                'Sepsis',
                'Shoulder Replacement [RIGHT OR LEFT]',
                'Spinal Stenosis [LOCATION]',
                'Stage II multiple wounds on [LOCATION]',
                'Stage III wound on [LOCATION]',
                'Stage IV wound on [LOCATION]',
                'Surgery of [LOCATION]',
                'TIA (Transient Ischemic Attack)',
                'Tracheostomy',
                'TURP (Transurethral Resection of the Prostate)',
                'UTI (Urinary Tract Infection)',
                'Venous stasis ulcer [LOCATION]',
            ],
            OT: [
                'Amputation [LOCATION]',
                'Compression fracture [LOCATION]',
                'Wounds [LOCATION]',
                'Tendinosis [LOCATION]',
                'Venous stasis ulcer [LOCATION]',
                'Achilles tendon tear [LOCATION]',
                'ACL tear surgically repaired [LOCATION]',
                'Above knee amputation (AKA) [LOCATION]',
                'Below knee amputation (BKE) [LOCATION]',
                'Cancer (SITE/TYPE)',
                'Surgery (TYPE)',
                'AAA (Abdominal Aortic Aneurysm)',
                'Achilles tendon tear [LOCATION]',
                'Acute Renal Failure',
                'AIDS (Acquired Immune Deficiency Syndrome)',
                "Alzheimer's Disease",
                'Anemia',
                'Angina',
                'Anxiety',
                'ASHD (Arteriosclerotic Heart Disease)',
                'Atrial Fibrillation',
                'Bipolar Disorder',
                'Bowel Obstruction',
                'C-Diff',
                'Coronary Artery Bypass Graft (CABG)',
                'CAD (Coronary Artery Disease)',
                'Carpal tunnel syndrome',
                'Chronic bronchitis',
                'Chronic renal failure',
                'Colostomy',
                'COPD (Chronic Obstructive Pulmonary Disease)',
                'CRPS (Complex Regional Pain Syndrome)',
                'CVA (Cerebrovascular Accident)',
                'CVI (Chronic Venous Insufficiency)',
                'DDD (Degenerative Disc Disease)',
                'Depression',
                'Diabetes -- Type 1',
                'Diabetes -- Type2',
                'Dialysis (TYPE & FREQUENCY)',
                'Diverticulitis',
                'DJD (Degenerative Joint Disease) [JOINT/LOCATION]',
                'Emphysema',
                'Esophageal dysphagia',
                'ESRD (End Stage Renal Disease)',
                'ETOH abuse',
                'Fibromyalgia',
                'Fracture of [LOCATION]',
                'GERD (Gastroesophageal Reflux Disease)',
                'GI Bleed',
                'Gout',
                'Head Injury [TYPE]',
                'Heart Failure',
                'Hip Fracture [LOCATION]',
                'Hip Replacement [LOCATION]',
                'History of aspiration',
                'History of falls',
                'HIV (Human Immunodeficiency Virus)',
                'HTN (hypertension)',
                "Huntington's Disease",
                'Hypothyroidism',
                'Hysterectomy',
                'Inguinal hernia repair',
                'Kyphoscoliosis',
                'Kyphosis',
                'Hemiplegia [RIGHT/LEFT]',
                'Low vision',
                'Lower lobe infiltrate [LOCATION]',
                'Lymphedema [LOCATION]',
                'Meniscus teat [LOCATION]',
                'MI (Myocardial Infarction)',
                'Mild cognitive impairment',
                'Multiple sclerosis',
                'NG (Nasogastric) tube',
                'Oral dysphagia',
                'Oropharyngeal dysphagia',
                'Osteoarthritis of [LOCATION]',
                'Osteoporosis',
                "Parkinson's Disease",
                'PCL (Posterior Cruciate Ligament) tear surgically repaired [LOCATION]',
                'PCL (Posterior Cruciate Ligament) tear ',
                'Pharyngeal dysphagia',
                'Pneumonia',
                'Pulmonary fibrosis',
                'PVD (Peripheral Vascular Disease)',
                'Rheumatoid arthritis in [LOCATION]',
                'Rotator cuff surgery [RIGHT OR LEFT]',
                'Rotator cuff tear [RIGHT OR LEFT]',
                'Scoliosis',
                'Sepsis',
                'Shoulder Replacement [RIGHT OR LEFT]',
                'Spinal Stenosis [LOCATION]',
                'Stage II multiple wounds on [LOCATION]',
                'Stage III wound on [LOCATION]',
                'Stage IV wound on [LOCATION]',
                'Surgery of [LOCATION]',
                'TIA (Transient Ischemic Attack)',
                'Tracheostomy',
                'TURP (Transurethral Resection of the Prostate)',
                'UTI (Urinary Tract Infection)',
                'Venous stasis ulcer [LOCATION]',
            ],
            ST: [
                'Amputation [LOCATION]',
                'Wounds [LOCATION]',
                'Tendinosis [LOCATION]',
                'Venous stasis ulcer [LOCATION]',
                'Achilles tendon tear [LOCATION]',
                'ACL tear surgically repaired [LOCATION]',
                'Above knee amputation (AKA) [LOCATION]',
                'Below knee amputation (BKE) [LOCATION]',
                'Cancer (SITE/TYPE)',
                'Surgery (TYPE)',
                'AAA (Abdominal Aortic Aneurysm)',
                'Achilles tendon tear [LOCATION]',
                'Acute Renal Failure',
                'AIDS (Acquired Immune Deficiency Syndrome)',
                "Alzheimer's Disease",
                'Anemia',
                'Angina',
                'Anxiety',
                'ASHD (Arteriosclerotic Heart Disease)',
                'Atrial Fibrillation',
                'Bipolar Disorder',
                'Bowel Obstruction',
                'C-Diff',
                'Coronary Artery Bypass Graft (CABG)',
                'CAD (Coronary Artery Disease)',
                'Carpal tunnel syndrome',
                'Chronic bronchitis',
                'Chronic renal failure',
                'Colostomy',
                'COPD (Chronic Obstructive Pulmonary Disease)',
                'CRPS (Complex Regional Pain Syndrome)',
                'CVA (Cerebrovascular Accident)',
                'CVI (Chronic Venous Insufficiency)',
                'DDD (Degenerative Disc Disease)',
                'Depression',
                'Diabetes -- Type 1',
                'Diabetes -- Type2',
                'Dialysis (TYPE & FREQUENCY)',
                'Diverticulitis',
                'DJD (Degenerative Joint Disease) [JOINT/LOCATION]',
                'Emphysema',
                'Esophageal dysphagia',
                'ESRD (End Stage Renal Disease)',
                'ETOH abuse',
                'Fibromyalgia',
                'Fracture of [LOCATION]',
                'GERD (Gastroesophageal Reflux Disease)',
                'GI Bleed',
                'Gout',
                'Head Injury [TYPE]',
                'Heart Failure',
                'Hip Fracture [LOCATION]',
                'Hip Replacement [LOCATION]',
                'History of aspiration',
                'History of falls',
                'HIV (Human Immunodeficiency Virus)',
                'HTN (hypertension)',
                "Huntington's Disease",
                'Hypothyroidism',
                'Hysterectomy',
                'Inguinal hernia repair',
                'Kyphoscoliosis',
                'Kyphosis',
                'Hemiplegia [RIGHT/LEFT]',
                'Low vision',
                'Lower lobe infiltrate [LOCATION]',
                'Lymphedema [LOCATION]',
                'Meniscus teat [LOCATION]',
                'MI (Myocardial Infarction)',
                'Mild cognitive impairment',
                'Multiple sclerosis',
                'NG (Nasogastric) tube',
                'Oral dysphagia',
                'Oropharyngeal dysphagia',
                'Osteoarthritis of [LOCATION]',
                'Osteoporosis',
                "Parkinson's Disease",
                'PCL (Posterior Cruciate Ligament) tear surgically repaired [LOCATION]',
                'PCL (Posterior Cruciate Ligament) tear ',
                'Pharyngeal dysphagia',
                'Pneumonia',
                'Pulmonary fibrosis',
                'PVD (Peripheral Vascular Disease)',
                'Rheumatoid arthritis in [LOCATION]',
                'Rotator cuff surgery [RIGHT OR LEFT]',
                'Rotator cuff tear [RIGHT OR LEFT]',
                'Scoliosis',
                'Sepsis',
                'Shoulder Replacement [RIGHT OR LEFT]',
                'Spinal Stenosis [LOCATION]',
                'Stage II multiple wounds on [LOCATION]',
                'Stage III wound on [LOCATION]',
                'Stage IV wound on [LOCATION]',
                'Surgery of [LOCATION]',
                'TIA (Transient Ischemic Attack)',
                'Tracheostomy',
                'TURP (Transurethral Resection of the Prostate)',
                'UTI (Urinary Tract Infection)',
                'Venous stasis ulcer [LOCATION]',
                'Venous stasis ulcer [LOCATION]',
            ],
        };
    }
    getMedications() {
        return {
            PT: [
                'Angina Medication: Patient is at risk for Dizziness, Headache or Tachycardia with impact on Therapy POC of _______________.',
                'Anti-Anxiety Medication: at risk for Abnormal thinking, Anxiety, Arrhythmias, Clumsiness, Dizziness, Drowsiness, Dry mouth, GI disturbances, Headache, Increased appetite, Loss of appetite, Orthostatic hypotension, Sedation, Seizures, Tachycardia, Unsteadiness, Weakness or Weight gain with impact on Therapy POC of _____________.',
                'Anti-Arrhythmic Agents: at risk for Arrhythmias, Confusion, EKG changes, Hallucinations, Hepatotoxicity, Increased blood pressure, Increased heart rate, Lethargy or Toxicity with impact on Therapy POC of',
                'Anti-Coagulant medications: with potential risk for hemorrhage (including rectal bleeding and coughing up blood), and heparin-induced thrombocytopenia(HIT syndrome). Potential impact on therapy progress includes _________.',
                'Anti-Depressant medications: with potential risk for abnormal thinking, anxiety, dizziness, drowsiness, dry mouth, GI disturbance, increased appetite, loss of appetite, sedation, seizures, or weight gain. Possible impact on therapy progress includes ___________.',
                'Anti-Hypertensive: at risk for at risk for Chest pain, Cough, Depression, Dizziness, Fainting, GI disturbances, Headache, Lethargy, Lightheadedness, Nausea, Orthostatic, hypotension, Pedal edema, Possible exercise intolerance or tachycardia with impact on Therapy POC of ___________.',
                'Anti-Psychotic medications with potential risk for blurred vision, dizziness, drowsiness, dyskinesia, dystonia, GI disturbances, sedation, or weight gain. Potential impact on speech therapy progress includes _______________.',
                'Aspirin for ______________.',
                'Bronchodilator medications with potential risk for GI disturbances, nervousness, and restlessness. Impact on speech therapy includes _______________.',
                'Corticosteroid medications with potential risk for cough, depression, GI irritation, hoarseness, , increased appetite, increased risk of infection, moodiness, oral thrush. Impact on speech therapy progress includes ________.',
                "Dementia/Alzheimer's Medications at risk for Anorexia, Diarrhea, Dizziness, Hepatotoxicity, Lethargy, Loss of appetite, Muscle cramping, Nausea, Ulcers, Vomiting or Weight loss with impact on Therapy POC of ___________.",
                'Diabetic Medications at risk for Abdominal discomfort, Anemia, Back pain, Blurred vision, Constipation, Decreased body temperature, Diarrhea, Dizziness, Fatigue, Headache, Hyperglycemia, Hypoglycemia, Increased blood pressure, Lactic acidosis, Light sensitivity, Liver problems, Muscle aches, Nausea, Rapid or trouble breathing, Respiratory tract infection, Sinus inflammation, Slow or irregular heartbeat, Swelling, Vomiting or Weakness with impact on Therapy POC of ______________.',
                'Diuretics at risk for Dizziness, Electrolyte imbalance, Fatigue, Urinary frequency or Weakness with impact on Therapy POC of ___________.',
                'GERD Medications at risk for Headache, Nausea, Stomach pain or Vomiting with impact on Therapy POC of _____________.',
                'Goiter Medications at risk for Anxiety, GI disturbances, Insomnia, Lethargy, Mood swings, Weakness with impact on Therapy POC of _____________.',
                'Hypothyroid Medications at risk for Anxiety, GI disturbances, Insomnia, Lethargy, Mood swings or Weakness with impact to Therapy POC of ____________.',
                'Muscle relaxer medications with potential risk for confusion, dizziness, drowsiness, irritability, lethargy, nausea, and vomiting. Impact on therapy progress includes ____________.',
                'Narcotic Pain Medications at risk for Addiction potential, GI irritation, Liver toxicity, Mental clouding, Respiratory depression or Withdrawal risk with impact on Therapy POC of ___________.',
                'No precautions with regard to pharmacology has been identified or communicated to clinician.',
                'Non-Steroidal Anti-Inflammatories (NSAIDS) at risk for Bleeding of stomach and GI tract, Irritation or Ulceration with impact on Therapy POC of ____________.',
                'None that affect the plan of care.',
                "Parkinson's Medications at risk for Behavioral issues, Dyskinesia or GI disturbances with impact on Therapy POC of ___________.",
                'See Nursing Medication Administration Record (MAR)',
                'Zollinger-Ellison Syndrome medications at potential risk for diarrhea, nausea or stomach pain, and vomiting. Potential impact on speech therapy progress includes _______________.',
            ],
            OT: [
                'Angina Medication: Patient is at risk for Dizziness, Headache or Tachycardia with impact on Therapy POC of _______________.',
                'Anti-Anxiety Medication: at risk for Abnormal thinking, Anxiety, Arrhythmias, Clumsiness, Dizziness, Drowsiness, Dry mouth, GI disturbances, Headache, Increased appetite, Loss of appetite, Orthostatic hypotension, Sedation, Seizures, Tachycardia, Unsteadiness, Weakness or Weight gain with impact on Therapy POC of _____________.',
                'Anti-Arrhythmic Agents: at risk for Arrhythmias, Confusion, EKG changes, Hallucinations, Hepatotoxicity, Increased blood pressure, Increased heart rate, Lethargy or Toxicity with impact on Therapy POC of',
                'Anti-Coagulant medications: with potential risk for hemorrhage (including rectal bleeding and coughing up blood), and heparin-induced thrombocytopenia (HIT syndrome). Potential impact on therapy progress includes _________.',
                'Anti-Depressant medications: with potential risk for abnormal thinking, anxiety, dizziness, drowsiness, dry mouth, GI disturbance, increased appetite, loss of appetite, sedation, seizures, or weight gain. Possible impact on therapy progress includes ___________.',
                'Anti-Hypertensive: at risk for at risk for Chest pain, Cough, Depression, Dizziness, Fainting, GI disturbances, Headache, Lethargy, Lightheadedness, Nausea, Orthostatic, hypotension, Pedal edema, Possible exercise intolerance or tachycardia with impact on Therapy POC of ___________.',
                'Anti-Psychotic medications with potential risk for blurred vision, dizziness, drowsiness, dyskinesia, dystonia, GI disturbances, sedation, or weight gain. Potential impact on speech therapy progress includes _______________.',
                'Aspirin for ______________.',
                'Bronchodilator medications with potential risk for GI disturbances, nervousness, and restlessness. Impact on speech therapy includes _______________.',
                'Corticosteroid medications with potential risk for cough, depression, GI irritation, hoarseness, , increased appetite, increased risk of infection, moodiness, oral thrush. Impact on speech therapy progress includes ________.',
                "Dementia/Alzheimer's Medications at risk for Anorexia, Diarrhea, Dizziness, Hepatotoxicity, Lethargy, Loss of appetite, Muscle cramping, Nausea, Ulcers, Vomiting or Weight loss with impact on Therapy POC of ___________.",
                'Diabetic Medications at risk for Abdominal discomfort, Anemia, Back pain, Blurred vision, Constipation, Decreased body temperature, Diarrhea, Dizziness, Fatigue, Headache, Hyperglycemia, Hypoglycemia, Increased blood pressure, Lactic acidosis, Light sensitivity, Liver problems, Muscle aches, Nausea, Rapid or trouble breathing, Respiratory tract infection, Sinus inflammation, Slow or irregular heartbeat, Swelling, Vomiting or Weakness with impact on Therapy POC of ______________.',
                'Diuretics at risk for Dizziness, Electrolyte imbalance, Fatigue, Urinary frequency or Weakness with impact on Therapy POC of ___________.',
                'GERD Medications at risk for Headache, Nausea, Stomach pain or Vomiting with impact on Therapy POC of _____________.',
                'Goiter Medications at risk for Anxiety, GI disturbances, Insomnia, Lethargy, Mood swings, Weakness with impact on Therapy POC of _____________.',
                'Hypothyroid Medications at risk for Anxiety, GI disturbances, Insomnia, Lethargy, Mood swings or Weakness with impact to Therapy POC of ____________.',
                'Muscle relaxer medications with potential risk for confusion, dizziness, drowsiness, irritability, lethargy, nausea, and vomiting. Impact on therapy progress includes ____________.',
                'Narcotic Pain Medications at risk for Addiction potential, GI irritation, Liver toxicity, Mental clouding, Respiratory depression or Withdrawal risk with impact on Therapy POC of ___________.',
                'No precautions with regard to pharmacology has been identified or communicated to clinician.',
                'Non-Steroidal Anti-Inflammatories (NSAIDS) at risk for Bleeding of stomach and GI tract, Irritation or Ulceration with impact on Therapy POC of ____________.',
                'None that affect the plan of care.',
                "Parkinson's Medications at risk for Behavioral issues, Dyskinesia or GI disturbances with impact on Therapy POC of ___________.",
                'See Nursing Medication Administration Record (MAR)',
                'Zollinger-Ellison Syndrome medications at potential risk for diarrhea, nausea or stomach pain, and vomiting. Potential impact on speech therapy progress includes _______________.',
            ],
            ST: [
                'Angina Medication: Patient is at risk for Dizziness, Headache or Tachycardia with impact on Therapy POC of _______________.',
                'Anti-Arrhythmic Agents: at risk for Arrhythmias, Confusion, EKG changes, Hallucinations, Hepatotoxicity, Increased blood pressure, Increased heart rate, Lethargy or Toxicity with impact on Therapy POC of',
                'Anti-Coagulant medications: with potential risk for hemorrhage (including rectal bleeding and coughing up blood), and heparin-induced thrombocytopenia (HIT syndrome). Potential impact on therapy progress includes _________.',
                'Anti-Depressant medications: with potential risk for abnormal thinking, anxiety, dizziness, drowsiness, dry mouth, GI disturbance, increased appetite, loss of appetite, sedation, seizures, or weight gain. Possible impact on therapy progress includes ___________.',
                'Anti-Hypertensive: at risk for at risk for Chest pain, Cough, Depression, Dizziness, Fainting, GI disturbances, Headache, Lethargy, Lightheadedness, Nausea, Orthostatic, hypotension, Pedal edema, Possible exercise intolerance or tachycardia with impact on Therapy POC of ___________.',
                'Anti-Psychotic medications with potential risk for blurred vision, dizziness, drowsiness, dyskinesia, dystonia, GI disturbances, sedation, or weight gain. Potential impact on speech therapy progress includes _______________.',
                'Aspirin for ______________.',
                'Bronchodilator medications with potential risk for GI disturbances, nervousness, and restlessness. Impact on speech therapy includes _______________.',
                'Corticosteroid medications with potential risk for cough, depression, GI irritation, hoarseness, , increased appetite, increased risk of infection, moodiness, oral thrush. Impact on speech therapy progress includes ________.',
                "Dementia/Alzheimer's Medications at risk for Anorexia, Diarrhea, Dizziness, Hepatotoxicity, Lethargy, Loss of appetite, Muscle cramping, Nausea, Ulcers, Vomiting or Weight loss with impact on Therapy POC of ___________.",
                'Diabetic Medications at risk for Abdominal discomfort, Anemia, Back pain, Blurred vision, Constipation, Decreased body temperature, Diarrhea, Dizziness, Fatigue, Headache, Hyperglycemia, Hypoglycemia, Increased blood pressure, Lactic acidosis, Light sensitivity, Liver problems, Muscle aches, Nausea, Rapid or trouble breathing, Respiratory tract infection, Sinus inflammation, Slow or irregular heartbeat, Swelling, Vomiting or Weakness with impact on Therapy POC of ______________.',
                'Diuretics at risk for Dizziness, Electrolyte imbalance, Fatigue, Urinary frequency or Weakness with impact on Therapy POC of ___________.',
                'GERD Medications at risk for Headache, Nausea, Stomach pain or Vomiting with impact on Therapy POC of _____________.',
                'Goiter Medications at risk for Anxiety, GI disturbances, Insomnia, Lethargy, Mood swings, Weakness with impact on Therapy POC of _____________.',
                'Hypothyroid Medications at risk for Anxiety, GI disturbances, Insomnia, Lethargy, Mood swings or Weakness with impact to Therapy POC of ____________.',
                'Muscle relaxer medications with potential risk for confusion, dizziness, drowsiness, irritability, lethargy, nausea, and vomiting. Impact on therapy progress includes ____________.',
                'Narcotic Pain Medications at risk for Addiction potential, GI irritation, Liver toxicity, Mental clouding, Respiratory depression or Withdrawal risk with impact on Therapy POC of ___________.',
                'No precautions with regard to pharmacology has been identified or communicated to clinician.',
                'Non-Steroidal Anti-Inflammatories (NSAIDS) at risk for Bleeding of stomach and GI tract, Irritation or Ulceration with impact on Therapy POC of ____________.',
                'None that affect the plan of care.',
                "Parkinson's Medications at risk for Behavioral issues, Dyskinesia or GI disturbances with impact on Therapy POC of ___________.",
                'See Nursing Medication Administration Record (MAR)',
                'Zollinger-Ellison Syndrome medications at potential risk for diarrhea, nausea or stomach pain, and vomiting. Potential impact on speech therapy progress includes _______________.',
            ],
        };
    }
    getSocialDeterminantsOfHealth() {
        return {
            PT: [
                'Income / socioeconomic conditions: ',
                'Education: ',
                'Unemployment and job insecurity: ',
                'Working life conditions: ',
                'Food insecurity: ',
                'Housing, basic amenities and the environment: ',
                'Early childhood development: ',
                'Social inclusion and non-discrimination:',
                'Structural conflict:',
                'Access to affordable health services of decent quality:',
            ],
            OT: [
                'Income / socioeconomic conditions: ',
                'Education: ',
                'Unemployment and job insecurity: ',
                'Working life conditions: ',
                'Food insecurity: ',
                'Housing, basic amenities and the environment: ',
                'Early childhood development: ',
                'Social inclusion and non-discrimination:',
                'Structural conflict:',
                'Access to affordable health services of decent quality:',
            ],
            ST: [
                'Income / socioeconomic conditions: ',
                'Unemployment and job insecurity: ',
                'Working life conditions: ',
                'Food insecurity: ',
                'Housing, basic amenities and the environment: ',
                'Early childhood development: ',
                'Social inclusion and non-discrimination:',
                'Structural conflict:',
                'Access to affordable health services of decent quality:',
            ],
        };
    }
    getEnvironmentalFactors() {
        return {
            PT: [
                'DME/Assistive Device(s) include: ',
                'Environmental home factors include: ',
                "Patient requires assistance from [CAREGIVER] for [HOURS] for [ACTIVITIES/ADL'S].",
                'Patient did not use assistive devices or DME at home.',
                'Patient had meals on wheels.',
                'Patient has caregiver help at home who will be able to provide assistance upon discharge.',
                'Patient lived alone at home prior to admission and will [HAVE or HAVE NOT] assistance at home from [CAREGIVER] upon discharge.',
                'Patient lives alone.',
                'Patient lives with caregiver who provides support/aid for ____________.',
                'Patient lives with spouse/significant other.',
                'Patient needs to clime [NUMBER] stairs [WITH/WITHOUT] railing in order to reach [ROOM].',
                'Patient uses adaptive equipment at home including [EQUIPMENT] and has the following home modifications __________.',
                'Patient was able to complete community activities (driving, shopping, community ambulation, etc.) independently.',
                'Patient was independent in household functional activities and mobility and required no caregiver support.',
                "Patient's Responsible Party's (RP) name and contact # are:",
                'The patient will return to prior residence which has been modified with adaptive equipment to assist with safe mobility.',
                'The patient will return to prior residence which has not been modified with any adaptive equipment.',
            ],
            OT: [
                'DME/Assistive Device(s) include: ',
                'Environmental home factors include: ',
                "Patient requires assistance from [CAREGIVER] for [HOURS] for [ACTIVITIES/ADL'S].",
                'Patient did not use assistive devices or DME at home.',
                'Patient had meals on wheels.',
                'Patient has caregiver help at home who will be able to provide assistance upon discharge.',
                'Patient lived alone at home prior to admission and will [HAVE or HAVE NOT] assistance at home from [CAREGIVER] upon discharge.',
                'Patient lives alone.',
                'Patient lives with caregiver who provides support/aid for ____________.',
                'Patient lives with spouse/significant other.',
                'Patient needs to clime [NUMBER] stairs [WITH/WITHOUT] railing in order to reach [ROOM].',
                'Patient uses adaptive equipment at home including [EQUIPMENT] and has the following home modifications __________.',
                'Patient was able to complete community activities (driving, shopping, community ambulation, etc.) independently.',
                'Patient was independent in household functional activities and mobility and required no caregiver support.',
                "Patient's Responsible Party's (RP) name and contact # are:",
                'The patient will return to prior residence which has been modified with adaptive equipment to assist with safe mobility.',
                'The patient will return to prior residence which has not been modified with any adaptive equipment.',
            ],
            ST: [
                'DME/Assistive Device(s) include: ',
                "Patient requires assistance from [CAREGIVER] for [HOURS] for [ACTIVITIES/ADL'S].",
                'Patient did not use assistive devices or DME at home.',
                'Patient had meals on wheels.',
                'Patient has caregiver help at home who will be able to provide assistance upon discharge.',
                'Patient lived alone at home prior to admission and will [HAVE or HAVE NOT] assistance at home from [CAREGIVER] upon discharge.',
                'Patient lives alone.',
                'Patient lives with caregiver who provides support/aid for ____________.',
                'Patient lives with spouse/significant other.',
                'Patient needs to clime [NUMBER] stairs [WITH/WITHOUT] railing in order to reach [ROOM].',
                'Patient uses adaptive equipment at home including [EQUIPMENT] and has the following home modifications __________.',
                'Patient was able to complete community activities (driving, shopping, community ambulation, etc.) independently.',
                'Patient was independent in household functional activities and mobility and required no caregiver support.',
                "Patient's Responsible Party's (RP) name and contact # are:",
                'The patient will return to prior residence which has been modified with adaptive equipment to assist with safe mobility.',
                'The patient will return to prior residence which has not been modified with any adaptive equipment.',
                "Patient's caregiver/spouse/significant other provided assist with [COMMUNICATION AND/OR SAFE SWALLOWING].",
                "The patient's caregiver/spouse/significant other will be able to provide assist with [COMMUNICATION AND/OR SAFE SWALLOWING] upon discharge.",
            ],
        };
    }
    getPreviousTherapy() {
        return {
            PT: [
                'Patient had physical therapy during the recent inpatient hospitalization.',
                'Patient has not received Physical Therapy for this current medical condition in the past year.',
                'Prior Physical Therapy received on [DATE] for ___________.',
                'Prior Physical Therapy for [CONDITION] included [TYPE of THERAPY] in [MONTH/YEAR] with good results.',
                'Patient has not received Physical Therapy for this current medical condition in the past year and had yet to achieve LTGs prior to being hospitalized.',
                'Prior to this recent hospitalization, the patient had been on therapy case load for [TIME]and was still working to achieve LTGs before being hospitalized.',
            ],
            OT: [
                'Patient had occupational therapy during the recent inpatient hospitalization.',
                'Patient has not received Occupational Therapy for this current medical condition in the past year.',
                'Prior Occupational Therapy received on [DATE] for ___________.',
                'Prior Occupational Therapy for [CONDITION] included [TYPE of THERAPY] in [MONTH/YEAR] with good results.',
                'Patient has not received Occupational Therapy for this current medical condition in the past year and had yet to achieve LTGs prior to being hospitalized.',
                'Prior to this recent hospitalization, the patient had been on therapy case load for [TIME]and was still working to achieve LTGs before being hospitalized.',
            ],
            ST: [
                'Patient had speech therapy during the recent inpatient hospitalization.',
                'Prior Speech Therapy received on [DATE] for ___________.',
                'Prior Speech Therapy for [CONDITION] included [TYPE of THERAPY] in [MONTH/YEAR] with good results.',
                'Patient has not received Speech Therapy for this current medical condition in the past year and had yet to achieve LTGs prior to being hospitalized.',
                'Prior to this recent hospitalization, the patient had been on therapy case load for [TIME]and was still working to achieve LTGs before being hospitalized.',
            ],
        };
    }
    getPrecautions() {
        return {
            PT: [
                'Aspiration Risk',
                "Assess SaO2 for < 92% (or within the patient's pre-existing levels) throughout treatment secondary to increased potential for post-operative DVTs / PEs.",
                'Decreased sensation or non-intact skin.',
                'Cardiac',
                'Cease exercise/activity SpO2 < 88 - 90%, RPE > 16, RR > 45',
                'Check for modified diet / oral intake restrictions related to swallowing impairments. Consult ST as appropriate.',
                'Check INR lab results prior to activity if patient on blood thinners.',
                'Closely monitor anxiety or stress due to increased SOB/dyspnea and cease activity/exercise until patient is able to control this response',
                'Code Status: ',
                'Confirm surgical approach and discoloration or other precautions.',
                'Confirm surgical procedure and specific precautions based on procedure (e.g., no twisting/bending/lifting, need for post-op brace, limiting time in sitting, etc.).',
                'Confirm weight bearing status as defined by the surgeon.',
                'Continuous pulse oximetry (SpO2) during all periods of sleep (day and night) and when out of line of sight of a competent caregiver.',
                'Precautions for exercise include: ',
                'Depression',
                'Diabetic diet',
                'Do not apply ultrasound over joint cement or plastic implants.',
                'Fall risk',
                'Fluid restriction',
                'High fall risk related to cognitive, motor, perceptual, and sensory deficits',
                'Hip precaution',
                'HR response to exercise may be diminished if patient is on beta-blocker; use RPE in conjunction with HR.',
                'Impulsive tendencies, restrict patient performance in unsupervised tasks',
                'Include interventions to control and reduce post-operative swelling.',
                'Instruct in importance of participating in strengthening exercise and joint protection techniques.',
                'Isolation',
                'Lifting precaution',
                'Lymphedema',
                'Maintain detailed records of the date of last tracheostomy tube change, type and size of tracheotomy tube (including inner diameter, outer diameter, length cuffed or uncuffed tube, cuff inflation, suctioning distance, critical alert if applicable) to be accessible to caregivers.',
                'Monitor blood pressure (BP), SpO2, heart rate (HR), and rhythm before, during, and after therapy intervention.',
                'Monitor exercise response - increased pain, swelling, dyspnea, dizziness.',
                'Monitor for early warning signs of obstruction including tachypnea, increased work of breathing, abnormal breath sounds, tachycardia, and a decrease in SpO2 levels.',
                'Monitor for late signs of obstruction include cyanosis, bradycardia, and apnea. Do not wait for these to develop before intervening.',
                'Monitor for respiratory dysfunction',
                'Monitor for signs of major depression, especially thoughts of death or suicide.',
                'Monitor incision site for excessive heat, redness, swelling, and drainage.',
                'Monitor skin breakdown related to static positioning and splinting equipment',
                'Neurological changes/symptoms (e.g., tingling, numbness, weakness [foot drop], signs of central nervous system disorders) warrant a thorough neurological examination.',
                'No Valsalva: Consider use of stool softeners.',
                'NPO',
                'Oxygen [LITERS AND ROUTE; PRN OR CONTINUOUS]',
                'Patients with uncontrolled dyspnea are not able to participate in an exercise program.',
                'Positioning - keep foot bed flat, no pillows under knee.',
                'Required Equipment/Devices ___________',
                'Safety precautions (environmental) to reduce opportunities to cause self-harm',
                'Seizure',
                'Compromised skin integrity [LOCATION]',
                'Sternotomy precautions: No lifting/pushing/pulling > 5 pounds first 6 weeks s/p sternotomy.',
                'Supplemental oxygen may be required at rest and/or during activity or exercise.',
                'Syncope',
                'Weight bearing restrictions',
                'Vision impairment',
                'Hearing impairment',
            ],
            OT: [
                'Aspiration Risk',
                "Assess SaO2 for < 92% (or within the patient's pre-existing levels) throughout treatment secondary to increased potential for post-operative DVTs / PEs.",
                'Decreased sensation or non-intact skin.',
                'Cardiac',
                'Cease exercise/activity SpO2 < 88 - 90%, RPE > 16, RR > 45',
                'Check for modified diet / oral intake restrictions related to swallowing impairments. Consult ST as appropriate.',
                'Check INR lab results prior to activity if patient on blood thinners.',
                'Closely monitor anxiety or stress due to increased SOB/dyspnea and cease activity/exercise until patient is able to control this response',
                'Code Status: ',
                'Confirm surgical approach and discoloration or other precautions.',
                'Confirm surgical procedure and specific precautions based on procedure (e.g., no twisting/bending/lifting, need for post-op brace, limiting time in sitting, etc.).',
                'Confirm weight bearing status as defined by the surgeon.',
                'Continuous pulse oximetry (SpO2) during all periods of sleep (day and night) and when out of line of sight of a competent caregiver.',
                'Precautions for exercise include: ',
                'Depression',
                'Diabetic diet',
                'Do not apply ultrasound over joint cement or plastic implants.',
                'Fall risk',
                'Fluid restriction',
                'High fall risk related to cognitive, motor, perceptual, and sensory deficits',
                'Hip precaution',
                'HR response to exercise may be diminished if patient is on beta-blocker; use RPE in conjunction with HR.',
                'Impulsive tendencies, restrict patient performance in unsupervised tasks',
                'Include interventions to control and reduce post-operative swelling.',
                'Instruct in importance of participating in strengthening exercise and joint protection techniques.',
                'Isolation',
                'Lifting precaution',
                'Lymphedema',
                'Maintain detailed records of the date of last tracheostomy tube change, type and size of tracheotomy tube (including inner diameter, outer diameter, length cuffed or uncuffed tube, cuff inflation, suctioning distance, critical alert if applicable) to be accessible to caregivers.',
                'Monitor blood pressure (BP), SpO2, heart rate (HR), and rhythm before, during, and after therapy intervention.',
                'Monitor exercise response - increased pain, swelling, dyspnea, dizziness.',
                'Monitor for early warning signs of obstruction including tachypnea, increased work of breathing, abnormal breath sounds, tachycardia, and a decrease in SpO2 levels.',
                'Monitor for late signs of obstruction include cyanosis, bradycardia, and apnea. Do not wait for these to develop before intervening.',
                'Monitor for respiratory dysfunction',
                'Monitor for signs of major depression, especially thoughts of death or suicide.',
                'Monitor incision site for excessive heat, redness, swelling, and drainage.',
                'Monitor skin breakdown related to static positioning and splinting equipment',
                'Neurological changes/symptoms (e.g., tingling, numbness, weakness [foot drop], signs of central nervous system disorders) warrant a thorough neurological examination.',
                'No Valsalva: Consider use of stool softeners.',
                'NPO',
                'Patients with uncontrolled dyspnea are not able to participate in an exercise program.',
                'Oxygen [LITERS AND ROUTE; PRN OR CONTINUOUS]',
                'Positioning - keep foot bed flat, no pillows under knee.',
                'Required Equipment/Devices ___________',
                'Safety precautions (environmental) to reduce opportunities to cause self-harm',
                'Seizure',
                'Compromised skin integrity [LOCATION]',
                'Sternotomy precautions: No lifting/pushing/pulling > 5 pounds first 6 weeks s/p sternotomy.',
                'Supplemental oxygen may be required at rest and/or during activity or exercise.',
                'Syncope',
                'Weight bearing restrictions',
                'Vision impairment',
                'Hearing impairment',
            ],
            ST: [
                'Altered diet - Liquid [CONSISTENCY]',
                'Aspiration Risk',
                'Decreased sensation or non-intact skin.',
                'Cardiac',
                'Check for modified diet / oral intake restrictions related to swallowing impairments.',
                'Check INR lab results prior to activity if patient on blood thinners.',
                'Closely monitor anxiety or stress due to increased SOB/dyspnea and cease activity/exercise until patient is able to control this response',
                'Code Status: ',
                'Confirm surgical approach and discoloration or other precautions.',
                'Continuous pulse oximetry (SpO2) during all periods of sleep (day and night) and when out of line of sight of a competent caregiver.',
                'Precautions for exercise include: ',
                'Depression',
                'Diabetic diet',
                'Fall risk',
                'Fluid restriction',
                'High fall risk related to cognitive, motor, perceptual, and sensory deficits',
                'Hip precaution',
                'Impulsive tendencies, restrict patient performance in unsupervised tasks',
                'Isolation',
                'Lymphedema',
                'Maintain detailed records of the date of last tracheostomy tube change, type and size of tracheotomy tube (including inner diameter, outer diameter, length cuffed or uncuffed tube, cuff inflation, suctioning distance, critical alert if applicable) to be accessible to caregivers.',
                'Monitor blood pressure (BP), SpO2, heart rate (HR), and rhythm before, during, and after therapy intervention.',
                'Monitor for early warning signs of obstruction including tachypnea, increased work of breathing, abnormal breath sounds, tachycardia, and a decrease in SpO2 levels.',
                'Monitor for late signs of obstruction include cyanosis, bradycardia, and apnea. Do not wait for these to develop before intervening.',
                'Monitor for respiratory dysfunction',
                'Monitor for signs of major depression, especially thoughts of death or suicide.',
                'NPO',
                'Oxygen [LITERS AND ROUTE; PRN OR CONTINUOUS]',
                'Required Equipment/Devices ___________',
                'Safety precautions (environmental) to reduce opportunities to cause self-harm',
                'Seizure',
                'Compromised skin integrity [LOCATION]',
                'Syncope',
                'Vision impairment',
                'Hearing impairment',
            ],
        };
    }
    getContraindications() {
        return {
            PT: [
                'Active blood transfusion',
                'Cardiac Arrhythmia',
                'Chemotherapy in the last [HOURS]',
                'Complaint of chest pain',
                'DVT',
                'Hypertension [VALUES]',
                'Inflammation or infection in the heart.',
                'Oxygen saturation lower than [VALUE]',
                'Pacemaker',
                'Pulmonary infarction',
                'Recent changes in EKG',
                'Severe aortic stenosis',
                'Severe dehydration',
                'Severe diaphoresis',
                'Severe orthostatic hypotension',
                'Severe shortness of breath/dyspnea',
                'Significantly elevated potassium levels',
                'Significantly low potassium levels',
                'Suspected or known dissecting aneurysm',
                'Systemic infection',
                'Uncontrolled diabetes with blood sugar levels greater than [VALUE] or less than [Value] ',
                'Unstable angina',
                'Untreated blood clots',
            ],
            OT: [
                'Active blood transfusion',
                'Cardiac Arrhythmia',
                'Chemotherapy in the last [HOURS]',
                'Complaint of chest pain',
                'DVT',
                'Hypertension [VALUES]',
                'Inflammation or infection in the heart.',
                'Oxygen saturation lower than [VALUE]',
                'Pacemaker',
                'Pulmonary infarction',
                'Recent changes in EKG',
                'Severe aortic stenosis',
                'Severe dehydration',
                'Severe diaphoresis',
                'Severe orthostatic hypotension',
                'Severe shortness of breath/dyspnea',
                'Significantly elevated potassium levels',
                'Significantly low potassium levels',
                'Suspected or known dissecting aneurysm',
                'Systemic infection',
                'Uncontrolled diabetes with blood sugar levels greater than [VALUE] or less than [Value] ',
                'Unstable angina',
                'Untreated blood clots',
            ],
            ST: [
                'Active blood transfusion',
                'Chemotherapy in the last [HOURS]',
                'Complaint of chest pain',
                'DVT',
                'Hypertension [VALUES]',
                'Inflammation or infection in the heart.',
                'Oxygen saturation lower than [VALUE]',
                'Pacemaker',
                'Pulmonary infarction',
                'Recent changes in EKG',
                'Severe aortic stenosis',
                'Severe dehydration',
                'Severe diaphoresis',
                'Severe orthostatic hypotension',
                'Severe shortness of breath/dyspnea',
                'Significantly elevated potassium levels',
                'Significantly low potassium levels',
                'Suspected or known dissecting aneurysm',
                'Systemic infection',
                'Uncontrolled diabetes with blood sugar levels greater than [VALUE] or less than [Value] ',
                'Unstable angina',
                'Untreated blood clots',
            ],
        };
    }
    getAnticipatedDischargePlans() {
        return {
            PT: [
                'Anticipate discharge to an Assisted Living Facility.',
                'Anticipate discharge to another SNF.',
                'Anticipate discharge to home.',
                'Anticipate discharge to hospice care.',
                'Anticipate discharge to this SNF.',
                'Anticipate patient will need [FULL/PART TIME] caregiver assistance.',
                'Anticipate patient will need [ASSISTANCE LEVEL] assistance from [CAREGIVER].',
                'Anticipate patient will need 24-hour caregiver assistance.',
                'Anticipate patient will need no caregiver assistance.',
                'Discharge home and independent with caregiver.',
                'Discharge home and independent without caregiver.',
                'Discharge home and return to community activities.',
                'Discharge home and return to vocational activities.',
                'Discharge to home with patient continuing therapy services with out patient therapy.',
                'Discharge to home with patient continuing therapy with Home Health.',
                'Discharge to home with patient planning to live alone.',
                'DME - the following DME for this patient is recommended by Physical Therapy:',
                'Projected next site of care: ARU',
                'Projected next site of care: Assisted Living Facility',
                'Projected next site of care: Home',
                'Projected next site of care: Hospice',
                'Projected next site of care: Independent Living Facility',
                'Projected next site of care: LTAC',
                'Projected next site of care: Rehab Hospital /IRF',
                'Upon discharge, patient plans to return home alone.',
                'Upon discharge, patient will return home and receive rehab services as an outpatient.',
                'Upon discharge, patient will return home with Home Health rehab services.',
            ],
            OT: [
                'Anticipate discharge to an Assisted Living Facility.',
                'Anticipate discharge to another SNF.',
                'Anticipate discharge to home.',
                'Anticipate discharge to hospice care.',
                'Anticipate discharge to this SNF.',
                'Anticipate patient will need [FULL/PART TIME] caregiver assistance.',
                'Anticipate patient will need [ASSISTANCE LEVEL] assistance from [CAREGIVER].',
                'Anticipate patient will need 24-hour caregiver assistance.',
                'Anticipate patient will need no caregiver assistance.',
                'Discharge home and independent with caregiver.',
                'Discharge home and independent without caregiver.',
                'Discharge home and return to community activities.',
                'Discharge home and return to vocational activities.',
                'Discharge to home with patient continuing therapy services with out patient therapy.',
                'Discharge to home with patient continuing therapy with Home Health.',
                'Discharge to home with patient planning to live alone.',
                'DME - the following DME for this patient is recommended by Occupational Therapy:',
                'Projected next site of care: ARU',
                'Projected next site of care: Assisted Living Facility',
                'Projected next site of care: Home',
                'Projected next site of care: Hospice',
                'Projected next site of care: Independent Living Facility',
                'Projected next site of care: LTAC',
                'Projected next site of care: Rehab Hospital /IRF',
                'Upon discharge, patient plans to return home alone.',
                'Upon discharge, patient will return home and receive rehab services as an outpatient.',
                'Upon discharge, patient will return home with Home Health rehab services.',
            ],
            ST: [
                'Anticipate [ASSIST LEVELS] at meal times.',
                'Anticipate an ability to use an effective communication system.',
                'Anticipate assistance of external aids to communicate.',
                'Anticipate diet restrictions which may include ___________.',
                'Anticipate discharge to an Assisted Living Facility.',
                'Anticipate discharge to another SNF.',
                'Anticipate discharge to home.',
                'Anticipate discharge to hospice care.',
                'Anticipate discharge to this SNF.',
                'Anticipate independent dining.',
                'Anticipate no diet restrictions.',
                'Anticipate no signs or symptoms of aspiration with least restrictive diet level.',
                'Anticipate patient will need [FULL/PART TIME] caregiver assistance.',
                'Anticipate patient will need [ASSISTANCE LEVEL] assistance from [CAREGIVER].',
                'Anticipate patient will need 24-hour caregiver assistance.',
                'Anticipate patient will need no caregiver assistance.',
                'Discharge home and independent with caregiver.',
                'Discharge home and independent without caregiver.',
                'Discharge home and return to community activities.',
                'Discharge home and return to vocational activities.',
                'Discharge home with ability to communicate to familiar listeners.',
                'Discharge home with diet restrictions.',
                'Discharge home with effective communication system.',
                'Discharge home with no diet restrictions.',
                'Discharge to [SETTING] with assistance of external aids to communicate.',
                'Discharge to home with patient continuing therapy with Home Health.',
                'Discharge to home with patient planning to live alone.',
                'DME - the following DME for this patient is recommended by Speech Therapy:',
                'Projected next site of care: ARU',
                'Projected next site of care: Assisted Living Facility',
                'Projected next site of care: Home',
                'Projected next site of care: Hospice',
                'Projected next site of care: Independent Living Facility',
                'Projected next site of care: LTAC',
                'Projected next site of care: Rehab Hospital /IRF',
                'Upon discharge, patient plans to return home alone.',
                'Upon discharge, patient will return home and receive rehab services as an outpatient.',
                'Upon discharge, patient will return home with Home Health rehab services.',
            ],
        };
    }
    getCaregiverEducation() {
        return {
            PT: [
                'Caregiver able to demonstrate _______ with competency.',
                'Caregiver educated in precautions and is able to recount information with accuracy.',
                'Caregiver education initiated with _______ focusing on the following tasks/activities __________.',
                'Home exercise program initiated with caregiver focusing on __________.',
                'Patient educated in precautions and is able to recount information with [VALUE]% accuracy.',
            ],
            OT: [
                'Caregiver able to demonstrate _______ with competency.',
                'Caregiver educated in precautions and is able to recount information with accuracy.',
                'Caregiver education initiated with _______ focusing on the following tasks/activities __________.',
                'Home exercise program initiated with caregiver focusing on __________.',
                'Patient educated in precautions and is able to recount information with [VALUE]% accuracy.',
            ],
            ST: [
                'Caregiver able to demonstrate _______ with competency.',
                'Caregiver education initiated with _______ focusing on the following tasks/activities __________.',
                'Home exercise program initiated with caregiver focusing on __________.',
                'Patient educated in precautions and is able to recount information with [VALUE]% accuracy.',
            ],
        };
    }
    getDischargeInstructions() {
        return {
            PT: [
                'Discharge planned for this patient. Recommendations discussed with patient and/or caregiver include _________.',
                'DME: The following DME is recommended for this patient by Physical Therapy: ___________.',
                'Patient discharged to _________ with recommendations including _________.',
                'Patient discharged unexpectedly to __________. Recommendations include ____________.',
            ],
            OT: [
                'Discharge planned for this patient. Recommendations discussed with patient and/or caregiver include _________.',
                'DME: The following DME is recommended for this patient by Occupational Therapy: ___________.',
                'Patient discharged to _________ with recommendations including _________.',
                'Patient discharged unexpectedly to __________. Recommendations include ____________.',
            ],
            ST: [
                'Discharge planned for this patient. Recommendations discussed with patient and/or caregiver include _________.',
                'Patient discharged to _________ with recommendations including _________.',
                'Patient discharged unexpectedly to __________. Recommendations include ____________.',
            ],
        };
    }
    getEndOfCareReason() {
        return {
            PT: [
                'Further expectation for improvement limited due to ___________.',
                'Medical condition interfering with progress, therefore discharged from services until medical condition warrants continuation.',
                'Patient has met goals and is able to ________.',
                'Patient has reached safe level of _______ and is competent to follow prescribed home exercise program.',
                'Patient responded to therapy ____________.',
                'Unexpected facility discharge - patient continues to warrant further therapy and will be re-screened upon readmission.',
                'Unstable medical condition makes continued services inappropriate at this time.',
            ],
            OT: [
                'Further expectation for improvement limited due to ___________.',
                'Medical condition interfering with progress, therefore discharged from services until medical condition warrants continuation.',
                'Patient has met goals and is able to ________.',
                'Patient has reached safe level of _______ and is competent to follow prescribed home exercise program.',
                'Patient responded to therapy ____________.',
                'Unexpected facility discharge - patient continues to warrant further therapy and will be re-screened upon readmission.',
                'Unstable medical condition makes continued services inappropriate at this time.',
            ],
            ST: [
                'Further expectation for improvement limited due to ___________.',
                'Patient has met goals and is able to ________.',
                'Patient has reached safe level of _______ and is competent to follow prescribed home exercise program.',
                'Patient responded to therapy ____________.',
                'Unexpected facility discharge - patient continues to warrant further therapy and will be re-screened upon readmission.',
                'Unstable medical condition makes continued services inappropriate at this time.',
            ],
        };
    }
    getSummaryOfSkilledServices() {
        return {
            PT: [
                '97150 - Group Therapy for _______________.',
                '97150 - Falls Management Group: 97150 - The patient participated in Falls management group with focus on: education and discussion related to decreasing fall risks through: increase awareness of falls risk factors, teaching strategies to reduce fall risk factors, discussing how falls can be prevented, explaining how therapy can assist with reducing fall risks, discussing the importance of regular exercise in reducing falls, providing exercise and program recommendations for facilitating reduction of fall risk over time and discussing what to do if you fall and strategies for getting up from a fall.',
                '97010 - Cold Pack for _______.',
                '97010 - Hot Pack for ________.',
                '97024 - Shortwave Diathermy for _________.',
                '97035 - Ultrasound for ___________.',
                '97110 - Therapeutic Exercise for _________.',
                '97112 - Neuromuscular Re-education with  focus on _______.',
                '97116 - Gait Training with a focus on ________.',
                '97129/97130 - Cognitive Function Intervention for _________.',
                '97542 - Wheelchair Management',
                '97660 - Orthotics/Prosthetics fabrication/fitting',
                'G0283 - Electrical Stimulation',
            ],
            OT: [
                '97150 - Group Therapy for _______________.',
                '97150 - Medication Management Group: The patient participated in Medication Management Group Class with focus on education and discussion related to reading/comprehending medication bottles and organization system for daily/weekly medication schedule, establishing a system for medication times, sorting medications, renewal system for medication prescriptions. The patient actively participated in the group and education and training on medication management has aided in the effective carryover for safe discharge.  ',
                '97150 - Falls Management Group: 97150 - The patient participated in Falls management group with focus on: education and discussion related to decreasing fall risks through: increase awareness of falls risk factors, teaching strategies to reduce fall risk factors, discussing how falls can be prevented, explaining how therapy can assist with reducing fall risks, discussing the importance of regular exercise in reducing falls, providing exercise and program recommendations for facilitating reduction of fall risk over time and discussing what to do if you fall and strategies for getting up from a fall.',
                '97010 - Cold Pack for _______.',
                '97010 - Hot Pack for ________.',
                '97024 - Shortwave Diathermy for ________.',
                '97035 - Ultrasound for ___________.',
                '97110 - Therapeutic Exercise for _________.',
                '97112 - Neuromuscular Re-education with  focus on _______.',
                '97116 - Gait Training with a focus on ________.',
                '97129/97130 - Cognitive Function Intervention for _________.',
                '97530 - Therapeutic Activities with a focus on ________.',
                '97535 - Self-Care Management',
                '97542 - Wheelchair Management',
                'G0283 - Electrical Stimulation',
            ],
            ST: [
                '97150 - Group Therapy for _______________.',
                '97150 - Medication Management Group: The patient participated in Medication Management Group Class with focus on education and discussion related to reading/comprehending medication bottles and organization system for daily/weekly medication schedule, establishing a system for medication times, sorting medications, renewal system for medication prescriptions. The patient actively participated in the group and education and training on medication management has aided in the effective carryover for safe discharge.  ',
                '97150 - Falls Management Group: 97150 - The patient participated in Falls management group with focus on: education and discussion related to decreasing fall risks through: increase awareness of falls risk factors, teaching strategies to reduce fall risk factors, discussing how falls can be prevented, explaining how therapy can assist with reducing fall risks, discussing the importance of regular exercise in reducing falls, providing exercise and program recommendations for facilitating reduction of fall risk over time and discussing what to do if you fall and strategies for getting up from a fall.',
                '97010 - Cold Pack for _______.',
                '97010 - Hot Pack for ________.',
                '97024 - Shortwave Diathermy for ________.',
                '97035 - Ultrasound for ___________.',
                '97110 - Therapeutic Exercise for _________.',
                '97112 - Neuromuscular Re-education with  focus on _______.',
                '97116 - Gait Training with a focus on ________.',
                '97129/97130 - Cognitive Function Intervention for _________.',
                '97530 - Therapeutic Activities with a focus on ________.',
                '97535 - Self-Care Management',
                '97542 - Wheelchair Management',
                'G0283 - Electrical Stimulation',
            ],
        };
    }
    getReasonForContinuingTreatment() {
        return {
            PT: [
                '[ENTER FIRST] Patient continues to require skilled, medically necessary care. The focus for the upcoming week includes training in:',
                'Bathing',
                'Bed Mobility',
                'Car Transfers',
                'Discharge planning with a focus on ______.',
                'Dynamic balance with a focus on _________.',
                'Gait around obstacles.',
                'Gait on level surfaces.',
                'Gait on unlevel surfaces.',
                'Life skills management.',
                'Pain management.',
                'Stair negotiation.',
                'Static balance.',
                'Toileting',
                'Transfers from bed.',
                'Transfers from chair.',
                'Transfers from toilet.',
                'Transfers to/from tub',
                'Transition home program.',
                'Wound care management.',
            ],
            OT: [
                '[ENTER FIRST] Patient continues to require skilled, medically necessary care. The focus for the upcoming week includes training in:',
                'Bathing',
                'Bed Mobility',
                'Car Transfers',
                'Discharge planning with a focus on ______.',
                'Dressing with a focus on ________.',
                'Dynamic balance with a focus on _________.',
                'Grooming.',
                'Home management with a focus on ________.',
                'Life skills management.',
                'Meal Prep',
                'Pain management.',
                'Self-Feeding.',
                'Static balance.',
                'Toileting',
                'Transfers from bed.',
                'Transfers from chair.',
                'Transfers from toilet.',
                'Transfers to/from tub',
                'Transition home program.',
            ],
            ST: [
                '[ENTER FIRST] Patient continues to require skilled, medically necessary care. The focus for the upcoming week includes training in:',
                'Cognition with a focus on _______.',
                'Diet modification for liquids.',
                'Diet modification for solids',
                'Discharge planning with a focus on ________.',
                'Expressive language with a focus on __________.',
                'FEES',
                'Life Skills Management',
                'Motor speech with a focus on ________.',
                'Swallow strategies.',
                'Medication management.',
            ],
        };
    }
    getReasonForUpdate() {
        return {
            PT: [
                'Completion of updated plan of care in order to progress treatment plan and goals based on progression toward Physical Therapy long term goals.',
                'Updated plan of care completed due to new diagnosis of _______ requiring assessment and modification of treatment plan.',
                'Updated plan of care due to modification of long term goal(s).',
            ],
            OT: [
                'Completion of updated plan of care in order to progress treatment plan and goals based on progression toward Physical Therapy long term goals.',
                'Updated plan of care completed due to new diagnosis of _______ requiring assessment and modification of treatment plan.',
                'Updated plan of care due to modification of long term goal(s).',
            ],
            ST: [
                'Completion of updated plan of care in order to progress treatment plan and goals based on progression toward Speech Therapy long term goals.',
                'Updated plan of care completed due to new diagnosis of _______ requiring assessment and modification of treatment plan.',
                'Updated plan of care due to modification of long term goal(s).',
            ],
        };
    }
    getResponseToTreatment() {
        return {
            PT: [
                'Caregiver reports ___________.',
                'Change in ________ status has resulted in setback in _______ due to ________, requiring patient to need more assist for __________.   Treatment plan adjustments to be made include ________.  Progress towards goals is expected to continue due to _________.',
                'Decreased pain in __________ to [LEVEL] in response to [MODALITY/TREATMENT] allows for improvement in _________.',
                "Functional gains in _______ have impacted the patient's ability to perform _________ with a reduction in assist levels to _________.",
                'Functional progress this week has been significant due to __________.',
                "Gains in ________ have improved the patient's ability to perform ______ with decreased levels of assist to ___________.",
                'Improvement in ________ allows Patient to tolerate higher levels of challenges in _________.',
                'Pain in [AREA] has decreased to [LEVEL] in response to [TREATMENT/MODALITY], allowing for ease in completing __________.',
                'Patient can now tolerate higher level challenges in [ACTIVITY/TASK] demonstrating an improvement in[IMPAIRMENT].',
                'Patient is now able to perform ______ with fewer episodes of [BARRIER] allowing for improved [TASK/FUNCTION].',
                'Patient progressed from using [DEVICE] to using [DEVICE] for [FUNCTIONAL ACTIVITY].',
                "Patient reports [PATIENT'S CONCERNS AND SELF REPORT OF TREATMENT RESPONSE]",
                'Patient requires fewer [VERBAL, VISUAL OR TACTILE] cues to complete [ACTIVITY] with [ASSIST LEVEL].',
                'Patient shows improvement in [TASK] with fewer episodes of [IMPAIRMENT] allowing for improved quality and safety of [FUNCTIONAL ACTIVITY]',
                "Patient's consistent use of [STRATEGY] has improved [FUNCTIONAL IMPAIRMENT] which resulted in decreased assist to [ASSIST LEVEL]",
                "Patient's consistent use of [STRATEGY] has yielded overall improvements in [FUNCTION]. Patient now requiring a reduction in [CUES/ASSIST] to (LEVEL]",
                "Patient's setback in [AREA] due to [REASON], has resulted in the patient requiring more assist for [TASK].  Adjustments in treatment this week included [ADJUSTMENTS] because of the change in status.  However, because of [REASON], it is expected that progress towards goals should resume within approaching week.",
                "Patient's functional progress gains due to _________.",
                'The patient did not make significant progress this week toward goals. Adjustment will be made to the treatment plan in order to improve response to treatment. See update to plan indicated below.',
                'The patient did not make significant progress toward goals. Treatment plan adjustments to be made include [ADJUSTMENTS].',
                'Patient lack of progress due to ____________.',
            ],
            OT: [
                'Caregiver reports ___________.',
                'Change in ________ status has resulted in setback in _______ due to ________, requiring patient to need more assist for __________.   Treatment plan adjustments to be made include ________.  Progress towards goals is expected to continue due to _________.',
                'Decreased pain in __________ to [LEVEL] in response to [MODALITY/TREATMENT] allows for improvement in _________.',
                "Functional gains in _______ have impacted the patient's ability to perform _________ with a reduction in assist levels to _________.",
                'Functional progress this week has been significant due to __________.',
                "Gains in ________ have improved the patient's ability to perform ______ with decreased levels of assist to ___________.",
                'Improvement in ________ allows patient to tolerate higher levels of challenges in _________.',
                'Pain in [AREA] has decreased to [LEVEL] in response to [TREATMENT/MODALITY], allowing for ease in completing __________.',
                'Patient can now tolerate higher level challenges in [ACTIVITY/TASK] demonstrating an improvement in[IMPAIRMENT].',
                'Patient is now able to perform ______ with fewer episodes of [BARRIER] allowing for improved [TASK/FUNCTION].',
                'Patient progressed from using [DEVICE] to using [DEVICE] for [FUNCTIONAL ACTIVITY].',
                'Patient progressed this week from [TASK] with [ASSIST OR CUING] to [ASSIST OR CUING].',
                "Patient reports [PATIENT'S CONCERNS AND SELF REPORT OF TREATMENT RESPONSE]",
                'Patient requires fewer [VERBAL, VISUAL OR TACTILE] cues to complete [ACTIVITY] with [ASSIST LEVEL].',
                'Patient shows improvement in [TASK] with fewer episodes of [IMPAIRMENT] allowing for improved quality and safety of [FUNCTIONAL ACTIVITY]',
                "Patient's consistent use of [STRATEGY] has improved [FUNCTIONAL IMPAIRMENT] which resulted in decreased assist to [ASSIST LEVEL]",
                "Patient's consistent use of [STRATEGY] has yielded overall improvements in [FUNCTION]. Patient now requiring a reduction in [CUES/ASSIST] to (LEVEL]",
                "Patient's setback in [AREA] due to [REASON], has resulted in the patient requiring more assist for [TASK].  Adjustments in treatment this week included [ADJUSTMENTS] because of the change in status.  However, because of [REASON], it is expected that progress towards goals should resume within approaching week.",
                "Patient's functional progress gains due to _________.",
                'The patient did not make significant progress this week toward goals. Adjustment will be made to the treatment plan in order to improve response to treatment. See update to plan indicated below.',
                'The patient did not make significant progress toward goals. Treatment plan adjustments to be made include [ADJUSTMENTS].',
                'Patient lack of progress due to ____________.',
            ],
            ST: [
                'Caregiver reports ___________.',
                'Functional progress this week has been significant due to __________.',
                "Gains in ________ have improved the patient's ability to perform ______ with decreased levels of assist to ___________.",
                'Pain in [AREA] has decreased to [LEVEL] in response to [TREATMENT/MODALITY], allowing for ease in completing __________.',
                'Patient can now tolerate higher level challenges in [ACTIVITY/TASK] demonstrating an improvement in[IMPAIRMENT].',
                'Patient is now able to perform ______ with fewer episodes of [BARRIER] allowing for improved [TASK/FUNCTION].',
                'Patient progressed this week from [TASK] with [ASSIST OR CUING] to [ASSIST OR CUING].',
                "Patient reports [PATIENT'S CONCERNS AND SELF REPORT OF TREATMENT RESPONSE]",
                'Patient responds well to therapeutic cues for [ACTIVITY]',
                "Patient's consistent use of [STRATEGY] has yielded overall improvements in [FUNCTION]. Patient now requiring a reduction in [CUES/ASSIST] to (LEVEL]",
                "Patient's setback in [AREA] due to [REASON], has resulted in the patient requiring more assist for [TASK].  Adjustments in treatment this week included [ADJUSTMENTS] because of the change in status.  However, because of [REASON], it is expected that progress towards goals should resume within approaching week.",
                "Patient's functional progress gains due to _________.",
                'The patient did not make significant progress this week toward goals. Adjustment will be made to the treatment plan in order to improve response to treatment. See update to plan indicated below.',
                'The patient did not make significant progress toward goals. Treatment plan adjustments to be made include [ADJUSTMENTS].',
                'Patient lack of progress due to ____________.',
            ],
        };
    }
    getUpdatesToTreatment() {
        return {
            PT: [
                'Changes to therapy to be implemented for the coming week include ___________.',
                'Continued [PATIENT/CAREGIVER] training required in [AREA] to improve safety in [FUNCTIONAL ACTIVITY]',
                'Due to [STATUS CHANGE] the treatment plan will be modified to [ADD/DISCONTINUE] [SPECIFIC MODALITY/TREATMENT TECHNIQUE].',
                "Goals related to ___________ have been met.  Will add new STG's to address _______ in the upcoming week.",
                'Updated precautions include ________.',
                'Progress treatment to include ____________.',
                'Requires further [PATIENT/CAREGIVER] training in ______ to improve safety in ________.',
                'Short term goals related to _________ have been met and new short term goals to be added as appropriate for patient.',
                'STGs have been met, will now focus on LTGs.',
                "The plan for next week's visits include [INTERVENTIONS] with the objective of improving [IMPAIRMENTS] to continue to progress toward long term goal(s).",
                'Treatment for upcoming week will focus on progressing [UNDERLYING IMPAIRMENT(S)] in order to improve [ENTER FUNCTION(S)].',
                'Treatment plan is being adjusted to _______ in order to improve response to treatment and overall progress towards goals.',
                'Changes to therapy to be implemented for the coming week include ___________.',
                'Continued [PATIENT/CAREGIVER] training required in [AREA] to improve safety in [FUNCTIONAL ACTIVITY]',
                'Due to [STATUS CHANGE] the treatment plan will be modified to [ADD/DISCONTINUE] [SPECIFIC MODALITY/TREATMENT TECHNIQUE].',
            ],
            ST: [
                'Changes to therapy to be implemented for the coming week include ___________.',
                'Continued [PATIENT/CAREGIVER] training required in [AREA] to improve safety in [FUNCTIONAL ACTIVITY]',
                'Due to [STATUS CHANGE] the treatment plan will be modified to [ADD/DISCONTINUE] [SPECIFIC MODALITY/TREATMENT TECHNIQUE].',
                "Goals related to ___________ have been met.  Will add new STG's to address _______ in the upcoming week.",
                'Updated precautions include ________.',
                'Progress treatment to include ____________.',
                'Requires further [PATIENT/CAREGIVER] training in ______ to improve safety in ________.',
                'Short term goals related to _________ have been met and new short term goals to be added as appropriate for patient.',
                'STGs have been met, will now focus on LTGs.',
                "The plan for next week's visits include [INTERVENTIONS] with the objective of improving [IMPAIRMENTS] to continue to progress toward long term goal(s).",
                'Treatment for upcoming week will focus on progressing [UNDERLYING IMPAIRMENT(S)] in order to improve [ENTER FUNCTION(S)].',
                'Treatment plan is being adjusted to _______ in order to improve response to treatment and overall progress towards goals.',
                'Changes to therapy to be implemented for the coming week include ___________.',
                'Continued [PATIENT/CAREGIVER] training required in [AREA] to improve safety in [FUNCTIONAL ACTIVITY]',
                'Due to [STATUS CHANGE] the treatment plan will be modified to [ADD/DISCONTINUE] [SPECIFIC MODALITY/TREATMENT TECHNIQUE].',
            ],
            OT: [
                'Changes to therapy to be implemented for the coming week include ___________.',
                'Continued [PATIENT/CAREGIVER] training required in [AREA] to improve safety in [FUNCTIONAL ACTIVITY]',
                'Due to [STATUS CHANGE] the treatment plan will be modified to [ADD/DISCONTINUE] [SPECIFIC MODALITY/TREATMENT TECHNIQUE].',
                "Goals related to ___________ have been met.  Will add new STG's to address _______ in the upcoming week.",
                'Updated precautions include ________.',
                'Progress treatment to include ____________.',
                'Requires further [PATIENT/CAREGIVER] training in ______ to improve safety in ________.',
                'Short term goals related to _________ have been met and new short term goals to be added as appropriate for patient.',
                'STGs have been met, will now focus on LTGs.',
                "The plan for next week's visits include [INTERVENTIONS] with the objective of improving [IMPAIRMENTS] to continue to progress toward long term goal(s).",
                'Treatment for upcoming week will focus on progressing [UNDERLYING IMPAIRMENT(S)] in order to improve [ENTER FUNCTION(S)].',
                'Treatment plan is being adjusted to _______ in order to improve response to treatment and overall progress towards goals.',
                'Changes to therapy to be implemented for the coming week include ___________.',
                'Continued [PATIENT/CAREGIVER] training required in [AREA] to improve safety in [FUNCTIONAL ACTIVITY]',
                'Due to [STATUS CHANGE] the treatment plan will be modified to [ADD/DISCONTINUE] [SPECIFIC MODALITY/TREATMENT TECHNIQUE].',
            ],
        };
    }
    getAnticipatedDestination() {
        return {
            PT: [
                'Home',
                'Home Health Care',
                'SNF',
                'Assisted Living',
                'Independent Living',
                'Hospital',
                'Long term acute care hospital (LTACH)',
                'Inpatient Rehab Facility (IRF)',
            ],
            ST: [
                'Home',
                'Home Health Care',
                'SNF',
                'Assisted Living',
                'Independent Living',
                'Hospital',
                'Long term acute care hospital (LTACH)',
                'Inpatient Rehab Facility (IRF)',
            ],
            OT: [
                'Home',
                'Home Health Care',
                'SNF',
                'Assisted Living',
                'Independent Living',
                'Hospital',
                'Long term acute care hospital (LTACH)',
                'Inpatient Rehab Facility (IRF)',
            ],
        };
    }
    getImpactOnDailyLife() {
        return {
            PT: [
                'The patient has shown gains in [FUNCTION OR TASK] with allowed for [IMPROVEMENT].',
                'The patient is no longer dependent upon caregiver for[TASK]',
                'The patient will require continued therapy in the [CLINICAL SETTING]',
                "The patient's [CAREGIVER/SPOUSE] reports that the patient continues to require assist with [TASK], however the amount of assist required has decreased from ______ to _________.",
                "The patient's gain in [FUNCTION / TASK] has allowed for [IMPROVEMENT]",
                "The patient's set backs in [FUNCTION/TASKS] due to [CAUSE], have caused an increase in burden of care for [TASK].  It is expected , however, that the patient will improve once [REASON]",
                'Nursing staff reports that patient is able to participate with [MORE/LESS ASSISTANCE] during [ACTIVITY].',
                'Patient has progressed in [IMPAIRMENT] and is no longer dependent upon caregiver for [TASK].',
                'Persisting deficits include [FUNCTION] however, caregivers report ease in [TASK] when utilizing [CUES, TECHNIQUES, STRATEGIES] as instructed by therapist.',
                'Report from [FAMILY MEMBER/CAREGIVER] that the amount of assist required for [FUNCTIONAL ACTIVITY] has decreased from [ASSIST] to [ASSIST].',
                'Skilled therapy services provided this treatment episode resulted in functional progression from [FUNCTIONAL LEVELS SINCE SOC] to [CURRENT LEVEL OF FUNCTION].',
            ],
            ST: [
                'The patient has shown gains in [FUNCTION OR TASK] with allowed for [IMPROVEMENT].',
                'The patient is no longer dependent upon caregiver for[TASK]',
                'The patient will require continued therapy in the [CLINICAL SETTING]',
                "The patient's [CAREGIVER/SPOUSE] reports that the patient continues to require assist with [TASK], however the amount of assist required has decreased from ______ to _________.",
                "The patient's gain in [FUNCTION / TASK] has allowed for [IMPROVEMENT]",
                "The patient's set backs in [FUNCTION/TASKS] due to [CAUSE], have caused an increase in burden of care for [TASK].  It is expected , however, that the patient will improve once [REASON]",
                'Nursing staff reports that patient is able to participate with [MORE/LESS ASSISTANCE] during [ACTIVITY].',
                'Patient has progressed in [IMPAIRMENT] and is no longer dependent upon caregiver for [TASK].',
                'Persisting deficits include [FUNCTION] however, caregivers report ease in [TASK] when utilizing [CUES, TECHNIQUES, STRATEGIES] as instructed by therapist.',
                'Report from [FAMILY MEMBER/CAREGIVER] that the amount of assist required for [FUNCTIONAL ACTIVITY] has decreased from [ASSIST] to [ASSIST].',
                'Skilled therapy services provided this treatment episode resulted in functional progression from [FUNCTIONAL LEVELS SINCE SOC] to [CURRENT LEVEL OF FUNCTION].',
            ],
            OT: [
                'The patient has shown gains in [FUNCTION OR TASK] with allowed for [IMPROVEMENT].',
                'The patient is no longer dependent upon caregiver for[TASK]',
                'The patient will require continued therapy in the [CLINICAL SETTING]',
                "The patient's [CAREGIVER/SPOUSE] reports that the patient continues to require assist with [TASK], however the amount of assist required has decreased from ______ to _________.",
                "The patient's gain in [FUNCTION / TASK] has allowed for [IMPROVEMENT]",
                "The patient's set backs in [FUNCTION/TASKS] due to [CAUSE], have caused an increase in burden of care for [TASK].  It is expected , however, that the patient will improve once [REASON]",
                'Nursing staff reports that patient is able to participate with [MORE/LESS ASSISTANCE] during [ACTIVITY].',
                'Patient has progressed in [IMPAIRMENT] and is no longer dependent upon caregiver for [TASK].',
                'Persisting deficits include [FUNCTION] however, caregivers report ease in [TASK] when utilizing [CUES, TECHNIQUES, STRATEGIES] as instructed by therapist.',
                'Report from [FAMILY MEMBER/CAREGIVER] that the amount of assist required for [FUNCTIONAL ACTIVITY] has decreased from [ASSIST] to [ASSIST].',
                'Skilled therapy services provided this treatment episode resulted in functional progression from [FUNCTIONAL LEVELS SINCE SOC] to [CURRENT LEVEL OF FUNCTION].',
            ],
        };
    }
    getReasonForMissedVisit() {
        return {
            PT: [
                'Patient refused due to _________.',
                'Schedule conflict due to ________.',
                'Treatment withheld due to _______.',
            ],
            ST: [
                'Patient refused due to _________.',
                'Schedule conflict due to ________.',
                'Treatment withheld due to _______.',
            ],
            OT: [
                'Patient refused due to _________.',
                'Schedule conflict due to ________.',
                'Treatment withheld due to _______.',
            ],
        };
    }
    getEvaluationType() {
        return {
            PT: [
                'Low complexity evaluation examination: addressed 1-2 body structures and functions of __________, activity limitations, and/or participation restrictions of ____________.',
                'Moderate Complexity evaluation examination: addressed 3 or more body structures and functions of ________, activity limitations, and/or participation restrictions of _______.',
                'High Complexity evaluation examination: addressed 4 or more body structures and functions of _______, activity limitations, and/or participation restrictions of _________',
            ],
            OT: [
                'Low complexity evaluation examination: addressed 1-2 body structures and functions of __________, activity limitations, and/or participation restrictions of ____________.',
                'Moderate Complexity evaluation examination: addressed 3 or more body structures and functions of ________, activity limitations, and/or participation restrictions of _______.',
                'High Complexity evaluation examination: addressed 4 or more body structures and functions of _______, activity limitations, and/or participation restrictions of _________',
            ],
        };
    }
    getVitals() {
        return {
            PT: [
                'Heart Rate (50-200)',
                'Blood Pressure (Diastolic 90 - 200 / Systolic 40 - 200)',
                'Respiratory Rate',
                'O2 Saturation (80 - 100)',
                'Temperature (80 - 120F)',
            ],
            ST: [
                'Heart Rate (50-200)',
                'Blood Pressure (Diastolic 90 - 200 / Systolic 40 - 200)',
                'Respiratory Rate',
                'O2 Saturation (80 - 100)',
                'Temperature (80 - 120F)',
            ],
            OT: [
                'Heart Rate (50-200)',
                'Blood Pressure (Diastolic 90 - 200 / Systolic 40 - 200)',
                'Respiratory Rate',
                'O2 Saturation (80 - 100)',
                'Temperature (80 - 120F)',
            ],
        };
    }
    getSkilledServices() {
        const data = {
            PT: [
                {
                    key: '97024',
                    value: '[Sub thermal/Thermal] diathermy applied to [anatomical location] for treatment of [acute pain/chronic pain/decrease edema/decrease joint contracture/decrease joint stiffness/increase collagen tissue extensibility/increase local circulation/inhibit muscle spasm] with set up/take down time of [enter time] and run time of [enter time].  Skilled supervision during run time necessary due to [enter reason for skilled service].',
                },
                {
                    key: '97032',
                    value: 'Electrical stimulation using [identify waveform] applied to [anatomical location] for treatment of [acute pain/chronic pain/increase local circulation/increase ROM/inhibit muscle spasticity/muscle re-education/strengthening and decrease muscle atrophy/inhibit muscle spasm] with set up/take down time of [enter time] and run time of [enter time].  Skilled supervision during run time necessary due to [enter reason for skilled service]',
                },
                {
                    key: '97035',
                    value: '[Thermal/Sub thermal] ultrasound applied to [anatomical location] for treatment of acute pain/chronic pain/contractions/joint stiffness/improving collagen tissue extensibility/increasing local circulation/muscle spasm/muscle tone with set up/take down time of [enter time] and run time of [enter time].',
                },
                {
                    key: '97110',
                    value: [
                        '[Instructed/Modified/Progressed/Assessed] resistive exercise program for [identify muscle groups] using [identify mode of resistance] including [describe instruction, modification, progression, and/or assessment]',
                        '[Instructed/Modified/Progressed/Assessed] aerobic exercise program using [identify equipment/mode] including [describe instruction, modification,progression, and/or assessment]',
                        '[Instructed/Modified/Progressed/Assessed] [PROM/A/AROM/AROM] program for [identify joint movements] using [contract-relax, over-pressure, inhibitory techniques, other]',
                        '[Assessed/Tested] aerobic capacity with administration of [aerobic capacity test]',
                    ],
                },
                {
                    key: '97112',
                    value: [
                        '[Instructed/Modified/Progressed/Facilitated/Assessed] automatic balance strategies including [ankle/hip/stepping/suspensory] using [identify technique/exercise] in order to [functional reason].',
                        '[Instructed/Modified/Progressed/Facilitated/Assessed] sensory integration techniques including [visual inhibition/somatosensory inhibition/visual excitatory/somatosensory excitatory/vestibular excitatory] using [identify technique/exercise] in order to [functional reason].',
                        '[Instructed/Modified/Progressed/Facilitated/Assessed] visual input including [oculomotor exercises, smooth pursuits, saccades, visual field, other] in order to [functional reasons].',
                        '[Instructed/Modified/Progressed/Assessed] somatosensory techniques including [joint compression, proprioceptive activities, other] in order to [functional reasons].',
                        '[Instructed/Modified/Progressed/Assessed] vestibular techniques including [gaze stabilization, Brandt-Darhoff, Epley, other] in order to [functional reasons].',
                        '[Instructed/Modified/Progressed/Assessed] neuro re-education techniques including [pnf patterns, NDT, tone inhibition, other] in order to [functional reason].',
                        '[Assessed/Tested] balance with administration of [identify test].',
                    ],
                },
                {
                    key: '97116',
                    value: [
                        '[Instructed/Modified/Progressed/Facilitated/Assessed] gait with emphasis on [swing through, heel strike, hip extension during stance, other] during [gait phase] using [assistive device] for [distance]',
                        '[Instructed/Modified/Progressed/Facilitated/Assessed] use of [assistive device] and [NWB, PWB, step-to gait pattern, step through gait pattern]',
                        "[Instructed/Modified/Progressed/Facilitated/Assessed] patient's ability to [ascend/descend # of steps, perform directional changes, walk on even/uneven surfaces, pick-up objects off floor, velocity changes, other] using [assistive device].",
                        '[Instructed/Modified/Progressed/Facilitated/Assessed] pre-gait activities including [identify exercise] in order to prepare for gait training.',
                        'Assessed cardiovascular/cardiopulmonary response during gait  for [distance] using [assistive device].   Pt amb (distance) with (device) and (assistance) focusing on (phase of gait) to (functional task), antalgic gait, ataxic gait, hip hiking, festinating gait, foot drop, four point gait, two point gait, shuffling gait, scissoring gait, swing through, toe/foot clearance, foot drop, decreased cadence, circumduction, ataxic gait, trunk flexion, step length, speed, heel off/toe off, three point gait, reciprocal movement.',
                    ],
                },
                {
                    key: '97140',
                    value: [
                        'Performed soft tissue mobilization/technique of [identify technique] to anatomical location/muscle group in order [identify purpose].',
                        'Performed [anterior glide, posterior glide, inferior glide, distraction, caudal glide, other] joint mobilization of [grade I, II, III, IV] to [identify joint] in order to [identify purpose]',
                        'Performed neuro-tension technique of [identify technique] to [identify nerve] in order to [identify purpose]',
                        'Instructed [patient/caregiver/spouse] in [identify technique] in order to [identify purpose].',
                    ],
                },
                {
                    key: '97150',
                    value: 'Pt participated in [type of group] consisting of [number of patients] with focus on [instruction/modification/progression/facilitation/assessment] of (identify mobility/ADL skill, vital sign assessment, exercise type, other] in order to address [identify STG/LTG # and description]',
                },
                {
                    key: '97161',
                    value: [
                        'Completed evaluation, including review of medical record, and established plan of care.',
                        'Completed evaluation, including review of medical record.',
                    ],
                },
                {
                    key: '97163',
                    value: [
                        'Completed evaluation, including review of medical record, and established plan of care.',
                        'Completed evaluation, including review of medical record.',
                    ],
                },
                {
                    key: '97530',
                    value: '[Instructed/Modified/Progressed/Assessed/Facilitated] in mobility skills including [identify specific bed mobility, transfer skill, other] using [assistive device/adaptive equipment] including [treatment encounter specifics]',
                },
                {
                    key: '97535',
                    value: '[Instructed/Modified/Progressed/Assessed/Facilitated] in self-care skills including [identify specific self-care skill] using [assistive device/adaptive equipment] including [identify treatment encounter specifics]',
                },
                {
                    key: '97542',
                    value: 'Self propulsion in chair over (surface) with (level of assistance) (footage and cues). Wc adjustments made (seat to floor height up or down) (brakes adjusted) (cushion changed) to increase independence with mobility. Power chair mobility assessed with obstacle negotiation, safety while in chair, ability to operate with (level of assistance).  postural alignment, facilitation of normal mvmt, postural control, prevention of skin breakdown, trunk stabilization.',
                },
                {
                    key: '97760',
                    value: 'Orthotic placed on (limb) and tolerated (time) for (function) Skin checked prior to and after. Skin presented (color) (breakdown)',
                },
                {
                    key: '97763',
                    value: 'Skin check to (limb). (type of splint) applied to (limb) and tolerated (time) with skin check after.',
                },
                {
                    key: '97799',
                    value: 'Prosthetic to limb with shrinker and (ply) socks. Skin checked prior to and after donning and doffing.',
                },
                {
                    key: 'G0281',
                    value: 'Electrical stimulation using [identify waveform] to increase local circulation applied to [anatomical location] for treatment of chronic [stage 3 pressure ulcer/stage 4 pressure ulcer/arterial ulcer/diabetic ulcer/venous stasis ulcer]  with set up/take down time of [enter time] and run time of [enter time].  Skilled supervision during run time necessary due to [enter reason for skilled service] E-stim over (stage) pressure ulcer x (mins) with (Type set up) to increase blood flow and decrease pain.',
                },
                {
                    key: '97014',
                    value: [
                        'Electrical stimulation using [identify waveform] to increase local circulation applied to [anatomical location] for treatment of chronic [stage 3 pressure ulcer/stage 4 pressure ulcer/arterial ulcer/diabetic ulcer/venous stasis ulcer]  with set up/take down time of [enter time] and run time of [enter time].  Skilled supervision during run time necessary due to [enter reason for skilled service] E-stim over (stage) pressure ulcer x (mins) with (Type set up) to increase blood flow and decrease pain.',
                        'Electrical stimulation using [identify waveform] applied to [anatomical location] for treatment of [acute pain/chronic pain/increase local circulation/increase ROM/inhibit muscle spasticity/muscle re-education/strengthening and decrease muscle atrophy/inhibit muscle spasm] with set up/take down time of [enter time] and run time of [enter time].  Skilled supervision during run time necessary due to [enter reason for skilled service].(Type of estim) to _________at ________x ______mins to ___________utilizing _____ set up. Focusing on (ms group) for (task).',
                    ],
                },
                {
                    key: 'G0283',
                    value: [
                        'Electrical stimulation using [identify waveform] applied to [anatomical location] for treatment of [acute pain/chronic pain/increase local circulation/increase ROM/inhibit muscle spasticity/muscle re-education/strengthening and decrease muscle atrophy/inhibit muscle spasm] with set up/take down time of [enter time] and run time of [enter time].  Skilled supervision during run time necessary due to [enter reason for skilled service].(Type of estim) to _________at ________x ______mins to ___________utilizing _____ set up. Focusing on (ms group) for (task).',
                    ],
                },
                {
                    key: 'G0329',
                    value: [
                        'Electromagnetic therapy using [thermal/sub-thermal] intensity applied to [anatomical location] for treatment of chronic [stage 3 pressure ulcer/stage 4 pressure ulcer/arterial ulcer/diabetic ulcer/venous stasis ulcer]  with set up/take down time of [enter time] and run time of [enter time].  Skilled supervision during run time necessary due to [enter reason for skilled service]  electromagnetic set up (area) x ( mins) to increase circulation causing (symptoms) for (tasks)',
                    ],
                },
                {
                    key: 'G0281 / 97014',
                    value: 'Electrical stimulation using [identify waveform] to increase local circulation applied to [anatomical location] for treatment of chronic [stage 3 pressure ulcer/stage 4 pressure ulcer/arterial ulcer/diabetic ulcer/venous stasis ulcer]  with set up/take down time of [enter time] and run time of [enter time].  Skilled supervision during run time necessary due to [enter reason for skilled service] E-stim over (stage) pressure ulcer x (mins) with (Type set up) to increase blood flow and decrease pain.',
                },
                {
                    key: 'G0283 / 97014',
                    value: 'Electrical stimulation using [identify waveform] applied to [anatomical location] for treatment of [acute pain/chronic pain/increase local circulation/increase ROM/inhibit muscle spasticity/muscle re-education/strengthening and decrease muscle atrophy/inhibit muscle spasm] with set up/take down time of [enter time] and run time of [enter time].  Skilled supervision during run time necessary due to [enter reason for skilled service].(Type of estim) to _________at ________x ______mins to ___________utilizing _____ set up. Focusing on (ms group) for (task).',
                },
                {
                    key: 'G0329',
                    value: 'Electromagnetic therapy using [thermal/sub thermal] intensity applied to [anatomical location] for treatment of chronic [stage 3 pressure ulcer/stage 4 pressure ulcer/arterial ulcer/diabetic ulcer/venous stasis ulcer]  with set up/take down time of [enter time] and run time of [enter time]. Skilled supervision during run time necessary due to [enter reason for skilled service]  electromagnetic set up (area) x ( mins) to increase circulation causing (symptoms) for (tasks)',
                },
                {
                    key: '97124',
                    value: 'Pt performed gait to increase Heel strike and utilized (AFO/device)',
                }
            ],
            OT: [
                {
                    key: '97024',
                    value: '[Sub thermal/Thermal] diathermy applied to [anatomical location] for treatment of [acute pain/chronic pain/decrease edema/decrease joint contracture/decrease joint stiffness/increase collagen tissue extensibility/increase local circulation/inhibit muscle spasm] with set up/take down time of [enter time] and run time of [enter time].  Skilled supervision during run time necessary due to [enter reason for skilled service]. ',
                },
                {
                    key: '97032',
                    value: 'Electrical stimulation using [identify waveform] applied to [anatomical location] for treatment of [acute pain/chronic pain/increase local circulation/increase ROM/inhibit muscle spasticity/muscle re-education/strengthening and decrease muscle atrophy/inhibit muscle spasm] with set up/take down time of [enter time] and run time of [enter time].  Skilled supervision during run time necessary due to [enter reason for skilled service]',
                },
                {
                    key: '97035',
                    value: '[Thermal/Sub thermal] ultrasound applied to [anatomical location] for treatment of acute pain/chronic pain/contractions/joint stiffness/improving collagen tissue extensibility/increasing local circulation/muscle spasm/muscle tone with set up/take down time of [enter time] and run time of [enter time].',
                },
                {
                    key: '97110',
                    value: [
                        '[Instructed/Modified/Progressed/Assessed] resistive exercise program for [identify muscle groups] using [identify mode of resistance] including [describe instruction, modification, progression, and/or assessment]',
                        '[Instructed/Modified/Progressed/Assessed] aerobic exercise program using [identify equipment/mode] including [describe instruction, modification,progression, and/or assessment]',
                        '[Instructed/Modified/Progressed/Assessed] [PROM/A/AROM/AROM] program for [identify joint movements] using [contract-relax, over-pressure, inhibitory techniques, other]',
                        '[Assessed/Tested] aerobic capacity with administration of [aerobic capacity test]',
                    ],
                },
                {
                    key: '97112',
                    value: [
                        '[Instructed/Modified/Progressed/Facilitated/Assessed] automatic balance strategies including [ankle/hip/stepping/suspensory] using [identify technique/exercise] in order to [functional reason].',
                        '[Instructed/Modified/Progressed/Facilitated/Assessed] sensory integration techniques including [visual inhibition/somatosensory inhibition/visual excitatory/somatosensory excitatory/vestibular excitatory] using [identify technique/exercise] in order to [functional reason].',
                        '[Instructed/Modified/Progressed/Facilitated/Assessed] visual input including [oculomotor exercises, smooth pursuits, saccades, visual field, other] in order to [functional reasons].',
                        '[Instructed/Modified/Progressed/Assessed] somatosensory techniques including [joint compression, proprioceptive activities, other] in order to [functional reasons].',
                        '[Instructed/Modified/Progressed/Assessed] vestibular techniques including [gaze stabilization, Brandt-Darhoff, Epley, other] in order to [functional reasons].',
                        '[Instructed/Modified/Progressed/Assessed] neuro re-education techniques including [pnf patterns, NDT, tone inhibition, other] in order to [functional reason].',
                        '[Assessed/Tested] balance with administration of [identify test].',
                    ],
                },
                {
                    key: '97140',
                    value: [
                        'Performed soft tissue mobilization/technique of [identify technique] to anatomical location/muscle group in order [identify purpose].',
                        'Performed [anterior glide, posterior glide, inferior glide, distraction, caudal glide, other] joint mobilization of [grade I, II, III, IV] to [identify joint] in order to [identify purpose]',
                        'Performed neuro-tension technique of [identify technique] to [identify nerve] in order to [identify purpose]',
                        'Instructed [patient/caregiver/spouse] in [identify technique] in order to [identify purpose].',
                    ],
                },
                {
                    key: '97150',
                    value: 'Pt participated in [type of group] consisting of [number of patients] with focus on [instruction/modification/progression/facilitation/assessment] of (identify mobility/ADL skill, vital sign assessment, exercise type, other] in order to address [identify STG/LTG # and description]',
                },
                {
                    key: '97165',
                    value: [
                        'Completed evaluation, including review of medical record, and established plan of care.',
                        'Completed evaluation, including review of medical record.',
                    ],
                },
                {
                    key: '97166',
                    value: [
                        'Completed evaluation, including review of medical record, and established plan of care.',
                        'Completed evaluation, including review of medical record.',
                    ],
                },
                {
                    key: '97167',
                    value: [
                        'Completed evaluation, including review of medical record, and established plan of care.',
                        'Completed evaluation, including review of medical record.',
                    ],
                },
                {
                    key: '97530',
                    value: '[Instructed/Modified/Progressed/Assessed/Facilitated] in mobility skills including [identify specific bed mobility, transfer skill, other] using [assistive device/adaptive equipment] including [treatment encounter specifics]',
                },
                {
                    key: '97535',
                    value: '[Instructed/Modified/Progressed/Assessed/Facilitated] in self-care skills including [identify specific self-care skill] using [assistive device/adaptive equipment] including [identify treatment encounter specifics]',
                },
                {
                    key: '97542',
                    value: 'Self propulsion in chair over (surface) with (level of assistance) (footage and cues). Wc adjustments made (seat to floor height up or down) (brakes adjusted) (cushion changed) to increase independence with mobility. Power chair mobility assessed with obstacle negotiation, safety while in chair, ability to operate with (level of assistance).  postural alignment, facilitation of normal mvmt, postural control, prevention of skin breakdown, trunk stabilization.',
                },
                {
                    key: '97760',
                    value: 'Orthotic placed on (limb) and tolerated (time) for (function) Skin checked prior to and after. Skin presented (color) (breakdown)',
                },
                {
                    key: '97763',
                    value: 'Skin check to (limb). (type of splint) applied to (limb) and tolerated (time) with skin check after.',
                },
                {
                    key: '97799',
                    value: 'Prosthetic to limb with shrinker and (ply) socks. Skin checked prior to and after donning and doffing.',
                },
                {
                    key: 'G0281',
                    value: 'Electrical stimulation using [identify waveform] to increase local circulation applied to [anatomical location] for treatment of chronic [stage 3 pressure ulcer/stage 4 pressure ulcer/arterial ulcer/diabetic ulcer/venous stasis ulcer]  with set up/take down time of [enter time] and run time of [enter time].  Skilled supervision during run time necessary due to [enter reason for skilled service] E-stim over (stage) pressure ulcer x (mins) with (Type set up) to increase blood flow and decrease pain.',
                },
                {
                    key: '97014',
                    value: [
                        'Electrical stimulation using [identify waveform] to increase local circulation applied to [anatomical location] for treatment of chronic [stage 3 pressure ulcer/stage 4 pressure ulcer/arterial ulcer/diabetic ulcer/venous stasis ulcer]  with set up/take down time of [enter time] and run time of [enter time].  Skilled supervision during run time necessary due to [enter reason for skilled service] E-stim over (stage) pressure ulcer x (mins) with (Type set up) to increase blood flow and decrease pain.',
                        'Electrical stimulation using [identify waveform] applied to [anatomical location] for treatment of [acute pain/chronic pain/increase local circulation/increase ROM/inhibit muscle spasticity/muscle re-education/strengthening and decrease muscle atrophy/inhibit muscle spasm] with set up/take down time of [enter time] and run time of [enter time].  Skilled supervision during run time necessary due to [enter reason for skilled service].(Type of estim) to _________at ________x ______mins to ___________utilizing _____ set up. Focusing on (ms group) for (task).',
                    ],
                },
                {
                    key: 'G0283',
                    value: [
                        'Electrical stimulation using [identify waveform] applied to [anatomical location] for treatment of [acute pain/chronic pain/increase local circulation/increase ROM/inhibit muscle spasticity/muscle re-education/strengthening and decrease muscle atrophy/inhibit muscle spasm] with set up/take down time of [enter time] and run time of [enter time].  Skilled supervision during run time necessary due to [enter reason for skilled service].(Type of estim) to _________at ________x ______mins to ___________utilizing _____ set up. Focusing on (ms group) for (task).',
                    ],
                },
                {
                    key: 'G0329',
                    value: [
                        'Electromagnetic therapy using [thermal/sub-thermal] intensity applied to [anatomical location] for treatment of chronic [stage 3 pressure ulcer/stage 4 pressure ulcer/arterial ulcer/diabetic ulcer/venous stasis ulcer]  with set up/take down time of [enter time] and run time of [enter time].  Skilled supervision during run time necessary due to [enter reason for skilled service]  electromagnetic set up (area) x ( mins) to increase circulation causing (symptoms) for (tasks)',
                    ],
                },
                {
                    key: 'G0281 / 97014',
                    value: 'Electrical stimulation using [identify waveform] to increase local circulation applied to [anatomical location] for treatment of chronic [stage 3 pressure ulcer/stage 4 pressure ulcer/arterial ulcer/diabetic ulcer/venous stasis ulcer]  with set up/take down time of [enter time] and run time of [enter time].  Skilled supervision during run time necessary due to [enter reason for skilled service] E-stim over (stage) pressure ulcer x (mins) with (Type set up) to increase blood flow and decrease pain.',
                },
                {
                    key: 'G0283 / 97014',
                    value: 'Electrical stimulation using [identify waveform] applied to [anatomical location] for treatment of [acute pain/chronic pain/increase local circulation/increase ROM/inhibit muscle spasticity/muscle re-education/strengthening and decrease muscle atrophy/inhibit muscle spasm] with set up/take down time of [enter time] and run time of [enter time].  Skilled supervision during run time necessary due to [enter reason for skilled service].(Type of estim) to _________at ________x ______mins to ___________utilizing _____ set up. Focusing on (ms group) for (task).',
                },
                {
                    key: 'G0329',
                    value: 'Electromagnetic therapy using [thermal/sub thermal] intensity applied to [anatomical location] for treatment of chronic [stage 3 pressure ulcer/stage 4 pressure ulcer/arterial ulcer/diabetic ulcer/venous stasis ulcer]  with set up/take down time of [enter time] and run time of [enter time]. Skilled supervision during run time necessary due to [enter reason for skilled service]  electromagnetic set up (area) x ( mins) to increase circulation causing (symptoms) for (tasks)',
                }
            ],
            ST: [
                {
                    key: '97150',
                    value: 'Pt participated in [type of group] consisting of [number of patients] with focus on [instruction/modification/progression/facilitation/assessment] of (identify mobility/ADL skill, vital sign assessment, exercise type, other] in order to address [identify STG/LTG # and description]',
                },
                {
                    key: '97530',
                    value: '[Instructed/Modified/Progressed/Assessed/Facilitated] in mobility skills including [identify specific bed mobility, transfer skill, other] using [assistive device/adaptive equipment] including [treatment encounter specifics]',
                },
                {
                    key: '92610',
                    value: [
                        'Completed evaluation, including review of medical record, and established plan of care.', 'Completed evaluation, including review of medical record.'
                    ],
                },
                {
                    key: '92610',
                    value: [
                        'Completed evaluation, including review of medical record, and established plan of care.', 'Completed evaluation, including review of medical record.'
                    ],
                },
                {
                    key: '92507',
                    value: [
                        '[Instructed/Modified/Progressed/Facilitate/Assessed] [identify cognitive-communicative interventions] in order to [identify cognitive communicative functional reasons]', 'Assessed cognitive-communicative skills using [identify test].'
                    ],
                },
                {
                    key: '92526',
                    value: [
                        '[Instructed/Modified/Progressed/Facilitate/Assessed] [identify interventions for oral prep/oral/pharyngeal phase] in order to [reduce aspiration risk, consume food/drinks without fear, consume food/drinks without supervision, improve safe oral intake, other]', '[Instructed/Modified/Progressed/Facilitate/Assessed] [identify compensatory methods such as alternating bites/sips, effortful swallow, other] in order to [reduce aspiration risk, consume food/drinks without fear, consume food/drinks without supervision, improve safe oral intake, other]', '[Instructed/Modified/Progressed/Assessed] trials of [identify IDDSI Food/Drink Level or NDD Solid/Liquid Level] in order to [reduce aspiration risk, consume food/drinks without fear, consume food/drinks without supervision, improve safe oral intake, other]', '[Instructed/Modified/Progressed/Facilitate/Assessed] [identify interventions for oral prep/oral/pharyngeal phase] during electrical stimulation using [identify estim protocol] in order to [reduce aspiration risk, consume food/drinks without fear, consume food/drinks without supervision, improve safe oral intake, other] with electrodes placed [identify electrode placement] at intensity of [identify milliamp (ma)] for a duration of [minutes]', 'Assessed swallow with administration of [identify test]'
                    ],
                },
                {
                    key: '92507',
                    value: [
                        'Completed evaluation, including review of medical record, and established plan of care.', 'Completed evaluation, including review of medical record.'
                    ],
                },
                {
                    key: '92523',
                    value: [
                        'Completed evaluation, including review of medical record, and established plan of care.', 'Completed evaluation, including review of medical record.'
                    ],
                },
                {
                    key: '92508',
                    value: 'Pt participated in [swallow group/cognitive-linguistic group] consisting of [number of patients] with focus on [instruction/modification/progression/facilitation/assessment] of (identify swallow phase, swallow skill, cognitive linguistic skill] in order to address [identify STG/LTG # and description]',
                },
            ],
        };

        return data;
    }
}
