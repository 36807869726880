import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DIALOG_DATA } from '@app/helpers/dialog-tokens';
import { DailyNote, DetailedCptCodes, Exercises, TherapyAdmission } from '@app/interfaces';
import { DialogRef, DailyNotesService, CptCodesService, TherapyAdmissionService } from '@app/services';
import { Subject, Subscription, catchError, debounceTime, of } from 'rxjs';
import { ToasterService } from '@app/services';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import * as uuid from 'uuid';

@Component({
    selector: 'app-procedures',
    templateUrl: './add-procedures.component.html',
    styleUrls: ['./add-procedures.component.scss'],
})
export class AddProceduresComponent implements OnInit {
    private queryChanged$: Subject<string> = new Subject<string>();

    heading = '';
    currentCptCode = '';
    justification = '';
    selectedProcedures: Array<any> = [];
    customProcedure = '';
    seeMoreProcedures = [];
    underSearchMsg = '';
    searchedProcedures = [];
    SEARCH_CHUNK_SIZE = 10;
    private DELAY = 500;
    proceduresQuery = '';
    loading = false;

    private subscriptions: Subscription[] = [];
    private currentTA: TherapyAdmission;
    private currentDN: DailyNote;
    private currentAdmissions: TherapyAdmission[];
    private routeData: { doc: string; discipline: string } = {
        doc: '',
        discipline: '',
    };

    @ViewChild('seeMoreBtn') seeMoreBtn: ElementRef;

    constructor(
        @Inject(DIALOG_DATA) public data,
        private ref: DialogRef,
        private route: ActivatedRoute,
        private dailyNotesService: DailyNotesService,
        private toaster: ToasterService,
        private cptCodesService: CptCodesService,
        private store: Store<{
            therapyAdmission: TherapyAdmission[];
        }>,
        private therapyService: TherapyAdmissionService
    ) {
        this.route.queryParamMap.subscribe({
            next: (params) => {
                this.routeData.doc = params.get('doc');
                this.routeData.discipline = params.get('discipline');
            },
        });

        this.subscriptions.push(
            this.store.select('therapyAdmission').subscribe((admissions: TherapyAdmission[]) => {
                if (!admissions || !admissions.length) {
                    return;
                }

                this.currentAdmissions = admissions;

                this.currentTA = this.therapyService.initCurrentTherapyAdmissionByIdQueryString(admissions);
                this.currentDN = this.currentTA?.documentation.dailyNote?.find((elem) => elem.id === this.routeData.doc);
            }),

            this.queryChanged$.pipe(debounceTime(this.DELAY)).subscribe((query) => {
                this.fetchProceduresCall(query);
            })
        );
    }

    ngOnInit(): void {
        this.heading = this.data.heading;
        this.currentCptCode = this.data.cptCode;
        this.justification = this.data.justification === 'NA' ? '' : this.data.justification;
    }

    onClose() {
        this.ref.close();
    }
    onSave() {
        console.log('Saving...');
        this.onClose();
    }

    searchProcedures(): void {
        this.seeMoreProcedures = [];
        this.queryChanged$.next(this.proceduresQuery);
    }

    fetchProceduresCall(query: string) {
        this.underSearchMsg = 'searching...';
        this.loading = true;

        this.cptCodesService
            .searchExercisesInCptCode(query, this.currentCptCode)
            .pipe(catchError((err) => of({ error: err })))
            .subscribe((results) => {
                if (results.error) {
                    this.underSearchMsg = 'Failed to fetch records!';
                    this.searchedProcedures = [];
                    return;
                }

                if (!results) {
                    this.searchedProcedures = [];
                    return;
                }

                if (results.message === 'type more chars') {
                    this.underSearchMsg = 'Please type more characters...';
                    this.searchedProcedures = [];
                    return;
                }

                // checks search results if they were already selected
                results?.forEach((result) => {
                    const exists = this.selectedProcedures.find((elem) => elem._id === result._id);
                    result.checked = exists ? true : false;
                });

                this.seeMoreProcedures = [...results];
                this.searchedProcedures = [];
                this.applySeeMoreFilter();

                if (results.length === 0) {
                    this.underSearchMsg = `No Records found for '${query}' in code '${this.currentCptCode}'`;
                }
            });
        this.loading = false;
    }

    applySeeMoreFilter(): void {
        if (!this.seeMoreProcedures.length) {
            return;
        }
        if (this.seeMoreBtn?.nativeElement) {
            this.seeMoreBtn.nativeElement.scrollIntoView({
                behavior: 'smooth',
            });
        }
        this.searchedProcedures = [
            ...this.searchedProcedures,
            ...this.seeMoreProcedures.slice(0, this.SEARCH_CHUNK_SIZE),
        ];
        this.seeMoreProcedures = this.seeMoreProcedures.slice(this.SEARCH_CHUNK_SIZE, this.seeMoreProcedures.length);
    }

    addCustomProcedure() {
        const containsOnlySpaces = /^\s*$/.test(this.customProcedure);
        if (this.customProcedure && !containsOnlySpaces) {
            this.selectedProcedures.push({
                _id: uuid.v4(),
                description: this.customProcedure,
                frequency: 0,
                checked: true,
            });
        }
        this.customProcedure = '';
    }

    removeProcedure(item, index) {
        this.selectedProcedures.splice(index, 1);
    }

    removeChecked = (arr: any[]): Exercises[] =>
        arr.map((elem) => {
            delete elem['checked'];
            return elem;
        });

    saveClicked(): Promise<void> | undefined {
        const currentExercises = [];
        const payload: any = cloneDeep(this.currentDN);
        payload.documentation_id = this.currentTA.documentation.id;
        if (!payload) {
            console.log('ERR: unable to get current Daily Note');
            return;
        }
        const foundIndex = payload.objectiveAssessment.detailedCptCodes.findIndex(
            (record: DetailedCptCodes) => record.code === this.currentCptCode
        );
        const exercises = this.currentDN.objectiveAssessment.detailedCptCodes[foundIndex].exercises;
        if (exercises.length) {
            exercises.forEach((exercise) => {
                currentExercises.push(exercise);
            });
        }
        this.selectedProcedures.forEach((procedure) => {
            currentExercises.push(procedure);
        });
        const procedures: Exercises[] = this.removeChecked(currentExercises);

        const newRecord = {
            code: this.currentCptCode,
            exercises: procedures,
            goals: payload.objectiveAssessment.detailedCptCodes[foundIndex].goals,
            cptMinutes: payload.objectiveAssessment.detailedCptCodes[foundIndex].cptMinutes,
            justification: this.justification,
        };
        payload.objectiveAssessment.detailedCptCodes[foundIndex] = newRecord;

        this.dailyNotesService.updateDailyNote(payload, this.currentAdmissions).subscribe((result) => {
            if (!result) {
                return;
            }

            this.toaster.show({
                title: 'Successful',
                body: 'Procedure Added to CPT ' + this.currentCptCode,
                type: 'success',
            });
            this.onClose();
        });
        return;
    }
}
