<ag-grid-angular
    #grid
    class="ag-theme-alpine w-100 h-100"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [pagination]="pagination"
    [paginationPageSize]="paginationPageSize"
    (gridReady)="gridReady($event)"
    (gridSizeChanged)="gridSizeChanged($event)"
    (firstDataRendered)="gridPopulated($event)"
    paginationAutoPageSize="true"
    (rowClicked)="rowClicked($event)"
    suppressDragLeaveHidesColumns="true"
    [rowClassRules]="rowClassRules"
    [context]="context"
    [overlayLoadingTemplate]="overlayLoadingTemplate"
    (sortChanged)="onSortChanged($event)"
    [overlayNoRowsTemplate]="(loadingObs | async)?.isLoading ? overlayEmptyRowsTemplate : overlayNoRowsTemplate"
>
</ag-grid-angular>

<div class="ag-theme-alpine ag-paging-panel mt-2" *ngIf="showCount">
    <span class="ag-paging-row-summary-panel-number">Patients: {{ rowData.length }}</span>
</div>
