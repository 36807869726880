<div class="d-flex">
    <label class="body-medium-semibold">Set Target</label>
    <div *ngIf="minValue && showError" ngbTooltip="{{errorMessage}}" placement="top"
        class="material-icons-outlined d-flex flex-column align-self-center ml-5 text-danger mb-1 cursor-pointer">
        info
    </div>
</div>
<div class="d-flex align-items-center col-1-with-no-margins-padding">
    <ng-container *ngFor="let field of fields; index as i">
        <div class="form-group rounded-curve d-flex">
            <input
                #inputField
                class="form-control {{ inputClass }}"
                [ngClass]="backgroundClass ? backgroundClass : ''"
                [placeholder]="placeholderIndex[field]"
                [value]="valueIndex[field] ?? ''"
                (change)="onChange(field, $event)"
                (blur)="onChange(field, $event)"
                (keydown)="onChange(field, $event)"
                (keyup)="onChange(field, $event)"
                type="number"
            />
        </div>
        <div *ngIf="i < fields.length - 1" class="ml-2 mr-2 mb-3 body-medium-bold">:</div>
    </ng-container>
</div>
