export { AlertComponent } from './alert/alert.component';
import { DatePickerCalenderInputComponent } from './date-picker-calender-input/date-picker-calender-input.component';
import { DragDropComponent } from './drag-drop/drag-drop.component';
import { PatientsLeftCardComponent } from './patients-left-card/patients-left-card.component';
import { SelectDateComponent } from './select-date/select-date.component';
import { ToggleComponent } from './toggle/toggle.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { MrnBarComponent } from './mrn-bar/mrn-bar.component';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { InputComponent } from './input/input.component';
import { NInputsComponent } from './n-inputs/n-inputs.component';
import { SelectComponent } from './select/select.component';
import { SelectOptionComponent } from './select-option/select-option.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { TabSmallComponent } from './tab-small/tab-small.component';
import { StepsTabComponent } from './steps-tab/steps-tab.component';
import { GoBackComponent } from './go-back/go-back.component';
import { InputDropdownComponent } from './input-dropdown/input-dropdown.component';
import { QuickLinksComponent } from './quick-links/quick-links.component';
import { TableComponent } from './table/table.component';
import { BarCardComponent } from './bar-card/bar-card.component';
import { StepperNavComponent } from '@app/modules/add-patient/stepper-nav/stepper-nav.component';
import { OnSetDateCellRenderer } from '@app/helpers/AgGrid';
import { RecipientFacilityProfileGroupsComponent } from './recipient-facility-profile-groups/recipient-facility-profile-groups.component';

import { SelectWithCardsComponent } from './select-with-cards/select-with-cards.component';

import { PermanentAlertComponent } from './permanent-alert/permanent-alert.component';
import { DynamicContentCardComponent } from './dynamic-content-card/dynamic-content-card.component';
import { CardComponent } from './card/card.component';
import { GridCardComponent } from './grid-card/grid-card.component';
import { MedicalDiagnosisCardComponent } from './medical-diagnosis-card/medical-diagnosis-card.component';
import { TreatmentDiagnosisCardComponent } from './treatment-diagnosis-card/treatment-diagnosis-card.component';
import { ControlWidgetComponent } from './control-widget/control-widget.component';
import { SwitchComponent } from './switch/switch.component';

import { SearchComponent } from './search/search.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { DataListComponent } from './data-list/data-list.component';
import { TextBoxComponent } from './text-box/text-box.component';
import { ChipComponent } from './chip/chip.component';
import { BaseModalCardComponent } from './base-modal-card/base-modal-card.component';
import { DeletionConfirmationDialogComponent } from './deletion-confirmation-dialog/deletion-confirmation-dialog.component';
import { ButtonComponent } from './button/button.component';
import { ViewModeTableComponent } from './view-mode-table/view-mode-table.component';
import { ViewModeParagraphComponent } from './view-mode-paragraph/view-mode-paragraph.component';
import { ViewModeGoalsComponent } from './view-mode-goals/view-mode-goals.component';
import { HistoryComponent } from './history/history.component';
import { GoalModalComponent } from './charts/modals/goal-modal/goal-modal.component';
import { GoalsChartComponent } from './charts/goals-chart/goals-chart.component';
import { FrequencyAndDurationComponent } from './documentation/frequency-and-duration/frequency-and-duration.component';
import { DemographicsComponent } from './documentation/demographics/demographics.component';
import { VitalsComponent } from './documentation/vitals/vitals.component';
import { AddCptComponent } from './documentation/skilled-services/modals/add-cpt/add-cpt.component';
import { AddGoalsComponent } from './documentation/skilled-services/modals/add-goals/add-goals.component';
import { AddSkilledServicesComponent } from './documentation/skilled-services/modals/add-skilled-services/add-skilled-services.component';
import { AddProceduresComponent } from './documentation/skilled-services/modals/add-procedures/add-procedures.component';
import { SkilledServicesComponent } from './documentation/skilled-services/skilled-services.component';
import { ContraindicationsComponent } from './documentation/contraindications/contraindications.component';
import { PrecautionsComponent } from './documentation/precautions/precautions.component';
import { SkilledServicesTooltipComponent } from './documentation/custom/skilled-services-tooltip/skilled-services-tooltip.component';
import { AddItemTableComponent } from './add-item-table/add-item-table.component';
import { NotesGoalContainerComponent } from './documentation/notes-goal-container/notes-goal-container.component';
import { AutoSaveLoaderComponent } from './auto-save-loader/auto-save-loader.component';
import { CptCodesComponent } from './cpt-codes/cpt-codes.component';
import { PatientsSearchComponent } from './patients-search/patients-search.component';
import { PriorHospitalizationStaysComponent } from './prior-hospitalization-stays/prior-hospitalization-stays.component';
import { MinutesModalComponent } from './documentation/minutes-modal/minutes-modal.component';
import { SearchableListComponent } from './searchable-list/searchable-list.component';
import { SearchableDataGridComponent } from './searchable-data-grid/searchable-data-grid.component';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { CombinedInputComponent } from './combined-input/combined-input.component';
import { TimeConfirmationDialogComponent } from './time-confirmation-dialog/time-confirmation-dialog.component';
import { SimpleDatePickerComponent } from './simple-date-picker/simple-date-picker.component';
import { SchedulerTimeBlockComponent } from './scheduler-time-block/scheduler-time-block.component';
import { AddAppointmentNotesComponent } from './add-appointment-notes/add-appointment-notes.component';
import { SelectWithSearchComponent } from './select-with-search/select-with-search.component';
import { FacilitySelectionCombinedTableComponent } from './facility-selection-combined-table/facility-selection-combined-table.component';
import { TabsComponent } from './tabs/tabs.component';
import { PlanConfirmationModalComponent } from './plan-confirmation-modal/plan-confirmation-modal.component';
import { DropdownPillComponent } from './dropdown-pill/dropdown-pill.component';
import { PpMrnBarComponent } from './pp-mrn-bar/pp-mrn-bar.component';
import { PpViewModeGoalsComponent } from './pp-view-mode-goals/pp-view-mode-goals.component';
import { DocumentCommentsModalComponent } from './documents-comments-modal/documents-comments-modal.component'; // colworx-ak: add DocumentCommentsModalComponent
import { DocumentPendingStackComponent } from './document-pending-stack/document-pending-stack.component'; // colworx-sk
import { ToggleMobileSizeComponent } from './toggle-mobile-size/toggle-mobile-size.component';
import { DocumentRejectCommentModalComponent } from './document-reject-coment-modal/document-reject-coment-modal.component'; //colworx sk
import { CheckboxMultiSelectComponent } from './checkbox-multi-select/checkbox-multi-select.component';
import { AppointmentDetailsModalComponent } from './modals/appointment-details-modal/appointment-details-modal.component';
import { RadioBtnChangeValueComponent } from './radio-btn-change-value/radio-btn-change-value.component';
export const SHARED_COMPONENTS = [
    DatePickerCalenderInputComponent,
    DragDropComponent,
    PatientsLeftCardComponent,
    SelectDateComponent,
    ToggleComponent,
    SearchBarComponent,
    MrnBarComponent,
    InputComponent,
    NInputsComponent,
    CombinedInputComponent,
    SelectComponent,
    SelectOptionComponent,
    SelectWithSearchComponent,
    RadioButtonComponent,
    DatePickerComponent,
    TabSmallComponent,
    StepsTabComponent,
    GoBackComponent,
    InputDropdownComponent,
    QuickLinksComponent,
    BarCardComponent,
    TableComponent,
    OnSetDateCellRenderer,
    SelectWithCardsComponent,
    PermanentAlertComponent,
    DynamicContentCardComponent,
    StepperNavComponent,
    CardComponent,
    SearchComponent,
    GridCardComponent,
    MedicalDiagnosisCardComponent,
    TreatmentDiagnosisCardComponent,
    ControlWidgetComponent,
    SwitchComponent,
    CheckboxComponent,
    DataListComponent,
    TextBoxComponent,
    ChipComponent,
    BaseModalCardComponent,
    DeletionConfirmationDialogComponent,
    ButtonComponent,
    ViewModeTableComponent,
    ViewModeParagraphComponent,
    ViewModeGoalsComponent,
    HistoryComponent,
    GoalModalComponent,
    GoalsChartComponent,
    FrequencyAndDurationComponent,
    DemographicsComponent,
    VitalsComponent,
    AddCptComponent,
    AddGoalsComponent,
    AddSkilledServicesComponent,
    AddProceduresComponent,
    SkilledServicesComponent,
    ContraindicationsComponent,
    PrecautionsComponent,
    SkilledServicesTooltipComponent,
    AddItemTableComponent,
    NotesGoalContainerComponent,
    AutoSaveLoaderComponent,
    CptCodesComponent,
    PatientsSearchComponent,
    PriorHospitalizationStaysComponent,
    MinutesModalComponent,
    SearchableListComponent,
    SearchableDataGridComponent,
    MultiSelectComponent,
    TimeConfirmationDialogComponent,
    SimpleDatePickerComponent,
    SchedulerTimeBlockComponent,
    AddAppointmentNotesComponent,
    FacilitySelectionCombinedTableComponent,
    TabsComponent,
    PlanConfirmationModalComponent,
    DropdownPillComponent,
    PpMrnBarComponent,
    PpViewModeGoalsComponent,
    DocumentCommentsModalComponent,
    DocumentPendingStackComponent,
    ToggleMobileSizeComponent,
    DocumentRejectCommentModalComponent,
    CheckboxMultiSelectComponent,
    AppointmentDetailsModalComponent,
    RadioBtnChangeValueComponent,//colworx-ak
    RecipientFacilityProfileGroupsComponent,

];
