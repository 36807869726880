<app-card>
    <!-- Header -->
    <div class="header" *ngIf="view !== noDataView || heading !== ''">
        <div class="row">
            <div class="col-md-6 d-flex">
                <p class="text-secondary">{{ heading }}</p>
                &nbsp;&nbsp;<span
                    *ngIf="headerInfo"
                    class="material-symbols-outlined align-self-center text-secondary cursor-default"
                    ngbTooltip="{{ headerInfo }}"
                >
                    help
                </span>
            </div>
            <div class="col-md-6">
                <ng-container *ngFor="let item of headerActionItems">
                    <ng-template *ngIf="view === noDataView; else roundedButtons">
                        <app-button
                            *ngIf="item.payload?.views.includes(view) || item.hasHeaderItem"
                            (click)="toggleIsDone()"
                            type="secondary"
                            [text]="item.label"
                            [icon]="item.icon"
                            class="{{ item.class }} float-right"
                        ></app-button>
                    </ng-template>
                    <ng-template #roundedButtons>
                        <button
                            *ngIf="item.payload?.views.includes(view) || item.hasHeaderItem"
                            type="button"
                            class="{{ item.class }} btn-circle float-right"
                            (click)="invokeActionFor(item)"
                            [ngbTooltip]="heading === 'Goals' ? 'Long Term Goal' : ''"
                            placement="left"
                        >
                            <span class="material-icons-outlined text-white-100" style="vertical-align: middle">
                                {{ item.icon }}
                            </span>
                            <!-- <span class="body-small">{{ item.label }}</span> -->
                        </button>
                    </ng-template>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="body">
        <!-- Content to show if no item -->
        <div *ngIf="view === noDataView" class="body-small-semibold text-secondary text-center my-3">
            <p>No Added Items</p>
            <app-button
                type="secondary"
                icon="add"
                class="ml-1 mt-2"
                [text]="'Add New'"
                (click)="onDefaultAction()"
            ></app-button>
        </div>
        <!-- Content / Card Body -->
        <ng-content *ngIf="view !== noDataView"></ng-content>
        <!-- Footer -->
        <div class="d-flex">
            <div style="margin-left: auto">
                <button
                    *ngFor="let item of footerActionItems"
                    type="button"
                    [class]="item.class"
                    (click)="item.action()"
                >
                    <span class="material-icons-outlined text-white-100" style="vertical-align: middle">
                        {{ item.icon }} </span
                    ><span class="body-small">&nbsp;{{ item.label }}</span>
                </button>
            </div>
        </div>
    </div>
</app-card>
