import { Component, Inject, OnInit } from '@angular/core';
import { DIALOG_DATA } from '@app/helpers/dialog-tokens';
import { DialogRef } from '@app/services';

@Component({
    selector: 'app-plan-confirmation-modal',
    templateUrl: './plan-confirmation-modal.component.html',
    styleUrls: ['./plan-confirmation-modal.component.scss'],
})
export class PlanConfirmationModalComponent implements OnInit {
    heading = 'Confirm Plan';
    message = '';
    plan: any;
    button1Text = 'Yes';
    button2Text = 'No';
    frequency = '';
    counter = 0;

    constructor(@Inject(DIALOG_DATA) private data, private dialogRef: DialogRef) {
        this.plan = data?.plan;
    }

    ngOnInit(): void {
        if (this.plan?.frequency?.mode) {
            Object.keys(this.plan.frequency.mode).forEach((day) => {
                if (this.plan.frequency.mode[day]) {
                    this.counter = this.counter + 1;
                    const capitalizedDay = day.charAt(0).toUpperCase() + day.slice(1);
                    this.frequency = this.frequency + capitalizedDay + ', ';
                }
            });
            // Removing comma from last lament
            if (this.frequency.length > 2) {
                this.frequency = this.frequency.slice(0, -2);
            }
        }
    }

    deleteAction(isDeleted) {
        this.dialogRef.close(isDeleted);
    }
}
