import { createReducer, on } from '@ngrx/store';
import { userChangeAction, userLogoutAction } from './../actions/user.action';

import { initialLoginState } from '@app/store/selectors';
export const userReducer = createReducer(
    initialLoginState,
    on(userChangeAction, (state, { refreshToken, accessToken, user }) => ({
        refreshToken: refreshToken,
        accessToken: accessToken,
        user: user,
    })),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    on(userLogoutAction, (state) => ({
        ...initialLoginState,
    }))
);
