import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ROUTER_UTILS } from '@app/helpers';
import { PccResolver } from '@app/resolvers/pcc/pcc.resolver';
import { AddendumComponent } from './addendum/addendum.component';
import { DailyNotesComponent } from './daily-notes/daily-notes.component';
import { DischargeNoteComponent } from './discharge-note/discharge-note.component';
import { EvalDocComponent } from './eval-doc/eval-doc.component';
import { RecertificationComponent } from './recertification/recertification.component';
import { UpdatePlanOfCareComponent } from './update-plan-of-care/update-plan-of-care.component';
import { ViewModProgressNoteComponent } from './view-mod-progress-note/view-mod-progress-note.component';
import { ViewModeLayoutComponent } from './view-mode-layout/view-mode-layout.component';
const routes: Routes = [
    {
        path: ROUTER_UTILS.config.documentationViewMode.home,
        component: ViewModeLayoutComponent,
        children: [
            { path: ROUTER_UTILS.config.documentationViewMode.dailyNoteDoc.root, component: DailyNotesComponent },
            {
                path: ROUTER_UTILS.config.documentationViewMode.progressNoteDoc.root,
                component: ViewModProgressNoteComponent,
            },
            {
                path: ROUTER_UTILS.config.documentationViewMode.updatePlanOfCare.root,
                component: UpdatePlanOfCareComponent,
            },
            {
                path: ROUTER_UTILS.config.documentationViewMode.reCertificationDoc.root,
                component: RecertificationComponent,
            },
            { path: ROUTER_UTILS.config.documentationViewMode.dischargeNote.root, component: DischargeNoteComponent },
            { path: ROUTER_UTILS.config.documentationViewMode.evalDoc.root, component: EvalDocComponent },
            { path: ROUTER_UTILS.config.documentationViewMode.addendum.root, component: AddendumComponent },
        ],
        resolve: {
            currentTherapyAdmission: PccResolver
        }
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ViewModeRoutingModule {}
