<div [class]="'body-medium text-secondary ' + class" [class.input-error]="hasError">
    <label class="form-label body-medium-semibold {{ labelClass }}" *ngIf="label"
        >{{ label }} {{ isRequired ? '*' : '' }}</label
    >
    <div class="position-relative">
        <div
            class="position-relative border-radius-10"
            [class]="backgroundClass ? backgroundClass : 'bg-body-background'"
            [ngClass]="{ 'border border-danger': counter !== 0 }"
            [class.input-error]="hasError"
            [ngStyle]="{ border: disabled ? '' : '1px solid rgba(28, 55, 117, 0.15)' }"
        >
            <div #backdrop class="backdrop form-control" style="z-index: 1 !important">
                <div class="highlights" [innerHTML]="highlightedText"></div>
            </div>
            <textarea
                #suggestionBox
                class="form-control"
                [ngClass]="{ border: !hasError && borderClass }"
                style="z-index: 2 !important; background: transparent"
                [rows]="rows"
                [placeholder]="placeholder"
                [ngModel]="suggestionBoxValue"
                [disabled]="disabled"
                (keyup)="onSearch($event)"
                (ngModelChange)="valueChange($event)"
                (scroll)="handleScroll()"
                (click)="clickHandler(suggestionBox)"
                (focus)="focusHandler($event)"
                [maxlength]="maxLength"
                >{{ suggestionBoxValue }}</textarea
            >
        </div>
        <ng-container *ngIf="isSuggestionBox">
            <span
                class="icon-position material-icons cursor-pointer"
                style="z-index: 5 !important"
                [ngClass]="{'text-disabled' : !isSuggestionEnabled || suggestionsList?.length <= 0 || disabled, 'pointer-events-none': disabled}"
                (click)="onSuggestionToggle()"
                [ngbTooltip]="suggestionsList?.length > 0 ? 'Suggestions' : 'No library items found'"
                placement="left"
            >
                fact_check
            </span>
            <div
                *ngIf="suggestionsListFiltered?.length > 0 && isSuggestionEnabled === true"
                class="data-list zindex-dropdown"
            >
                <table class="table table-borderless">
                    <thead>
                        <tr>
                            <th>Sr.No</th>
                            <th>Suggestion</th>
                        </tr>
                    </thead>
                    <tbody #suggestionListElement>
                        <tr
                            *ngFor="let suggestion of suggestionsListFiltered; let i = index"
                            [ngClass]="{ hovered: suggestion === highlightedSuggestion }"
                            (mousedown)="onSuggestionSelect(suggestion)"
                            (mouseover)="setHighlightedItem(i)"
                            [id]="i + '-selection-item'"
                        >
                            <td>{{ i + 1 }}</td>
                            <td class="bottom-border">{{ suggestion }}</td>
                        </tr>
                    </tbody>
                </table>
                <!-- <option *ngFor="let suggestion of suggestionsListFiltered" value="{{ suggestion }}"
          (click)="onSuggestionSelect(suggestion)">
          {{ suggestion }}
        </option> -->
            </div>
        </ng-container>
    </div>
</div>
