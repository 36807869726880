import { Component, ElementRef, ViewChild } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'app-custom-cell-editor',
    templateUrl: './custom-cell-editor.component.html',
    styleUrls: ['./custom-cell-editor.component.scss'],
})
export class CustomCellEditorComponent implements ICellEditorAngularComp {
    params: any;
    value: any;
    showEdit = true;
    @ViewChild('time') timeEl: ElementRef;

    agInit(params: any): void {
        this.params = params;
        this.value = this.params.value;
        if (this.params.data.code === 'Total') {
            this.showEdit = false;
        }
    }

    showTimeInput() {
        this.showEdit = true;
        this.value = '1';
        setTimeout(() => {
            this.timeEl.nativeElement.focus();
        }, 0);
    }

    getValue(): any {
        return this.value;
    }

    isPopup(): boolean {
        return true; // Return 'true' if the custom editor appears as a popup, 'false' if it appears embedded in the cell.
    }

    onEnterKey(): void {
        // Called when Enter key is pressed
        // this.params.stopEditing(); // Commit the edited value and close the editor
        this.params.context.componentParent.onCellValueChanged(this.params);
    }

    onEscapeKey(): void {
        // Called when Escape key is pressed
        // this.params.stopEditing(true); // Cancel the editing and close the editor
        // this.params.context.componentParent.onCellValueChanged(this.params);
    }

    onBlur(): void {
        // Called when the input loses focus
        // this.params.stopEditing(); // Commit the edited value and close the editor
        this.params.context.componentParent.onCellValueChanged(this.params);
    }

    onFocus(): void {
        // this.value = '';
    }

    validateInput(event: any): void {
        const value = event?.target?.value ? Number(event.target.value) : 0;
        if (value % 1 !== 0) {
            this.value = parseInt(value.toString().split('.').join(''));
            this.params.value = this.value;
        }
    }
}
