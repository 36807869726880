import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as ExcelJS from 'exceljs';
import { environment } from '@environments/environment';
import { FacilityManagementService } from './facilityManagement.service';
import { ExcelService } from './excel.service';
import { OrganizationManagementService } from './organizationManagement.service';
const baseUrl = environment.apiUrl + '/billing/recipient';
@Injectable({
    providedIn: 'root',
})
export class RecipientService {
    facilities = [];
        constructor(private http: HttpClient, private facilityService: FacilityManagementService,private _excelService: ExcelService,private organizationService : OrganizationManagementService) { 
            this.facilityService._Facilities.subscribe((result) => {
                this.organizationService.selectedOrganization;
            this.organizationService._currentOrganization;
                if (result.length > 0) {
                    this.facilities = result;
                }
            });
        }

    updateRecipientInFacilities(selectedFacilities: any, removedFacilities: any, currentRecipient: any) {
        const facilities = this.facilities
        selectedFacilities?.forEach((facility) => {
            const found = facilities.findIndex((data) => data._id ? data._id : data.id === facility.id);
            if (found != -1) {
                const index = facilities[found].recipients?.findIndex((recipient: any) => recipient?._id == currentRecipient?._id);
                if (index === -1 || index == undefined) {
                    // If not present, push the recipient to the recipients array
                    if (!facilities[found].recipients) {
                        facilities[found].recipients = [];
                    }
                    facilities[found].recipients.push(currentRecipient);
                }
            }
        });
        removedFacilities?.forEach((facility) => {
            const found = facilities.findIndex((data) => data._id === facility.id);
            if (found != -1) {
                const index = facilities[found].recipients?.findIndex((recipient: any) => recipient?._id == currentRecipient?._id);
                if (index > -1) {
                    facilities[found].recipients.splice(index, 1);
                }
            }
        });
        this.facilities = facilities;
        this.facilityService.updateFacilities(facilities);
        this.facilityService.mapFacilitiesIntoDropdown();
    }

    updateRecipientInFacility(data) {
        let facilities = [];
        this.facilityService._Facilities.subscribe((result) => {
            if (result.length > 0) {
                facilities = result;
            }
        });
        data?.forEach((facility) => {
            const found = facilities.findIndex((data) => data.id === facility._id);
            if (found != -1) {
                facilities[found].recipients = facility.recipients;
            }
        });
        this.facilityService.updateFacilities(facilities);
        this.facilityService.mapFacilitiesIntoDropdown();
    }

    getRecipientById(id: string): Observable<any> {
        return this.http.get<any>(`${baseUrl}/${id}`);
    }
    createRecipientProfile(payload: any) {
        return this.http.post(`${baseUrl}/create-recipient-profile`, payload, {
            withCredentials: true,
        });
    }
    updateRecipientProfileStatus(id: string, recipient: any) {
        return this.http.put(
            `${baseUrl}/update-recipient-profile-status/${id}`,
            { recipient },
            {
                withCredentials: true,
            }
        );
    }
    updateRecipientProfile(id: string, recipient: any) {
        return this.http.put(
            `${baseUrl}/update-recipient-profile/${id}`,
            { recipient },
            {
                withCredentials: true,
            }
        );
    }

    getRecipientByOrganizationId(organizationId: string) {
        return this.http.get(`${baseUrl}/get-recipient-by-organization/${organizationId}`);
    }
    deleteAndUnLinkRecipient(ids: any) {
        // eslint-disable-next-line spellcheck/spell-checker
        return this.http.post(`${baseUrl}/facility/remove-unlink-recipient`,
            { ids },
            {
                withCredentials: true,
            }
        );
    }
    printTable(data, organizationName) {
        const printWindow = window.open('', '', 'height=600,width=800');
        if (!printWindow) return;
        // Render Html to the new Window
        this.renderTable(data, printWindow, organizationName);
        // Close the document to trigger the print dialog
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
    }
    exportList(selectedRecipients: any[] = [], head = {}, fileName = ''): void {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(fileName);
        // Add headers
        const headers = Object.keys(head);
        const headerRow = worksheet.addRow(headers);
        headerRow.eachCell({ includeEmpty: true }, (cell) => {
            cell.font = { bold: true };
        }); 
        // Define a mapping for header keys to recipient data extraction
        const valueMapping: { [key: string]: (recipient: any) => any } = {
            'Name': (recipient) => `${recipient.lastName}, ${recipient.firstName}`,
            'Facilities': (recipient) => recipient.assignedFacilities?.length || 0,
            'Customers': (recipient) => recipient.associatedProfiles?.length || 0,
            'Email': (recipient) => recipient.email
        }; 
        // Add data rows
        selectedRecipients.forEach((recipient) => {
            const row = headers.map((key) => {
                return valueMapping[key] ? valueMapping[key](recipient) : '';
            });
            worksheet.addRow(row);
        });
        // Adjust column widths
         worksheet.columns.forEach((column) => {
            let maxLength = 0;
            column.eachCell({ includeEmpty: true }, (cell) => {
                const columnLength = cell.value ? cell.value.toString().length : 10;
                if (columnLength > maxLength) {
                    maxLength = columnLength;
                }
            });
            column.width = maxLength < 10 ? 10 : maxLength;
        }); 
        // Trigger the download
        this._excelService.downloadExcelSheet(workbook, fileName);
    }
    renderTable(data, printWindow, orgName) {
        printWindow.document.write('<html>');
        printWindow.document.write('<head><style>');
        printWindow.document.write('table { width: 100%; border-collapse: collapse; }');
        printWindow.document.write('th, td { border: 1px solid black; padding: 8px; text-align: left; }');
        printWindow.document.write('th { background-color: #F2F2F2; }');
        printWindow.document.write(
            'div.header { display: flex; justify-content: space-between; margin-bottom: 10px; }'
        );
        printWindow.document.write('div.header span { font-weight: normal; }');
        printWindow.document.write('div.header .heading { font-weight: bold; }');
        printWindow.document.write('</style></head><body>');
        // Table Header
        printWindow.document.write('<table>');
        printWindow.document.write(
            '<thead><tr><th>Name</th><th>Email</th><th>Total Facilities</th><th>Total Customer</th></tr></thead>'
        );
        printWindow.document.write('<tbody>');
        // Header
        printWindow.document.write('<div class="header">');
        printWindow.document.write('<div>');
        printWindow.document.write('<span class="heading">Org Name: </span><span>' + orgName + '</span>');
        printWindow.document.write('</div>');
        printWindow.document.write('</div>');
        // Recipient Management
        printWindow.document.write('<div>');
        printWindow.document.write('<span  style="font-weight: bold; margin-bottom:1vh;">Recipient Management </span>');
        printWindow.document.write('<br/>');
        printWindow.document.write('</div>');
        // Table Body
        data.forEach((recipient) => {
            printWindow.document.write(
                `<tr><td>${recipient.lastName + ',' + recipient.firstName}</td><td>${recipient.email}</td><td>${
                    recipient.assignedFacilities?.length || 0
                }</td><td>${recipient.associatedProfiles?.length || 0}</td></tr>`
            );
        });
        printWindow.document.write('</tbody></table>');
        printWindow.document.write('</body></html>');
    }  
}