import { Component, Inject } from '@angular/core';
import { DIALOG_DATA } from '@app/helpers/dialog-tokens';
import { DialogRef } from '@app/services';
import { Options } from '../checkbox/checkbox.component';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-time-confirmation-dialog',
    templateUrl: './time-confirmation-dialog.component.html',
    styleUrls: ['./time-confirmation-dialog.component.scss'],
})
export class TimeConfirmationDialogComponent {
    content: any;
    showAgain: [];
    dateForm: FormGroup;
    constructor(@Inject(DIALOG_DATA) private data, private dialogRef: DialogRef) {
        this.content = data;
        if (data.toDate) {
            this.dateForm = new FormGroup({
                fromDate: new FormControl(''),
                toDate: new FormControl(''),
            });
            this.dateForm.patchValue({ fromDate: new Date(data.fromDate), toDate: new Date(data.toDate) });
            this.dateForm.controls.fromDate.valueChanges.subscribe((value) => {
                this.dialogRef.emitAction('fromDate', value);
            });
            this.dateForm.controls.toDate.valueChanges.subscribe((value) => {
                this.dialogRef.emitAction('toDate', value);
            });
        }
    }

    confirmAction(isConfirmed) {
        this.dialogRef.close(isConfirmed);
    }

    closeAction(close) {
        this.dialogRef.close(close);
    }

    onCheckboxChange({ isChecked }: Options) {
        this.dialogRef.emitAction('showAgain', isChecked);
    }
}
