import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SignedNoteService {
    constructor(private http: HttpClient) {}

    getSignedNote(params: any, noteType: any): Observable<any> {
        if (!params) {
            return of(null);
        }
        const baseUrl = `${environment.apiUrl}/${noteType}`;
        return this.http.get(`${baseUrl}/signed-note/${params.doc}`);
    }
}
