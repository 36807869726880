import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbCalendar, NgbDatepickerConfig, NgbDateStruct, NgbInputDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-select-date',
    templateUrl: './select-date.component.html',
    styleUrls: ['./select-date.component.scss'],
})
export class SelectDateComponent implements OnInit {
    showPicker = false;
    @Input() datePicked;
    @Output() date$ = new EventEmitter();
    myDate: Date;
    model: { month: number; day: number; year: number };
    constructor(config: NgbDatepickerConfig, inputConfig: NgbInputDatepickerConfig, calendar: NgbCalendar) {
        this.customizeDatePicker(config, inputConfig, calendar);
    }
    ngOnInit(): void {
        this.setDate();
        this.emitResults(this.model);
    }
    togglePicker(): void {
        this.setDate();
        this.showPicker = !this.showPicker;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    customizeDatePicker(config, inputConfig, calendar) {
        // customize default values of datepickers used by this component tree
        config.minDate = { year: 1950, month: 1, day: 1 };
        config.maxDate = { year: 2022, month: 12, day: 31 };
        // days that don't belong to current month are not visible
        config.outsideDays = 'collapsed';
        inputConfig.autoClose = true;
        // weekends are disabled
        //config.markDisabled = (date: NgbDate) => calendar.getWeekday(date) >= 6;
        // setting datepicker popup to close only on click outside
        // setting datepicker popup to open above the input
        // config.placement = ['top-left', 'top-right'];
    }

    emitResults(value) {
        this.togglePicker();
        this.date$.emit(this.toDateJs(value).toString());
    }
    setDate() {
        if (this.datePicked) {
            this.myDate = new Date(this.datePicked);
            this.model = {
                month: this.myDate.getMonth() + 1,
                day: this.myDate.getDate(),
                year: this.myDate.getFullYear(),
            };
        }
    }
    toDateJs(date: NgbDateStruct): Date {
        return date ? new Date(Date.UTC(date.year, date.month - 1, date.day, 0, 0, 0)) : null;
    }
}
