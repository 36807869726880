import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
export interface Button {
  text: string;
  isDisabled?: boolean;
  isSelected: boolean;
  hasIndicator?: boolean;
  tooltip?: string;
  toolTipPlacement?: string;
  name?: string
}
@Component({
  selector: 'app-toggle-mobile-size',
  templateUrl: './toggle-mobile-size.component.html',
  styleUrls: ['./toggle-mobile-size.component.scss']
})
export class ToggleMobileSizeComponent implements OnInit {

  @Input() buttons: Button[];
    @Input() selectedButton: string;
    @Input() hasNavigation: boolean;
    @Input() fullWidth = true;
    @Input() class: string;
    @Input() buttonClass: string;
    @Output() buttonChanged = new EventEmitter<string>();

    ngOnInit(): void {
        // this.buttonChanged.emit(this.selectedButton);
    }
    // Actions
    setSelectedButton(button: string) {
        this.buttons = this.buttons.map((b) => {
            if (b.text.toLowerCase() === button.toLowerCase()) b.isSelected = true;
            else b.isSelected = false;
            return b;
        });
        this.selectedButton = button;
        this.buttonChanged.emit(this.selectedButton);
    }

    previousTab() {
        document.getElementById('tabs').scrollLeft -= 300;
    }
    nextTab() {
        document.getElementById('tabs').scrollLeft += 300;
    }

}
