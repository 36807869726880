import { DatePipe } from '@angular/common';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    Output,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActionsCellRendererComponent, RadioButtonCellRendererComponent } from '@app/data-grid/cell-renderers';
import { GridRowAction } from '@app/data-grid/interfaces/grid-row-action';
import { GridActionsService } from '@app/data-grid/services/grid-actions.service';
import { OnSetDateCellRenderer } from '@app/helpers';
import { FacilityAdmission, TherapyAdmission } from '@app/interfaces';
import { DOCUMENTATION_CONSTANTS, PATIENT_DEMOGRAPHICS, SEARCHABLE_LIST_NAMES } from '@app/interfaces/documentation';
import { ClinicalCategoriesService, ModalService, SlpComorbidityService, StoreService } from '@app/services';
import { loadingSpinnerState, SLPComorbidity } from '@app/store/store.interface';
import { Store } from '@ngrx/store';
import { ColDef, GridApi, RowClassRules } from 'ag-grid-community';
import * as _ from 'lodash';
import { cloneDeep, isEqual } from 'lodash';
import { map, Observable, of, Subject, switchMap, take, takeUntil } from 'rxjs';
import { DeletionConfirmationDialogComponent } from '../deletion-confirmation-dialog/deletion-confirmation-dialog.component';
import { ConfirmationModalComponent } from '../modals/v2/confirmation-modal/confirmation-modal.component';

@Component({
    selector: 'app-searchable-data-grid',
    templateUrl: './searchable-data-grid.component.html',
    styleUrls: ['./searchable-data-grid.component.scss'],
})
export class SearchableDataGridComponent implements OnChanges, OnDestroy {
    public therapyDiscipline;
    private readonly onDestroy = new Subject<void>();

    @Input() name: string; // always required
    @Input() recordsInput: any[];
    @Input() heading = '';
    @Input() onSetDate = '';
    @Input() discipline = '';
    @Input() isReadOnly = false;
    @Input() disableAdd = false;
    @Input() recordsData = [];
    @Input() withOutHeader = false;
    @Input() maxOnSetDate: string;
    @Input() maxTherapyOnsetDate: string;
    @Input() additionalInfo: string;
    @Output() recordsEvent: EventEmitter<any[]> = new EventEmitter<any[]>();
    @Output() recordsSave: EventEmitter<any[]> = new EventEmitter();
    @Output() isPrimaryMarked: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('seeMoreBtn') seeMoreBtn: ElementRef;
    @ViewChild('searchBar') searchBar: ElementRef;

    state: 'zero' | 'one' = 'zero';
    loading = false;
    isDone = true;
    gridApi: GridApi;
    data: any[] = [];
    patientDemographic = PATIENT_DEMOGRAPHICS;
    currentAdmission: TherapyAdmission;
    therapyAdmissions$: Observable<TherapyAdmission[]>;
    selectedData: any[] = [];
    facilityAdmission$: Observable<FacilityAdmission>;
    allRecords: any[] = [];
    seeMore: { offset: number; active: boolean; chunkSize: number } = {
        offset: 0,
        active: true,
        chunkSize: 10,
    };
    focusEvent: Subject<boolean> = new Subject<boolean>();

    SEARCHABLE_LIST_NAMES = SEARCHABLE_LIST_NAMES;
    primaryDXMarked = false;
    normalSelectedRows = [];

    cols: {
        [name: string]: {
            recordCols: ColDef[];
            editCols: ColDef[];
            readOnlyCols: ColDef[];
            rowSelection?: string;
            suppressRowClickSelection?: boolean;
        };
    } = {
        [SEARCHABLE_LIST_NAMES.MEDICAL_DIAGNOSIS]: {
            recordCols: [
                {
                    headerName: 'Code',
                    field: 'code',
                    minWidth: 120,
                    maxWidth: 120,
                    checkboxSelection: true,
                    headerCheckboxSelection: true,
                },
                { headerName: 'Description', field: 'description' },
                { headerName: 'Pdpm Clinical Category', field: 'clinicalCategory' },
                { headerName: 'Major Procedure', field: 'majorProcedure' },
                { headerName: 'SLP Comorbidity', field: 'comorbidity' },
                {
                    headerName: this.primaryDXMarked
                        ? DOCUMENTATION_CONSTANTS.PRIMARY_DX
                        : DOCUMENTATION_CONSTANTS.PRIMARY_DX_REQUIRED,
                    field: 'isPrimary',
                    cellRenderer: RadioButtonCellRendererComponent,
                    headerClass: () => {
                        return this.primaryDXMarked ? '' : 'primary-dx-required';
                    },
                },
                {
                    headerName: 'Onset Date',
                    field: 'onsetDate',
                    cellRenderer: OnSetDateCellRenderer,
                    cellRendererParams: (params) => {
                        try {
                            params.data = JSON.parse(JSON.stringify(params.data));
                            params.data.maxOnSetDate = this.maxOnSetDate;
                        } catch (err) {
                            console.log(err.message);
                        }
                    },
                },
                // {
                //     headerName: 'Actions',
                //     type: 'rightAligned',
                //     field: 'actions',
                //     cellRenderer: ActionsCellRendererComponent,
                //     cellRendererParams: {
                //         delete: true,
                //         gridName: 'MedicalDiagnosis',
                //     },
                // },
            ],
            rowSelection: 'multiple',
            suppressRowClickSelection: true,
            editCols: [
                {
                    headerName: 'Code',
                    field: 'code',
                    cellStyle: { cursor: 'pointer' },
                    checkboxSelection: true,
                    headerCheckboxSelection: true,
                },
                { headerName: 'Description', field: 'description', cellStyle: { cursor: 'pointer' } },
                { headerName: 'Pdpm Clinical Category', field: 'clinicalCategory', cellStyle: { cursor: 'pointer' } },
                { headerName: 'Major Procedure', field: 'majorProcedure', cellStyle: { cursor: 'pointer' } },
                { headerName: 'SLP Comorbidity', field: 'comorbidity', cellStyle: { cursor: 'pointer' } },
            ],
            readOnlyCols: [
                { headerName: 'Code', field: 'code', width: 80 },
                { headerName: 'Description', field: 'description', width: 150 },
                { headerName: 'Pdpm Clinical Category', field: 'clinicalCategory', width: 150 },
                { headerName: 'Major Procedure', field: 'majorProcedure', width: 150 },
                { headerName: 'SLP Comorbidity', field: 'comorbidity', width: 150 },
                {
                    headerName: 'Onset Date',
                    field: 'onsetDate',
                    width: 150,
                    valueFormatter: (params) => {
                        return this.dateFormatter(params);
                    },
                },
                {
                    headerName: '',
                    field: 'isPrimary',
                    width: 150,
                    cellRenderer: (params) => {
                        let badges = '';
                        let style = 'badge p-2 px-3 rounded-pill ';
                        if (params.data.isPrimary) {
                            style += 'bg-secondary-a10 text-secondary';

                            badges += `<span class='${style} ml-1 d-inline-block align-middle'>Primary</span>`;
                        }
                        return `<span class="d-block">${badges}</span>`;
                    },
                },
            ],
        },
        [SEARCHABLE_LIST_NAMES.TREATMENT_DIAGNOSIS]: {
            recordCols: [
                { headerName: 'Code', field: 'code', minWidth: 120, maxWidth: 120, checkboxSelection: true, headerCheckboxSelection: true,},
                { headerName: 'Description', field: 'description', width: 200 },
                {
                    headerName: 'Onset Date',
                    field: 'onsetDate',
                    cellRenderer: OnSetDateCellRenderer,
                    cellRendererParams: (params) => {
                        try {
                            const modifiedData = { ...params.data };
                            const isOnsetRequired = modifiedData.onsetDate == "" && 
                                                    (this.currentUrl.includes('evaluation-document') || 
                                                     this.currentUrl.includes('upoc-note-document') || 
                                                     this.currentUrl.includes('re-certification-document'));
                            return {
                                data: modifiedData,
                                maxOnSetDate: this.maxTherapyOnsetDate,
                                isOnsetRequired: isOnsetRequired, // Pass as an additional param
                            };
                            } catch (err) {
                            console.log(err.message);
                        }
                    },
                },
            ],
            editCols: [
                { headerName: 'Code', field: 'code', cellStyle: { cursor: 'pointer' },
                checkboxSelection: true,
                headerCheckboxSelection: true, },
                { headerName: 'Description', field: 'description', cellStyle: { cursor: 'pointer' } },
            ],
            readOnlyCols: [
                { headerName: 'Code', field: 'code', width: 80 },
                { headerName: 'Description', field: 'description', width: 280 },
                {
                    headerName: 'Onset Date',
                    field: 'onsetDate',
                    width: 150,
                    valueFormatter: (params) => {
                        return this.dateFormatter(params);
                    },
                },
            ],
        },
        [PATIENT_DEMOGRAPHICS.PRECAUTIONS]: {
            recordCols: [
                { headerName: 'No.', valueGetter: 'node.rowIndex + 1', minWidth: 80 },
                {
                    field: 'description',
                    wrapText: true,
                    autoHeight: true,
                    width: 900,
                    cellStyle: () => {
                        return { wordBreak: 'normal' };
                    },
                },
                {
                    field: 'actions',
                    type: 'rightAligned',
                    cellRenderer: ActionsCellRendererComponent,
                    cellRendererParams: { delete: true, gridName: 'precautionCols' },
                },
            ],
            editCols: [
                {
                    headerName: 'No.',
                    valueGetter: 'node.rowIndex + 1',
                    resizable: true,
                    maxWidth: 70,
                    cellStyle: { cursor: 'pointer' },
                },
                {
                    headerName: 'Description',
                    field: 'description',
                    wrapText: true,
                    autoHeight: true,
                    width: 900,
                    cellStyle: { cursor: 'pointer' },
                },
            ],
            readOnlyCols: [
                { headerName: 'No.', valueGetter: 'node.rowIndex + 1', resizable: true, maxWidth: 70 },
                {
                    headerName: 'Description',
                    field: 'description',
                    wrapText: true,
                    autoHeight: true,
                    width: 900,
                },
            ],
        },
        [PATIENT_DEMOGRAPHICS.CONTRAINDICATIONS]: {
            recordCols: [
                { headerName: 'No.', minWidth: 70, valueGetter: 'node.rowIndex + 1' },
                {
                    field: 'description',
                    wrapText: true,
                    width: 900,
                    autoHeight: true,
                    cellStyle: () => {
                        return { wordBreak: 'normal' };
                    },
                },
                {
                    field: 'actions',
                    type: 'rightAligned',
                    cellRenderer: ActionsCellRendererComponent,
                    cellRendererParams: { delete: true, gridName: 'contraindicationCols' },
                },
            ],
            editCols: [
                { headerName: 'No.', valueGetter: 'node.rowIndex + 1', maxWidth: 70, cellStyle: { cursor: 'pointer' } },
                {
                    headerName: 'Description',
                    field: 'description',
                    wrapText: true,
                    autoHeight: true,
                    width: 900,
                    cellStyle: { cursor: 'pointer' },
                },
            ],
            readOnlyCols: [
                { headerName: 'No.', valueGetter: 'node.rowIndex + 1', maxWidth: 70 },
                {
                    headerName: 'Description',
                    field: 'description',
                    wrapText: true,
                    autoHeight: true,
                    width: 900,
                },
            ],
        },
        [SEARCHABLE_LIST_NAMES.CPT_CODES]: {
            recordCols: [
                {
                    headerName: 'Code',
                    field: 'cptCode',
                    width: 10,
                    sortable: true,
                    sort: 'asc',
                    sortingOrder: ['desc', 'asc'],
                },
                { headerName: 'Description', field: 'description' },
                {
                    headerName: 'Actions',
                    field: 'actions',
                    type: 'rightAligned',
                    cellRenderer: ActionsCellRendererComponent,
                    cellRendererParams: {
                        delete: true,
                        gridName: 'cptCodes',
                    },
                },
            ],
            editCols: [
                {
                    headerName: 'Code',
                    field: 'cptCode',
                    cellStyle: { cursor: 'pointer' },
                    sortable: true,
                    sort: 'asc',
                    sortingOrder: ['desc', 'asc'],
                },
                { headerName: 'Description', field: 'description', cellStyle: { cursor: 'pointer' } },
            ],
            readOnlyCols: [
                { headerName: 'Code', field: 'cptCode', width: 10 },
                { headerName: 'Description', field: 'description' },
                {
                    headerName: 'Actions',
                    field: 'actions',
                    type: 'rightAligned',
                    cellRenderer: ActionsCellRendererComponent,
                    cellRendererParams: {
                        delete: true,
                        gridName: 'cptCodes',
                    },
                },
            ],
        },
        [SEARCHABLE_LIST_NAMES.COMORBIDITY]: {
            recordCols: [
                { headerName: 'Code', field: 'code', width: 60, suppressMovable: true },
                { headerName: 'Description', field: 'description', width: 250, suppressMovable: true },
                { headerName: 'SLP Clinical Category', field: 'clinicalCategory', suppressMovable: true },
                { headerName: 'SLP comorbidity', field: 'comorbidity', suppressMovable: true },
                {
                    headerName: 'Actions',
                    field: 'actions',
                    type: 'rightAligned',
                    width: 100,
                    suppressMovable: true,
                    cellRenderer: ActionsCellRendererComponent,
                    cellRendererParams: {
                        delete: true,
                        gridName: 'comorbidity',
                    },
                },
            ],
            editCols: [
                {
                    headerName: 'Code',
                    field: 'code',
                    width: 60,
                    suppressMovable: true,
                    cellStyle: { cursor: 'pointer' },
                },
                {
                    headerName: 'Description',
                    field: 'description',
                    suppressMovable: true,
                    cellStyle: { cursor: 'pointer' },
                },
                {
                    headerName: 'SLP Clinical Category',
                    field: 'clinicalCategory',
                    suppressMovable: true,
                    cellStyle: { cursor: 'pointer' },
                },
                {
                    headerName: 'SLP comorbidity',
                    field: 'comorbidity',
                    suppressMovable: true,
                    cellStyle: { cursor: 'pointer' },
                },
            ],
            readOnlyCols: [{ headerName: 'Code', field: 'code', width: 10 }],
        },
    };

    paginate: { page: number; total: number } = { page: 1, total: 0 };
    searchedText = '';
    @Input() currentUrl = '';
    macId;
    savedData = [];
    loadingObs: any;
    simpleLoadingObs: any;

    // Row class rules to display
    rowClassRules: RowClassRules = {
        'bg-primary-50': function (params) {
            return (
                this.selectedData.findIndex(
                    (object) => object[this.getAttribute()] === params.data[this.getAttribute()]
                ) !== -1
            );
        }.bind(this),
        'text-secondary': function (params) {
            return (
                this.selectedData.findIndex(
                    (object) => object[this.getAttribute()] === params.data[this.getAttribute()]
                ) !== -1
            );
        }.bind(this),
    };

    gridNameMapping = {
        MedicalDiagnosis: SEARCHABLE_LIST_NAMES.MEDICAL_DIAGNOSIS,
        treatmentDiagnosis: SEARCHABLE_LIST_NAMES.TREATMENT_DIAGNOSIS,
        cptCodes: SEARCHABLE_LIST_NAMES.CPT_CODES,
        comorbidity: SEARCHABLE_LIST_NAMES.COMORBIDITY,
    };
    note: string;

    constructor(
        private clinicalCategoryService: ClinicalCategoriesService,
        private slpComorbidityService: SlpComorbidityService,
        private gridActionService: GridActionsService,
        private store: Store<{ therapyAdmission: TherapyAdmission[]; spinnerDocumentState: loadingSpinnerState }>,
        private route: ActivatedRoute,
        private modalService: ModalService,
        private storeService: StoreService,
        private ngZone: NgZone,
    ) {
        this.gridActionService.action.subscribe((params) => {
            if (
                params.actionType === 'delete' &&
                this.gridNameMapping[params.gridName] === this.name &&
                (params.gridName === 'MedicalDiagnosis' ||
                    params.gridName === 'treatmentDiagnosis' ||
                    params.gridName === 'cptCodes' ||
                    params.gridName === 'comorbidity')
            ) {
                this.deleteSelectedRowItem(params);
            }
        });

        this.route.queryParamMap.subscribe({
            next: (params) => {
                this.therapyDiscipline = params.get('discipline');
                this.note = params.get('note');
            },
        });

        this.therapyAdmissions$ = this.store.select('therapyAdmission');

        this.therapyAdmissions$.pipe(takeUntil(this.onDestroy)).subscribe(() => {
            if (this.isReadOnly) {
                this.setReadOnlyData();
            }
        });
        this.macId = JSON.parse(localStorage.getItem('current-facility')).mailingAddress.macId;
        this.storeService.getTherapyAdmissionState().subscribe((obs) => {
            this.loadingObs = obs.isLoading;
            if (this.gridApi) {
                this.loadingObs ? this.gridApi.showLoadingOverlay() : this.gridApi.hideOverlay();
            }
        });
    }

    getAttribute = () => {
        const attribute = this.name === SEARCHABLE_LIST_NAMES.CPT_CODES ? 'cptCode' : 'code';
        return attribute;
    };

    ngOnChanges(): void {
        // Setting vales
        if (this.recordsInput?.length > 0) {
            this.selectedData = this.recordsInput;

            if (this.name === SEARCHABLE_LIST_NAMES.MEDICAL_DIAGNOSIS) {
                this.markSelectedPrimaryDx(this.recordsInput);
            }
            this.data = this.recordsInput;
            this.state = 'one';
        }
        if (this.isReadOnly) {
            this.setReadOnlyData();
        }
    }

    markSelectedPrimaryDx(list) {
        if (list?.length > 0) {
            this.selectedData = list.map((mD) => {
                if (mD.isPrimary) {
                    return {
                        ...mD,
                        isSelected: true,
                    };
                }
                return {
                    ...mD,
                    isSelected: false,
                };
            });
            this.primaryDXMarked = list.some((x) => x.isPrimary);
            this.isPrimaryMarked.emit(this.primaryDXMarked);
            const found = this.cols[SEARCHABLE_LIST_NAMES.MEDICAL_DIAGNOSIS].recordCols.find(
                (x) => x.field === 'isPrimary'
            );
            if (found) {
                if (this.primaryDXMarked) {
                    found.headerClass = '';
                    found.headerName = DOCUMENTATION_CONSTANTS.PRIMARY_DX;
                } else {
                    found.headerClass = 'primary-dx-required';
                    found.headerName = DOCUMENTATION_CONSTANTS.PRIMARY_DX_REQUIRED;
                }
            }
            this.cols[SEARCHABLE_LIST_NAMES.MEDICAL_DIAGNOSIS].recordCols = [
                ...this.cols[SEARCHABLE_LIST_NAMES.MEDICAL_DIAGNOSIS].recordCols,
            ];
        }
    }

    setState(value: 'zero' | 'one') {
        this.allRecords = [];
        this.state = value;
        this.isDone = false;
        this.data = this.recordsInput;
    }

    fetchMoreRecords(): void {
        this.recordsSearch(this.searchedText);
    }

    searched(text: string): void {
        this.resetEverything();
        this.recordsSearch(text);
    }

    recordsSearch(text: string) {
        this.loading = true;
        this[this.name + 'Search'](text).subscribe({
            next: ({ data, total }: any) => {
                this.paginate.page++;
                this.paginate.total = total;
                this.searchedText = text;
                if (this.isDone === false) {
                    this.data = [...this.data, ...data];
                    if (this.name === SEARCHABLE_LIST_NAMES.TREATMENT_DIAGNOSIS || this.name === SEARCHABLE_LIST_NAMES.MEDICAL_DIAGNOSIS) {
                        this.data = _.uniqBy(this.data, 'code');
                    }
                    // Remove duplicates based on cptCode property
                    if (this.name === 'cptCodes') this.data = _.uniqBy(this.data, 'cptCode');
                }
                // this.recordsInput.map((item) => {
                //     if (item.code === node.data.code) {
                //         node.setSelected(true);
                //     }
                // });
                this.scrollToSeeMoreBtn();
                this.loading = false;
            },
            error: (err) => {
                console.log('ERR:: ', err);
                this.loading = false;
                this.slpComorbidityService.isLoading = false;
            },
        });
    }

    cptCodesSearch(text: string) {
        return this.clinicalCategoryService.cptCodesSearch(text, this.therapyDiscipline, this.paginate.page).pipe(
            map(({ data, total }: { data: any; total: number }) => {
                if (data && data.length) {
                    data = data.map((item: any) => ({
                        id: item.id,
                        cptCode: item.cptCode,
                        description: item.description,
                    }));
                } else if (!data && !total) {
                    data = [];
                    total = 0;
                }

                return {
                    data,
                    total,
                };
            })
        );
    }

    treatmentDiagnosisSearch(text) {
        if (typeof this.macId === 'object' && this.macId !== null && this.macId._id) {
            this.macId = this.macId._id;
        }
        return this.clinicalCategoryService
            .categorySearch(text, this.therapyDiscipline || this.discipline, this.macId, this.paginate.page)
            .pipe(
                map(({ data, total }: { data: any; total: number }) => {
                    if (data && data.length) {
                        data = data.map((item: any) => ({ code: item.code, description: item.description}));
                    } else if (!data && !total) {
                        data = [];
                        total = 0;
                    }

                    return {
                        data,
                        total,
                    };
                })
            );
    }
    comorbiditySearch(text) {
        return this.clinicalCategoryService.categorySearch(text, '', '', this.paginate.page, true).pipe(
            map(({ data, total }: { data: any; total: number }) => {
                if (data && data.length) {
                    data = data.map((item: any) => ({
                        _id: item._id,
                        code: item.code,
                        description: item.description,
                        clinicalCategory: item.clinicalCategory,
                        id: item._id,
                        comorbidity: item.comorbidity,
                    }));
                } else if (!data && !total) {
                    data = [];
                    total = 0;
                }

                return {
                    data,
                    total,
                };
            })
        );
    }

    medicalDiagnosisSearch(text: string) {
        let allSLPs: SLPComorbidity[] = [];

        return this.slpComorbidityService.getAllSLPs().pipe(
            take(1),
            switchMap((slps) => {
                allSLPs = slps;
                return this.clinicalCategoryService.categorySearch(text, '', '', this.paginate.page);
            }),
            switchMap(({ data: category, total }) => {
                let modCats = [];
                if (category && category.length) {
                    const cat = [...category];
                    modCats = cat.map((item) => {
                        const tempComorbidity = allSLPs.filter((slp) => {
                            return slp.code == item.code;
                        });
                        if (tempComorbidity?.length > 0) {
                            item.comorbidity = tempComorbidity[0].comorbidity;
                        } else {
                            item.comorbidity = 'N/A';
                        }
                        item.icd10 = item._id;
                        delete item._id;
                        return item;
                    });
                } else if (!category && !total) {
                    total = 0;
                }

                return of({ data: modCats, total });
            })
        );
    }

    // For Ag Grid
    // Working with clinical categories
    addSelectedItem(event: any) {
        const data = cloneDeep(event.data);
        const index = this.selectedData.findIndex(
            (object) => object[this.getAttribute()] === data[this.getAttribute()]
        );

        if (index === -1) {
            data.onsetDate = '';
            data.date = '';
            data.isPrimary = false;
            this.selectedData = [...this.selectedData, data];
        } else if (index >= 0) {
            this.selectedData = this.selectedData.filter(
                (item) => item[this.getAttribute()] !== data[this.getAttribute()]
            );
        }
        this.recordsEvent.emit(this.selectedData);
    }

    onRowSelected(event) {
        if (!event.node.isSelected()) {
            this.allRecords = this.allRecords.filter((item) => item.code != event.node.data.code);
        }
    }

    rowSelection(event: Array<any>) {
        this.updateSelection(event);
        event.map((data) => {
            data.onsetDate = data.onsetDate ? data.onsetDate : '';
            data.date = data.date ? data.date : '';
            data.isPrimary = data.isPrimary === true ? true : false;
        });

        if (isEqual(this.savedData, event)) {
            this.selectedData = cloneDeep(this.savedData);
        } else {
            this.selectedData =
                this.savedData.length > 0 && this.allRecords.length <= 0
                    ? [...event, ...this.savedData]
                    : [...event, ...this.selectedData];
            //this.selectedData = [...new Set(this.selectedData)];
            this.removeDuplicates('selectedData');
            const unSelectedData = new Set(this.data.filter((item) => !item.checked).map((item) => item.code));
            this.selectedData = this.selectedData.filter((elem) => !unSelectedData.has(elem.code));
        }
        this.allRecords = [...this.allRecords, ...this.selectedData];
        this.removeDuplicates('allRecords');
        this.allRecords = this.allRecords.filter((item) => item.checked);
        // if (isEqual(this.savedData, event)) {
        //     this.selectedData = cloneDeep(this.savedData);
        // } else {
        //     // Combine the arrays
        //     const combinedData = this.savedData.length
        //         ? [...event, ...this.savedData]
        //         : [...event, ...this.selectedData];

        //     // Create a map to ensure uniqueness based on 'code'
        //     const uniqueDataMap = new Map();
        //     combinedData.forEach((item) => {
        //         uniqueDataMap.set(item.code, item);
        //     });

        //     // Convert map back to an array
        //     this.selectedData = Array.from(uniqueDataMap.values());

        //     // Filter the array to keep only the checked items
        //     this.selectedData = this.selectedData.filter((elem) => elem.checked);
        // }

        this.recordsEvent.emit(this.selectedData);
    }

    updateSelection(selectedRows) {
        this.data.map((f) => (f.checked = false));
        selectedRows.map((row) => {
            this.data.map((fRow) => {
                if (row.code === fRow.code) {
                    fRow.checked = true;
                }
            });
        });
    }

    onRowDataChanged(event) {
        if (this.gridApi) {
            this.loadingObs ? this.gridApi.showLoadingOverlay() : this.gridApi.hideOverlay();
        }
        // this.data = this.recordsInput.map((item) => ({ ...item, checked: true }));
        if (this.normalSelectedRows.length > 0) {
            this.normalSelectedRows.map((row) => {
                event.api.forEachNode((node) => {
                    if (node.data.checked || row.code === node.data.code) {
                        node.setSelected(true);
                    }
                });
            });
        } else {
            event.api.forEachNode((node) => {
                node.setSelected(false);
            });
            event.api.forEachNode((node) => {
                if (this.allRecords.length > 0 && this.savedData.length > 0) {
                    if (this.allRecords.some((data) => node.data.code === data.code && data.checked)) {
                        node.data.checked = true;
                        node.setSelected(true);
                    }
                } else if (node.data.checked) {
                    node.setSelected(true);
                }
            });
        }
    }

    removeSelectedItem(data: any) {
        if (this.name === SEARCHABLE_LIST_NAMES.CPT_CODES) {
            this.selectedData = [...this.selectedData.filter((elem) => elem.cptCode !== data?.cptCode)];
        } else if (data === null) {
            this.selectedData = [];
            this.allRecords = [];
        } else {
            this.selectedData = this.selectedData.filter((item) => item.code !== data?.code);
        }
        if (data != null) {
            this.allRecords = this.allRecords.filter((item) => item.code !== data?.code);
        }

        this.updateSelection(this.selectedData);
        this.gridApi.setRowData(this.data);
        this.recordsEvent.emit(this.selectedData);
    }

    normalModeSelection(event): void {
        this.normalSelectedRows = event;
    }

    deleteSelectedData() {
        if(this.heading === this.patientDemographic.MEDICAL_DIAGNOSIS || this.heading === this.patientDemographic.TREATMENT_DIAGNOSIS) {
        const dialogRef = this.modalService.open(ConfirmationModalComponent, {
            data: {
                heading: `Delete ${this.heading}`,
                message: `Do you want to delete the selected ${this.heading}? This action can not be undone.`,
                iconName: 'warning',
                primaryButtonText: 'Yes',
                primaryButtonClass: 'btn-danger',
                secondaryButtonText: 'Cancel',
            },
          });
          dialogRef.afterClosed().subscribe((isConfirmed) => {
            if (isConfirmed) {
                this.data = this.data.filter((item) => !this.normalSelectedRows.some((row) => row.code === item.code));
                this.savedData = [...this.data];
                this.allRecords = [...this.data];
                const params = {
                    actionType: 'delete',
                    data: this.normalSelectedRows,
                    gridName: 'MedicalDiagnosis',
                    rowIndex: 0,
                };
                this.deleteSelectedRowItem(params);
                this.normalSelectedRows.length = 0;
            }
          });
        }
        else {
            const dialogRef = this.modalService.open(DeletionConfirmationDialogComponent, {
            data: { heading: 'Delete Confirmation', message: 'Are you sure you want to delete these items?' },
        });
        dialogRef.afterClosed().subscribe((isDeleted) => {
            if (isDeleted) {
                const params = {
                    actionType: 'delete',
                    data: this.normalSelectedRows,
                    gridName: 'MedicalDiagnosis',
                    rowIndex: 0,
                };
                this.deleteSelectedRowItem(params);
                this.normalSelectedRows.length = 0;
            }
        });
        }
        
    }

    setLoading(value: boolean) {
        this.loading = value;
    }

    toggleIsDone(): void {
        this.resetEverything();
        this.isDone = !this.isDone;
        if (this.isDone === true) {
            this.selectedData.map((selectedRow) => {
                this.recordsInput.map((row) => {
                    if ((selectedRow.code === row.code) && row.onsetDate) {
                            selectedRow.onsetDate = row.onsetDate; 
                    }
                })
                this.allRecords.map((row) => {
                    if ((selectedRow.code === row.code) && row.onsetDate) {
                            selectedRow.onsetDate = row.onsetDate; 
                    }
                })
            });
            this.gridApi.setColumnDefs(this.cols[this.name].recordCols);
        } else {
            if (this.selectedData.length > 0 && this.recordsInput.length <= 0) {
                this.data = this.selectedData.map((item) => ({ ...item, checked: true }));
            } else {
                this.data = this.recordsInput.map((item) => ({ ...item, checked: true }));
            }
            this.savedData = [...this.data];
            this.allRecords = [...this.data];
            this.gridApi.setColumnDefs(this.cols[this.name].editCols);
        }
        this.gridApi.sizeColumnsToFit();
        this.selectedData.map((selectedRow) => {
            this.recordsInput = this.recordsInput.map((row) => {
                if ((selectedRow.code === row.code) && !row.onsetDate && selectedRow.onsetDate) {
                    return {
                        ...row, 
                        onsetDate: selectedRow.onsetDate 
                    };
                }
                return row; 
            });
            this.data.map((row) => {
                if ((selectedRow.code === row.code) && !row.onsetDate && selectedRow.onsetDate) {
                    row.onsetDate = selectedRow.onsetDate;
                }
            })
        });
        this.redrawRows();
        this.recordsEvent.emit(this.selectedData);
        if (this.isDone === true) {
            // if user click save button without selection
            if (this.selectedData.length <= 0) {
                this.state = 'zero';
            } else {
                this.selectedData.map((d) => (d.checked = false));
                this.allRecords.map((d) => (d.checked = false));
                this.recordsSave.emit(this.selectedData);
                this.data = cloneDeep(this.selectedData);
                // this.selectedData.length = 0;
            }
        }
    }

    cancelAddition(): void {
        this.selectedData.length = 0;
        if (this.savedData.length > 0) {
            this.selectedData = cloneDeep(this.savedData);
            //this.allRecords = cloneDeep(this.savedData);
        }
        if (this.selectedData.length > 0) {
            this.state = 'one';
        } else {
            this.state = 'zero';
        }
        this.resetEverything();
        this.markSelectedPrimaryDx(this.selectedData);
        this.isDone = true;
        this.gridApi.setColumnDefs(this.cols[this.name].recordCols);
        this.gridApi.sizeColumnsToFit();
        this.recordsEvent.emit(this.selectedData);
        this.selectedData.map((d) => (d.checked = false));
        this.savedData.length = 0;
    }

    // Grid API functions
    initGrid(event: any) {
        this.gridApi = event.api;
    }

    redrawRows() {
        this.gridApi.redrawRows();
    }

    // AG Grid actions for renderers
    setRendererRadio(param: any) {
        this.selectedData = this.selectedData.map((item) => {
            const deepItem = cloneDeep(item);

            const attribute = this.name === SEARCHABLE_LIST_NAMES.CPT_CODES ? 'cptCode' : 'code';

            if (deepItem[attribute] === param.data[attribute]) {
                deepItem.isPrimary = true;
            } else {
                deepItem.isPrimary = false;
            }
            return deepItem;
        });

        if (this.name === SEARCHABLE_LIST_NAMES.MEDICAL_DIAGNOSIS) {
            this.markSelectedPrimaryDx(this.selectedData);
        }

        this.recordsEvent.emit(this.selectedData);
        this.recordsSave.emit(this.selectedData);
    }

    setRendererDate(param: any) {
        this.selectedData = this.selectedData.map((item) => {
            const deepItem = cloneDeep(item);

            const attribute = this.name === SEARCHABLE_LIST_NAMES.CPT_CODES ? 'cptCode' : 'code';
            if (deepItem[attribute] === param.data[attribute]) {
                deepItem.onsetDate = param.value;
            }
            return deepItem;
        });
        this.recordsEvent.emit(this.selectedData);
        this.recordsSave.emit(this.selectedData);
    }

    deleteSelectedRowItem(params: GridRowAction) {
        const attribute = this.name === SEARCHABLE_LIST_NAMES.CPT_CODES ? 'cptCode' : 'code';
        this.selectedData = this.selectedData.filter(
            (item1) => !params.data.some((item2) => item1[attribute] === item2[attribute])
        );
        this.recordsEvent.emit(_.cloneDeep(this.selectedData));
        this.recordsSave.emit(this.selectedData);
        if (this.name === SEARCHABLE_LIST_NAMES.MEDICAL_DIAGNOSIS) {
            this.markSelectedPrimaryDx(this.selectedData);
        }
        // if user click save button without selection
        if (this.selectedData.length <= 0) {
            this.state = 'zero';
        }
    }

    setReadOnlyData() {
        if (this.recordsData?.length > 0) {
            this.isDone = true;
            this.state = 'one';
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        // destroy gridApi element so it wont leak memory
        this.gridApi = undefined;
    }

    dateFormatter(params) {
        const datePipe: DatePipe = new DatePipe('en-US');
        return params.value ? datePipe.transform(new Date(params.value), 'MM/dd/yyyy') : null;
    }

    scrollToBar(): void {
        setTimeout(() => {
            this.searchBar?.nativeElement?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
            this.focusEvent.next(true);
        }, 10);
    }

    scrollToSeeMoreBtn(): void {
        setTimeout(() => {
            if (this.seeMoreBtn?.nativeElement) {
                this.seeMoreBtn.nativeElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'end',
                });
            }
        }, 100);
    }

    resetEverything(): void {
        this.paginate.page = 1;
        this.paginate.total = 0;
        this.searchedText = '';
        this.data = [];
    }
    removeDuplicates(arrayName) {
        // Create a Set to track unique codes
        const seenCodes = new Set<string>();

        // Filter the array based on unique codes
        this[arrayName] = this[arrayName].filter((item) => {
            if (!seenCodes.has(item.code)) {
                seenCodes.add(item.code);
                return true;
            }
            return false;
        });
    }
}
