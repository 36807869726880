import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '@app/services';

@Injectable({ providedIn: 'root' })
export class AuthenticationGuard implements CanActivate {
    constructor(private router: Router, private AuthService: AuthService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const account = this.AuthService.accountValue;
        if (account) {
            // check if route is restricted by role
            // if (route.data.roles && !route.data.roles.includes(account.user.role)) {
            //   // role not authorized so redirect to home page
            //   this.router.navigate(['/']);
            //   return false;
            // }

            // authorized so return true
            return true;
        }
        //colworx-ak: add conditon for redirect on physician login page behlaf of return url
        if(state.url.includes('physician')){
            // not logged in and if user want to open any physician url so redirect to physician login page with the return url
            this.router.navigate(['/account/physician/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }else{
            // not logged in so redirect to login page with the return url
            this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }
       
    }
}
