/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalService, ToasterService } from '@app/services';
import { AppointmentDetailsModalComponent } from '../modals/appointment-details-modal/appointment-details-modal.component';
import { getFullDayName } from '@app/helpers/utils';
import { ConfirmationModalComponent } from '../modals/v2/confirmation-modal/confirmation-modal.component';
import { appointment } from '../../interfaces';

@Component({
    selector: 'app-add-appointment-notes',
    templateUrl: './add-appointment-notes.component.html',
    styleUrls: ['./add-appointment-notes.component.scss'],
})
export class AddAppointmentNotesComponent implements OnInit {
    @Input() widthSmall = 'w-25';
    @Input() widthLarge = 'w-75';
    @Input() day = '';
    @Input() appointment: appointment;
    @Input() disabled = false;
    @Output() deleteDay: EventEmitter<any> = new EventEmitter();
    @Output() saveNote: EventEmitter<any> = new EventEmitter();
    editIcon = false;

    constructor(private modalService: ModalService, private toasterService: ToasterService) {}

    ngOnInit(): void {
        this.editIcon = !!this.appointment.notes.length;
    }

    /**
     * Emitting appointment to planner to save it for particular day
     */
    saveDayNote(note?): void {
        this.saveNote.emit({ day: this.day, note: note ? note : this.appointment });
        this.editIcon = true;
    }

    deleteDayNote(): void {
        const dayOfWeek = getFullDayName(this.day);
        this.modalService
            .open(ConfirmationModalComponent, {
                data: {
                    heading: 'Update Timecards',
                    message: `Are you sure you want to delete ${dayOfWeek}'s appointment details?`,
                    iconName: 'warning',
                    primaryButtonText: 'Yes',
                    primaryButtonClass: 'btn-secondary',
                    secondaryButtonText: 'No',
                },
            })
            .afterClosed()
            .subscribe((deleted) => {
                if (deleted) {
                    this.deleteDay.emit(this.day);
                    this.toasterService.show({
                        title: 'Deletion',
                        body: 'Timecard deleted successfully',
                        type: 'success',
                    });
                }
            });
    }

    editDayNote(): void {
        const dayOfWeek = getFullDayName(this.day);
        this.modalService
            .open(AppointmentDetailsModalComponent, {
                data: {
                    title: `Appointment Details: ${dayOfWeek}`,
                    notes: this.appointment.notes,
                    plannedTx: this.appointment.plannedTx,
                    startTime: this.appointment.startTime,
                    endTime: this.appointment.endTime,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.saveDayNote(result);
                } else {
                    return;
                }
            });
    }
}
