import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ROUTER_UTILS } from '@app/helpers/utils/router.utils';
import { AddFacilityComponent } from './add-facility/add-facility.component';

import { FacilityLayoutComponent } from './facility-layout/facility-layout.component';
import { AddPayorsComponent } from './add-payors/add-payors.component';
import { AddRecipientPortalComponent } from './add-recipient-portal/add-recipient-portal.component';
import { AuthorizationGuard } from '@app/guards';
import { AuthPolicies } from '@app/helpers/utils';

const facilityHome = ROUTER_UTILS.config.admin.facility.home;
const addFacility = ROUTER_UTILS.config.admin.facility.addFacility;
const addPayors = ROUTER_UTILS.config.admin.facility.addPayors;
const details = ROUTER_UTILS.config.admin.facility.details;
const editFacility = ROUTER_UTILS.config.admin.facility.editFacility;
const addRecipient = ROUTER_UTILS.config.admin.facility.addRecipients;
const editRecipient = ROUTER_UTILS.config.admin.facility.addRecipients;

const detailsModule = () => import('./facility-details/facility-details.module').then((x) => x.FacilityDetailsModule);
const routes: Routes = [
    { path: ROUTER_UTILS.config.admin.facility.root, redirectTo: facilityHome },
    { path: facilityHome, component: FacilityLayoutComponent },
    {
        path: addFacility,
        component: AddFacilityComponent,
        canActivate: [AuthorizationGuard],
        data: { rights: [AuthPolicies.Facilities.Add] },
    },
    {
        path: addPayors,
        component: AddPayorsComponent,
        canActivate: [AuthorizationGuard],
        data: { rights: [AuthPolicies.Payors.Add] },
    },
    {
        path: addRecipient, component: AddRecipientPortalComponent, canActivate: [AuthorizationGuard],  data: { rights: [AuthPolicies.Recipient.Add] },
    },
    {
        path: editRecipient, component: AddRecipientPortalComponent,  canActivate: [AuthorizationGuard],    data: { rights: [AuthPolicies.Recipient.Edit] },
    },
    {
        path: editFacility,
        component: AddFacilityComponent,
        canActivate: [AuthorizationGuard],
        data: { rights: [AuthPolicies.Facilities.Edit] },
    },
    { path: details, loadChildren: detailsModule },
];
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class FacilityRoutingModule { }
