<div
    class="bg-white input-group form-group facility-search-container body-large {{ marginBottom }} {{ borderRadius }}"
    style="height: 40px; border: 1px solid #1c377526; border-radius: 8px !important"
    [ngClass]="{ focused: isInputFocused, disabled: isInputFocused }"
>
    <div class="input-group-prepend d-flex flex-column align-self-center" style="padding: 0 15px">
        <div>
            <span [class.disabled]="isInputFocused" class="body-large"
                ><img loading="lazy" src="./../../../assets/icons/search.svg"
            /></span>
        </div>
    </div>

    <input
        type="search"
        spellcheck="true"
        autocomplete="off"
        name="searchFilter"
        [(ngModel)]="searchTerm"
        (ngModelChange)="debounce($event)"
        (keydown)="preventChar($event)"
        class="borderless facility-search-container input-border-radius"
        [placeholder]="placeHolder"
        aria-label="Search Directory"
        aria-describedby="basic-addon2"
        (focus)="onInputFocus()"
        (blur)="onInputBlur()"
    />
</div>
