import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Contraindications, MedicalDiagnosis, Precautions, TreatmentDiagnosis } from '@app/interfaces';
import { PATIENT_DEMOGRAPHICS, SEARCHABLE_LIST_NAMES } from '@app/interfaces/documentation';
import { Button } from '@app/shared/toggle/toggle.component';

@Component({
    selector: 'app-demographics',
    templateUrl: './demographics.component.html',
    styleUrls: ['./demographics.component.scss'],
})
export class DemographicsComponent implements OnInit {
    @Input() isDischargeNote = false;
    @Input() precautions: Precautions[];
    @Input() contraindications: Contraindications[];
    @Input() medicalDiagnosis: MedicalDiagnosis[];
    @Input() treatmentDiagnosis: TreatmentDiagnosis[];
    @Input() maxOnSetDate: string;
    @Input() isEditMode: boolean;
    @Output() update = new EventEmitter();
    currentUrl= "";
    tabs: Button[];
    selectedTab: Button;
    SEARCHABLE_LIST_NAMES = SEARCHABLE_LIST_NAMES;
    PATIENT_DEMOGRAPHICS = PATIENT_DEMOGRAPHICS;

    @Input() editableDemographics = [
        PATIENT_DEMOGRAPHICS.MEDICAL_DIAGNOSIS,
        PATIENT_DEMOGRAPHICS.TREATMENT_DIAGNOSIS,
        PATIENT_DEMOGRAPHICS.PRECAUTIONS,
        PATIENT_DEMOGRAPHICS.CONTRAINDICATIONS,
    ];
    constructor(private router: Router) {}
    ngOnInit() {
        this.currentUrl = this.router.url;
        if (this.isDischargeNote === true) {
            this.tabs = [
                { text: 'Medical Diagnosis', isSelected: true },
                { text: 'Treatment Diagnosis', isSelected: false },
            ];
        } else {
            this.tabs = [
                { text: 'Medical Diagnosis', isSelected: true },
                { text: 'Treatment Diagnosis', isSelected: false },
                { text: 'Precautions', isSelected: false },
                { text: 'Contraindications', isSelected: false },
            ];
        }
        this.selectedTab = this.tabs[0];
    }
    setSelectedTab(value: string) {
        this.selectedTab = this.tabs.filter((tab) => tab.text === value)[0];
    }

    updateDemographics(event, section: string) {
        this.update.emit({ data: event, section });
    }
}
