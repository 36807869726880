<app-base-modal-card [heading]="heading" (close)="onClose()" wrapperClass="no-body-padding">
    <div body class="body-container">
        <div style="height: 310px">
            <app-data-grid
                height="100%"
                [gridOptions]="gridOptions"
                [columnDefs]="cptCols"
                [rowData]="cptData"
                [getRowStyle]="getRowStyle"
                rowSelected="multiple"
                (firstDataRendered)="onFirstDataRendered()"
                [rowMultiSelectWithClick]="true"
                (rowClicked)="onRowClicked($event)"
                id="add-cpt-modal-grid"
            ></app-data-grid>
        </div>
        <!-- Search end -->
    </div>
    <div
        footer
        class="w-100 d-flex flex-row align-items-center justify-content-end"
        [ngClass]="{ disabled: selectedData.length === 0 || disableSaveButton }"
        (click)="!disableSaveButton && selectedData.length ? saveClicked() : null"
    >
        <button class="btn btn-secondary rounded-pill d-flex flex-row align-items-enter">Save</button>
    </div>
</app-base-modal-card>
