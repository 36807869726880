import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Notification } from '@app/interfaces';

import { BehaviorSubject, Observable } from 'rxjs';
const baseUrl = environment.apiUrl + '/notification';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    public dataChanged: BehaviorSubject<string> = new BehaviorSubject(null);
    public pccTabChanged: BehaviorSubject<string> = new BehaviorSubject(null);

    constructor(private http: HttpClient) {}
    getNotifications(page: number, status = 'All', selectFacility = ''): Observable<any[]> {
        let url = `${baseUrl}/list?page=${page}`;

        if (status != 'All') {
            if (status == 'Archive') {
                url += `&isArchive=true`;
            } else if (status == 'UnArchived') {
                url += `&isArchive=false`;
            } else {
                url += `&isRead=${status == 'Unread' ? false : true}`;
            }
        }
        if (selectFacility != '') {
            url += `&facility=${selectFacility}`;
        }
        return this.http.get<any>(url);
    }
    createNotification(data: Notification): Observable<Notification> {
        return this.http.post<any>(`${baseUrl}`, data).pipe(map((v) => v.data));
    }
    updateNotification(id: string, data: Notification): Observable<Notification> {
        return this.http.put<any>(`${baseUrl}/${id}`, data).pipe(map((v) => v.data));
    }
    markAllAsRead(ids: any): Observable<any> {
        return this.http.post<any>(`${baseUrl}/mark-read`, { ids });
    }
    getA03IntegrationNotification(patient: string): Observable<any> {
        return this.http.get<any>(`${baseUrl}/get-a03-notification/${patient}`).pipe(map((v) => v.data));
    }
}
