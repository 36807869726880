import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FooterActionItem, HeaderActionItem, CardUIAction, InputBottomActionItems } from '@app/interfaces/ui';
import { isUndefined } from 'lodash';

@Component({
    selector: 'app-dynamic-content-card',
    templateUrl: './dynamic-content-card.component.html',
    styleUrls: ['./dynamic-content-card.component.scss'],
})
export class DynamicContentCardComponent implements OnChanges {
    @Output() emitViewChangeEvent: EventEmitter<string> = new EventEmitter<string>();
    @Output() defaultAction: EventEmitter<string> = new EventEmitter();
    @Input() view: string;
    @Input() noDataView: string;
    @Input() hasData: boolean;
    @Input() heading: string;
    @Input() actions: Map<string, CardUIAction>;
    @Input() headerActionItems: HeaderActionItem[] = [];
    @Input() inputBottomActionItems: InputBottomActionItems[] = [];
    @Input() footerActionItems: FooterActionItem[] = [];
    @Input() noDataTargetView: string;
    @Input() headerInfo: string;
    @Input() isEditMode: boolean;

    ngOnChanges() {
        if (isUndefined(this.headerActionItems)) this.headerActionItems = [];
        if (isUndefined(this.footerActionItems)) this.footerActionItems = [];
    }

    viewChangeEvent(view: string) {
        this.view = view;
        this.emitViewChangeEvent.emit(this.view);
    }
    invokeActionFor(item: HeaderActionItem) {
        const ACTION_SAVE = 'ACTION_SAVE';
        if (item.action === ACTION_SAVE) {
            this.actions.get(ACTION_SAVE)(item.payload);
            return;
        }
        if (item.autoView) {
            this.viewChangeEvent(item.payload.targetView);
            this.actions.get(item.action)(item.payload);
        } else if (item.payload) {
            item.payload.viewChangeEvent = this.viewChangeEvent.bind(this);
            this.actions.get(item.action)(item.payload);
        } else {
            this.onDefaultAction();
        }
    }
    onDefaultAction() {
        this.defaultAction.emit(this.heading);
        this.actions?.get('ACTION_DEFAULT')({
            viewChangeEvent: this.viewChangeEvent.bind(this),
            views: ['NO_CONTENT'],
            targetView: this.noDataTargetView,
        });
    }
}
