<div class="steps-tab">
    <div *ngFor="let tab of tabs" class="step" (click)="setSelectedTab(tab)">
        <div
            class="title ml-2 tab-item mb-2"
            [class.text-primary]="tab.highlight === true"
            [class.text-secondary]="tab.highlight === false"
            [class.disabled]="tab.unauthorized"
            *ngVar="tab.titleCount?.toString()?.length as titleCountLength"
            [ngbTooltip]="tab.unauthorized ? 'You don’t have authorizations' : ''"
            tooltipClass="unauthorized-tooltip"
        >
            {{ tab.title | stringLength: 21 - titleCountLength:21 - titleCountLength }}
            <span [ngClass]="tab.titleCount && tab.titleCount > 0 ? '' : 'opacity-0'" class="numberCircle">
                <span class="numberCircle-inner text-secondary">{{ tab.titleCount }}</span></span
            >
        </div>
        <div class="path">
            <div *ngIf="tab.underline" class="current d-flex flex-row align-items-center">
                <div class="circle-end rounded-circle d-flex flex-row align-items-center justify-content-center">
                    <span *ngIf="tab.isDone" class="done-icon material-icons text-white"> done </span>
                </div>
            </div>
            <div *ngIf="!tab.underline" class="not-done d-flex flex-row align-items-center"></div>
        </div>
    </div>
</div>
