import { Component, EventEmitter, Input, Output, SimpleChanges, OnChanges } from '@angular/core';
import { GridApi } from 'ag-grid-community';

@Component({
    selector: 'app-view-mode-table',
    templateUrl: './view-mode-table.component.html',
    styleUrls: ['./view-mode-table.component.scss'],
})
export class ViewModeTableComponent implements OnChanges {
    gridApi: GridApi;
    @Input() tableTitle: string;
    @Input() selectedOption: string;
    @Input() options: any[];
    @Input() tableCount: string;
    @Input() tableCountTitle: string;
    @Input() tableTitleSecond: string;
    @Input() tableCol;
    @Input() tableData;
    @Input() configData: any;
    @Input() pagination: boolean;
    @Input() showCount: boolean;
    @Input() tableHeight: string;
    @Input() showMore = false;
    @Input() headerFull = false;
    @Input() visibleSectionGG = true;
    @Input() insideCard = true;
    @Output() showMoreEvent: EventEmitter<any> = new EventEmitter();
    @Output() optionChangeEvent: EventEmitter<any> = new EventEmitter();
    showGrid = false;
    public showMoreState = true;
    inputClass = 'border border-1';
    public showMoreClick() {
        this.showMoreEvent.emit(true);
    }
    public onOptionChange(event) {
        this.optionChangeEvent.emit(event);
    }
    public showLessClick() {
        this.showMoreEvent.emit(false);
    }
    initGrid(event: any) {
        this.gridApi = event.api;
    }

    ngOnChanges(simpleChanges: SimpleChanges): void {
        if (simpleChanges.tableData?.currentValue?.length > 0) {
            this.showGrid = true;
        } else this.showGrid = false;
    }
}
