import { FacilityAdmission, AssignFacilities } from '@app/models';
import { createAction, props } from '@ngrx/store';

export const facilityAdmissionChangedAction = createAction(
    '[facilityAdmission action] facilityAdmission changed',
    props<{ facilityAdmission: FacilityAdmission }>()
);
export const facilityAdmissionRemovedAction = createAction('[facilityAdmission action] facilityAdmission removed');
// colworx-ak start
export const assignFacilitiesChangedAction = createAction(
    '[assignFacilities action] assignFacilities changed',
    props<{ assignFacilities: AssignFacilities }>()
);
export const assignFacilitiesRemovedAction = createAction('[assignFacilities action] assignFacilities removed');

export const cachedFacilityAdmissionChangedAction = createAction(
    '[cached facilityAdmission action] cached facilityAdmission changed',
    props<{ facilityAdmission: FacilityAdmission }>()
);
export const cachedFacilityAdmissionRemovedAction = createAction(
    '[cached facilityAdmission action] cached facilityAdmission removed'
);

export const pastFacilityAdmissionsChangedAction = createAction(
    '[pastFacilityAdmissions action] facilityAdmissions changed',
    props<{ facilityAdmissions: FacilityAdmission[] }>()
);
export const pastFacilityAdmissionsRemovedAction = createAction(
    '[pastFacilityAdmissions action] facilityAdmissions removed'
);
