import { Component, Inject } from '@angular/core';
import { DIALOG_DATA } from '@app/helpers/dialog-tokens';
import { DialogRef } from '@app/services';

@Component({
  selector: 'app-paragraph-modal',
  templateUrl: './paragraph-modal.component.html',
  styleUrls: ['./paragraph-modal.component.scss']
})
export class ParagraphModalComponent {

    paragraphTitle: string;
    paragraph: string;
    innerHtml;

    constructor(@Inject(DIALOG_DATA) private data, private ref: DialogRef) {
        this.paragraphTitle = data.paragraphTitle;
        this.paragraph = data.paragraph;
    }

    onClose() {
        this.ref.close();
    }

}
