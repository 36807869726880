import { Pipe, PipeTransform } from '@angular/core';
import { isToday } from '@app/helpers/utils/date.utils';

@Pipe({
    name: 'isToday',
    pure: true,
})
export class isTodayPipe implements PipeTransform {
    transform(date: Date): boolean {
        return isToday(date);
    }
}
