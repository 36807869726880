<div class="d-flex flex-column col-12 rounded pl-0">
    <div class="d-flex flex-row col-12 justify-content-between pl-0 pr-0 pt-2">
        <span class="body-large-semibold text-primary"> {{ patient.name | titlecase | name }} </span>
        <ng-container *ngIf="!(date | isPastDate)">
            <span
                *ngIf="!patient.isTreated"
                class="material-icons-outlined"
                [ngStyle]="{ color: patient.isSelected ? '#69a981' : '#e8e8e7' }"
            >
                check_box
            </span>
        </ng-container>
    </div>
    <div class="d-flex flex-row col-12 justify-content-between pl-0 pr-0 pt-2">
        <span class="col-3 body-small-semibold text-Secondary pl-0">Plnd</span>
        <span class="col-5 body-small-semibold text-Secondary" style="white-space: nowrap">Docs Due</span>
        <span class="col-4 body-small-semibold text-Secondary"></span>
    </div>
    <div class="d-flex flex-row col-12 justify-content-between pl-0 pr-0">
        <span class="col-3 body-small text-Secondary pl-0">{{ plannedMinutes | minutesToHoursMinutes }}</span>
        <span class="col-5 body-small text-Secondary">Daily Note</span>
        <span *ngIf="patient.isTreated; else noStatus" class="col-4 body-small status-label text-white pr-0"
            ><span style="vertical-align: middle">Treated</span></span
        >
        <ng-template #noStatus><span class="col-4 pr-0"></span></ng-template>
    </div>
</div>
