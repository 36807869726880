import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import * as jDiff from 'jsondiffpatch';
import * as changeSet from 'diff-json';
import { escapeSpecialChars, formatDate } from '@app/helpers/utils';

@Component({
    selector: 'app-diff-cell-renderer',
    templateUrl: './diff-cell-renderer.component.html',
    styleUrls: ['./diff-cell-renderer.component.scss'],
})
export class DiffCellRendererComponent implements ICellRendererAngularComp {
    innerHtml;
    params;
    tooltip = false;
    currentTooltip = null;
    @ViewChild('tooltipJustification') tooltipJustificationElement: ElementRef;

    
    refresh(): boolean {
        return true;
    }
      // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private renderer: Renderer2) { }
    agInit(params: ICellRendererParams): void {
        
        this.params = params

        // Always consult before making any change in this function
        if (params.value?.previousValue || params.value?.currentValue) {
            let obj1;
            let obj2;
            if (params['key'] === 'nestedArray') {
                obj1 = params.value.previousValue?.join();
                obj2 = params.value.currentValue?.join();
            }  else if (params['key'] === 'valueFormatted') {
                obj1 = escapeSpecialChars(params.valueFormatted?.previousValue);
                obj2 = escapeSpecialChars(params.valueFormatted?.currentValue);
            } else {
                obj1 = escapeSpecialChars(params.value.previousValue);
                obj2 = escapeSpecialChars(params.value.currentValue);
            }
            let delta = jDiff.create({ textDiff: { minLength: 10000 } }).diff(obj1, obj2);
            const innerHtml = jDiff.formatters.html.format(delta, obj1);
            jDiff.formatters.html.hideUnchanged();
            delta = changeSet.diff(obj1, obj2);
            if (innerHtml && params['key'] === 'date') {
                const extractedDate = innerHtml.split(`&quot;`)?.filter((x) => !isNaN(Date.parse(x)));
                let formattedInner = innerHtml;
                extractedDate?.forEach((x) => {
                    const fd = formatDate(x);
                    formattedInner = formattedInner.replace(x, fd.replace('"', ''));
                });
                this.innerHtml = formattedInner;
            } else if (innerHtml && params['key'] === 'primaryDX') {
                if (innerHtml.includes('true')) {
                    this.innerHtml = innerHtml.replace(
                        'true',
                        '<span class="material-icons-round text-success" style="vertical-align: middle" >check</span>'
                    );
                    this.innerHtml = this.innerHtml.replace('false', '');
                }
            } else if (innerHtml) this.innerHtml = innerHtml;
            else {
                if (params['key'] === 'date') {
                    this.innerHtml =
                        formatDate(params.value.currentValue) == 'NaN/NaN/NaN'
                            ? '------'
                            : formatDate(params.value.currentValue);
                } else if (params['key'] === 'primaryDX') {
                    this.innerHtml =
                        '<span class="material-icons-round text-success" style="vertical-align: middle" >check</span>';
                } else this.innerHtml = params.value.currentValue;
            }
        } else if (params['key'] === 'date') {
            const formattedDate = formatDate(params.value);
            this.innerHtml = formattedDate == 'NaN/NaN/NaN' ? '------' : formatDate(params.value);
        } else if (params['key'] === 'primaryDX') {
            if (typeof params.value === 'object' && !params.value.currentValue) '';
            else if (params.value)
                this.innerHtml =
                    '<span class="material-icons-round text-success" style="vertical-align: middle" >check</span>';
            else '';
        } else if (params.value && !Object.keys(params.value).includes('currentValue')) {
            if (params['key'] === 'valueFormatted') {
                this.innerHtml = params.valueFormatted;
            } else {
                this.innerHtml = params.value;
            }
        } else this.innerHtml = '';
        
    }
    onMouseEnter(event) {
        this.currentTooltip = this.tooltipJustificationElement;
        this.tooltip = true;
        this.currentTooltip.nativeElement.style.top = event.pageY + 10 + 'px';
        this.currentTooltip.nativeElement.style.left = event.pageX - 250 + 'px';
        this.renderer.insertBefore(document.body, this.currentTooltip.nativeElement, document.body.firstChild);
        this.currentTooltip.nativeElement.style.display = 'block';
    }
    
    onMouseLeave() {
      
        this.currentTooltip = this.tooltipJustificationElement;
        this.tooltip = false;
        this.currentTooltip.nativeElement.style.display = 'none';
        this.renderer.removeChild(document.body, this.currentTooltip.nativeElement);
    }
}
