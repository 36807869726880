import { Injectable, Injector } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ToastRef, ToastData } from '@app/helpers/toaster';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { ToasterComponent } from '@app/shared/toaster/toaster.component';
@Injectable({
    providedIn: 'root',
})
export class ToasterService {
    constructor(private overlay: Overlay, private parentInjector: Injector) {}

    show(data: ToastData) {
        const positionStrategy = this.getPositionStrategy(data.type);
        const overlayRef = this.overlay.create({ positionStrategy });

        const toastRef = new ToastRef(overlayRef);
        const injector = this.getInjector(data, toastRef, this.parentInjector);
        const toastPortal = new ComponentPortal(ToasterComponent, null, injector);

        overlayRef.attach(toastPortal);

        return toastRef;
    }

    private getPositionStrategy(type: string) {
        if (type == 'notification') {
            return this.overlay.position().global().bottom('20px').right('40px');
        } else {
            return this.overlay.position().global().bottom('20px').centerHorizontally();
        }
    }

    private getInjector(data: ToastData, toastRef: ToastRef, parentInjector: Injector) {
        const tokens = new WeakMap();

        tokens.set(ToastData, data);
        tokens.set(ToastRef, toastRef);

        return new PortalInjector(parentInjector, tokens);
    }
}
