<div>
    <div>
        <div class="custom-card">
            <div class="header">
                <p>Preferences*</p>
                <span *ngIf="!isEditMode" class="cursor-pointer plus-icon" (click)="updateEditMode()">
                    <img loading="lazy" class="edit-icon" [src]="editIcon" />
                </span>
            </div>

            <table class="data-table">
                <tbody>
                    <tr>
                        <td>
                            Contract therapy billing & Invoicing
                        </td>
                        <td class="select-unit">
                            <app-checkbox
                                [(ngModel)]="billOption"
                                [options]="billingOptions"
                                class="preferences-checkbox"
                                (optionChecked)="onCheckboxChange()"
                            ></app-checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Clock in & out functionality
                        </td>
                        <td class="select-unit">
                            <app-checkbox
                                [(ngModel)]="clockOption"
                                [options]="clockOptions"
                                class="preferences-checkbox"
                                (optionChecked)="onCheckboxChange()"
                            ></app-checkbox>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="isEditMode" class="no-activities-parent">
            <div class="btns" *ngIf="!editingOrgId">
                <button
                    (click)="handleCancel()"
                    class="innova-btn button-skip btn-secondary ml-1 cancel-btn"
                >
                    {{ backTitle }}
                </button>
                <button
                    (click)="handleContinue()"
                    class="btn-secondary innova-btn innova-btn-lg save-btn"
                >
                    <span *ngIf="isDataSaving" class="spinner-border spinner-border-sm mr-1 text-primary"></span>
                    Continue
                </button>
            </div>

            <div class="btns" *ngIf="editingOrgId">
                <button
                    (click)="handleCancel()"
                    class="innova-btn button-skip btn-secondary ml-1 cancel-btn"
                >
                    Cancel
                </button>
                <button
                    (click)="handleSave()"
                    class="btn-secondary innova-btn innova-btn-lg save-btn"
                >
                    <span *ngIf="isDataSaving" class="spinner-border spinner-border-sm mr-1 text-primary"></span>
                    Save
                </button>
            </div>
        </div>
    </div>
</div>