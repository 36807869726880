import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-check-icon-cell-renderer',
    template: `<span
        *ngIf="params.value === true"
        class="material-icons-round text-success"
        style="vertical-align: middle"
        >check</span
    >`,
})
export class CheckIconCellRendererComponent implements ICellRendererAngularComp {
    params: ICellRendererParams;

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }
    refresh(): boolean {
        return false;
    }
}
