import { Component } from '@angular/core';
import { GridActionsService } from '@app/data-grid/services/grid-actions.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';

@Component({
    selector: 'app-dropdown-pill',
    templateUrl: './dropdown-pill-cell-renderer.component.html',
    styleUrls: ['./dropdown-pill-cell-renderer.component.scss'],
    providers: [{ provide: NgbDropdown }],
})
export class DropdownPillComponent implements ICellRendererAngularComp {
    public _params: any;
    public isLocked: boolean;
    public selectedOption: string;
    public isReady: boolean;
    public options: {
        name: string;
        value: string;
        disabled: boolean;
    }[] = [];

    constructor(private gridActionService: GridActionsService) {}

    agInit(params: ICellRendererParams): void {
        this._params = params;
        const { readonly } = params as any;
        this.isLocked = this._params?.value?.isLocked || false;
        // * if user doesn't have edit permission
        if(readonly !== undefined) {
            this.isLocked = readonly;
        }
        // generic way for passing payload (through params)
        if (this._params.options) {
            this.options = this._params.options;
        }
        if (!this.options?.length) {
            this.options = [
                {
                    name: 'Pending',
                    value: 'Pending',
                    disabled: false,
                },
                {
                    name: 'Ready for billing',
                    value: 'Ready',
                    disabled: false,
                },
            ];
        }
        if (!this.selectedOption) {
            this.selectedOption = this._params?.value?.status || '';
            this.isReady = this.selectedOption === 'Ready' || this.selectedOption === 'Invoiced';
        }
    }

    checkDisabled(): boolean {
        return !this.options?.find((o) => !o.disabled);
    }

    refresh(): boolean {
        return false;
    }

    selectOption(option: string): void {
        this.selectedOption = option;
        this.isReady = option === 'Ready' || option === 'Invoiced';
        const { data, rowIndex, gridName, colDef } = this._params;
        const headerName = colDef?.headerName;
        this.gridActionService.setSelectedAction(option, data, rowIndex, gridName ? gridName : '', headerName);
    }
}
