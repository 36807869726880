<div class="custom-card" [class.top-card]="state === 'one'">
    <div class="header d-flex flex-row align-items-center justify-content-between">
        <p class="text-secondary">{{ heading }}</p>
        <app-button
            (click)="toggleIsDone()"
            *ngIf="isDone === false && !isReadOnly"
            [circle]="true"
            size="sm"
            type="secondary"
            text=""
            icon="save"
        ></app-button>
        <app-button
            *ngIf="isDone === true && !isReadOnly && state == 'one'"
            (click)="toggleIsDone()"
            [circle]="true"
            [disabled]="disableAdd"
            size="sm"
            type="secondary"
            text=""
            icon="add"
        ></app-button>
    </div>
    <!-- <div class="mt-2"></div>
    <div class="bg-secondary-a20 mb-2" style="height: 1px"></div> -->

    <div class="body">
        <div class="bg-white" style="padding: 12px 12px 0" *ngIf="isDone === false">
            <app-search-bar
                type="multi"
                [placeholder]="searchPlaceholder"
                (searchEvent)="searchStart($event)"
                (removeEvent)="removeSelectedItem($event); isDone === false ? redrawRows() : null"
                [selectedData]="selectedData"
                [loading]="loading"
                [disabled]="isDone"
                [objectKey]="addedItemKey"
                [objectSubKey]="addedItemSubKey"
            ></app-search-bar>
        </div>
        <div *ngIf="state == 'zero'" class="d-flex flex-row justify-content-center align-items-center my-3">
            <div class="d-flex flex-column align-items-center justify-content-center body-small-semibold">
                <p class="text-secondary">{{ heading ? 'No ' + heading + ' yet' : 'No Added Items' }}</p>
                <app-button *ngIf="isReadOnly === false" (click)="setState('one'); toggleIsDone()" type="secondary"
                    [text]="'Add New'" icon="add"  [disabled]="disableAdd"></app-button>
            </div>
        </div>
        <!-- ag grid table -->
        <app-data-grid
            *ngIf="state == 'one'"
            style="display: block"
            [ngStyle]="{ height: !selectedData.length ? '230px' : 'auto' }"
            [domLayout]="selectedData.length ? 'autoHeight' : 'normal'"
            [context]="{ componentParent: this }"
            [columnDefs]="isDone === true ? columns : editColumns"
            [rowData]="isDone === true ? selectedData : data"
            [showCount]="false"
            (rowClicked)="addSelectedItem($event); isDone === false ? redrawRows() : null"
            [rowClassRules]="isDone === false ? rowClassRules : null"
            (gridReady)="initGrid($event)"
            [isSearchable]="true"
            [loading]="loading"
        ></app-data-grid>
    </div>
</div>
