import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SignedNoteService } from '@app/services';
import { ROUTER_UTILS } from '@app/helpers';
import { cloneDeep } from 'lodash';
import { Store } from '@ngrx/store';
import { TherapyAdmission } from '@app/interfaces';

@Injectable({ providedIn: 'root' })
export class EditNoteGuard implements CanActivate {
    pccURL = '/' + ROUTER_UTILS.config.patients.pcc.root + '/documentation/';
    constructor(
        private router: Router,
        private signedNoteService: SignedNoteService,
        private store: Store<{
            therapyAdmission: TherapyAdmission[];
        }>
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const noteType = this.getNoteTypes(route.routeConfig.path.split('-document')[0]);

        this.store.select('therapyAdmission').subscribe((data) => {
            const currentAdmission = cloneDeep(
                data.filter((admission) => admission.therapyDiscipline == route.queryParams.discipline)
            );
            if (currentAdmission.length) {
                if (noteType === 'evaluation') {
                    if (currentAdmission[0].documentation?.evaluation?.signed?.signedBy) {
                        this.router.navigate([this.pccURL], {
                            queryParams: { patient: route.queryParams.patient, tab: 'documentation' },
                        });
                    }
                } else if (noteType === 'dailyNote') {
                    const dailyNote = currentAdmission[0].documentation.dailyNote.find(
                        (d) => d.id === route.params.doc
                    );
                    if (dailyNote && dailyNote.signed.signedBy) {
                        this.router.navigate([this.pccURL], {
                            queryParams: { patient: route.queryParams.patient, tab: 'documentation' },
                        });
                    }
                } else if (noteType === 'progressNote') {
                    const progressNote = currentAdmission[0].documentation.progressNote.find(
                        (d) => d.id === route.params.doc
                    );
                    if (progressNote && progressNote.signed.signedBy) {
                        this.router.navigate([this.pccURL], {
                            queryParams: { patient: route.queryParams.patient, tab: 'documentation' },
                        });
                    }
                } else if (noteType === 'upoc') {
                    const upoc = currentAdmission[0].documentation.updatedPlanOfCareNote.find(
                        (d) => d.id === route.params.doc
                    );
                    if (upoc && upoc.signed.signedBy) {
                        this.router.navigate([this.pccURL], {
                            queryParams: { patient: route.queryParams.patient, tab: 'documentation' },
                        });
                    }
                } else if (noteType === 'recertification') {
                    const recertNote = currentAdmission[0].documentation.recertificationNote.find(
                        (d) => d.id === route.params.doc
                    );
                    if (recertNote && recertNote.signed.signedBy) {
                        this.router.navigate([this.pccURL], {
                            queryParams: { patient: route.queryParams.patient, tab: 'documentation' },
                        });
                    }
                } else if (noteType === 'dischargeNote') {
                    if (currentAdmission[0].documentation?.dischargeNote?.signed?.signedBy) {
                        this.router.navigate([this.pccURL], {
                            queryParams: { patient: route.queryParams.patient, tab: 'documentation' },
                        });
                    }
                }
            } else {
                this.signedNoteService.getSignedNote(route.queryParams, noteType).subscribe((result) => {
                    if (result.data) {
                        this.router.navigate([this.pccURL], {
                            queryParams: { patient: route.queryParams.patient, tab: 'documentation' },
                        });
                    }
                });
            }
        });
        return true;
    }
    getNoteTypes(noteType: any) {
        if (noteType === 'daily-note') {
            noteType = 'dailyNote';
        }
        if (noteType === 'progress-note') {
            noteType = 'progressNote';
        }
        if (noteType === 'upoc-note') {
            noteType = 'upoc';
        }
        if (noteType === 're-certification') {
            noteType = 'recertification';
        }
        if (noteType === 'discharge-note') {
            noteType = 'dischargeNote';
        }
        return noteType;
    }
}
