import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import {
    AuthenticationGuard,
    FacilityAdmissionGuard,
    PatientGuard,
    AuthorizationGuard,
    AdmissionFormGuard,
    AddStaffGuard,
    NavigationBlockerGuard,
    EomBillingGuard,
} from '@app/guards';
//import { Role } from '@app/helpers';
import { ROUTER_UTILS } from '@app/helpers';
import { ShowcaseComponent } from './core/showcase/showcase.component';
import { PrintLayoutModule } from './shared/print-layout/print-layout.module';
const dashboardModule = () => import('./modules/dashboard/dashboard.module').then((x) => x.DashboardModule);
const accountModule = () => import('./modules/account/account.module').then((x) => x.AccountModule);
const PPAccountsDocuments = () =>
    import('./modules/pp-accounts-documents/pp-accounts-documents.module').then((x) => x.PPAccountsDocuments);
const POCDocuments = () =>
    import('./modules/pp-accounts-documents/pp-documents/pp-documents.module').then((x) => x.PPDocumentsModule);
const adminModule = () => import('./modules/admin/admin.module').then((x) => x.AdminModule);
const patientsModule = () => import('./modules/patients/patients.module').then((x) => x.PatientsModule);
const PCCModule = () => import('./modules/patients/PCC/pcc.module').then((x) => x.PCCModule);
const addPatientModule = () => import('./modules/add-patient/add-patient.module').then((x) => x.AddPatientModule);
const schedulingModule = () => import('./modules/scheduling/scheduling.module').then((x) => x.SchedulingModule);
const notificationModule = () => import('./modules/notification/notification.module').then((x) => x.NotificationModule);
import { AuthPolicies } from '@app/helpers/utils';
import { GroupedPolicies } from '@app/helpers/utils';
import { InvoicingProfileRoutes } from './modules/billing/invoicing/invoicing-routes';
import { RecipientRoutes } from './modules/billing/recipients/recipient-routes';
import { EOMRoutes } from './modules/billing/eom-billing/eom-routes';
import { OrganizationModule } from './modules/admin/organization/organization.module';
import { FacilityModule } from './modules/admin/facility/facility.module';
import { ViewUserComponent } from './modules/admin/user-management/view-user/view-user.component';

const therapyAdmissionModule = () =>
    import('./modules/therapy-admission/therapy-admission.module').then((x) => x.TherapyAdmissionModule);
const timeTrackingModule = () =>
    import('./modules/time-tracking/time-tracking.module').then((x) => x.TimeTrackingModule);
const showTherapyAdmissionModule = () =>
    import('./modules/therapy-admission/show-therapy-admission/show-therapy-admission.module').then(
        (x) => x.ShowTherapyAdmissionModule
    );
const therapyAdmissionMainModule = () =>
    import('./modules/therapy-admission/therapy-admission-main/therapy-admission-main.module').then(
        (x) => x.TherapyAdmissionMainModule
    );
const payorInformationModule = () =>
    import('./modules/payor-information/payor-information.module').then((x) => x.PayorInformationModule);

const reportsModule = () => import('./modules/reports/reports.module').then((x) => x.ReportsModule);
const billingModule = () => import('./modules/billing/billing.module').then((x) => x.BillingModule);
const invoiceProfileModule = () =>
    import('./modules/billing/invoicing/invoicing-routing.module').then((x) => x.InvoiceProfileRoutingModule);
const recipientModule = () =>
    import('./modules/billing/recipients/recipients-routing.module').then((x) => x.RecipientsRoutingModule);
const eomBillingModule = () =>
    import('./modules/billing/eom-billing/eom-billing.module').then((x) => x.EomBillingModule);

const helpModule = () => import('./modules/help/help.module').then((x) => x.HelpModule);
const laborManagementModule = () =>
    import('./modules/labor-management/labor-management.module').then((x) => x.LaborManagementModule);
const printModule = () => import('./shared/print-layout/print-layout.module').then((x) => x.PrintLayoutModule);
const documentationModule = () =>
    import('./modules/documentation/documentation.module').then((x) => x.DocumentationModule);
const ViewModeModule = () =>
    import('./modules/documentation/view-mode/view-mode.module').then((module) => module.ViewModeModule);

const dischargeModule = () => import('./modules/discharge/discharge.module').then((x) => x.DischargeModule);

const routes: Routes = [
    { path: ROUTER_UTILS.config.base.root, loadChildren: dashboardModule, canActivate: [AuthenticationGuard] },
    { path: ROUTER_UTILS.config.account.root, loadChildren: accountModule },

    {
        path: ROUTER_UTILS.config.profile.root,
        component: ViewUserComponent,
        canActivate: [AuthorizationGuard],
        data: { rights: [AuthPolicies.Users.View] },
    },
    {
        path: ROUTER_UTILS.config.admin.root,
        loadChildren: adminModule,
        canActivate: [AuthenticationGuard, AuthorizationGuard],
        data: { rights: GroupedPolicies.AdminPolicies },
    },
    {
        path: ROUTER_UTILS.config.notification.root,
        loadChildren: notificationModule,
        // canActivate: [AuthenticationGuard, AuthorizationGuard],
        // data: { rights: GroupedPolicies.PatientPolicies },
    },
    // colworx-ak add physician user management routes and assign its components
    {
        path: ROUTER_UTILS.config.physicianAccountsAndDocument.root,
        loadChildren: PPAccountsDocuments,
        canActivate: [AuthenticationGuard, AuthorizationGuard],
        canDeactivate: [AddStaffGuard],
        data: { rights: [AuthPolicies.PocDocuments.View] },
    },
    {
        path: ROUTER_UTILS.config.physicianAccountsAndDocument.pocDoc,
        loadChildren: POCDocuments,
        // canActivate: [AuthenticationGuard, AuthorizationGuard],
        // data: { rights: [...AuthPolicies.PocDocuments.View] },
    },
    //
    {
        path: ROUTER_UTILS.config.patients.root,
        loadChildren: patientsModule,
        canActivate: [AuthenticationGuard, AuthorizationGuard],
        data: { rights: GroupedPolicies.PatientPolicies },
    },
    {
        path: ROUTER_UTILS.config.patients.pcc.root,
        loadChildren: PCCModule,
        canActivate: [AuthenticationGuard, AuthorizationGuard],
        data: { rights: GroupedPolicies.PatientPolicies },
    },
    {
        path: ROUTER_UTILS.config.patients.pcc.tabs.documentation.root,
        loadChildren: documentationModule,
        canActivate: [AuthenticationGuard, AuthorizationGuard],
        canDeactivate: [NavigationBlockerGuard],
        data: { rights: [AuthPolicies.ClinicalDocumentation.Add, AuthPolicies.ClinicalDocumentation.Edit] },
    },
    {
        path: ROUTER_UTILS.config.scheduling.root,
        loadChildren: schedulingModule,
        canActivate: [AuthenticationGuard, AuthorizationGuard],
        data: { rights: GroupedPolicies.PlanPatientPolicies },
    },
    {
        path: ROUTER_UTILS.config.therapyAdmission.root,
        loadChildren: therapyAdmissionModule,
        canActivate: [AuthenticationGuard, AuthorizationGuard, PatientGuard, FacilityAdmissionGuard],
        data: { rights: [AuthPolicies.Patient.View] },
    },
    {
        path: ROUTER_UTILS.config.timeTracking.root,
        loadChildren: timeTrackingModule,
        canActivate: [AuthenticationGuard],
    },
    {
        path: ROUTER_UTILS.config.therapyAdmission.show.root,
        loadChildren: showTherapyAdmissionModule,
        canActivate: [AuthenticationGuard, AuthorizationGuard, PatientGuard, FacilityAdmissionGuard],
        data: { rights: [AuthPolicies.Patient.View] },
    },
    {
        path: ROUTER_UTILS.config.therapyAdmission.add.root,
        loadChildren: therapyAdmissionMainModule,
        canActivate: [AuthenticationGuard, AuthorizationGuard, PatientGuard, FacilityAdmissionGuard],
        data: { rights: [AuthPolicies.Patient.Add] },
    },
    {
        path: ROUTER_UTILS.config.therapyAdmission.edit.root,
        loadChildren: therapyAdmissionMainModule,
        canActivate: [AuthenticationGuard, PatientGuard, AuthorizationGuard, FacilityAdmissionGuard],
        data: { rights: [AuthPolicies.Patient.Edit] },
    },

    {
        path: ROUTER_UTILS.config.payorInformation.root,
        loadChildren: payorInformationModule,
        canActivate: [AuthenticationGuard, AuthorizationGuard, PatientGuard],
        data: { rights: [AuthPolicies.Patient.Add, AuthPolicies.Patient.Edit] },
    },
    {
        path: ROUTER_UTILS.config.addPatients.root,
        loadChildren: addPatientModule,
        canActivate: [AuthenticationGuard, AuthorizationGuard],
        canDeactivate: [AdmissionFormGuard],
        data: { rights: GroupedPolicies.AdmissionPolicies },
    },
    {
        path: ROUTER_UTILS.config.reports.root,
        loadChildren: reportsModule,
        canActivate: [AuthenticationGuard, AuthorizationGuard],
        data: { rights: GroupedPolicies.ReportsPolicies },
    },
    {
        path: ROUTER_UTILS.config.billing.root,
        loadChildren: billingModule,
        canActivate: [AuthenticationGuard, AuthorizationGuard, EomBillingGuard],
        data: { rights: GroupedPolicies.BillingPolicies },
    },
    {
        path: EOMRoutes.endOfMonthBilling,
        loadChildren: eomBillingModule,
        canActivate: [AuthenticationGuard, AuthorizationGuard, EomBillingGuard],
        data: { rights: [AuthPolicies.EOM.View] },
    },
    {
        path: InvoicingProfileRoutes.invoice,
        loadChildren: invoiceProfileModule,
        canActivate: [AuthenticationGuard, AuthorizationGuard, EomBillingGuard],
        data: { rights: [AuthPolicies.Invoicing.View] },
    },
    {
        path: RecipientRoutes.home,
        loadChildren: recipientModule,
        canActivate: [AuthenticationGuard, AuthorizationGuard, EomBillingGuard],
        data: { rights: [AuthPolicies.Recipient.View] },
    },
    {
        path: ROUTER_UTILS.config.help.root,
        loadChildren: helpModule,
        canActivate: [AuthenticationGuard],
    },
    {
        path: ROUTER_UTILS.config.labor.root,
        loadChildren: laborManagementModule,
        canActivate: [AuthenticationGuard],
    },
    {
        path: ROUTER_UTILS.config.print.root,
        loadChildren: printModule,
        canActivate: [AuthenticationGuard, AuthorizationGuard],
    },
    {
        path: ROUTER_UTILS.config.documentationViewMode.root,
        loadChildren: ViewModeModule,
        canActivate: [AuthenticationGuard, AuthorizationGuard],
        data: { rights: [AuthPolicies.ClinicalDocumentation.View] },
    },

    {
        path: ROUTER_UTILS.config.discharge.root,
        loadChildren: dischargeModule,
        canActivate: [AuthenticationGuard, AuthorizationGuard],
        data: { rights: [AuthPolicies.Patient.Discharge] },
    },

    { path: 'showcase', component: ShowcaseComponent, canActivate: [AuthenticationGuard] },

    { path: 'print', outlet: 'print', loadChildren: () => PrintLayoutModule },

    { path: 'organization', loadChildren: () => OrganizationModule },

    { path: 'facility', loadChildren: () => FacilityModule },

    // otherwise redirect to home
    { path: '**', redirectTo: '' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })], //adding a preload strategy for all modules, for time being, will opt for more detailed strategies in future
    exports: [RouterModule],
})
export class AppRoutingModule {}
