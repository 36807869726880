/* eslint-disable spellcheck/spell-checker */
import { Component, Inject } from '@angular/core';
import { DIALOG_DATA } from '@app/helpers/dialog-tokens';
import { DialogRef } from '@app/services';
import { FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-document-reject-coment-modal',
    templateUrl: './document-reject-coment-modal.component.html',
    styleUrls: ['./document-reject-coment-modal.component.scss'],
})
// colowrox-sk: add DocumentRejectCommentModalComponent
export class DocumentRejectCommentModalComponent {
    content: any;
    selectedValue: any;
    reason: any;
    reasonForm = new FormGroup({
        reason: new FormControl('' ,[Validators.required, control => control.value.trim().length ? null : { whitespace: true }]),
    });

    isOpen = true; // Track if modal is open

    constructor(@Inject(DIALOG_DATA) private data, private dialogRef: DialogRef) {
        this.content = data;
    }


    onClose(data?: any) {
        this.isOpen = false; 
        setTimeout(() => {
            this.dialogRef.close(data); 
        }, 500); 
    }

    addReason() {
        if (this.selectedValue == 'Other') {
            if (this.reasonForm.invalid) {
                this.reasonForm.markAllAsTouched();
                return;
            }
            this.reason = this.reasonForm.value.reason;
        } else {
            this.reason = this.selectedValue;
        }
        this.onClose(this.reason);
    }

    closeAction() {
        this.onClose();
    }
}
