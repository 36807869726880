<table #dischargeNoteReport id="dischargeNoteReport" class="print-table" *ngIf="isDataLoaded">
    <thead #header id="header" class="print-header">
        <!-- <th class="n-header">
            <app-header [isFirst]="false" [type]="'dischargeNote'"></app-header>
        </th> -->
        <tr class="print-first-header">
            <app-header [isFirst]="true" [type]="'dischargeNote'" [toExport]="toExport"></app-header>
        </tr>
    </thead>
    <tr style="page-break-before: avoid">
        <app-grid-layout [type]="'dischargeNote'" [key]="'medicalDiagnosis'"></app-grid-layout>
    </tr>
    <tr>
        <app-grid-layout [type]="'dischargeNote'" [key]="'treatmentDiagnosis'"></app-grid-layout>
    </tr>
    <tr>
        <app-paragraph [type]="'dischargeNote'" [key]="'patientAssessmentSinceLastTreatment'"></app-paragraph>
    </tr>
    <tr>
        <app-grid-layout [gridType]="'DynamicGrid'" [type]="'dischargeNote'" [key]="'goals'"></app-grid-layout>
    </tr>
    <tr>
        <app-grid-layout
            [gridType]="'TreeGrid'"
            [type]="'dischargeNote'"
            [key]="'functionalDeficits'"
        ></app-grid-layout>
    </tr>
    <tr>
        <app-grid-layout [gridType]="'TreeGrid'" [type]="'dischargeNote'" [key]="'sectionGg'"></app-grid-layout>
    </tr>
    <tr>
        <app-paragraph [type]="'dischargeNote'" [key]="'clinicalImpression'"></app-paragraph>
    </tr>
    <tr>
        <app-paragraph [type]="'dischargeNote'" [key]="'endOfCareReason'"></app-paragraph>
    </tr>
    <tr>
        <app-paragraph [type]="'dischargeNote'" [key]="'dischargePlan'"></app-paragraph>
    </tr>
    <tr>
        <app-paragraph [type]="'dischargeNote'" [key]="'caregiverEducation'"></app-paragraph>
    </tr>
    <tr>
        <app-paragraph [type]="'dischargeNote'" [key]="'dischargeDestination'"></app-paragraph>
    </tr>
    <tr>
        <app-grid-layout [gridType]="'TreeGrid'" [type]="'dischargeNote'" [key]="'addendum'"></app-grid-layout>
    </tr>
    <tr [ngClass]="{'print-footer': !withEdits}" *ngIf="isDataLoaded">
        <app-signature [type]="'dischargeNote'" [toShowPhysician]="false"></app-signature>
    </tr>
    <tr class="print-footer" *ngIf="withEdits">
        <app-grid-layout [type]="'dischargeNote'" [key]="'changeHistoryPrint'"></app-grid-layout>
    </tr>
</table>
