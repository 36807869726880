import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationManagementService, StoreService } from '@app/services';

@Component({
    selector: 'app-view-organization',
    templateUrl: './view-organization.component.html',
    styleUrls: ['./view-organization.component.scss'],
})
export class ViewOrganizationComponent implements OnInit {
    facilityListData = [];
    loadingObs: any;
    constructor(
        private _organizationService: OrganizationManagementService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private storeService: StoreService
    ) {}

    ngOnInit(): void {
        this.storeService.setLoadingState(true);
        this.loadingObs = this.storeService.getLoadingState();
        this.activatedRoute.queryParams.subscribe((params) => {
            this._organizationService.getOrganizationFacilitiesById(params.organization).subscribe((res) => {
                this.facilityListData = res.data.facility.map((obj) => {
                    const { _id, ...rest } = obj;
                    return { ...rest, id: _id };
                });

                this.storeService.setLoadingState(false);
            });
        });
    }
}
