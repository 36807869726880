<div class="row justify-content-center" style="height: calc(100vh - 245px)">
    <div class="col-12">
        <app-data-grid
            [columnDefs]="columnDefs"
            [rowData]="facilities"
            [pagination]="true"
            [showCount]="false"
            (gridReady)="initGrid($event)"
            class="ag-header-dark"
            (selectedRowsEmitter)="rowSelection($event)"
            (sortChange)="onSortChanged($event)"
        >
        </app-data-grid>
    </div>
</div>
<!-- [rowData]="patients"
        [pagination]="true"
        [showCount]="false"
        (rowClicked)="onRowClicked($event)"
        (gridReady)="initGrid($event)" -->
