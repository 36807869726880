import { Component, Inject } from '@angular/core';
import { DIALOG_DATA } from '@app/helpers/dialog-tokens';
import { DialogRef } from '@app/services';
import { ColDef } from 'ag-grid-community';

@Component({
    selector: 'app-code-view-modal',
    templateUrl: './code-view-modal.component.html',
    styleUrls: ['./code-view-modal.component.scss'],
})
export class CodeViewModalComponent {
    defaultColDef = {
        headerClass: 'bg-secondary text-white',
    };

    public goalsCols: ColDef[] = [
        {
            headerName: 'Sr No.',
            width: 30,
            valueGetter: 'node.rowIndex + 1',
        },
        { headerName: 'Goals', field: 'goal' },
    ];

    public exercisesCols: ColDef[] = [
        {
            headerName: 'Sr No.',
            width: 30,
            valueGetter: 'node.rowIndex + 1',
        },
        { headerName: 'Exercises', field: 'exercise' },
    ];

    public goalsData = [];
    public exercisesData = [];

    constructor(@Inject(DIALOG_DATA) public data, private ref: DialogRef) {
        if (this.data?.goal?.length) {
            this.goalsData = this.data.goal.map((name: string) => {
                return {
                    goal: name,
                };
            });
        }

        if (this.data?.exercise?.length) {
            this.exercisesData = this.data.exercise.map((name: string) => {
                return {
                    exercise: name,
                };
            });
        }
    }

    onClose(data?: any) {
        this.ref.close(data);
    }

    onSave() {
        this.onClose(true);
    }
}
