<div
    [class]="'form-group align-items-center justify-content-center ' + formGroupClass"
    [class.select-error]="hasError"
    [style]="formGroupStyle"
    [ngClass]="{ 'd-flex align-items-center mb-0': labelLeft }"
>
    <div class="d-flex label-cont">
        <label [ngClass]="[labelClass, 'd-block', subLabel ? 'label-with-subLabel' : '', labelLeft ? 'mx-0 my-0' : '']" *ngIf="label"
            >{{ label | translate }} {{ isRequired && !subLabel ? '*' : '' }}</label
        ><span *ngIf="!!subLabel" class="text-secondary">{{subLabel}}</span>
    </div>
    <div ngbDropdown autoClose="outside">
        <div
            [ngClass]="{ disabled: disabled }"
            ngbDropdownToggle
            [class]="backgroundClass"
            class="cursor-pointer form-control rounded-curve d-flex"
            id="facilityDropdown"
            style="border: 1px solid rgba(28, 55, 117, 0.15) !important"
        >
            <div
                [style]="
                    ' text-overflow: ellipsis; overflow: hidden !important; white-space: nowrap;  font-weight: 500; width:90%; padding:6px 10px'
                "
                
            >
            <div *ngIf="showSelectedOptions">
                <div
                    *ngFor="let option of selectedOptions"
                    class="badge badge-pill badge-primary d-inline-flex flex-row align-items-center justify-content-between pill"
                >
                    <span
                        class="body-medium text-white pill-text"
                        [ngClass]="{
                            'options-disabled': checkDisabled(option.id)
                        }"
                    >
                        {{ option.name || option | translate }}
                    </span>

                    <span
                        *ngIf="!checkDisabled(option.id)"
                        (click)="selectOption(option); $event.stopPropagation()"
                        class="material-icons-outlined close-icon"
                    >
                        close
                    </span>
                </div>
            </div>
            <div *ngIf="!showSelectedOptions && selectedOptions.length">
                <span>{{ selectedOptions.length +' '+ 'Selected' }}</span>
            </div>
                <!-- <span *ngFor="let option of selectedOptions; let i = index"
                >{{ option.name || option | translate }} <span *ngIf="selectedOptions.length - 1 != i">,</span>
            </span> -->
                <span class="choose-option" *ngIf="selectedOptions.length < 1">{{ placeholder }}</span>
            </div>
            <div class="expand-more">
                <span class="material-symbols-outlined" style="float: right"> expand_more </span>
            </div>
        </div>
        <div class="list-holder" ngbDropdownMenu aria-labelledby="facilityDropdown">
            <app-search
                (search)="searchOptions($event)"
                placeholder="Search options"
                wrapperClass="mb-2 bg-body-background"
                inputClass="bg-body-background"
                [autocomplete]="'off'"
            ></app-search>
            <div class="list-dropdown">
                <ul>
                    <li
                        class="bg-body-background cursror-pointer"
                        *ngFor="let option of filteredOptions"
                        [class.disabled]="checkDisabled(option.id)"
                        [class.active]="checkActive(option)"
                        (click)="selectOption(option)"
                        ngbDropdownItem
                    >
                        {{ option.name || option | translate }}
                    </li>
                </ul>
                <span *ngIf="filteredOptions.length < 1" class="text-center d-block pt-3 not-found"
                    >No Option Found</span
                >
            </div>
        </div>
    </div>
</div>
