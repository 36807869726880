<table class="print-table" *ngIf="isDataLoaded">
    <thead class="print-header">
        <!-- <th class="n-header">
            <app-header [isFirst]="false" [type]="'addendumNote'"></app-header>
        </th> -->
        <tr class="print-first-header">
            <app-header [isFirst]="true" [type]="'addendumNote'"></app-header>
        </tr>
    </thead>
    <tr style="page-break-before: avoid">
        <app-paragraph [type]="'addendumNote'" [key]="key"></app-paragraph>
    </tr>
    <tr class="print-footer" *ngIf="isDataLoaded">
        <app-signature [type]="'addendumNote'" [toShowPhysician]="false"></app-signature>
    </tr>
</table>
