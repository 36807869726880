import { Component, ElementRef, Input, NgZone, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DocumentationReportService, PrintService } from '@app/services';
import { EvaluationService } from '@app/services/evaluation.service';

@Component({
    selector: 'app-evaluation-report',
    templateUrl: './evaluation-report.component.html',
    styleUrls: ['./evaluation-report.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class EvaluationReportComponent implements OnInit {
    @ViewChild('header') reportHeader: ElementRef;
    @Input() reportId: string;
    @Input() report;
    @Input() physicianDetails;
    isDataLoaded: boolean;
    toExport = false;
    withEdits = false;

    constructor(
        private docReportService: DocumentationReportService,
        public evaluationService: EvaluationService,
        private ngZone: NgZone,
        private printService: PrintService
    ) {}

    async ngOnInit(): Promise<void> {
        this.withEdits = this.printService.getWithEdits();
        if (this.reportId && !this.report) {
            this.toExport = false;
            this.isDataLoaded = await this.docReportService.prepareEvaluationData(this.reportId, this.physicianDetails);
            if (this.isDataLoaded) {
                setTimeout(() => {
                    this.docReportService.countLoadedNotes();
                }, 0);
            }
        } else {
            this.toExport = true;
            this.isDataLoaded = await this.docReportService.prepareEvaluationDataForIntegration(this.report);
            setTimeout(async () => {
                // ! not use anywhere
                // const element = document.getElementById('evaluationReport');
                this.ngZone.runOutsideAngular(() => {
                    window.history.back();
                    this.evaluationService.loadedEvaluation.next(1);
                });
            }, 10);
        }
    }
}
