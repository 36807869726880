import { Pipe, PipeTransform } from '@angular/core';
import { isBefore } from 'date-fns';

@Pipe({
    name: 'isBefore',
    pure: true,
})
export class IsBeforePipe implements PipeTransform {
    transform(date: Date, dateToCompare: Date): boolean {
        return isBefore(new Date(date).setHours(0, 0, 0, 0), new Date(dateToCompare).setHours(0, 0, 0, 0));
    }
}
