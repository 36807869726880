import { Account } from '@app/models';
import { AuthService, OrganizationManagementService } from '@app/services';

export function appInitializer(authService: AuthService, organizationManagementService: OrganizationManagementService) {
    return () => 
        new Promise((resolve: any) => {
            authService.refreshToken().subscribe({
                next: (object: Account) => {
                    if (object) {
                        organizationManagementService.setOrganizationBasedOnRole(object.user).then(() => resolve());
                    } else {
                        resolve();
                    }
                },
                error: () => {
                    resolve();  
                }
            });
        });
}
