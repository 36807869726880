import { Component, Inject } from '@angular/core';
import { DIALOG_DATA } from '@app/helpers/dialog-tokens';
import { DialogRef } from '@app/services/modal.service';

@Component({
    selector: 'app-back-confirmation-dialog',
    templateUrl: './back-confirmation-dialog.component.html',
    styleUrls: ['./back-confirmation-dialog.component.scss'],
})
export class BackConfirmationDialogComponent {
    heading = '';
    constructor(@Inject(DIALOG_DATA) private data, private dialogRef: DialogRef) {
        this.heading = data?.heading;
    }

    actionConfirmation(isActionRequired) {
        this.dialogRef.close(isActionRequired);
    }
    goBack() {
        this.dialogRef.events.next({
            type: 'confirm',
            data: null,
        });
    }
    close() {
        this.dialogRef.events.next({
            type: 'close',
            data: null,
        });
    }
}
