import { Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { getCurrentUser } from '@app/helpers/constants/documentation-report';
import { DIALOG_DATA } from '@app/helpers/dialog-tokens';
import { shortTherapistType } from '@app/helpers/utils/therapist.role';
import { Concurrent, CoTreat, DailyNote, TherapyAdmission } from '@app/interfaces';
import { CptCodesService, DailyNotesService, DialogRef, TherapyAdmissionService } from '@app/services';
import { TherapistService } from '@app/services/therapist.service';
import { Options } from '@app/shared/checkbox/checkbox.component';
import { Button } from '@app/shared/toggle/toggle.component';
import { Store } from '@ngrx/store';
import { cloneDeep, uniqBy } from 'lodash';
import { Observable, Subscription, map, switchMap, take } from 'rxjs';

@Component({
    selector: 'app-minutes-modal',
    templateUrl: './minutes-modal.component.html',
    styleUrls: ['./minutes-modal.component.scss'],
})
export class MinutesModalComponent implements OnDestroy {
    heading: string;
    checklistTitle = 'Select Discipline';
    checkList = {
        title: this.checklistTitle,
        options: [
            // { text: 'PT', isChecked: false, isDisabled: false, therapists: [] },
            // { text: 'OT', isChecked: false, isDisabled: false, therapists: [] },
            // { text: 'ST', isChecked: false, isDisabled: false, therapists: [] },
        ],
    };
    buttons: Button[] = [
        {
            text: 'Co-Treatment Minutes',
            isSelected: this.data.allowCoTreatment && this.data.allTherapyAdmissions.length > 1,
            isDisabled: this.data.allTherapyAdmissions.length <= 1 || !this.data.allowCoTreatment,
            tooltip:
                this.data.allTherapyAdmissions.length <= 1 ? 'Patient does not have another therapy admission' : '',
            toolTipPlacement: 'right',
        },
        {
            text: 'Concurrent Minutes',
            isSelected: this.data.allowConcurrent && this.data.allTherapyAdmissions.length <= 1 && this.data.mdsCodeExists,
            isDisabled: (this.data.allTherapyAdmissions.length > 1 && !this.data.mdsCodeExists) || !this.data.allowConcurrent,
            tooltip: !this.data.mdsCodeExists ? 'No MDS code(s) is selected' : '',
            toolTipPlacement: 'right',
        },
    ];
    currToggle: string = this.textKeyConverter(
        this.buttons[0].isSelected ? this.buttons[0].text : this.buttons[1].text
    );

    mainForm: FormGroup = new FormGroup({
        coTreatForm: new FormGroup({
            minutes: new FormControl('', [
                Validators.required,
                Validators.pattern('^[0-9]*$'),
                Validators.min(1),
                Validators.max(720),
            ]),
            disciplines: new FormControl([], [Validators.required, Validators.minLength(2)]),
            justification: new FormControl('', Validators.required),
        }),
        concurrentForm: new FormGroup({
            minutes: new FormControl('', [
                Validators.required,
                Validators.pattern('^[0-9]*$'),
                Validators.min(1),
                Validators.max(720),
            ]),
        }),
    });
    selectWithCards;
    selectWithCardsOptions = {};
    treatingTherapist;
    saveBtnStatus = false;
    btnText = 'save';
    underGrandTotal = true;
    underMdsMinutesGrandTotal = true;
    currentDisciplineTherapist;
    allowCoTreatment = false;
    allowConcurrent = false;

    private subscriptions: Subscription[] = [];
    private currentTA: TherapyAdmission;
    private currentDA: DailyNote;
    private routeData: { doc: string; discipline: string } = {
        doc: null,
        discipline: null,
    };
    allTherapists = [];
    allTherapistsUnformatted = []; // needed for deciding 'currentDisciplineTherapist'
    selectedTherapist;

    disciplineTherapistForm: FormGroup = this.fb.group({});
    currentSelectCardValues = {};
    allRequiredTherapistsSelected;
    loader: boolean;
    SKELETON_CSS = {
        HEADER_BAR: {
            width: '20%',
            height: '6vh',
            'border-radius': '3px',
            margin: '0 2vh 1vh 0vh',
        },

        LINE: {
            width: '25%',
            height: '3vh',
            'border-radius': '3px',
            margin: '0 2vh 1vh 0vh',
        },
        LINE2: {
            width: '60%',
            height: '5vh',
            'border-radius': '3px',
            margin: '0 2vh 1vh 0vh',
        },
        BLOCK: {
            width: '100%',
            height: '10vh',
            'border-radius': '3px',
            margin: '0 2vh 1vh 0vh',
        },
    };

    constructor(
        @Inject(DIALOG_DATA) public data,
        private ref: DialogRef,
        private route: ActivatedRoute,
        private store: Store<{
            therapyAdmission: TherapyAdmission[];
        }>,
        private dailyNoteService: DailyNotesService,
        private therapistService: TherapistService,
        private fb: FormBuilder,
        private CPTCodeService: CptCodesService,
        private therapyService: TherapyAdmissionService
    ) {
        this.heading = this.data.heading;
        this.allowCoTreatment = this.data.allowCoTreatment;
        this.allowConcurrent = this.data.allowConcurrent;

        // allTherapists exist, load from cache
        if (this.CPTCodeService.allTherapists) {
            this.executeConstructorLogic();
            return;
        }

        this.loader = true;
        // fetch from service
        this.fetchAllTherapists().subscribe(() => {
            this.loader = false;
            this.executeConstructorLogic();
        });
    }

    buildCurrentDisciplineTherapist(): void {
        // commenting because its breaking logic in case of EDIT MODE
        // // 1. if already saved in DB, load from 'allTherapistsUnformatted'
        // const coTreatDisciplines = this.currentDA.objectiveAssessment?.otherModeMinutes?.coTreat?.disciplines;
        // const currentDisciplineMapping = coTreatDisciplines?.find(
        //     (elem) => elem.discipline === this.currentTA.therapyDiscipline
        // );
        // if (currentDisciplineMapping) {
        //     this.currentDisciplineTherapist = currentDisciplineMapping.therapistId; // therapistId is populated object
        //     return;
        // }

        // always use the one who's signing(logged in) the document
        // // 2. else if treating therapist exist, pick it.
        // this.treatingTherapist = this.currentTA.assignedTherapists.find((elem) => elem.role === roles.Treating);
        // if (this.treatingTherapist) {
        //     this.currentDisciplineTherapist = this.treatingTherapist.therapist; // setting the same format to be used in child component
        //     return;
        // }

        // 3. lastly, pick from current logged user
        const storeUser = getCurrentUser();
        this.currentDisciplineTherapist = this.allTherapistsUnformatted.find(
            (elem) => elem._id === storeUser?.therapist?.id
        );
    }

    executeConstructorLogic(): void {
        this.allTherapists = this.CPTCodeService.allTherapists;
        this.allTherapistsUnformatted = this.CPTCodeService.allTherapistsUnformatted;

        /**
         * Actions: add time, edit
         */
        if (this.data.action) {
            this.selectToggle(this.data);
            this.populateFields(this.data);
        }

        this.subscriptions.push(
            this.store.select('therapyAdmission').subscribe((admissions: TherapyAdmission[]) => {
                if (!admissions || !admissions.length) {
                    return;
                }

                admissions.forEach((admission: TherapyAdmission) => {
                    this.checkList.options.push({
                        text: admission.therapyDiscipline,
                        isChecked: false,
                        isDisabled: false,
                        therapists: [],
                    });
                });

                this.checkList.options = uniqBy(this.checkList.options, 'text');

                this.selectWithCards = admissions.length >= 2;

                this.route.queryParamMap.subscribe({
                    next: (params) => {
                        this.routeData.doc = params.get('doc');
                        this.routeData.discipline = params.get('discipline');

                        this.checkList.options.forEach((option: Options) => {
                            if (option.text === this.routeData.discipline) {
                                option.isChecked = true;
                                option.isDisabled = true;
                            }
                        });
                    },
                });

                // setting card options
                this.checkList.options.forEach((option) => {
                    const disciplineTherapists = this.allTherapists.filter((therapist) =>
                        therapist.therapistType.includes(option.text)
                    );

                    this.selectWithCards = true;
                    this.selectWithCardsOptions[option.text] = disciplineTherapists;

                    option.therapists = disciplineTherapists;
                });
                this.currentTA = this.therapyService.initCurrentTherapyAdmissionByIdQueryString(admissions);

                this.currentDA = this.currentTA.documentation?.dailyNote?.find(
                    (item) => item.id === this.routeData.doc
                );

                this.buildCurrentDisciplineTherapist();

                if (this.currentDA?.objectiveAssessment?.otherModeMinutes?.coTreat) {
                    this.currentDA.objectiveAssessment.otherModeMinutes.coTreat.disciplines.forEach(
                        (elem: { discipline: string; therapistId: any }) => {
                            // mark tick for selected options coming from DB
                            const option = this.checkList.options.find((curr) => curr.text === elem.discipline);
                            option.isChecked = true;

                            // setting obj that will help loading values from DB (inside checkbox component)

                            // don't include the note's discipline in this obj
                            // because its handled separately inside checkbox component
                            if (this.currentTA.therapyDiscipline !== elem.discipline) {
                                const therapist = this.allTherapists.find(
                                    (curr) => curr.id === elem.therapistId._id || curr.id === elem.therapistId
                                );
                                this.currentSelectCardValues[elem.discipline] = therapist;
                            }
                        }
                    );
                }
            })
        );

        this.subscriptions.push(
            this.mainForm.valueChanges.subscribe(() => {
                this.evaluateSaveStatus();
            })
        );

        this.evaluateSaveStatus();
    }

    fetchAllTherapists(): Observable<any[]> {
        // save all therapists in service (needed for coTreatment form)
        return this.therapistService.getAllActiveTherapists().pipe(
            map((therapists) => {
                this.allTherapistsUnformatted = cloneDeep(therapists);

                this.CPTCodeService.allTherapistsUnformatted = this.allTherapistsUnformatted;
                this.CPTCodeService.allTherapists = therapists.map((v) => {
                    return {
                        id: v._id,
                        name: v.user?.lastName + ' ' + v.user?.firstName,
                        discipline: `${shortTherapistType(v?.therapistType)}`,
                        therapistType: v.therapistType,
                        NPI: v.NPI ? v.NPI : 'N/A ',
                        licensureNumber: v.licensures?.[0]?.licensureNumber ? v.licensures?.[0]?.licensureNumber : 'N/A ',
                    };
                });

                return this.CPTCodeService.allTherapists;
            })
        );
    }

    selectToggle(data: any): void {
        // sets up right toggle state
        const { currToggle } = data;
        this.currToggle = currToggle;

        // updating btn states
        this.buttons = this.buttons.reduce((acc, curr) => {
            curr.isSelected = this.textKeyConverter(currToggle) === curr.text;
            return [...acc, curr];
        }, []);
    }

    /**
     * Edit Modal Case: Loads Edited Data in fields
     */
    populateFields(data: any): void {
        const { otherModeMinutes } = cloneDeep(data);

        if (!otherModeMinutes) {
            return;
        }

        if (otherModeMinutes.coTreat) {
            otherModeMinutes.coTreat.disciplines = otherModeMinutes.coTreat.disciplines.map((elem) => elem.discipline);
        }

        // populating fields
        Object.entries(otherModeMinutes).forEach(([mode, value]) => {
            this.mainForm.get(`${mode}Form`).setValue(value);
        });

        this.evaluateSaveStatus();
    }

    evaluateSaveStatus() {
        const editableCheckedByUser = this.checkList.options.filter(
            (elem) => elem.isChecked && elem.text !== this.currentTA.therapyDiscipline
        );

        this.underGrandTotal = true;

        this.underMdsMinutesGrandTotal = true;

        this.checkList.title =
            this.checkList.options.filter((elem) => elem.isChecked).length < 2
                ? `${this.checklistTitle} *`
                : this.checklistTitle;

        const grandOtherTxMinutes =
            +this.mainForm.get('coTreatForm').get('minutes').value +
            +this.mainForm.get('concurrentForm').get('minutes').value;

        if (!isNaN(grandOtherTxMinutes)) {
            this.underGrandTotal = grandOtherTxMinutes <= this.data.mdsMinutesGrandTotal;
        }

        // const concurrentMinutes = +this.mainForm.get('concurrentForm').get('minutes').value;

        // if (!isNaN(concurrentMinutes)) {
        //     this.underMdsMinutesGrandTotal = concurrentMinutes <= this.data.mdsMinutesGrandTotal;
        // }

        this.saveBtnStatus =
            ((this.currToggle === 'coTreat' &&
                this.mainForm.get('coTreatForm').valid &&
                editableCheckedByUser.length === Object.keys(this.currentSelectCardValues).length) ||
                (this.currToggle === 'concurrent' && this.mainForm.get('concurrentForm').valid)) &&
            this.underGrandTotal;

        this.evaluateBtnText();
    }

    evaluateBtnText() {
        this.btnText = 'save';
        if (this.data && this.data.otherModeMinutes && this.data.otherModeMinutes[this.currToggle]) {
            this.btnText = 'update';
        }
    }

    onClose() {
        this.ref.close();
    }

    formSubmitted() {
        if (!this.saveBtnStatus) {
            return;
        }

        const otherModeMinutes = this.currentDA.objectiveAssessment.otherModeMinutes;

        const [coTreatForm, concurrentForm] = [this.mainForm.get('coTreatForm'), this.mainForm.get('concurrentForm')];

        if (coTreatForm.valid) {
            const disciplines = [];

            // Here, formControl 'discipline' can have two types of values
            // 1. simple string like 'ST'
            // 2. or an Options type Object
            // so handling it.
            coTreatForm.value.disciplines
                .map((discipline: string | Options) => (typeof discipline === 'object' ? discipline.text : discipline))
                .forEach((discipline: string) => {
                    const selectedTherapist = this.currentSelectCardValues[discipline];

                    if (selectedTherapist) {
                        // selectable therapist
                        // other than current discipline
                        disciplines.push({
                            discipline: discipline,
                            therapistId: selectedTherapist.id,
                        });
                    } else {
                        // current discipline
                        disciplines.push({
                            discipline: discipline,
                            therapistId: this.currentDisciplineTherapist._id,
                        });
                    }
                });

            const coTreat: CoTreat = {
                minutes: +coTreatForm.get('minutes').value,
                disciplines,
                justification: coTreatForm.get('justification').value,
            };
            otherModeMinutes.coTreat = coTreat;
        }

        if (concurrentForm.valid) {
            const concurrent: Concurrent = {
                minutes: +concurrentForm.get('minutes').value,
            };
            otherModeMinutes.concurrent = concurrent;
        }

        // remove keys with null value (existing data)
        Object.keys(otherModeMinutes).forEach((key) => otherModeMinutes[key] === null && delete otherModeMinutes[key]);
        this.currentDA.objectiveAssessment.otherModeMinutes = otherModeMinutes;

        if (this.data.isEditMode) {
            const currentTA = JSON.parse(JSON.stringify(this.currentTA));
            currentTA.documentation.dailyNote[this.data.dailyNoteIndex].objectiveAssessment.otherModeMinutes =
                otherModeMinutes;
            if (this.currToggle === 'coTreat') {
                this.dailyNoteService.prepareDataForEdit(
                    'objectiveAssessment',
                    'otherModeMinutes.coTreat',
                    otherModeMinutes.coTreat,
                    'array',
                    [...this.data.allTherapyAdmissions],
                    currentTA,
                    this.data.dailyNoteIndex
                );
            }
            if (this.currToggle === 'concurrent') {
                this.dailyNoteService.prepareDataForEdit(
                    'objectiveAssessment',
                    'otherModeMinutes.concurrent',
                    otherModeMinutes.concurrent,
                    'array',
                    [...this.data.allTherapyAdmissions],
                    currentTA,
                    this.data.dailyNoteIndex
                );
            }
            this.onClose();
        } else {
            this.subscriptions.push(
                this.store
                    .select('therapyAdmission')
                    .pipe(
                        take(1),
                        switchMap((admissions: TherapyAdmission[]) => {
                            const payload: any = cloneDeep(this.currentDA);
                            payload.documentation_id = this.currentTA.documentation.id;

                            return this.dailyNoteService.updateDailyNote(payload, admissions);
                        })
                    )
                    .subscribe(() => {
                        this.onClose();
                    })
            );
        }
    }

    appToggleChanged(): void {
        this.currToggle = this.textKeyConverter(this.buttons.find((btn) => btn.isSelected).text);

        this.evaluateSaveStatus();
    }

    ngOnDestroy(): void {
        this.subscriptions.map((sub: Subscription) => sub.unsubscribe());
    }

    textKeyConverter(text: string): string {
        if (text === 'Co-Treatment Minutes') return 'coTreat';
        if (text === 'Concurrent Minutes') return 'concurrent';
        if (text === 'coTreat') return 'Co-Treatment Minutes';
        if (text === 'concurrent') return 'Concurrent Minutes';
    }

    onCheckboxChange(option) {
        // remove therapist from store when its option unchecked
        if (!option.isChecked) {
            delete this.currentSelectCardValues[option.text];
        }

        this.evaluateSaveStatus();
    }
    getTherapists(selectedDiscipline) {
        return this.checkList.options.find((option) => option.text == selectedDiscipline).therapists;
    }

    selectWithCardChangedCalled(event) {
        // only include those disciplines that have values,
        // and do not have null because they are not selected yet
        this.currentSelectCardValues = {};
        Object.keys(event).forEach((key) => {
            if (event[key]) {
                this.currentSelectCardValues[key] = event[key];
            }
        });
        this.evaluateSaveStatus();
    }
}
