<!--   Procedure Pills  -->
<div
    *ngIf="params.procedure && !params.empty"
    (mouseenter)="onMouseEnter($event, 'procedure')"
    (mouseleave)="onMouseLeave()"
>
    <div>
        <span class="btn btn-primary btn-sm rounded-pill"> {{ params.data }} Procedure</span>
    </div>
</div>

<span *ngIf="params.empty && params.procedure" class="material-symbols-outlined d-flex">add</span>

<ng-container *ngIf="params.procedure && !params.empty">
    <div class="info-details" #tooltipProcedure>
        <ul>
            <li class="text-secondary" *ngFor="let item of params.chipTooltip">{{ item }}</li>
        </ul>
    </div>
</ng-container>

<!--   Skill Service  -->
<span *ngIf="params.empty && params.justification" class="material-symbols-outlined d-flex">add</span>
<div class="d-flex flex-column w-100" *ngIf="params.justification && !params.empty">
    <span *ngIf="params.data?.length <= 99" class="body-medium text-secondary">
        {{ params.data }}
    </span>
    <span
        *ngIf="params.data?.length >= 99"
        class="body-medium text-secondary"
        style="font-size: 11px"
        (mouseenter)="onMouseEnter($event, 'skill')"
        (mouseleave)="onMouseLeave()"
    >
        {{ params.data?.slice(0, 100) }}...
    </span>
</div>
<ng-container *ngIf="params.justification && !params.empty">
    <div class="info-details" #tooltipJustification>
        <div class="text-secondary">
            {{ params.data }}
        </div>
    </div>
</ng-container>

<!--   Goal Pills  -->

<div *ngIf="params.goal && !params.empty" (mouseenter)="onMouseEnter($event, 'goal')" (mouseleave)="onMouseLeave()">
    <div>
        <span class="btn btn-primary btn-sm rounded-pill"> {{ params.data.goal.length }} Goal(s)</span>
    </div>
</div>

<span *ngIf="params.empty && params.goal" class="material-symbols-outlined d-flex">add</span>

<ng-container *ngIf="params.goal && !params.empty">
    <div class="info-details" #tooltipGoal>
        <ul>
            <li class="text-secondary" *ngFor="let item of params.data.goal">{{ item }}</li>
        </ul>
    </div>
</ng-container>

<!--   Description  -->
<div class="d-flex flex-column w-100" *ngIf="params.description && !params.empty">
    <span *ngIf="params.data?.length <= 99" class="body-medium text-secondary">
        {{ params.data }}
    </span>
    <span
        *ngIf="params.data?.length >= 99"
        class="body-medium text-secondary"
        style="font-size: 11px"
        (mouseenter)="onMouseEnter($event, 'Description')"
        (mouseleave)="onMouseLeave()"
    >
        {{ params.data?.slice(0, 100) }}...
    </span>
</div>
<ng-container *ngIf="params.description && !params.empty">
    <div class="info-details" #tooltipDescription>
        <div class="text-secondary">
            {{ params.data }}
        </div>
    </div>
</ng-container>

<div class="d-flex flex-column w-100" *ngIf="params.default">
    <span
        *ngIf="params.data?.length <= 15"
        (mouseenter)="onMouseEnter($event, 'default')"
        (mouseleave)="onMouseLeave()"
    >
        {{ params.data }}
    </span>
    <span *ngIf="params.data?.length > 15" (mouseenter)="onMouseEnter($event, 'default')" (mouseleave)="onMouseLeave()">
        {{ params.data }}
    </span>
</div>

<div class="info-details" #defaultTooltip>
    <div class="body-medium text-secondary">
        {{ params.data }}
    </div>
</div>
