import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'minutesToHoursMinutes',
})
export class MinutesToHoursMinutesPipe implements PipeTransform {
    transform(minutes: number, ...args: any[]): unknown {
        if (minutes) {
            minutes = parseInt(minutes.toString());
            const hour = (minutes / 60) ^ 0;

            // needed in Manager minutes-by-patient component
            if (args[0]?.includes('no-minutes')) {
                return `${hour}h`;
            }
            const mint = Math.floor(minutes % 60);
            if (args.includes(':')) {
                if (hour === 0) return `00:${mint < 10 ? '0' + mint : mint}`;
                else return `${hour < 10 ? '0' + hour : hour}:${mint < 10 ? '0' + mint : mint}`;
            } else {
                if (hour === 0) return `${mint} mins`;
                else return `${hour}h ${mint}mins`;
            }
        } else {
            if (args.includes(':')) {
                return `00:00`;
            } else {
                return `0h 0mins`;
            }
        }
    }
}
