import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-pp-view-mode-goals',
  templateUrl: './pp-view-mode-goals.component.html',
  styleUrls: ['./pp-view-mode-goals.component.scss']
})
export class PpViewModeGoalsComponent  {
  @Input() tableTitle: string;
  @Input() goalData: Array<any> = [];
  @Input() isHistoryMode: string;

}
