<div>
    <div class="mb-3">
        <app-go-back title="Edit Time Cards" [goBackAction]="true"></app-go-back>
    </div>
    <div *ngIf="!loading" class="selected-facilities d-flex justify-content-between align-items-center pl-2 pr-2 mb-5">
        <div class="facilities-count text-secondary fw-bolder ml-3">
            Selected Facilities: {{ selectedFacilities?.length ? selectedFacilities?.length : 0 }}
        </div>
        <app-button
            (click)="editFacilities()"
            type="secondary"
            class="mr-3 cursor-pointer"
            size="lg"
            [text]="'Edit Facilities'"
        ></app-button>
    </div>
    <div *ngIf="(!loading && npcList?.length) || activities">
        <div class="custom-card">
            <div class="header">
                <p>{{ title }}</p>
            </div>

            <form [formGroup]="form" (ngSubmit)="openConfirmationModal()">
                <table class="data-table">
                    <thead>
                        <tr>
                            <ng-container *ngFor="let header of headerSizes">
                                <th [style.width]="header.size" [style.paddingRight]="header.padding">
                                    {{ header.title }}
                                </th>
                            </ng-container>
                        </tr>
                    </thead>
                    <tbody formArrayName="rows">
                        <ng-container *ngFor="let item of rowForms.controls; let i = index">
                            <tr [formGroupName]="i" *ngIf="!item.get('isDeleted').value">
                                <td
                                    [ngClass]="{
                                        disable: item?.value?.activity?.toLowerCase() === 'labor time'
                                    }"
                                >
                                    <app-switch
                                        class="d-inline-block align-middle"
                                        formGroupClass="mb-0"
                                        controlContainerClass="h-auto"
                                        (toggleUpdated)="toggleChanged(item, $event)"
                                        formControlName="enable"
                                    ></app-switch>
                                </td>

                                <td
                                    class="editable-name-padding"
                                    [ngClass]="{
                                        disable: !item?.value?.enable
                                    }"
                                >
                                    <input
                                        placeholder="Enter Activity Name"
                                        type="text"
                                        class="form-control border"
                                        [ngClass]="{ 'remove-border': disableInputField(item.value) }"
                                        formControlName="activity"
                                    />
                                </td>

                                <td
                                    [ngClass]="{
                                        'disable-select': !item?.value?.isDeletable || !item?.value?.enable,
                                        disable: !item?.value?.enable
                                    }"
                                    class="select-unit editable-select-padding"
                                >
                                    <app-select
                                        formControlName="unit"
                                        [options]="units"
                                        [selectedValue]="unit"
                                    ></app-select>
                                </td>

                                <td class="editable-select-padding">
                                    <label *ngIf="item.value.enable" class="checkbox-label">
                                        <label
                                            [ngClass]="{
                                                'greyed-select ':
                                                    item?.value?.activity?.toLowerCase() === 'labor time' || !item?.value?.enable,
                                                'select-padding': disableInputField(item.value) || !item?.value?.enable,
                                                'disable-select': !item?.value?.enable
                                            }"
                                            class="billOptions"
                                        >
                                            <app-select
                                                formControlName="billable"
                                                [options]="billOptions"
                                                [selectedValue]="
                                                    item.get('billable').value ? 'Billable' : 'Non-Billable'
                                                "
                                                (optionChanged)="updateOptions($event, item.value)"
                                            ></app-select>
                                        </label>
                                    </label>

                                    <span class="invisible-rate hide-select" *ngIf="!item.value.enable">Invisible</span>
                                </td>

                                <td class="rate-cell editable-rate-padding">
                                    <div *ngIf="item.value.billable "  class="edit-mode-input">
                                        <span
                                            *ngIf="
                                                item.value.billable && item?.value?.enable && item?.value?.isDeletable
                                            "
                                            [ngClass]="{
                                                'editable-currency': !item.get('billable').value,
                                                dollar: item.get('billable').value,
                                                'greyed-out':
                                                    !item.value.isDeletable && item.get('activity').value?.toLowerCase() !== 'mileage'
                                            }"
                                            >$</span
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            [placeholder]="getPlaceholderText(item.value)"
                                            (input)="validateInput($event)"
                                            maxlength="10"
                                            [ngClass]="{
                                                'remove-border': disableInputField(item.value) || !item?.value?.enable,
                                                'non-billable':
                                                    item.get('billable').value === 'Non-Billable' ||
                                                    item.get('billable').value === false,
                                                billable:
                                                    item.get('billable').value === 'Billable' ||
                                                    item.get('billable').value === true,
                                                'greyed-out': disableInputField(item.value) 
                                            }"
                                            formControlName="rate"
                                        />
                                        <span
                                            *ngIf="
                                                item.value.billable && item?.value?.enable && item?.value?.isDeletable
                                            "
                                            [ngClass]="{
                                                'disable-rate': !item.get('billable').value
                                            }"
                                            class="rate"
                                            >{{ getUnit(item.get('unit').value) }}</span
                                        >
                                    </div>
                                    <div *ngIf="!item.value.billable"  class="edit-mode-input" >
                                        <input
                                            type="text"
                                            class="form-control greyed-out"
                                            [placeholder]="item.value.enable ? individualRatesActivity.includes(item.get('activity').value) ? 'Individual rates' : 'Non Billable' : 'Invisible'"
                                        />
                                    </div>
                                </td>

                                <td
                                    class="rate-cell end-date"
                                    [ngClass]="{
                                        'disable-date-picker':
                                            item.get('activity').value?.toLowerCase() === 'labor time' || !item?.value?.enable
                                    }"
                                >
                                <app-date-picker (ngModelChange)="endDateSelected($event, item, i)" [placeholder]="item.value.activity === 'Labor Time' ? 'Open Ended' : 'MM/DD/YYYY'" formControlName="endDate" backgroundClass="bg-body-background">
                                </app-date-picker>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </form>
        </div>
        <div class="no-activities-parent">
            <div class="btns">
                <button
                    (click)="handleCancel()"
                    [ngClass]="{ 'change-border': isRehabCenter }"
                    class="innova-btn button-skip btn-secondary ml-1 cancel-btn"
                >
                    Cancel
                </button>
                <button
                    [disabled]="isMandatoryFieldsFilled() || (npcList?.length && isDataSame())"
                    (click)="openConfirmationModal()"
                    class="btn-secondary innova-btn innova-btn-lg save-btn"
                >
                    <span *ngIf="isDataSaving" class="spinner-border spinner-border-sm mr-1 text-primary"></span>

                    Save
                </button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="loading">
    <ng-container>
        <div>
            <br />
            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
            <hr class="table-footer" />
            <div class="pagination-loader">
                <ngx-skeleton-loader [theme]="{ width: '10em', 'margin-right': '0' }"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{ width: '10em', 'margin-left': '5em' }"></ngx-skeleton-loader>
            </div>
        </div>
    </ng-container>
</div>
