<app-base-modal-card [heading]="'CPT Code Details'" (close)="onClose()">
    <div body>
        <div class="mt-1"><b class="mr-1 heading">Code: </b> {{ data.code }}</div>
        <div class="mt-1"><b class="mr-1 heading">Description: </b> {{ data.description }}</div>
        <div class="mt-1">
            <b class="mr-1 heading">Time: </b> {{ data.time === 'Add Time' ? 'Time not added.' : data.time }} |
            <b class="mr-1 heading">Units: </b> {{ data.units }}
        </div>

        <div class="mt-4">
            <app-data-grid
                domLayout="autoHeight"
                [columnDefs]="goalsCols"
                [rowData]="goalsData"
                [defaultColDef]="defaultColDef"
            ></app-data-grid>
        </div>

        <div class="mt-3">
            <app-data-grid
                class="mt-2"
                domLayout="autoHeight"
                [columnDefs]="exercisesCols"
                [rowData]="exercisesData"
                [defaultColDef]="defaultColDef"
            ></app-data-grid>
        </div>

        <div class="mt-4">
            <b class="heading">Justification </b>
            <div>{{ data.justification }}</div>
        </div>
    </div>
</app-base-modal-card>
