import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TherapistCaseLoadComponent } from '@app/shared/print-layout/therapist-case-load/therapist-case-load.component';
import { PrintLayoutComponent } from './print-layout.component';
import { ViewModeDocLayoutComponent } from './view-mode-doc-layout/view-mode-doc-layout.component';
import { DocumentationComponent } from './documentation/documentation.component';
import { WhiteboardPrintComponent } from './whiteboard-print/whiteboard-print.component';
const routes: Routes = [
    {
        path: '',
        component: PrintLayoutComponent,
        children: [
            { path: 'therapist/schedule', component: TherapistCaseLoadComponent },
            { path: 'therapist/:therapistIds', component: TherapistCaseLoadComponent },
            { path: 'viewMode/document', component: ViewModeDocLayoutComponent },
            { path: 'whiteboard', component: WhiteboardPrintComponent },
        ],
    },
    {
        path: 'document',
        component: DocumentationComponent,
    },
    {
        path: 'therapy-case/therapy-admission',
        component: DocumentationComponent,
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PrintLayoutRoutingModule {}
