import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { StoreService } from '@app/services';
import { AgGridAngular } from 'ag-grid-angular';
import {
    ColDef,
    FirstDataRenderedEvent,
    GridApi,
    GridReadyEvent,
    GridSizeChangedEvent,
    RowClassRules,
    RowClickedEvent,
    RowStyle,
    ColumnApi,
    CellValueChangedEvent,
    SortChangedEvent,
    CellClickedEvent,
    FilterChangedEvent,
    RowSelectedEvent,
} from 'ag-grid-community';
import { GridActionsService } from './services/grid-actions.service';
@Component({
    selector: 'app-data-grid',
    templateUrl: './data-grid.component.html',
    styleUrls: ['./data-grid.component.scss'],
})
export class DataGridComponent implements OnDestroy, OnInit, OnChanges {
    @ViewChild('agGrid', { static: true }) agGrid: AgGridAngular;
    private gridApi: GridApi;
    private gridColumnApi: ColumnApi;
    public isLoading = false;
    @Input() headerHeight: number;
    @Input() defaultColDef = { resizable: true, suppressMovable: true };
    @Input() tooltipShowDelay = 0;
    @Input() tooltipHideDelay = 1000;
    @Input() columnDefs: ColDef[] = [];
    @Input() rowData = [];
    @Input() pinnedBottomRowData = [];
    @Input() getRowStyle: RowStyle | undefined;
    @Input() pagination = false;
    @Input() paginationPageSize = 6;
    @Input() showCount = true;
    @Input() rowClassRules: RowClassRules;
    @Input() rowClass: '';
    @Input() context: any;
    @Input() rowMultiSelectWithClick = false;
    @Input() domLayout: 'normal' | 'autoHeight' | 'print' = 'normal';
    @Input() singleClickEdit: true | false = true;
    @Input() quickFilterText = '';
    @Output() gridReady: EventEmitter<GridReadyEvent> = new EventEmitter();
    @Output() rowClicked: EventEmitter<RowClickedEvent> = new EventEmitter();
    @Output() cellClicked: EventEmitter<CellClickedEvent> = new EventEmitter();
    @Output() rowDataChanged: EventEmitter<RowClickedEvent> = new EventEmitter();
    @Output() rowSelected: EventEmitter<RowClickedEvent> = new EventEmitter();

    // colworx sk
    @Output() cellMouseDown: EventEmitter<any> = new EventEmitter();
    @Output() cellMouseUp: EventEmitter<any> = new EventEmitter();
    @Output() cellMouseLeave: EventEmitter<any> = new EventEmitter();

    // colworx sk
    @Output() paginationChanged: EventEmitter<RowClickedEvent> = new EventEmitter();
    @Output() selectedRowsEmitter: EventEmitter<any> = new EventEmitter();
    @Output() cellValueChanged: EventEmitter<any> = new EventEmitter();
    @Output() sortChange: EventEmitter<SortChangedEvent> = new EventEmitter();
    @Output() filterChange: EventEmitter<FilterChangedEvent> = new EventEmitter();
    @Output() sizeChanged: EventEmitter<GridSizeChangedEvent> = new EventEmitter();
    @Input() isSearchable = false;
    @Input() loading = false;
    @Input() printDoc;
    @Input() colUpdate = false;
    @Input() gridOptions;
    @Input() suppressDragLeaveHidesColumns = true;
    @Input() cacheOverflowSize;
    // eslint-disable-next-line spellcheck/spell-checker
    @Input() maxConcurrentDatasourceRequests;
    @Input() infiniteInitialRowCount;
    @Input() rowHeight = 40;
    @Input() rowSelection = 'multiple';
    @Input() maxBlocksInCache;
    @Input() paginationAutoPageSize = true;
    @Input() overrideNoRowMessage = '';
    @Input() noRowsOverlayMessage = 'No Rows To Show'; // colworx-ak Define the @input to noRowsOverlayMessage

    loadingObs: any;
    loadingOverlayMessage = 'Loading...';
    emptyRowsOverlayMessage = 'No Rows To Show';
    defaultHeaderHeight = 49; // 49px header height is fixed in ag-grid
    postSort;
    public overlayLoadingTemplate =
        '<span  class="bg-transparent spinner-border text-primary spinner-border-sm" style="padding: 10px;"></span>';
    public overlayNoRowsTemplate =
        '<span  class="bg-transparent body-regular text-secondary" style="padding: 10px;">' +
        this.noRowsOverlayMessage +
        '</span>';
    public overlayEmptyRowsTemplate =
        '<span  class="bg-transparent body-regular text-secondary" style="padding: 10px;">' +
        this.emptyRowsOverlayMessage +
        '</span>';

    constructor(private storeService: StoreService, private gridActionService: GridActionsService) {
        this.loadingObs = this.storeService.getLoadingState();
        gridActionService.print.subscribe((v) => {
            if (v) this.readyPrintLayout();
        });

        /**
         * To make 'no facility admissions' come to top in 'asc' case
         */
        this.postSort = (params) => {
            const sort = this.gridColumnApi?.getColumn('admitDate')?.getSort();

            if (sort === 'asc') {
                if (!params || !params.nodes || !params.nodes.length) {
                    return;
                }

                const rowNodes = params.nodes;

                let nextInsert = 0;
                for (let i = 0; i < rowNodes.length; i++) {
                    const node = rowNodes[i];
                    if (!node || !node.data || !node.data.facilityAdmission || !node.data.facilityAdmission.admitDate) {
                        rowNodes.splice(nextInsert, 0, rowNodes.splice(i, 1)[0]);
                        nextInsert++;
                    }
                }
            }
        };
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.rowData || !changes.rowData.currentValue || changes?.rowData?.currentValue.length == 0) {
            this.overlayNoRowsTemplate = `<span  class="bg-transparent body-regular text-secondary" style="padding: 10px;">${this.noRowsOverlayMessage}</span>`;
        }
        if (changes?.loading?.currentValue) {
            this.gridApi?.showLoadingOverlay();
        }
        if (!changes?.printDoc?.firstChange) {
            if (this.colUpdate) {
                this.updateTableState();
            }
        }

        if (this.overrideNoRowMessage) {
            this.overlayNoRowsTemplate = this.overrideNoRowMessage;
            this.overlayEmptyRowsTemplate = this.overrideNoRowMessage;
        }
    }
    ngOnInit(): void {
        if (this.isSearchable) {
            this.overlayNoRowsTemplate = this.overlayEmptyRowsTemplate;
        }
        if (this.overrideNoRowMessage) {
            this.overlayNoRowsTemplate = this.overrideNoRowMessage;
            this.overlayEmptyRowsTemplate = this.overrideNoRowMessage;
        }
        this.headerHeight = isNaN(this.headerHeight) ? this.defaultHeaderHeight : this.headerHeight;
    }

    onGridReady(event: GridReadyEvent): void {
        if ((!this.rowData || !this.rowData.length) && this.domLayout !== 'autoHeight') {
            this.setNoDataOverlayDimensions();
        }

        this.gridReady.emit(event);
        this.gridApi = event.api;
        this.gridColumnApi = event.columnApi;
    }
    autoWidthColumns() {
        // const allColumnIds: string[] = [];
        // this.gridColumnApi.getAllColumns().forEach((column) => {
        //     allColumnIds.push(column.getId());
        // });
        // this.gridColumnApi.autoSizeColumns(allColumnIds, true);
        this.gridApi.sizeColumnsToFit();
    }
    gridPopulated(event: FirstDataRenderedEvent): void {
        event.api.sizeColumnsToFit();
        setTimeout(() => {
            this.autoWidthColumns();
        }, 500);
    }
    gridSizeChanged(event: GridSizeChangedEvent): void {
        const { clientHeight, clientWidth } = event;
        if (clientHeight && clientWidth) {
            event.api.sizeColumnsToFit();
            this.sizeChanged.emit(event);
        }
    }
    onRowClicked(event: RowClickedEvent): void {
        this.rowClicked.emit(event);
    }
    onRowSelected(event: RowSelectedEvent): void {
        this.rowSelected.emit(event);
    }
    onCellClicked(event: CellClickedEvent): void {
        this.cellClicked.emit(event);
    }
    onSortChanged(event: SortChangedEvent): void {
        this.sortChange.emit(event);
    }
    onFilterChanged(event: FilterChangedEvent) {
        this.filterChange.emit(event);
    }
    onSelectionChanged(event: AgGridAngular) {
        const selectedRows = event.api.getSelectedRows();
        this.selectedRowsEmitter.emit(selectedRows);
        event.api.refreshCells();
    }
    onCellValueChanged(params: CellValueChangedEvent) {
        this.cellValueChanged.emit(params);
    }
    readyPrintLayout() {
        if (this.gridApi) this.gridApi.setDomLayout('print');
    }
    setNoDataOverlayDimensions(): void {
        const overlayElements: HTMLCollectionOf<Element> | any = document.getElementsByClassName('ag-overlay');
        for (const overlay of overlayElements) {
            // eslint-disable-next-line spellcheck/spell-checker
            overlay.style.height = `calc(100% - ${this.headerHeight}px)`;
            overlay.style.top = `${this.headerHeight}px`;
        }
    }
    onRowDataChanged(event) {
        this.rowDataChanged.emit(event);
    }

    // col-worx sk
    onRowMouseDown(event) {
        this.cellMouseDown.emit(event);
    }
    onRowMouseUp() {
        this.cellMouseUp.emit();
    }
    onRowMouseLeave() {
        this.cellMouseLeave.emit();
    }
    // col-worx sk

    onPaginationChanged(event) {
        this.paginationChanged.emit(event);
    }
    ngOnDestroy() {
        // this.gridActionService.resetSelectedAction()
    }
    public updateTableState = () => {
        if (this.columnDefs && this.columnDefs.length > 0) {
            this.columnDefs.forEach((col) => {
                if (col.headerName == 'Type') {
                    col.headerCheckboxSelection = this.printDoc;
                    col.checkboxSelection = this.printDoc;
                }
            });
            this.gridApi?.setColumnDefs(this.columnDefs);
            this.gridApi?.setRowData(this.rowData);
        }
    };
}
