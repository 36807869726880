/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl, ValidationErrors } from '@angular/forms';

@Component({
    selector: 'app-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit, ControlValueAccessor {
    @Input() label: string;
    @Input() hideLabel = false;
    @Input() placeholder = 'Type here';
    @Input() customToolTip: { tooltip: boolean; msg: string };
    @Input() type: 'text' | 'number' | 'password' | 'email' = 'text';
    @Input() mask: any;
    @Input() allowSpaces: boolean;
    @Input() tooltip = false;
    @Input() backgroundClass = 'bg-body-background';
    @Input() inputClass = '';
    @Input() positionRelative = '';
    @Input() positionAbsolute = '';
    @Input() marginBottom = '';
    @Input() marginTop = '';
    @Input() mrnNumber: false;
    @Input() cptPercentage: false;
    @Input() autocomplete: 'on' | 'off' = 'on';
    @Input() minLength = 0;
    @Input() maxLength = 100;
    @Input() allowDecimal = false;
    @Input() allowPercentage = false;
    @Input() allowFloatValues = false;
    @Input() tooltipConfig: any = {};
    @Input() tooltipPlacement = 'left';
    @Input() labelLeft = false;
    @Input() disabled: boolean;
    @Input() inputWithContent = false;
    @Input() readOnly = false;
    @Input() contentClass: string;
    @Input() contentInfo: {
        type: string;
        text?: string;
        options?: any[];
        selectedOption?: string;
        showErrorTooltip: boolean;
    };
    @Input() rightAlignToggle = false;
    @Input() temperatureScale = 'F';
    @Input() labelClass = 'text-secondary';
    @Input() marginZero = 'ml-0';
    @Input() required: boolean;
    // * New White Color tooltip
    @Input() whiteTooltipEnabled = false;
    @Input() whiteTooltipWarning = '';
    @Input() whiteTooltipPlacement = 'left';
    // * Pretext and post text
    @Input() preText: {
        text: string;
        style: any;
    };

    @Input() postText: {
        text: string;
        style: any;
    };
    @Input() preTextSvg = '';

    @Input() isFloat = false;

    @Output() changeHappened = new EventEmitter();
    @Output() inputWithContentOptionChanged = new EventEmitter();

    maskObj: any;
    value: any;
    errorMsg = '';
    showErrorTooltip: boolean;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    propagateChange = (_: any) => {};
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onTouched = (_: any) => {};

    constructor(public readonly ngControl: NgControl) {
        ngControl.valueAccessor = this;
    }

    ngOnInit(): void {
        if (!this.placeholder) this.placeholder = 'Type here';
        if (this.mrnNumber && this.type === 'text') {
            this.mask = new RegExp('^[A-Z0-9]*$');
        }
        if (this.cptPercentage && this.type === 'number') {
            this.mask = new RegExp('^(100(?:\\.00?)?|\\d{1,2}(?:\\.\\d{0,2})?)$');
        }
        if (this.allowDecimal && this.type === 'number') {
            this.mask = new RegExp('');
        } else if (this.type === 'email') {
            new RegExp('^[a-zA-Z0-9`~@#$%^&*()+={\\}|;:“?/,. "<>!\'-_]*$');
        } else if (this.mask === undefined || this.mask === null) {
            if (!this.allowPercentage && !this.allowFloatValues) {
                this.mask =
                    this.allowSpaces === false
                        ? new RegExp('^[a-zA-Z0-9`~@#$%^&*()+={\\}|;:“?/,. "<>!\'-]*$')
                        : new RegExp('^[a-zA-Z0-9`~@#$%^&*()+={\\}|;:“?/,. "<>!\'-]*$');
            } else if (this.allowPercentage) {
                this.mask = new RegExp('^[1-9][0-9]?$|^100$');
            } else if (this.allowFloatValues) {
                this.mask = new RegExp(/^(?=.*[1-9])\d{0,2}(?:\.\d{0,2})?$/);
            }
        }
        this.maskObj = this.mask != null ? { mask: this.mask } : null;
        if (this.mask == '000.00') {
            this.maskObj = {
                mask: Number,
                scale: 2,
                signed: false,
                thousandsSeparator: '',
                padFractionalZeros: true,
                normalizeZeros: true,
                // eslint-disable-next-line spellcheck/spell-checker
                radix: '.',
                min: 0,
                max: 999.99,
            };
        }
    }

    get isRequired(): boolean {
        const control: AbstractControl = this.ngControl.control;
        if (control?.validator === null) return false;
        return control?.validator(control)?.required ?? false;
    }

    get hasError(): boolean {
        const control: AbstractControl = this.ngControl.control;
        const controlErrors: ValidationErrors = control.errors;

        const condition1 = control && (control.dirty || control.touched) && control.invalid;

        if (condition1) {
            if (controlErrors != null) {
                if (this.inputWithContent && this.contentInfo?.showErrorTooltip) {
                    this.showErrorTooltip = true;
                }
                Object.keys(controlErrors).forEach((keyError) => {
                    // console.log('Key control: ' + this.ngControl.name + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                    switch (keyError) {
                        case 'required':
                            this.errorMsg = `Field is required!`;
                            break;
                        case 'pattern':
                            this.errorMsg = `Field has wrong pattern!`;
                            break;
                        case 'email':
                            this.errorMsg = `Wrong email format!`;
                            break;
                        case 'minlength':
                            this.errorMsg = `Min length required is ${controlErrors[keyError].requiredLength}`;
                            break;
                        case 'maxlength':
                            this.errorMsg = `Max length required is ${controlErrors[keyError].requiredLength}`;
                            break;
                        case 'max':
                            this.errorMsg = `Max value required is ${controlErrors[keyError].max}`;
                            break;
                        case 'min':
                            this.errorMsg = `Min value required is ${controlErrors[keyError].min}`;
                            break;
                        case 'invalidTime':
                            this.errorMsg = `${controlErrors[keyError]}`;
                            break;
                        case 'roleExist':
                            this.errorMsg = 'A role with the same name already exists. Enter a different name.';
                            break;
                        case 'customMatch':
                            this.errorMsg = 'Passwords do not match';
                            break;
                        case 'incorrectPassword':
                            //colworx-ak: incorrectPassword
                            this.errorMsg = 'Incorrect Password';
                            break;
                        default:
                            this.errorMsg = `${keyError} value error`;
                    }
                });
            }
            return true;
        } else {
            this.showErrorTooltip = false;
        }

        // tooltip from Props instead of Form Controls
        if (this.customToolTip?.tooltip) {
            this.errorMsg = this.customToolTip.msg;
            return true;
        }

        return false;
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    onChange(event: any): void {
        this.value = event.target.value;
        if (this.inputWithContent && this.contentInfo.type === 'dropdown') {
            this.emitChangeWithContent();
        } else {
            if (this.type === 'number' && this.isFloat) {
                this.propagateChange(parseFloat(event.target.value));
                return;
            }
            this.propagateChange(event.target.value);
        }
    }

    onBlur(): void {
        this.ngControl.control.markAsTouched();
        this.changeHappened.emit();
    }

    emitChangeWithContent() {
        if (
            this.contentInfo.type === 'dropdown' &&
            this.contentInfo.options.includes('F') &&
            this.contentInfo.options.includes('C')
        ) {
            this.propagateChange(this.value + this.temperatureScale);
        } else if (this.contentInfo.type === 'button') {
            this.propagateChange(this.value);
        }
        this.changeHappened.emit();
    }

    emitChangeWithContentSelectedOption() {
        this.inputWithContentOptionChanged.emit(this.contentInfo.selectedOption);
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    //for time being
    validateTimeFormat(event: any) {
        const [hours, minutes] = event.target.value.split(':');
        if (+hours > 23) {
            event.target.value = `23:00`;
            event.preventDefault();
            event.stopPropagation();
        }
        // if (+minutes > 59 && +hours < 23) {
        //     event.target.value = `${hours + 1}:00`;
        // } else
        if (+minutes > 59) {
            event.target.value = `${hours}:59`;
        }
        const regexPattern = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
        if (regexPattern.test(event.target.value)) {
            //         // The entered value is in the correct format
            this.onChange(event);
        }
    }
}
