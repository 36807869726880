import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { schedulerFilters } from '@app/interfaces';

@Injectable({
    providedIn: 'root',
})
export class SchedulingFilterService {
    _filterApplied: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private schedulerPatientFilters$: BehaviorSubject<schedulerFilters> = new BehaviorSubject({
        mode: 'ALL',
        payor: 'ALL',
        search: '',
        type: 'ALL',
        therapyDiscipline: 'ALL',
    });
    set schedulerPatientFilters(val: Observable<schedulerFilters>) {
        val.subscribe((data) => {
            this.schedulerPatientFilters$.next(data);
        });
    }

    get schedulerPatientFilters(): Observable<schedulerFilters> {
        return this.schedulerPatientFilters$.asObservable();
    }

    get filterApplied(): any {
        return this._filterApplied.asObservable();
    }
    set filterApplied(value: any) {
        this._filterApplied.next(value);
    }
    resetFilters() {
        this.schedulerPatientFilters = of({
            mode: 'ALL',
            payor: 'ALL',
            search: '',
            type: 'ALL',
            therapyDiscipline: 'ALL',
        });
        this.filterApplied = false;
    }
}
