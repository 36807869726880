<div [class]="formGroupClass" [class.datepicker-error]="hasError" id="date-picker-wrapper">
    <div class="d-flex align-items-center" [ngClass]="{ 'calendar-class': showErrorState }">
        <label class="{{ labelClass }}" *ngIf="label" [ngClass]="showInfoToolTip ? 'd-inline-block' : 'd-block'"
            >{{ label | translate }} {{ isRequired ? '*' : '' }}</label
        >
        <span
            *ngIf="showInfoToolTip || (hasError && showErrorState)"
            class="material-icons-outlined info-icon"
            placement="right"
            [ngbTooltip]="infoToolTipMsg"
            style="margin-top: -6px; margin-left: 2px"
        >
            info
        </span>
    </div>

    <i *ngIf="editIconOnly" (click)="d.toggle()" class="material-icons-outlined edit-icon"> edit </i>

    <div
        [ngClass]="
            applyBackground
                ? (backgroundClass ? backgroundClass + ' ' + inputWrapperClass : inputWrapperClass) +
                  ' input-group rounded-curve'
                : ''
        "
        [class.disabled]="disabled"
        class="{{ disableDate }}"
    >
        <input
            [hidden]="calendarOnly"
            [class]="inputClass + (colorRed ? ' placeholder-yellow' : '')"
            [style]="inputStyle"
            [ngStyle]="{ cursor: disabled ? 'default' : 'pointer' }"
            readonly
            [container]="container"
            ngbDatepicker
            #d="ngbDatepicker"
            type="text"
            firstDayOfWeek="7"
            [(ngModel)]="model"
            [placeholder]="placeholder"
            [dayTemplate]="customDay"
            [value]="value"
            [minDate]="minDateLocal"
            [maxDate]="maxDateLocal"
            [markDisabled]="markDisabled"
            [disabled]="disabled"
            (dateSelect)="dateSelect($event)"
            (click)="d.toggle()"
            [class.ssn-check-input]="tooltip"
            [placement]="placement"
            (keydown)="handleKeyPress($event, d)"
            [positionTarget]="positionTarget"
            [ngClass]="{'disabled-background-color':disabled}"
        />
        <div
            *ngIf="!editIconOnly"
            class="input-group-append bg-transparent"
            [ngStyle]="{ cursor: disabled ? 'default' : 'pointer' }"
            (click)="disabled ? $event.preventDefault() : d.toggle()"
            [class.ssn-check-icon]="tooltip"
        >
            <span
                class="icon input-group-text border-0 bg-transparent text-secondary"
                [ngClass]="disabled ? 'text-gray-descriptor' : 'text-secondary'"
                ><i class="material-icons-outlined"> calendar_today </i>
            </span>
        </div>
    </div>
</div>

<ng-template
    #customDay
    let-date
    let-currentMonth="currentMonth"
    let-selected="selected"
    let-disabled="disabled"
    let-focused="focused"
>
    <span
        class="custom-day"
        [class.focused]="focused"
        [class.selected-day]="selected"
        [class.hidden]="date.month !== currentMonth"
        [class.text-muted]="disabled && !focused"
    >
        <!-- [ngClass]="
    daysToDisable.length &&
    (disabled && !focused ? 'dot-badge-before-primary' : disabled && focused ? 'dot-badge-before-gray-50' : '')
  " -->
        <span
            *ngIf="!hideDotBadge"
            style="margin: -1px 0px 0px -9px"
            class="position-absolute"
            [ngClass]="
                daysToDisable.length &&
                markDisabledEvent(date) &&
                (disabled && !focused
                    ? 'dot-badge-before-primary'
                    : disabled && focused
                    ? 'dot-badge-before-gray-50'
                    : '')
            "
        ></span>
        {{ date.day }}
    </span>
</ng-template>
