import { Pipe, PipeTransform } from '@angular/core';
import { differenceInDays } from 'date-fns';

@Pipe({
    name: 'differenceInDays',
    pure: true,
})
export class DifferenceInDaysPipe implements PipeTransform {
    transform(date1: Date, date2: Date): number {
        return differenceInDays(new Date(date1).setHours(0, 0, 0, 0), new Date(date2).setHours(0, 0, 0, 0));
    }
}
