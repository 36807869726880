import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ROUTER_UTILS } from '@app/helpers';
import { OrganizationManagementService, ToasterService } from '@app/services';

@Injectable({
  providedIn: 'root'
})
export class EomBillingGuard implements CanActivate {
  dashboardUrl: string = ROUTER_UTILS.config.base.root;
  constructor(private router: Router, private _organizationService: OrganizationManagementService,private toasterService : ToasterService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      const isBillingEnabled = this._organizationService._currentOrganization?.config?.enableTherapyInvoice;
      if (isBillingEnabled) {
          return true;
      }else{
        this.toasterService.show({
          title: 'Failure',
          body: 'Permission Denied',
          type: 'error',
      });
        this.router.navigate([this.dashboardUrl], { queryParams: { returnUrl: state.url } });
        false;
      }
    
     
  }
}
