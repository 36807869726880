import { Component, Input, OnInit } from '@angular/core';
import { ReportHeader } from '@app/interfaces';
import { DocumentationReportService } from '@app/services';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    @Input() type;
    @Input() toExport = false;
    @Input() isFirst = false;

    isDischarged: boolean;
    header: ReportHeader | null = null;
    mrn: string | null = null;

    constructor(private documentationService: DocumentationReportService) {}

    ngOnInit(): void {
        this.isDischarged = this.type === 'dischargeNote';
        this.header = this.documentationService[this.type].header;
        this.mrn = this.documentationService.MRN;
    }
}
