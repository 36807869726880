<div id="admission-history-cont" *ngIf="!!rowData.length">
    <div class="table-cont" [ngStyle]="{ height: this.gridHeight }">
        <div class="h-100">
            <app-data-grid
                [columnDefs]="columnDefs"
                [rowData]="rowData"
                [rowClass]="rowClass"
                [rowClassRules]="rowClassRules"
                [gridOptions]="gridOptions"
            ></app-data-grid>
        </div>
    </div>
    <div
        class="abs-center toggle-history-btn cursor-pointer medium-semi-light-bold"
        [ngClass]="toggleTitleClass"
        (click)="toggleList()"
    >
        <span>{{ listExpanded ? 'Hide' : 'Show' }} {{ toggleTitle }} History ({{ rowData.length }})</span>&nbsp;
        <span class="material-symbols-outlined"> {{ listExpanded ? 'expand_less' : 'expand_more' }} </span>
    </div>
</div>
