import { Pipe, PipeTransform } from '@angular/core';
import { shortTherapistType } from '@app/helpers';

@Pipe({
    name: 'disciplineShortForm',
})
export class DisciplineShortFormPipe implements PipeTransform {
    transform(value: string): unknown {
        return shortTherapistType(value)
    }
}