<div
    class="input-group parent flex-nowrap input-error"
    [ngClass]="{
        'input-error': input.value?.length > params?.maxLength && params.editMode,
        disabled: params.disabled,
        'opacity-low': params.lowOpacity
    }"
>
    <span
        [ngClass]="{ 'not-enabled': !params.enabled }"
        *ngIf="params.data.billable && !params.disableRate"
        class="currency"
        id="addon-wrapping"
        >{{ params?.preFix ? params?.preFix : '' }}</span
    >

    <input
        #input
        [placeholder]="params.placeholder"
        (keyup)="inputChanged($event)"
        [type]="params.type"
        [value]="params.value"
        class="form-control rate-input"
        [ngClass]="getInputClasses()"
        aria-label="Username"
        aria-describedby="addon-wrapping"
    />

    <span
        [ngClass]="{ 'not-enabled': !params.enabled, 'adjust-Width': params.adjustWidth }"
        *ngIf="params.data.billable && !params.showIcon && !params.disableRate"
        class="currency-unit"
        id="addon-wrapping"
        >{{ params?.postFix ? params?.postFix : '' }}</span
    >
    <span
        [ngClass]="{ 'not-enabled': !params.enabled, 'calendar-class': params.calendarClass }"
        *ngIf="params.showIcon"
        class="material-symbols-outlined"
        id="addon-wrapping"
        >{{ params.iconName }}</span
    >
</div>
