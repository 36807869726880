import { getDayName } from './date.utils';

export const getProgressBarType = (minutes: number) => {
    if (minutes <= 50) return 'secondary-400';
    else if (minutes > 50 && minutes <= 80) return 'warning-400';
    else if (minutes > 80) return 'danger-100';
};

export const getPatientHoursAssignmentValue = (therapist, currentDay?) => {
    let totalMinutes = 0;
    let plannedMinutes = 0;
    const frequencyWithTime: { available: boolean; time: number } =
        therapist.availability.days[currentDay.toLowerCase()];
    if (frequencyWithTime.available) totalMinutes = frequencyWithTime.time;
    therapist.patients.map((p) => {
        p.weekFrequency?.map((wf) => {
            if (wf.day.day && getDayName(new Date(wf.day.day).getDay()) === currentDay.toLowerCase())
                plannedMinutes += wf.day.individual.planned + wf.day.concurrent.planned + wf.day.group.planned;
        });
    });
    return { scheduledTime: plannedMinutes, assignmentValue: (plannedMinutes / totalMinutes) * 100 };
};

export const schedulerView = {
    week: 'weekView',
    day: 'dayView',
};

export const convertMinutesIntoHours = (minutes) => {
    return (minutes / 60).toFixed(1);
};
export const convertHoursIntoMinutes = (minutes) => {
    return (minutes * 60).toFixed(0);
};

export const getAvailabilityByDay = (date, therapistAvailability) => {
    let matchedAvailability = null;
    for (const availability of therapistAvailability) {
        if (availability.duration.effectiveFrom && availability.duration.effectiveThru) {
            if (
                new Date(date).setHours(0, 0, 0, 0) >=
                    new Date(availability.duration.effectiveFrom).setHours(0, 0, 0, 0) &&
                new Date(date).setHours(0, 0, 0, 0) <=
                    new Date(availability.duration.effectiveThru).setHours(0, 0, 0, 0)
            ) {
                matchedAvailability = availability;
            }
        } else if (availability.duration.effectiveFrom && !availability.duration.effectiveThru) {
            if (
                new Date(availability.duration.effectiveFrom).setHours(0, 0, 0, 0) <=
                new Date(date).setHours(0, 0, 0, 0)
            ) {
                matchedAvailability = availability;
            }
        }
    }
    return matchedAvailability;
};

export const findDayAvailability = (days: any, dayName: string) => {
    const day = dayName.toLowerCase();
    if (Object.prototype.hasOwnProperty.call(days, day)) {
        return days[day];
    } else {
        return null; // Day not found in the object
    }
};

export const caseLoadAssignedNotes = (therapistNotes) => {
    let notesLength = 0;
    (Object.keys(therapistNotes) as (keyof typeof therapistNotes)[]).forEach((key) => {
        if (key !== 'timeBlocks' && key !== 'splitInfo') {
            notesLength = notesLength + therapistNotes[key].length;
        }
    });
    return notesLength;
};

export const getNotesFromFacilityAdmission = (facilityAdmission, currentDiscipline) => {
    const notes = [];
    const therapyAdmission = facilityAdmission.therapyAdmissions.find((x) => x.therapyDiscipline == currentDiscipline);
    if (therapyAdmission?.documentation?.dailyNote) {
        for (const dailyNote of therapyAdmission.documentation.dailyNote) {
            notes.push({ docType: 'Daily Note', ...dailyNote });
        }
    }
    if (therapyAdmission?.documentation?.progressNote) {
        for (const progressNote of therapyAdmission.documentation.progressNote) {
            notes.push({ docType: 'Progress Note', ...progressNote });
        }
    }
    if (therapyAdmission?.documentation?.evaluation) {
        for (const evaluation of therapyAdmission.documentation.evaluation) {
            notes.push({ docType: 'Evaluation Note', ...evaluation });
        }
    }
    if (therapyAdmission?.documentation?.dischargeNote) {
        for (const dischargeNote of therapyAdmission.documentation.dischargeNote) {
            notes.push({ docType: 'Discharge Note', ...dischargeNote });
        }
    }
    if (therapyAdmission?.documentation?.recertificationNote) {
        for (const recertNote of therapyAdmission.documentation.recertificationNote) {
            notes.push({ docType: 'Recertification Note', ...recertNote });
        }
    }
    return notes;
};

export const therapistHasAvailability = (availability: any, selectedDate: Date) => {
    const daysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    const dayIndex = selectedDate.getDay(); // Get the day index from the date
    const dayName = daysOfWeek[dayIndex]; // Get the day name from the array

    // const availability = therapist.availability.find((availability) => !availability.duration.effectiveThru);
    const dayAvailability = findDayAvailability(availability.days, dayName);
    return dayAvailability;
};

export const calculateAvailableCareTime = (therapistData: any): any => {
    let availableCareTime = 0;
    for (const therapist of therapistData) {
        //availableCareTime = availableCareTime + +convertMinutesIntoHours(therapist.availableTx);
        availableCareTime = availableCareTime + therapist.availableTx;
    }
    return (availableCareTime / 60).toFixed(1);
};
