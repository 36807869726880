<app-base-modal-card heading="Goal Marking" (close)="onClose()">
        <div body class="mt-4 text-center">
            <img src="assets/icons/goals-delete.svg" />
            <h5 class="h5-bold">{{ data.heading }}</h5>
            <p class="body-medium-semibold">{{ data.message }}</p>
            <!-- <app-checkbox [options]="options" [(ngModel)]="deleteOption"></app-checkbox> -->
        </div>
    <div footer class="d-flex justify-content-center">
        <button class="innova-btn innova-btn-sm btn-secondary" (click)="onDone()">Yes, Sure</button>
        <button class="ml-1 innova-btn innova-btn-sm btn-danger" (click)="onClose()">Cancel</button>
    </div>
</app-base-modal-card>
