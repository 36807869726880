export interface Patient {
    _id?: string;
    id?: string;
    integrationEntity: {
        name: string;
        patientId: string;
    };
    firstName: string;
    middleName: string;
    lastName: string;
    gender: string;
    SSN: string;
    DOB: string;
    isAnIntegratedPatient: boolean;
    // medicareNo: string;
    // medicaidNo: string;
    // medicareIdentifier: string;
    filesAndDocuments: [
        {
            name: string;
            date: string;
            facilityAdmission: string;
            therapyAdmission: string;
            type: string;
            description: string;
            files: string[];
        }
    ];
}

// export interface FilesAndDocuments {
//   date: string;
//   facilityAdmission: string;
//   therapyAdmission: string;
//   type: string;
//   description: string;
//   files: string[];
// }

export interface patientFilters {
    therapyDiscipline: string;
    active: boolean | string;
    payor: string;
    search: string;
    patientType: 'Inpatient' | 'Outpatient' | 'ALL' | '';
    therapyStatus: 'Open' | 'Closed' | 'ALL';
}
export interface patientMenuEvents {
    print: boolean;
    export: boolean
}
export interface plannerFilters {
    therapyDiscipline: string;
    mode: string;
    payor: string;
    search: string;
}
export interface schedulerFilters {
    mode: string;
    payor: string;
    search: string;
    type: string;
    therapyDiscipline: string;
    isFilterApplied?: boolean;
}

export const certificationStatus = {
    EXPIRED: 'Expired',
    EXPIRING: 'Expiring',
};
