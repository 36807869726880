<div [@openClose]="state" class="right-bar text-secondary body-small">
    <div class="d-flex flex-row justify-content-between pl-0 pr-0">
        <h6 class="h6 h6-semibold">{{ therapist.name }}</h6>
        <span class="material-icons-round text-primary-light cursor-pointer" (click)="close()">close</span>
    </div>

    <div class="d-flex flex-row col-12 pl-0 pr-0 body-medium">
        <span> Therapist | </span> <span>&nbsp; {{ data.date | date: 'EEE, MM/dd/yy' }} |&nbsp;</span
        ><span class="material-icons-outlined cursor-pointer" (click)="initiatePrinting()"> print </span>
    </div>
    <div class="mt-4"></div>

    <p class="col-12 pl-0 pr-0">
        <ngb-progressbar
            [type]="getType(getValue(therapist).assignmentValue)"
            [value]="getValue(therapist).assignmentValue"
        ></ngb-progressbar>
    </p>
    <div class="row mt-4">
        <div class="col-4">
            <p class="body-small body-small-bold text-secondary mb-1">Total</p>
            <p class="body-small text-secondary-300 mb-0">{{ totalTime }} mins</p>
        </div>
        <div class="col-4">
            <p class="body-small body-small-bold text-secondary mb-1">Scheduled</p>
            <p class="body-small text-secondary-300 mb-0">{{ scheduledTime }} mins</p>
        </div>
        <div class="col-4">
            <p class="body-small body-small-bold text-secondary mb-1">Remaining</p>
            <p class="body-small text-secondary-300 mb-0" *ngVar="totalTime - scheduledTime as remaining">
                {{ remaining < 0 ? 'overBooked' : remaining + ' mins' }}
            </p>
        </div>
    </div>
    <!-- <app-toggle [buttons]="buttons"></app-toggle> -->

    <div
        *ngIf="therapist?.patients?.length > 0; else noPatientsAssigned"
        class="d-flex flex-row col-12 pl-0 pr-0 justify-content-between pt-4"
        style="margin-left: -4px"
    >
        <ng-container *ngIf="!(data.date | isPastDate)">
            <div
                class="d-flex cursor-pointer"
                (click)="selectedPatients === 0 ? selectAllPatients() : cancelPatientSelection()"
            >
                <div
                    class="material-icons-outlined"
                    [ngStyle]="{ color: selectedPatients > 0 ? '#69a981' : '#e8e8e7' }"
                >
                    check_box
                </div>
                <div class="body-small text-secondary cursor-pointer align-self-center">
                    {{ selectedPatients === 0 ? 'Select All' : 'Unselect' }}
                </div>
            </div>
        </ng-container>
        <span class="body-small body-small-bold text-secondary">Patients: {{ therapist.patients.length }}</span>
    </div>

    <ng-template #noPatientsAssigned>
        <div class="mt-5 text-center">
            <span class="body-small text-secondary"> No Patients Assigned</span>
        </div>
    </ng-template>

    <!-- <div class="row mt-4">
    <div class="col-4">
      <p class="body-small body-small-bold text-secondary mb-1">Total</p>
      <p class="body-small text-secondary-300 mb-0">{{ totalTime | minutesToHoursMinutes }}</p>
    </div>
    <div class="col-4">
      <p class="body-small body-small-bold text-secondary mb-1">Scheduled</p>
      <p class="body-small text-secondary-300 mb-0">{{ scheduledTime | minutesToHoursMinutes }}</p>
    </div>
    <div class="col-4">
      <p class="body-small body-small-bold text-secondary mb-1">Remaining</p>
      <p class="body-small text-secondary-300 mb-0">{{ totalTime - scheduledTime | minutesToHoursMinutes }}</p>
    </div>
  </div> -->
    <div class="mt-4 right-bar-patients" style="max-height: calc(100vh - 400px); overflow-y: auto">
        <div
            *ngFor="let patient of therapist.patients"
            [class.cursor-pointer]="!(data.date | isPastDate)"
            class="mt-2"
            [@removePatient]
            (click)="selectPatientCard(patient)"
        >
            <app-scheduler-right-bar-card [patient]="patient" [date]="data.date"></app-scheduler-right-bar-card>
            <hr />
        </div>
    </div>
    <div
        *ngIf="!(data.date | isPastDate) && therapist?.patients?.length > 0"
        style="margin-bottom: 2%; position: absolute; bottom: 0"
    >
        <div *ngVar="getSelectedPatients() as count" class="d-flex flex-row col-12 pl-0 pr-0 justify-content-between">
            <button class="innova-btn innova-btn-sm btn-primary" (click)="unAssignSelectedPatients(false, count)">
                <span class="body-small">Unassign: {{ count }}</span>
            </button>
            <button class="innova-btn ml-4 innova-btn-sm btn-secondary" (click)="unAssignSelectedPatients(true, count)">
                <span class="body-small">Auto Re-Schedule: {{ count }}</span>
            </button>
        </div>
    </div>
    <div class="mt-5 text-center" *ngIf="(data.date | isPastDate) && therapist?.patients?.length > 0">
        <span class="body-small text-secondary"> Past Days cannot be un-assigned</span>
    </div>
</div>

<!-- <app-therapist-case-load #caseLoad></app-therapist-case-load> -->
