export function documents(
    type: string,
    docType: string,
    therapist: string,
    isAssignedTherapist: boolean,
    discipline: string,
    dueDate: string,
    completionDate: string,
    status: Array<string>,
    id: string
) {
    this.type = type;
    this.docType = docType;
    this.therapist = therapist;
    this.discipline = discipline;
    this.dueDate = dueDate;
    this.completionDate = completionDate;
    this.status = status;
    this.id = id;
    this.isAssignedTherapist = isAssignedTherapist;
}
