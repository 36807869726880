import { Component, Inject, OnInit } from '@angular/core';
import { DIALOG_DATA } from '@app/helpers/dialog-tokens';
import { DialogRef } from '@app/services';
import { Options } from '../checkbox/checkbox.component';
import { AuthService } from '@app/services';

@Component({
  selector: 'app-document-sign-dialog',
  templateUrl: './document-sign-dialog.component.html',
  styleUrls: ['./document-sign-dialog.component.scss']
})
export class DocumentSignDialogComponent implements OnInit {
  account = this.AuthService.accountValue;


  content: any;
  showAgain: [];
  // dateForm: FormGroup;
  isOpen = true; // Track if modal is open

  constructor(@Inject(DIALOG_DATA) private data, private dialogRef: DialogRef, private AuthService: AuthService) {
    this.content = data;

  }
  ngOnInit(): void {
    // throw new Error('Method not implemented.');
  }

  confirmAction(isConfirmed) {
    this.isOpen = false; 
    setTimeout(() => {
      this.dialogRef.close(isConfirmed);
    }, 500); 

  }

  closeAction(close) {
    this.isOpen = false; 
    setTimeout(() => {
      this.dialogRef.close(close);
    }, 500); 

  }

  isCheck = false;
  onCheckboxChange({ isChecked }: Options) {
    this.isCheck = isChecked;
    // this.dialogRef.emitAction('showAgain', isChecked);
  }

}
