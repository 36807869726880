export const findTherapyCaseByDisciplineNParamId = (admissions, discipline, therapyId) => {
    if(!admissions) return;

    return admissions.find(admission => admission._id === therapyId && admission.therapyDiscipline === discipline);
}

export const findTherapyCaseByParamId = (admissions, therapyId) => {
    if(!admissions || !therapyId) return;

    return admissions.find(admission => admission._id === therapyId);
}