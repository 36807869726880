import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LegendGoal } from '@app/interfaces';

@Component({
    selector: 'app-legend-goal',
    templateUrl: './legend-goal.component.html',
    styleUrls: ['./legend-goal.component.scss'],
})
export class LegendGoalComponent implements OnInit {
    @Input() goal: LegendGoal;
    @Input() class = '';
    @Output() clickEvent: EventEmitter<string> = new EventEmitter<string>();
    constructor() {
        //
    }

    ngOnInit(): void {
        //
    }
}
