export enum THERAPIST_TYPE {
    PT = 'PT',
    PT_STUDENT = 'Student PT',
    PTA = 'PTA',
    PTA_STUDENT = 'Student PTA',
    OT = 'OT',
    OT_STUDENT = 'Student OT',
    OTA = 'OTA',
    OTA_STUDENT = 'Student OTA',
    ST = 'ST',
    ST_STUDENT = 'Student ST',
}

export const ALL_THERAPIST_TYPES = ['PT', 'SPT', 'PTA', 'SPTA', 'OT', 'SOT', 'OTA', 'SOTA', 'ST', 'SST'];
