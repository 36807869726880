import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';

@Component({
    selector: 'app-colored-cell-renderer',
    templateUrl: './colored-cell-renderer.component.html',
    styleUrls: ['./colored-cell-renderer.component.scss'],
})
export class ColoredCellRendererComponent {
    private _params: any;
    public text: string;
    public textColor: string;
    public colors = {
        error: '#AC1616',
        warning: '#E1AA00',
        disabled: '#D1D1D0',
    };

    agInit(params: ICellRendererParams): void {
        this._params = params;
        this.text = this._params.value;
        this.textColor = this.text && this.text !== '0' ? this.colors[this._params.color] : this.colors.disabled;
    }

    refresh(): boolean {
        return false;
    }
}
