import { Component, Inject } from '@angular/core';
import { DIALOG_DATA } from '@app/helpers/dialog-tokens';
import { DialogRef, StandardizedTestsService } from '@app/services';

@Component({
    selector: 'app-custom-test-modal',
    templateUrl: './custom-test-modal.component.html',
    styleUrls: ['./custom-test-modal.component.scss'],
})
export class CustomTestModalComponent {
    toUpdate: boolean;
    heading: string;
    previousHeading: string;
    loader: boolean;
    score: string;
    interpretation: string;
    notes: string;
    isEditMode: any;

    constructor(
        @Inject(DIALOG_DATA) private data,
        private ref: DialogRef,
        private standardizedTestsService: StandardizedTestsService
    ) {
        this.toUpdate = this.data?.toUpdate ?? false;
        this.heading = this.data?.heading ?? '';
        this.previousHeading = this.data?.heading ?? '';
        this.score = this.data?.score ?? '';
        this.notes = this.data?.notes ?? '';
        this.interpretation = this.data?.interpretation ?? '';
        this.isEditMode = this.data?.isEditMode ?? '';
    }

    saveCustomTest() {
        const testStoreData = {
            name: `${this.heading} (Custom)`,
            score: this.score,
            interpretation: this.interpretation,
            isCompleted: true,
            form: this.score,
            note: this.notes,
        };
        if (this.toUpdate) {
            this.standardizedTestsService.saveStandardTestsData(testStoreData, this.previousHeading, this.isEditMode);
        } else {
            this.standardizedTestsService.saveStandardTestsData(testStoreData, '', this.isEditMode);
        }

        this.ref.close({
            title: this.heading,
            score: this.score,
            notes: this.notes,
            interpretation: this.interpretation,
        });
    }

    headerUpdate(title: string) {
        this.heading = title;
    }

    onClose() {
        this.ref.close();
    }
}
