import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-empty-cell-renderer',
    templateUrl: './empty-cell-renderer.component.html',
    styleUrls: ['./empty-cell-renderer.component.scss'],
})
export class EmptyCellRendererComponent implements ICellRendererAngularComp {
    params;
    agInit(params: ICellRendererParams): void {
        this.params = params;
    }
    refresh(): boolean {
        return;
    }
}
