import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
// import { Physician } from '@app/models';
import { Physician } from '@app/interfaces';
import { OrganizationManagementService } from './organizationManagement.service';
import { FacilityManagementService } from './facilityManagement.service';
// import { PhysicianChangedAction } from '@app/store';
// import { PhysicianState } from '@app/store/store.interface';
// import { PhysicianRemovedAction } from '@app/store/actions/index';

const baseUrl = `${environment.apiUrl}/physician`;

@Injectable({ providedIn: 'root' })
export class PhysicianService {
    //   private PhysicianSubject: BehaviorSubject<Physician>;
    //   public _Physician: Observable<Physician>;
    tab$: Observable<any>;
    constructor(
        private router: Router,
        private http: HttpClient, // , private store: Store<{ Physician: PhysicianState }>
        private organizationManagementService: OrganizationManagementService,
        private facilityManagementService: FacilityManagementService
    ) {
        // this.PhysicianSubject = new BehaviorSubject<Physician>(null);
        // this._Physician = this.PhysicianSubject.asObservable();
        // this.tab$ = store.pipe(select('Physician'));
    }

    getPhysicianByOrganization() {
        const { id } = this.organizationManagementService._currentOrganization;
        const facility = this.facilityManagementService._currentFacility._id
        return this.http.get<any>(`${baseUrl}/physician/organization/${id}/${facility}`, { withCredentials: true }).pipe(
            map((Physician) => {
                return Physician.data;
            })
        );
    }
    //   PhysicianRemovedService() {
    //     return this.store.dispatch(PhysicianRemovedAction());
    //   }

    getPhysician(id: string): Observable<Physician> {
        return this.http.get<any>(`${baseUrl}/${id}`).pipe(map((v) => v.data));
    }
}
