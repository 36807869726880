import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthService } from '../../services';

@Component({
    selector: 'app-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.scss'],
})
export class HistoryComponent {
    @Input() optionToShow: Array<any>;
    @Input() isClass = false;
    @Input() header: string;
    @Input() isCollapsed = false;
    @Output() closed = new EventEmitter();
    showBackground: boolean;// colworx-ak
    
    constructor(
        private AuthService: AuthService,// colworx-ak
      
    ) {
        this.showBackground = this.AuthService.isPhysician || this.AuthService.isPhysicianStaff// colworx-ak
    }
}
