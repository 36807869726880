import { Component, OnInit } from '@angular/core';
import { ToastRef, ToastData } from '@app/helpers/toaster';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'app-toaster',
    templateUrl: './toaster.component.html',
    styleUrls: ['./toaster.component.scss'],
    animations: [
        trigger('openClose', [
            state(
                'open',
                style({
                    opacity: 1,
                })
            ),
            state(
                'closed',
                style({
                    opacity: 1,
                })
            ),
            transition('open => closed', [animate('300ms', style({ transform: 'translateY(100%)' }))]),
            transition('* => open', [style({ opacity: 1, transform: 'translateY(50px)' }), animate('300ms')]),
        ]),
    ],
})
export class ToasterComponent implements OnInit {
    state = 'open';
    constructor(readonly data: ToastData, readonly ref: ToastRef) {}

    ngOnInit(): void {
        setTimeout(() => {
            this.close();
        }, 5000);
    }

    close() {
        this.state = 'closed';
        setTimeout(() => {
            this.ref.close();
        }, 250);
    }
}
