import { Component, Inject, OnInit } from '@angular/core';
import { DIALOG_DATA } from '@app/helpers/dialog-tokens';
import { FacilityManagementService } from '@app/services';
import { DialogRef } from '@app/services/modal.service';
import { ColDef, ColumnApi, GridApi } from 'ag-grid-community';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-facilities-pop-up',
    templateUrl: './facilities-pop-up.component.html',
    styleUrls: ['./facilities-pop-up.component.scss'],
})
export class FacilitiesPopUpComponent implements OnInit {
    searchString = '';
    gridApi: GridApi;
    gridColumnApi: ColumnApi;
    allFacilities = [];
    facilities = [];
    selectedFacilities = [];
    selectedTab = 'active'
    onDestroy$: Subject<boolean> = new Subject();
    facilityColumns: ColDef[] = [
        {
            field: 'facilityName',
            headerName: 'Facility',
            cellStyle: { 'text-align': 'left' },
            sortable: true,
            sort: 'asc',
            headerCheckboxSelection: true,
            checkboxSelection: true,
        },
        {
            field: 'facilityType',
            headerName: 'Facility Type',
            sortable: true,
            sort: 'asc',
        },
        {
            field: 'facilityNPI.billingNPI',
            headerName: 'NPI',
            sortable: true,
            sort: 'asc',
        },
    ];
    heading = 'Select Facility';

    constructor(
        @Inject(DIALOG_DATA) private data,
        private ref: DialogRef,
        private facilityManagement: FacilityManagementService
    ) {
        this.heading = this.data.heading;
    }

    onClose() {
        this.ref.close(false);
    }
    ngOnInit(): void {
        this.selectedTab = this.data.selectedTab ? this.data.selectedTab : this.selectedTab;
        this.facilityManagement._Facilities.pipe(takeUntil(this.onDestroy$)).subscribe((facilities) => {
            facilities = facilities.filter((f)=> f.status?.toLowerCase() === this.selectedTab)
            this.allFacilities = facilities;
            this.facilities = facilities;
            this.rowSelection(this.data.selectedFacilities);
        });
    }

    rowSelection(event) {
        this.selectedFacilities = event;
    }

    initGrid(params: any) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.selectRowsBasedOnSelection();
    }

    ngOnDestroy() {
        this.onDestroy$.next(true);
    }

    selectFacilities() {
        this.ref.close(this.selectedFacilities);
    }

    searchFacilities(event) {
        if (typeof event === 'string') {
            const searchString = event?.toLowerCase();
            if (!searchString?.length) {
                this.facilities = this.allFacilities;
            } else {
                this.facilities = this.allFacilities?.filter((facility) => {
                    return (
                        facility?.facilityName.toLowerCase().includes(searchString) ||
                        facility?.facilityType?.toLowerCase()?.includes(searchString)
                    );
                });
            }
        } else {
            this.facilities = this.allFacilities;
        }
    }

    selectRowsBasedOnSelection() {
        if (this.gridApi && this.selectedFacilities.length) {
            const selectedIds = this.selectedFacilities.map((facility) => facility._id ? facility._id : facility.id );
            this.gridApi.forEachNode((node) => {
                if (selectedIds.includes(node.data._id ? node.data._id : node.data.id)) {
                    node.setSelected(true);
                }
            });
        }
    }
}