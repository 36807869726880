import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PrintLayoutRoutingModule } from './print-layout-routing.module';
import { PrintLayoutComponent } from './print-layout.component';
import { TherapistCaseLoadComponent } from './therapist-case-load/therapist-case-load.component';
import { DataGridModule } from '@app/data-grid/data-grid.module';
import { ViewModeDocLayoutComponent } from './view-mode-doc-layout/view-mode-doc-layout.component';
import { SharedModule } from '../shared.module';
import { ViewModeModule } from '@app/modules/documentation/view-mode/view-mode.module';
import { DocumentationComponent } from './documentation/documentation.component';
import { ParagraphComponent } from './documentation/components/paragraph/paragraph.component';
import { TreatmentComponent } from './documentation/components/treatment/treatment.component';
import { HeaderComponent } from './documentation/components/header/header.component';
import { DailyNotesReportComponent } from './documentation/layouts/daily-notes-report/daily-notes-report.component';
import { GridLayoutComponent } from './documentation/components/grid-layout/grid-layout.component';
import { ProgressNoteReportComponent } from './documentation/layouts/progress-note-report/progress-note-report.component';
import { RecertificationReportComponent } from './documentation/layouts/recertification-report/recertification-report.component';
import { UpocReportComponent } from './documentation/layouts/upoc-report/upoc-report.component';
import { EvaluationReportComponent } from './documentation/layouts/evaluation-report/evaluation-report.component';
import { DischargeReportComponent } from './documentation/layouts/discharge-report/discharge-report.component';
import { SignatureComponent } from './documentation/components/signature/signature.component';
import { AddendumReportComponent } from './documentation/layouts/addendum-report/addendum-report.component';
import { WhiteboardPrintComponent } from './whiteboard-print/whiteboard-print.component';

@NgModule({
    declarations: [
        PrintLayoutComponent,
        TherapistCaseLoadComponent,
        WhiteboardPrintComponent,
        ViewModeDocLayoutComponent,
        DocumentationComponent,
        ParagraphComponent,
        TreatmentComponent,
        HeaderComponent,
        DailyNotesReportComponent,
        GridLayoutComponent,
        ProgressNoteReportComponent,
        RecertificationReportComponent,
        UpocReportComponent,
        EvaluationReportComponent,
        DischargeReportComponent,
        SignatureComponent,
        AddendumReportComponent,
    ],
    imports: [CommonModule, PrintLayoutRoutingModule, DataGridModule, SharedModule, ViewModeModule],
    exports: [TherapistCaseLoadComponent],
})
export class PrintLayoutModule {}
