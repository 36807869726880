import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridRowAction } from '@app/data-grid/interfaces/grid-row-action';
import { Goal, TherapyAdmission } from '@app/interfaces';
import {
    DOCUMENT_CONSTANTS,
    Doc_Notes,
    GOAL_CONSTANTS,
    MODAL_HEADINGS,
    MODAL_MESSAGES,
} from '@app/interfaces/documentation';
import { AssessmentDeletionModalComponent } from '@app/modules/documentation/patient-assessment/modals/assessment-deletion-modal/assessment-deletion-modal.component';
import { AssessmentGoalsDeletionModalComponent } from '@app/modules/documentation/patient-assessment/modals/assessment-goals-deletion-modal/assessment-goals-deletion-modal.component';
import { Subject } from 'rxjs';
import { DocumentationService } from './documentation.service';
import { ModalService } from './modal.service';

@Injectable({
    providedIn: 'root',
})
export class PatientAssessmentService {
    $addImpairment = new Subject();
    $addFunctionalDeficit = new Subject();
    $addStandardizedTest = new Subject();
    _currentNote: string;
    _currentNoteId: string;
    selectedNoteIndex;

    latestPocType: string;
    latestPocIndex: number;

    constructor(
        private route: ActivatedRoute,
        private documentationService: DocumentationService,
        private modalService: ModalService,
        private router: Router
    ) {
        route.queryParamMap.subscribe((param) => {
            this._currentNote = param.get('note') || param.get('doc_type');
            this._currentNoteId = param.get('doc');
        });
    }

    // Dynamic assessment items path for different notes
    getAssessmentPath(currentAdmission, assessmentType: string): any[] {
        if (this._currentNote === 'Recertification') {
            this.selectedNoteIndex = currentAdmission.documentation[this.getMappedNoteName()].findIndex(
                (x: any) => x.id == this._currentNoteId
            );
        }
        switch (this._currentNote) {
            case 'Evaluation': {
                return currentAdmission?.documentation?.evaluation?.patientAssessment[assessmentType];
            }
            case 'Updated-Plan-of-Care': {
                const currentUPOC = currentAdmission?.documentation?.updatedPlanOfCareNote?.find(
                    (upoc) => upoc && upoc.id === this._currentNoteId
                );
                const result = currentUPOC.objectiveAssessment[assessmentType];

                return result;
            }
            case 'Recertification': {
                return currentAdmission.documentation.recertificationNote[this.selectedNoteIndex].objectiveAssessment[
                    assessmentType
                ];
            }
            case 'Progress-Note': {
                // In case of progress note assessment path is returned for latest binded poc
                const evaluation = currentAdmission.documentation.evaluation;
                evaluation.type = 'evaluation';

                const upoc = currentAdmission.documentation.updatedPlanOfCareNote
                    .filter((x) => x.status === 'Completed')
                    .sort((a, b) => {
                        return new Date(b.day).getTime() - new Date(a.day).getTime();
                    })
                    .shift();
                if (upoc) upoc.type = 'updatedPlanOfCareNote';

                const recertification = currentAdmission.documentation.recertificationNote
                    .filter((x) => x.status === 'Completed')
                    .sort((a, b) => {
                        return new Date(b.day).getTime() - new Date(a.day).getTime();
                    })
                    .shift();
                if (recertification) recertification.type = 'recertificationNote';

                const combinedPOCs = [...[evaluation], ...[upoc], ...[recertification]]
                    .sort((a, b) => {
                        return new Date(b.signed.signDate).getTime() - new Date(a.signed.signDate).getTime();
                    })
                    .shift();

                if (combinedPOCs.type === 'evaluation') {
                    this.latestPocType = 'evaluation';
                    return combinedPOCs.patientAssessment[assessmentType];
                } else {
                    this.latestPocType = combinedPOCs.type;
                    this.latestPocIndex = currentAdmission.documentation[this.latestPocType].findIndex(
                        (x) => x.id === combinedPOCs.id
                    );
                    return combinedPOCs.objectiveAssessment[assessmentType];
                }
            }
        }
    }

    // Dynamic goals path for different notes
    getGoalsPath(currentAdmission: TherapyAdmission) {
        switch (this._currentNote) {
            case 'Evaluation': {
                return currentAdmission.documentation.evaluation.planOfCare.goals;
            }
            case 'Updated-Plan-of-Care': {
                const currentUpoc = currentAdmission?.documentation?.updatedPlanOfCareNote?.find(
                    (upoc) => upoc && upoc.id === this._currentNoteId
                );
                const result = currentUpoc.plan.goals;

                return result;
            }
            case 'Recertification': {
                const currentRecertification = currentAdmission?.documentation?.recertificationNote?.find(
                    (recertification) => recertification && recertification.id === this._currentNoteId
                );
                return currentRecertification.plan.goals;
            }
        }
    }

    getMappedNoteName() {
        switch (this._currentNote) {
            case 'Evaluation':
                return Doc_Notes.EVALUATION;
            case 'Updated-Plan-of-Care':
                return Doc_Notes.UPOC;
            case 'Recertification':
                return Doc_Notes.Re_Cert;
        }
    }

    // This function would update the clof of the goal associated with the assessment item and wipe of its target
    reflectChangesInGoal(currentAdmission, assessmentId: string, clof): void {
        let found;
        let searching = true;
        currentAdmission.documentation.evaluation.planOfCare.goals.forEach((goal) => {
            if (searching && goal.scale.assessmentId === assessmentId) {
                found = goal;
                searching = false;
            } else if (searching && goal.stgs.length > 0) {
                goal.stgs.forEach((stg) => {
                    if (searching && stg.scale.assessmentId === assessmentId) {
                        found = stg;
                        searching = false;
                    }
                });
            }
        });
        if (found) {
            found.clof = clof;
            found.target = null;
        }
    }

    updateDocBasedOnNote(currentAdmission: TherapyAdmission, note: string): void {
        switch (note) {
            case 'Updated-Plan-of-Care': {
                this.documentationService.updateDocumentation(currentAdmission, Doc_Notes.UPOC);
                break;
            }
            case 'Evaluation': {
                this.documentationService.updateDocumentation(currentAdmission);
                break;
            }
            case 'Recertification': {
                this.documentationService.updateDocumentation(currentAdmission, Doc_Notes.Re_Cert);
                break;
            }
        }
    }
    // This function will make a goal out of the selected assessment item
    markAssessmentItemAsAGoal(currentAdmission, rowData: GridRowAction) {
        if (rowData.gridName === 'impairments') {
            this.makeAGoal(
                currentAdmission,
                rowData.data,
                `${rowData.data.impairmentName ?? ''} ${rowData.data.subSection ?? ''} ${
                    rowData.data.fieldName ?? ''
                }`.trim(),
                rowData
            );
        } else {
            this.makeAGoal(currentAdmission, rowData.data, rowData.data.name, rowData);
        }
    }

    // Extension of the above function
    makeAGoal(currentAdmission, item: any, goalName: string, rowData: GridRowAction) {
        const goal: Goal = this.mapAssessmentItemToAGoal(item, goalName);
        if (!item.isAGoal) {
            if (this._currentNote === DOCUMENT_CONSTANTS.EVALUATION) {
                this.unmarkAssessmentItemAsAGoal(currentAdmission, rowData);
            } else {
                this.discontinueGoalAssociatedWithAssessmentItemExtended(currentAdmission, rowData, {
                    discontinueStgs: rowData.data.discontinueStgs,
                    reasonForDiscontinuing: rowData.data.reasonForDiscontinuing,
                });
            }
        } else {
            this.getGoalsPath(currentAdmission).push(goal);
        }
    }

    // Map assessment item model data to goal model data
    mapAssessmentItemToAGoal(item: any, goalName: string): Goal {
        item.scale.assessmentId = item._id;
        return {
            goal: goalName,
            plof: item.plof,
            clof: this.mapCLOF(item.clof),
            targetDate: null,
            updatedTargetDate: null,
            stgs: [] as any,
            target: null,
            goalPurpose: null,
            assistiveDevice: null,
            scale: item.scale,
            status: { description: 'On Going', reason: '' },
        };
    }

    // Map stg item model data to ltg goal model data
    mapStgGoalItemToAGoal(item: Goal, goalName: string): Goal {
        return {
            goal: goalName,
            plof: item.plof,
            clof: item.clof,
            targetDate: item.targetDate,
            stgs: [] as any,
            target: item.target,
            updatedTargetDate: item.updatedTargetDate,
            goalPurpose: item.goalPurpose,
            assistiveDevice: item.assistiveDevice,
            scale: item.scale,
            status: item.status,
        };
    }

    // Would open edit flows for deficits and tests
    editDeficitsAssessmentItem(rowData: GridRowAction) {
        this.router.navigate(['documentation/patient-assessment/functional-deficits'], {
            state: { data: rowData.data },
            queryParamsHandling: 'preserve',
        });
    }

    // Delete any goal associated with the assessment item
    deleteGoalAssociatedWithAssessmentItem(currentAdmission: TherapyAdmission, rowData: GridRowAction) {
        this.modalService
            .open(AssessmentDeletionModalComponent, {
                data: {
                    heading: 'Delete Assessment',
                    message: MODAL_MESSAGES.DELETE_ASSESSMENT_ITEM_GOALS,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    let goalToDel;
                    this.getGoalsPath(currentAdmission).forEach((goal, index) => {
                        const found = goal.scale.assessmentId === rowData.data._id;
                        if (found) goalToDel = index;
                        if (found && goal.stgs.length > 0) {
                            goal.stgs.forEach((stg) => {
                                this.getGoalsPath(currentAdmission).push(this.mapAssessmentItemToAGoal(stg, stg.goal));
                            });
                        } else if (!found && goal.stgs.length > 0) {
                            let stgToDel;
                            goal.stgs.forEach((stg, index) => {
                                const found = stg.scale.assessmentId === rowData.data._id;
                                if (found) stgToDel = index;
                            });
                            if (stgToDel !== undefined) goal.stgs.splice(stgToDel, 1);
                        }
                    });
                    if (goalToDel !== undefined) this.getGoalsPath(currentAdmission).splice(goalToDel, 1);
                    this.deleteAssessmentItem(currentAdmission, rowData);
                }
            });
    }

    // Delete assessment item
    deleteAssessmentItem(currentAdmission: TherapyAdmission, item: GridRowAction) {
        const deleteFrom =
            item.gridName === 'standardizedTests'
                ? 'standardizedTests'
                : item.gridName === 'deficits'
                ? 'functionalDeficits'
                : 'impairments';
        const found = this.getAssessmentPath(currentAdmission, deleteFrom).findIndex((x) => x._id === item.data._id);
        if (found !== -1) this.getAssessmentPath(currentAdmission, deleteFrom).splice(found, 1);
        this.updateDocBasedOnNote(currentAdmission, this._currentNote);
    }

    // Unmark assessment item and delete goal associated with it
    unmarkAssessmentItemAsAGoal(currentAdmission: TherapyAdmission, rowData: GridRowAction) {
        let goalToDel;
        this.getGoalsPath(currentAdmission).forEach((goal, index) => {
            const found = goal.scale.assessmentId === rowData.data._id;
            if (found) goalToDel = index;
            if (found && goal.stgs.length > 0) {
                goal.stgs.forEach((stg) => {
                    this.getGoalsPath(currentAdmission).push(this.mapStgGoalItemToAGoal(stg, stg.goal));
                });
            } else if (!found && goal.stgs.length > 0) {
                let stgToDel;
                goal.stgs.forEach((stg, index) => {
                    const found = stg.scale.assessmentId === rowData.data._id;
                    if (found) stgToDel = index;
                });
                if (stgToDel !== undefined) goal.stgs.splice(stgToDel, 1);
            }
        });
        if (goalToDel !== undefined) this.getGoalsPath(currentAdmission).splice(goalToDel, 1);
    }

    // This function would discontinue the goals related to an assessment item. Upoc/Recert usecase.
    discontinueGoalAssociatedWithAssessmentItem(currentAdmission: TherapyAdmission, rowData: GridRowAction) {
        this.modalService
            .open(AssessmentGoalsDeletionModalComponent, {
                data: {
                    heading: MODAL_HEADINGS.DELETE_ASSESSMENT_ITEM,
                    message: MODAL_MESSAGES.DELETE_ASSESSMENT_ITEM_GOALS,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.discontinueGoalAssociatedWithAssessmentItemExtended(currentAdmission, rowData, result);
                    this.deleteAssessmentItem(currentAdmission, rowData);
                }
            });
    }

    // Extension of above function can be used independently as well
    discontinueGoalAssociatedWithAssessmentItemExtended(
        currentAdmission: TherapyAdmission,
        rowData: GridRowAction,
        result
    ) {
        // First search the item at root level if not found go to sub level one by one, find it and discontinue it.
        let ltgGoalIndex;
        this.getGoalsPath(currentAdmission).forEach((goal, index) => {
            const found = goal.scale.assessmentId === rowData.data._id;
            if (found) ltgGoalIndex = index;
            if (found && goal.stgs.length > 0) {
                if (!result.discontinueStgs) {
                    goal.stgs.forEach((stg) => {
                        this.getGoalsPath(currentAdmission).push(this.mapStgGoalItemToAGoal(stg, stg.goal));
                    });
                } else if (result.discontinueStgs) {
                    goal.stgs.forEach((stg) => {
                        stg.status.description = GOAL_CONSTANTS.DISCONTINUED;
                        stg.status.reason = result.reasonForDiscontinuing;
                        stg.status.dateOfCompletion = new Date().toDateString();
                        // Unmark assessment item as well if goal discontinued
                        const assessmentPath = this.getAssessmentPath(currentAdmission, stg.scale.assessmentType);
                        // Check if assessmentPath exists as custom goals does not have assessmentType
                        if (assessmentPath?.length) {
                            const assessmentItemFound = assessmentPath.find((x) => x._id === stg.scale.assessmentId);
                            if (assessmentItemFound) assessmentItemFound.isAGoal = false;
                        }
                        // No need of assessment id after discontinual of a goal
                        stg.scale.assessmentId = '';
                    });
                }
            } else if (!found && goal.stgs.length > 0) {
                let stgGoalIndex;
                goal.stgs.forEach((stg, index) => {
                    const found = stg.scale.assessmentId === rowData.data._id;
                    if (found) stgGoalIndex = index;
                });
                if (stgGoalIndex !== undefined) {
                    goal.stgs[stgGoalIndex].status.description = GOAL_CONSTANTS.DISCONTINUED;
                    goal.stgs[stgGoalIndex].status.reason = result.reasonForDiscontinuing;
                    goal.stgs[stgGoalIndex].status.dateOfCompletion = new Date().toDateString();
                    goal.stgs[stgGoalIndex].scale.assessmentId = '';
                }
            }
        });
        if (ltgGoalIndex !== undefined) {
            this.getGoalsPath(currentAdmission)[ltgGoalIndex].status.description = GOAL_CONSTANTS.DISCONTINUED;
            this.getGoalsPath(currentAdmission)[ltgGoalIndex].status.reason = result.reasonForDiscontinuing;
            this.getGoalsPath(currentAdmission)[ltgGoalIndex].status.dateOfCompletion = new Date().toDateString();
            this.getGoalsPath(currentAdmission)[ltgGoalIndex].scale.assessmentId = '';
            !result.discontinueStgs ? (this.getGoalsPath(currentAdmission)[ltgGoalIndex].stgs = []) : false;
        }
    }

    // Removes decimal point and converts to original +ve value. (Used for slider scales of MMT)
    removeDecimalAndReturnOriginalValue(value: number) {
        return `+${value.toString().split('.')[1]}`;
    }
    // Converts original value to a required +ve value (Used for slider scales of MMT)
    convertOriginalValueToDecimal(value: string | number) {
        if (typeof value === 'number') return value;
        return value.includes('+') ? parseFloat(`0.${value.split('+')[1]}`) : parseInt(value);
    }

    mapToOriginalMMTScaleValues(MMTValues) {
        if (MMTValues) {
            Object.keys(MMTValues).forEach((key) => {
                if (!key.endsWith('Note')) {
                    MMTValues[key] % 1 !== 0
                        ? (MMTValues[key] = this.removeDecimalAndReturnOriginalValue(MMTValues[key]))
                        : false;
                }
            });
        }
        return MMTValues;
    }

    mapToSliderMMTScaleValues(MMTValues) {
        if (MMTValues) {
            Object.keys(MMTValues).forEach((key) => {
                if (!key.endsWith('Note')) {
                    MMTValues[key] = this.convertOriginalValueToDecimal(`${MMTValues[key]}`);
                }
            });
        }
        return MMTValues;
    }

    // Scale used for MMT of body parts
    getMMTScale() {
        return [
            { value: 0 },
            { value: 1 },
            { value: -2 },
            { value: 2 },
            { value: 0.2 },
            { value: -3 },
            { value: 3 },
            { value: 0.3 },
            { value: -4 },
            { value: 4 },
            { value: 0.4 },
            { value: 5 },
        ];
    }

    // function to map clof for regularAlike goals
    mapCLOF(clof) {
        if (Array.isArray(clof.levelOfFunction)) {
            return {
                ...clof,
                levelOfFunction: clof.levelOfFunction.map((val) => val.text).join(', '),
            };
            //return clof.levelOfFunction.map((val) => val.text).join(', ');
        } else return clof;
    }
}
