<div class="custom-card legend w-100">
    <div class="header d-flex flex-row align-items-center justify-content-between py-1 pl-3 pr-0">
        <p class="body-medium-semibold">Goals</p>
        <div class="d-flex flex-row align-items-center justify-content-between">
            <!-- This stg toggle will be used in the legend -->
            <span
                class="body-medium-bold"
                [ngClass]="{
                    'text-secondary': selectedGoal !== 'all',
                    'text-disabled': selectedGoal === 'all'
                }"
                >STG</span
            >
            <app-switch
                [disabled]="selectedGoal === 'all'"
                [(ngModel)]="stg"
                (ngModelChange)="setStg($event)"
                (click)="showSTGNotification.emit(true)"
                formGroupClass="m-0"
            ></app-switch>
        </div>
    </div>
    <div class="body p-3 max-h-18em overflow-auto">
        <ng-container *ngIf="filteredGoals?.length > 0; else NoGoalsForLegend">
            <ng-container *ngFor="let goal of filteredGoals; index as i">
                <app-legend-goal
                    [goal]="goal"
                    [class]="
                        (goal.stgs.length > 0 && selectedGoal !== 'all' && stg) || i !== filteredGoals.length - 1
                            ? 'border-bottom '
                            : ''
                    "
                ></app-legend-goal>
                <ng-container *ngIf="selectedGoal !== 'all' && stg === true">
                    <ng-container *ngFor="let stgGoal of goal.stgs; index as stgIndex">
                        <app-legend-goal
                            [goal]="stgGoal"
                            [class]="
                                stgIndex !== goal.stgs.length - 1 ||
                                (i !== filteredGoals.length - 1 &&
                                    stgIndex === goal.stgs.length - 1 &&
                                    selectedGoal !== 'all')
                                    ? 'border-bottom '
                                    : ''
                            "
                        ></app-legend-goal>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</div>

<ng-template #NoGoalsForLegend>
    <div class="h-10em d-flex flex-column justify-content-center align-items-center">
        <span class="body-large text-secondary-50">No goals</span>
    </div>
</ng-template>
