import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { facilityAdmissionRemovedAction } from '@app/store/actions/index';

import { environment } from '@environments/environment';
import { FacilityAdmission } from '@app/models';
import { Store } from '@ngrx/store';
import { PatientState } from '@app/store/store.interface';

const baseUrl = `${environment.apiUrl}/facility-admission`;

@Injectable({ providedIn: 'root' })
export class FacilityAdmissionService {
    tab$: Observable<any>;
    hasActiveFacilityAdmission: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    constructor(private router: Router, private http: HttpClient, private store: Store<{ patient: PatientState }>) {}

    facilityAdmissionSave(facilityAdmissionData: FacilityAdmission) {
        return this.http.post<any>(`${baseUrl}`, facilityAdmissionData, { withCredentials: true }).pipe(
            map((facilityAdmission) => {
                return facilityAdmission.data;
            })
        );
    }
    getPlannerPatients(facilityId) {
        return this.http.get<any>(`${baseUrl}/planner/${facilityId}`, { withCredentials: true }).pipe(
            map((patients) => {
                return patients.data;
            })
        );
    }
    getPlannerFacilityStats(facilityId) {
        return this.http
            .get<any>(`${baseUrl}/planner/refactor/facility-stats/${facilityId}`, { withCredentials: true })
            .pipe(
                map((patients) => {
                    return patients.data;
                })
            );
    }
    getPlannerPatients_refactor(facilityId) {
        return this.http.get<any>(`${baseUrl}/planner/refactor/${facilityId}`, { withCredentials: true }).pipe(
            map((patients) => {
                return patients.data;
            })
        );
    }
    getPlannerPatientByFacilityAdmission(facilityAdmission_id) {
        return this.http
            .get<any>(`${baseUrl}/planner/patient/refactor/${facilityAdmission_id}`, { withCredentials: true })
            .pipe(
                map((patients) => {
                    return patients.data;
                })
            );
    }
    getFacilityAdmission(
        patientId: string,
        facility: string,
        facilityAdmissionId,
        facilitiesWithoutRestriction = false
    ) {
        return !facilityAdmissionId
            ? this.getActiveFacilityAdmissionOnPatientId(patientId, facility, facilitiesWithoutRestriction)
            : this.getOneFacilityAdmission(facilityAdmissionId);
    }

    getActiveFacilityAdmissionOnPatientId(patientId: string, facility: string, facilitiesWithoutRestriction = false) {
        return this.http.get<any>(`${baseUrl}/patient/${patientId}/facility/${facility}`, {
            params: {
                facilitiesWithoutRestriction
            }
        }).pipe(
            map((facilityAdmission) => {
                return facilityAdmission.data;
            })
        );
    }
    getOneFacilityAdmission(facilityAdmissionId: string) {
        return this.http.get<any>(`${baseUrl}/` + facilityAdmissionId).pipe(
            map((facilityAdmission) => {
                return facilityAdmission.data;
            })
        );
    }
    facilityAdmissionUpdate(facilityAdmissionData: FacilityAdmission) {
        return this.http.patch<any>(`${baseUrl}`, facilityAdmissionData, { withCredentials: true }).pipe(
            map((facilityAdmission) => {
                return facilityAdmission.data;
            })
        );
    }
    facilityUpdateWithFiles(facility: { facilityAdmission: string; filesAndDocuments: any }) {
        return this.http.patch(`${baseUrl}/files`, facility, { withCredentials: true });
    }
    facilityAdmissionUpdateFile(facilityAndFile: { facilityAdmissionId: string; filesAndDocumentsId: string, fileName: string }) {
        return this.http.patch(`${baseUrl}/files-update`, facilityAndFile, { withCredentials: true });
    }
    facilityAdmissionRemovedService() {
        return this.store.dispatch(facilityAdmissionRemovedAction());
    }
    facilityAdmissionDischarged(facilityAdmission: FacilityAdmission) {
        return facilityAdmission.patientDischarge && facilityAdmission.patientDischarge.facilityDischargeDate;
    }

    getFacilityAdmissionByPatientId(
        patientId: string,
        activeAdmission = false
    ): Observable<{ data: FacilityAdmission; success: boolean; message: string }> {
        let url = `${baseUrl}/patient/${patientId}?facilityId=${
            JSON.parse(localStorage.getItem('current-facility')).id
        }`;

        if (activeAdmission) {
            url = `${url}&activeAdmission=true`;
        }

        return this.http.get<{ data: FacilityAdmission; success: boolean; message: string }>(url);
    }

    updateMedicalDiagnosis(payload: { facilityAdmissionId: string; icd10Codes: any }): Observable<any> {
        return this.http.patch(`${baseUrl}/medical-diagnosis`, payload);
    }
}
