<div class="steps-tab">
    <div *ngFor="let tab of tabs; let i = index" class="step">
        <div
            (click)="selectedTab(i)"
            class="title ml-2 tab-item mb-2"
            [class.text-primary]="tab.highlight === true"
            [class.text-secondary]="tab.highlight === false"
            *ngVar="tab.titleCount?.toString()?.length as titleCountLength"
            [ngbTooltip]="tab.unauthorized ? 'You don’t have authorizations' : ''"
            tooltipClass="unauthorized-tooltip"
            [ngClass]="{
                blueColor: tab.isDone,
                orangeColor: tab.title?.toLowerCase() === 'organization information*' && !tab.isDone,
                greyColor: !tab.isDone && !tab.underline
            }"
            [ngClass]="{
                blueColor: tab.isDone && !tab.highlight,
                orangeColor: tab.highlight
            }"
        >
            {{ tab.title | stringLength: 40 - titleCountLength:40 - titleCountLength }}
            <span [ngClass]="tab.titleCount && tab.titleCount > 0 ? '' : 'opacity-0'" class="numberCircle">
                <span class="numberCircle-inner text-secondary">{{ tab.titleCount }}</span></span
            >
        </div>
        <div class="path">
            <div
                *ngIf="tab.underline"
                [ngClass]="{
                    'blue-bg': tab.isDone && !tab.highlight,
                    'orange-bg': tab.highlight
                }"
                class="current d-flex flex-row align-items-center"
            >
                <div
                    class="circle-end rounded-circle d-flex flex-row align-items-center justify-content-center"
                    [ngClass]="{
                        'blue-bg': tab.isDone && !tab.highlight,
                        'orange-bg': tab.highlight
                    }"
                >
                    <span *ngIf="tab.isDone" class="done-icon material-icons text-white"> done </span>
                </div>
            </div>
            <div *ngIf="!tab.underline" class="not-done d-flex flex-row align-items-center"></div>
        </div>
    </div>
</div>
