import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
const baseUrl = `${environment.apiUrl}/files`;

@Injectable({
    providedIn: 'root',
})
export class DragDropService {
    constructor(private http: HttpClient) {}

    async addFiles(image: File) {
        // var arr = [];
        const formData = new FormData();
        // arr.push(images);

        // arr[0].forEach((item, i) => {
        formData.append('file', image);
        // });

        return await this.http
            .post(baseUrl + '/upload', formData, {
                reportProgress: true,
                observe: 'events',
            })
            .pipe(catchError(this.errorMgmt));
    }

    deleteFiles(files: string[]): Observable<any> {
        return this.http.post(baseUrl + '/delete', files);
    }
    downloadFile(file: string) {
        return this.http.post<any>(`${baseUrl}/download`, { file: file }).pipe();
    }
    s3Upload(images: File) {
        const arr = [];
        const formData = new FormData();
        arr.push(images);

        arr[0].forEach((item, i) => {
            formData.append('file', arr[0][i]);
        });

        return this.http
            .post(baseUrl + 'document-upload', formData, {
                reportProgress: true,
                observe: 'events',
            })
            .pipe(catchError(this.errorMgmt));
    }

    errorMgmt(error: HttpErrorResponse) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }
}
