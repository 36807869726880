<ng-container *ngIf="(loadingObs | async)?.isLoading; else loaded" [ngTemplateOutlet]="skeleton"></ng-container>
<ng-template #loaded>
    <div
        class="mrn-patient d-flex align-items-center"
        #mrnBar
        id="mrn-bar"
        [ngStyle]="{ transform: widthToTransform ?? isSticky }"
        [ngClass]="{ 'mt-3': !isSticky }"
    >
        <div class="d-flex justify-content-center align-items-center mrn-patient-name-wrapper">
            <span *ngIf="isChevron" class="material-symbols-outlined solid chevron"> chevron_left </span>
            <div class="d-flex flex-row align-self-center align-items-center">
                <app-go-back
                    mainClass="mt-2 text-secondary mr-4"
                    [path]="rootPath"
                    [pathParams]="pathParams"
                    *ngIf="isSticky"
                ></app-go-back>
                <h6
                    class="patient-initial-mrn-bar h6-semibold bg-primary text-white d-flex flex-row justify-content-center align-items-center rounded-circle m-0 flex-shrink-0">
                    <span> {{ data?.patientName.charAt(0) | titlecase }}</span>
                    <!-- <span style="margin-left: 1px"> {{ data?.lastName?.charAt(0) | titlecase }}</span> -->
                </h6>
                <h6
                    class="h6-semibold mb-0 ml-2 overflow-hidden text-ellipsis text-secondary whitespace-nowrap"
                    style="max-width: 120px"
                    placement="bottom"
                  
                >
                    {{ data?.patientName | titlecase }},
                </h6>
                <!-- <h6
                    class="h6-semibold mb-0 ml-2 overflow-hidden text-ellipsis text-secondary whitespace-nowrap"
                    style="max-width: 70px"
                    placement="bottom"
              
                >
                    {{ data?.lastName | titlecase }}
                </h6> -->
            </div>
            <span *ngIf="isChevron" class="material-symbols-outlined solid chevron"> chevron_right </span>
        </div>
        <div>
            <app-bar-card
                [wrapperClass]="isSticky ? 'parentIsSticky' : ''"
                (dateChange)="updateSOCDate($event)"
                [values]="[
                    { title: 'MRN', detail: data?.MRN},
                    { title: 'Date Recieved', detail: data?.details[0]?.dateRecieved | date:'MM/dd/YYYY'},
                    { title: 'Admit Date', detail: data?.admitDate | date: 'MM/dd/YYYY' },
                    { title: 'DOB', detail: data?.DOB | date: 'MM/dd/YYYY' },
                    { title: 'Discipline', detail: data?.discipline },
                    { title: 'Facility', detail: data?.facilityName },
                    { title: 'Therapist Name', detail: data?.therapistName },
                ]"
            >
            </app-bar-card>
        </div>
    </div>
    <div class="mrn-patient-placeholder" id="mrn-placeholder"></div>
</ng-template>

<ng-template #skeleton>
    <div>
        <ngx-skeleton-loader
            appearance="line"
            [theme]="{ width: '25em', height: '7em', 'margin-left': '2em' }"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
            appearance="line"
            [theme]="{ width: '70em', height: '7em', 'margin-left': '3em' }"
        ></ngx-skeleton-loader>
        <br />
    </div>
</ng-template>
