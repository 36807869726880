import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import {
    NgbCalendar,
    NgbDatepickerConfig,
    NgbDateStruct,
    NgbInputDatepickerConfig,
} from '@ng-bootstrap/ng-bootstrap';
@Component({
    selector: 'app-date-picker-calender-input',
    templateUrl: './date-picker-calender-input.component.html',
    styleUrls: ['./date-picker-calender-input.component.scss'],
})
export class DatePickerCalenderInputComponent implements OnChanges {
    //reminder remove these inputs as well @Bilal

    model: NgbDateStruct;
    currentDate: Date;
    myDate: Date;
    tempDate: Date;
    @Output() currentDate$ = new EventEmitter<string>();
    @Input() inputDate: string;

    constructor(config: NgbDatepickerConfig, inputConfig: NgbInputDatepickerConfig, calendar: NgbCalendar) {
        this.customizeDatePicker(config, inputConfig, calendar);
    }
    ngOnChanges(): void {
        if (this.inputDate) {
            this.myDate = new Date(this.inputDate);
            this.model = {
                month: this.myDate.getMonth() + 1,
                day: this.myDate.getDate(),
                year: this.myDate.getFullYear(),
            };
            this.calendarDateChange(this.model);
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    customizeDatePicker(config, inputConfig, calendar) {
        // customize default values of datepickers used by this component tree
        config.minDate = { year: 1950, month: 1, day: 1 };
        config.maxDate = { year: 2022, month: 12, day: 31 };
        // days that don't belong to current month are not visible
        config.outsideDays = 'collapsed';
        inputConfig.autoClose = true;
        // weekends are disabled
        //config.markDisabled = (date: NgbDate) => calendar.getWeekday(date) >= 6;
        // setting datepicker popup to close only on click outside
        // setting datepicker popup to open above the input
        // config.placement = ['top-left', 'top-right'];
    }

    calendarDateChange(date) {
        this.currentDate$.emit(this.toDateJs(date).toString());
    }

    toDateJs(date: NgbDateStruct): Date {
        return date ? new Date(Date.UTC(date.year, date.month - 1, date.day, 0, 0, 0)) : null;
    }
}
