/* eslint-disable @typescript-eslint/no-unused-vars */
import { createReducer, on } from '@ngrx/store';
import {
    cachedTherapyadmissionChangedAction,
    cachedTherapyadmissionRemovedAction,
    therapyadmissionChangedAction,
    therapyadmissionRemovedAction,
} from '@app/store/actions/index';
import { initialTherapyAdmission } from '@app/store/selectors';

export const therapyadmissionReducer = createReducer(
    initialTherapyAdmission,
    on(therapyadmissionChangedAction, (state, { therapyadmission }) => [...therapyadmission]),
    on(therapyadmissionRemovedAction, (state) => [...initialTherapyAdmission])
);
//cached
export const cachedTherapyadmissionReducer = createReducer(
    initialTherapyAdmission,
    on(cachedTherapyadmissionChangedAction, (state, { therapyadmission }) => [...therapyadmission]),
    on(cachedTherapyadmissionRemovedAction, (state) => [...initialTherapyAdmission])
);
