import { Component } from '@angular/core';
import { StoreService } from '@app/services';
import { map, Observable, startWith } from 'rxjs';
@Component({
    selector: 'app-auto-save-loader',
    templateUrl: './auto-save-loader.component.html',
    styleUrls: ['./auto-save-loader.component.scss'],
})
export class AutoSaveLoaderComponent {
    loading$: Observable<boolean>;

    constructor(private storeService: StoreService) {
        this.loading$ = this.storeService.getDocumentLoadingState().pipe(
            startWith({ isLoading: false }),
            map((elem) => elem.isLoading)
        );
    }
}
