import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import {
    FacilityManagementService,
    InvoicingProfileService,
    OrganizationManagementService,
    ToasterService,
} from '@app/services';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';

@Component({
    selector: 'app-recipient-facility-profile-groups',
    templateUrl: './recipient-facility-profile-groups.component.html',
    styleUrls: ['./recipient-facility-profile-groups.component.scss']
})
export class RecipientFacilityProfileGroupsComponent implements OnInit {

    @Input() allFacilities = [];
    @Input() selectedFacilities = [];
    @Input() addedFacilitiesId = [];
    @Input() invoiceProfile = false;
    @Output() facilitySelectionEvent: EventEmitter<any[]> = new EventEmitter<any[]>();
    private gridApi!: GridApi;
    facilityList = [];
    facilityMasterCopy = [];
    statusFilter = {
        profiles: 'ALL',
    };
    searchedFacilities = [];
    // Will move this columns to services soon
    facilityCols: ColDef[] = [
        {
            field: 'name',
            headerName: 'Facility Name',
            headerCheckboxSelection: true,
            checkboxSelection: false,
        },
        { field: 'facilityNPI', headerName: 'Facility NPI' },
        { field: 'profileName', headerName: 'Customer Name' },


    ];
    searchString = '';
    addedFacilities: any[];
    isLoading = false;
    profiles: any;
    profilesFacilityMapper = {};
    profilesFacilityMapperCopy: any;
    constructor(
        private _facilityManagementService: FacilityManagementService,
        private toasterService: ToasterService,
        private invoiceService: InvoicingProfileService,
        private organizationService: OrganizationManagementService
    ) {}
    ngOnChanges(changes: SimpleChanges): void {
        this.handleChanges(changes.selectedFacilities.currentValue);
    }
    handleChanges(selectedFacilities: any): void {
        selectedFacilities?.forEach((facility) => {
            const selectedIndex = this.facilityList.findIndex(
                (f) => f.name.toLowerCase() === (facility?.facilityName?.toLowerCase() || facility?.name?.toLowerCase())
            );
            if (selectedIndex > -1) {
                this.facilityList.splice(selectedIndex, 1);
                this.gridApi?.setRowData(this.facilityList);
            }
        });
    }
    ngOnInit(): void {
        this.isLoading = true;
        this.invoiceService
            .getAllInvoicingProfilesByOrganizationId(this.organizationService._currentOrganization?.id)
            .subscribe({
                next: (profiles) => {
                    const profilesList = profiles.data;
                    this.profiles = profilesList;
                    this.addedFacilitiesId = this.getAddedFacilities(profilesList);
                    this.addedFacilities = this.selectedFacilities;

                    this.getAllFacilities();

                    if (this.selectedFacilities?.length) {
                        this.getProfileNames(this.selectedFacilities);
                        this.selectedFacilities.forEach((e) => this.createProfileFacilityMapper(e));
                        this.profilesFacilityMapperCopy = JSON.parse(JSON.stringify(this.profilesFacilityMapper));
                    }
                },
                error: (err) => {
                    console.error("Error fetching invoicing profiles:", err);
                    this.toasterService.show({
                        title: 'Error',
                        body: 'Error fetching invoicing profiles',
                        type: 'error',
                    });
                },
                complete: () => {
                    this.isLoading = false; // Set loading to false when the observable completes
                }
            });
    }


    getAddedFacilities(profilesList): any[] {
        const allFacilityIds = [];
        profilesList?.forEach((profile) => {
            profile?.facilities?.forEach((facility) => {
                if (facility?._id) {
                    allFacilityIds.push(facility._id);
                }
            });
        });

        return allFacilityIds ?? [];
    }

    filterAddedFacilities(data) {
        data = data?.filter((facility) => {
            return !this.addedFacilitiesId?.includes(facility?.id);
        });

        return data;
    }
    facilitySearch(text: string) {
        if (!text?.length) {
            this.facilityList = [...this.allFacilities];
            return;
        }
        this.searchString = text;
        this.searchedFacilities = this.facilityList;
        this.facilityList = this.facilityMasterCopy;
        this.facilityList = this.facilityList.filter((facility) => facility.name.toLowerCase().includes(text.toLowerCase()) || facility.profileName.toLowerCase().includes(text.toLowerCase()));
        this.facilityList = this.facilityList.filter((facility) =>
            !this.selectedFacilities.some((element) => element.id === facility.id)
          );
    }

    showClearAll() {
        return this.profilesFacilityMapper && Object.keys(this.profilesFacilityMapper).length
    }
    redrawRows(text: string) {
        this.facilityList = this.searchedFacilities;
        if (!text?.length) {
            this.facilityList = this.facilityMasterCopy;
        }
        this.excludeSelectedFacilities();
    }

    excludeSelectedFacilities() {
        // exclude those facilities those are already selected
        if (this.selectedFacilities?.length) {
            this.facilityList = this.facilityMasterCopy?.filter((facility) => {
                return !this.selectedFacilities?.some((selectedFacility) => {
                    return selectedFacility?.name?.toLowerCase() === facility?.name?.toLowerCase();
                });
            });
        }
    }

    facilitySelectionChanged(e) {
        e = e.data ? e.data : e;

        if (this.profilesFacilityMapper[e.profileName]) {
            const index = this.profilesFacilityMapper[e.profileName].indexOf(e.name);
            if (index !== -1) {
                this.profilesFacilityMapper[e.profileName].splice(index, 1)
            } else {
                this.profilesFacilityMapper[e.profileName].push(e.name)

            }

        } else {
            this.profilesFacilityMapper[e.profileName] = [];
            this.profilesFacilityMapper[e.profileName].push(e.name)
        }
        const selectedIndex = this.facilityList.findIndex(
            (f) => f.name.toLowerCase() === (e.name ? e.name.toLowerCase() : e.facilityName.toLowerCase())
        );
        if (selectedIndex > -1) {
            this.selectedFacilities.push(this.facilityList[selectedIndex]);
            this.facilityList.splice(selectedIndex, 1);
            this.gridApi.setRowData(this.facilityList);
            this.facilitySelectionEvent.emit(this.selectedFacilities);
            this.getProfileNames(this.allFacilities)

        }
    }
    createProfileFacilityMapper(e: any) {
        if (this.profilesFacilityMapper[e.profileName]) {
            const index = this.profilesFacilityMapper[e.profileName].indexOf(e.name);
            if (index !== -1) {
                this.profilesFacilityMapper[e.profileName].splice(index, 1)
            } else {
                this.profilesFacilityMapper[e.profileName].push(e.name)

            }

        } else {
            this.profilesFacilityMapper[e.profileName] = [];
            this.profilesFacilityMapper[e.profileName].push(e.name)
        }
    }
    rowSelection(items) {
        items.forEach(e => {
            if (this.profilesFacilityMapper[e.profileName]) {
                const index = this.profilesFacilityMapper[e.profileName].indexOf(e.name);
                if (index !== -1) {
                    this.profilesFacilityMapper[e.profileName].splice(index, 1)
                } else {
                    this.profilesFacilityMapper[e.profileName].push(e.name)

                }

            } else {
                this.profilesFacilityMapper[e.profileName] = [];
                this.profilesFacilityMapper[e.profileName].push(e.name)
            }
            const selectedIndex = this.facilityList.findIndex(
                (f) => f.name.toLowerCase() === (e.name ? e.name.toLowerCase() : e.facilityName.toLowerCase())
            );
            if (selectedIndex > -1) {
                this.selectedFacilities.push(this.facilityList[selectedIndex]);
                this.facilityList.splice(selectedIndex, 1);
                this.getProfileNames(this.allFacilities)
            }
        });
        this.gridApi.setRowData(this.facilityList);
        this.facilitySelectionEvent.emit(this.selectedFacilities);
    }

    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
    }

    getAllFacilities() {

        // commenting it out for now as i think it might be useful in the future

        // this._facilityManagementService._Facilities.subscribe({
        //     next:(result) => {
        //         if (result.length > 0) {
                    this.isLoading = false;
                    const data = this._facilityManagementService.mapFacilitiesIntoDropdown();
                    this.facilityList = this.invoiceProfile ? this.filterAddedFacilities(data) : data;
                    this.facilityMasterCopy = [...this.facilityList];
                    this.allFacilities = [...this.facilityMasterCopy];
                    this.getProfileNames(this.allFacilities)
                    this.handleChanges(this.selectedFacilities);
                // }

            // },
        //     error: (error) => {
        //         console.error("Error fetching Facilities:", error);
        //         this.toasterService.show({
        //             title: 'Error',
        //             body: 'Error fetching invoicing profiles',
        //             type: 'error',
        //         });
        //     },

        // })
    }

    getProfileNames(allFacilities): any[] {
        return allFacilities.map(facility => {
            // Find the profile where this facility's _id matches an object in the profile's facilities array
            const matchingProfile = this.profiles.find(profile =>
                profile.facilities.some((profFacility: any) => profFacility._id === facility.id)
            );

            // If a matching profile is found, append the profileName to the facility
            if (matchingProfile) {
                facility.profileName = matchingProfile.profileName; // Assuming profileName exists in profile
            } else {
                facility.profileName = facility.profileName ? facility.profileName : '--'
            }

            return facility; // Return the modified facility
        });
    }


    // eslint-disable-next-line spellcheck/spell-checker
    removeSelectedFacility(facility, profileIdx: number, facilityIdx, profile = ''): void {

        // eslint-disable-next-line spellcheck/spell-checker
        this.profilesFacilityMapper[profile].splice(facilityIdx, 1)
        if (!this.profilesFacilityMapper[profile]?.length) {
            delete this.profilesFacilityMapper[profile];
        }
        // eslint-disable-next-line spellcheck/spell-checker
        const facilityIndex = this.selectedFacilities.findIndex((fac)=> fac?.name?.toLowerCase() === facility?.toLowerCase())
        this.facilityList.push(this.selectedFacilities[facilityIndex]);
        this.selectedFacilities.splice(facilityIndex, 1);
        if (facility?.MACProvider) {
            facility.id = facility._id;
            facility.name = facility.facilityName;
            facility.type = facility.facilityType;
        }
        this.facilityMasterCopy = [...this.facilityList];
        this.searchedFacilities.push(facility);
        this.gridApi.setRowData(this.facilityList);
        this.facilitySelectionEvent.emit(this.selectedFacilities);
        this.getProfileNames(this.allFacilities)

    }
    removeAll() {
    
        this.addedFacilities?.forEach((facility) => {
            const index = this.allFacilities.findIndex(
                (element) => element?.id === facility?.id
            );
            if (index === -1) {
                this.allFacilities.push(facility);
            }
        });
        this.facilityList = [...this.allFacilities];
        this.facilityMasterCopy = [...this.allFacilities];

        this.gridApi.setRowData(this.facilityList);
        this.profilesFacilityMapper = {}
        this.selectedFacilities = [];
        this.facilitySelectionEvent.emit(this.selectedFacilities);
    }

    getKeys() {
        return Object.keys(this.profilesFacilityMapper);
    }

    updateFilter(item) {
        if (item === 'ALL') {
            this.facilityList = this.allFacilities;

        } else {
            this.facilityList = this.allFacilities.filter((facility) => facility.profileName === item);
        }
        this.facilityList = this.facilityList.filter((facility) =>
            !this.selectedFacilities.some((element) => element.id === facility.id)
          );
    }

}