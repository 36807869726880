<!-- Progress Bar -->
<div class="col-md-12" *ngIf="progress">
    <div class="progress form-group">
        <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width.%]="progress"></div>
    </div>
    <div>
        <span> [ Uploading {{ uploadingFileNumber }}/</span> <span> {{ totalFiles }}</span
        ><span> {{ progress }}% ]</span>
    </div>
</div>

<div
    class="container col-md-12 align-self-center d-flex flex-column justify-content-center"
    [ngClass]="{ defaultheight: this.responseFiles.length == 0, disableDragDrop: progress }"
    appDragDropFileUpload
    (click)="fileField.click()"
    (fileDropped)="upload($event)"
    (isDragged$)="isDragged = $event"
>
    <form [formGroup]="form">
        <div class="row">
            <div class="col-12 pt-2">
                <span><img src="/assets/icons/upload-icon.svg" alt="" /></span>
                <span *ngIf="!isDragged" class="ddinfo mt-2 body-medium-semibold" style="color: #1c3775"
                    >Drag & drop files, or <span style="color: #f4633a">Browse</span></span
                >
                <span *ngIf="isDragged" class="ddinfo body-medium-semibold" style="color: #1c3775"
                    >Drop files here</span
                >
                <span class="body-medium">Supported formates: JPEG, PNG, PDF, DOCX</span>
                <input type="file" name="avatars" #fileField (change)="upload($event.target.files)" hidden multiple />
            </div>

            <!-- <div class="col-md-12"> -->
            <!-- <div class="image-list" *ngFor="let file of fileArr; let i = index">
          <div class="profile">
            <img [src]="sanitize(file['url'])" alt="" />
          </div>
          <p>{{ file.item.name }}</p>
        </div> -->
            <!-- <ul>
          <li class="d-flex flex-row justify-content-between p-2 bg-blue-select mt-2"
            *ngFor="let file of responseFiles; let i = index">
            <span class="text-white">{{ file.originalname }}</span> -->
            <!-- <span class="material-icons-outlined text-primary cursor-pointer"> close </span> -->
            <!-- </li>
        </ul> -->
            <!-- <p class="message">{{ msg }}</p> -->
            <!-- </div> -->
        </div>
    </form>
</div>
<span *ngIf="isDragged" class="iconBottom material-icons-outlined"> dataset </span>
