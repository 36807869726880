import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DiffCellRendererComponent } from '@app/data-grid/cell-renderers';
import { convertSecsToMins, placeZerosOnLeft } from '@app/helpers/utils/data.utils';
import {
    EvalChangeHistory,
    EvaluationDocument,
    FacilityAdmission,
    MedicalReview,
    TherapyAdmission,
} from '@app/interfaces';
// colworx-sk
import { PPUserManagementService } from '@app/services';
// colworx-sk
import { DOCUMENTATION_CONSTANTS, Doc_Notes, NEURO_MUSCULO_SKELETAL_IMPAIRMENTS } from '@app/interfaces/documentation';
import { PrintData } from '@app/interfaces/print';
import { Account } from '@app/models';
import { SignNoteComponent } from '@app/modules/patients/PCC/pcc-tabs/pcc-documentation/modals/sign-note/sign-note.component';
import {
    AuthService,
    DocumentationService,
    FacilityAdmissionService,
    FacilityManagementService,
    GoalService,
    ModalService,
    StandardizedTestsService,
    StoreService,
    ToasterService,
} from '@app/services';
import { EvaluationService } from '@app/services/evaluation.service';
import { select, Store } from '@ngrx/store';
import { ColDef } from 'ag-grid-community';
import { format, parse } from 'date-fns';
import { cloneDeep } from 'lodash';
import { Subject, takeUntil } from 'rxjs';
import { prepareSimpleTextChangeLog } from '@app/helpers/utils';
import { scrollToElementWithOffset } from '@app/helpers/utils/scroll.helper';
import { isAllowedToCoSign } from '@app/helpers';
import { CoSignNoteComponent } from '@app/modules/patients/PCC/pcc-tabs/pcc-documentation/modals/co-sign-note/co-sign-note.component';
import { findTherapyCaseByParamId } from '@app/helpers/utils/therapy-case.utils';
import { TimeConfirmationDialogComponent } from '@app/shared/time-confirmation-dialog/time-confirmation-dialog.component';
@Component({
    selector: 'app-eval-doc',
    templateUrl: './eval-doc.component.html',
    styleUrls: ['./eval-doc.component.scss'],
})
export class EvalDocComponent implements OnInit, OnDestroy {
    @Input() printMode: boolean;
    @Input() printData: PrintData;
    @Input() hideBtn = true;
    @Input() evalHistorySide: boolean;
    noteTitle = 'Evaluation';
    DOCUMENTATION_CONSTANTS = DOCUMENTATION_CONSTANTS;
    admissionDischarged = false;
    showCoSign = false;
    contentEl = document.getElementById('content');
    enableCoSignBtn = false;
    DEEP_CHANGE_KEYS = ['isTelehealth'];

    private readonly onDestroy = new Subject<void>();

    medicalDiagnosis: { title: string; cols: ColDef[]; data: Array<object> } = {
        title: 'Medical Diagnosis',
        cols: [
            { field: 'no', headerName: 'No.', width: 85, valueGetter: 'node.rowIndex + 1' },
            { field: 'code', headerName: 'Code', width: 100, cellRenderer: DiffCellRendererComponent },
            {
                field: 'description',
                headerName: 'Description',
                width: 300,
                wrapText: true,
                autoHeight: true,
                cellRenderer: DiffCellRendererComponent,
            },
            {
                field: 'clinicalCategory',
                headerName: 'PDPM Clinical Category',
                width: 300,
                wrapText: true,
                autoHeight: true,
                cellRenderer: DiffCellRendererComponent,
            },
            {
                field: 'onsetDate',
                headerName: 'Onset Date',
                cellRenderer: DiffCellRendererComponent,
                wrapText: true,
                autoHeight: true,
                cellRendererParams: { key: 'date' },
            },
            { field: 'slp', headerName: 'SLP Comorbidity', cellRenderer: DiffCellRendererComponent },
            {
                headerName: 'Primary DX',
                field: 'isPrimary',
                cellRenderer: DiffCellRendererComponent,
                cellRendererParams: { key: 'primaryDX' },
            },
        ],
        data: [],
    };
    medicalDiagnosisData: any[] = [];
    reasonReferral: { title: string; paragraph: string } = {
        title: 'Reason for Referral',
        paragraph: '',
    };
    chiefComplaint: { title: string; paragraph: string } = {
        title: 'Chief Complaint',
        paragraph: '',
    };
    treatmentDiagnosis: { title: string; cols: ColDef[]; data: Array<object> } = {
        title: 'Treatment Diagnosis',
        cols: [
            { field: 'no', headerName: 'No.', width: 30, valueGetter: 'node.rowIndex + 1' },
            {
                field: 'code',
                headerName: 'Code',
                width: 100,
                cellRenderer: DiffCellRendererComponent,
                cellRendererParams: { key: 'code' },
            },
            {
                field: 'description',
                headerName: 'Description',
                cellClass: ['body-medium', 'line-space'],
                autoHeight: true,
                wrapText: true,
                cellRenderer: DiffCellRendererComponent,
                cellRendererParams: { key: 'description' },
            },
            {
                headerName: 'Onset Date',
                field: 'onsetDate',
                width: 150,
                cellRenderer: DiffCellRendererComponent,
                cellRendererParams: { key: 'date' },
            },
        ],
        data: [],
    };
    precautions: { title: string; cols: ColDef[]; data: Array<object> } = {
        title: 'Precautions',
        cols: [
            { field: 'no', headerName: 'No.', width: 30, valueGetter: 'node.rowIndex + 1' },
            {
                field: 'description',
                headerName: 'Description',
                cellClass: ['body-medium', 'line-space'],
                autoHeight: true,
                cellRenderer: DiffCellRendererComponent,
            },
        ],
        data: [],
    };
    medication: { title: string; cols: ColDef[]; data: Array<object> } = {
        title: 'Medications',
        cols: [
            { headerName: 'No.', width: 20, valueGetter: 'node.rowIndex + 1' },
            {
                field: 'description',
                headerName: 'Description',
                cellClass: ['body-medium', 'line-space'],
                autoHeight: true,
                cellRenderer: DiffCellRendererComponent,
            },
        ],
        data: [],
    };
    contraindications: { title: string; cols: ColDef[]; data: Array<object> } = {
        title: 'Contraindications',
        cols: [
            { field: 'no', headerName: 'No.', width: 30, valueGetter: 'node.rowIndex + 1' },
            {
                field: 'description',
                headerName: 'Description',
                cellClass: ['body-medium', 'line-space'],
                autoHeight: true,
                cellRenderer: DiffCellRendererComponent,
            },
        ],
        data: [],
    };
    patientsGoals: { title: string; cols: ColDef[]; data: Array<object> } = {
        title: 'Patients Goals',
        cols: [
            { field: 'number', headerName: 'No.', width: 30, valueGetter: 'node.rowIndex + 1' },
            {
                field: 'description',
                headerName: 'Description',
                cellClass: ['body-medium', 'line-space'],
                autoHeight: true,
                cellRenderer: DiffCellRendererComponent,
            },
        ],
        data: [],
    };
    priorLevel: { title: string; paragraph: string } = {
        title: 'Prior Level of Function',
        paragraph: '',
    };
    socialDeterminants: { title: string; cols: ColDef[]; data: Array<object> } = {
        title: 'Social Determinants of health',
        cols: [
            { headerName: 'No.', width: 30, valueGetter: 'node.rowIndex + 1' },
            {
                field: 'description',
                wrapText: true,
                autoHeight: true,
                cellStyle: () => {
                    return { wordBreak: 'normal' };
                },
                cellRenderer: DiffCellRendererComponent,
            },
        ],
        data: [],
    };
    environmentalFactors: { title: string; cols: ColDef[]; data: Array<object> } = {
        title: 'Environmental Factors',
        cols: [
            { headerName: 'No.', width: 30, valueGetter: 'node.rowIndex + 1' },
            {
                field: 'description',
                wrapText: true,
                autoHeight: true,
                cellStyle: () => {
                    return { wordBreak: 'normal' };
                },
                cellRenderer: DiffCellRendererComponent,
            },
        ],
        data: [],
    };
    previousTherapy: { title: string; paragraph: string } = {
        title: 'Previous Therapy',
        paragraph: '',
    };
    caregiverEducation: { title: string; paragraph: string } = {
        title: 'Caregiver Education',
        paragraph: '',
    };
    impairments: { title: string; cols: ColDef[]; data: any[] } = {
        title: 'Impairments',
        cols: [
            {
                headerName: 'No.',
                field: 'no',
                minWidth: 60,
                maxWidth: 60,
                valueGetter: 'node.rowIndex + 1',
            },
            {
                headerName: 'Name',
                field: 'impairmentName',
                cellRenderer: DiffCellRendererComponent,
                wrapText: true,
                autoHeight: true,
                minWidth: 300,
                cellStyle: () => {
                    return { wordBreak: 'normal' };
                },
            },
            {
                headerName: 'Value',
                field: 'fieldValue',
                valueFormatter: this.impairmentValueFormatter,
                width: 350,
                wrapText: true,
                autoHeight: true,
                cellRenderer: DiffCellRendererComponent,
                cellRendererParams: { key: 'valueFormatted' },
                cellStyle: () => {
                    return { wordBreak: 'normal' };
                },
            },
            {
                headerName: 'Note',
                field: 'note',
                wrapText: true,
                autoHeight: true,
                width: 400,
                cellRenderer: DiffCellRendererComponent,
                cellStyle: () => {
                    return { wordBreak: 'normal' };
                },
            },
        ],
        data: [],
    };
    functionalDeficits: { title: string; cols: ColDef[]; data: any[] } = {
        title: 'Functional Deficits',
        cols: [
            { field: 'no', headerName: 'No.', minWidth: 60, maxWidth: 60, valueGetter: 'node.rowIndex + 1' },
            { field: 'name', headerName: 'Name', cellRenderer: DiffCellRendererComponent },
            {
                field: 'clof',
                headerName: 'Current level of function',
                autoHeight: true,
                wrapText: true,
                valueFormatter: this.functionalDeficitsValueFormatter,
                //cellRendererParams: { key: 'valueFormatted' },
                cellRenderer: DiffCellRendererComponent,
                cellRendererParams: (params) => {
                    return {
                        key: 'valueFormatted',
                        note: params.data.clof.note,
                        noteCurrentValue: params.value.currentValueNote,
                        notePreviousValue: params.value.previousValueNote,
                    };
                },
            },
            {
                field: 'plof',
                headerName: 'Prior level of function',
                autoHeight: true,
                wrapText: true,
                valueFormatter: this.functionalDeficitsValueFormatter,
                //cellRendererParams: { key: 'valueFormatted' },
                cellRenderer: DiffCellRendererComponent,
                cellRendererParams: (params) => {
                    return {
                        key: 'valueFormatted',
                        note: params.data.plof.note,
                        noteCurrentValue: params.value.currentValueNote,
                        notePreviousValue: params.value.previousValueNote,
                    };
                },
            },
        ],
        data: [],
    };
    standardizedTest: { title: string; cols: ColDef[]; data: any[] } = {
        title: 'Standardized test',
        cols: [
            { field: 'no', headerName: 'No.', minWidth: 60, maxWidth: 60, valueGetter: 'node.rowIndex + 1' },
            { field: 'name', headerName: 'Name', cellRenderer: DiffCellRendererComponent },
            {
                headerName: 'Score',
                field: 'score',
                width: 40,
                valueFormatter: (params) => {
                    return params.data?.totalScore
                        ? `${params.data.score} / ${params.data.totalScore}`
                        : params.data.score;
                },
                // cellRenderer: (params) =>
                //     params.data?.totalScore ? `${params.data.score} / ${params.data.totalScore}` : params.data.score,
                cellRenderer: DiffCellRendererComponent,
                cellRendererParams: { key: 'valueFormatted' },
            },
            {
                field: 'interpretation',
                headerName: 'Interpretation',
                width: 40,
                cellClass: ['body-medium', 'line-space'],
                autoHeight: true,
                cellRenderer: DiffCellRendererComponent,
            },
            {
                field: 'note',
                headerName: 'Note',
                suppressMovable: true,
                cellClass: ['body-medium', 'line-space'],
                autoHeight: true,
                cellRenderer: DiffCellRendererComponent,
            },
        ],
        data: [],
    };
    therapyNecessity: { title: string; paragraph: string } = {
        title: 'Therapy Necessity',
        paragraph: '',
    };
    interventionApproach: { title: string; paragraph: string } = {
        title: 'Intervention Approach',
        paragraph: '',
    };
    goal: any = [];
    cptCodes: { title: string; cols: ColDef[]; data: Array<object> } = {
        title: 'Skilled Services',
        cols: [
            { field: 'no', headerName: 'No.', width: 30, valueGetter: 'node.rowIndex + 1' },
            {
                field: 'cptCode',
                headerName: 'Code',
                width: 60,
                cellStyle: { 'white-space': 'normal' },
                autoHeight: true,
                cellRenderer: DiffCellRendererComponent,
            },
            { field: 'description', width: 600, headerName: 'Description', cellRenderer: DiffCellRendererComponent },
        ],
        data: [],
    };
    frequencyDuration: { title: string; cols: ColDef[]; data: any[] } = {
        title: 'Frequency and Duration',
        cols: [
            { field: 'frequencyFrom', headerName: 'Frequency', width: 30, cellRenderer: DiffCellRendererComponent },
            {
                field: 'duration',
                headerName: 'Duration',
                width: 30,
                cellRenderer: DiffCellRendererComponent,
                valueGetter: (x) => {
                    if (typeof x.data.duration === 'object') {
                        return {
                            currentValue: x.data.duration?.currentValue + ' ' + x.data.durationType?.currentValue,
                            previousValue: x.data.duration?.previousValue
                                ? x.data.duration?.previousValue + ' ' + x.data.durationType?.previousValue
                                : '',
                        };
                    }
                    return x.data.duration + ' ' + x.data.durationType;
                },
            },
            // Comment the field till further notice
            // { field: 'intensity', headerName: 'Intensity', width: 30, cellRenderer: DiffCellRendererComponent },
        ],
        data: [],
    };
    certification: { title: string; cols: ColDef[]; data: any[] } = {
        title: 'Certification',
        cols: [
            {
                field: 'form',
                headerName: 'From',
                valueFormatter: (params) => {
                    return this.transformDate(new Date(params.data.form));
                },
            },
            {
                field: 'through',
                headerName: 'Through',
                cellRenderer: DiffCellRendererComponent,
                // valueFormatter: (params) => {
                //     return this.transformDate(new Date(params.data.through));
                // },
            },
        ],
        data: [{ form: '', through: '' }],
    };
    prognosisAchieving: { title: string; paragraph: string } = {
        title: 'Prognosis for achieving rehab Goals',
        paragraph: '',
    };
    anticipatedDischarge: { title: string; paragraph: string } = {
        title: 'Anticipated Discharge Plan',
        paragraph: '',
    };
    anticipatedDestination: { title: string; paragraph: string } = {
        title: 'Anticipated Discharge Destination',
        paragraph: '',
    };
    evalType: { title: string; paragraph: string } = {
        title: 'Eval Type',
        paragraph: '',
    };
    evalDescription: { title: string; paragraph: string } = {
        title: 'Eval Description',
        paragraph: '',
    };
    evalOnlyReason = {
        title: 'Reason for Not Including the Plan of Care',
        paragraph: '',
    };
    priorHospitalization: { title: string; cols: ColDef[]; data: any } = {
        title: 'Prior Hospitalization Dates',
        cols: [
            {
                headerName: 'No.',
                maxWidth: 60,
                valueGetter: 'node.rowIndex + 1',
            },
            {
                field: 'description',
                headerName: 'Description',
                cellRenderer: DiffCellRendererComponent,
                cellStyle: { 'white-space': 'normal' },
            },
            {
                field: 'admission',
                headerName: 'Admission Date',
                cellRenderer: DiffCellRendererComponent,
                cellRendererParams: { key: 'date' },
            },
            {
                field: 'discharge',
                headerName: 'Discharge Date',
                cellRenderer: DiffCellRendererComponent,
                cellRendererParams: { key: 'date' },
            },
        ],
        data: [],
    };
    pastMedicalHistory: { title: string; cols: ColDef[]; data: any } = {
        title: 'Past Medical History',
        cols: [
            { headerName: 'No.', maxWidth: 70, valueGetter: 'node.rowIndex + 1' },
            {
                field: 'description',
                wrapText: true,
                autoHeight: true,
                cellStyle: () => {
                    return { wordBreak: 'normal' };
                },
                cellRenderer: DiffCellRendererComponent,
            },
        ],
        data: [],
    };
    addendum: { title: string; cols: ColDef[]; data: Array<object> } = {
        title: 'Addendum',
        cols: [
            {
                field: 'description',
                width: 900,
                headerName: 'Description',
                autoHeight: true,
                wrapText: true,
            },
            {
                field: 'userNameRole',
                headerName: 'User name & Role',
                cellRenderer: (params) => {
                    const keyData = params.data?.signed.signedBy[0];
                    return this.userNameFormatter(keyData);
                },
                autoHeight: true,
                width: 250,
            },
            {
                field: 'date',
                headerName: 'Date & Time',
                valueFormatter: (params) => {
                    const completionDate = params.data?.signed?.signDate;
                    const completionTime =
                        ('0' + new Date(completionDate).getHours()).slice(-2) +
                        ':' +
                        ('0' + new Date(completionDate).getMinutes()).slice(-2);
                    return completionDate
                        ? this.transformDate(new Date(completionDate)) + ' ' + completionTime
                        : '-------';
                },
            },
        ],
        data: [],
    };
    optionToShow = [
        {
            date: this.dateFormat('2021-03-25'),
            text: 'Green Field dade county',
            changes: ['Caregiver education', 'Vitals Updated', 'Plan of treatment updated'],
        },
        { date: this.dateFormat('2021-03-26'), text: 'Green Field dade county', changes: ['CPT Codes Updated'] },
    ];
    minutes = {
        title: 'Evaluation Minutes for Not Including the Plan of Care',
        paragraph: '',
    };
    dateFormat(date): string {
        const d = parse(date, 'yyyy-MM-dd', new Date());
        return format(d, 'MMMM, dd h:mm a');
    }

    userNameFormatter(user: any) {
        return user?.roles
            ? `<p class="name mb-0" >${user.lastName + ', ' + user.firstName}</p><span>${user.roles.map(
                  (role) => role.name
              )}</span>`
            : `<p class="name mb-0" >${
                  this.user.user.lastName + ', ' + this.user.user.firstName
              }</p><span>${this.user.user.roles.map((role) => role.name)}</span>`;
    }
    buildFunctionalDeficits(deficits) {
        const functionalDeficits = [];
        for (let index = 0; index < deficits?.length; index++) {
            const deficit = deficits[index];
            functionalDeficits.push({
                no: index + 1,
                name: deficit.name,
                clof: deficit.clof.levelOfFunction,
                plof: deficit.plof.levelOfFunction,
            });
        }

        return functionalDeficits;
    }
    functionalDeficitsValueFormatter(params) {
        // EditHistory scenario where we are showing current and previous values
        if (params.value?.previousValue || params.value?.currentValue) {
            if (params.value.previousValue) {
                if (params.value.previousValue.note) {
                    params.value.previousValueNote = params.value.previousValue.note;
                }
                params.value.previousValue = params.value.previousValue.levelOfFunction;
            }
            if (params.value.currentValue) {
                if (params.value.currentValue.note) {
                    params.value.currentValueNote = params.value.currentValue.note;
                }
                params.value.currentValue = params.value.currentValue.levelOfFunction;
            }
            return params.value;
        }
        // ViewMode scenario
        return params.value.levelOfFunction;
    }
    impairmentValueFormatter(params: any) {
        // To Handle the edit history case where we are showing current and previous values
        if (params.value?.previousValue || params.value?.currentValue) {
            if (params.value.previousValue) {
                if (
                    params.data.impairmentName.previousValue === NEURO_MUSCULO_SKELETAL_IMPAIRMENTS.ACTIVITY_TOLERANCE
                ) {
                    const { min, sec } = convertSecsToMins(+params.data.fieldValue.previousValue);
                    params.value.previousValue = placeZerosOnLeft(min, 2) + ':' + placeZerosOnLeft(sec, 2);
                }
                if (Array.isArray(params.value.previousValue)) {
                    params.value.previousValue = params.value.previousValue?.map((val) => val.text).join(', ');
                }
            }
            if (params.value.currentValue) {
                if (params.data.impairmentName.currentValue === NEURO_MUSCULO_SKELETAL_IMPAIRMENTS.ACTIVITY_TOLERANCE) {
                    const { min, sec } = convertSecsToMins(+params.data.fieldValue.currentValue);
                    params.value.currentValue = placeZerosOnLeft(min, 2) + ':' + placeZerosOnLeft(sec, 2);
                }
                if (Array.isArray(params.value.currentValue)) {
                    params.value.currentValue = params.value.currentValue?.map((val) => val.text).join(', ');
                }
            }
            return params.value;
        }

        // To handle viewMode scenario
        if (params.data.impairmentName === NEURO_MUSCULO_SKELETAL_IMPAIRMENTS.ACTIVITY_TOLERANCE) {
            const { min, sec } = convertSecsToMins(+params.data.fieldValue);
            return placeZerosOnLeft(min, 2) + ':' + placeZerosOnLeft(sec, 2);
        }
        if (Array.isArray(params.value)) {
            const val = params.value.map((val) => val.text).join(', ');
            return val;
        }
        return params.value;
    }

    medicalReview: MedicalReview;
    chiefComplaintMedicalReview: MedicalReview;
    currentAdmission: TherapyAdmission;
    therapyAdmissions: TherapyAdmission[];
    evaluation: EvaluationDocument;
    therapyDiscipline: string;
    loadingObs: any;
    currentAddendum: string;
    user: any;

    documentId: string;
    changeHistory: EvalChangeHistory[];
    selectedChangeInstance = 0;
    selectedLabel = '';

    isEvalOnlyTherapy: boolean;
    therapistDiscipline: any;
    isDifferentDisciplineTherapist = false;
    goLiveEnabled: boolean;
    currentDocSigned = false;
    PocDoc = false;

    constructor(
        private userManagementService:PPUserManagementService,
        private facilityService: FacilityAdmissionService,
        private datePipe: DatePipe,
        private route: ActivatedRoute,
        private storeService: StoreService,
        private authService: AuthService,
        private modalService: ModalService,
        private documentationService: DocumentationService,
        private toasterService: ToasterService,
        private _facilityManagementService: FacilityManagementService,
        private store: Store<{
            therapyAdmission: TherapyAdmission[];
            facilityAdmission: FacilityAdmission;
            user: Account;
        }>,
        private evaluationService: EvaluationService,
        private standardizedTestsService: StandardizedTestsService,
        private goalService: GoalService
    ) {
        this.loadingObs = this.storeService.getLoadingState();
        this.store
            .select('facilityAdmission')
            .pipe(takeUntil(this.onDestroy))
            .subscribe((data) => {
                if (data.patientDischarge) {
                    this.admissionDischarged = true;
                }
            });
        this.route.queryParamMap.subscribe({
            next: (params) => {
                this.therapyDiscipline = params.get('discipline');
                this.documentId = params.get('doc');
                // colworx-sk
                const pocDocParam = params.get('pocDocuments');
                this.PocDoc = pocDocParam === 'true';
                if(this.PocDoc){
                    this.getEditHistory(this.documentId)
                }
               // colworx-sk
            },
        });
        this.store
            .select('facilityAdmission')
            .pipe(takeUntil(this.onDestroy))
            .subscribe((facilityAdmission) => {
                this.admissionDischarged = this.facilityService.facilityAdmissionDischarged(facilityAdmission);
            });
        this.store.pipe(select('user')).subscribe((user) => {
            if (user) {
                this.user = user;
            }
        });
        this.evaluationService.getEvaluationEditHistory(this.documentId).subscribe({
            next: (result) => {
                if (result) {
                    this.changeHistory = result;
                }
            },
            complete: () => {
                this.prepareChangeInstanceData(0);
            },
        });
        if (this._facilityManagementService._currentFacility) {
            this.goLiveEnabled = this._facilityManagementService._currentFacility.goLiveEnabled;
        }
    }

    ngOnInit(): void {
        const queryString = this.route.snapshot.queryParams;
        this.contentEl.addEventListener('scroll', this.divScroll, true);
        if (this.printMode) {
            const { discipline } = this.printData;
            this.therapyDiscipline = discipline;
        }
        this.store
            .select('therapyAdmission')
            .pipe(takeUntil(this.onDestroy))
            .subscribe((therapyAdmissions) => {
                this.currentAddendum = '';
                this.therapyAdmissions = therapyAdmissions;
                const currentTherapyAdmission = findTherapyCaseByParamId(
                    therapyAdmissions,
                    queryString['therapy-case']
                );
                this.currentAdmission = cloneDeep(currentTherapyAdmission);
                if (this.currentAdmission) {
                    this.documentationService.setCurrentTherapyAdmission(
                        therapyAdmissions,
                        this.currentAdmission,
                        this.therapyDiscipline
                    );
                    this.evaluation = this.currentAdmission.documentation.evaluation;
                    this.evaluationService.isEvalOnly = this.evaluation.evaluationType.evalOnly;
                    this.currentDocSigned = !!this.evaluation?.signed?.signDate;
                    this.showCoSign = isAllowedToCoSign(this.currentAdmission, this.user, this.evaluation.coSigned);
                    this.addendum.data = this.currentAdmission.documentation.evaluation.addendum;
                    this.isEvalOnlyTherapy = this.currentAdmission.documentation.evaluation.evaluationType.evalOnly;
                }
            });
        if (this.authService.isTherapist) {
            this.therapistDiscipline = this.authService.accountValue.user.therapist.discipline;
            if (this.therapistDiscipline.replace('A', '') !== this.therapyDiscipline) {
                this.isDifferentDisciplineTherapist = true;
            }
        } else if (
            this.authService.isManager ||
            this.authService.isSuperAdmin ||
            (!this.goLiveEnabled && (this.authService.isPhysician || this.authService.isSystemAdmin))
        )
            this.isDifferentDisciplineTherapist = true;
    }

    onCoSignClicked() {
        if (this.admissionDischarged) {
            const data = {
                heading: 'Confirmation',
                icon: '',
                btn1Text: 'Cancel',
                btn2Text: 'Continue',
                lowerBodyValue: '',
                iconFilled: true,
                bodyText: `This patient has a facility discharge, would you like to continue?`,
            };
            const dialogRef = this.modalService.open(TimeConfirmationDialogComponent, {
                data,
            });
            dialogRef.afterClosed().subscribe((res) => {
                if (res) {
                    this.modalService.open(CoSignNoteComponent, {
                        data: { documentType: Doc_Notes.EVALUATION, heading: 'Evaluation', document: this.evaluation },
                    });
                }
            });
        } else {
            this.modalService.open(CoSignNoteComponent, {
                data: { documentType: Doc_Notes.EVALUATION, heading: 'Evaluation', document: this.evaluation },
            });
        }
    }
    divScroll = (event): void => {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 500)
            this.enableCoSignBtn = true;
    };
    private transformDate = (date) => {
        if (!date || date == 'Invalid Date') {
            return '';
        }
        return this.datePipe.transform(new Date(date), 'MM/dd/yyyy');
    };

    /**
     * Function to scroll to a specific section when user clicks item on edit history section
     * @param id - Id of the section that user clicked
     * @param index - Index of the currently selected history (there can be multiple histories)
     */
    scroll(id: string, index: number): void {
        event.stopPropagation();
        if (index === this.selectedChangeInstance) {
            this.selectedLabel = id;
            id = this.generateSectionId(id);
            if (id === 'minutesSection' || id === 'evalOnlySection') id = 'evalTypeSection';
            const sectionElement: ElementRef = new ElementRef(document.getElementById(id));
            scrollToElementWithOffset(sectionElement, 'start');
        } else {
            // If user is clicking on other history Instance, then select that history instance
            this.prepareChangeInstanceData(index);
        }
    }

    /**
     * Function to generate ID to move to that section in HTML
     * @param id - Current ID coming as label
     * @returns - ID according to sections
     */
    generateSectionId(id): string {
        // As there are some label that have dots so we need the later part
        // eslint-disable-next-line spellcheck/spell-checker
        const str = id.includes('.') ? id.split('.')[1] : id.split('.')[0];
        // eslint-disable-next-line spellcheck/spell-checker
        return str + 'Section';
    }

    prepareChangeInstanceData(item: number) {
        this.selectedLabel = '';
        // Always consult before making any change in this function
        if (this.changeHistory) {
            if(this.changeHistory[item]?.changeHistory?.isPocChangeHistory) return
            this.treatmentDiagnosis.data = this.prepareDynamicArrayChangeLog(
                item,
                'medicalReview',
                'treatmentDiagnosis',
                'code'
            );
            //  this.changeHistory[item]?.medicalReview.treatmentDiagnosis;
            const MD = this.changeHistory[item]?.changeHistory?.changedFields?.filter(
                (x) => x.fieldKey === 'medicalDiagnosis'
            );
            if (MD && MD.length > 0) {
                const temp = [];
                this.changeHistory[item]?.changeHistory.changedFields
                    .filter((x) => x.fieldKey === 'medicalDiagnosis')
                    .forEach((y) => {
                        y['currentValue' as any]['icd10Codes'].forEach((cv) => {
                            const found = y['previousValue' as any]['icd10Codes'].find((pv) => pv.code === cv.code);
                            if (found) {
                                //delta
                                temp.push({
                                    code: { currentValue: cv.code, previousValue: found.code },
                                    description: { currentValue: cv.description, previousValue: found.description },
                                    onsetDate: {
                                        currentValue: cv.onsetDate,
                                        previousValue: found.onsetDate == '' ? undefined : found.onsetDate,
                                    },
                                    clinicalCategory: {
                                        currentValue: cv.clinicalCategory,
                                        previousValue: found.clinicalCategory,
                                    },
                                    isPrimary: {
                                        currentValue: cv.isPrimary,
                                        previousValue: found.isPrimary,
                                    },
                                });
                            } else {
                                //new value addition
                                temp.push({
                                    code: { currentValue: cv.code, previousValue: undefined },
                                    description: { currentValue: cv.description, previousValue: undefined },
                                    onsetDate: { currentValue: cv.onsetDate, previousValue: undefined },
                                    clinicalCategory: { currentValue: cv.clinicalCategory, previousValue: undefined },
                                    isPrimary: {
                                        currentValue: cv.isPrimary,
                                        previousValue: undefined,
                                    },
                                });
                            }
                        });
                        y['previousValue' as any]['icd10Codes'].forEach((pv) => {
                            const found = y['currentValue' as any]['icd10Codes'].find((cv) => cv.code === pv.code);
                            if (!found) {
                                // value deletion
                                temp.push({
                                    code: { currentValue: undefined, previousValue: pv.code },
                                    description: { currentValue: undefined, previousValue: pv.description },
                                    onsetDate: { currentValue: undefined, previousValue: pv.onsetDate },
                                    clinicalCategory: {
                                        currentValue: undefined,
                                        previousValue: pv.clinicalCategory ? pv.clinicalCategory : ' ',
                                    },
                                    isPrimary: {
                                        currentValue: undefined,
                                        previousValue: pv.isPrimary,
                                    },
                                });
                            }
                        });
                    });
                this.medicalDiagnosisData = temp;
            } else this.medicalDiagnosisData = this.changeHistory[item]?.medicalReview.medicalDiagnosis['icd10Codes'];

            this.priorHospitalization.data = this.prepareDynamicArrayChangeLog(
                item,
                'medicalReview',
                'priorHospitalizationDates',
                'id'
            );
            this.pastMedicalHistory.data = this.prepareDynamicArrayChangeLog(
                item,
                'medicalReview',
                'pastMedicalHistory',
                'id'
            );
            this.reasonReferral.paragraph = prepareSimpleTextChangeLog(
                this.changeHistory,
                item,
                'medicalReview',
                'reasonForReferral'
            );
            this.chiefComplaint.paragraph = prepareSimpleTextChangeLog(
                this.changeHistory,
                item,
                'medicalReview',
                'chiefComplaint'
            );
            this.medication.data = this.prepareDynamicArrayChangeLog(item, 'medicalReview', 'medications', 'id');
            this.precautions.data = this.prepareDynamicArrayChangeLog(item, 'medicalReview', 'precautions', 'id');
            this.contraindications.data = this.prepareDynamicArrayChangeLog(
                item,
                'medicalReview',
                'contraindications',
                'id'
            );
            this.patientsGoals.data = this.prepareDynamicArrayChangeLog(
                item,
                'backgroundAssessment',
                'patientGoals',
                'id'
            );
            this.priorLevel.paragraph = prepareSimpleTextChangeLog(
                this.changeHistory,
                item,
                'backgroundAssessment',
                'priorLevelOfFunction'
            );
            this.socialDeterminants.data = this.prepareDynamicArrayChangeLog(
                item,
                'backgroundAssessment',
                'socialDeterminantsOfHealth',
                'id'
            );
            this.environmentalFactors.data = this.prepareDynamicArrayChangeLog(
                item,
                'backgroundAssessment',
                'environmentalFactors',
                'id'
            );
            this.previousTherapy.paragraph = prepareSimpleTextChangeLog(
                this.changeHistory,
                item,
                'backgroundAssessment',
                'previousTherapy'
            );
            this.caregiverEducation.paragraph = prepareSimpleTextChangeLog(
                this.changeHistory,
                item,
                'backgroundAssessment',
                'careGiverEducation'
            );
            // this.impairments.data = this.changeHistory[item].patientAssessment.impairments;
            this.impairments.data = this.prepareDynamicArrayChangeLog(item, 'patientAssessment', 'impairments', '_id');
            this.functionalDeficits.data = this.prepareDynamicArrayChangeLog(
                item,
                'patientAssessment',
                'functionalDeficits',
                '_id'
            );
            this.standardizedTest.data = this.prepareDynamicArrayChangeLog(
                item,
                'patientAssessment',
                'standardizedTests',
                '_id'
            );
            this.standardizedTest.data = this.standardizedTestsService.addTotalsInStandardizedTestsData(
                this.standardizedTest.data
            );
            this.therapyNecessity.paragraph = prepareSimpleTextChangeLog(
                this.changeHistory,
                item,
                'medicalNecessity',
                'therapyNecessity'
            );
            this.interventionApproach.paragraph = prepareSimpleTextChangeLog(
                this.changeHistory,
                item,
                'medicalNecessity',
                'interventionApproaches'
            );
            this.populateGoalsData(item);
            this.cptCodes.data = this.prepareDynamicArrayChangeLog(item, 'planOfCare', 'skilledServices', 'cptCode');
            this.frequencyDuration.data = this.prepareDynamicArrayChangeLog(item, 'planOfCare', 'frequencyAndDuration');
            const certification = this.changeHistory[item].planOfCare.certification;
            this.certification.data = [
                {
                    form: certification?.fromDate,
                    through: prepareSimpleTextChangeLog(
                        this.changeHistory,
                        item,
                        'planOfCare',
                        'certification.throughDate'
                    ),
                },
            ];
            this.prognosisAchieving.paragraph = prepareSimpleTextChangeLog(
                this.changeHistory,
                item,
                'planOfCare',
                'goalPurpose'
            );
            this.anticipatedDischarge.paragraph = prepareSimpleTextChangeLog(
                this.changeHistory,
                item,
                'planOfCare',
                'anticipatedDischarge.dischargePlans'
            );
            this.anticipatedDestination.paragraph = prepareSimpleTextChangeLog(
                this.changeHistory,
                item,
                'planOfCare',
                'anticipatedDischarge.dischargeDestination'
            );
            this.evalType.paragraph = prepareSimpleTextChangeLog(
                this.changeHistory,
                item,
                'evaluationType',
                'evalType'
            );
            this.minutes.paragraph = prepareSimpleTextChangeLog(
                this.changeHistory,
                item,
                'evaluationType',
                'minutes',
                'mins'
            );
            this.evalDescription.paragraph = prepareSimpleTextChangeLog(
                this.changeHistory,
                item,
                'evaluationType',
                'description'
            );
            this.evalOnlyReason.paragraph = prepareSimpleTextChangeLog(
                this.changeHistory,
                item,
                'evaluationType',
                'evalOnlyReason'
            );
            this.isEvalOnlyTherapy = this.changeHistory[item].evaluationType.evalOnly;
            this.selectedChangeInstance = item;
        }
    }

    prepareDynamicArrayChangeLog(changeInstance: number, section: string, key: string, uniqueKeyMatcher = '_id') {
        const arrayData = this.changeHistory[changeInstance]?.changeHistory?.changedFields?.filter(
            (x) => x.fieldKey === key
        );
        if (arrayData && arrayData.length > 0) {
            const temp = [];
            this.changeHistory[changeInstance]?.changeHistory.changedFields
                .filter((x) => x.fieldKey === key)
                .forEach((y) => {
                    y['currentValue' as any].forEach((cv) => {
                        const found = y['previousValue' as any].find(
                            (pv) => pv[uniqueKeyMatcher] === cv[uniqueKeyMatcher]
                        );
                        if (found) {
                            const fullBody = {};
                            //delta
                            Object.keys(y['currentValue'][0]).forEach((key) => {
                                fullBody[key] = { currentValue: cv[key], previousValue: found[key] };
                            });
                            temp.push(fullBody);
                        } else {
                            const fullBody = {};
                            //new value addition
                            Object.keys(y['currentValue'][0]).forEach((key) => {
                                fullBody[key] = { currentValue: cv[key], previousValue: undefined };
                            });
                            temp.push(fullBody);
                        }
                    });

                    y['previousValue' as any].forEach((pv) => {
                        const found = y['currentValue' as any].find(
                            (cv) => cv[uniqueKeyMatcher] === pv[uniqueKeyMatcher]
                        );
                        if (!found) {
                            const fullBody = {};
                            Object.keys(y['previousValue'][0]).forEach((key) => {
                                fullBody[key] = { currentValue: undefined, previousValue: pv[key] };
                            });
                            // value deletion
                            temp.push(fullBody);
                        }
                    });
                });
            return temp;
        } else return this.changeHistory[changeInstance][section]?.[key];
    }
    viewFrequencyAndDuration(frequencyAndDuration) {
        const frequencyAndDurationList = [];
        let index = 0;
        if (Array.isArray(frequencyAndDuration) && frequencyAndDuration.length) {
            for (const data of frequencyAndDuration) {
                index++;
                frequencyAndDurationList.push({
                    no: index,
                    range: data.range ? 'Yes' : 'No',
                    frequency: data.frequencyFrom, //data.range ? `${data.frequencyFrom} to ${data.frequencyTo}` : `${data.frequencyFrom} `,
                    duration: `${data.duration + ' ' + data.durationType}`,
                    intensity: data.intensity,
                });
            }
        } else {
            frequencyAndDurationList.push({ range: '', frequency: '', duration: '', intensity: '' });
        }
        return frequencyAndDurationList;
    }

    updateAddendum(description) {
        this.currentAddendum = description;
    }

    padTo2Digits(digit: number) {
        return digit.toString().padStart(2, '0');
    }
    addAddendumNote() {
        if (this.authService.isManager || this.authService.isTherapist) {
            if (this.currentAddendum) {
                const addendumNote = {
                    description: this.currentAddendum,
                };
                this.modalService.open(SignNoteComponent, {
                    data: { documentType: 'evaluation', addendumNote, heading: 'Addendum Note' },
                });
            } else {
                this.toasterService.show({ title: 'Failure', body: 'Add description', type: 'error' });
            }
        }
    }

    public tooltipCheck = () => {
        if (this.admissionDischarged) {
            return 'Patient Discharged';
        } else if (!this.authService.isManager && !this.authService.isTherapist) {
            return 'You are not authorized to add addendum';
        } else if (this.isDifferentDisciplineTherapist) {
            return '';
        } else {
            return 'Add Addendum Note';
        }
    };

    // copied this function from view-mod-progress-note.component.ts file
    populateGoalsData(item: number) {
        const goals = this.prepareDynamicGoalsArrayChangeLog(item, 'planOfCare', 'goals', 'id');
        if (goals?.length && goals[0].goal?.currentValue) {
            this.goal = this.goalService.buildGoalsWithDiffView(goals);
        } else {
            this.goal = this.goalService.buildGoal(goals);
        }
    }
    // copied this function from view-mod-progress-note.component.ts file
    prepareDynamicGoalsArrayChangeLog(changeInstance: number, section: string, key: string, uniqueKeyMatcher = '_id') {
        const arrayData = this.changeHistory[changeInstance]?.changeHistory?.changedFields?.filter(
            (x) => x.fieldKey === key
        );
        if (arrayData && arrayData.length > 0) {
            const temp = [];
            this.changeHistory[changeInstance]?.changeHistory.changedFields
                .filter((x) => x.fieldKey === key)
                .forEach((y) => {
                    y['currentValue' as any].forEach((cv) => {
                        const found = y['previousValue' as any].find(
                            (pv) => pv[uniqueKeyMatcher] === cv[uniqueKeyMatcher]
                        );
                        if (found) {
                            const fullBody = {};
                            //delta
                            Object.keys(y['currentValue'][0]).forEach((key) => {
                                fullBody[key] = { currentValue: cv[key], previousValue: found[key] };
                            });

                            let finalStgs = [];
                            // do in one loop outside this foreach. Remember if chunk
                            cv.stgs.forEach((stg) => {
                                const prevStg = found.stgs.find((prevStg) => prevStg._id === stg._id);
                                const stgToPush = {};
                                if (prevStg) {
                                    Object.keys(stg).forEach((key) => {
                                        stgToPush[key] = { currentValue: stg[key], previousValue: prevStg[key] };
                                    });
                                } else {
                                    Object.keys(stg).forEach((key) => {
                                        stgToPush[key] = { currentValue: stg[key], previousValue: undefined };
                                    });
                                }
                                finalStgs.push(stgToPush);
                            });
                            fullBody['stgs'].currentValue = finalStgs;

                            // currentV = finalStgs ?
                            temp.push(fullBody);
                            finalStgs = [];
                        } else {
                            const fullBody = {};
                            //new value addition
                            Object.keys(y['currentValue'][0]).forEach((key) => {
                                fullBody[key] = { currentValue: cv[key], previousValue: undefined };
                            });
                            temp.push(fullBody);
                        }
                    });

                    y['previousValue' as any].forEach((pv) => {
                        const found = y['currentValue' as any].find(
                            (cv) => cv[uniqueKeyMatcher] === pv[uniqueKeyMatcher]
                        );
                        if (!found) {
                            const fullBody = {};
                            Object.keys(y['previousValue'][0]).forEach((key) => {
                                fullBody[key] = { currentValue: undefined, previousValue: pv[key] };
                            });
                            // value deletion
                            temp.push(fullBody);
                        }
                    });
                });
            return temp;
        } else return this.changeHistory[changeInstance][section]?.[key];
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.contentEl.removeEventListener('scroll', this.divScroll, true);
    }
    openEvalHistory() {
        this.evalHistorySide = true;
        this.hideBtn = false;
        this.prepareChangeInstanceData(this.changeHistory?.length == 1 ? 0 : 1);
    }

    closeHistorySideBar(event: boolean) {
        this.evalHistorySide = event;
        this.prepareChangeInstanceData(0);
    }


    //colworx-sk
    @Input() evalHistorySidePoc: boolean;
    changeHistoryPoc: any = [];
    openEvalHistoryPoc() {
        this.evalHistorySidePoc = true;
    }

    closeHistorySideBarPoc(event: boolean) {
        this.evalHistorySidePoc = event;
    }
    getEditHistory(docId: any) {
        this.userManagementService.getEvaluationHistory(docId).subscribe((res: any) => {
            this.changeHistoryPoc = res.data;
        });
    }
}
