<ng-container *ngIf="!loadingChartData; else chartLoader">
    <div class="custom-card">
        <div class="header d-flex flex-row align-items-between pr-3">
            <div class="d-flex flex-row align-items-center flex-wrap">
                <h6 class="h6-bold text-secondary d-flex flex-row align-items-center m-0 p-0">Outcomes</h6>
                <span
                    class="material-symbols-outlined text-secondary ml-1"
                    ngbTooltip="Outcomes charts are showing Functional Deficits and Standardized tests stats for now, Impairments and Self Created Goals outcomes will be available soon."
                >
                    info
                </span>
            </div>
            <div class="d-flex justify-content-between flex-wrap ml-auto">
                <div class="d-flex flex-row align-items-center justify-content-between">
                    <!-- Left section -->
                    <div class="d-flex flex-row align-items-center justify-content-between flex-wrap">
                        <div class="d-flex flex-row align-items-center justify-content-center mx-4">
                            <span class="body-medium-bold text-secondary">Goals: </span>
                            <select
                                class="form-control border border-1 filter-select mx-2"
                                name="status"
                                id="status_id"
                                (change)="setSelectedGoal($event); generate()"
                            >
                                <option value="all">All Goals</option>
                                <option
                                    *ngFor="let goal of goals | getNonProjected: therapyDiscipline"
                                    [value]="goal.name"
                                >
                                    {{ goal.name | truncateText }}
                                </option>
                            </select>
                        </div>
                        <div class="d-flex flex-row align-items-center justify-content-center mx-4"  *ngIf="!hideDisciplineFilter">
                            <span class="body-medium-bold text-secondary">Discipline:</span>
                            <select
                                (change)="setTherapyDiscipline($event); recallChartDataGetter()"
                                class="form-control border border-1 filter-select mx-2"
                                name="status"
                                id="status_id"
                            >
                                <!-- <option value="all">All</option> -->
                                <option value="PT">PT</option>
                                <option value="OT">OT</option>
                                <option value="ST">ST</option>
                            </select>
                        </div>
                    </div>
                    <!-- Right section -->
                    <div class="d-flex flex-row align-items-center justify-content-between flex-wrap">
                        <div class="d-flex flex-row align-items-center justify-content-center ml-4">
                            <span class="body-medium-bold text-secondary">Duration:</span>
                            <select
                                class="form-control border border-1 filter-select mx-2"
                                name="status"
                                id="status_id"
                                (change)="setChartView($event); renderChart(); generate()"
                            >
                                <option
                                    *ngFor="let chartView of chartViews; index as i"
                                    [value]="chartView"
                                    [disabled]="i !== 0"
                                >
                                    {{ chartView }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="body d-flex flex-wrap pb-3">
            <div class="d-flex align-items-center justify-content-end w-100 p-3 px-4">
                <div class="d-flex flex-row align-items-center justify-content-between">
                    <button
                        [disabled]="this.selectedChartView === 'Full POC'"
                        [ngClass]="{ 'btn-disabled': this.selectedChartView === 'Full POC' }"
                        class="btn btn-secondary p-1 d-flex flex-row align-items-center justify-content-center border-radius-0"
                        (click)="leftChevronClick(); generate()"
                    >
                        <span class="material-symbols-outlined solid"> chevron_left </span>
                    </button>
                    <button
                        [disabled]="this.selectedChartView === 'Full POC'"
                        [ngClass]="{ 'btn-disabled': this.selectedChartView === 'Full POC' }"
                        class="ml-1 btn btn-secondary p-1 d-flex flex-row align-items-center justify-content-center border-radius-0"
                        (click)="rightChevronClick(); generate()"
                    >
                        <span class="material-symbols-outlined solid"> chevron_right </span>
                    </button>
                </div>
            </div>
            <div class="d-flex flex-wrap w-100 mb-2">
                <div class="col-xl-10 col-lg-9 col-md-8 d-flex flex-column justify-content-center">
                    <button style="opacity: 0" #hiddenBtnToOpenModal (click)="hiddenBtnClicked()"></button>
                    <div
                        *ngIf="!loadingChartData && this.option.xAxis.data.length === 0"
                        class="w-100 d-flex flex-row align-items-center justify-content-center"
                    >
                        <h5 class="text-disabled">No outcomes data</h5>
                    </div>
                    <div
                        *ngIf="!loadingChartData &&  this.option.xAxis.data.length > 0"
                        echarts
                        (chartInit)="onChartInit($event)"
                        [options]="option"
                        [initOpts]="initOpts"
                        class="outcomes-chart"
                    ></div>
                </div>
                <div
                    class="col-xl-2 col-lg-3 col-md-4 h-100 d-flex flex-column align-items-between justify-content-between pr-4"
                >
                    <app-legend
                        [stgs]="stgs"
                        [goals]="mapGoalsToLegend()"
                        [selectedGoal]="selectedGoal"
                        (setStgEvent)="setStg($event); generate()"
                        [stg]="stg"
                        (showSTGNotification)="showSTGNotification()"
                    ></app-legend>
                    <div class="ml-3 mb-4 body-small-semibold text-secondary" *ngIf="option.xAxis.data.length > 0">
                        <div class="body-small-semibold text-secondary">
                            Anticipated <br />
                            Discharge{{ anticipatedDischargeGraphic }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #chartLoader>
    <div class="row">
        <div class="col-12">
            <div class="d-flex flex-row align-items-center justify-content-between">
                <div>
                    <ngx-skeleton-loader
                        [theme]="{ width: '3rem', height: '3rem', margin: '1rem' }"
                    ></ngx-skeleton-loader>
                    <ngx-skeleton-loader
                        [theme]="{ width: '3rem', height: '3rem', margin: '1rem' }"
                    ></ngx-skeleton-loader>
                </div>
                <div>
                    <ngx-skeleton-loader
                        [theme]="{ width: '3rem', height: '3rem', margin: '1rem' }"
                    ></ngx-skeleton-loader>
                    <ngx-skeleton-loader
                        [theme]="{ width: '3rem', height: '3rem', margin: '1rem' }"
                    ></ngx-skeleton-loader>
                    <ngx-skeleton-loader
                        [theme]="{ width: '3rem', height: '3rem', margin: '1rem' }"
                    ></ngx-skeleton-loader>
                </div>
            </div>
        </div>
        <div class="col-12">
            <ngx-skeleton-loader [theme]="{ width: '100%', height: '20rem' }"></ngx-skeleton-loader>
        </div>
    </div>
</ng-template>
