<app-base-modal-card
    [heading]="heading"
    id="unassign-patient"
    wrapperClass="modal-semi-medium"
    (close)="deleteAction(false)"
>
    <div body class="p-3 rounded" *ngIf="plan">
        <p class="d-flex flex-row body-large-semibold text-secondary">
            Are you sure you want to plan with following details?
        </p>
        <div class="d-flex mb-2">
            <div *ngIf="this.counter" class="d-flex flex-row body-medium-semibold text-secondary">
                <div class="label">Frequency: </div>
                <div class="body-medium">{{ ' ' + this.counter + 'x (' + frequency + ') ' }}&nbsp;</div>
                <!-- <span class="body-medium" style="padding-left: 117px"></span> -->
            </div>
            <div *ngIf="plan && plan.duration" class="d-flex flex-row body-medium text-secondary">
                for <span class="pl-1 body-medium">{{ ' ' + plan.duration + ' Weeks' }}</span>
            </div>
        </div>

        <div class="d-flex flex-row body-medium-semibold text-secondary mb-2">
            <div class="label">Effective from: </div>
            <div class="body-medium">{{ ' ' + plan.effectiveFrom | date }}</div>
        </div>
        <div class="d-flex flex-row body-medium-semibold text-secondary mb-2">
            <div class="label">End of plan: </div>
            <div class="body-medium">{{ ' ' + plan.endOfPlan | date }}</div>
        </div>
        <div *ngIf="plan.anticipatedDischargeDate" class="d-flex flex-row body-medium-semibold text-secondary">
            <div class="label">Anticipated DC Date: </div>
            <div class="body-medium">{{ ' ' + plan.anticipatedDischargeDate | date }}</div>
        </div>
    </div>
    <div footer class="d-flex flex-row col-12 justify-content-end pl-0 pr-0">
        <button class="btn btn-secondary innova-btn mr-2 btn-danger" (click)="deleteAction(false)">
            {{ button2Text }}
        </button>
        <button class="btn innova-btn btn-secondary" (click)="deleteAction(true)">
            {{ button1Text }}
        </button>
    </div>
</app-base-modal-card>
