import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { AuthService } from '@app/services';


@Injectable()
export class UserIdInterceptor implements HttpInterceptor {

  constructor(private AuthService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isApiUrl = request.url.startsWith(environment.apiUrl);
    const userId = this.AuthService.accountValue?.user?.id;
    if (isApiUrl && userId) {
        request = request.clone({
            setHeaders: { 'x-user-id': userId },
        });
    }

    return next.handle(request);
  }
}
