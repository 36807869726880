<!-- <div *ngIf="(loadingObs | async)?.isLoading" [class.loading-animation-background]="(loadingObs | async)?.isLoading">
    <div>
        <img class="loading-animation" src="./assets/images/logo_animate.gif" alt="loading..." />
    </div>
</div> -->
<!-- main nav -->
<div [class.app-container]="account" [class.app-container-expanded]="layout === 'expanded'">
    <div>
        <app-header [layout]="layout" *ngIf="account"></app-header>
    </div>

    <div [class.body-grid-layout]="account" [class.app-body-container]="account">
        <div *ngIf="account">
            <app-left-nav-bar (layoutEvent)="setLayout($event)"></app-left-nav-bar>
        </div>
        <!-- main router outlet -->
        <div
            class="layout-transition content"
            id="content"
            [ngClass]="layout === 'collapsed' ? 'body-collapsed' : 'body-expanded'"
        >
            <!-- Spinner with fixed position to cover content -->
            <ng-container *ngIf="skeletonLoader; then skeletonTemplate;"></ng-container>

            <router-outlet></router-outlet>
            <router-outlet name="print"></router-outlet>
        </div>
    </div>
</div>

<!-- global alert -->
<alert class="alert-container"></alert>


<!-- ============================== -->
<!-- Skeleton Loader -->
<!-- ============================== -->
<ng-template #skeletonTemplate>
    <div id="app-skeleton-cont">
        <ngx-skeleton-loader
            appearance="line"
            [theme]="{
                width: '8%',
                height: '3em',
                'margin-left': '2em',
                'margin-right': '3em',
                'border-radius': '2em',
                float: 'right'
            }"
        >
        </ngx-skeleton-loader>
        <ngx-skeleton-loader
            appearance="line"
            [theme]="{ width: '15%', height: '3em', 'margin-left': '2em', 'border-radius': '2em', float: 'right' }"
        >
        </ngx-skeleton-loader>
        <br />
        <br />
        <ngx-skeleton-loader
            appearance="line"
            [theme]="{ width: '96%', height: '15em', 'margin-left': '2em' }"
        ></ngx-skeleton-loader>
        <br />
        <ngx-skeleton-loader
            appearance="line"
            [theme]="{ width: '96%', height: '3em', 'margin-left': '2em' }"
        ></ngx-skeleton-loader>
        <br />
        <br />
        <ngx-skeleton-loader
            appearance="line"
            [theme]="{ width: '96%', height: '5em', 'margin-left': '2em' }"
        ></ngx-skeleton-loader>
        <br />
        <ngx-skeleton-loader
            appearance="line"
            [theme]="{ width: '96%', height: '5em', 'margin-left': '2em' }"
        ></ngx-skeleton-loader>
        <br />
        <ngx-skeleton-loader
            appearance="line"
            [theme]="{ width: '96%', height: '5em', 'margin-left': '2em' }"
        ></ngx-skeleton-loader>
        <br />
        <ngx-skeleton-loader
            appearance="line"
            [theme]="{ width: '96%', height: '5em', 'margin-left': '2em' }"
        ></ngx-skeleton-loader>
    </div>
</ng-template>

