import { Component } from '@angular/core';
import { ITooltipParams } from 'ag-grid-community';
import { ITooltipAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'app-skilled-services-tooltip',
    templateUrl: './skilled-services-tooltip.component.html',
    styleUrls: ['./skilled-services-tooltip.component.scss'],
})
export class SkilledServicesTooltipComponent implements ITooltipAngularComp {
    private params!: ITooltipParams;
    public data!: any;

    agInit(params: ITooltipParams): void {
        this.params = params;

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.data = params.api!.getDisplayedRowAtIndex(params.rowIndex!)!.data;
    }
}
