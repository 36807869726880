<div class="d-inline-flex align-items-center combined-input" [class.border-bottom]="borderBottom">
    <div class="d-flex justify-content-between" [class.input-error]="hasError">
        <label class="text-secondary">{{ label | translate }} {{ isRequired ? '*' : '' }}</label>
        <div
            *ngIf="hasError"
            ngbTooltip="{{ errorMessage }}"
            placement="{{ tooltipPlacement }}"
            class="material-icons-outlined d-flex flex-column align-self-center ml-1 text-danger mb-1 cursor-pointer"
        >
            info
        </div>
    </div>

    <div class="d-flex" [class.input-error]="hasError">
        <input
            class="bg-body-background"
            [type]="type"
            [placeholder]="placeholder"
            [value]="value"
            [disabled]="disabled"
            (keyup)="onChange($event)"
            (change)="onChange($event)"
            (blur)="onBlur()"
            [imask]="maskObj"
            minlength="minLength"
            maxlength="maxLength"
        />
    </div>
</div>
