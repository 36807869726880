<ng-container *ngIf="(loadingObj | async)?.isLoading; else loaded" [ngTemplateOutlet]="skeleton"></ng-container>
<ng-template #loaded>
    <div class="row justify-content-center mt-2" style="height: 100%" *ngIf="!(loadingObj | async)?.isLoading">
        <!-- style="height: calc(100vh - 173px)" -->
        <div class="col-12">
            <app-data-grid
                [columnDefs]="columnDefs"
                [rowData]="organizations"
                [pagination]="true"
                [showCount]="false"
                (gridReady)="initGrid($event)"
                (rowClicked)="onRowClicked($event)"
            >
            </app-data-grid>
        </div>
    </div>
</ng-template>
<ng-template #skeleton>
    <div>
        <br />
        <ng-container *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]">
            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
        </ng-container>
        <hr class="table-footer" />
        <div class="pagination-loader">
            <ngx-skeleton-loader [theme]="{ width: '10em', 'margin-right': '0' }"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{ width: '10em', 'margin-left': '5em' }"></ngx-skeleton-loader>
        </div>
    </div>
</ng-template>
