import { ElementRef, Renderer2 } from '@angular/core';
export const removeWrapper = (elRef: ElementRef, renderer: Renderer2) => {
    // access the DOM. get the element to unwrap
    const el = elRef.nativeElement;
    const parent = renderer.parentNode(elRef.nativeElement);

    // move all children out of the element
    while (el.firstChild) {
        // this line doesn't work with server-rendering
        renderer.appendChild(parent, el.firstChild);
    }

    // remove the empty element from parent
    renderer.removeChild(parent, el);
};
