import { Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EvaluationDocument, PriorHospitalizationDate, TherapyAdmission } from '@app/interfaces';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { DocumentationService, TherapyAdmissionService } from '@app/services';
import { ColDef } from 'ag-grid-community';
import { format } from 'date-fns';
import { ActionsCellRendererComponent } from '@app/data-grid/cell-renderers';
import { GridActionsService } from '@app/data-grid/services/grid-actions.service';
import { EvaluationService } from '@app/services/evaluation.service';
import { uuidv4 } from '@app/helpers/utils/charts/goals.utils';

@Component({
    selector: 'app-prior-hospitalization-stays',
    templateUrl: './prior-hospitalization-stays.component.html',
    styleUrls: ['./prior-hospitalization-stays.component.scss'],
})
export class PriorHospitalizationStaysComponent implements OnDestroy {
    @Input() heading: string;
    @Input() isEditMode: string;

    defaultColDef: ColDef = {
        suppressMovable: true,
        autoHeight: true,
    };
    colsData: ColDef[] = [
        {
            field: 'no',
            headerName: 'No.',
            maxWidth: 60,
            minWidth: 60,
            valueGetter: 'node.rowIndex + 1',
        },
        { field: 'description', headerName: 'Description', cellStyle: { 'white-space': 'normal' } },
        { field: 'admission', headerName: 'Admission Date', width: 10 },
        { field: 'discharge', headerName: 'Discharge Date', width: 10 },
        {
            field: 'actions',
            type: 'rightAligned',
            maxWidth: 100,
            cellClass: 'd-flex flex-column justify-content-center align-items-end',
            cellRenderer: ActionsCellRendererComponent,
            cellRendererParams: { edit: true, delete: true, gridName: 'priorHospitalizationStays' },
        },
    ];
    records: PriorHospitalizationDate[] = [];
    hospitalizationStaysForm = new FormGroup({
        admission: new FormControl('', Validators.required),
        discharge: new FormControl('', Validators.required),
        description: new FormControl(''),
        id: new FormControl(''),
    });
    action: 'add' | 'edit';
    submitted: boolean;
    allTherapyAdmissions: TherapyAdmission[];

    private subscriptions: Subscription[] = [];
    private currentTA: TherapyAdmission;
    private routeData: { doc: string; discipline: string } = {
        doc: null,
        discipline: null,
    };
    dischargeMinDate = '';
    hospitalizationMaxDate = new Date();
    @ViewChild('form', { read: ElementRef, static: false }) formRef: ElementRef;

    constructor(
        private store: Store<{
            therapyAdmission: TherapyAdmission[];
        }>,
        private route: ActivatedRoute,
        private documentationService: DocumentationService,
        private gridActionsService: GridActionsService,
        private evaluationService: EvaluationService,
        private therapyService: TherapyAdmissionService
    ) {
        this.route.queryParamMap.subscribe({
            next: (params) => {
                this.routeData.doc = params.get('doc');
                this.routeData.discipline = params.get('discipline');
            },
        });

        this.subscriptions.push(
            this.store.select('therapyAdmission').subscribe((admissions: TherapyAdmission[]) => {
                if (!admissions || !admissions.length) {
                    return;
                }

                this.allTherapyAdmissions = JSON.parse(JSON.stringify(admissions));
                this.currentTA = this.therapyService.initCurrentTherapyAdmissionByIdQueryString(this.allTherapyAdmissions);

                if (!this.currentTA?.documentation?.evaluation?.medicalReview?.priorHospitalizationDates) {
                    return;
                }

                const priorHospitalizationDates =
                    this.currentTA?.documentation?.evaluation?.medicalReview?.priorHospitalizationDates;

                this.records = priorHospitalizationDates.map((row: PriorHospitalizationDate) => {
                    return {
                        description: row.description,
                        admission: format(new Date(row.admission), 'MM/dd/yyyy') as unknown as Date,
                        discharge: format(new Date(row.discharge), 'MM/dd/yyyy') as unknown as Date,
                        id: row.id,
                    };
                });
            })
        );

        this.subscriptions.push(
            this.gridActionsService.action.subscribe((info) => {
                this.gridActionHandler(info);
            })
        );
    }

    gridActionHandler(info) {
        if (!info) {
            return;
        }

        const { actionType, data, gridName } = info;
        if (gridName === 'priorHospitalizationStays') {
            switch (actionType) {
                case 'delete': {
                    this.handledDeletionAction(data);
                    break;
                }
                case 'edit': {
                    this.handleEditAction(data);
                    break;
                }
            }
        }
    }

    handledDeletionAction(toDelete: PriorHospitalizationDate) {
        const newRecords = this.currentTA.documentation.evaluation.medicalReview.priorHospitalizationDates.filter(
            (elem) => elem.id !== toDelete.id
        );
        this.currentTA.documentation.evaluation.medicalReview.priorHospitalizationDates = newRecords;
        if (this.isEditMode) {
            this.evaluationService.prepareDataForEdit(
                'medicalReview',
                'priorHospitalizationDates',
                newRecords,
                'array',
                this.allTherapyAdmissions,
                this.currentTA
            );
        } else this.documentationService.updateDocumentation(this.currentTA);
    }

    updateDischargeDate() {
        this.dischargeMinDate = this.hospitalizationStaysForm.get('admission').value;
        // update dischargeDate if admission date is greater than the dischargeDate
        let discharge: Date;
        const admission = new Date(this.hospitalizationStaysForm.get('admission').value);
        // Date automatically convert null value to date string that's why we need this check
        if (this.hospitalizationStaysForm.get('discharge').value) {
            discharge = new Date(this.hospitalizationStaysForm.get('discharge').value);
        }
        if (admission > discharge) {
            this.hospitalizationStaysForm.get('discharge').setValue(admission);
        }
    }

    handleEditAction(data: PriorHospitalizationDate) {
        this.action = 'edit';
        this.hospitalizationStaysForm.get('description').patchValue(data.description);
        this.hospitalizationStaysForm.get('admission').patchValue(data.admission);
        this.hospitalizationStaysForm.get('discharge').patchValue(data.discharge);
        this.hospitalizationStaysForm.get('id').patchValue(data.id);
        this.dischargeMinDate = this.hospitalizationStaysForm.get('admission').value;

        // call at the end of current tick of event loop
        // because DOM doesn't immediately have formRef
        setTimeout(() => {
            this.formRef.nativeElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }, 0);
    }

    setState(value: 'add' | 'edit') {
        this.action = value;
    }

    cancel() {
        this.hospitalizationStaysForm.reset();
        this.action = null;
    }

    formSubmitted() {
        if (this.hospitalizationStaysForm.invalid) {
            return;
        }
        const { admission, discharge, description, id } = this.hospitalizationStaysForm.value;
        const evaluation: EvaluationDocument = this.currentTA?.documentation?.evaluation;

        if (!evaluation) {
            return;
        }

        switch (this.action) {
            case 'add': {
                this.currentTA.documentation.evaluation = this.getSavePayload({
                    admission,
                    discharge,
                    description,
                    evaluation,
                });
                break;
            }

            case 'edit': {
                this.currentTA.documentation.evaluation = this.getUpdatedPayload(evaluation);
                break;
            }
        }

        this.submitted = true;
        if (this.isEditMode) {
            this.evaluationService.prepareDataForEdit(
                'medicalReview',
                'priorHospitalizationDates',
                { admission, discharge, description, id },
                'array',
                this.allTherapyAdmissions,
                this.currentTA
            );
            this.action = null;
            this.hospitalizationStaysForm.reset();
            this.submitted = false;
        } else {
            this.documentationService.updateDocumentation(this.currentTA).then(() => {
                this.action = null;
                this.hospitalizationStaysForm.reset();
                this.submitted = false;
            });
        }
    }

    getSavePayload = ({ admission, discharge, description, evaluation }) => {
        const payload = {
            admission,
            discharge,
            description,
        };

        if (this.isEditMode) payload['id'] = uuidv4();

        evaluation.medicalReview?.priorHospitalizationDates.push(payload as PriorHospitalizationDate);
        return evaluation;
    };

    getUpdatedPayload = (evaluation: EvaluationDocument) => {
        const id = this.hospitalizationStaysForm.get('id').value;
        const index = evaluation.medicalReview.priorHospitalizationDates.findIndex((record) => record.id === id);
        evaluation.medicalReview.priorHospitalizationDates[index] = {
            id,
            description: this.hospitalizationStaysForm.get('description').value,
            admission: this.hospitalizationStaysForm.get('admission').value,
            discharge: this.hospitalizationStaysForm.get('discharge').value,
        };

        return evaluation;
    };

    ngOnDestroy(): void {
        this.subscriptions.map((sub: Subscription) => sub.unsubscribe());
    }
}
