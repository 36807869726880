/* eslint-disable spellcheck/spell-checker */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FacilityManagementService, ModalService, OrganizationManagementService, ToasterService } from '@app/services';
import { forkJoin, take } from 'rxjs';
import { setHours } from '@app/helpers/constants';
import { FacilitiesPopUpComponent } from '../facilities-pop-up/facilities-pop-up.component';
import { ConfirmationModalComponent } from '@app/shared/modals/v2/confirmation-modal/confirmation-modal.component';
import { BILL_STATUS , STANDARD_ACTIVITIES } from '@app/helpers/constants/NPC';
import { uuidv4 } from '@app/helpers/utils/charts/goals.utils';
import { DeletionConfirmationDialogComponent } from '@app/shared/deletion-confirmation-dialog/deletion-confirmation-dialog.component';
@Component({
    selector: 'app-edit-npc',
    templateUrl: './edit-npc.component.html',
    styleUrls: ['./edit-npc.component.scss'],
})
export class EditNpcComponent implements OnInit {
    @Output() goBack = new EventEmitter<boolean>();
    @Input() title = 'Activities';
    @Input() backTitle = 'Go Back';
    @Input() isRehabCenter = false;
    @Input() hideTitle = false;
    @Input() editingOrgId = '';
    @Input() npcList = [];
    selectedFacilities = [];

    activities = false;
    isEditMode = false;
    loading = true;
    isDataSaving = false;
    // eslint-disable-next-line spellcheck/spell-checker
    addIcon = './../../../../../assets/icons/add-icon.svg';
    // eslint-disable-next-line spellcheck/spell-checker
    editIcon = './../../../../../assets/icons/edit-icon.svg';
    data: any[] = [];
    headerSizes = [
        { title: 'Display', size: '4vw' },
        { title: 'Type', size: '16vw', padding: '2vw' },
        { title: 'Unit', size: '8vw', padding: '2vw' },
        { title: 'Billable', size: '12vw', padding: '2vw' },
        { title: 'Rate', size: '12vw', padding: '2vw' },
        { title: 'End Date', size: '12vw', padding: '2vw' },
        // { title: '', size: '5vw', padding: '2vw' },
    ];

    public SKELETON_CSS = {
        CELL: {
            width: '96%',
            height: '3.4em',
            margin: '0 2em 0.1em 2em',
        },
    };
    items = { unit: null };

    units = ['By hour', 'By mile', 'By session'];
    billOptions = ['Billable', 'Non-Billable'];
    individualRatesActivity = ['Labor Time', 'Travel Time', 'Administrative Time']

    buttons: { text: string; isSelected: boolean; isDisabled: boolean }[];
    id = '';

    organizationId = '';

    form: FormGroup;
    conceptRehabURL = '';
    isEditing = true;
    facilitiesOfOrganization: string[];
    facilityStatus = 'active';

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private _organizationService: OrganizationManagementService,
        private _facilityService: FacilityManagementService,
        private toasterService: ToasterService,
        private modalService: ModalService
    ) {}

    ngOnInit(): void {
        this.form = this.fb.group({
            rows: this.fb.array([]),
        });

        const state = history.state;
        this.selectedFacilities = state.selectedFacilities;
        this.facilityStatus = state.facilityStatus;
        if(!this.selectedFacilities?.length){
            history.go(-1);
            return;
        }
        const url = window.location.href;
        const segments = url.split('/');
        this.id = segments.pop();

        forkJoin([
            this._facilityService.getOrganizationFacilitiesById(this.id),
            this._organizationService.getOrganizationNpc(this.id)
        ])
        .subscribe((res) => {
            this.facilitiesOfOrganization = [];
            res?.[0]?.data?.forEach((facility) => {
                this.facilitiesOfOrganization.push(facility?._id);
            });
            this.npcList = res?.[1]?.data?.npc?.filter((item) => !item?.isDeleted);
            this.loading = false;
            if (this.npcList?.length) {
                this.populateFormWithNPCList();
            } 
        });
    }

    addActivities() {
        this.activities = true;
        // this.isEditMode = true;
        const allDeleted = this.rowForms.value.every((item) => item.isDeleted);
        if (!this.rowForms?.controls?.length || allDeleted) {
            this.addNPC();
        }
    }
    populateFormWithNPCList(): void {
        this.npcList.forEach((item) => {
            if (!item?.isDeleted) {
                this.addRow(item);
            }
        });
    }

    toggleChanged(item  , event){
        item.value.enable = event;
    }

    editFacilities() {
        const dialogRef = this.modalService.open(FacilitiesPopUpComponent, {
            data: { heading: 'Select Facility' , selectedFacilities : this.selectedFacilities , selectedTab : this.facilityStatus },
        });
        dialogRef.afterClosed().subscribe((selectedFacilities) => {
            if(selectedFacilities?.length){
                this.selectedFacilities = selectedFacilities;
            }
        });
        return;
    }

    get rowForms() {
        return this.form.get('rows') as FormArray;
    }

    createRow(data?: any): FormGroup {
        return this.fb.group({
            activity: data?.activity ? data.activity : '',
            unit: data?.unit ? data.unit : '',
            billable: data?.billable,
            rate: data?.rate ? data.rate : '',
            activityId: data?.activityId ? data.activityId : uuidv4(),
            isDeleted: data?.isDeleted || false,
            _id: data?._id ? data?._id : '',
            isDeletable: data?.isDeletable === false ? data.isDeletable : true,
            endDate: data?.endDate || '',
            enable: data?.enable === false ? data?.enable : true,
        });
    }

    showIcons(item) {
        return (
            item.activity?.toLowerCase() !== STANDARD_ACTIVITIES.LABOUR_TIME?.toLowerCase() &&
            item.activity?.toLowerCase() !== STANDARD_ACTIVITIES.TRAVEL_TIME?.toLowerCase() &&
            item.activity?.toLowerCase() !== STANDARD_ACTIVITIES.ADMINISTRATIVE_TIME?.toLowerCase()
        );
    }

    disableInputField(item) {
        if (item.activity?.toLowerCase() === STANDARD_ACTIVITIES.LABOUR_TIME?.toLowerCase() || !item.enable) {
            return true;
        }
        if (item.isDeletable) {
            return false;
        } else {
            if (item.billable && item.activity?.toLowerCase() === STANDARD_ACTIVITIES.MILEAGE?.toLowerCase()) {
                return false;
            }
            return true;
        }
    }




    disableLabourTime(item){
        return item.activity?.toLowerCase() === STANDARD_ACTIVITIES.LABOUR_TIME?.toLowerCase()
    }
    updateOptions(option, item) {
        item.billable = option === BILL_STATUS.BILLABLE;
    }
    addRow(data?: any): void {
        const row = this.createRow(data);
        this.rowForms.push(row);
    }

    addNPC() {
        // if (this.isEditMode || !this.rowForms.value?.length) {
        //     this.rowForms.push(this.createRow());
        // } else {
        //     this.isEditMode = !this.isEditMode;
        // }
    }

    deleteNPC(index: number) {
        const control = this.rowForms.at(index);
        if (control) {
            control.patchValue({ isDeleted: true });
        }

        if (!this.rowForms.value?.some((form: any) => !form.isDeleted)) {
            this.activities = false;
        }
    }

    endDateSelected(event, item, index) {
        if(event){
        const dialogRef = this.modalService.open(DeletionConfirmationDialogComponent, {
            data: {
                heading: 'Confirmation',
                message:
                    'Timecard session type will no longer be valid as of the date entered. Do you want to continue?',
                button1Text: 'Yes',
                button2Text: 'No',

            },
        });
        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                const endDate = setHours(event)
                item.value.endDate = endDate;
            }else {
                item.value.endDate = ''; 
                this.rowForms.controls[index].get('endDate').reset()
                dialogRef.close()
            }
        });
    }
    }

    toggleBillable(row: FormGroup) {
        const currentBillable = row.get('billable').value;
        row.get('billable').patchValue(!currentBillable);
    }

    isMandatoryFieldsFilled() {
        const npcData = this.form.controls.rows.value;
        const isValid = this.npcValidation(npcData);
        return !isValid;
    }

    isDataSame() {
        const npcData = this.form.controls.rows.value;
        const nonDeletedFormNpc = npcData?.filter((act) => !act?.isDeleted);
        const nonDeletedNpc = this.npcList?.filter((act) => !act.isDeleted);
        for (let i = 0; i < this.npcList.length; i++) {
            const npcItem = this.npcList[i];
            const rowFormItem = npcData[i];
          
            if ((!rowFormItem.rate || rowFormItem.rate == 0 ) && rowFormItem.billable && (!rowFormItem.isDeletable || rowFormItem.activity === STANDARD_ACTIVITIES.MILEAGE)) {
                if(!rowFormItem.isDeletable && rowFormItem.activity?.toLowerCase() !== STANDARD_ACTIVITIES.MILEAGE?.toLowerCase()){
                    // adding this condition  because the rate will come from the user , after that just need to remove this check
                }else{
                return true;
                }
            }

            // adding this condition  because the rate will come from the user , after that just need to remove this check
            npcItem.endDate = npcItem.endDate  ? npcItem.endDate : '';
            npcItem.rate = npcItem.rate  ? npcItem.rate : ''; 

            if (
                npcItem?.activity?.toLowerCase() !== STANDARD_ACTIVITIES.LABOUR_TIME?.toLowerCase() && 
                npcItem?.endDate !== rowFormItem?.endDate ||
                npcItem.activity !== rowFormItem.activity ||
                npcItem.billable !== rowFormItem.billable ||
                npcItem.rate !== rowFormItem.rate ||
                npcItem.unit !== rowFormItem.unit ||
                npcItem.isDeleted !== rowFormItem.isDeleted ||
                npcItem.enable !== rowFormItem.enable ||
                nonDeletedNpc?.length !== nonDeletedFormNpc?.length
            ) {
                return false;
            }
        }
        return true;
    }

    openConfirmationModal() {
        const npcData = this.form.controls.rows.value;
        const isValid = this.npcValidation(npcData);
        const isUnique = this.checkDuplicatedData(npcData);

        if (!isUnique) {
            this.toasterService.show({ title: 'Failure', body: 'Duplicated Activity Names', type: 'error' });

            return;
        }

        if (isValid) {
            if (!this.facilitiesOfOrganization?.length) {
                this.saveUpdateNPC(npcData);
                return;
            }
           

            const dialogRef = this.modalService.open(ConfirmationModalComponent, {
                data: {
                    heading: 'Update Timecards',
                    message: 'Are you sure you want to save configuration timecards for the selected facilities?',
                    iconName: 'warning',
                    primaryButtonText: 'Yes',
                    secondaryButtonText: 'No',
                    secondaryButtonClass: 'btn btn-outline-danger'

                },
            });

            dialogRef.afterClosed().subscribe((data) => {

                if (data) {
                    this.saveUpdateNPC(npcData);
                }
            });
            return;
        } else {
            this.toasterService.show({ title: 'Failure', body: 'Please Fill Mandatory Fields', type: 'error' });
            return;
        }
    }
    checkDuplicatedData(npcData: any) {
        let isValid = true;
        const activitiesSet = new Set<string>();
        npcData.forEach((element) => {
            if (!element.isDeleted) {
                if (activitiesSet.has(element.activity?.toLowerCase())) {
                    isValid = false;
                    return isValid;
                }
                activitiesSet.add(element.activity?.toLowerCase());
            }
        });
        return isValid;
    }

    saveUpdateNPC(data) {
        // data = this.enableNPC(data);
        const payload = {
            data,
            facilityIds: this.selectedFacilities?.map(facility =>  facility?._id),
        };
        this.deleteId(data);
        this.isDataSaving = true;
        this._facilityService
            .updateMultipleFacilityNPC(payload)
            .pipe(take(1))
            .subscribe(
                (result: any) => {
                    this.isDataSaving = false;
                    if (result.success) {
                        this.toasterService.show({ title: 'Success', body: 'Timecard session type saved and changes will be effective immediately.', type: 'success' });
                        history.go(-1)
                        // this.npcList = cloneDeep(result.updatedData.result);
                        // this.activities = this.npcList.length ? true : false;
                    } else {
                        this.toasterService.show({ title: 'Failure', body: result.message, type: 'error' });
                    }
                },
                (error) => {
                    this.toasterService.show({ title: 'Failure', body: error, type: 'error' });
                }
            );
       
    }

    /**
     * This function is deleting _id as its not necessary in the payload
     * @param data
     */
    deleteId(data: any) {
        data.forEach((element) => {
            delete element?._id;
        });
    }

    /**
     * 1- This function will add enable as true key while adding NPC to Facility and Organization
     * 2- Also as we are deleting ativities only from frontend
     * if the activity is saved already it will check that by _id as the saved activity will have _id
     * if an activity has isDeleted true and _id then it will only update its isDeleted property
     */
    enableNPC(data: any) {
        data = data.filter((item) => {
            item.enable = true;

            if (item.isDeleted && item._id.length) {
                return item;
            }

            if (!item.isDeleted) {
                return item;
            }
        });
        return data;
    }

    setHours(data) {
        data?.forEach((activity) => {
            activity.endDate = setHours(activity.endDate);
        });
        return data;
    }

 

    npcValidation(data): boolean {
        let isValid = true;
        data?.forEach((item) => {
            if (!item.isDeleted) {
                const { activity, unit, billable, rate  } = item;
                if (!activity || !unit) {
                    isValid = false;
                    return;
                }
                if (billable && (!rate || rate == 0) ) {
                    if(this.individualRatesActivity.includes(activity)){
                        // adding this check because for non-deletable activities i dont have rate for now
                        // once i get the rates from user this check needs to be removed
                    }else{
                    isValid = false;
                    return;
                    }
                }
            }
        });
        return isValid;
    }

    validateInput(event: any) {
        const input = event.target.value;
        const regex = /^[0-9]*$/;
        if (!regex.test(input)) {
            event.target.value = input.slice(0, -1);
        }
    }
    handleCancel() {
      history.go(-1);
    }

    skipNow() {
        // this.router.navigateByUrl(this.conceptRehabURL);
    }

    backButton() {
        this.goBack.emit(true);
    }

    getUnit(unit: any) {
        const words = unit.split(' ');

        if (words.length === 1) {
            return 'per ' + unit;
        }
        return unit.replace('By', 'per');
    }

    getPlaceholderText(item): string {
        if (item.billable) {
            if (!item.isDeletable) {
                return 'Individual rates';
            } else {
                return 'Enter Rate';
            }
        } else {
            return 'Non-Billable';
        }
    }

    resetInputValue(item) {
        if (!item.get('billable').value) {
            // Reset the value of the input field
            item.get('rate').setValue('');
        }
    }
}