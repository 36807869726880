// colworx-ak start
import { Injectable } from '@angular/core';
import { CanDeactivate, UrlTree } from '@angular/router';
import { AddUserLayoutComponent } from '@app/modules/pp-accounts-documents/pp-accounts/add-user-layout/add-user-layout.component';
import { AppService, ModalService } from '@app/services';
import { BackConfirmationDialogComponent } from '@app/shared/go-back/back-confirmation-dialog/back-confirmation-dialog.component';
import { StaffState } from '@app/store/store.interface';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ROUTER_UTILS } from '@app/helpers';

@Injectable({
    providedIn: 'root',
})
export class AddStaffGuard implements CanDeactivate<AddUserLayoutComponent> {
    staff$: Observable<any>;
    editing: boolean;
    targetRoute: string;
    constructor(
        private store: Store<{
            staff: StaffState;
        }>,
        private modal: ModalService,
        private appService: AppService
    ) {
        this.appService.targetRoute.subscribe((val) => {
            this.targetRoute = val;
        });
        this.staff$ = this.store.pipe(select('staff'));
        this.staff$.subscribe((staff) => {
            if (staff.email) {
                this.editing = true;
            } else {
                this.editing = false;
            }
        });
    }
    canDeactivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (
            this.editing &&
            !this.targetRoute.includes(ROUTER_UTILS.config.therapyAdmission.show.root) &&
            !this.targetRoute.includes(ROUTER_UTILS.config.therapyAdmission.add.root) &&
            !this.targetRoute.includes(ROUTER_UTILS.config.patients.pcc.root) &&
            !this.targetRoute.includes(ROUTER_UTILS.config.payorInformation.insuranceCoverage.add.root) &&
            !this.targetRoute.includes('payor-information')
        ) {
            const dialogRef = this.modal.open(BackConfirmationDialogComponent, {
                data: { heading: 'Confirmation' },
                width: '520px',
            });
            return dialogRef.afterClosed();
        } else {
            return true;
        }
    }
}
// colworx-ak end
