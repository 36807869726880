import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'clof-btn-cell-renderer',
    template: ` <button
        (click)="btnClickedHandler($event)"
        style="height:25px"
        class="align-items-center btn-btn btn-secondary d-inline-flex flex-row  innova-btn justify-content-center float-right mt-2"
    >
        <ng-container>
            <span style="font-size:19px" class="material-symbols-outlined">edit</span>
        </ng-container>
        <span>CLOF</span>
    </button>`,
})
export class ClofBtnCellRenderer implements ICellRendererAngularComp {
    private params: any;

    agInit(params: any): void {
        this.params = params;
    }

    btnClickedHandler(event: any) {
        event.stopPropagation();
        this.params.clicked(this.params.data);
    }

    refresh() {
        return false;
    }
}
