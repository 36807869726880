/* eslint-disable spellcheck/spell-checker */
import { SkilledServicesTooltipComponent } from './../custom/skilled-services-tooltip/skilled-services-tooltip.component';
import {
    CellStyle,
    CellValueChangedEvent,
    ColDef,
    GridApi,
    GridOptions,
    RowClassParams,
    RowStyle,
} from 'ag-grid-community';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ActionsCellRendererComponent, EmptyCellRendererComponent } from '@app/data-grid/cell-renderers';
import { AuthService, CptCodesService, DailyNotesService, DocumentationService, ModalService, StoreService, TherapyAdmissionService } from '@app/services';
import { AddCptComponent } from './modals/add-cpt/add-cpt.component';
import { AddGoalsComponent } from './modals/add-goals/add-goals.component';
import { Store } from '@ngrx/store';
import { DetailedCptCodes, OtherModeMinutes, TherapyAdmission } from '@app/interfaces';
import { Subscription, switchMap, take } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { GridActionsService } from '@app/data-grid/services/grid-actions.service';
import { cloneDeep, isArray } from 'lodash';
import { GenericDeletionModalComponent } from '@app/shared/generic-deletion-modal/generic-deletion-modal.component';
import { CptCellRendererComponent } from '@app/data-grid/cell-renderers/cpt-cell-renderer/cpt-cell-renderer.component';
import { MinutesModalComponent } from '../minutes-modal/minutes-modal.component';
import { CodeViewModalComponent } from '@app/shared/code-view-modal/code-view-modal.component';
import { AddSkilledServicesComponent } from './modals/add-skilled-services/add-skilled-services.component';
import { AddProceduresComponent } from './modals/add-procedures/add-procedures.component';
import { SkillsCellRendererComponent } from '@app/data-grid/cell-renderers/skills-cell-renderer/skills-cell-renderer.component';
import { CustomCellEditorComponent } from '@app/data-grid/cell-renderers/custom-cell-editor/custom-cell-editor.component';
import { ParagraphModalComponent } from '@app/shared/paragraph-modal/paragraph-modal.component';
import { CautionModalComponent } from '@app/shared/caution-modal/caution-modal.component';
import { Doc_Notes } from '@app/interfaces/documentation';
import { AuthPolicies } from '@app/helpers/utils';

interface GridHandler {
    [gridName: string]: {
        [action in 'add' | 'edit' | 'delete' | 'addTime' | 'view']?: (gridData: any) => void;
    };
}

@Component({
    selector: 'app-skilled-services',
    templateUrl: './skilled-services.component.html',
    styleUrls: ['./skilled-services.component.scss'],
})
export class SkilledServicesComponent implements OnDestroy {
    @Input() txGreaterThanCPT: boolean;
    @Input() concurrentGreaterThanMDSMinutes: boolean;
    @Input() mode = '';
    @Input() isEditMode: boolean;
    @Output() cellValueChanged: EventEmitter<any> = new EventEmitter();
    public cptData = [];
    public doesTeleHealthExist = false;
    public otherModes: OtherModeMinutes;
    public otherModeArr = [];
    mdsCodeExists = false;
    public otherModeCols: ColDef[] = [
        { field: 'mode', headerName: 'Other Modes', width: 10 },
        {
            field: 'minutes',
            headerName: 'Minutes',
            width: 30,
            cellRenderer: CptCellRendererComponent,
            cellRendererParams: { gridName: 'otherModeMinutes' },
        },
        { field: 'discipline', headerName: 'Discipline', width: 50 },
        { field: 'justification', headerName: 'Justification', cellStyle: { 'white-space': 'normal' } },
        {
            field: 'actions',
            type: 'rightAligned',
            width: 10,
            cellClass: 'd-flex flex-column justify-content-center align-items-end',
            cellRendererSelector: (params) => {
                if (params.data.minutes !== 'Add Time') {
                    return {
                        component: ActionsCellRendererComponent,
                        params: { delete: true, edit: true, gridName: 'otherModeMinutes' },
                    };
                } else {
                    return {
                        component: EmptyCellRendererComponent,
                    };
                }
            },
        },
    ];
    public tooltipShowDelay = 0;
    public tooltipHideDelay = 999999999;
    public otherModeBtnsStatus = {
        bottom: false,
    };
    defaultCellStyle: CellStyle = {
        height: '100%',
        display: 'flex ',
        'align-items': 'center',
        'word-break': 'normal',
    };
    gridOptions: GridOptions;
    defaultColDef: ColDef = {
        flex: 1,
        filter: true,
        tooltipComponent: SkilledServicesTooltipComponent,
        suppressMovable: true,
        suppressMenu: true,
        cellStyle: this.defaultCellStyle,
        wrapText: true,
        autoHeight: true,
    };
    MIN_ROW_HEIGHT = 70;
    // Cpt codes
    cptCols = [
        {
            field: 'code',
            headerName: 'Code',
            width: 80,
            cellRendererSelector: (params) => {
                if (params.node.rowPinned) {
                    return params.data.code;
                }
            },
            cellStyle: {
                ...this.defaultCellStyle,
                'padding-left': '1vw',
            },
            tooltipValueGetter: (params) => {
                return '' + params.data.units;
            },
        },
        {
            field: 'description',
            headerName: 'Description',
            cellClass: 'text-secondary',
            width: 130,
            cellRendererSelector: (params) => {
                if (params.node.rowPinned) {
                    return '';
                } else if (params.value === null || params.value.length === 0) {
                    return {
                        component: SkillsCellRendererComponent,
                        params: {
                            description: true,
                            empty: true,
                        },
                    };
                } else {
                    return {
                        component: SkillsCellRendererComponent,
                        params: {
                            description: true,
                            data: params.value,
                        },
                    };
                }
            },
        },
        {
            field: 'time',
            headerName: 'Time',
            headerClass: 'header-center',
            cellClass: 'text-secondary',
            width: 120,
            cellStyle: { justifyContent: 'center' },
            cellRenderer: CustomCellEditorComponent,
        },
        {
            field: 'goal',
            headerName: 'Goals Addressed',
            headerClass: 'header-center',
            cellClass: 'text-secondary',
            width: 160,
            cellStyle: {
                ...this.defaultCellStyle,
                'work-break': 'normal',
                cursor: 'pointer',
                justifyContent: 'center',
            },
            onCellClicked: (event) => {
                if (event.data.code !== 'Total') {
                    this.addGoalsModal(event);
                }
            },
            cellRendererSelector: (params) => {
                if (params.node.rowPinned) {
                    return '';
                } else if (params.value === null || params.value.length === 0) {
                    return {
                        component: SkillsCellRendererComponent,
                        params: {
                            goal: true,
                            empty: true,
                        },
                    };
                } else {
                    return {
                        component: SkillsCellRendererComponent,
                        params: {
                            goal: true,
                            data: params.value,
                        },
                    };
                }
            },
        },
        {
            field: 'justification',
            headerName: 'Skilled Services & Justification for Tx',
            headerClass: 'header-center',
            tooltipField: 'Justification for Services',
            cellStyle: { cursor: 'pointer', justifyContent: 'center', wordBreak: 'break-all' },
            onCellClicked: (event) => {
                if (event.data.code !== 'Total') {
                    this.addSkilledServicesModal(event);
                }
            },
            cellRendererSelector: (params) => {
                if (params.node.rowPinned) {
                    return '';
                } else if (params.data.justification === 'NA') {
                    return {
                        component: SkillsCellRendererComponent,
                        params: {
                            justification: true,
                            empty: true,
                        },
                    };
                } else {
                    return {
                        component: SkillsCellRendererComponent,
                        params: {
                            data: params.data.justification,
                            justification: true,
                        },
                    };
                }
            },
        },
        // {
        //     field: 'exercise',
        //     headerName: 'Procedures',
        //     cellClass: 'text-secondary',
        //     width: 130,
        //     cellStyle: {
        //         ...this.defaultCellStyle,
        //         justifyContent: 'center',
        //         cursor: 'pointer',
        //     },
        //     onCellClicked: (event) => {
        //         if (event.data.code !== 'Total') {
        //             this.addProceduresModal(event);
        //         }
        //     },
        //     cellRendererSelector: (params) => {
        //         if (params.node.rowPinned) {
        //             return '';
        //         } else if (params.value === null || params.value.length === 0) {
        //             return {
        //                 component: SkillsCellRendererComponent,
        //                 params: {
        //                     procedure: true,
        //                     empty: true,
        //                 },
        //             };
        //         } else {
        //             return {
        //                 component: SkillsCellRendererComponent,
        //                 params: {
        //                     procedure: true,
        //                     data: params.data.exercise.length,
        //                     chipTooltip: params.data.exercise,
        //                 },
        //             };
        //         }
        //     },
        // },
        {
            field: 'actions',
            headerClass: 'header-center',
            width: 80,
            cellClass: 'd-flex flex-column justify-content-center align-items-center',
            cellRenderer: ActionsCellRendererComponent,
            cellRendererParams: { delete: true, gridName: 'skilledServicesDN' },
            cellRendererSelector: (params) => {
                if (params.node.rowPinned) {
                    return {
                        component: EmptyCellRendererComponent,
                    };
                }
            },
        },
    ];
    pinnedRow = [];
    private subscriptions: Subscription[] = [];
    private routeData: { doc: string; discipline: string } = {
        doc: null,
        discipline: null,
    };
    private UNDEFINED_TIME_MSG = '';
    private currentDN: any;
    private totalModes: string[] = ['coTreat', 'concurrent'];
    private gridNames: string[] = ['skilledServicesDN', 'otherModeMinutes'];
    greaterThanSixUnits;
    currentAdmissions: any;
    previousPlanTreatment = false;
    gridApi: GridApi;

    allTherapyAdmissions: TherapyAdmission[];
    currentTA: TherapyAdmission;
    allowMinutes: boolean;
    dailyNoteIndex: number;
    gridHandlers: GridHandler = {
        skilledServicesDN: {
            delete: (data: any): void => {
                const msg = this.getDeleteModalMsg();
                this.modalService
                    .open(GenericDeletionModalComponent, {
                        data: {
                            title: 'Delete Service Code',
                            message: msg,
                        },
                    })
                    .afterClosed()
                    .subscribe((result) => {
                        if (!result) {
                            return;
                        }

                        this.updateDetailedCptCode(data, this.cptData.length === 1);
                    });
            },
            edit: (data: any) => {
                this.handleEditAction(data);
            },
            view: (data: any) => {
                this.modalService.open(CodeViewModalComponent, { data });
            },
            addTime: (data: any) => {
                this.handleEditAction(data, 'time');
            },
        },
        otherModeMinutes: {
            delete: (data: any): void => {
                if (
                    (data.mode == 'Co-Treat' &&
                        (!this.currentTA.payorInformation || this.currentTA.payorInformation.payor.coTreatment)) ||
                    (data.mode == 'Concurrent' &&
                        (!this.currentTA.payorInformation || this.currentTA.payorInformation.payor.concurrent))
                ) {
                    this.modalService
                        .open(GenericDeletionModalComponent, {
                            data: {
                                title: 'Delete Record',
                                message: 'Do you really want to delete these minutes?',
                            },
                        })
                        .afterClosed()
                        .subscribe((result) => {
                            if (!result) {
                                return;
                            }
                            this.deleteOtherModeMinutes(data);
                        });
                }
            },
            edit: (data: any): void => {
                this.openLoadedModal(data, 'edit');
            },
            addTime: (data: any): void => {
                this.openLoadedModal(data, 'addTime');
            },
        },
    };
    switchValue: boolean;
    currentCptCode = '';
    policies = AuthPolicies;

    constructor(
        private modalService: ModalService,
        private store: Store<{
            therapyAdmission: TherapyAdmission[];
        }>,
        private route: ActivatedRoute,
        private gridActionsService: GridActionsService,
        private dailyNotesService: DailyNotesService,
        private cptCodeService: CptCodesService,
        private therapyAdmissionService: TherapyAdmissionService,
        private storeService: StoreService,
        private therapyService: TherapyAdmissionService,
        private documentationService: DocumentationService,
        private authService: AuthService
    ) {
        this.allowMinutes = false;
        this.gridOptions = {
            context: {
                componentParent: this,
            },
        };
        this.gridOptions.rowHeight = this.MIN_ROW_HEIGHT;

        this.route.queryParamMap.subscribe({
            next: (params) => {
                this.routeData.doc = params.get('doc');
                this.routeData.discipline = params.get('discipline');
            },
        });

        this.subscriptions.push(
            this.store.select('therapyAdmission')
                .subscribe((admissions: TherapyAdmission[]) => {
                if (!admissions || !admissions.length) {
                    return;
                }
                this.allTherapyAdmissions = JSON.parse(JSON.stringify(admissions));
                this.currentAdmissions = admissions;
                this.currentTA = this.therapyService.initCurrentTherapyAdmissionByIdQueryString(admissions);
                this.allowMinutes =
                    !this.currentTA.payorInformation ||
                    !!(
                        this.currentTA.payorInformation?.payor?.coTreatment ||
                        this.currentTA.payorInformation?.payor?.concurrent
                    );
                this.currentDN = cloneDeep(
                    this.currentTA?.documentation.dailyNote?.find((elem) => elem.id === this.routeData.doc)
                );
                this.dailyNoteIndex = this.currentTA?.documentation.dailyNote?.findIndex(
                    (elem) => elem.id === this.routeData.doc
                );

                if (this.currentDN) {
                    if (this.currentDN?.plan?.previousPlanForNextTreatment) {
                        this.previousPlanTreatment = true;
                    }
                    this.switchValue = this.currentDN.isTeleHealthEligible;
                    this.cptCodeService.setTeleHealthVisit(this.switchValue);
                    this.populateCptData();
                    this.populateOtherModesData();
                    this.evaluateOtherModesBtnStates();
                } else {
                    this.cptData = [];
                    this.otherModeArr = [];
                }
            })
        );
        this.subscriptions.push(
            this.gridActionsService.action.subscribe((info) => {
                this.gridActionHandler(info);
            })
        );
    }

    onCellValueChanged(params: CellValueChangedEvent) {
        this.cellValueChanged.emit(params);
        const payload: any = cloneDeep(this.currentDN);
        this.currentCptCode = params.data.code;

        if (!payload) {
            console.log('ERR: unable to get current Daily Note');
            return;
        }

        const foundIndex = payload.objectiveAssessment.detailedCptCodes.findIndex(
            (record: DetailedCptCodes) => record.code === this.currentCptCode
        );

        const newRecord = {
            cptMinutes: params.value,
            description: params.data.description,
            code: this.currentCptCode,
            exercises: payload.objectiveAssessment.detailedCptCodes[foundIndex].exercises,
            goals: payload.objectiveAssessment.detailedCptCodes[foundIndex].goals,
            justification: payload.objectiveAssessment.detailedCptCodes[foundIndex].justification,
            isTeleHealthEligible: payload.objectiveAssessment.detailedCptCodes[foundIndex].isTeleHealthEligible,
            isMds: payload.objectiveAssessment.detailedCptCodes[foundIndex].isMds,
        };

        payload.objectiveAssessment.detailedCptCodes[foundIndex] = newRecord;
        // Need to update the cached object as well to fix quickly adding/removing data
        this.currentDN.objectiveAssessment.detailedCptCodes[foundIndex] = newRecord;

        const currentTA = JSON.parse(JSON.stringify(this.currentTA));

        currentTA.documentation.dailyNote[this.dailyNoteIndex].objectiveAssessment.detailedCptCodes =
            payload['objectiveAssessment']['detailedCptCodes'];
        this.dailyNotesService.prepareDataForEdit(
            'objectiveAssessment',
            'detailedCptCodes',
            payload['objectiveAssessment']['detailedCptCodes'],
            'array',
            this.allTherapyAdmissions,
            currentTA,
            this.dailyNoteIndex
        );
        
        if (!this.isEditMode) {
            payload.documentation_id = this.currentTA.documentation.id;
            this.documentationService.updateDocumentationV2(currentTA, Doc_Notes.Daily_note).then((result) => {
                if (!result) {
                    return;
                }
            }, () => {
                this.therapyAdmissionService
                    .getAdmissionOnFacilities(this.currentTA.facilityAdmission)
                    .subscribe((admissions) => {
                        this.storeService.setTherapyAdmission(admissions);
                    });
            });
        }
    }

    evaluateOtherModesBtnStates() {
        this.otherModeBtnsStatus.bottom = false;
        this.otherModeBtnsStatus.bottom = !!(
            !this.otherModes.coTreat?.minutes && !this.otherModes?.concurrent?.minutes
        );
        if (!this.otherModes.coTreat?.minutes && !this.otherModes?.concurrent?.minutes) {
            if (this.cptData.length) {
                this.otherModeBtnsStatus.bottom = true;
            } else {
                this.otherModeBtnsStatus.bottom = false;
            }
        }
    }

    populateCptData() {
        const totalUnits = this.cptCodeService.calculateTotalUnits(this.currentDN.objectiveAssessment.detailedCptCodes);
        const unitsDistribution = this.cptCodeService.calculateIndividualUnits(
            this.currentDN.objectiveAssessment.detailedCptCodes
        );
        this.greaterThanSixUnits = false;

        if (unitsDistribution) {
            Object.keys(unitsDistribution).forEach((code) => {
                if (unitsDistribution[code] > 6) {
                    this.greaterThanSixUnits = true;
                }
            });
        }

        const services = this.currentDN.objectiveAssessment.detailedCptCodes.map((row) => {
            return {
                code: row.code,
                time: this.getFormattedTime(row.cptMinutes ?? row.time),
                description: row.description,
                goal: (row.goals && row.goals.map((current) => current.goal)) ?? row.goal ?? [],
                exercise: (row.exercises && row.exercises.map((current) => current.description)) ?? [],
                justification: row.justification || 'NA',
                isTeleHealthEligible: row.isTeleHealthEligible || false,
                units: unitsDistribution[row.code],
                isMds: row.isMds,
            };
        });

        const teleHealth = services.filter((cpt) => cpt.isTeleHealthEligible);
        const nonTeleHealth = services.filter((cpt) => !cpt.isTeleHealthEligible);

        // sorting data, putting telehealth codes above and non telehealth down
        this.cptData = [...teleHealth, ...nonTeleHealth];
        this.mdsCodeExists = this.cptData.some((cpt) => cpt.isMds == true);

        if (this.cptData.length) {
            const unitsString = `(${totalUnits} ${totalUnits > 1 ? 'units' : 'unit'})`;
            this.pinnedRow = [
                {
                    code: 'Total',
                    description: null,
                    time:
                        this.cptCodeService.grandTotalMinutes(this.currentDN.objectiveAssessment.detailedCptCodes) +
                        ' min ' +
                        unitsString,
                    goal: null,
                    exercise: null,
                    justification: '',
                },
            ];
        }
    }

    grandTotalMinutes(): void {
        return this.currentDN.objectiveAssessment.detailedCptCodes.reduce((acc, row) => {
            const minutes = row.cptMinutes ?? row.time;
            const rowSum = typeof minutes === 'string' ? parseInt(minutes) : minutes;
            return rowSum ? acc + rowSum : acc;
        }, 0);
    }

    mdsMinutesGrandTotal(): void {
        return this.currentDN.objectiveAssessment.detailedCptCodes.reduce((acc, row) => {
            if (row.isMds && row.code !== '97150' && row.code !== '92508') {
                const minutes = row.cptMinutes ?? row.time;
                const rowSum = typeof minutes === 'string' ? parseInt(minutes) : minutes;
                return rowSum ? acc + rowSum : acc;
            }

            return acc;
        }, 0);
    }

    populateOtherModesData() {
        this.otherModes = this.currentDN.objectiveAssessment?.otherModeMinutes;
        this.otherModeArr = [];

        // first insert modes having minutes
        if (this.otherModes) {
            const coTreat = this.otherModes.coTreat;
            const concurrent = this.otherModes.concurrent;
            if (coTreat && this.allTherapyAdmissions.length > 1) {
                this.otherModeArr.push({
                    mode: this.titleModeMapper('coTreat'),
                    minutes: coTreat?.minutes ? coTreat.minutes + '' : 'Add Time',
                    discipline: this.parseDisciplines(coTreat),
                    justification: coTreat?.justification,
                    isAllowed: !this.currentTA.payorInformation || this.currentTA.payorInformation.payor.coTreatment,
                });
            }
            if (concurrent && this.mdsCodeExists) {
                this.otherModeArr.push({
                    mode: this.titleModeMapper('concurrent'),
                    minutes: concurrent?.minutes ? concurrent?.minutes + '' : 'Add Time',
                    discipline: 'N/A',
                    justification: 'N/A',
                    isAllowed: !this.currentTA.payorInformation || this.currentTA.payorInformation.payor.concurrent,
                });
            }
        }

        // remove keys with null value (existing data)
        Object.keys(this.otherModes).forEach((key) => this.otherModes[key] === null && delete this.otherModes[key]);
        // Add other modes if these conditions exist, Reason: to stop user from accessing disabled modes
        if (this.mdsCodeExists && this.allTherapyAdmissions.length > 1) {
            const toBeAdded = this.totalModes.filter(
                (item) => this.otherModes && !Object.keys(this.otherModes).includes(item)
            );
            toBeAdded.forEach((mode) => {
                this.otherModeArr.push(this.toBeAddedPayload(mode));
            });
        }
    }

    parseDisciplines(coTreat) {
        if (coTreat?.disciplines?.length) {
            let result = '';

            // show all disciplines except the current one
            const list = coTreat.disciplines.filter(
                (elem: { discipline: string; therapistId: string }) =>
                    this.currentTA.therapyDiscipline !== elem.discipline
            );

            list.forEach((elem: any, index: number) => {
                // BUG: Edit mode coTreat update removes disciplines to N/A
                // sometimes it gets string, other times, it gets populated object
                if (typeof elem.therapistId === 'string') {
                    const therapist =
                        this.cptCodeService.allTherapists?.find((therapist) => therapist.id === elem.therapistId) ??
                        elem.therapistId;

                    if (therapist.name) {
                        result =
                            result +
                            `${therapist.name.split(' ')[0]}, ${therapist.name.split(' ')[1]} (${elem.discipline})`;
                    }
                } else {
                    if (elem?.therapistId?.user) {
                        const user = elem.therapistId.user;
                        result = result + `${user.lastName}, ${user.firstName} (${elem.discipline})`;
                    }
                }

                // add comma if it is not the last index
                if (index !== list.length - 1) {
                    result = result + ', ';
                }
            });

            if (!result.length) {
                result = 'N/A';
            }

            return result;
        }
        return 'N/A';
    }

    toBeAddedPayload(mode) {
        switch (mode) {
            case 'coTreat': {
                return {
                    mode: this.titleModeMapper(mode),
                    minutes: 'Add Time',
                    discipline: '',
                    justification: '',
                    isAllowed:  !this.currentTA.payorInformation || this.currentTA.payorInformation.payor.coTreatment,
                };
            }
            case 'concurrent': {
                return {
                    mode: this.titleModeMapper(mode),
                    minutes: 'Add Time',
                    discipline: '',
                    justification: '',
                    isAllowed:  !this.currentTA.payorInformation || this.currentTA.payorInformation.payor.concurrent,
                };
            }
        }
    }

    titleModeMapper(key: string) {
        switch (key) {
            case 'coTreat': {
                return 'Co-Treat';
            }
            case 'concurrent': {
                return 'Concurrent';
            }
            case 'Co-Treat': {
                return 'coTreat';
            }
            case 'Concurrent': {
                return 'concurrent';
            }
        }
    }

    gridActionHandler(info) {
        if (!info) {
            return;
        }

        const { actionType, data, gridName } = info;

        if (this.gridNames.includes(gridName)) {
            this.gridHandlers[gridName][actionType](data);
        }
    }

    handleEditAction(data: any, scrollTo = null) {
        this.modalService.open(AddCptComponent, {
            data: {
                heading: 'Skilled Services',
                action: 'edit',
                code: data.code,
                scrollTo,
                isEditMode: this.isEditMode,
                allTherapyAdmissions: this.allTherapyAdmissions,
                dailyNoteIndex: this.dailyNoteIndex,
            },
        });
    }

    updateDetailedCptCode = (data, isLastCptCode = false) => {
        const updatedRecord = this.currentDN.objectiveAssessment.detailedCptCodes.filter((row) =>
            isArray(data) ? !data.includes(row.code) : row.code !== data.code
        );
        // to mutate read-only props
        const updatedDN = cloneDeep(this.currentDN);
        updatedDN.objectiveAssessment.detailedCptCodes = updatedRecord;

        const mdsCodeExist = updatedDN.objectiveAssessment.detailedCptCodes.some(item => item.isMds == true);
        if (!mdsCodeExist) {
            updatedDN.objectiveAssessment.otherModeMinutes.concurrent = {};
        }
        if (updatedDN.objectiveAssessment.detailedCptCodes.length <= 0) {
            updatedDN.objectiveAssessment.otherModeMinutes.coTreat = {};
        }

        if (isLastCptCode) {
            updatedDN.objectiveAssessment.otherModeMinutes = null;
        }

        if (this.isEditMode) {
            const currentTA = JSON.parse(JSON.stringify(this.currentTA));
            currentTA.documentation.dailyNote[this.dailyNoteIndex].objectiveAssessment.detailedCptCodes =
                updatedDN['objectiveAssessment']['detailedCptCodes'];
            this.dailyNotesService.prepareDataForEdit(
                'objectiveAssessment',
                'detailedCptCodes',
                updatedDN.objectiveAssessment.detailedCptCodes,
                'array',
                this.allTherapyAdmissions,
                currentTA,
                this.dailyNoteIndex
            );
        } else {
            this.subscriptions.push(
                this.store
                    .select('therapyAdmission')
                    .pipe(
                        take(1),
                        switchMap((admissions: TherapyAdmission[]) => {
                            updatedDN.documentation_id = this.currentTA.documentation.id;

                            return this.dailyNotesService.updateDailyNote(updatedDN, admissions);
                        })
                    )
                    .subscribe()
            );
        }
    };

    getDeleteModalMsg(): string {
        const isLastCptCode: boolean = this.cptData.length === 1;

        let modalMessage = 'Do you really want to delete this Service Code?';

        if (isLastCptCode) {
            modalMessage = 'All Service Code data and other Tx minutes will be lost. \n' + modalMessage;
        }
        return modalMessage;
    }

    deleteOtherModeMinutes = (data: any): void => {
        delete this.currentDN.objectiveAssessment.otherModeMinutes[this.titleModeMapper(data.mode)];

        if (this.isEditMode) {
            const currentTA = JSON.parse(JSON.stringify(this.currentTA));
            currentTA.documentation.dailyNote[this.dailyNoteIndex].objectiveAssessment.otherModeMinutes =
                this.currentDN.objectiveAssessment.otherModeMinutes;
            this.dailyNotesService.prepareDataForEdit(
                'objectiveAssessment',
                `otherModeMinutes.${[this.titleModeMapper(data.mode)]}`,
                this.currentDN.objectiveAssessment.otherModeMinutes[this.titleModeMapper(data.mode)],
                'array',
                this.allTherapyAdmissions,
                currentTA,
                this.dailyNoteIndex
            );
            this.populateOtherModesData();
        } else {
            this.subscriptions.push(
                this.store
                    .select('therapyAdmission')
                    .pipe(
                        take(1),
                        switchMap((admissions: TherapyAdmission[]) => {
                            this.currentDN.documentation_id = this.currentTA.documentation.id;
                            return this.dailyNotesService.updateDailyNote(this.currentDN, admissions);
                        })
                    )
                    .subscribe()
            );
        }
    };

    addCptCode(event): void {
        this.modalService.open(AddCptComponent, {
            data: { heading: 'Skilled Services', event, isEditMode: this.isEditMode },
        });
    }
    addPreviousCptCode(): void {
        this.currentDN.objectiveAssessment.previousCptCodes.map((previous) => {
            this.currentDN.objectiveAssessment.detailedCptCodes.push({
                code: previous.code,
                description: previous.description,
                exercises: previous.exercises,
                isTeleHealthEligible: previous.isTeleHealthEligible,
                goals: previous.goals,
                isMds: previous.isMds,
            });
        });
        this.populateCptData();
        const payload: any = cloneDeep(this.currentDN);
        payload.documentation_id = this.currentTA.documentation.id;

        if (!payload) {
            console.log('ERR: unable to get current Daily Note');
            return;
        }

        const foundIndex = payload.objectiveAssessment.detailedCptCodes.findIndex(
            (record: DetailedCptCodes) => record.code === this.currentDN.objectiveAssessment.detailedCptCodes[0].code
        );

        const newRecord = {
            code: this.currentDN.objectiveAssessment.detailedCptCodes[0].code,
            description: this.currentDN.objectiveAssessment.detailedCptCodes[0].description,
            exercises: payload.objectiveAssessment.detailedCptCodes[foundIndex].exercises,
            goals: payload.objectiveAssessment.detailedCptCodes[foundIndex].goals,
            isTeleHealthEligible: payload.objectiveAssessment.detailedCptCodes[foundIndex].isTeleHealthEligible,
            isMds: payload.objectiveAssessment.detailedCptCodes[foundIndex].isMds,
        };

        payload.objectiveAssessment.detailedCptCodes[foundIndex] = newRecord;
        this.cptCodeService.setTeleHealthVisit(this.switchValue);

        if (this.isEditMode) {
            const currentTA = JSON.parse(JSON.stringify(this.currentTA));
            currentTA.documentation.dailyNote[this.dailyNoteIndex].objectiveAssessment.detailedCptCodes =
                this.currentDN.objectiveAssessment.detailedCptCodes;
            this.dailyNotesService.prepareDataForEdit(
                'objectiveAssessment',
                'detailedCptCodes',
                this.currentDN.objectiveAssessment.detailedCptCodes,
                'array',
                this.allTherapyAdmissions,
                currentTA,
                this.dailyNoteIndex
            );
        } else {
            this.dailyNotesService.updateDailyNote(payload, this.currentAdmissions).subscribe((result) => {
                if (!result) {
                    return;
                }
            });
        }
    }

    getFormattedTime(minutes) {
        if (!minutes) {
            return this.UNDEFINED_TIME_MSG;
        }

        const totalMinutesFormatted: string = minutes;

        return totalMinutesFormatted === '0 min' ? this.UNDEFINED_TIME_MSG : totalMinutesFormatted;
    }

    addMinutesModal() {
        this.modalService.open(MinutesModalComponent, {
            data: {
                heading: 'Other Tx Minutes',
                grandTotalMinutes: this.grandTotalMinutes(),
                isEditMode: this.isEditMode,
                allTherapyAdmissions: this.allTherapyAdmissions,
                dailyNoteIndex: this.dailyNoteIndex,
                mdsMinutesGrandTotal: this.mdsMinutesGrandTotal(),
                mdsCodeExists: this.mdsCodeExists,
                allowCoTreatment: !this.currentTA.payorInformation || this.currentTA.payorInformation.payor.coTreatment,
                allowConcurrent: !this.currentTA.payorInformation || this.currentTA.payorInformation.payor.concurrent,
            },
        });
    }
    viewPlanTreatment() {
        this.modalService.open(ParagraphModalComponent, {
            data: {
                paragraphTitle: 'Plan For Previous Treatment',
                paragraph: this.currentDN?.plan?.previousPlanForNextTreatment,
            },
        });
    }
    addGoalsModal(event) {
        this.modalService.open(AddGoalsComponent, {
            data: {
                heading: 'Goal Addressed - ' + event.data.code,
                cptCode: event.data.code,
                description: event.data.description,
                goal: event.data.goal,
                isEditMode: this.isEditMode,
                allTherapyAdmissions: this.allTherapyAdmissions,
                dailyNoteIndex: this.dailyNoteIndex,
                isTeleHealthEligible: event.data.isTeleHealthEligible,
                isMds: event.data.isMds,
            },
        });
    }

    addSkilledServicesModal(event) {
        let previousJustification = '';
        this.currentDN.objectiveAssessment.previousCptCodes.map((previous) => {
            if (previous.justification && previous.code === event.data.code) {
                previousJustification = previous.justification;
            }
        });
        this.modalService.open(AddSkilledServicesComponent, {
            data: {
                heading: 'Skilled Services & Justification for Tx - ' + event.data.code,
                cptCode: event.data.code,
                description: event.data.description,
                justification: event.data.justification,
                previousJustification: previousJustification,
                allTherapyAdmissions: this.allTherapyAdmissions,
                dailyNoteIndex: this.dailyNoteIndex,
                isEditMode: this.isEditMode,
                isTeleHealthEligible: event.data.isTeleHealthEligible,
                isMds: event.data.isMds,
            },
        });
    }

    addProceduresModal(event) {
        this.modalService.open(AddProceduresComponent, {
            data: {
                heading: 'Procedures - ' + event.data.code,
                cptCode: event.data.code,
                justification: event.data.justification,
                exercise: event.data.exercise,
            },
        });
    }

    openLoadedModal(data, action) {
        if (
            (data.mode == 'Co-Treat' &&
                (!this.currentTA.payorInformation || this.currentTA.payorInformation.payor.coTreatment)) ||
            (data.mode == 'Concurrent' &&
                (!this.currentTA.payorInformation || this.currentTA.payorInformation.payor.concurrent))
        ) {
            this.modalService.open(MinutesModalComponent, {
                data: {
                    heading: 'Other Tx Minutes',
                    action,
                    currToggle: this.titleModeMapper(data.mode),
                    otherModeMinutes: cloneDeep(this.currentDN.objectiveAssessment?.otherModeMinutes),
                    grandTotalMinutes: this.grandTotalMinutes(),
                    isEditMode: this.isEditMode,
                    allTherapyAdmissions: this.allTherapyAdmissions,
                    dailyNoteIndex: this.dailyNoteIndex,
                    mdsMinutesGrandTotal: this.mdsMinutesGrandTotal(),
                    mdsCodeExists: this.mdsCodeExists,
                    allowCoTreatment:
                        !this.currentTA.payorInformation || this.currentTA.payorInformation.payor.coTreatment,
                    allowConcurrent:
                        !this.currentTA.payorInformation || this.currentTA.payorInformation.payor.concurrent,
                },
            });
        }
    }

    getRowStyle: (params: RowClassParams) => RowStyle | undefined = (params: RowClassParams): RowStyle | undefined => {
        if (params.node.rowPinned) {
            return {
                'font-weight': 'bold',
                'font-size': '1.2em',
                'background-color': '#C2D3F6',
                height: '3em',
            };
        }
    };

    initGrid(event: any) {
        this.gridApi = event.api;
    }

    emitChangedValue() {
        const currentTA = JSON.parse(JSON.stringify(this.currentTA));
        if (this.switchValue && this.cptData.length) {
            this.modalService
                .open(CautionModalComponent, {
                    data: {
                        heading: 'Caution',
                        message: 'Non telehealth codes will be removed from the treatment',
                    },
                })
                .afterClosed()
                .subscribe((toContinue: boolean) => {
                    if (toContinue && !this.isEditMode) {
                        const targetCodes = this.cptData.reduce((acc, val) => {
                            if (this.switchValue && !val.isTeleHealthEligible) {
                                acc.push(val.code);
                            }
                            return acc;
                        }, []);
                        this.currentDN.isTeleHealthEligible = this.switchValue;
                        this.updateDetailedCptCode(targetCodes);
                    } else if (toContinue && this.isEditMode) {
                        const updatedCPT = currentTA.documentation.dailyNote[
                            this.dailyNoteIndex
                        ].objectiveAssessment.detailedCptCodes.reduce((acc, val) => {
                            if (this.switchValue && val.isTeleHealthEligible) {
                                acc.push(val);
                            }
                            return acc;
                        }, []);
                        currentTA.documentation.dailyNote[this.dailyNoteIndex].objectiveAssessment.detailedCptCodes =
                            updatedCPT;
                        this.dailyNotesService.prepareDataForEdit(
                            '',
                            'isTeleHealthEligible',
                            this.switchValue,
                            'boolean',
                            this.allTherapyAdmissions,
                            currentTA,
                            this.dailyNoteIndex
                        );
                        this.dailyNotesService.prepareDataForEdit(
                            'objectiveAssessment',
                            'detailedCptCodes',
                            updatedCPT,
                            'array',
                            this.allTherapyAdmissions,
                            currentTA,
                            this.dailyNoteIndex
                        );
                    } else if (!toContinue) {
                        this.switchValue = !this.switchValue;
                        this.cptCodeService.setTeleHealthVisit(this.switchValue);
                    }
                });
        } else {
            if (this.isEditMode) {
                this.dailyNotesService.prepareDataForEdit(
                    '',
                    'isTeleHealthEligible',
                    this.switchValue,
                    'boolean',
                    this.allTherapyAdmissions,
                    currentTA,
                    this.dailyNoteIndex
                );
            } else {
                this.currentDN.isTeleHealthEligible = this.switchValue;
                this.updateDetailedCptCode(this.cptData);
            }
        }
        this.cptCodeService.setTeleHealthVisit(this.switchValue);
    }

    ngOnDestroy(): void {
        this.subscriptions.map((sub: Subscription) => sub.unsubscribe());
    }
}
