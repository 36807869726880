import { createReducer, on } from '@ngrx/store';
import { plannerPatientChangedAction, plannerPatientRemovedAction } from '@app/store/actions/index';
import { initialPlannerPatient } from '@app/store/selectors';

export const plannerPatientReducer = createReducer(
    initialPlannerPatient,
    on(plannerPatientChangedAction, (state, { plannerPatient }) => ({
        ...plannerPatient,
    })),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    on(plannerPatientRemovedAction, (state) => ({
        ...initialPlannerPatient,
    }))
);
