// colowrx start
import { createReducer, on } from '@ngrx/store';
import { staffChangedAction, staffRemovedAction } from '@app/store/actions/index';
import { initialStaff } from '@app/store/selectors';

export const staffReducer = createReducer(
    initialStaff,
    on(staffChangedAction, (state, { staff }) => ({
        ...staff,
    })),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    on(staffRemovedAction, (state) => ({
        ...initialStaff,
    }))
);
// colowrx end
