import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  Router
} from '@angular/router';
import { ROUTER_UTILS } from '@app/helpers';
import { FacilityAdmission, TherapyAdmission } from '@app/interfaces';
import { AppService, FacilityManagementService, PatientService, StoreService } from '@app/services';
import { Store } from '@ngrx/store';
import { Observable, of, switchMap, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PccResolver implements Resolve<boolean> {

  therapyAdmissions$: Observable<TherapyAdmission[]>;
  currentTherapyAdmission$: Observable<TherapyAdmission>;
  facilityAdmission$: Observable<FacilityAdmission>;
  constructor(
    private store: Store<{ therapyAdmission: TherapyAdmission[], facilityAdmission: FacilityAdmission }>,
    private storeService: StoreService,
    private facilityManagementService: FacilityManagementService,
    private patientService: PatientService,
    private appService: AppService,
    private router: Router
  ) {
    this.therapyAdmissions$ = this.store.select('therapyAdmission');
    this.facilityAdmission$ = this.store.select('facilityAdmission');
  }

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    // * this condition only works for print clinical docs, if refresh, don't redirect to print page
    // * instead go to clinical docs because on refresh we don't have docs selected in print page
    // * due to which print shows null data.
    const pccUrl =  '/' + ROUTER_UTILS.config.patients.pcc.root;
    const therapyAdmissionUrl = pccUrl + '/' + ROUTER_UTILS.config.patients.pcc.therapyCase.root + '/' + ROUTER_UTILS.config.patients.pcc.therapyCase.therapyAdmission;
    if(route['_routerState'].url.includes('print:print/therapy-case/therapy-admission')) {
      this.router.navigate([
        therapyAdmissionUrl
      ], { queryParams: route.queryParams });
    }


    // if patient & facilityAd queryString 
    return route.queryParams['patient'] && route.queryParams['facilityAd'] ?
      this.initializeData(route) :
      of(true);
  }

  private initializeData(route: ActivatedRouteSnapshot): Observable<any> {
    const { patient, facilityAd } = route.queryParams;
    const pccMainUrls = [
      '/pcc?',
      '/pcc/therapy-case?',
      '/pcc/mds?',
      '/pcc/pdpm?',
      '/pcc/payor-information?',
      '/pcc/files-and-documents?'
    ];
    const currentUrl = route['_routerState'].url;
    return this.facilityAdmission$.pipe(take(1), switchMap(facility => {
      const facilityId = this.facilityManagementService._currentFacility.id;
      if(facility && facility.id !== route.queryParams['facilityAd']) {
        this.appService.appLoader$.next(true);
        return this.storeService.initializePatientData(
          patient,
          facilityId,
          facilityAd
        ).pipe(
          take(1),
          switchMap(() => this.patientService.getPatientAdmissionHistory(patient, facilityId).pipe(
            take(1),
            switchMap((result) => {
              this.appService.appLoader$.next(false);
              this.storeService.setPastFacilityAdmissions(result.data ?? []);
              return of(true);
            })
          ))
        )
      } else {
        const requiredUrl = pccMainUrls.find(url => currentUrl.includes(url));
        if(requiredUrl) {
          return this.patientService.getPatientAdmissionHistory(patient, facilityId).pipe(
            take(1),
            switchMap((result) => {
              this.appService.appLoader$.next(false);
              this.storeService.setPastFacilityAdmissions(result.data ?? []);
              return of(true);
            }));
        } else return of(true);
      }
    }));
  }
}
