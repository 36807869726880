/* eslint-disable spellcheck/spell-checker */
export const environment = {
    production: false,
    apiUrl: 'https://innova-cron-testing-26ae87f01b57.herokuapp.com/api/v1',
    socketUrl: 'https://innova-cron-testing-26ae87f01b57.herokuapp.com/',
    // eslint-disable-next-line spellcheck/spell-checker
    productFruitsKey: 'aW9IMXRXdzFhV0VPTGp2RA==',
    ddApplicationId: '6105cc4d-a041-4066-80ad-96935011cd05',
    ddClientToken: 'pub43dda2e6c10775600a2989fa001272da',
    ddSite: 'datadoghq.com',
    environmentName: 'staging',
    ddService: 'innova-frontend',
    ddEnv: 'staging',
    ddSampleRate: 100,
    ddReplaySampleRate: 0,
    ddTrackUserInteractions: true,
    ddTrackResources: true,
    ddTrackLongTasks: true,
    ddAllowedTracingUrls: [(url) => url.startsWith('https://innova-cron-testing-26ae87f01b57.herokuapp.com')],
};
