<div class="d-flex align-items-center custom-cell-renderer-wrapper" [ngClass]="{'mt-2':gridName=='insuranceCoverage','opacity-50 pointer-events-none':gridName=='insuranceCoverage' && !comment}">
    <span *ngIf="value">{{ value }}</span>
    <span
        *ngIf="comment || gridName=='insuranceCoverage'"
        container="body"
        class="ml-2 material-icons-outlined text-secondary custom-cell-icon-font"
        [ngbTooltip]="comment"
        placement="right-bottom"
        [tooltipClass]="'ngb-therapist-caseload-comment'"
        >comment</span
    >
    <span
        *ngIf="cellParams.data.visitStatus == 'Completed'"
        id="visit-status-completed">
        <span class="material-symbols-outlined"> done </span>
    </span>
    <!-- <span
        *ngIf="cellParams.data.isSplit"
        class="badge p-2 px-1 rounded-pill d-inline-block ml-2 align-middle split-pill"
        >Split Treatment</span
    > -->
</div>
