import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { findTherapyCaseByParamId } from '@app/helpers/utils/therapy-case.utils';
import { FacilityAdmission, TherapyAdmission } from '@app/interfaces';
import { PrintData } from '@app/interfaces/print';
import { StoreService } from '@app/services';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';

@Component({
    selector: 'app-addendum',
    templateUrl: './addendum.component.html',
    styleUrls: ['./addendum.component.scss'],
})
export class AddendumComponent implements OnInit {
    @Input() printMode = false;
    @Input() printData: PrintData;
    public addendum: any;

    currentAdmission: TherapyAdmission;
    therapyDiscipline: string;
    docId: string;
    loadingObs: any;
    constructor(
        private route: ActivatedRoute,
        private storeService: StoreService,
        private store: Store<{
            therapyAdmission: TherapyAdmission[];
            facilityAdmission: FacilityAdmission;
        }>
    ) {
        this.loadingObs = this.storeService.getLoadingState();
        this.route.queryParamMap.subscribe({
            next: (params) => {
                this.therapyDiscipline = params.get('discipline');
                this.docId = params.get('doc');
            },
        });
    }

    ngOnInit(): void {
        if (this.printMode) {
            const { discipline, id } = this.printData;
            this.therapyDiscipline = discipline;
            this.docId = id;
        }

        this.store.select('therapyAdmission').subscribe((data) => {
            const queryString = this.route.snapshot.queryParams;
            this.currentAdmission = findTherapyCaseByParamId(data, queryString['therapy-case']);
            this.therapyDiscipline ||= this.currentAdmission?.therapyDiscipline;
            this.therapyDiscipline ||= 'PT';
            this.currentAdmission = cloneDeep(this.currentAdmission);

            this.addendum = this.currentAdmission?.documentation?.addendum.filter((documentation) => {
                if (typeof documentation.id === 'string') {
                    return documentation.id === this.docId;
                } else {
                    return Object.values(documentation)[3].value === this.docId;
                }
            })[0];
        });
    }
}
