<table class="w-100">
    <!-- <thead *ngIf="repeatingLogo">
        <tr>
            <td>
                <div class="header-space">
                    <div class="header"><img src="../../../assets/logo/logo.svg" /></div>
                </div>
            </td>
        </tr>
    </thead> -->
    <tbody>
        <tr>
            <td>
                <div class="content mt-5">
                    <!-- give any url that you want to print and also set it in its routing module -->
                    <router-outlet></router-outlet>
                </div>
            </td>
        </tr>
    </tbody>
</table>
<!-- <div class="footer"><hr /></div> -->
