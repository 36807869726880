import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'click-btn-renderer',
    template: `<span class="cursor-pointer body-medium-bold text-secondary d-inline-block align-middle">ADD</span> `,
})
export class ClickBtnRenderer implements ICellRendererAngularComp {
    private params: any;

    agInit(params: any): void {
        this.params = params;
    }

    btnClickedHandler(event: any) {
        event.stopPropagation();
        this.params.clicked(this.params.data);
    }

    refresh() {
        return false;
    }
}
