import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'disciplineAbbreviation',
})
export class DisciplineAbbreviationPipe implements PipeTransform {
    transform(value: unknown): unknown {
        switch (value) {
            case 'OT': {
                return 'Occupational Therapy';
                break;
            }
            case 'PT': {
                return 'Physical Therapy';
            }
            case 'ST': {
                return 'Speech Therapy';
            }
            default:
                return '';
                break;
        }
    }
}
