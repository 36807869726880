import { createReducer, on } from '@ngrx/store';
import { payorInformationChangedAction, payorInformationRemovedAction } from '@app/store/actions/index';
import { initialPayorInformation } from '@app/store/selectors';

export const payorInformationReducer = createReducer(
    initialPayorInformation,
    on(payorInformationChangedAction, (state, { payorInformation }) => ({
        ...payorInformation,
    })),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    on(payorInformationRemovedAction, (state) => ({
        ...initialPayorInformation,
    }))
);
