import { ActionsCellRendererComponent } from './../../../data-grid/cell-renderers/actions-cell-renderer/actions-cell-renderer.component';
import { SwitchCellRendererComponent } from './../../../data-grid/cell-renderers/switch-cell-renderer/switch-cell-renderer.component';
import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import {
    CardPayload,
    CustomControlType,
    FrequencyAndDuration,
    InputConfiguration,
    TherapyAdmission,
    TherapyDiscipline,
} from './../../../interfaces';
import { ColDef } from 'ag-grid-community';
import { Observable, Subject, debounceTime } from 'rxjs';
import { DocumentationService } from '@app/services';
import { GridActionsService } from '@app/data-grid/services/grid-actions.service';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { findTherapyCaseByParamId } from '@app/helpers/utils/therapy-case.utils';
@Component({
    selector: 'app-frequency-and-duration',
    templateUrl: './frequency-and-duration.component.html',
    styleUrls: ['./frequency-and-duration.component.scss'],
})
export class FrequencyAndDurationComponent implements OnInit, OnDestroy {
    @Input() dataInput: any;
    @Output() updatedFrequencyAndDuration = new EventEmitter<FrequencyAndDuration>();
    data: Subject<any> = new Subject<any>();
    frequencyAndDuration: any[] = [];
    therapyAdmissions$: Observable<TherapyAdmission[]>;
    currentAdmission: TherapyAdmission;
    // Frequency and Duration
    therapyDiscipline;
    suggestionsList: string[] = [];
    controls: InputConfiguration[] = [
        {
            type: 'suggestion-box' as CustomControlType,
            column: '12',
            key: 'description',
            class: 'w-100',
            isSuggestionBox: true,
            suggestionsList: this.suggestionsList,
            label: 'Description',
            onChange: (e) => console.log('Search on change', e),
        },
    ];
    frequencyFromOptions = ['1x', '2x', '3x', '4x', '5x', '6x', '7x'];
    frequencyToOptions = ['1x', '2x', '3x', '4x', '5x', '6x', '7x'];
    filteredFrequencyToOptions = cloneDeep(this.frequencyToOptions);
    durationParamOptions = ['Weeks'];
    frequencyIntensityOptions = ['Daily', 'BID'];
    frequencyControls: InputConfiguration[] = [
        // {
        //     type: 'switch' as CustomControlType,
        //     column: 'lg-1',
        //     key: 'range',
        //     label: 'Range',
        //     defaultValue: false,
        //     onChange: (e) => console.log('Switch button on change', e),
        // },
        {
            type: 'merge' as CustomControlType,
            column: 'lg-3',
            key: 'frequency',
            label: 'Frequency',
            labelClass: 'm-0',
            mergeClass: 'd-flex flex-column mr-5',
            mergeStyle: 'margin-top: -16px;',
            merge: [
                {
                    type: 'select' as CustomControlType,
                    backgroundClass: 'bg-body-background',
                    key: 'frequencyFrom',
                    placeholder: 'Frequency',
                    // label: 'Frequency From',
                    selectOptions: this.frequencyFromOptions,
                    onChange: (e) => console.log('frequencyFrom on change', e),
                },
                // {
                //     type: 'label' as CustomControlType,
                //     key: 'toLabel',
                //     label: 'to',
                //     class: 'ml-2 mr-2',
                // },
                // {
                //     type: 'select' as CustomControlType,
                //     backgroundClass: 'bg-body-background',
                //     key: 'frequencyTo',
                //     placeholder: 'To',
                //     disableToggle: ['range', 'frequencyFrom'],
                //     // label: 'Frequency From',
                //     selectOptions: this.frequencyToOptions,
                //     onChange: (e) => console.log('frequencyTo on change', e),
                // },
            ],
        },
        {
            type: 'merge' as CustomControlType,
            column: 'lg-3',
            key: 'duration',
            label: 'Duration',
            labelClass: 'm-0',
            mergeClass: 'd-flex flex-row align-items-center justify-content-start',
            mergeStyle: 'margin-top: -16px;',
            merge: [
                {
                    type: 'text' as CustomControlType,
                    key: 'duration',
                    placeholder: '',
                    class: 'form-control',
                    backgroundClass: 'bg-body-background',
                    wrapperClass: 'p-0 m-0 col-4 mr-2',
                    // wrapperStyle: 'margin-bottom: 31px !important;', ( remove margin bottom )
                    // label: 'Frequency From',
                    onChange: (e) => console.log('Duration time on change', e),
                    validationType: 'number',
                    regex: new RegExp('^([1-9]|1[0-3])$'),
                },
                {
                    type: 'select' as CustomControlType,
                    key: 'durationType',
                    placeholder: 'Duration',
                    wrapperClass: 'ml-2',
                    backgroundClass: 'bg-body-background',
                    // label: 'Frequency From',
                    selectOptions: this.durationParamOptions,
                    onChange: (e) => console.log('Duration params on change', e),
                },
            ],
        },
        // {
        //   type: 'select' as CustomControlType,
        //   column: 'lg-3',
        //   key: 'duration',
        //   label: 'Duration',
        //   selectOptions: this.frequencyFromOptions,
        //   onChange: (e) => console.log('duration on change', e),
        // },
        // {
        //     type: 'select' as CustomControlType,
        //     column: 'lg-3',
        //     key: 'intensity',
        //     label: 'Intensity',
        //     selectOptions: this.frequencyIntensityOptions,
        //     backgroundClass: 'bg-body-background',
        //     onChange: (e) => console.log('intensity on change', e),
        // },
    ];
    frequencyCols: ColDef[] = [
        {
            field: 'range',
            headerName: 'Range',
            cellRenderer: SwitchCellRendererComponent,
            cellRendererParams: { disabled: true },
        },
        { field: 'frequencyFrom', headerName: 'Frequency From', hide: true },
        { field: 'frequencyTo', headerName: 'Frequency To', hide: true },
        // { field: 'frequency', headerName: "Frequency", valueGetter: (params) => params.data.frequencyTo !== undefined ? `${params.data.frequencyFrom} to ${params.data.frequencyTO}` : params.data.frequencyFrom },
        {
            field: 'frequency',
            headerName: 'Frequency',
            valueGetter: (params) =>
                params.data.frequencyTo.length !== 0
                    ? `${params.data.frequencyFrom} to ${params.data.frequencyTo}`
                    : params.data.frequencyFrom,
        },
        { field: 'duration', headerName: 'Duration' },
        //{ field: 'intensity', headerName: 'Intensity' },
        {
            field: 'actions',
            type: 'rightAligned',
            cellRenderer: ActionsCellRendererComponent,
            cellRendererParams: { delete: true, gridName: 'frequencyCols' },
            // cellRendererParams: { delete: true, edit: true, gridName: 'frequencyCols' },
        },
    ];
    durationReset = false;

    // frequencyAndDurationForm: FormGroup;
    constructor(
        private documentationService: DocumentationService,
        private store: Store<{ therapyAdmission: TherapyAdmission[] }>,
        private route: ActivatedRoute,
        private gridActionsService: GridActionsService,
        private fb: FormBuilder
    ) {
        // this.frequencyAndDurationForm = this.fb.group({
        //     frequency: ['', [Validators.required]],
        //     duration: this.fb.group({
        //         duration: [''],
        //         durationType: ['Weeks', [Validators.required]],
        //     }),
        // });
        this.route.queryParamMap.subscribe({
            next: (params) => {
                this.therapyDiscipline = params.get('discipline') as TherapyDiscipline;
            },
        });
        //this.documentationService.initializeDocumentation(); comment as it causes issues in recertification
        this.therapyAdmissions$ = this.store.select('therapyAdmission');
        this.therapyAdmissions$.subscribe((data) => {
            const queryString = this.route.snapshot.queryParams;
            this.currentAdmission = findTherapyCaseByParamId(data, queryString['therapy-case']);
            this.therapyDiscipline ||= this.currentAdmission?.therapyDiscipline;
            this.therapyDiscipline ||= 'PT';
            this.currentAdmission = cloneDeep(this.currentAdmission);

            this.frequencyAndDuration = this.currentAdmission?.documentation.evaluation.planOfCare.frequencyAndDuration;
        });
        this.gridActionsService.action.subscribe((data) => {
            if (data.gridName === 'frequencyCols' && data.actionType === 'delete') {
                this.removeItem('frequencyAndDuration', 'frequencyAndDuration', data.data);
            }
        });
    }
    ngOnInit() {
        this.data.pipe(debounceTime(500)).subscribe((data) => {
            this.setDocStore('frequencyAndDuration', [{ ...this.buildFrequencyAndDuration(data) }], 'description');
        });
    }
    ngOnDestroy() {
        this.data.unsubscribe();
    }
    defaultAction: (payload: any) => void = (payload: CardPayload) => {
        if (payload.viewChangeEvent) {
            payload.viewChangeEvent(payload.targetView);
        }
    };
    addTo(arr: string, data: any) {
        this[arr] = [...this[arr], data];
    }
    removeItem(storeKey: string, arr: any, data: any) {
        this[arr] = this[arr].filter((item) => JSON.stringify(item) !== JSON.stringify(data));
        this.setDocStore(storeKey, this[arr]);
    }
    // To set store key descriptions with new array data
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setDocStore(attribute: string, data: any, key?: string) {
        // if (this.isValidFrequencyAndDuration(data[0])) {
        this.updatedFrequencyAndDuration.emit(data);
        // }
    }
    isValidFrequencyAndDuration(frequencyAndDuration) {
        let valid = true;
        for (const key in frequencyAndDuration) {
            const value = frequencyAndDuration[key];
            if (key == 'frequencyTo' && !frequencyAndDuration['range']) {
                valid = true;
            } else if (key != 'range' && !value) {
                valid = false;
            }
        }
        if (this.durationReset) {
            valid = true;
            frequencyAndDuration.duration = '';
        }
        this.durationReset = false;
        return valid;
    }
    dataHandler(value: any) {
        this.adjustDurationRegex(value);
        this.data.next(value);
    }
    adjustDurationRegex(value) {
        const found = this.frequencyControls.find((x) => x.key === 'duration').merge.find((y) => y.key === 'duration');
        if (value.duration.durationType === 'Weeks') {
            found.regex = new RegExp('^([1-9]|1[0-3])$');
        } else found.regex = new RegExp('^([1-9]|[1-8][0-9]|90)$');
    }
    buildFrequencyAndDuration(value) {
        return {
            range: value.range,
            frequencyFrom: value.frequency.frequencyFrom,
            frequencyTo: value.frequency.frequencyTo,
            duration: value.duration.duration,
            durationType: value.duration.durationType,
            //intensity: value.intensity,
        };
    }
    setFrequencyToOptions(value: string) {
        const index = this.frequencyFromOptions.findIndex((x) => x === value);
        //this.frequencyToOptions = this.frequencyFromOptions.slice(0, index);
        this.frequencyToOptions.length = 0;
        for (let i = index; i < this.frequencyFromOptions.length; i++) {
            this.frequencyToOptions.push(this.frequencyFromOptions[i]);
        }
    }
    /* setDuration(selectedProperty: string) {
        if (
            Object.keys(selectedProperty)[0] === 'durationType' ||
            (Object.keys(selectedProperty)[0] === 'duration' && Object.values(selectedProperty)[0] == '')
        ) {
            const frequencyAndDuration = this.frequencyAndDuration[0];
            if (frequencyAndDuration) {
                Object.keys(frequencyAndDuration).forEach((key) => {
                    if (key != 'range' && key != 'frequencyTo') {
                        if (key != selectedProperty) {
                            if (frequencyAndDuration[key]) {
                                this.durationReset = true;
                            } else {
                                this.durationReset = false;
                            }
                        }
                    }
                });
            }
        }
    }*/
}
