import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';

@Component({
    selector: 'app-signatures-cell-renderer',
    templateUrl: './signatures-cell-renderer.component.html',
    styleUrls: ['./signatures-cell-renderer.component.scss'],
})
export class SignaturesCellRendererComponent {
    public signatures;

    agInit(params: ICellRendererParams): void {
        this.signatures = params?.data?.therapist;
    }

    refresh(): boolean {
        return false;
    }
}
