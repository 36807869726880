import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Inject, ViewChild } from '@angular/core';
import { AuthPolicies, getDayName, getPatientHoursAssignmentValue, getProgressBarType } from '@app/helpers/utils';
import { SideNavData, Ref } from '@app/helpers/toaster';
import { PlannedDay, TherapistCard } from '@app/interfaces';
import { AuthService, PrintService, SchedulerService, ToasterService } from '@app/services';
import { Router } from '@angular/router';

@Component({
    selector: 'app-scheduler-right-bar',
    templateUrl: './scheduler-right-bar.component.html',
    styleUrls: ['./scheduler-right-bar.component.scss'],
    animations: [
        trigger('openClose', [
            state(
                'open',
                style({
                    opacity: 1,
                })
            ),
            state(
                'closed',
                style({
                    opacity: 1,
                })
            ),
            transition('open => closed', [animate('200ms', style({ transform: 'translateX(100%)' }))]),
            transition('* => open', [style({ opacity: 1, transform: 'translateX(300px)' }), animate('200ms')]),
        ]),
        trigger('removePatient', [
            transition('* => void', [
                animate('0.5s ease'),
                style({
                    transform: 'translateX(-300px)',
                    opacity: 0,
                }),
            ]),
            transition('void => *', [
                animate('0.2s ease'),
                style({
                    transform: 'translateX(-100px)',
                    opacity: 0,
                }),
            ]),
        ]),
    ],
})
export class SchedulerRightBarComponent {
    buttons = [{ text: 'Planned', isSelected: true }, { text: 'Unplanned' }];
    state = 'open';
    therapist: TherapistCard;
    scheduledTime: number;
    totalTime: number;
    @ViewChild('caseLoad') caseLoad;
    currentDay: Date;
    checkPermission = {
        edit: false,
        add: false,
    };
    policies = AuthPolicies;

    constructor(
        @Inject(SideNavData) public data: any,
        readonly ref: Ref,
        private schedulerService: SchedulerService,
        private printService: PrintService,
        private router: Router,
        private toasterService: ToasterService,
        private authService: AuthService
    ) {
        this.checkPermission.edit = this.authService.applyPolicy(this.policies.Scheduler.Edit);
        this.checkPermission.add = this.authService.applyPolicy(this.policies.Scheduler.Add);
        this.therapist = data.therapist;
        this.currentDay = new Date(data.date);

        this.updateMinutes();
        this.patchTreatedStatus();
    }
    updateMinutes() {
        const day = getDayName(this.currentDay.getDay());
        this.scheduledTime = getPatientHoursAssignmentValue(this.therapist, day).scheduledTime;
        this.totalTime = this.therapist.availability.days[day].time;
    }

    close() {
        this.state = 'closed';
        setTimeout(() => {
            this.ref.close();
            this.ref.afterClose.next(true);
        }, 180);
    }

    selectPatientCard(event) {
        this.therapist.patients.find((p) => p.mrn === event.mrn && !p.isTreated).isSelected = !event.isSelected;
    }
    getSelectedPatients() {
        let count = 0;
        this.therapist.patients.forEach((patient) => {
            patient.isSelected ? (count += 1) : '';
        });
        return count;
    }

    selectAllPatients() {
        this.therapist.patients.map((p) => {
            if (!p.isTreated) p.isSelected = true;
        });
    }
    cancelPatientSelection() {
        this.therapist.patients.map((p) => (p.isSelected = false));
    }
    unAssignSelectedPatients(autoReScheduling, count) {
        if (count == 0) {
            this.toasterService.show({
                title: 'No Patient Selected',
                body: 'Please select patients first!',
                type: 'warning',
            });
            return;
        }
        // eslint-disable-next-line prefer-const
        let { untreated, days } = this.filterSelectedPatients();
        this.schedulerService.savePatientDays([days]).subscribe({
            next: () => {
                untreated = untreated.map((ut) => {
                    const plannedDay = ut.planTherapy.plannedDay as unknown as PlannedDay[];
                    plannedDay.find(
                        (pd) => new Date(pd.day).setHours(0, 0, 0, 0) === new Date(this.data.date).setHours(0, 0, 0, 0)
                    ).assignedTherapists = [];
                    ut.isSelected = false;
                    return ut;
                });
                this.ref.afterActionData.next(untreated);
                // eslint-disable-next-line spellcheck/spell-checker
                untreated.forEach((utp) => {
                    const foundIndex = this.therapist.patients.findIndex(
                        // eslint-disable-next-line spellcheck/spell-checker
                        (p) => p.discipline === utp.discipline && p.id === utp.id
                    );
                    this.therapist.patients.splice(foundIndex, 1);
                });
                if (!autoReScheduling) {
                    this.toasterService.show({
                        title: 'Patient' + (count > 1 ? 's' : '') + ' Unassigned successfully',
                        body: 'Please go to left patient list for re-scheduling',
                        type: 'success',
                    });
                }
            },
            complete: () => {
                if (autoReScheduling)
                    this.schedulerService.autoReScheduling(untreated, this.currentDay, this.therapist);
                this.updateMinutes();
            },
            error: () => {
                this.toasterService.show({
                    title: 'Something went wrong!',
                    body: 'Please try again later.',
                    type: 'error',
                });
            },
        });
    }

    get selectedPatients() {
        return this.therapist.patients.filter((p) => p.isSelected).length;
    }

    filterSelectedPatients() {
        const untreated = this.therapist.patients.filter((p) => p.isSelected);
        const days = untreated
            .map((up) => {
                return up['weekFrequency'].find(
                    (wf) => new Date(wf.day.day).setHours(0, 0, 0, 0) === new Date(this.data.date).setHours(0, 0, 0, 0)
                ).day;
            })
            .map((day) => {
                day.id = day._id;
                day.assignedTherapists = [];
                return day;
            });
        return { untreated, days };
    }

    patchTreatedStatus() {
        this.therapist.patients.map((p) => {
            if (
                p['weekFrequency'].find(
                    (wf) => new Date(wf.day?.day).setHours(0, 0, 0, 0) === new Date(this.data.date).setHours(0, 0, 0, 0)
                ).day.treated
            ) {
                p.isTreated = true;
            }
        });
    }

    initiatePrinting() {
        this.close();
        // const therapistIds = ['101', '102'];
        // this.printService.printDocument('therapist', therapistIds);
        // this.caseLoad.printdoc();
        // this.printService.onDataReady()
        this.router.navigate(
            [
                '/',
                {
                    outlets: {
                        print: ['print', 'therapist', 'schedule'],
                    },
                },
            ],
            { state: { therapist: this.therapist }, queryParams: { date: this.currentDay.toISOString() } }
        );
    }
    getType(minutes): string {
        return getProgressBarType(minutes);
    }
    getValue(therapist): { assignmentValue: any; scheduledTime: any } {
        return getPatientHoursAssignmentValue(therapist, getDayName(new Date(this.currentDay).getDay()));
    }
}
