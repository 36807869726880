<app-grid-card
    enterTheViewportNotifier
    rootMargin="-35% 0%"
    threshold="0.25"
    noDataTargetView="ADD_MORE"
    [heading]="heading"
    [columnDefs]="precautionCols"
    [rowData]="precautionsData"
    [defaultAction]="defaultAction"
    [controls]="precautionsControls"
    [isReadonly]="isReadonly"
    (onChange)="updateList($event.description)"
    [view]="viewMode"
    (viewOut)="updateViewMode($event)"
    (dataForEditOut)="dataForEditOut($event)"
    [dataForEdit]="editData"
>
</app-grid-card>
