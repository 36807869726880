import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { PayorInformation } from '@app/interfaces';

const baseUrl = `${environment.apiUrl}/payor-information`;

@Injectable({ providedIn: 'root' })
export class PayorInformationService {
    insuranceCoverageList: any = {};

    //payorListFormState = new Subject<object>();
    payorListFormState: BehaviorSubject<any> = new BehaviorSubject([]);

    // latest saved payor from DB (used for reverting in cancel case )
    payorJSON: any;

    constructor(private http: HttpClient) {}

    createPayorInformation(payor: PayorInformation) {
        return this.http.post<any>(baseUrl, payor);
    }
    updatePayorInformation(payor: PayorInformation) {
        return this.http.patch<any>(baseUrl, payor);
    }
    getAllInsurances(facilityAdmissionId: string) {
        return this.http.get<any>(`${baseUrl}/${facilityAdmissionId}/insurances`).pipe(map((v) => v.data));
    }

    getPayorInformationByFacilityAdmissionId(facilityAdmissionId: string): Observable<{
        data: PayorInformation;
        success: boolean;
        message: string;
    }> {
        return this.http.get<{ data: PayorInformation; success: boolean; message: string }>(
            `${baseUrl}/facilityAdmission/${facilityAdmissionId}`,
            {
                withCredentials: true,
            }
        );
    }

    getPayorInformationByPayorId(payorId: string): any {
        return this.http.get(`${baseUrl}/payorInformation/${payorId}`, {
            withCredentials: true,
        });
    }
}
