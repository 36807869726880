export const getNestedObjectValue = (data, keyToFind: string) => {
    let finalValue;
    Object.keys(data).forEach((x) => {
        if (typeof x === 'object' && !Array.isArray(x)) {
            getNestedObjectValue(data[x], keyToFind);
        } else {
            if (keyToFind === x) finalValue = data[x];
        }
    });
    return finalValue;
};

export const isEmpty = <T>(object: T) => {
    return !object || !Object.keys(object).length;
}