import { Subject } from 'rxjs';

export class RequestAbortController {
    public abortController: Subject<void>;
    constructor() {
        this.abortController = new Subject<void>();
    }
    cancelPreviousRequest() {
        if (this.abortController) {
            this.abortController?.next();
            this.abortController?.complete();
            this.abortController = new Subject<void>();
        }
    }
    cleanup(): void {
        this.abortController?.next();
        this.abortController?.complete();
    }
}