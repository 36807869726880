import { Pipe, PipeTransform } from '@angular/core';
import { TherapyAdmission } from '@app/interfaces';

@Pipe({
    name: 'getTherapyDiscipline',
})
export class GetTherapyDisciplinePipe implements PipeTransform {
    transform(value: TherapyAdmission[]): unknown {
        return value.map((admission) => {
            return admission.therapyDiscipline;
        });
    }
}
