/* eslint-disable @typescript-eslint/no-unused-vars */
import { createReducer, on } from '@ngrx/store';
import {
    cachedFacilityAdmissionChangedAction,
    cachedFacilityAdmissionRemovedAction,
    facilityAdmissionChangedAction,
    facilityAdmissionRemovedAction,
    pastFacilityAdmissionsChangedAction,
    pastFacilityAdmissionsRemovedAction,
    assignFacilitiesChangedAction,
    assignFacilitiesRemovedAction,
} from '@app/store/actions/index';
import { initialFacilityAdmission, initialPastFacilityAdmissions, initialAssignFacilities  } from '@app/store/selectors';
export const pastFacilityAdmissionsReducer = createReducer(
    initialPastFacilityAdmissions,
    on(pastFacilityAdmissionsChangedAction, (state, { facilityAdmissions }) => [...facilityAdmissions]),
    on(pastFacilityAdmissionsRemovedAction, () => ({
        ...initialPastFacilityAdmissions,
    }))
);
export const facilityAdmissionReducer = createReducer(
    initialFacilityAdmission,
    on(facilityAdmissionChangedAction, (state, { facilityAdmission }) => ({
        ...facilityAdmission,
    })),
    on(facilityAdmissionRemovedAction, (state) => ({
        ...initialFacilityAdmission,
    }))
);
export const cachedFacilityAdmissionReducer = createReducer(
    initialFacilityAdmission,
    on(cachedFacilityAdmissionChangedAction, (state, { facilityAdmission }) => ({
        ...facilityAdmission,
    })),
    on(cachedFacilityAdmissionRemovedAction, (state) => ({
        ...initialFacilityAdmission,
    }))
);
// colworx-ak start
export const assignFacilitiesReducer = createReducer(
    initialAssignFacilities,
    on(assignFacilitiesChangedAction, (state, { assignFacilities }) => ({
        ...assignFacilities,
    })),
    on(assignFacilitiesRemovedAction, (state) => ({
        ...initialAssignFacilities,
    }))
);
// colworx-ak end
