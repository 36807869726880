import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DOC_PRINT_ROUTE } from '@app/helpers/constants/documentation-report';
import { PrintService } from '@app/services';
@Component({
    selector: 'app-print-layout',
    templateUrl: './print-layout.component.html',
    styleUrls: ['./print-layout.component.scss'],
})
export class PrintLayoutComponent {
    repeatingLogo = true;
    isClinicalDocPrint = false;

    constructor(
        public printService: PrintService,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {
        this.isClinicalDocPrint = this.router.url.includes(DOC_PRINT_ROUTE);
        this.activatedRoute.queryParams.subscribe((params) => {
            if (params?.tab === 'documentation') {
                this.repeatingLogo = false;
            }
        });
    }
}
