<app-base-modal-card
    [heading]="heading"
    wrapperClass="modal-medium"
    (close)="close()"
    [inputStyle]="'overflow-y: unset'"
>
    <div body class="p-3 rounded">
        <div class="text-secondary ml-3">Please select Quarterly OBRA Assessment Rehab Date</div>

        <div body class="ml-0 mt-3 col-7">
            <app-date-picker
                required
                label="ARD"
                placeholder="MM/DD/YYYY"
                [hideDotBadge]="true"
                [(ngModel)]="selectedDate"
                [maxDate]="today"
            >
            </app-date-picker>
        </div>
        <!-- [minDate]="minDate" -->
        <!-- [maxDate]="today" -->
    </div>
    <div footer class="w-100 d-flex flex-row align-items-center justify-content-end">
        <button class="btn btn-outline-secondary rounded-pill d-flex flex-row align-items-enter" (click)="close()">
            Cancel
        </button>
        <button
            [disabled]="!selectedDate"
            style="margin-left: 1vw"
            class="btn btn-secondary rounded-pill d-flex flex-row align-items-enter"
            (click)="generateReport()"
        >
            Download Report
        </button>
    </div>
</app-base-modal-card>
