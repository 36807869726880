import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
    ADLs_SELF_CARE,
    AssistiveDevices,
    COGNITIVE_LANGUAGE_MEMORY,
    CONSTANTS,
    DEFICITS_SCALE,
    FUNCTIONAL_DEFICITS,
    GG_SCORE,
} from '@app/interfaces/documentation';

@Injectable({
    providedIn: 'root',
})
export class DeficitsService {
    getFunctionalDeficits() {
        return [
            {
                text: '1 step (curb)',
                existsIn: ['PT', 'OT'],
                hasGGScore: true,
                isCompleted: false,
            },
            {
                text: '4 steps (stairs)',
                existsIn: ['PT', 'OT'],
                hasGGScore: true,
                isCompleted: false,
            },
            {
                text: '12 steps (stairs)',
                existsIn: ['PT', 'OT'],
                hasGGScore: true,
                isCompleted: false,
            },
            {
                text: 'Car transfer',
                existsIn: ['PT', 'OT'],
                hasGGScore: true,
                isCompleted: false,
                assistiveDevices: [...this.getAssistiveDevices(), ...this.getCarTransferADs()],
            },
            {
                text: 'Chair/bed to chair transfer',
                existsIn: ['PT', 'OT'],
                hasGGScore: true,
                isCompleted: false,
                assistiveDevices: [...this.getAssistiveDevices(), ...this.getChairBedToChairTransferADs()],
            },
            {
                text: 'General Wheelchair Mobility',
                existsIn: ['PT', 'OT'],
                hasGGScore: false,
                isCompleted: false,
                assistiveDevices: this.getGeneralWheelChairMobilityADs(),
            },
            {
                text: 'Lying to sitting on side of bed',
                existsIn: ['PT', 'OT'],
                hasGGScore: true,
                isCompleted: false,
                assistiveDevices: this.getLyingToSittingOnSideOfBed(),
            },
            {
                text: 'Pick up object from floor',
                existsIn: ['PT', 'OT'],
                hasGGScore: true,
                isCompleted: false,
            },
            {
                text: 'Roll left to right while in bed',
                existsIn: ['PT', 'OT'],
                hasGGScore: true,
                isCompleted: false,
                assistiveDevices: this.getRollLeftToRightWhileInBedADs(),
            },
            {
                text: 'Sit to lying while in bed',
                existsIn: ['PT', 'OT'],
                hasGGScore: true,
                isCompleted: false,
                assistiveDevices: this.getSitToLyingWhileInBedADs(),
            },
            {
                text: 'Sit to stand',
                existsIn: ['PT', 'OT'],
                hasGGScore: true,
                isCompleted: false,
            },
            {
                text: 'Toilet transfer',
                existsIn: ['PT', 'OT'],
                hasGGScore: true,
                isCompleted: false,
            },
            {
                text: 'Tub/Shower Transfer',
                existsIn: ['PT', 'OT'],
                hasGGScore: true,
                isCompleted: false,
                assistiveDevices: [
                    ...this.getAssistiveDevices().filter(
                        (x) =>
                            x !== 'Wheelchair' &&
                            x !== 'Power Wheelchair' &&
                            x !== 'Cardiac/Support Walker' &&
                            x !== 'Transport Chair'
                    ),
                    ...this.getTubShowerTransferADs(),
                ],
            },
            {
                text: 'Walk 10 feet',
                existsIn: ['PT', 'OT'],
                hasGGScore: true,
                isCompleted: false,
            },
            {
                text: 'Walk 10 feet on uneven surfaces',
                existsIn: ['PT', 'OT'],
                hasGGScore: true,
                isCompleted: false,
            },
            {
                text: 'Walk 50 feet',
                existsIn: ['PT', 'OT'],
                hasGGScore: false,
                isCompleted: false,
            },
            {
                text: 'Walk 50 feet with two turns',
                existsIn: ['PT', 'OT'],
                hasGGScore: true,
                isCompleted: false,
            },
            {
                text: 'Walk 100 feet',
                existsIn: ['PT', 'OT'],
                hasGGScore: false,
                isCompleted: false,
            },
            {
                text: 'Walk 150 feet',
                existsIn: ['PT', 'OT'],
                hasGGScore: true,
                isCompleted: false,
            },
            {
                text: 'Wheel 50 feet with two turns',
                existsIn: ['PT', 'OT'],
                hasGGScore: true,
                isCompleted: false,
            },
            {
                text: 'Wheel 150 feet',
                existsIn: ['PT', 'OT'],
                hasGGScore: true,
                isCompleted: false,
            },
            {
                text: 'Wheel long ramp (if using wheelchair)',
                existsIn: ['PT', 'OT'],
                hasGGScore: false,
                isCompleted: false,
                assistiveDevices: this.getWheelRampADs(),
            },
            {
                text: 'Wheel short ramp (if using wheelchair)',
                existsIn: ['PT', 'OT'],
                hasGGScore: false,
                isCompleted: false,
                assistiveDevices: this.getWheelRampADs(),
            },
        ];
    }
    getSectionGGItems() {
        return [
            {
                text: FUNCTIONAL_DEFICITS.EATING,
                isCompleted: false,
            },
            {
                text: FUNCTIONAL_DEFICITS.ORAL_HYGIENE,
                isCompleted: false,
            },
            {
                text: FUNCTIONAL_DEFICITS.TOILETING_HYGIENE,
                isCompleted: false,
            },
            // {
            //     text: FUNCTIONAL_DEFICITS.WASH_UPPER_BODY,
            //     isCompleted: false,
            // },
            {
                text: FUNCTIONAL_DEFICITS.SHOWER_BATHE_SELF,
                isCompleted: false,
            },
            {
                text: FUNCTIONAL_DEFICITS.UPPER_BODY_DRESSING,
                isCompleted: false,
            },
            {
                text: FUNCTIONAL_DEFICITS.LOWER_BODY_DRESSING,
                isCompleted: false,
            },
            {
                text: 'Put on/take off footwear',
                isCompleted: false,
            },
            {
                text: 'Personal hygiene',
                isCompleted: false,
            },
            {
                text: 'Roll left to right while in bed',
                isCompleted: false,
            },
            {
                text: FUNCTIONAL_DEFICITS.SIT_TO_LYING,
                isCompleted: false,
            },
            {
                text: FUNCTIONAL_DEFICITS.LYING_TO_SITTING_ON_SIDE_OF_BED,
                isCompleted: false,
            },
            {
                text: FUNCTIONAL_DEFICITS.SIT_TO_STAND,
                isCompleted: false,
            },
            {
                text: FUNCTIONAL_DEFICITS.CHAIR_BED_TO_CHAIR_TRANSFER,
                isCompleted: false,
            },
            {
                text: FUNCTIONAL_DEFICITS.TOILET_TRANSFER,
                isCompleted: false,
            },
            {
                text: 'Tub/Shower Transfer',
                isCompleted: false,
            },
            {
                text: 'Car transfer',
                isCompleted: false,
            },
            {
                text: FUNCTIONAL_DEFICITS.WALK_10_FEET,
                isCompleted: false,
            },
            {
                text: FUNCTIONAL_DEFICITS.WALK_50_FEET_WITH_2_TURNS,
                isCompleted: false,
            },
            {
                text: FUNCTIONAL_DEFICITS.WALK_150_FEET,
                isCompleted: false,
            },
            {
                text: 'Walk 10 feet on uneven surfaces',
                isCompleted: false,
            },
            {
                text: '1 step (curb)',
                isCompleted: false,
            },
            {
                text: '4 steps (stairs)',
                isCompleted: false,
            },
            {
                text: '12 steps (stairs)',
                isCompleted: false,
            },
            {
                text: 'Pick up object from floor',
                isCompleted: false,
            },
            {
                text: FUNCTIONAL_DEFICITS.WHEEL_50_FEET_WITH_2_Turns,
                isCompleted: false,
            },
            {
                text: FUNCTIONAL_DEFICITS.WHEEL_150_FEET,
                isCompleted: false,
            },
        ];
    }
    getDeficitScale() {
        return [
            DEFICITS_SCALE.UNKNOWN,
            DEFICITS_SCALE.PATIENT_REFUSED,
            DEFICITS_SCALE.NOT_APPLICABLE,
            DEFICITS_SCALE.NOT_ATTEMPTED_DUE_TO_ENVIRONMENTAL_LIMITATION,
            DEFICITS_SCALE.NOT_ATTEMPTED_DUE_TO_MEDICAL_CONDITION_OR_SAFETY_CONCERNS,
            DEFICITS_SCALE.DEPENDENT_TOTAL_ASSISTANCE_D,
            DEFICITS_SCALE.MAXIMAL_ASSISTANCE_MAX_A,
            DEFICITS_SCALE.MODERATE_ASSISTANCE_MOD_A,
            DEFICITS_SCALE.MINIMAL_ASSISTANCE_MIN_A,
            DEFICITS_SCALE.CONTACT_GUARD_ASSISTANCE_CGA,
            DEFICITS_SCALE.STANDBY_ASSIST_SBA,
            DEFICITS_SCALE.SUPERVISED_S,
            DEFICITS_SCALE.MODIFIED_I,
            DEFICITS_SCALE.INDEPENDENT_I,
        ];
    }
    getChairBedToChairTransferADs() {
        return [
            AssistiveDevices.BED_RAILS,
            AssistiveDevices.TRANSFER_BENCH,
            AssistiveDevices.SLIDE_BOARD,
            AssistiveDevices.HOYER_LIFT,
            AssistiveDevices.OTHER,
        ];
    }
    getCarTransferADs() {
        return [AssistiveDevices.TRANSFER_BENCH, AssistiveDevices.WHEELCHAIR_RAMP, AssistiveDevices.OTHER];
    }
    getGeneralWheelChairMobilityADs() {
        return [AssistiveDevices.NONE, AssistiveDevices.WHEELCHAIR_ADAPTIVE_TECHNOLOGY, AssistiveDevices.OTHER];
    }
    getLyingToSittingOnSideOfBed() {
        return [AssistiveDevices.NONE, AssistiveDevices.BED_RAILS, AssistiveDevices.OTHER];
    }
    getRollLeftToRightWhileInBedADs() {
        return [AssistiveDevices.NONE, AssistiveDevices.BED_RAILS, AssistiveDevices.OTHER];
    }
    getSitToLyingWhileInBedADs() {
        return [AssistiveDevices.NONE, AssistiveDevices.BED_RAILS, AssistiveDevices.OTHER];
    }
    getTubShowerTransferADs() {
        return [
            AssistiveDevices.SHOWER_CHAIR,
            AssistiveDevices.GRAB_BARS,
            AssistiveDevices.TRANSFER_BENCH,
            AssistiveDevices.OTHER,
        ];
    }
    getWheelRampADs() {
        return [AssistiveDevices.NONE, AssistiveDevices.WHEELCHAIR_ADAPTIVE_TECHNOLOGY, AssistiveDevices.OTHER];
    }
    getCookingAndMealPreparationADs() {
        return [
            AssistiveDevices.NONE,
            AssistiveDevices.ADAPTED_PLATES_AND_BOWLS,
            AssistiveDevices.NON_SKID_PLATES_AND_BOWLS,
            AssistiveDevices.WEIGHTED_UTENSILS,
            AssistiveDevices.UTENSIL_HOLDERS_FOR_BETTER_GRIP,
            AssistiveDevices.SPECIALIZED_AND_AUTOMATED_FEEDING_DEVICES,
            AssistiveDevices.SPECIALIZED_COOKING_UTENSILS,
            AssistiveDevices.OTHER,
        ];
    }
    getEatingADs() {
        return [
            AssistiveDevices.NONE,
            AssistiveDevices.WEIGHTED_UTENSILS,
            AssistiveDevices.DIVIDED_PLATE,
            AssistiveDevices.PLATE_GUARD,
            AssistiveDevices.DYCEM,
            AssistiveDevices.NOSEY_CUP,
            AssistiveDevices.ADAPTED_PLATES_AND_BOWLS,
            AssistiveDevices.NON_SKID_PLATES_AND_BOWLS,
            AssistiveDevices.UTENSIL_HOLDERS_FOR_BETTER_GRIP,
            AssistiveDevices.SPECIALIZED_AND_AUTOMATED_FEEDING_DEVICES,
            AssistiveDevices.OTHER,
        ];
    }
    getPersonalHygieneADs() {
        return [
            AssistiveDevices.NONE,
            AssistiveDevices.SHOWER_CHAIR,
            AssistiveDevices.GRAB_BARS,
            AssistiveDevices.LONG_HANDLE_SHOWER_HOSE,
            AssistiveDevices.WASH_MIT,
            AssistiveDevices.REACHER,
            AssistiveDevices.LONG_HANDLED_SPONGE,
            AssistiveDevices.OTHER,
        ];
    }
    getHouseCleaningADs() {
        return [AssistiveDevices.NONE, AssistiveDevices.REACHER, AssistiveDevices.OTHER];
    }
    getLaundryADs() {
        return [AssistiveDevices.NONE, AssistiveDevices.REACHER, AssistiveDevices.OTHER];
    }
    getShowerBatheSelfADs() {
        return [
            AssistiveDevices.NONE,
            AssistiveDevices.SHOWER_CHAIR,
            AssistiveDevices.GRAB_BARS,
            AssistiveDevices.LONG_HANDLE_SHOWER_HOSE,
            AssistiveDevices.WASH_MIT,
            AssistiveDevices.REACHER,
            AssistiveDevices.LONG_HANDLED_SPONGE,
            AssistiveDevices.TRANSFER_BENCH,
            AssistiveDevices.OTHER,
        ];
    }
    getWashUpperBodyADs() {
        return [
            AssistiveDevices.NONE,
            AssistiveDevices.SHOWER_CHAIR,
            AssistiveDevices.GRAB_BARS,
            AssistiveDevices.LONG_HANDLE_SHOWER_HOSE,
            AssistiveDevices.WASH_MIT,
            AssistiveDevices.REACHER,
            AssistiveDevices.LONG_HANDLED_SPONGE,
            AssistiveDevices.OTHER,
        ];
    }
    getWritingADs() {
        return [
            AssistiveDevices.NONE,
            AssistiveDevices.WEIGHTED_PENS_AND_PENCILS,
            AssistiveDevices.PENS_AND_PENCILS_OF_VARYING_SIZES,
            AssistiveDevices.OTHER,
        ];
    }
    getToiletingHygieneADs() {
        return [
            AssistiveDevices.NONE,
            AssistiveDevices.TRANSFER_BENCH,
            AssistiveDevices.ELEVATED_COMMODE_SEAT,
            AssistiveDevices.COMMODE_3_IN_1,
            AssistiveDevices.OTHER,
        ];
    }
    getUpperBodyDressingADs() {
        return [
            AssistiveDevices.NONE,
            AssistiveDevices.REACHER,
            AssistiveDevices.BUTTON_HOOK,
            AssistiveDevices.DRESSING_STICK,
            AssistiveDevices.OTHER,
        ];
    }
    getLowerBodyDressingADs() {
        return [
            AssistiveDevices.NONE,
            AssistiveDevices.REACHER,
            AssistiveDevices.LONG_HANDLED_SHOE_HORN,
            AssistiveDevices.BUTTON_HOOK,
            AssistiveDevices.DRESSING_STICK,
            AssistiveDevices.SOCK_AID,
            AssistiveDevices.LEG_LIFTER,
            AssistiveDevices.OTHER,
        ];
    }
    getPuttingOnTakingOffFootwearADs() {
        return [
            AssistiveDevices.NONE,
            AssistiveDevices.REACHER,
            AssistiveDevices.LONG_HANDLED_SHOE_HORN,
            AssistiveDevices.DRESSING_STICK,
            AssistiveDevices.SOCK_AID,
            AssistiveDevices.LEG_LIFTER,
            AssistiveDevices.OTHER,
        ];
    }
    getOralHygieneADs() {
        return [
            'Dental Floss Holders',
            'Threaders or Picks',
            'Adapted Tooth Brushes',
            'Denture Brushes',
            'Oral Irrigator',
            'Adaptive Devices for Toothpaste',
        ];
    }
    getGGItemScore() {
        return [
            GG_SCORE.DEPENDENT,
            GG_SCORE.SUBSTANTIAL_MAXIMAL_ASSISTANCE,
            GG_SCORE.PARTIAL_MODERATE_ASSISTANCE,
            GG_SCORE.SUPERVISION_OR_TOUCHING_ASSISTANCE,
            GG_SCORE.SETUP_OR_CLEAN_UP_ASSISTANCE,
            GG_SCORE.INDEPENDENT,
            GG_SCORE.PATIENT_REFUSED,
            GG_SCORE.NOT_APPLICABLE,
            GG_SCORE.NOT_ATTEMPTED_DUE_TO_ENVIRONMENTAL_CONSTRAINTS,
            GG_SCORE.NOT_ATTEMPTED_DUE_TO_MEDICAL_CONDITION_OR_SAFETY_CONCERNS,
        ];
    }
    getAssistiveDevices() {
        return [
            'None',
            'Standard Cane',
            'Offset Cane',
            'Quad Cane',
            'Hemi Walker',
            'Standard Walker',
            'Two-wheel Walker',
            'Three-wheel Walker',
            'Four-wheel Walker',
            'Rollator',
            'Axillary Crutches',
            'Knee Walker',
            'Forearm Crutches',
            'Gutter Crutches',
            'Lofstrand Crutches',
            'Cardiac/Support Walker',
            'Wheelchair',
            'Power Wheelchair',
            'Transport Chair',
        ];
    }
    getAdlSelfCareItems() {
        return [
            {
                text: ADLs_SELF_CARE.COOKING_AND_MEAL_PREPARATION,
                existsIn: ['PT', 'OT'],
                hasGGScore: false,
                isCompleted: false,
                assistiveDevices: this.getCookingAndMealPreparationADs(),
            },
            {
                text: ADLs_SELF_CARE.EATING,
                existsIn: ['PT', 'OT'],
                hasGGScore: true,
                isCompleted: false,
                assistiveDevices: this.getEatingADs(),
            },
            {
                text: ADLs_SELF_CARE.GROOMING_PERSONAL_HYGIENE,
                existsIn: ['PT', 'OT'],
                hasGGScore: true,
                isCompleted: false,
                assistiveDevices: this.getPersonalHygieneADs(),
            },
            {
                text: ADLs_SELF_CARE.HOUSE_CLEANING,
                existsIn: ['PT', 'OT'],
                hasGGScore: false,
                isCompleted: false,
                assistiveDevices: this.getHouseCleaningADs(),
            },
            {
                text: ADLs_SELF_CARE.LAUNDRY,
                existsIn: ['PT', 'OT'],
                hasGGScore: false,
                isCompleted: false,
                assistiveDevices: this.getLaundryADs(),
            },
            {
                text: ADLs_SELF_CARE.LOWER_BODY_DRESSING,
                existsIn: ['PT', 'OT'],
                hasGGScore: true,
                isCompleted: false,
                assistiveDevices: this.getLowerBodyDressingADs(),
            },
            {
                text: ADLs_SELF_CARE.ORAL_HYGIENE,
                existsIn: ['PT', 'OT'],
                hasGGScore: true,
                isCompleted: false,
                assistiveDevices: this.getOralHygieneADs(),
            },
            {
                text: ADLs_SELF_CARE.PUT_ON_TAKE_OFF_FOOTWEAR,
                existsIn: ['PT', 'OT'],
                hasGGScore: true,
                isCompleted: false,
                assistiveDevices: this.getPuttingOnTakingOffFootwearADs(),
            },
            {
                text: ADLs_SELF_CARE.SHOWER_BATHE_SELF,
                existsIn: ['PT', 'OT'],
                hasGGScore: true,
                isCompleted: false,
                assistiveDevices: this.getShowerBatheSelfADs(),
            },
            {
                text: ADLs_SELF_CARE.TOILETING_HYGIENE,
                existsIn: ['PT', 'OT'],
                hasGGScore: true,
                isCompleted: false,
                assistiveDevices: this.getToiletingHygieneADs(),
            },
            {
                text: ADLs_SELF_CARE.UPPER_BODY_DRESSING,
                existsIn: ['PT', 'OT'],
                hasGGScore: true,
                isCompleted: false,
                assistiveDevices: this.getUpperBodyDressingADs(),
            },
            {
                text: ADLs_SELF_CARE.WASH_UPPER_BODY,
                existsIn: ['PT', 'OT'],
                hasGGScore: false,
                isCompleted: false,
                assistiveDevices: this.getWashUpperBodyADs(),
            },
            {
                text: ADLs_SELF_CARE.WRITING,
                existsIn: ['PT', 'OT'],
                hasGGScore: false,
                isCompleted: false,
                assistiveDevices: this.getWritingADs(),
            },
        ];
    }
    getCognitiveLanguageMemoryItems() {
        return [
            {
                text: COGNITIVE_LANGUAGE_MEMORY.ATTENTION,
                hasGGScore: false,
            },
            {
                text: COGNITIVE_LANGUAGE_MEMORY.COGNITION,
                hasGGScore: false,
            },
            {
                text: COGNITIVE_LANGUAGE_MEMORY.MEMORY,
                hasGGScore: false,
            },
            {
                text: COGNITIVE_LANGUAGE_MEMORY.MOTOR_SPEECH,
                hasGGScore: false,
            },
            {
                text: COGNITIVE_LANGUAGE_MEMORY.READING,
                hasGGScore: false,
            },
            {
                text: COGNITIVE_LANGUAGE_MEMORY.SPOKEN_LANGUAGE_COMPREHENSION,
                hasGGScore: false,
            },
            {
                text: COGNITIVE_LANGUAGE_MEMORY.SPOKEN_LANGUAGE_EXPRESSION,
                hasGGScore: false,
            },
            {
                text: COGNITIVE_LANGUAGE_MEMORY.VOICE,
                hasGGScore: false,
            },
            {
                text: CONSTANTS.SWALLOWING,
                hasGGScore: false,
            },
        ];
    }
    getCognitiveLanguageMemoryAttentionScale() {
        return [
            '0 - Unknown',
            '0 - Not Measured',
            '1 - Attention is non functional. Generally unresponsive to most stimuli. Complete Dependence',
            '2 - Able to briefly attend with consistent maximal stimulation, but not long enough to complete even simple living tasks',
            '3 - Maintains attention over time to complete simple living tasks of short duration with consistent maximal cueing in the absence of distracting stimuli',
            '4 - Maintains attention during simple living tasks of multiple steps or long duration within a minimally distracting environment with consistent minimal cueing',
            '5 - Maintains attention within simple living activities with occasional minimal cues within distracting environments. The individual requires increased cueing to restart, continue, and attend simultaneously to multiple demands',
            '6 - Has mild difficulty or takes more than a reasonable amount of time. Maintains attention within complex activities and can attend simultaneously to multiple demands with rare minimal cues.',
            '7 - Complete Independence. Without limitation, they are able to participate in vocational, avocational, or social activities with or without compensatory strategies',
        ];
    }
    getCognitiveLanguageMemoryCognitionScale() {
        return [
            '0 - Unknown',
            '0 - Not Measured',
            '1 - Profound involvement. Not functional. Complete Dependence',
            '2 - Severe involvement',
            '3 - Moderate/Severe involvement',
            '4 - Moderate involvement',
            '5 - Mild/Moderate involvement. Needs supervision or assistance. Requires cueing to start, continue, and change',
            '6 - Mild difficulty or takes more than a reasonable amount of time. Usually uses compensatory strategies when encountering difficulty',
            '7 - Normal. Complete Independence',
        ];
    }
    getCognitiveLanguageMemoryScale() {
        return [
            '0 - Unknown',
            '0 - Not Measured',
            '1 - Complete Dependence. Activity is non functional. Individual is unable to recall any information, regardless of cueing',
            '2 - Consistently requires maximal verbal cues or uses external aids to recall personal information (e.g. family members, biographical information, physical location, etc.) in structured environments',
            '3 - Usually requires maximal cues to recall or use external aids for simple routing and personal information (eg schedule, names of familiar staff, location of therapy areas, etc) in structured environments',
            '4 - Occasionally requires minimal cues to recall or use external memory aids for simple routine and personal information in structured environments. Requires consistent maximal cues to recall or use external memory aids for complex and novel information',
            '5 - Consistently requires minimal cues to recall or use external memory aids for complex and novel information.  Consistently requires minimal cues to plan and follow through on complex future events',
            '6 - Able to recall or use external aids/memory aids for complex information and planning complex future events most of the time',
            '7 - Complete Independence. Successful and independent in recalling or using external aids/memory strategies for complex information and planning future events in vocational, avocational, or social activities',
        ];
    }
    getCognitiveLanguageMemoryMotorSpeechScale() {
        return [
            '0 - Unknown',
            '0 - Not Measured',
            '1 - Complete Dependence. Activity is non functional. Attempts to speak, but speech cannot be understood by familiar or unfamiliar listeners at any time',
            '2 - Attempts to speak. The communication partner must assume responsibility for interpreting the message, with consistent and maximal cues. Patient can produce short consonant-vowel combinations or automatic words',
            '3 - Communication partner must assume primary responsibility for interpreting the communication exchange; even so, the individual is able to produce short consonant-vowel combinations or automatic words',
            '4 - In simple structured conversation with familiar communication partners, the individual can produce simple words and phrases intelligibly. Usually requires moderate cueing in order to produce simple words and phrases intelligibly with unfamiliar communication partners',
            '5 - Individual is able to speak intelligibly using simple sentences in daily routine activities with both familiar and unfamiliar communication partners. Occasionally requires minimal cueing to speak intelligibly using complex sentences',
            '6 - Successfully able to communicate intelligibly in most activities, but some limitations in intelligibility are still apparent in vocational, avocational, and social activities. Rarely requires cueing',
            '7 - Complete Independence. Ability to successfully and independently participate in vocational, avocational , or social activities is not limited by speech production',
        ];
    }
    getCognitiveLanguageMemoryReadingScale() {
        return [
            '0 - Unknown',
            '0 - Not Measured',
            "1 - Complete Dependence. Individual attends to printed material, but doesn't recognize even single letters or common words",
            '2 - Reads single letters and common words with consistent maximal cueing',
            '3 - Reads single letters and common words with consistent moderate cueing, can read some words that are less familiar, longer, and more complex',
            '4 - Reads words and phrases related to routine daily activities and words that are less familiar, longer, and more complex. Usually requires moderate cueing to read sentences of approx 5-7 words long',
            '5 - Reads sentence-level material containing some complex words. Occasionally requires minimal cueing to read more complex sentences and paragraph-level material. Occasionally uses compensatory strategies',
            '6 - Successfully able to read most material but some limitations in reading are still apparent in vocational, avocational, and social activities. Rarely requires minimal cueing to read complex sentences and paragraph-level material',
            '7 - Complete Independence. Ability to successfully and independently participate in vocational, avocational, and social activities is not limited by reading skills',
        ];
    }
    getCognitiveLanguageMemorySpokenLanguageComprehensionScale() {
        return [
            '0 - Unknown',
            '0 - Not Measured',
            '1 - Complete Dependence. Alert, but unable to follow simple directions or respond to yes/no questions, even with cues',
            '2 - With consistent, maximal cues, the individual is able to follow simple directions, respond to simple yes/no question in context, and respond to simple words or phrases',
            '3 - Usually responds accurately to simple yes/no questions. Able to follow simple directions out of context, although moderate cueing is consistently needed',
            '4 - Consistently responds accurately to simple yes/no questions and occasionally follows simple directions without cues. Moderate contextual support is usually needed to understand complex sentences',
            '5 - Able to understand communication in structured conversations with both familiar and unfamiliar communication partners. Occasionally requires minimal cueing to understand more complex sentences',
            '6 - Able to understand communication in most activities, but some limitations in comprehension are still apparent in vocational, avocational, and social activities. Rarely requires minimal cueing to understand more complex sentences',
            '7 - Complete Independence. Ability to independently participate in vocational, avocational, and social activities is not limited by spoken language comprehension',
        ];
    }
    getCognitiveLanguageMemorySpokenLanguageExpressionScale() {
        return [
            '0 - Unknown',
            '0 - Not Measured',
            '1 - Complete Dependence. Attempts to speak, but verbalizations are not meaningful to familiar or unfamiliar communication partners at any time',
            '2 - Attempts to speak, although few attempts are accurate or appropriate. The Communication partner must assume responsibility for structuring the communication exchange',
            '3 - Communication partner must assume responsibility for structuring the communication exchange, and with consistent and moderate cueing, the individual can produce words and phrases that are accurate and appropriate',
            '4 - Successfully able to initiate communication using spoken language in simple, structured conversations in routine daily activities with familiar communication partners. Usually requires moderate cueing',
            '5 - Successfully able to initiate communication using spoken language in structured conversations with both familiar and unfamiliar communication partners. Occasionally requires minimal cueing',
            '6 - Successfully able to communicate in most activities, but some limitations in spoken language are still apparent in vocational, avocational, and social activities. Rarely requires minimal cueing',
            '7 - Complete Independence. Ability to successfully and independently participate in vocational, avocational, and social activities is not limited by spoken language skills',
        ];
    }
    getSwallowingScale() {
        return [
            '0 - Unknown',
            '0 - Not Measured',
            '1 - Complete Dependence. Not able to swallow anything safely by mouth. All nutrition and hydration are received through non-oral means (eg nasogastric tube, PEG)',
            '2 - Not able to swallow safely by mouth for nutrition and hydration, buy may take some consistency with consistent maximal cues in therapy only. Alternative method of feeding is required',
            '3 - Alternative method of feeding is required as individual takes less than 50% of nutrition and hydration by mouth, and/or swallowing is safe with consistent use of moderate cues to use compensatory strategies',
            '4 - Swallowing is safe, but usually requires moderate cues to use compensatory strategies, and/or the individual has moderate diet restrictions and/or still requires tube feeding',
            '5 - Swallowing is safe with minimal diet restriction and/or occasionally requires minimal cueing to use compensatory strategies. May occasionally self-cue. All nutrition and hydration needs are met by mouth',
            '6 - Swallowing is safe, and the individual eats and drinks independently and may rarely require minimal cueing. Usually self-cues when difficulty occurs. May need to avoid specific food items for safety',
            '7 - Complete Independence. Ability to eat independently is not limited by swallow function. Swallowing is safe and efficient for all consistencies. Compensatory strategies are effectively used when needed',
        ];
    }
    getCognitiveLanguageMemoryVoiceScale() {
        return [
            '0 - Unknown',
            '0 - Not Measured',
            '1 - Activity is non functional. Complete Dependence',
            '2 - Requires constant cueing. Able to briefly perform, though not long enough to complete even simple living tasks',
            '3 - Requires a quiet environment and Moderate cueing',
            '4 - Requires a quiet environment with consistent minimal cueing to complete',
            '5 - Needs supervision or assistance. Requires cueing to start, continue, and change attention during complex activities',
            '6 - Has mild difficulty or takes more than a reasonable amount of time. Usually uses compensatory strategies when encountering difficulty',
            '7 - Complete Independence. Without limitation, able to participate in vocational, avocational, or social activities with or without compensatory strategies',
        ];
    }

    mapScaleToGGscore(scaleValue: string, currentForm: FormGroup) {
        switch (scaleValue) {
            case DEFICITS_SCALE.PATIENT_REFUSED:
                currentForm.patchValue({ ggScore: GG_SCORE.PATIENT_REFUSED });
                break;
            case DEFICITS_SCALE.NOT_ATTEMPTED_DUE_TO_ENVIRONMENTAL_LIMITATION:
                currentForm.patchValue({
                    ggScore: GG_SCORE.NOT_ATTEMPTED_DUE_TO_ENVIRONMENTAL_CONSTRAINTS,
                });
                break;
            case DEFICITS_SCALE.NOT_ATTEMPTED_DUE_TO_MEDICAL_CONDITION_OR_SAFETY_CONCERNS:
                currentForm.patchValue({
                    ggScore: GG_SCORE.NOT_ATTEMPTED_DUE_TO_MEDICAL_CONDITION_OR_SAFETY_CONCERNS,
                });
                break;
            case DEFICITS_SCALE.DEPENDENT_TOTAL_ASSISTANCE_D:
                currentForm.patchValue({ ggScore: GG_SCORE.DEPENDENT });
                break;
            case DEFICITS_SCALE.MAXIMAL_ASSISTANCE_MAX_A:
                currentForm.patchValue({
                    ggScore: GG_SCORE.SUBSTANTIAL_MAXIMAL_ASSISTANCE,
                });
                break;
            case DEFICITS_SCALE.MODERATE_ASSISTANCE_MOD_A:
                currentForm.patchValue({
                    ggScore: GG_SCORE.PARTIAL_MODERATE_ASSISTANCE,
                });
                break;
            case DEFICITS_SCALE.MINIMAL_ASSISTANCE_MIN_A:
                currentForm.patchValue({
                    ggScore: GG_SCORE.PARTIAL_MODERATE_ASSISTANCE,
                });
                break;
            case DEFICITS_SCALE.CONTACT_GUARD_ASSISTANCE_CGA:
                currentForm.patchValue({
                    ggScore: GG_SCORE.SUPERVISION_OR_TOUCHING_ASSISTANCE,
                });
                break;
            case DEFICITS_SCALE.STANDBY_ASSIST_SBA:
                currentForm.patchValue({
                    ggScore: GG_SCORE.SUPERVISION_OR_TOUCHING_ASSISTANCE,
                });
                break;
            case DEFICITS_SCALE.SUPERVISED_S:
                currentForm.patchValue({ ggScore: GG_SCORE.SUPERVISION_OR_TOUCHING_ASSISTANCE });
                break;
            case DEFICITS_SCALE.MODIFIED_I:
                currentForm.patchValue({ ggScore: GG_SCORE.INDEPENDENT });
                break;
            case DEFICITS_SCALE.INDEPENDENT_I:
                currentForm.patchValue({ ggScore: GG_SCORE.INDEPENDENT });
                break;
        }
    }
}
