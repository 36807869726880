<div *ngIf="!isVertical" class="form-group">
    <label class="d-block">{{ label | translate }}</label>
    <div class="d-flex flex-row ml-1">
        <div
            class="d-flex align-items-center innova-radio mr-3 rounded-curve {{ contentAlign }}"
            *ngFor="let option of radioOptions"
            [ngClass]="backgroundClass ? backgroundClass : ''"
        >
            <input
                class="radio-select"
                type="radio"
                [id]="label + option.name"
                [name]="label"
                [value]="option.value"
                [(ngModel)]="value"
                [disabled]="disabled"
                (change)="radioSelect(option.value)"
                style="background-color: transparent !important"
            />
            <label [ngClass]="disabled && 'text-gray-400'"[for]="label + option.name" class="radio-label ml-2 pt-2 body-small">{{ option.name | translate }}</label>
        </div>
    </div>
</div>

<div *ngIf="isVertical" class="form-group">
    <label class="ml-0">{{ label | translate }}</label>
    <div class="d-flex mb-1" *ngFor="let option of radioOptions">
        <div>
            <input
                type="radio"
                [id]="label + option.name + id"
                [name]="label"
                [value]="option.value"
                [(ngModel)]="value"
                [disabled]="disabled"
                (change)="radioSelect(option.value)"
            />
        </div>
        <label [for]="label + option.name + id" class="radio-label ml-2 body-medium">{{ option.name | translate }}</label>
    </div>
</div>
