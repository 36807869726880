import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CheckIconCellRendererComponent } from '@app/data-grid/cell-renderers';
import { isAllowedToCoSign } from '@app/helpers';
import { findTherapyCaseByParamId } from '@app/helpers/utils/therapy-case.utils';
import { FacilityAdmission, TherapyAdmission, UpdatedPlanOfCareNote } from '@app/interfaces';
import { DOCUMENTATION_CONSTANTS, Doc_Notes } from '@app/interfaces/documentation';
import { PrintData } from '@app/interfaces/print';
import { Account } from '@app/models';
import { CoSignNoteComponent } from '@app/modules/patients/PCC/pcc-tabs/pcc-documentation/modals/co-sign-note/co-sign-note.component';
import { SignNoteComponent } from '@app/modules/patients/PCC/pcc-tabs/pcc-documentation/modals/sign-note/sign-note.component';
import {
    AuthService,
    GoalService,
    ModalService,
    StandardizedTestsService,
    StoreService,
    ToasterService,
    PPUserManagementService
} from '@app/services';

import { EvaluationService } from '@app/services/evaluation.service';
import { TimeConfirmationDialogComponent } from '@app/shared/time-confirmation-dialog/time-confirmation-dialog.component';
import { select, Store } from '@ngrx/store';
import { ColDef } from 'ag-grid-community';
import { format, parse } from 'date-fns';
import { cloneDeep } from 'lodash';
import { Subject, takeUntil } from 'rxjs';
import { FunctionalDeficitNoteComponent } from '../../patient-assessment/functional-deficit-note/functional-deficit-note.component';

@Component({
    selector: 'app-update-plan-of-care',
    templateUrl: './update-plan-of-care.component.html',
    styleUrls: ['./update-plan-of-care.component.scss'],
})
export class UpdatePlanOfCareComponent implements OnInit, OnDestroy {
    @Input() printMode = false;
    @Input() printData: PrintData;
    admissionDischarged = false;
    noteTitle = 'Updated Plan Of Care';
    DOCUMENTATION_CONSTANTS = DOCUMENTATION_CONSTANTS;
    showCoSign = false;
    contentEl = document.getElementById('content');
    enableCoSignBtn = false;
    public medicalDiagnosis: { title: string; cols: ColDef[]; data: any[] } = {
        title: 'Medical Diagnosis',
        cols: [
            { field: 'no', headerName: 'No.', width: 60, valueGetter: 'node.rowIndex + 1' },
            { field: 'code', headerName: 'Code', width: 100 },
            { field: 'description', headerName: 'Description', width: 300, wrapText: true, autoHeight: true },
            {
                field: 'pdpmClinicalCategory',
                headerName: 'PDPM Clinical Category',
                width: 300,
                wrapText: true,
                autoHeight: true,
            },
            {
                field: 'onsetDate',
                headerName: 'Onset Date',
                cellRenderer: (params) => {
                    return params.data.onsetDate ? this.transformDate(new Date(params.data.onsetDate)) : '-----';
                },
            },
            { field: 'slp', headerName: 'SLP Comorbidity' },
            { headerName: 'Primary DX', field: 'isPrimary', cellRenderer: CheckIconCellRendererComponent },
        ],
        data: [],
    };
    public treatmentDiagnosis: { title: string; cols: ColDef[]; data: any[] } = {
        title: 'Treatment Diagnosis',
        cols: [
            { headerName: 'No.', width: 30, valueGetter: 'node.rowIndex + 1' },
            { field: 'code', headerName: 'Code', width: 100 },
            {
                field: 'description',
                headerName: 'Description',
                cellClass: ['body-medium', 'line-space'],
                autoHeight: true,
            },
            {
                field: 'onsetDate',
                headerName: 'Onset Date',
                cellRenderer: (params) => {
                    return params.data.onsetDate ? this.transformDate(new Date(params.data.onsetDate)) : '-----';
                },
            }
        ],
        data: [],
    };
    public precautions: { title: string; cols: ColDef[]; data: any[] } = {
        title: 'Precautions',
        cols: [
            { headerName: 'No.', width: 30, valueGetter: 'node.rowIndex + 1' },
            {
                field: 'description',
                headerName: 'Description',
                cellClass: ['body-medium', 'line-space'],
                autoHeight: true,
            },
        ],
        data: [],
    };
    public contraindications: { title: string; cols: ColDef[]; data: any[] } = {
        title: 'Contraindications',
        cols: [
            { headerName: 'No.', width: 30, valueGetter: 'node.rowIndex + 1' },
            {
                field: 'description',
                headerName: 'Description',
                cellClass: ['body-medium', 'line-space'],
                autoHeight: true,
            },
        ],
        data: [],
    };
    public impairments: { title: string; cols: ColDef[]; data: any[] } = {
        title: 'Impairments',
        cols: [],
        data: [],
    };

    public functionalDeficits: { title: string; cols: ColDef[]; data: any[] } = {
        title: 'Functional Deficits',
        cols: [
            { field: 'no', headerName: 'No.', minWidth: 60, maxWidth: 60, valueGetter: 'node.rowIndex + 1' },
            { field: 'name', headerName: 'Name' },
            {
                field: 'clof',
                headerName: 'Current level of function',
                autoHeight: true,
                wrapText: true,
                cellRenderer: FunctionalDeficitNoteComponent,
                cellRendererParams: (params) => {
                    return {
                        note: params.data.clofNote,
                    };
                },
            },
            {
                field: 'plof',
                headerName: 'Prior level of function',
                autoHeight: true,
                wrapText: true,
                cellRenderer: FunctionalDeficitNoteComponent,
                cellRendererParams: (params) => {
                    return {
                        note: params.data.plofNote,
                    };
                },
            },
        ],
        data: [],
    };

    public standardizedTest: { title: string; cols: ColDef[]; data: any[] } = {
        title: 'Standardized test',
        cols: [
            { headerName: 'No.', minWidth: 60, maxWidth: 60, valueGetter: 'node.rowIndex + 1' },
            { field: 'name', headerName: 'Name', width: 200 },
            {
                headerName: 'Score',
                field: 'score',
                width: 40,
                cellRenderer: (params) =>
                    params.data?.totalScore ? `${params.data.score} / ${params.data.totalScore}` : params.data.score,
            },
            {
                field: 'interpretation',
                headerName: 'Interpretation',
                width: 200,
                autoHeight: true,
                cellClass: ['body-medium', 'line-space'],
            },
            {
                field: 'note',
                headerName: 'Note',
                suppressMovable: true,
                cellClass: ['body-medium', 'line-space'],
                autoHeight: true,
            },
        ],
        data: [],
    };

    public reasonForUpdate: { title: string; paragraph: string } = {
        title: 'Reason for update',
        paragraph: '',
    };

    public cptCodes: { title: string; cols: ColDef[]; data: any[] } = {
        title: 'Skilled Services',
        cols: [
            { headerName: 'No.', width: 30, valueGetter: 'node.rowIndex + 1' },
            {
                field: 'cptCode',
                headerName: 'Code',
                width: 60,
                cellStyle: { 'white-space': 'normal' },
                autoHeight: true,
            },
            { field: 'description', headerName: 'Code Description' },
        ],
        data: [],
    };

    public frequencyDuration: { title: string; cols: ColDef[]; data: any[] } = {
        title: 'Frequency and Duration',
        cols: [
            { field: 'frequency', headerName: 'Frequency', width: 30 },
            { field: 'duration', headerName: 'Duration', width: 30 },
            // comment the code fot further notice
            // { field: 'intensity', headerName: 'Intensity', width: 30 },
        ],
        data: [],
    };
    public certification: { title: string; cols: ColDef[]; data: Array<object> } = {
        title: 'Certification',
        cols: [
            {
                field: 'fromDate',
                headerName: 'From',
            },
            {
                field: 'throughDate',
                headerName: 'Through',
            },
        ],
        data: [{ fromDate: '', throughDate: '' }],
    };
    public goal: any = [];

    public descriptionCSS = () => {
        return {
            color: '#1C3775',
            'white-space': 'normal',
            height: 'auto',
            'line-height': 2,
        };
    };

    public addendum: { title: string; cols: ColDef[]; data: any[] } = {
        title: 'Addendum',
        cols: [
            {
                field: 'description',
                width: 900,
                headerName: 'Description',
                autoHeight: true,
                wrapText: true,
            },
            {
                field: 'userNameRole',
                headerName: 'User name & Role',
                cellRenderer: (params) => {
                    const keyData = params.data?.signed.signedBy[0];
                    return this.userNameFormatter(keyData);
                },
                autoHeight: true,
                width: 250,
            },
            {
                field: 'date',
                headerName: 'Date & Time',
                valueFormatter: (params) => {
                    const completionDate = params.data?.signed?.signDate;
                    const completionTime =
                        ('0' + new Date(completionDate).getHours()).slice(-2) +
                        ':' +
                        ('0' + new Date(completionDate).getMinutes()).slice(-2);
                    return completionDate
                        ? this.transformDate(new Date(completionDate)) + ' ' + completionTime
                        : '-------';
                },
            },
        ],
        data: [],
    };

    public optionToShow = [
        {
            date: this.dateFormat('2021-03-25'),
            text: 'Green Field Dade County',
            changes: ['Caregiver education', 'Vitals Updated', 'Plan of treatment updated'],
        },
        { date: this.dateFormat('2021-03-26'), text: 'Green Field Dade County', changes: ['CPT Codes Updated'] },
    ];

    public dateFormat(date): string {
        const d = parse(date, 'yyyy-MM-dd', new Date());
        return format(d, 'MMMM, dd h:mm a');
    }

    private transformDate = (date) => {
        if (!date) {
            return '';
        }
        return this.datePipe.transform(new Date(date), 'MM/dd/yyyy');
    };
    public userNameFormatter(user: any) {
        return user?.roles
            ? `<p class="name mb-0" >${user.lastName + ', ' + user.firstName}</p><span>${user.roles.map(
                  (role) => role.name
              )}</span>`
            : `<p class="name mb-0" >${
                  this.user.user.lastName + ', ' + this.user.user.firstName
              }</p><span>${this.user.user.roles.map((role) => role.name)}</span>`;
    }
    private readonly onDestroy = new Subject<void>();
    ngOnDestroy(): void {
        this.onDestroy.next();
        this.contentEl.removeEventListener('scroll', this.divScroll, true);
    }
    currentAdmission: TherapyAdmission;
    therapyAdmissions: TherapyAdmission[];
    therapyDiscipline: string;
    medicalDiagnosisData: any[] = [];
    upocNoteId: string;
    loadingObs: any;
    user: any;
    currentAddendum: string;
    upocNote: UpdatedPlanOfCareNote;
    therapistDiscipline: any;
    isDifferentDisciplineTherapist = false;
    currentDocSigned = false;
    PocDoc = false;
    constructor(
        private userManagementService:PPUserManagementService,
        private route: ActivatedRoute,
        private storeService: StoreService,
        private datePipe: DatePipe,
        private modalService: ModalService,
        private authService: AuthService,
        private toasterService: ToasterService,
        private standardizedTestsService: StandardizedTestsService,
        private store: Store<{
            therapyAdmission: TherapyAdmission[];
            facilityAdmission: FacilityAdmission;
            user: Account;
        }>,
        private evaluationService: EvaluationService,
        private goalService: GoalService
    ) {
        this.impairments.cols = [
            ...this.evaluationService
                .getImpairmentCols()
                .filter(
                    (elem) =>
                        elem.headerName.toLowerCase() !== 'actions' && elem.headerName.toLowerCase() !== 'make a goal'
                ),
        ];
        this.loadingObs = this.storeService.getLoadingState();
        this.store
            .select('facilityAdmission')
            .pipe(takeUntil(this.onDestroy))
            .subscribe((data) => {
                if (data.patientDischarge) {
                    this.admissionDischarged = true;
                }
            });
        this.route.queryParamMap.subscribe({
            next: (params) => {
                this.therapyDiscipline = params.get('discipline');
                this.upocNoteId = params.get('doc');
                // colworx-sk
                const pocDocParam = params.get('pocDocuments');
                this.PocDoc = pocDocParam === 'true';
                if(this.PocDoc){
                    this.getEditHistory(this.upocNoteId)
                }
               // colworx-sk
            },
        });
        this.store.pipe(select('user')).subscribe((user) => {
            if (user) {
                this.user = user;
            }
        });
    }
    ngOnInit(): void {
        const queryString = this.route.snapshot.queryParams;
        this.contentEl.addEventListener('scroll', this.divScroll, true);
        if (this.printMode) {
            const { discipline, id } = this.printData;
            this.therapyDiscipline = discipline;
            this.upocNoteId = id;
        }

        this.store
            .select('therapyAdmission')
            .pipe(takeUntil(this.onDestroy))
            .subscribe((data) => {
                this.currentAddendum = '';
                this.therapyAdmissions = data;

                this.currentAdmission = findTherapyCaseByParamId(this.therapyAdmissions, queryString['therapy-case']);
                this.therapyDiscipline ||= this.currentAdmission?.therapyDiscipline;
                this.therapyDiscipline ||= 'PT';
                this.currentAdmission = cloneDeep(this.currentAdmission);

                if (this.currentAdmission) {
                    this.treatmentDiagnosis.data = this.currentAdmission?.treatmentDiagnosis ?? [];
                    this.upocNote = this.currentAdmission.documentation.updatedPlanOfCareNote.find(
                        (upoc) => upoc.id == this.upocNoteId
                    );
                    this.currentDocSigned = !!this.upocNote?.signed?.signDate;
                    this.showCoSign = isAllowedToCoSign(this.currentAdmission, this.user, this.upocNote.coSigned);
                    this.treatmentDiagnosis.data = this.upocNote?.patientHistory?.treatmentDiagnosis ?? [];
                    this.medicalDiagnosis.data = this.upocNote?.patientHistory?.medicalDiagnosis ?? [];

                    this.precautions.data = this.upocNote?.patientHistory?.precautions;
                    this.contraindications.data = this.upocNote?.patientHistory?.contraindications;
                    this.impairments.data = this.upocNote.objectiveAssessment.impairments;
                    this.functionalDeficits.data = this.buildFunctionalDeficits(
                        this.upocNote.objectiveAssessment.functionalDeficits
                    );
                    this.standardizedTest.data = this.upocNote.objectiveAssessment.standardizedTests;
                    this.standardizedTest.data = this.standardizedTestsService.addTotalsInStandardizedTestsData(
                        this.standardizedTest.data
                    );
                    this.cptCodes.data = this.upocNote.plan.skilledServices;
                    this.frequencyDuration.data = this.viewFrequencyAndDuration(
                        this.upocNote.plan.frequencyAndDuration
                    );
                    this.certification.data = [this.upocNote.plan.certification];
                    this.addendum.data = this.upocNote.addendum ? this.upocNote.addendum : [];
                    this.reasonForUpdate.paragraph = this.upocNote.assessment.reasonForUpdate;
                    this.goal = this.goalService.buildGoal(this.upocNote.plan.goals);
                }
            });
        if (this.authService.isTherapist) {
            this.therapistDiscipline = this.authService.accountValue.user.therapist.discipline;
            if (this.therapistDiscipline.replace('A', '') !== this.therapyDiscipline) {
                this.isDifferentDisciplineTherapist = true;
            }
        } else if (this.authService.isManager || this.authService.isSuperAdmin) this.isDifferentDisciplineTherapist = true;
    }
    onCoSignClicked() {
        if (this.admissionDischarged) {
            const data = {
                heading: 'Confirmation',
                icon: '',
                btn1Text: 'Cancel',
                btn2Text: 'Continue',
                lowerBodyValue: '',
                iconFilled: true,
                bodyText: `This patient has a facility discharge, would you like to continue?`,
            };
            const dialogRef = this.modalService.open(TimeConfirmationDialogComponent, {
                data,
            });
            dialogRef.afterClosed().subscribe((res) => {
                if (res) {
                    this.modalService.open(CoSignNoteComponent, {
                        data: {
                            documentType: Doc_Notes.UPOC,
                            heading: 'Updated Plan of Care',
                            document: this.upocNote,
                        },
                    });
                }
            });
        } else {
            this.modalService.open(CoSignNoteComponent, {
                data: { documentType: Doc_Notes.UPOC, heading: 'Updated Plan of Care', document: this.upocNote },
            });
        }
    }
    divScroll = (event): void => {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 500)
            this.enableCoSignBtn = true;
    };
    buildFunctionalDeficits(deficits) {
        const functionalDeficits = [];
        for (let index = 0; index < deficits.length; index++) {
            const deficit = deficits[index];
            functionalDeficits.push({
                no: index + 1,
                name: deficit.name,
                clof: deficit.clof.levelOfFunction,
                clofNote: deficit.clof.note,
                plof: deficit.plof.levelOfFunction,
                plofNote: deficit.plof.note,
            });
        }

        return functionalDeficits;
    }

    viewFrequencyAndDuration(frequencyAndDuration) {
        const frequencyAndDurationList = [];
        if (Array.isArray(frequencyAndDuration) && frequencyAndDuration.length) {
            for (const item of frequencyAndDuration) {
                frequencyAndDurationList.push({
                    range: item.range ? 'Yes' : 'No',
                    frequency: item.frequencyFrom, //item.range ? `${item.frequencyFrom} to ${item.frequencyTo}` : `${item.frequencyFrom} `,
                    duration: `${item.duration + ' ' + item.durationType}`,
                    intensity: item.intensity,
                });
            }
        } else {
            frequencyAndDurationList.push({ range: '', frequency: '', duration: '', intensity: '' });
        }
        return frequencyAndDurationList;
    }

    updateAddendum(description) {
        this.currentAddendum = description;
    }

    padTo2Digits(number: number) {
        return number.toString().padStart(2, '0');
    }

    public tooltipCheck = () => {
        if (this.admissionDischarged) {
            return 'Patient Discharged';
        } else if (!this.authService.isManager && !this.authService.isTherapist) {
            return 'You are not authorized to add addendum';
        } else if (this.isDifferentDisciplineTherapist) {
            return '';
        } else {
            return 'Add Addendum Note';
        }
    };
    addAddendumNote() {
        if (this.authService.isManager || this.authService.isTherapist) {
            if (this.currentAddendum) {
                const addendumNote = {
                    description: this.currentAddendum,
                };
                this.modalService.open(SignNoteComponent, {
                    data: {
                        documentType: 'updatedPlanOfCareNote',
                        addendumNote,
                        heading: 'Addendum Note',
                        upocNote: this.upocNoteId,
                    },
                });
            } else {
                this.toasterService.show({ title: 'Failure', body: 'Add description', type: 'error' });
            }
        }
    }

      //colworx-sk
      @Input() evalHistorySidePoc: boolean;
      changeHistoryPoc: any = [];
      openEvalHistoryPoc() {
          this.evalHistorySidePoc = true;
      }
  
      closeHistorySideBarPoc(event: boolean) {
          this.evalHistorySidePoc = event;
      }
      getEditHistory(docId: any) {
          this.userManagementService.getEvaluationHistory(docId).subscribe((res: any) => {
              this.changeHistoryPoc = res.data;
          });
      }
}
