import { Component, Inject, Input } from '@angular/core';
import { DIALOG_DATA } from '@app/helpers/dialog-tokens';
import { DialogRef } from '@app/services';

@Component({
  selector: 'app-caution-modal',
  templateUrl: './caution-modal.component.html',
  styleUrls: ['./caution-modal.component.scss']
})
export class CautionModalComponent {

  @Input() heading: string;
  @Input() message: string;
  
  constructor(
    @Inject(DIALOG_DATA) public data,
    private ref: DialogRef
  ) {
    this.heading = data?.heading ?? this.heading
    this.message = data?.message ?? this.message
  }

  onClick(action: boolean): void {
    this.ref.close(action)
  }

}
