<!-- search with filters -->
<div
    (insideClickEvent)="setShowResults($event)"
    (outsideClickEvent)="setShowResults($event)"
    *ngIf="type == 'filter'"
    class="position-relative input-height input-border-radius"
>
    <div
        class="rounded absolute-right-0 absolute-left-0 zindex-dropdown pt-1 pr-1 search-patients"
        [ngClass]="{ 'search-fixed-width': type === 'filter' }"
        [ngClass]="{ 'position-absolute': patientSearchResults.length > 0 }"
    >
        <div class="input-group search-container body-large form-group input-height input-border-radius">
            <div class="input-group-prepend d-flex flex-column align-self-center col-1">
                <div [hidden]="loading" class="material-icons-outlined">search</div>
                <span *ngIf="loading" class="spinner-border"></span>
            </div>
            <input
                type="search"
                name="searchFilter"
                #searchFilter
                ngModel
                class="borderless bg-transparent col-10"
                placeholder="Search Patients"
                aria-label="Search Patients"
                aria-describedby="basic-addon2"
            />
            <div class="input-group-append col-1">
                <span class="input-group-text borderless bg-transparent" id="basic-addon2">
                    <img loading="lazy" class="innova-icon" src="./../../../assets/icons/tune.svg" />
                </span>
            </div>
        </div>
        <!-- Results -->
        <div
            class="d-flex flex-column pl-2 pr-2 position-absolute absolute-left-0 absolute-right-0 zindex-dropdown shadow-main-sheet bg-white mt-1"
            [ngClass]="{ 'bg-white': !isDisabled }"
            [ngClass]="{ 'bg-disabled-text': isDisabled }"
            style="max-height: 50vh; overflow-y: scroll"
            *ngIf="patientSearchResults?.length > 0 && showResults"
        >
            <!-- Single search result -->
            <div class="d-flex flex-row justify-content-between hover mt-2" *ngFor="let result of patientSearchResults">
                <div class="d-flex flex-column justify-content-center">
                    <p class="body2">{{ result.firstName | titlecase }} {{ result.lastName | titlecase }}</p>
                    <div class="d-flex flex-row align-items-center justify-content-start">
                        <span class="caption text-primary-light">SSN: {{ result.SSN }}</span>
                        <span class="caption text-primary-light ml-3">DOB: {{ result.DOB | date }}</span>
                    </div>
                </div>
                <button type="button" class="btn btn-outline-blue-select" (click)="getPatient(result._id)">Add</button>
            </div>
        </div>
    </div>
</div>

<!-- search only -->

<div
    *ngIf="type == 'search'"
    
    (insideClickEvent)="setShowResults($event)"
    (outsideClickEvent)="setShowResults($event)"
>
    <div
        class="rounded absolute-right-0 absolute-left-0 zindex-dropdown bg-white"
        [ngClass]="{ 'position-absolute': patientSearchResults.length > 0 }"
    >
        <div
            class="input-group form-group search-container body-large input-height input-border-radius bg-body-background"
            [ngClass]="{ shadow: patientSearchResults.length > 0 }"
            style="z-index: 9999"
        >
            <div class="input-group-prepend d-flex flex-column align-self-center col-2">
                <div [hidden]="loading">
                    <span class="body-large pl-2"><img loading="lazy" src="./../../../assets/icons/search.svg" /></span>
                </div>
                <span *ngIf="loading" class="spinner-border"></span>
            </div>

            <input
                list="patientResults"
                type="search"
                spellcheck="true"
                autocomplete="off"
                name="search"
                #search
                ngModel
                class="borderless col-10 search-container ml-n2 pl-0"
                style="background-color: transparent !important"
                placeholder="{{ placeholder }}"
                aria-label="Search Directory"
                aria-describedby="basic-addon2"
            />

            <!-- <datalist id="patientResults">
          <option value="{{ result.ssn }}" *ngFor="let result of patientSearchResults">{{ result.name }}</option>
        </datalist> -->

            <!-- <div class="results col-12" *ngIf="showpatientSearchResults === true">
          <div class="result" *ngFor="let result of patientSearchResults">{{ result }}</div>
        </div> -->
        </div>
        <div
            class="rounded d-flex flex-column bg-white pl-3 pr-3 mt-n3 text-secondary"
            style="max-height: 50vh; overflow-y: scroll"
            *ngIf="patientSearchResults?.length > 0 && showResults"
        >
            <!-- Single search result -->
            <p class="body-medium-semibold pt-3 pb-3 mb-n1 pl-2 sticky-top bg-white">Select Patient</p>
            <div
                class="d-flex flex-row hover border-search-results cursor-pointer pl-2"
                *ngFor="let result of patientSearchResults"
                (click)="getPatient(result._id)"
            >
                <div class="d-flex flex-column justify-content-center pt-2 pb-2">
                    <p class="body-small-semibold">
                        {{ result.firstName | titlecase }} {{ result.lastName | titlecase }}
                    </p>
                    <div class="d-flex flex-row align-items-center justify-content-start">
                        <span class="body-small mr-3">SSN: {{ result.SSN }}</span>
                        <span class="body-small">DOB {{ result.DOB | date }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- categories search, to  be changed later -->

<div
    class="input-group mb-3 search-container"
    style="max-width: 100%"
    *ngIf="type == 'category'"
    (insideClickEvent)="setShowResults($event)"
    (outsideClickEvent)="setShowResults($event)"
>
    <input
        type="text"
        name="search"
        ngModel
        class="form-control"
        placeholder="{{ placeholder }}"
        aria-label="Search Directory"
        aria-describedby="basic-addon2"
        #diagnosisSearch
    />
    <span *ngIf="loading" class="spinner-border"></span>

    <div class="input-group-append">
        <span [hidden]="loading" class="input-group-text" id="basic-addon2">
            <img loading="lazy" class="innova-icon" src="./../../../assets/icons/search.svg" />
        </span>
    </div>
</div>

<!-- categories search, to  be changed later -->
<!-- 
<div class="input-group search-container" style="max-width: 100%" *ngIf="type == 'physician'">
    <input
        type="search"
        spellcheck="true"
        autocomplete="off"
        name="search"
        ngModel
        class="borderless search-container w-100 search-input"
        placeholder="{{ placeholder }}"
        aria-label="Search Directory"
        aria-describedby="basic-addon2"
        #physicianSearch
    />
    <span *ngIf="loading" class="spinner-border"></span>

    <div class="input-group-append">
        <span [hidden]="loading" class="input-group-text" id="basic-addon2">
            <img loading="lazy" class="innova-icon" src="./../../../assets/icons/search.svg" />
        </span>
    </div>
</div> -->

<!-- Multiselect Search -->

<div [ngClass]="{'row px-3 mt-2': !dataGridSearch, 'mt-3 mx-3': dataGridSearch}">
    <div
        *ngIf="type === 'multi' || type === 'physician'"
        class="col-12"
        (insideClickEvent)="setShowResults($event)"
        (outsideClickEvent)="setShowResults($event)"
    >
        <div class="row rounded bg-white">
            <div
                class="col-12 input-group form-group search-container body-large input-height input-border-radius bg-body-background"
            >
                <div class="d-flex w-100">
                    <div class="input-group-prepend d-flex flex-column align-self-center mr-2">
                        <div [hidden]="loading">
                            <span class="body-large pl-2"
                                ><img loading="lazy" src="./../../../assets/icons/search.svg"
                            /></span>
                        </div>
                        <span *ngIf="loading" class="spinner-border"></span>
                    </div>

                    <div class="w-100">
                        <div #badgesInput class="d-flex flex-row align-items-center w-100 overflow-auto">
                            <div
                                *ngFor="let selectedItem of selectedData"
                                class="badge badge-pill badge-primary mr-1 p-2 d-flex flex-row align-items-center justify-content-between"
                            >
                                <span
                                    *ngIf="!objectSubKey && type !== 'physician'"
                                    class="body-medium text-white"
                                    style="margin-left: 5px"
                                >
                                    {{ selectedItem['' + objectKey] }}
                                </span>
                                <span
                                    *ngIf="!objectSubKey && type === 'physician'"
                                    class="body-medium text-white"
                                    style="margin-left: 5px"
                                >
                                    {{
                                        selectedItem['' + objectKey].firstName +
                                            ', ' +
                                            selectedItem['' + objectKey].lastName
                                    }}
                                </span>
                                <span *ngIf="objectSubKey" class="body-medium text-white" style="margin-left: 5px">
                                    {{ selectedItem['' + objectKey][objectSubKey] }}
                                </span>
                                <span
                                    class="material-icons-outlined"
                                    style="font-size: 14px; margin-left: 10px; cursor: pointer"
                                    (click)="removeSelectedItemEvent(selectedItem)"
                                >
                                    close
                                </span>
                            </div>
                            <input
                                list="patientResults"
                                type="search"
                                spellcheck="true"
                                autocomplete="off"
                                name="search"
                                #multiSearchField
                                ngModel
                                (ngModelChange)="inputChanged($event)"
                                class="borderless search-container ml-n2 pl-0 w-100 search-input"
                                style="background-color: transparent !important; margin-left: 10px !important"
                                placeholder="{{ placeholder }}"
                                aria-label="Search Directory"
                                aria-describedby="basic-addon2"
                                (search)="xClicked()"
                                [disabled]="disabled"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Multi select search with separated pills (new design)  -->
<div [ngClass]="{'row px-3 mt-2': !dataGridSearch, 'mt-3 mx-3': dataGridSearch}">
    <div
        *ngIf="type === 'multi-pill'"
        class="col-12"
        (insideClickEvent)="setShowResults($event)"
        (outsideClickEvent)="setShowResults($event)"
    >
        <div class="row rounded bg-white">
            <div
                class="col-12 input-group form-group search-container body-large input-height input-border-radius bg-body-background"
            >
                <div class="d-flex w-100">
                    <div class="input-group-prepend d-flex flex-column align-self-center mr-2">
                        <div [hidden]="loading">
                            <span class="body-large pl-2"
                                ><img loading="lazy" src="./../../../assets/icons/search.svg"
                            /></span>
                        </div>
                        <span *ngIf="loading" class="spinner-border"></span>
                    </div>

                    <div class="w-100">
                        <div #badgesInput class="d-flex flex-row align-items-center w-100 overflow-auto">
                            <input
                                list="patientResults"
                                type="text"
                                spellcheck="true"
                                autocomplete="off"
                                name="search"
                                #multiSearchField
                                ngModel
                                class="borderless search-container ml-n2 pl-0 w-100 search-input"
                                style="background-color: transparent !important; margin-left: 10px !important"
                                placeholder="{{ placeholder }}"
                                aria-label="Search Directory"
                                aria-describedby="basic-addon2"
                                (search)="xClicked()"
                                [disabled]="disabled"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="pills-outer mb-3">
                <div class="pills-holder" [ngClass]="{'flex-wrap': showAllPills}">
                    <div class="body-medium-semibold" *ngIf="visibleItems.length <= 0"> {{heading !== patientDemographic.TREATMENT_DIAGNOSIS ? 'No Medical Diagnosis Selected' : 'No Treatment Diagnosis Selected' }}</div>
                    <div
                        *ngFor="let selectedItem of visibleItems"
                        class="badge badge-pill badge-blue-40 p-1 d-flex flex-row align-items-center justify-content-between"
                    >
                        <span
                            *ngIf="!objectSubKey && type !== 'physician'"
                            class="body-medium"
                            style="margin-left: 5px"
                        >
                            {{ selectedItem['' + objectKey] }}
                        </span>
                        <span
                            *ngIf="!objectSubKey && type === 'physician'"
                            class="body-medium"
                            style="margin-left: 5px"
                        >
                            {{
                                selectedItem['' + objectKey].firstName +
                                    ', ' +
                                    selectedItem['' + objectKey].lastName
                            }}
                        </span>
                        <span *ngIf="objectSubKey" class="body-medium" style="margin-left: 5px">
                            {{ selectedItem['' + objectKey][objectSubKey] }}
                        </span>
                        <span
                            class="material-icons-outlined icon-cross"
                            (click)="removeSelectedItemEvent(selectedItem)"
                        >
                            close
                        </span>
                    </div>
                </div>
                
                <div class="right-content justify-content-end" [ngClass]="{'justify-content-between': remainingCount > 0 && !showAllPills && selectedData.length > 0, 'justify-content-end': remainingCount <= 0 || showAllPills || selectedData.length <= 0}">
                    <span class="body-medium" *ngIf="remainingCount > 0 && !showAllPills && selectedData.length > 0">+{{remainingCount}} more...</span>
                    <div class="d-flex">
                        <span class="d-flex body-bold-14 align-items-center">
                            <span class="d-flex align-items-center cursor-pointer" [ngClass]="{'disabled': selectedData.length <= 0}" (click)="clearSelection()">
                                <span class="cursor-pointer">Clear</span>
                                <span class="material-icons-outlined icon-cross font-18 ml-1 cursor-pointer">close</span>
                            </span>
                            <span class="text-gray-200 mx-2">|</span>
                        </span>
                        <span class="material-symbols-outlined cursor-pointer" [ngClass]="{'disabled': selectedData.length <= 0 || remainingCount <= 0}" (click)="toggleAllPills()">
                            {{ showAllPills ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Basic search type -->
<div *ngIf="type == 'basic'">
    <div
        class="bg-white input-group form-group facility-search-container body-large input-height input-border-radius"
        [ngClass]="{ shadow: patientSearchResults.length > 0 }"
        style="z-index: 9999"
    >
        <div class="input-group-prepend d-flex flex-column align-self-center col-2">
            <div [hidden]="loading">
                <span class="body-large pl-2"><img loading="lazy" src="./../../../assets/icons/search.svg" /></span>
            </div>
            <span *ngIf="loading" class="spinner-border"></span>
        </div>

        <input
            list="patientResults"
            type="search"
            spellcheck="true"
            autocomplete="off"
            name="search"
            #search
            ngModel
            class="borderless col-10 facility-search-container ml-n2 pl-0"
            placeholder="{{ placeholder }}"
            aria-label="Search Directory"
            aria-describedby="basic-addon2"
        />

        <!-- <datalist id="patientResults">
        <option value="{{ result.ssn }}" *ngFor="let result of patientSearchResults">{{ result.name }}</option>
      </datalist> -->

        <!-- <div class="results col-12" *ngIf="showpatientSearchResults === true">
        <div class="result" *ngFor="let result of patientSearchResults">{{ result }}</div>
      </div> -->
    </div>
</div>
