import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateFormatter',
})
export class DateFormatterPipe implements PipeTransform {
    transform(value: string): unknown {
        if (!value) return 'N/A'; // if not value exist
        if(typeof value === 'string' && value.includes('/')) return value; // if value already formatted to mm/dd/yyyy

        const td = value?.substring(0, 10).split('-');
        return `${td[1]}/${td[2]}/${td[0]}`;
        // return value ? new Date(value).toLocaleDateString() : 'N/A';
    }
}
