export const AuthPolicies = {
    OperationsDashboard: {
        Access: 'Policy::OperationsDashboard:Access',
    },
    TherapistDashboard: {
        Access: 'Policy::TherapistDashboard:Access',
    },
    Patient: {
        View: 'Policy::Patient:View',
        Add: 'Policy::Patient:Add',
        Edit: 'Policy::Patient:Edit',
        Discharge: 'Policy::Patient:Discharge',
        All: 'Policy::Patient:All',
    },
    PatientBoard: {
        View: 'Policy::PatientBoard:View',
        Add: 'Policy::PatientBoard:Add',
        Edit: 'Policy::PatientBoard:Edit',
    },
    PDPMBoard: {
        View: 'Policy::PDPMBoard:View',
        Add: 'Policy::PDPMBoard:Add',
        Edit: 'Policy::PDPMBoard:Edit',
    },
    Planner: {
        View: 'Policy::Planner:View',
        Add: 'Policy::Planner:Add',
        Edit: 'Policy::Planner:Edit',
        Delete: 'Policy::Planner:Delete',
        All: 'Policy::Planner:All',
    },
    Scheduler: {
        View: 'Policy::Scheduler:View',
        Add: 'Policy::Scheduler:Add',
        Edit: 'Policy::Scheduler:Edit',
        Delete: 'Policy::Scheduler:Delete',
        All: 'Policy::Scheduler:All',
    },
    Whiteboard: {
        View: 'Policy::Whiteboard:View',
    },
    Timecards: {
        View: 'Policy::Timecards:View',
        Add: 'Policy::Timecards:Add',
        Edit: 'Policy::Timecards:Edit',
        Delete: 'Policy::Timecards:Delete',
        All: 'Policy::Timecards:All',
    },
    Facilities: {
        View: 'Policy::Facilities:View',
        Add: 'Policy::Facilities:Add',
        Edit: 'Policy::Facilities:Edit',
        Delete: 'Policy::Facilities:Delete',
        All: 'Policy::Facilities:All',
    },
    Payors: {
        View: 'Policy::Payors:View',
        Add: 'Policy::Payors:Add',
        Edit: 'Policy::Payors:Edit',
        Delete: 'Policy::Payors:Delete',
        All: 'Policy::Payors:All',
    },
    Roles: {
        View: 'Policy::Roles:View',
        Add: 'Policy::Roles:Add',
        Edit: 'Policy::Roles:Edit',
        Delete: 'Policy::Roles:Delete',
        All: 'Policy::Roles:All',
    },
    Users: {
        View: 'Policy::Users:View',
        Add: 'Policy::Users:Add',
        Edit: 'Policy::Users:Edit',
        Delete: 'Policy::Users:Delete',
        All: 'Policy::Users:All',
    },
    GoLive: {
        Access: 'Policy::GoLive:Access',
    },
    MatrixCare: {
        Access: 'Policy::MatrixCare:Access',
    },
    ServiceLog: {
        View: 'Policy::ServiceLog:View',
        Download: 'Policy::ServiceLog:Download',
        All: 'Policy::ServiceLog:All',
    },
    BillingExport: {
        View: 'Policy::BillingExport:View',
        Download: 'Policy::BillingExport:Download',
        All: 'Policy::BillingExport:All',
    },
    TripleCheckReport: {
        View: 'Policy::TripleCheckReport:View',
        Download: 'Policy::TripleCheckReport:Download',
        All: 'Policy::TripleCheckReport:All',
    },
    EOMReport: {
        View: 'Policy::EOMReport:View',
        Download: 'Policy::EOMReport:Download',
        All: 'Policy::EOMReport:All',
    },
    MinutesByPayor: {
        View: 'Policy::MinutesByPayor:View',
        Download: 'Policy::MinutesByPayor:Download',
        All: 'Policy::MinutesByPayor:All',
    },
    EOM: {
        View: 'Policy::EOM:View',
        Add: 'Policy::EOM:Add',
        Edit: 'Policy::EOM:Edit',
        Delete: 'Policy::EOM:Delete',
        All: 'Policy::EOM:All',
    },
    Invoicing: {
        View: 'Policy::Invoicing:View',
        Add: 'Policy::Invoicing:Add',
        Edit: 'Policy::Invoicing:Edit',
        Delete: 'Policy::Invoicing:Delete',
        All: 'Policy::Invoicing:All',
    },
    Recipient: {
        View: 'Policy::Recipients:View',
        Add: 'Policy::Recipients:Add',
        Edit: 'Policy::Recipients:Edit',
        Delete: 'Policy::Recipients:Delete',
        All: 'Policy::Recipients:All',
    },
    TherapistAvailability: {
        View: 'Policy::TherapistAvailability:View',
        Add: 'Policy::TherapistAvailability:Add',
        Edit: 'Policy::TherapistAvailability:Edit',
        Delete: 'Policy::TherapistAvailability:Delete',
        All: 'Policy::TherapistAvailability:All',
    },
    ClinicalDocumentation: {
        View: 'Policy::ClinicalDocuments:View',
        Add: 'Policy::ClinicalDocuments:Add',
        Edit: 'Policy::ClinicalDocuments:Edit',
        Delete: 'Policy::ClinicalDocuments:Delete',
        Print: 'Policy::ClinicalDocuments:Print',
        All: 'Policy::ClinicalDocuments:All',
    },
    CompletedClinicalDocumentation: {
        Delete: 'Policy::CompletedClinicalDocuments:Delete',
    },
    // colworx-ak
    UserManagement: {
        View: 'Policy::UserManagement:View',
        Add: 'Policy::UserManagement:Add',
        Edit: 'Policy::UserManagement:Edit',
        Delete: 'Policy::UserManagement:Delete',
        Print: 'Policy::UserManagement:Print',
        All: 'Policy::UserManagement:All',
    },
    PocDocuments: {
        View: 'Policy::POCDocument:View',
        Add: 'Policy::POCDocument:Add',
        Edit: 'Policy::POCDocument:Edit',
        Delete: 'Policy::POCDocument:Delete',
        Print: 'Policy::POCDocument:Print',
        All: 'Policy::POCDocument:All',
    },
};
