import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { LegendGoal } from '@app/interfaces';

@Component({
    selector: 'app-legend',
    templateUrl: './legend.component.html',
    styleUrls: ['./legend.component.scss'],
})
export class LegendComponent implements OnInit, OnChanges {
    constructor() {
        //
    }
    @Input() stg = false;
    @Input() selectedGoal: string;
    @Input() goals: LegendGoal[] = [];
    @Output() setStgEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() showSTGNotification: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() goalSelectionEvent: EventEmitter<string> = new EventEmitter<string>();
    filteredGoals = [];
    ngOnInit(): void {
        // Empty
    }
    ngOnChanges() {
        if (this.selectedGoal === 'all') this.filteredGoals = this.goals;
        else this.filteredGoals = this.goals?.filter((item) => item.title === this.selectedGoal);
    }
    setStg(value: boolean) {
        this.setStgEvent.emit(value);
    }
}
