import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { MedicalDiagnosis, TherapyAdmission } from '../interfaces/therapyAdmission';
import { Observable } from 'rxjs/internal/Observable';
import { GOAL_CONSTANTS } from '@app/interfaces/documentation';
import {
    DailyNoteReport,
    DetailedCptCodes,
    DiagnosisReport,
    GridLayoutReport,
    ProgressNoteReport,
    ReportHeader,
    Goal,
    TreeGrid,
    RecertificationNoteReport,
    TableDataBlock,
    Impairments,
    SkilledServices,
    UpdatedPlanOfCareNoteReport,
    EvaluationNoteReport,
    DischargeNoteReport,
    ReportSignature,
    AddendumNoteReport,
    Credentials,
    DailyNote,
} from '../interfaces/documentation-interface';
import { format } from 'date-fns';
import { FacilityAdmission, Patient } from '@app/interfaces';
import {
    CONSTANTS,
    GRID_CONSTANTS,
    HEADERS,
    NOTES_SEQUENCE,
    getCurrentUser,
    gridValueMapper,
} from '@app/helpers/constants/documentation-report';
import { cloneDeep } from 'lodash';
import { DOCUMENTATION_CONSTANTS, NEURO_MUSCULO_SKELETAL_IMPAIRMENTS } from '@app/interfaces/documentation';
import { convertSecsToMins, placeZerosOnLeft } from '@app/helpers/utils/data.utils';
import { TherapistService } from './therapist.service';
import { BehaviorSubject, take } from 'rxjs';
import { ToasterService } from './toaster.service';
import { StandardizedTestsService } from './standardized-tests.service';
import { TranslateService } from '@ngx-translate/core';
import { TIME } from '@app/helpers/constants';
import { shortTherapistType } from '@app/helpers';
import { states } from '@app/helpers/States-Counties';

@Injectable({
    providedIn: 'root',
})
export class DocumentationReportService {
    private __patientAssessmentSinceLastTreatment: string;
    private __responseToTreatment: string;
    private __planForNextTreatment: string;
    private __anticipatedDischargeDate: string;
    private __overallPlanAndUpdates: string;
    private __clinicalImpression: string;
    private __reasonForContinuingTreatment: string;
    private __therapyNecessity: string;
    private __interventionApproach: string;
    private __caregiverEducation: string;
    private __previousTherapy: string;
    private __endOfCareReason: string;
    private __reasonForReferral: string;
    private __prognosisForAchievingRehabGoals: string;
    private __priorLevelOfFunction: string;
    private __chiefComplaint: string;
    private __updateToTreatment: string;
    private __dischargeDestination: string;
    private __dischargePlan: string;
    private __reasonForUpdate: string;
    private __description: string;
    private __reasonForNotCreatingPoc: string;
    private __header = new ReportHeader();
    private __signature = new ReportSignature();
    private __vitals = new GridLayoutReport();
    private __treatment: DetailedCptCodes[];
    private __contraindications = new GridLayoutReport();
    private __goals = new GridLayoutReport();
    private __impairments = new GridLayoutReport();
    private __functionalDeficits = new GridLayoutReport();
    private __standardizedTests = new GridLayoutReport();
    private __skilledServices = new GridLayoutReport();
    private __frequencyAndDuration = new GridLayoutReport();
    private __precautions = new GridLayoutReport();
    private __environmentalFactors = new GridLayoutReport();
    private __patientGoals = new GridLayoutReport();
    private __socialDeterminantsOfHealth = new GridLayoutReport();
    private __sectionGg = new GridLayoutReport();
    private __medications = new GridLayoutReport();
    private __pastMedicalHistory = new GridLayoutReport();
    private __priorHospitalizationDates = new GridLayoutReport();
    private __treatmentDiagnosis = new GridLayoutReport();
    private __medicalDiagnosis = new GridLayoutReport();
    private __evaluationType = new GridLayoutReport();
    private __coTreatmentMinutes = new GridLayoutReport();
    private __addendum = new GridLayoutReport();
    private __changeHistoryPrint = new GridLayoutReport();
    private __addendumNote: AddendumNoteReport | any = null;
    private __dailyNote: DailyNoteReport | null = null;
    private __progressNote: ProgressNoteReport | null = null;
    private __recertificationNote: RecertificationNoteReport | null = null;
    private __updatedPlanOfCareNote: UpdatedPlanOfCareNoteReport | null = null;
    private __evaluationNote: EvaluationNoteReport | null = null;
    private __dischargeNote: DischargeNoteReport | null = null;
    private __MRN: string | null = null;
    private __credentials = [new Credentials()];
    patient: Patient | null = null;
    patient$: Observable<Patient>;
    therapyAdmission$: Observable<TherapyAdmission[]>;
    facilityAdmission$: Observable<FacilityAdmission>;
    loadedNotes: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    therapyAdmission: any;

    constructor(
        private store: Store<{
            therapyAdmission: TherapyAdmission[];
            facilityAdmission: FacilityAdmission;
            patient: Patient;
        }>,
        private therapistService: TherapistService,
        private toaster: ToasterService,
        private standardizedTestsService: StandardizedTestsService,
        private translateService: TranslateService
    ) {
        this.patient$ = this.store.pipe(select(CONSTANTS.PATIENT));
        this.patient$.subscribe((patient) => {
            this.patient = patient;
        });
        this.facilityAdmission$ = this.store.pipe(select(CONSTANTS.FACILITY_ADMISSION));
        this.facilityAdmission$.subscribe((facilityAdmission) => {
            this.__MRN = facilityAdmission.MRN;
        });
    }

    prepareAddendumNoteData(selectedDocsId: string, noteData, therapyAdmission?): Promise<boolean> {
        this.therapyAdmission$ = this.store.pipe(select(CONSTANTS.THERAPY_ADMISSION));
        const therapist = getCurrentUser();
        return new Promise((resolve) => {
            this.therapyAdmission$.pipe(take(1)).subscribe(async (therapyAdmissions) => {
                if (noteData) {
                    try {
                        const userId = noteData.signed.signedBy;
                        const credentials = await this.setCredentials(userId);
                        this.header = {
                            dueDate: new Date().toISOString(),
                            signed: {
                                ...noteData.signed,
                                signedBy: {
                                    firstName: therapist.firstName,
                                    lastName: therapist.lastName,
                                },
                            },
                            note: CONSTANTS.ADDENDUM,
                            discipline: therapyAdmission.therapyDiscipline,
                            dob: this.patient.DOB,
                            physician: therapyAdmission.attendingPhysicians[0]
                                ? therapyAdmission.attendingPhysicians[0]
                                : CONSTANTS.NA,
                        };
                        this.description = noteData.description;
                        this.signature = {
                            signed: {
                                ...noteData.signed,
                                signedBy: {
                                    firstName: therapist.firstName,
                                    lastName: therapist.lastName,
                                },
                            },
                            physician: therapyAdmission.attendingPhysicians[0],
                            discipline: therapyAdmission.therapyDiscipline,
                            license: credentials ? credentials.license : CONSTANTS.NA,
                            signCredentials: credentials ? credentials.signCredentials : CONSTANTS.NA,
                            therapistType: noteData?.signed?.therapistType
                                ? noteData?.signed?.therapistType
                                : credentials
                                ? credentials.therapistType
                                : CONSTANTS.NA,
                        };

                        this.__addendumNote = {
                            header: cloneDeep(this.header),
                            description: cloneDeep(this.description),
                            signature: cloneDeep(this.signature),
                        };
                        resolve(true);
                    } catch (error) {
                        this.toaster.show({
                            title: 'Addendum',
                            body: 'Export unsuccessful.',
                            type: 'error',
                        });
                    }
                } else {
                    for (const therapyAdmission of therapyAdmissions) {
                        for (const addendumNote of therapyAdmission.documentation.addendum) {
                            try {
                                if (addendumNote && selectedDocsId === addendumNote.id) {
                                    const userId = addendumNote.signed.signedBy['_id'];
                                    const credentials = await this.setCredentials(userId);

                                    this.header = {
                                        dueDate: addendumNote['day'],
                                        signed: addendumNote.signed,
                                        note: CONSTANTS.ADDENDUM,
                                        discipline: therapyAdmission.therapyDiscipline,
                                        dob: this.patient.DOB,
                                        physician: therapyAdmission.attendingPhysicians[0]
                                            ? therapyAdmission.attendingPhysicians[0]
                                            : CONSTANTS.NA,
                                    };
                                    this.description = addendumNote.description;
                                    this.signature = {
                                        signed: addendumNote.signed,
                                        physician: therapyAdmission.attendingPhysicians[0],
                                        discipline: therapyAdmission.therapyDiscipline,
                                        license: credentials ? credentials.license : CONSTANTS.NA,
                                        signCredentials: credentials ? credentials.signCredentials : CONSTANTS.NA,
                                        therapistType: addendumNote?.signed?.therapistType
                                            ? addendumNote?.signed?.therapistType
                                            : credentials
                                            ? credentials.therapistType
                                            : CONSTANTS.NA,
                                    };

                                    this.__addendumNote = {
                                        header: cloneDeep(this.header),
                                        description: cloneDeep(this.description),
                                        signature: cloneDeep(this.signature),
                                    };
                                    resolve(true);
                                }
                            } catch (error) {
                                this.toaster.show({
                                    title: 'Print',
                                    body: 'Layout load unsuccessful due to some error.',
                                    type: 'error',
                                });
                                window.history.back();
                            }
                        }
                    }
                }
            });
        });
    }

    prepareDailyNoteData(selectedDocsId: string, key?: string): Promise<boolean> {
        this.therapyAdmission$ = this.store.pipe(select(CONSTANTS.THERAPY_ADMISSION));
        return new Promise((resolve) => {
            this.therapyAdmission$.pipe(take(1)).subscribe(async (therapyAdmissions) => {
                for (const therapyAdmission of therapyAdmissions) {
                    for (const dailyNote of therapyAdmission.documentation.dailyNote) {
                        if (selectedDocsId === dailyNote.id) {
                            try {
                                const userId = dailyNote?.signed?.signedBy
                                    ? dailyNote.signed.signedBy['_id']
                                    : this.extractAssignedTherapist(dailyNote) &&
                                      this.extractAssignedTherapist(dailyNote)['_id'];

                                let credentials = null;
                                if (userId) {
                                    credentials = await this.setCredentials(userId);
                                }

                                this.header = {
                                    dueDate: dailyNote.day,
                                    signed: dailyNote?.signed?.signedBy ? dailyNote.signed : null,
                                    assignedTherapist: this.extractAssignedTherapist(dailyNote),
                                    note: CONSTANTS.DAILY,
                                    discipline: therapyAdmission.therapyDiscipline,
                                    dob: this.patient.DOB,
                                    physician: therapyAdmission.attendingPhysicians[0]
                                        ? therapyAdmission.attendingPhysicians[0]
                                        : CONSTANTS.NA,
                                };
                                if (dailyNote.status !== DOCUMENTATION_CONSTANTS.COMPLETED) {
                                    this.description = dailyNote.reason;
                                } else {
                                    this.patientAssessmentSinceLastTreatment =
                                        dailyNote.subjectiveAssessment.patientAssessmentSinceLastTreatment;
                                    this.vitals = Array.isArray(dailyNote.objectiveAssessment.vitals)
                                        ? dailyNote.objectiveAssessment.vitals[0]
                                        : dailyNote.objectiveAssessment.vitals;
                                    this.coTreatment = dailyNote.objectiveAssessment.otherModeMinutes;
                                    this.treatment = dailyNote.objectiveAssessment.detailedCptCodes;
                                    this.responseToTreatment = dailyNote.assessment.responseToTreatment;
                                    this.planForNextTreatment = dailyNote.plan.planForNextTreatment;
                                    // this.anticipatedDischargeDate = new Date(
                                    //     new Date(dailyNote.objectiveAssessment.anticipatedDischargeDate).setHours(
                                    //         TIME.hours,
                                    //         TIME.minutes,
                                    //         TIME.seconds
                                    //     )
                                    // ).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }),
                                    this.addendum = dailyNote.addendum;
                                    let transformedHistory = [];
                                    if (dailyNote.changeHistoryPrint) {
                                        transformedHistory = dailyNote.changeHistoryPrint.map((el) => {
                                            return {
                                                timestamp: el.timestamp,
                                                therapist: el.therapist,
                                                changedFields: el.changedFields.map((t: any) => {
                                                    const fieldValue = t.fieldKey ? t.fieldKey : t;
                                                    return this.translateService.instant(
                                                        'appTranslation.pages.documentation.daily.' + fieldValue
                                                    );
                                                }),
                                            };
                                        });
                                    }
                                    this.changeHistoryPrint = transformedHistory;
                                }
                                this.signature = {
                                    signed: dailyNote?.signed?.signedBy ? dailyNote.signed : null,
                                    coSigned: dailyNote.coSigned,
                                    assignedTherapist: this.extractAssignedTherapist(dailyNote),
                                    physician: therapyAdmission.attendingPhysicians[0],
                                    discipline: therapyAdmission.therapyDiscipline,
                                    license: credentials ? credentials.license : CONSTANTS.NA,
                                    signCredentials: credentials ? credentials.signCredentials : CONSTANTS.NA,
                                    therapistType: dailyNote?.signed?.therapistType
                                        ? dailyNote?.signed?.therapistType
                                        : credentials
                                        ? credentials.therapistType
                                        : CONSTANTS.NA,
                                    noteDate: dailyNote.withheldOrRefusedAt,
                                    status: dailyNote.status,
                                };
                                if (dailyNote.status !== DOCUMENTATION_CONSTANTS.COMPLETED) {
                                    this.__addendumNote = {
                                        header: cloneDeep(this.header),
                                        [key ? key : CONSTANTS.DESCRIPTION]: cloneDeep(this.description),
                                        signature: cloneDeep(this.signature),
                                    };
                                } else {
                                    this.__dailyNote = {
                                        header: cloneDeep(this.header),
                                        patientAssessmentSinceLastTreatment: cloneDeep(
                                            this.patientAssessmentSinceLastTreatment
                                        ),
                                        vitals: cloneDeep(this.vitals),
                                        treatment: cloneDeep(this.treatment),
                                        coTreatment: cloneDeep(this.coTreatment),
                                        responseToTreatment: cloneDeep(this.responseToTreatment),
                                        planForNextTreatment: cloneDeep(this.planForNextTreatment),
                                        // anticipatedDischargeDate: cloneDeep(this.anticipatedDischargeDate),
                                        addendum: cloneDeep(this.addendum),
                                        changeHistoryPrint: cloneDeep(this.changeHistoryPrint),
                                        signature: cloneDeep(this.signature),
                                    };
                                }
                                resolve(true);
                            } catch (error) {
                                this.toaster.show({
                                    title: 'Print',
                                    body: 'Layout load unsuccessful due to some error.',
                                    type: 'error',
                                });
                                window.history.back();
                            }
                        }
                    }
                }
            });
        });
    }
    prepareDailyNoteDataForIntegration(dailyNote: any, key?: string): Promise<boolean> {
        this.therapyAdmission$ = this.store.pipe(select(CONSTANTS.THERAPY_ADMISSION));
        return new Promise((resolve) => {
            this.therapyAdmission$.pipe(take(1)).subscribe(async (therapyAdmissions) => {
                for (const therapyAdmission of therapyAdmissions) {
                    for (const dailyNoteData of therapyAdmission.documentation.dailyNote) {
                        if (dailyNote.id === dailyNoteData.id) {
                            const userId = dailyNote?.signed?.signedBy
                                ? dailyNote.signed.signedBy['id']
                                : this.extractAssignedTherapist(dailyNote) &&
                                  this.extractAssignedTherapist(dailyNote)['id'];

                            let credentials = null;
                            if (userId) {
                                credentials = await this.setCredentials(userId);
                            }
                            this.header = {
                                dueDate: dailyNote.day,
                                signed: dailyNote?.signed?.signedBy
                                    ? dailyNote.signed
                                    : dailyNote.status !== DOCUMENTATION_CONSTANTS.COMPLETED
                                    ? new Date()
                                    : null,
                                assignedTherapist: this.extractAssignedTherapist(dailyNote),
                                note: CONSTANTS.DAILY,
                                discipline: therapyAdmission.therapyDiscipline,
                                dob: this.patient.DOB,
                                physician: therapyAdmission.attendingPhysicians[0]
                                    ? therapyAdmission.attendingPhysicians[0]
                                    : CONSTANTS.NA,
                            };
                            if (dailyNote.status !== DOCUMENTATION_CONSTANTS.COMPLETED) {
                                this.description = dailyNote.reason;
                            } else {
                                this.patientAssessmentSinceLastTreatment =
                                    dailyNote.subjectiveAssessment.patientAssessmentSinceLastTreatment;
                                this.vitals = Array.isArray(dailyNote.objectiveAssessment.vitals)
                                    ? dailyNote.objectiveAssessment.vitals[0]
                                    : dailyNote.objectiveAssessment.vitals;
                                this.coTreatment = dailyNote.objectiveAssessment.otherModeMinutes;
                                this.treatment = dailyNote.objectiveAssessment.detailedCptCodes;
                                this.responseToTreatment = dailyNote.assessment.responseToTreatment;
                                this.planForNextTreatment = dailyNote.plan.planForNextTreatment;
                                (this.anticipatedDischargeDate = new Date(
                                    new Date(dailyNote.objectiveAssessment.anticipatedDischargeDate).setHours(
                                        TIME.hours,
                                        TIME.minutes,
                                        TIME.seconds
                                    )
                                ).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })),
                                    (this.addendum = dailyNote.addendum);
                            }
                            this.signature = {
                                signed: dailyNote?.signed?.signedBy ? dailyNote.signed : null,
                                coSigned: dailyNote.coSigned,
                                assignedTherapist: this.extractAssignedTherapist(dailyNote),
                                physician: therapyAdmission.attendingPhysicians[0],
                                discipline: therapyAdmission.therapyDiscipline,
                                license: credentials ? credentials.license : CONSTANTS.NA,
                                signCredentials: credentials ? credentials.signCredentials : CONSTANTS.NA,
                                therapistType: dailyNote?.signed?.therapistType
                                    ? dailyNote?.signed?.therapistType
                                    : credentials
                                    ? credentials.therapistType
                                    : CONSTANTS.NA,
                            };
                            if (dailyNote.status !== DOCUMENTATION_CONSTANTS.COMPLETED) {
                                this.__addendumNote = {
                                    header: cloneDeep(this.header),
                                    [key ? key : CONSTANTS.DESCRIPTION]: cloneDeep(this.description),
                                    signature: cloneDeep(this.signature),
                                };
                            } else {
                                this.__dailyNote = {
                                    header: cloneDeep(this.header),
                                    patientAssessmentSinceLastTreatment: cloneDeep(
                                        this.patientAssessmentSinceLastTreatment
                                    ),
                                    vitals: cloneDeep(this.vitals),
                                    treatment: cloneDeep(this.treatment),
                                    coTreatment: cloneDeep(this.coTreatment),
                                    responseToTreatment: cloneDeep(this.responseToTreatment),
                                    planForNextTreatment: cloneDeep(this.planForNextTreatment),
                                    //anticipatedDischargeDate: cloneDeep(this.anticipatedDischargeDate),
                                    addendum: cloneDeep(this.addendum),
                                    signature: cloneDeep(this.signature),
                                };
                            }
                            // this.countLoadedNotes();
                            resolve(true);
                        }
                    }
                }
            });
        });
    }
    prepareProgressNoteData(selectedDocsId: string): Promise<boolean> {
        this.therapyAdmission$ = this.store.pipe(select(CONSTANTS.THERAPY_ADMISSION));
        return new Promise((resolve) => {
            this.therapyAdmission$.pipe(take(1)).subscribe(async (therapyAdmissions) => {
                for (const therapyAdmission of therapyAdmissions) {
                    for (const progressNote of therapyAdmission.documentation.progressNote) {
                        try {
                            if (selectedDocsId === progressNote.id) {
                                const userId = progressNote.signed.signedBy['_id'];
                                const credentials = await this.setCredentials(userId);

                                this.header = {
                                    dueDate: progressNote.day,
                                    signed: progressNote.signed,
                                    note: CONSTANTS.PROGRESS,
                                    discipline: therapyAdmission.therapyDiscipline,
                                    dob: this.patient.DOB,
                                    physician: therapyAdmission.attendingPhysicians[0]
                                        ? therapyAdmission.attendingPhysicians[0]
                                        : CONSTANTS.NA,
                                    totalVisits: this.calculateRangeAndVisits(
                                        therapyAdmission.documentation,
                                        progressNote
                                    ).totalVisits,
                                    range: this.calculateRangeAndVisits(therapyAdmission.documentation, progressNote)
                                        .range,
                                };
                                this.patientAssessmentSinceLastTreatment =
                                    progressNote.subjectiveAssessment.patientAssessmentSinceLastTreatment;
                                this.clinicalImpression = progressNote.assessment.clinicalImpression;
                                this.overallPlanAndUpdates = progressNote.plan.overallPlanAndUpdates;
                                this.treatmentDiagnosis = progressNote.patientHistory.treatmentDiagnosis;
                                this.medicalDiagnosis =
                                    progressNote.patientHistory.medicalDiagnosis[CONSTANTS.ICD_10_CODES] ??
                                    progressNote.patientHistory.medicalDiagnosis;
                                this.contraindications = {
                                    data: progressNote.patientHistory.contraindications.map(
                                        (contraindication) => contraindication.description
                                    ),
                                };
                                this.precautions = {
                                    data: progressNote.patientHistory.precautions.map(
                                        (precaution) => precaution.description
                                    ),
                                };
                                this.goals = {
                                    goals: progressNote.objectiveAssessment.goals,
                                    type: CONSTANTS.PROGRESS,
                                };
                                this.addendum = progressNote.addendum;

                                let transformedHistory = [];
                                if (progressNote.changeHistoryPrint) {
                                    transformedHistory = progressNote.changeHistoryPrint.map((el) => {
                                        return {
                                            timestamp: el.timestamp,
                                            therapist: el.therapist,
                                            changedFields: el.changedFields.map((t: any) => {
                                                const fieldValue = t.fieldKey ? t.fieldKey : t;
                                                return this.translateService.instant(
                                                    'appTranslation.pages.documentation.progress.' + fieldValue
                                                );
                                            }),
                                        };
                                    });
                                }
                                this.changeHistoryPrint = transformedHistory;

                                this.signature = {
                                    signed: progressNote.signed,
                                    coSigned: progressNote.coSigned,
                                    physician: therapyAdmission.attendingPhysicians[0],
                                    discipline: therapyAdmission.therapyDiscipline,
                                    license: credentials ? credentials.license : CONSTANTS.NA,
                                    signCredentials: credentials ? credentials.signCredentials : CONSTANTS.NA,
                                    therapistType: progressNote?.signed?.therapistType
                                        ? progressNote?.signed?.therapistType
                                        : credentials
                                        ? credentials.therapistType
                                        : CONSTANTS.NA,
                                };
                                this.__progressNote = {
                                    header: cloneDeep(this.header),
                                    patientAssessmentSinceLastTreatment: cloneDeep(
                                        this.patientAssessmentSinceLastTreatment
                                    ),
                                    clinicalImpression: cloneDeep(this.clinicalImpression),
                                    medicalDiagnosis: cloneDeep(this.medicalDiagnosis),
                                    treatmentDiagnosis: cloneDeep(this.treatmentDiagnosis),
                                    contraindications: cloneDeep(this.contraindications),
                                    precautions: cloneDeep(this.precautions),
                                    goals: cloneDeep(this.goals),
                                    overallPlanAndUpdates: cloneDeep(this.overallPlanAndUpdates),
                                    addendum: cloneDeep(this.addendum),
                                    changeHistoryPrint: cloneDeep(this.changeHistoryPrint),
                                    signature: cloneDeep(this.signature),
                                };
                                resolve(true);
                            }
                        } catch (error) {
                            this.toaster.show({
                                title: 'Print',
                                body: 'Layout load unsuccessful due to some error.',
                                type: 'error',
                            });
                            window.history.back();
                        }
                    }
                }
            });
        });
    }

    prepareProgressNoteDataForIntegration(progressNote: any): Promise<boolean> {
        this.therapyAdmission$ = this.store.pipe(select(CONSTANTS.THERAPY_ADMISSION));
        return new Promise((resolve) => {
            this.therapyAdmission$.pipe(take(1)).subscribe(async (therapyAdmissions) => {
                for (const therapyAdmission of therapyAdmissions) {
                    for (const progress of therapyAdmission.documentation.progressNote) {
                        if (progressNote.id === progress.id) {
                            const userId = progressNote.signed.signedBy['id'] || progressNote.signed.signedBy['_id'];
                            const credentials = await this.setCredentials(userId);

                            this.header = {
                                dueDate: progressNote.day,
                                signed: progressNote.signed,
                                note: CONSTANTS.PROGRESS,
                                discipline: therapyAdmission.therapyDiscipline,
                                dob: this.patient.DOB,
                                physician: therapyAdmission.attendingPhysicians[0]
                                    ? therapyAdmission.attendingPhysicians[0]
                                    : CONSTANTS.NA,
                                totalVisits: this.calculateRangeAndVisits(therapyAdmission.documentation, progressNote)
                                    .totalVisits,
                                range: this.calculateRangeAndVisits(therapyAdmission.documentation, progressNote).range,
                            };
                            this.patientAssessmentSinceLastTreatment =
                                progressNote.subjectiveAssessment.patientAssessmentSinceLastTreatment;
                            this.clinicalImpression = progressNote.assessment.clinicalImpression;
                            this.overallPlanAndUpdates = progressNote.plan.overallPlanAndUpdates;
                            this.treatmentDiagnosis = progressNote.patientHistory.treatmentDiagnosis;
                            this.medicalDiagnosis =
                                progressNote.patientHistory.medicalDiagnosis[CONSTANTS.ICD_10_CODES] ??
                                progressNote.patientHistory.medicalDiagnosis;
                            this.contraindications = {
                                data: progressNote.patientHistory.contraindications.map(
                                    (contraindication) => contraindication.description
                                ),
                            };
                            this.precautions = {
                                data: progressNote.patientHistory.precautions.map(
                                    (precaution) => precaution.description
                                ),
                            };
                            this.goals = {
                                goals: progressNote.objectiveAssessment.goals,
                                type: CONSTANTS.PROGRESS,
                            };
                            this.addendum = progressNote.addendum;
                            this.signature = {
                                signed: progressNote.signed,
                                coSigned: progressNote.coSigned,
                                physician: therapyAdmission.attendingPhysicians[0],
                                discipline: therapyAdmission.therapyDiscipline,
                                license: credentials ? credentials.license : CONSTANTS.NA,
                                signCredentials: credentials ? credentials.signCredentials : CONSTANTS.NA,
                                therapistType: progressNote?.signed?.therapistType
                                    ? progressNote?.signed?.therapistType
                                    : credentials
                                    ? credentials.therapistType
                                    : CONSTANTS.NA,
                            };
                            this.__progressNote = {
                                header: cloneDeep(this.header),
                                patientAssessmentSinceLastTreatment: cloneDeep(
                                    this.patientAssessmentSinceLastTreatment
                                ),
                                clinicalImpression: cloneDeep(this.clinicalImpression),
                                medicalDiagnosis: cloneDeep(this.medicalDiagnosis),
                                treatmentDiagnosis: cloneDeep(this.treatmentDiagnosis),
                                contraindications: cloneDeep(this.contraindications),
                                precautions: cloneDeep(this.precautions),
                                goals: cloneDeep(this.goals),
                                overallPlanAndUpdates: cloneDeep(this.overallPlanAndUpdates),
                                addendum: cloneDeep(this.addendum),
                                signature: cloneDeep(this.signature),
                            };
                            //   this.countLoadedNotes();
                            resolve(true);
                        }
                    }
                }
            });
        });
    }

    prepareRecertificationData(selectedDocsId: string, physicianDetails: any): Promise<boolean> {
        this.therapyAdmission$ = this.store.pipe(select(CONSTANTS.THERAPY_ADMISSION));
        return new Promise((resolve) => {
            this.therapyAdmission$.pipe(take(1)).subscribe(async (therapyAdmissions) => {
                for (const therapyAdmission of therapyAdmissions) {
                    for (const recertificationNote of therapyAdmission.documentation.recertificationNote) {
                        try {
                            if (selectedDocsId === recertificationNote.id) {
                                const userId = recertificationNote.signed.signedBy['_id'];
                                const credentials = await this.setCredentials(userId);

                                this.header = {
                                    dueDate: recertificationNote.day,
                                    signed: recertificationNote.signed,
                                    note: CONSTANTS.RECERTIFICATION,
                                    discipline: therapyAdmission.therapyDiscipline,
                                    dob: this.patient.DOB,
                                    physician: therapyAdmission.attendingPhysicians[0]
                                        ? therapyAdmission.attendingPhysicians[0]
                                        : CONSTANTS.NA,
                                };
                                this.patientAssessmentSinceLastTreatment =
                                    recertificationNote.subjectiveAssessment.patientAssessmentSinceTreatmentStarted;
                                this.clinicalImpression = recertificationNote.assessment.clinicalImpression;
                                this.therapyNecessity = recertificationNote.assessment.therapyNecessity;
                                this.updateToTreatment = recertificationNote.assessment.updateToTreatment;
                                this.dischargeDestination =
                                    recertificationNote.assessment.anticipatedDischarge.dischargeDestination;
                                this.dischargePlan = recertificationNote.assessment.anticipatedDischarge.dischargePlan;
                                this.reasonForContinuingTreatment =
                                    recertificationNote.assessment.reasonForContinuingTreatment;
                                this.treatmentDiagnosis = recertificationNote.patientHistory.treatmentDiagnosis;
                                this.medicalDiagnosis =
                                    recertificationNote.patientHistory.medicalDiagnosis[CONSTANTS.ICD_10_CODES] ??
                                    recertificationNote.patientHistory.medicalDiagnosis;
                                this.impairments = recertificationNote.objectiveAssessment.impairments;
                                this.functionalDeficits = recertificationNote.objectiveAssessment.functionalDeficits;
                                const stTest = recertificationNote.objectiveAssessment.standardizedTests;
                                this.standardizedTests =
                                    this.standardizedTestsService.addTotalsInStandardizedTestsData(stTest);
                                this.skilledServices = recertificationNote.plan.skilledServices;
                                this.prognosisForAchievingRehabGoals =
                                    recertificationNote.assessment.prognosisForAchievingRehabGoals;
                                this.frequencyAndDuration = {
                                    ...recertificationNote.plan.frequencyAndDuration[0],
                                    ...recertificationNote.plan.certification,
                                };
                                this.contraindications = {
                                    data: recertificationNote.patientHistory.contraindications.map(
                                        (contraindication) => contraindication.description
                                    ),
                                };
                                this.precautions = {
                                    data: recertificationNote.patientHistory.precautions.map(
                                        (precaution) => precaution.description
                                    ),
                                };
                                this.goals = { goals: recertificationNote.plan.goals, type: CONSTANTS.RECERTIFICATION };
                                this.addendum = recertificationNote.addendum;
                                this.signature = {
                                    signed: recertificationNote.signed,
                                    coSigned: recertificationNote.coSigned,
                                    physician: physicianDetails ?? {
                                        signedBy: {
                                            user: therapyAdmission.attendingPhysicians[0]?.user,
                                            NPI: therapyAdmission.attendingPhysicians[0]?.NPI,
                                            credentials: therapyAdmission.attendingPhysicians[0]?.credentials,
                                        },
                                        signedAt: null,
                                    },
                                    discipline: therapyAdmission.therapyDiscipline,
                                    license: credentials ? credentials.license : CONSTANTS.NA,
                                    signCredentials: credentials ? credentials.signCredentials : CONSTANTS.NA,
                                    therapistType: recertificationNote?.signed?.therapistType
                                        ? recertificationNote?.signed?.therapistType
                                        : credentials
                                        ? credentials.therapistType
                                        : CONSTANTS.NA,
                                };
                                this.__recertificationNote = {
                                    header: cloneDeep(this.header),
                                    patientAssessmentSinceLastTreatment: cloneDeep(
                                        this.patientAssessmentSinceLastTreatment
                                    ),
                                    therapyNecessity: cloneDeep(this.therapyNecessity),
                                    updateToTreatment: cloneDeep(this.updateToTreatment),
                                    prognosisForAchievingRehabGoals: cloneDeep(this.prognosisForAchievingRehabGoals),
                                    dischargeDestination: cloneDeep(this.dischargeDestination),
                                    dischargePlan: cloneDeep(this.dischargePlan),
                                    clinicalImpression: cloneDeep(this.clinicalImpression),
                                    medicalDiagnosis: cloneDeep(this.medicalDiagnosis),
                                    treatmentDiagnosis: cloneDeep(this.treatmentDiagnosis),
                                    contraindications: cloneDeep(this.contraindications),
                                    precautions: cloneDeep(this.precautions),
                                    goals: cloneDeep(this.goals),
                                    impairments: cloneDeep(this.impairments),
                                    functionalDeficits: cloneDeep(this.functionalDeficits),
                                    standardizedTests: cloneDeep(this.standardizedTests),
                                    skilledServices: cloneDeep(this.skilledServices),
                                    frequencyAndDuration: cloneDeep(this.frequencyAndDuration),
                                    reasonForContinuingTreatment: cloneDeep(this.reasonForContinuingTreatment),
                                    addendum: cloneDeep(this.addendum),
                                    signature: cloneDeep(this.signature),
                                };
                                resolve(true);
                            }
                        } catch (error) {
                            this.toaster.show({
                                title: 'Print',
                                body: 'Layout load unsuccessful due to some error.',
                                type: 'error',
                            });
                            window.history.back();
                        }
                    }
                }
            });
        });
    }
    prepareRecertificationDataForIntegration(recertificationNote: any): Promise<boolean> {
        this.therapyAdmission$ = this.store.pipe(select(CONSTANTS.THERAPY_ADMISSION));
        return new Promise((resolve) => {
            this.therapyAdmission$.pipe(take(1)).subscribe(async (therapyAdmissions) => {
                for (const therapyAdmission of therapyAdmissions) {
                    for (const recertification of therapyAdmission.documentation.recertificationNote) {
                        if (recertificationNote.id === recertification.id) {
                            const userId = recertificationNote.signed.signedBy['_id'] || recertificationNote.signed.signedBy['id'];
                            const credentials = await this.setCredentials(userId);

                            this.header = {
                                dueDate: recertificationNote.day,
                                signed: recertificationNote.signed,
                                note: CONSTANTS.RECERTIFICATION,
                                discipline: therapyAdmission.therapyDiscipline,
                                dob: this.patient.DOB,
                                physician: therapyAdmission.attendingPhysicians[0]
                                    ? therapyAdmission.attendingPhysicians[0]
                                    : CONSTANTS.NA,
                            };
                            this.patientAssessmentSinceLastTreatment =
                                recertificationNote.subjectiveAssessment.patientAssessmentSinceTreatmentStarted;
                            this.clinicalImpression = recertificationNote.assessment.clinicalImpression;
                            this.therapyNecessity = recertificationNote.assessment.therapyNecessity;
                            this.updateToTreatment = recertificationNote.assessment.updateToTreatment;
                            this.dischargeDestination =
                                recertificationNote.assessment.anticipatedDischarge.dischargeDestination;
                            this.dischargePlan = recertificationNote.assessment.anticipatedDischarge.dischargePlan;
                            this.reasonForContinuingTreatment =
                                recertificationNote.assessment.reasonForContinuingTreatment;
                            this.treatmentDiagnosis = recertificationNote.patientHistory.treatmentDiagnosis;
                            this.medicalDiagnosis =
                                recertificationNote.patientHistory.medicalDiagnosis[CONSTANTS.ICD_10_CODES] ??
                                recertificationNote.patientHistory.medicalDiagnosis;
                            this.impairments = recertificationNote.objectiveAssessment.impairments;
                            this.functionalDeficits = recertificationNote.objectiveAssessment.functionalDeficits;
                            const stTest = recertificationNote.objectiveAssessment.standardizedTests;
                            this.standardizedTests =
                                this.standardizedTestsService.addTotalsInStandardizedTestsData(stTest);
                            this.skilledServices = recertificationNote.plan.skilledServices;
                            this.prognosisForAchievingRehabGoals =
                                recertificationNote.assessment.prognosisForAchievingRehabGoals;
                            this.frequencyAndDuration = {
                                ...recertificationNote.plan.frequencyAndDuration[0],
                                ...recertificationNote.plan.certification,
                            };
                            this.contraindications = {
                                data: recertificationNote.patientHistory.contraindications.map(
                                    (contraindication) => contraindication.description
                                ),
                            };
                            this.precautions = {
                                data: recertificationNote.patientHistory.precautions.map(
                                    (precaution) => precaution.description
                                ),
                            };
                            this.goals = { goals: recertificationNote.plan.goals, type: CONSTANTS.RECERTIFICATION };
                            this.addendum = recertificationNote.addendum;
                            this.signature = {
                                signed: recertificationNote.signed,
                                coSigned: recertificationNote.coSigned,
                                physician: {
                                    signedBy: {
                                        user: therapyAdmission.attendingPhysicians[0]?.user,
                                        NPI: therapyAdmission.attendingPhysicians[0]?.NPI,
                                        credentials: therapyAdmission.attendingPhysicians[0]?.credentials,
                                    },
                                    signedAt: null,
                                },
                                discipline: therapyAdmission.therapyDiscipline,
                                license: credentials ? credentials.license : CONSTANTS.NA,
                                signCredentials: credentials ? credentials.signCredentials : CONSTANTS.NA,
                                therapistType: recertificationNote?.signed?.therapistType
                                    ? recertificationNote?.signed?.therapistType
                                    : credentials
                                    ? credentials.therapistType
                                    : CONSTANTS.NA,
                            };
                            this.__recertificationNote = {
                                header: cloneDeep(this.header),
                                patientAssessmentSinceLastTreatment: cloneDeep(
                                    this.patientAssessmentSinceLastTreatment
                                ),
                                therapyNecessity: cloneDeep(this.therapyNecessity),
                                updateToTreatment: cloneDeep(this.updateToTreatment),
                                prognosisForAchievingRehabGoals: cloneDeep(this.prognosisForAchievingRehabGoals),
                                dischargeDestination: cloneDeep(this.dischargeDestination),
                                dischargePlan: cloneDeep(this.dischargePlan),
                                clinicalImpression: cloneDeep(this.clinicalImpression),
                                medicalDiagnosis: cloneDeep(this.medicalDiagnosis),
                                treatmentDiagnosis: cloneDeep(this.treatmentDiagnosis),
                                contraindications: cloneDeep(this.contraindications),
                                precautions: cloneDeep(this.precautions),
                                goals: cloneDeep(this.goals),
                                impairments: cloneDeep(this.impairments),
                                functionalDeficits: cloneDeep(this.functionalDeficits),
                                standardizedTests: cloneDeep(this.standardizedTests),
                                skilledServices: cloneDeep(this.skilledServices),
                                frequencyAndDuration: cloneDeep(this.frequencyAndDuration),
                                reasonForContinuingTreatment: cloneDeep(this.reasonForContinuingTreatment),
                                addendum: cloneDeep(this.addendum),
                                signature: cloneDeep(this.signature),
                            };
                            // this.countLoadedNotes();
                            resolve(true);
                        }
                    }
                }
            });
        });
    }
    prepareUpocData(selectedDocsId: string, physicianDetails: any): Promise<boolean> {
        this.therapyAdmission$ = this.store.pipe(select(CONSTANTS.THERAPY_ADMISSION));
        return new Promise((resolve) => {
            this.therapyAdmission$.pipe(take(1)).subscribe(async (therapyAdmissions) => {
                for (const therapyAdmission of therapyAdmissions) {
                    for (const updatedPlanOfCareNote of therapyAdmission.documentation.updatedPlanOfCareNote) {
                        try {
                            if (selectedDocsId === updatedPlanOfCareNote.id) {
                                const userId = updatedPlanOfCareNote.signed.signedBy['_id'];
                                const credentials = await this.setCredentials(userId);

                                this.header = {
                                    dueDate: updatedPlanOfCareNote.day,
                                    signed: updatedPlanOfCareNote.signed,
                                    note: CONSTANTS.UPOC,
                                    discipline: therapyAdmission.therapyDiscipline,
                                    dob: this.patient.DOB,
                                    physician: therapyAdmission.attendingPhysicians[0]
                                        ? therapyAdmission.attendingPhysicians[0]
                                        : CONSTANTS.NA,
                                };
                                this.treatmentDiagnosis = updatedPlanOfCareNote.patientHistory.treatmentDiagnosis;
                                this.medicalDiagnosis =
                                    updatedPlanOfCareNote.patientHistory.medicalDiagnosis[CONSTANTS.ICD_10_CODES] ??
                                    updatedPlanOfCareNote.patientHistory.medicalDiagnosis;
                                this.impairments = updatedPlanOfCareNote.objectiveAssessment.impairments;
                                this.functionalDeficits = updatedPlanOfCareNote.objectiveAssessment.functionalDeficits;
                                const stTest = updatedPlanOfCareNote.objectiveAssessment.standardizedTests;
                                this.standardizedTests =
                                    this.standardizedTestsService.addTotalsInStandardizedTestsData(stTest);
                                this.reasonForUpdate = updatedPlanOfCareNote.assessment.reasonForUpdate;
                                this.skilledServices = updatedPlanOfCareNote.plan.skilledServices;
                                this.frequencyAndDuration = {
                                    ...updatedPlanOfCareNote.plan.frequencyAndDuration[0],
                                    ...updatedPlanOfCareNote.plan.certification,
                                };
                                this.contraindications = {
                                    data: updatedPlanOfCareNote.patientHistory.contraindications.map(
                                        (contraindication) => contraindication.description
                                    ),
                                };
                                this.precautions = {
                                    data: updatedPlanOfCareNote.patientHistory.precautions.map(
                                        (precaution) => precaution.description
                                    ),
                                };
                                this.goals = { goals: updatedPlanOfCareNote.plan.goals, type: CONSTANTS.UPOC };
                                this.addendum = updatedPlanOfCareNote.addendum;
                                this.signature = {
                                    signed: updatedPlanOfCareNote.signed,
                                    coSigned: updatedPlanOfCareNote.coSigned,
                                    physician: physicianDetails ?? {
                                        signedBy: {
                                            user: therapyAdmission.attendingPhysicians[0]?.user,
                                            NPI: therapyAdmission.attendingPhysicians[0]?.NPI,
                                            credentials: therapyAdmission.attendingPhysicians[0]?.credentials,
                                        },
                                        signedAt: null,
                                    },
                                    discipline: therapyAdmission.therapyDiscipline,
                                    license: credentials ? credentials.license : CONSTANTS.NA,
                                    signCredentials: credentials ? credentials.signCredentials : CONSTANTS.NA,
                                    therapistType: updatedPlanOfCareNote?.signed?.therapistType
                                        ? updatedPlanOfCareNote?.signed?.therapistType
                                        : credentials
                                        ? credentials.therapistType
                                        : CONSTANTS.NA,
                                };
                                this.__updatedPlanOfCareNote = {
                                    header: cloneDeep(this.header),
                                    medicalDiagnosis: cloneDeep(this.medicalDiagnosis),
                                    treatmentDiagnosis: cloneDeep(this.treatmentDiagnosis),
                                    contraindications: cloneDeep(this.contraindications),
                                    precautions: cloneDeep(this.precautions),
                                    reasonForUpdate: cloneDeep(this.reasonForUpdate),
                                    goals: cloneDeep(this.goals),
                                    impairments: cloneDeep(this.impairments),
                                    functionalDeficits: cloneDeep(this.functionalDeficits),
                                    standardizedTests: cloneDeep(this.standardizedTests),
                                    skilledServices: cloneDeep(this.skilledServices),
                                    frequencyAndDuration: cloneDeep(this.frequencyAndDuration),
                                    addendum: cloneDeep(this.addendum),
                                    signature: cloneDeep(this.signature),
                                };
                                resolve(true);
                            }
                        } catch (error) {
                            this.toaster.show({
                                title: 'Print',
                                body: 'Layout load unsuccessful due to some error.',
                                type: 'error',
                            });
                            window.history.back();
                        }
                    }
                }
            });
        });
    }
    /**
     * This function prepares and sets the data for UPOC document export.
     * @param UPOC
     * @returns boolean
     */
    prepareUpocDataForIntegration(UPOC: any): Promise<boolean> {
        this.therapyAdmission$ = this.store.pipe(select(CONSTANTS.THERAPY_ADMISSION));
        return new Promise((resolve) => {
            this.therapyAdmission$.pipe(take(1)).subscribe(async (therapyAdmissions) => {
                for (const therapyAdmission of therapyAdmissions) {
                    for (const updatedPlanOfCareNote of therapyAdmission.documentation.updatedPlanOfCareNote) {
                        try {
                            if (UPOC.id === updatedPlanOfCareNote.id) {
                                const userId = UPOC.signed.signedBy.id;
                                let credentials = null;
                                credentials = await this.setCredentials(userId);

                                this.header = {
                                    dueDate: UPOC.day,
                                    signed: UPOC?.signed?.signedBy
                                        ? UPOC.signed
                                        : UPOC.status !== DOCUMENTATION_CONSTANTS.COMPLETED
                                        ? new Date()
                                        : null,
                                    note: CONSTANTS.UPOC,
                                    discipline: therapyAdmission.therapyDiscipline,
                                    dob: this.patient.DOB,
                                    physician: therapyAdmission.attendingPhysicians[0]
                                        ? therapyAdmission.attendingPhysicians[0]
                                        : CONSTANTS.NA,
                                };
                                this.treatmentDiagnosis = UPOC.patientHistory.treatmentDiagnosis;
                                this.medicalDiagnosis =
                                    UPOC.patientHistory.medicalDiagnosis[CONSTANTS.ICD_10_CODES] ??
                                    UPOC.patientHistory.medicalDiagnosis;
                                this.impairments = UPOC.objectiveAssessment.impairments;
                                this.functionalDeficits = UPOC.objectiveAssessment.functionalDeficits;
                                const stTest = UPOC.objectiveAssessment.standardizedTests;
                                this.standardizedTests =
                                    this.standardizedTestsService.addTotalsInStandardizedTestsData(stTest);
                                this.reasonForUpdate = UPOC.assessment.reasonForUpdate;
                                this.skilledServices = UPOC.plan.skilledServices;
                                this.frequencyAndDuration = {
                                    ...UPOC.plan.frequencyAndDuration[0],
                                    ...UPOC.plan.certification,
                                };
                                this.contraindications = {
                                    data: UPOC.patientHistory.contraindications.map(
                                        (contraindication) => contraindication.description
                                    ),
                                };
                                this.precautions = {
                                    data: UPOC.patientHistory.precautions.map((precaution) => precaution.description),
                                };
                                this.goals = { goals: UPOC.plan.goals, type: CONSTANTS.UPOC };
                                this.addendum = UPOC.addendum;
                                this.signature = {
                                    signed: UPOC.signed,
                                    coSigned: UPOC.coSigned,
                                    assignedTherapist: this.extractAssignedTherapist(UPOC),
                                    physician: {
                                        signedBy: {
                                            user: therapyAdmission.attendingPhysicians[0]?.user,
                                            NPI: therapyAdmission.attendingPhysicians[0]?.NPI,
                                            credentials: therapyAdmission.attendingPhysicians[0]?.credentials,
                                        },
                                        signedAt: null,
                                    },
                                    discipline: therapyAdmission.therapyDiscipline,
                                    license: credentials ? credentials.license : CONSTANTS.NA,
                                    signCredentials: credentials ? credentials.signCredentials : CONSTANTS.NA,
                                    therapistType: UPOC?.signed?.therapistType
                                        ? UPOC?.signed?.therapistType
                                        : credentials
                                        ? credentials.therapistType
                                        : CONSTANTS.NA,
                                };
                                this.__updatedPlanOfCareNote = {
                                    header: cloneDeep(this.header),
                                    medicalDiagnosis: cloneDeep(this.medicalDiagnosis),
                                    treatmentDiagnosis: cloneDeep(this.treatmentDiagnosis),
                                    contraindications: cloneDeep(this.contraindications),
                                    precautions: cloneDeep(this.precautions),
                                    reasonForUpdate: cloneDeep(this.reasonForUpdate),
                                    goals: cloneDeep(this.goals),
                                    impairments: cloneDeep(this.impairments),
                                    functionalDeficits: cloneDeep(this.functionalDeficits),
                                    standardizedTests: cloneDeep(this.standardizedTests),
                                    skilledServices: cloneDeep(this.skilledServices),
                                    frequencyAndDuration: cloneDeep(this.frequencyAndDuration),
                                    addendum: cloneDeep(this.addendum),
                                    signature: cloneDeep(this.signature),
                                };
                                resolve(true);
                            }
                        } catch (error) {
                            this.toaster.show({
                                title: 'Print',
                                body: 'Layout load unsuccessful due to some error.',
                                type: 'error',
                            });
                            window.history.back();
                        }
                    }
                }
            });
        });
    }

    async prepareEvaluationData(selectedDocsId: string, physicianDetails: any): Promise<boolean> {
        this.therapyAdmission$ = this.store.pipe(select(CONSTANTS.THERAPY_ADMISSION));
        return new Promise((resolve) => {
            this.therapyAdmission$.pipe(take(1)).subscribe(async (therapyAdmissions) => {
                for (const therapyAdmission of therapyAdmissions) {
                    try {
                        const evaluationNote = therapyAdmission.documentation.evaluation;
                        if (selectedDocsId === evaluationNote.id) {
                            const evalOnly = !!evaluationNote?.evaluationType?.evalOnly;

                            const userId = evaluationNote.signed.signedBy['_id'];
                            const credentials = await this.setCredentials(userId);

                            this.header = {
                                dueDate: evaluationNote.day,
                                signed: evaluationNote.signed,
                                note: CONSTANTS.EVALUATION,
                                discipline: therapyAdmission.therapyDiscipline,
                                dob: this.patient.DOB,
                                physician: therapyAdmission.attendingPhysicians[0]
                                    ? therapyAdmission.attendingPhysicians[0]
                                    : CONSTANTS.NA,
                            };
                            if (!evalOnly) {
                                this.prognosisForAchievingRehabGoals = evaluationNote.planOfCare.goalPurpose;
                                this.dischargeDestination =
                                    evaluationNote.planOfCare.anticipatedDischarge.dischargeDestination;
                                this.dischargePlan = evaluationNote.planOfCare.anticipatedDischarge.dischargePlans;
                                this.skilledServices = evaluationNote.planOfCare.skilledServices;
                                this.frequencyAndDuration = {
                                    ...evaluationNote.planOfCare.frequencyAndDuration[0],
                                    ...evaluationNote.planOfCare.certification,
                                };
                                this.goals = { goals: evaluationNote.planOfCare.goals, type: CONSTANTS.EVALUATION };
                            } else {
                                this.reasonForNotCreatingPoc = evaluationNote?.evaluationType['evalOnlyReason'];
                            }
                            this.reasonForReferral = evaluationNote.medicalReview.reasonForReferral;
                            this.chiefComplaint = evaluationNote.medicalReview.chiefComplaint;
                            this.priorLevelOfFunction = evaluationNote.backgroundAssessment.priorLevelOfFunction;
                            this.previousTherapy = evaluationNote.backgroundAssessment.previousTherapy;
                            this.caregiverEducation = evaluationNote.backgroundAssessment.careGiverEducation;
                            this.interventionApproach = evaluationNote.medicalNecessity.interventionApproaches;
                            this.therapyNecessity = evaluationNote.medicalNecessity.therapyNecessity;
                            this.treatmentDiagnosis = evaluationNote.medicalReview.treatmentDiagnosis;
                            this.medicalDiagnosis =
                                evaluationNote.medicalReview.medicalDiagnosis[CONSTANTS.ICD_10_CODES] ??
                                evaluationNote.medicalReview.medicalDiagnosis;
                            this.pastMedicalHistory = evaluationNote.medicalReview.pastMedicalHistory;
                            this.priorHospitalizationDates = evaluationNote.medicalReview.priorHospitalizationDates;
                            this.evaluationType = evaluationNote.evaluationType;
                            this.impairments = evaluationNote.patientAssessment.impairments;
                            this.functionalDeficits = evaluationNote.patientAssessment.functionalDeficits;
                            const stTest = evaluationNote.patientAssessment.standardizedTests;
                            this.standardizedTests =
                                this.standardizedTestsService.addTotalsInStandardizedTestsData(stTest);
                            this.contraindications = {
                                data: evaluationNote.medicalReview.contraindications.map(
                                    (contraindication) => contraindication.description
                                ),
                            };
                            this.precautions = {
                                data: evaluationNote.medicalReview.precautions.map(
                                    (precaution) => precaution.description
                                ),
                            };
                            this.medications = {
                                data: evaluationNote.medicalReview.medications.map(
                                    (medication) => medication.description
                                ),
                            };
                            this.socialDeterminantsOfHealth = {
                                data: evaluationNote.backgroundAssessment.socialDeterminantsOfHealth.map(
                                    (socialDeterminantOfHealth) => socialDeterminantOfHealth.description
                                ),
                            };
                            this.environmentalFactors = {
                                data: evaluationNote.backgroundAssessment.environmentalFactors.map(
                                    (environmentalFactor) => environmentalFactor.description
                                ),
                            };
                            this.patientGoals = {
                                data: evaluationNote.backgroundAssessment.patientGoals.map(
                                    (patientGoal) => patientGoal.description
                                ),
                            };
                            this.addendum = evaluationNote.addendum;

                            let transformedHistory = [];
                            if (evaluationNote.changeHistoryPrint) {
                                transformedHistory = evaluationNote.changeHistoryPrint.map((el) => {
                                    return {
                                        timestamp: el.timestamp,
                                        therapist: el.therapist,
                                        changedFields: el.changedFields.map((t: any) => {
                                            const fieldValue = t.fieldKey ? t.fieldKey : t;
                                            return this.translateService.instant(
                                                'appTranslation.pages.documentation.evaluation.' + fieldValue
                                            );
                                        }),
                                    };
                                });
                            }
                            this.changeHistoryPrint = transformedHistory;

                            this.signature = {
                                signed: evaluationNote.signed,
                                coSigned: evaluationNote.coSigned,
                                physician: physicianDetails ?? {
                                    signedBy: {
                                        user: therapyAdmission.attendingPhysicians[0]?.user,
                                        NPI: therapyAdmission.attendingPhysicians[0]?.NPI,
                                        credentials: therapyAdmission.attendingPhysicians[0]?.credentials,
                                    },
                                    signedAt: null,
                                },
                                discipline: therapyAdmission.therapyDiscipline,
                                license: credentials ? credentials.license : CONSTANTS.NA,
                                signCredentials: credentials ? credentials.signCredentials : CONSTANTS.NA,
                                therapistType: evaluationNote?.signed?.therapistType
                                    ? evaluationNote?.signed?.therapistType
                                    : credentials
                                    ? credentials.therapistType
                                    : CONSTANTS.NA,
                            };

                            this.__evaluationNote = {
                                header: cloneDeep(this.header),
                                reasonForReferral: cloneDeep(this.reasonForReferral),
                                chiefComplaint: cloneDeep(this.chiefComplaint),
                                priorLevelOfFunction: cloneDeep(this.priorLevelOfFunction),
                                previousTherapy: cloneDeep(this.previousTherapy),
                                prognosisForAchievingRehabGoals: evalOnly
                                    ? null
                                    : cloneDeep(this.prognosisForAchievingRehabGoals),
                                caregiverEducation: cloneDeep(this.caregiverEducation),
                                interventionApproach: cloneDeep(this.interventionApproach),
                                therapyNecessity: cloneDeep(this.therapyNecessity),
                                dischargeDestination: evalOnly ? null : cloneDeep(this.dischargeDestination),
                                dischargePlan: evalOnly ? null : cloneDeep(this.dischargePlan),
                                medicalDiagnosis: cloneDeep(this.medicalDiagnosis),
                                treatmentDiagnosis: cloneDeep(this.treatmentDiagnosis),
                                pastMedicalHistory: cloneDeep(this.pastMedicalHistory),
                                priorHospitalizationDates: cloneDeep(this.priorHospitalizationDates),
                                contraindications: cloneDeep(this.contraindications),
                                medications: cloneDeep(this.medications),
                                socialDeterminantsOfHealth: cloneDeep(this.socialDeterminantsOfHealth),
                                environmentalFactors: cloneDeep(this.environmentalFactors),
                                precautions: cloneDeep(this.precautions),
                                evaluationType: cloneDeep(this.evaluationType),
                                goals: evalOnly ? null : cloneDeep(this.goals),
                                patientGoals: cloneDeep(this.patientGoals),
                                impairments: cloneDeep(this.impairments),
                                functionalDeficits: cloneDeep(this.functionalDeficits),
                                standardizedTests: cloneDeep(this.standardizedTests),
                                skilledServices: evalOnly ? null : cloneDeep(this.skilledServices),
                                frequencyAndDuration: evalOnly ? null : cloneDeep(this.frequencyAndDuration),
                                reasonForNotCreatingPoc: !evalOnly ? null : cloneDeep(this.reasonForNotCreatingPoc),
                                addendum: cloneDeep(this.addendum),
                                changeHistoryPrint: cloneDeep(this.changeHistoryPrint),
                                signature: cloneDeep(this.signature),
                            };
                            resolve(true);
                        }
                    } catch (error) {
                        this.toaster.show({
                            title: 'Print',
                            body: 'Layout load unsuccessful due to some error.',
                            type: 'error',
                        });
                        window.history.back();
                    }
                }
            });
        });
    }

    async prepareEvaluationDataForIntegration(evaluation): Promise<boolean> {
        this.therapyAdmission$ = this.store.pipe(select(CONSTANTS.THERAPY_ADMISSION));
        return new Promise((resolve) => {
            this.therapyAdmission$.pipe(take(1)).subscribe(async (therapyAdmissions) => {
                for (const therapyAdmission of therapyAdmissions) {
                    const evaluationNote = therapyAdmission.documentation.evaluation;
                    if (evaluation.id === evaluationNote.id) {
                        const userId = evaluation.signed.signedBy['_id'] || evaluation.signed.signedBy['id'];
                        const evalOnly = !!evaluation?.evaluationType?.evalOnly;
                        const credentials = await this.setCredentials(userId);

                        this.header = {
                            dueDate: evaluationNote.day,
                            signed: evaluation.signed,
                            note: CONSTANTS.EVALUATION,
                            discipline: therapyAdmission.therapyDiscipline,
                            dob: this.patient.DOB,
                            physician: therapyAdmission.attendingPhysicians[0]
                                ? therapyAdmission.attendingPhysicians[0]
                                : CONSTANTS.NA,
                        };
                        if (!evalOnly) {
                            this.prognosisForAchievingRehabGoals = evaluationNote.planOfCare.goalPurpose;
                            this.dischargeDestination =
                                evaluationNote.planOfCare.anticipatedDischarge.dischargeDestination;
                            this.dischargePlan = evaluationNote.planOfCare.anticipatedDischarge.dischargePlans;
                            this.skilledServices = evaluationNote.planOfCare.skilledServices;
                            this.frequencyAndDuration = {
                                ...evaluationNote.planOfCare.frequencyAndDuration[0],
                                ...evaluationNote.planOfCare.certification,
                            };
                            this.goals = { goals: evaluationNote.planOfCare.goals, type: CONSTANTS.EVALUATION };
                        } else {
                            this.reasonForNotCreatingPoc = evaluationNote?.evaluationType['evalOnlyReason'];
                        }
                        this.reasonForReferral = evaluationNote.medicalReview.reasonForReferral;
                        this.chiefComplaint = evaluationNote.medicalReview.chiefComplaint;
                        this.priorLevelOfFunction = evaluationNote.backgroundAssessment.priorLevelOfFunction;
                        this.previousTherapy = evaluationNote.backgroundAssessment.previousTherapy;
                        this.caregiverEducation = evaluationNote.backgroundAssessment.careGiverEducation;
                        this.interventionApproach = evaluationNote.medicalNecessity.interventionApproaches;
                        this.therapyNecessity = evaluationNote.medicalNecessity.therapyNecessity;
                        this.treatmentDiagnosis = evaluationNote.medicalReview.treatmentDiagnosis;
                        this.medicalDiagnosis =
                            evaluationNote.medicalReview.medicalDiagnosis[CONSTANTS.ICD_10_CODES] ??
                            evaluationNote.medicalReview.medicalDiagnosis;
                        this.pastMedicalHistory = evaluationNote.medicalReview.pastMedicalHistory;
                        this.priorHospitalizationDates = evaluationNote.medicalReview.priorHospitalizationDates;
                        this.evaluationType = evaluationNote.evaluationType;
                        this.impairments = evaluationNote.patientAssessment.impairments;
                        this.functionalDeficits = evaluationNote.patientAssessment.functionalDeficits;
                        const stTest = evaluationNote.patientAssessment.standardizedTests;
                        this.standardizedTests = this.standardizedTestsService.addTotalsInStandardizedTestsData(stTest);
                        this.contraindications = {
                            data: evaluationNote.medicalReview.contraindications.map(
                                (contraindication) => contraindication.description
                            ),
                        };
                        this.precautions = {
                            data: evaluationNote.medicalReview.precautions.map((precaution) => precaution.description),
                        };
                        this.medications = {
                            data: evaluationNote.medicalReview.medications.map((medication) => medication.description),
                        };
                        this.socialDeterminantsOfHealth = {
                            data: evaluationNote.backgroundAssessment.socialDeterminantsOfHealth.map(
                                (socialDeterminantOfHealth) => socialDeterminantOfHealth.description
                            ),
                        };
                        this.environmentalFactors = {
                            data: evaluationNote.backgroundAssessment.environmentalFactors.map(
                                (environmentalFactor) => environmentalFactor.description
                            ),
                        };
                        this.patientGoals = {
                            data: evaluationNote.backgroundAssessment.patientGoals.map(
                                (patientGoal) => patientGoal.description
                            ),
                        };
                        this.addendum = evaluationNote.addendum;
                        this.signature = {
                            signed: evaluationNote.signed,
                            coSigned: evaluation.coSigned,
                            physician: {
                                signedBy: {
                                    user: therapyAdmission.attendingPhysicians[0]?.user,
                                    NPI: therapyAdmission.attendingPhysicians[0]?.NPI,
                                    credentials: therapyAdmission.attendingPhysicians[0]?.credentials,
                                },
                                signedAt: null,
                            },
                            discipline: therapyAdmission.therapyDiscipline,
                            license: credentials ? credentials.license : CONSTANTS.NA,
                            signCredentials: credentials ? credentials.signCredentials : CONSTANTS.NA,
                            therapistType: evaluationNote?.signed?.therapistType
                                ? evaluationNote?.signed?.therapistType
                                : credentials
                                ? credentials.therapistType
                                : CONSTANTS.NA,
                        };

                        this.__evaluationNote = {
                            header: cloneDeep(this.header),
                            reasonForReferral: cloneDeep(this.reasonForReferral),
                            chiefComplaint: cloneDeep(this.chiefComplaint),
                            priorLevelOfFunction: cloneDeep(this.priorLevelOfFunction),
                            previousTherapy: cloneDeep(this.previousTherapy),
                            prognosisForAchievingRehabGoals: evalOnly
                                ? null
                                : cloneDeep(this.prognosisForAchievingRehabGoals),
                            caregiverEducation: cloneDeep(this.caregiverEducation),
                            interventionApproach: cloneDeep(this.interventionApproach),
                            therapyNecessity: cloneDeep(this.therapyNecessity),
                            dischargeDestination: evalOnly ? null : cloneDeep(this.dischargeDestination),
                            dischargePlan: evalOnly ? null : cloneDeep(this.dischargePlan),
                            medicalDiagnosis: cloneDeep(this.medicalDiagnosis),
                            treatmentDiagnosis: cloneDeep(this.treatmentDiagnosis),
                            pastMedicalHistory: cloneDeep(this.pastMedicalHistory),
                            priorHospitalizationDates: cloneDeep(this.priorHospitalizationDates),
                            contraindications: cloneDeep(this.contraindications),
                            medications: cloneDeep(this.medications),
                            socialDeterminantsOfHealth: cloneDeep(this.socialDeterminantsOfHealth),
                            environmentalFactors: cloneDeep(this.environmentalFactors),
                            precautions: cloneDeep(this.precautions),
                            evaluationType: cloneDeep(this.evaluationType),
                            goals: evalOnly ? null : cloneDeep(this.goals),
                            patientGoals: cloneDeep(this.patientGoals),
                            impairments: cloneDeep(this.impairments),
                            functionalDeficits: cloneDeep(this.functionalDeficits),
                            standardizedTests: cloneDeep(this.standardizedTests),
                            skilledServices: evalOnly ? null : cloneDeep(this.skilledServices),
                            frequencyAndDuration: evalOnly ? null : cloneDeep(this.frequencyAndDuration),
                            reasonForNotCreatingPoc: !evalOnly ? null : cloneDeep(this.reasonForNotCreatingPoc),
                            addendum: cloneDeep(this.addendum),
                            signature: cloneDeep(this.signature),
                        };
                        //  this.countLoadedNotes();
                        resolve(true);
                    }
                }
            });
        });
    }
    prepareDischargeData(selectedDocsId: string): Promise<boolean> {
        this.therapyAdmission$ = this.store.pipe(select(CONSTANTS.THERAPY_ADMISSION));
        return new Promise((resolve) => {
            this.therapyAdmission$.pipe(take(1)).subscribe(async (therapyAdmissions) => {
                for (const therapyAdmission of therapyAdmissions) {
                    try {
                        const dischargeNote = therapyAdmission.documentation.dischargeNote;
                        if (selectedDocsId === dischargeNote.id) {
                            const userId = dischargeNote.signed.signedBy['_id'];
                            const credentials = await this.setCredentials(userId);

                            this.header = {
                                dueDate: dischargeNote.day,
                                endCareDate: dischargeNote.assessment.endOfCareDate,
                                signed: dischargeNote.signed,
                                note: CONSTANTS.DISCHARGE,
                                discipline: therapyAdmission.therapyDiscipline,
                                dob: this.patient.DOB,
                                physician: therapyAdmission.attendingPhysicians[0]
                                    ? therapyAdmission.attendingPhysicians[0]
                                    : CONSTANTS.NA,
                            };
                            this.patientAssessmentSinceLastTreatment =
                                dischargeNote.subjectiveAssessment.patientAssessmentSinceTreatmentStarted;
                            this.clinicalImpression = dischargeNote.assessment.clinicalImpression;
                            this.endOfCareReason = dischargeNote.assessment.endOfCareReason;
                            this.dischargeDestination = dischargeNote.plan.dischargeDestination;
                            this.dischargePlan = dischargeNote.plan.dischargeInstructions;
                            this.treatmentDiagnosis = dischargeNote.patientHistory.treatmentDiagnosis;
                            this.medicalDiagnosis = dischargeNote.patientHistory.medicalDiagnosis;
                            this.functionalDeficits = dischargeNote.objectiveAssessment.functionalDeficits;
                            this.sectionGg = dischargeNote.objectiveAssessment.ggItems;
                            this.caregiverEducation = dischargeNote.plan.caregiverEducation;
                            this.goals = { goals: dischargeNote.goalsStatus, type: CONSTANTS.DISCHARGE };
                            this.addendum = dischargeNote.addendum;

                            let transformedHistory = [];
                            if (dischargeNote.changeHistoryPrint) {
                                transformedHistory = dischargeNote.changeHistoryPrint.map((el) => {
                                    return {
                                        timestamp: el.timestamp,
                                        therapist: el.therapist,
                                        changedFields: el.changedFields.map((t: any) => {
                                            const fieldValue = t.fieldKey ? t.fieldKey : t;
                                            return this.translateService.instant(
                                                'appTranslation.pages.documentation.discharge.' + fieldValue
                                            );
                                        }),
                                    };
                                });
                            }
                            this.changeHistoryPrint = transformedHistory;

                            this.signature = {
                                signed: dischargeNote.signed,
                                coSigned: dischargeNote.coSigned,
                                physician: therapyAdmission.attendingPhysicians[0],
                                discipline: therapyAdmission.therapyDiscipline,
                                license: credentials ? credentials.license : CONSTANTS.NA,
                                signCredentials: credentials ? credentials.signCredentials : CONSTANTS.NA,
                                therapistType: dischargeNote?.signed?.therapistType
                                    ? dischargeNote?.signed?.therapistType
                                    : credentials
                                    ? credentials.therapistType
                                    : CONSTANTS.NA,
                            };
                            this.__dischargeNote = {
                                header: cloneDeep(this.header),
                                patientAssessmentSinceLastTreatment: cloneDeep(
                                    this.patientAssessmentSinceLastTreatment
                                ),
                                clinicalImpression: cloneDeep(this.clinicalImpression),
                                endOfCareReason: cloneDeep(this.endOfCareReason),
                                dischargeDestination: cloneDeep(this.dischargeDestination),
                                dischargePlan: cloneDeep(this.dischargePlan),
                                treatmentDiagnosis: cloneDeep(this.treatmentDiagnosis),
                                medicalDiagnosis: cloneDeep(this.medicalDiagnosis),
                                functionalDeficits: cloneDeep(this.functionalDeficits),
                                sectionGg: cloneDeep(this.sectionGg),
                                caregiverEducation: cloneDeep(this.caregiverEducation),
                                goals: cloneDeep(this.goals),
                                addendum: cloneDeep(this.addendum),
                                changeHistoryPrint: cloneDeep(this.changeHistoryPrint),
                                signature: cloneDeep(this.signature),
                            };
                            resolve(true);
                        }
                    } catch (error) {
                        this.toaster.show({
                            title: 'Print',
                            body: 'Layout load unsuccessful due to some error.',
                            type: 'error',
                        });
                        window.history.back();
                    }
                }
            });
        });
    }
    prepareDischargeDataForIntegration(dischargeNote: any): Promise<boolean> {
        this.therapyAdmission$ = this.store.pipe(select(CONSTANTS.THERAPY_ADMISSION));
        return new Promise((resolve) => {
            this.therapyAdmission$.pipe(take(1)).subscribe(async (therapyAdmissions) => {
                for (const therapyAdmission of therapyAdmissions) {
                    const discharge = therapyAdmission.documentation.dischargeNote;
                    if (dischargeNote.id === discharge.id) {
                        const userId = dischargeNote.signed.signedBy['_id'] || dischargeNote.signed.signedBy['id'];
                        const credentials = await this.setCredentials(userId);

                        this.header = {
                            dueDate: dischargeNote.day,
                            endCareDate: dischargeNote.assessment.endOfCareDate,
                            signed: dischargeNote.signed,
                            note: CONSTANTS.DISCHARGE,
                            discipline: therapyAdmission.therapyDiscipline,
                            dob: this.patient.DOB,
                            physician: therapyAdmission.attendingPhysicians[0]
                                ? therapyAdmission.attendingPhysicians[0]
                                : CONSTANTS.NA,
                        };
                        this.patientAssessmentSinceLastTreatment =
                            dischargeNote.subjectiveAssessment.patientAssessmentSinceTreatmentStarted;
                        this.clinicalImpression = dischargeNote.assessment.clinicalImpression;
                        this.endOfCareReason = dischargeNote.assessment.endOfCareReason;
                        this.dischargeDestination = dischargeNote.plan.dischargeDestination;
                        this.dischargePlan = dischargeNote.plan.dischargeInstructions;
                        this.treatmentDiagnosis = dischargeNote.patientHistory.treatmentDiagnosis;
                        this.medicalDiagnosis = dischargeNote.patientHistory.medicalDiagnosis;
                        this.functionalDeficits = dischargeNote.objectiveAssessment.functionalDeficits;
                        this.sectionGg = dischargeNote.objectiveAssessment.ggItems;
                        this.caregiverEducation = dischargeNote.plan.caregiverEducation;
                        this.goals = { goals: dischargeNote.goalsStatus, type: CONSTANTS.DISCHARGE };
                        this.addendum = dischargeNote.addendum;
                        this.signature = {
                            signed: dischargeNote.signed,
                            coSigned: dischargeNote.coSigned,
                            physician: therapyAdmission.attendingPhysicians[0],
                            discipline: therapyAdmission.therapyDiscipline,
                            license: credentials ? credentials.license : CONSTANTS.NA,
                            signCredentials: credentials ? credentials.signCredentials : CONSTANTS.NA,
                            therapistType: dischargeNote?.signed?.therapistType
                                ? dischargeNote?.signed?.therapistType
                                : credentials
                                ? credentials.therapistType
                                : CONSTANTS.NA,
                        };
                        this.__dischargeNote = {
                            header: cloneDeep(this.header),
                            patientAssessmentSinceLastTreatment: cloneDeep(this.patientAssessmentSinceLastTreatment),
                            clinicalImpression: cloneDeep(this.clinicalImpression),
                            endOfCareReason: cloneDeep(this.endOfCareReason),
                            dischargeDestination: cloneDeep(this.dischargeDestination),
                            dischargePlan: cloneDeep(this.dischargePlan),
                            treatmentDiagnosis: cloneDeep(this.treatmentDiagnosis),
                            medicalDiagnosis: cloneDeep(this.medicalDiagnosis),
                            functionalDeficits: cloneDeep(this.functionalDeficits),
                            sectionGg: cloneDeep(this.sectionGg),
                            caregiverEducation: cloneDeep(this.caregiverEducation),
                            goals: cloneDeep(this.goals),
                            addendum: cloneDeep(this.addendum),
                            signature: cloneDeep(this.signature),
                        };
                        // this.countLoadedNotes();
                        resolve(true);
                    }
                }
            });
        });
    }

    set reasonForNotCreatingPoc(value: string) {
        this.__reasonForNotCreatingPoc = value;
    }

    get reasonForNotCreatingPoc() {
        return this.__reasonForNotCreatingPoc;
    }

    set description(value: string) {
        this.__description = value;
    }

    get description() {
        return this.__description;
    }

    set endOfCareReason(value: string) {
        this.__endOfCareReason = value;
    }

    get endOfCareReason() {
        return this.__endOfCareReason;
    }

    set prognosisForAchievingRehabGoals(value: string) {
        this.__prognosisForAchievingRehabGoals = value;
    }

    get prognosisForAchievingRehabGoals() {
        return this.__prognosisForAchievingRehabGoals;
    }

    set reasonForReferral(value: string) {
        this.__reasonForReferral = value;
    }

    get reasonForReferral() {
        return this.__reasonForReferral;
    }

    set interventionApproach(value: string) {
        this.__interventionApproach = value;
    }

    get interventionApproach() {
        return this.__interventionApproach;
    }

    set caregiverEducation(value: string) {
        this.__caregiverEducation = value;
    }

    get caregiverEducation() {
        return this.__caregiverEducation;
    }

    set previousTherapy(value: string) {
        this.__previousTherapy = value;
    }

    get previousTherapy() {
        return this.__previousTherapy;
    }

    set priorLevelOfFunction(value: string) {
        this.__priorLevelOfFunction = value;
    }

    get priorLevelOfFunction() {
        return this.__priorLevelOfFunction;
    }

    set chiefComplaint(value: string) {
        this.__chiefComplaint = value;
    }

    get chiefComplaint() {
        return this.__chiefComplaint;
    }

    set reasonForUpdate(value: string) {
        this.__reasonForUpdate = value;
    }

    get reasonForUpdate() {
        return this.__reasonForUpdate;
    }

    set reasonForContinuingTreatment(value: string) {
        this.__reasonForContinuingTreatment = value;
    }

    get reasonForContinuingTreatment() {
        return this.__reasonForContinuingTreatment;
    }

    set dischargePlan(value: string) {
        this.__dischargePlan = value;
    }

    get dischargePlan() {
        return this.__dischargePlan;
    }

    set dischargeDestination(value: string) {
        this.__dischargeDestination = value;
    }

    get dischargeDestination() {
        return this.__dischargeDestination;
    }

    set updateToTreatment(value: string) {
        this.__updateToTreatment = value;
    }

    get updateToTreatment() {
        return this.__updateToTreatment;
    }

    set therapyNecessity(value: string) {
        this.__therapyNecessity = value;
    }

    get therapyNecessity() {
        return this.__therapyNecessity;
    }

    set patientAssessmentSinceLastTreatment(value: string) {
        this.__patientAssessmentSinceLastTreatment = value;
    }

    get patientAssessmentSinceLastTreatment() {
        return this.__patientAssessmentSinceLastTreatment;
    }

    set clinicalImpression(value: string) {
        this.__clinicalImpression = value;
    }

    get clinicalImpression() {
        return this.__clinicalImpression;
    }

    set precautions(value: GridLayoutReport) {
        this.__precautions = value;
    }

    get precautions() {
        return this.__precautions;
    }

    set medications(value: GridLayoutReport) {
        this.__medications = value;
    }

    get medications() {
        return this.__medications;
    }

    set socialDeterminantsOfHealth(value: GridLayoutReport) {
        this.__socialDeterminantsOfHealth = value;
    }

    get socialDeterminantsOfHealth() {
        return this.__socialDeterminantsOfHealth;
    }

    set environmentalFactors(value: GridLayoutReport) {
        this.__environmentalFactors = value;
    }

    get environmentalFactors() {
        return this.__environmentalFactors;
    }

    set patientGoals(value: GridLayoutReport) {
        this.__patientGoals = value;
    }

    get patientGoals() {
        return this.__patientGoals;
    }

    set contraindications(value: GridLayoutReport) {
        this.__contraindications = value;
    }

    get contraindications() {
        return this.__contraindications;
    }

    set responseToTreatment(value: string) {
        this.__responseToTreatment = value;
    }

    get responseToTreatment() {
        return this.__responseToTreatment;
    }

    set planForNextTreatment(value: string) {
        this.__planForNextTreatment = value;
    }

    get planForNextTreatment() {
        return this.__planForNextTreatment;
    }
    set anticipatedDischargeDate(value: string) {
        this.__anticipatedDischargeDate = value;
    }

    get anticipatedDischargeDate() {
        return this.__anticipatedDischargeDate;
    }

    set overallPlanAndUpdates(value: string) {
        this.__overallPlanAndUpdates = value;
    }

    get overallPlanAndUpdates() {
        return this.__overallPlanAndUpdates;
    }

    set vitals(vitals: any) {
        const gridData: TreeGrid[] = [];
        const data = this.gridCrane(GRID_CONSTANTS.VITALS, vitals, CONSTANTS.VITALS);
        gridData.push({ data });
        this.__vitals = { data: gridData };
    }

    get vitals() {
        return this.__vitals;
    }

    set coTreatment(coTreatment: any) {
        this.__coTreatmentMinutes = new GridLayoutReport();
        if (coTreatment && Object.keys(coTreatment).length) {
            const gridData: TreeGrid[] = [];
            const pile = {
                coTreatTime: coTreatment?.coTreat?.minutes?.toString(),
                coTreatingTherapist: coTreatment?.coTreat?.disciplines
                    .map(
                        (dis) =>
                            `${dis?.therapistId?.user?.firstName} ${dis?.therapistId?.user?.lastName} (${dis?.discipline})`
                    )
                    .join(', '),
                justification: coTreatment?.coTreat?.justification,
                concurrentTime: coTreatment?.concurrent?.minutes?.toString(),
            };
            const data = this.gridCrane(
                GRID_CONSTANTS.OTHER_TREATMENT_MINUTES,
                pile,
                CONSTANTS.OTHER_TREATMENT_MINUTES
            );
            gridData.push({ data });
            this.__coTreatmentMinutes = { data: gridData };
        }
    }

    get coTreatment() {
        return this.__coTreatmentMinutes;
    }

    set treatment(data: DetailedCptCodes[]) {
        this.__treatment = data;
    }

    get treatment() {
        return this.__treatment;
    }

    set addendum(allAddendum: any) {
        const gridData: TreeGrid[][] = [];
        const data = allAddendum.map((addendum) => {
            const date = new Date(addendum.signed.signDate);
            const name = `${addendum.signed.signedBy[0].firstName} ${addendum.signed.signedBy[0].lastName}`;
            const role = `${
                addendum.signed.signedBy[0]?.roles?.length
                    ? this.getCommaSeparatedRoles(addendum.signed.signedBy[0]?.roles) || ''
                    : addendum.signed.signedBy[0]?.role || ''
            }`;
            return {
                description: addendum.description,
                usernameWithRole: `${name}, ${role}`,
                date: `${format(date, CONSTANTS.DATE_FORMATE)}`,
                time: `${format(date, CONSTANTS.TIME_FORMATE)}`,
            };
        });
        data.forEach((addendum) => {
            const data = this.gridCrane(GRID_CONSTANTS.ADDENDUM, addendum, CONSTANTS.ADDENDUM);
            gridData.push(data);
        });
        this.__addendum = { data: gridData };
    }

    get addendum(): GridLayoutReport {
        return this.__addendum;
    }

    set changeHistoryPrint(allHistory: any) {
        const header = HEADERS.CHANGE_HISTORY_PRINT;
        const data = allHistory
            .map((history) => {
                return {
                    username: history.therapist.name + ` (${history.therapist.type})`,
                    sectionsEdited: history.changedFields.map((e) => ' ' + e),
                    timestamp: history.timestamp,
                    timeDate: history.timestamp
                        ? `${format(new Date(history.timestamp), CONSTANTS.DATE_FORMATE)}` +
                          ' ' +
                          `${format(new Date(history.timestamp), CONSTANTS.TIME_FORMATE)}`
                        : CONSTANTS.NA,
                };
            })
            .sort((a, b) => {
                const dateA = a.timestamp ? new Date(a.timestamp).getTime() : 0;
                const dateB = b.timestamp ? new Date(b.timestamp).getTime() : 0;
                return dateB - dateA;
            });
        this.__changeHistoryPrint = { header, data };
    }

    get changeHistoryPrint(): GridLayoutReport {
        return this.__changeHistoryPrint;
    }

    set treatmentDiagnosis(treatmentDiagnosis: any) {
        const header = HEADERS.DIAGNOSIS;
        const data: DiagnosisReport[] = treatmentDiagnosis.map((diagnosis) => {
            return {
                code: diagnosis.code,
                description: diagnosis.description,
                onsetDate: diagnosis.onsetDate
                    ? `${format(new Date(diagnosis.onsetDate), CONSTANTS.DATE_FORMATE)}`
                    : CONSTANTS.NA,
            };
        });
        this.__treatmentDiagnosis = { header, data };
    }

    get treatmentDiagnosis(): GridLayoutReport {
        return this.__treatmentDiagnosis;
    }

    set pastMedicalHistory(pastMedicalHistory: any) {
        const header = HEADERS.MEDICAL_HISTORY;
        const data: DiagnosisReport[] = pastMedicalHistory.map((history, index) => {
            return {
                no: index + 1,
                description: history.description,
            };
        });
        this.__pastMedicalHistory = { header, data };
    }

    get pastMedicalHistory(): GridLayoutReport {
        return this.__pastMedicalHistory;
    }

    set priorHospitalizationDates(priorHospitalizationDates: any) {
        const header = HEADERS.HOSPITALIZATION_DATES;
        const data: DiagnosisReport[] = priorHospitalizationDates.map((date, index) => {
            return {
                no: index + 1,
                description: date.description,
                admissionDate: date?.admission?.length
                    ? `${format(new Date(date.admission), CONSTANTS.DATE_FORMATE)}`
                    : CONSTANTS.NA,
                dischargeDate: date?.discharge?.length
                    ? `${format(new Date(date.discharge), CONSTANTS.DATE_FORMATE)}`
                    : CONSTANTS.NA,
            };
        });
        this.__priorHospitalizationDates = { header, data };
    }

    get priorHospitalizationDates(): GridLayoutReport {
        return this.__priorHospitalizationDates;
    }

    set skilledServices(skilledServices: any) {
        const header = HEADERS.SKILLED_SERVICES;
        const data = skilledServices.map((service: SkilledServices, index: number) => {
            return {
                no: index + 1,
                code: service.cptCode,
                description: service.description,
            };
        });
        this.__skilledServices = { header, data };
    }

    get skilledServices(): GridLayoutReport {
        return this.__skilledServices;
    }

    set medicalDiagnosis(medicalDiagnosis: any) {
        const header = HEADERS.DIAGNOSIS;
        const data: DiagnosisReport[] = medicalDiagnosis.map((diagnosis: MedicalDiagnosis) => {
            return {
                code: diagnosis.code,
                description: diagnosis.description,
                isPrimary: diagnosis.isPrimary,
                onsetDate:
                    typeof diagnosis?.onsetDate === 'string'
                        ? // For string
                          diagnosis.onsetDate
                            ? `${format(new Date(diagnosis.onsetDate), CONSTANTS.DATE_FORMATE)}`
                            : CONSTANTS.NA
                        : // For numeric values
                        new Date(diagnosis?.onsetDate)
                              .toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
                              .includes('-')
                        ? `${format(new Date(diagnosis?.onsetDate), CONSTANTS.DATE_FORMATE)}`
                        : CONSTANTS.NA,
            };
        });
        this.__medicalDiagnosis = { header, data };
    }

    get medicalDiagnosis(): GridLayoutReport {
        return this.__medicalDiagnosis;
    }

    set goals(data: any) {
        const { goals, type } = data;
        const gridData: TreeGrid[] = [];
        goals.forEach((goal: Goal) => {
            const children = [];
            const ltgBlocks = cloneDeep(GRID_CONSTANTS.LTG);
            if (
                type === CONSTANTS.EVALUATION ||
                ![GOAL_CONSTANTS.DISCONTINUED, GOAL_CONSTANTS.NOT_ACHIEVED].includes(goal.status.description)
            )
                ltgBlocks.splice(
                    ltgBlocks.findIndex((stg: TableDataBlock) => stg.key === CONSTANTS.REASON_FOR_DISCONTINUE),
                    1
                );
            if (goal.status.description === GOAL_CONSTANTS.NOT_ACHIEVED) {
                this.handleNotAchievedCase(ltgBlocks);
            }
            const data = this.gridCrane(ltgBlocks, goal);
            if (goal.stgs.length) {
                goal.stgs.forEach((shortGoal: Goal) => {
                    const stgBlocks = cloneDeep(GRID_CONSTANTS.STG);
                    if (
                        type === CONSTANTS.EVALUATION ||
                        ![GOAL_CONSTANTS.DISCONTINUED, GOAL_CONSTANTS.NOT_ACHIEVED].includes(
                            shortGoal.status.description
                        )
                    )
                        stgBlocks.splice(
                            stgBlocks.findIndex((stg: TableDataBlock) => stg.key === CONSTANTS.REASON_FOR_DISCONTINUE),
                            1
                        );
                    if (shortGoal.status.description === GOAL_CONSTANTS.NOT_ACHIEVED) {
                        this.handleNotAchievedCase(stgBlocks);
                    }
                    const child = this.gridCrane(stgBlocks, shortGoal);
                    children.push(child);
                });
            }
            gridData.push({ data, children });
        });
        this.__goals = { data: gridData };
    }

    get goals(): GridLayoutReport {
        return this.__goals;
    }

    set impairments(impairments: any) {
        const gridData: TreeGrid[][] = [];
        impairments.forEach((impairment: Impairments) => {
            const data = this.gridCrane(GRID_CONSTANTS.IMPAIRMENT, impairment, CONSTANTS.IMPAIRMENT);
            gridData.push(data);
        });
        this.__impairments = { data: gridData };
    }

    get impairments(): GridLayoutReport {
        return this.__impairments;
    }

    set functionalDeficits(functionalDeficits: any) {
        const gridData: TreeGrid[][] = [];
        functionalDeficits.forEach((deficit) => {
            const data = this.gridCrane(GRID_CONSTANTS.FUNCTIONAL_DEFICITS, deficit, CONSTANTS.FUNCTIONAL_DEFICITS);
            gridData.push(data);
        });
        this.__functionalDeficits = { data: gridData };
    }

    get functionalDeficits(): GridLayoutReport {
        return this.__functionalDeficits;
    }

    set sectionGg(sectionGg: any) {
        const gridData: TreeGrid[][] = [];
        sectionGg.forEach((item) => {
            const data = this.gridCrane(GRID_CONSTANTS.SECTION_GG, item, CONSTANTS.SECTION_GG);
            gridData.push(data);
        });
        this.__sectionGg = { data: gridData };
    }

    get sectionGg(): GridLayoutReport {
        return this.__sectionGg;
    }

    set standardizedTests(standardizedTests: any) {
        const gridData: TreeGrid[][] = [];
        standardizedTests.forEach((test) => {
            const data = this.gridCrane(GRID_CONSTANTS.STANDARDIZED_TESTS, test, CONSTANTS.STANDARDIZED_TESTS);
            gridData.push(data);
        });
        this.__standardizedTests = { data: gridData };
    }

    get standardizedTests(): GridLayoutReport {
        return this.__standardizedTests;
    }

    set frequencyAndDuration(frequencyAndDuration: any) {
        const gridData: TreeGrid[] = [];
        const data = this.gridCrane(GRID_CONSTANTS.FREQUENCY_AND_DURATION, frequencyAndDuration);
        gridData.push({ data });
        this.__frequencyAndDuration = { data: gridData };
    }

    get frequencyAndDuration(): GridLayoutReport {
        return this.__frequencyAndDuration;
    }

    set evaluationType(evaluationType: any) {
        const gridData: TreeGrid[] = [];
        const data = this.gridCrane(GRID_CONSTANTS.EVALUATION_TYPE, evaluationType, CONSTANTS.EVAL_TYPE);
        gridData.push({ data });
        this.__evaluationType = { data: gridData };
    }

    get evaluationType(): GridLayoutReport {
        return this.__evaluationType;
    }

    set header(data: any) {
        const date = new Date(this.calculateDueDate(data.note, data.dueDate));
        if (data.endCareDate) {
            const endCareDate = new Date((data.note, data.endCareDate));
            this.__header.endCareDate = `${format(endCareDate, CONSTANTS.DATE_FORMATE)}`;
        }
        const therapist = data?.signed?.signedBy
            ? `${data.signed.signedBy.lastName}, ${data.signed.signedBy.firstName}`
            : data?.assignedTherapist
            ? `${data.assignedTherapist.lastName}, ${data.assignedTherapist.firstName}`
            : CONSTANTS.NA;

        this.__header.patientName = `${this.patient.lastName}, ${this.patient.firstName}`;
        this.__header.note = data.note;
        this.__header.dateOfService = `${format(date, CONSTANTS.DATE_FORMATE)}`;
        this.__header.discipline = data.discipline;
        this.__header.dob = data.dob;
        this.__header.therapist = therapist;
        this.__header.totalVisits = data.totalVisits;
        this.__header.range = data.range;
        this.__header.integration = !!data.integration;
        this.__header.mrn = this.MRN;
        this.__header.attendingPhysician =
            data?.physician?.user?.lastName && data?.physician?.user?.firstName
                ? `${data.physician.user.lastName}, ${data.physician.user.firstName}`
                : null;
    }
    get header(): ReportHeader {
        return this.__header;
    }

    set signature(data: any) {
        const date = data.signed?.signDate;
        const therapist = data?.signed?.signedBy
            ? `${data.signed.signedBy.lastName}, ${data.signed.signedBy.firstName}`
            : data?.assignedTherapist
            ? `${data.assignedTherapist.lastName}, ${data.assignedTherapist.firstName}`
            : CONSTANTS.NA;

        this.__signature.physician =
            data?.physician?.signedBy?.user?.lastName && data?.physician?.signedBy?.user?.firstName
                ? `${data.physician.signedBy.user.lastName}, ${data.physician.signedBy.user.firstName}`
                : null;
        this.__signature.physicianSign = data.physician?.signedAt
            ? `${format(new Date(data.physician?.signedAt), CONSTANTS.DATE_FORMATE)}`
            : null;
        this.__signature.physicianNpi = data?.physician?.signedBy?.NPI ? data.physician.signedBy.NPI : null;
        this.__signature.physicianCredentials = data?.physician?.physicianCredentials ?? data?.physician?.signedBy?.credentials
        this.__signature.therapist = therapist;
        this.__signature.therapistLicense = data.license;
        this.__signature.integration = !!data.integration;
        this.__signature.therapistDiscipline = `${data?.discipline}`;
        this.__signature.signCredentials = data.signCredentials;
        this.__signature.therapistType = `${shortTherapistType(data.therapistType)}`;
        this.__signature.therapistSign = date ? `${format(new Date(date), CONSTANTS.DATE_FORMATE)}` : null;
        this.__signature.coSignTherapist = `${data?.coSigned?.signedTherapist?.user?.firstName} ${data?.coSigned?.signedTherapist?.user?.lastName}`;
        this.__signature.coSignTherapistDiscipline = data?.coSigned?.signedTherapist?.therapistType;
        this.__signature.coSignTherapistLicense = data?.coSigned?.signedTherapist?.licensures?.licensureNumber;
        this.__signature.coSignTherapistSign = data?.coSigned?.signedDate
            ? `${format(new Date(data?.coSigned?.signedDate), CONSTANTS.DATE_FORMATE)}`
            : null;
        this.__signature.coSignTherapistSignCredentials = data?.coSigned?.signedTherapist?.signCredentials;
        this.__signature.noteDate = data.noteDate ? `${format(new Date(data.noteDate), CONSTANTS.DATE_FORMATE)}` : null;
        this.__signature.status = data.status;
    }

    get signature(): ReportSignature {
        return this.__signature;
    }

    get MRN(): string {
        return this.__MRN;
    }

    get dailyNote(): DailyNoteReport {
        return this.__dailyNote;
    }

    get progressNote(): ProgressNoteReport {
        return this.__progressNote;
    }

    get recertificationNote(): RecertificationNoteReport {
        return this.__recertificationNote;
    }

    get updatedPlanOfCareNote(): UpdatedPlanOfCareNoteReport {
        return this.__updatedPlanOfCareNote;
    }

    get evaluationNote(): EvaluationNoteReport {
        return this.__evaluationNote;
    }

    get dischargeNote(): DischargeNoteReport {
        return this.__dischargeNote;
    }

    get addendumNote(): AddendumNoteReport {
        return this.__addendumNote;
    }

    async setCredentials(userId: string): Promise<Credentials> {
        const therapist: Promise<Credentials> = new Promise((resolve) => {
            this.therapistService
                .getTherapist(userId)
                .pipe(take(1))
                .subscribe((data) => {
                    // Show all valid licenses along with state code
                    let licensures = ''
                    const validLicenses = data?.licensures.filter(license => new Date(license.expirationDate) > new Date())
                    validLicenses.forEach((license, index) => {
                        const stateCode = states.find(state => state.stateName === license.state)?.stateCode
                        licensures = licensures + `${license?.licensureNumber} - ${stateCode}${index < validLicenses.length - 1 ? ', ' : ''}`
                    })
                    const credentials = {
                        userId,
                        therapistNpi: data?.NPI || CONSTANTS.NA,
                        license: licensures,
                        signCredentials: data?.signCredentials || null,
                        therapistType: data?.therapistType,
                    };
                    this.__credentials.push(credentials);
                    resolve(credentials);
                });
        });
        return therapist;
    }

    /**
     * This grid crane puts big blocks of data from the pile of data which we are providing it
     * to build a dynamic grid
     * @param pile Array of TableDataBlock which is used as pile of un-arranged data
     * @param data The actual data to map on the grid
     * @param section Name of the topic for which the grid is to be manufactured e.g. vitals, functionalDeficits etc.
     * @returns Arrange set of grid blocks
     */
    gridCrane = (pile: TableDataBlock[], data, section?: string) => {
        let block;
        const dataClone = cloneDeep(data);
        const row = [];
        pile.forEach((d) => {
            let widthIncrement;
            // setting odd block
            block = cloneDeep(d);
            // in case of impairments there are dynamic field name
            if (section === CONSTANTS.IMPAIRMENT && d.key === CONSTANTS.VALUE) {
                const { value, updatedWidth, incrementedWidth } = this.setFieldValuesForImpairments(
                    dataClone?.fieldName,
                    block.width
                );
                block.value = value;
                block.width = updatedWidth;
                widthIncrement = incrementedWidth;
            }
            // pushing odd block in row
            row.push(cloneDeep(block));
            // in case of activitiy tolerance in goals there are dynamic field values
            if (
                dataClone?.goal?.includes(NEURO_MUSCULO_SKELETAL_IMPAIRMENTS.ACTIVITY_TOLERANCE) &&
                (d.key === CONSTANTS.STG ||
                    d.key === CONSTANTS.LTG ||
                    d.key === CONSTANTS.CLOF ||
                    d.key === CONSTANTS.PLOF)
            ) {
                switch (d.key) {
                    case CONSTANTS.STG:
                    case CONSTANTS.LTG:
                        dataClone.target = this.conversionToMins(dataClone.target);
                        break;
                    case CONSTANTS.CLOF:
                        dataClone.clof.levelOfFunction = dataClone?.clof?.levelOfFunction?.length
                            ? this.conversionToMins(dataClone.clof.levelOfFunction)
                            : '';
                        break;
                    case CONSTANTS.PLOF:
                        dataClone.plof.levelOfFunction = dataClone?.plof?.levelOfFunction?.length
                            ? this.conversionToMins(dataClone.plof.levelOfFunction)
                            : '';
                        break;
                }
            }
            // mapping block values based on data
            const mapper = gridValueMapper(d.key, dataClone, section);
            // in case of activitiy tolerance in impairments there are dynamic field values
            if (
                dataClone?.impairmentName === NEURO_MUSCULO_SKELETAL_IMPAIRMENTS.ACTIVITY_TOLERANCE &&
                d.key === CONSTANTS.VALUE
            ) {
                mapper.value = this.conversionToMins(dataClone.fieldValue);
            }
            // setting even block
            block = {
                key: `${d.key}-${CONSTANTS.VALUE}`,
                value: mapper.value,
                width: widthIncrement ? `${parseInt(mapper.width.slice(0, -1)) - widthIncrement}%` : mapper.width,
                isBold: mapper.isBold,
                isRightBordered: mapper.isRightBordered,
                paddingLeft: mapper.paddingLeft,
            };
            // pushing even block in row
            row.push(cloneDeep(block));
        });
        return row;
    };

    notesSequence = (selectedDocs) => {
        const alignedNotes = NOTES_SEQUENCE.reduce((sequencedNotes: any, note) => {
            const notes = selectedDocs.filter((doc) => doc.type === note);
            const sortByDueDate = notes.sort((a, b) => {
                if (a.dueDate && b.dueDate && new Date(a.dueDate) && new Date(b.dueDate)) {
                    return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
                }
            });
            sequencedNotes = [...sequencedNotes, ...sortByDueDate];
            return sequencedNotes;
        }, []);
        return alignedNotes;
    };

    conversionToMins = (value: string): string => {
        if (!(parseInt(value) && isNaN(parseInt(value)))) {
            return value;
        }
        const { min, sec } = convertSecsToMins(+value);
        return placeZerosOnLeft(min, 2) + ':' + placeZerosOnLeft(sec, 2);
    };

    countLoadedNotes = () => {
        const count = this.getLoadedNotes() + 1;
        this.loadedNotes.next(count);
    };

    resetLoadedNotes = () => {
        this.loadedNotes.next(0);
    };

    getLoadedNotes = (): number => {
        return this.loadedNotes.value;
    };

    setFieldValuesForImpairments = (fieldName: string, width: string) => {
        let incrementedWidth = 0;
        const value = fieldName?.length ? fieldName : 'Value';
        incrementedWidth = fieldName.length / 2;
        const newWidth = parseInt(width.slice(0, -1)) + incrementedWidth;
        const updatedWidth = newWidth ? `${newWidth}%` : '10%'; // Updated it because it was showing the error that left side would never be undefined/null
        return { value, updatedWidth, incrementedWidth };
    };

    calculateDueDate(docType, date) {
        if (docType == DOCUMENTATION_CONSTANTS.EVALUATION) {
            const dueDate = new Date(date).toISOString();
            return dueDate ? dueDate : CONSTANTS.NA;
        } else if (docType == DOCUMENTATION_CONSTANTS.DISCHARGE && date) {
            const dueDate = new Date(date).toISOString();
            return dueDate ? dueDate : CONSTANTS.NA;
        } else if (docType == DOCUMENTATION_CONSTANTS.RECERTIFICATION && date) {
            const dueDate = new Date(new Date(date).setDate(new Date(date).getDate())).toISOString();
            return dueDate ? dueDate : CONSTANTS.NA;
        } else if (docType == DOCUMENTATION_CONSTANTS.ADDENDUM && date) {
            const dueDate = new Date(date).toISOString();
            return dueDate ? dueDate : CONSTANTS.NA;
        } else if (docType == DOCUMENTATION_CONSTANTS.UPOC && date) {
            const dueDate = new Date(new Date(date).setDate(new Date(date).getDate())).toISOString();
            return dueDate ? dueDate : CONSTANTS.NA;
        } else if (date) {
            const dueDate = date;
            return dueDate ? dueDate : CONSTANTS.NA;
        } else return CONSTANTS.NA;
    }
    calculateRangeAndVisits(document, note) {
        let from: any = new Date(-1);
        let to: any = new Date(note.day);
        document.progressNote.forEach((pNote) => {
            const dateOfService = new Date(pNote.day);
            if (
                pNote.id !== note.id &&
                pNote.status === DOCUMENTATION_CONSTANTS.COMPLETED &&
                dateOfService.getTime() >= from.getTime() &&
                ((document.progressNote.length > 1 && dateOfService.getTime() <= to.getTime()) ||
                    (document.progressNote.length <= 1 && dateOfService.getTime() < to.getTime()))
            ) {
                from = dateOfService;
            }
        });
        if (from.getTime() === new Date(-1).getTime()) {
            from = new Date(document.evaluation.day);
        }

        const visits = document.dailyNote.filter((note) => {
            const dateOfService = new Date(note.day);
            if (dateOfService.getTime() <= to.getTime() && dateOfService.getTime() >= from.getTime()) {
                return note;
            }
        });
        from = format(new Date(from), CONSTANTS.DATE_FORMATE);
        to = format(new Date(to), CONSTANTS.DATE_FORMATE);
        return {
            range: `${from} - ${to}`,
            totalVisits: visits.length.toString(),
        };
    }

    getCommaSeparatedRoles(roles) {
        return roles.map((role) => role.name).join(', ');
    }

    setCurrentTherapy(therapyAdmission) {
        this.therapyAdmission = therapyAdmission;
    }
    getCurrentDiscipline() {
        return this.therapyAdmission;
    }
    extractAssignedTherapist = (note: DailyNote) => {
        const assignedTherapist =
            note['assignedTherapists'] &&
            Array.isArray(note['assignedTherapists']) &&
            note['assignedTherapists'].length &&
            note['assignedTherapists'][0]['user']
                ? cloneDeep(note['assignedTherapists'][0]['user'])
                : null;
        return assignedTherapist;
    };
    /**
     * Changing text for heading if the current goal is not achieved
     */
    handleNotAchievedCase(goalBlock: any) {
        const index = goalBlock.findIndex((block) => block.key === 'reasonForDiscontinue');
        if (index > -1) {
            goalBlock[index].value = 'Reason For Not Achieving';
        }
    }
}
