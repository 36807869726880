<div
    [class]="'form-group ' + formGroupClass"
    class="{{ removeMargin }}"
    [ngClass]="{ 'right-aligned-switch': rightAlignToggle, 'disabled': teleHealthDisabled }"
>
    <label [class]="'body-large-bold text-secondary ' + labelClass" *ngIf="label"
        >{{ label | translate }} {{ isRequired ? '*' : '' }}</label
    >
    <div
        [class]="
            'label-outer d-flex flex-row justify-content-start align-items-center p-0 m-0 ' + controlContainerClass
        "
        [ngClass]="{ 'right-aligned-toggle': rightAlignToggle }"
    >
        <label class="switch p-0 m-0">
            <input [disabled]="disabled" type="checkbox" [(ngModel)]="value" (change)="valueChange($event)" />
            <span class="slider round"></span>
        </label>
    </div>
</div>