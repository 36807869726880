<div class="form-group body-small">
    <label>{{ label }}</label>
    <div
        ngbDropdown
        #dropdown="ngbDropdown"
        [placement]="placement"
        class="d-inline-block form-control rounded-curve"
        [ngClass]="disabled ? 'disabled-background-color' : 'bg-body-background'"
    >
        <button style="padding: 15px 40px 15px 15px" id="dropdownForm1" [disabled]="disabled" ngbDropdownToggle>
            <span
                [ngClass]="{ 'body-small placeholder': !value }"
                class="text-secondary float-left w-100 overflow-hidden text-left"
                >{{ value?.name ? (value?.name | titlecase | name) : placeholder }}</span
            >
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownForm1" class="w-100">
            <app-search
                *ngIf="options.length > 3"
                [placeholder]="searchPlaceholder"
                wrapperClass="mb-2 bg-body-background"
                (search)="therapistSearch($event)"
                inputClass="bg-body-background"
            ></app-search>
            <div
                *ngIf="filteredOptions.length > 0"
                style="overflow-y: scroll; overflow-x: hidden; max-height: 14rem"
                class="text-secondary body-small-bold bg-white"
            >
                <div
                    *ngFor="let option of filteredOptions"
                    class="pt-1 pr-2 pb-1 pl-2 dropdown-list"
                    (click)="optionSelect(option); $event.stopPropagation(); dropdown.close()"
                >
                    <div class="row">
                        <div class="col-8 body-large-semibold">{{ option.name | titlecase }}</div>
                        <div *ngIf="option.discipline" class="col-4 body-large-semibold text-right">
                            {{ option.discipline }}
                        </div>
                    </div>
                    <div class="mt-1 ml-1">
                        <!-- <div class="pills d-flex">
                            <span class="days">
                                <ngb-progressbar [type]="getType(25)" value="25"></ngb-progressbar>
                            </span>
                            <span class="days">
                                <ngb-progressbar [type]="getType(85)" value="85"></ngb-progressbar>
                            </span>
                            <span class="days">
                                <ngb-progressbar [type]="getType(50)" value="50"></ngb-progressbar>
                            </span>
                            <span class="weeks">
                                <ngb-progressbar [type]="getType(65)" value="65"></ngb-progressbar>
                            </span>
                        </div> -->
                        <div *ngIf="option.NPI" class="d-flex">
                            <div class="mr-3">
                                <div class="body-small-semibold mr-2">
                                    NPI # <span class="ml-1 body-small">{{ option.NPI }}</span>
                                </div>
                            </div>
                            <div>
                                <div class="body-small-semibold mr-2">
                                    License # <span class="ml-1 body-small">{{ option.licensureNumber }}</span>
                                </div>
                            </div>
                        </div>

                        <!-- <div *ngIf="option.duration" class="d-flex">
                            <div class="mr-3">
                                <div class="body-small-semibold mr-2">
                                    FREQUENCY : <span class="ml-1 body-small">{{ option.frequency }}</span>
                                </div>
                            </div>
                            <div>
                                <div class="body-small-semibold mr-2">
                                    Duration : <span class="ml-1 body-small">{{ option.duration }}</span>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="option.additionalRequirements">
                            <div class="body-small-semibold mr-2">
                                Additional Requirement :
                                <span class="ml-1 body-small" *ngFor="let add of option.additionalRequirements">
                                    {{ add }},
                                </span>
                            </div>
                        </div> -->
                    </div>
                    <div class="mt-3 dropdown-divider"></div>
                </div>
            </div>
            <div *ngIf="filteredOptions.length <= 0" class="text-secondary body-small-semibold text-center">
                No record found
            </div>
        </div>
    </div>
</div>
