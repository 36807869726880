import { createReducer, on } from '@ngrx/store';
import { payorRoutingChangedAction, payorRoutingsResetAction } from './../actions/payor-routing.actions';

import { payorRoutingInitialState } from '@app/store/selectors';
export const payorRoutingReducer = createReducer(
    payorRoutingInitialState,
    on(payorRoutingChangedAction, (state, { selectedTab }) => ({
        selectedTab: selectedTab,
    })),

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    on(payorRoutingsResetAction, (state) => ({
        ...payorRoutingInitialState,
    }))
);
