import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, take } from 'rxjs';
import { PatientCard, TherapistCard } from '@app/interfaces';
import { getDayName, getDayOfWeek, getPatientHoursAssignmentValue, noPatientSelected } from '@app/helpers/utils';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { isPast, isToday, getDay, addDays, startOfWeek } from 'date-fns';
import { ToasterService } from './toaster.service';
import { SideNavService } from '.';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class SchedulerService {
    autoSchedulingComplete: BehaviorSubject<boolean> = new BehaviorSubject(false);
    unAssignTriggered: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    _dropTriggered: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    refreshTherapistPatientData: Subject<any> = new Subject();
    private _therapists: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    selectedWeekDateLabel: string;
    constructor(
        private http: HttpClient,
        private toasterService: ToasterService,
        private sideNavService: SideNavService
    ) {}

    droppedPatients(event: CdkDragDrop<any>, therapist, day, type: 'dayView' | 'weekView') {
        if (noPatientSelected(event.previousContainer.data)) {
            if (this.isDroppable(day, event.previousContainer.data[event.previousIndex])) {
                this.schedulePatients(therapist, day, type, event.previousContainer.data[event.previousIndex]);
                this.schedulePatientDays([event.previousContainer.data[event.previousIndex]]).then((result) => {
                    if (result) {
                        if (this.removeCardFromList(event.previousContainer.data[event.previousIndex], day, type))
                            event.previousContainer.data.splice(event.previousIndex, 1);
                    }
                });
            }
        } else {
            const selectedResults = event.previousContainer.data.filter((item) => item.isSelected === true);
            this.schedulePatients(therapist, day, type, ...selectedResults);
            this.schedulePatientDays(selectedResults).then((result) => {
                if (result) {
                    selectedResults.forEach((sr) => {
                        const index = event.previousContainer.data.findIndex((d) => d.mrn == sr.mrn);
                        if (this.removeCardFromList(sr, day, type)) event.previousContainer.data.splice(index, 1);
                    });
                }
            });
        }
    }

    schedulePatients(chosenTherapist, day, type: 'dayView' | 'weekView', ...patients) {
        this.processPatients(patients, type, day, false, false, chosenTherapist);
    }

    autoScheduling(patients: any[], day, type: 'dayView' | 'weekView', rescheduling) {
        this.therapists.pipe(take(1)).subscribe((daysWithTherapist) => {
            this.customSortTherapists(daysWithTherapist);
            this.processPatients(patients, type, day, true, rescheduling, daysWithTherapist);
        });
        this.schedulePatientDays(patients).then((result) => {
            if (result) this.autoSchedulingComplete.next(true);
        });
    }

    autoReScheduling(patients, day, currentTherapist: TherapistCard) {
        this.autoScheduling(patients, day, 'dayView', currentTherapist);
    }

    //this function takes a patient performs all necessary checks and then pass it to next function for assignment
    processPatients(patients, type, day, autoScheduling, reschedulingRemovalTherapist, therapist) {
        patients.map((patient) => {
            patient.weekFrequency.map((weekday) => {
                if (weekday?.day?.day) {
                    const date = new Date(weekday.day.day);
                    if (
                        type == 'weekView' ||
                        (type == 'dayView' && date.setHours(0, 0, 0, 0) == new Date(day).setHours(0, 0, 0, 0))
                    ) {
                        //only if date is present or future
                        if (!isPast(date) || isToday(date)) {
                            // if day has conflicts
                            if (weekday.hasConflict && !weekday.hadConflict && weekday.planned) {
                                if (autoScheduling) {
                                    const filteredTherapists = this.sortAgain(
                                        therapist[getDayOfWeek(weekday.day.day)],
                                        patient.treatingTherapist,
                                        reschedulingRemovalTherapist
                                    );
                                    filteredTherapists.forEach((chosenTherapist) => {
                                        //check for patients treating therapist first and then look for other therapists

                                        this.processTherapistWithPatient(date, weekday, chosenTherapist, patient);
                                    });
                                } else {
                                    this.processTherapistWithPatient(date, weekday, therapist, patient);
                                }
                            }
                        }
                    }
                }
            });
        });
    }
    //sortAgainTherapistBasedOnPatientTreatingTherapist
    sortAgain(therapists: any[], treatingTherapist: any, reschedulingRemovalTherapist) {
        if (reschedulingRemovalTherapist) {
            //remove current therapist so that auto scheduling does not reassessing that therapist again
            const filteredTherapists = therapists.filter(
                (therapist) => therapist.id !== reschedulingRemovalTherapist.id
            );
            return filteredTherapists;
        } else {
            //put current therapist on top to have continium of care
            const filteredTherapists = therapists.filter((therapist) => therapist.id !== treatingTherapist);
            if (filteredTherapists.length != therapists.length) {
                filteredTherapists.unshift(therapists.filter((therapist) => therapist.id == treatingTherapist)[0]);
                return filteredTherapists;
            }
            return therapists;
        }
    }
    //this function takes a patient and therapist and assigns them to each other
    processTherapistWithPatient(date, weekday, chosenTherapist, patient) {
        if (chosenTherapist.availability?.days) {
            if (!isPast(date) || isToday(date)) {
                // if day has conflicts
                if (weekday.hasConflict && !weekday.hadConflict && weekday.planned) {
                    //check therapist availability
                    if (
                        chosenTherapist.availability?.days[getDayName(getDay(date))].available &&
                        this.hasTherapistAvailableTime(
                            chosenTherapist,
                            getDayName(getDay(date)),
                            weekday.day.individual.planned + weekday.day.concurrent.planned + weekday.day.group.planned
                        )
                    ) {
                        weekday.day.assignedTherapists.push({
                            role: 'Treating Therapist',
                            therapist: chosenTherapist.id,
                        });
                        this.therapists.pipe(take(1)).subscribe((therapists) => {
                            const found = therapists[getDayName(getDay(date))].findIndex(
                                (t) => t.id == chosenTherapist.id
                            );
                            weekday.tempTherapists = {
                                therapists,
                                dayName: getDayName(getDay(date)),
                                found,
                                patient: patient,
                            };
                        });
                        // weekday.hasConflict = false;
                        weekday.hadConflict = true;
                        weekday.isLoading = true;
                        weekday.hasUnresolvableConflict = false;
                    } else {
                        weekday.isLoading = true;
                        weekday.hasUnresolvableConflict = true;
                    }
                }
            }
        }
    }

    setSelectedWeekDateLabel(label) {
        this.selectedWeekDateLabel = label;
    }

    getSelectedWeekDateLabel() {
        return this.selectedWeekDateLabel;
    }

    isDroppable(day, ...patients) {
        let droppable = false;
        patients.map((patient) => {
            const wf = this.calculateSelectedPatientFrequency(patient.weekFrequency);
            if (wf.includes(getDayName(new Date(day).getDay()))) droppable = true;
        });
        return droppable;
    }

    hasTherapistAvailableTime(therapist, day, timeToBeAssigned) {
        const totalTime = therapist.availability.days[day].time;
        const scheduledTime = getPatientHoursAssignmentValue(therapist, day).scheduledTime;
        return scheduledTime + timeToBeAssigned <= totalTime;
    }

    calculateSelectedPatientFrequency(weekFrequency) {
        return weekFrequency
            ?.filter(
                (wf) =>
                    wf.planned &&
                    wf.hasConflict &&
                    new Date(wf.day.day).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)
            )
            .map((wf) => getDayName(new Date(wf.day.day).getDay()));
    }

    schedulePatientDays(patients) {
        return new Promise((resolve) => {
            let allConflictsResolved = true;
            const mappedPatients = patients.map((patient) =>
                patient.weekFrequency
                    .map((day) => {
                        if (day.planned && day.hadConflict) {
                            day.day.id = day.day._id;
                            return day.day;
                        }
                        if (day.hasUnresolvableConflict) allConflictsResolved = false;
                    })
                    .filter((day) => day)
            );

            // Uncomment this function to simulate the save functionality
            // this.simulateSave(patients, allConflictsResolved, mappedPatients, resolve)

            // Comment the following if simulate function is turned on
            this.savePatientDays(mappedPatients).subscribe(() => {
                patients.map((p) => {
                    p.weekFrequency?.map((wf) => {
                        if (wf.hadConflict) {
                            wf.isLoading = false;
                            wf.hasConflict = false;
                            if (wf.tempTherapists) {
                                const therapist = wf.tempTherapists;
                                if (therapist.found !== -1) {
                                    therapist.therapists[therapist.dayName][therapist.found].patients.push(
                                        therapist.patient
                                    );
                                }
                                this.therapists = this.customSortTherapists(therapist.therapists);
                                wf.tempTherapists = null;
                            }
                        } else if (wf.hasUnresolvableConflict) {
                            wf.isLoading = false;
                        }
                    });
                });
                if (allConflictsResolved && mappedPatients.flat().length > 0)
                    this.toasterService.show({
                        title: 'Scheduled',
                        type: 'success',
                        body: 'Patients scheduled successfully.',
                    });
                else
                    this.toasterService.show({
                        title: 'Scheduled',
                        type: 'warning',
                        body: 'Some patient days were not scheduled.',
                    });
                resolve(true);
            });
        });
    }

    simulateSave(patients, allConflictsResolved, mappedPatients, resolve) {
        setTimeout(() => {
            patients.map((p) => {
                p.weekFrequency?.map((wf) => {
                    if (wf.hadConflict) {
                        wf.isLoading = false;
                        wf.hasConflict = false;
                        if (wf.tempTherapists) {
                            const therapist = wf.tempTherapists;
                            if (therapist.found !== -1) {
                                therapist.therapists[therapist.dayName][therapist.found].patients.push(
                                    therapist.patient
                                );
                            }
                            // this.therapists=therapist.therapists
                            this.therapists = this.customSortTherapists(therapist.therapists);
                            wf.tempTherapists = null;
                        }
                    } else if (wf.hasUnresolvableConflict) {
                        wf.isLoading = false;
                    }
                });
            });
            if (allConflictsResolved && mappedPatients.flat().length > 0)
                this.toasterService.show({
                    title: 'Scheduled',
                    type: 'success',
                    body: 'Patients scheduled successfully.',
                });
            else
                this.toasterService.show({
                    title: 'Scheduled',
                    type: 'warning',
                    body: 'Some patient days were not scheduled.',
                });
            resolve(true);
        }, 2000);
    }

    openTherapyInfoPane(component, data) {
        const TSN = this.sideNavService.show(component, { data, position: 'right' });
        // TSN.afterClose.subscribe((v) => {});
        TSN.afterAction.subscribe((v) => {
            if (v) {
                // this.patientService.getAllPatients().subscribe((v) => {
                //   this.patients = v.data;
                //   this.segregatePatients(this.patients);
                // });
            }
        });
    }
    savePatientDays(patients) {
        return this.http.patch(`${environment.apiUrl}/plan-day/schedule`, patients);
    }

    removeCardFromList(patient: any[], day, type) {
        if (type === 'dayView') {
            return !patient['weekFrequency'].find(
                (wf) => new Date(wf.day?.day).setHours(0, 0, 0, 0) === new Date(day).setHours(0, 0, 0, 0)
            ).hasConflict;
        } else return patient['weekFrequency'].every((wk) => !wk.hasConflict);
    }

    processTherapistsData(day, therapist: TherapistCard, patients: PatientCard[]) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let scheduledTime = 0;
        patients.forEach((_patient) => {
            _patient.days?.forEach((_day) => {
                if (new Date(_day.day).setHours(0, 0, 0, 0) == new Date(day).setHours(0, 0, 0, 0))
                    if (
                        _day.assignedTherapists[0]?.therapist?._id == therapist.id ||
                        (_day.assignedTherapists[0]?.therapist as any) == therapist.id
                    ) {
                        therapist.patients.push(_patient);
                        scheduledTime += _day.individual.planned + _day.concurrent.planned + _day.group.planned;
                    }
            });
        });
        return therapist;
    }
    loopFilteredTherapists(date, filteredTherapists, patients) {
        filteredTherapists.forEach((therapist) => {
            therapist.patients = [];
            this.processTherapistsData(date, therapist, patients);
        });

        const weeklyTherapists = [];
        filteredTherapists.forEach((ft) => {
            if (ft.availability?.days[getDayName(new Date(date).getDay())].available) weeklyTherapists.push(ft);
        });

        return _.cloneDeep(weeklyTherapists);
    }

    makeData(event: { date: string; view: 'dayView' | 'weekView' }, filteredTherapists, patients) {
        if (event.view == 'dayView') {
            const weekDaysObject = {};
            weekDaysObject[getDayName(new Date(event.date).getDay())] = this.loopFilteredTherapists(
                event.date,
                filteredTherapists,
                patients
            );
            this.therapists = this.customSortTherapists(weekDaysObject as any);
        } else {
            const weekDaysObject = {};
            for (let i = 0; i < 7; i++) {
                const protagonistDate = addDays(startOfWeek(new Date(event.date)), i);
                weekDaysObject[getDayName(new Date(protagonistDate).getDay())] = this.loopFilteredTherapists(
                    protagonistDate,
                    filteredTherapists,
                    patients
                );
            }
            this.therapists = this.customSortTherapists(weekDaysObject as any);
        }
    }

    get therapists(): any {
        return this._therapists.asObservable();
    }
    set therapists(therapists: any) {
        this._therapists.next(therapists);
    }
    get dropTriggered(): any {
        return this._dropTriggered.asObservable();
    }
    set dropTriggered(value: any) {
        this._dropTriggered.next(value);
    }
    customSortTherapists(weekDays: {
        sun: TherapistCard[];
        mon: TherapistCard[];
        tue: TherapistCard[];
        wed: TherapistCard[];
        thu: TherapistCard[];
        fri: TherapistCard[];
        sat: TherapistCard[];
    }) {
        for (const key in weekDays) {
            weekDays[key].sort((a, b) => {
                if (
                    getPatientHoursAssignmentValue(a, key).scheduledTime <
                    getPatientHoursAssignmentValue(b, key).scheduledTime
                ) {
                    return -1;
                } else if (
                    getPatientHoursAssignmentValue(a, key).scheduledTime >
                    getPatientHoursAssignmentValue(b, key).scheduledTime
                ) {
                    return 1;
                } else return 0;
            });
        }
        return weekDays;
    }

    sendTherapistScheduleEmail(data: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}/email/therapist-schedule`, data);
    }
}
