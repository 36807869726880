<div class="cell">
    <ng-container [ngSwitch]="params?.gridName">
        <ng-container *ngSwitchCase="'skilledServicesDN'" [ngTemplateOutlet]="skilledServicesDN"></ng-container>
        <ng-container *ngSwitchCase="'otherModeMinutes'" [ngTemplateOutlet]="otherModeMinutes"></ng-container>
    </ng-container>
</div>

<ng-template #skilledServicesDN>
    <div class="body-medium text-secondary fw-600 font-weight-bold">{{ params.data.code }}</div>
    <ng-container *ngIf="params.data.time === 'Add Time'; else timeExits">
        <div class="body-small text-primary fw-400" (click)="timeClicked()">
            <span class="time">{{ params.data.time }}</span> <span class="text-danger">*</span>
        </div>
    </ng-container>
    <ng-template #timeExits>
        <div class="body-small text-primary fw-400">{{ params.data.time }}</div>
    </ng-template>
</ng-template>

<ng-template #otherModeMinutes>
    <div
        *ngIf="params.data.minutes === 'Add Time'; else timeExits"
        class="text-primary fw-400 time"
        (click)="timeClicked()"
        [ngClass]="!params.data.isAllowed ? 'disable':''"
    >
        {{ params.data.minutes }}
    </div>

    <ng-template #timeExits>
        <div class="text-secondary fw-400">{{ params.data.minutes }}</div>
    </ng-template>
</ng-template>
