<div>
    <div class="d-flex flex-row">
        <div class="align-self-center">
            <app-go-back
                [title]="facilityName + (!editMode ? (importPayor ? ': Import Payors' : ': Add Payors') : ' Contract')"
            ></app-go-back>
        </div>
    </div>
</div>
<app-steps-tab *ngIf="!editMode" [tabs]="tabs" storeTab="selectedPayors"> </app-steps-tab>
<div *ngIf="!isLoading" class="mt-4">
    <!-- <div class="row justify-content-between mb-5" *ngIf="!editMode && importPayor && selectedTab == 'previous'">
        <div class="col-6 divider">
            <div class="custom-card">
                <div class="header">
                    <p>Facilities</p>
                </div>
                <div class="body pt-3">
                    <app-search-bar
                        type="multi"
                        (searchEvent)="facilitySearch($event)"
                        (removeEvent)="redrawRows()"
                    ></app-search-bar>
                    <div class="grid-parent">
                        <app-data-grid
                            class="payors-list"
                            headerHeight="0"
                            [columnDefs]="facilityCols"
                            [rowData]="facilityList"
                            [showCount]="false"
                            rowSelection="single"
                            (rowClicked)="facilitySelectionChanged($event)"
                        >
                        </app-data-grid>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6 divider">
            <div class="custom-card">
                <div class="header">
                    <p>Payors</p>
                </div>
                <div class="body pt-3">
                    <app-search-bar
                        type="multi"
                        (searchEvent)="payorSearch($event)"
                        (removeEvent)="redrawRows()"
                    ></app-search-bar>
                    <div class="grid-parent">
                        <app-data-grid
                            class="payors-list"
                            headerHeight="0"
                            [columnDefs]="payorCols"
                            [rowData]="payorsList"
                            [showCount]="false"
                            rowSelection="single"
                            (gridReady)="onGridReady($event)"
                            (selectedRowsEmitter)="importPayors($event)"
                        >
                        </app-data-grid>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div *ngIf="!editMode && selectedTab == 'previous'" class="d-flex justify-content-between align-self-center mb-4">
        <div class="contract-search-box">
            <app-patients-search
                [searchTerm]="searchTerm"
                (search)="handleSearch($event)"
                marginBottom="mb-0"
                placeHolder="Search payors"
                borderRadius="border-radius-8"
            ></app-patients-search>
        </div>
        <div class="d-flex flex-row justify-content-around align-self-center">
            <div class="d-flex flex-row align-items-center justify-content-center mx-1">
                <label class="mt-2 text-secondary" for="status_id">Payor Type</label>
                <select
                    [(ngModel)]="selectedType"
                    (change)="handleFilterChange()"
                    class="w-10em form-control px-4 border border-1 filter-select mx-2 sm"
                    name="documentType"
                    id="documentType"
                >
                    <option selected value="All">All</option>
                    <option *ngFor="let type of payorTypes" [value]="type">{{ type }}</option>
                </select>
            </div>
            <div *ngIf="importPayor" class="d-flex flex-row align-items-center justify-content-center mx-1">
                <label class="mt-2 text-secondary" for="status_id">Facility</label>
                <select
                    [(ngModel)]="selectedFacility"
                    (change)="handleFilterChange()"
                    class="w-10em form-control px-4 border border-1 filter-select mx-2 sm"
                    name="documentType"
                    id="documentType"
                >
                    <option selected value="All">All</option>
                    <option *ngFor="let facility of facilities" [value]="facility">{{ facility }}</option>
                </select>
            </div>
            <app-button
                *ngIf="filterCheck()"
                tooltipText="Clear filters"
                (click)="cleanFilters()"
                tooltipPlacement="bottom"
                type="secondary"
                [circle]="true"
                class="mr-3 cursor-pointer"
                icon="filter_alt_off"
                size="sm"
            ></app-button>
        </div>
    </div>
    <div class="custom-card mb-5" *ngIf="!editMode && selectedTab == 'previous'">
        <div class="header">
            <p>Payor</p>
        </div>
        <div class="body">
            <div class="body payors-list-2">
                <app-data-grid
                    class="payors-grid"
                    [columnDefs]="groupCols"
                    [rowData]="payorsList"
                    [pagination]="true"
                    [showCount]="false"
                    (gridReady)="onGridReady($event)"
                    (selectedRowsEmitter)="rowSelection($event)"
                    (rowDataChanged)="onRowDataChanged($event)"
                >
                </app-data-grid>
            </div>
            <div class="row payor-search-holder"></div>
        </div>
    </div>
    <ngb-accordion
        *ngIf="selectedPayors.length > 0 && selectedTab == 'previous' && !editMode"
        class="contract-accordion"
        #a="ngbAccordion"
        [closeOthers]="true"
        activeIds=""
    >
        <ngb-panel class="mb-2">
            <ng-template ngbPanelHeader let-opened="opened">
                <div
                    [class.collapsed]="!opened"
                    class="align-items-center collapsed d-flex flex-row justify-content-between main-row p-3 text-center"
                >
                    <strong class="h6-semibold text-secondary m-0">
                        <span *ngIf="isFilterApplied && selectedDataCount > 0"> {{ selectedDataCount }}</span>
                        <span *ngIf="!isFilterApplied && selectedPayors.length > 0">{{ selectedPayors.length }} </span>
                        Payors Selected
                    </strong>
                    <button ngbPanelToggle class="btn-toggle bg-transparent border-0">
                        <span class="material-symbols-outlined text-secondary d-inline-block align-middle">
                            {{ !opened ? 'expand_more' : 'expand_less' }}
                        </span>
                    </button>
                </div>
            </ng-template>
            <ng-template ngbPanelContent class="p-0">
                <div class="selected-payors">
                    <div class="grid-parent">
                        <app-data-grid
                            [columnDefs]="selectedPayorsDefs"
                            [rowData]="selectedPayors"
                            [pagination]="false"
                            [showCount]="false"
                            (gridReady)="initGrid2($event)"
                        >
                        </app-data-grid>
                    </div>
                </div>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
    <div class="">
        <div
            *ngIf="selectedPayors.length > 0 && !editMode && selectedTab == 'next'"
            class="d-flex justify-content-between align-items-center text-secondary my-3 p-3 bg-white add-contract-section"
        >
            <span class="h6-semibold text-secondary m-0">{{ selectedPayors.length }} Payors Selected</span>
            <div class="d-flex align-items-end flex-column">
                <span class="d-inline-block align-middle contract-required">
                    Contract Required
                    <app-switch
                        class="d-inline-block align-middle"
                        formGroupClass="mb-0"
                        controlContainerClass="h-auto"
                        [(ngModel)]="contractRequired"
                        (change)="isContractRequired(contractRequired)"
                    ></app-switch>
                </span>
                <span
                    *ngIf="contractRequired"
                    class="d-inline-block align-middle configure-individually"
                    [ngClass]="importPayor ? 'pointer-events-none opacity-50' : ''"
                >
                    Configure Individually
                    <app-switch
                        class="d-inline-block align-middle"
                        formGroupClass="mb-0"
                        controlContainerClass="h-auto"
                        [(ngModel)]="configureIndividually"
                        (change)="configureContractsIndividually()"
                    ></app-switch>
                </span>
            </div>
        </div>
    </div>
</div>
<ng-container *ngIf="isLoading; else loaded" [ngTemplateOutlet]="skeleton"></ng-container>
<ng-template #loaded>
    <form *ngIf="(contractRequired && selectedTab == 'next') || editMode" [formGroup]="addContractForm">
        <ng-container formArrayName="contracts">
            <ng-container *ngFor="let contractForm of contracts.controls; let i = index">
                <div [formGroup]="contractForm">
                    <div class="custom-card mb-3">
                        <div class="header d-flex justify-content-between align-items-center">
                            <div>
                                <p>
                                    {{ configureIndividually || editMode ? selectedPayors[i]?.value : 'All Payors' }}
                                </p>
                            </div>
                            <div class="zindex-dropdown">
                                <div ngbDropdown class="d-inline-block justify-content-end mr-3">
                                    <span
                                        ngbDropdownToggle
                                        class="cursor-pointer choose-template"
                                        id="templateDropdown"
                                    >
                                        <span
                                            ><span
                                                class="material-symbols-outlined position-relative mr-2 choose-template-description"
                                            >
                                                description </span
                                            ><u>CHOOSE TEMPLATE</u></span
                                        >
                                    </span>
                                    <div
                                        class="template-list-holder"
                                        ngbDropdownMenu
                                        aria-labelledby="templatenDropdown"
                                        *ngIf="contractTemplateList?.length"
                                    >
                                        <div class="template-list-dropdown">
                                            <ul>
                                                <li
                                                    class="bg-body-background d-flex flex-row justify-content-between"
                                                    *ngFor="let contractTemplate of contractTemplateList"
                                                    (click)="contractTemplateChanged(contractTemplate, i)"
                                                    ngbDropdownItem
                                                >
                                                    <span
                                                        class="flex flex-wrap"
                                                        [ngbTooltip]="
                                                            contractTemplate.name?.length > 17
                                                                ? contractTemplate.name
                                                                : ''
                                                        "
                                                        >{{ contractTemplate.name | stringLength: 18:18 }}</span
                                                    >
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="body card-padding">
                            <div class="row">
                                <div class="col-3">
                                    <app-date-picker
                                        label="Contract Effective Date"
                                        backgroundClass=""
                                        inputWrapperClass="bg-body-background"
                                        required
                                        formControlName="dateFrom"
                                        [minDate]="startMinDate[i]"
                                        (ngModelChange)="startDateChanged(i)"
                                    >
                                    </app-date-picker>
                                </div>
                                <div class="col-3">
                                    <app-date-picker
                                        label="Contract Termination Date"
                                        backgroundClass=""
                                        inputWrapperClass="bg-body-background"
                                        required
                                        formControlName="dateThrough"
                                        [minDate]="getEndDate(i)"
                                    >
                                    </app-date-picker>
                                </div>
                                <div class="col-3">
                                    <div class="form-group">
                                        <label class="d-block">&nbsp;</label>
                                        <label class="d-block">&nbsp;</label>
                                        <input
                                            class="align-middle"
                                            type="checkbox"
                                            [id]="'openEnded'+i"
                                            (change)="handleChange($event, i)"
                                            formControlName="openEnded"
                                        />
                                        <label class="mb-0 text-secondary" [for]="'openEnded'+i"> Open ended contract </label>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col-3">
                                    <app-select
                                        label="Select contract type"
                                        backgroundClass="bg-body-background"
                                        (change)="updateContractVariantsList($event, i)"
                                        [options]="contractTypeList"
                                        required
                                        formControlName="contractType"
                                    >
                                    </app-select>
                                </div>
                                <div class="col-3">
                                    <app-select
                                        *ngIf="this.contractVariantList[i]?.length"
                                        label="Select contract variant"
                                        backgroundClass="bg-body-background"
                                        [options]="contractVariantList[i]"
                                        (change)="changeVariants($event, i)"
                                        [disabled]="!this.contractVariantList[i]?.length"
                                        [required]="this.activeContracts[foundIndex]?.openEnded ? false : true"
                                        formControlName="contractVariant"
                                    >
                                    </app-select>
                                </div>
                            </div>

                            <div class="row">
                                <ng-container formArrayName="controlsArray">
                                    <ng-container *ngFor="let control of controlsArray(i)?.controls; let j = index">
                                        <div class="col-3">
                                            <div [formGroupName]="j" class="form-group">
                                                <label
                                                    *ngIf="getControl(control.controls).label === 'label'"
                                                    class="text-secondary"
                                                    >{{ getControl(control.controls).label }}</label
                                                >
                                                <label *ngIf="getControl(control.controls).label === 'label'"
                                                    >Enter Percentage</label
                                                >

                                                <app-input
                                                    *ngIf="getControl(control.controls).type === 'input'"
                                                    placeholder="Type here"
                                                    type="number"
                                                    [cptPercentage]="true"
                                                    [allowPercentage]="
                                                        getControl(control.controls).label.includes('Percentage') ||
                                                        getControl(control.controls).label.includes('%')
                                                            ? true
                                                            : false
                                                    "
                                                    [allowFloatValues]="
                                                        getControl(control.controls).label.includes('Percentage') ||
                                                        getControl(control.controls).label.includes('%')
                                                            ? false
                                                            : true
                                                    "
                                                    backgroundClass="bg-body-background"
                                                    class="mr-3"
                                                    [label]="getControl(control.controls).label"
                                                    [formControlName]="getControl(control.controls).controlName"
                                                >
                                                </app-input>

                                                <div
                                                    *ngIf="getControl(control.controls).type === 'checkbox'"
                                                    class="col-12"
                                                >
                                                    <br />
                                                    <app-checkbox
                                                        [formControlName]="getControl(control.controls).controlName"
                                                        [options]="[
                                                            {
                                                                text: getControl(control.controls).label,
                                                                isChecked: false
                                                            }
                                                        ]"
                                                    ></app-checkbox>
                                                </div>
                                                <br />
                                                <p
                                                    class="labelClass"
                                                    *ngIf="getControl(control.controls).type === 'label'"
                                                >
                                                    {{ getControl(control.controls).label&nbsp;&nbsp; }}
                                                    &nbsp;&nbsp;Enter Percentage
                                                </p>
                                                <div formArrayName="nestedControlsArray">
                                                    <ng-container
                                                        *ngFor="
                                                            let nestedControl of getNestedControlsArray(i, j)?.controls;
                                                            let k = index
                                                        "
                                                    >
                                                        <div [formGroupName]="k">
                                                            <app-combined-input
                                                                placeholder="Type here"
                                                                type="number"
                                                                [allowDecimal]="true"
                                                                backgroundClass="bg-body-background"
                                                                class="mr-3"
                                                                [labelLeft]="true"
                                                                [borderBottom]="
                                                                    k <
                                                                    getNestedControlsArray(i, j)?.controls.length - 1
                                                                "
                                                                [label]="
                                                                    getNestedControls(nestedControl.controls).label
                                                                "
                                                                [formControlName]="
                                                                    getNestedControls(nestedControl.controls)
                                                                        .controlName
                                                                "
                                                            >
                                                            </app-combined-input>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <div
                                class="row mb-3"
                                *ngIf="isLevelContract(i) && getLevelsArray(i)?.controls?.length == 0"
                            >
                                <div class="col-lg-3 col-md-3">
                                    <button
                                        (click)="addLevel(i)"
                                        type="button"
                                        class="btn btn-secondary w-100 add-level-btn"
                                    >
                                        <span class="add-level-link">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="25"
                                                height="25"
                                                viewBox="0 0 25 25"
                                                fill="none"
                                            >
                                                <g clip-path="url(#clip0_3761_18731)">
                                                    <path
                                                        d="M19.5 13.5H13.5V19.5H11.5V13.5H5.5V11.5H11.5V5.5H13.5V11.5H19.5V13.5Z"
                                                        fill="white"
                                                    />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_3761_18731">
                                                        <rect
                                                            width="24"
                                                            height="24"
                                                            fill="white"
                                                            transform="translate(0.5 0.5)"
                                                        />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <span class="add-level-link-innner">Add Level</span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div *ngIf="isLevelContract(i) && getLevelsArray(i)">
                                <ng-container formArrayName="levels">
                                    <div
                                        class="row"
                                        *ngFor="let levelForm of getLevelsArray(i)?.controls; let a = index"
                                    >
                                        <div [formGroupName]="a" class="col-lg-3 col-md-4 d-flex">
                                            <div class="w-100">
                                                <app-input
                                                    placeholder="Type here"
                                                    type="number"
                                                    backgroundClass="bg-body-background"
                                                    class="mr-3"
                                                    [label]="'Level ' + (a + 1)"
                                                    formControlName="level"
                                                >
                                                </app-input>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-4 d-flex remove-level-container">
                                            <span (click)="removeLevel(i, a)" class="remove-level">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M1.71423 6H22.2857"
                                                        stroke="#BC4141"
                                                        stroke-width="1.71429"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                    <path
                                                        d="M4.28564 6H19.7142V21.4286C19.7142 21.8832 19.5336 22.3193 19.2121 22.6408C18.8906 22.9622 18.4546 23.1429 17.9999 23.1429H5.99993C5.54527 23.1429 5.10924 22.9622 4.78775 22.6408C4.46626 22.3193 4.28564 21.8832 4.28564 21.4286V6Z"
                                                        stroke="#BC4141"
                                                        stroke-width="1.71429"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                    <path
                                                        d="M7.71423 5.99833V5.14118C7.71423 4.00454 8.16576 2.91445 8.96949 2.11073C9.77322 1.307 10.8633 0.855469 11.9999 0.855469C13.1366 0.855469 14.2267 1.307 15.0304 2.11073C15.8341 2.91445 16.2857 4.00454 16.2857 5.14118V5.99833"
                                                        stroke="#BC4141"
                                                        stroke-width="1.71429"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                    <path
                                                        d="M9.42847 9.42578V18.8544"
                                                        stroke="#BC4141"
                                                        stroke-width="1.71429"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                    <path
                                                        d="M14.5714 9.42578V18.8544"
                                                        stroke="#BC4141"
                                                        stroke-width="1.71429"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="row mb-4" *ngIf="getLevelsArray(i)?.controls?.length > 0">
                                <div class="col-3">
                                    <span (click)="addLevel(i)" class="add-link w-8em">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="12"
                                            height="12"
                                            viewBox="0 0 12 12"
                                            fill="none"
                                        >
                                            <path
                                                d="M11.1429 6.85714H6.85714V11.1429C6.85714 11.6143 6.47143 12 6 12C5.52857 12 5.14286 11.6143 5.14286 11.1429V6.85714H0.857143C0.385714 6.85714 0 6.47143 0 6C0 5.52857 0.385714 5.14286 0.857143 5.14286H5.14286V0.857143C5.14286 0.385714 5.52857 0 6 0C6.47143 0 6.85714 0.385714 6.85714 0.857143V5.14286H11.1429C11.6143 5.14286 12 5.52857 12 6C12 6.47143 11.6143 6.85714 11.1429 6.85714Z"
                                                fill="#3E568E"
                                            />
                                        </svg>
                                        <span class="add-link-inner">Add Level</span>
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div
                                    *ngIf="
                                        contracts?.controls[i]?.get('contractType')?.value &&
                                        ['PDPM', 'Per Diem Rate'].includes(
                                            contracts.controls[i].get('contractType').value
                                        )
                                    "
                                    class="col-3"
                                >
                                    <div class="form-group">
                                        <input
                                            class="align-middle"
                                            type="checkbox"
                                            id="billableDischarge"
                                            formControlName="billableDischarge"
                                        />
                                        <label class="mb-0 text-secondary" for="billableDischarge">
                                            Billable Discharge
                                        </label>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="form-group">
                                        <input
                                            class="align-middle"
                                            type="checkbox"
                                            [id]="'enableContract'+i"
                                            formControlName="contractEnabled"
                                        />
                                        <label class="mb-0 text-secondary" [for]="'enableContract'+i">
                                            Enable Contract
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </form>
</ng-template>

<ng-template #skeleton>
    <br />
    <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
    <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
    <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
    <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
    <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
    <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
</ng-template>
<div class="d-flex justify-content-end my-3">
    <button
        *ngIf="!editMode"
        type="button"
        class="innova-btn mr-2"
        [ngClass]="selectedTab == 'previous' ? 'btn-outline-danger' : 'btn-outline-secondary'"
        (click)="changeTab(selectedTab == 'previous' ? 'previous-page' : 'previous')"
    >
        {{ selectedTab == 'previous' ? 'Cancel' : 'Go Back' }}
    </button>
    <button
        *ngIf="selectedTab == 'previous' && !editMode"
        type="button"
        [disabled]="selectedPayors.length <= 0"
        class="innova-btn btn-secondary"
        (click)="changeTab('next')"
    >
        Continue

        <span *ngIf="isLoadingReport" class="spinner-border spinner-border-sm mr-1"></span>
    </button>
    <button
        *ngIf="(selectedTab == 'next' || editMode) && !isLoading"
        type="button"
        [disabled]="submitContract || isLoading"
        class="innova-btn btn-secondary"
        (click)="createContract(addContractForm.value)"
    >
        Save

        <span *ngIf="isLoadingReport" class="spinner-border spinner-border-sm mr-1"></span>
    </button>
</div>