import { Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { DocumentationReportService, PrintService } from '@app/services';
import { DailyNotesService } from '@app/services';

@Component({
    selector: 'app-daily-notes-report',
    templateUrl: './daily-notes-report.component.html',
    styleUrls: ['./daily-notes-report.component.scss'],
})
export class DailyNotesReportComponent implements OnInit {
    @ViewChild('header') reportHeader: ElementRef;
    @Input() reportId: string;
    @Input() report;
    isDataLoaded: boolean;
    toExport: boolean;
    withEdits = false;

    constructor(
        private docReportService: DocumentationReportService,
        private dailyNoteService: DailyNotesService,
        private ngZone: NgZone,
        private printService: PrintService
    ) { }

    async ngOnInit(): Promise<void> {
        this.withEdits = this.printService.getWithEdits();
        if (this.reportId && !this.report) {
            this.toExport = false;
            this.isDataLoaded = await this.docReportService.prepareDailyNoteData(this.reportId);
            if (this.isDataLoaded) {
                setTimeout(() => {
                    this.docReportService.countLoadedNotes();
                }, 0);
            }
        } else {
            this.isDataLoaded = await this.docReportService.prepareDailyNoteDataForIntegration(this.report);
            this.toExport = true;
            setTimeout(async () => {
                this.ngZone.runOutsideAngular(() => {
                    window.history.back();
                    this.dailyNoteService.loadedDailyNote.next(1);
                });
            }, 10);
        }
    }
}
