//colworx-ak: added POC_DOC_STATUS
export enum POC_DOC_STATUS {
    READY = 'Ready',
    PENDING = 'Pending',
    SIGNED = 'Signed',
    REJECTED = 'Rejected',
    SENT = 'Sent',
    COMPLETED = 'Completed'

}

