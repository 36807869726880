<div
    *ngIf="params.showNAChip"
    class="chip chip-warning"
    style="justify-content: center; height: 30px; width: 0px; margin-top: 6px"
    ngbTooltip="{{ params.chipTooltip }}"
    placement="left"
    container="body"
>
    <div style="margin-top: -5px">N/A</div>
</div>
