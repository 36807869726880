<div id="rounded-modal" class="card text-secondary body-medium p-4">
    <div class="d-flex mt-2">
        <button
            style="position: absolute; right: 0; top: 0"
            class="btn btn-primary d-flex flex-row align-items-center justify-content-center modal-close-button"
            (click)="onClose(false)"
        >
            <span class="material-icons-outlined text-white-100" style="vertical-align: middle"> close </span>
        </button>
    </div>
    <div class="mt-4 text-center">
        <img src="assets/icons/goals-delete.svg" />
        <h5 class="h5-bold">{{ data.heading }}</h5>
        <p class="body-medium-semibold text-left mb-0" *ngIf="data.discontinueMessage">
            {{ data.discontinueMessage }}
        </p>
        <p class="body-medium-semibold text-left mb-0">
            {{ data.message }}
        </p>
    </div>
    <app-text-box
        *ngIf="data.isStg"
        class="mt-2"
        label="Reason for discontinuing*"
        backgroundClass="bg-body-background"
        [(ngModel)]="reasonForDiscontinuing"
    ></app-text-box>
    <div class="d-flex justify-content-end mt-4 mb-1" *ngIf="data.isStg">
        <button [disabled]="!reasonForDiscontinuing" class="innova-btn innova-btn-sm btn-secondary" (click)="onDone()">
            Yes, Sure
        </button>
        <button class="ml-1 innova-btn innova-btn-sm btn-secondary" (click)="onClose(false)">No</button>
    </div>
    <div class="d-flex justify-content-end mt-4 mb-1" *ngIf="!data.isStg">
        <button class="innova-btn innova-btn-sm btn-secondary" (click)="onDone()">
            OK
        </button>
    </div>

</div>
