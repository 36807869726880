<div class="d-flex flex-row back-btn-title back-btn-arrow align-items-center {{ mainClass }}">
    <div class="material-icons-outlined cursor-pointer back-btn-arrow" *ngIf="goBackAction" (click)="backButtonAction()">
        arrow_back
    </div>
    <h6 *ngIf="titleCaseOff; else withoutTitleCase" class="back-btn-title mb-0 text-secondary {{ responsiveFont }}">
        {{ title | translate }}{{ acronym }}
    </h6>
    <ng-template #withoutTitleCase>
        <h6 [ngClass]="{ 'ml-2': goBackAction }" class="back-btn-title mb-0 text-secondary {{ responsiveFont }}">
            {{ title | translate | titlecase }}{{ acronym }}
        </h6>
    </ng-template>
</div>
