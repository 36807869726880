import { Component, EventEmitter, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { ToasterService } from '@app/services';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

// backspace starts the editor on Windows
const KEY_BACKSPACE = 'Backspace';

@Component({
    selector: 'editor-cell',
    templateUrl: 'input-cell-renderer.component.html',
    styleUrls: ['./input-cell-renderer.component.scss'],
})
export class InputCellRenderer implements ICellEditorAngularComp {
    @Output() inputChange = new EventEmitter<string>();
    params!: any;
    public value: any;

    @ViewChild('input', { read: ViewContainerRef })
    public input!: ViewContainerRef;

    constructor(private toasterService: ToasterService) {}
    agInit(params: ICellEditorParams): void {
        this.params = params;
        this.value = this.getInitialValue(params);
    }

    getInputClasses() {
        return {
          'disabled': !this.params.editMode,
          'not-enabled': !this.params.data.enable,
          'non-billable': !this.params.data.billable || this.params.disableRate,


        };
      }

    getValue() {
        return this.value;
    }

    isPopup(): boolean {
        return false;
    }

    getInitialValue(params: ICellEditorParams) {
        let startValue = params.value;

        const eventKey = params.eventKey;
        const isBackspace = eventKey === KEY_BACKSPACE;

        if (isBackspace) {
            startValue = '';
        } else if (eventKey && eventKey.length === 1) {
            startValue = eventKey;
        }

        if (startValue !== null && startValue !== undefined) {
            ``;
            return startValue;
        }

        return '';
    }

    myCustomFunction() {
        return {
            rowIndex: this.params.rowIndex,
            colId: this.params.column.getId(),
        };
    }

    inputChanged(event) {
        // const inputElement = event.target as HTMLInputElement;
        // const maxLength = this.params.maxLength;
        // if (inputElement.value.length > maxLength) {
        //     this.toasterService.show({ title: 'Failure', body: 'Max Length Exceeded', type: 'error' });
        //     inputElement.value = inputElement.value.slice(0, maxLength);
        // }

        this.params.inputChange(event.target.value);
    }
}