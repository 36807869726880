import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { FUNCTIONAL_DEFICITS } from '@app/interfaces/documentation';

const baseUrl = `${environment.apiUrl}`;

@Injectable({
    providedIn: 'root',
})
export class MdsService {
    fsItems = [
        FUNCTIONAL_DEFICITS.EATING,
        FUNCTIONAL_DEFICITS.ORAL_HYGIENE,
        FUNCTIONAL_DEFICITS.TOILETING_HYGIENE,
        FUNCTIONAL_DEFICITS.SIT_TO_LYING,
        FUNCTIONAL_DEFICITS.LYING_TO_SITTING_ON_SIDE_OF_BED,
        FUNCTIONAL_DEFICITS.SIT_TO_STAND,
        FUNCTIONAL_DEFICITS.CHAIR_BED_TO_CHAIR_TRANSFER,
        FUNCTIONAL_DEFICITS.TOILET_TRANSFER,
        FUNCTIONAL_DEFICITS.WALK_50_FEET_WITH_2_TURNS,
        FUNCTIONAL_DEFICITS.WALK_150_FEET,
    ];

    constructor(private http: HttpClient) {}

    updateMds(mds): Observable<any> {
        return this.http.put(`${baseUrl}/mds/${mds._id}`, mds, {
            withCredentials: true,
        });
    }
    getMds(id): Observable<any> {
        return this.http.get<any>(`${baseUrl}/mds/${id}`).pipe(map((mds) => mds.data));
    }
}
