import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as jDiff from 'jsondiffpatch';
import * as changeSet from 'diff-json';
import { convertSecsToMins, placeZerosOnLeft } from '@app/helpers/utils/data.utils';
@Component({
    selector: 'app-view-mode-goals',
    templateUrl: './view-mode-goals.component.html',
    styleUrls: ['./view-mode-goals.component.scss'],
})
export class ViewModeGoalsComponent implements OnChanges {
    @Input() tableTitle: string;
    @Input() goalData: Array<any> = [];
    @Input() isHistoryMode: string;

    ngOnChanges(change: SimpleChanges) {
        if (change.goalData?.currentValue[0]?.title?.currentValue) {
            // converted to 'mm:ss' format before adding innerHTML
            change.goalData.currentValue = change.goalData.currentValue.map((goal) => {
                if (goal.title.currentValue?.toLowerCase().includes('activity tolerance')) {
                    goal.target.currentValue = this.formatSecMinutes(goal.target.currentValue);
                    goal.target.previousValue = this.formatSecMinutes(goal.target.previousValue);
                    goal.clof.currentValue = this.formatSecMinutes(goal.clof.currentValue);
                    goal.clof.previousValue = this.formatSecMinutes(goal.clof.previousValue);

                    goal.childGoal.forEach((CG) => {
                        if (CG.title.currentValue.toLowerCase().includes('activity tolerance')) {
                            CG.target.currentValue = this.formatSecMinutes(CG.target.currentValue);
                            CG.target.previousValue = this.formatSecMinutes(CG.target.previousValue);
                            CG.clof.currentValue = this.formatSecMinutes(CG.clof.currentValue);
                            CG.clof.previousValue = this.formatSecMinutes(CG.clof.previousValue);
                        }
                    });
                }
                return goal;
            });
            this.goalData = this.processGoalsData(change.goalData.currentValue);
        } else {
            this.goalData = change?.goalData?.currentValue;
            this.goalData = this.goalData?.map((goal) => {
                if (goal.title?.toLowerCase()?.includes('activity tolerance')) {
                    goal.scaleType = 'n-inputs';
                    goal.target = this.formatSecMinutes(goal.target);
                    goal.clof = this.formatSecMinutes(goal.clof);
                }
                goal.childGoal.forEach((CG) => {
                    if (CG.title.toLowerCase().includes('activity tolerance')) {
                        CG.target = this.formatSecMinutes(CG.target);
                        CG.clof = this.formatSecMinutes(CG.clof);
                    }
                });
                return goal;
            });
        }
    }

    formatSecMinutes(value) {
        if (value === '' || value == null) {
            return 'N/A';
        }
        if (!(parseInt(value) && isNaN(parseInt(value)))) {
            return value;
        }
        const { min, sec } = convertSecsToMins(parseInt(value, 10));
        return placeZerosOnLeft(min, 2) + ':' + placeZerosOnLeft(sec, 2);
    }

    processGoalsData(goals: any[]) {
        return goals.map((x) => {
            Object.keys(x).forEach((y) => {
                if (Array.isArray(x[y]) && x[y].length) this.processGoalsData(x[y]);
                else if (!x[y]) {
                    return;
                } else if (
                    x[y].previousValue ||
                    x[y].currentValue ||
                    x[y].previousValue == '' ||
                    x[y].currentValue == ''
                ) {
                    const obj1 = x[y].previousValue;
                    const obj2 = x[y].currentValue;
                    let delta = jDiff.create({ textDiff: { minLength: 10000 } }).diff(obj1, obj2);
                    const innerHtml = jDiff.formatters.html.format(delta, obj1);
                    jDiff.formatters.html.hideUnchanged();
                    delta = changeSet.diff(obj1, obj2);
                    if (innerHtml) x[y] = innerHtml;
                    else {
                        x[y] = x[y].currentValue;
                    }
                } else if (
                    x[y] == null ||
                    x[y] == undefined ||
                    x[y].previousValue == null ||
                    x[y].currentValue == null
                ) {
                    x[y] = '';
                }
            });
            return x;
        });
    }
}
