<app-base-modal-card [heading]="heading" (close)="onClose()">
    <div body class="body">
        <label class="body-medium-semibold cursor-pointer" (click)="toggleAllCheckbox()">Select All/UnSelect All</label>
        <!-- Goals -->
        <div class="mb-2" *ngFor="let goal of goals">
            <div class="d-flex flex-row w-100 p-2 mb-2 align-item-center">
                <span class="align-items-center d-flex">
                    <input
                        type="checkbox"
                        [name]="goal.title"
                        class="checkbox cursor-pointer"
                        [id]="goal.title"
                        [ngModel]="goal.isSelected"
                        (ngModelChange)="goalsChanged(goal, $event)"
                    />
                    <span class="ml-3 text-secondary">{{ goal.title }}</span>
                </span>

                <div class="align-items-center d-flex flex-row mt-1">
                    <span class="text-secondary ltg-pill ml-2"> LTG </span>
                </div>
            </div>
            <div class="pl-4 ml-2 mt-n2">
                <div *ngIf="goal.stg.length !== 0">
                    <ng-container *ngFor="let item of goal.stg">
                        <div class="m-1 d-flex flex-row w-100 align-items-center">
                            <div class="m-1 pl-2 d-flex flex-row align-items-center">
                                <input
                                    type="checkbox"
                                    [name]="item.title"
                                    class="checkbox cursor-pointer"
                                    [id]="item.title"
                                    [ngModel]="item.isSelected"
                                    (ngModelChange)="goalsChanged(item, $event)"
                                />
                                <div class="ml-3">{{ item.title }}</div>
                            </div>

                            <span class="text-primary stg-pill ml-2 mt-1"> STG </span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <div footer class="w-100 d-flex flex-row align-items-center justify-content-end">
        <button
            [ngClass]="{ disabled: !saveBtnStatus }"
            (click)="saveClicked()"
            class="btn btn-secondary rounded-pill d-flex flex-row align-items-enter"
        >
            Save
        </button>
    </div>
</app-base-modal-card>
