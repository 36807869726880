<div (insideClickEvent)="setShowResults($event)" (outsideClickEvent)="setShowResults($event)">
    <div class="form-group d-flex justify-content-between mb-0" [class.input-error]="hasError">
        <label>{{ label | translate }} {{ isRequired ? '*' : '' }}</label>
        <div
            *ngIf="hasError"
            ngbTooltip="{{ errorMsg }}"
            placement="left"
            class="material-icons-outlined d-flex flex-column align-self-center ml-1 text-danger mb-1"
        >
            info
        </div>
    </div>
    <div
        class="form-group"
        [class.input-error]="hasError"
        [class.ssn-check]="tooltip || (patientSearchResults?.length > 0 && showResults)"
    >
        <input
            class="form-control"
            [type]="type"
            [placeholder]="placeholder"
            [value]="value"
            [disabled]="disabled"
            (keyup)="onChange($event)"
            (blur)="onBlur()"
            #search
            [imask]="{ mask: mask }"
            (focus)="onFocus()"
        />
        <div
            *ngIf="tooltip && mask.toString() == '000-00-0000'"
            ngbTooltip="Patient already exists"
            placement="left"
            class="material-icons-outlined d-flex flex-column align-self-center ml-1"
        >
            info
        </div>
    </div>
    <div
        class="rounded patient-dd d-flex flex-column bg-white pl-3 pr-3 mt-n2 position-absolute absolute-right-0 absolute-left-0 zindex-dropdown"
        *ngIf="patientSearchResults?.length > 0 && showResults"
        style="width: 130% !important"
    >
        <!-- Single search result -->
        <div
            class="d-flex flex-row justify-content-between body-medium-semibold text-secondary pt-3 pb-3 mb-n1 pl-2 sticky-top bg-white"
        >
            <span>Suggestions</span>
            <span>{{ patientSearchResults.length }} {{ patientSearchResults.length > 1 ? 'records' : 'record' }} </span>
        </div>
        <div [class.expanded-results]="expandedResults" [class.collapsed-results]="!expandedResults">
            <div
                class="d-flex flex-row hover border-search-results cursor-pointer pl-2"
                *ngFor="let result of patientSearchResults; let i = index"
                (click)="getPatient(result._id)"
            >
                <div class="d-flex flex-column justify-content-center pt-2 pb-2 w-100">
                    <p class="body-small-semibold">
                        {{ result.firstName | titlecase }} {{ result.lastName | titlecase }}
                    </p>
                    <div class="d-flex align-items-center flex-row justify-content-between w-100">
                        <div>
                            <span class="body-small mr-3"><b>SSN:</b> {{ result.SSN }}</span>
                            <span class="body-small mr-3"><b>DOB:</b> {{ result.DOB | dateFormatter }}</span>
                            <br />
                            <span
                                *ngIf="patientHaveSameFacility(result) && !patientHaveActiveFacilityAdmissions(result)"
                                class="body-small"
                                ><b>Discharged:</b> {{ patientLastDischargeDate(result) | date }}</span
                            >
                            <span
                                *ngIf="patientHaveSameFacility(result) && patientHaveActiveFacilityAdmissions(result)"
                                class="body-small"
                                ><b>Admit Date:</b> {{ activeFacilityAdmissionsAdmitDate(result) | date }}</span
                            >
                        </div>

                        <button
                            *ngIf="patientHaveSameFacility(result) && patientHaveActiveFacilityAdmissions(result)"
                            class="btn btn-sm body-small rounded-pill px-3 btn-disabled cursor-pointer mr-3"
                            disabled
                            style="color: #4d8b63; background-color: #edf8f1 !important; border: none !important"
                        >
                            Active
                        </button>
                        <button
                            *ngIf="patientHaveSameFacility(result) && !patientHaveActiveFacilityAdmissions(result)"
                            class="btn btn-sm body-small rounded-pill px-3 btn-disabled cursor-pointer mr-3"
                            disabled
                            style="color: #6076a7; background-color: #e8edf9 !important; border: none !important"
                        >
                            Discharged
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="d-flex flex-row justify-content-center body-small-semibold text-primary p-2 cursor-pointer"
            (click)="expandResults()"
        >
            <div *ngIf="patientSearchResults.length > 2">
                <span [hidden]="!expandedResults">See Less</span>
                <span [hidden]="expandedResults">See More</span>
            </div>
        </div>
    </div>
</div>
