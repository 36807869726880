import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
    FacilityManagementService,
    OrganizationManagementService,
    ToasterService,
    AuthService,
    UserService,
} from '@app/services/index';
import { first, Subject, takeUntil, filter, debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { scrollToElementWithOffset } from '@app/helpers/utils/scroll.helper';
import { ColDef, GridApi } from 'ag-grid-community';
import { Router } from '@angular/router';
import { ROUTER_UTILS } from '@app/helpers';
import { cloneDeep } from 'lodash';
import { AVAILABILITY_OBJ } from '../../user-management/constants';
import { TherapistService } from '@app/services/therapist.service';

@Component({
    selector: 'app-add-facility-layout',
    templateUrl: './add-facility.component.html',
    styleUrls: ['./add-facility.component.scss'],
})
export class AddFacilityComponent implements OnInit {
    currentFacility: string;
    facilityOIDLoading = true;
    public selectedLink: any;
    lcdColDefs: ColDef[] = [];
    gridApi: GridApi;
    lcdRows = [
        {
            name: 'M42.3',
            address: 'Bariatric Surgery Status',
            city: 'Other Orthopedic/ Non-Neurologic',
            state: 'Oral Cancers',
            zipCode: '36501',
        },
        {
            name: 'M42.3',
            address: 'Bariatric Surgery Status',
            city: 'Other Orthopedic/ Non-Neurologic',
            state: 'Oral Cancers',
            zipCode: '36502',
        },
        {
            name: 'M42.3',
            address: 'Bariatric Surgery Status',
            city: 'Other Orthopedic/ Non-Neurologic',
            state: 'Oral Cancers',
            zipCode: '36503',
        },
    ];
    facilityQuickLinks: any;

    facilityForm: FormGroup;
    facilityTypeOptions = [
        'Skilled nursing',
        'Skilled Home Health Care',
        'Hospice Care',
        'Continuum Care Retirement Center (CCRC)',
        'Hospital',
        'Outpatient - Independent Living (IL)',
        'Outpatient - Assisted Living (AL)',
        'Outpatient - Clinic',
        'Outpatient - Home Therapy',
        'Other',
    ];
    admissionTypeOptions = [
        { text: 'Inpatient - Skilled Nursing (SNF)', isChecked: false },
        { text: 'Skilled Home Health Care', isChecked: false },
        { text: 'Hospice Care', isChecked: false },
        { text: 'Outpatient - Independent Living (IL)', isChecked: false },
        { text: 'Outpatient - Assisted Living (AL)', isChecked: false },
        { text: 'Outpatient - Clinic', isChecked: false },
        { text: 'Outpatient - Home Therapy', isChecked: false },
        { text: 'Outpatient - True Patient', isChecked: false },
        { text: 'Other', isChecked: false },
    ];
    coSignOptions = [
        { text: 'Co-Signature for PTA (Daily Note only)', isChecked: false },
        { text: 'Co-Signature for OTA (Daily Note only)', isChecked: false },
        { text: 'Co-Signature for Student (All notes)', isChecked: false },
    ];
    statusOptions = ['active', 'inactive'];
    PBJOptions = ['option1', 'option2'];
    organizations = [];
    counties = [];
    mailingCounties: any = [];
    billingCounties: any = [];
    states = [];
    filteredStates: any = [];
    macList: any = [];
    macInfo: any = {};
    timezone = [
        { name: 'Eastern Standard Time (EST)', id: 'America/New_York' },
        { name: 'Central Standard Time (CST)', id: 'America/Chicago' },
        { name: 'Mountain Standard Time (MST)', id: 'America/Denver' },
        { name: 'Pacific Standard Time (PST)', id: 'America/Los_Angeles' },
        { name: 'Alaska Standard Time (AKST)', id: 'America/Anchorage' },
        { name: 'Hawaii-Aleutian Standard Time (HST)', id: 'Pacific/Honolulu' },
    ];
    isEditing: boolean;
    EditingData: any;

    public scrollAutoPointer = true;
    private readonly onDestroy = new Subject<void>();
    SSNTooltip = false;
    currentDate: any;
    organizationsData = [];
    showOrganizations: boolean;
    //addFacility = ROUTER_UTILS.config.facility.root + '/' + ROUTER_UTILS.config.facility.addFacility;
    @ViewChild('OnBoarding') facilityOnBoarding: ElementRef;
    @ViewChild('mailAddress') mailAddress: ElementRef;
    @ViewChild('billingAddress') billingAddress: ElementRef;
    @ViewChild('admission') admission: ElementRef;
    @ViewChild('customerInfo') customerInfo: ElementRef;
    @ViewChild('lcd') lcd: ElementRef;
    @ViewChild('npi') npi: ElementRef;
    @ViewChild('medCare') medCare: ElementRef;

    loading: boolean;

    public SKELETON_CSS = {
        CARD: {
            width: '21%',
            height: '5em',
            'border-radius': '1em',
            'margin-left': '2em',
        },
        DETAIL: {
            width: '21%',
            height: '32em',
            'border-radius': '1em',
            'margin-left': '2em',
            position: 'absolute',
        },
        RIGHTDETAIL: {
            width: '15%',
            height: '25em',
            'border-radius': '3em',
            'margin-left': '2em',
            float: 'right',
            'margin-right': '2em',
        },
    };
    isAddressSame = false;

    pageTitle = 'Add New Facility';
    constructor(
        private facilityManagementService: FacilityManagementService,
        private router: Router,
        private toasterService: ToasterService,
        private _orgManagementService: OrganizationManagementService,
        public AuthService: AuthService,
        private userService: UserService,
        private therapistService: TherapistService
    ) {
        this.currentFacility = router.getCurrentNavigation().extras?.state?.data?.id;
        if (router.routerState.snapshot.url === '/admin/facility/edit') {
            if (router.getCurrentNavigation().extras.state) {
                this.pageTitle = router.getCurrentNavigation().extras.state.data.facilityName;
                this.isEditing = true;
                this.loading = true;
                this.EditingData = router.getCurrentNavigation().extras.state.data;
                this.macInfo.name = this.EditingData?.mailingAddress?.macId?.name;
                this.macInfo.id = this.EditingData?.mailingAddress?.macId?._id;
            } else
                this.router.navigateByUrl(
                    ROUTER_UTILS.config.admin.root + '/' + ROUTER_UTILS.config.admin.facility.root
                );
        }
        this.showOrganizations = this.AuthService.isSuperAdmin;
        this._orgManagementService.__Organizations.subscribe((res) => {
            if (res.length > 0) {
                this.organizationsData = res;
                this.organizations = this._orgManagementService.mapOrganizationsForDropDown();
            }
        });
        this.facilityOIDLoading = false;
    }

    ngOnInit(): void {
        // * id is not using any where, linting raise warnings
        // const navigation = window.history.state;
        // if (navigation.organizationId) {
        //     const id = navigation.organizationId;
        // }
        this.facilityForm = this.facilityManagementService.facilityOnBoardingForm();
        this.states = this.facilityManagementService.getUSAStates();
        this.filteredStates = this.states.map((item: any) => item.stateName);
        this.counties = this.facilityManagementService.getUSACounties();
        // this.facilityManagement
        //     .getOrganizationsList()
        //     .pipe(take(1))
        //     .subscribe((result) => {
        //         this.organizations = result.data.map((x) => {
        //             return { id: x.id, name: x.name };
        //         });
        if (this.isEditing) {
            this.loading = false;
            const selectedOrganization = this.organizations.filter(
                (item) => item.name == this.EditingData.organization.name
            )[0];
            this.updateCounties(this.EditingData.mailingAddress.state, 'mailing');
            this.updateCounties(this.EditingData.billingAddress.state, 'billing');
            this.facilityForm.patchValue(this.EditingData);
            this.facilityForm.controls.organization.setValue(selectedOrganization.id);
            this.isAddressSame = this.compareAddresses();
            this.facilityForm.get('isBothAddressSame').patchValue(this.isAddressSame);
        } else {
            this.facilityForm.controls.organization.enable();
        }
        if (!this.showOrganizations) {
            this.facilityForm.controls.organization.setValue(this._orgManagementService._currentOrganization.id);
            this.facilityForm.controls.organization.disable();
        }
        //  });
        this.currentDate = new Date();
        this.facilityQuickLinks = this.facilityManagementService.getQuickLinks();
        this.getQuickLinkClickSubscription();
        this.lcdColDefs = this.facilityManagementService.getLcdCols();
        this.valueChangeSubscriptions();
        // if (this.isEditing) {
        //     this.facilityForm.patchValue(this.EditingData);
        // }
    }

    /**
     * This function will check weather the billing and mailing address are same
     * @returns True or False
     */
    compareAddresses() {
        const { mailingAddress, billingAddress } = this.EditingData as any;
        const fieldsToCompare = ['address', 'county', 'fax', 'locality', 'phoneNo', 'state', 'zipCode'];
        for (const field of fieldsToCompare) {
            if (mailingAddress[field]?.toLowerCase() !== billingAddress[field]?.toLowerCase()) {
                return false;
            }
        }
        return true;
    }

    public getQuickLinkClickSubscription() {
        this.facilityManagementService.QuickLinkClickedSubject.pipe(takeUntil(this.onDestroy)).subscribe(
            (link: string) => {
                if (link) {
                    this.scrollAutoPointer = false;
                    this.linkChanged(link);
                }
            }
        );
    }

    public inViewPort = (event, section) => {
        // console.log(event, section);
        if (event.visible) {
            this.selectedLink = this.facilityQuickLinks.find((el) => el.label == section);
            // this.facilityManagementService.QuickLinkNotifySubject.next(section);
        }
    };

    async linkChanged(e) {
        switch (e.label) {
            // eslint-disable-next-line spellcheck/spell-checker
            case 'Facility Onboarding':
                await scrollToElementWithOffset(this.facilityOnBoarding).then(() => {
                    this.scrollAutoPointer = true;
                });
                break;
            case 'Mailing Address & Contact':
                scrollToElementWithOffset(this.mailAddress).then(() => {
                    this.scrollAutoPointer = true;
                });
                break;
            case 'Billing Address & Contact':
                scrollToElementWithOffset(this.billingAddress).then(() => {
                    this.scrollAutoPointer = true;
                });
                break;
            case 'Admission':
                scrollToElementWithOffset(this.admission).then(() => {
                    this.scrollAutoPointer = true;
                });
                break;
            case 'Customer information':
                scrollToElementWithOffset(this.admission).then(() => {
                    this.scrollAutoPointer = true;
                });
                break;
            case 'Facility NPI':
                scrollToElementWithOffset(this.npi).then(() => {
                    this.scrollAutoPointer = true;
                });
                break;
            case 'Medicare and Medicaid Information':
                scrollToElementWithOffset(this.medCare).then(() => {
                    this.scrollAutoPointer = true;
                });
                break;
        }
    }
    async facilityOIDExists(currentFacility?: string) {
        try {
            // Check if form is filled
            if (this.facilityForm.invalid) {
                this.facilityForm.markAllAsTouched();
                return;
            }
            if (this.facilityForm.invalid) {
                this.facilityForm.markAllAsTouched();
                return;
            }
            if (this.facilityForm.get('facilityOID').value.length > 0) {
                // Then check if the facilityOID already exists
                this.facilityManagementService
                    .OIDExists(this.facilityForm.get('facilityOID').value, [currentFacility])
                    .pipe(first())
                    .subscribe((data: any) => {
                        if (data.data.isDuplicateFacilityOID) {
                            this.toasterService.show({
                                title: 'Failure',
                                body: 'Duplicate facility OID',
                                type: 'error',
                            });
                            this.facilityOIDLoading = false;
                        } else {
                            // If facilityOID is not duplicate, then call validOID() method to check if the facilityOID is valid for external cms
                            this.validOID();
                        }
                    });
            } else {
                this.saveUpdateFacility();
            }
        } catch (err) {
            this.facilityOIDLoading = false;
            console.error(err);
        }
    }
    async validOID() {
        // If facilityOID is not duplicate, then this method is called and we validate the oid with external cms
        try {
            // Check if form is filled
            if (this.facilityForm.invalid) {
                this.facilityForm.markAllAsTouched();
                return;
            }
            if (this.facilityForm.invalid) {
                this.facilityForm.markAllAsTouched();
                return;
            }
            // Validate facilityOID from external cms
            if (this.facilityForm.get('facilityOID').value.length > 0) {
                this.facilityOIDLoading = true;
                this.facilityManagementService
                    .isValidOID(this.facilityForm.get('facilityOID').value)
                    .pipe(first())
                    .subscribe((data: any) => {
                        if (!data.isValid) {
                            this.toasterService.show({ title: 'Failure', body: 'Invalid facility OID', type: 'error' });
                            this.facilityOIDLoading = false;
                        } else {
                            // If facilityOID is valid for external cms, then call the saveUpdateFacility() method
                            this.saveUpdateFacility();
                        }
                    });
            } else {
                this.saveUpdateFacility();
            }
        } catch (err) {
            console.error(err);
        }
    }
    async saveUpdateFacility() {
        if (this.facilityForm.invalid) {
            this.facilityForm.markAllAsTouched();
            return;
        }
        if (this.facilityForm.invalid) {
            this.facilityForm.markAllAsTouched();
            return;
        }
        if (!this.isEditing) {
            this.facilityOIDLoading = true;
            //for save
            const data = { ...this.facilityForm.getRawValue() };
            if (!this.isEditing) {
                data.goLiveEnabled = false;
            }
            data.mailingAddress.mac = this.macInfo.name;
            data.mailingAddress.macId = this.macInfo.id;
            data.coSignSettings == '' ? (data.coSignSettings = []) : data.coSignSettings;
            const superAdmin = this.AuthService.isSuperAdmin;
            if (superAdmin) {
                data.user = 'Super Admin';
            } else {
                data.user = await this.getFacilityCreator();
            }
            this.facilityManagementService
                .saveFacilityOnBoarding(data)
                .pipe(first())
                .subscribe(
                    (result: any) => {
                        if (result.success) {
                            this.updateFacilityInOrganization(result.data, result.message);
                        } else {
                            this.toasterService.show({ title: 'Failure', body: result.message, type: 'error' });
                            this.facilityOIDLoading = false;
                        }
                    },
                    (err) => {
                        this.toasterService.show({ title: 'Failure', body: err, type: 'error' });
                        this.facilityOIDLoading = false;
                    }
                );
        } else {
            // for update
            this.facilityOIDLoading = true;
            const data = { ...this.facilityForm.getRawValue() };
            data.mailingAddress.mac = this.macInfo.name;
            data.mailingAddress.macId = this.macInfo.id;
            this.facilityManagementService
                .updateFacilityOnBoarding(this.EditingData.id, data)
                .pipe(first())
                .subscribe(
                    (result) => {
                        if (result.success) {
                            this.toasterService.show({ title: 'Success', body: result.message, type: 'success' });
                            this.goBack();
                            // this.router.navigateByUrl(
                            //     //ROUTER_UTILS.config.admin.root + '/' + ROUTER_UTILS.config.admin.facility.root
                            // );
                        } else {
                            this.toasterService.show({ title: 'Failure', body: result.message, type: 'error' });
                        }
                        this.facilityOIDLoading = false;
                    },
                    (err) => {
                        this.toasterService.show({ title: 'Failure', body: err, type: 'error' });
                        this.facilityOIDLoading = false;
                    }
                );
        }
    }

    initGrid(event: any) {
        this.gridApi = event.api;
    }

    // onRowClicked(event: any): void {
    //   debugger;
    //   console.log(event);
    // }

    public rowSelection(event) {
        if (event) {
            this.facilityForm.controls.MACProvider.patchValue(event[0]);
        }
    }
    valueChangeSubscriptions() {
        this.facilityForm
            .get('isBothAddressSame')
            .valueChanges.pipe(takeUntil(this.onDestroy))
            .subscribe((val) => {
                if (val) {
                    this.facilityForm.controls.billingAddress.patchValue(
                        this.facilityForm.controls.mailingAddress.value
                    );
                } else {
                    this.facilityForm.controls.billingAddress.reset();
                }
                this.facilityForm.controls['billingAddress'].updateValueAndValidity();
            });

        this.facilityForm
            .get('mailingAddress')
            .get('state')
            .valueChanges.pipe(takeUntil(this.onDestroy))
            .subscribe((val) => {
                this.updateCounties(val, 'mailing');
            });

        this.facilityForm
            .get('mailingAddress')
            .get('zipCode')
            .valueChanges.pipe(
                filter(Boolean),
                debounceTime(500),
                distinctUntilChanged(),
                tap((text) => {
                    if (text.length === 5) {
                        this.facilityManagementService.getLocalityByZipCode(text).subscribe((response: any) => {
                            if (!response.data) {
                                this.facilityForm.get('mailingAddress').get('zipCode').setErrors({ invalid: true });
                                this.facilityForm.get('mailingAddress').patchValue({
                                    locality: '',
                                    carrier: '',
                                    urbanOrRural: '',
                                });
                            } else {
                                this.facilityForm.get('mailingAddress').patchValue({
                                    carrier: response.data.carrier,
                                    locality: response.data.locality,
                                    urbanOrRural: this.mapRuralUrbanStatus(response.data.ruralInd),
                                });
                            }
                        });
                    }
                })
            )
            .subscribe();
        this.facilityForm
            .get('billingAddress')
            .get('zipCode')
            .valueChanges.pipe(
                filter(Boolean),
                debounceTime(500),
                tap((text) => {
                    if (text.length === 5) {
                        this.facilityManagementService.getLocalityByZipCode(text).subscribe((response: any) => {
                            if (!response.data) {
                                this.facilityForm.get('billingAddress').get('zipCode').setErrors({ invalid: true });
                                this.facilityForm.get('billingAddress').patchValue({
                                    carrier: '',
                                    locality: '',
                                    urbanOrRural: '',
                                });
                            } else {
                                this.facilityForm.get('billingAddress').patchValue({
                                    carrier: response.data.carrier,
                                    locality: response.data.locality,
                                    urbanOrRural: this.mapRuralUrbanStatus(response.data.ruralInd),
                                });
                            }
                        });
                    }
                })
            )
            .subscribe();
        this.facilityForm
            .get('billingAddress')
            .get('state')
            .valueChanges.pipe(takeUntil(this.onDestroy))
            .subscribe((val) => {
                this.updateCounties(val, 'billing');
            });

        this.facilityForm
            .get('mailingAddress')
            .valueChanges.pipe(takeUntil(this.onDestroy))
            .subscribe(() => {
                if (this.facilityForm.controls.isBothAddressSame.value) {
                    this.facilityForm.controls.billingAddress.patchValue(
                        this.facilityForm.controls.mailingAddress.value
                    );
                    this.facilityForm.controls['billingAddress'].updateValueAndValidity();
                }
            });
    }

    /**
     * This function will return true if all mandatory fields of
     * mailing address are filled
     * @returns true or false
     */
    isMailingAddressFilled() {
        const mailingAddress = this.facilityForm?.controls?.mailingAddress.value;
        return !!(
            mailingAddress?.address?.length &&
            mailingAddress?.phoneNo?.length &&
            mailingAddress?.zipCode?.length &&
            mailingAddress?.state?.length
        );
    }
    mapRuralUrbanStatus(status: any) {
        if (!status) return 'Urban';
        else if (status.toLowerCase() == 'r') return 'Rural';
        else if (status.toLowerCase() == 'b') return 'Super Rural';
    }
    updateCounties(val: string, address: 'mailing' | 'billing') {
        const stateData = this.states.filter(
            (x) => x.stateName === val || x.stateCode.toLowerCase() === val?.toLowerCase()
        );
        if (address === 'mailing') {
            this.mailingCounties = this.counties
                ?.filter((x) => x?.stateCode === stateData[0]?.stateCode)
                ?.map((item) => item.countyName);
        } else if (address === 'billing') {
            this.billingCounties = this.counties
                ?.filter((x) => x?.stateCode === stateData[0]?.stateCode)
                ?.map((item) => item?.countyName);
        }
    }

    async updateFacilityInOrganization(data: any, message: string) {
        const organization = this.organizationsData.find(
            (org) => org.id == this.facilityForm.get('organization').value
        );
        const tempF = {
            facilityName: data.facilityName,
            facilityNumber: data.facilityNumber,
            facilityOID: data.facilityOID,
            facilityType: data.facilityType,
            id: data.id,
            mailingAddress: data.mailingAddress,
            organization: data.organization,
            status: data.status,
            goLiveEnabled: data.goLiveEnabled,
            facilityNPI: data.facilityNPI,
        };
        organization.facilities.push(tempF);
        organization.facilityCount = organization.facilities.length;
        if (this._orgManagementService._currentOrganization.id == organization.id) {
            const facilities = this.facilityManagementService._Facilities.value
            const exists = facilities.some(facility => facility?._id == tempF.id || facility?.id == tempF.id)
            if (!exists) {
                this.facilityManagementService._Facilities = [...facilities, tempF];
            }
        }
        this._orgManagementService.__Organizations = this.organizationsData;
        this.organizations = this._orgManagementService.mapOrganizationsForDropDown();
        this.toasterService.show({ title: 'Success', body: message, type: 'success' });
        this.router.navigateByUrl(ROUTER_UTILS.config.admin.root + '/' + ROUTER_UTILS.config.admin.facility.root);
    }

    /**
     * This Function will prevent user from clicking same as mailing
     * address checkbox if he has'nt added valid mailing address
     * @param event
     */
    preventClick(event: Event): void {
        if (!this.isMailingAddressFilled()) {
            event.preventDefault();
        }
    }
    ngOnDestroy(): void {
        // eslint-disable-next-line spellcheck/spell-checker
        this.facilityManagementService.QuickLinkNotifySubject.next('');
        this.onDestroy.next();
    }

    goBack() {
        history.go(-1);
    }
    getStateMac(state: string) {
        const obj = { 'stateJurisdiction.state': state };
        this.facilityManagementService.getMac(obj).subscribe((result: any) => {
            this.macInfo = result.data[0];
        });
    }
    getFacilityCreator(): Promise<any> {
        return new Promise((resolve, reject) => {
            const user = JSON.parse(localStorage.getItem('innova-frontend'))?.user;
            const currentFacility = JSON.parse(localStorage.getItem('current-facility'))?._id;
            const newFacility = {
                facilityId: null,
                roles: [],
            };
            let thisUser;
            let hasTherapistRole = false;
            this.userService.getById(user.id).subscribe(
                (result) => {
                    thisUser = cloneDeep(result);
                    thisUser?.userRoles.forEach((userRole) => {
                        userRole.facilities.forEach((facility) => {
                            facility.facilityId = facility.facilityId._id;
                            if (facility.facilityId === currentFacility) {
                                hasTherapistRole = facility.roles.some((role) => role.baseRole === 'Therapist');
                                newFacility.roles = cloneDeep(facility.roles);
                                newFacility.roles = newFacility.roles.map((role) => role._id);
                            }
                            facility.roles = facility.roles.map((role) => role._id);
                        });
                        userRole.facilities.push(newFacility);
                    });

                    if (hasTherapistRole) {
                        this.therapistService.getTherapist(result._id).subscribe(
                            (therapistData) => {
                                const therapist = therapistData;
                                const availability: any = AVAILABILITY_OBJ;
                                availability.facility = newFacility.facilityId;
                                availability.isChanged = true;
                                thisUser.therapist = {};
                                thisUser.therapist = cloneDeep(therapist);
                                thisUser.therapist.availability.push(availability);
                                resolve(thisUser);
                            },
                            (error) => {
                                reject(error);
                            }
                        );
                    } else {
                        resolve(thisUser);
                    }
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }
}
