
<div class="modal-content modal-dimensions custom-card text-secondary">
    <div  class="head border-rounded d-flex justify-content-end align-items-center">
        <p *ngIf="showHeading" >
            {{ heading }} <span class="fw-400">{{subHeading}}</span>
        </p>
       
        <button
            *ngIf="showCross"
            class="btn btn-close d-flex flex-row align-items-center justify-content-center close-icon"
            [ngClass]="crossIconClasses"
            (click)="onClose()"
        >
            <span class="material-icons-outlined cross-color text-white-100" style="vertical-align: middle ;color:black"> close </span>
        </button>
    </div>
    <div class="modal-body {{ paddingZero }}" [style]="inputStyle">
        <div class="container text-center">
            <div class="billing-heading">Billing Export Type</div>
            <div class="row justify-content-center my-4">
                <div class="col-auto options" *ngFor="let option of options; let i = index">
                    <div 
                        class="option-tile" 
                        [ngClass]="{'selected': selectedOption === option}"
                        (click)="selectOption(option)">
                        {{ option }}
                    </div>
                </div>
            </div>
        </div>
        
    </div>

    <div footer class="d-flex border-rounded flex-row col-12 justify-content-end pl-0 pr-0 modal-footer">
        <app-button
        [disabled]="!selectedOption"
        class="mr-2"
        [text]="button1Text"
        tooltipPlacement="bottom-right"
        size="lg"
        type="secondary"
        (click)="onSave()"
    >
    </app-button>
    </div>
  
</div>
