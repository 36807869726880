<div class="d-flex flex-row align-items-center justify-content-between mb-4">
    <div class="align-self-center">
        <app-go-back title="Facility Management" [goBackAction]="true"></app-go-back>
    </div>
    <div class="dd-small dropdown facility-dropdown dropdown-menu-end">
        <div ngbDropdown class="d-inline-block">
            <button
                class="innova-btn btn-secondary btn-sm d-flex flex-row justify-content-center align-items-center p-2"
                id="dd-toggle"
                placement="left"
                ngbDropdownToggle
            >
                <span class="material-symbols-outlined"> menu </span>
                <!-- <span class="mr-2">Options</span> -->
            </button>
            <!-- *ngIf="!disableCheck()" -->
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dd-small facility-options">
                <button
                    ngbDropdownItem
                    [routerLink]="newFacilityURL"
                    [disabled]="isFacilitySelected || !permissions.Add"
                    class="nav-item nav-link"
                >
                    Add new facility
                </button>
                <button
                    ngbDropdownItem
                    (click)="printList()"
                    [disabled]="!isFacilitySelected"
                    class="nav-item nav-link"
                >
                    Print List
                </button>
                <button
                    ngbDropdownItem
                    (click)="exportList()"
                    [disabled]="!isFacilitySelected"
                    class="nav-item nav-link"
                >
                    Export List
                </button>

                <button
                ngbDropdownItem
                (click)="editTimecards()"
                [disabled]="!isFacilitySelected"
                class="nav-item nav-link"
            >
                Edit Timecards
            </button>
            </div>
        </div>
    </div>
</div>
<div class="d-flex align-items-center justify-content-between mb-3">
    <div style="width: 500px">
        <app-patients-search
            [searchTerm]="facilityFilter.facilityName"
            placeHolder="Search Facilities"
            marginBottom="mb-0"
            (search)="changeFilters($event, 'facilityName')"
        ></app-patients-search>
    </div>
    <div class="d-flex pl-0 pr-0 justify-content-between align-items-center">
        <div
            class="body-large flex-shrink-0 text-primary cursor-pointer text-underline"
            *ngIf="toFilter"
            (click)="resetFilters()"
        >
            Reset Filters
        </div>
        <!-- This filter is under discussion. Please don't delete the code. -->
        <!-- <div class="body-medium-semibold text-secondary-100 ml-2 align-self-center">Integration</div>
        <select
            class="ml-2 cursor-pointer border border-1 filter-select form-control sm"
            style="max-width: max-content; width: 100%"
            (change)="changeFilters($event, 'integrated')"
        >
            <option selected value="All">All</option>
            <option value="MatrixCare">MatrixCare</option>
            <option value="Point Click care">Point Click care</option>
        </select> -->
        <div class="body-medium-semibold text-secondary-100 ml-4 align-self-center">Type</div>

        <select
            [(ngModel)]="facilityFilter.type"
            class="ml-2 cursor-pointer border border-1 filter-select form-control sm"
            (change)="changeFilters($event, 'type')"
        >
            <option value="All">All</option>
            <option *ngFor="let type of facilityTypeOptions" [value]="type">{{ type }}</option>
        </select>

        <div class="body-medium-semibold text-secondary-100 ml-4 align-self-center">State</div>
        <select
            [(ngModel)]="facilityFilter.state"
            class="ml-2 cursor-pointer border border-1 filter-select form-control sm"
            style="max-width: 253px; width: 100%"
            (change)="changeFilters($event, 'state')"
        >
            <option selected value="All">All</option>
            <option *ngFor="let state of states" [value]="state.stateCode">{{ state.stateCode }}</option>
        </select>
    </div>
</div>
