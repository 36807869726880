import { THERAPIST_TYPE } from "../constants";

export const roles = {
    Evaluating: 'Evaluating Therapist',
    Treating: 'Treating Therapist',
    Supervising: 'Supervising Therapist',
};

export const isUserTherapistAssistant = (user) => {
    return (user?.therapist?.therapistType === 'PTA' || user?.therapist?.therapistType === 'OTA')
}

export const getTherapistByRoleFromTherapyAdmission = (therapyAdmission, role) => {
    return therapyAdmission?.assignedTherapists?.find(
        (therapist) => therapist.role == role
    )?.therapist?._id ?? null;
}

export const isAllowedToCoSign = (therapyAdmission, user, coSigned) => {
    return user.user.therapist && coSigned && !coSigned.signedDate &&
        user.user.therapist.id === getTherapistByRoleFromTherapyAdmission(therapyAdmission, roles.Supervising)
}

export const getTherapistTypes = (user) => {
    if (user?.therapist?.discipline === 'PT') {
        return ['PT', 'PTA', 'Student PT', 'Student PTA'];
    } else if (user?.therapist?.discipline === 'OT') {
        return ['OT', 'OTA', 'Student OT', 'Student OTA'];
    } else if (user?.therapist?.discipline === 'ST') {
        return ['ST', 'Student ST'];
    }
}

export function checkCoSignEligibility(user: any, coSignSettings: Array<any>): boolean {

    if (!coSignSettings) return false;
    // this commented code is on purpose, will remove it after testing new implementation of it

    // return (
    //     (user?.therapist?.therapistType === 'PTA' && coSignSettings.some(c => c.text.includes('PTA'))) ||
    //     (user?.therapist?.therapistType === 'OTA' && coSignSettings.some(c => c.text.includes('OTA'))) ||
    //     (user?.therapist?.isStudent && coSignSettings.some(c => c.text.includes('Student')))
    // );
    return (
        (user?.therapist?.therapistType === THERAPIST_TYPE.PTA && coSignSettings.some(c => c.text.includes(THERAPIST_TYPE.PTA))) ||
        (user?.therapist?.therapistType === THERAPIST_TYPE.OTA && coSignSettings.some(c => c.text.includes(THERAPIST_TYPE.OTA))) ||
        (checkIfStudent(user?.therapist?.therapistType) && coSignSettings.some(c => c.text.includes('Student')))
    );

}

export function checkIfStudent(therapistType: THERAPIST_TYPE): boolean {
    const studentTypes = [
        THERAPIST_TYPE.PT_STUDENT,
        THERAPIST_TYPE.PTA_STUDENT,
        THERAPIST_TYPE.OT_STUDENT,
        THERAPIST_TYPE.OTA_STUDENT,
        THERAPIST_TYPE.ST_STUDENT
    ];
    return studentTypes.includes(therapistType);
}

export function shortTherapistType(therapistType) {
    if (therapistType && typeof therapistType === 'string') {
        return therapistType.includes('Student') ? therapistType.replace('Student ', 'S') : therapistType;
    }
    return therapistType;
}

export const THERAPIST_TYPES_BY_DISCIPLINE = {
    PT: [THERAPIST_TYPE.PT, THERAPIST_TYPE.PTA, THERAPIST_TYPE.PT_STUDENT, THERAPIST_TYPE.PTA_STUDENT],
    OT: [THERAPIST_TYPE.OT, THERAPIST_TYPE.OTA, THERAPIST_TYPE.OT_STUDENT, THERAPIST_TYPE.OTA_STUDENT],
    ST: [THERAPIST_TYPE.ST, THERAPIST_TYPE.ST_STUDENT]
}

