import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-notes-goal-container',
    templateUrl: './notes-goal-container.component.html',
    styleUrls: ['./notes-goal-container.component.scss'],
})
export class NotesGoalContainerComponent {
    @Input() heading: string;
    @Input() collapsed = false;
    @Input() showActionItems = true;
    @Input() hasAddButton = true;
    @Output() add: EventEmitter<void> = new EventEmitter();
}
