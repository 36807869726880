export const InvoicingProfileRoutes = {
    root: '',
    invoice: 'invoice',
    baseURL: 'billing/invoice',
    createInvoice: 'create-invoice',
    createInvoiceProfile: 'create-invoice-profile',
    manageRecipient: 'manage-recipient',
    addPreferences: 'add-preferences',
    invoiceProfileOverview: 'invoice-profile-overview',
};
