import { Injectable } from '@angular/core';
import { CanDeactivate, UrlTree } from '@angular/router';
import { DocumentationService } from '@app/services';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NavigationBlockerGuard implements CanDeactivate<unknown> {
    isAllowed = false;
    constructor(private documentationService: DocumentationService) {}
    canDeactivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        this.isAllowed = this.documentationService.signatureProcessStatus;
        return !this.isAllowed;
    }
}
