import { OverlayRef } from '@angular/cdk/overlay';

export class ToastRef {
    constructor(readonly overlay: OverlayRef) {
        this.overlay.outsidePointerEvents().subscribe(() => {
            this.close();
        });
    }

    close() {
        this.overlay.dispose();
    }
}
