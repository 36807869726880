import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-scheduler-right-bar-card',
    templateUrl: './scheduler-right-bar-card.component.html',
    styleUrls: ['./scheduler-right-bar-card.component.scss'],
})
export class SchedulerRightBarCardComponent {
    @Input() patient;
    @Input() date;

    get plannedMinutes(): number {
        const found = this.patient.weekFrequency.find(
            (wf) => new Date(wf.day?.day).setHours(0, 0, 0, 0) === new Date(this.date).setHours(0, 0, 0, 0)
        );
        if (found) return found.day.individual.planned + found.day.concurrent.planned + found.day.group.planned;
        else return 0;
    }
}
