import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTER_UTILS } from '@app/helpers';
import { TherapyAdmission, TherapyDiscipline } from '@app/interfaces';

import { CONSTANTS, STANDARDIZED_TESTS } from '@app/interfaces/documentation';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import { DocumentationService } from './documentation.service';
import { ModalService } from './modal.service';
import { StandardTestFormComponent } from '@app/modules/documentation/patient-assessment/modals/standard-test-form/standard-test-form.component';
import { PatientAssessmentService } from './patient-assessment.service';
import { CustomTestModalComponent } from '@app/modules/patients/PCC/pcc-tabs/pcc-documentation/modals/custom-test-modal/custom-test-modal.component';
import { findTherapyCaseByParamId } from '@app/helpers/utils/therapy-case.utils';
import { EvaluationService } from './evaluation.service';
import { uuidv4 } from '@app/helpers/utils/charts/goals.utils';
@Injectable({
    providedIn: 'root',
})
export class StandardizedTestsService {
    $refreshTestsList = new Subject();
    standardizedTests = new Subject();
    currentAdmission: TherapyAdmission;
    documentationUrl = ROUTER_UTILS.config.patients.pcc.tabs.documentation.root;
    evalUrl = '/' + this.documentationUrl + '/' + ROUTER_UTILS.config.patients.pcc.tabs.documentation.evalDoc.root;
    therapyDiscipline: TherapyDiscipline;
    allAdmissions: TherapyAdmission[];
    note: string;
    constructor(
        private store: Store<{
            therapyAdmission: TherapyAdmission[];
        }>,
        private documentationService: DocumentationService,
        private router: Router,
        private modalService: ModalService,
        private patientAssessmentService: PatientAssessmentService,
        private evaluationService: EvaluationService,
        private route: ActivatedRoute
    ) {
        this.route.queryParamMap.subscribe((params) => {
            this.therapyDiscipline = params.get('discipline') as TherapyDiscipline;
            this.note = params.get('note') as string;
            if (this.allAdmissions) this.selectCurrentAdmission(this.allAdmissions);
        });
        store.select('therapyAdmission').subscribe((admission) => {
            this.allAdmissions = admission;
            this.selectCurrentAdmission(this.allAdmissions);
        });
    }

    selectCurrentAdmission(admissions) {
        const queryString = this.route.snapshot.queryParams;
        this.currentAdmission = findTherapyCaseByParamId(admissions, queryString['therapy-case']);
        this.therapyDiscipline ||= this.currentAdmission?.therapyDiscipline;
        this.therapyDiscipline ||= 'PT';
        this.currentAdmission = cloneDeep(this.currentAdmission);
    }
    getStandardizedTests() {
        return [
            {
                text: STANDARDIZED_TESTS.WALK_TEST_10_METER,
                existsIn: ['PT'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.WALK_TEST_2_MINUTE,
                existsIn: ['PT'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.STEP_TEST_2_MINUTE,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.SIT_TO_STAND_30_SECOND,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.WALK_TEST_6_MINUTE,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.HOLE_PEG_TEST,
                existsIn: ['OT'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.ACTIVITY_SPECIFIC_BALANCE_CONFIDENCE_SCALE,
                existsIn: ['PT'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.ALLEN_COGNITIVE_LEVELS,
                existsIn: ['PT', 'OT', 'ST'],
                isCompleted: false,
                systemSupported: false,
            },
            {
                text: STANDARDIZED_TESTS.AMPAC,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
                systemSupported: false,
            },
            {
                text: STANDARDIZED_TESTS.ARIZONA_BATTERY_FOR_COMMUNICATION_DISORDERS_OF_DEMENTIA,
                existsIn: ['OT', 'ST'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.ASSESSMENT_OF_LANGUAGE_RELATED_FUNCTIONAL_ACTIVITIES,
                existsIn: ['ST'],
                isCompleted: false,
                systemSupported: false,
            },
            {
                text: STANDARDIZED_TESTS.BARTHEL_INDEX,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
                systemSupported: true,
                maxValue: 100,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.BRIEF_INTERVIEW_FOR_MENTAL_STATUS,
                existsIn: ['PT', 'OT', 'ST'],
                isCompleted: false,
                systemSupported: true,
                maxValue: 15,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.BERG_BALANCE,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
                systemSupported: true,
                maxValue: 56,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.BLADDER_ASSESSMENT_SCORE,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
                systemSupported: false,
            },
            {
                text: STANDARDIZED_TESTS.BORG_CR_10,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.BORG_SCALE_OF_PERCEIVED_EXERTION,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.BOSTON_DIAGNOSTIC_APHASIA_EXAMINATION,
                existsIn: ['ST'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.BOSTON_NAMING_TEST,
                existsIn: ['ST'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.BRADEN_SCALE,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.BCAT,
                existsIn: ['ST'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.BCRS,
                existsIn: ['ST'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.CLQT,
                existsIn: ['ST'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.DASH,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.DGI,
                existsIn: ['PT'],
                isCompleted: false,
                systemSupported: true,
                maxValue: 24,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.DYNAMOMETER,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.FALLS_EFFICACY_SCALE,
                existsIn: ['PT'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.FEES,
                existsIn: ['ST'],
                isCompleted: false,
                systemSupported: false,
            },
            {
                text: STANDARDIZED_TESTS.FRENCHAY_DYSARTHRIA_ASSESSMENT,
                existsIn: ['ST'],
                isCompleted: false,
                systemSupported: false,
            },
            {
                text: STANDARDIZED_TESTS.FROMAJE,
                existsIn: ['ST'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.FUGL_MEYER_ASSESSMENT_OF_MOTOR_PERFORMANCE,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.FUNCTIONAL_KNEE_EVALUATION,
                existsIn: ['PT'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.FLCI,
                existsIn: ['ST'],
                isCompleted: false,
                systemSupported: false,
            },
            {
                text: STANDARDIZED_TESTS.FUNCTIONAL_REACH_TEST,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.FUNCTIONAL_STAGING_ASSESSMENT,
                existsIn: ['OT', 'ST'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.GARS_M,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.GAIT_SPEED,
                existsIn: ['PT'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.GLOBAL_DETERIORATION_SCALE,
                existsIn: ['OT', 'ST'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.HARRIS_HIP_SCORE,
                existsIn: ['PT'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.HOOS,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.KNEE_RATING_SCALE,
                existsIn: ['PT'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.KELS,
                existsIn: ['OT'],
                isCompleted: false,
                systemSupported: false,
            },
            {
                text: STANDARDIZED_TESTS.LOWER_EXTREMITY_FUNCTIONAL_SCALE,
                existsIn: ['PT'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.LYSHOLM_KNEE_RATING_SCALE,
                existsIn: ['PT'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.MASA,
                existsIn: ['ST'],
                isCompleted: false,
                systemSupported: true,
                maxValue: 200,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.MACTAR,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.MINI_MENTAL_STATE_EXAM,
                existsIn: ['PT', 'OT', 'ST'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.MONTREAL_COGNITIVE_ASSESSMENT,
                existsIn: ['PT', 'OT', 'ST'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.MVPT,
                existsIn: ['OT'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.NIH_STROKE_SCALE,
                existsIn: ['PT', 'OT', 'ST'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            // {
            //   text: STANDARDIZED_TESTS.NOMS_Attention,
            //   existsIn: ['ST'],
            //   isCompleted: false,
            //   systemSupported: true,
            // },
            {
                text: STANDARDIZED_TESTS.NOMS_MEMORY,
                existsIn: ['ST'],
                isCompleted: false,
                systemSupported: true,
            },
            {
                text: STANDARDIZED_TESTS.NOMS_MOTOR_SPEECH,
                existsIn: ['ST'],
                isCompleted: false,
                systemSupported: true,
            },
            // {
            //   text: STANDARDIZED_TESTS.NOMS_Reading,
            //   existsIn: ['ST'],
            //   isCompleted: false,
            //   systemSupported: true,
            // },
            {
                text: STANDARDIZED_TESTS.NOMS_SPOKEN_LANGUAGE,
                existsIn: ['ST'],
                isCompleted: false,
                systemSupported: true,
            },
            // {
            //   text: STANDARDIZED_TESTS.NOMS_SPOKEN_LANGUAGE_Expression,
            //   existsIn: ['ST'],
            //   isCompleted: false,
            //   systemSupported: true,
            // },
            {
                text: STANDARDIZED_TESTS.NOMS_SWALLOWING,
                existsIn: ['ST'],
                isCompleted: false,
                systemSupported: true,
            },
            // {
            //   text: STANDARDIZED_TESTS.NOMS_Writing,
            //   existsIn: ['ST'],
            //   isCompleted: false,
            //   systemSupported: true,
            // },
            {
                text: STANDARDIZED_TESTS.ORPINGTON_PROGNOSTIC_SCALE,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.OSWESTRY_LOW_BACK_PAIN_DISABILITY_QUESTIONNAIRE,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.PAIN_ASSESSMENT_IN_ADVANCED_DEMENTIA,
                existsIn: ['PT', 'OT', 'ST'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.PAIN_DISABILITY_INDEX,
                existsIn: ['PT', 'OT', 'ST'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.PHYSICAL_PERFORMANCE_TEST,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.PHQ9,
                existsIn: ['PT', 'OT', 'ST'],
                isCompleted: false,
                systemSupported: true,
                maxValue: 27,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.ROMBERG_SHARPENED_ROMBERG,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.RIPA_G,
                existsIn: ['ST'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.SHORT_FORM_MCGILL_PAIN_QUESTIONNAIRE,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.SIMPLE_SHOULDER_TEST,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.SLUMS,
                existsIn: ['ST'],
                isCompleted: false,
                systemSupported: true,
                maxValue: 30,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.SNELLEN_EYE_CHART,
                existsIn: ['OT', 'ST'],
                isCompleted: false,
                systemSupported: false,
            },
            {
                text: STANDARDIZED_TESTS.STROKE_IMPACT_SCALE,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.STROKE_REHABILITATION_ASSESSMENT_OF_MOVEMENT,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
                systemSupported: false,
            },
            {
                text: STANDARDIZED_TESTS.TEST_OF_VISUAL_PERCEPTUAL_SKILLS_REVISED,
                existsIn: ['OT', 'ST'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.SPPB,
                existsIn: ['PT'],
                isCompleted: false,
                systemSupported: true,
                maxValue: 12,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.CPS,
                existsIn: ['PT', 'OT', 'ST'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.ROLAND_QUESTIONNAIRE,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.TIME_UP_AND_GO_TUG,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
                systemSupported: true,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.WOMAC_OSTEOARTHRITIS_INDEX,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
                systemSupported: false,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.TINETTI_ASSESSMENT,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
                systemSupported: true,
                maxValue: 28,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.Functional_Oral_Intake,
                existsIn: ['ST'],
                isCompleted: false,
                systemSupported: true,
                maxValue: 7,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.Dysphagia_Outcome_and_Severity_Scale,
                existsIn: ['ST'],
                isCompleted: false,
                systemSupported: true,
                maxValue: 7,
                scaleInterpretation: CONSTANTS.LESS_IS_BEST,
            },
            {
                text: STANDARDIZED_TESTS.PASS,
                existsIn: ['PT', 'OT'],
                isCompleted: false,
                systemSupported: true,
                maxValue: 36,
                scaleInterpretation: CONSTANTS.MORE_IS_BEST,
            },
        ];
    }
    get_SLUMS_Test() {
        return [
            {
                label: 'Level of education',
                instruction: '',
                controlType: 'radio',
                hideScore: true,
                isExcludeFromTotal: true,
                options: [
                    {
                        score: 1,
                        description: 'HIGH SCHOOL EDUCATION',
                    },
                    {
                        score: 0,
                        description: 'LESS THAN HIGH SCHOOL EDUCATION',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'What day of the week is it?',
                instruction: '1 point for correct answer',
                controlType: 'radio',
                hideScore: true,
                options: [
                    {
                        score: 1,
                        description: 'Answered correctly',
                    },
                    {
                        score: 0,
                        description: 'Answered Incorrectly',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'What is the year?',
                instruction: '1 point for correct answer',
                controlType: 'radio',
                hideScore: true,
                options: [
                    {
                        score: 1,
                        description: 'Answered correctly',
                    },
                    {
                        score: 0,
                        description: 'Answered Incorrectly',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'What state are we in?',
                instruction: '1 point for correct answer',
                controlType: 'radio',
                hideScore: true,
                options: [
                    {
                        score: 1,
                        description: 'Answered correctly',
                    },
                    {
                        score: 0,
                        description: 'Answered Incorrectly',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Please remember these five objects. I will ask you what they are later.',
                instruction: '',
                controlType: 'readOnly',
                whiteSpace: 'pre',
                value: `  Apple        Pen         Tie       House          Car`,
                options: [],
                subOptions: [],
            },
            {
                label: 'You have $100 and you go to the store and buy a dozen apples for $3 and a tricycle for $20.',
                instruction: '',
                controlType: '',
                options: [],
                subOptions: [
                    {
                        label: 'How much did you spend? ',
                        instruction: '1 point for correct answer',
                        controlType: 'radio',
                        alphabet: 'A',
                        hideScore: true,
                        options: [
                            {
                                score: 1,
                                description: 'Answered correctly',
                            },
                            {
                                score: 0,
                                description: 'Answered Incorrectly',
                            },
                        ],
                    },
                    {
                        label: 'How much do you have left?',
                        instruction: '2 points for correct answer',
                        controlType: 'radio',
                        alphabet: 'B',
                        hideScore: true,
                        options: [
                            {
                                score: 2,
                                description: 'Answered correctly',
                            },
                            {
                                score: 0,
                                description: 'Answered Incorrectly',
                            },
                        ],
                    },
                ],
            },
            {
                label: 'Please name as many animals as you can in one minute.',
                instruction: '',
                controlType: 'radio',
                hideScore: true,
                options: [
                    {
                        score: 0,
                        description: '0-4 animals',
                    },
                    {
                        score: 1,
                        description: '5-9 animals',
                    },
                    {
                        score: 2,
                        description: '10-14 animals',
                    },
                    {
                        score: 3,
                        description: '15+ animals',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'What were the five objects I asked you to remember?',
                instruction: '1 point for each one correct.',
                controlType: 'checkbox',
                hideScore: true,
                options: [
                    { text: 'Apple', score: 1 },
                    { text: 'Pen', score: 1 },
                    { text: 'Tie', score: 1 },
                    { text: 'House', score: 1 },
                    { text: 'Car', score: 1 },
                    { text: 'None', score: 0 },
                ],
                subOptions: [],
            },
            {
                label: 'I am going to give you a series of numbers and I would like you to give them to me backwards. For example, if I say 42, you would say 24.',
                instruction: '',
                controlType: 'checkbox',
                hideScore: true,
                options: [
                    { text: '87', score: 0 },
                    { text: '649', score: 1 },
                    { text: '8537', score: 1 },
                    { text: 'None', score: 0 },
                ],
                subOptions: [],
            },
            {
                label: "This is a clock face. Please put in the hour markers and the time at ten minutes to eleven o'clock.",
                instruction: '',
                controlType: 'checkbox',
                hideScore: true,
                options: [
                    { text: 'Hour markers okay', score: 2 },
                    { text: 'Time correct', score: 2 },
                    { text: 'Neither', score: 0 },
                ],
                subOptions: [],
            },

            {
                label: ' ',
                instruction: '',
                controlType: '',
                options: [],
                subOptions: [
                    {
                        label: 'Please place an X in the triangle.',
                        instruction: '1 point for correct answer',
                        controlType: 'radio',
                        alphabet: 'A',
                        hideScore: true,
                        options: [
                            {
                                score: 1,
                                description: 'Done correctly',
                            },
                            {
                                score: 0,
                                description: 'Done Incorrectly',
                            },
                        ],
                    },
                    {
                        label: 'Which of the above figures is largest? ',
                        instruction: '1 point for correct answer',
                        controlType: 'radio',
                        alphabet: 'B',
                        hideScore: true,
                        options: [
                            {
                                score: 1,
                                description: 'Answered correctly',
                            },
                            {
                                score: 0,
                                description: 'Answered Incorrectly',
                            },
                        ],
                    },
                ],
            },
            {
                label: "I am going to tell you a story. Please listen carefully because afterwards, I'm going to ask you some questions about it.",
                instruction: '',
                controlType: 'readOnly',
                value: `  Jill was a very successful stockbroker. She made a lot of money on the stock market. She then met Jack, a devastatingly handsome man.
                She married him and had three children.They lived in Chicago. She then stopped work and stayed at home to bring up her children. When they were teenagers, she
                went back to work. She and Jack lived happily ever after.`,
                options: [],
                whiteSpace: 'inherit !important',
                subOptions: [
                    {
                        label: "What was the female's name?",
                        instruction: '2 points for correct answer',
                        controlType: 'radio',
                        alphabet: 'A',
                        hideScore: true,
                        options: [
                            {
                                score: 2,
                                description: 'Answered correctly',
                            },
                            {
                                score: 0,
                                description: 'Answered Incorrectly',
                            },
                        ],
                    },
                    {
                        label: 'What work did she do? ',
                        instruction: '2 point for correct answer',
                        controlType: 'radio',
                        alphabet: 'B',
                        hideScore: true,
                        options: [
                            {
                                score: 2,
                                description: 'Answered correctly',
                            },
                            {
                                score: 0,
                                description: 'Answered Incorrectly',
                            },
                        ],
                    },
                    {
                        label: 'When did she go back to work?',
                        instruction: '2 point for correct answer',
                        controlType: 'radio',
                        alphabet: 'C',
                        hideScore: true,
                        options: [
                            {
                                score: 2,
                                description: 'Answered correctly',
                            },
                            {
                                score: 0,
                                description: 'Answered Incorrectly',
                            },
                        ],
                    },
                    {
                        label: 'What state did she live in?',
                        instruction: '2 point for correct answer',
                        controlType: 'radio',
                        alphabet: 'D',
                        hideScore: true,
                        options: [
                            {
                                score: 2,
                                description: 'Answered correctly',
                            },
                            {
                                score: 0,
                                description: 'Answered Incorrectly',
                            },
                        ],
                    },
                ],
            },
        ];
    }
    get_Functional_Oral_Intake_Test() {
        return [
            {
                label: '',
                controlType: 'radio',
                options: [
                    {
                        score: 1,
                        description: 'Nothing by mouth',
                    },
                    {
                        score: 2,
                        description: 'Tube dependent with minimal attempts of food or liquid',
                    },
                    {
                        score: 3,
                        description: 'Tube dependent with consistent oral intake of food or liquid',
                    },
                    {
                        score: 4,
                        description: 'Total oral diet of a single consistency',
                    },
                    {
                        score: 5,
                        description:
                            'Total oral diet with multiple consistencies, but requiring special preparation or compensations',
                    },
                    {
                        score: 6,
                        description:
                            'Total oral diet with multiple consistencies without special preparation, but with specific food limitations',
                    },
                    {
                        score: 7,
                        description: 'Total oral diet with no restrictions',
                    },
                ],
                subOptions: [],
            },
        ];
    }

    get_Dysphagia_Outcome_and_Severity_Scale_Test() {
        return [
            {
                label: '',
                controlType: 'radio',
                options: [
                    {
                        score: 1,
                        description: 'Saliva Aspiration',
                    },
                    {
                        score: 2,
                        description: 'Food Aspiration',
                    },
                    {
                        score: 3,
                        description: 'Water Aspiration',
                    },
                    {
                        score: 4,
                        description: 'Occasional Aspiration',
                    },
                    {
                        score: 5,
                        description: 'Oral Problems',
                    },
                    {
                        score: 6,
                        description: 'Minimum Problems',
                    },
                    {
                        score: 7,
                        description: 'Within Normal Limits',
                    },
                ],
                subOptions: [],
            },
        ];
    }

    get_BERG_BALANCE_Test() {
        return [
            {
                label: 'Sit to stand',
                controlType: 'radio',
                options: [
                    {
                        score: 4,
                        description: 'Able to stand without using hands and stabilize independently',
                    },
                    {
                        score: 3,
                        description: 'Able to stand independently using hands',
                    },
                    {
                        score: 2,
                        description: 'Able to stand using hands after several tries',
                    },
                    {
                        score: 1,
                        description: 'Needs minimal aid to stand or stabilize',
                    },
                    {
                        score: 0,
                        description: 'Needs moderate or maximal assist to stand',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Standing Unsupported',
                controlType: 'radio',
                options: [
                    {
                        score: 4,
                        description: 'Able to stand safely for 2 minutes',
                    },
                    {
                        score: 3,
                        description: 'Able to stand 2 minutes with supervision',
                    },
                    {
                        score: 2,
                        description: 'Able to stand 30 seconds unsupported',
                    },
                    {
                        score: 1,
                        description: 'Needs several tries to stand 30 seconds unsupported',
                    },
                    {
                        score: 0,
                        description: 'Unable to stand 30 seconds unsupported',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Sitting Unsupported',
                controlType: 'radio',
                options: [
                    {
                        score: 4,
                        description: 'Able to sit safely and securely for 2 minutes',
                    },
                    {
                        score: 3,
                        description: 'Able to sit 2 minutes under supervision',
                    },
                    {
                        score: 2,
                        description: 'Able to sit 30 seconds',
                    },
                    {
                        score: 1,
                        description: 'Able to sit 10 seconds',
                    },
                    {
                        score: 0,
                        description: 'Unable to sit without support 10 seconds',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Standing to sitting',
                controlType: 'radio',
                options: [
                    {
                        score: 4,
                        description: 'Sits safely with minimal use of hands',
                    },
                    {
                        score: 3,
                        description: 'Controls descent by using hands',
                    },
                    {
                        score: 2,
                        description: 'Uses back of legs against chair to control descent',
                    },
                    {
                        score: 1,
                        description: 'Sits independently but has uncontrolled descent',
                    },
                    {
                        score: 0,
                        description: 'Needs assist to sit',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Transfers',
                controlType: 'radio',
                options: [
                    {
                        score: 4,
                        description: 'Able to transfer safely with minor use of hands',
                    },
                    {
                        score: 3,
                        description: 'Able to transfer safely definite need of hands',
                    },
                    {
                        score: 2,
                        description: 'Able to transfer with verbal cuing and/or supervision',
                    },
                    {
                        score: 1,
                        description: 'Needs one person to assist',
                    },
                    {
                        score: 0,
                        description: 'Needs two people to assist or supervise to be safe',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Standing with eyes closed',
                controlType: 'radio',
                options: [
                    {
                        score: 4,
                        description: 'Able to stand 10 seconds safely',
                    },
                    {
                        score: 3,
                        description: 'Able to stand 10 seconds with supervision',
                    },
                    {
                        score: 2,
                        description: 'Able to stand 3 seconds',
                    },
                    {
                        score: 1,
                        description: 'Unable to keep eyes closed 3 seconds but stays safely',
                    },
                    {
                        score: 0,
                        description: 'Needs help to keep from falling',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Standing with feet together',
                controlType: 'radio',
                options: [
                    {
                        score: 4,
                        description: 'Able to place feet together independently and stand 1 minute safely',
                    },
                    {
                        score: 3,
                        description: 'Able to place feet together independently and stand 1 minute with supervision',
                    },
                    {
                        score: 2,
                        description: 'Able to place feet together independently but unable to hold for 30 seconds',
                    },
                    {
                        score: 1,
                        description: 'Needs help to attain position but able to stand 15 seconds feet together',
                    },
                    {
                        score: 0,
                        description: 'Needs help to attain position and unable to hold for 15 seconds',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Reaching forward with outstretched arms',
                controlType: 'radio',
                options: [
                    {
                        score: 4,
                        description: 'Can reach forward confidently 25 cm (10 inches)',
                    },
                    {
                        score: 3,
                        description: 'Can reach forward 12 cm (5 inches)',
                    },
                    {
                        score: 2,
                        description: 'Can reach forward 5 cm (2 inches)',
                    },
                    {
                        score: 1,
                        description: 'Reaches forward but needs supervision',
                    },
                    {
                        score: 0,
                        description: 'Loses balance while trying/requires external support',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Retrieving object from ground',
                controlType: 'radio',
                options: [
                    {
                        score: 4,
                        description: 'Able to pick up slipper safely and easily',
                    },
                    {
                        score: 3,
                        description: 'Able to pick up slipper but needs supervision',
                    },
                    {
                        score: 2,
                        description:
                            "Unable to pick up but reaches 2-5 cm (1-2 inches) from slipper and keeps balance independently'",
                    },
                    {
                        score: 1,
                        description: 'Unable to pick up and needs supervision while trying',
                    },
                    {
                        score: 0,
                        description: 'Unable to try/needs assist to keep from losing balance or falling',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Turning to look behind',
                controlType: 'radio',
                options: [
                    {
                        score: 4,
                        description: 'Looks behind from both sides and weight shifts well',
                    },
                    {
                        score: 3,
                        description: 'Looks behind one side only other side shows less weight shift',
                    },
                    {
                        score: 2,
                        description: 'Turns sideways only but maintains balance',
                    },
                    {
                        score: 1,
                        description: 'Needs supervision when turning',
                    },
                    {
                        score: 0,
                        description: 'Needs assist to keep from losing balance or falling',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Turning 360 degrees',
                controlType: 'radio',
                options: [
                    {
                        score: 4,
                        description: 'Able to turn 360 degrees safely in 4 seconds or less',
                    },
                    {
                        score: 3,
                        description: 'Able to turn 360 degrees safely one side only 4 seconds or less',
                    },
                    {
                        score: 2,
                        description: 'Able to turn 360 degrees safely but slowly',
                    },
                    {
                        score: 1,
                        description: 'Needs close supervision or verbal cuing',
                    },
                    {
                        score: 0,
                        description: ' Needs assistance while turning',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Placing alternate foot on stool',
                controlType: 'radio',
                options: [
                    {
                        score: 4,
                        description: 'Able to stand independently and safely and complete 8 steps in 20 seconds',
                    },
                    {
                        score: 3,
                        description: 'Able to stand independently and complete 8 steps in > 20 seconds',
                    },
                    {
                        score: 2,
                        description: 'Able to complete 4 steps without aid with supervision',
                    },
                    {
                        score: 1,
                        description: 'Able to complete > 2 steps needs minimal assist',
                    },
                    {
                        score: 0,
                        description: 'Needs assistance to keep from falling/unable to try',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Standing with one foot in front',
                controlType: 'radio',
                options: [
                    {
                        score: 4,
                        description: 'Able to place foot tandem independently and hold 30 seconds',
                    },
                    {
                        score: 3,
                        description: 'Able to place foot ahead independently and hold 30 seconds',
                    },
                    {
                        score: 2,
                        description: 'Able to take small step independently and hold 30 seconds',
                    },
                    {
                        score: 1,
                        description: 'Needs help to step but can hold 15 seconds',
                    },
                    {
                        score: 0,
                        description: 'Loses balance while stepping or standing',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Standing on one foot',
                controlType: 'radio',
                options: [
                    {
                        score: 4,
                        description: 'Able to lift leg independently and hold > 10 seconds',
                    },
                    {
                        score: 3,
                        description: 'Able to lift leg independently and hold 5-10 seconds',
                    },
                    {
                        score: 2,
                        description: 'Able to lift leg independently and hold 3 seconds',
                    },
                    {
                        score: 1,
                        description: 'Tries to lift leg unable to hold 3 seconds but remains standing independently',
                    },
                    {
                        score: 0,
                        description: 'Unable to try or needs assist to prevent fall',
                    },
                ],
                subOptions: [],
            },
        ];
    }
    get_DGI_Test() {
        return [
            {
                label: 'Gait level surface',
                instruction: "Walk at your normal speed from here to the next mark (20')",
                controlType: 'radio',
                options: [
                    {
                        score: 3,
                        description:
                            "Normal: Walks 20', no assistive devices, good speed, no evidence for imbalance, normal gait pattern",
                    },
                    {
                        score: 2,
                        description:
                            "Mild Impairment: Walks 20', uses assistive devices, slower speed, mild gait deviations.",
                    },
                    {
                        score: 1,
                        description:
                            "Moderate Impairment: Walks 20', slow speed, abnormal gait pattern, evidence for imbalance.",
                    },
                    {
                        score: 0,
                        description:
                            "Severe Impairment: Cannot walk 20' without assistance, severe gait deviations or imbalance.",
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Change in gait speed',
                instruction:
                    "Begin walking at your normal pace (for 5'), when I tell you “go,” walk as fast as you can (for 5'). When I tell you “slow,” walk as slowly as you can (for 5').",
                controlType: 'radio',
                options: [
                    {
                        score: 3,
                        description:
                            'Normal: Able to smoothly change walking speed without loss of balance or gait deviation. Shows a significant difference in walking speeds between normal, fast and slow speeds.',
                    },
                    {
                        score: 2,
                        description:
                            'Mild Impairment: Is able to change speed but demonstrates mild gait deviations, or not gait deviations but unable to achieve a significant change in velocity, or uses an assistive device.',
                    },
                    {
                        score: 1,
                        description:
                            'Moderate Impairment: Makes only minor adjustments to walking speed, or accomplishes a change in speed with significant gait deviations, or changes speed but has significant gait deviations, or changes speed but loses balance but is able to recover and continue walking.',
                    },
                    {
                        score: 0,
                        description:
                            'Severe Impairment: Cannot change speeds, or loses balance and has to reach for wall or be caught.',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Gait with horizontal head turns',
                instruction:
                    'Begin walking at your normal pace. When I tell you to “look right,” keep walking straight, but turn your head to the right. Keep looking to the right until I tell you, “look left,” then keep walking straight and turn your head to the left. Keep your head to the left until I tell you “look straight,“ then keep walking straight, but return your head to the center.',
                controlType: 'radio',
                options: [
                    {
                        score: 3,
                        description: 'Normal: Performs head turns smoothly with no change in gait.',
                    },
                    {
                        score: 2,
                        description:
                            'Mild Impairment: Performs head turns smoothly with slight change in gait velocity, i.e., minor disruption to smooth gait path or uses walking aid.',
                    },
                    {
                        score: 1,
                        description:
                            'Moderate Impairment: Performs head turns with moderate change in gait velocity, slows down, staggers but recovers, can continue to walk.',
                    },
                    {
                        score: 0,
                        description:
                            'Severe Impairment: Performs task with severe disruption of gait, i.e., staggers outside 15” path, loses balance, stops, reaches for wall.',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Gait with vertical head turns',
                instruction:
                    'Begin walking at your normal pace. When I tell you to “look up,” keep walking straight, but tip your head up. Keep looking up until I tell you, “look down,” then keep walking straight and tip your head down. Keep your head down until I tell you “look straight,“ then keep walking straight, but return your head to the center.',
                controlType: 'radio',
                options: [
                    {
                        score: 3,
                        description: 'Normal: Performs head turns smoothly with no change in gait.',
                    },
                    {
                        score: 2,
                        description:
                            'Mild Impairment: Performs head turns smoothly with slight change in gait velocity, i.e., minor disruption to smooth gait path or uses walking aid.',
                    },
                    {
                        score: 1,
                        description:
                            'Moderate Impairment: Performs head turns with moderate change in gait velocity, slows down, staggers but recovers, can continue to walk.',
                    },
                    {
                        score: 0,
                        description:
                            'Severe Impairment: Performs task with severe disruption of gait, i.e., staggers outside 15” path, loses balance, stops, reaches for wall.',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Gait and pivot turn',
                instruction:
                    'Begin walking at your normal pace. When I tell you, “turn and stop,” turn as quickly as you can to face the opposite direction and stop.',
                controlType: 'radio',
                options: [
                    {
                        score: 3,
                        description:
                            'Normal: Pivot turns safely within 3 seconds and stops quickly with no loss of balance.',
                    },
                    {
                        score: 2,
                        description:
                            'Mild Impairment: Pivot turns safely in > 3 seconds and stops with no loss of balance.',
                    },
                    {
                        score: 1,
                        description:
                            'Moderate Impairment: Turns slowly, requires verbal cueing, requires several small steps to catch balance following turn and stop.',
                    },
                    {
                        score: 0,
                        description: 'Severe Impairment: Cannot turn safely, requires assistance to turn and stop.',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Step over obstacle',
                instruction:
                    'Begin walking at your normal speed. When you come to the shoe box, step over it, not around it, and keep walking.',
                controlType: 'radio',
                options: [
                    {
                        score: 3,
                        description:
                            'Normal: Is able to step over the box without changing gait speed, no evidence of imbalance.',
                    },
                    {
                        score: 2,
                        description:
                            'Mild Impairment: Is able to step over box, but must slow down and adjust steps to clear box safely.',
                    },
                    {
                        score: 1,
                        description:
                            'Moderate Impairment: Is able to step over box but must stop, then step over. May require verbal cueing.',
                    },
                    {
                        score: 0,
                        description: 'Severe Impairment: Cannot perform without assistance.',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Step around obstacles',
                instruction:
                    " Begin walking at normal speed. When you come to the first cone (about 6' away), walk around the right side of it. When you come to the second cone (6' past first cone), walk around it to the left.",
                controlType: 'radio',
                options: [
                    {
                        score: 3,
                        description:
                            'Normal: Is able to walk around cones safely without changing gait speed; no evidence of imbalance.',
                    },
                    {
                        score: 2,
                        description:
                            'Mild Impairment: Is able to step around both cones, but must slow down and adjust steps to clear cones.',
                    },
                    {
                        score: 1,
                        description:
                            'Moderate Impairment: Is able to clear cones but must significantly slow, speed to accomplish task, or requires verbal cueing.',
                    },
                    {
                        score: 0,
                        description:
                            'Severe Impairment: Unable to clear cones, walks into one or both cones, or requires physical assistance.',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Steps',
                instruction:
                    'Walk up these stairs as you would at home, i.e., using the railing if necessary. At the top, turn around and walk down.',
                controlType: 'radio',
                options: [
                    {
                        score: 3,
                        description: 'Normal: Alternating feet, no rail.',
                    },
                    {
                        score: 2,
                        description: 'Mild Impairment: Alternating feet, must use rail.',
                    },
                    {
                        score: 1,
                        description: 'Moderate Impairment: Two feet to a stair, must use rail.',
                    },
                    {
                        score: 0,
                        description: 'Severe Impairment: Cannot do safely',
                    },
                ],
                subOptions: [],
            },
        ];
    }

    get_TINETTI_ASSESSMENT_Test() {
        return [
            {
                type: 'Balance Tests',
                label: 'Sitting Balance',
                controlType: 'radio',
                options: [
                    {
                        score: 0,
                        description: 'Leans or slides in chair',
                    },
                    {
                        score: 1,
                        description: 'Steady, safe',
                    },
                ],
                subOptions: [],
            },
            {
                type: 'Balance Tests',
                label: 'Arises',
                controlType: 'radio',
                options: [
                    {
                        score: 0,
                        description: 'Unable without help',
                    },
                    {
                        score: 1,
                        description: 'Able, uses arms to help',
                    },
                    {
                        score: 2,
                        description: 'Able without using arms',
                    },
                ],
                subOptions: [],
            },
            {
                type: 'Balance Tests',
                label: 'Attempts to Arise',
                controlType: 'radio',
                options: [
                    {
                        score: 0,
                        description: 'Unable without help',
                    },
                    {
                        score: 1,
                        description: 'Able, requires > 1 attempt',
                    },
                    {
                        score: 2,
                        description: 'Able to rise, 1 attempt',
                    },
                ],
                subOptions: [],
            },
            {
                type: 'Balance Tests',
                label: 'Immediate Standing Balance',
                instruction: '(first 5 seconds)',
                controlType: 'radio',
                options: [
                    {
                        score: 0,
                        description: 'Unsteady (swaggers, moves feet, trunk sway) ',
                    },
                    {
                        score: 1,
                        description: 'Steady but uses walker or other support',
                    },
                    {
                        score: 2,
                        description: 'Steady without walker or other support',
                    },
                ],
                subOptions: [],
            },
            {
                type: 'Balance Tests',
                label: 'Standing Balance',
                controlType: 'radio',
                options: [
                    {
                        score: 0,
                        description: 'Unsteady',
                    },
                    {
                        score: 1,
                        description:
                            'Steady but wide stance( medial heals > 4 inches apart) and uses cane or other support',
                    },
                    {
                        score: 2,
                        description: 'Narrow stance without support',
                    },
                ],
                subOptions: [],
            },
            {
                type: 'Balance Tests',
                label: 'Nudged',
                instruction:
                    "(subject at maximum position with feet as close together as possible, examiner pushes lightly on subject's sternum with palm of hand 3 times)",
                controlType: 'radio',
                options: [
                    {
                        score: 0,
                        description: 'Begins to fall',
                    },
                    {
                        score: 1,
                        description: 'Staggers, grabs, catches self',
                    },
                    {
                        score: 2,
                        description: 'Steady',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Turing 360 Degrees',
                controlType: '',
                instruction: '',
                options: [],
                sameColumn: true,
                subOptions: [
                    {
                        label: 'A',
                        controlType: 'radio',
                        options: [
                            {
                                score: 0,
                                description: 'Discontinuous steps',
                            },
                            {
                                score: 1,
                                description: 'Continuous steps',
                            },
                        ],
                    },
                    {
                        label: 'B',
                        controlType: 'radio',
                        options: [
                            {
                                score: 0,
                                description: 'Unsteady (grabs, staggers)',
                            },
                            {
                                score: 1,
                                description: 'Steady',
                            },
                        ],
                    },
                ],
            },
            {
                type: 'Balance Tests',
                label: 'Eyes Closed',
                instruction: '(at maximum position of item 6)',
                controlType: 'radio',
                options: [
                    {
                        score: 0,
                        description: 'Unsteady',
                    },
                    {
                        score: 1,
                        description: 'Steady',
                    },
                ],
                subOptions: [],
            },
            {
                type: 'Balance Tests',
                label: 'Sitting Down',
                controlType: 'radio',
                options: [
                    {
                        score: 0,
                        description: 'Unsafe (misjudged distance, falls into chair)',
                    },
                    {
                        score: 1,
                        description: 'Uses arms or not a smooth motion',
                    },
                    {
                        score: 2,
                        description: 'Safe, smooth motion',
                    },
                ],
                subOptions: [],
            },

            {
                type: 'Gait Tests',
                label: 'Initiation of Gait',
                instruction: 'immediately after told to “go”',
                controlType: 'radio',
                fullWidth: true,
                options: [
                    {
                        score: 0,
                        description: 'Any hesitancy or multiple attempts to start',
                    },
                    {
                        score: 1,
                        description: 'No hesitancy',
                    },
                ],
                subOptions: [],
            },
            {
                type: 'Gait Tests',
                label: 'Step Length and Height(Right swing foot)',
                controlType: 'radio',
                options: [],
                sameColumn: true,
                subOptions: [
                    {
                        label: 'A',
                        controlType: 'radio',
                        options: [
                            {
                                score: 0,
                                description: 'Does not pass left stance foot with step',
                            },
                            {
                                score: 1,
                                description: 'Passes left stance foot',
                            },
                        ],
                    },
                    {
                        label: 'B',
                        controlType: 'radio',
                        options: [
                            {
                                score: 0,
                                description: 'Right foot does not clear floor completely with step',
                            },
                            {
                                score: 1,
                                description: 'Right foot completely clears floor',
                            },
                        ],
                    },
                ],
            },
            {
                type: 'Gait Tests',
                label: 'Step Length and Height(Left swing foot)',
                controlType: 'radio',
                options: [],
                sameColumn: true,
                subOptions: [
                    {
                        label: 'A',
                        controlType: 'radio',
                        options: [
                            {
                                score: 0,
                                description: 'Does not pass right stance foot with step',
                            },
                            {
                                score: 1,
                                description: 'Passes right stance foot',
                            },
                        ],
                    },
                    {
                        label: 'B',
                        controlType: 'radio',
                        options: [
                            {
                                score: 0,
                                description: 'Left foot does not clear floor completely with step',
                            },
                            {
                                score: 1,
                                description: 'Left foot completely clears floor',
                            },
                        ],
                    },
                ],
            },
            {
                type: 'Gait Tests',
                label: 'Step Symmetry',
                controlType: 'radio',
                options: [
                    {
                        score: 0,
                        description: 'Right and left step length not equal (estimate)',
                    },
                    {
                        score: 1,
                        description: 'Right and left step length appear equal',
                    },
                ],
                subOptions: [],
            },
            {
                type: 'Gait Tests',
                label: 'Step Continuity',
                controlType: 'radio',
                options: [
                    {
                        score: 0,
                        description: 'Stopping or discontinuity between steps',
                    },
                    {
                        score: 1,
                        description: 'Steps appear continuous',
                    },
                ],
                subOptions: [],
            },
            {
                type: 'Gait Tests',
                label: 'Path',
                instruction:
                    '(estimated in relation to floor tiles, 12-inch diameter; observe excursion of 1 foot over about 10 ft. of the course) ',
                controlType: 'radio',
                options: [
                    {
                        score: 0,
                        description: 'Marked deviation',
                    },
                    {
                        score: 1,
                        description: 'Mild/moderate deviation or uses walking aid',
                    },
                    {
                        score: 2,
                        description: 'Straight without walking aid',
                    },
                ],
                subOptions: [],
            },
            {
                type: 'Gait Tests',
                label: 'Trunk',
                controlType: 'radio',
                options: [
                    {
                        score: 0,
                        description: 'Marked sway or uses walking aid',
                    },
                    {
                        score: 1,
                        description: 'No sway but flexion of knees or back or Spreads arms out while walking',
                    },
                    {
                        score: 2,
                        description: 'No sway, no flexion, no use of arms, and no Use of walking aid ',
                    },
                ],
                subOptions: [],
            },
            {
                type: 'Gait Tests',
                label: 'Walking Stance',
                controlType: 'radio',
                options: [
                    {
                        score: 0,
                        description: 'Heels apart',
                    },
                    // {
                    //     score: 1,
                    //     description: 'No sway but flexion of knees or back or Spreads arms out while walking',
                    // },
                    {
                        score: 1,
                        description: 'Heels almost touching while walking',
                    },
                ],
                subOptions: [],
            },
        ];
    }
    get_TIME_UP_AND_GO_TUG_Test() {
        return [
            {
                label: "Observe the patient's postural stability, gait, stride length, and sway",
                controlLabel: 'Check all that apply',
                controlType: 'checkbox',
                instruction: `1 Instruct the patient: 
          When I say “Go,” I want you to:
           1. Stand up from the chair.
           2. Walk to the line on the floor at your normal pace.
           3. Turn.
           4. Walk back to the chair at your normal pace.
           5. Sit down again.
           2 On the word “Go,” begin timing.
           3 Stop timing after patient sits back down.
           4 Record time.`,
                options: [
                    { text: 'Slow tentative pace' },
                    { text: 'Loss of balance' },
                    { text: 'Short strides' },
                    { text: 'Little or no arm swing' },
                    { text: 'Steadying self on walls' },
                    { text: 'Shuffling' },
                    { text: 'En bloc turning' },
                    { text: 'Not using assistive device properly' },
                    { text: 'None' },
                ],
                subOptions: [],
            },
            {
                label: 'Time Recorded',
                controlType: 'text',
                options: [],
                subOptions: [],
                hideLabel: true,
            },
        ];
    }
    get_BRIEF_INTERVIEW_FOR_MENTAL_STATUS_Test() {
        return [
            {
                label: 'Repetition of three words',
                instruction:
                    'Inform the resident that you are going to say three words and that he or she needs to remember the words and to repeat them. Say the three words: sock, blue and bed. Ask the resident to reproduce the three words and note the number after the first attempt:',
                controlType: 'radio',
                options: [
                    {
                        score: 0,
                        description: 'No word repeated',
                    },
                    {
                        score: 1,
                        description: 'One word repeated',
                    },
                    {
                        score: 2,
                        description: 'Two words repeated',
                    },
                    {
                        score: 3,
                        description: 'All words repeated',
                    },
                ],
                subOptions: [],
            },
            // {
            //   label:
            //     'Inform the resident that you are going to say three words and that he or she needs to remember the words and to repeat them. Say the three words: sock, blue and bed. Ask the resident to reproduce the three words and note the number after the first attempt:',
            //   controlType: 'radio',
            //   options: [
            //     {
            //       score: 0,
            //       description: 'No word repeated',
            //     },
            //     {
            //       score: 1,
            //       description: 'One word repeated',
            //     },
            //     {
            //       score: 2,
            //       description: 'Two words repeated',
            //     },
            //     {
            //       score: 3,
            //       description: 'All words repeated',
            //     },
            //   ],
            // },
            {
                label: 'Temporal orientation',
                instruction: '',
                controlType: '',
                options: [],
                subOptions: [
                    {
                        label: 'Ask the resident what year is it right now and report their answer:',
                        controlType: 'radio',
                        alphabet: 'A',
                        options: [
                            {
                                score: 0,
                                description: 'Missed by >5 years or no answer',
                            },
                            {
                                score: 1,
                                description: 'Missed by 2 – 5 years',
                            },
                            {
                                score: 2,
                                description: 'Missed by 1 year',
                            },
                            {
                                score: 3,
                                description: 'Correct',
                            },
                        ],
                    },
                    {
                        label: 'Ask the resident what month we are in at the moment and report their answer:',
                        controlType: 'radio',
                        alphabet: 'B',
                        options: [
                            {
                                score: 0,
                                description: 'Missed by >1 month or no answer',
                            },
                            {
                                score: 1,
                                description: 'Missed by 6 days to one month',
                            },
                            {
                                score: 2,
                                description: 'Accurate within 5 days',
                            },
                        ],
                    },
                    {
                        label: 'Ask the resident what day of the week is today and report their answer:',
                        controlType: 'radio',
                        alphabet: 'C',
                        options: [
                            {
                                score: 0,
                                description: 'Incorrect, or no answer',
                            },
                            {
                                score: 1,
                                description: 'Correct',
                            },
                        ],
                    },
                ],
            },

            {
                label: 'Recall',
                instruction:
                    'Tell the resident that you are going back to the earlier question and ask them to say the words that they were asked to repeat. If they are unable to remember a word, provide cues such as “something to wear”,” a color”, “a piece of furniture',
                controlType: '',
                options: [],
                subOptions: [
                    {
                        label: 'Able to recall “sock”',
                        controlType: 'radio',
                        instruction: '',
                        alphabet: 'A',
                        options: [
                            {
                                score: 0,
                                description: 'No, could not recall',
                            },
                            {
                                score: 1,
                                description: 'Yes, after cueing “something to wear”',
                            },
                            {
                                score: 2,
                                description: 'Yes, no cue required',
                            },
                        ],
                    },
                    {
                        label: 'Able to recall “blue”',
                        controlType: 'radio',
                        instruction: '',
                        alphabet: 'B',
                        options: [
                            {
                                score: 0,
                                description: 'No, could not recall',
                            },
                            {
                                score: 1,
                                description: 'Yes, after cueing “color”',
                            },
                            {
                                score: 2,
                                description: 'Yes, no cue required',
                            },
                        ],
                    },
                    {
                        label: 'Able to recall “bed”',
                        controlType: 'radio',
                        instruction: '',
                        alphabet: 'C',
                        options: [
                            {
                                score: 0,
                                description: 'No, could not recall',
                            },
                            {
                                score: 1,
                                description: 'Yes, after cueing “a piece of furniture”',
                            },
                            {
                                score: 2,
                                description: 'Yes, no cue required',
                            },
                        ],
                    },
                ],
            },
        ];
    }
    get_BARTHEL_INDEX_Test() {
        return [
            {
                label: 'Bowels',
                controlType: 'radio',
                options: [
                    {
                        score: 0,
                        description: 'Incontinent (or needs to be given enema)',
                    },
                    {
                        score: 1,
                        description: 'occasional accident (once/week)',
                    },
                    {
                        score: 2,
                        description: 'continent',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Bladder',
                controlType: 'radio',
                options: [
                    {
                        score: 0,
                        description: 'incontinent, or catheterized and unable to manage',
                    },
                    {
                        score: 1,
                        description: 'occasional accident (max. once per 24 hours)',
                    },
                    {
                        score: 2,
                        description: 'continent (for over 7 days)',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Grooming',
                controlType: 'radio',
                options: [
                    {
                        score: 0,
                        description: 'needs help with personal care',
                    },
                    {
                        score: 1,
                        description: 'independent face/hair/teeth/shaving (implements provided)',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Toilet use',
                controlType: 'radio',
                options: [
                    {
                        score: 0,
                        description: 'dependent',
                    },
                    {
                        score: 1,
                        description: 'needs some help, but can do something alone',
                    },
                    {
                        score: 2,
                        description: 'independent (on and off, dressing, wiping)',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Feeding',
                controlType: 'radio',
                options: [
                    {
                        score: 0,
                        description: 'unable',
                    },
                    {
                        score: 1,
                        description: 'needs help cutting, spreading butter, etc.',
                    },
                    {
                        score: 2,
                        description: 'independent (food provided within reach)',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Transfer',
                controlType: 'radio',
                options: [
                    {
                        score: 0,
                        description: 'unable – no sitting balance',
                    },
                    {
                        score: 1,
                        description: 'major help (one or two people, physical), can sit',
                    },
                    {
                        score: 2,
                        description: 'minor help (verbal or physical)',
                    },
                    {
                        score: 3,
                        description: 'independent',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Mobility',
                controlType: 'radio',
                options: [
                    {
                        score: 0,
                        description: 'immobile',
                    },
                    {
                        score: 1,
                        description: 'wheelchair independent, including corners, etc.',
                    },
                    {
                        score: 2,
                        description: 'walks with help of one person (verbal or physical)',
                    },
                    {
                        score: 3,
                        description: 'independent (but may use any aid)',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Dressing',
                controlType: 'radio',
                options: [
                    {
                        score: 0,
                        description: 'dependent',
                    },
                    {
                        score: 1,
                        description: 'needs help, but can do about half unaided',
                    },
                    {
                        score: 2,
                        description: 'independent (including buttons, zips, laces etc)',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Stairs',
                controlType: 'radio',
                options: [
                    {
                        score: 0,
                        description: 'unable',
                    },
                    {
                        score: 1,
                        description: 'needs help (verbal, physical, carrying aid)',
                    },
                    {
                        score: 2,
                        description: 'independent up and down',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Bathing',
                controlType: 'radio',
                options: [
                    {
                        score: 0,
                        description: 'dependent',
                    },
                    {
                        score: 1,
                        description: 'independent (or in shower)',
                    },
                ],
                subOptions: [],
            },
        ];
    }

    /**
     * Generic Function to Aggregate Total of Tests.
     * Agreed Assumptions:
     * 1. 'subOptions' will always be at first level with no more recursive nesting.
     * 2. Only outside 'options' or 'subOptions.options' will be populated at a single time.
     */
    calculateTotal(test: any[]): number {
        return test.reduce((acc, curr) => {
            const outsideOptionSum = curr.options
                .map((option: { score: number; description: string }) => option.score)
                .reduce((a: number, b: number) => {
                    if (curr.isExcludeFromTotal) {
                        return 0;
                    } else if (curr.controlType == 'radio') {
                        return Math.max(a, b);
                    } else if (curr.controlType == 'checkbox') {
                        return a + b;
                    }
                }, 0);

            const subOptionsSum = curr.subOptions.reduce(
                (acc1: number, curr1: any) =>
                    acc1 +
                    curr1.options
                        .map((option: { score: number; description: string }) => option.score)
                        .reduce((a: number, b: number) => {
                            if (curr1.controlType == 'radio') {
                                return Math.max(a, b);
                            } else if (curr1.controlType == 'checkbox') {
                                return a + b;
                            }
                        }, 0),
                0
            );
            return acc + outsideOptionSum + subOptionsSum;
        }, 0);
    }

    get_PHQ9_Test() {
        return [
            {
                label: 'Little interest or pleasure in doing things',
                controlType: 'radio',
                options: [
                    {
                        score: 0,
                        description: 'Not at all',
                    },
                    {
                        score: 1,
                        description: 'Several days',
                    },
                    {
                        score: 2,
                        description: 'More than one-half of the days',
                    },
                    {
                        score: 3,
                        description: 'Nearly every day',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Feeling down, depressed or hopeless',
                controlType: 'radio',
                options: [
                    {
                        score: 0,
                        description: 'Not at all',
                    },
                    {
                        score: 1,
                        description: 'Several days',
                    },
                    {
                        score: 2,
                        description: 'More than one-half of the days',
                    },
                    {
                        score: 3,
                        description: 'Nearly every day',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Trouble falling asleep, staying asleep, or sleeping too much',
                controlType: 'radio',
                options: [
                    {
                        score: 0,
                        description: 'Not at all',
                    },
                    {
                        score: 1,
                        description: 'Several days',
                    },
                    {
                        score: 2,
                        description: 'More than one-half of the days',
                    },
                    {
                        score: 3,
                        description: 'Nearly every day',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Feeling tired or having little energy',
                controlType: 'radio',
                options: [
                    {
                        score: 0,
                        description: 'Not at all',
                    },
                    {
                        score: 1,
                        description: 'Several days',
                    },
                    {
                        score: 2,
                        description: 'More than one-half of the days',
                    },
                    {
                        score: 3,
                        description: 'Nearly every day',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Poor appetite or overeating',
                controlType: 'radio',
                options: [
                    {
                        score: 0,
                        description: 'Not at all',
                    },
                    {
                        score: 1,
                        description: 'Several days',
                    },
                    {
                        score: 2,
                        description: 'More than one-half of the days',
                    },
                    {
                        score: 3,
                        description: 'Nearly every day',
                    },
                ],
                subOptions: [],
            },
            {
                label: "Feeling bad about yourself - or that you're a failure or have let yourself or your family down",
                controlType: 'radio',
                options: [
                    {
                        score: 0,
                        description: 'Not at all',
                    },
                    {
                        score: 1,
                        description: 'Several days',
                    },
                    {
                        score: 2,
                        description: 'More than one-half of the days',
                    },
                    {
                        score: 3,
                        description: 'Nearly every day',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Trouble concentrating on things, such as reading the newspaper or watching television',
                controlType: 'radio',
                options: [
                    {
                        score: 0,
                        description: 'Not at all',
                    },
                    {
                        score: 1,
                        description: 'Several days',
                    },
                    {
                        score: 2,
                        description: 'More than one-half of the days',
                    },
                    {
                        score: 3,
                        description: 'Nearly every day',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Moving or speaking so slowly that other people could have noticed; or the opposite - being so fidgety or restless that you have been moving around a lot more than usual',
                controlType: 'radio',
                options: [
                    {
                        score: 0,
                        description: 'Not at all',
                    },
                    {
                        score: 1,
                        description: 'Several days',
                    },
                    {
                        score: 2,
                        description: 'More than one-half of the days',
                    },
                    {
                        score: 3,
                        description: 'Nearly every day',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Thoughts that you would be better off dead or of hurting yourself in some way',
                controlType: 'radio',
                options: [
                    {
                        score: 0,
                        description: 'Not at all',
                    },
                    {
                        score: 1,
                        description: 'Several days',
                    },
                    {
                        score: 2,
                        description: 'More than one-half of the days',
                    },
                    {
                        score: 3,
                        description: 'Nearly every day',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'If you checked off any problems listed above, how difficult have those problems made it for you to do your work, take care of things at home, or get along with other people?',
                controlType: 'radio',
                options: [
                    {
                        score: 0,
                        description: 'Not difficult at all ',
                    },
                    {
                        score: 1,
                        description: 'Somewhat difficult',
                    },
                    {
                        score: 2,
                        description: 'Very difficult',
                    },
                    {
                        score: 3,
                        description: 'Extremely difficult',
                    },
                ],
                subOptions: [],
            },
        ];
    }

    get_NOMS_Attention_Test() {
        return [
            {
                label: 'Attention',
                controlType: 'radio',
                options: [
                    {
                        score: 1,
                        description:
                            'Attention is nonfunctional. The individual is generally unresponsive to most stimuli.',
                    },
                    {
                        score: 2,
                        description:
                            'The individual can briefly attend with consistent maximal stimulation, but not long enough to complete even simple living tasks.',
                    },
                    {
                        score: 3,
                        description:
                            'The individual maintains attention over time to complete simple living tasks of short duration with consistent maximal cueing in the absence of distracting stimuli.',
                    },
                    {
                        score: 4,
                        description:
                            'The individual maintains attention during simple living tasks of multiple steps and long duration within a minimally distracting environment with consistent minimal cueing.',
                    },
                    {
                        score: 5,
                        description:
                            'The individual maintains attention within simple living activities with occasional minimal cues within distracting environments. The individual requires increased cueing to start, continue, and change attention during complex activities.',
                    },
                    {
                        score: 6,
                        description:
                            'The individual maintains attention within complex activities and can attend simultaneously to multiple demands with rare minimal cues. The individual usually uses compensatory strategies when encountering difficulty. The individual has mild difficulty or takes more than a reasonable amount of time to attend to multiple tasks/stimuli.',
                    },
                    {
                        score: 7,
                        description:
                            "The individual's ability to participate in vocational, avocational, or social activities is not limited by attentional abilities. Independent functioning may occasionally include the use of compensatory strategies.",
                    },
                ],
                subOptions: [],
            },
        ];
    }
    get_NOMS_MEMORY_Test() {
        return [
            {
                label: 'Memory',
                controlType: 'radio',
                options: [
                    {
                        score: 1,
                        description: 'The individual is unable to recall any information, regardless of cueing.',
                    },
                    {
                        score: 2,
                        description:
                            'The individual consistently requires maximal verbal cues or uses external aids to recall personal information (e.g., family members, biographical information, physical location, etc.) in structured environments.',
                    },
                    {
                        score: 3,
                        description:
                            'The individual usually requires maximal cues to recall or use external aids for simple routine and personal information (e.g., schedule, names of familiar staff, location of therapy areas, etc.) in structured environments.',
                    },
                    {
                        score: 4,
                        description:
                            'The individual occasionally requires minimal cues to recall or use external memory aids for simple routine and personal information in structured environments. The individual requires consistent maximal cues to recall or use memory aids for complex and novel information (e.g., carry out multiple steps activities, accommodate schedule changes, anticipate meal times, etc.), plan and follow through on simple future events (e.g., use calendar to keep appointments, use log books to complete a single assignment/task, etc.) in structured environments.',
                    },
                    {
                        score: 5,
                        description:
                            'The individual consistently requires minimal cues to recall or use external memory aids for complex and novel information. The individual consistently requires minimal cues to plan and follow through on complex future events (e.g., menu planning and meal preparation, planning a party, etc.).',
                    },
                    {
                        score: 6,
                        description:
                            "The individual is able to recall or use external aids/memory strategies for complex information and planning complex future events most of the time. When there is a breakdown in the use of recall/memory strategies/external memory aids, the individual occasionally requires minimal cues. These breakdowns may occasionally interfere with the individual's functioning in vocational, avocational, and social activities.",
                    },
                    {
                        score: 7,
                        description:
                            'The individual is successful and independent in recalling or using external aids/memory strategies for complex information and planning future events in all vocational, avocational, and social activities. ',
                    },
                ],
                subOptions: [],
            },
        ];
    }
    get_NOMS_MOTOR_SPEECH_Test() {
        return [
            {
                label: 'Motor Speech',
                controlType: 'radio',
                options: [
                    {
                        score: 1,
                        description:
                            'The individual attempts to speak, but speech cannot be understood by familiar or unfamiliar listeners at any time.',
                    },
                    {
                        score: 2,
                        description:
                            'The individual attempts to speak. The communication partner must assume responsibility for interpreting the message, and with consistent and maximal cues, the patient can produce short consonant-vowel combinations or automatic words that are rarely intelligible in context.',
                    },
                    {
                        score: 3,
                        description:
                            'The communication partner must assume primary responsibility for interpreting the communication exchange; however, the individual is able to produce short consonant–vowel combinations or automatic words intelligibly. With consistent and moderate cueing, the individual can produce simple words and phrases intelligibly, although accuracy may vary.',
                    },
                    {
                        score: 4,
                        description:
                            'In simple structured conversation with familiar communication partners, the individual can produce simple words and phrases intelligibly. The individual usually requires moderate cueing in order to produce simple sentences intelligibly, although accuracy may vary.',
                    },
                    {
                        score: 5,
                        description:
                            'The individual is able to speak intelligibly using simple sentences in daily routine activities with both familiar and unfamiliar communication partners. The individual occasionally requires minimal cueing to produce more complex sentences/messages in routine activities, although accuracy may vary and the individual may occasionally use compensatory strategies.',
                    },
                    {
                        score: 6,
                        description:
                            'The individual is successfully able to communicate intelligibly in most activities, but some limitations in intelligibility are still apparent in vocational, avocational, and social activities. The individual rarely requires minimal cueing to produce complex sentences/messages intelligibly. The individual usually uses compensatory strategies when encountering difficulty.',
                    },
                    {
                        score: 7,
                        description:
                            "The individual's ability to successfully and independently participate in vocational, avocational, or social activities is not limited by speech production. Independent functioning may occasionally include the use of compensatory techniques.",
                    },
                ],
                subOptions: [],
            },
        ];
    }
    get_NOMS_Reading_Test() {
        return [
            {
                label: 'Reading',
                controlType: 'radio',
                options: [
                    {
                        score: 1,
                        description:
                            "The individual attends to printed material, but doesn't recognize even single letters or common words.",
                    },
                    {
                        score: 2,
                        description:
                            'The individual reads single letters and common words with consistent maximal cueing.',
                    },
                    {
                        score: 3,
                        description:
                            'The individual reads single letters and common words, and with consistent moderate cueing, can read some words that are less familiar, longer, and more complex.',
                    },
                    {
                        score: 4,
                        description:
                            'The individual reads words and phrases related to routine daily activities and words that are less familiar, longer, and more complex. The individual usually requires moderate cueing to read sentences of approximately 5–7 words.',
                    },
                    {
                        score: 5,
                        description:
                            'The individual reads sentence-level material containing some complex words. The individual occasionally requires minimal cueing to read more complex sentences and paragraph-level material. The individual occasionally uses compensatory strategies.',
                    },
                    {
                        score: 6,
                        description:
                            'The individual is successfully able to read most material but some limitations in reading are still apparent in vocational, avocational, and social activities. The individual rarely requires minimal cueing to read complex material. Although reading is successful, it may take the individual longer to read the material. The individual usually uses compensatory strategies when encountering difficulty.',
                    },
                    {
                        score: 7,
                        description:
                            "The individual's ability to successfully and independently participate in vocational, avocational, and social activities is not limited by reading skills. Independent functioning may occasionally include use of compensatory strategies.",
                    },
                ],
                subOptions: [],
            },
        ];
    }
    get_NOMS_SPOKEN_LANGUAGE_Test() {
        return [
            {
                label: 'Spoken Language Comprehension',
                controlType: 'radio',
                options: [
                    {
                        score: 1,
                        description:
                            'The individual is alert, but unable to follow simple directions or respond to yes/no questions, even with cues.',
                    },
                    {
                        score: 2,
                        description:
                            'With consistent, maximal cues, the individual is able to follow simple directions, respond to simple yes/no questions in context, and respond to simple words or phrases related to personal needs.',
                    },
                    {
                        score: 3,
                        description:
                            'The individual usually responds accurately to simple yes/no questions. The individual is able to follow simple directions out of context, although moderate cueing is consistently needed. Accurate comprehension of more complex directions/messages is infrequent.',
                    },
                    {
                        score: 4,
                        description:
                            'The individual consistently responds accurately to simple yes/no questions and occasionally follows simple directions without cues. Moderate contextual support is usually needed to understand complex sentences/messages. The individual is able to understand limited conversations about routine daily activities with familiar communication partners.',
                    },
                    {
                        score: 5,
                        description:
                            'The individual is able to understand communication in structured conversations with both familiar and unfamiliar communication partners. The individual occasionally requires minimal cueing to understand more complex sentences/messages. The individual occasionally initiates the use of compensatory strategies when encountering difficulty.',
                    },
                    {
                        score: 6,
                        description:
                            'The individual is able to understand communication in most activities, but some limitations in comprehension are still apparent in vocational, avocational, and social activities. The individual rarely requires minimal cueing to understand complex sentences. The individual usually uses compensatory strategies when encountering difficulty.',
                    },
                    {
                        score: 7,
                        description:
                            "The individual's ability to independently participate in vocational, avocational,and social activities is not limited by spoken language comprehension. When difficulty with comprehension occurs, the individual consistently uses a compensatory strategy.",
                    },
                ],
                subOptions: [],
            },
        ];
    }
    get_NOMS_SPOKEN_LANGUAGE_Expression_Test() {
        return [
            {
                label: 'Spoken Language Expression',
                controlType: 'radio',
                options: [
                    {
                        score: 1,
                        description:
                            'The individual attempts to speak, but verbalizations are not meaningful to familiar or unfamiliar communication partners at any time.',
                    },
                    {
                        score: 2,
                        description:
                            'The individual attempts to speak, although few attempts are accurate or appropriate.The communication partner must assume responsibility for structuring the communication exchange, and with consistent and maximal cueing, the individual can only occasionally produce automatic and/or imitative words and phrases that are rarely meaningful in context.',
                    },
                    {
                        score: 3,
                        description:
                            'The communication partner must assume responsibility for structuring the communication exchange, and with consistent and moderate cueing, the individual can produce words and phrases that are appropriate and meaningful in context.',
                    },
                    {
                        score: 4,
                        description:
                            'The individual is successfully able to initiate communication using spoken language in simple, structured conversations in routine daily activities with familiar communication partners. The individual usually requires moderate cueing, but is able to demonstrate use of simple sentences (i.e., semantics, syntax, and morphology) and rarely uses complex sentences/messages.',
                    },
                    {
                        score: 5,
                        description:
                            'The individual is successfully able to initiate communication using spoken language in structured conversations with both familiar and unfamiliar communication partners.The individual occasionally requires minimal cueing to frame more complex sentences in messages. The individual occasionally self-cues when encountering difficulty.',
                    },
                    {
                        score: 6,
                        description:
                            'The individual is successfully able to communicate in most activities, but some limitations in spoken language are still apparent in vocational, avocational, and social activities. The individual rarely requires minimal cueing to frame complex sentences.The individual usually self-cues when encountering difficulty.',
                    },
                    {
                        score: 7,
                        description:
                            "The individual's ability to successfully and independently participate in vocational,avocational, and social activities is not limited by spoken language skills. Independent functioning may occasionally include use of self-cueing.",
                    },
                ],
                subOptions: [],
            },
        ];
    }
    get_NOMS_SWALLOWING_Test() {
        return [
            {
                label: 'Swallowing',
                controlType: 'radio',
                options: [
                    {
                        score: 1,
                        description:
                            'Individual is not able to swallow anything safely by mouth. All nutrition and hydration are received through non-oral means (e.g., nasogastric tube, PEG).',
                    },
                    {
                        score: 2,
                        description:
                            'Individual is not able to swallow safely by mouth for nutrition and hydration, but may take some consistency with consistent maximal cues in therapy only. Alternative method of feeding is required.',
                    },
                    {
                        score: 3,
                        description:
                            'Alternative method of feeding is required as individual takes less than 50% of nutrition and hydration by mouth, and/or swallowing is safe with consistent use of moderate cues to use compensatory strategies and/or requires maximum diet restriction.',
                    },
                    {
                        score: 4,
                        description:
                            'Swallowing is safe, but usually requires moderate cues to use compensatory strategies, and/or the individual has moderate diet restrictions and/or still requires tube feeding and/or oral supplements.',
                    },
                    {
                        score: 5,
                        description:
                            'Swallowing is safe with minimal diet restriction and/or occasionally requires minimal cueing to use compensatory strategies. The individual may occasionally self-cue. All nutrition and hydration needs are met by mouth at mealtime.',
                    },
                    {
                        score: 6,
                        description:
                            'Swallowing is safe, and the individual eats and drinks independently and may rarely require minimal cueing. The individual usually self-cues when difficulty occurs. May need to avoid specific food items (e.g., popcorn and nuts) or require additional time(due to dysphagia).',
                    },
                    {
                        score: 7,
                        description:
                            "The individual's ability to eat independently is not limited by swallow function.Swallowing would be safe and efficient for all consistencies. Compensatory strategies are effectively used when needed.",
                    },
                ],
                subOptions: [],
            },
        ];
    }
    get_NOMS_Writing_Test() {
        return [
            {
                label: 'Writing',
                controlType: 'radio',
                options: [
                    {
                        score: 1,
                        description:
                            "The individual attempts to write, but doesn't produce recognizable single letters or common words.",
                    },
                    {
                        score: 2,
                        description:
                            'The individual writes single letters and common words with consistent maximal cueing.',
                    },
                    {
                        score: 3,
                        description:
                            'The individual writes single letters and common words, and with consistent moderate cueing, can write some words that are less familiar, longer, and more complex.',
                    },
                    {
                        score: 4,
                        description:
                            'The individual writes words and phrases related to routine daily activities and words that are less familiar, longer, and more complex. The individual usually requires moderate cueing to write sentences of approximately 5–7 words.',
                    },
                    {
                        score: 5,
                        description:
                            'The individual writes sentence-level material containing some complex words.The individual occasionally requires minimal cueing to write more complex sentences and paragraph-level material. The individual occasionally uses compensatory strategies.',
                    },
                    {
                        score: 6,
                        description:
                            'The individual is successfully able to write most material, but some limitations in writing are still apparent in vocational, avocational, and social activities. The individual rarely requires minimal cueing to write complex material. The individual usually uses compensatory strategies when encountering difficulty.',
                    },
                    {
                        score: 7,
                        description:
                            "The individual's ability to successfully and independently participate in vocational,avocational, and social activities is not limited by writing skills. Independent functioning may occasionally include use of compensatory strategies.",
                    },
                ],
                subOptions: [],
            },
        ];
    }
    get_SPPB_Test() {
        return [
            {
                label: 'Gait Speed Test',
                controlType: 'radio',
                instruction: `person attempts walk a 4-meter course at their usual speed, just as if walking down the street to
        go to the store. And calculate average of 2 attempts.`,
                options: [
                    {
                        score: 0,
                        description: 'Unable to do the walk',
                    },
                    {
                        score: 1,
                        description: 'Time is more than 8.70 sec',
                    },
                    {
                        score: 2,
                        description: 'Time is 6.21 to 8.70 sec',
                    },
                    {
                        score: 3,
                        description: 'Time is 4.82 to 6.20 sec',
                    },
                    {
                        score: 4,
                        description: 'Time is less than 4.82 sec',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Chair Stand Test',
                controlType: 'radio',
                instruction: `person attempts to rise from a chair five times without using their arms.`,
                options: [
                    {
                        score: 0,
                        description: 'Unable to complete 5 chair stands or completes stands in >60 seconds',
                    },
                    {
                        score: 1,
                        description: 'Chair stand time is 16.70 sec or more',
                    },
                    {
                        score: 2,
                        description: 'Chair stand time is 13.70 to 16.69 sec or more',
                    },
                    {
                        score: 3,
                        description: 'Chair stand time is 11.20 to 13.69 sec',
                    },
                    {
                        score: 4,
                        description: 'Chair stand time is 11.19 sec or less',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Standing Balance',
                instruction: '',
                controlType: '',
                options: [],
                subOptions: [
                    {
                        label: 'Side-by-Side Stand',
                        controlType: 'radio',
                        alphabet: 'A',
                        instruction: `person attempts to stand with feet together, side-by-side, for 10 seconds`,
                        options: [
                            {
                                score: 0,
                                description: 'Not held for 10 seconds or not attempted',
                            },
                            {
                                score: 1,
                                description: 'Held for 10 seconds or more',
                            },
                        ],
                    },
                    {
                        label: 'Semi-tandem stand',
                        controlType: 'radio',
                        instruction: `person attempts to stand with the side of the heel of one foot touching the big toe of the
    other foot for 10 seconds.`,
                        alphabet: 'B',
                        options: [
                            {
                                score: 0,
                                description: 'Not held for 10 seconds or not attempted',
                            },
                            {
                                score: 1,
                                description: 'Held for 10 seconds or more',
                            },
                        ],
                    },
                    {
                        label: 'Tandem Stand',
                        controlType: 'radio',
                        instruction: `person attempts to stand with the heel of one foot in front of and touching the toes of the other
            foot for about 10 seconds.`,
                        alphabet: 'C',
                        options: [
                            {
                                score: 0,
                                description: 'Held for <3 seconds or not attempted',
                            },
                            {
                                score: 1,
                                description: 'Held for 3 to 9.99 sec',
                            },
                            {
                                score: 2,
                                description: 'Held for 10 seconds or more',
                            },
                        ],
                    },
                ],
            },
        ];
    }

    get_MASA_Test() {
        return [
            {
                label: 'Alertness',
                controlType: 'radio',
                options: [
                    {
                        score: 2,
                        description: 'No response to speech',
                    },
                    {
                        score: 5,
                        description: 'Difficult to rouse',
                    },
                    {
                        score: 8,
                        description: 'Fluctuates',
                    },
                    {
                        score: 10,
                        description: 'Alert',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Cooperation',
                controlType: 'radio',
                options: [
                    {
                        score: 2,
                        description: 'No cooperation',
                    },
                    {
                        score: 5,
                        description: 'Reluctant',
                    },
                    {
                        score: 8,
                        description: 'Fluctuating cooperation',
                    },
                    {
                        score: 10,
                        description: 'Cooperative',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Auditory Comprehension',
                controlType: 'radio',
                options: [
                    {
                        score: 2,
                        description: 'No response to speech',
                    },
                    {
                        score: 4,
                        description: 'Occasional motor response if cued',
                    },
                    {
                        score: 6,
                        description: 'Follows simple conversation with repetition',
                    },
                    {
                        score: 8,
                        description: 'Follows ordinary conversation with little difficulty',
                    },
                    {
                        score: 10,
                        description: 'NAD',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Respiration',
                controlType: 'radio',
                options: [
                    {
                        score: 2,
                        description: 'Chest infection suctioning',
                    },
                    {
                        score: 4,
                        description: 'Coarse basal crepitations chest physiotherapy',
                    },
                    {
                        score: 6,
                        description: 'Fine basal crepitations',
                    },
                    {
                        score: 8,
                        description: 'Sputum upper airway other condition',
                    },
                    {
                        score: 10,
                        description: 'Chest clear',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Respiratory Rate (for swallow)',
                controlType: 'radio',
                options: [
                    {
                        score: 1,
                        description: 'No independent control',
                    },
                    {
                        score: 3,
                        description: 'Some control/uncoordinated',
                    },
                    {
                        score: 5,
                        description: 'Able to control breath rate for swallow',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Dysphasia',
                controlType: 'radio',
                options: [
                    {
                        score: 1,
                        description: 'Able to assess',
                    },
                    {
                        score: 2,
                        description: 'No functional speech sounds/single words',
                    },
                    {
                        score: 3,
                        description: 'Expresses self in limited manner short phrases/words',
                    },
                    {
                        score: 4,
                        description: 'Mild difficulty finding words or expressing ideas',
                    },
                    {
                        score: 5,
                        description: 'NAD',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Dyspraxia',
                controlType: 'radio',
                options: [
                    {
                        score: 1,
                        description: 'Able to Assess',
                    },
                    {
                        score: 2,
                        description: 'Groping/inaccurate/partial or irrelevant responses',
                    },
                    {
                        score: 3,
                        description: 'Speech crude/defective in accuracy or speed on command',
                    },
                    {
                        score: 4,
                        description: 'Speech accurate after trial and error, minor searching movements',
                    },
                    {
                        score: 5,
                        description: 'NAD',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Dysarthria',
                controlType: 'radio',
                options: [
                    {
                        score: 1,
                        description: 'Able to Assess',
                    },
                    {
                        score: 2,
                        description: 'Speech unintelligible',
                    },
                    {
                        score: 3,
                        description: 'Speech intelligible but obviously defective',
                    },
                    {
                        score: 4,
                        description: 'Slow with occasional hesitation or slurring',
                    },
                    {
                        score: 5,
                        description: 'NAD',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Saliva',
                controlType: 'radio',
                options: [
                    {
                        score: 1,
                        description: 'Gross drool',
                    },
                    {
                        score: 2,
                        description: 'Some drool consistently',
                    },
                    {
                        score: 3,
                        description: 'Drooling at times',
                    },
                    {
                        score: 4,
                        description: 'Frothy/expectorated',
                    },
                    {
                        score: 5,
                        description: 'NAD',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Lip Seal',
                controlType: 'radio',
                options: [
                    {
                        score: 1,
                        description: 'No closure unable to assess',
                    },
                    {
                        score: 2,
                        description: 'Incomplete seal',
                    },
                    {
                        score: 3,
                        description: 'Unilaterally weak poor maintenance',
                    },
                    {
                        score: 4,
                        description: 'Mild impairment occasional leakage',
                    },
                    {
                        score: 5,
                        description: 'NAD',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Tongue Movement',
                controlType: 'radio',
                options: [
                    {
                        score: 2,
                        description: 'No movement',
                    },
                    {
                        score: 4,
                        description: 'Minimal movement',
                    },
                    {
                        score: 6,
                        description: 'Incomplete movement',
                    },
                    {
                        score: 8,
                        description: 'Mild impairment in range',
                    },
                    {
                        score: 10,
                        description: 'Full Rom',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Tongue Strength',
                controlType: 'radio',
                options: [
                    {
                        score: 2,
                        description: 'Gross weakness',
                    },
                    {
                        score: 5,
                        description: 'Unilateral weakness',
                    },
                    {
                        score: 8,
                        description: 'Minimal weakness',
                    },
                    {
                        score: 10,
                        description: 'NAD',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Tongue Coordination',
                controlType: 'radio',
                options: [
                    {
                        score: 2,
                        description: 'No movement unable to assess',
                    },
                    {
                        score: 5,
                        description: 'Gross incoordination',
                    },
                    {
                        score: 8,
                        description: 'Mild incoordination',
                    },
                    {
                        score: 10,
                        description: 'NAD',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Oral Preparation',
                controlType: 'radio',
                options: [
                    {
                        score: 2,
                        description: 'Unable to examine',
                    },
                    {
                        score: 4,
                        description: 'No bolus formation no attempt',
                    },
                    {
                        score: 6,
                        description: 'Minimal chew thrust gravity assisted',
                    },
                    {
                        score: 8,
                        description: 'Lip or tongue seal bolus escape',
                    },
                    {
                        score: 10,
                        description: 'NAD',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Gag',
                controlType: 'radio',
                options: [
                    {
                        score: 1,
                        description: 'No gag',
                    },
                    {
                        score: 2,
                        description: 'Absent unilaterally',
                    },
                    {
                        score: 3,
                        description: 'Diminished unilaterally',
                    },
                    {
                        score: 4,
                        description: 'Diminished bilaterally',
                    },
                    {
                        score: 5,
                        description: 'Hyperreflexia NAD',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Palate',
                controlType: 'radio',
                options: [
                    {
                        score: 2,
                        description: 'No spread or elevation',
                    },
                    {
                        score: 4,
                        description: 'Minimal movement nasal regurgitation/air escape',
                    },
                    {
                        score: 6,
                        description: 'Unilaterally weak',
                    },
                    {
                        score: 8,
                        description: 'Slight asymmetry mobile',
                    },
                    {
                        score: 10,
                        description: 'NAD',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Bolus Clearance',
                controlType: 'radio',
                options: [
                    {
                        score: 2,
                        description: 'No clearance',
                    },
                    {
                        score: 5,
                        description: 'Some clearance/residue',
                    },
                    {
                        score: 8,
                        description: 'Significant clearance',
                    },
                    {
                        score: 10,
                        description: 'fully cleared',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Oral Transit',
                controlType: 'radio',
                options: [
                    {
                        score: 2,
                        description: 'No movement observed',
                    },
                    {
                        score: 4,
                        description: 'Delay > 10 sec',
                    },
                    {
                        score: 6,
                        description: 'Delay > 5 sec',
                    },
                    {
                        score: 8,
                        description: 'Delay > 1 sec',
                    },
                    {
                        score: 10,
                        description: 'NAD',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Cough Reflex',
                controlType: 'radio',
                options: [
                    {
                        score: 1,
                        description: 'None observed/unable to assess',
                    },
                    {
                        score: 3,
                        description: 'Weak reflexive cough',
                    },
                    {
                        score: 5,
                        description: 'NAD',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Voluntary Cough',
                controlType: 'radio',
                options: [
                    {
                        score: 1,
                        description: 'No attempt/unable to assess',
                    },
                    {
                        score: 5,
                        description: 'Attempt inadequate',
                    },
                    {
                        score: 8,
                        description: 'Attempt bovine',
                    },
                    {
                        score: 10,
                        description: 'NAD',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Voice',
                controlType: 'radio',
                options: [
                    {
                        score: 2,
                        description: 'Aphonic unable to assess',
                    },
                    {
                        score: 4,
                        description: 'Wet/gurgling',
                    },
                    {
                        score: 6,
                        description: 'Hoarse',
                    },
                    {
                        score: 8,
                        description: 'Mild impairment slight huskiness',
                    },
                    {
                        score: 10,
                        description: 'NAD',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Trache',
                controlType: 'radio',
                options: [
                    {
                        score: 1,
                        description: 'Trache/cuffed',
                    },
                    {
                        score: 5,
                        description: 'Trache/fenestrated',
                    },
                    {
                        score: 10,
                        description: 'No trache',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Pharyngeal Phase',
                controlType: 'radio',
                options: [
                    {
                        score: 2,
                        description: 'No Swallow unable to assess',
                    },
                    {
                        score: 5,
                        description: 'Pooling/gurgling laryngeal elevation incomplete',
                    },
                    {
                        score: 8,
                        description: 'Laryngeal elevation mildly restricted slow initiation incomplete clearance',
                    },
                    {
                        score: 10,
                        description: 'Immediate laryngeal elevation clearance of material',
                    },
                ],
                subOptions: [],
            },
            {
                label: 'Pharyngeal Response',
                controlType: 'radio',
                options: [
                    {
                        score: 1,
                        description: 'Not coping/gurgling',
                    },
                    {
                        score: 5,
                        description: 'Cough before/during/after swallow',
                    },
                    {
                        score: 10,
                        description: 'NAD',
                    },
                ],
                subOptions: [],
            },
        ];
    }

    get_PASS_Test() {
        return [
            {
                sectionHeading: 'Maintaining a Posture',
                label: '',
                instruction: '',
                controlType: '',
                options: [],
                subOptions: [
                    {
                        label: 'Sitting Without Support ',
                        instruction:
                            'Examiner: Have the subject sit on a bench/mat without back support and with feet flat on the floor.',
                        controlType: 'radio',
                        alphabet: '1',
                        hideScore: false,
                        options: [
                            {
                                score: 3,
                                description: 'Can sit for 5 minutes without support',
                            },
                            {
                                score: 2,
                                description: 'Can sit for more than 10 seconds without support',
                            },
                            {
                                score: 1,
                                description: 'Can sit with slight support(for example, by 1 hand)',
                            },
                            {
                                score: 0,
                                description: 'Cannot sit',
                            },
                        ],
                    },
                    {
                        label: 'Standing With Support',
                        instruction: `Examiner: Have the subject stand, providing support as needed. Evaluate only the ability to stand with or without support. Do not consider the quality of the stance`,
                        controlType: 'radio',
                        alphabet: '2',
                        options: [
                            {
                                score: 3,
                                description: 'Can stand with support of only 1 hand',
                            },
                            {
                                score: 2,
                                description: 'Can stand with moderate support of 1 person',
                            },
                            {
                                score: 1,
                                description: 'Can stand with strong support of 2 people',
                            },
                            {
                                score: 0,
                                description: 'Cannot stand, even with support',
                            },
                        ],
                    },
                    {
                        label: 'Standing Without Support',
                        instruction: `Examiner: Have the subject stand without support. Evaluate only the ability to stand with or without support. Do not consider the quality of the stance`,
                        controlType: 'radio',
                        alphabet: '3',
                        options: [
                            {
                                score: 3,
                                description:
                                    'Can stand without support for more than 1 minute and simultaneously perform arm movements at about shoulder level',
                            },
                            {
                                score: 2,
                                description: 'Can stand without support for 1 minute or stands slightly asymmetrically',
                            },
                            {
                                score: 1,
                                description: 'Can stand without support for 10 seconds or leans heavily on 1 leg',
                            },
                            {
                                score: 0,
                                description: 'Cannot stand without support',
                            },
                        ],
                    },
                    {
                        label: 'Standing on Nonparetic Leg',
                        instruction: `Examiner: Have the subject stand on the nonparetic leg. Evaluate only the ability to bear weight entirely on the nonparetic leg. Do not consider how the subject accomplishes the task`,
                        controlType: 'radio',
                        alphabet: '4',
                        options: [
                            {
                                score: 3,
                                description: 'Can stand on nonparetic leg for more than 10 seconds',
                            },
                            {
                                score: 2,
                                description: 'Can stand on nonparetic leg for more than 5 seconds',
                            },
                            {
                                score: 1,
                                description: 'Can stand on nonparetic leg for a few seconds',
                            },
                            {
                                score: 0,
                                description: 'Cannot stand on nonparetic leg',
                            },
                        ],
                    },

                    {
                        label: 'Standing on Paretic Leg',
                        instruction: `Examiner: Have the subject stand on the paretic leg. Evaluate only the ability to bear weight entirely on the paretic leg. Do not consider how the subject accomplishes the task`,
                        controlType: 'radio',
                        alphabet: '5',
                        options: [
                            {
                                score: 3,
                                description: 'Can stand on paretic leg for more than 10 seconds',
                            },
                            {
                                score: 2,
                                description: 'Can stand on paretic leg for more than 5 seconds',
                            },
                            {
                                score: 1,
                                description: 'Can stand on paretic leg for a few seconds',
                            },
                            {
                                score: 0,
                                description: 'Cannot stand on paretic leg',
                            },
                        ],
                    },
                ],
            },

            {
                sectionHeading: 'Changing a Posture',
                label: '',
                instruction: '',
                controlType: '',
                options: [],
                subOptions: [
                    {
                        label: 'Supine to Paretic Side Lateral',
                        instruction: `Examiner: Begin with the subject in supine on a treatment mat. Instruct the subject to roll to the paretic side (lateral movement). Assist as necessary. Evaluate the subject's performance on the amount of help required. Do not consider the quality of performance.`,
                        controlType: 'radio',
                        alphabet: '6',
                        hideScore: false,
                        options: [
                            {
                                score: 3,
                                description: 'Can perform without help',
                            },
                            {
                                score: 2,
                                description: 'Can perform with little help',
                            },
                            {
                                score: 1,
                                description: 'Can perform with much help',
                            },
                            {
                                score: 0,
                                description: 'Cannot perform',
                            },
                        ],
                    },
                    {
                        label: 'Supine to Nonparetic Side Lateral',
                        instruction: `Examiner: Begin with the subject in supine on a treatment mat. Instruct the subject to roll to the nonparetic side(lateral movement). Assist as necessary. Evaluate the subject's performance on the amount of help required.Do not consider the quality of performance.`,
                        controlType: 'radio',
                        alphabet: '7',
                        options: [
                            {
                                score: 3,
                                description: 'Can perform without help',
                            },
                            {
                                score: 2,
                                description: 'Can perform with little help',
                            },
                            {
                                score: 1,
                                description: 'Can perform with much help',
                            },
                            {
                                score: 0,
                                description: 'Cannot perform',
                            },
                        ],
                    },
                    {
                        label: 'Supine to Sitting Up on the Edge of the Mat',
                        instruction: `Examiner: Begin with the subject in supine on a treatment mat. Instruct the subject to come to sitting on the edge of the mat. Assist as necessary. Evaluate the subject's performance on the amount of help required. Do not consider the quality of performance.`,
                        controlType: 'radio',
                        alphabet: '8',
                        options: [
                            {
                                score: 3,
                                description: 'Can perform without help',
                            },
                            {
                                score: 2,
                                description: 'Can perform with little help',
                            },
                            {
                                score: 1,
                                description: 'Can perform with much help',
                            },
                            {
                                score: 0,
                                description: 'Cannot perform',
                            },
                        ],
                    },
                    {
                        label: 'Sitting on the Edge of the Mat to Supine',
                        instruction: `Examiner: Begin with the subject sitting on the edge of a treatment mat. Instruct the subject to return to supine. Assist if necessary. Evaluate the subject's performance on the amount of help required. Do not consider the quality of performance.`,
                        controlType: 'radio',
                        alphabet: '9',
                        options: [
                            {
                                score: 3,
                                description: 'Can perform without help',
                            },
                            {
                                score: 2,
                                description: 'Can perform with little help',
                            },
                            {
                                score: 1,
                                description: 'Can perform with much help',
                            },
                            {
                                score: 0,
                                description: 'Cannot perform',
                            },
                        ],
                    },
                    {
                        label: 'Sitting to Standing Up',
                        instruction: `Examiner: Begin with the subject sitting on the edge of a treatment mat. Instruct the subject to stand up without support. Assist if necessary. Evaluate the subject's performance on the amount of help required. Do not consider the quality of performance.`,
                        controlType: 'radio',
                        alphabet: '10',
                        options: [
                            {
                                score: 3,
                                description: 'Can perform without help',
                            },
                            {
                                score: 2,
                                description: 'Can perform with little help',
                            },
                            {
                                score: 1,
                                description: 'Can perform with much help',
                            },
                            {
                                score: 0,
                                description: 'Cannot perform',
                            },
                        ],
                    },

                    {
                        label: 'Standing Up to Sitting Down',
                        instruction: `Examiner: Begin with the subject standing by the edge of a treatment mat. Instruct the subject to sit on edge of mat without support. Assist if necessary. Evaluate the subject's performance on the amount of help required. Do not consider the quality of performance.`,
                        controlType: 'radio',
                        alphabet: '11',
                        options: [
                            {
                                score: 3,
                                description: 'Can perform without help',
                            },
                            {
                                score: 2,
                                description: 'Can perform with little help',
                            },
                            {
                                score: 1,
                                description: 'Can perform with much help',
                            },
                            {
                                score: 0,
                                description: 'Cannot perform',
                            },
                        ],
                    },
                    {
                        label: 'Sitting, Picking Up a Pencil from the Floor',
                        instruction: `Examiner: Begin with the subject standing. Instruct the subject to pick up a pencil from the floor without support. Assist if necessary. Evaluate the subject's performance on the amount of help required. Do not consider the quality of performance.`,
                        controlType: 'radio',
                        alphabet: '12',
                        options: [
                            {
                                score: 3,
                                description: 'Can perform without help',
                            },
                            {
                                score: 2,
                                description: 'Can perform with little help',
                            },
                            {
                                score: 1,
                                description: 'Can perform with much help',
                            },
                            {
                                score: 0,
                                description: 'Cannot perform',
                            },
                        ],
                    },
                ],
            },
        ];
    }

    get_BERG_BALANCE_Test_Interpretation(score: number): string {
        if (score >= 41 && score >= 56) return 'independent';
        else if (score >= 21 && score <= 40) return 'walking with assistance';
        else if (score >= 0 && score <= 20) return 'wheelchair bound';
        /*if (score >= 45) return 'Patient is mostly independent in their movement and carries a low risk of falling';
        else if (score >= 41 && score <= 44)
            return 'Patient is mostly independent in their movement but carries a significant risk of falling';
        else if (score >= 21 && score <= 40)
            return 'Patient may require assistance performing some of the tasks in the balance test and in general, activities of daily living. There is a 100% fall risk';
        else if (score >= 0 && score <= 20)
            return 'The patient is wheelchair bound at the moment or may be in the future and carries a 100% fall risk';*/
    }
    get_DGI_Test_Interpretation(score: number): string {
        if (score >= 0 && score <= 19) return 'predictive of falls in the elderly';
        else if (score > 19) return 'safe ambulators';
    }
    get_TINETTI_ASSESSMENT_Test_Interpretation(score: number): string {
        if (score >= 24) return 'Low fall risk ';
        else if (score >= 19 && score <= 23) return 'Moderate fall risk';
        else if (score >= 0 && score <= 18) return 'High fall risk';
    }
    get_TIME_UP_AND_GO_TUG_Test_Interpretation(score: number): string {
        if (score > 13.5) return 'High fall risk';
        else if (score >= 0 && score <= 13.5) return 'None/low/moderate fall risk';
        // if (score >= 21) return 'normal';
        // else if (score >= 11 && score <= 20) return 'good mobility, can go out alone, mobile without a gait aid.';
        // else if (score >= 0 && score <= 10) return 'problems, cannot go outside alone, requires a gait aid.';
    }
    get_BRIEF_INTERVIEW_FOR_MENTAL_STATUS_Test_Interpretation(score: number): string {
        if (score >= 13) return 'Intact cognitive response';
        else if (score >= 8 && score <= 12) return 'Moderate impairment';
        else if (score >= 0 && score <= 7) return 'Severe cognitive impact';
    }
    get_PHQ9_Test_Interpretation(score: number): string {
        if (score >= 20) return 'Severe Depression';
        else if (score >= 15 && score <= 19) return 'Moderately Severe Depression';
        else if (score >= 10 && score <= 14) return 'Moderate Depression';
        else if (score >= 5 && score <= 9) return 'Mild Depression';
        else if (score >= 0 && score <= 4) return 'None-minimal Depression';
    }
    get_BARTHEL_INDEX_Test_Interpretation(score: number): string {
        // multiply score to 5
        //score = score * 5;
        if (score >= 80) return 'Patient should be able to live independently';
        else if (score >= 60 && score <= 79) return 'The Patient is minimally dependent';
        else if (score >= 40 && score <= 59) return 'The Patient is partially dependent';
        else if (score >= 20 && score <= 39) return 'The Patient is very dependent';
        else if (score >= 0 && score <= 19) return 'The Patient is totally dependent';
    }
    get_SPPB_Test_Interpretation(score: number): string {
        if (score >= 10) return 'Patient have no sarcopenia but no mobility disability indicates robustness';
        else if (score >= 3 && score <= 9)
            return 'Patient may have sarcopenia but no mobility disability indicates frailty';
        else if (score >= 0 && score <= 2)
            return 'The patient have sarcopenia, potential cachexia, and mobility disability';
    }

    get_Functional_Oral_Intake_Test_Interpretation(score: number): string {
        if (score == 1 || score == 2) return 'Profound deficit';
        else if (score == 3) return 'Severe deficit';
        else if (score == 4) return 'Moderate deficit';
        else if (score == 5) return 'Mild deficit';
        else if (score == 6) return 'Minimal deficit';
        else if (score == 7) return 'None deficit';
    }
    get_MASA_Test_Interpretation(score: number): string {
        let dysphagiaInterpretation = '',
            aspirationInterpretation = '';
        //dysphagia
        if (score <= 138) dysphagiaInterpretation = '1 - Severe dysphagia. \n';
        else if (score >= 139 && score <= 167) dysphagiaInterpretation = `1 - Moderate dysphagia. \n`;
        else if (score >= 168 && score <= 177) dysphagiaInterpretation = `1 - Mild dysphagia. \n`;
        else if (score >= 178) dysphagiaInterpretation = '1 - No abnormality. \n';
        //aspiration
        if (score <= 140) aspirationInterpretation = `2 - Severe aspiration.`;
        else if (score >= 141 && score <= 148) aspirationInterpretation = `2 - Moderate aspiration.`;
        else if (score >= 149 && score <= 169) aspirationInterpretation = `2 - Mild aspiration.`;
        else if (score >= 170) aspirationInterpretation = `2 - No abnormality.`;

        return dysphagiaInterpretation + aspirationInterpretation;
    }
    get_Dysphagia_Outcome_and_Severity_Scale_Test_Interpretation(score: number): string {
        switch (score) {
            case 1:
                return '(Unstable medical condition due to severe saliva aspiration) or (Tube feeding or gastrostoma. Difficulty with direct therapy)';
            case 2:
                return '(Food aspiration with no effect from compensatory techniques or food consistency changes) or (Tube feeding or gastrostoma. Directs therapy in a professional medical organization)';
            case 3:
                return '(Aspiration of thin liquids; change in food consistency is effective) or (Dysphagia diet, thick liquids, or use of intermittent oral catheterization. Direct therapy in the hospital or at home)';
            case 4:
                return '(Possible aspiration or aspiration is suspected due to pharyngeal residue) or (Dysphagia diet, regular diet, or use of intermittent oral catheterization. Direct therapy in the hospital or at home)';
            case 5:
                return '(Significant symptoms in the pre-oral anticipatory stage or oral stage without aspiration) or (Softened rice and food or paste food. Direct therapy in the hospital or at home)';
            case 6:
                return '(Some symptoms of dysphagia but no need for rehabilitation or exercise) or (Softened rice and food, direct therapy if necessary)';
            case 7:
                return '(No symptoms of dysphagia) or (Regular diet)';
        }
    }
    get_SLUMS_Test_Interpretation(score: number, higherEducation: number): string {
        let slumsInterpretation = '';
        if (higherEducation == 1) {
            if (score > 0 && score <= 20) slumsInterpretation = 'Dementia';
            else if (score > 20 && score <= 26) slumsInterpretation = 'Mild Neurocognitive Disorder (MNCD)';
            else if (score > 27 && score <= 30) slumsInterpretation = 'Normal';
        } else if (higherEducation == 0) {
            if (score > 0 && score <= 19) slumsInterpretation = 'Dementia';
            else if (score > 19 && score <= 24) slumsInterpretation = 'Mild Neurocognitive Disorder (MNCD)';
            else if (score > 24 && score <= 30) slumsInterpretation = 'Normal';
        }
        return slumsInterpretation;
    }
    generateRemainingControls(fields: any) {
        const formControls = {};
        fields.forEach((field) => {
            formControls[field.label] = new FormControl(field.value || '', Validators.required);
        });
        return formControls;
    }
    toFormGroup(fields: any) {
        const group: any = {};

        fields.forEach((field) => {
            // if (field.options?.length > 0 && field.subOptions?.length > 0) {
            //     if (field.controlType != 'readOnly') {
            //         group[field.label] = new FormControl(field.value || '', Validators.required);
            //     }
            //     if (field.subOptions.length > 0) {
            //         field.subOptions.forEach((subField) => {
            //             group[subField.label] = new FormControl(subField.value || '', Validators.required);
            //         });
            //     }
            // } else {
            if (field.subOptions.length > 0) {
                field.subOptions.forEach((subField) => {
                    group[field.label + subField.label] = new FormControl(subField.value || '', Validators.required);
                });
            } else {
                if (field.controlType != 'readOnly') {
                    group[field.label] = new FormControl(field.value || '', Validators.required);
                }
            }
            //}
        });
        return new FormGroup(group);
    }

    saveStandardTestsData(result, previousName?: string, isEditMode = '') {
        const body = {
            name: result.name,
            note: result.note,
            score: result.score,
            interpretation: result.interpretation,
            isCompleted: result.isCompleted,
            questionnaire: result.questionnaire,
            isAGoal: false,
            scale: {
                scaleType: 'input',
                assessmentType: 'standardizedTests',
                scaleDetails: {
                    name: null,
                    placeholder: result.placeholder,
                    minValue: result.minValue,
                    maxValue: result.maxValue,
                    scaleInterpretation: result.scaleInterpretation,
                },
            },
            clof: { levelOfFunction: result.score },
        };
        // this.patientAssessmentService.$addStandardizedTest.next(body);
        let standardTest;
        if (previousName) {
            standardTest = this.patientAssessmentService
                .getAssessmentPath(this.currentAdmission, 'standardizedTests')
                .findIndex((fd) => fd.name === `${previousName} (Custom)`);
        } else {
            standardTest = this.patientAssessmentService
                .getAssessmentPath(this.currentAdmission, 'standardizedTests')
                .findIndex((fd) => fd.name === result.name);
        }
        if (standardTest !== -1) {
            // Check if item exists and if so update it
            const existingItem = this.patientAssessmentService.getAssessmentPath(
                this.currentAdmission,
                'standardizedTests'
            )[standardTest];
            // Copy previous essential values. Other values exist in body
            body['_id'] = existingItem._id;
            body.scale = existingItem.scale;
            body.isAGoal = existingItem.isAGoal;
            // Update it
            this.patientAssessmentService.getAssessmentPath(this.currentAdmission, 'standardizedTests')[standardTest] =
                body;
            // Check if it's a goal reflect changes in that as well
            if (body.isAGoal)
                this.patientAssessmentService.reflectChangesInGoal(this.currentAdmission, body['_id'], body.clof);
        } else {
            body['_id'] = uuidv4();
            this.patientAssessmentService.getAssessmentPath(this.currentAdmission, 'standardizedTests').push(body);
        }

        if (isEditMode) {
            this.currentAdmission.documentation.evaluation.patientAssessment.standardizedTests =
                this.patientAssessmentService.getAssessmentPath(this.currentAdmission, 'standardizedTests');
            this.evaluationService.prepareDataForEdit(
                'patientAssessment',
                'standardizedTests',
                this.patientAssessmentService.getAssessmentPath(this.currentAdmission, 'standardizedTests'),
                'array',
                JSON.parse(JSON.stringify(this.allAdmissions)),
                this.currentAdmission
            );
        } else {
            this.patientAssessmentService.updateDocBasedOnNote(this.currentAdmission, this.note);
        }
    }

    openTest(test: string, testDetails?: any, isEditMode = '') {
        return new Promise((resolve) => {
            const indexOfTest = Object.values(STANDARDIZED_TESTS).indexOf(test as unknown as STANDARDIZED_TESTS);
            const functionName = 'get_' + Object.keys(STANDARDIZED_TESTS)[indexOfTest] + '_Test';
            let formData = this.patientAssessmentService.getAssessmentPath(this.currentAdmission, 'standardizedTests');
            formData = formData?.find((x) => x.name === test);

            switch (test) {
                case STANDARDIZED_TESTS.BERG_BALANCE:
                case STANDARDIZED_TESTS.DGI:
                case STANDARDIZED_TESTS.TINETTI_ASSESSMENT:
                case STANDARDIZED_TESTS.TIME_UP_AND_GO_TUG:
                case STANDARDIZED_TESTS.BRIEF_INTERVIEW_FOR_MENTAL_STATUS:
                case STANDARDIZED_TESTS.BARTHEL_INDEX:
                case STANDARDIZED_TESTS.PHQ9:
                case STANDARDIZED_TESTS.NOMS_MEMORY:
                case STANDARDIZED_TESTS.NOMS_MOTOR_SPEECH:
                case STANDARDIZED_TESTS.NOMS_SPOKEN_LANGUAGE:
                case STANDARDIZED_TESTS.NOMS_SWALLOWING:
                case STANDARDIZED_TESTS.SPPB:
                case STANDARDIZED_TESTS.Functional_Oral_Intake:
                case STANDARDIZED_TESTS.Dysphagia_Outcome_and_Severity_Scale:
                case STANDARDIZED_TESTS.MASA:
                case STANDARDIZED_TESTS.SLUMS:
                case STANDARDIZED_TESTS.PASS:
                    this.router.navigate(['documentation/patient-assessment/standardized-tests/detail'], {
                        state: {
                            heading: test,
                            data: this[functionName](),
                            formData: formData,
                            testMaxValue: testDetails.maxValue,
                            scaleInterpretation: testDetails.scaleInterpretation,
                            isEditMode,
                        },
                        queryParamsHandling: 'preserve',
                    });
                    break;
                default: {
                    const testScore = this.patientAssessmentService
                        .getAssessmentPath(this.currentAdmission, 'standardizedTests')
                        .find((x) => x.name === test)?.score;
                    const testNote = this.patientAssessmentService
                        .getAssessmentPath(this.currentAdmission, 'standardizedTests')
                        .find((x) => x.name === test)?.note;
                    const testInterpretation = this.patientAssessmentService
                        .getAssessmentPath(this.currentAdmission, 'standardizedTests')
                        .find((x) => x.name === test)?.interpretation;
                    if (test.includes('(Custom)')) {
                        this.modalService.open(CustomTestModalComponent, {
                            data: {
                                toUpdate: true,
                                heading: test.split(' (')[0],
                                interpretation: testInterpretation,
                                score: testScore,
                                notes: testNote,
                                isEditMode,
                            },
                        });
                    } else {
                        this.modalService
                            .open(StandardTestFormComponent, {
                                data: {
                                    heading: test,
                                    formData: testScore ? { testScore } : undefined,
                                    note: testNote ? { testNote } : undefined,
                                    isEditMode,
                                },
                            })
                            .afterClosed()
                            .subscribe((result) => {
                                if (result) {
                                    resolve(true);
                                }
                            });
                    }
                    break;
                }
            }
        });
    }

    openMDSTest(test: string) {
        return new Promise((resolve) => {
            const indexOfTest = Object.values(STANDARDIZED_TESTS).indexOf(test as unknown as STANDARDIZED_TESTS);
            const functionName = 'get_' + Object.keys(STANDARDIZED_TESTS)[indexOfTest] + '_Test';
            switch (test) {
                case STANDARDIZED_TESTS.BRIEF_INTERVIEW_FOR_MENTAL_STATUS:
                case STANDARDIZED_TESTS.PHQ9:
                    this.router.navigate(['documentation/patient-assessment/standardized-tests/detail'], {
                        state: {
                            heading: test,
                            data: this[functionName](),
                            tab: 'mds',
                            formData: undefined,
                        },
                        queryParamsHandling: 'preserve',
                    });
                    break;
                default: {
                    this.modalService
                        .open(StandardTestFormComponent, {
                            data: {
                                heading: test,

                                formData: undefined,
                            },
                        })
                        .afterClosed()
                        .subscribe((result) => {
                            if (result) {
                                resolve(true);
                            }
                        });
                    break;
                }
            }
        });
    }

    addTotalsInStandardizedTestsData(stData): any[] {
        const systemSupportedSTs = this.getStandardizedTests().filter((elem) => elem.systemSupported);

        const _stData = cloneDeep(stData);
        // Attach Total Scores for only 'System Supported' STs
        _stData.forEach((ST) => {
            if (systemSupportedSTs.find((SST) => SST.text === ST.name)) {
                const indexOfTest = Object.values(STANDARDIZED_TESTS).indexOf(ST.name as unknown as STANDARDIZED_TESTS);

                // Test Name to Service Function Name Mapping
                const functionName = 'get_' + Object.keys(STANDARDIZED_TESTS)[indexOfTest] + '_Test';

                ST.totalScore = this.calculateTotal(this[functionName]());

                // custom requirement for 'Barthel Index' to amplify score 5 times
                if (ST.name === STANDARDIZED_TESTS.BARTHEL_INDEX) {
                    ST.totalScore = ST.totalScore * 5;
                }
                if (ST.name === STANDARDIZED_TESTS.PHQ9) {
                    ST.totalScore = 27;
                }
            }
            // Add N/A for those where record is not
            if (ST.interpretation == '') {
                ST.interpretation = 'N/A';
            }
            // Add N/A for those where record is not
            if (ST.note == '') {
                ST.note = 'N/A';
            }
            // Add N/A for those where record is not
            if (ST.note == null) {
                ST.note = 'N/A';
            }
        });

        return _stData;
    }
}
