import { Directive, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';
@Directive({
    selector: '[outsideClickEvent]',
})
export class ClickOutsideDirective {
    constructor(private _elementRef: ElementRef) {}

    // Returns true if click event happens outside of the element using this binding
    @Output() outsideClickEvent: EventEmitter<boolean> = new EventEmitter();

    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement) {
        const clickedInside = this._elementRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
            this.outsideClickEvent.emit(clickedInside);
        }
    }
}
