import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import {
    FacilityManagementService,
    OrganizationManagementService,
    RecipientService,
    ToasterService,
} from '@app/services';
import { first } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FACILITY_NAV_ITEMS } from '@app/modules/billing/eom-billing/constants';
@Component({
    selector: 'app-add-recipient-portal',
    templateUrl: './add-recipient-portal.component.html',
    styleUrls: ['./add-recipient-portal.component.scss'],
})
export class AddRecipientPortalComponent implements OnInit {
    newUserForm: FormGroup;
    reportingPreferences: FormGroup;
    facilityId: string;
    recipients = [];
    editMode = false;
    recipientId: string;
    currentRecipient: any;
    constructor(
        private fb: FormBuilder,
        private location: Location,
        private toasterService: ToasterService,
        private recipientService: RecipientService,
        private facilityService: FacilityManagementService,
        private route: ActivatedRoute,
        private organizationManagement: OrganizationManagementService,
    ) {}

    ngOnInit(): void {
        this.newUserForm = this.fb.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            title: [''],
            email: ['', [Validators.required, Validators.email]],
        });
        this.reportingPreferences = this.fb.group({
            invoiceForSelectedFacility: [false],
            serviceLogs: [false],
            BillingExport: [false],
            UBO4Reports: [false],
        });
        this.recipients = this.facilityService.getCurrentFacilityRecipients();
        this.route.queryParamMap.subscribe({
            next: (params) => {
                this.facilityId = params.get('id');
                this.recipientId = params.get('recipientId');
                if (this.recipientId) {
                    this.recipientService.getRecipientById(this.recipientId).subscribe((recipients) => {
                        this.currentRecipient = recipients?.data;
                        this.patchValues(recipients?.data);
                    });
                }
            },
        });
    }
    patchValues(data: any) {
        this.editMode = true;
        this.newUserForm.patchValue(data);
        this.reportingPreferences.patchValue(data.reportingPreferences);
    }
    AddNewRecipient() {
        if (this.newUserForm.valid) {
            const recipientObject = {
                ...this.newUserForm.value,
                reportingPreferences: this.reportingPreferences.value,
                organizationId: this.organizationManagement._currentOrganization.id,
                facilityId: this.facilityId
            };
            if (!this.editMode) {
                this.recipientService
                    .createRecipientProfile(recipientObject)
                    .pipe(first())
                    .subscribe({
                        next: (result: any) => {
                            if (result.data && result.data.success) {
                                if(!result?.data?.result?.isEmailSoftDeleted){
                                this.recipients.push(result.data.result);
                                this.facilityService
                                    .updateRecipientInFacility({
                                        facilityIds: [this.facilityId],
                                        recipientIds: this.recipients.map((recipient) => recipient._id),
                                    })
                                    .pipe(first())
                                    .subscribe((result: any) => {
                                        this.recipientService.updateRecipientInFacility(result);
                                        if (result) {
                                            this.toasterService.show({
                                                title: 'success',
                                                body: 'Recipient Successfully Created.',
                                                type: 'success',
                                            });
                                        }
                                       this.goBack();
                                    });
                                }else{
                                    this.toasterService.show({
                                        title: 'success',
                                        body: 'Recipient Successfully Created.',
                                        type: 'success',
                                    });
                                    this.goBack();
                                }
                            } else {
                                this.toasterService.show({
                                    title: 'Error',
                                    body: result.message,
                                    type: 'error',
                                });
                            }
                        },
                        error: (error) => {
                            this.toasterService.show({
                                title: 'Error',
                                body: error,
                                type: 'error',
                            });
                        },
                    });
            } else {
                this.recipientService
                    .updateRecipientProfile(this.currentRecipient._id, recipientObject)
                    .pipe(first())
                    .subscribe({
                        next: (result: any) => {
                            if (result && (result.data.success)) {
                                this.toasterService.show({
                                    title: 'success',
                                    body: 'Recipient Updated',
                                    type: 'success',
                                });
                                this.goBack();
                            } else {
                                this.toasterService.show({
                                    title: 'Error',
                                    body: result.message,
                                    type: 'error',
                                });
                            }
                        },
                        error: (error) => {
                            this.toasterService.show({
                                title: 'Error',
                                body: error,
                                type: 'error',
                            });
                        },
                    });
            }
        } else {
            this.toasterService.show({
                title: 'Error',
                body: 'All Fields are required.',
                type: 'error',
            });
        }
    }
    goBack() {
        this.organizationManagement.updatedTab = FACILITY_NAV_ITEMS.RECIPIENTS;
        this.location.back();
    }
}
