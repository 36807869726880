<div class="document" *ngFor="let doc of docs">
    <div
        (onafterprint)="afterPrint()"
        class="row mt-5 mb-5"
        *ngIf="printMode"
        style="height: 100px; margin: 0 auto; width: 93%"
    >
        <div class="col-9">
            <div><b> Name:</b> {{ doc.printData.therapist }}</div>
            <div><b> Discipline:</b> {{ doc.printData.discipline }}</div>
            <div><b> Note:</b> {{ doc.printData.type }}</div>
            <div><b> Due Date:</b> {{ doc.printData.dueDate | date: 'MM/dd/yy' }}</div>
            <div><b> Completion Date:</b> {{ doc.printData.completionDate }}</div>
        </div>
        <div class="col-3">
            <img src="../../../../assets/logo/logo.svg" />
        </div>
    </div>

    <ng-container [ngSwitch]="doc.docType">
        <ng-container *ngSwitchCase="'Evaluation'">
            <ng-container *ngTemplateOutlet="evaluation; context: doc"></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="'Daily'">
            <ng-container *ngTemplateOutlet="dailyNote; context: doc"></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="'UPOC'">
            <ng-container *ngTemplateOutlet="UPOC; context: doc"></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="'Progress'">
            <ng-container *ngTemplateOutlet="progressNote; context: doc"></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="'Recert'">
            <ng-container *ngTemplateOutlet="recertNote; context: doc"></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="'Addendum'">
            <ng-container *ngTemplateOutlet="addendumNote; context: doc"></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="'Discharge'">
            <ng-container *ngTemplateOutlet="dischargeNote; context: doc"></ng-container>
        </ng-container>
    </ng-container>
</div>

<ng-template #evaluation let-printData="printData">
    <app-eval-doc [printData]="printData" [printMode]="true"></app-eval-doc>
</ng-template>

<ng-template #dailyNote let-printData="printData">
    <app-daily-notes [printData]="printData" [printMode]="true"></app-daily-notes>
</ng-template>

<ng-template #UPOC let-printData="printData">
    <app-update-plane-of-care [printData]="printData" [printMode]="true"></app-update-plane-of-care>
</ng-template>

<ng-template #progressNote let-printData="printData">
    <app-view-mod-progress-note [printData]="printData" [printMode]="true"></app-view-mod-progress-note>
</ng-template>

<ng-template #recertNote let-printData="printData">
    <app-recertification [printData]="printData" [printMode]="true"></app-recertification>
</ng-template>

<ng-template #addendumNote let-printData="printData">
    <app-addendum [printData]="printData" [printMode]="true"></app-addendum>
</ng-template>

<ng-template #dischargeNote let-printData="printData">
    <app-discharge-note [printData]="printData" [printMode]="true"></app-discharge-note>
</ng-template>
