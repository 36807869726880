<div class="container">
    <div>
        <h5>Reactive Examples</h5>
    </div>

    <form [formGroup]="form">
        <div class="row">
            <div class="col-4">
                <app-input placeholder="Type here" type="text" label="Input" formControlName="firstName"></app-input>
                <!-- <div *ngIf="f.firstName.errors">
          <div *ngIf="f.firstName.errors.required">First Name is required</div>
        </div> -->
            </div>

            <app-select class="col-4" label="Select" formControlName="title" [options]="titles"></app-select>
            <app-date-picker class="col-4" label="Datepicker" formControlName="date"></app-date-picker>
            <app-radio-button class="col-6" label="Radio" formControlName="gender" [options]="radioOptions">
            </app-radio-button>
        </div>
    </form>

    <br />
    <div>
        <h5>Template Driven Examples</h5>
    </div>

    <div class="row">
        <app-input class="col-4" label="Input" placeholder="Type here" [(ngModel)]="exampleInputValue" required="true">
        </app-input>
        <app-select class="col-4" label="Select" [(ngModel)]="exampleSelectValue" [options]="titles"></app-select>
        <app-date-picker class="col-4" label="Datepicker" placeholder="MM/DD/YYYY" [(ngModel)]="exampleDateValue">
        </app-date-picker>
    </div>

    <br />
    <div>
        <h5>Result..</h5>
        {{ form.value | json }}
        {{ exampleInputValue }}
        {{ exampleSelectValue }}
        {{ exampleDateValue }}
        {{ exampleRadioValue }}
    </div>
    <!-- Search -->
    <h1>Search fields</h1>
    <div class="form-group">
        <app-search-bar type="search" placeholder="Search Directory"></app-search-bar>
    </div>
    <div class="form-group">
        <app-search-bar type="filter" placeholder="Search Directory"></app-search-bar>
    </div>
    <!-- Toggles -->
    <h1>Toggles and Tabs</h1>
    <!-- Steps Tab -->
    <app-steps-tab
        [tabs]="[
            { title: 'Personal Information', highlight: true, isDone: false },
            { title: 'Facility Admission', highlight: false, isDone: false }
        ]"
    ></app-steps-tab>
    <!-- Other -->
    <div class="mt-5"></div>
    <!-- <app-toggle [_buttons]="['01 Tab', '02 Tab', '03 Tab', '04 Tab', '05 Tab']" selectedButton="01 Tab"></app-toggle> -->
    <div class="mt-2"></div>
    <div class="d-flex flex-row align-items-center justify-content-start">
        <span class="body-medium-semibold">Filter By: </span>
        <app-tab-small class="ml-4" [_buttons]="['OT', 'PT', 'ST']" selectedButton="OT"></app-tab-small>
    </div>
    <!-- Alerts -->
    <h1>Alerts</h1>
    <app-permanent-alert type="warning" message="you got a warning"></app-permanent-alert>
    <app-permanent-alert type="info" message="you got to know something important"></app-permanent-alert>
    <app-permanent-alert type="danger" message="Something went wrong"></app-permanent-alert>
    <app-permanent-alert type="success" message="You did great!"></app-permanent-alert>

    <!-- Buttons -->
    <!-- Left Icon -->
    <h1>Buttons - Left Icons</h1>
    <h5>Small Buttons</h5>
    <app-button type="primary" text="Default Button" size="sm" icon="expand_more" class="ml-1 mt-2"></app-button>
    <app-button type="secondary" text="Default Button" size="sm" icon="expand_more" class="ml-1 mt-2"></app-button>
    <app-button type="warning" text="Default Button" size="sm" icon="expand_more" class="ml-1 mt-2"></app-button>
    <app-button type="danger" text="Default Button" size="sm" icon="expand_more" class="ml-1 mt-2"></app-button>
    <app-button type="info" text="Default Button" size="sm" icon="expand_more" class="ml-1 mt-2"></app-button>
    <app-button
        type="info"
        text="Default Button"
        size="sm"
        icon="expand_more"
        class="ml-1 mt-2"
        [disabled]="true"
    ></app-button>
    <h5>Medium Buttons</h5>
    <app-button type="primary" text="Default Button" icon="expand_more" class="ml-1 mt-2"></app-button>
    <app-button type="secondary" text="Default Button" icon="expand_more" class="ml-1 mt-2"></app-button>
    <app-button type="warning" text="Default Button" icon="expand_more" class="ml-1 mt-2"></app-button>
    <app-button type="danger" text="Default Button" icon="expand_more" class="ml-1 mt-2"></app-button>
    <app-button type="info" text="Default Button" icon="expand_more" class="ml-1 mt-2"></app-button>
    <app-button type="info" text="Default Button" icon="expand_more" class="ml-1 mt-2" [disabled]="true"></app-button>
    <h5>Large Buttons</h5>
    <app-button type="primary" text="Default Button" size="lg" icon="expand_more" class="ml-1 mt-2"></app-button>
    <app-button type="secondary" text="Default Button" size="lg" icon="expand_more" class="ml-1 mt-2"></app-button>
    <app-button type="warning" text="Default Button" size="lg" icon="expand_more" class="ml-1 mt-2"></app-button>
    <app-button type="danger" text="Default Button" size="lg" icon="expand_more" class="ml-1 mt-2"></app-button>
    <app-button type="info" text="Default Button" size="lg" icon="expand_more" class="ml-1 mt-2"></app-button>
    <app-button
        type="info"
        text="Default Button"
        size="lg"
        icon="expand_more"
        class="ml-1 mt-2"
        [disabled]="true"
    ></app-button>
    <!-- Right Icon -->
    <h1>Buttons - Right Icons</h1>
    <h5>Small Buttons</h5>
    <app-button
        [rightIcon]="true"
        type="primary"
        text="Default Button"
        size="sm"
        icon="expand_more"
        class="ml-1 mt-2"
    ></app-button>
    <app-button
        [rightIcon]="true"
        type="secondary"
        text="Default Button"
        size="sm"
        icon="expand_more"
        class="ml-1 mt-2"
    ></app-button>
    <app-button
        [rightIcon]="true"
        type="warning"
        text="Default Button"
        size="sm"
        icon="expand_more"
        class="ml-1 mt-2"
    ></app-button>
    <app-button
        [rightIcon]="true"
        type="danger"
        text="Default Button"
        size="sm"
        icon="expand_more"
        class="ml-1 mt-2"
    ></app-button>
    <app-button
        [rightIcon]="true"
        type="info"
        text="Default Button"
        size="sm"
        icon="expand_more"
        class="ml-1 mt-2"
    ></app-button>
    <app-button
        [rightIcon]="true"
        type="info"
        text="Default Button"
        size="sm"
        icon="expand_more"
        class="ml-1 mt-2"
        [disabled]="true"
    ></app-button>
    <h5>Medium Buttons</h5>
    <app-button
        [rightIcon]="true"
        type="primary"
        text="Default Button"
        icon="expand_more"
        class="ml-1 mt-2"
    ></app-button>
    <app-button
        [rightIcon]="true"
        type="secondary"
        text="Default Button"
        icon="expand_more"
        class="ml-1 mt-2"
    ></app-button>
    <app-button
        [rightIcon]="true"
        type="warning"
        text="Default Button"
        icon="expand_more"
        class="ml-1 mt-2"
    ></app-button>
    <app-button
        [rightIcon]="true"
        type="danger"
        text="Default Button"
        icon="expand_more"
        class="ml-1 mt-2"
    ></app-button>
    <app-button [rightIcon]="true" type="info" text="Default Button" icon="expand_more" class="ml-1 mt-2"></app-button>
    <app-button
        [rightIcon]="true"
        type="info"
        text="Default Button"
        icon="expand_more"
        class="ml-1 mt-2"
        [disabled]="true"
    ></app-button>
    <h5>Large Buttons</h5>
    <app-button
        [rightIcon]="true"
        type="primary"
        text="Default Button"
        size="lg"
        icon="expand_more"
        class="ml-1 mt-2"
    ></app-button>
    <app-button
        [rightIcon]="true"
        type="secondary"
        text="Default Button"
        size="lg"
        icon="expand_more"
        class="ml-1 mt-2"
    ></app-button>
    <app-button
        [rightIcon]="true"
        type="warning"
        text="Default Button"
        size="lg"
        icon="expand_more"
        class="ml-1 mt-2"
    ></app-button>
    <app-button
        [rightIcon]="true"
        type="danger"
        text="Default Button"
        size="lg"
        icon="expand_more"
        class="ml-1 mt-2"
    ></app-button>
    <app-button
        [rightIcon]="true"
        type="info"
        text="Default Button"
        size="lg"
        icon="expand_more"
        class="ml-1 mt-2"
    ></app-button>
    <app-button
        [rightIcon]="true"
        type="info"
        text="Default Button"
        size="lg"
        icon="expand_more"
        class="ml-1 mt-2"
        [disabled]="true"
    ></app-button>
    <!-- Filters -->
    <h1>Filters and Selections</h1>
    <div class="bg-white p-5 rounded">
        <h6 class="h6-bold">Filters</h6>
        <div>
            <span class="body-medium-semibold"> Filter by: </span>
            <select class="select-field ml-3" name="byWeek" id="byWeek">
                <option value="this_week">This Week</option>
                <option value="last_week">Last Week</option>
            </select>
            <select class="select-field ml-3" name="order" id="order">
                <option value="newest">Newest</option>
                <option value="oldest">Oldest</option>
            </select>
        </div>
    </div>

    <h1>Toasters</h1>
    <button (click)="showSuccessToast()">Success</button>&nbsp; <button (click)="showInfoToast()">Info</button>&nbsp;
    <button (click)="showWarningToast()">Warning</button>&nbsp; <button (click)="showErrorToast()">Error</button>&nbsp;
    <h1>Modal</h1>
    <button (click)="openModal()">Open Modal</button>
    <h1>Sidenav</h1>
    <button (click)="openSideNav()">Open Sidenav</button>

    <!-- To add margin bottom -->
    <div class="mb-5"></div>
</div>
