<div class="functional-deficit-note-wrapper d-flex align-items-center">
    <span>{{ params.value }}</span>
    <span
        *ngIf="params.note"
        (mousemove)="onMouseEnter($event)"
        (mouseleave)="onMouseLeave()"
        class="material-symbols-outlined text-secondary cursor-pointer"
        style="margin-left: 2px; margin-top: 5px"
        >comment</span
    >
    <div class="hover-note-box custom-root-tooltip" #tooltipJustification>{{ params.note }}</div>
</div>
