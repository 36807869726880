export enum BILL_STATUS {
    BILLABLE = 'Billable',
    NON_BILLABLE = 'Non Billable',
}


export enum STANDARD_ACTIVITIES {
    LABOUR_TIME = 'Labor time',
    TRAVEL_TIME = 'Travel time',
    ADMINISTRATIVE_TIME = 'Administrative time',
    MILEAGE = 'Mileage',
}


