import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ROUTER_UTILS } from '@app/helpers/utils/router.utils';
import { AddOrganizationComponent } from './add-organization/add-organization.component';
import { OrganizationLayoutComponent } from './organization-layout/organization-layout.component';
import { ViewOrganizationComponent } from './view-organization/view-organization.component';
import { EditOrganization } from './edit-organization/edit-organization.component';
import { AddFacilityComponent } from '../facility/add-facility/add-facility.component';
import { EditNpcComponent } from './edit-npc/edit-npc.component';

const organizationHome = ROUTER_UTILS.config.admin.organization.home;
const addOrganization = ROUTER_UTILS.config.admin.organization.addOrganization;
const viewOrganization = ROUTER_UTILS.config.admin.organization.viewOrganization;
const editOrganization = ROUTER_UTILS.config.admin.organization.editOrganization;
const organizationUrl = `${ROUTER_UTILS.config.admin.organization.organizationDetails}/:id`;
const editNpc = `${ROUTER_UTILS.config.admin.organization.editNpc}/:id`;
const addNewFacility = ROUTER_UTILS.config.admin.facility.addFacility;

const routes: Routes = [
    { path: editNpc, component: EditNpcComponent },
    { path: organizationUrl, component: EditOrganization },
    { path: organizationHome, component: OrganizationLayoutComponent },
    { path: addOrganization, component: AddOrganizationComponent },
    { path: viewOrganization, component: ViewOrganizationComponent },
    { path: editOrganization, component: AddOrganizationComponent },
    { path: addNewFacility, component: AddFacilityComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class OrganizationRoutingModule {}
