<app-base-modal-card
    [heading]="heading"
    id="unassign-patient"
    wrapperClass="modal-medium"
    (close)="deleteAction(false)"
>
    <div body class="p-3 rounded">
        <p class="d-flex flex-row justify-content-center body-medium-semibold text-secondary">
            {{ heading == 'Deletion' ? 'Are you sure, you want to delete this ' + resourceType + '?' : message }}
            <!-- We don't need this, it is being handled by sending text in 'message' field. We've checked, it's working fine without below code. -->
            <!-- {{
                heading == 'Deletion' || heading == 'Reset Note'
                    ? resourceType + '?'
                    : 'All assigned patients/block time will be un-assigned, do you want to proceed?'
            }} -->
        </p>
    </div>
    <div footer class="d-flex flex-row col-12 justify-content-end pl-0 pr-0">
        <button
            class="btn innova-btn"
            [ngClass]="[button1Text == 'Continue' ? 'btn-secondary' : 'btn-danger']"
            (click)="deleteAction(true)"
        >
            {{ button1Text }}
        </button>
        <button
            class="btn btn-secondary innova-btn ml-1"
            [ngClass]="[button1Text == 'Continue' ? 'btn-danger' : 'btn-secondary']"
            (click)="deleteAction(false)"
        >
            {{ button2Text }}
        </button>
    </div>
</app-base-modal-card>
