import { NgModule, ModuleWithProviders } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { ToasterComponent } from './toaster.component';
import { defaultToastConfig, TOAST_CONFIG_TOKEN } from '@app/helpers/toaster';
@NgModule({
    imports: [OverlayModule],
    declarations: [ToasterComponent],
})
export class ToastModule {
    public static forRoot(): ModuleWithProviders<any> {
        return {
            ngModule: ToastModule,
            providers: [{ provide: TOAST_CONFIG_TOKEN, useValue: { defaultToastConfig } }],
        };
    }
}
