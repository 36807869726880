<app-base-modal-card wrapperClass="modal-medium" [heading]="heading" (close)="actionConfirmation(false)">
    <div body class="p-3 rounded">
        <p class="d-flex flex-row justify-content-center body-medium-semibold text-secondary m-0">
            Are you sure you want to leave? Unsaved changes will be lost.
        </p>
    </div>
    <div footer class="d-flex flex-row justify-content-center">
        <button class="btn btn-danger innova-btn" (click)="actionConfirmation(true)">Yes</button>
        <button class="btn btn-secondary innova-btn ml-1" (click)="actionConfirmation(false)">Cancel</button>
    </div>
</app-base-modal-card>
