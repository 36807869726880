/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss']
})
export class CustomTooltipComponent implements OnInit {
  @Input() warningText: string;
  @Input() text: string;
  @Input() visible = false;
  @Input() position: { top: string, left: string, bottom: string, right: string } = { top: '0px', left: 'auto', bottom: 'auto', right: '0px' };

  constructor() { }

  ngOnInit(): void {
  }

}
