import { Injectable, Injector } from '@angular/core';
import { Overlay, ComponentType, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DIALOG_DATA } from '../helpers/dialog-tokens';
export interface DialogConfig {
    data?: any;
    width?: string;
}
@Injectable({
    providedIn: 'root',
})
export class ModalService {
    private openDialog: DialogRef[] = [];
    constructor(private overlay: Overlay, private injector: Injector) {}
    open<T>(component: ComponentType<T>, config?: DialogConfig): DialogRef {
        const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();

        // Create the overlay with customizable options
        const overlayRef = this.overlay.create({
            positionStrategy,
            hasBackdrop: true,
            backdropClass: 'cdk-overlay-backdrop',
            panelClass: 'overlay-panel',
            scrollStrategy: this.overlay.scrollStrategies.block(),
            width: config?.width,
        });

        const dialogRef = new DialogRef(overlayRef);
        const injector = Injector.create({
            parent: this.injector,
            providers: [
                { provide: DialogRef, useValue: dialogRef },
                { provide: DIALOG_DATA, useValue: config?.data },
            ],
        });

        // Attach component portal to the overlay
        const portal = new ComponentPortal(component, null, injector);
        overlayRef.attach(portal);
        this.openDialog.push(dialogRef); // Add the dialog to the list of open dialogs

        return dialogRef;
    }
    closeAll(): void {
        while (this.openDialog.length > 0) {
            const dialogRef = this.openDialog.pop();
            if (dialogRef) {
                dialogRef.close(); // Close the dialog
            }
        }
    }
}

export class DialogRef {
    public events = new BehaviorSubject<any>(null);
    private afterClosedSubject = new Subject<any>();
    private customActionSubject = new Subject<any>();

    constructor(private overlayRef: OverlayRef) {
        this.overlayRef.keydownEvents().subscribe((v) => {
            if (v.key === 'Escape') {
                this.close();
                this.afterClosed();
            }
        });
    }

    /**
     * Closes the overlay. You can optionally provide a result.
     */
    public close(result?: any) {
        this.overlayRef.dispose();
        this.afterClosedSubject.next(result);
        this.afterClosedSubject.complete();
    }

    /**
     * An Observable that notifies when the overlay has closed
     */
    public afterClosed(): Observable<any> {
        return this.afterClosedSubject.asObservable();
    }
    public emitAction(actionName: string, actionValue: any) {
        this.customActionSubject.next({ actionName, value: actionValue });
    }
    public customAction(): Observable<any> {
        return this.customActionSubject.asObservable();
    }
}
