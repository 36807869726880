import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { PrintService } from '@app/services';
import { Location } from '@angular/common';
import { Doc } from '@app/interfaces/print';

@Component({
    selector: 'app-view-mode-doc-layout',
    templateUrl: './view-mode-doc-layout.component.html',
    styleUrls: ['./view-mode-doc-layout.component.scss'],
})
export class ViewModeDocLayoutComponent implements AfterViewInit, OnInit {
    @Input() printMode = true;
    docs: Doc[] = [];

    constructor(private printService: PrintService, private location: Location) {}

    ngOnInit(): void {
        this.docs = this.printService.selectedDocs.map((item) => {
            return {
                docType: item.docType,

                printData: {
                    discipline: item.discipline,
                    id: item.id,
                    therapist: item.therapist?.split('-')?.shift() ?? 'N/A',
                    type: item.type,
                    dueDate: item.dueDate,
                    completionDate: item.completionDate,
                },
            };
        });

        this.printService.selectedDocs = [];
    }

    ngAfterViewInit(): void {
        // added to fix some section ag grids get shrink
        const delay = Math.ceil(this.docs.length / 8) * 500;

        window.onafterprint = this.afterPrint;
        setTimeout(() => {
            print();
        }, delay);
    }

    afterPrint(): void {
        window.history.back();
    }
}
