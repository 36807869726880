import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { ROUTER_UTILS } from '@app/helpers';
import { FacilityAdmission } from '@app/models';
import { StoreService, ToasterService } from '@app/services';
import { PatientState } from '@app/store/store.interface';
import { Store } from '@ngrx/store';
import { Observable, take } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class FacilityAdmissionGuard implements CanActivate {
    facilityAdmission$: Observable<FacilityAdmission>;
    facilityAdmission: string = ROUTER_UTILS.config.addPatients.facilityAdmission;
    addPatient: string = ROUTER_UTILS.config.addPatients.editAdmission;

    constructor(
        private store: Store<{
            patient: PatientState;
            facilityAdmission: FacilityAdmission;
        }>,
        private router: Router,
        private storeService: StoreService,
        private toasterService: ToasterService
    ) {
        this.facilityAdmission$ = this.store.select('facilityAdmission');
    }
    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        this.facilityAdmission$.pipe(take(1)).subscribe((data) => {
            if (!data.id) {
                // check if there is no facility admission then send back
                this.storeService.admissionStepper = this.facilityAdmission;
                this.router.navigate([this.addPatient], { skipLocationChange: true });
                // this.alertService.error('No facility admission found');
                this.toasterService.show({
                    title: 'Error',
                    body: 'No facility admission found',
                    type: 'error',
                });
                // authorized so return true
                return true;
            } else {
                return true;
            }
        });
        return true;
    }
}
