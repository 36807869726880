<app-base-modal-card heading="Warning" id="warning-discharge-note" wrapperClass="modal-small"
    (close)="deleteAction(false)">
    <div body class="p-3 rounded text-center">
        <div><img src="../assets/icons/warning-icon.svg" alt="" /></div>
        <p class="mt-4 d-flex flex-row justify-content-center body-large-semibold text-secondary">
            Deleting this Discharge Note will re-open the therapy case resulting the most recent plan of care will
            resume.
        </p>
    </div>
    <div footer class="d-flex flex-row col-12 justify-content-end pl-0 pr-0">
        <button class="btn btn-secondary innova-btn ml-1" (click)="deleteAction(true)">Continue</button>
    </div>
</app-base-modal-card>