<ng-container *ngIf="(loadingObj | async)?.isLoading; else loaded" [ngTemplateOutlet]="skeleton"></ng-container>
<ng-template #loaded>
    <div id="hidden-on-print">
        <div class="pb-2">
            <app-facility-header
                (facilityFilters)="changeFilters($event)"
                (eventEnded)="onEventEnded($event)"
                [selectedFacilities]="selectedFacilities"
                [isFacilitySelected]="isFacilitySelected"
                [filtersReset]="filtersReset"
                [currentTab]="currentTab"
            ></app-facility-header>
        </div>
        <div class="custom-card" *ngIf="!(loadingObj | async)?.isLoading">
            <div id="tab-nav-cont">
                <app-toggle
                    [buttons]="buttons"
                    (buttonChanged)="navUpdated($event)"
                    (filtersReset)="onResetFilters()"
                ></app-toggle>
            </div>
            <div class="body">
                <app-facility-list
                    [facilities]="filterFacilityData"
                    [eventEnded]="eventEnded"
                    (selectedFacilities)="setSelectedFacilities($event)"
                    (isFacilitySelected)="setIsFacilitySelected($event)"
                    [currentTab]="currentTab"
                ></app-facility-list>
            </div>
        </div>
    </div>
</ng-template>
<table id="print-table" style="table-layout: fixed; width: 100%; margin: 0 0 30px; page-break-before: avoid">
    <thead>
        <tr style="border-bottom: 1px solid #d1d1d0">
            <th [width]="500">
                <span class="semi-bold">Org Name: </span>
                <span>{{ selectedOrganization }}</span>
            </th>
            <th [width]="150">
                <span>Status: </span>
                <span>{{ this.currentTab }}</span>
            </th>
            <th [width]="150">
                <span>State: </span>
                <span>{{ selectedState ? selectedState: 'All' }}</span>
            </th>
            <th [width]="150">
                <span>Type: </span>
                <span>{{ selectedType ? selectedType: 'All' }}</span>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td colspan="5" class="document-heading">Facility Management</td>
        </tr>
        <tr style="page-break-before: avoid">
            <td colspan="5">
                <table class="table-inner">
                    <tbody>
                        <tr>
                            <td class="semi-bold">Name</td>
                            <td class="semi-bold">Type</td>
                            <td class="semi-bold">State</td>
                            <td class="semi-bold">Go-Live</td>
                            <td class="semi-bold">Integrated</td>
                        </tr>
                        <ng-container *ngFor="let facility of selectedFacilities">
                            <tr>
                                <td>{{ facility.facilityName }}</td>
                                <td>{{ facility.facilityType }}</td>
                                <td>{{ facility.mailingAddress.state }}</td>
                                <td>{{ facility.goLiveEnabled ? 'On' : 'Off' }}</td>
                                <td>{{ facility.facilityOID ? 'Yes' : 'No' }}</td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </td>
        </tr>
    </tbody>
</table>
<ng-template #skeleton>
    <div>
        <br />
        <ng-container *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]">
            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
        </ng-container>
        <hr class="table-footer" />
        <div class="pagination-loader">
            <ngx-skeleton-loader [theme]="{ width: '10em', 'margin-right': '0' }"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{ width: '10em', 'margin-left': '5em' }"></ngx-skeleton-loader>
        </div>
    </div>
</ng-template>
<!-- <table id="print-table" style="table-layout: fixed; width: 100%; margin: 0 0 30px; page-break-before: avoid">
    <thead>
        <tr style="border-bottom: 1px solid #d1d1d0">
            <th [width]="500">
                <span class="semi-bold">Org Name: </span>
                <span>{{ selectedOrganization }}</span>
            </th>
            <th [width]="150">
                <span>Status: </span>
                <span>{{ this.currentTab }}</span>
            </th>
            <th [width]="150">
                <span>State: </span>
                <span>{{ selectedState ? selectedState: 'All' }}</span>
            </th>
            <th [width]="150">
                <span>Type: </span>
                <span>{{ selectedType ? selectedType: 'All' }}</span>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td colspan="5" class="document-heading">Facility Management</td>
        </tr>
        <tr style="page-break-before: avoid">
            <td colspan="5">
                <table class="table-inner">
                    <tbody>
                        <tr>
                            <td class="semi-bold">Name</td>
                            <td class="semi-bold">Type</td>
                            <td class="semi-bold">State</td>
                            <td class="semi-bold">Go-Live</td>
                            <td class="semi-bold">Integrated</td>
                        </tr>
                        <ng-container *ngFor="let facility of selectedFacilities">
                            <tr>
                                <td>{{ facility.facilityName }}</td>
                                <td>{{ facility.facilityType }}</td>
                                <td>{{ facility.mailingAddress.state }}</td>
                                <td>{{ facility.goLiveEnabled ? 'On' : 'Off' }}</td>
                                <td>{{ facility.facilityOID ? 'Yes' : 'No' }}</td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </td>
        </tr>
    </tbody>
</table> -->
