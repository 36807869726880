<div [class]="formGroupClass" [class.datepicker-error]="hasError">
    <label class="{{ labelClass }} d-block" *ngIf="label">{{ label | translate }} {{ isRequired ? '*' : '' }}</label>
    <i *ngIf="editIconOnly" (click)="d.toggle()" class="material-icons-outlined edit-icon"> edit </i>

    <div
        [ngClass]="
            applyBackground
                ? (backgroundClass ? backgroundClass + ' ' + inputWrapperClass : inputWrapperClass) +
                  ' input-group rounded-curve'
                : ''
        "
        [class.disabled]="disabled"
        class="{{ disableDate }}"
    >
        <input
            #inputElement
            [hidden]="calendarOnly"
            [class]="inputClass"
            [style]="inputStyle"
            [ngStyle]="{ cursor: disabled ? 'default' : 'pointer' }"
            readonly
            [container]="container"
            ngbDatepicker
            #d="ngbDatepicker"
            type="text"
            firstDayOfWeek="7"
            [(ngModel)]="model"
            [placeholder]="placeholder"
            [dayTemplate]="customDay"
            [value]="value"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [markDisabled]="markDisabled"
            [disabled]="disabled"
            (dateSelect)="dateSelect($event)"
            (click)="d.toggle()"
            [placement]="placement"
            (keydown)="handleKeyPress($event, d)"
        />
        <div
            *ngIf="!editIconOnly"
            class="input-group-append bg-transparent"
            [ngStyle]="{ cursor: disabled ? 'default' : 'pointer' }"
            (click)="disabled ? $event.preventDefault() : d.toggle()"
        >
            <span class="icon input-group-text border-0 bg-transparent"
                ><i class="material-icons-outlined"> calendar_today </i>
            </span>
        </div>
    </div>
</div>

<ng-template
    #customDay
    let-date
    let-currentMonth="currentMonth"
    let-selected="selected"
    let-disabled="disabled"
    let-focused="focused"
>
    <span
        class="custom-day"
        [class.focused]="focused"
        [class.selected-day]="selected"
        [class.hidden]="date.month !== currentMonth"
        [class.text-muted]="disabled && !focused"
    >
        {{ date.day }}
    </span>
</ng-template>
