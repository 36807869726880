import { Component, OnInit } from '@angular/core';
import { DetailedCptCodes } from '@app/interfaces';
import { CptCodesService, DocumentationReportService } from '@app/services';

@Component({
    selector: 'app-treatment',
    templateUrl: './treatment.component.html',
    styleUrls: ['./treatment.component.scss'],
})
export class TreatmentComponent implements OnInit {
    treatments: DetailedCptCodes[];
    totalMinutes: number;
    totalUnits: number;

    constructor(private documentationService: DocumentationReportService, private cptCodeService: CptCodesService) {}

    ngOnInit(): void {
        this.treatments = this.documentationService.treatment;
        this.totalMinutes = this.treatments.reduce(
            (accumulative: number, value: DetailedCptCodes) => accumulative + value.cptMinutes,
            0
        );
        this.totalUnits = this.cptCodeService.calculateTotalUnits(this.treatments);
    }
}
