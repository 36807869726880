<app-base-modal-card [heading]="content.title" wrapperClass="modal-medium" (close)="onClose(false)">
    <div body class="poppins" [formGroup]="appointmentForm">
        <span class="d-block font-weight-600 text-secondary">Planned min:</span>
        <p>{{ appointmentForm.get('plannedTx').value }}</p>
        <hr class="ml-n3 mr-n3" />
        <div class="d-flex align-items-end mb-4">
            <div>
                <span class="d-block font-weight-600 text-secondary">Start time</span>
                <ngb-timepicker formControlName="startTime" [spinners]="false" [meridian]="true"> </ngb-timepicker>
            </div>
            <span class="d-block font-weight-600 text-secondary mx-4 pb-1">to</span>
            <div>
                <span class="d-block font-weight-600 text-secondary">End time</span>
                <ngb-timepicker formControlName="endTime" [spinners]="false" [meridian]="true" class="read-only">
                </ngb-timepicker>
            </div>
            <span class="text-primary cursor-pointer mb-2 ml-5 reset"
                [ngClass]="{'disabled': !appointmentForm.get('startTime').value}"
                (click)="appointmentForm.get('startTime').setValue('')">Reset</span>
        </div>
        <app-text-box formControlName="notes" label="Notes" placeholder="Type appointment notes here"></app-text-box>
    </div>
    <div footer>
        <app-button
            [buttonClass]="'btn-secondary innova-btn standard-btn innova-btn-md body-regular-light-bold-14 mr-2'"
            type="outline"
            text="Cancel"
            (click)="onClose(false)"
        >
        </app-button>
        <app-button
            [buttonClass]="'btn-secondary innova-btn standard-btn innova-btn-md body-regular-light-bold-14'"
            type="secondary"
            [disabled]="appointmentForm.invalid || (!appointmentForm.get('startTime').value && !appointmentForm.get('notes').value)"
            [text]="saveButtonText"
            (click)="onSave()"
        >
        </app-button>
    </div>
</app-base-modal-card>
