import { getNestedObjectValue } from './object.utils';
import * as jDiff from 'jsondiffpatch';
import * as changeSet from 'diff-json';
import { DCChangeHistory, DNChangeHistory, EvalChangeHistory, PNChangeHistory } from '@app/interfaces';

export const prepareSimpleTextChangeLog = (
    changeHistory: EvalChangeHistory[] | DNChangeHistory[] | PNChangeHistory[] | DCChangeHistory[],
    changeInstance: number,
    section: string,
    key: string,
    toAppend?: string
) => {
    // Always consult before making any change in this function
    const foundChange = changeHistory[changeInstance].changeHistory?.changedFields?.find((x) => x.fieldKey === key);
    if (foundChange) {
        const obj1 = escapeSpecialChars(foundChange.previousValue);
        const obj2 = escapeSpecialChars(foundChange.currentValue);
        let delta = jDiff.create({ textDiff: { minLength: 10000 } }).diff(obj1, obj2);
        let innerHtml = jDiff.formatters.html.format(delta, obj1);
        jDiff.formatters.html.hideUnchanged();
        delta = changeSet.diff(obj1, obj2);
        // Hanlding the case where user enters "" within a string, jDiff adds backslash with it
        innerHtml = innerHtml.replace(/\\/g, '');
        return innerHtml;
    } else {
        const pathList = key.split('.');
        let obj;
        let value;
        if (pathList && pathList.length > 1) {
            for (let i = 0; i < pathList.length; i++) {
                if (i === 0) {
                    obj = changeHistory[changeInstance][section][pathList[i]];
                } else if (i === pathList.length - 1) {
                    value = getNestedObjectValue(obj, pathList[i]);
                }
            }
            return value;
        } else {
            if (toAppend) {
                return `${changeHistory[changeInstance][section]?.[key]} ${toAppend}`;
            } else {
                return changeHistory[changeInstance][section]?.[key];
            }
        }
    }
};

// Using for \n (newline). Can be extended to use for others like \t if needed.
export const escapeSpecialChars = (value) => {
    if (typeof value === 'string') return value?.replace(/\n/g, '');
    else return value;
};

// Generic dynamic array change log function. Use with caution!
export const prepareDynamicArrayChangeLog = (
    changeHistory: DNChangeHistory[] | PNChangeHistory[] | DCChangeHistory[],
    changeInstance: number,
    section: string,
    key: string,
    uniqueKeyMatcher = '_id'
) => {
    // Always consult before making any change in this function
    const arrayData = changeHistory[changeInstance]?.changeHistory?.changedFields?.filter((x) => x.fieldKey === key);
    if (arrayData && arrayData.length > 0) {
        const temp = [];
        changeHistory[changeInstance]?.changeHistory.changedFields
            .filter((x) => x.fieldKey === key)
            .forEach((y) => {
                y['currentValue' as any].forEach((cv) => {
                    const found = y['previousValue' as any].find((pv) => pv[uniqueKeyMatcher] === cv[uniqueKeyMatcher]);
                    if (found) {
                        const fullBody = {};
                        //delta
                        Object.keys(y['currentValue'][0]).forEach((key) => {
                            fullBody[key] = {
                                currentValue: cv[key] ?? undefined,
                                previousValue: found[key] ?? undefined,
                            };
                        });
                        temp.push(fullBody);
                    } else {
                        const fullBody = {};
                        //new value addition
                        Object.keys(y['currentValue'][0]).forEach((key) => {
                            fullBody[key] = { currentValue: cv[key], previousValue: undefined };
                        });
                        temp.push(fullBody);
                    }
                });

                y['previousValue' as any].forEach((pv) => {
                    const found = y['currentValue' as any].find((cv) => cv[uniqueKeyMatcher] === pv[uniqueKeyMatcher]);
                    if (!found) {
                        const fullBody = {};
                        Object.keys(y['previousValue'][0]).forEach((key) => {
                            fullBody[key] = { currentValue: undefined, previousValue: pv[key] };
                        });
                        // value deletion
                        temp.push(fullBody);
                    }
                });
            });
        return temp;
    } else return changeHistory[changeInstance][section]?.[key];
};

// For use with PN and DC
export const prepareDynamicGoalsArrayChangeLog = (
    changeHistory: DNChangeHistory[] | PNChangeHistory[] | DCChangeHistory[],
    changeInstance: number,
    section: string,
    key: string,
    uniqueKeyMatcher = '_id'
) => {
    const arrayData = changeHistory[changeInstance]?.changeHistory?.changedFields?.filter((x) => x.fieldKey === key);
    if (arrayData && arrayData.length > 0) {
        const temp = [];
        changeHistory[changeInstance]?.changeHistory.changedFields
            .filter((x) => x.fieldKey === key)
            .forEach((y) => {
                y['currentValue' as any].forEach((cv) => {
                    const found = y['previousValue' as any].find((pv) => pv[uniqueKeyMatcher] === cv[uniqueKeyMatcher]);
                    if (found) {
                        const fullBody = {};
                        //delta
                        Object.keys(y['currentValue'][0]).forEach((key) => {
                            fullBody[key] = { currentValue: cv[key], previousValue: found[key] };
                        });

                        let finalStgs = [];
                        // do in one loop outside this foreach. Remember if chunk
                        cv.stgs.forEach((stg) => {
                            const prevStg = found.stgs.find((prevStg) => prevStg.id === stg.id);
                            const stgToPush = {};
                            if (prevStg) {
                                Object.keys(stg).forEach((key) => {
                                    stgToPush[key] = { currentValue: stg[key], previousValue: prevStg[key] };
                                });
                            } else {
                                Object.keys(stg).forEach((key) => {
                                    stgToPush[key] = { currentValue: stg[key], previousValue: undefined };
                                });
                            }
                            finalStgs.push(stgToPush);
                        });
                        fullBody['stgs'].currentValue = finalStgs;

                        // currentV = finalStgs ?
                        temp.push(fullBody);
                        finalStgs = [];
                    } else {
                        const fullBody = {};
                        //new value addition
                        Object.keys(y['currentValue'][0]).forEach((key) => {
                            fullBody[key] = { currentValue: cv[key], previousValue: undefined };
                        });
                        temp.push(fullBody);
                    }
                });

                y['previousValue' as any].forEach((pv) => {
                    const found = y['currentValue' as any].find((cv) => cv[uniqueKeyMatcher] === pv[uniqueKeyMatcher]);
                    if (!found) {
                        const fullBody = {};
                        Object.keys(y['previousValue'][0]).forEach((key) => {
                            fullBody[key] = { currentValue: undefined, previousValue: pv[key] };
                        });
                        // value deletion
                        temp.push(fullBody);
                    }
                });
            });
        return temp;
    } else {
        if (section) return changeHistory[changeInstance][section]?.[key];
        else return changeHistory[changeInstance][key];
    }
};
