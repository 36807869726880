import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
    FacilityManagementService,
    InvoicingProfileService,
    OrganizationManagementService,
    ToasterService,
} from '@app/services';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';

@Component({
    selector: 'app-facility-selection-combined-table',
    templateUrl: './facility-selection-combined-table.component.html',
    styleUrls: ['./facility-selection-combined-table.component.scss'],
})
export class FacilitySelectionCombinedTableComponent implements OnInit, OnChanges {
    @Input() selectedFacilities = [];
    @Input() addedFacilitiesId = [];
    @Input() invoiceProfile = false;
    @Output() facilitySelectionEvent: EventEmitter<any[]> = new EventEmitter<any[]>();
    private gridApi!: GridApi;
    facilityList = [];
    facilityMasterCopy = [];

    searchedFacilities = [];
    // Will move this columns to services soon
    facilityCols: ColDef[] = [
        {
            field: 'name',
            headerName: 'Facility Name',
            headerCheckboxSelection: true,
            checkboxSelection: false,
        },
        { field: 'type', headerName: 'Type' },
    ];
    searchString = '';
    allFacilities: any[];
    addedFacilities: any[];
    isLoading = false;
    constructor(
        private _facilityManagementService: FacilityManagementService,
        private toasterService: ToasterService,
        private invoiceService: InvoicingProfileService,
        private organizationService: OrganizationManagementService
    ) {}
    ngOnChanges(changes: SimpleChanges): void {
        this.handleChanges(changes.selectedFacilities.currentValue);
    }
    handleChanges(selectedFacilities: any): void {
        selectedFacilities?.forEach((facility) => {
            const selectedIndex = this.facilityList.findIndex(
                (f) => f.name.toLowerCase() === (facility?.facilityName?.toLowerCase() || facility?.name?.toLowerCase())
            );
            if (selectedIndex > -1) {
                this.facilityList.splice(selectedIndex, 1);
                this.gridApi?.setRowData(this.facilityList);
            }
        });
    }
    ngOnInit(): void {
        this.isLoading = true;
        this.invoiceService
            .getAllInvoicingProfilesByOrganizationId(this.organizationService._currentOrganization?.id)
            .subscribe((profiles) => {
                const profilesList = profiles.data;
                this.addedFacilitiesId = this.getAddedFacilities(profilesList);
                this.addedFacilities = this.selectedFacilities;
                this.getAllFacilities();
            });
    }

    getAddedFacilities(profilesList): any[] {
        const allFacilityIds = [];
        profilesList?.forEach((profile) => {
            profile?.facilities?.forEach((facility) => {
                if (facility?._id) {
                    allFacilityIds.push(facility._id.toLowerCase());
                }
            });
        });

        return allFacilityIds ?? [];
    }

    filterAddedFacilities(data) {
        data = data?.filter((facility) => {
            return !this.addedFacilitiesId?.includes(facility?.id);
        });

        return data;
    }
    facilitySearch(text: string) {
        if (!text?.length) {
            this.facilityList = [...this.allFacilities];
            return;
        }
        this.searchString = text;
        this.searchedFacilities = this.facilityList;
        this.facilityList = this.facilityMasterCopy;
        this.facilityList = this.facilityList.filter((payor) => payor.name.toLowerCase().includes(text.toLowerCase()));
    }
    redrawRows(text: string) {
        this.facilityList = this.searchedFacilities;
        if (!text?.length) {
            this.facilityList = this.facilityMasterCopy;
        }
        this.excludeSelectedFacilities();
    }

    excludeSelectedFacilities() {
        // exclude those facilities those are already selected
        if (this.selectedFacilities?.length) {
            this.facilityList = this.facilityMasterCopy?.filter((facility) => {
                return !this.selectedFacilities?.some((selectedFacility) => {
                    return selectedFacility?.name?.toLowerCase() === facility?.name?.toLowerCase();
                });
            });
        }
    }

    facilitySelectionChanged(e) {
        e = e.data ? e.data : e;
        const selectedIndex = this.facilityList.findIndex(
            (f) => f.name.toLowerCase() === (e.name ? e.name.toLowerCase() : e.facilityName.toLowerCase())
        );
        if (selectedIndex > -1) {
            this.selectedFacilities.push(this.facilityList[selectedIndex]);
            this.facilityList.splice(selectedIndex, 1);
            this.gridApi.setRowData(this.facilityList);
            this.facilitySelectionEvent.emit(this.selectedFacilities);
        }
    }
    rowSelection(e) {
        for (let i = 0; i < e.length; i++) {
            const selectedIndex = this.facilityList.findIndex((f) => f.name.toLowerCase() === e[i].name.toLowerCase());
            if (selectedIndex > -1) {
                this.selectedFacilities.push(this.facilityList[selectedIndex]);
                this.facilityList.splice(selectedIndex, 1);
                this.gridApi.setRowData(this.facilityList);
                this.facilitySelectionEvent.emit(this.selectedFacilities);
            }
        }
    }

    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
    }

    getAllFacilities() {
        this._facilityManagementService._Facilities.subscribe((result) => {
            if (result.length > 0) {
                this.isLoading = false;
                const data = this._facilityManagementService.mapFacilitiesIntoDropdown();
                this.facilityList = this.invoiceProfile ? this.filterAddedFacilities(data) : data;
                this.facilityMasterCopy = [...this.facilityList];
                this.allFacilities = [...this.facilityMasterCopy];
                this.handleChanges(this.selectedFacilities);
            }
        });
    }

    removeSelectedFacility(facility, index: number): void {
        const isFacilityInvoiced = this.selectedFacilities[index].invoiceStatus?.some((invoice) =>
            invoice?.status?.toLowerCase().includes('invoiced')
        );
        if (isFacilityInvoiced) {
            this.toasterService.show({ title: 'Failure', body: 'Invoiced Facility can not be Removed', type: 'error' });
            return;
        }

        this.selectedFacilities.splice(index, 1);
        if (facility?.MACProvider) {
            facility.id = facility._id;
            facility.name = facility.facilityName;
            facility.type = facility.facilityType;
        }
        this.facilityList.push(facility);
        this.facilityMasterCopy = [...this.facilityList];
        this.searchedFacilities.push(facility);
        this.gridApi.setRowData(this.facilityList);
        this.facilitySelectionEvent.emit(this.selectedFacilities);
    }
    removeAll() {
        const isFacilityInvoiced = this.selectedFacilities.some((facility) =>
            facility.invoiceStatus?.some((invoice) => invoice?.status?.toLowerCase().includes('invoiced'))
        );

        if (isFacilityInvoiced) {
            this.toasterService.show({ title: 'Failure', body: 'Invoiced Facility can not be Removed', type: 'error' });
            return;
        }
        this.addedFacilities?.forEach((facility) => {
            const index = this.allFacilities.findIndex(
                (element) => element?.id?.toLowerCase() === facility?.id?.toLowerCase()
            );
            if (index === -1) {
                this.allFacilities.push(facility);
            }
        });
        this.facilityList = [...this.allFacilities];
        this.facilityMasterCopy = [...this.allFacilities];

        this.gridApi.setRowData(this.facilityList);
        this.selectedFacilities = [];
        this.facilitySelectionEvent.emit(this.selectedFacilities);
    }
}
