import { Component, Input, OnInit } from '@angular/core';
import { ReportSignature } from '@app/interfaces';
import { DocumentationReportService } from '@app/services';

@Component({
    selector: 'app-signature',
    templateUrl: './signature.component.html',
    styleUrls: ['./signature.component.scss'],
})
export class SignatureComponent implements OnInit {
    @Input() type: string;
    @Input() toShowPhysician = true;

    signature: ReportSignature;
    toAddSignature: boolean;

    constructor(private documentationReportService: DocumentationReportService) {}

    ngOnInit(): void {
        this.signature = this.documentationReportService[this.type].signature;
    }
}
