import { Component } from '@angular/core';
import { ROUTER_UTILS } from '@app/helpers';
import { StoreService } from '@app/services';
@Component({
    selector: 'app-organization-layout',
    templateUrl: './organization-layout.component.html',
    styleUrls: ['./organization-layout.component.scss'],
})
export class OrganizationLayoutComponent {
    addOrganizationURL = ROUTER_UTILS.config.admin.organization.addOrganization;
    organizationsListData = [];
    loadingObj: any;
    constructor(private storeService: StoreService) {
        this.loadingObj = this.storeService.getLoadingState();
    }
    public SKELETON_CSS = {
        CELL: {
            width: '96%',
            height: '3.4em',
            margin: '0 2em 0.1em 2em',
        },
    };
}
