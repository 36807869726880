import { Component, ElementRef, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'comments-cell-renderer',
    templateUrl: 'poc-comments-cell-render.html',
    styleUrls: ['./poc-comments-cell-render.scss'],
})
export class CommentsCellRenderer implements ICellRendererAngularComp {
    params: any;
    @ViewChild('infoDetails') infoDetailsEl: ElementRef;
    public disabled = false;
    agInit(params: any): void {
        this.params = params;
        this.disabled = params.disabled;
    }

    btnClickedHandler() {
        this.params.clicked(this.params.data);
    }
    hideInfo() {
        this.infoDetailsEl.nativeElement.style.display = 'none';
    }
    showInfo(event) {
        //patientDoc.day
        const elHeight = this.infoDetailsEl.nativeElement.offsetHeight;
        const remainingWindowHeight = window.innerHeight - event.pageY;
        const flHeight = window.outerHeight - remainingWindowHeight;
        if (elHeight < remainingWindowHeight) {
            // show element below as it was before
            // this.infoDetailsEl.nativeElement.style.top = event.pageY - 400 + 'px';
            // this.infoDetailsEl.nativeElement.style.right = event.pageX - 650 + 'px';
            this.infoDetailsEl.nativeElement.style.display = 'block';
        } else if (remainingWindowHeight < flHeight) {
            // show element on top of the cursor
            // this.infoDetailsEl.nativeElement.style.top = event.pageY - elHeight - 400 + 'px';
            // this.infoDetailsEl.nativeElement.style.right = event.pageX - 650 + 'px';
            this.infoDetailsEl.nativeElement.style.display = 'block';
        }
    }
    refresh() {
        return false;
    }
}
