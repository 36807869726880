<div class="text-secondary w-100 {{ wrapperClass }}">
    <div id="bar-card-wrapper" class="d-flex overflow-hidden flex-row pb-md-2" style="justify-content: space-between">
        <ng-container *ngFor="let value of values; let i = index">
            <div *ngIf="value.detail !== false" class="d-flex flex-row vl" style="white-space: nowrap">
                <div
                    class="d-flex flex-column align-items-center {{ PaddingClass }}"
                    [ngClass]="fixedWidth == false ? 'pl-lg-4 pr-lg-4 pl-md-2 pr-md-2' : 'fixed-width-view'"
                >
                    <div class="body-large-bold">{{ value.title }}</div>
                    <div class="body-medium {{ nameWrapperClass }}">
                        <span *ngIf="!value.detail || value.detail.length == 0; else cardValue">N/A</span>
                        <ng-template #cardValue>
                            <span
                                *ngIf="!value.type || value.type != 'date-picker'; else datePicker"
                                [ngClass]="setDottedAuthState(value.detail)"
                                [ngStyle]="{
                                    color:
                                        value.detail === 'Not Authorized' || value.detail === 'Unassigned'
                                            ? '#F73939'
                                            : value.detail === 'Authorized'
                                              ? '#419B17'
                                              : ''
                                }"
                                >{{ value.detail }}</span
                            >
                            <ng-template #datePicker>
                                <div class="date-with-picker">
                                    <span [ngClass]="setDottedAuthState(value.detail)" class="date-with-picker-value"
                                        >{{
                                            value.detail.length == 10
                                                ? value.detail
                                                : (value.detail | date: 'MM/dd/YYYY')
                                        }}
                                    </span>
                                    <app-date-picker
                                        (ngModelChange)="changeDate($event)"
                                        [(ngModel)]="value.detail"
                                        [tooltip]="false"
                                        [editIconOnly]="true"
                                        [calendarOnly]="true"
                                        [minDate]="value.minSOCDate"
                                        [container]="'body'"
                                        [placement]="'bottom'"
                                        [positionTarget]="'.date-with-picker'"
                                    >
                                    </app-date-picker>
                                </div>
                            </ng-template>
                        </ng-template>
                    </div>
                    <div></div>
                </div>
            </div>
        </ng-container>
        <div class="align-self-center pl-5 pr-5" *ngIf="therapyMessage != ' '">
            <span class="body-large-bold therapy-message"
                >No {{ therapyMessage | disciplineAbbreviation }} Admission</span
            >
        </div>
        <div class="pr-5 align-self-center" *ngIf="therapyMessage">
            <span
                class="body-large-bold cursor-pointer"
                [ngClass]="button == 'Add' ? 'text-primary' : ' text-success-100'"
                (click)="openTherapyAdmission(button)"
                >{{ button | uppercase }}</span
            >
        </div>
    </div>

    <div *ngIf="showDottedDropdown" class="mr-4">
        <div ngbDropdown class="d-inline-block">
            <img
                loading="lazy"
                src="./../../../assets/icons/ellipsis-colored.svg"
                height="20px"
                width="20px"
                style="cursor: pointer"
                ngbDropdownToggle
            />
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button
                    class="body-small"
                    ngbDropdownItem
                    style="color: #104061"
                    (click)="dottedDropdownSelect('Authorize')"
                >
                    {{ dottedAuthState }}
                </button>
                <button
                    class="body-small"
                    ngbDropdownItem
                    style="color: #104061"
                    (click)="dottedDropdownSelect('Edit insurance information')"
                >
                    Edit insurance information
                </button>
                <button
                    class="body-small"
                    ngbDropdownItem
                    style="color: #f73939"
                    (click)="dottedDropdownSelect('Delete')"
                >
                    Delete
                </button>
            </div>
        </div>
    </div>
</div>
