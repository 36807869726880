<div class="functional-deficit-note-wrapper d-flex align-items-center">
    <p style="word-break: break-word" [innerHtml]="innerHtml"></p>
    <!-- <p style="word-break: break-word" >{{noteValue}}</p> -->
    <span
        *ngIf="params.noteCurrentValue || params.notePreviousValue || params.note"
        (mouseenter)="onMouseEnter($event)"
        (mouseleave)="onMouseLeave()"
        class="material-symbols-outlined text-secondary"
        style="margin-left: 2px; margin-top: -10px"
        >comment</span
    >
    <ng-container>
        <div
            *ngIf="!(params.noteCurrentValue && params.notePreviousValue) && params.note"
            class="hover-note-box custom-root-tooltip"
            #tooltipJustification
        >
            {{ params.note }}
        </div>

        <div *ngIf="params.noteCurrentValue == params.notePreviousValue" class="hover-note-box custom-root-tooltip" #tooltipJustification>
            {{ params.noteCurrentValue }}
        </div>
    </ng-container>
    <div *ngIf="params.noteCurrentValue && !params.notePreviousValue" class="hover-note-box custom-root-tooltip" #tooltipJustification>
        <div class="jsondiffpatch-textdiff-deleted body-small">""</div>
        <div class="jsondiffpatch-textdiff-added body-small">{{ '"' + params.noteCurrentValue + '"' }}</div>
    </div>
    <ng-container>
        <div
            *ngIf="
                params.notePreviousValue &&
                params.noteCurrentValue &&
                params.notePreviousValue != params.noteCurrentValue
            "
            class="hover-note-box custom-root-tooltip"
            #tooltipJustification
        >
            <div class="jsondiffpatch-textdiff-deleted">{{ '"' + params.notePreviousValue + '"' }}</div>
            <div class="jsondiffpatch-textdiff-added"> {{ '"' + params.noteCurrentValue + '"' }}</div>
        </div>
    </ng-container>
</div>
