import { Injectable, Injector } from '@angular/core';
import { GlobalPositionStrategy, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType, PortalInjector } from '@angular/cdk/portal';
import { SideNavData } from '@app/helpers';
import { Ref } from '@app/helpers/toaster';
import { NavigationStart, Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class SideNavService {
    private overlayRef: OverlayRef | null = null;
    constructor(private overlay: Overlay, private parentInjector: Injector, private router: Router) {
        // route change close
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                // Close the side navigation when a navigation event starts
                this.closeSideNav();
            }
        });
    }

    show(component: ComponentType<unknown>, config: { data: unknown; position: 'right' }): Ref {
        if (this.overlayRef && this.overlayRef.hasAttached()) {
            this.overlayRef.detach();
        }
        const positionStrategy = this.getPositionStrategy(config.position);
        this.overlayRef = this.overlay.create({ positionStrategy });
        const ref = new Ref(this.overlayRef);
        const injector = this.getInjector(config.data, ref, this.parentInjector);
        const toastPortal = new ComponentPortal(component, null, injector);

        this.overlayRef.attach(toastPortal);

        return ref;
    }

    private getPositionStrategy(position: 'right'): GlobalPositionStrategy {
        return this.overlay.position().global()[position]();
    }

    private getInjector<T>(data: T, ref: Ref, parentInjector: Injector) {
        const tokens = new WeakMap();

        tokens.set(SideNavData, data);
        tokens.set(Ref, ref);

        return new PortalInjector(parentInjector, tokens);
    }

    closeSideNav() {
        if (this.overlayRef && this.overlayRef.hasAttached()) {
            this.overlayRef.detach();
        }
    }
}
