import { createReducer, on } from '@ngrx/store';
import { therapyDisciplineChangedAction, therapyDisciplinesResetAction } from './../actions/therapyDiscipline.actions';

import { initialTherapyDiscipline } from '@app/store/selectors';
export const therapyDisciplineReducer = createReducer(
    initialTherapyDiscipline,
    on(therapyDisciplineChangedAction, (state, { therapyDiscipline }) => therapyDiscipline),

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    on(therapyDisciplinesResetAction, (state) => initialTherapyDiscipline)
);
