<div *ngIf="params.value" class="align-items-center justify-content-center d-flex">
    <div>
        <input
            class="checkbox"
            type="checkbox"
            (click)="onChange($event)"
            [checked]="params.checked"
            [disabled]="params.disabled"
        />
    </div>
</div>
