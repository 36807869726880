import { Injectable } from '@angular/core';
import { TabItem } from '@app/interfaces';
import { IActivityRate, USER_TYPES } from '@app/models';
import { ADD_USER_TABS, USER_TABS_INDEX } from '@app/modules/admin/user-management/constants';
import { cloneDeep } from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserManagementService {
    userPersonalInfo = new BehaviorSubject({})
    userFacilityRoles = new BehaviorSubject([])
    userActivityRates = new BehaviorSubject([])
    isSameRoleForAllFacilities = new BehaviorSubject(false)
    userAdditionalInfo = new BehaviorSubject({})
    physicianAdditionalInfo = new BehaviorSubject({})
    userType = new BehaviorSubject(USER_TYPES.NONE)
    isConfirmedPhysician = new BehaviorSubject(false)
    addUserTabs = new BehaviorSubject(cloneDeep(ADD_USER_TABS))

    formInvalidStatuses = [true, true, true, true]
    formInvalidStatusesSub = new BehaviorSubject(this.formInvalidStatuses);
    isSubmitting = new BehaviorSubject(false)


    initializeAllFormStates() {
        this.userAdditionalInfo.next({})
        this.physicianAdditionalInfo.next({})
        this.userPersonalInfo.next({})
        this.userFacilityRoles.next([])
        this.userActivityRates.next([])
        this.isSameRoleForAllFacilities.next(false)
        this.userType.next(USER_TYPES.NONE)
        this.isConfirmedPhysician.next(false)
        this.addUserTabs.next(cloneDeep(ADD_USER_TABS))
        this.isSubmitting.next(false)
        this.formInvalidStatuses = [true, true, true, true]

    }

    formStatusChanged(formIndex, isInValid) {
        const previousFormStatuses = this.formInvalidStatuses.join('')
        this.formInvalidStatuses[formIndex] = isInValid
        this.formInvalidStatusesSub.next(this.formInvalidStatuses)
        const currentFormStatuses = this.formInvalidStatuses.join('')
        if (previousFormStatuses !== currentFormStatuses) this.updateTabs()
    }

    userTypeUpdated() {
        this.updateTabs()
    }

    physicianUnchecked() {
        this.formInvalidStatuses[USER_TABS_INDEX.FACILITY_ROLES] = true
        this.formInvalidStatuses[USER_TABS_INDEX.ACTIVITY_RATES] = true
        this.formInvalidStatuses[USER_TABS_INDEX.ADDITIONAL_DETAILS] = true
    }

    getActivityRatesToSave(): IActivityRate[] {
        const activityRates= this.userActivityRates.value
        return activityRates.map(activityRate => ({
                        ...activityRate,
                        laborTime: parseFloat(activityRate.laborTime), 
                        administrativeTime: parseFloat(activityRate.administrativeTime),
                        travelTime: parseFloat(activityRate.travelTime)
                    }))
                .filter(activityRate => activityRate.facilities.length)
    }


    // Update Tab logic. Following update tab functions

    updateTabs() {
        const currentTabs = cloneDeep(this.addUserTabs.value)
        this.updateDoneStatusOfTab(currentTabs)
        this.addUserTabs.next(currentTabs)
    }

    private updateDoneStatusOfTab(tabs: TabItem[]) {
        this.initTabs(tabs)
        this.setDoneTabs(tabs)
        this.setAuthorizedTabs(tabs)
    }

    initTabs(tabs: TabItem[]) {
        for(let i=0; i < tabs.length; i++) {
            tabs[i].isDone = false
            tabs[i].underline = false
            if(i+1 < tabs.length) tabs[i + 1].unauthorized = true
        }
        tabs[USER_TABS_INDEX.PERSONAL_INFO].unauthorized = false
    }

    setDoneTabs(tabs: TabItem[]) {
        const validTab = this.getCompletedUserTabNumber()
        for(let i=0; i <= validTab; i++) {
            tabs[i].isDone = true;
            tabs[i].underline = true;
        }
    }

    setAuthorizedTabs(tabs: TabItem[]) {
        const validTab = this.getAuthorizedTabNumber()
        for(let i=0; i <= validTab; i++) {
            tabs[i].unauthorized = false
        }
        if(this.userType.value === USER_TYPES.PHYSICIAN) {
            tabs[USER_TABS_INDEX.ACTIVITY_RATES].unauthorized = true
            if (validTab === USER_TABS_INDEX.ACTIVITY_RATES) tabs[USER_TABS_INDEX.ADDITIONAL_DETAILS].unauthorized = false
        }
        if(this.userType.value === USER_TYPES.THERAPIST) {
            if (validTab === USER_TABS_INDEX.ACTIVITY_RATES) tabs[USER_TABS_INDEX.ADDITIONAL_DETAILS].unauthorized = false
        }
    }

    getCompletedUserTabNumber() {
        let validTab =  this.formInvalidStatuses.indexOf(true)
        if (validTab == -1) validTab = USER_TABS_INDEX.ADDITIONAL_DETAILS
        else validTab -= 1
        if(this.userType.value === USER_TYPES.PHYSICIAN && validTab == USER_TABS_INDEX.FACILITY_ROLES) validTab = USER_TABS_INDEX.ACTIVITY_RATES
        return validTab
    }

    getAuthorizedTabNumber() {
        let validTab =  this.formInvalidStatuses.indexOf(true)
        if(validTab === -1) validTab = USER_TABS_INDEX.ADDITIONAL_DETAILS;
        if(this.userType.value !== USER_TYPES.PHYSICIAN && this.userType.value !== USER_TYPES.THERAPIST && validTab == USER_TABS_INDEX.ADDITIONAL_DETAILS) validTab = USER_TABS_INDEX.ACTIVITY_RATES
        return validTab
    }

    disabledAllTabs() {
        const currentTabs = cloneDeep(this.addUserTabs.value)
        for(let i=0; i <= 3; i++) {
            currentTabs[i].unauthorized = true
        }
        this.addUserTabs.next(currentTabs)
    }

    



}
