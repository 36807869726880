import { createReducer, on } from '@ngrx/store';
import { plannerStateChangedAction, plannerStatesResetAction } from './../actions/planner-state.actions';

import { initialPlannerState } from '@app/store/selectors';
export const plannerStatesReducer = createReducer(
    initialPlannerState,
    on(plannerStateChangedAction, (state, { selectedPlannerState }) => selectedPlannerState),

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    on(plannerStatesResetAction, (state) => initialPlannerState)
);
