import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'minutesToHours',
})
export class MinutesToHoursPipe implements PipeTransform {
    transform(minutes: number, display?: string): unknown {
        const hour = (minutes / 60) ^ 0;
        const mint = minutes % 60;
        if (display === 'short') {
            //for scheduler main layout
            if (hour === 0 && mint === 0) return `0`;
            else {
                if (mint === 0 && hour !== 0) {
                    return `${hour}h `;
                } else if (hour === 0 && mint !== 0) {
                    return `${mint}m `;
                } else {
                    return `${hour}h ${mint}m`;
                }
            }
        } else if (display === 'time') {
            //for scheduler right bar availability
            const formattedHours = hour.toString().padStart(2, '0');
            const formattedMinutes = mint.toString().padStart(2, '0');

            return `${formattedHours}:${formattedMinutes}`;
        } else {
            // scheduler right bar
            if (hour === 0 && mint === 0) return `00`;
            else return `${hour}h ${mint}min`;
        }
    }
}
