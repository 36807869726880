import { Injectable } from '@angular/core';
import { CanDeactivate, UrlTree } from '@angular/router';
import { AddPatientLayoutComponent } from '@app/modules/add-patient/add-patient-layout/add-patient-layout.component';
import { AppService, ModalService } from '@app/services';
import { BackConfirmationDialogComponent } from '@app/shared/go-back/back-confirmation-dialog/back-confirmation-dialog.component';
import { PatientState } from '@app/store/store.interface';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ROUTER_UTILS } from '@app/helpers';
import { FacilityAdmission } from '@app/interfaces';

@Injectable({
    providedIn: 'root',
})
export class AdmissionFormGuard implements CanDeactivate<AddPatientLayoutComponent> {
    patient$: Observable<any>;
    isPatientInfoFilled: boolean;
    facilityAdmission$: Observable<any>;
    isFacilityAdmissionAdded: boolean;
    targetRoute: string;
    constructor(
        private store: Store<{
            patient: PatientState;
            facilityAdmission: FacilityAdmission;
        }>,
        private modal: ModalService,
        private appService: AppService
    ) {
        this.appService.targetRoute.subscribe((val) => {
            this.targetRoute = val;
        });
        this.patient$ = this.store.pipe(select('patient'));
        this.patient$.subscribe((patient) => {
            this.isPatientInfoFilled = patient?.firstName
        });
        this.facilityAdmission$ = this.store.pipe(select('facilityAdmission'));
        this.facilityAdmission$.subscribe((facilityAdmission) => {
            this.isFacilityAdmissionAdded = facilityAdmission?.id
        });
    }
    canDeactivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (
            !this.isFacilityAdmissionAdded && this.isPatientInfoFilled &&
            !this.targetRoute.includes(ROUTER_UTILS.config.therapyAdmission.show.root) &&
            !this.targetRoute.includes(ROUTER_UTILS.config.therapyAdmission.add.root) &&
            !this.targetRoute.includes(ROUTER_UTILS.config.patients.pcc.root) &&
            !this.targetRoute.includes(ROUTER_UTILS.config.payorInformation.insuranceCoverage.add.root) &&
            !this.targetRoute.includes('payor-information')
        ) {
            const dialogRef = this.modal.open(BackConfirmationDialogComponent, {
                data: { heading: 'Confirmation' },
                width: '520px',
            });
            return dialogRef.afterClosed();
        } else {
            return true;
        }
    }
}
