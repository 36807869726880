/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
    selector: 'app-multi-select',
    templateUrl: './multi-select.component.html',
    styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent implements OnChanges, ControlValueAccessor, OnInit {
    @Input() selectedOptions: any = [];
    filteredOptions: any = [];

    @Input('options') selectOptions = [];
    @Input() label: string;
    @Input() subLabel: string;
    @Input() placeholder = 'Choose options';
    @Input() backgroundClass: string;
    @Input() foregroundClass: string;
    @Input() class: string;
    @Input() labelClass = '';
    @Input() inputClass = '';
    @Input() formGroupClass = '';
    @Input() formGroupStyle = '';
    @Input() selectStyle = '';
    @Input() labelLeft = false;
    @Input() selected = '';
    @Input() customSelect = false;
    @Input() disabledOptions = []; //if someone wants to disabled any selected option to remove
    @Input() showSelectedOptions = true
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    propagateChange = (_: any) => {};
    onTouched = () => {};

    value: any = '';
    @Input() disabled: boolean;
    @Output() optionsSelected = new EventEmitter();
    constructor(public ngControl: NgControl) {
        ngControl.valueAccessor = this;
    }
    ngOnInit(): void {
        const control: AbstractControl = this.ngControl.control;

        control.valueChanges.subscribe((value) => {
            if (Array.isArray(value)) {
                this.selectedOptions = [];
                value.forEach((v) => {
                    const option = this.selectOptions.find((opt) => (opt.id ? opt.id == v : opt == v));
                    if (option) {
                        this.selectedOptions.push(option);
                    }
                });
            } else if (value) {
                this.selectedOptions = [];
                this.selectedOptions = this.selectOptions.filter((opt) => value.includes(opt.id));
            }
        });
    }

    ngOnChanges(): void {
        this.filteredOptions = [...this.selectOptions];
    }
    searchOptions(search: string) {
        if(typeof search === 'string') {
            this.filteredOptions = this.selectOptions.filter(
                (op) =>
                    (op.name && op.name.toLowerCase().includes(search?.toLowerCase())) ||
                    (!op.name && op.toLowerCase().includes(search?.toLowerCase()))
            );
        } else {
            this.filteredOptions = this.selectOptions
        }
    }
    selectOption(option: any) {
        if(this.checkDisabled(option.id)) return
        const index = this.selectedOptions.findIndex(
            (op) => (op.id && op.id === option.id) || (!op.id && op == option)
        );
        let options = [];
        if (index > -1) {
            this.selectedOptions.splice(index, 1);
            options = [...this.selectedOptions];
        } else {
            options = [...this.selectedOptions, option];
        }

        if (option.id) {
            const ids = options.map((option) => option.id);
            this.propagateChange(ids);
            this.optionsSelected.emit(ids);
        } else {
            this.optionsSelected.emit(options);
            this.propagateChange(options);
        }
    }
    checkActive(option: any) {
        return this.selectedOptions.find((op) => (op.id && op.id === option.id) || (!op.id && op == option))
            ? true
            : false;
    }
    checkDisabled(optionId: any): boolean {
        return this.disabledOptions.findIndex(disabledOption => disabledOption.id === optionId) !== -1
    }
    get isRequired(): boolean {
        const control: AbstractControl = this.ngControl.control;

        if (!control || control && control.validator === null) return false;
        return control.validator(control)?.required ?? false;
    }

    get hasError(): boolean {
        const control: AbstractControl = this.ngControl.control;
        if (control && control.touched && control.invalid) return true;
        return false;
    }
    writeValue(obj: any): void {
        if (obj) {
            this.value = obj;
            this.selectedOptions = this.selectOptions.filter(option => this.value.includes(option.id))
        }
        else this.value = '';
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
