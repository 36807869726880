/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { cloneDeep } from 'lodash';

@Component({
    selector: 'app-select-with-search',
    templateUrl: './select-with-search.component.html',
    styleUrls: ['./select-with-search.component.scss'],
})
export class SelectWithSearchComponent implements OnChanges, ControlValueAccessor {
    @Input() label: string;
    @Input() placeholder: string;
    @Input() placement: string;
    @Input() options: [];

    @Output() optionChanged: EventEmitter<void> = new EventEmitter();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    propagateChange = (_: any) => {};
    onTouched = () => {};

    value: any;
    showSearch = true;
    @Input() disabled = false;
    filteredOptions: any = [];

    constructor(public ngControl: NgControl) {
        ngControl.valueAccessor = this;
    }
    ngOnChanges(): void {
        this.filteredOptions = cloneDeep(this.options);
        this.filteredOptions = this.filteredOptions.sort((a: string, b: string) => a.localeCompare(b));
    }

    writeValue(obj: any): void {
        if (obj) this.value = obj;
        else this.value = undefined;
    }

    getType(event: number) {
        if (event <= 50) return 'secondary-400';
        else if (event > 50 && event <= 80) return 'warning-400';
        else if (event > 80) return 'danger-100';
    }

    optionSelect(event: any) {
        this.value = event;
        this.propagateChange(event);
        this.optionChanged.emit(event);
    }
    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
    search(searchValue) {
        if (typeof searchValue === 'string' && searchValue != '') {
            this.filteredOptions = this.options.filter((option: string) =>
                option.toLowerCase().includes(searchValue.toLowerCase())
            );
        } else {
            this.filteredOptions = this.options;
        }
        this.filteredOptions = this.filteredOptions.sort((a: string, b: string) => a.localeCompare(b));
    }

    dropdownOpenChanged(isDropDownOpen: boolean) {
        if(!isDropDownOpen) {
            this.search('')
            this.showSearch = false
        } else {
            this.showSearch = true
        }
    }
}
