<div class="custom-card h-100">
    <div *ngIf="paragraphTitle" class="header d-flex flex-row align-items-center justify-content-between">
        <p class="text-secondary">{{ paragraphTitle }}</p>
    </div>
    <div class="body d-flex flex-row justify-content-between p-3" style="height: auto">
        <ng-container *ngIf="innerHtml">
            <p class="body-medium text-secondary" [innerHtml]="innerHtml"></p>
            <span
                *ngIf="pinToRightText"
                class="body-medium text-secondary pin-block"
                [innerHtml]="pinToRightText"
            ></span>
        </ng-container>
        <ng-container>
            <p *ngIf="!innerHtml" class="body-medium text-secondary">
                {{ paragraph || 'No Data Available' }}
            </p>
        </ng-container>
    </div>
</div>
