import { WeekDay } from '@app/interfaces';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'isTherapistAvailable',
    pure: true,
})
export class isTherapistAvailablePipe implements PipeTransform {
    transform(therapist: any, day: WeekDay) {
        return therapist?.availability?.days?.[day].available;
    }
}
