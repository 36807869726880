import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { isArray } from 'lodash';

@Component({
    selector: 'app-vitals',
    templateUrl: './vitals.component.html',
    styleUrls: ['./vitals.component.scss'],
})
export class VitalsComponent implements OnInit {
    @Input() vitals;
    @Input() disabled = false;
    @Output() changeVitals = new EventEmitter();

    temperatureScale = 'F';

    vitalsForm = new FormGroup({
        respiratoryRate: new FormControl(),
        temperature: new FormControl(),
        pulseRate: new FormControl(),
        bloodPressure: new FormControl(),
        SBP: new FormControl(),
        DBP: new FormControl(),
        o2Saturation: new FormControl(),
        note: new FormControl(),
    });

    constructor(private cd: ChangeDetectorRef) {
        this.vitalsForm.valueChanges.subscribe((x) => {
            if (x.SBP || x.DBP) {
                this.vitalsForm.get('SBP').setValidators(Validators.required);
                this.vitalsForm.get('DBP').setValidators(Validators.required);
                this.vitalsForm.markAllAsTouched();
                this.vitalsForm.get('SBP').updateValueAndValidity({ emitEvent: false, onlySelf: true });
                this.vitalsForm.get('DBP').updateValueAndValidity({ emitEvent: false, onlySelf: true });
                this.vitalsForm.patchValue(
                    {
                        bloodPressure: `${x.SBP}/${x.DBP}`,
                    },
                    { emitEvent: false, onlySelf: true }
                );
            } else {
                this.vitalsForm.get('SBP').removeValidators(Validators.required);
                this.vitalsForm.get('DBP').removeValidators(Validators.required);
                this.vitalsForm.markAllAsTouched();
                this.vitalsForm.get('SBP').updateValueAndValidity({ emitEvent: false, onlySelf: true });
                this.vitalsForm.get('DBP').updateValueAndValidity({ emitEvent: false, onlySelf: true });
                this.vitalsForm.patchValue(
                    {
                        bloodPressure: '',
                    },
                    { emitEvent: false, onlySelf: true }
                );
            }
        });
        this.vitalsForm.get('temperature').valueChanges.subscribe((x) => {
            const currentTemperatureScale = x.includes('C') ? 'C' : 'F';
            if (currentTemperatureScale !== this.temperatureScale) {
                let convertedTemperature;
                if (currentTemperatureScale === 'C') {
                    convertedTemperature = ((parseFloat(x.replace('F', '')) - 32) * 5) / 9;
                } else {
                    convertedTemperature = (parseFloat(x.replace('C', '')) * 9) / 5 + 32;
                }
                this.vitalsForm.patchValue(
                    {
                        temperature: `${convertedTemperature.toFixed(2)}`,
                    },
                    { emitEvent: false, onlySelf: true }
                );
                this.temperatureScale = currentTemperatureScale;
            }
        });
    }
    ngOnInit(): void {
        // Special case for editing
        if (isArray(this.vitals)) {
            let vitalsCopy = JSON.parse(JSON.stringify(this.vitals));
            vitalsCopy = vitalsCopy.pop();
            if (Object.keys(vitalsCopy).length !== 0) {
                const BP = vitalsCopy?.bloodPressure?.replaceAll(' ', '')?.split('/');
                vitalsCopy['SBP'] = BP?.[0];
                vitalsCopy['DBP'] = BP?.[1];
                this.temperatureScale = vitalsCopy.temperature?.includes('C') ? 'C' : 'F';
                vitalsCopy.temperature = vitalsCopy.temperature?.replace(/[FC]/, '');
            }
            this.vitalsForm.patchValue(vitalsCopy, { emitEvent: false, onlySelf: true });
        } else if (Object.keys(this.vitals).length !== 0) {
            const BP = this.vitals?.bloodPressure?.replaceAll(' ', '')?.split('/');
            this.vitals['SBP'] = BP?.[0];
            this.vitals['DBP'] = BP?.[1];
            this.temperatureScale = this.vitals.temperature?.includes('C') ? 'C' : 'F';
            this.vitals.temperature = this.vitals.temperature?.replace(/[FC]/, '');
            this.vitalsForm.patchValue(this.vitals, { emitEvent: false, onlySelf: true });
        } else this.vitalsForm.patchValue(this.vitals, { emitEvent: false, onlySelf: true });
    }

    onChange() {
        if (
            this.vitalsForm.value.temperature &&
            !this.vitalsForm.value.temperature.includes('C') &&
            !this.vitalsForm.value.temperature.includes('F')
        )
            this.vitalsForm.value.temperature = `${this.vitalsForm.value.temperature}${this.temperatureScale}`;
        this.changeVitals.emit(this.vitalsForm);
    }
}
