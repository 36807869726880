<div class="custom-card">
    <div class="header">
        <p>Patient Demographics</p>
    </div>
    <div class="body card-padding">
        <div class="row">
            <div class="pl-3" id="toggle-wrapper">
                <app-toggle [buttons]="tabs" (buttonChanged)="setSelectedTab($event)"  [fullWidth]="false"></app-toggle>
            </div>
        </div>
        <!-- Tabs Section Start -->
        <ng-container *ngIf="selectedTab.text === 'Precautions'">
            <div class="mt-3">
                <app-precautions
                    [isReadonly]="!editableDemographics.includes('Precautions')"
                    [precautionsData]="precautions"
                    [isEditMode]="isEditMode"
                    (update)="updateDemographics($event, 'precautions')"
                ></app-precautions>
            </div>
        </ng-container>
        <ng-container *ngIf="selectedTab.text === 'Medical Diagnosis'">
            <div class="mt-3">
                <!-- <app-searchable-list
                    [isReadOnly]="!editableDemographics.includes('Medical Diagnosis')"
                    [recordsData]="medicalDiagnosis"
                    [recordsInput]="medicalDiagnosis"
                    [name]="SEARCHABLE_LIST_NAMES.MEDICAL_DIAGNOSIS"
                    (recordsSave)="updateDemographics($event, 'medicalDiagnosis')"
                    [withOutHeader]="!editableDemographics.includes('Medical Diagnosis')"
                    [maxOnSetDate]="maxOnSetDate"
                >
                </app-searchable-list> -->
                <!-- <div #MedicalDiagnosis class="mt-4"> -->
                    <app-searchable-data-grid
                        [isReadOnly]="!editableDemographics.includes('Medical Diagnosis')"
                        [recordsData]="medicalDiagnosis"
                        [recordsInput]="medicalDiagnosis"
                        [name]="SEARCHABLE_LIST_NAMES.MEDICAL_DIAGNOSIS"
                        (recordsSave)="updateDemographics($event, 'medicalDiagnosis')"
                        [withOutHeader]="!editableDemographics.includes('Medical Diagnosis')"
                        [maxOnSetDate]="maxOnSetDate"
                        heading="Medical Diagnosis"
                    >
                    </app-searchable-data-grid>
                <!-- </div> -->
            </div>
        </ng-container>
        <ng-container *ngIf="selectedTab.text === 'Treatment Diagnosis'">
            <div class="mt-3">
                <app-searchable-data-grid
                    [isReadOnly]="!editableDemographics.includes('Treatment Diagnosis')"
                    [recordsData]="treatmentDiagnosis"
                    [recordsInput]="treatmentDiagnosis"
                    [name]="SEARCHABLE_LIST_NAMES.TREATMENT_DIAGNOSIS"
                    (recordsSave)="updateDemographics($event, 'treatmentDiagnosis')"
                    [withOutHeader]="!editableDemographics.includes('Treatment Diagnosis')"
                    [maxOnSetDate]="maxOnSetDate"
                    heading="Treatment Diagnosis"
                    [currentUrl]="currentUrl"
                ></app-searchable-data-grid>
            </div>
        </ng-container>
        <ng-container *ngIf="selectedTab.text === 'Contraindications'">
            <div class="mt-3">
                <div class="mt-1" #Contraindications>
                    <app-contraindications
                        [isReadonly]="!editableDemographics.includes('Contraindications')"
                        [contraindicationsData]="contraindications"
                        [isEditMode]="isEditMode"
                        (update)="updateDemographics($event, 'contraindications')"
                    ></app-contraindications>
                </div>
            </div>
        </ng-container>
        <!-- Tabs Section End -->
    </div>
</div>
