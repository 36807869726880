<div class="list-container row text-secondary body-medium-semibold">
    <div
        *ngFor="let item of listItems; let i = index"
        class="col-12 list-row cursor-pointer"
        (click)="selectItem(item)"
    >
        <div class="row">
            <div class="col-1 mr-0">
                <span [class.text-primary]="item.isCompleted">{{ i + 1 }}</span>
            </div>
            <div id="gg-score-box" class="col-1 pr-1" *ngIf="hasIconColumn">
                <span *ngIf="item.hasGGScore"><img src="assets/icons/gg-score.svg" height="25" width="25" /></span>
                <span *ngIf="item.systemSupported"
                    ><img src="assets/icons/system-supported-test.svg" height="20" width="20"
                /></span>
            </div>
            <div id="std-test-name" class="col-10 pr-1 ml-n5 {{ paddingLeft }}">
                <span [class.text-primary]="item.isCompleted">{{ item.text }}</span>
            </div>
        </div>
    </div>
</div>
