/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Injectable, Input, OnChanges, OnInit, ViewChild } from '@angular/core';

import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
// import { IMaskDirective } from 'angular-imask';

/**
 * This handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
    readonly DELIMITER = '/';

    parse(value: string): NgbDateStruct | null {
        if (value) {
            const date = value.split(this.DELIMITER);
            return {
                day: parseInt(date[0], 10),
                month: parseInt(date[1], 10),
                year: parseInt(date[2], 10),
            };
        }
        return null;
    }

    format(date: NgbDateStruct | null): string {
        return date ? date.month + this.DELIMITER + date.day + this.DELIMITER + date.year : '';

        // return date ? this.pad(date.month) + this.DELIMITER + this.pad(date.day) + this.DELIMITER + date.year : '';
        // return date ? date.month + this.DELIMITER + date.day + this.DELIMITER + date.year : '';
    }

    private isNumber(value: any): value is number {
        return !isNaN(Number(value));
    }

    private pad(value: number): string {
        if (this.isNumber(value)) {
            return `0${value}`.slice(-2);
        } else return '';
    }
}

@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
    providers: [{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }],
})
export class DatePickerComponent implements OnInit, OnChanges, ControlValueAccessor {
    // @ViewChild(IMaskDirective, { static: false }) iMask: IMaskDirective<IMask.MaskedNumber>;
    @Input() label: string;
    @Input() labelClass: string;
    @Input() minDate: any = '01/01/1900';
    @Input() maxDate;
    @Input() placeholder = 'MM/DD/YYYY';
    @Input() tooltip = false;
    @Input() backgroundClass = 'bg-body-background';
    @Input() calendarOnly = false;
    @Input() daysToDisable = [];
    @Input() formGroupClass: string;
    @Input() inputWrapperClass: string;
    @Input() inputClass: string;
    @Input() inputStyle: string;
    @Input() disabled: boolean;
    @Input() editIconOnly = false;
    @Input() container = '';
    @Input() format = 'local';
    @Input() applyBackground = true;
    @Input() placement = 'bottom';
    @Input() hideDotBadge: boolean;
    @Input() disableDate: '';
    @Input() tooltipText: '';
    @Input() positionTarget = undefined;
    @Input() showInfoToolTip = false;
    @Input() showErrorState = false;
    @Input() infoToolTipMsg = '';
    @Input() colorRed = false;
    // @Input() insideTable = false;
    @ViewChild('d') datepicker: NgbDatepicker;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    propagateChange = (_: any) => {};
    onTouched = () => {};
    minDateLocal;
    maxDateLocal;
    constructor(public readonly ngControl: NgControl, private calendar: NgbCalendar) {
        ngControl.valueAccessor = this;
    }

    value: any;

    markDisabled: any;
    model: NgbDateStruct;
    // @ViewChild('inputElement') inputElement: ElementRef;
    // @Input() mask = '00/00/0000';
    ngOnInit(): void {
        this.markDisabledDays();
        this.formGroupClass = `form-group wv-date-holder ${this.formGroupClass}`;
        this.inputClass = `form-control border-right-0 ${this.inputClass}`;
        this.inputStyle = `background-color: transparent !important; ${this.inputStyle}`;
        // this.minDateLocal = this.formatForNgb(this.minDate);
        // this.maxDateLocal = this.formatForNgb(this.maxDate);

        // const control: AbstractControl = this.ngControl.control;
        // if (control) {
        //     control.addValidators([this.minDateValidator(this.minDateLocal), this.maxDateValidator(this.maxDateLocal)]);
        //     control.updateValueAndValidity();
        // }
    }

    // ngAfterViewInit(): void {
    //     this.inputElement.nativeElement.addEventListener('input', this.onInput.bind(this));
    // }

    ngOnChanges(): void {
        this.minDateLocal = this.formatForNgb(this.minDate);
        this.maxDateLocal = this.formatForNgb(this.maxDate);
    }

    get isRequired(): boolean {
        const control: AbstractControl = this.ngControl.control;
        // console.log(control.errors)
        if (control.validator === null) return false;
        return control.validator(control)?.required ?? false;
    }
    get hasError(): boolean {
        const control: AbstractControl = this.ngControl.control;
        if (control && (control.dirty || control.touched) && control.invalid) return true;
        return false;
    }

    writeValue(obj: any): void {
        // const padStart = (value: number): string => value.toString().padStart(2, '0');
        if (obj) {
            this.value = obj;
            this.model = this.formatForNgb(obj);
        } else this.value = '';
    }

    dateSelect(date: any) {
        this.value = date ? date.month + '/' + date.day + '/' + date.year : '';
        this.propagateChange(this.toDateJs(date).toString());
    }

    toDateJs(date: NgbDateStruct): Date {
        const fnsDate = new Date(date.year, date.month - 1, date.day);

        return date ? fnsDate : null;
    }

    formatForNgb(value: string) {
        const date = new Date(value);
        return {
            year: this.format == 'local' ? date.getFullYear() : new Date(date).getUTCFullYear(),
            month: this.format == 'local' ? date.getMonth() + 1 : new Date(date).getUTCMonth() + 1,
            day: this.format == 'local' ? date.getDate() : new Date(date).getDate(),
        };
    }

    isDisabled = (date: NgbDate, current: { month: number; year: number }) => date.month !== current.month;
    markDisabledDays() {
        if (!this.daysToDisable.length) {
            this.markDisabled = (date: NgbDate, current: { month: number; year: number }) =>
                date.month !== current.month;
        } else {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            this.markDisabled = (date: NgbDate, current: { month: number; year: number }) => {
                return this.daysToDisable.find((v) => {
                    return new NgbDate(v.year, v.month, v.day).equals(date) ? true : false;
                });
            };
        }
    }
    markDisabledEvent(event: any) {
        return this.daysToDisable?.find((v) => new NgbDate(v.year, v.month, v.day).equals(event)) ? true : false;
    }
    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
    handleKeyPress(event: KeyboardEvent, datepicker) {
        if (event.key === 'Tab') {
            datepicker.toggle();
        }
    }

    // onInput(event: Event) {
    //     const input = (event.target as HTMLInputElement).value;
    //     if (input === '') {
    //         this.clearDate();
    //     } else {
    //         const parsedDate = this.parseInput(input);
    //         if (parsedDate) {
    //             this.dateSelect(parsedDate);
    //         }
    //     }
    // }
    // onFocus() {
    //     this.iMask.maskRef.updateValue();
    // }

    // clearDate() {
    //     this.value = null;
    //     this.model = null;
    //     const control: AbstractControl = this.ngControl.control;
    //     control.setValue(null);
    //     control.markAsTouched();
    //     control.updateValueAndValidity();
    //     this.propagateChange(null);
    // }

    // parseInput(value: string): NgbDateStruct | null {
    //     return new CustomDateParserFormatter().parse(value);
    // }

    // minDateValidator(minDate: NgbDateStruct): ValidatorFn {
    //     return (control: AbstractControl): ValidationErrors | null => {
    //         if (!control.value) return null;
    //         const date = new Date(control.value);
    //         const min = new Date(`${minDate.year}-${('0' + minDate.month).slice(-2)}-${('0' + minDate.day).slice(-2)}`);
    //         return date < min ? { minDate: { value: control.value } } : null;
    //     };
    // }

    // maxDateValidator(maxDate: NgbDateStruct): ValidatorFn {
    //     return (control: AbstractControl): ValidationErrors | null => {
    //         if (!control.value) return null;
    //         const date = new Date(control.value);
    //         if (isNaN(date.getTime())) {
    //             return { invalidDate: { value: control.value } };
    //         }
    //         const max = new Date(`${maxDate.year}-${('0' + maxDate.month).slice(-2)}-${('0' + maxDate.day).slice(-2)}`);
    //         return date > max ? { maxDate: { value: control.value } } : null;
    //     };
    // }
}
