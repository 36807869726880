/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, OnInit } from '@angular/core';
import { DialogRef } from '@app/services';

@Component({
    selector: 'app-warning-confirmation-dialog',
    templateUrl: './warning-confirmation-dialog.component.html',
    styleUrls: ['./warning-confirmation-dialog.component.scss'],
})
export class WarningConfirmationDialogComponent implements OnInit {
    constructor(private dialogRef: DialogRef) {}

    ngOnInit(): void {}
    deleteAction(isDeleted) {
        this.dialogRef.close(isDeleted);
    }
}
