<app-base-modal-card
    [showFooter]="false"
    [heading]="content.heading"
      [wrapperClass]="content.heading === 'Approve Hours' ? 'modal-time' : isOpen ? 'modal-medium modal-mobile modal-mobile-open' : 'modal-medium modal-mobile modal-mobile-close'"
    (close)="onClose(false)"
>
    <div body class="card-wrapping" [formGroup]="commentForm">
        <app-text-box formControlName="comment"></app-text-box>
        <div class="d-flex justify-content-end mt-2 mb-2">
            <app-button
                [buttonClass]="'btn-secondary innova-btn standard-btn innova-btn-md body-regular-light-bold-14'"
                type="secondary"
                [disabled]="commentForm.invalid"
                text="{{ content.btn2Text }}"
                (click)="addComment()"
            >
            </app-button>
        </div>
    </div>
</app-base-modal-card>
