<div ngbDropdown class="d-inline-block" [container]="'body'">
    <!-- dots -->

    <img
        [ngClass]="{
            'opacity-50 pointer-events-none': checkDisabled()
        }"
        loading="lazy"
        src="../../../../assets/icons/ellipsis-colored.svg"
       height="{{ !mobSizeEnabled ? '20' : '0' }}"
        width="{{ !mobSizeEnabled ? '20' : '0' }}"
        style="cursor: pointer"
        ngbDropdownToggle
    />

    <!-- dropdown options -->
      <!-- colworx-ak add option.name?.toLowerCase() === 'terminated' for change text color of dropdown terminated text -->

    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <ng-container *ngFor="let option of options">
            <button
                [ngClass]="{
                    'default-text': !option.colorClass?.length,
                    'text-danger':
                        option.name?.toLowerCase() === 'delete' ||
                        option.name?.toLowerCase() === 'undo visit status' ||
                        option.name?.toLowerCase() === 'delete note' ||
                        option?.colorClass?.length ||
                        option.name === 'Cancel Invite' ||
                        option.name === 'Terminate User' ||
                        option.name === 'Activate User' ||
                        option.name === 'Re-activate User' ||
                        option.name?.toLowerCase() === 'terminated' ||
                        option.name?.toLowerCase() === 'cancel invite',
                    'opacity-50 pointer-events-none': option.disabled
                }"
                class="body-small"
                ngbDropdownItem
                (click)="dottedDropdownSelect(option.name)"
            >
                {{ option.disablePipe ? option.name : (option.name | titlecase) }}
            </button>
            <ng-container *ngIf="option.children">
                <button
                    *ngFor="let child of option.children"
                    [ngClass]="{
                        'default-text': !child.colorClass?.length,
                        'font-weight-bold': true,
                        'ml-1': true
                    }"
                    class="body-small"
                    ngbDropdownItem
                    (click)="dottedDropdownSelect(child.id)"
                >
                    {{ child.disablePipe ? child.name : (child.name | titlecase) }}
                </button>
            </ng-container>
        </ng-container>
    </div>
</div>
