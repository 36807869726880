<div class="custom-card">
    <div class="header">
        <p class="text-secondary">{{ tableTitle }}</p>
    </div>
    <div class="body" style="padding: 20px;">
       


        <div *ngFor="let goal of goalData" class="topdiv">

            <div class="firstTbale">
                <div class="row full-width">
                    <div class="col">
                        <span class="text-secondary body-large-bold" [innerHtml]="goal?.goal">{{ goal?.goal }}</span>
                        <span style="font-size: 14px; font-weight: 500;" class="text-secondary ml-2">({{ goal?.targetDate | date: 'MM-dd-yyyy' }})</span>
                    </div>
                </div>
                <div class="row full-width">
                    <div class="col">
                        <strong class="text-secondary body-medium-semibold">PLOF: </strong>
                        <span
                            class="header-text"
                            [class.align-middle]="isHistoryMode"
                            [innerHtml]="goal?.plof.levelOfFunction ? goal.plof.levelOfFunction : 'N/A'"
                            >{{ goal?.plof.levelOfFunction }}</span>
                    </div>
                    <div class="col">
                        <strong class="text-secondary body-medium-semibold">CLOF: </strong>
                        <span
                            class="header-text"
                            [class.align-middle]="isHistoryMode"
                            [innerHtml]="goal?.clof.levelOfFunction"
                            >{{ goal?.clof.levelOfFunction }}</span>
                    </div>
                    <div class="col">
                        <strong class="body-medium-semibold" style="color: orangered;">Target: </strong>
                        <span
                            class="header-text"
                            [class.align-middle]="isHistoryMode"
                            [innerHtml]="goal?.target"
                            >{{ goal?.target }}</span>
                    </div>
                </div>
            </div>
           
        
            <ng-container *ngIf="goal?.stgs?.length > 0">
                <ng-container *ngFor="let subGoal of goal.stgs">
                    <div class="row full-width">
                        <div class="col">
                            <span style="color: orangered;" class="body-large-bold">STG-1: {{ subGoal?.goal }}</span>
                            <span style="font-size: 14px; font-weight: 500;" class="text-secondary ml-2">({{ subGoal?.targetDate | date: 'MM-dd-yyyy' }})</span>
                        </div>
                    </div>
                    <div class="row full-width">
                        <div class="col">
                            <strong class="text-secondary body-medium-semibold">PLOF: </strong>
                            <span
                                class="header-text"
                                [class.align-middle]="isHistoryMode"
                                [innerHtml]="subGoal?.plof.levelOfFunction ? subGoal.plof.levelOfFunction : 'N/A'"
                                >{{ subGoal?.plof.levelOfFunction }}</span>
                        </div>
                        <div class="col">
                            <strong class="text-secondary body-medium-semibold">CLOF: </strong>
                            <span
                                class="header-text"
                                [class.align-middle]="isHistoryMode"
                                [innerHtml]="subGoal.clof.levelOfFunction"
                                >{{ subGoal?.clof.levelOfFunction }}</span>
                        </div>
                        <div class="col">
                            <strong class=" body-medium-semibold" style="color: orangered;">Target: </strong>
                            <span
                                class="header-text"
                                [class.align-middle]="isHistoryMode"
                                [innerHtml]="subGoal?.target"
                                >{{ subGoal?.target }}</span>
                        </div>
                    </div>
                    <div class="row full-width">
                        <div class="col-12">
                            <div class="bg-secondary-a20 mr-left" style="height: 1px"></div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>


  <!-- <table class="table table-borderless table-responsive"
         style="width: 100%; 
          ">
            <div *ngFor="let goal of goalData" class="topdiv">
                <tr>
                    <td class="firstTbale">
                        <span class="text-secondary body-large-bold" [innerHtml]="goal?.goal">{{ goal?.goal}}</span>
                        <span style="font-size: 14px;font-weight: 500;" class="text-secondary ml-2">({{ goal?.targetDate | date: 'MM-dd-yyyy'}})</span>
                        <tr>
                            <td style="padding-left: 0px !important">
                                <strong class="text-secondary body-medium-semibold">PLOF: </strong>
                                <span
                                    class="header-text"
                                    [class.align-middle]="isHistoryMode"
                                    [innerHtml]="goal?.plof.levelOfFunction ? goal.plof.levelOfFunction : 'N/A'"
                                    >{{ goal?.plof.levelOfFunction }}</span
                                >
                            </td>
                            <td style="width:33%">
                                <strong class="text-secondary body-medium-semibold">CLOF: </strong>
                                <span
                                    class="header-text"
                                    [class.align-middle]="isHistoryMode"
                                    [innerHtml]="goal?.clof.levelOfFunction"
                                    >{{ goal?.clof.levelOfFunction }}</span
                                >
                            </td>
                            <td>
                                <strong class=" body-medium-semibold" style="color: orangered;">Target: </strong>
                                <span
                                    class="header-text"
                                    [class.align-middle]="isHistoryMode"
                                    [innerHtml]="goal?.target"
                                    >{{ goal?.target }}</span
                                >
                            </td>
                          
                        </tr>
                     
                    </td>
                </tr>
                <ng-container *ngIf="goal?.stgs?.length > 0">
                    <ng-container *ngFor="let subGoal of goal.stgs">
                        <tr>
                            <td class="subRow">
                                <span style="color: orangered;" class=" body-large-bold">STG-1:{{
                                    subGoal?.goal}}</span>
                                    <span style="font-size: 14px;font-weight: 500;" class="text-secondary ml-2">({{ subGoal?.targetDate | date: 'MM-dd-yyyy'}})</span>
                                <tr>
                                    <td style="padding-left: 0px !important">
                                        <strong class="text-secondary body-medium-semibold">PLOF: </strong>
                                        <span
                                            class="header-text"
                                            [class.align-middle]="isHistoryMode"
                                            [innerHtml]="subGoal?.plof.levelOfFunction  ? subGoal.plof.levelOfFunction  : 'N/A'"
                                            >{{ subGoal?.plof.levelOfFunction  }}</span
                                        >
                                    </td>
                                    <td>
                                        <strong class="text-secondary body-medium-semibold">CLOF: </strong>
                                        <span
                                            class="header-text"
                                            [class.align-middle]="isHistoryMode"
                                            [innerHtml]="subGoal.clof.levelOfFunction"
                                            >{{ subGoal?.clof.levelOfFunction }}</span
                                        >
                                    </td>
                                    <td>
                                        <strong class="text-secondary body-medium-semibold">Target: </strong>
                                        <span
                                            class="header-text"
                                            [class.align-middle]="isHistoryMode"
                                            [innerHtml]="subGoal?.target"
                                            >{{ subGoal?.target }}</span
                                        >
                                    </td>
                                </tr>
                         
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <div class="bg-secondary-a20 mr-left" style="height: 1px"></div>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
            </div>
        </table> -->