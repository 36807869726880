<div *ngIf="!(loadingObs | async)?.isLoading" class="d-flex flex-row">
    <div class="col-12 px-4">
        <div class="pt-4 pb-2">
            <organization-header></organization-header>
        </div>

        <div class="row p-0 m-0 bg-white p-3 mt-2 px-4">
            <div class="w-100">
                <div class="mt-3">
                    <div class="d-flex flex-row justify-content-between">
                        <h6 class="h6-bold text-secondary">Facilities</h6>
                        <!-- <app-button
                            tooltipText="Add New Facility"
                            tooltipPlacement="top"
                            [circle]="true"
                            size="sm"
                            [routerLink]="newFacilityURL"
                            type="secondary"
                            text=""
                            icon="add"
                        ></app-button> -->
                    </div>
                </div>
                <div class="w-100 my-3">
                    <div style="border: 1px solid #000000; opacity: 0.1"></div>
                </div>
                <app-facility-list [facilities]="facilityListData"></app-facility-list>
            </div>
        </div>
    </div>
</div>
