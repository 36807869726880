import { createReducer, on } from '@ngrx/store';
import { caseloadCurrentDateAction, patientChangedAction, patientRemovedAction } from '@app/store/actions/index';
import { initialCaseloadCalendarState, initialPatient } from '@app/store/selectors';

export const patientReducer = createReducer(
    initialPatient,
    on(patientChangedAction, (state, { patient }) => ({
        ...patient,
    })),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    on(patientRemovedAction, (state) => ({
        ...initialPatient,
    }))
);

export const caseloadCalendarReducer = createReducer(
    initialCaseloadCalendarState,
    on(caseloadCurrentDateAction, (state, { selectedDate }) => ({
        selectedDate: selectedDate,
    })),

);
