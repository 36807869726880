<div *ngIf="showPicker" class="d-flex flex-row">
    <input
        readonly
        type="text"
        class="form-control"
        name="dp"
        placeholder="MM/DD/YYYY"
        ngbDatepicker
        [(ngModel)]="model"
        (click)="d.toggle()"
        #d="ngbDatepicker"
        (ngModelChange)="emitResults($event)"
        ngModel
        style="background: transparent"
    />
    <div class="d-flex flex-row form-control calendar-Btn justify-content-center" (click)="d.toggle()">
        <span class="material-icons-outlined"> calendar_today </span>
    </div>
</div>

<div *ngIf="!showPicker" (click)="togglePicker()">
    <div class="row" *ngIf="datePicked; else dateNotPicked">
        <div class="material-icons-outlined table-icon mr-3">calendar_today</div>
        <div class="mt-2">
            {{ datePicked | date: 'MM/dd/YYYY' }}
        </div>
    </div>
    <ng-template #dateNotPicked>
        <div class="row">
            <div class="material-icons-outlined table-icon mr-3">calendar_today</div>
            <div class="mt-2">Select Date</div>
        </div>
    </ng-template>
</div>
