import { LengthOfStay, TherapyAdmission, TherapyDiscipline } from '@app/interfaces';
import { last } from 'lodash';
import {
    ChartColors,
    ChartGoal,
    ChartOptionData,
    GoalType,
    TLOFTrend,
} from './../../../interfaces/charts';
import { differenceInDays } from 'date-fns';

// Returns all goals with value isProjected=false;
export const getNonProjected = (goals: ChartGoal[], discipline?: TherapyDiscipline) => {
    if (discipline) return goals.filter((goal) => goal.isProjected === false && goal.discipline === discipline);
    else return goals.filter((goal) => goal.isProjected === false);
};

// Generate the series array for options :: options.series = [generated]
export const generateSeries = (args: {
    goals: ChartGoal[];
    xAxis: any[];
    TLOF: ChartGoal[];
    commonProps: any;
    TLOFLineProps: any;
    selectedChartView: string;
    verticalLineEnd: any;
    verticalLineToday: any;
    selectedGoal: string;
    stg: boolean;
    colors: ChartColors;
}) => {
    let temp: ChartGoal[] = args.goals.map((goal) => ({
        ...goal,
        // name: goal.name,
        // discipline: goal.discipline,
        // goalType: goal.goalType,
        // isProjected: goal.isProjected,
        // data: goal.data,
        // stgs: goal.stgs,
        itemStyle: {
            color: goal.color,
        },
        lineStyle: {
            normal: {
                type: '',
            },
        },
        ...args.commonProps,
    }));
    // Filter and map item with isProjected === false;
    temp.filter((item) => item.isProjected === false).forEach((item) => {
        // Set the line type based on isProjected
        temp.filter((_item) => item.name === _item.name).forEach((_item) => {
            if (_item.isProjected === true) _item.lineStyle.normal.type = 'dashed';
        });
    });
    // Generating stgs for a goal
    if (args.selectedGoal !== 'all' && args.stg === true) {
        temp = [...temp, ...generateGoalSTGs(temp, args.commonProps)];
    }
    // for week or month view
    if (args.selectedChartView === 'Month' || args.selectedChartView === 'Week') {
        args.verticalLineEnd.data = args.verticalLineEnd.data.map((item) => {
            return { ...item, xAxis: args.goals[0].data.length - 1 };
        });
        temp[temp.length - 1].markLine = args.verticalLineEnd;
    } else if (args.selectedChartView === 'Full POC') {
        // Adding vertical divider between actual vs projected
        temp[temp.length - 1].markLine = args.verticalLineToday;
    }
    // Removing already created TLOF
    temp = temp.filter((goal) => goal.goalType !== 'TLOF');
    temp.forEach((goal) => {
        goal.goalType = goal.goalType.toUpperCase() as GoalType;
        goal.color = args.colors[goal.goalType];
    });
    // Adding TLOF for each projected goal
    temp = addTLOF(temp, args.xAxis, args.TLOF, args.TLOFLineProps, args.stg, args.colors);
    return temp;
};

// Adding TLOF for each projected goal
export const addTLOF = (
    goals: ChartGoal[],
    xAxis: any,
    TLOF: ChartGoal[],
    TLOFLineProps = {},
    stg = false,
    colors: ChartColors
) => {
    /**
     *  - TLOF is the straight line from last value of goal data to  TLOF value on the chart.
     */
    const TLOF_COLOR = '#f4633a';
    const generated = [];
    const generatedStgs = [];
    goals
        .filter((goal) => goal.isProjected === false && goal.goalType === 'LTG')
        .forEach((goal) => {
            const TLOFGoal = TLOF.find((_) => _.uid === goal.uid);
            if (!TLOFGoal) return;
            // Last data point of current selected goal
            // To get the last numeric value of goal. That means the starting point to tlof trend.
            const goalValue = last(goal.data.filter((_) => _ !== '-'));
            // TLOF value
            const _diff = diffXY(xAxis.data, goal.data);
            const _trend = getTLOFTrend(TLOFGoal.data, goal.data);
            /**
             * Here, TLOFGoal.TLOF is the target level of function value.
             * goalValue is the latest value of note that is drawn on the chart till today.
             */
            const _singleStep = ((TLOFGoal.TLOF as number) - (goalValue as number)) / _diff;
            const preparedData = [];
            const today = ISODateToXAxis(new Date().toISOString());
            for (let i = 0; i <= xAxis.data.indexOf(today) - 1; i++) {
                preparedData.push('-');
            }
            let value = Number(goalValue);
            preparedData.push(goalValue);
            for (let i = 1; i <= _diff; i++) {
                if (value >= 0) {
                    if (_trend.trend === 'up') value += _singleStep;
                    if (_trend.trend === 'down') value -= _singleStep;
                    preparedData.push(value);
                }
            }
            // if calculation of last point goes above 100, then set it to 100
            if (last(preparedData) > 100) preparedData[preparedData.length - 1] = 100;
            generated.push({
                name: TLOFGoal.name,
                discipline: TLOFGoal.discipline,
                goalType: TLOFGoal.goalType || 'TLOF',
                isProjected: TLOFGoal.isProjected,
                data: [...preparedData],
                stgs: TLOFGoal.stgs,
                itemStyle: {
                    // color: goal?.itemStyle?.color,
                    color: colors?.LTG,
                },
                markPoint: {
                    tooltip: {
                        show: false,
                    },
                    data: [
                        {
                            name: goal.target,
                            showSymbol: true,
                            symbolSize: 8,
                            symbol: 'circle',
                            coord: [last(xAxis.data), last(preparedData)],
                            itemStyle: {
                                color: TLOFGoal.color,
                            },
                            // label: {
                            //     position: 'right',
                            //     color: goal.color,
                            //     formatter: '{b}',
                            //     textStyle: {
                            //         fontWeight: 'bold',
                            //         fontSize: 12,
                            //         color: goal.color,
                            //     },
                            // },
                        },
                    ],
                },
                ...TLOFLineProps,
            });
            if (stg === true) {
                goal.stgs.forEach((goalStg) => {
                    // Last data point of current selected goal
                    // To get the last numeric value of goal. That means the starting point to tlof trend.
                    const STGGoalTLOF = TLOFGoal.stgs.find((_) => _._id === goalStg._id);
                    if (!STGGoalTLOF) return;
                    if (!STGGoalTLOF) return;
                    const goalValue = last(goalStg.data.filter((_) => _ !== '-'));
                    // TLOF value
                    // const TLOFValue = last(goalStg.data)
                    const _diff = diffXY(xAxis.data, goalStg.data);
                    const _trend = getTLOFTrend(STGGoalTLOF?.data, goalStg.data);
                    // const _singleStep = singleStep(_trend.value, _diff);
                    // const _singleStep = singleStep(goalStg.TLOF as number, _diff);
                    // We are using goal value because we want to start the trend from the last value of the goal.
                    let _singleStep = ((STGGoalTLOF?.TLOF as number) - (goalValue as number)) / _diff;
                    const preparedStgData = [];
                    const today = ISODateToXAxis(new Date().toISOString());
                    for (let i = 0; i <= xAxis.data.indexOf(today) - 1; i++) {
                        preparedStgData.push('-');
                    }
                    let value = Number(goalValue);
                    let maxTarget = 100;
                    if (goalStg.target.includes(' - ')) {
                        const target = goalStg.target.split(' - ')[0] as unknown as number;
                        maxTarget = (target / 8) * 100;
                        _singleStep = ((maxTarget as number) - (goalValue as number)) / _diff;
                    }
                    preparedStgData.push(value);
                    for (let i = 1; i <= _diff; i++) {
                        if (value >= 0 && value <= maxTarget) {
                            if (_trend.trend === 'up') value += _singleStep;
                            if (_trend.trend === 'down') value -= _singleStep;
                            preparedStgData.push(value);
                        }
                    }
                    // if calculation of last point goes above 100, then set it to 100
                    if (last(preparedStgData) > maxTarget) preparedStgData[preparedStgData.length - 1] = maxTarget;
                    generatedStgs.push({
                        name: goalStg.name,
                        discipline: goalStg.discipline,
                        goalType: goalStg.goalType || 'TLOF',
                        isProjected: goalStg.isProjected,
                        data: [...preparedStgData],
                        // This is going to be an empty array because we are not going to show stgs of stgs
                        stgs: [],
                        itemStyle: {
                            // color: goal?.itemStyle?.color,
                            color: colors?.STG,
                        },
                        markPoint: {
                            tooltip: {
                                show: false,
                            },
                            data: [
                                {
                                    name: goalStg.target,
                                    showSymbol: true,
                                    symbolSize: 8,
                                    symbol: 'circle',
                                    coord: [last(xAxis.data), last(preparedStgData)],
                                    itemStyle: {
                                        color: TLOF_COLOR,
                                    },
                                    // label: {
                                    //     position: 'right',
                                    //     color: TLOF_COLOR,
                                    //     formatter: '{b}',
                                    //     textStyle: {
                                    //         fontWeight: 'bold',
                                    //         fontSize: 12,
                                    //         color: TLOF_COLOR,
                                    //     },
                                    // },
                                },
                            ],
                        },
                        ...TLOFLineProps,
                    });
                });
            }
        });
    // Here, generated are the TLOF generated for the goals
    // generatedStgs are the TLOF generated for the stgs
    // goals are the actual goals
    return [...goals, ...generated, ...generatedStgs];
};

// Filters and return goals with same name and returns all if value is 'all'
export const getGoalSelection = (goals: ChartGoal[], selectedGoal: string) => {
    if (selectedGoal === 'all') {
        return goals;
    } else {
        return goals.filter((goal) => goal.name === selectedGoal);
    }
};

// Generate and returns stgs goals from a list of goals
// Stgs will be added to main array of data of goals with goal type of stg
export const generateGoalSTGs = (goalStgs: ChartGoal[], commonProps = {}): ChartGoal[] => {
    const stgs = [];
    goalStgs.forEach((goal) =>
        goal.stgs.forEach((goal) =>
            stgs.push({
                // Make sure the value is consistent to goal everywhere from now on.
                name: goal.name,
                discipline: goal.discipline,
                goalType: goal.goalType,
                isProjected: goal.isProjected,
                stgs: [],
                data: goal.data,
                itemStyle: { color: goal.color },
                lineStyle: {
                    normal: {
                        type: goal.isProjected === true ? 'dashed' : '',
                    },
                },
                ...commonProps,
            })
        )
    );
    return stgs;
};

// ---Formula---

/**
 * Returns the difference between x axis and y axis number of values
 * It returns the difference of only one goal from xAxis
 * Series length must always be smaller then xAxis length
 * diff = x.length - y.length
 */
export const diffXY = (xAxis: number[], goalData: ChartOptionData[]): number => {
    return xAxis.length - goalData.filter((_) => _ !== '-').length;
};

/**
 * Returns the value of single step.
 * Single step is a single tep to climb to reach TLOF
 * singleStep * difference = heightToTLOF
 * trend = value / diff
 */
export const singleStep = (trendValue: number, diff: number) => {
    return trendValue / diff;
};

/**
 * Height to TLOF returns the difference between current value and TLOF
 * that is the remaining height to climb for our TLOF
 */
export const getTLOFTrend = (TLOFData: ChartOptionData[], data: ChartOptionData[]): TLOFTrend => {
    const tlof = Number(last(TLOFData));
    const goal = Number(last(data.filter((_) => _ !== '-')));
    const response: TLOFTrend = { value: 0, trend: 'none' };
    if (goal > tlof) {
        response.value = goal - tlof;
        response.trend = 'down';
    } else if (goal < tlof) {
        response.value = tlof - goal;
        response.trend = 'up';
    } else {
        response.value = goal;
        response.trend = 'straight-line';
    }
    return response;
};

// other

// Converts ISO Date format to a format that can be shown on goals chart x Axis
export const ISODateToXAxis = (date: string): string => {
    const _date = new Date(date);
    return `${_date.getMonth() + 1}/${_date.getDate()}`;
};

// Returns the length of stay in days and date format that can be shown on chart
export const getLengthOfStay = (admitDate: string) => {
    const today = new Date();
    return {
        days: differenceInDays(today, new Date(admitDate)),
        date: ISODateToXAxis(today.toISOString()),
    };
};

// To add markLine on today's date
export const getTodayMarkLine = (lengthOfStay: LengthOfStay, custom: number | null = null) => {
    return {
        silent: true, // ignore mouse events
        symbol: 'none',
        lineStyle: {
            color: '#D1D1D0',
            width: 2.5,
        },
        label: {
            show: true,
            position: 'start',
            distance: [30, 30],
            color: '#BC4141',
            fontWeight: 'bolder',
            formatter: () => `Length of Stay - ${lengthOfStay.days} Days`,
        },
        data: [
            {
                xAxis: custom ? custom : lengthOfStay.date,
            },
        ],
    };
};

// Returns the smallest evaluation from all therapy admissions
export const getSmallestEvaluationDate = (admissions: TherapyAdmission[]): Date => {
    return new Date(
        // This section was updated as the admission filter reduction below to get evaluation date by day not signed day
        // admissions
        //     .filter((admission) => !!admission.documentation.evaluation.signed)
        //     .reduce((acc: TherapyAdmission, admission: TherapyAdmission) =>
        //         Date.parse(admission.documentation.evaluation.signed.signDate) <
        //         Date.parse(acc.documentation.evaluation.signed.signDate)
        //             ? admission
        //             : acc
        //     ).documentation.evaluation.signed.signDate
        admissions
            .filter((admission) => !!admission.documentation.evaluation.signed)
            // eslint-disable-next-line spellcheck/spell-checker
            .reduce((acc: TherapyAdmission, admission: TherapyAdmission) =>
                // eslint-disable-next-line spellcheck/spell-checker
                Date.parse(admission.documentation.evaluation.day) < Date.parse(acc.documentation.evaluation.day)
                    ? admission
                    : // eslint-disable-next-line spellcheck/spell-checker
                      acc
            ).documentation.evaluation.day
    );
};

// This function is used to fill in the line gaps in option.series.data
// Connects the data in a way so that [1,2,-,-,5] becomes [1,2,2,2,5]
interface FillInLineGapsOptions {
    startDate: number;
    endDate: number;
    evaluationCLOF?: number;
    xAxis: any;
}
export function fillInLineGaps(goal: any, scatterData?: any, options?: FillInLineGapsOptions) {
    // Here, startDate and endDate are the indexes of the dates in array of dates.
    const { startDate = 0, endDate = goal.data.length - 1, evaluationCLOF, xAxis } = options;
    /**
     * Since array starts from 0, startDate is the 0 index
     * Since array of let us say 10 elements start from 0 and ends at 9, endDate index is (length - 1)
     * Then, the length became 9 for an element of 10 items.
     * As we know while slicing, we need to give proper length from [1:N].
     * In the example above, 1 is the 0th index and N is the length of array returned by Array.length.
     * So, we have to user Array.length + 1 in the next line to make sure we are slicing data properly.
     * For example, in the above case of array of 10 elements, Array.slice(0, length) is Array.slice(0,9).
     * Hence it returns 9 items. To fix this, we do Array.slice(0, length + 1) which is Array.length(0, 10).
     * Hence returning proper slice of the array.
     */
    const filteredSeriesData = goal.data.slice(startDate, endDate + 1);
    if (evaluationCLOF >= 0) filteredSeriesData[0] = evaluationCLOF;
    // if the logic below is tested stable, then for new requirements we need to remove the logical if condition above.
    // In this logic, we are removing evaluation values from
    // filteredSeriesData.forEach((item) => {
    //     if (item !== '-') {
    //         filteredSeriesData[0] = item;
    //         return;
    //     }
    // });
    // iterate through the data and replace "-" values with the previous non-null value
    for (let i = startDate; i <= endDate; i++) {
        //   if (filteredSeriesData[i] === '-' || null) {
        if (scatterData.length && goal.goalType !== 'stg') {
            for (let k = 0; k < scatterData.length; k++) {
                const foundProgressNote = scatterData[k].data.find((note) => note.name === xAxis.data[i]);
                if (
                    foundProgressNote &&
                    goal.discipline === scatterData[k].discipline &&
                    goal.name === scatterData[k].name
                ) {
                    filteredSeriesData[i] = foundProgressNote.value;
                } else {
                    for (let j = i; j >= 0; j--) {
                        if (filteredSeriesData[j] !== '-') {
                            filteredSeriesData[i] = filteredSeriesData[j];
                            break;
                        }
                    }
                }
            }
        } else {
            for (let j = i; j >= 0; j--) {
                if (filteredSeriesData[j] !== '-') {
                    filteredSeriesData[i] = filteredSeriesData[j];
                    break;
                }
            }
        }
        //  }
    }

    return filteredSeriesData;
}

// Legacy function used to create TLOF data.
// => Remove it if not required after proper testing.
export const createTLOF = (start: number, end: number, array: ChartOptionData[]): ChartOptionData[] => {
    const tlofArray: ChartOptionData[] = [];
    const startIndex = array.indexOf(start);
    const endIndex = array.indexOf(end);
    const diff = endIndex - startIndex;
    const step = diff > 0 ? 1 : -1;
    let value = start;

    for (let i = startIndex; i <= endIndex; i += step) {
        tlofArray.push(value);
        value += step;
    }

    for (let i = 0; i < startIndex; i++) {
        tlofArray[i] = '-';
    }

    for (let i = endIndex + 1; i < array.length; i++) {
        tlofArray[i] = '-';
    }

    return tlofArray;
};

export const uuidv4 = () => {
    return (<any>[1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        // eslint-disable-next-line spellcheck/spell-checker
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
    );
};

export const VerifyGoalsIntegrity = (data: any): boolean => {
    let flag = true;
    const ltgLength = data.y.seriesData.length;
    for (let i = 0; i < ltgLength && flag === true; i++) {
        console.log(`LTG:  ${data.y.tlof[i].uid} === ${data.y.seriesData[i].uid}`);
        if (data.y.tlof[i].uid !== data.y.seriesData[i].uid) {
            flag = false;
        }
        for (let j = 0; j < data.y.seriesData[i].stgs.length && flag === true; j++) {
            console.log(`STG: ${data.y.tlof[i].stgs[j].uid} === ${data.y.seriesData[i].stgs[j].uid}`);
            if (data.y.tlof[i].stgs[j].uid !== data.y.seriesData[i].stgs[j].uid) {
                flag = false;
            }
        }
    }
    return flag;
};
