<div class="pill-dropdown" [class.disabled]="disabled">
    <div class="selected-pill" [class.off]="pillStatus === statusEnum.Off" (click)="toggleDropdown()">
      <span class="pill">
        <span class="pill-status" [class.on]="pillStatus === statusEnum.On" [class.off]="pillStatus === statusEnum.Off"></span>
        <span class="pill-text body-medium-semibold">{{ selectedPill }}</span>
        <span *ngIf="!disabled">
          <span class="dropdown-icon" *ngIf="!isDropdownOpen">
            <span class="material-symbols-outlined font-20">
            arrow_drop_down
            </span></span>
          <span class="dropdown-icon" *ngIf="isDropdownOpen">
            <span class="material-symbols-outlined font-20">
            arrow_drop_up
            </span></span>
        </span>
        
      </span>
    </div>
    <div class="dropdown-menu" *ngIf="isDropdownOpen" tabindex="0">
      <div class="pill-item" 
           *ngFor="let option of options"
           [ngStyle]="{'color': option.disabled ? null : option.color}"
           [class.disabled]="option.disabled"
           (click)="selectPill(option)">
        {{ option.label }}
      </div>
    </div>
  </div>
  