import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-data-list',
    templateUrl: './data-list.component.html',
    styleUrls: ['./data-list.component.scss'],
})
export class DataListComponent {
    @Input() listItems: any[] = [];
    @Input() hasIconColumn = false;
    @Input() paddingLeft: '';
    @Output() listItemClicked: EventEmitter<any> = new EventEmitter();

    selectItem(event: any): void {
        this.listItemClicked.emit(event);
    }
}
