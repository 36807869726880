<div *ngIf="toRender" class="grid-main">
    <div *ngIf="title" class="heading">
        {{ title }}
    </div>
    <ng-container [ngSwitch]="gridType">
        <div class="titled-grid" *ngSwitchCase="'HeaderGrid'">
            <ng-container *ngTemplateOutlet="HeaderGrid"></ng-container>
        </div>
        <div class="non-titled-grid" *ngSwitchCase="'NonHeaderGrid'">
            <ng-container *ngTemplateOutlet="NonHeaderGrid"></ng-container>
        </div>
        <div class="hierarchy-grid" *ngSwitchCase="'DynamicGrid'">
            <ng-container *ngTemplateOutlet="DynamicGrid"></ng-container>
        </div>
        <div class="tree-grid" *ngSwitchCase="'TreeGrid'">
            <ng-container
                *ngTemplateOutlet="TreeGrid; context: { $implicit: 'standalone', data: gridData.data }"
            ></ng-container>
        </div>
    </ng-container>
</div>

<ng-template #HeaderGrid>
    <table>
        <tr>
            <th *ngFor="let header of gridData.header" [ngStyle]="{ width: header.width }">{{ header.value }}</th>
        </tr>
        <tr *ngFor="let data of gridData.data; index as i">
            <ng-container *ngFor="let header of gridData.header; index as i">
                <td
                    [ngStyle]="{
                        width: data.isPrimary && i === 1 ? widthSetter(header.width, header.key) : header.width
                    }"
                >
                    {{ data[header.key] }}
                </td>
                <ng-container *ngIf="data.isPrimary && i === 1">
                    <td
                        class="tag-holder"
                        [ngStyle]="{
                            width: widthSetter(header.width, 'isPrimary')
                        }"
                    >
                        <span class="tag"> Primary Diagnosis </span>
                    </td>
                </ng-container>
            </ng-container>
        </tr>
    </table>
</ng-template>

<ng-template #NonHeaderGrid>
    <table class="grid">
        <tr class="block" *ngFor="let index of nonGridArray">
            <td class="count">
                {{ nonHeaderDataByRow(index)[0] }}
            </td>
            <td class="data" [ngStyle]="{ width: gridData.data[nonHeaderDataByRow(index)[3]] ? '45%' : '90%' }">
                {{ gridData.data[nonHeaderDataByRow(index)[1]] }}
            </td>
            <td class="count left-border" *ngIf="gridData.data[nonHeaderDataByRow(index)[3]]">
                {{ nonHeaderDataByRow(index)[2] }}
            </td>
            <td class="data" *ngIf="gridData.data[nonHeaderDataByRow(index)[3]]">
                {{ gridData.data[nonHeaderDataByRow(index)[3]] }}
            </td>
        </tr>
    </table>
</ng-template>

<ng-template #DynamicGrid>
    <div class="hierarchy-container" *ngFor="let grid of gridData.data; index as i">
        <div *ngIf="type === 'evaluationNote' && key === 'goals' && i < 1" class="header">Plan of Care</div>
        <div *ngIf="key === 'goals'" class="header">Long Term Goal {{ i + 1 }}</div>
        <div class="grid">
            <span
                class="block"
                [ngStyle]="{
                    width: data.width,
                    'border-right': data.isRightBordered ? '1px solid #7c7c7c' : 'none',
                    'padding-left': data.paddingLeft,
                    'font-weight': data.isBold ? 'bold' : 'normal'
                }"
                *ngFor="let data of grid.data"
                [innerHTML]="data.value"
            ></span>
        </div>
        <div *ngIf="grid.children && grid.children.length" class="header">
            <span>Associated Short Term Goals of ({{ grid.children.length }})</span>
        </div>
        <div class="tree">
            <ng-container
                *ngTemplateOutlet="TreeGrid; context: { $implicit: 'dependent', data: grid.children }"
            ></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #TreeGrid let-children="data">
    <div class="tree-container" *ngFor="let child of children; index as i">
        <div class="counter">{{ i + 1 }}</div>
        <div class="grid">
            <span
                class="block"
                [ngStyle]="{
                    width: data.width,
                    'border-right': data.isRightBordered ? '1px solid ##7c7c7c' : 'none',
                    'padding-left': data.paddingLeft,
                    'font-weight': data.isBold ? 'bold' : 'normal'
                }"
                *ngFor="let data of child"
                [innerHTML]="data.value"
            ></span>
        </div>
    </div>
</ng-template>
