import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DragDropService, ToasterService } from '@app/services';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-drag-drop',
    templateUrl: './drag-drop.component.html',
    styleUrls: ['./drag-drop.component.scss'],
})
export class DragDropComponent implements OnChanges {
    fileArr = [];
    imgArr = [];
    fileObj = [];
    form: FormGroup;
    msg: string;
    progress = 0;
    uploadingFileNumber = 0;
    totalFiles = 0;
    responseFiles: any[] = [];
    @Output() responseFiles$ = new EventEmitter<any[]>();
    @Output() isLoading$ = new EventEmitter<boolean>();
    @Output() progress$ = new EventEmitter<any>();
    isDragged: boolean;
    allFiles = [];
    @Input() deletedFile;

    @ViewChild('fileField') fileField: ElementRef;
    constructor(
        public fb: FormBuilder,
        // eslint-disable-next-line spellcheck/spell-checker
        private sanitizer: DomSanitizer,
        // eslint-disable-next-line spellcheck/spell-checker
        public dragdropService: DragDropService,
        private toasterService: ToasterService
    ) {
        this.form = this.fb.group({
            file: [null],
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.deletedFile.currentValue) {
            this.allFiles.map((file, i) => {
                // eslint-disable-next-line spellcheck/spell-checker
                if (changes.deletedFile.currentValue.originalname === file.name) {
                    this.allFiles.splice(i, 1);
                    this.responseFiles.splice(i, 1);
                    this.fileField.nativeElement.value = '';
                }
            });
        }
    }

    findDuplicatesByProperty(propertyToCheck, files, selectedFiles) {
        const seen = new Set();
        const duplicates = [];

        for (const item of files) {
            const propertyValue = item[propertyToCheck];
            selectedFiles.map((file) => {
                if (item[propertyToCheck] === file[propertyToCheck]) {
                    // duplicate exist
                    duplicates.push(item);
                } else {
                    seen.add(propertyValue);
                }
            });
        }
        return duplicates;
    }

    upload(e) {
        const fileListAsArray: any[] = Array.from(e);
        this.totalFiles = fileListAsArray.length;
        const duplicates = this.findDuplicatesByProperty('name'.toLowerCase(), this.allFiles, fileListAsArray);

        for (let i = 0; i < fileListAsArray.length; i++) {
            if (fileListAsArray[i]?.size > 10485760) {
                this.toasterService.show({
                    title: 'error',
                    type: 'error',
                    body: `${fileListAsArray[i]?.name} is to large. Max size allowed 10MB.`,
                });

                fileListAsArray.splice(i, 1);

                if (this.fileField) {
                    this.fileField.nativeElement.value = '';
                }

                if (!fileListAsArray.length) return;
            }
        }

        if (duplicates.length !== 0) {
            this.toasterService.show({
                title: 'error',
                type: 'error',
                body: 'File already exists',
            });
        } else {
            fileListAsArray.forEach((item: any, i) => {
                const file = e as HTMLInputElement;
                // check supported files type
                if (
                    item.type === 'image/jpg' ||
                    item.type === 'image/jpeg' ||
                    item.type === 'image/png' ||
                    item.type === 'application/pdf' ||
                    item.type === 'application/msword' ||
                    item.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                ) {
                    const url = URL.createObjectURL(file[i]);
                    this.imgArr.push(url);
                    this.fileArr.push({ item, url: url });
                    this.allFiles.push(item);
                } else {
                    // for Unsupported file
                    this.toasterService.show({
                        title: 'Error',
                        body: `Unsupported file(s) can't be uploaded`,
                        type: 'error',
                    });
                }
            });

            this.fileArr.forEach((item) => {
                this.fileObj.push(item.item);
            });

            // Set files form control
            this.form.patchValue({
                file: this.fileObj,
            });

            this.form.get('file').updateValueAndValidity();

            // eslint-disable-next-line spellcheck/spell-checker
            this.form.value.file.map(async (item, idx) => {
                // eslint-disable-next-line spellcheck/spell-checker
                console.log(`FileCounter: ${idx}`);
                this.isLoading$.emit(true);
                // this.uploadingFileNumber=idx++;
                // eslint-disable-next-line spellcheck/spell-checker
                this.uploadingFileNumber = idx;
                // eslint-disable-next-line spellcheck/spell-checker
                (await this.dragdropService.addFiles(item)).subscribe(
                    (event: HttpEvent<any>) => {
                        switch (event.type) {
                            case HttpEventType.Sent:
                                console.log('Request has been made!');
                                break;
                            case HttpEventType.ResponseHeader:
                                console.log('Response header has been received!');
                                break;
                            case HttpEventType.UploadProgress:
                                this.progress = Math.round((event.loaded / event.total) * 100);
                                this.progress$.emit(this.progress);
                                console.log(`Uploaded! ${this.progress}%`);
                                break;
                            case HttpEventType.Response:
                                console.log('File uploaded successfully!', event.body);
                                this.responseFiles.push(event.body.data);
                                this.responseFiles$.emit(this.responseFiles);
                                this.progress = 0;
                                this.fileArr = this.fileArr.filter((file) => file.item != item);
                                this.fileObj = this.fileObj.filter((file) => file != item);
                                this.msg = 'File uploaded successfully!';
                                this.isLoading$.emit(false);
                        }
                    },
                    () => {
                        // this.alertService.error();
                        this.toasterService.show({
                            title: 'Error',
                            body: 'Something went wrong with file ' + item.name + ', please try again later',
                            type: 'error',
                        });
                        // this.responseFiles = [];
                        // this.responseFiles$.emit(this.responseFiles);
                        // this.progress = 0;
                        // this.fileArr = [];
                        // this.fileObj = [];
                        this.responseFiles = this.responseFiles.filter((file) => file.item != item);
                        this.responseFiles$.emit(this.responseFiles);
                        this.progress = 0;
                        this.fileArr = this.fileArr.filter((file) => file.item != item);
                        this.fileObj = this.fileObj.filter((file) => file != item);
                        this.isLoading$.emit(false);
                    }
                );
            });
        }
        if (this.fileField) {
            this.fileField.nativeElement.value = '';
        }
    }
    // Clean Url
    sanitize(url: string) {
        // eslint-disable-next-line spellcheck/spell-checker
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }
}
