import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
    selector: 'app-select-option',
    templateUrl: './select-option.component.html',
    styleUrls: ['./select-option.component.scss'],
})
export class SelectOptionComponent implements OnInit, ControlValueAccessor {
    @Input('options') selectOptions = [];
    @Input() label: string;
    @Input() placeholder = 'Choose one Option';
    @Input() backgroundClass: string;
    @Input() foregroundClass: string;
    @Input() class: string;
    @Input() fontSize: '';
    @Input() labelClass = '';

    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
    propagateChange = (_: any) => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched = () => {};

    value: any = {};
    disabled: boolean;
    constructor(public ngControl: NgControl) {
        ngControl.valueAccessor = this;
        if (this.backgroundClass === undefined) this.backgroundClass = '';
        if (this.foregroundClass === undefined) this.foregroundClass = '';
    }

    ngOnInit(): void {
        if (!this.value) {
            // this.value = this.selectOptions[0];
        }
    }

    get isRequired(): boolean {
        const control: AbstractControl = this.ngControl.control;
        if (control.validator === null) return false;
        return control.validator(control)?.required ?? false;
    }

    get hasError(): boolean {
        const control: AbstractControl = this.ngControl.control;
        if (control && control.touched && control.invalid) return true;
        return false;
    }

    writeValue(obj: any): void {
        if (obj) this.value = obj;
        else this.value = '';
    }

    optionSelect(event: any) {
        this.propagateChange(event.target.value);
    }
    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
