import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogRef } from '@app/services';

@Component({
  selector: 'app-grid-options-modal',
  templateUrl: './grid-options-modal.component.html',
  styleUrls: ['./grid-options-modal.component.scss']
})
export class GridOptionsModalComponent  {

  @Output() optionSelected: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<void> = new EventEmitter();
  @Output() headingEmitter: EventEmitter<string> = new EventEmitter();

  @Input() heading = 'Billing Export Type';
  @Input() button1Text = 'Continue';
  @Input() placeholder = 'Type Here';
  @Input() options = ['.CSV', '.XLS', 'MatrixCare']
  @Input() subHeading: string;
  @Input() minHeight: string;
  @Input() crossIconClasses = '';
  @Input() paddingZero = '';
  @Input() inputStyle: string;
  @Input() showHeading = false;
  @Input() showTooltip = false;
  @Input() showFooterDivider = false;
  @Input() showFooter = true;
  @Input() showCross = true;


  selectedOption: string | null = null;


  constructor(private dialogRef: DialogRef) {}

  onClose() {
    this.dialogRef.close(false);

  }

  onSave() {
    this.dialogRef.close(this.selectedOption);

  }


  selectOption(option: string): void {
    this.selectedOption = option;
  }

  isButtonDisabled(): boolean {
    return this.selectedOption === null;
  }

}
