import { TherapyAdmission } from '@app/interfaces';
import { createAction, props } from '@ngrx/store';

export const therapyadmissionChangedAction = createAction(
    '[therapyadmission action] therapyadmission changed',
    props<{ therapyadmission: TherapyAdmission[] }>()
);
export const therapyadmissionRemovedAction = createAction('[therapyadmission action] therapyadmission removed');
//cached
export const cachedTherapyadmissionChangedAction = createAction(
    '[cached therapyadmission action] cached therapyadmission changed',
    props<{ therapyadmission: TherapyAdmission[] }>()
);
export const cachedTherapyadmissionRemovedAction = createAction(
    '[cached therapyadmission action] cached therapyadmission removed'
);