import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'app-chips-cell-renderer',
    templateUrl: './chips-cell-renderer.component.html',
    styleUrls: ['./chips-cell-renderer.component.scss'],
})
export class ChipsCellRendererComponent implements ICellRendererAngularComp {
    refresh(): boolean {
        return;
    }
    agInit(): void {
        return;
    }
}
