import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FacilityRoutingModule } from './facility-routing.module';
import { FacilityLayoutComponent } from './facility-layout/facility-layout.component';
import { SharedModule } from '@app/shared/shared.module';
import { FacilityHeaderComponent } from './facility-header/facility-header.component';
import { FacilityListComponent } from './facility-list/facility-list.component';
import { AddFacilityComponent } from './add-facility/add-facility.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AddPayorsComponent } from './add-payors/add-payors.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        FacilityLayoutComponent,
        FacilityHeaderComponent,
        FacilityListComponent,
        AddFacilityComponent,
        AddPayorsComponent
    ],
    imports: [CommonModule, FacilityRoutingModule, SharedModule, FormsModule, ReactiveFormsModule, NgxSkeletonLoaderModule],
    exports: [FacilityListComponent],
})
export class FacilityModule {}
