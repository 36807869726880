import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'btn-cell-renderer',
    template: `
        <span
            class="material-symbols-outlined text-secondary d-inline-block align-middle cursor-pointer bold"
            [class.opacity-50]="disabled"
            [class.pointer-events-none]="disabled"
            (click)="btnClickedHandler($event)"
            >open_in_new</span
        >
    `,
})
export class BtnCellRenderer implements ICellRendererAngularComp {
    private params: any;
    public disabled = false;
    agInit(params: any): void {
        this.params = params;
        this.disabled = params.disabled;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    btnClickedHandler(event: any) {
        this.params.clicked(this.params.data);
    }

    refresh() {
        return false;
    }
}
