<div class="facilities">
    <app-base-modal-card [showFooter]="false" [heading]="heading" (close)="onClose()">
        <div body>
            <div class="payor-search">
                <app-patients-search
                    [searchTerm]="searchString"
                    [placeHolder]="'Search Facility'"
                    (search)="searchFacilities($event)"
                ></app-patients-search>
            </div>
            <div class="row justify-content-center">
                <div class="col-12" style="height: 270px">
                    <app-data-grid
                        [columnDefs]="facilityColumns"
                        [rowData]="facilities"
                        [pagination]="false"
                        [showCount]="false"
                        (gridReady)="initGrid($event)"
                        (selectedRowsEmitter)="rowSelection($event)"
                        [rowHeight]="50"
                    >
                    </app-data-grid>
                </div>
            </div>

            <div class="footer d-flex justify-content-end">
                <app-button
                    (click)="selectFacilities()"
                    type="secondary"
                    class="mr-3 cursor-pointer"
                    size="lg"
                    [text]="'Select'"
                ></app-button>
            </div>
        </div>
    </app-base-modal-card>
</div>
