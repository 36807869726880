<table #evaluationReport id="evaluationReport" class="print-table" *ngIf="isDataLoaded">
    <thead #header id="header" class="print-header">
        <!-- <th class="n-header">
            <app-header [isFirst]="false" [type]="'evaluationNote'"></app-header>
        </th> -->
        <tr class="print-first-header">
            <app-header [isFirst]="true" [type]="'evaluationNote'" [toExport]="toExport"></app-header>
        </tr>
    </thead>
    <tr style="page-break-before: avoid">
        <app-grid-layout [type]="'evaluationNote'" [key]="'medicalDiagnosis'"></app-grid-layout>
    </tr>
    <tr>
        <app-grid-layout [type]="'evaluationNote'" [key]="'treatmentDiagnosis'"></app-grid-layout>
    </tr>
    <tr>
        <app-grid-layout [type]="'evaluationNote'" [key]="'pastMedicalHistory'"></app-grid-layout>
    </tr>
    <tr>
        <app-grid-layout [type]="'evaluationNote'" [key]="'priorHospitalizationDates'"></app-grid-layout>
    </tr>
    <tr>
        <app-grid-layout [gridType]="'NonHeaderGrid'" [type]="'evaluationNote'" [key]="'precautions'"></app-grid-layout>
    </tr>
    <tr>
        <app-grid-layout
            [gridType]="'NonHeaderGrid'"
            [type]="'evaluationNote'"
            [key]="'contraindications'"
        ></app-grid-layout>
    </tr>
    <tr>
        <app-grid-layout [gridType]="'NonHeaderGrid'" [type]="'evaluationNote'" [key]="'medications'"></app-grid-layout>
    </tr>
    <tr>
        <app-paragraph [type]="'evaluationNote'" [key]="'reasonForReferral'"></app-paragraph>
    </tr>
    <tr>
        <app-paragraph [type]="'evaluationNote'" [key]="'chiefComplaint'"></app-paragraph>
    </tr>
    <tr>
        <app-grid-layout
            [gridType]="'NonHeaderGrid'"
            [type]="'evaluationNote'"
            [key]="'patientGoals'"
        ></app-grid-layout>
    </tr>
    <tr>
        <app-paragraph [type]="'evaluationNote'" [key]="'priorLevelOfFunction'"></app-paragraph>
    </tr>
    <tr>
        <app-grid-layout
            [gridType]="'NonHeaderGrid'"
            [type]="'evaluationNote'"
            [key]="'socialDeterminantsOfHealth'"
        ></app-grid-layout>
    </tr>
    <tr>
        <app-grid-layout
            [gridType]="'NonHeaderGrid'"
            [type]="'evaluationNote'"
            [key]="'environmentalFactors'"
        ></app-grid-layout>
    </tr>
    <tr>
        <app-paragraph [type]="'evaluationNote'" [key]="'previousTherapy'"></app-paragraph>
    </tr>
    <tr>
        <app-paragraph [type]="'evaluationNote'" [key]="'caregiverEducation'"></app-paragraph>
    </tr>
    <tr>
        <app-grid-layout [gridType]="'TreeGrid'" [type]="'evaluationNote'" [key]="'impairments'"></app-grid-layout>
    </tr>
    <tr>
        <app-grid-layout
            [gridType]="'TreeGrid'"
            [type]="'evaluationNote'"
            [key]="'functionalDeficits'"
        ></app-grid-layout>
    </tr>
    <tr>
        <app-grid-layout
            [gridType]="'TreeGrid'"
            [type]="'evaluationNote'"
            [key]="'standardizedTests'"
        ></app-grid-layout>
    </tr>
    <tr>
        <app-paragraph [type]="'evaluationNote'" [key]="'therapyNecessity'"></app-paragraph>
    </tr>
    <tr>
        <app-paragraph [type]="'evaluationNote'" [key]="'interventionApproach'"></app-paragraph>
    </tr>
    <tr>
        <app-grid-layout
            [gridType]="'DynamicGrid'"
            [type]="'evaluationNote'"
            [key]="'evaluationType'"
        ></app-grid-layout>
    </tr>
    <tr style="page-break-before: always">
        <app-grid-layout [gridType]="'DynamicGrid'" [type]="'evaluationNote'" [key]="'goals'"></app-grid-layout>
    </tr>
    <tr>
        <app-grid-layout [type]="'evaluationNote'" [key]="'skilledServices'"></app-grid-layout>
    </tr>
    <tr>
        <app-grid-layout
            [gridType]="'DynamicGrid'"
            [type]="'evaluationNote'"
            [key]="'frequencyAndDuration'"
        ></app-grid-layout>
    </tr>
    <tr>
        <app-paragraph [type]="'evaluationNote'" [key]="'prognosisForAchievingRehabGoals'"></app-paragraph>
    </tr>
    <tr>
        <app-paragraph [type]="'evaluationNote'" [key]="'dischargePlan'"></app-paragraph>
    </tr>
    <tr>
        <app-paragraph [type]="'evaluationNote'" [key]="'dischargeDestination'"></app-paragraph>
    </tr>
    <tr>
        <app-paragraph [type]="'evaluationNote'" [key]="'reasonForNotCreatingPoc'"></app-paragraph>
    </tr>
    <tr>
        <app-grid-layout [gridType]="'TreeGrid'" [type]="'evaluationNote'" [key]="'addendum'"></app-grid-layout>
    </tr>
    <tr [ngClass]="{'print-footer': !withEdits}" *ngIf="isDataLoaded">
        <app-signature [type]="'evaluationNote'"></app-signature>
    </tr>
    <tr class="print-footer" *ngIf="withEdits">
        <app-grid-layout [type]="'evaluationNote'" [key]="'changeHistoryPrint'"></app-grid-layout>
    </tr>
</table>
