

<ngu-carousel #myCarousel [inputs]="carouselTileConfig" [dataSource]="pocData">
    <ngu-tile *nguCarouselDef="let item; let i = index; let ani = animate"> 
        <div class="h-100 pocForms" >
            <div class="header d-flex flex-row align-items-center justify-content-between">
                <p class="text-secondary">{{ item?.patientName }}</p>
                <p class="text-secondary">{{ item?.discipline }}</p>
            </div>
    
            <div class="body p-3" style="height: auto">
    
                <div class="body-large-bold text-secondary">Information</div>
    
                <div class="row py-1 pendingstackcard">
                    <div class="col-6">
                        <div class="body-medium-semibold text-secondary">MRN</div>
                        <div class="text-secondary">{{ item?.MRN }}</div>
                    </div>
                    <div class="col-6">
                        <div class="body-medium-semibold text-secondary">Days outstanding</div>
                        <div class="text-secondary">{{outstandingDays(item)}}</div>
                    </div>
                    <div class="col-12 mt-1">
                        <div class="body-medium-semibold text-secondary">Facility</div>
                        <div class="text-secondary">{{ item?.facilityName }}</div>
                    </div>
                </div>
    
                <!-- // -->
                <div class="body-large-bold text-secondary">Plan of care</div>
    
                <div class="row py-1 pendingstackcard">
                    <div class="col-4">
                        <div class="body-medium-semibold text-secondary">Frequency</div>
                        <div class="text-secondary">{{ item?.details[0]?.frequency }}</div>
                    </div>
                    <div class="col-4">
                        <div class="body-medium-semibold text-secondary">Duration</div>
                        <div class="text-secondary">{{ item?.details[0]?.duration }}</div>
                    </div>
                    <div class="col-4">
                        <div class="body-medium-semibold text-secondary">Visits</div>
                        <div class="text-secondary">{{ item?.details[0]?.visits }}</div>
                    </div>
                    <div *ngIf="item?.details[0]?.longTermGoals" class="col-6 mt-1">
                        <div class="body-medium-semibold text-secondary">Long term goals</div>
                        <div class="text-secondary">{{ item?.details[0]?.longTermGoals }}</div>
                    </div>
                    <div *ngIf="item?.details[0]?.shortTermGoals" class="col-6 mt-1">
                        <div class="body-medium-semibold text-secondary">Shot term goals</div>
                        <div class="text-secondary">{{ item?.details[0]?.shortTermGoals }}</div>
                    </div>
                </div>
    
                <!-- // -->
                <div class="body-large-bold text-secondary">Therapy Necessity</div>
    
                <div class="row py-1 pendingstackcard">
                    <div class="col-12 mt-1">
                        <p class="text-secondary text-truncate" style="max-width: 320px">{{ item?.details[0]?.therapyNecessity }}</p>
                    </div>
                </div>
    
                <div
                    (click)="onActionClick('pocform', item)"
                    class="d-flex align-items-center justify-content-center body-medium-semibold text-secondary mt-4 mb-2"
                >
                    <span>View More Details</span>
                    <span class="material-icons-outlined ml-1">open_in_new</span>
                </div>
                <hr />
                <div class="d-flex flex-row justify-content-center">
                    <button
                        (click)="onActionClick('reject', item)"
                        type="btn btn-secondary"
                        class="btn-outline-danger innova-btn mr-3 standard-btn body-regular-light-bold-14"
                    >
                        Reject
                    </button>
                    <button
                        (click)="onActionClick('sign', item)"
                        type="btn btn-secondary"
                        class="btn-secondary innova-btn standard-btn body-regular-light-bold-14"
                    >
                        <span> {{ authService.isPhysicianStaff ? 'Ready' : 'Sign' }}</span>
                    </button>
                </div>
            </div>
        </div>


    </ngu-tile>
  
    
    <button NguCarouselPrev class="leftRs"   [style.opacity]="myCarousel.isFirst ? 0:1">
          <span class="material-icons-outlined scrollbtn">
                    chevron_left
                </span> 
    </button>
    <button NguCarouselNext class="rightRs" [style.opacity]="myCarousel.isLast ? 0:1">
        <span class="material-icons-outlined scrollbtn">
                    chevron_right
                </span> 
    </button>
  </ngu-carousel>










