import { TIME } from '../constants';
import { endOfDay, startOfDay, format, parseISO } from 'date-fns';

export const getDifferenceBetweenTwoDates = (startDate: string | Date, endDate: string | Date): number => {
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    const diffDays = Math.floor(
        (Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate()) -
            Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate())) /
            (1000 * 60 * 60 * 24)
    );
    return diffDays;
};

export const getDayName = (day: number): 'sun' | 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' => {
    switch (day) {
        case 0:
            return 'sun';
        case 1:
            return 'mon';
        case 2:
            return 'tue';
        case 3:
            return 'wed';
        case 4:
            return 'thu';
        case 5:
            return 'fri';
        case 6:
            return 'sat';
        default:
            return 'mon';
    }
};

export const getMonthName = (
    day: number
): 'Jan' | 'Feb' | 'Mar' | 'Apr' | 'May' | 'Jun' | 'Jul' | 'Aug' | 'Sep' | 'Oct' | 'Nov' | 'Dec' => {
    switch (day) {
        case 0:
            return 'Jan';
        case 1:
            return 'Feb';
        case 2:
            return 'Mar';
        case 3:
            return 'Apr';
        case 4:
            return 'May';
        case 5:
            return 'Jun';
        case 6:
            return 'Jul';
        case 7:
            return 'Aug';
        case 8:
            return 'Sep';
        case 9:
            return 'Oct';
        case 10:
            return 'Nov';
        case 11:
            return 'Dec';
    }
};
export const isPastDate = (date) => {
    return new Date(date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
};

export const isToday = (date) => {
    return new Date(date).toDateString() === new Date().toDateString();
};
export const getWeekNumber = (date) => {
    const oneJan: any = new Date(date.getFullYear(), 0, 1);
    const numberOfDays = Math.floor((date - oneJan) / (24 * 60 * 60 * 1000));
    // comment as not working properly e.g pass (26-07-2023 and 27-07-2023) return Math.ceil((date.getDay() + 1 + numberOfDays) / 7);
    return Math.ceil((1 + numberOfDays) / 7);
};
export const getTargetDateXDaysAgo = (baseDate: Date, daysAgo: number): Date => {
    const targetDate = new Date(baseDate.getTime() - daysAgo * 24 * 60 * 60 * 1000);
    return new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate());
};
export const getTargetDateXDaysAhead = (baseDate: Date, daysAhead: number): Date => {
    const targetDate = new Date(baseDate.getTime() + daysAhead * 24 * 60 * 60 * 1000);
    return new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate());
};
export const getWeekStartDate = (date) => {
    if (typeof date == 'string') {
        date = new Date(date);
    }

    const startOfWeek = new Date(date.setDate(date.getDate() - date.getDay())).setHours(
        TIME.hours,
        TIME.minutes,
        TIME.seconds
    );
    return new Date(startOfWeek).toISOString();
    //return new Date(date.setDate(date.getDate() - date.getDay())).toISOString();
};
export const getWeekEndDate = (date) => {
    if (typeof date === 'string') {
        date = new Date(date);
    }

    const startOfWeek = new Date(date);
    startOfWeek.setDate(date.getDate() - date.getDay()); // Set to the start of the week

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6); // Set to the end of the week

    return endOfWeek.toISOString();
};

export const isSameDate = (date1: Date, date2: Date) => date1.toDateString() === date2.toDateString();

export const getEndOfDay = (date: Date) => endOfDay(date);
export const getStartOfDay = (date: Date) => startOfDay(date);

export const applyTimezoneConversionOnDate = (date: string | Date): string => {
    if (!date) {
        return;
    }

    // converted according to schedular team timezone logic
    date = new Date(new Date(date).setHours(TIME.hours, TIME.minutes, TIME.seconds)).toISOString();
    return date;
};
export const formatDateForAuditLog = (dateString, passHours = false) => {
    if (!dateString) {
        return;
    }
    const date = parseISO(dateString);
    if (passHours) {
        return format(date, 'MM/dd/yyyy | HH:mm');
    } else {
        return format(date, 'MM/dd/yyyy');
    }
};
