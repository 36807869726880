import { Directive, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
    selector: '[insideClickEvent]',
})
export class ClickInsideDirective {
    // Returns true if click event happens inside of the element using this binding
    @Output() insideClickEvent: EventEmitter<boolean> = new EventEmitter();

    @HostListener('click')
    public onClick() {
        this.insideClickEvent.emit(true);
    }
}
