<app-base-modal-card [heading]="heading" wrapperClass="modal-medium" (close)="onClose()">
    <div body>
        <div class="d-flex flex-row align-items-center justify-content-center">
            <div class="flex-1">
                <span class="ml-3 pl-3 text-secondary" style="display: block">
                    I agree, and it is my intent, to sign this record/document and affirmation of electronic signature
                    for electronic submission and printed record/document. I understand that my signing and submitting
                    this record/document in this fashion is the legal equivalent of having placed my handwritten
                    signature on the submitted record/document and this affirmation. I understand and agree that by
                    electronically signing and submitting this record/document in this fashion I am affirming to the
                    truth of the information contained.
                </span>
            </div>
        </div>
        <div class="d-flex mt-4 ml-2">
            <input id="is-agree" type="checkbox" [(ngModel)]="isAgree" class="checkbox" />
            <label for="is-agree" class="credentials ml-2 cursor-pointer mb-0">{{ loggedInUser }}</label>
        </div>
    </div>

    <div footer class="d-flex flex-row justify-content-end w-100">
        <button
            [disabled]="!isAgree || pendingResponse"
            class="btn btn-secondary px-4 py-1 rounded-pill d-flex flex-row"
            (click)="onSign()"
        >
            Co-Sign
            <span *ngIf="loader" class="ml-2 mt-1 spinner-border spinner-border-sm"></span>
        </button>
    </div>
</app-base-modal-card>
