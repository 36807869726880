<ng-container>
    <span *ngIf="!params.data.comment">
        <span
            class="material-symbols-outlined text-secondary d-inline-block align-middle cursor-pointer"
            [class.opacity-50]="disabled"
            [class.pointer-events-none]="disabled"
            (click)="btnClickedHandler($event)"
            >{{disabled ? 'insert_comment': 'add'}}</span
        >
    </span>
    <span style="position: relative" *ngIf="params.data.comment">
        <span
            class="material-symbols-outlined text-secondary d-inline-block align-middle cursor-pointer"
            [class.opacity-50]="disabled"
            (mouseleave)="hideInfo($event)"
            (mousemove)="showInfo($event)"
            [class.pointer-events-none]="disabled"
            >insert_comment</span
        >
        <div class="info-details text-secondary" #infoDetails style="display: none">
            <!-- <div class="d-flex justify-content-between mb-1">
                <span class="text-secondary">hiiiiiiiiiiiii</span>
            </div> -->
            {{params?.data?.comment}}
        </div>
    </span>
</ng-container>
