import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FacilityAdmission, MedicalDiagnosis, TherapyAdmission } from '@app/interfaces';
import { ClinicalCategoriesService, SlpComorbidityService } from '@app/services';
import { RowClassRules, GridApi } from 'ag-grid-community';
import { SLPComorbidity } from '@app/store/store.interface';
import { OnSetDateCellRenderer } from '@app/helpers/AgGrid';
import { DatePipe } from '@angular/common';

import {
    ActionsCellRendererComponent,
    CheckIconCellRendererComponent,
    RadioButtonCellRendererComponent,
} from '@app/data-grid/cell-renderers';
import { GridActionsService } from '@app/data-grid/services/grid-actions.service';
import { Observable, of, Subject, switchMap, take } from 'rxjs';
import { cloneDeep, isEqual } from 'lodash';

@Component({
    selector: 'app-medical-diagnosis-card',
    templateUrl: './medical-diagnosis-card.component.html',
    styleUrls: ['./medical-diagnosis-card.component.scss'],
})
export class MedicalDiagnosisCardComponent implements OnChanges {
    private readonly onDestroy = new Subject<void>();

    @Input() medicalDiagnosisInput: MedicalDiagnosis[];
    @Input() heading = '';
    @Input() onSetDate = '';
    @Input() isReadOnly = false;
    @Input() medicalDiagnosisData = [];
    @Output() medicalDiagnosisEvent: EventEmitter<MedicalDiagnosis[]> = new EventEmitter<MedicalDiagnosis[]>();
    @Output() medicalDiagnosisSave: EventEmitter<MedicalDiagnosis[]> = new EventEmitter();

    state: 'zero' | 'one' = 'zero';
    loading = false;
    isDone = false;
    gridApi: GridApi;
    data: any[] = [];
    selectedData: any[] = [];
    therapyAdmissions$: Observable<TherapyAdmission[]>;
    facilityAdmission$: Observable<FacilityAdmission>;

    columns = [
        { headerName: 'Code', field: 'code', resizable: true, width: 100 },
        { headerName: 'Description', field: 'description', resizable: true },
        { headerName: 'Pdpm Clinical Category', field: 'clinicalCategory', resizable: true },
        { headerName: 'Major Procedure', field: 'majorProcedure', resizable: true },
        { headerName: 'SLP Comorbidity', field: 'comorbidity', resizable: true },
        {
            headerName: 'Primary D/X',
            field: 'isPrimary',
            cellRenderer: RadioButtonCellRendererComponent,
            resizable: true,
        },
        {
            headerName: 'Onset Date',
            field: 'onsetDate',
            cellRenderer: OnSetDateCellRenderer,
            resizable: true,
        },
        {
            headerName: 'Actions',
            type: 'rightAligned',
            field: 'actions',
            cellRenderer: ActionsCellRendererComponent,
            cellRendererParams: {
                delete: true,
                gridName: 'MedicalDiagnosis',
            },
            resizable: true,
        },
    ];
    editColumns = [
        { headerName: 'Code', field: 'code', resizable: true },
        { headerName: 'Description', field: 'description', resizable: true },
        { headerName: 'Pdpm Clinical Category', field: 'clinicalCategory', resizable: true },
        { headerName: 'Major Procedure', field: 'majorProcedure', resizable: true },
        { headerName: 'SLP Comorbidity', field: 'comorbidity', resizable: true },
    ];
    medicalDiagnosisColumns = [
        { headerName: 'Code', field: 'code', width: 80 },
        { headerName: 'Description', field: 'description', width: 150 },
        { headerName: 'Pdpm Clinical Category', field: 'clinicalCategory', width: 150 },
        { headerName: 'Major Procedure', field: 'majorProcedure', width: 150 },
        { headerName: 'SLP Comorbidity', field: 'comorbidity', width: 150 },
        {
            headerName: 'Onset Date',
            field: 'onsetDate',
            width: 150,
            valueFormatter: (params) => {
                return this.dateFormatter(params);
            },
        },
        { headerName: 'Primary DX', field: 'isPrimary', width: 150, cellRenderer: CheckIconCellRendererComponent },
    ];
    // Row class rules to display
    rowClassRules: RowClassRules = {
        'bg-primary-50': function (params) {
            return this.selectedData.findIndex((object) => object.code === params.data.code) !== -1;
        }.bind(this),
        'text-secondary': function (params) {
            return this.selectedData.findIndex((object) => object.code === params.data.code) !== -1;
        }.bind(this),
    };

    constructor(
        private clinicalCategoryService: ClinicalCategoriesService,
        private slpComorbidityService: SlpComorbidityService,
        private gridActionService: GridActionsService
    ) {
        gridActionService.action.subscribe((params) => {
            if (params.actionType === 'delete' && params.gridName === 'MedicalDiagnosis') {
                this.removeSelectedItem(params.data);
            }
        });
    }

    ngOnChanges(): void {
        // Setting vales
        if (this.medicalDiagnosisInput?.length > 0) {
            this.markSelectedPrimaryDx(this.medicalDiagnosisInput);
            this.data = this.medicalDiagnosisInput;
            this.isDone = true;
            this.state = 'one';
        }
        if (this.isReadOnly) {
            this.setReadonlyData();
        }
    }

    markSelectedPrimaryDx(list) {
        if (list?.length > 0) {
            this.selectedData = list.map((mD) => {
                if (mD.isPrimary) {
                    return {
                        ...mD,
                        isSelected: true,
                    };
                }
                return {
                    ...mD,
                    isSelected: false,
                };
            });
        }
    }
    setState(value: 'zero' | 'one') {
        this.state = value;
    }
    clinicalCategoriesSearch(text: string) {
        this.loading = true;
        let allSLPs: SLPComorbidity[] = [];
        this.slpComorbidityService
            .getAllSLPs()
            .pipe(
                take(1),
                switchMap((slps) => {
                    allSLPs = slps;
                    return this.clinicalCategoryService.categorySearch(text, '', '');
                }),
                switchMap((category) => {
                    const cat = [...category];
                    const modCats = cat.map((item) => {
                        const tempCorMob = allSLPs.filter((slp) => {
                            return slp.code == item.code;
                        });
                        if (tempCorMob?.length > 0) {
                            item.comorbidity = tempCorMob[0].comorbidity;
                        } else {
                            item.comorbidity = 'N/A';
                        }
                        item.icd10 = item._id;
                        delete item._id;
                        return item;
                    });

                    return of(modCats);
                })
            )
            .subscribe((data) => {
                this.data = data;
                this.loading = false;
            });
    }
    // For Ag Grid
    // Working with clinical categories
    addSelectedItem(event: any) {
        const data = cloneDeep(event.data);
        const index = this.selectedData.findIndex((object) => object.code === data.code);
        if (index === -1) {
            data.onsetDate = '';
            data.isPrimary = false;
            // this.selectedData.push(data);
            this.selectedData = [...this.selectedData, data];
        } else if (index >= 0) {
            this.selectedData = this.selectedData.filter((item) => item.code !== data.code);
        }
        this.medicalDiagnosisEvent.emit(this.selectedData);
    }
    removeSelectedItem(data: any) {
        this.selectedData = this.selectedData.filter((item) => !isEqual(item, data));
        this.medicalDiagnosisEvent.emit(this.selectedData);
        this.medicalDiagnosisSave.emit(this.selectedData);

        // clear list ONLY when search 'x' is clicked
        if (!data) {
            this.data = [];
        }
    }
    setLoading(value: boolean) {
        this.loading = value;
    }
    toggleIsDone(): void {
        this.isDone = !this.isDone;
        if (this.isDone === true) {
            this.gridApi.setColumnDefs(this.columns);
        } else {
            this.gridApi.setColumnDefs(this.editColumns);
        }
        this.gridApi.sizeColumnsToFit();
        this.medicalDiagnosisEvent.emit(this.selectedData);
        if (this.isDone === true) this.medicalDiagnosisSave.emit(this.selectedData);
    }
    // Grid API functions
    initGrid(event: any) {
        this.gridApi = event.api;
    }
    redrawRows() {
        this.gridApi.redrawRows();
    }
    // AG Grid actions for renderers
    setRendererRadio(param: any) {
        this.selectedData = this.selectedData.map((item) => {
            const deepItem = cloneDeep(item);
            if (deepItem.code === param.data.code) {
                deepItem.isPrimary = true;
            } else {
                deepItem.isPrimary = false;
            }
            return deepItem;
        });
        this.markSelectedPrimaryDx(this.selectedData);
        this.medicalDiagnosisEvent.emit(this.selectedData);
        this.medicalDiagnosisSave.emit(this.selectedData);
    }
    setRendererDate(param: any) {
        this.selectedData = this.selectedData.map((item) => {
            const deepItem = cloneDeep(item);
            if (deepItem.code === param.data.code) {
                deepItem.onsetDate = param.value;
            }
            return deepItem;
        });
        this.medicalDiagnosisEvent.emit(this.selectedData);
        this.medicalDiagnosisSave.emit(this.selectedData);
    }

    setReadonlyData() {
        //this.medicalDiagnosisInput = this.medicalDiagnosis;
        if (this.medicalDiagnosisData?.length > 0) {
            this.isDone = true;
            this.state = 'one';
        }
    }
    ngOnDestroy(): void {
        this.onDestroy.next();
    }
    dateFormatter(params) {
        const datePipe: DatePipe = new DatePipe('en-US');
        return params.value ? datePipe.transform(new Date(params.value), 'MM/dd/yyyy') : null;
    }
}
