import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Subject } from 'rxjs';
const baseUrl = `${environment.apiUrl}/recertification`;
@Injectable({
    providedIn: 'root',
})
export class RecertificationNoteService {
    RecertificationNotePDF = '';
    public loadedRecertNote: Subject<number> = new Subject<number>();
    constructor(
        private http: HttpClient,
    ) { }

    getRecertificationNotePDF(): any {
        return this.RecertificationNotePDF;
    }

    setRecertificationNotePDF(value): any {
        this.RecertificationNotePDF = value;
    }
    delete(id: string) {
        return this.http.delete(`${baseUrl}/delete/with_cleanup/${id}`, {
            withCredentials: true,
        });
    }
    updateAppointment(noteId: string, note: any) {
        return this.http.patch(`${baseUrl}/update-appointment/${noteId}`, note);
    }
}
