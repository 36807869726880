import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncateText',
})
export class TruncateTextPipe implements PipeTransform {
    transform(text: string, length = 20, suffix = '...'): string {
        if (text.split('').length > length) {
            return text.split('').splice(0, length).join('') + suffix;
        }
        return text;
    }
}
