import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
const baseUrl = environment.apiUrl + '/billing/invoicing-profile';
@Injectable({
    providedIn: 'root',
})
export class InvoicingProfileService {
    private currentInvoiceProfile: any;
    private selectedFacilities: any;
    constructor(private http: HttpClient) {}

    setSelectedFacilities(facilities: any) {
        this.selectedFacilities = facilities;
    }
    getSelectedFacilities() {
        return this.selectedFacilities;
    }
    setInvoiceProfileSubject(currentInvoiceProfile: any) {
        this.currentInvoiceProfile = currentInvoiceProfile;
    }

    getCurrentInvoiceProfile() {
        return this.currentInvoiceProfile;
    }
    createInvoiceProfile(payload: any) {
        return this.http.post(`${baseUrl}/create-invoice-profile`, payload, {
            withCredentials: true,
        });
    }
    updateInvoiceProfile(payload: any) {
        return this.http.put(`${baseUrl}/update-invoice-profile`, payload, {
            withCredentials: true,
        });
    }
    getInvoiceProfileById(id: string): Observable<any> {
        return this.http.get<any>(`${baseUrl}/${id}`);
    }
    getAllInvoicingProfilesByOrganizationId(organizationId: string): Observable<any> {
        return this.http.get<any>(`${baseUrl}/invoicing-profiles-by-organizationId/${organizationId}`);
    }
    removeFacilityFromInvoice(facilityId: string, invoicingProfileId: string): Observable<any> {
        return this.http.put<any>(`${baseUrl}/remove-facility-from-invoice/${facilityId}/${invoicingProfileId}`, {
            withCredentials: true,
        });
    }
    getGeneratedInvoiceData(facilityId: string, minDate: string, maxDate: string): Observable<any> {
        return this.http.get<any>(`${baseUrl}/generate/${facilityId}/${minDate}/${maxDate}`);
    }
}
