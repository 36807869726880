import { Component } from '@angular/core';
import { GridActionConstants } from '@app/data-grid/interfaces/grid-row-action';
import { GridActionsService } from '@app/data-grid/services/grid-actions.service';
import { DOCUMENT_CONSTANTS, MODAL_HEADINGS, MODAL_MESSAGES } from '@app/interfaces/documentation';
import { ModalService } from '@app/services';
import { ConfirmationModalComponent } from '@app/shared/modals/v2/confirmation-modal/confirmation-modal.component';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { AssessmentGoalsDeletionModalComponent } from '../assessment-goals-deletion-modal/assessment-goals-deletion-modal.component';

@Component({
    selector: 'app-patient-assessment-switch-cell-renderer',
    templateUrl: './patient-assessment-switch-cell-renderer.component.html',
    styleUrls: ['./patient-assessment-switch-cell-renderer.component.scss'],
})
export class PatientAssessmentSwitchCellRendererComponent implements ICellRendererAngularComp {
    switchValue: boolean;
    params;
    radioId: string;

    noteType: string;
    deletionModalComponentToOpen;
    modalHeading: string;
    modalMessage: string;

    constructor(private gridActionService: GridActionsService, private modalService: ModalService) {}

    agInit(params: ICellRendererParams): void {
        this.noteType = params['noteType'];
        if (params['noteType'] === DOCUMENT_CONSTANTS.EVALUATION) {
            this.modalHeading = MODAL_HEADINGS.UNMARK_ASSESSMENT_ITEM_AS_A_GOAL;
            this.modalMessage = MODAL_MESSAGES.UNMARK_ASSESSMENT_ITEM_AS_A_GOAL;
            this.deletionModalComponentToOpen = ConfirmationModalComponent;
        } else {
            this.modalHeading = MODAL_HEADINGS.UNMARK_ASSESSMENT_ITEM_AS_A_GOAL;
            this.modalMessage = MODAL_MESSAGES.UNMARK_ASSESSMENT_ITEM_AND_DISCONTINUE_GOALS;
            this.deletionModalComponentToOpen = AssessmentGoalsDeletionModalComponent;
        }
        this.switchValue = params.value;
        this.params = params;
        params.data?.impairmentName
            ? (this.radioId = params.data.impairmentName + params.data.fieldName + params.data.subSection)
            : params.data.name
            ? (this.radioId = params.data.name)
            : (this.radioId = 'defaultId');
    }
    refresh() {
        // Return false so the grid will refresh the cell for you.
        return false;
    }

    onSwitchValue(event): void {
        if (this.switchValue) {
            event.preventDefault();
            this.modalService
                .open(this.deletionModalComponentToOpen, {
                    data: {
                        heading: this.modalHeading,
                        message: this.modalMessage,
                        iconName: 'circleCross',
                        primaryButtonText: 'Yes, Sure',
                        primaryButtonClass: 'btn-danger',
                        secondaryButtonText: 'Cancel'
                    },
                })
                .afterClosed()
                .subscribe((result) => {
                    if (result) {
                        this.params.value = this.params.data.isAGoal = false;
                        if (this.noteType !== DOCUMENT_CONSTANTS.EVALUATION) {
                            this.params.data.discontinueStgs = result.discontinueStgs;
                            this.params.data.reasonForDiscontinuing = result.reasonForDiscontinuing;
                        }
                        this.emitRowAction();
                    } else {
                        event.preventDefault();
                    }
                });
        }
    }

    emitChangedValue(): void {
        this.params.value = this.params.data.isAGoal = this.switchValue;
        this.emitRowAction();
    }

    emitRowAction(): void {
        const { data, rowIndex, gridName } = this.params;
        this.gridActionService.setSelectedAction(GridActionConstants.MAKE_A_GOAL, data, rowIndex, gridName);
    }
}
