import { Component, Input } from '@angular/core';
import { PatientState } from '@app/store/store.interface';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-patients-left-card',
    templateUrl: './patients-left-card.component.html',
    styleUrls: ['./patients-left-card.component.scss'],
})
export class PatientsLeftCardComponent {
    @Input() patient$: Observable<PatientState>;
}
