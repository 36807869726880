<!-- <app-grid-card
  heading="Frequency and Duration"
  [columnDefs]="frequencyCols"
  [rowData]="frequencyAndDuration"
  [defaultAction]="defaultAction"
  noDataTargetView="ADD_MORE"
  [numbered]="true"
  [controls]="frequencyControls"
  (onChange)="addTo('frequencyAndDuration', $event); setDocStore('frequencyAndDuration', frequencyAndDuration)"
>
</app-grid-card> -->

<div >
    <app-control-widget
        [controls]="frequencyControls"
        contentClass="d-flex flex-row align-items-center justify-content-start"
        (onEmitData)="dataHandler($event)"
        [dataInput]="dataInput"
        (frequencyFromEvent)="setFrequencyToOptions($event)"
    ></app-control-widget>
    <!-- (durationEvent)="setDuration($event)" -->
</div>

<!-- <div class="custom-card bg-white p-2">
    <form [formGroup]="frequencyAndDurationForm" class="d-flex flex-row">
        <div class="mb-3 mt-3 row col-md-12">
            <app-select
                class="col-md-3"
                label="Frequency"
                formControlName="frequency"
                [options]="frequencyFromOptions"
                [placeholder]="'Frequency'"
            >
            </app-select>
            <div class="form-group col-md-6">
                <div class="ml-1">
                    <label for="durationGroup" class="text-secondary body-medium-semibold mb-0"
                        >Duration<span>*</span></label
                    >
                </div>
                <div id="durationGroup">
                    <div formGroupName="duration" class="d-flex flex-row">
                        <app-input class="col-md-3" type="number" [placeholder]="' '" formControlName="duration">
                        </app-input>
                        <app-select
                            class="col-md-3"
                            formControlName="durationType"
                            [placeholder]="'Duration'"
                            [options]="durationParamOptions"
                        >
                        </app-select>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div> -->
