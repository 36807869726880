<div class="row">
    <div class="col-md-6">
        <!-- <h5>{{ documentType }}</h5> -->
    </div>

    <div class="col-md-6 d-flex justify-content-end" *ngIf="isButton">
        <button *ngIf="statusChip.show" class="mt-3 mr-2 innova-btn btn-success">{{ statusChip.text }}</button>

        <app-button
            *ngIf="canEdit()"
            [type]="isCoSign ? 'btn text-secondary' : 'btn btn-outline-secondary'"
            text="Add Addendum"
            icon="add"
            tooltipPlacement="left"
            class="mt-2"
            [tooltipText]="tooltipCheck()"
            [disabled]="disableButtons"
            [rightIcon]="false"
            [style]="
                !admissionDischarged
                    ? this.AuthService.isManager || this.AuthService.isTherapist
                        ? ''
                        : 'opacity:0.5'
                    : 'opacity:0.5'
            "
            (click)="!admissionDischarged && scrollToElement('addendumNote')"
        ></app-button>
        <app-button
            *ngIf="
                documentType !== DOCUMENTATION_CONSTANTS.RECERTIFICATION &&
                documentType !== DOCUMENTATION_CONSTANTS.UPDATED_PLAN_OF_CARE &&
                canEdit()
            "
            [type]="isCoSign ? 'btn btn-outline-secondary' : 'btn btn-secondary'"
            text="Edit"
            icon="edit"
            iconClass="small ml-1"
            class="ml-2 mt-2"
            buttonClass="py-2"
            [rightIcon]="true"
            (onClick)="onEdit()"
            [disabled]="disableButtons"
        ></app-button>
    </div>
</div>
