<ng-container *ngIf="loading$ | async; else disabled">
    <span>
        <div class="auto-saving-spinner enable">
            <img loading="lazy" class="arrow-spinner rotation" src="./../../../assets/icons/arrow-circle.svg" />
            <img loading="lazy" class="icon-with-txt" src="./../../../assets/icons/auto-saving-icon-txt.svg" />
        </div>
    </span>
</ng-container>

<ng-template #disabled>
    <span>
        <div class="auto-saving-spinner disable">
            <img loading="lazy" class="arrow-spinner" src="./../../../assets/icons/arrow-circle.svg" />
            <img loading="lazy" class="icon-with-txt" src="./../../../assets/icons/auto-saving-icon-txt.svg" />
        </div>
    </span>
</ng-template>
