<div class="form-shadow p-3 sticky-sidebar">
    <h5 class="h5 mt-2">{{ (patient$ | async).firstName }}, {{ (patient$ | async).lastName }}</h5>
    <!-- list -->
    <div>
        <!-- list title -->
        <p class="body1 mt-4 text-primary-light">
            {{ 'appTranslation.pages.patients.general_information' | translate | titlecase }}
        </p>
        <!-- list item -->
        <div class="row">
            <div class="col-6">
                <p class="caption">{{ 'appTranslation.pages.patients.mrn' | translate | uppercase }}</p>
            </div>
            <div class="col-6">
                <p class="caption text-primary-light text-right">98527</p>
            </div>
        </div>
        <!-- list item -->
        <div class="row">
            <div class="col-6">
                <p class="caption">{{ 'appTranslation.pages.patients.disc' | translate | uppercase }}</p>
            </div>
            <div class="col-6">
                <p class="caption text-primary-light text-right">PT</p>
            </div>
        </div>
        <!-- list item -->
        <div class="row">
            <div class="col-6">
                <p class="caption">{{ 'appTranslation.pages.patients.admitted' | translate | titlecase }}</p>
            </div>
            <div class="col-6">
                <p class="caption text-primary-light text-right">10-18-2021</p>
            </div>
        </div>
        <!-- list item -->
        <div class="row">
            <div class="col-6">
                <p class="caption">{{ 'appTranslation.pages.patients.pt_soc' | translate | uppercase }}</p>
            </div>
            <div class="col-6">
                <p class="caption text-primary-light text-right">9-18-2021</p>
            </div>
        </div>
        <!-- list item -->
        <div class="row">
            <div class="col-6">
                <p class="caption">{{ 'appTranslation.pages.patients.room_no' | translate | titlecase }}</p>
            </div>
            <div class="col-6">
                <p class="caption text-primary-light text-right">005</p>
            </div>
        </div>
        <!-- list item -->
        <div class="row">
            <div class="col-6">
                <p class="caption">{{ 'appTranslation.pages.patients.facility' | translate | titlecase }}</p>
            </div>
            <div class="col-6">
                <p class="caption text-primary-light text-right">8005-Training Facility</p>
            </div>
        </div>
    </div>
    <!-- list -->
    <div>
        <!-- list title -->
        <p class="body1 mt-4 text-primary-light">
            {{ 'appTranslation.pages.patients.payor_information' | translate | titlecase }}
        </p>
        <!-- list item -->
        <div class="row">
            <div class="col-6">
                <p class="caption">Payor</p>
            </div>
            <div class="col-6">
                <p class="caption text-primary-light text-right">RUG MNG</p>
            </div>
        </div>
        <!-- list item -->
        <div class="row">
            <div class="col-6">
                <p class="caption">{{ 'appTranslation.pages.patients.payor_type' | translate | titlecase }}</p>
            </div>
            <div class="col-6">
                <p class="caption text-primary-light text-right">Medicare Part A</p>
            </div>
        </div>
        <!-- list item -->
        <div class="row">
            <div class="col-6">
                <p class="caption">{{ 'appTranslation.pages.patients.co_pay' | translate | titlecase }}</p>
            </div>
            <div class="col-6">
                <p class="caption text-primary-light text-right">$ 50</p>
            </div>
        </div>
        <!-- list item -->
        <div class="row">
            <div class="col-6">
                <p class="caption">{{ 'appTranslation.pages.patients.prior_auth_required' | translate | titlecase }}</p>
            </div>
            <div class="col-6">
                <p class="caption text-primary-light text-right">YES</p>
            </div>
        </div>
        <!-- list item -->
        <div class="row">
            <div class="col-6">
                <p class="caption">{{ 'appTranslation.pages.patients.authorized_visits' | translate | titlecase }}</p>
            </div>
            <div class="col-6">
                <p class="caption text-primary-light text-right">12</p>
            </div>
        </div>
        <!-- list item -->
        <div class="row">
            <div class="col-6">
                <p class="caption">{{ 'appTranslation.pages.patients.completed_visits' | translate | titlecase }}</p>
            </div>
            <div class="col-6">
                <p class="caption text-primary-light text-right">2/12</p>
            </div>
        </div>
    </div>
    <!-- list -->
    <div>
        <!-- list title -->
        <p class="body1 mt-4 text-primary-light">
            {{ 'appTranslation.pages.patients.physicians_orders' | translate | titlecase }}
        </p>
        <!-- list item -->
        <div class="row">
            <div class="col-6">
                <p class="caption">{{ 'appTranslation.pages.patients.therapy_orders' | translate | titlecase }}</p>
            </div>
            <div class="col-6">
                <p class="caption text-primary-light text-right">Date</p>
            </div>
        </div>
        <!-- list item -->
        <div class="row">
            <div class="col-6">
                <p class="caption">{{ 'appTranslation.pages.patients.disc' | translate | uppercase }}</p>
            </div>
            <div class="col-6">
                <p class="caption text-primary-light text-right">PT</p>
            </div>
        </div>
        <!-- list item -->
        <div class="row">
            <div class="col-6">
                <p class="caption">{{ 'appTranslation.pages.patients.admitted' | translate | titlecase }}</p>
            </div>
            <div class="col-6">
                <p class="caption text-primary-light text-right">10-18-2021</p>
            </div>
        </div>
        <!-- list item -->
        <div class="row">
            <div class="col-6">
                <p class="caption">{{ 'appTranslation.pages.patients.pt_soc' | translate | uppercase }}</p>
            </div>
            <div class="col-6">
                <p class="caption text-primary-light text-right">9-18-2021</p>
            </div>
        </div>
        <!-- list item -->
        <div class="row">
            <div class="col-6">
                <p class="caption">{{ 'appTranslation.pages.patients.room_no' | translate | titlecase }}</p>
            </div>
            <div class="col-6">
                <p class="caption text-primary-light text-right">005</p>
            </div>
        </div>
        <!-- list item -->
        <div class="row">
            <div class="col-6">
                <p class="caption">{{ 'appTranslation.pages.patients.facility' | translate | titlecase }}</p>
            </div>
            <div class="col-6">
                <p class="caption text-primary-light text-right">8005-Training Facility</p>
            </div>
        </div>
    </div>
</div>
