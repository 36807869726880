<table #recertNoteReport id="recertNoteReport" class="print-table" *ngIf="isDataLoaded">
    <thead #header id="header" class="print-header">
        <!-- <th class="n-header">
            <app-header [isFirst]="false" [type]="'recertificationNote'"></app-header>
        </th> -->
        <tr class="print-first-header">
            <app-header [isFirst]="true" [type]="'recertificationNote'" [toExport]="toExport"></app-header>
        </tr>
    </thead>
    <tr style="page-break-before: avoid">
        <app-grid-layout [type]="'recertificationNote'" [key]="'medicalDiagnosis'"></app-grid-layout>
    </tr>
    <tr>
        <app-grid-layout [type]="'recertificationNote'" [key]="'treatmentDiagnosis'"></app-grid-layout>
    </tr>
    <tr>
        <app-grid-layout
            [gridType]="'NonHeaderGrid'"
            [type]="'recertificationNote'"
            [key]="'precautions'"
        ></app-grid-layout>
    </tr>
    <tr>
        <app-grid-layout
            [gridType]="'NonHeaderGrid'"
            [type]="'recertificationNote'"
            [key]="'contraindications'"
        ></app-grid-layout>
    </tr>
    <tr>
        <app-paragraph [type]="'recertificationNote'" [key]="'patientAssessmentSinceLastTreatment'"></app-paragraph>
    </tr>
    <tr>
        <app-grid-layout [gridType]="'TreeGrid'" [type]="'recertificationNote'" [key]="'impairments'"></app-grid-layout>
    </tr>
    <tr>
        <app-grid-layout
            [gridType]="'TreeGrid'"
            [type]="'recertificationNote'"
            [key]="'functionalDeficits'"
        ></app-grid-layout>
    </tr>
    <tr>
        <app-grid-layout
            [gridType]="'TreeGrid'"
            [type]="'recertificationNote'"
            [key]="'standardizedTests'"
        ></app-grid-layout>
    </tr>
    <tr>
        <app-paragraph [type]="'recertificationNote'" [key]="'clinicalImpression'"></app-paragraph>
    </tr>
    <tr>
        <app-paragraph [type]="'recertificationNote'" [key]="'therapyNecessity'"></app-paragraph>
    </tr>
    <tr>
        <app-paragraph [type]="'recertificationNote'" [key]="'prognosisForAchievingRehabGoals'"></app-paragraph>
    </tr>
    <tr>
        <app-paragraph [type]="'recertificationNote'" [key]="'reasonForContinuingTreatment'"></app-paragraph>
    </tr>
    <tr>
        <app-paragraph [type]="'recertificationNote'" [key]="'updateToTreatment'"></app-paragraph>
    </tr>
    <tr>
        <app-paragraph [type]="'recertificationNote'" [key]="'dischargePlan'"></app-paragraph>
    </tr>
    <tr>
        <app-paragraph [type]="'recertificationNote'" [key]="'dischargeDestination'"></app-paragraph>
    </tr>
    <tr>
        <app-grid-layout [gridType]="'DynamicGrid'" [type]="'recertificationNote'" [key]="'goals'"></app-grid-layout>
    </tr>
    <tr>
        <app-grid-layout [type]="'recertificationNote'" [key]="'skilledServices'"></app-grid-layout>
    </tr>
    <tr>
        <app-grid-layout
            [gridType]="'DynamicGrid'"
            [type]="'recertificationNote'"
            [key]="'frequencyAndDuration'"
        ></app-grid-layout>
    </tr>
    <tr>
        <app-grid-layout [gridType]="'TreeGrid'" [type]="'recertificationNote'" [key]="'addendum'"></app-grid-layout>
    </tr>
    <tr class="print-footer" *ngIf="isDataLoaded">
        <app-signature [type]="'recertificationNote'"></app-signature>
    </tr>
</table>
