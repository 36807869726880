<ng-container *ngIf="(loadingObs | async)?.isLoading; else loaded" [ngTemplateOutlet]="skeleton"></ng-container>
<ng-template #loaded>
    <div *ngIf="!(loadingObs | async)?.isLoading" [class]="printMode ? 'col-md-11' : ''">
        <div *ngIf="!printMode" class="info-bar-padding" style="height: 65px">
            <div *ngIf="!evalHistorySide && currentDocSigned" class="w-100">
                <app-info-bar
                    [documentType]="DOCUMENTATION_CONSTANTS.PROGRESS"
                    [disableButtons]="isDifferentDisciplineTherapist"
                    [isCoSign]="showCoSign"
                >
                </app-info-bar>
            </div>
        </div>

        <div class="row">
            <div
                class="col-lg-10 col-md-9 col-sm-9 mb-5"
                [ngClass]="evalHistorySide == true ? 'col-lg-10' : 'col-lg-12'"
                [class]="printMode ? '' : 'w-100'"
            >
                <app-view-mode-table
                    id="medicalDiagnosisSection"
                    [tableTitle]="medicalDiagnosis.title"
                    [tableCol]="medicalDiagnosis.cols"
                    [tableData]="medicalDiagnosis.data"
                    [pagination]="false"
                    [showCount]="false"
                    [headerFull]="true"
                    tableHeight="height:250px"
                ></app-view-mode-table>

                <div class="row pt-5">
                    <div class="col-md-6">
                        <app-view-mode-table
                            id="treatmentDiagnosisSection"
                            [tableTitle]="treatmentDiagnosis.title"
                            [tableCol]="treatmentDiagnosis.cols"
                            [tableData]="treatmentDiagnosis.data"
                            [pagination]="false"
                            [showCount]="false"
                            [headerFull]="true"
                            tableHeight="height:250px"
                        ></app-view-mode-table>
                    </div>

                    <div class="col-md-6" id="precautionsSection">
                        <app-view-mode-table
                            [tableTitle]="precautions.title"
                            [tableCol]="precautions.cols"
                            [tableData]="precautions.data"
                            [pagination]="false"
                            [showCount]="false"
                            [headerFull]="true"
                            tableHeight="height:250px"
                        ></app-view-mode-table>
                    </div>
                </div>

                <div class="row pt-5">
                    <div class="col-md-6" id="contraindicationsSection">
                        <app-view-mode-table
                            [tableTitle]="contraindications.title"
                            [tableCol]="contraindications.cols"
                            [tableData]="contraindications.data"
                            [pagination]="false"
                            [showCount]="false"
                            [headerFull]="true"
                            tableHeight="height:250px"
                        ></app-view-mode-table>
                    </div>

                    <div class="col-md-6" id="patientAssessmentSinceLastTreatmentSection">
                        <app-view-mode-paragraph
                            [paragraphTitle]="assessmentLastWeek.title"
                            [paragraph]="assessmentLastWeek.paragraph"
                            [innerHtml]="assessmentLastWeek.paragraph"
                        ></app-view-mode-paragraph>
                    </div>
                </div>

                <div class="row pt-5">
                    <div class="col-md-12" id="goalsSection">
                        <app-view-mode-goals
                            tableTitle="Patient Goals"
                            [goalData]="goal"
                            [isHistoryMode]="evalHistorySide"
                        ></app-view-mode-goals>
                    </div>
                </div>

                <div class="row pt-5">
                    <div class="col-md-12" id="clinicalImpressionSection">
                        <app-view-mode-paragraph
                            [paragraphTitle]="clinicalImpression.title"
                            [paragraph]="clinicalImpression.paragraph"
                            [innerHtml]="clinicalImpression.paragraph"
                        ></app-view-mode-paragraph>
                    </div>
                </div>

                <div class="row pt-5" id="overallPlanAndUpdatesSection">
                    <div class="col-md-12">
                        <app-view-mode-paragraph
                            [paragraphTitle]="currentUpdatesTreatment.title"
                            [paragraph]="currentUpdatesTreatment.paragraph"
                            [innerHtml]="currentUpdatesTreatment.paragraph"
                        ></app-view-mode-paragraph>
                    </div>
                </div>

                <div class="custom-card mt-5" id="addendumSection">
                    <div [ngClass]="!printMode && !evalHistorySide ? 'addendumBox' : ''">
                        <app-view-mode-table
                            [tableTitle]="addendum.title"
                            [tableCol]="addendum.cols"
                            [tableData]="addendum.data"
                            [pagination]="false"
                            [showCount]="false"
                            [insideCard]="false"
                            [headerFull]="true"
                        ></app-view-mode-table>
                    </div>

                    <div id="addendumNote" class="row pt-4 px-3" *ngIf="!printMode && !evalHistorySide && canEdit()">
                        <div class="col-md-12">
                            <app-text-box
                                [isSaveButton]="true"
                                backgroundClass="bg-body-background"
                                (emitData)="updateAddendum($event)"
                                [suggestionBoxValue]="currentAddendum"
                                [disabled]="(!authService.isManager && !authService.isTherapist) || admissionDischarged"
                                label="Add new addendum"
                                maxLength="5000"
                            ></app-text-box>
                        </div>
                    </div>

                    <div class="row my-3 pr-3" *ngIf="!printMode && !evalHistorySide && canEdit()">
                        <div class="col-md-12">
                            <app-button
                                type="btn btn-outline-secondary"
                                text="Add"
                                icon="chevron_right"
                                [tooltipText]="tooltipCheck()"
                                tooltipPlacement="left"
                                [rightIcon]="true"
                                class="ml-1 add-btn"
                                [disabled]="isDifferentDisciplineTherapist"
                                (click)="!admissionDischarged && addAddendumNote()"
                                [style]="
                                    !admissionDischarged
                                        ? authService.isManager || authService.isTherapist
                                            ? ''
                                            : 'opacity:0.5'
                                        : 'opacity:0.5'
                                "
                            ></app-button>
                        </div>
                    </div>
                </div>
                <div *ngIf="showCoSign" class="co-sign-btn-holder bg-body-background text-right">
                    <app-button
                        type="btn btn-secondary"
                        text="Co-sign"
                        icon="chevron_right"
                        iconClass="small ml-1"
                        class="ml-2 mt-2"
                        buttonClass="py-2"
                        [rightIcon]="true"
                        [disabled]="!enableCoSignBtn"
                        (onClick)="onCoSignClicked()"
                    ></app-button>
                </div>

                <div
                    *ngIf="currentDocSigned"
                    class="open-history-btn text-white d-flex align-items-center"
                    [ngClass]="evalHistorySide == true ? 'hide' : 'show'"
                    (click)="openEvalHistory()"
                >
                    <span class="material-icons-round text-white">history</span>
                    Open Edit History
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-3" *ngIf="!printMode">
                <app-history (closed)="closeHistorySideBar($event)" header="Edit History" *ngIf="evalHistorySide">
                    <div
                        id="changeInstance"
                        *ngFor="let history of changeHistory; let i = index; let first = first; let last = last"
                        (click)="prepareChangeInstanceData(i)"
                    >
                        <div
                            *ngIf="!first || last"
                            class="main-body p-4"
                            [class.selected-changeInstance]="i == selectedChangeInstance"
                        >
                            <span class="text-secondary body-medium-bold">{{
                                last
                                    ? (history.signed.signDate | date: 'MMM d, y, h:mm a')
                                    : (history.changeHistory.editedAt | date: 'MMM d, y, h:mm a')
                            }}</span>
                            <ng-container *ngIf="history?.changeHistory?.changedFields?.length > 0 && !last">
                                <p
                                    class="change-item-text"
                                    *ngFor="let change of history.changeHistory.changedFields"
                                    [ngClass]="{
                                        'underline-item':
                                            change.fieldKey === selectedLabel && i === selectedChangeInstance
                                    }"
                                    (click)="scroll(change.fieldKey, i)"
                                >
                                    {{
                                        'appTranslation.pages.documentation.progress' + '.' + change.fieldKey
                                            | translate
                                    }}
                                </p>
                            </ng-container>
                            <ng-container *ngIf="last">
                                <p class="change-item-text">Original Document</p>
                            </ng-container>
                            <p class="mb-0">
                                <svg
                                    width="8"
                                    height="8"
                                    viewBox="0 0 8 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle cx="4" cy="4" r="3.5" fill="#69A981" />
                                </svg>
                                <span class="ml-2 body-small">{{
                                    last
                                        ? history.signed.signedBy.lastName + ', ' + history.signed.signedBy.firstName
                                        : history.changeHistory.signedBy.lastName +
                                          ', ' +
                                          history.changeHistory.signedBy.firstName
                                }}</span>
                            </p>
                        </div>
                        <div class="bg-secondary-a20" style="height: 1px"></div>
                    </div>
                </app-history>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #skeleton>
    <div>
        <br />
        <ngx-skeleton-loader
            appearance="line"
            [theme]="{ width: '25em', height: '7em', 'margin-left': '2em' }"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
            appearance="line"
            [theme]="{ width: '70em', height: '7em', 'margin-left': '3em' }"
        ></ngx-skeleton-loader>
        <br />
        <ngx-skeleton-loader
            appearance="line"
            [theme]="{ width: '25em', height: '5em', 'margin-left': '2em' }"
        ></ngx-skeleton-loader>
        <br />
        <ngx-skeleton-loader
            appearance="line"
            [theme]="{ width: '80%', height: '5em', 'margin-left': '2em' }"
        ></ngx-skeleton-loader>
        <br />
        <ngx-skeleton-loader
            appearance="line"
            [theme]="{ width: '40%', height: '4em', 'margin-left': '2em' }"
        ></ngx-skeleton-loader>
        <br />
        <ngx-skeleton-loader
            appearance="line"
            [theme]="{ width: '80%', height: '30em', 'margin-left': '2em' }"
        ></ngx-skeleton-loader>
    </div>
</ng-template>
