import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getWeekEndDate, getWeekStartDate } from '@app/helpers/utils';
import { filterNotesByDateRange } from '@app/helpers/utils/data.utils';
import { Scheduler } from '@app/interfaces';
import { MinutesToHoursMinutesPipe } from '@app/pipes';
import {
    DocumentationService,
    FacilityManagementService,
    PrintService,
    SchedulerService,
    SchedulerV2Service,
    StoreService,
} from '@app/services';
import { SchedulerCalendarState } from '@app/store/store.interface';
import { Store } from '@ngrx/store';
import { ColDef } from 'ag-grid-community';
import { cloneDeep } from 'lodash';
import { Subject, Subscription, takeUntil } from 'rxjs';

@Component({
    selector: 'app-therapist-case-load',
    templateUrl: './therapist-case-load.component.html',
    styleUrls: ['./therapist-case-load.component.scss'],
    providers: [MinutesToHoursMinutesPipe],
})
export class TherapistCaseLoadComponent implements OnDestroy {
    private readonly onDestroy = new Subject<void>();

    therapistIds: string[];
    columnDefs: ColDef[] = [];
    rowData = [];
    sortCaseload: any = {};
    therapistDetails: any[] = [];
    scheduleDate = '';
    therapists: any = {};
    subscriptions: Subscription = new Subscription();
    dateLabel = ''; // used when print from therapist caseload for week view
    therapistCaseloadFilters: any;

    constructor(
        router: Router,
        route: ActivatedRoute,
        private printService: PrintService,
        private storeService: StoreService,
        private schedulerService: SchedulerService,
        private schedulerV2Service: SchedulerV2Service,
        private minutesHoursPipe: MinutesToHoursMinutesPipe,
        private facilityManagementService: FacilityManagementService,
        private documentationService: DocumentationService,
        private store: Store<{
            scheduler: Scheduler;
            schedulerCalendar: SchedulerCalendarState;
        }>
    ) {
        this.sortCaseload = this.documentationService.getSortedCaseload();
        this.scheduleDate = route.snapshot.queryParamMap.get('date');
        this.therapistIds = route.snapshot.params['therapistIds']?.split(',');
        if (this.therapistIds && this.therapistIds.length > 0) {
            this.schedulerV2Service
                .getSchedulerData(
                    this.facilityManagementService._currentFacility.id,
                    getWeekStartDate(new Date(this.scheduleDate))
                )
                .pipe(takeUntil(this.onDestroy))
                .subscribe((result: any) => {
                    this.storeService.setSchedulerCalendar(new Date(this.scheduleDate));
                    result.data.patients = filterNotesByDateRange(
                        result.data.patients,
                        getWeekStartDate(new Date(this.scheduleDate)),
                        getWeekEndDate(new Date(this.scheduleDate))
                    );
                    this.storeService.setScheduler(result.data);
                    this.storeService.setLoadingState(false);
                    this.therapists = result.data.therapists;
                    this.mapRowData(this.therapists, this.therapistIds);
                });
        } else {
            const routerStateData = router.getCurrentNavigation()?.extras?.state?.therapist;
            if (router.getCurrentNavigation()?.extras?.state?.dateLabel) {
                this.dateLabel = router.getCurrentNavigation()?.extras?.state?.dateLabel;
            }
            this.therapistCaseloadFilters = router.getCurrentNavigation()?.extras?.state?.therapistCaseloadFilters;
            if (routerStateData) {
                this.therapists = routerStateData;
                this.mapTherapistDetails(routerStateData instanceof Array ? routerStateData : [routerStateData]);
            }
        }
    }
    mapRowData(therapists, therapistIds) {
        this.rowData = [];
        therapists.forEach((d) => {
            const therapistId = therapistIds.find((i) => i == d._id);
            if (therapistId) {
                const noOfPatients = this.dailyTherapistData(d);
                const caseload = this.therapistCaseload(d);
                noOfPatients &&
                    this.rowData.push({
                        id: d._id,
                        name: `${d.user.lastName}, ${d.user.firstName}`,
                        email: d.user.email,
                        discipline: d.discipline,
                        noOfPatients: noOfPatients,
                        caseload: caseload,
                        currentFacility: this.facilityManagementService._currentFacility,
                    });
            }
        });

        if (this.rowData && this.rowData.length) {
            this.therapists = this.rowData;
            this.mapTherapistDetails(this.rowData instanceof Array ? this.rowData : [this.rowData]);
        }
    }

    dailyTherapistData(therapist) {
        let notesCount = 0;
        for (const availability of therapist.availability) {
            if (availability.caseloads.length > 0) {
                const availabilityCaseloads = availability.caseloads.find(
                    (caseLoad) =>
                        new Date(caseLoad.day).setHours(0, 0, 0, 0) == new Date(this.scheduleDate).setHours(0, 0, 0, 0)
                );
                notesCount = availabilityCaseloads
                    ? availabilityCaseloads.notes.eval?.length +
                      availabilityCaseloads.notes.dailyNotes?.length +
                      availabilityCaseloads.notes.progressNotes?.length +
                      availabilityCaseloads.notes.dischargeNotes?.length
                    : 0;
            }
        }
        return notesCount;
    }
    therapistCaseload(therapist) {
        let caseloads = null;
        for (const availability of therapist.availability) {
            if (availability.caseloads.length > 0) {
                const availabilityCaseloads = availability.caseloads.find(
                    (caseLoad) =>
                        new Date(caseLoad.day).setHours(0, 0, 0, 0) == new Date(this.scheduleDate).setHours(0, 0, 0, 0)
                );
                if (availabilityCaseloads) {
                    caseloads = availabilityCaseloads;
                }
            }
        }
        return caseloads;
    }
    mapTherapistDetails(therapists: any[]) {
        const caseloadIds = therapists.map((therapist: any) => therapist.caseload._id);
        this.schedulerV2Service
            .caseloadPrint({ ids: caseloadIds })
            .pipe(takeUntil(this.onDestroy))
            .subscribe({
                next: (result: any) => {
                    for (let index = 0; index < therapists.length; index++) {
                        therapists[index].caseload = cloneDeep(
                            result.data.find((caseload: any) => caseload._id == therapists[index].caseload._id)
                        );
                        therapists[index].caseload.patientNotes = cloneDeep(
                            this.buildNotes(therapists[index].caseload)
                        );
                        if (
                            therapists[index].caseload &&
                            therapists[index].caseload.notes?.timeBlocks?.length &&
                            !this.dateLabel
                        ) {
                            const timeBlock = {
                                minutes: therapists[index].caseload.notes.timeBlocks[0].plannedTx,
                                reason: therapists[index].caseload.notes.timeBlocks[0].reason,
                                exists: true,
                            };
                            therapists[index].timeBlock = timeBlock;
                        }
                    }
                    therapists[0].caseload.patientNotes = therapists[0].caseload.patientNotes.sort((a, b) => {
                        if (this.sortCaseload.colId === 'patientName') {
                            if (this.sortCaseload.sort === 'asc') {
                                return a.docId.facilityAdmission?.patient?.lastName
                                    ?.toUpperCase()
                                    .localeCompare(b.docId.facilityAdmission?.patient?.lastName?.toUpperCase()); // Ascending order
                            } else {
                                return b.docId.facilityAdmission?.patient?.lastName
                                    ?.toUpperCase()
                                    .localeCompare(a.docId?.facilityAdmission?.patient?.lastName?.toUpperCase()); // Descending order
                            }
                        } else {
                            if (this.sortCaseload.sort === 'asc') {
                                return a.docId.facilityAdmission?.roomNumber?.localeCompare(
                                    b.docId.facilityAdmission?.roomNumber
                                ); // Ascending order
                            } else {
                                return b.docId.facilityAdmission?.roomNumber?.localeCompare(
                                    a.docId.facilityAdmission?.roomNumber
                                ); // Descending order
                            }
                        }
                    });
                    therapists[0].caseload.patientNotes.sort((a, b) => {
                        return a.docId.facilityAdmission?.patient?.lastName
                            ?.toUpperCase()
                            .localeCompare(b.docId.facilityAdmission?.patient?.lastName?.toUpperCase());
                    });
                    this.therapists = therapists;
                    this.sortTherapists();
                    window.onafterprint = this.afterPrint;
                    setTimeout(() => {
                        print();
                    }, 0);
                },
                error: (v) => {
                    console.log(v);
                },
            });
    }
    afterPrint(): void {
        window.history.back();
    }
    sortTherapists() {
        this.therapists = this.therapists.sort((a, b) => {
            const dateA = new Date(a.caseload?.day);
            const dateB = new Date(b.caseload?.day);
          
            // Check if both dates are valid
            if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
              return dateA.getTime() - dateB.getTime(); // Sort by date
            } else {
              return 0; // If either date is invalid, treat them as equal
            }
          }); 
    }
    checkDescriptionExists(descObjects: any) {
        if (!descObjects || descObjects.length == 0) {
            return false;
        } else {
            return descObjects.find((descObject) => descObject.description);
        }
    }
    buildNotes(caseload) {
        if (this.therapistCaseloadFilters) {
            this.appliedFilter(caseload);
        }
        const notes = [];
        if (caseload.notes?.progressNotes) {
            for (const progressNote of caseload.notes.progressNotes) {
                notes.push({ noteType: 'Progress Note', ...progressNote });
            }
        }
        if (caseload.notes?.dailyNotes) {
            for (const dailyNote of caseload.notes.dailyNotes) {
                notes.push({ noteType: 'Daily Note', ...dailyNote });
            }
        }
        if (caseload.notes?.eval) {
            for (const evaluation of caseload.notes.eval) {
                notes.push({ noteType: 'Evaluation', ...evaluation });
            }
        }
        if (caseload.notes?.dischargeNotes) {
            for (const dischargeNote of caseload.notes.dischargeNotes) {
                notes.push({ noteType: 'Discharge Note', ...dischargeNote });
            }
        }
        if (caseload.notes?.recertificationNotes) {
            for (const recertNote of caseload.notes.recertificationNotes) {
                notes.push({ noteType: 'Recertification Note', ...recertNote });
            }
        }
        return notes;
    }

    calculatePlannedMinutes(weekFrequency): number {
        const found = weekFrequency.find(
            (wf) => new Date(wf.day?.day).setHours(0, 0, 0, 0) === new Date(this.scheduleDate).setHours(0, 0, 0, 0)
        );
        if (found) return found.day.individual.planned + found.day.concurrent.planned + found.day.group.planned;
        else return 0;
    }

    appliedFilter(caseload) {
        if (this.therapistCaseloadFilters.documentType != 'All') {
            let docType =
                this.therapistCaseloadFilters.documentType == 'Evaluation'
                    ? 'eval'
                    : this.therapistCaseloadFilters.documentType.replace(' ', '') + 's';
            docType = docType.charAt(0).toLowerCase() + docType.slice(1);
            for (const key in caseload.notes) {
                // Clear all arrays except the one matching the docType
                if (key.toLowerCase() !== docType.toLowerCase() && key != 'timeBlocks') {
                    caseload.notes[key] = [];
                }
            }
            caseload.notes[docType] = caseload.notes[docType].filter((note) => {
                // Need to match whole name without comma and spaces
                const patientFullName = note.docId.facilityAdmission.patient
                    ? note.docId.facilityAdmission.patient?.lastName +
                        ', ' +
                        note.docId.facilityAdmission.patient?.firstName
                    : 'N/A';
                return (
                    (this.therapistCaseloadFilters.docStatus != 'All'
                        ? note.docId.status.includes(this.therapistCaseloadFilters.docStatus)
                        : true) &&
                    (this.therapistCaseloadFilters.visitStatus != 'All'
                        ? note.visitStatus.toLocaleLowerCase() ==
                          this.therapistCaseloadFilters.visitStatus.toLocaleLowerCase()
                        : true) &&
                    (this.therapistCaseloadFilters.patientName
                        ?  this.flatString(patientFullName).includes(this.flatString(this.therapistCaseloadFilters.patientName))
                        : true)
                );
            });
        } else {
            for (const key in caseload.notes) {
                // Clear all arrays except the one matching the docType
                if (key != 'timeBlocks') {
                    caseload.notes[key] = caseload.notes[key].filter((note) => {
                        const patientFullName = note.docId.facilityAdmission.patient
                            ? note.docId.facilityAdmission.patient?.lastName +
                              ', ' +
                              note.docId.facilityAdmission.patient?.firstName
                            : 'N/A';
                        return (
                            (this.therapistCaseloadFilters.docStatus != 'All'
                                ? note.docId.status.includes(this.therapistCaseloadFilters.docStatus)
                                : true) &&
                            (this.therapistCaseloadFilters.visitStatus != 'All'
                                ? note.visitStatus.toLocaleLowerCase() ==
                                  this.therapistCaseloadFilters.visitStatus.toLocaleLowerCase()
                                : true) &&
                            (this.therapistCaseloadFilters.patientName
                                ?  this.flatString(patientFullName).includes(this.flatString(this.therapistCaseloadFilters.patientName))
                                : true)
                        );
                    });
                }
            }
        }
    }
    flatString(name: string) {
        return name?.replace(/[^a-zA-Z0-9]/g, '')?.toLowerCase();
    }

    ngOnDestroy(): void {
        if (window.onafterprint === this.afterPrint) {
            window.onafterprint = null;
        }
        this.subscriptions.unsubscribe();
        this.onDestroy.next();
    }
}
