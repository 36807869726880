import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActionsCellRendererComponent } from '@app/data-grid/cell-renderers';
import { GridActionsService } from '@app/data-grid/services/grid-actions.service';
import { uuidv4 } from '@app/helpers/utils/charts/goals.utils';
import { CardPayload, CustomControlType, InputConfiguration, TherapyDiscipline } from '@app/interfaces';
import { LibrariesService } from '@app/services';
import { ColDef } from 'ag-grid-community';

@Component({
    selector: 'app-precautions',
    templateUrl: './precautions.component.html',
    styleUrls: ['./precautions.component.scss'],
})
export class PrecautionsComponent implements OnInit {
    precautionCols: ColDef[] = [
        { headerName: 'No.', maxWidth: 70, minWidth: 70, valueGetter: 'node.rowIndex + 1' },
        {
            field: 'description',
            wrapText: true,
            autoHeight: true,
            width: 900,
            cellStyle: () => {
                return { wordBreak: 'normal' };
            },
        },
        {
            field: 'actions',
            type: 'rightAligned',
            resizable: true,
            cellRenderer: ActionsCellRendererComponent,
            cellRendererParams: { edit: true, delete: true, gridName: 'precautionCols' },
        },
    ];
    precautionsControls: InputConfiguration[] = [
        {
            type: 'suggestion-box' as CustomControlType,
            column: '12',
            key: 'description',
            placeholder: 'Description',
            backgroundClass: 'bg-body-background',
            class: 'w-100',
            isSuggestionBox: true,
            suggestionsList: [],
            onChange: (e) => console.log('Search on change', e),
        },
    ];
    therapyDiscipline: TherapyDiscipline;

    @Input() precautionsData = [];
    @Input() isReadonly: boolean;
    @Input() heading: string;
    @Input() isEditMode: boolean;
    @Output() update = new EventEmitter();
    @Input() viewMode = '';
    editData: any = {};

    constructor(
        private gridActionsService: GridActionsService,
        private librariesService: LibrariesService,
        private route: ActivatedRoute
    ) {
        this.route.queryParamMap.subscribe({
            next: (params) => {
                this.therapyDiscipline = params.get('discipline') as TherapyDiscipline;
                this.precautionsControls[0].suggestionsList =
                    this.librariesService.getPrecautions()[this.therapyDiscipline];
            },
        });
        // precautionCols
        this.gridActionsService.action.subscribe((data) => {
            if (data.gridName === 'precautionCols') {
                if (data.actionType === 'delete') {
                    this.removeItem(data.data);
                } else if (data.actionType === 'edit') {
                    this.editItem(data.data);
                }   
            }
        });
    }

    ngOnInit(): void {
        if (this.isReadonly) {
            this.precautionCols.find((col) => col.field === 'actions').hide = true;
        }
    }

    defaultAction: (payload: any) => void = (payload: CardPayload) => {
        payload.viewChangeEvent(payload.targetView);
    };

    updateList(description) {
        if (this.editData && Object.keys(this.editData).length !== 0) {
            this.precautionsData[this.precautionsData.findIndex(el => el.id === this.editData.id)].description = description;
        } else {
            const body = { description };
            if (this.isEditMode) body['id'] = uuidv4();
            this.precautionsData = [...this.precautionsData, body];
        }
        this.update.emit(this.precautionsData);
        this.editData = {};
    }

    removeItem(data: any) {
        this.precautionsData = this.precautionsData?.filter((item) => item.id !== data.id);
        this.update.emit(this.precautionsData);
    }

    editItem(data: any) {
        this.viewMode = 'ADD_MORE';
        this.editData = data;
    }

    updateViewMode(e) {
        this.viewMode = e;
    }

    dataForEditOut(e) {
        this.editData = e;
    }
}
