<div class="main-card custom-card card text-secondary body-medium">
    <div class="d-flex">
        <div class="header w-100">
            <p>{{ data.title }}</p>
        </div>
        <button
            class="cross-btn btn btn-primary d-flex flex-row align-items-center justify-content-center"
            (click)="onClose()"
        >
            <span class="material-icons-outlined text-white-100" style="vertical-align: middle"> close </span>
        </button>
    </div>
    <div class="d-flex align-items-center justify-content-center main-card-body">
        <div class="text-center text-container">
            <img src="assets/icons/goals-delete.svg" class="cross-svg" />
            <p class="body-medium-semibold">{{ data.message }}</p>
            <div class="d-flex justify-content-center mt-4 mb-1">
                <button class="innova-btn innova-btn-sm btn-secondary" (click)="onDone()">Yes, Sure</button>
                <button class="ml-1 innova-btn innova-btn-sm btn-danger" (click)="onClose()">Cancel</button>
            </div>
        </div>
    </div>
</div>
