import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { TabItem } from '@app/interfaces';

@Component({
    selector: 'app-steps-tab',
    templateUrl: './steps-tab.component.html',
    styleUrls: ['./steps-tab.component.scss'],
})
export class StepsTabComponent implements OnChanges {
    @Input() tabs: TabItem[];
    @Input() storeTab: string;
    selectedTab: TabItem;
    @Output() selectedTabEvent = new EventEmitter<TabItem>();

    ngOnChanges(): void {
        this.tabs.forEach((tab) => {
            if (tab.url === this.storeTab) {
                tab.highlight = true;
            } else {
                tab.highlight = false;
            }
        });
    }
    // Actions
    setSelectedTab(tab: TabItem) {
        if(!tab.unauthorized) this.selectedTabEvent.emit(tab);
    }
}
