// Author: T4professor

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-pill-cell-renderer',
  templateUrl: './pill-cell-renderer.component.html',
  styleUrls: ['./pill-cell-renderer.component.scss'],
  providers: [],
})
export class PillCellRendererComponent implements ICellRendererAngularComp {
  params: any;
  public displayedData: string[] = [];
  public hiddenDataLength = 0;
  data = [];
  showSeeMore = false;
  displayMore = true;
  count = 0;

  agInit(params): void {
    this.params = params;
    this.count = this.params?.count;
    this.data = params.value;
    this.displayedData = this.data?.length > 10 ? this.data?.slice(0, this.count) : this.data;
    this.hiddenDataLength = this.data?.length > 10 ? this.data?.length - this.displayedData?.length : 0;
    this.showSeeMore = !!this.hiddenDataLength;
  }


  showMoreOrLess() {
    if (this.displayMore) {
      this.displayedData = this.data;
    } else {
      this.displayedData = this.data?.slice(0, this.count)
    }
    this.displayMore = !this.displayMore
  }
  refresh(params: any) {
    this.params = params;
    return true;
  }
}