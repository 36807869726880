import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { ROUTER_UTILS } from '@app/helpers';
import { Observable } from 'rxjs';
import { AuthService } from '@app/services';
@Injectable({
    providedIn: 'root',
})
export class DashboardGuard implements CanActivate {
    account = this.AuthService.accountValue;
    dorDash = '/' + ROUTER_UTILS.config.base.dorDashboard;
    home = '/' + ROUTER_UTILS.config.base.home;

    therapistDash = '/' + ROUTER_UTILS.config.base.therapistDashboard;

    constructor(private AuthService: AuthService, private router: Router) {}
    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        //doing this here so that on whole app we can just use plain '/' as home address and then this component will re-route based on role.
        this.account = this.AuthService.accountValue;
        if (!this.AuthService.isManager && !this.AuthService.isTherapist) {
            return true;
        }
        this.router.navigate(['/']);
        return true;
    }
}
