import { Pipe, PipeTransform } from '@angular/core';
import { isSameDay } from 'date-fns';

@Pipe({
    name: 'isSameDay',
    pure: true,
})
export class IsSameDayPipe implements PipeTransform {
    transform(date1: Date, date2: Date): boolean {
        return isSameDay(new Date(date1), new Date(date2));
    }
}
