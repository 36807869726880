import { Component, EventEmitter, Input, Output, SimpleChanges, OnInit, OnChanges } from '@angular/core';
import { ROUTER_UTILS } from '@app/helpers';
import { AuthPolicies } from '@app/helpers/utils';
import { AuthService, ExcelService, FacilityManagementService, OrganizationManagementService } from '@app/services';
import * as ExcelJS from 'exceljs';
import { Router } from '@angular/router';

@Component({
    selector: 'app-facility-header',
    templateUrl: './facility-header.component.html',
    styleUrls: ['./facility-header.component.scss'],
})
export class FacilityHeaderComponent implements OnInit, OnChanges {
    workbook;
    newFacilityURL = ROUTER_UTILS.config.admin.facility.addFacility;
    facilityFilter = {
        facilityName: '',
        integrated: 'All',
        state: 'All',
        type: 'All',
    };
    states = [];
    facilityTypeOptions = [
        'Skilled nursing',
        'Skilled Home Health Care',
        'Hospice Care',
        'Continuum Care Retirement Center (CCRC)',
        'Hospital',
        'Outpatient - Independent Living (IL)',
        'Outpatient - Assisted Living (AL)',
        'Outpatient - Clinic',
        'Outpatient - Home Therapy',
        'Other',
    ];
    sortedDocsList = [];
    policies = AuthPolicies;
    permissions = {
        Add: false,
    };
    @Input() isFacilitySelected = false;
    @Input() currentTab = 'Active';
    @Input() selectedFacilities = [];
    @Output() facilityFilters = new EventEmitter<any>();
    @Output() eventEnded = new EventEmitter<string>();
    toFilter = false;
    @Input() filtersReset = false;
    constructor(
        private _excelService: ExcelService,
        private facilityManagementService: FacilityManagementService,
        private authService: AuthService,
        private router: Router,
        private organizationService: OrganizationManagementService
    ) {
        this.states = this.facilityManagementService.getUSAStates();
    }

    ngOnInit(): void {
        this.permissions.Add = this.authService.applyPolicy(this.policies.Facilities.Add);
    }

    changeFilters(event: any, filterName: string) {
        let filterValue = typeof event == 'string' ? event : event.target.value;
        if (filterName == 'facilityName') filterValue = filterValue.toLowerCase();
        this.facilityFilter[filterName] = filterValue;

        this.facilityFilters.emit(this.facilityFilter);

        if (
            this.facilityFilter.facilityName === '' &&
            this.facilityFilter.state === 'All' &&
            this.facilityFilter.type === 'All'
        ) {
            this.toFilter = false;
        } else {
            this.toFilter = true;
        }
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.filtersReset && changes.filtersReset.currentValue != changes.filtersReset.previousValue) {
            this.resetFilters();
        }
    }

    editTimecards() {
        const orgId = this.organizationService.selectedOrganization.id;
        const url = `/${ROUTER_UTILS.config.admin.organization.root}/${ROUTER_UTILS.config.admin.organization.editNpc}/${orgId}`;
        const state = { selectedFacilities: this.selectedFacilities , facilityStatus : this.currentTab.toLowerCase() };
        this.router.navigate([url], { state });
    }
    exportList(): void {
        const head = {
            Name: '',
            'Facility Type': '',
            State: '',
            'Go-Live': '',
            Integrated: '',
        };

        this.workbook = new ExcelJS.Workbook();
        const worksheet = this.workbook.addWorksheet(this.facilityManagementService._currentFacility.facilityName);
        // Add headers
        const headers = Object.keys(head);
        worksheet.addRow(headers);
        worksheet.getRow(1).eachCell((cell) => {
            cell.font = { bold: true };
        });
        this.selectedFacilities.map((doc) => {
            const exportData: any[] = [];
            exportData.push({
                Name: doc.facilityName,
                'Facility Type': doc.facilityType,
                State: doc.mailingAddress.state,
                'Go-Live': doc.goLiveEnabled ? 'On' : 'Off',
                integrated: 'Matrix Care',
            });
            // Add data
            exportData.forEach((item) => {
                const row: any = [];
                headers.forEach((header) => {
                    row.push(item[header]);
                });
                worksheet.addRow(row);
            });
            worksheet.columns.forEach(function (column) {
                let maxLength = 0;
                column['eachCell']({ includeEmpty: true }, function (cell) {
                    const columnLength = cell.value ? cell.value.toString().length : 10;
                    if (columnLength > maxLength) {
                        maxLength = columnLength;
                    }
                });
                column.width = maxLength < 10 ? 10 : maxLength;
            });
        });

        this._excelService.downloadExcelSheet(
            this.workbook,
            this.facilityManagementService._currentFacility.facilityName
        );
        this.eventEnded.emit('export');
    }
    printList() {
        setTimeout(() => {
            window.print();
            this.eventEnded.emit('print');
        }, 0);
    }
    resetFilters() {
        // Reset each filter to its default value, e.g., an empty string or null.
        this.facilityFilter = {
            facilityName: '',
            integrated: 'All',
            state: 'All',
            type: 'All',
        };

        // Emit the updated (reset) filters
        this.facilityFilters.emit(this.facilityFilter);

        // Set the toFilter flag to false, indicating that filtering is not active
        this.toFilter = false;
    }
    ngOnDestroy(): void {
        this.resetFilters();
    }
}