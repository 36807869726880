/* eslint-disable spellcheck/spell-checker */
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Goal, TherapyAdmission } from '@app/interfaces';
import { GOAL_CONSTANTS } from '@app/interfaces/documentation';
import { GoalDiscontinuationModalComponent } from '@app/modules/documentation/goals/modals/goal-discontinuation-modal/goal-discontinuation-modal.component';
import { ModalService } from './modal.service';
import { PatientAssessmentService } from './patient-assessment.service';

export interface GoalClofEditModal {
    id: string;
    name: string;
    plof: {
        levelOfFunction: string;
        assistiveDevice: string;
        isEditable: boolean;
    };
    clof: {
        levelOfFunction: string;
        assistiveDevice: string;
        isEditable: boolean;
    };
    target: {
        levelOfFunction: string;
        assistiveDevice: string;
        date: string;
        isEditable: boolean;
    };
    goalPurpose: string;
    status: { description: string; reason: string };
    stgs: any[];
    scale: { assessmentId: string; assessmentType: string; scaleDetails: any; scaleType: string };
    documentType: string;
}
@Injectable({
    providedIn: 'root',
})
export class GoalService {
    constructor(
        private patientAssessmentService: PatientAssessmentService,
        private modalService: ModalService,
        private datePipe: DatePipe
    ) {}

    mapToClofEditModel(goal: Goal) {
        return {
            id: goal.id ? goal.id : goal['_id'],
            name: goal.goal,
            plof: {
                levelOfFunction: goal.plof.levelOfFunction,
                assistiveDevice: goal.plof.assistiveDevice,
                isEditable: false,
            },
            clof: {
                levelOfFunction: goal.clof.levelOfFunction,
                assistiveDevice: goal.clof.assistiveDevice,
                isEditable: true,
            },
            target: {
                levelOfFunction: goal.target,
                assistiveDevice: goal.assistiveDevice,
                date: goal.targetDate,
                isEditable: false,
            },
            updatedTarget: {
                levelOfFunction: goal.target,
                assistiveDevice: goal.assistiveDevice,
                date: goal.updatedTargetDate,
                isEditable: true,
            },
            goalPurpose: goal.goalPurpose,
            status: goal.status,
            stgs: goal.stgs,
            scale: goal.scale,
            documentType: '',
            maxTargetDate: goal.maxTargetDate,
        };
    }
    mapToGoal(item): Goal {
        return {
            id: item.id,
            goal: item.name,
            plof: {
                levelOfFunction: item.plof.levelOfFunction,
                assistiveDevice: item.plof.assistiveDevice,
            },
            clof: {
                levelOfFunction: item.clof.levelOfFunction,
                assistiveDevice: item.clof.assistiveDevice,
            },
            target: item.target.levelOfFunction,
            assistiveDevice: item.target.assistiveDevice,
            targetDate: item.target.date,
            updatedTargetDate: item.updatedTarget.date,
            goalPurpose: item.goalPurpose,
            status: item.status,
            stgs: item.stgs,
            scale: item.scale,
            maxTargetDate: item.maxTargetDate,
        };
    }

    // Updates local goals data
    updateGoal(goalsData: Goal[], goal: Goal, ltgId) {
        if (ltgId) {
            const foundLtg = goalsData.find((x) => x.id === ltgId);
            if (foundLtg) {
                const foundStg = foundLtg.stgs.findIndex((x) => x.id === goal.id);
                if (foundStg !== -1) foundLtg.stgs[foundStg] = goal;
            }
        } else {
            const found = goalsData.findIndex((x) => x.id === goal.id);
            if (found !== -1) {
                goalsData[found] = goal;
            }
        }
    }

    discontinueGoal(event: GoalClofEditModal, ltgId, goalsData: Goal[], currentAdmission: TherapyAdmission) {
        return new Promise((resolve, reject) => {
            try {
                if (event.stgs.length > 0 && event.stgs.some((stg) => stg.status.description === GOAL_CONSTANTS.ON_GOING)) {
                    this.modalService
                        .open(GoalDiscontinuationModalComponent, {
                            data: {
                                heading: 'Discontinue Short Term Goals',
                                isStg: true,
                                discontinueMessage:
                                    'Goal status has been updated and will reflect the current date as the date of the update.',
                                message:
                                    'There are some on going short term goals. Do you want to discontinue them as well?',
                            },
                        })
                        .afterClosed()
                        .subscribe((result) => {
                            let parentGoalIndex;
                            if (!result.discontinueStgs) {
                                parentGoalIndex = goalsData.findIndex((g) => g.id === event.id);
                                event.stgs.forEach((stg, index) => {
                                    if (
                                        stg.status.description === GOAL_CONSTANTS.ON_GOING ||
                                        stg.status.description === GOAL_CONSTANTS.ACHIEVED
                                    ) {
                                        goalsData.push(this.patientAssessmentService.mapStgGoalItemToAGoal(stg, stg.goal));
                                        if (parentGoalIndex !== undefined) goalsData[parentGoalIndex].stgs.splice(index, 1);
                                    } else {
                                        this.unmarkBindedAssessmentItem(stg, currentAdmission);
                                    }
                                });
                            } else if (result.discontinueStgs) {
                                parentGoalIndex = goalsData.findIndex((g) => g.id === event.id);
                                event.stgs.forEach((stg, index) => {
                                    if (stg.status.description === GOAL_CONSTANTS.ON_GOING) {
                                        stg.status.description = GOAL_CONSTANTS.DISCONTINUED;
                                        stg.status.reason = result.reasonForDiscontinuing;
                                        stg.status.dateOfCompletion = new Date().toDateString();
                                        this.unmarkBindedAssessmentItem(stg, currentAdmission);
                                        if (parentGoalIndex !== undefined) {
                                            const found = goalsData[parentGoalIndex].stgs.findIndex(
                                                (pgi) => pgi.id === stg.id
                                            );
                                            goalsData[parentGoalIndex].stgs[found] = stg;
                                        }
                                    } else if (stg.status.description === GOAL_CONSTANTS.ACHIEVED) {
                                        goalsData.push(this.patientAssessmentService.mapStgGoalItemToAGoal(stg, stg.goal));
                                        if (parentGoalIndex !== undefined) goalsData[parentGoalIndex].stgs.splice(index, 1);
                                    }
                                });
                            }
                            this.unmarkBindedAssessmentItem(goalsData[parentGoalIndex], currentAdmission);
                            goalsData[parentGoalIndex].status.description = GOAL_CONSTANTS.DISCONTINUED;
                            goalsData[parentGoalIndex].status.reason = event.status.reason;
                            goalsData[parentGoalIndex].status.dateOfCompletion = new Date().toDateString();
                            goalsData[parentGoalIndex].scale.assessmentId = '';
                            !result.discontinueStgs ? (goalsData[parentGoalIndex].stgs = []) : false;
                            resolve(true);
                        });
                } else {
                    this.modalService
                        .open(GoalDiscontinuationModalComponent, {
                            data: {
                                heading: ltgId ? 'Discontinue Short Term Goals' : 'Discontinue Long Term Goal',
                                discontinueMessage:
                                    'Goal status has been updated and will reflect the current date as the date of the update.',
                            },
                        })
                        .afterClosed()
                        .subscribe(() => {
                            this.updateGoal(goalsData, this.mapToGoal(event), ltgId);
                            this.unmarkBindedAssessmentItem(event as unknown as Goal, currentAdmission);
                            resolve(true);
                        });
                }
            } catch(err) {
                reject(err)
            }
        });
    }

    unmarkBindedAssessmentItem(goal: Goal, currentAdmission: TherapyAdmission): void {
        // Unmark assessment item as well if goal discontinued
        const assessmentItemFound = this.patientAssessmentService
            .getAssessmentPath(currentAdmission, goal.scale.assessmentType)
            ?.find((x) => x._id === goal.scale.assessmentId);
        if (assessmentItemFound) assessmentItemFound.isAGoal = false;
        // No need of assessment id after discontinue of a goal
        goal.scale.assessmentId = '';
    }

    // Toggles goal status (ON) in respective list (Impairments/Deficits/Tests)
    toggleGoalStatus(event, currentAdmission: TherapyAdmission): void {
        const found = this.patientAssessmentService
            .getAssessmentPath(currentAdmission, event.goal.scale?.assessmentType)
            ?.findIndex((x) => x._id === event.goal.scale.assessmentId);
        if (found !== -1 && found !== undefined) {
            this.patientAssessmentService.getAssessmentPath(currentAdmission, event.goal.scale?.assessmentType)[
                found
            ].isAGoal = true;
            this.patientAssessmentService.getAssessmentPath(currentAdmission, event.goal.scale?.assessmentType)[
                found
            ].scale.assessmentId = event.goal.scale.assessmentId;
        }
    }

    buildGoal(goals: any[]) {
        const evalGoal = [];
        for (let index = 0; index < goals?.length; index++) {
            const goal = goals[index];
            const newGoal = {
                title: goal.goal,
                plof: goal.plof.levelOfFunction,
                clof: goal.clof.levelOfFunction,
                target: goal.target,
                status: goal.status.description,
                goalPurpose: goal.goalPurpose,
                date: this.transformDate(goal.targetDate),
                updatedDate: this.transformDate(goal.updatedTargetDate),
                childGoal: [],
            };
            if (goal.stgs && Array.isArray(goal.stgs) && goal.stgs.length) {
                newGoal.childGoal = this.buildGoal(goal.stgs);
            }
            evalGoal.push(newGoal);
        }
        return evalGoal;
    }

    buildGoalsWithDiffView(goals: any[]) {
        const evalGoal = [];
        for (let index = 0; index < goals?.length; index++) {
            const goal = goals[index];
            const newGoal = {
                title: { currentValue: goal.goal.currentValue, previousValue: goal.goal.previousValue },
                plof: {
                    currentValue: goal.plof.currentValue?.levelOfFunction,
                    previousValue: goal.plof.previousValue?.levelOfFunction,
                },
                clof: {
                    currentValue: goal.clof.currentValue?.levelOfFunction,
                    previousValue: goal.clof.previousValue?.levelOfFunction,
                },
                target: { currentValue: goal.target.currentValue, previousValue: goal.target.previousValue },
                date: {
                    currentValue: this.transformDate(goal.targetDate.currentValue),
                    previousValue:
                        this.transformDate(goal.targetDate.previousValue) == ''
                            ? undefined
                            : this.transformDate(goal.targetDate.previousValue),
                },
                updatedDate: {
                    currentValue: goal.updatedTargetDate
                        ? this.transformDate(goal.updatedTargetDate.currentValue)
                        : null,
                    previousValue:
                        this.transformDate(goal.updatedTargetDate?.previousValue) == ''
                            ? undefined
                            : this.transformDate(goal.updatedTargetDate.previousValue),
                },
                status: {
                    currentValue: goal.status.currentValue?.description,
                    previousValue: goal.status.previousValue?.description,
                },
                goalPurpose: {
                    currentValue: goal.goalPurpose.currentValue,
                    previousValue: goal.goalPurpose.previousValue,
                },
                childGoal: [],
            };
            if (goal.stgs.currentValue && Array.isArray(goal.stgs.currentValue) && goal.stgs.currentValue.length) {
                newGoal.childGoal = this.buildGoalsWithDiffView(goal.stgs.currentValue);
            }
            evalGoal.push(newGoal);
        }
        return evalGoal;
    }

    private transformDate = (date) => {
        if (!date) {
            return '';
        }
        return this.datePipe.transform(new Date(date), 'MM/dd/yyyy');
    };
}
