<app-base-modal-card [heading]="heading" wrapperClass="modal-xl" (close)="close()">
    <div body class="p-3 rounded">
        <div *ngFor="let record of matrix">
            <h6 class="text-secondary h6-semibold">{{ record.day }}</h6>
            <div style="height: 200px" class="custom-card overflow-hidden mb-4">
                <app-data-grid [columnDefs]="record.cols" [rowData]="record.rowData"></app-data-grid>
            </div>
        </div>
    </div>
</app-base-modal-card>
