<ng-container *ngIf="(loadingObj | async)?.isLoading; else loaded" [ngTemplateOutlet]="skeleton"></ng-container>
<ng-template #loaded>
    <div class="mb-2">
        <organization-header></organization-header>
    </div>
<div class="custom-card" style="height: calc(100vh - 179px)" *ngIf="!(loadingObj | async)?.isLoading">
    <div class="header d-flex flex-row justify-content-between">
        <p class="text-secondary">Organizations</p>
        <app-button
            tooltipText="Add New Organization"
            tooltipPlacement="left"
            [circle]="true"
            size="sm"
            [routerLink]="addOrganizationURL"
            type="secondary"
            text=""
            icon="add"
        ></app-button>
    </div>
    <div class="body" style="height: calc(100% - 63px)">
        <app-organization-list></app-organization-list>
    </div>
</div>
</ng-template>
<ng-template #skeleton>
    <div>
        <br />
        <ng-container *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]">
            <ngx-skeleton-loader appearance="line" [theme]="SKELETON_CSS.CELL"></ngx-skeleton-loader>
        </ng-container>
        <hr class="table-footer" />
        <div class="pagination-loader">
            <ngx-skeleton-loader [theme]="{ width: '10em', 'margin-right': '0' }"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{ width: '10em', 'margin-left': '5em' }"></ngx-skeleton-loader>
        </div>
    </div>
</ng-template>