import { Injectable } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AppService {
    private targetRoute$: BehaviorSubject<string> = new BehaviorSubject(null);
    public onScreenResize$: BehaviorSubject<{ width: number; height: number }> = new BehaviorSubject({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public previousUrl$: Observable<string> = this.previousUrl.asObservable();
    public appLoader$ = new Subject<boolean>();

    constructor(eventManager: EventManager, private router: Router) {
        eventManager.addGlobalEventListener('window', 'resize', (event) =>
            this.onScreenResize$.next({
                width: event.target.innerWidth,
                height: event.target.innerHeight,
            })
        );
    }
    get targetRoute(): any {
        return this.targetRoute$.asObservable();
    }
    set targetRoute(value: string) {
        this.targetRoute$.next(value);
    }
    setPreviousUrl(value: string) {
        this.previousUrl.next(value);
    }
    getWindowSize() {
        this.onScreenResize$.next({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }
}
