import { Component, Input, OnInit } from '@angular/core';
import { DOC_KEY_VALUE, REPORT_GRIDS } from '@app/helpers/constants/documentation-report';
import { GridLayoutReport, ReportGridTypes } from '@app/interfaces';
import { DocumentationReportService } from '@app/services';
import { isArray } from 'lodash';

@Component({
    selector: 'app-grid-layout',
    templateUrl: './grid-layout.component.html',
    styleUrls: ['./grid-layout.component.scss'],
})
export class GridLayoutComponent implements OnInit {
    @Input() type: string;
    @Input() key: string;
    @Input() gridType: ReportGridTypes;

    title: string;
    gridData: GridLayoutReport | GridLayoutReport[];
    nonGridArray: number[] = [];
    toRender: boolean;

    constructor(private documentationService: DocumentationReportService) {
        this.gridType = REPORT_GRIDS.HEADER;
        this.toRender = false;
    }

    ngOnInit(): void {
        this.title = DOC_KEY_VALUE[this.key];
        this.gridData = this.documentationService[this.type][this.key];
        this.toRender = !!(
            (this.gridData && !isArray(this.gridData) && this.gridData.data && this.gridData.data.length) ||
            (isArray(this.gridData) && this.gridData.length)
        );
        if (!isArray(this.gridData) && this.gridType === REPORT_GRIDS.NON_HEADER) {
            this.nonGridArray = Array.from(Array(Math.ceil(this.gridData.data.length / 2)).keys());
        }
    }

    ceilValue(value: number): number {
        return Math.ceil(value);
    }

    widthSetter(width: string, key: string): string {
        const widthRatio: number = key.toLowerCase() === 'description' ? 0.75 : 0.25;
        const widthInNumber: number = parseInt(width.slice(0, -1));
        const newWidth = `${Math.round(widthInNumber * widthRatio) - 0.5}%`;
        return newWidth;
    }

    nonHeaderDataByRow(row) {
        if (row === 0) {
            return [1, 0, 2, 1];
        } else {
            return [row + row + 1, row + row, row + row + 2, row + row + 1];
        }
    }
}
