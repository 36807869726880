import { Directive, EventEmitter, Output, HostListener, HostBinding } from '@angular/core';

@Directive({
    selector: '[appDragDropFileUpload]',
})
export class DragDropFileUploadDirective {
    @Output() fileDropped = new EventEmitter<any>();
    @Output() isDragged$ = new EventEmitter<boolean>();

    @HostBinding('style.background-color') private background = '#ffffff';

    // Dragover Event
    @HostListener('dragover', ['$event']) dragOver(event) {
        event.preventDefault();
        event.stopPropagation();
        this.background = '#e2eefd';
        this.isDragged$.emit(true);
    }

    // Dragleave Event
    @HostListener('dragleave', ['$event']) public dragLeave(event) {
        event.preventDefault();
        event.stopPropagation();
        this.background = '#ffffff';
        this.isDragged$.emit(false);
    }

    // Drop Event
    @HostListener('drop', ['$event']) public drop(event) {
        event.preventDefault();
        event.stopPropagation();
        this.background = '#ffffff';
        const files = event.dataTransfer.files;
        if (files.length > 0) {
            this.fileDropped.emit(files);
        }
        this.isDragged$.emit(false);
    }
}
