<div class="report-header" *ngIf="isFirst; else nthPage">
    <div class="logo-section" *ngIf="!toExport; else noStylesLogo">
        <img class="logo" src="../../../../../../assets/logo/logo.svg" />
    </div>
    <div class="info-section">
        <div class="info" style="width: 40%">
            <span class="key">Patient Name:</span>
            <span>{{ header.patientName }}</span>
        </div>
        <div class="info" style="width: 35%">
            <span class="key">MRN:</span>
            <span class="value">{{ mrn }}</span>
        </div>
        <div class="info" style="width: 25%">
            <span class="key">Note:</span>
            <span>{{ header.note }}</span>
        </div>
        <div class="info" style="width: 40%">
            <span class="key">Date of Service:</span>
            <span>{{ header.dateOfService }}</span>
        </div>
        <div *ngIf="isDischarged" class="info" style="width: 35%">
            <span class="key">EOC:</span>
            <span>{{ header.endCareDate }}</span>
        </div>
        <div *ngIf="header.discipline" class="info" style="width: 35%">
            <span class="key">Discipline:</span>
            <span>{{ header.discipline }}</span>
        </div>
        <div *ngIf="header.dob" class="info" style="width: 25%">
            <span class="key">DOB:</span>
            <span>{{ header.dob | dateFormatter }}</span>
        </div>
        <div class="info" style="width: 40%">
            <span class="key">Therapist:</span>
            <span>{{ header.therapist }}</span>
        </div>
        <div *ngIf="header.totalVisits" class="info" style="width: 35%">
            <span class="key">No. of Visits:</span>
            <span>{{ header.totalVisits }}</span>
        </div>
        <div *ngIf="header.range" class="info" style="width: 50%">
            <span class="key">Progress Notes Covers:</span>
            <span>{{ header.range }}</span>
        </div>
        <div *ngIf="header.attendingPhysician" class="info" style="width: 35%">
            <span class="key">Physician:</span>
            <span>{{ header.attendingPhysician }}</span>
        </div>
    </div>
</div>

<ng-template #nthPage>
    <div class="nth-header">
        <div class="info">
            <span class="key">Patient Name:</span>
            <span class="value">{{ header.patientName }}</span>
        </div>
        <div class="info">
            <span class="key">MRN:</span>
            <span class="value">{{ mrn }}</span>
        </div>
        <div *ngIf="header.npi" class="info">
            <span class="key">NPI:</span>
            <span class="value">{{ header.npi }}</span>
        </div>
        <div class="info">
            <span class="key">Therapist:</span>
            <span class="value">{{ header.therapist }}</span>
        </div>
        <div class="info">
            <span *ngIf="header.discipline" class="key">Discipline:</span>
            <span class="value">{{ header.discipline }}</span>
        </div>
    </div>
</ng-template>

<ng-template #noStylesLogo>
    <img width="175px" src="../../../../../../assets/logo/logo.svg" />
</ng-template>
