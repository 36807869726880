import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-view-mode-paragraph',
    templateUrl: './view-mode-paragraph.component.html',
    styleUrls: ['./view-mode-paragraph.component.scss'],
})
export class ViewModeParagraphComponent {
    @Input() paragraphTitle: string;
    @Input() paragraph: string;
    @Input() innerHtml;
    @Input() pinToRightText: string;
}
