import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MinutesToHoursMinutesPipe } from '@app/pipes';
import { PrintService } from '@app/services';
import { ColDef } from 'ag-grid-community';
import { Subject, Subscription } from 'rxjs';

@Component({
    selector: 'app-whiteboard-print',
    templateUrl: './whiteboard-print.component.html',
    styleUrls: ['./whiteboard-print.component.scss'],
    providers: [MinutesToHoursMinutesPipe],
})
export class WhiteboardPrintComponent implements OnDestroy {
    private readonly onDestroy = new Subject<void>();

    therapistIds: string[];
    columnDefs: ColDef[] = [];
    rowData = [];

    therapistDetails: any[] = [];
    scheduleDate = '';
    patients: any = {};
    subscriptions: Subscription = new Subscription();

    constructor(router: Router, route: ActivatedRoute, private printService: PrintService) {
        const routerStateData = router.getCurrentNavigation()?.extras.state.patients;

        if (routerStateData) {
            this.patients = routerStateData;
        }
    }

    afterPrint(): void {
        window.history.back();
    }

    ngOnDestroy(): void {
        // this.subscriptions.unsubscribe();
        this.onDestroy.next();
    }
    ngAfterViewInit(): void {
        // added to fix some section ag grids get shrink
        // const delay = Math.ceil(this.docs.length / 8) * 500;

        window.onafterprint = this.afterPrint;
        setTimeout(() => {
            print();
        }, 200);
    }
}
