import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateToDay',
})
export class DateToDayPipe implements PipeTransform {
    transform(date: Date): unknown {
        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const dayIndex = new Date(date).getDay(); // Get the day index from the date
        const day = daysOfWeek[dayIndex]; // Get the day name from the array
        return day;
    }
}
