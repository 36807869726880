<app-base-modal-card [showFooter]="false" [heading]="heading" [headingClasses]="'headline5-5'"
    [crossIconClasses]="'primary-cross-icon'" [wrapperClass]="iconSize ? 'modal-time border-rounded':'modal-time'"
    (close)="closeAction()">
    <div body class="content-cont">
        <div class="separator-top-2"></div>
        <div class="icon-cont d-flex justify-content-center" *ngIf="iconName === iconTitle.delete">
            <img src="/assets/icons/blue-warning-icon.svg" alt="warning-icon" width="55.51" height="47.94">
        </div>
        <div class="icon-cont d-flex justify-content-center" *ngIf="iconName === iconTitle.warning">
            <img src="/assets/icons/warning-icon.svg" alt="warning-icon" width="{{iconSize ? iconSize :'55.51'}}"
                height="{{iconSize ? '89' :'47.94'}}">
        </div>
        <div class="icon-cont d-flex justify-content-center" *ngIf="iconName === iconTitle.circleCross">
            <img src="assets/icons/goals-delete.svg" alt="cross-delete-icon" class="cross-svg" width="55.51"
                height="47.94" />
        </div>
        <div class="icon-cont d-flex justify-content-center" *ngIf="iconName === iconTitle.check">
            <img src="assets/icons/checked-success.svg" alt="cross-delete-icon" class="cross-svg" width="55.51"
                height="47.94" />
        </div>
        <div class="separator-top-1-6 mt-2"></div>
        <div class="body-message-cont headline5 d-flex justify-content-center align-items-center">
            <p>
                {{ message }}
            </p>
        </div>
        <div class="separator-top-2"></div>
        <div class="footer d-flex justify-content-center">
         
            <button class="standard-btn ml-5 body-regular-14" [ngClass]="primaryButtonClass"
                (click)="closeAction(true)">{{primaryButtonText}}</button>
                <button class="innova-btn standard-btn body-regular-light-bold-14 no-padding ml-3"
                [ngClass]="secondaryButtonClass" (click)="closeAction()">{{secondaryButtonText}}</button>
        </div>
        <div class="mt-3"></div>
    </div>
</app-base-modal-card>