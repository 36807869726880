<app-base-modal-card class="modal-container" [heading]="heading" (close)="onClick(false)">
    <div body class="body-container">
        <img src="assets/icons/caution.svg" height="50" width="400" />
        <p>{{message}}</p>
    </div>
    <div
        footer
        class="w-100 d-flex flex-row align-items-center justify-content-end"
    >
        <button class="btn btn-outline-secondary rounded-pill d-flex flex-row align-items-enter" (click)="onClick(false)">Cancel</button>
        <button style="margin-left: 1vw;" class="btn btn-secondary rounded-pill d-flex flex-row align-items-enter" (click)="onClick(true)">Continue</button>
    </div>
</app-base-modal-card>