import {
    FacilityAdmission,
    TherapyAdmission,
} from "@app/interfaces";
import { createFeatureSelector, createSelector } from '@ngrx/store';

const therapyAdmissions = createFeatureSelector<TherapyAdmission[]>('therapyAdmission');
// * not use anywhere right now, but can be modify in future to create selector to fetch multiple store values.
export const fetchTherapyAdmissionsWithSelectedOne = createSelector(
    therapyAdmissions,
    (therapyAdmissions) => {
        return {
            therapyAdmissions
        };
    }
);
const pastTherapyAdmissions = createFeatureSelector<FacilityAdmission[]>('pastFacilityAdmissions');
export const fetchTherapyAdmissionANDPastFacilityAdmissions = createSelector(
    therapyAdmissions,
    pastTherapyAdmissions,
    (therapyCases, admissions) => {
        return {
            therapyAdmissions: therapyCases,
            pastFacilityAdmissions: admissions
        };
    }
);