import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataGridComponent } from './data-grid.component';
import { AgGridModule } from 'ag-grid-angular';
import { CheckIconCellRendererComponent } from './cell-renderers/check-icon-cell-renderer/check-icon-cell-renderer.component';
import { RadioButtonCellRendererComponent } from './cell-renderers/radio-button-cell-renderer/radio-button-cell-renderer.component';
import { DateCellRendererComponent } from './cell-renderers/date-cell-renderer/date-cell-renderer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActionsCellRendererComponent } from './cell-renderers/actions-cell-renderer/actions-cell-renderer.component';
import { SwitchCellRendererComponent } from './cell-renderers/switch-cell-renderer/switch-cell-renderer.component';
import { ImpairmentsCellRendererComponent } from './cell-renderers/impairments-cell-renderer/impairments-cell-renderer.component';
import { EmptyCellRendererComponent } from './cell-renderers/empty-cell-renderer/empty-cell-renderer.component';
import { DropdownCellRendererComponent } from './cell-renderers/dropdown-cell-renderer/dropdown-cell-renderer.component';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ChipsCellRendererComponent } from './cell-renderers/chips-cell-renderer/chips-cell-renderer.component';
import { CptCellRendererComponent } from './cell-renderers/cpt-cell-renderer/cpt-cell-renderer.component';
import {
    InputCellRenderer,
    SelectCellRendererComponent,
    PermissionCheckBoxCellRendererComponent,
} from './cell-renderers';
import { PillCellRendererComponent } from './cell-renderers';
import { SkillsCellRendererComponent } from './cell-renderers/skills-cell-renderer/skills-cell-renderer.component';
import { CustomCellEditorComponent } from './cell-renderers/custom-cell-editor/custom-cell-editor.component';
import { CustomCellRendererComponent } from './cell-renderers/custom-cell-renderer/custom-cell-renderer.component';
import { DiffCellRendererComponent } from './cell-renderers/diff-cell-renderer/diff-cell-renderer.component';
import { DropdownPillComponent } from './cell-renderers/dropdown-pill/dropdown-pill-cell-renderer.component';
import { ColoredCellRendererComponent } from './cell-renderers/colored-cell-renderer/colored-cell-renderer.component';
import { SignaturesCellRendererComponent } from './cell-renderers/signatures-cell-renderer/signatures-cell-renderer.component';

@NgModule({
    declarations: [
        DataGridComponent,
        CheckIconCellRendererComponent,
        RadioButtonCellRendererComponent,
        DateCellRendererComponent,
        ActionsCellRendererComponent,
        SwitchCellRendererComponent,
        ImpairmentsCellRendererComponent,
        EmptyCellRendererComponent,
        DropdownCellRendererComponent,
        ChipsCellRendererComponent,
        CptCellRendererComponent,
        SelectCellRendererComponent,
        PillCellRendererComponent,
        SkillsCellRendererComponent,
        CustomCellEditorComponent,
        CustomCellRendererComponent,
        DiffCellRendererComponent,
        DropdownPillComponent,
        ColoredCellRendererComponent,
        SignaturesCellRendererComponent,
        InputCellRenderer,
        PermissionCheckBoxCellRendererComponent,
    ],
    imports: [
        CommonModule,
        NgbTooltipModule,
        FormsModule,
        ReactiveFormsModule,
        AgGridModule.withComponents([]),
        NgbDropdownModule,
    ],
    exports: [DataGridComponent],
})
export class DataGridModule {}
