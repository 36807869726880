<div>
    <div class="pb-0 d-flex flex-row align-items-center justify-content-between">
        <p>{{heading}}</p>
        <div *ngIf="showActionItems" class="d-flex flex-row align-items-center justify-content-between">
            <!-- <button class="btn btn-primary mr-2" (click)="collapsed = !collapsed">
                <span *ngIf="collapsed === true" class="d-flex flex-row align-items-center justify-content-between"
                    >Show Progress<span class="material-symbols-outlined solid"> expand_more </span></span
                >
                <span *ngIf="collapsed === false" class="d-flex flex-row align-items-center justify-content-between"
                    >Hide Progress<span class="material-symbols-outlined solid"> expand_less </span></span
                >
            </button> -->
            <!-- <button *ngIf="hasAddButton" class="btn btn-md btn-secondary btn-circle mr-2" (click)="add.emit()">
                <span class="material-symbols-outlined solid"> monitoring </span>
            </button> -->
            <button *ngIf="hasAddButton" class="btn btn-md btn-secondary btn-circle" (click)="add.emit()">
                <span class="material-symbols-outlined solid"> add </span>
            </button>
        </div>
    </div>
    <!-- <hr class="w-100" />
    <div class="mt-3"></div> -->
    <!-- chart -->
    <!-- <div class="p-3" #collapse="ngbCollapse" [(ngbCollapse)]="collapsed">
        <ng-content select="[chart]"></ng-content>
    </div> -->
</div>
