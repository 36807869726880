import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { GridRowAction } from '../interfaces/grid-row-action';

@Injectable({
    providedIn: 'root',
})
export class GridActionsService {
    action: Subject<GridRowAction> = new Subject<GridRowAction>();
    print: Subject<boolean> = new Subject<boolean>();

    setSelectedAction(actionType: string, data, rowIndex: number, gridName: string, headerName?: string) {
        const action = headerName
            ? {
                  actionType,
                  data,
                  rowIndex,
                  gridName,
                  headerName,
              }
            : {
                  actionType,
                  data,
                  rowIndex,
                  gridName,
              };
        this.action.next(action);
    }

    resetSelectedAction() {
        this.action.next({
            actionType: undefined,
            data: undefined,
            rowIndex: undefined,
            gridName: undefined,
            headerName: undefined,
        });
    }
}
