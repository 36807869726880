<app-base-modal-card [heading]="heading" (close)="onClose()">
    <div body>
        <div [formGroup]="standardizedTestForm" class="card text-secondary">
            <div>
                <app-input
                    backgroundClass="bg-body-background"
                    placeholder="Enter Test value"
                    type="number"
                    formControlName="testScore"
                    label="Enter Test value"
                    [allowDecimal]="true"
                >
                </app-input>
                <app-text-box label="Notes/Comments" formControlName="testNote"></app-text-box>
            </div>
        </div>
    </div>
    <div footer class="text-right">
        <button
            class="ml-2 innova-btn btn-md btn-secondary"
            (click)="onSave()"
            [disabled]="!standardizedTestForm.valid"
        >
            <span class="material-icons-outlined text-white-100" style="vertical-align: text-top"> </span>
            {{ isCompletedItem ? 'Update' : 'Save' }}
        </button>
    </div>
</app-base-modal-card>
