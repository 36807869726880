import { DatePipe, TitleCasePipe } from '@angular/common';
import { Component, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MODES } from '@app/helpers/constants';
import { DIALOG_DATA } from '@app/helpers/dialog-tokens';
import { FacilityAdmission, Patient, PayorInformation, TherapyAdmission } from '@app/interfaces';
import { DialogRef, ExcelService, FacilityManagementService, PDPMService, ToasterService } from '@app/services';
import { PatientState } from '@app/store/store.interface';
import { Store, select } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import * as ExcelJS from 'exceljs';
interface TableItem {
    [key: string]: string | number;
}

interface MinutesData {
    therapyMinutesData: TableItem[];
    grandTotal: any[];
}

@Component({
    selector: 'app-obra-report',
    templateUrl: './obra-report.component.html',
    styleUrls: ['./obra-report.component.scss'],
    providers: [TitleCasePipe],
})
export class OBRAReportComponent implements OnDestroy {
    heading;
    selectedDate;
    disciplineIndex: {
        [discipline: string]: {
            SOC: string;
            EOC: string;
        };
    } = {
        PT: {
            SOC: '',
            EOC: '',
        },
        OT: {
            SOC: '',
            EOC: '',
        },
        ST: {
            SOC: '',
            EOC: '',
        },
    };
    private _patientId: string;
    private _facilityId: string;
    private _subscriptions: Subscription[] = [];
    private _headerInfo: {
        firstName?: string;
        lastName?: string;
        MRN?: string;
        payor?: string;
        facilityDischarge?: string;
        admitDate?: string;
    } = {};
    columnFont = { size: 12, bold: true };
    columnFill: ExcelJS.FillPattern = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' },
    };
    grandTotalFill: ExcelJS.FillPattern = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'c9daf8' },
        bgColor: { argb: 'c9daf8' },
    };
    therapyAdmissions: TherapyAdmission[];
    today;
    private _facilityAdmission: FacilityAdmission;

    constructor(
        @Inject(DIALOG_DATA) public data,
        private store: Store<{
            patient: PatientState;
            therapyAdmission: TherapyAdmission[];
            facilityAdmission: FacilityAdmission;
            payorInformation: PayorInformation;
        }>,
        private dialogRef: DialogRef,
        private datePipe: DatePipe,
        private _PDPMService: PDPMService,
        private _route: ActivatedRoute,
        private _toasterService: ToasterService,
        private _excelService: ExcelService,
        private titleCasePipe: TitleCasePipe,
        private facilityManagementService: FacilityManagementService
    ) {
        this.heading = data.heading;
        this.today = new Date();
        this._facilityId = this.facilityManagementService._currentFacility.id;

        this._route.queryParams.subscribe((params) => {
            this._patientId = params['patient'];
        });

        this._subscriptions.push(
            store.pipe(select('patient')).subscribe((patient: Patient) => {
                this._headerInfo.firstName = this.titleCasePipe.transform(patient.firstName);
                this._headerInfo.lastName = this.titleCasePipe.transform(patient.lastName);
            })
        );

        this._subscriptions.push(
            store.pipe(select('therapyAdmission')).subscribe((therapyAdmissions: TherapyAdmission[]) => {
                this.therapyAdmissions = cloneDeep(therapyAdmissions);

                this.therapyAdmissions.forEach((therapyAdmission: TherapyAdmission) => {
                    if (therapyAdmission?.documentation?.dischargeNote?.assessment?.endOfCareDate) {
                        this.disciplineIndex[therapyAdmission.therapyDiscipline].EOC = this.formatDate(
                            therapyAdmission.documentation.dischargeNote.assessment.endOfCareDate
                        );
                    }

                    if (therapyAdmission.SOCDate) {
                        this.disciplineIndex[therapyAdmission.therapyDiscipline].SOC = this.formatDate(
                            therapyAdmission.SOCDate
                        );
                    }
                });
            })
        );
        this._subscriptions.push(
            store.pipe(select('facilityAdmission')).subscribe((facilityAdmission: FacilityAdmission) => {
                this._facilityAdmission = facilityAdmission;

                if (facilityAdmission.admitDate) {
                    this._headerInfo.admitDate = this.formatDate(facilityAdmission.admitDate);
                }

                this._headerInfo.MRN = facilityAdmission.MRN;

                if (facilityAdmission.patientDischarge?.facilityDischargeDate) {
                    this._headerInfo.facilityDischarge = this.formatDate(
                        facilityAdmission.patientDischarge?.facilityDischargeDate
                    );
                }
            })
        );
        this._subscriptions.push(
            store.pipe(select('payorInformation')).subscribe((payorInformation: any) => {
                if (payorInformation?.payor?.name) {
                    this._headerInfo.payor = payorInformation.payor.name;
                    return;
                }

                // reset if payor not found
                this._headerInfo.payor = '';
            })
        );
    }

    close() {
        this._PDPMService.minutesCache = {};
        this.dialogRef.close();
    }

    generateReport() {
        this._toasterService.show({
            title: 'Generating Report...',
            body: 'Compiling Data for OBRA Report, will be downloaded automatically.',
            type: 'success',
        });
        this.close();

        let queryParams = `OBRASevenDaysLookBack=${new Date(this.selectedDate).toISOString()}`;
        if (this._facilityAdmission.patientDischarge) {
            queryParams = queryParams + `&dischargedFacilityAdmission=${this._facilityAdmission.id}`;
        }

        this._PDPMService.getTherapyMinutes(this._patientId, this._facilityId, queryParams).subscribe(({ data }) => {
            try {
                const header = [];

                header.push({
                    'Last Name': this._headerInfo.lastName,
                    'First Name': this._headerInfo.firstName,
                    MRN: this._headerInfo.MRN,
                    'Admit Date': this._headerInfo.admitDate,
                    Payor: this._headerInfo.payor,
                    'OBRA ARD': this.formatDate(this.selectedDate),
                    'Facility Discharge': this.formatDate(this._headerInfo.facilityDischarge),
                });

                this._PDPMService.minutesCache[MODES.SEVEN_DAYS_LOOK_BACK] = cloneDeep(data);

                const PTData = this._PDPMService.therapyMinutesHandler(data, 'PT', MODES.SEVEN_DAYS_LOOK_BACK);
                const OTData = this._PDPMService.therapyMinutesHandler(data, 'OT', MODES.SEVEN_DAYS_LOOK_BACK);
                const STData = this._PDPMService.therapyMinutesHandler(data, 'ST', MODES.SEVEN_DAYS_LOOK_BACK);

                const fileName = `${this._headerInfo.lastName}-${
                    this._headerInfo.firstName
                }-OBRA-Report-${this.formatDate(this.selectedDate, 'MM-dd-yyyy')}`;

                // creating custom workbook
                const workbook = this.createWorkbook({ header, PTData, OTData, STData });

                // once workbook is done, convert to xlsx and download it
                this._excelService.downloadExcelSheet(workbook, fileName);
            } catch (error) {
                console.log('error', error);

                this._toasterService.show({
                    title: 'Failed',
                    body: 'Could not generate OBRA Report',
                    type: 'error',
                });
            }
        });
    }

    ngOnDestroy(): void {
        this._subscriptions.map((sub: Subscription) => sub.unsubscribe());
    }

    formatDate(date: string, pattern = 'MM/dd/yyyy') {
        if (!date) {
            return '';
        }
        return this.datePipe.transform(new Date(date), pattern);
    }

    createWorkbook(data: {
        header: TableItem[];
        PTData: MinutesData;
        OTData: MinutesData;
        STData: MinutesData;
    }): ExcelJS.Workbook {
        // creating and filling custom data in worksheet

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('OBRA Quarterly Report');

        this.addTableInSheetWithStyling(worksheet, data.header);

        worksheet.addRow({});
        worksheet.addRow({});

        // do this for all disciplines
        this.therapyAdmissions
            .map((admission: TherapyAdmission) => admission.therapyDiscipline)
            .forEach((discipline: string) => {
                const disciplineHeader = worksheet.addRow([`${discipline}`, 'SOC', 'EOC']);
                disciplineHeader.font = this.columnFont;

                disciplineHeader.eachCell({ includeEmpty: false }, (cell) => {
                    cell.fill = this.columnFill;
                });

                worksheet.addRow(['', this.disciplineIndex[discipline].SOC, this.disciplineIndex[discipline].EOC]);

                worksheet.addRow({});

                const rawMinutes = data[`${discipline}Data`].therapyMinutesData;
                const minutes = this.convertKeysToProperColumnName(rawMinutes);
                this.addTableInSheetWithStyling(worksheet, minutes);

                const grandTotal = data[`${discipline}Data`].grandTotal[0];

                if (minutes.length || grandTotal) {
                    const grandTotalRow = worksheet.addRow([
                        'Grand Total',
                        grandTotal.therapyDaysGrandTotal,
                        `${grandTotal.individual} (${grandTotal.individualPercentage}%)`,
                        `${grandTotal.concurrent} (${grandTotal.concurrentPercentage}%)`,
                        `${grandTotal.group} (${grandTotal.groupPercentage}%)`,
                        `${grandTotal.co_treatment} (${grandTotal.co_treatmentPercentage}%)`,
                        `${grandTotal.total} (100%)`,
                    ]);

                    // styling
                    grandTotalRow.font = this.columnFont;
                    grandTotalRow.eachCell({ includeEmpty: false }, (cell) => {
                        cell.fill = this.grandTotalFill;
                    });
                }

                worksheet.addRow({});
                worksheet.addRow({});
            });

        return workbook;
    }

    /**
     * Table = array of objects having keys(columns) and values(rows)
     */
    addTableInSheetWithStyling(worksheet: ExcelJS.Worksheet, data: TableItem[]): void {
        if (!data || !data.length) {
            return;
        }

        // Add headers
        const headers = Object.keys(data[0]);
        const headerRow = worksheet.addRow(headers);
        headerRow.font = this.columnFont;
        headerRow.height = 20;

        headerRow.eachCell({ includeEmpty: false }, (cell) => {
            cell.fill = this.columnFill;
        });

        // Add data
        data.forEach((item) => {
            const row: any = [];
            headers.forEach((header) => {
                row.push(item[header]);
            });
            worksheet.addRow(row);
        });

        worksheet.columns.forEach((column) => {
            column.width = 20;
        });
    }

    convertKeysToProperColumnName(data: any[]) {
        return data.map((row) => {
            return {
                Date: row.date,
                'Ther. Days': row.dailyNoteOrder,
                Individual: row.individual,
                Concurrent: row.concurrent,
                Group: row.group,
                'Co-Treatment': row.co_treatment,
                Total: row.total,
            };
        });
    }
}
