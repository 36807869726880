export const DOCUMENTATION_ROUTES = {
    DOCUMENTATION_URL: '/documentation',
    EVALUATION_URL: '/evaluation-document',
    DAILY_NOTE_URL: '/daily-note-document',
    PROGRESS_NOTE_URL: '/progress-note-document',
    UPDATED_PLAN_OF_CARE_NOTE_URL: '/upoc-note-document',
    RECERTIFICATION_NOTE_URL: '/re-certification-document',
    DISCHARGE_NOTE_URL: '/discharge-note-document',
    ADDENDUM_NOTE_URL: '/addendum-note-document',
    DAILY_NOTE_VIEW_URL: '/daily-note-view',
    PROGRESS_NOTE_VIEW_URL: '/progress-note-view',
    DISCHARGE_NOTE_VIEW_URL: '/discharge-note-document',
};

export const CONTRACT_ROUTES = {
    CONTRACT_TEMPLATE_URL: '/add-contract-template',
    ADD_CONTRACT: '/add-contract',
};

export const PAYOR_ROUTES = {
    ADD_PAYOR: '/admin/payors/add-payor',
};
