import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl, ValidationErrors } from '@angular/forms';

@Component({
    selector: 'app-combined-input',
    templateUrl: './combined-input.component.html',
    styleUrls: ['./combined-input.component.scss'],
})
export class CombinedInputComponent implements OnInit, ControlValueAccessor {
    @Input() label: string;
    @Input() placeholder = 'Type here';
    @Input() customToolTip: { tooltip: boolean; msg: string };
    errorMessage = '';
    @Input() type: 'text' | 'number' | 'password' | 'email' = 'text';
    @Input() allowSpaces: boolean;
    @Input() tooltip = false;
    @Input() minLength = 0;
    @Input() maxLength = 100;
    @Input() allowDecimal = false;
    @Input() tooltipConfig: any = {};
    @Input() tooltipPlacement = 'left';
    @Input() borderBottom = true;
    @Input() mask: any;
    maskObj: any;
    @Output() changeHappened = new EventEmitter();

    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
    propagateChange = (_: any) => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
    onTouched = (_: any) => {};

    value: any;
    disabled: boolean;
    constructor(public readonly ngControl: NgControl) {
        ngControl.valueAccessor = this;
    }
    ngOnInit(): void {
        if (!this.placeholder) this.placeholder = 'Type here';
        if (this.allowDecimal && this.type === 'number') {
            this.mask = new RegExp('^(100(?:\\.00?)?|\\d{1,2}(?:\\.\\d{0,2})?)$');
        } else if (this.mask === undefined || this.mask === null) {
            this.mask = this.allowSpaces === false ? new RegExp('^[a-zA-Z0-9]*$') : new RegExp('^[A-Za-z0-9 ]*$');
        }
        this.maskObj = this.mask != null ? { mask: this.mask } : null;
    }

    get isRequired(): boolean {
        const control: AbstractControl = this.ngControl.control;
        if (control.validator === null) return false;
        return control.validator(control)?.required ?? false;
    }

    get hasError(): boolean {
        const control: AbstractControl = this.ngControl.control;
        const controlErrors: ValidationErrors = control.errors;

        const condition1 = control && (control.dirty || control.touched) && control.invalid;

        if (condition1) {
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach((keyError) => {
                    switch (keyError) {
                        case 'required':
                            this.errorMessage = `Field is required!`;
                            break;
                        case 'pattern':
                            this.errorMessage = `Field has wrong pattern!`;
                            break;
                        case 'email':
                            this.errorMessage = `Wrong email format!`;
                            break;
                        case 'minlength':
                            this.errorMessage = `Min length required is ${controlErrors[keyError].requiredLength}`;
                            break;
                        case 'maxlength':
                            this.errorMessage = `Max length required is ${controlErrors[keyError].requiredLength}`;
                            break;
                        case 'max':
                            this.errorMessage = `Max value required is ${controlErrors[keyError].max}`;
                            break;
                        case 'min':
                            this.errorMessage = `Min value required is ${controlErrors[keyError].min}`;
                            break;
                        default:
                            this.errorMessage = `${keyError} value error`;
                    }
                });
            }
            return true;
        }

        // tooltip from Props instead of Form Controls
        if (this.customToolTip?.tooltip) {
            this.errorMessage = this.customToolTip.msg;
            return true;
        }

        return false;
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    onChange(event: any): void {
        this.propagateChange(event.target.value);
    }

    onBlur(): void {
        this.ngControl.control.markAsTouched();
        this.changeHappened.emit();
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
