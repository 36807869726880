<div
    *ngIf="!params.hidden"
    class="custom-control custom-switch ml-4 mt-2 mr-3"
    [ngClass]="{ 'adjust-margin': params?.adjustMargin }"
>
    <input
        [disabled]="params.disabled"
        type="checkbox"
        class="custom-control-input"
        id="{{ radioId }}"
        [(ngModel)]="switchValue"
        (change)="onSwitchValue()"
    />
    <label class="custom-control-label" [ngClass]="{ 'cursor-pointer': !params.disabled }" for="{{ radioId }}"></label>
</div>
