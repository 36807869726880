import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { InsuranceCoverage } from '@app/interfaces';

const baseUrl = `${environment.apiUrl}/insurance-coverage`;

@Injectable({ providedIn: 'root' })
export class InsuranceCoverageService {
    constructor(private http: HttpClient) {}

    createInsurance(insuranceForm: InsuranceCoverage) {
        return this.http.post<any>(baseUrl, insuranceForm);
    }
    authorizePayor(authForm: any) {
        return this.http.patch<any>(`${baseUrl}/authorize`, authForm);
    }
    deleteInsurance(id: string,payorInformation:string) {
        return this.http.delete<any>(`${baseUrl}/${payorInformation}/${id}`);
    }
    updateInsurance(insuranceForm) {
        return this.http.patch<any>(`${baseUrl}/update`, insuranceForm);
    }

    getAllStates(): string[] {
        return [
            'Alaska',
            'Alabama',
            'Arizona',
            'Arkansas',
            'California',
            'Colorado',
            'Connecticut',
            'Delaware',
            'Florida',
            'Gregoria',
            'Hawaii',
            'Idaho',
            'Illinois',
            'Indiana',
            'Iowa',
            'Kansas',
            'Kentucky',
            'Maine',
            'Maryland',
            'Massachusetts',
            'Michigan',
            'Minnesota',
            'Utah',
            'Oregon',
            'Ohio',
            'New York',
            'Nevada',
            'Washington',
            'Virginia',
            'Texas',
            'West Virginia',
            'Vermont',
            'Wyoming',
            'South Dakota',
            'Oklahoma',
            'New Jersey',
            'New Mexico',
            'New Hemisphere',
            'Mississippi',
            'Montana',
        ];
    }
}
