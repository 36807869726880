import { EventEmitter } from '@angular/core';
import { sortBy } from 'lodash';
import {
    ICursorHighlight,
    IHighlightedBlanks,
    IHighlightItem,
    ISelectionRange,
} from './../../interfaces/ui';

// For the selection of blank values
/**
 * This function is used to select a blank value if it is selected for typing a new one.
 * It basically happens on click in the text-box
 */
export const highlightBlanks = (
    userSelection: ISelectionRange,
    suggestionBoxValue: string,
    highlightTexts: IHighlightItem[]
): IHighlightedBlanks => {
    let highlights: ICursorHighlight[] = [];
    let range: ISelectionRange;
    highlights = getAllHighlightsSorted(suggestionBoxValue, highlightTexts, ['start']);
    highlights.forEach((item) => {
        if (userSelection.start >= item.start && userSelection.end <= item.end) {
            range = { start: item.start, end: item.end };
        }
    });
    if (range === undefined && highlights.length > 0) range = { start: highlights[0].start, end: highlights[0].end };
    return { highlights, selectionRange: range };
};

export const getHighlightsCounter = (value: string, highlights: IHighlightItem[]): number => {
    if (typeof value !== 'string') return 0;
    return getAllHighlights(value, highlights).length;
};

// To get all the matches in an array with their starting and ending ranges => ICursorHighlight
export const getAllHighlights = (value: string, highlights: IHighlightItem[]): ICursorHighlight[] => {
    if (typeof value !== 'string') return;
    let _stringData: any[] = [];
    let _objectData: string[] = [];
    // Getting all highlights
    highlights.forEach((x) => {
        if (typeof x === 'string') {
            const _ = [...value.matchAll(new RegExp('_{2,}', 'g'))];
            if (_ !== null) _stringData = [..._stringData, ..._];
        } else if (typeof x === 'object') {
            const _ = value.match(new RegExp(`\\${x.from}[^\\${x.to}[]*]`, 'g'));
            if (_ !== null) _objectData = [..._objectData, ..._];
        }
    });
    return [
        ..._stringData.map((_item) => getIndexRange(value, _item, 'string', _item.index)),
        ..._objectData.map((_item) => getIndexRange(value, _item, 'object')),
    ];
};
export const getHighlightItem = (highlights: ICursorHighlight[], range: ISelectionRange): ICursorHighlight => {
    return highlights.filter((item) => item.start >= range.start && item.end <= range.end)[0];
};
export const isLastSelectedItem = (sortedHighlights: ICursorHighlight[], caretPosition: number): boolean => {
    if(!sortedHighlights.length) return true
    return sortedHighlights[sortedHighlights.length -1].start <= caretPosition // If caret position is equal or greater than the start of last highlight than last item is selected
};
export const getAllHighlightsSorted = (
    value: string,
    highlights: IHighlightItem[],
    sortByValues: string[]
): ICursorHighlight[] => {
    return sortBy(getAllHighlights(value, highlights), sortByValues);
};
// Returns back data in ICursorHighlight format
// Returns value, starting index and ending index for a substring in string
export const getIndexRange = (
    value: string,
    subString: string,
    type: 'string' | 'object',
    stringStart: number = null
): ICursorHighlight => {
    if (stringStart === null) {
        const start = value.indexOf(subString);
        const end = start + subString.length;
        return { value: subString, start, end, type };
    } else {
        const end = stringStart + subString[0].length;
        return { value: subString[0], start: stringStart, end, type };
    }
};

// highlights all blanks at once with the highlighter color
export const applyHighlights = (
    text: string,
    highlightTexts: IHighlightItem[],
    counter: number,
    emitter: EventEmitter<boolean>
) => {
    // Continue if text is not null
    if (typeof text !== 'string') return;
    let _ = 0;
    if (highlightTexts.length > 0 && text !== null) {
        highlightTexts.forEach((x) => {
            if (typeof x === 'string' && text !== null) {
                _ += (text.split(x).length - 1) | 0;
            } else if (typeof x === 'object') {
                _ += (text.split(x.from).length - 1) | 0;
            }
        });
        counter = _;
    }
    if (counter === 0) emitter.emit(true);
    else emitter.emit(false);
    text = text ? text.replace(/\n$/g, '\n\n') : '';
    // Highlighting the keywords
    highlightTexts.forEach((x) => {
        if (typeof x === 'string') {
            text = text.replace(new RegExp(x, 'g'), '<mark class="highlight-mark">$&</mark>');
        } else if (typeof x === 'object') {
            text = text.replace(
                new RegExp(`\\${x.from}[^\\${x.to}[]*]`, 'g'),
                '<mark class="highlight-mark">$&</mark>'
            );
        }
    });
    // Fixing the issue with IE and edge
    const ua = window.navigator.userAgent.toLowerCase();
    const isIE = !!ua.match(/msie|trident\/7|edge/);
    if (isIE) {
        // IE wraps whitespace differently in a div vs textarea, this fixes it
        text = text.replace(/ /g, ' <wbr>');
    }
    return text;
};

const capitalizeSingleString = (str: string) => {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
};

export const capitalize = (str: string, allString?: boolean) => {
    if (!allString) return capitalizeSingleString(str);
    return str
        .split(' ')
        .map((_str) => capitalizeSingleString(_str))
        .join(' ');
};

export function formatAdditionalRequirements(input: string): string {
    let words: string[] = input.split(',');

    words = words.map((word) => {
        word = word.replace(/([A-Z])/g, ' $1').trim();

        word = word
            .split(' ')
            .map((w) => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase())
            .join(' ');

        return word;
    });

    let result: string = words.join(', ');
    const lastCommaIndex: number = result.lastIndexOf(', ');
    if (lastCommaIndex !== -1) {
        result = result.substring(0, lastCommaIndex) + ' and' + result.substring(lastCommaIndex + 1);
    }

    return result;
}

export const isValidEmail = (email: string) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
}

export const findClosestIndex = (array: number[], checkNum: number) => {
    // Get the index of the highest number which is lower than the given number in array which sorted in ascending order
    let result = -1; // Default to -1 if no valid index is found
    for (let i = 0; i < array.length; i++) {
      if (array[i] <= checkNum) {
        result = i; // Update result to the current index
      } else {
        break; // Break the loop as the array is sorted
      }
    }
    return result;
  }
