import { Component, Inject } from '@angular/core';
import { DIALOG_DATA } from '@app/helpers/dialog-tokens';
import { DialogRef } from '@app/services';

@Component({
    selector: 'app-assessment-deletion-modal',
    templateUrl: './assessment-deletion-modal.component.html',
    styleUrls: ['./assessment-deletion-modal.component.scss'],
})
export class AssessmentDeletionModalComponent {
    constructor(@Inject(DIALOG_DATA) public data, private ref: DialogRef) {}

    onClose(result?) {
        this.ref.close(result);
    }
    onDone() {
        this.onClose(true);
    }
}
