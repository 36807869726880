<div class="treatment-main">
    <div class="heading">
        <span>Treatment</span>
        <span>
            <span class="bold">Total Time:&nbsp;</span>{{totalMinutes}}
            <span class="bold">&nbsp;&nbsp;Total Unit:&nbsp;</span>{{totalUnits}}
        </span>
    </div>
    <ng-container *ngFor="let treatment of treatments">
        <div class="grid">
            <table class="layout">
                <tr class="row">
                    <td class="data-cell">
                        <span class="bold">Code:</span>
                        <span>{{treatment.code}}</span>
                    </td>
                    <td class="description-cell">
                        <span class="bold">Description:</span>
                        <span>{{treatment.description}}</span>
                    </td>
                    <td class="data-cell">
                        <span class="bold">Time:</span>
                        <span>{{treatment.cptMinutes}} mins</span>
                    </td>
                </tr>
            </table>
        </div>
        <div class="treatment-bottom">
            <div class="title">
                Skilled Services and Justification for Treatment
            </div>
            <p>
                {{treatment.justification || "No data available"}}
            </p>
        </div>
    </ng-container>
</div>