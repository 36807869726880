import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CellClickedEvent, ColDef, GridOptions } from 'ag-grid-community';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
// eslint-disable-next-line spellcheck/spell-checker
import { StatusEnum } from '@app/helpers/constants/enums';
import { AuthPolicies, ROUTER_UTILS, formatDate } from '@app/helpers/utils';
import { FacilityAdmission, IAssignedTherapist, TherapyAdmission } from '@app/interfaces';
import { roles } from '@app/helpers';
import { PccService } from '@app/modules/patients/PCC/services/pcc.service';
import { Subject, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { AuthService } from '@app/services';

interface ITherapyAdmissionBaseDiscipline {
  [key: string]: TherapyAdmission;
}

@Component({
  selector: 'app-therapy-discipline-bar-card',
  templateUrl: './therapy-discipline-bar-card.component.html',
  styleUrls: ['./therapy-discipline-bar-card.component.scss']
})
export class TherapyDisciplineBarCardComponent implements OnInit, OnChanges {
  @Input() therapyAdmissions: TherapyAdmission[];
  @Input() patientId: string;

  private readonly onDestroy$ = new Subject<void>();
  disciplines = ['PT', 'OT', 'ST'];
  activeTherapyAdmissions: ITherapyAdmissionBaseDiscipline;
  admissionsDataGridColumns: ColDef[] = [];
  admissionsDataGridRows = {
    'PT': [],
    'OT': [],
    'ST': []
  };
  pccUrl = '/' + ROUTER_UTILS.config.patients.pcc.root;
  therapyAdmissionDetailUrl = this.pccUrl + '/' + ROUTER_UTILS.config.patients.pcc.therapyCase.root + '/' + ROUTER_UTILS.config.patients.pcc.therapyCase.therapyAdmission;
  // eslint-disable-next-line spellcheck/spell-checker
  statusEnum = StatusEnum;
  expandAdmissionHistory = false;
  isFacilityDischarged = false;
  gridOptions: GridOptions = {
    getRowStyle: params => {
      const height = 60;
      const translateY = height * params.node.rowIndex;
      return { height: height + 'px', transform: `translateY(${translateY}px)` };
    }
  }
  policies = AuthPolicies;
  checkAddPermission = false;

  constructor(
    private router: Router,
    private datePipe: DatePipe,
    private pccService: PccService,
    private store: Store<{ facilityAdmission: FacilityAdmission }>,
    private authService: AuthService
  ) {
    this.store
      .select('facilityAdmission')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((facilityAdmission) => {
          this.isFacilityDischarged = facilityAdmission?.patientDischarge?.facilityDischargeDate ? true : false
      });
  }

  ngOnInit(): void {
    this.pccService.admissionHistoryExpand$.pipe(takeUntil(this.onDestroy$)).subscribe((expand: boolean) => {
      this.expandAdmissionHistory = expand;
    });
    this.checkAddPermission = this.authService.applyPolicy(this.policies.Patient.Add)
  }

  ngOnChanges(): void {
    this.activeTherapyAdmissions = this.disciplines.reduce((acc, m) => {
      acc[m] = this.therapyAdmissions.find(ta => ta.therapyDiscipline?.toLowerCase() === m.toLowerCase() && !ta?.isDischarged
      );
      return acc;
    }, {});

    this.generateAdmissionsDataGrid();
  }

  addTherapyCase(discipline) {
    this.router.navigate([ROUTER_UTILS.config.therapyAdmission.add.root], {
      state: { therapyDiscipline: discipline },
    });
  }

  openTherapyCase(therapyCaseId: string, admissionId: string) {
    this.router.navigate([this.therapyAdmissionDetailUrl], { queryParams: {
      patient: this.patientId, 'therapy-case': therapyCaseId,
      facilityAd: admissionId
    }});
  }

  getSuperTherapistInfo(therapists: IAssignedTherapist[], withoutEllipsis = false): string {
    const superTherapist = therapists.find(assignedTherapist => {
      return assignedTherapist.role === roles.Supervising;
    })?.therapist;
    if(!superTherapist) {
      return '--';
    }
    let name = '';
    const superTherapistName = superTherapist.user?.lastName + ', ' + superTherapist.user?.firstName;
    if(superTherapistName.replace(', ', '').length) {
      name = withoutEllipsis ? superTherapistName : this.textEllipsis(superTherapistName);
      return name + ' - ' + superTherapist.discipline;
    }

    return '--';
  }

  transformDate(date: any) {
    return this.datePipe.transform(date, 'MM/dd/yyyy');
  }

  // ====================
  // Private Methods
  // ====================
  private generateAdmissionsDataGrid(): void {
    try {
      const activeAdmissions = Object.values(this.activeTherapyAdmissions).filter(a => a);

      // find non active admissions
      const historyAdmissions = this.therapyAdmissions.filter(therapy => {
        const dischargedAdmission = activeAdmissions?.length ? activeAdmissions.find(admission => admission._id !== therapy._id && therapy.isDischarged) : therapy;
        return dischargedAdmission;
      });
      // clear old cache data before generating new one (in case of switching facility admissions, data remain as cache)
      this.admissionsDataGridRows = {
        'PT': [],
        'OT': [],
        'ST': []
      };

      if(historyAdmissions.length) {
        this.admissionsDataGridColumns = [
          { headerName: 'Case No.', field: 'caseNo', headerClass: 'body-bold-14 bg-white', cellClass: "body-regular-14 abs-center" },
          { headerName: 'SOC Date', field: 'socDate', headerClass: 'body-bold-14 bg-white', cellClass: "body-regular-14 abs-center" },
          { headerName: 'Supervising Therapist', field: 'supervising', headerClass: 'body-bold-14 bg-white', cellClass: "body-regular-14 abs-center",
            cellRenderer: (params) => {
              const supervising = this.textEllipsis(params.data.supervising);
              const tooLongText = supervising.includes('...');
              return tooLongText ? `
                <span
                  title="${params.data.supervisingTitle}"
                >
                  ${supervising}
                </span>` : supervising;
            },
          },
          { headerName: 'EOC Date', field: 'eocDate', headerClass: 'body-bold-14 bg-white', cellClass: "body-regular-14 abs-center"},
          { headerName: '', field: 'status',
            headerClass: 'body-bold-14 bg-white', cellClass: "body-regular-14 abs-center",
            cellRenderer: (params) => {
              // eslint-disable-next-line spellcheck/spell-checker
              return `<span class="${params.data.status === this.statusEnum.Open ? 'bg-success-100-a40 text-success-200' : 'bg-danger-100-a40 text-danger-200'} badge rounded-pill rounded-pill-fixed-w mb-1 body-regular-12-no-lineHeight"
                  >${params.data.status}</span>`;
            }
          },
          { headerName: '', field: '',
            cellClass: "abs-center icon-sm-cell",
            headerClass: 'bg-white',
            cellRenderer: () => {
              return `
                <div
                  class="material-symbols-outlined text-secondary d-inline-block align-middle cursor-pointer"
                >
                  open_in_new
                </div>`;
            },
            onCellClicked: (event: CellClickedEvent) => this.openTherapyCase(event.data.id, event.data.admissionId),
          }
        ];

        Object.keys(this.admissionsDataGridRows).forEach(key => {
          this.admissionsDataGridRows[key] = historyAdmissions.filter(ha => ha.therapyDiscipline?.toLowerCase() === key?.toLowerCase())
            .map(historyAdmission => {
              return {
                id: historyAdmission._id,
                caseNo: historyAdmission.caseNo || '#####',
                socDate: formatDate(historyAdmission.SOCDate),
                supervising: this.getSuperTherapistInfo(historyAdmission.assignedTherapists) || '--',
                supervisingTitle: this.getSuperTherapistInfo(historyAdmission.assignedTherapists, true),
                eocDate: formatDate(historyAdmission?.documentation?.dischargeNote?.assessment?.endOfCareDate) || '--',
                // eslint-disable-next-line spellcheck/spell-checker
                status: historyAdmission?.isDischarged ? this.statusEnum.Closed : this.statusEnum.Open,
                disabled: historyAdmission?._id === this.activeTherapyAdmissions[key]?._id,
                admissionId: historyAdmission.facilityAdmission,
                createdAt: historyAdmission.createdAt
              }
            }).sort((w, y) => {
              if(!isNaN(+y.caseNo) && !isNaN(+w.caseNo)) {
                return parseInt(y.caseNo) - parseInt(w.caseNo)
              } else {
                return +new Date(y.createdAt) - +new Date(w.createdAt);
              }
            });
        });
      }
    } catch(err) {
      if(err instanceof Error) console.log("generateAdmissionsDataGrid Error ==>", err.message);

      Object.keys(this.admissionsDataGridRows).forEach(key => {
        this.admissionsDataGridRows[key] = [];
      });
    }
  }

  private textEllipsis(value: string): string {
    return value.length > 25 ? value.slice(0, 22) + '...' : value;
  }

}
