import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { ROUTER_UTILS } from '@app/helpers';
import { PayorInformation } from '@app/interfaces';
import { FacilityAdmissionService } from '@app/services';
import { PayorInformationService } from '@app/services/payorInformation.service';
import { Store } from '@ngrx/store';
import { first, Observable, switchMap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PCCGuard implements CanActivate {
    constructor(
        private store: Store<{
            payorInformation: PayorInformation;
        }>,
        private _facilityAdmissionService: FacilityAdmissionService,
        private _payorInformationService: PayorInformationService,
        private _router: Router
    ) {}

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('tab') !== 'pdpm' && urlParams.get('tab') !== 'mds') {
            return true;
        }

        switch (urlParams.get('tab')) {
            case 'pdpm': {
                return new Observable((subscriber) => {
                    this.store
                        .select('payorInformation')
                        .pipe(first())
                        .subscribe((payorInformation: any) => {
                            // CASE: store has value
                            if (payorInformation.payor !== '') {
                                if (payorInformation.payor.pdpm) {
                                    subscriber.next(true);
                                    return;
                                }
                                this._router.navigateByUrl(ROUTER_UTILS.config.base.home);
                                return;
                            }

                            // CASE: store doesn't have value because parallel promise is pending
                            const patientId = urlParams.get('patient');

                            this._facilityAdmissionService
                                .getFacilityAdmissionByPatientId(patientId)
                                .pipe(
                                    switchMap(({ data }) =>
                                        this._payorInformationService.getPayorInformationByFacilityAdmissionId(data._id)
                                    )
                                )
                                .subscribe(({ data }) => {
                                    const payor: any = data.payor;
                                    if (payor.pdpm) {
                                        subscriber.next(true);
                                        return;
                                    } else {
                                        this._router.navigateByUrl(ROUTER_UTILS.config.base.home);
                                    }
                                });
                        });
                });
                break;
            }
            case 'mds': {
                return new Observable((subscriber) => {
                    this.store
                        .select('payorInformation')
                        .pipe(first())
                        .subscribe((payorInformation: any) => {
                            // CASE: store has value
                            if (payorInformation.payor !== '') {
                                if (payorInformation.payor.pdpm || payorInformation.payor.mds) {
                                    subscriber.next(true);
                                    return;
                                }
                                this._router.navigateByUrl(ROUTER_UTILS.config.base.home);
                                return;
                            }

                            // CASE: store doesn't have value because parallel promise is pending
                            const patientId = urlParams.get('patient');

                            this._facilityAdmissionService
                                .getFacilityAdmissionByPatientId(patientId)
                                .pipe(
                                    switchMap(({ data }) =>
                                        this._payorInformationService.getPayorInformationByFacilityAdmissionId(data._id)
                                    )
                                )
                                .subscribe(({ data }) => {
                                    const payor: any = data.payor;
                                    if (payor.pdpm || payor.mds) {
                                        subscriber.next(true);
                                        return;
                                    } else {
                                        this._router.navigateByUrl(ROUTER_UTILS.config.base.home);
                                    }
                                });
                        });
                });
                break;
            }
        }
    }
}
