import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[dateMask]',
})
export class InputMaskDirective {
    constructor(private elementRef: ElementRef) {}

    @HostListener('input', ['$event'])
    onInput() {
        const input = this.elementRef.nativeElement;
        const value = input.value.replace(/\D+/g, '');
        const selectionStart = input.selectionStart;

        if (value.length > 8) {
            input.value = input.value.substring(0, 10);
            return;
        }

        let formattedValue = '';

        if (value.length <= 2) {
            formattedValue = value;
        } else if (value.length <= 4) {
            formattedValue = `${value.substring(0, 2)}/${value.substring(2, 4)}`;
        } else if (value.length <= 8) {
            formattedValue = `${value.substring(0, 2)}/${value.substring(2, 4)}/${value.substring(4, 8)}`;
        }

        input.value = formattedValue;

        // Adjust cursor position
        let newCursorPosition = selectionStart;
        if (selectionStart > 2 && selectionStart <= 5) {
            newCursorPosition++;
        } else if (selectionStart > 5 && selectionStart <= 8) {
            newCursorPosition += 2;
        }
        input.setSelectionRange(newCursorPosition, newCursorPosition);
    }

    @HostListener('keydown.backspace', ['$event'])
    @HostListener('keydown.delete', ['$event'])
    onKeyup() {
        const input = this.elementRef.nativeElement;
        const value = input.value.replace(/\D+/g, '');
        const selectionStart = input.selectionStart;

        if (value.length === 8) {
            if (selectionStart === 6) {
                input.value = `${value.substring(0, 2)}/${value.substring(2, 4)}`;
                input.setSelectionRange(5, 5);
            } else if (selectionStart === 2) {
                input.value = value.substring(0, 2);
                input.setSelectionRange(2, 2);
            }
        } else if (value.length === 4) {
            if (selectionStart === 2) {
                input.value = value.substring(0, 2);
                input.setSelectionRange(2, 2);
            }
        }
    }
}
