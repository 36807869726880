import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DIALOG_DATA } from '@app/helpers/dialog-tokens';
import { StandardizedTestsService } from '@app/services';
import { DialogRef } from '@app/services/modal.service';

@Component({
    selector: 'app-standard-test-form',
    templateUrl: './standard-test-form.component.html',
    styleUrls: ['./standard-test-form.component.scss'],
})
export class StandardTestFormComponent {
    heading = '';
    isCompletedItem = false;
    isEditMode: string;
    standardizedTestForm = new FormGroup({
        testScore: new FormControl('', Validators.required),
        testNote: new FormControl(),
    });
    constructor(
        @Inject(DIALOG_DATA) private data,
        private ref: DialogRef,
        private standardizedTestsService: StandardizedTestsService
    ) {
        this.heading = data.heading;
        this.isEditMode = data.isEditMode;
        if (data.formData) {
            this.standardizedTestForm.patchValue(data.formData);
            this.standardizedTestForm.patchValue(data.note);
            this.isCompletedItem = true;
        }
    }

    onClose() {
        this.ref.close(true);
    }
    onSave() {
        console.log(this.standardizedTestForm);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let testScore = null;
        Object.keys(this.standardizedTestForm.controls).forEach((val) => {
            testScore = Number(this.standardizedTestForm.controls[val].value);
        });
        const testStoreData = {
            name: this.heading,
            score: this.standardizedTestForm.value.testScore,
            interpretation: '',
            isCompleted: true,
            form: this.standardizedTestForm.value.testScore,
            note: this.standardizedTestForm.value.testNote,
        };
        this.standardizedTestsService.saveStandardTestsData(testStoreData, '', this.isEditMode);
        this.onClose();
    }
}
