import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-radio-button-cell-renderer',
    template:
        '<input #radio type="radio" class="cursor-pointer" name="ag_radio" [disabled]="disabled" [checked]="params.data.isSelected" (change)="invokeSetRadio($event)" style="display: inline-block; vertical-align: middle" />',
})
export class RadioButtonCellRendererComponent implements ICellRendererAngularComp {
    params: any;
    disabled = false;
    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.disabled = this.params?.disabled;
    }
    invokeSetRadio(event: any) {
        this.params.context.componentParent.setRendererRadio({ data: this.params.data, value: event.target.value });
    }
    refresh(): boolean {
        return false;
    }
}
