<app-base-modal-card heading="Feedback" (close)="onClose()">
    <div body>
        <div class="text-center my-3">
            <img src="../../../assets/images/feedback.png" alt="Feedback" width="180" />
            <h5 class="h5-semibold">Share your Feedback</h5>
        </div>
        <form [formGroup]="formFeedback">
            <!-- <app-radio-button label="Type" formControlName="feedbackType"
                [options]="['Problem','Suggestion','Comment']"></app-radio-button> -->
            <app-select
                [options]="feedbackTypes"
                inputClass="border border-1"
                label="Type"
                formControlName="feedbackType"
            ></app-select>
            <app-text-box
                formControlName="feedback"
                [borderClass]="true"
                placeholder="Share your suggestion / question / feedback"
                label="Feedback"
            ></app-text-box>
        </form>
    </div>
    <div footer class="text-right pt-3">
        <button class="btn-outline-secondary innova-btn mr-2" (click)="onClose()">Close</button>
        <button
            class="btn-secondary innova-btn"
            (click)="sendFeedback(formFeedback.value)"
            [disabled]="!formFeedback.valid"
        >
            Submit
        </button>
    </div>
</app-base-modal-card>
