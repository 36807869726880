import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActionsCellRendererComponent, RadioButtonCellRendererComponent } from '@app/data-grid/cell-renderers';
import { GridRowAction } from '@app/data-grid/interfaces/grid-row-action';
import { GridActionsService } from '@app/data-grid/services/grid-actions.service';
import { OnSetDateCellRenderer } from '@app/helpers';
import { FacilityAdmission, TherapyAdmission } from '@app/interfaces';
import { DOCUMENTATION_CONSTANTS, PATIENT_DEMOGRAPHICS, SEARCHABLE_LIST_NAMES } from '@app/interfaces/documentation';
import { ClinicalCategoriesService, SlpComorbidityService } from '@app/services';
import { SLPComorbidity } from '@app/store/store.interface';
import { Store } from '@ngrx/store';
import { ColDef, GridApi, RowClassRules } from 'ag-grid-community';
import * as _ from 'lodash';
import { cloneDeep, isEqual } from 'lodash';
import { map, Observable, of, Subject, switchMap, take, takeUntil } from 'rxjs';

@Component({
    selector: 'app-searchable-list',
    templateUrl: './searchable-list.component.html',
    styleUrls: ['./searchable-list.component.scss'],
})
export class SearchableListComponent implements OnChanges, OnDestroy {
    public therapyDiscipline;
    private readonly onDestroy = new Subject<void>();

    @Input() name: string; // always required
    @Input() recordsInput: any[];
    @Input() heading = '';
    @Input() onSetDate = '';
    @Input() discipline = '';
    @Input() isReadOnly = false;
    @Input() disableAdd = false;
    @Input() recordsData = [];
    @Input() withOutHeader = false;
    @Input() maxOnSetDate: string;
    @Input() maxTherapyOnsetDate: string;
    @Input() additionalInfo: string;
    @Output() recordsEvent: EventEmitter<any[]> = new EventEmitter<any[]>();
    @Output() recordsSave: EventEmitter<any[]> = new EventEmitter();
    @Output() isPrimaryMarked: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('seeMoreBtn') seeMoreBtn: ElementRef;
    @ViewChild('searchBar') searchBar: ElementRef;

    state: 'zero' | 'one' = 'zero';
    loading = false;
    isDone = true;
    gridApi: GridApi;
    data: any[] = [];
    currentAdmission: TherapyAdmission;
    therapyAdmissions$: Observable<TherapyAdmission[]>;
    selectedData: any[] = [];
    facilityAdmission$: Observable<FacilityAdmission>;
    allRecords: any[];
    seeMore: { offset: number; active: boolean; chunkSize: number } = {
        offset: 0,
        active: true,
        chunkSize: 10,
    };
    focusEvent: Subject<boolean> = new Subject<boolean>();

    SEARCHABLE_LIST_NAMES = SEARCHABLE_LIST_NAMES;
    primaryDXMarked = false;

    cols: { [name: string]: { recordCols: ColDef[]; editCols: ColDef[]; readOnlyCols: ColDef[] } } = {
        [SEARCHABLE_LIST_NAMES.MEDICAL_DIAGNOSIS]: {
            recordCols: [
                { headerName: 'Code', field: 'code', minWidth: 90, maxWidth: 90 },
                { headerName: 'Description', field: 'description' },
                { headerName: 'Pdpm Clinical Category', field: 'clinicalCategory' },
                { headerName: 'Major Procedure', field: 'majorProcedure' },
                { headerName: 'SLP Comorbidity', field: 'comorbidity' },
                {
                    headerName: this.primaryDXMarked
                        ? DOCUMENTATION_CONSTANTS.PRIMARY_DX
                        : DOCUMENTATION_CONSTANTS.PRIMARY_DX_REQUIRED,
                    field: 'isPrimary',
                    cellRenderer: RadioButtonCellRendererComponent,
                    headerClass: () => {
                        return this.primaryDXMarked ? '' : 'primary-dx-required';
                    },
                },
                {
                    headerName: 'Onset Date',
                    field: 'onsetDate',
                    cellRenderer: OnSetDateCellRenderer,
                    cellRendererParams: (params) => {
                        try {
                            params.data = JSON.parse(JSON.stringify(params.data));
                            params.data.maxOnSetDate = this.maxOnSetDate;
                        } catch (err) {
                            console.log(err.message);
                        }
                    },
                },
                {
                    headerName: 'Actions',
                    type: 'rightAligned',
                    field: 'actions',
                    cellRenderer: ActionsCellRendererComponent,
                    cellRendererParams: {
                        delete: true,
                        gridName: 'MedicalDiagnosis',
                    },
                },
            ],
            editCols: [
                { headerName: 'Code', field: 'code', cellStyle: { cursor: 'pointer' } },
                { headerName: 'Description', field: 'description', cellStyle: { cursor: 'pointer' } },
                { headerName: 'Pdpm Clinical Category', field: 'clinicalCategory', cellStyle: { cursor: 'pointer' } },
                { headerName: 'Major Procedure', field: 'majorProcedure', cellStyle: { cursor: 'pointer' } },
                { headerName: 'SLP Comorbidity', field: 'comorbidity', cellStyle: { cursor: 'pointer' } },
            ],
            readOnlyCols: [
                { headerName: 'Code', field: 'code', width: 80 },
                { headerName: 'Description', field: 'description', width: 150 },
                { headerName: 'Pdpm Clinical Category', field: 'clinicalCategory', width: 150 },
                { headerName: 'Major Procedure', field: 'majorProcedure', width: 150 },
                { headerName: 'SLP Comorbidity', field: 'comorbidity', width: 150 },
                {
                    headerName: 'Onset Date',
                    field: 'onsetDate',
                    width: 150,
                    valueFormatter: (params) => {
                        return this.dateFormatter(params);
                    },
                },
                {
                    headerName: '',
                    field: 'isPrimary',
                    width: 150,
                    cellRenderer: (params) => {
                        let badges = '';
                        let style = 'badge p-2 px-3 rounded-pill ';
                        if (params.data.isPrimary) {
                            style += 'bg-secondary-a10 text-secondary';

                            badges += `<span class='${style} ml-1 d-inline-block align-middle'>Primary</span>`;
                        }
                        return `<span class="d-block">${badges}</span>`;
                    },
                },
            ],
        },
        [SEARCHABLE_LIST_NAMES.TREATMENT_DIAGNOSIS]: {
            recordCols: [
                { headerName: 'Code', field: 'code', minWidth: 90, maxWidth: 90 },
                { headerName: 'Description', field: 'description', width: 200 },
                {
                    headerName: 'Onset Date',
                    field: 'onsetDate',
                    cellRenderer: OnSetDateCellRenderer,
                    cellRendererParams: (params) => {
                        try {
                            params.data = JSON.parse(JSON.stringify(params.data));
                            params.data.maxOnSetDate = this.maxTherapyOnsetDate;
                        } catch (err) {
                            console.log(err.message);
                        }
                    },
                },
                {
                    headerName: 'Actions',
                    field: 'actions',
                    type: 'rightAligned',
                    cellRenderer: ActionsCellRendererComponent,
                    cellRendererParams: {
                        delete: true,
                        gridName: 'treatmentDiagnosis',
                    },
                },
            ],
            editCols: [
                { headerName: 'Code', field: 'code', cellStyle: { cursor: 'pointer' } },
                { headerName: 'Description', field: 'description', cellStyle: { cursor: 'pointer' } },
            ],
            readOnlyCols: [
                { headerName: 'Code', field: 'code', width: 80 },
                { headerName: 'Description', field: 'description', width: 280 },
                {
                    headerName: 'Onset Date',
                    field: 'onsetDate',
                    width: 150,
                    valueFormatter: (params) => {
                        return this.dateFormatter(params);
                    },
                },
            ],
        },
        [PATIENT_DEMOGRAPHICS.PRECAUTIONS]: {
            recordCols: [
                { headerName: 'No.', valueGetter: 'node.rowIndex + 1', minWidth: 80 },
                {
                    field: 'description',
                    wrapText: true,
                    autoHeight: true,
                    width: 900,
                    cellStyle: () => {
                        return { wordBreak: 'normal' };
                    },
                },
                {
                    field: 'actions',
                    type: 'rightAligned',
                    cellRenderer: ActionsCellRendererComponent,
                    cellRendererParams: { delete: true, gridName: 'precautionCols' },
                },
            ],
            editCols: [
                {
                    headerName: 'No.',
                    valueGetter: 'node.rowIndex + 1',
                    resizable: true,
                    maxWidth: 70,
                    cellStyle: { cursor: 'pointer' },
                },
                {
                    headerName: 'Description',
                    field: 'description',
                    wrapText: true,
                    autoHeight: true,
                    width: 900,
                    cellStyle: { cursor: 'pointer' },
                },
            ],
            readOnlyCols: [
                { headerName: 'No.', valueGetter: 'node.rowIndex + 1', resizable: true, maxWidth: 70 },
                {
                    headerName: 'Description',
                    field: 'description',
                    wrapText: true,
                    autoHeight: true,
                    width: 900,
                },
            ],
        },
        [PATIENT_DEMOGRAPHICS.CONTRAINDICATIONS]: {
            recordCols: [
                { headerName: 'No.', minWidth: 70, valueGetter: 'node.rowIndex + 1' },
                {
                    field: 'description',
                    wrapText: true,
                    width: 900,
                    autoHeight: true,
                    cellStyle: () => {
                        return { wordBreak: 'normal' };
                    },
                },
                {
                    field: 'actions',
                    type: 'rightAligned',
                    cellRenderer: ActionsCellRendererComponent,
                    cellRendererParams: { delete: true, gridName: 'contraindicationCols' },
                },
            ],
            editCols: [
                { headerName: 'No.', valueGetter: 'node.rowIndex + 1', maxWidth: 70, cellStyle: { cursor: 'pointer' } },
                {
                    headerName: 'Description',
                    field: 'description',
                    wrapText: true,
                    autoHeight: true,
                    width: 900,
                    cellStyle: { cursor: 'pointer' },
                },
            ],
            readOnlyCols: [
                { headerName: 'No.', valueGetter: 'node.rowIndex + 1', maxWidth: 70 },
                {
                    headerName: 'Description',
                    field: 'description',
                    wrapText: true,
                    autoHeight: true,
                    width: 900,
                },
            ],
        },
        [SEARCHABLE_LIST_NAMES.CPT_CODES]: {
            recordCols: [
                {
                    headerName: 'Code',
                    field: 'cptCode',
                    width: 10,
                    sortable: true,
                    sort: 'asc',
                    sortingOrder: ['desc', 'asc'],
                },
                { headerName: 'Description', field: 'description' },
                {
                    headerName: 'Actions',
                    field: 'actions',
                    type: 'rightAligned',
                    cellRenderer: ActionsCellRendererComponent,
                    cellRendererParams: {
                        delete: true,
                        gridName: 'cptCodes',
                    },
                },
            ],
            editCols: [
                {
                    headerName: 'Code',
                    field: 'cptCode',
                    cellStyle: { cursor: 'pointer' },
                    sortable: true,
                    sort: 'asc',
                    sortingOrder: ['desc', 'asc'],
                },
                { headerName: 'Description', field: 'description', cellStyle: { cursor: 'pointer' } },
            ],
            readOnlyCols: [
                { headerName: 'Code', field: 'cptCode', width: 10 },
                { headerName: 'Description', field: 'description' },
                {
                    headerName: 'Actions',
                    field: 'actions',
                    type: 'rightAligned',
                    cellRenderer: ActionsCellRendererComponent,
                    cellRendererParams: {
                        delete: true,
                        gridName: 'cptCodes',
                    },
                },
            ],
        },
        [SEARCHABLE_LIST_NAMES.COMORBIDITY]: {
            recordCols: [
                { headerName: 'Code', field: 'code', width: 60, suppressMovable: true },
                { headerName: 'Description', field: 'description', width: 250, suppressMovable: true },
                { headerName: 'SLP Clinical Category', field: 'clinicalCategory', suppressMovable: true },
                { headerName: 'SLP comorbidity', field: 'comorbidity', suppressMovable: true },
                {
                    headerName: 'Actions',
                    field: 'actions',
                    type: 'rightAligned',
                    width: 100,
                    suppressMovable: true,
                    cellRenderer: ActionsCellRendererComponent,
                    cellRendererParams: {
                        delete: true,
                        gridName: 'comorbidity',
                    },
                },
            ],
            editCols: [
                {
                    headerName: 'Code',
                    field: 'code',
                    width: 60,
                    suppressMovable: true,
                    cellStyle: { cursor: 'pointer' },
                },
                {
                    headerName: 'Description',
                    field: 'description',
                    suppressMovable: true,
                    cellStyle: { cursor: 'pointer' },
                },
                {
                    headerName: 'SLP Clinical Category',
                    field: 'clinicalCategory',
                    suppressMovable: true,
                    cellStyle: { cursor: 'pointer' },
                },
                {
                    headerName: 'SLP comorbidity',
                    field: 'comorbidity',
                    suppressMovable: true,
                    cellStyle: { cursor: 'pointer' },
                },
            ],
            readOnlyCols: [{ headerName: 'Code', field: 'code', width: 10 }],
        },
    };

    paginate: { page: number; total: number } = { page: 1, total: 0 };
    searchedText = '';
    macId;

    // Row class rules to display
    rowClassRules: RowClassRules = {
        'bg-primary-50': function (params) {
            return (
                this.selectedData.findIndex(
                    (object) => object[this.getAttribute()] === params.data[this.getAttribute()]
                ) !== -1
            );
        }.bind(this),
        'text-secondary': function (params) {
            return (
                this.selectedData.findIndex(
                    (object) => object[this.getAttribute()] === params.data[this.getAttribute()]
                ) !== -1
            );
        }.bind(this),
    };

    gridNameMapping = {
        MedicalDiagnosis: SEARCHABLE_LIST_NAMES.MEDICAL_DIAGNOSIS,
        treatmentDiagnosis: SEARCHABLE_LIST_NAMES.TREATMENT_DIAGNOSIS,
        cptCodes: SEARCHABLE_LIST_NAMES.CPT_CODES,
        comorbidity: SEARCHABLE_LIST_NAMES.COMORBIDITY,
    };
    note: string;

    constructor(
        private clinicalCategoryService: ClinicalCategoriesService,
        private slpComorbidityService: SlpComorbidityService,
        private gridActionService: GridActionsService,
        private store: Store<{ therapyAdmission: TherapyAdmission[] }>,
        private route: ActivatedRoute
    ) {
        this.gridActionService.action.subscribe((params) => {
            if (
                params.actionType === 'delete' &&
                this.gridNameMapping[params.gridName] === this.name &&
                (params.gridName === 'MedicalDiagnosis' ||
                    params.gridName === 'treatmentDiagnosis' ||
                    params.gridName === 'cptCodes' ||
                    params.gridName === 'comorbidity')
            ) {
                this.deleteSelectedRowItem(params);
            }
        });

        this.route.queryParamMap.subscribe({
            next: (params) => {
                this.therapyDiscipline = params.get('discipline');
                this.note = params.get('note');
            },
        });

        this.therapyAdmissions$ = this.store.select('therapyAdmission');

        this.therapyAdmissions$.pipe(takeUntil(this.onDestroy)).subscribe(() => {
            if (this.isReadOnly) {
                this.setReadOnlyData();
            }
        });
        const currentFacility = JSON.parse(localStorage.getItem('current-facility'));
        if (currentFacility?.mailingAddress) {
            if (currentFacility.mailingAddress.macId?._id) {
                this.macId = currentFacility.mailingAddress.macId._id;
            } else if (currentFacility.mailingAddress.macId) {
                this.macId = currentFacility.mailingAddress.macId
            }
        }
    }

    getAttribute = () => {
        const attribute = this.name === SEARCHABLE_LIST_NAMES.CPT_CODES ? 'cptCode' : 'code';
        return attribute;
    };

    ngOnChanges(): void {
        // Setting vales
        if (this.recordsInput?.length > 0) {
            this.selectedData = this.recordsInput;

            if (this.name === SEARCHABLE_LIST_NAMES.MEDICAL_DIAGNOSIS) {
                this.markSelectedPrimaryDx(this.recordsInput);
            }

            this.data = this.recordsInput;
            this.state = 'one';
        }
        if (this.isReadOnly) {
            this.setReadOnlyData();
        }
    }

    markSelectedPrimaryDx(list) {
        if (list?.length > 0) {
            this.selectedData = list.map((mD) => {
                if (mD.isPrimary) {
                    return {
                        ...mD,
                        isSelected: true,
                    };
                }
                return {
                    ...mD,
                    isSelected: false,
                };
            });
            this.primaryDXMarked = list.some((x) => x.isPrimary);
            this.isPrimaryMarked.emit(this.primaryDXMarked);
            const found = this.cols[SEARCHABLE_LIST_NAMES.MEDICAL_DIAGNOSIS].recordCols.find(
                (x) => x.field === 'isPrimary'
            );
            if (found) {
                if (this.primaryDXMarked) {
                    found.headerClass = '';
                    found.headerName = DOCUMENTATION_CONSTANTS.PRIMARY_DX;
                } else {
                    found.headerClass = 'primary-dx-required';
                    found.headerName = DOCUMENTATION_CONSTANTS.PRIMARY_DX_REQUIRED;
                }
            }
            this.cols[SEARCHABLE_LIST_NAMES.MEDICAL_DIAGNOSIS].recordCols = [
                ...this.cols[SEARCHABLE_LIST_NAMES.MEDICAL_DIAGNOSIS].recordCols,
            ];
        }
    }

    setState(value: 'zero' | 'one') {
        this.state = value;

        this.isDone = false;
        this.data = this.recordsInput;
    }

    fetchMoreRecords(): void {
        this.recordsSearch(this.searchedText);
    }

    searched(text: string): void {
        this.resetEverything();
        this.recordsSearch(text);
    }

    recordsSearch(text: string) {
        this.loading = true;
        this[this.name + 'Search'](text).subscribe({
            next: ({ data, total }: any) => {
                this.paginate.page++;
                this.paginate.total = total;
                this.searchedText = text;

                if (this.isDone === false) {
                    this.data = [...this.data, ...data];

                    // Remove duplicates based on cptCode property
                    if (this.name === 'cptCodes') this.data = _.uniqBy(this.data, 'cptCode');
                }

                this.scrollToSeeMoreBtn();
                this.loading = false;
            },
            error: (err) => {
                console.log('ERR:: ', err);
                this.loading = false;
                this.slpComorbidityService.isLoading = false;
            },
        });
    }

    cptCodesSearch(text: string) {
        return this.clinicalCategoryService.cptCodesSearch(text, this.therapyDiscipline, this.paginate.page).pipe(
            map(({ data, total }: { data: any; total: number }) => {
                if (data && data.length) {
                    data = data.map((item: any) => ({
                        id: item.id,
                        cptCode: item.cptCode,
                        description: item.description,
                    }));
                } else if (!data && !total) {
                    data = [];
                    total = 0;
                }

                return {
                    data,
                    total,
                };
            })
        );
    }

    treatmentDiagnosisSearch(text) {
        return this.clinicalCategoryService
            .categorySearch(text, this.therapyDiscipline || this.discipline, this.macId, this.paginate.page)
            .pipe(
                map(({ data, total }: { data: any; total: number }) => {
                    if (data && data.length) {
                        data = data.map((item: any) => ({ code: item.code, description: item.description }));
                    } else if (!data && !total) {
                        data = [];
                        total = 0;
                    }

                    return {
                        data,
                        total,
                    };
                })
            );
    }
    comorbiditySearch(text) {
        return this.clinicalCategoryService.categorySearch(text, '', '', this.paginate.page, true).pipe(
            map(({ data, total }: { data: any; total: number }) => {
                if (data && data.length) {
                    data = data.map((item: any) => ({
                        _id: item._id,
                        code: item.code,
                        description: item.description,
                        clinicalCategory: item.clinicalCategory,
                        id: item._id,
                        comorbidity: item.comorbidity,
                    }));
                } else if (!data && !total) {
                    data = [];
                    total = 0;
                }

                return {
                    data,
                    total,
                };
            })
        );
    }

    medicalDiagnosisSearch(text: string) {
        let allSLPs: SLPComorbidity[] = [];

        return this.slpComorbidityService.getAllSLPs().pipe(
            take(1),
            switchMap((slps) => {
                allSLPs = slps;
                return this.clinicalCategoryService.categorySearch(text, '', '', this.paginate.page);
            }),
            switchMap(({ data: category, total }) => {
                let modCats = [];
                if (category && category.length) {
                    const cat = [...category];
                    modCats = cat.map((item) => {
                        const tempComorbidity = allSLPs.filter((slp) => {
                            return slp.code == item.code;
                        });
                        if (tempComorbidity?.length > 0) {
                            item.comorbidity = tempComorbidity[0].comorbidity;
                        } else {
                            item.comorbidity = 'N/A';
                        }
                        item.icd10 = item._id;
                        delete item._id;
                        return item;
                    });
                } else if (!category && !total) {
                    total = 0;
                }

                return of({ data: modCats, total });
            })
        );
    }

    // For Ag Grid
    // Working with clinical categories
    addSelectedItem(event: any) {
        const data = cloneDeep(event.data);

        const index = this.selectedData.findIndex(
            (object) => object[this.getAttribute()] === data[this.getAttribute()]
        );

        if (index === -1) {
            data.onsetDate = '';
            data.date = '';
            data.isPrimary = false;
            // this.selectedData.push(data);
            this.selectedData = [...this.selectedData, data];
        } else if (index >= 0) {
            this.selectedData = this.selectedData.filter(
                (item) => item[this.getAttribute()] !== data[this.getAttribute()]
            );
        }
        this.recordsEvent.emit(this.selectedData);
    }

    removeSelectedItem(data: any) {
        if (this.name === SEARCHABLE_LIST_NAMES.CPT_CODES) {
            this.selectedData = [...this.selectedData.filter((elem) => elem.cptCode !== data?.cptCode)];
        } else {
            this.selectedData = this.selectedData.filter((item) => !isEqual(item, data));
        }

        this.recordsEvent.emit(this.selectedData);
    }

    setLoading(value: boolean) {
        this.loading = value;
    }

    toggleIsDone(): void {
        this.resetEverything();
        this.isDone = !this.isDone;
        if (this.isDone === true) {
            this.gridApi.setColumnDefs(this.cols[this.name].recordCols);
        } else {
            this.data = this.recordsInput;
            this.gridApi.setColumnDefs(this.cols[this.name].editCols);
        }
        this.gridApi.sizeColumnsToFit();
        this.recordsEvent.emit(this.selectedData);
        if (this.isDone === true) {
            // this.recordsSave.emit(this.selectedData);
            // if user click save button without selection
            if (this.selectedData.length <= 0) {
                this.state = 'zero';
            } else {
                this.recordsSave.emit(this.selectedData);
            }
        }
    }

    // Grid API functions
    initGrid(event: any) {
        this.gridApi = event.api;
    }

    redrawRows() {
        this.gridApi.redrawRows();
    }

    // AG Grid actions for renderers
    setRendererRadio(param: any) {
        this.selectedData = this.selectedData.map((item) => {
            const deepItem = cloneDeep(item);

            const attribute = this.name === SEARCHABLE_LIST_NAMES.CPT_CODES ? 'cptCode' : 'code';

            if (deepItem[attribute] === param.data[attribute]) {
                deepItem.isPrimary = true;
            } else {
                deepItem.isPrimary = false;
            }
            return deepItem;
        });

        if (this.name === SEARCHABLE_LIST_NAMES.MEDICAL_DIAGNOSIS) {
            this.markSelectedPrimaryDx(this.selectedData);
        }

        this.recordsEvent.emit(this.selectedData);
        this.recordsSave.emit(this.selectedData);
    }

    setRendererDate(param: any) {
        this.selectedData = this.selectedData.map((item) => {
            const deepItem = cloneDeep(item);

            const attribute = this.name === SEARCHABLE_LIST_NAMES.CPT_CODES ? 'cptCode' : 'code';
            if (deepItem[attribute] === param.data[attribute]) {
                deepItem.onsetDate = param.value;
            }
            return deepItem;
        });
        this.recordsEvent.emit(this.selectedData);
        this.recordsSave.emit(this.selectedData);
    }

    deleteSelectedRowItem(params: GridRowAction) {
        const attribute = this.name === SEARCHABLE_LIST_NAMES.CPT_CODES ? 'cptCode' : 'code';
        this.selectedData = this.selectedData.filter((item) => item[attribute] != params.data[attribute]);
        this.recordsEvent.emit(_.cloneDeep(this.selectedData));
        this.recordsSave.emit(this.selectedData);
        if (this.name === SEARCHABLE_LIST_NAMES.MEDICAL_DIAGNOSIS) {
            this.markSelectedPrimaryDx(this.selectedData);
        }
        // if user click save button without selection
        if (this.selectedData.length <= 0) {
            this.state = 'zero';
        }
    }

    setReadOnlyData() {
        if (this.recordsData?.length > 0) {
            this.isDone = true;
            this.state = 'one';
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
    }

    dateFormatter(params) {
        const datePipe: DatePipe = new DatePipe('en-US');
        return params.value ? datePipe.transform(new Date(params.value), 'MM/dd/yyyy') : null;
    }

    scrollToBar(): void {
        setTimeout(() => {
            this.searchBar?.nativeElement?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
            this.focusEvent.next(true);
        }, 10);
    }

    scrollToSeeMoreBtn(): void {
        setTimeout(() => {
            if (this.seeMoreBtn?.nativeElement) {
                this.seeMoreBtn.nativeElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'end',
                });
            }
        }, 100);
    }

    resetEverything(): void {
        this.paginate.page = 1;
        this.paginate.total = 0;
        this.searchedText = '';
        this.data = [];
    }
}
