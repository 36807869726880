import { TherapyAdmission } from '@app/interfaces';
import { createAction, props } from '@ngrx/store';

export const plannerTherapyAdmissionsChangedAction = createAction(
    '[plannertherapyadmissions action] therapyadmission changed',
    props<{ therapyadmissions: TherapyAdmission[] }>()
);
export const plannerTherapyAdmissionsRemovedAction = createAction(
    '[plannertherapyadmissions action] therapyadmissions removed'
);
