<div
    class="innova-alert-{{ type }} {{
        className
    }} rounded-0 sticky-alert d-flex flex-row align-items-center justify-content-between "
    role="alert"
>
    <span class="d-flex flex-row align-items-center justify-content-between">
        <span class="material-icons {{ className }} align-text-top"> {{ icon }} </span>
        {{ message | titlecase }}
    </span>
</div>
