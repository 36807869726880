<div [class]="'tabs-container  d-flex flex-row w-100 ' + class" role="group" aria-label="Basic example">
    <button *ngIf="hasNavigation" class="medium-semi-bold4-5 tabs-nav-btn lt bg-white" (click)="previousTab()">
        <span class="material-icons-outlined text-white-100" style="vertical-align: bottom"> chevron_left </span>
    </button>
    <div id="tabs" class="d-inline-flex overflow-auto w-100">
        <div *ngFor="let button of buttons; index as i" class="w-100" [ngClass]="{ 'ml-2': i !== 0 }">
            <button
                type="button"
                [ngClass]="{
                    'selected-button': button.isSelected,
                    'unselected-button': !button.isSelected
                }"
                [class]="'medium-semi-bold4-5  ' + buttonClass"
                [disabled]="button.isDisabled"
                (click)="setSelectedButton(button.text)"
                [ngbTooltip]="button.tooltip"
                [placement]="button.toolTipPlacement"
            >
                <!-- <span [class.opacity-10]="_button?.toLowerCase() !== selectedButton?.toLowerCase()">{{ _button }}</span> -->
                <span
                    [ngbTooltip]="
                        button.text === 'Timecard Activities' && button.isDisabled ? 'This functionality is coming soon' : ''
                    "
                    placement="left"
                    class="nav-button-label"
                >
                    {{ button.text }}
                    <span
                        *ngIf="button.hasIndicator"
                        [ngClass]="button.isSelected ? 'bg-white' : 'bg-red'"
                        class="p-notification-badge position-absolute top-0 start-100 translate-middle border border-light rounded-circle"
                    ></span>
                    <span
                        *ngIf="button.count >= 0"
                        [ngClass]="button.isSelected ? 'count-selected' : 'count-unselected'"
                        >&nbsp;&nbsp;&nbsp;({{ button.count }})</span
                    ></span
                >
            </button>
        </div>
    </div>
    <button *ngIf="hasNavigation" class="medium-semi-bold4-5 tabs-nav-btn rt bg-white" (click)="nextTab()">
        <span class="material-icons-outlined text-white-100" style="vertical-align: bottom"> chevron_right </span>
    </button>
</div>
