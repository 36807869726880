import { TherapyDiscipline } from '@app/interfaces';
import { getNonProjected } from './../../helpers/utils/charts/goals.utils';
import { ChartGoal } from './../../interfaces/charts';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getNonProjected',
    pure: true,
})
export class GetNonProjectedPipe implements PipeTransform {
    transform(goals: ChartGoal[], discipline?: TherapyDiscipline) {
        return getNonProjected(goals, discipline);
    }
}
