/* eslint-disable @typescript-eslint/no-empty-function */
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    ViewChild,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { FacilityManagementService, PatientService, StoreService } from '@app/services';
import { PatientState } from '@app/store/store.interface';
import { Store } from '@ngrx/store';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    finalize,
    fromEvent,
    Observable,
    Subject,
    take,
    takeUntil,
    tap,
} from 'rxjs';
import { ToasterService } from '@app/services/toaster.service';
import { FacilityAdmission } from '@app/interfaces';
import { cloneDeep } from 'lodash';
import { IMaskDirective } from 'angular-imask';

@Component({
    selector: 'app-input-dropdown',
    templateUrl: './input-dropdown.component.html',
    styleUrls: ['./input-dropdown.component.scss'],
})
export class InputDropdownComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
    @ViewChild(IMaskDirective, { static: false }) iMask: IMaskDirective<IMask.MaskedNumber>;
    @Input() label: string;
    @Input() placeholder = '';
    @Input() type: 'text' | 'number' | 'password' = 'text';
    @Input() mask = new RegExp('^[a-zA-Z0-9_ ]*$');
    @Input() tooltip = false;
    @Output() checkSSN: EventEmitter<boolean> = new EventEmitter();
    @ViewChild('search') search: ElementRef;
    expandedResults = false;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    propagateChange = (_: any) => {};
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onTouched = (_: any) => {};

    value: any;
    @Input() disabled: boolean;

    patientSearchResults: PatientState[] = [];
    showResults: boolean;
    facilityAdmission$: Observable<FacilityAdmission>;
    facilityAdmission: FacilityAdmission;
    errorMsg = '';
    apiResponse = null;

    private readonly onDestroy = new Subject<void>();
    constructor(
        public readonly ngControl: NgControl,
        private patientService: PatientService,
        private router: Router,
        private storeService: StoreService,
        private toasterService: ToasterService,
        private store: Store<{
            facilityAdmission: FacilityAdmission;
        }>,
        private facilityManagementService: FacilityManagementService
    ) {
        ngControl.valueAccessor = this;
        this.facilityAdmission$ = this.store.select('facilityAdmission');
        this.facilityAdmission$.pipe(takeUntil(this.onDestroy)).subscribe((data) => {
            this.facilityAdmission = cloneDeep(data);
        });
    }

    ngAfterViewInit(): void {
        if (this.search && this.search.nativeElement)
            fromEvent(this.search?.nativeElement, 'keyup')
                .pipe(
                    filter(Boolean),
                    debounceTime(500),
                    distinctUntilChanged(),
                    tap(() => {
                        const control: AbstractControl = this.ngControl.control;
                        if (this.mask.toString() == '000-00-0000' && control && control.value.toString().length == 11) {
                            this.patientService.checkPatientSSN(control.value).subscribe((val: boolean) => {
                                this.checkSSN.emit(val);
                            });
                        } else {
                            this.checkSSN.emit(false);
                        }
                        this.searchPatient(this.search?.nativeElement?.value);
                    })
                )
                .subscribe();
    }
    onFocus() {
        this.iMask.maskRef.updateValue();
    }
    expandResults() {
        this.expandedResults = !this.expandedResults;
    }
    searchPatient(queryString) {
        if (queryString.length > 0) {
            this.patientService.searchPatient(queryString).subscribe({
                next: (data) => {
                    this.apiResponse = data;

                    queryString;
                    //this.patientSearchResults =
                    const activePatients = [];
                    const dischargedPatients = [];
                    const patientsWithoutActiveAndDischargedDates = [];
                    data.forEach((item: any) => {
                        if (this.activeFacilityAdmissionsAdmitDate(item)) {
                            return activePatients.push(item);
                        } else if (
                            JSON.stringify(this.patientLastDischargeDate(item)) !== JSON.stringify(new Date('invalid'))
                        ) {
                            return dischargedPatients.push(item);
                        } else {
                            return patientsWithoutActiveAndDischargedDates.push(item);
                        }
                    });
                    this.patientSearchResults = [
                        ...activePatients,
                        ...dischargedPatients,
                        ...patientsWithoutActiveAndDischargedDates,
                    ];
                },
                error: (err) => {
                    // this.patientSearchResults = [];
                    console.log(err)
                }
            });
        } else {
            this.patientSearchResults = [];
        }
    }
    getPatient(id) {
        this.patientService.searchPatientResponse = this.apiResponse;
        this.patientService.setSelectedPatientData(id);
        this.patientSearchResults = [];
        this.storeService.setLoadingState(true);

        // if (ssn.length == 11) {
        this.storeService
            .initializePatientData(id, this.facilityManagementService._currentFacility.id)
            .pipe(
                take(1),
                finalize(() => {
                    this.storeService.setLoadingState(false);
                })
            )
            .subscribe(
                (data) => {
                    if (data) {
                        this.toasterService.show({
                            title: 'Success',
                            body: 'Patient Selected',
                            type: 'success',
                        });
                        this.checkSSN.next(false)
                        //this.alertService.success('Patient Selected');
                        // if (this.router.url.split('/')[1] == ROUTER_UTILS.config.addPatients.root) {
                        //     this.router.navigate([ROUTER_UTILS.config.addPatients.editAdmission], {
                        //         skipLocationChange: true,
                        //     });
                        //     this.storeService.admissionStepper = ROUTER_UTILS.config.addPatients.personalInformation;
                        // }
                    }
                },
                (err) => {
                    // this.alertService.error(err);
                    this.toasterService.show({
                        title: 'Error',
                        body: err,
                        type: 'error',
                    });
                }
            );
        // }
    }
    clearResults() {
        this.patientSearchResults = [];
        this.expandedResults = false;
    }
    setShowResults(value: boolean) {
        this.showResults = value;
    }

    get isRequired(): boolean {
        const control: AbstractControl = this.ngControl.control;
        // console.log(control.errors)
        if (control.validator === null) return false;
        return control.validator(control)?.required ?? false;
    }

    get hasError(): boolean {
        const control: AbstractControl = this.ngControl.control;
        const controlErrors: ValidationErrors = control.errors;

        if (control && control.touched && control.invalid) {
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach((keyError) => {
                    // console.log('Key control: ' + this.ngControl.name + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                    switch (keyError) {
                        case 'required':
                            this.errorMsg = `Field is required!`;
                            break;
                        case 'pattern':
                            this.errorMsg = `Field has wrong pattern!`;
                            break;
                        case 'email':
                            this.errorMsg = `Wrong email format!`;
                            break;
                        case 'minlength':
                            if (this.ngControl.name === 'SSN') {
                                this.errorMsg = `Min length required is ${controlErrors[keyError].requiredLength - 2}`;
                            } else {
                                this.errorMsg = `Min length required is ${controlErrors[keyError].requiredLength}`;
                            }
                            break;
                        case 'maxlength':
                            this.errorMsg = `Max length required is ${controlErrors[keyError].requiredLength}`;
                            break;
                        default:
                            this.errorMsg = `${this.ngControl.name}: ${keyError}: ${controlErrors[keyError]}`;
                    }
                });
            }
            return true;
        }
        return false;
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    onChange(event: any): void {
        this.propagateChange(event.target.value);
    }

    onBlur(): void {
        this.ngControl.control.markAsTouched();
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
    disableCheck(res) {
        if (res.facilityAdmissions && res.facilityAdmissions.length) {
            const facilityAdmissions = res.facilityAdmissions.filter(
                (x) => x.facility == this.facilityManagementService._currentFacility.id
            );
            let patientDischarge = true;
            for (const facilityAdmission of facilityAdmissions) {
                if (
                    !(
                        facilityAdmission &&
                        facilityAdmission.patientDischarge &&
                        facilityAdmission.patientDischarge.facilityDischargeDate
                    )
                ) {
                    patientDischarge = false;
                }
            }

            return patientDischarge;
        }
        return false;
    }
    patientHaveSameFacility(res) {
        let isPatientHaveSameFacility = false;
        if (res.facilityAdmissions && res.facilityAdmissions.length) {
            const facilityAdmissions = res.facilityAdmissions.filter(
                (x) => x.facility == this.facilityManagementService._currentFacility.id
            );
            if (Array.isArray(facilityAdmissions) && facilityAdmissions.length) {
                isPatientHaveSameFacility = true;
            }
        }
        return isPatientHaveSameFacility;
    }
    patientHaveActiveFacilityAdmissions(res) {
        let isPatientHaveActiveFacilityAdmissions = false;
        if (res.facilityAdmissions && res.facilityAdmissions.length) {
            const facilityAdmissions = res.facilityAdmissions.filter(
                (x) => x.facility == this.facilityManagementService._currentFacility.id
            );
            if (Array.isArray(facilityAdmissions) && facilityAdmissions.length) {
                for (const facilityAdmission of facilityAdmissions) {
                    if (
                        !(
                            facilityAdmission &&
                            facilityAdmission.patientDischarge &&
                            facilityAdmission.patientDischarge.facilityDischargeDate
                        )
                    ) {
                        isPatientHaveActiveFacilityAdmissions = true;
                    }
                }
            }
        }
        return isPatientHaveActiveFacilityAdmissions;
    }
    patientLastDischargeDate(res) {
        const facilityDischargeDates = [];

        if (res.facilityAdmissions && res.facilityAdmissions.length) {
            const facilityAdmissions = res.facilityAdmissions.filter(
                (x) => x.facility == this.facilityManagementService._currentFacility.id
            );
            if (Array.isArray(facilityAdmissions) && facilityAdmissions.length) {
                for (const facilityAdmission of facilityAdmissions) {
                    if (
                        facilityAdmission &&
                        facilityAdmission.patientDischarge &&
                        facilityAdmission.patientDischarge.facilityDischargeDate
                    ) {
                        facilityDischargeDates.push(new Date(facilityAdmission.patientDischarge.facilityDischargeDate));
                    }
                }
            }
        }
        return new Date(
            Math.max.apply(
                null,
                facilityDischargeDates.map(function (facilityDischargeDate) {
                    return new Date(facilityDischargeDate);
                })
            )
        );
    }
    activeFacilityAdmissionsAdmitDate(res) {
        if (res.facilityAdmissions && res.facilityAdmissions.length) {
            const facilityAdmissions = res.facilityAdmissions.filter(
                (x) => x.facility == this.facilityManagementService._currentFacility.id
            );
            if (Array.isArray(facilityAdmissions) && facilityAdmissions.length) {
                for (const facilityAdmission of facilityAdmissions) {
                    if (
                        !(
                            facilityAdmission &&
                            facilityAdmission.patientDischarge &&
                            facilityAdmission.patientDischarge.facilityDischargeDate
                        )
                    ) {
                        return new Date(facilityAdmission.admitDate);
                    }
                }
            }
        }
    }
    ngOnDestroy() {
        this.onDestroy.next();
    }
}
