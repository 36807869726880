<div *ngIf="!isReadonly">
    <app-dynamic-content-card
        [headerActionItems]="headerActionItems"
        [inputBottomActionItems]="inputBottomActionItems"
        [actions]="actions"
        noDataView="NO_CONTENT"
        [noDataTargetView]="views[2]"
        [view]="view"
        [heading]="heading"
        (emitViewChangeEvent)="setView($event)"
        [hasData]="rowData.length > 0"
        [view]="rowData.length > 0 ? 'CONTENT' : 'NO_CONTENT'"
    >
        <div class="mt-2"></div>
        <app-control-widget
            *ngIf="view === 'ADD_MORE'"
            [controls]="controls"
            (onEmitData)="controlWidgetHandler($event)"
            [checkFill]="checkFill"
            (canSave)="setCanSave($event)"
            [dataForEdit]="dataForEdit"
        ></app-control-widget>
        <!-- actions to who below input -->
        <ng-container *ngIf="view === 'ADD_MORE'">
            <div class="d-flex flex-row w-100 justify-content-end card-padding-x" style="margin-left: auto; gap: 0.5em">
                <ng-container *ngFor="let item of inputBottomActionItems">
                    <app-button
                        *ngIf="item.payload?.views.includes(view) || item.hasHeaderItem"
                        (click)="btnClicked(item)"
                        [text]="item.label"
                        [icon]="item.icon ? item.icon : ''"
                        [rightIcon]="item.rightIcon ? item.rightIcon : false"
                        [class]="item.class"
                        [iconClass]="item.iconClass ? item.iconClass : ''"
                        [iconStyle]="item.iconStyle ? item.iconStyle : ''"
                        [style]="item.style ? item.style : ''"
                        [innovaBtn]="item.innovaBtn"
                        [innovaBtnLayout]="item.innovaBtnLayout"
                        [disabled]="item.label.toLowerCase() === saveButton ? !canSave : false"
                    ></app-button>
                </ng-container>
            </div>
        </ng-container>
        <div class="mt-3"></div>
        <div #dataGrid>
            <!-- This -->
            <app-data-grid
                *ngIf="view === 'CONTENT' || view === 'ADD_MORE'"
                [columnDefs]="columnDefs"
                [rowData]="rowData"
                (gridReady)="gridReady($event)"
            ></app-data-grid>
        </div>
    </app-dynamic-content-card>
</div>

<div *ngIf="isReadonly">
    <app-card>
        <div *ngIf="heading" class="p-3 text-secondary">
            <!-- Header -->
            <div class="d-flex body-medium-semibold body-medium-semibold">
                <div>
                    <span>{{ heading }}</span>
                </div>
            </div>
        </div>
        <div class="body col-12">
            <div #dataGrid>
                <!-- This -->
                <app-data-grid
                    *ngIf="view === 'CONTENT' || view === 'ADD_MORE'"
                    [columnDefs]="columnDefs"
                    [rowData]="rowData"
                    (gridReady)="gridReady($event)"
                ></app-data-grid>
            </div>
        </div>
    </app-card>
</div>
