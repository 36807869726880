import { Component, Inject, OnInit } from '@angular/core';
import { DIALOG_DATA } from '@app/helpers/dialog-tokens';
import { DialogRef } from '@app/services';

@Component({
    selector: 'app-document-reject-dialog',
    templateUrl: './document-reject-dialog.component.html',
    styleUrls: ['./document-reject-dialog.component.scss'],
})
export class DocumentRejectDialogComponent implements OnInit {
    heading = '';
    message = '';
    iconName = '';
    iconSize = '';
    primaryButtonText = 'Yes-Reject';
    primaryButtonClass = 'btn-secondary';
    secondaryButtonText = 'No-Cancel';
    secondaryButtonClass = 'btn-outline-secondary';

    iconTitle = {
        delete: 'delete',
        circleCross: 'circleCross',
        warning: 'warning',
        check: 'checkedSuccess',
    };

    constructor(@Inject(DIALOG_DATA) private data, private dialogRef: DialogRef) {}

    ngOnInit(): void {
        this.heading = this.data?.heading;
        this.message = this.data?.message;
        this.iconName = this.data?.iconName;
        this.iconSize = this.data?.iconSize || this.iconSize;
        this.primaryButtonText = this.data?.primaryButtonText || this.primaryButtonText;
        this.primaryButtonClass = this.data?.primaryButtonClass || this.primaryButtonClass;
        this.secondaryButtonText = this.data?.secondaryButtonText || this.secondaryButtonText;
        this.secondaryButtonClass = this.data?.secondaryButtonClass || this.secondaryButtonClass;
    }

    closeAction(isConfirmed = false) {
        this.dialogRef.close(isConfirmed);
    }
}
