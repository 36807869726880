import { Component, Inject } from '@angular/core';
import { DIALOG_DATA } from '@app/helpers/dialog-tokens';
import { DialogRef } from '@app/services';

@Component({
    selector: 'app-assessment-goals-deletion-modal',
    templateUrl: './assessment-goals-deletion-modal.component.html',
    styleUrls: ['./assessment-goals-deletion-modal.component.scss'],
})
export class AssessmentGoalsDeletionModalComponent {
    options = [{ text: 'Discontinue short term goals as well.', isChecked: false }];
    deleteOption = [];
    reasonForDiscontinuing = '';
    constructor(@Inject(DIALOG_DATA) public data, private ref: DialogRef) {}

    onClose(result?) {
        this.ref.close(result);
    }
    onDone() {
        this.onClose({
            discontinueStgs: this.deleteOption.some((v) => v.isChecked),
            reasonForDiscontinuing: this.reasonForDiscontinuing,
        });
    }
}
