<div *ngIf="!isVertical" class="form-group">
    <label class="d-block">{{ label | translate }}</label>
    <div class="d-flex flex-row ml-1">
        <div
            class="d-flex align-items-center innova-radio mr-3 rounded-curve {{ contentAlign }}"
            *ngFor="let option of radioOptions; let i = index"
            [ngClass]="backgroundClass ? backgroundClass : ''"
        >
            <input
                class="radio-select"
                type="radio"
                id="{{ label + option }}"
                name="{{ label + option }}"
                value="{{ option | translate }}"
                [(ngModel)]="value"
                [disabled]="disabled"
                (change)="radioSelect(this)"
                style="background-color: transparent !important"
            />
            <label for="{{ label + option }}" class="radio-label ml-2 pt-2 body-small">{{ option | translate }}</label>
        </div>
    </div>
</div>

<div *ngIf="isVertical" class="form-group">
    <label class="ml-0">{{ label | translate }}</label>
    <div class="d-flex mb-1" *ngFor="let option of radioOptions; let i = index">
        <div>
            <input
                type="radio"
                id="{{ label + option + id }}"
                name="{{ label + option + id }}"
                value="{{ option | translate }}"
                [(ngModel)]="value"
                [disabled]="disabled"
                (change)="radioSelect(this)"
            />
        </div>
        <label for="{{ label + option + id }}" class="radio-label ml-2 body-medium">{{ option | translate }}</label>
    </div>
</div>
