import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { GridRowAction } from '@app/data-grid/interfaces/grid-row-action';
import { GridActionsService } from '@app/data-grid/services/grid-actions.service';
import { InputConfiguration, MedicalDiagnosis } from '@app/interfaces';
import { ClinicalCategoriesService, SlpComorbidityService } from '@app/services';
import { SLPComorbidity } from '@app/store/store.interface';

@Component({
    selector: 'app-control-widget',
    templateUrl: './control-widget.component.html',
    styleUrls: ['./control-widget.component.scss'],
})
export class ControlWidgetComponent implements OnChanges, OnInit {
    @Input() controls: InputConfiguration[];
    @Input() class: string;
    @Input() contentClass: string;
    @Output() onEmitData: any = new EventEmitter<any>();
    @Output() canSave: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() dataInput: any;
    @Input() highlightTexts = [];
    @Input() textBoxFilled = false;
    @Input() checkFill = false;
    @Input() dataForEdit: any;
    data: any = {};
    loading: boolean;
    allSLPs: SLPComorbidity[] = [];
    selectedData: any[] = [];
    @Output() medicalDiagnosisEvent: EventEmitter<MedicalDiagnosis[]> = new EventEmitter<MedicalDiagnosis[]>();
    @Output() frequencyFromEvent: any = new EventEmitter<any>();
    @Output() durationEvent: any = new EventEmitter<any>();
    constructor(
        private clinicalCategoryService: ClinicalCategoriesService,
        private slpComorbidityService: SlpComorbidityService,
        private gridActionService: GridActionsService
    ) {
        gridActionService.action.subscribe((params) => {
            this.deleteSelectedRow(params);
        });
    }
    // clinicalCategoriesSearch(text: string) {
    //   this.loading = true;
    //   this.clinicalCategoryService.categorySearch(text).subscribe((data) => {
    //     this.loading = false;
    //     let cat = [...data];
    //     this.data = cat.map((item) => {
    //       if (this.allSLPs.length > 0) {
    //         let tempCormob = this.allSLPs.filter((slp) => slp.code == item.code);
    //         if (tempCormob?.length > 0) {
    //           item.comorbidity = tempCormob[0].comorbidity;
    //         } else {
    //           item.comorbidity = 'N/A';
    //         }
    //       }

    //       return item;
    //     });
    //   });
    // }
    deleteSelectedRow(param: GridRowAction) {
        this.selectedData = this.selectedData.filter((item) => item.code !== param.data.code);
        this.medicalDiagnosisEvent.emit(this.selectedData);
    }
    ngOnChanges(): void {
        if (this.dataInput !== undefined) this.data = this.dataInput;
    }
    ngOnInit(): void {
        if (this.controls) this.data = this.prepareWidgetData(this.controls);
        if (this.dataInput !== undefined) this.data = this.dataInput;
    }
    setData(value: string, key: string, parentKey: string = null) {
        if (parentKey === null) {
            this.data[key] = value;
        } else {
            this.data[parentKey][key] = value;
        }
        // for frequency From  changed
        if (key == 'frequencyFrom') this.frequencyFromEvent.emit(value);
        //  when duration or duration type is changed
        if (key === 'durationType' || key === 'duration') {
            this.durationEvent.emit({ [key]: value });
        }
    }
    emitData() {
        if (this.checkFill === true && this.textBoxFilled === false) return;
        else this.onEmitData.emit(this.data);
    }
    prepareWidgetData(data: any[]) {
        const prepared = {};
        data.forEach((item) => {
            if (item.type === 'merge') {
                prepared[item.key] = {};
                item.merge.forEach((mergeItem) => {
                    if (mergeItem.type !== 'label') {
                        mergeItem.defaultValue === undefined
                            ? (prepared[item.key][mergeItem.key] = '')
                            : (prepared[item.key][mergeItem.key] = mergeItem.defaultValue);
                    }
                });
            } else {
                item.defaultValue === undefined ? (prepared[item.key] = '') : (prepared[item.key] = item.defaultValue);
            }
        });
        return prepared;
    }
    setTextBoxFilled(value: boolean) {
        this.textBoxFilled = value;
    this.canSave.emit(this.textBoxFilled);
    }
    checkDisabled(items, parentKey: string) {
        const isDisabledArray = items.map(() => true);

        for (let i = 0; i <= items.length; i++) {
            const key = items[i];
            if (key) {
                if (this.data[key]) {
                    isDisabledArray[i] = false;
                } else {
                    if (this.data[parentKey][key] && this.data[parentKey][key] !== '') {
                        isDisabledArray[i] = false;
                    }
                }
            }
        }
        const isDisabled = isDisabledArray.filter((item) => item === true);
        if (isDisabled.length > 0) return true;
        else return false;
        // }
    }
}
