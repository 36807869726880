<div class="d-flex combined-facilities flex-row mt-3">
    <div class="col-6 facilities">
        <div class="custom-card">
            <div class="header d-flex justify-content-between">
                <p>Select Facilities</p>
                <div class="d-flex flex-row pl-0 pr-0 justify-content-around align-self-center">
                    <div
                        class="body-medium text-secondary-300 font-weight-bold fw-600 ml-2 align-self-center"
                        style="width: 6vw"
                    >
                        Customer
                    </div>
    
                    <select
                        class="ml-2 cursor-pointer border border-1 filter-select form-control sm"
                        (change)="updateFilter($event.target.value)"
                        style="width: 8vw"
                    >
                    <option value="ALL">All</option>
                    <option *ngFor="let profile of profiles" [value]="profile.profileName">
                        {{ profile.profileName }}
                    </option>
                    </select>
                </div>
            </div>
            <div class="body">
                <app-search-bar type="multi" (searchEvent)="facilitySearch($event)" (removeEvent)="redrawRows($event)" [queryLength]="2"
                    placeholder="Search with facility name and customer name" id="facility-search-for-recipient"
                    style="width: 98%; display: block; margin: 0 auto"></app-search-bar>
                <div class="facilities-list" style="height: calc(100vh - 520px)">
                    <app-data-grid *ngIf="!isLoading"  [columnDefs]="facilityCols" [rowData]="facilityList" [showCount]="false"
                        rowSelection="single" (rowClicked)="facilitySelectionChanged($event)"
                        (selectedRowsEmitter)="rowSelection($event)" (gridReady)="onGridReady($event)">
                    </app-data-grid>

                    <div *ngIf="isLoading" class="d-flex justify-content-center align-items-center assigned-facility-loader">
                        <span style="color:f4633a;" class="spinner-border text-primary"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-6 selected-facilities">
        <div class="custom-card h-100">
            <div class="header">
                <p>Assigned Facilities</p>
            </div>
            <div class="body pt-3">
                <div class="assigned-facility-section" style="height: calc(100vh - 470px)">
                    <div  *ngIf="showClearAll()" class="d-flex justify-content-end">
                        <div class="d-flex align-items-center" (click)="removeAll()">
                            <span class="font-16 text-secondary  mr-2 clear-all-button">Clear All</span>
                        </div>
                    </div>
                    <div  *ngIf="!showClearAll() && !isLoading" class="d-flex justify-content-center">
                        <div class="d-flex align-items-center" >
                            <span class="font-16 text-secondary  d-flex align-items-center  mr-2 no-facility">No Facility Selected</span>
                        </div>
                    </div>
                    <!-- *ngIf="selectedFacilities.length > 0" -->
                    <div  class="px-3 bg-white">


                        <div class="overflow-auto" style="max-height: calc(100vh - 514px)">

                            <ng-container *ngIf="!isLoading">
                            <div *ngFor="let profile of getKeys(); let profileIdx = index">
                                <span class="selected-facility">{{ profile === '--' ? 'No Customer Profiles assigned' :
                                    profile }}</span>
                                <br />

                                <span *ngFor="let facility of profilesFacilityMapper[profile]; let facilityIdx = index"
                                    class="badge rounded-pill rounded-pill-bg pl-3 pr-2 py-2 color-secondary-300 mr-2 mb-2 mt-2">
                                    {{ facility }}
                                    <span (click)="removeSelectedFacility(facility, profileIdx, facilityIdx, profile)"
                                        class="material-symbols-outlined font-16 align-middle cursor-pointer">close</span>
                                </span>

                            </div>
                        </ng-container>
                            <div *ngIf="isLoading" class="d-flex justify-content-center align-items-center facility-loader">
                                <span style="color:f4633a;" class="spinner-border text-primary"></span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>