<div class="custom-card mb-4">
    <div class="header d-flex flex-row justify-content-between align-items-center" [ngClass]="{'border-radius-12': appointment.notes.length <= 0}">
        <p>
            {{ day | titlecase }}
            <span class="font-weight-normal ml-4">
                {{ appointment.startTime | date: 'h:mm a' }}
                <span *ngIf="appointment.startTime"> - </span>
                {{ appointment.endTime | date: 'h:mm a' }}
            </span>
        </p>
        
        <div ngbDropdown placement="bottom-right" class="d-inline-block ml-3">
            <button
                class="innova-btn btn-secondary btn-sm d-flex flex-row justify-content-center align-items-center p-1 btn-circle"
                [disabled]="disabled"
                id="dd-toggle"
                placement="left"
                ngbDropdownToggle
            >
                <span class="material-symbols-outlined"> menu </span>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="font-14 poppins">
                <button
                    ngbDropdownItem
                    [disabled]="false"
                    class="nav-item nav-link"
                    (click)="editDayNote()"
                >
                    Edit
                </button>
                <button
                    ngbDropdownItem
                    [disabled]="false"
                    class="nav-item nav-link text-danger"
                    (click)="deleteDayNote()"
                >
                    Delete
                </button>
            </div>
        </div>

    </div>
    <div class="body bg-body-background text-secondary" [ngClass]="{'p-3': appointment.notes.length > 0}">
        {{ appointment.notes }}
    </div>
</div>
