import { Pipe, PipeTransform } from '@angular/core';
import { TherapyAdmission } from '@app/interfaces';

@Pipe({
    name: 'checkTherapyAdmissionForDiscipline',
})
export class CheckTherapyAdmissionForDisciplinePipe implements PipeTransform {
    transform(value: TherapyAdmission[], ...args: unknown[]): unknown {
        const result = value?.filter((admission) => admission.therapyDiscipline == args[0]).length;
        if (result > 0) return false;
        else return true;
    }
}
