import { Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { DocumentationReportService, PrintService, ProgressNoteService } from '@app/services';
import html2canvas from 'html2canvas';
// eslint-disable-next-line spellcheck/spell-checker
import * as html2pdf from 'html2pdf.js';

@Component({
    selector: 'app-progress-note-report',
    templateUrl: './progress-note-report.component.html',
    styleUrls: ['./progress-note-report.component.scss'],
})
export class ProgressNoteReportComponent implements OnInit {
    @ViewChild('header') reportHeader: ElementRef;
    @Input() reportId: string;
    @Input() report: any;
    isDataLoaded: boolean;
    toExport: boolean;
    withEdits = false;

    constructor(
        private docReportService: DocumentationReportService,
        private progressNoteService: ProgressNoteService,
        private ngZone: NgZone,
        private printService: PrintService
    ) {}

    async ngOnInit(): Promise<void> {
        this.withEdits = this.printService.getWithEdits();
        if (this.reportId && !this.report) {
            this.toExport = false;
            this.isDataLoaded = await this.docReportService.prepareProgressNoteData(this.reportId);
            if (this.isDataLoaded) {
                setTimeout(() => {
                    this.docReportService.countLoadedNotes();
                }, 0);
            }
        } else {
            this.toExport = true;
            this.isDataLoaded = await this.docReportService.prepareProgressNoteDataForIntegration(this.report);
            setTimeout(async () => {
                const element = document.getElementById('progressNoteReport');
                this.ngZone.runOutsideAngular(() => {
                    if (element) {
                        let PDFOptions;
                        if (window.innerWidth <= 1600) {
                            PDFOptions = {
                                margin: [30, 10, 20, 10],
                                enableLinks: false,
                                filename: 'fileName',
                                // eslint-disable-next-line spellcheck/spell-checker
                                image: { type: 'jpeg', quality: 0.3 },
                                html2canvas: {
                                    scale: 4,
                                },
                                jsPDF: { unit: 'mm', format: 'letter', orientation: 'portrait' },
                            };
                        } else {
                            PDFOptions = {
                                margin: [30, 10, 20, 10],
                                filename: 'fileName',
                                enableLinks: false,
                                // eslint-disable-next-line spellcheck/spell-checker
                                image: { type: 'jpeg', quality: 0.3 },
                                html2canvas: {
                                    scale: 4,
                                    width: 760,
                                },
                                jsPDF: { unit: 'mm', format: 'letter', orientation: 'portrait' },
                            };
                        }

                        html2canvas(document.getElementById('header'), { scale: 2 }).then((canvas) => {
                            const imgData = canvas.toDataURL('image/png');
                            this.reportHeader.nativeElement.style.display = 'none';
                            // eslint-disable-next-line spellcheck/spell-checker
                            html2pdf()
                                .from(element)
                                .set(PDFOptions)
                                // eslint-disable-next-line spellcheck/spell-checker
                                .toPdf()
                                // eslint-disable-next-line spellcheck/spell-checker
                                .get('pdf')
                                .then((PDF) => {
                                    const totalPages = PDF.internal.getNumberOfPages();
                                    for (let i = 1; i <= totalPages; i++) {
                                        PDF.addImage(imgData, 'PNG', 0, 7, 250, 0);
                                        PDF.setPage(i);
                                        PDF.setFontSize(10);
                                        PDF.text(
                                            'Page ' + i + ' of ' + totalPages,
                                            PDF.internal.pageSize.getWidth() - 30,
                                            PDF.internal.pageSize.getHeight() - 1
                                        );
                                    }
                                    const PDFData = PDF.output();
                                    // Convert the raw data to a Base64 string
                                    const base64String = btoa(PDFData);
                                    this.progressNoteService.setProgressNotePDF(base64String);
                                    window.history.back();
                                    this.progressNoteService.loadedProgressNote.next(1);
                                });
                        });
                    }
                });
            }, 10);
        }
    }
}
