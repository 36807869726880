import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'app-dropdown-pill',
  templateUrl: './dropdown-pill.component.html',
  styleUrls: ['./dropdown-pill.component.scss']
})
export class DropdownPillComponent{
  isDropdownOpen = false;
  @Input() pillStatus = '';
  @Input() selectedPill= '';
  @Output() selectedPillChange = new EventEmitter<any>();
  @Input() options: any[] = []; 
  @Input() disabled = false;
  statusEnum = {On: 'On', Off: 'Off'}
  isToggled = false;
  constructor(private elRef: ElementRef) { }


  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
    this.isToggled = true;
  }
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if(!this.isToggled && this.isDropdownOpen){ 
    const clickedInside = this.elRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.isDropdownOpen = false;
    }
  }
    this.isToggled = false;
}

  selectPill(option: any) {
    this.selectedPillChange.emit(option);
    this.isDropdownOpen = false;
  }
}