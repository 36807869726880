import { Pipe, PipeTransform } from '@angular/core';
import { toDateString } from '@app/helpers/utils/date-to-string.utils';

@Pipe({
    name: 'getAdmissionDates',
})
export class GetAdmissionDatesPipe implements PipeTransform {
    transform(patientState: any, ...args: unknown[]): unknown {
        let result = patientState.facilityAdmission.therapyAdmissions.filter(
            (admission) => admission.therapyDiscipline == args[0]
        )[0].planner?.effectiveFrom;

        if (result !== undefined) {
            result = new Date(result);
            return toDateString(
                {
                    year: result.getFullYear(),
                    month: result.getMonth(),
                    day: result.getDate(),
                },
                'MM-dd-yyyy'
            );
        } else return 'N/A';
    }
}
