import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {
    AppService,
    AuthService,
    FacilityManagementService,
    OrganizationManagementService,
    PatientService,
    SocketService,
    StoreService,
} from '@app/services';
import { Account, Role } from './models';
import { TranslateService } from '@ngx-translate/core';
import { filter, finalize, fromEvent, take } from 'rxjs';
import { Layout } from '@app/interfaces/layout';
import { ToasterService } from '@app/services';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { PatientState } from './store/store.interface';
import { ROUTER_UTILS } from './helpers';
import { ProductFruitsService } from './services/product-fruits.service';

@Component({ selector: 'app', templateUrl: 'app.component.html', styleUrls: ['./app.component.scss'] })
export class AppComponent implements OnInit, OnDestroy {
    previousUrl: string = null;
    currentUrl: string = null;
    patients = '/' + ROUTER_UTILS.config.patients.root;
    patientsListUrl = this.patients + '/' + ROUTER_UTILS.config.patients.patientList;
    Role = Role;
    account: Account;
    layout: Layout;
    loadingObs: any;
    patientData$: any;
    skeletonLoader = false;
    constructor(
        private patientService: PatientService,
        private facilityManagementService: FacilityManagementService,
        private AuthService: AuthService,
        private translate: TranslateService,
        private _organizationManagementService: OrganizationManagementService,
        private storeService: StoreService,
        private toast: ToasterService,
        private activatedRoute: ActivatedRoute,
        private socketService: SocketService,
        private store: Store<{
            patient: PatientState;
        }>,
        private router: Router,
        private cdRef: ChangeDetectorRef,
        private appService: AppService,
        private productFruitsService: ProductFruitsService
    ) {
        this.setLayout('collapsed');
        this.AuthService.account.subscribe((x) => {

            x ? this.productFruitsService.init() : this.productFruitsService.removeProductFruitsScript();
            this.account = x;
        });
        translate.setDefaultLang('en');
        this.loadingObs = this.storeService.getLoadingState();
        this.checkInternetConnectivity();
        this.patientData$ = store.pipe(select('patient'));
        //if user id exists in url then fetch data for him.
        this.updateUserState();
    }
    @HostListener('document:mousemove', ['$event'])
    @HostListener('document:keypress', ['$event'])
    onActivity() {
        this.AuthService.onActivity();
    }
    ngOnInit(): void {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            this.previousUrl = this.currentUrl;
            this.currentUrl = event.url;
            this.appService.setPreviousUrl(this.previousUrl);
        });

        this.appService.appLoader$.subscribe({
            next: (status: boolean) => {
                this.skeletonLoader = status;
            }
        })
    }
    updateUserState() {
        this.activatedRoute.queryParams.subscribe((params) => {
            if (params.patient) {
                this.patientData$.pipe(take(1)).subscribe((data) => {
                    if (!data.id) {
                        this.storeService.setLoadingState(true);
                        this.getAllFacilityAdmissions(params.patient);
                    }
                });
            }
        });
    }
    initializePatientDataByFacilityAdmission(patient, facilityAdmission?) {
        this.storeService
            .initializePatientData(patient, this.facilityManagementService._currentFacility.id, facilityAdmission)
            .pipe(
                take(1),

                finalize(() => {
                    this.storeService.setLoadingState(false);
                })
            )
            .subscribe({
                next: () => {
                    this.storeService.setLoadingState(false);
                },
                error: () => {
                    this.storeService.setLoadingState(false);
                    this.router.navigate([this.patientsListUrl]);
                }
            });
    }
    getAllFacilityAdmissions(patient) {
        if (patient) {
            this.storeService.setLoadingState(true);
            const facilityId = this.facilityManagementService._currentFacility.id;
            this.patientService
                .getPatientAdmissionHistory(patient, facilityId)
                .pipe(take(1))
                .subscribe((result) => {
                    if (result.data && result.data.length) {
                        this.storeService.setPastFacilityAdmissions(result.data);
                        const activeFacilityAdmission = result.data.find(
                            (x) =>
                                (!x.patientDischarge &&
                                    x.facility === this.facilityManagementService._currentFacility.facilityName) ||
                                (!x.discharge_date &&
                                    x.facility === this.facilityManagementService._currentFacility.facilityName)
                        );
                        if (activeFacilityAdmission) {
                            this.initializePatientDataByFacilityAdmission(patient);
                        } else {
                            const pastActiveFacilityAdmission = result.data.find(
                                (x) => x.patientDischarge || x.discharge_date
                            );
                            this.initializePatientDataByFacilityAdmission(
                                patient,
                                pastActiveFacilityAdmission?.facilityAdmission_id
                            );
                        }
                    }
                });
        }
    }
    ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }
    ngOnDestroy(): void {
        if (this.loadingObs) {
            this.loadingObs.unsubscribe();
        }
    }

    logout() {
        this.AuthService.logout();
    }
    setLayout(value: Layout) {
        this.layout = value;
    }

    checkInternetConnectivity() {
        fromEvent(window, 'online').subscribe(() => {
            this.toast.show({ title: 'Internet Connected!', body: "You're back online.", type: 'success' });
        });

        fromEvent(window, 'offline').subscribe(() => {
            this.toast.show({ title: 'Internet Disconnected!', body: "You're offline.", type: 'warning' });
        });
    }
}
