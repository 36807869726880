import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
@Component({
    selector: 'app-bar-card',
    templateUrl: './bar-card.component.html',
    styleUrls: ['./bar-card.component.scss'],
    providers: [{ provide: NgbDropdown }],
})
export class BarCardComponent {
    @Input() values: any[];
    @Input() fixedWidth = false;
    @Input() therapyMessage = ' ';
    @Input() button: string;
    @Input() showDottedDropdown: boolean;
    @Input() wrapperClass: '';
    @Input() nameWrapperClass: '';
    @Input() PaddingClass: '';
    @Output() viewTherapy = new EventEmitter<string>();
    @Output() dropDownSelect = new EventEmitter<string>();

    dottedAuthState: string;
    @Output() dateChange = new EventEmitter<string>();

    openTherapyAdmission(value: string) {
        this.viewTherapy.emit(value);
    }

    dottedDropdownSelect(event: string) {
        this.dropDownSelect.emit(event);
    }

    setDottedAuthState(event: string) {
        if (event === 'Not Authorized' || event === 'Authorized')
            event === 'Not Authorized'
                ? (this.dottedAuthState = 'Authorize')
                : (this.dottedAuthState = 'Edit Authorization');
    }
    changeDate(event: string) {
        this.dateChange.emit(event);
    }
}
