<div ngbDropdown *ngIf="_params?.value" class="d-inline-block" [container]="'body'">
    <!-- pill -->

    <div
        *ngIf="isLocked"
        class="pill"
        [ngClass]="{
            'opacity-50 pointer-events-none': checkDisabled(),
            ready: isReady,
            pending: !isReady
        }"
        loading="lazy"
        style="cursor: pointer"
    >
        <span>{{ selectedOption }}</span>
    </div>

    <div
        *ngIf="!isLocked"
        class="pill"
        [ngClass]="{
            'opacity-50 pointer-events-none': checkDisabled(),
            ready: isReady,
            pending: !isReady
        }"
        loading="lazy"
        style="cursor: pointer"
        ngbDropdownToggle
    >
        <span>{{ selectedOption }}</span>
    </div>

    <!-- dropdown options -->

    <div ngbDropdownMenu *ngIf="!isLocked" aria-labelledby="dropdownBasic1">
        <ng-container *ngFor="let option of options">
            <button
                [ngClass]="{
                    'text-danger':
                        option.name?.toLowerCase() === 'delete' ||
                        option.name?.toLowerCase() === 'undo visit status' ||
                        option.name?.toLowerCase() === 'delete note',
                    'opacity-50 pointer-events-none': option.disabled
                }"
                class="body-small"
                ngbDropdownItem
                style="color: #104061"
                (click)="selectOption(option.value)"
            >
                {{ option.name | titlecase }}
            </button>
        </ng-container>
    </div>
</div>
