<div class="row mt-4">
    <div [class]="printMode ? 'col-md-12' : 'col-md-9'">
        <div class="custom-card" *ngIf="!(loadingObs | async)?.isLoading">
            <div *ngIf="!printMode" class="header">
                <p>Addendum</p>
            </div>
            <div class="body card-padding">
                <app-view-mode-paragraph [paragraph]="addendum?.description"></app-view-mode-paragraph>
            </div>
        </div>
    </div>
</div>
