import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DIALOG_DATA } from '@app/helpers/dialog-tokens';
import { findTherapyCaseByParamId } from '@app/helpers/utils/therapy-case.utils';
import { FacilityAdmission, Patient, TherapyAdmission } from '@app/interfaces';
import { Doc_Notes, EXPORT_DOC_NOTES, EXPORT_DOC_NOTES_KEYS } from '@app/interfaces/documentation';
import { Account, User } from '@app/models';
import { AuthService, DialogRef, DocumentationReportService, DocumentationService, FacilityManagementService, ToasterService } from '@app/services';
import { therapyadmissionChangedAction } from '@app/store';
import { Store, select } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-co-sign-note',
    templateUrl: './co-sign-note.component.html',
    styleUrls: ['./co-sign-note.component.scss'],
})
export class CoSignNoteComponent {

    private readonly onDestroy = new Subject<void>();
    loader = false
    isAgree = false;
    pendingResponse = false;
    heading = 'Co-Sign the ';
    coSignedObject
    user: User
    currentTherapyAdmission: TherapyAdmission;
    currentTherapyAdmissionIndex: number;
    therapyAdmissions: TherapyAdmission[];
    therapyDiscipline: string;
    currentDocIndex: number;
    patient: Patient;
    facilityId: string;
    integratedOrganizationId: string;
    exportEnabled: boolean;
    facility: any;
    loggedInUser = '';

    constructor(
        @Inject(DIALOG_DATA) private data,
        private ref: DialogRef,
        private route: ActivatedRoute,
        private toasterService: ToasterService,
        private store: Store<{
            user: Account;
            therapyAdmission: TherapyAdmission[];
            patient: Patient;
            facilityAdmission: FacilityAdmission
        }>,
        private documentationService: DocumentationService,
        private documentationReportService: DocumentationReportService,
        private facilityManagementService: FacilityManagementService,
        private authService: AuthService
    ) {
        this.store
            .pipe(select('user'))
            .pipe(takeUntil(this.onDestroy))
            .subscribe((user) => {
                this.user = user.user;
            });

        this.store
            .pipe(select('patient'))
            .pipe(takeUntil(this.onDestroy))
            .subscribe((patient) => this.patient = patient)

        this.store
            .select('facilityAdmission')
            .pipe(takeUntil(this.onDestroy))
            .subscribe((facilityAdmission) => {
                if (facilityAdmission && facilityAdmission?.facility) {
                    this.facilityId =
                        typeof facilityAdmission.facility === 'string'
                            ? facilityAdmission.facility
                            : (facilityAdmission.facility as any)._id;
                    this.facilityManagementService
                        .getFacilityById(this.facilityId)
                        .pipe()
                        .subscribe((res: any) => {
                            this.facility = res.data
                            this.exportEnabled = res.data.exportEnabled;
                        });
                }
            });

        this.heading = this.heading + data.heading;
        this.coSignedObject = {
            signedDate: (new Date()).toISOString(),
            signedBy: this.user.therapist.id,
            signedTherapist: {
                user: {
                    firstName: this.user.firstName,
                    lastName: this.user.lastName
                },
                licensures: {
                    licensureNumber: this.user.therapist.licensureNumber,
                },
                therapistType: this.user.therapist.therapistType,
                signCredentials: this.user.therapist.signCredentials
            }
        }

        this.route.queryParamMap.subscribe({
            next: (params) => {
                this.therapyDiscipline = params.get('discipline');
            },
        });

        this.store
            .select('therapyAdmission')
            .pipe(takeUntil(this.onDestroy))
            .subscribe((therapyAdmissions) => {
                this.therapyAdmissions = cloneDeep(therapyAdmissions)
                if (therapyAdmissions && therapyAdmissions.length > 0) {
                    const queryString = this.route.snapshot.queryParams;
                    this.currentTherapyAdmission = findTherapyCaseByParamId(therapyAdmissions, queryString['therapy-case']);
                    this.therapyDiscipline ||= this.currentTherapyAdmission?.therapyDiscipline;
                    this.therapyDiscipline ||= 'PT';
                    this.currentTherapyAdmission = cloneDeep(this.currentTherapyAdmission);

                    this.currentTherapyAdmissionIndex = therapyAdmissions.findIndex(admission => admission._id === this.currentTherapyAdmission?._id);

                    if (![Doc_Notes.EVALUATION, Doc_Notes.Discharge].includes(this.data.documentType)) this.currentDocIndex = this.getCurrentDocIndex()
                }
            });
    }
    ngOnInit(): void {
        // Showing Currently logged in user's credentials in sign modal
        if (this.authService.isTherapist) {
            const user = this.authService.accountValue.user;
            if (user.firstName && user.lastName && user.therapist) {
                this.loggedInUser = `${user.lastName}, ${user.firstName} ${' - ' + user.therapist.therapistType}`;
            }
        }
    }
    onClose(data?: any) {
        this.ref.close(data);
    }
    onSign() {
        this.updateNote()
    }

    async updateNote() {
        const note = { ...this.data.document, coSigned: this.coSignedObject };
        let exportData
        if (this.patient.isAnIntegratedPatient) {
            exportData = await this.getDocExportData(note, this.data.documentType)
        }

        if (![Doc_Notes.EVALUATION, Doc_Notes.Discharge].includes(this.data.documentType)) {
            this.currentTherapyAdmission.documentation[this.data.documentType][this.currentDocIndex] = note;
        } else {
            this.currentTherapyAdmission.documentation[this.data.documentType] = note;
        }

        this.documentationService.coSignDocument(this.data.documentType, this.data.document.id, this.coSignedObject, exportData).subscribe(() => {
            this.pendingResponse = false;
            this.updateStore()
            this.toasterService.show({
                title: 'Success',
                body: 'Document co-signed successfully.',
                type: 'success',
            });
            this.ref.close();
        });
    }

    async getDocExportData(note, documentType) {
        const report = await this.getDocReport(note, documentType);
        return this.getReportMeta(note, report.exportDocType, report);
    }

    async getDocReport(note, type) {
        let prepareDocPromise
        let exportDocType
        let prepareDataKey
        if (type === Doc_Notes.Daily_note) {
            prepareDocPromise = this.documentationReportService.prepareDailyNoteDataForIntegration(note)
            exportDocType = EXPORT_DOC_NOTES.DAILY_NOTE
            prepareDataKey = EXPORT_DOC_NOTES_KEYS.DAILY_NOTE
        } else if (type === Doc_Notes.EVALUATION) {
            prepareDocPromise = this.documentationReportService.prepareEvaluationDataForIntegration(note)
            exportDocType = EXPORT_DOC_NOTES.EVALUATION
            prepareDataKey = EXPORT_DOC_NOTES_KEYS.EVALUATION
        } else if (type === Doc_Notes.Progress) {
            prepareDocPromise = this.documentationReportService.prepareProgressNoteDataForIntegration(note)
            exportDocType = EXPORT_DOC_NOTES.PROGRESS_NOTE
            prepareDataKey = EXPORT_DOC_NOTES_KEYS.PROGRESS_NOTE
        } else if (type === Doc_Notes.Re_Cert) {
            prepareDocPromise = this.documentationReportService.prepareRecertificationDataForIntegration(note)
            exportDocType = EXPORT_DOC_NOTES.RECERTIFICATION
            prepareDataKey = EXPORT_DOC_NOTES_KEYS.RECERTIFICATION
        } else if (type === Doc_Notes.Discharge) {
            prepareDocPromise = this.documentationReportService.prepareDischargeDataForIntegration(note)
            exportDocType = EXPORT_DOC_NOTES.DISCHARGE_NOTE
            prepareDataKey = EXPORT_DOC_NOTES_KEYS.DISCHARGE_NOTE
        }
        await prepareDocPromise
        const docReport = this.documentationReportService[prepareDataKey]
        return { docReport, exportDocType }
    }

    getReportMeta(note, exportDocType, report) {
        return {
            documentMeta: {
                documentType: exportDocType,
                discipline: this.therapyDiscipline,
                timestamp: note.signed.signDate,
                patientId: this.patient.integrationEntity.patientId,
                facilityId: this.facilityId,
                facilityOID: this.facility.facilityOID,
                patientName: this.patient.lastName + '_' + this.patient.firstName,
                effectiveFromDate: this.currentTherapyAdmission.SOCDate,
                effectiveThroughDate: note.signed.signDate,
                ...(this.data.changeHistoryCount ? { counter: this.data.changeHistoryCount } : {}),
            },
            document: report.docReport
        }
    }

    updateStore() {
        this.therapyAdmissions[this.currentTherapyAdmissionIndex] = this.currentTherapyAdmission
        this.store.dispatch(
            therapyadmissionChangedAction({
                therapyadmission: cloneDeep(this.therapyAdmissions),
            })
        );
    }

    getCurrentDocIndex() {
        return this.currentTherapyAdmission?.documentation[this.data.documentType].findIndex(
            (x: any) => x.id == this.data.document.id
        );
    }

}