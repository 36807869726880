import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
const baseUrl = environment.apiUrl + '/billing/invoice';
@Injectable({
    providedIn: 'root',
})
export class InvoiceGenerationService {
    constructor(private http: HttpClient) {}

    createInvoice(payload: any) {
        return this.http.post(`${baseUrl}/create-invoice`, payload, {
            withCredentials: true,
        });
    }
    updateInvoice(payload: any) {
        return this.http.put(`${baseUrl}/update-invoice`, payload, {
            withCredentials: true,
        });
    }
    getInvoiceById(id: string): Observable<any> {
        return this.http.get<any>(`${baseUrl}/${id}`);
    }
    getInvoiceByInvoiceNumber(invoiceNumber: string): Observable<any> {
        return this.http.get<any>(`${baseUrl}/invoice-number/${invoiceNumber}`);
    }
}
