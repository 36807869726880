import { Component, ElementRef, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-skills-cell-renderer',
    templateUrl: './skills-cell-renderer.component.html',
    styleUrls: ['./skills-cell-renderer.component.scss'],
})
export class SkillsCellRendererComponent implements ICellRendererAngularComp, OnDestroy {
    @ViewChild('tooltipProcedure') toolTipProcedureElement: ElementRef;
    @ViewChild('tooltipJustification') tooltipJustificationElement: ElementRef;
    @ViewChild('tooltipGoal') tooltipGoalElement: ElementRef;
    @ViewChild('tooltipDescription') tooltipDescriptionElement: ElementRef;
    @ViewChild('defaultTooltip') defaultTooltipElement: ElementRef;
    params;
    tooltip = false;
    currentTooltip = null;
    constructor(private renderer: Renderer2) {}

    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.removeTooltipInstances();
    }
    refresh(): boolean {
        return;
    }

    onMouseEnter(event, container: any) {
        if (container === 'goal') {
            this.currentTooltip = this.tooltipGoalElement;
        } else if (container === 'skill') {
            this.currentTooltip = this.tooltipJustificationElement;
        } else if (container === 'procedure') {
            this.currentTooltip = this.toolTipProcedureElement;
        } else if (container === 'Description') {
            this.currentTooltip = this.tooltipDescriptionElement;
        }
        this.tooltip = true;
        this.currentTooltip.nativeElement.style.top = event.pageY + 10 + 'px';
        this.currentTooltip.nativeElement.style.left = event.pageX - 150 + 'px';
        this.renderer.insertBefore(document.body, this.currentTooltip.nativeElement, document.body.firstChild);
        this.currentTooltip.nativeElement.style.display = 'block';
    }

    onMouseLeave() {
        this.tooltip = false;
        this.currentTooltip.nativeElement.style.display = 'none';
        this.renderer.removeChild(document.body, this.currentTooltip.nativeElement);
    }

    removeTooltipInstances(): void {
        const toolTips = document.body.querySelectorAll('.info-details');
        toolTips.forEach((box) => {
            box.remove();
        });
    }

    ngOnDestroy(): void {
        this.removeTooltipInstances();
    }
}
